// gimme: conversations-inbox-ui 06/09/2020 131cc2650403ed82bcd02adbe470fec8b864e1c9

import { handleActions, combineActions } from 'redux-actions';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestStartedWithOperator } from 'conversations-async-data/async-data/operators/requestStartedWithOperator';
import { requestFailedWithOperator } from 'conversations-async-data/async-data/operators/requestFailedWithOperator';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { deleteEntry } from 'conversations-async-data/indexed-async-data/operators/deleteEntry';
import ActivelyPublishing from 'conversations-message-history/unpublished-messages/records/ActivelyPublishing';
import FailedToPublish from 'conversations-message-history/unpublished-messages/records/FailedToPublish';
// @ts-expect-error file not typed
import { getId } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { messageKeyInvariant } from '../../_internal/invariants/messageKeyInvariant';
import { PUBLISH_MESSAGE } from '../constants/asyncActionTypes';
import { PUBLISH_EMAIL } from '../../../email/public/constants/asyncActionTypes';
const initialState = new IndexedAsyncData({
  name: 'publishing',
  notSetValue: new AsyncData(),
  // TODO CMF; replace with a proper id invariant
  idTransform: id => id,
  idInvariant: messageKeyInvariant
});
export default handleActions({
  [combineActions(PUBLISH_MESSAGE.STARTED, PUBLISH_EMAIL.STARTED).toString()](state, action) {
    const {
      transient,
      threadId,
      channel,
      message
    } = action.payload;
    return !transient ? updateEntry(getId(message), requestStartedWithOperator(() => ActivelyPublishing({
      threadId,
      channel,
      message
    })), state) : state;
  },
  [combineActions(PUBLISH_MESSAGE.SUCCEEDED, PUBLISH_EMAIL.SUCCEEDED).toString()](state, action) {
    const {
      message,
      transient
    } = action.payload;
    return !transient ? deleteEntry(getId(message), state) : state;
  },
  [combineActions(PUBLISH_MESSAGE.FAILED, PUBLISH_EMAIL.FAILED).toString()](state, action) {
    const {
      transient,
      threadId,
      channel,
      message,
      allowRetry
    } = action.payload;
    return !transient ? updateEntry(getId(message), requestFailedWithOperator(() => FailedToPublish({
      threadId,
      channel,
      message,
      allowRetry
    })), state) : state;
  }
}, initialState);