'use es6';

import { Set as ImmutableSet } from 'immutable';
import once from 'transmute/once';
import userInfo from 'hub-http/userInfo';
const userScopes = once(() => {
  return userInfo().then(({
    user: {
      scopes
    }
  }) => ImmutableSet(scopes));
});
export { userScopes };