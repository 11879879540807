export function parseRawDraft(draft) {
  try {
    const parsedDraft = JSON.parse(draft);
    const {
      id,
      timestamp,
      version,
      data
    } = parsedDraft;
    return {
      id,
      timestamp,
      version,
      data
    };
  } catch (e) {
    return null;
  }
}