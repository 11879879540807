import { connect } from 'react-redux';
import RequestAccessToInbox from './RequestAccessToInboxPrompt';
import { sendRequestAccessToInboxEmail } from '../../actions/sendRequestAccessToInboxEmail';
import { getCurrentInboxId, getLastAccessedInboxId } from 'conversations-inbox-lib/unified-inbox/public/selectors';
const mapStateToProps = state => {
  return {
    currentInboxId: getCurrentInboxId(state),
    lastAccessedInboxId: getLastAccessedInboxId(state)
  };
};
const mapDispatchToProps = {
  sendRequestAccessToInboxEmail
};
export default connect(mapStateToProps, mapDispatchToProps
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof RequestAccessToInboxPromp... Remove this comment to see the full error message
)(RequestAccessToInbox);