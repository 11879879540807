import getIn from 'transmute/getIn';
import map from 'transmute/map';
import pipe from 'transmute/pipe';
import { TICKET } from 'customer-data-objects/constants/ObjectTypes';
import { isSucceeded } from 'conversations-async-data/async-data/operators/statusComparators';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { useReduxAsyncFetcher } from 'conversations-async-data/async-data/hooks/useReduxAsyncFetcher';
import { fetchTicketSources } from '../actions/fetchTicketSources';
import { getAsyncTicketSources } from '../selectors/getAsyncTicketSources';
import { useMemo } from 'react';
const getTicketSourceTypes = pipe(getData, getIn(['properties', 'source_type', 'options']), map(getIn(['value'])));
export const useTicketSources = () => {
  const {
    asyncData,
    retryFailed
  } = useReduxAsyncFetcher({
    action: fetchTicketSources,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Curry1<unknown, unknown>' is not assignable ... Remove this comment to see the full error message
    selector: getAsyncTicketSources,
    id: TICKET,
    idTransform: id => ({
      objectType: id
    })
  });
  const validSourceTypes = useMemo(() => isSucceeded(asyncData) ? getTicketSourceTypes(asyncData) : null, [asyncData]);
  return {
    asyncTicketSources: asyncData,
    retryFailed,
    validSourceTypes
  };
};