import { createAction } from '@reduxjs/toolkit';
/**
 * @description This action should be dispatched when a ThreadsUpdateBulk
 * realtime message is received.
 */
export const threadsBulkUpdated = createAction('realtime/ThreadsBulkUpdate', ({
  message
}) => {
  return {
    payload: {
      message
    }
  };
});