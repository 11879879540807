import Track from '../../js/utils/ZorseGrrTaggingTracker';
import { LOAD_TICKET, CLOSE_TICKET, SAVE_PRODUCT_AREA, SAVE_RESOLUTION, SAVE_ROADBLOCK, SAVE_SUBJECT, START_SUBJECT_EDIT, CANCEL_SUBJECT_EDIT, CLEAR_ROADBLOCK } from './ActionTypes';
import { getTicketByConversation, resolveTicket, updateProductArea, updateResolution, updateRoadblock, updateSubject } from '../utils/ZorseGrrTaggingApi';
import { EventState } from '../types/events';
export function loadTicket(conversationId) {
  return dispatch => {
    dispatch({
      conversationId,
      type: LOAD_TICKET,
      meta: 'request'
    });
    return getTicketByConversation(conversationId).then(payload => dispatch({
      conversationId,
      type: LOAD_TICKET,
      meta: 'response',
      payload
    }), payload => dispatch({
      conversationId,
      type: LOAD_TICKET,
      error: true,
      payload
    }));
  };
}
export function closeTicket(ticketId) {
  return dispatch => {
    Track.resolveTicket(EventState.INITIATED);
    dispatch({
      type: CLOSE_TICKET,
      meta: 'request'
    });
    return resolveTicket(ticketId).then(__ => {
      Track.resolveTicket(EventState.SUCCEEDED);
      return dispatch({
        type: CLOSE_TICKET,
        meta: 'response'
      });
    }, payload => {
      Track.resolveTicket(EventState.FAILED);
      return dispatch({
        type: CLOSE_TICKET,
        error: true,
        payload
      });
    });
  };
}
export function startSubjectEdit() {
  return dispatch => dispatch({
    type: START_SUBJECT_EDIT
  });
}
export function cancelSubjectEdit() {
  return dispatch => dispatch({
    type: CANCEL_SUBJECT_EDIT
  });
}
export function clearRoadblock() {
  return dispatch => dispatch({
    type: CLEAR_ROADBLOCK
  });
}
export function saveProductArea(ticketId, productArea) {
  return dispatch => {
    dispatch({
      type: SAVE_PRODUCT_AREA,
      meta: 'request',
      payload: productArea
    });
    return updateProductArea(ticketId, productArea.id).then(() => dispatch({
      type: SAVE_PRODUCT_AREA,
      meta: 'response',
      payload: productArea
    }), payload => dispatch({
      type: SAVE_PRODUCT_AREA,
      error: true,
      payload
    }));
  };
}
export function saveResolution(ticketId, resolution) {
  return dispatch => {
    dispatch({
      type: SAVE_RESOLUTION,
      meta: 'request',
      payload: resolution
    });
    return updateResolution(ticketId, resolution.id).then(() => dispatch({
      type: SAVE_RESOLUTION,
      meta: 'response',
      payload: resolution
    }), payload => dispatch({
      type: SAVE_RESOLUTION,
      error: true,
      payload
    }));
  };
}
export function saveRoadblock(ticketId, roadblock) {
  return dispatch => {
    dispatch({
      type: SAVE_ROADBLOCK,
      meta: 'request',
      payload: roadblock
    });
    return updateRoadblock(ticketId, roadblock.id).then(() => dispatch({
      type: SAVE_ROADBLOCK,
      meta: 'response',
      payload: roadblock
    }), payload => dispatch({
      type: SAVE_ROADBLOCK,
      error: true,
      payload
    }));
  };
}
export function saveSubject(ticketId, subject) {
  return dispatch => {
    Track.editSubject(EventState.INITIATED);
    dispatch({
      type: SAVE_SUBJECT,
      meta: 'request',
      payload: subject
    });
    return updateSubject(ticketId, subject).then(() => {
      Track.editSubject(EventState.SUCCEEDED);
      return dispatch({
        type: SAVE_SUBJECT,
        meta: 'response',
        payload: subject
      });
    }, payload => {
      Track.editSubject(EventState.FAILED);
      return dispatch({
        type: SAVE_SUBJECT,
        error: true,
        payload
      });
    });
  };
}