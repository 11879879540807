import pipe from 'transmute/pipe';
import { List } from 'immutable';

// @ts-expect-error module not typed

import { getChannelInstanceIdByDeliveryIdentifier } from '../../../channel-instances/public/operators';
// @ts-expect-error module not typed
import { buildDeliveryIdentifier } from 'conversations-message-history/common-message-format/operators/buildDeliveryIdentifier';
import { buildCMFSender } from 'conversations-message-history/common-message-format/operators/buildCMFSender';
import { setSenders, setChannelInstanceId, setGenericChannelId } from 'conversations-message-history/common-message-format/operators/commonMessageSetters';
import { EMAIL_GENERIC_CHANNEL_ID } from '../../../generic-channels/public/constants';
import { updateFromEmail } from '../../_internal/operators/updateFromEmail';
import { getSenderIdFromMessage, getSenderTypeFromMessage } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
export const updateSenders = (deliveryIdentifierValue, channelInstances, deliveryIdentifierType, senderField, nextConnectedAccount, commonMessage) => {
  // Legacy update the fromAddress/connectedAccountAddress in the EmailMetadataAttachment
  const messageWithUpdatedAttachment = nextConnectedAccount ? updateFromEmail(nextConnectedAccount, commonMessage) : commonMessage;
  const nextDeliveryIdentifier = buildDeliveryIdentifier({
    deliveryIdentifierType,
    deliveryIdentifierValue
  });
  const senderId = getSenderIdFromMessage(commonMessage);
  const senderType = getSenderTypeFromMessage(commonMessage);
  const senders = List([buildCMFSender({
    senderId,
    senderType,
    senderField,
    deliveryIdentifier: nextDeliveryIdentifier
  })]);
  const fromAddressChannelInstanceId = getChannelInstanceIdByDeliveryIdentifier({
    channelInstances,
    deliveryIdentifierValue
  });
  const updatedCommonMessage = pipe(setSenders(senders), setChannelInstanceId(fromAddressChannelInstanceId), setGenericChannelId(EMAIL_GENERIC_CHANNEL_ID))(messageWithUpdatedAttachment);
  return updatedCommonMessage;
};