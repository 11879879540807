import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { updateEntries } from 'conversations-async-data/indexed-async-data/operators/updateEntries';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { CAPABILITIES_DATA_FETCH } from '../constants/asyncActionTypes';
import { capabilityKeyInvariant } from '../operators/capabilityKeyInvariant';
import { MERGE_SUPPLEMENTAL_OBJECTS } from '../constants/actionTypes';
const initialState = new IndexedAsyncData({
  idInvariant: capabilityKeyInvariant,
  idTransform: capabilityKey => capabilityKey,
  name: 'capabilitiesData',
  notSetValue: new AsyncData({
    data: {}
  })
});
export default handleActions({
  [CAPABILITIES_DATA_FETCH.STARTED](state, action) {
    const {
      requestArgs: {
        capabilityKeys
      }
    } = action.payload;
    return updateEntries(capabilityKeys, (__capabilityKey, asyncData) => requestStarted(asyncData), state);
  },
  [CAPABILITIES_DATA_FETCH.SUCCEEDED](state, action) {
    const {
      requestArgs: {
        capabilityKeys
      },
      data
    } = action.payload;
    return updateEntries(capabilityKeys, (capabilityKey, asyncData) => requestSucceededWithOperator(current => (data === null || data === void 0 ? void 0 : data.get(capabilityKey)) || current || {}, asyncData), state);
  },
  [CAPABILITIES_DATA_FETCH.FAILED](state, action) {
    const {
      requestArgs: {
        capabilityKeys
      }
    } = action.payload;
    return updateEntries(capabilityKeys, (__, asyncData) => requestFailed(asyncData), state);
  },
  [MERGE_SUPPLEMENTAL_OBJECTS](state, action) {
    const {
      capabilityKeys,
      supplementalObjects
    } = action.payload;
    return updateEntries(capabilityKeys, (__, asyncData) => requestSucceededWithOperator(current => {
      const newSupplementalObjects = Object.assign({}, current === null || current === void 0 ? void 0 : current.supplementalObjects, supplementalObjects);
      return Object.assign({}, current, {
        supplementalObjects: newSupplementalObjects
      });
    }, asyncData), state);
  }
}, initialState);