import I18n from 'I18n';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { buildRequestErrorMetaObject } from 'conversations-error-reporting/error-actions/builders/buildRequestErrorMetaObject';
import { CONTACT } from 'customer-data-objects/constants/ObjectTypes';
import { trackEvent } from '../../usage-tracking/clients/usageTracker';
import { disassociateContactClient } from '../clients/disassociateContactClient';
import { DISASSOCIATE_CONTACT } from '../constants/asyncActionTypes';
import { getUnassociatedCrmObject } from '../constants/Events';
const disassociateContactAsyncAction = createAsyncAction({
  requestFn: disassociateContactClient,
  actionTypes: DISASSOCIATE_CONTACT,
  toRecordFn: response => response,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'requestArgs' does not exist on type '{}'... Remove this comment to see the full error message
  successMetaActionCreator: ({
    requestArgs: {
      contactName
    }
  }) => ({
    notification: {
      type: 'success',
      titleText: I18n.text(`conversations-inbox-ui.alerts.disassociationSuccess.title`, {
        name: contactName
      })
    }
  }),
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type '{}'.
  failureMetaActionCreator: ({
    error,
    requestArgs: {
      contactName
    }
  }) => buildRequestErrorMetaObject({
    error,
    titleText: I18n.text(`conversations-inbox-ui.alerts.disassociationFailure.title`, {
      name: contactName
    })
  })
});
export const disassociateContact = ({
  contactName,
  threadId
}) => dispatch => {
  dispatch(disassociateContactAsyncAction({
    contactName,
    threadId
  }));
  trackEvent(getUnassociatedCrmObject({
    associationType: CONTACT
  }));
};