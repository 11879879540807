import { getDOMNodeByMessageId } from '../operators/getDOMNodeByMessageId';
// @ts-expect-error untyped file

export const AUTO_SCROLL_THRESHOLD = 300;

/**
 * Scrolls to the given message ID in history.
 * @param {String} messageId
 * @param {ScrollOptions} options
 */
export const scrollToMessageFactory = ({
  scrollWrapperRef
}) => messageId => {
  const current = scrollWrapperRef.current;
  if (!current || !current.state) return;
  const element = getDOMNodeByMessageId(messageId);
  if (messageId && element) {
    current.setScrollTop(element.offsetTop);
  }
};

/**
 * Scrolls to the bottom of the visible content.
 */
export const scrollToBottomFactory = ({
  scrollWrapperRef
}) => () => {
  const current = scrollWrapperRef.current;
  if (current) current.scrollToBottom();
};

/**
 * Given an optional bottom threshold, scrolls to the bottom of the visible content if inside
 * or at that threshold. If outside the threshold, no action is taken.
 * @param {number} threshold the inclusive bound to check. Defaults to `300`
 */
export const scrollToBottomIfInThresholdFactory = ({
  scrollWrapperRef
}) => ({
  threshold = AUTO_SCROLL_THRESHOLD
} = {}) => {
  const current = scrollWrapperRef.current;
  if (!current || !current.state) return;
  if (scrollWrapperRef.current.state.distanceToBottom <= threshold || current.getScrollDistanceFromBottom() <= threshold) {
    current.scrollToBottom();
  }
};