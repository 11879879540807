import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
export const NETWORK_CHECK_TIMEOUT_MS = 5000;
export function checkNetwork() {
  return noAuthApiClient.get(`network-check/is-the-internet-up.txt`, {
    timeout: NETWORK_CHECK_TIMEOUT_MS,
    query: {
      rnd: Math.random()
    }
  }).then(() => ({
    online: true
  })).catch(error => ({
    online: false,
    error
  }));
}