import { useQuery, registerQuery } from 'data-fetching-client';
import { fetchGenericChannels } from '../clients/fetchGenericChannels';
const FETCH_GENERIC_CHANNELS = 'fetchGenericChannels';
const FETCH_GENERIC_CHANNELS_QUERY = registerQuery({
  fieldName: FETCH_GENERIC_CHANNELS,
  fetcher: fetchGenericChannels
});
export function usePojoGenericChannels() {
  const {
    data,
    loading,
    error
  } = useQuery(FETCH_GENERIC_CHANNELS_QUERY);
  return {
    data: data && data[FETCH_GENERIC_CHANNELS],
    loading,
    error
  };
}