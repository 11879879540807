import http from 'conversations-http/clients/http';
import { registerQuery } from 'data-fetching-client';
export const CONTACT_PROMOTION_FIELD_NAME = 'promoteContact';
export const PROMOTE_TO_CONTACT_QUERY = registerQuery({
  fieldName: CONTACT_PROMOTION_FIELD_NAME,
  args: ['firstName', 'lastName', 'email', 'vid'],
  fetcher({
    firstName,
    lastName,
    email,
    vid,
    portalId,
    focusedThreadId
  }) {
    return http.put(`messages/v2/visitor/promote/to/contact/${focusedThreadId}`, {
      query: {
        portalId
      },
      data: {
        vid,
        firstName,
        lastName,
        email
      }
    });
  }
});