'use es6';

import AssociatedCompanyProperty from 'customer-data-objects/property/special/AssociatedCompanyProperty';
import AssociatedContactProperty from 'customer-data-objects/property/special/AssociatedContactProperty';
import FormSubmissionsProperty from 'customer-data-objects/property/special/FormSubmissionsProperty';
import InboundDbImportProperty from 'customer-data-objects/property/special/InboundDbImportProperty';
import ListMembershipsProperty from 'customer-data-objects/property/special/ListMembershipsProperty';
import InboundDbListMembershipProperty from 'customer-data-objects/property/special/InboundDbListMembershipProperty';
import RelatesToProperty from 'customer-data-objects/property/special/RelatesToProperty';
import { inboundDbPropertiesToEngagementsProperties } from 'crm_data/engagements/inboundDbProperties/engagementInboundDbObjectHelpers';
import filterNot from 'transmute/filterNot';
import getIn from 'transmute/getIn';
import { Map as ImmutableMap, Set as ImmutableSet, List } from 'immutable';
import * as ImmutableAPI from '../api/ImmutableAPI';
import indexBy from 'transmute/indexBy';
import partial from 'transmute/partial';
import pluck from 'transmute/pluck';
import invariant from 'react-utils/invariant';
import { COMPANY, CONTACT, DEAL, ENGAGEMENT, TASK, VISIT, TICKET } from 'customer-data-objects/constants/ObjectTypes';
import pipe from 'transmute/pipe';
import PropertyGroupRecord from 'customer-data-objects/property/PropertyGroupRecord';
import PropertyRecord from 'customer-data-objects/property/PropertyRecord';
import toSeq from 'transmute/toSeq';
import DealStageProbabilityProperty from 'customer-data-objects/property/special/DealStageProbabilityProperty';
import { isObjectTypeId, ObjectTypesToIds } from 'customer-data-objects/constants/ObjectTypeIds';
function getGroupsApi(objectTypeOrId) {
  const objectTypeId = ObjectTypesToIds[objectTypeOrId] || objectTypeOrId;
  invariant(isObjectTypeId(objectTypeId), `${objectTypeId} is not supported`, objectTypeOrId);
  return `properties/v4/groups/${objectTypeId}`;
}
export function normalizePropertyGroups(json) {
  const normalizedGroups = ImmutableMap(json.reduce((groupsMap, group) => {
    const propertyNames = group.properties.map(property => property.name);
    groupsMap[group.name] = PropertyGroupRecord.fromJS(Object.assign({}, group, {
      properties: propertyNames
    }));
    return groupsMap;
  }, {}));
  const normalizedProperties = ImmutableMap(json.reduce((allProperties, group) => {
    const properties = group.properties || List();
    return properties.reduce((acc, property) => {
      acc[property.name] = PropertyRecord.fromJS(property);
      return acc;
    }, allProperties);
  }, {}));
  return ImmutableMap({
    groups: normalizedGroups,
    properties: normalizedProperties
  });
}

// performs exact inverse of normalize function above. in technical terms:
// denormalize(normalize(apiResponse)) === apiResponse
export function denormalizePropertyGroups(map) {
  return map.get('groups').reduce((acc, entry) => {
    return acc.push(entry.set('properties', entry.properties.map(p => map.get('properties').get(p))));
  }, List()).toJS();
}
export function normalizePropertyGroupsImmutable(json) {
  const groups = json.results ? json.results : json;
  if (!groups.toJS) {
    return normalizePropertyGroups(groups);
  }
  const ret = ImmutableMap({
    groups: groups.reduce((groupsMap, group) => {
      return groupsMap.set(group.get('name'), PropertyGroupRecord.fromJS(group.set('properties', pluck('name', group.get('properties'))).toJS()));
    }, ImmutableMap()),
    properties: groups.reduce((allProperties, group) => {
      const properties = group.get('properties') || List();
      return properties.reduce((acc, property) => {
        return acc.set(property.get('name'), PropertyRecord.fromJS(property.toJS()));
      }, allProperties);
    }, ImmutableMap())
  });
  return ret;
}
const hiddenVisitProperties = ImmutableSet.of('address1', 'address2', 'dnsresolves', 'email', 'latitude', 'longitude', 'numcontacts', 'organization');
const dropHiddenProperties = pipe(toSeq, filterNot(({
  name
}) => hiddenVisitProperties.contains(name)), indexBy(getIn(['name'])));
const PROSPECTS_GROUP = 'prospects';
export function normalizeBidenGroups(json) {
  const visibleItems = dropHiddenProperties(json);
  return ImmutableMap({
    groups: ImmutableMap({
      [PROSPECTS_GROUP]: PropertyGroupRecord({
        displayName: 'Prospect properties',
        displayOrder: 0,
        name: PROSPECTS_GROUP,
        properties: visibleItems.keySeq().toList()
      })
    }),
    properties: visibleItems.map(item => PropertyRecord.fromJS(Object.assign({}, item, {
      groupName: PROSPECTS_GROUP,
      isBidenProperty: true,
      readOnlyValue: true
    }))).toMap()
  });
}
const ENGAGEMENT_GROUP = 'engagement';
export function normalizeEngagementGroups(inboundDbProperties) {
  const properties = inboundDbPropertiesToEngagementsProperties(ENGAGEMENT, inboundDbProperties.map(({
    property
  }) => PropertyRecord.fromJS(property)));
  return ImmutableMap({
    groups: ImmutableMap({
      [ENGAGEMENT_GROUP]: PropertyGroupRecord({
        displayName: 'Engagements',
        displayOrder: 0,
        hubspotDefined: true,
        name: ENGAGEMENT_GROUP,
        properties: properties.keySeq().toList()
      })
    }),
    properties
  });
}
const TASKS_GROUP = 'task';
export function normalizeTaskGroups(inboundDbProperties) {
  const properties = inboundDbPropertiesToEngagementsProperties(TASK, inboundDbProperties.map(({
    property
  }) => PropertyRecord.fromJS(property)));
  return ImmutableMap({
    groups: ImmutableMap({
      [TASKS_GROUP]: PropertyGroupRecord({
        displayName: 'Tasks',
        displayOrder: 0,
        hubspotDefined: true,
        name: TASKS_GROUP,
        properties: properties.keySeq().toList()
      })
    }),
    properties
  });
}
const VISITS_GROUP = 'companyVisits';
export function normalizeVisitGroups(json) {
  const visibleItems = dropHiddenProperties(json);
  return ImmutableMap({
    groups: ImmutableMap({
      [VISITS_GROUP]: PropertyGroupRecord({
        displayName: 'Visit properties',
        displayOrder: 1,
        name: VISITS_GROUP,
        properties: visibleItems.keySeq().toList()
      })
    }),
    properties: visibleItems.map(item => PropertyRecord.fromJS(Object.assign({}, item, {
      groupName: VISITS_GROUP,
      isBidenProperty: true,
      readOnlyValue: true
    }))).toMap()
  });
}
function addRelatesToProperty(result) {
  let groupPath;
  if (!result.hasIn(['groups', TASKS_GROUP]) && !result.hasIn(['groups', ENGAGEMENT_GROUP])) {
    return result;
  } else {
    groupPath = result.hasIn(['groups', TASKS_GROUP]) ? ['groups', TASKS_GROUP, 'properties'] : ['groups', ENGAGEMENT_GROUP, 'properties'];
  }
  return result.setIn(groupPath, result.getIn(groupPath).push(RelatesToProperty.name)).setIn(['properties', RelatesToProperty.name], RelatesToProperty);
}
export function addAssociatedCompanyProperty(groupname, result) {
  if (!result.hasIn(['groups', groupname])) {
    return result;
  }
  const groupPath = ['groups', groupname, 'properties'];
  return result.setIn(groupPath, result.getIn(groupPath).push(AssociatedCompanyProperty.name)).setIn(['properties', AssociatedCompanyProperty.name], AssociatedCompanyProperty);
}
export function addAssociatedContactProperty(groupname, result) {
  if (!result.hasIn(['groups', groupname])) {
    return result;
  }
  const groupPath = ['groups', groupname, 'properties'];
  return result.setIn(groupPath, result.getIn(groupPath).push(AssociatedContactProperty.name)).setIn(['properties', AssociatedContactProperty.name], AssociatedContactProperty);
}
export function addDealStageProbability(result) {
  if (!result.hasIn(['groups', 'dealinformation'])) {
    return result;
  }
  const groupPath = ['groups', 'dealinformation', 'properties'];
  return result.setIn(groupPath, result.getIn(groupPath).push(DealStageProbabilityProperty.name)).setIn(['properties', DealStageProbabilityProperty.name], DealStageProbabilityProperty);
}
export function addFormSubmissionProperty(result) {
  if (!result.hasIn(['groups', 'contactinformation'])) {
    return Promise.resolve(result);
  }
  const groupPath = ['groups', 'contactinformation', 'properties'];
  return Promise.resolve(result.setIn(groupPath, result.getIn(groupPath).push(FormSubmissionsProperty.name)).setIn(['properties', FormSubmissionsProperty.name], FormSubmissionsProperty));
}
export function addInboundDbImportProperty(groupname = 'contactinformation', result) {
  if (!result.hasIn(['groups', groupname])) {
    return Promise.resolve(result);
  }
  const groupPath = ['groups', groupname, 'properties'];
  return Promise.resolve(result.setIn(groupPath, result.getIn(groupPath).push(InboundDbImportProperty.name)).setIn(['properties', InboundDbImportProperty.name], InboundDbImportProperty));
}
export function addListMembershipProperty(result) {
  if (!result.hasIn(['groups', 'contactinformation'])) {
    return Promise.resolve(result);
  }
  const groupPath = ['groups', 'contactinformation', 'properties'];
  return Promise.resolve(result.setIn(groupPath, result.getIn(groupPath).push(ListMembershipsProperty.name)).setIn(['properties', ListMembershipsProperty.name], ListMembershipsProperty));
}
export function addInboundDbListMembershipProperty(groupName = 'contactinformation', result) {
  if (!result.hasIn(['groups', groupName])) {
    return Promise.resolve(result);
  }
  const groupPath = ['groups', groupName, 'properties'];
  return Promise.resolve(result.setIn(groupPath, result.getIn(groupPath).push(InboundDbListMembershipProperty.name)).setIn(['properties', InboundDbListMembershipProperty.name], InboundDbListMembershipProperty));
}
export function unhideTicketPipelineProperties(result) {
  if (!result.hasIn(['groups', 'ticketinformation'])) {
    return Promise.resolve(result);
  }
  const pipelineProperty = result.getIn(['properties', 'hs_pipeline']).set('hidden', false);
  const pipelineStageProperty = result.getIn(['properties', 'hs_pipeline_stage']).set('hidden', false);
  return Promise.resolve(result.setIn(['properties', 'hs_pipeline'], pipelineProperty).setIn(['properties', 'hs_pipeline_stage'], pipelineStageProperty));
}
export function fetchEngagementPropertyGroups({
  objectType
}) {
  return ImmutableAPI.get('properties/v4/engagements', {}, objectType === TASK ? normalizeTaskGroups : normalizeEngagementGroups).then(groups => addRelatesToProperty(groups));
}
export function fetchPropertyGroups({
  objectType,
  showHighlySensitiveProperties
}) {
  if (objectType === TASK || objectType === ENGAGEMENT) {
    return fetchEngagementPropertyGroups({
      objectType
    });
  }
  // Visit properties live at two different APIs
  if (objectType === VISIT) {
    return Promise.all([ImmutableAPI.get('biden-app/v1/properties', {}, normalizeBidenGroups), ImmutableAPI.get('companyprospects/v1/prospects/search/properties', {}, normalizeVisitGroups)]).then(([bidenGroup, visitGroup]) => bidenGroup.mergeDeep(visitGroup));
  }
  const result = ImmutableAPI.get(getGroupsApi(objectType), {
    includeProperties: true,
    showHighlySensitiveProperties: showHighlySensitiveProperties !== null && showHighlySensitiveProperties !== void 0 ? showHighlySensitiveProperties : false
  }, normalizePropertyGroupsImmutable);
  if (objectType === COMPANY) {
    return result.then(partial(addInboundDbImportProperty, 'companyinformation')).then(partial(addInboundDbListMembershipProperty, 'companyinformation'));
  }
  if (objectType === CONTACT) {
    return result.then(addFormSubmissionProperty).then(partial(addInboundDbImportProperty, 'contactinformation')).then(addListMembershipProperty).then(partial(addInboundDbListMembershipProperty, 'contactinformation'));
  }
  if (objectType === DEAL) {
    return result.then(partial(addAssociatedCompanyProperty, 'dealinformation')).then(partial(addAssociatedContactProperty, 'dealinformation')).then(partial(addInboundDbImportProperty, 'dealinformation')).then(partial(addInboundDbListMembershipProperty, 'dealinformation')).then(addDealStageProbability);
  }
  if (objectType === TICKET) {
    return result.then(partial(addAssociatedCompanyProperty, 'ticketinformation')).then(partial(addAssociatedContactProperty, 'ticketinformation')).then(unhideTicketPipelineProperties).then(partial(addInboundDbListMembershipProperty, 'ticketinformation'));
  }
  return result;
}
export function fetchPropertyGroupsBatch(objectTypes) {
  return Promise.all(objectTypes.map(objectType => fetchPropertyGroups({
    objectType
  })).toArray()).then(results => results.reduce((acc, result, index) => acc.set(objectTypes.get(index), result), ImmutableMap()));
}