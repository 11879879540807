import { useQuery } from 'data-fetching-client';
import { GET_THREAD_DELIVERY_IDENTIFIER_DATA } from '../queries/fetchThreadDeliveryIdentifierDataQuery';
import { COMPOSITION_THREAD_ID } from '../../../communicator/constants/compositionThreadId';
export const useThreadDeliveryIdentifierData = ({
  threadId,
  onCompleted
}) => {
  const isComposer = threadId === COMPOSITION_THREAD_ID;
  const {
    loading,
    error,
    data
  } = useQuery(GET_THREAD_DELIVERY_IDENTIFIER_DATA, {
    variables: {
      threadId
    },
    skip: isComposer,
    onCompleted
  });
  const threadDeliveryIdentifierData = data ? data.threadDeliveryIdentifierData : undefined;
  return {
    loading,
    error,
    threadDeliveryIdentifierData
  };
};