import { handleActions } from 'redux-actions';
import { updateEntries } from 'conversations-async-data/indexed-async-data/operators/updateEntries';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { FILE_ATTACHMENTS_FETCH } from '../constants/asyncActionTypes';
const initialState = new IndexedAsyncData({
  name: 'fileAttachments',
  notSetValue: new AsyncData()
});
export default handleActions({
  [FILE_ATTACHMENTS_FETCH.STARTED](state, action) {
    const {
      fileAttachmentIds
    } = action.payload.requestArgs;
    return updateEntries(fileAttachmentIds, (__id, asyncData) => requestStarted(asyncData), state);
  },
  [FILE_ATTACHMENTS_FETCH.SUCCEEDED](state, action) {
    const {
      data,
      requestArgs
    } = action.payload;
    const {
      fileAttachmentIds
    } = requestArgs;
    return updateEntries(fileAttachmentIds, (id, asyncData) => requestSucceededWithOperator(() => data.get(`${id}`) || null, asyncData), state);
  },
  [FILE_ATTACHMENTS_FETCH.FAILED](state, action) {
    const {
      fileAttachmentIds
    } = action.payload.requestArgs;
    return updateEntries(fileAttachmentIds, (__id, asyncData) => requestFailed(asyncData), state);
  }
}, initialState);