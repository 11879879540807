import ChatFilterOptions from 'conversations-internal-schema/constants/ChatFilterOptions';
import { Record } from 'immutable';
import { ALL } from './DateRangeTypes';
import { ASSIGNED_TO_BOT, CHANNEL_INSTANCE_ID, HIDE_FILTERED, STATUS, GENERIC_CHANNEL, ASSIGNEE, UNASSIGNED, START_TIME, END_TIME, DATE_TYPE, VID, SEARCH, HIDE_TRASH } from './FilterKeys';
const ChatFilters = Record({
  [ASSIGNED_TO_BOT]: null,
  [CHANNEL_INSTANCE_ID]: null,
  [HIDE_FILTERED]: true,
  [STATUS]: [ChatFilterOptions.STARTED],
  [GENERIC_CHANNEL]: null,
  [ASSIGNEE]: null,
  [UNASSIGNED]: null,
  [START_TIME]: null,
  [END_TIME]: null,
  [DATE_TYPE]: ALL,
  [VID]: null,
  [SEARCH]: '',
  [HIDE_TRASH]: true
}, 'ChatFilters');
export default ChatFilters;