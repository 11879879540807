import get from 'transmute/get';
import getIn from 'transmute/getIn';
import pipe from 'transmute/pipe';
import map from 'transmute/map';
import ChatFilterOptions from 'conversations-internal-schema/constants/ChatFilterOptions';
import { BOT } from 'conversations-message-history/common-message-format/constants/agentTypes';
import { MANUAL_BLOCK, NOT_FILTERED } from '../constants/filterSourceTypes';
import { AGENT_TO_AGENT_CHANNEL_DESCRIPTOR, AGENT_TO_AGENT_CHANNEL_NAME, AGENT_TO_VISITOR_CHANNEL_DESCRIPTOR, AGENT_TO_VISITOR_CHANNEL_NAME, ASSIGNEE, ASSIGNEE_ID, ASSIGNEE_TYPE, ASSOCIATED_TICKET_IDS, CHANNELS, CRM_CONVERSATION_OBJECT_ID, FILTERED_SOURCE, GENERIC_CHANNELS_USED, HAS_FILE_ATTACHMENT, INBOX_ID, LATEST_MESSAGE_TIMESTAMP, LATEST_RECEIVED_TIMESTAMP, LATEST_SENT_TIMESTAMP, ORIGINAL_CHANNEL_INSTANCE_ID, ORIGINAL_GENERIC_CHANNEL_ID, SEEN, SOURCE_OBJECT_TYPE_ID, STATUS, THREAD_ID, VID, VISITOR, LATEST_MESSAGE_PREVIEW } from '../constants/threadDetailsKeyPaths';
const {
  ENDED,
  STARTED,
  SOFT_DELETED
} = ChatFilterOptions;
// Core getters
export const getAgentToAgentChannelDescriptor = getIn(AGENT_TO_AGENT_CHANNEL_DESCRIPTOR);
export const getAgentToAgentChannelName = getIn(AGENT_TO_AGENT_CHANNEL_NAME);
export const getAgentToVisitorChannelDescriptor = getIn(AGENT_TO_VISITOR_CHANNEL_DESCRIPTOR);
export const getAgentToVisitorChannelName = getIn(AGENT_TO_VISITOR_CHANNEL_NAME);
export const getAssignee = getIn(ASSIGNEE);
export const getAssigneeType = getIn(ASSIGNEE_TYPE);
export const getAssigneeId = getIn(ASSIGNEE_ID);
export const getAssociatedTicketIds = getIn(ASSOCIATED_TICKET_IDS);
export const getChannels = getIn(CHANNELS);
export const getCrmConversationId = getIn(CRM_CONVERSATION_OBJECT_ID);
export const getFilteredSource = getIn(FILTERED_SOURCE);
export const getGenericChannelsUsed = getIn(GENERIC_CHANNELS_USED);
export const getHasFileAttachment = getIn(HAS_FILE_ATTACHMENT);
export const getInboxId = getIn(INBOX_ID);
export const getLatestMessageTimestamp = getIn(LATEST_MESSAGE_TIMESTAMP);
export const getLatestReceivedTimestamp = getIn(LATEST_RECEIVED_TIMESTAMP);
export const getLatestSentTimestamp = getIn(LATEST_SENT_TIMESTAMP);
export const getOriginalGenericChannelId = getIn(ORIGINAL_GENERIC_CHANNEL_ID);
export const getOriginalChannelInstanceId = getIn(ORIGINAL_CHANNEL_INSTANCE_ID);
export const getPreview = getIn(LATEST_MESSAGE_PREVIEW);
export const getSeen = getIn(SEEN);
export const getStatus = getIn(STATUS);
export const getThreadId = getIn(THREAD_ID);
export const getVid = getIn(VID);
export const getVisitor = getIn(VISITOR);
export const getSourceObjectTypeId = getIn(SOURCE_OBJECT_TYPE_ID);

// Extrapolative getters
export const getChannelNames = pipe(getChannels, map(get('name')), names => names.toList());
export const getIsBlocked = pipe(getFilteredSource, source => source === MANUAL_BLOCK);
export const getIsFiltered = pipe(getFilteredSource, source => source !== NOT_FILTERED);
export const getIsClosed = pipe(getStatus, status => status === ENDED);
export const getIsOpen = pipe(getStatus, status => status === STARTED);
export const getIsSoftDeleted = pipe(getStatus, status => status === SOFT_DELETED);
export const getIsAssignedToBot = pipe(getAssigneeType, type => type === BOT);
export const getIsUnassigned = pipe(getAssignee, assignee => !assignee);