import { DEFAULT_CLIENT_KEY } from '../constants/clientKeys';
import { CODE_SPLIT_FAILED_TO_LOAD, DISCONNECTED_FROM_PUBSUB, SUBSCRIPTIONS_FAILED } from '../constants/failureStates';
import { getFailureState } from '../selectors/healthCheckSelectors';
import { resubscribe } from './resubscribe';
import { reconnect } from './reconnect';
/**
 * Try to recover from the current failure state.
 *
 * @param {function} startPubSub - Function used to start your pubsub connection.
 */
export const retryPubSub = (startPubSub, clientKey = DEFAULT_CLIENT_KEY) => (dispatch, getState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
  const failureState = getFailureState(getState(), {
    clientKey
  });
  switch (failureState) {
    case CODE_SPLIT_FAILED_TO_LOAD:
      dispatch(startPubSub(clientKey));
      break;
    case SUBSCRIPTIONS_FAILED:
      dispatch(resubscribe(clientKey));
      break;
    case DISCONNECTED_FROM_PUBSUB:
      {
        dispatch(reconnect(clientKey));
        break;
      }
    default:
      break;
  }
};