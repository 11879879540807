import { THREAD_PRIVATE, THREAD_SHARED } from './channelTypes';
export const AGENT_TO_AGENT_CHANNEL_DESCRIPTOR = ['channels', THREAD_PRIVATE];
export const AGENT_TO_AGENT_CHANNEL_NAME = [...AGENT_TO_AGENT_CHANNEL_DESCRIPTOR, 'name'];
export const AGENT_TO_VISITOR_CHANNEL_DESCRIPTOR = ['channels', THREAD_SHARED];
export const AGENT_TO_VISITOR_CHANNEL_NAME = [...AGENT_TO_VISITOR_CHANNEL_DESCRIPTOR, 'name'];
export const ASSIGNEE_ID = ['assignee', 'agentId'];
export const ASSIGNEE_TYPE = ['assignee', 'agentType'];
export const ASSIGNEE = ['assignee'];
export const ASSOCIATED_TICKET_IDS = ['associatedTicketIds'];
export const CHANNELS = ['channels'];
export const CRM_CONVERSATION_OBJECT_ID = ['cvCrmObjectId'];
export const FILTERED_SOURCE = ['filteredSource'];
export const GENERIC_CHANNELS_USED = ['genericChannelsUsed'];
export const HAS_FILE_ATTACHMENT = ['latestMessagePreview', 'hasFileAttachment'];
export const INBOX_ID = ['inboxId'];
export const LATEST_MESSAGE_TIMESTAMP = ['latestMessageTimestamp'];
export const LATEST_RECEIVED_TIMESTAMP = ['latestReceivedTimestamp'];
export const LATEST_SENT_TIMESTAMP = ['latestSentTimestamp'];
export const ORIGINAL_GENERIC_CHANNEL_ID = ['originalGenericChannelId'];
export const ORIGINAL_CHANNEL_INSTANCE_ID = ['originalChannelInstanceId'];
export const SEEN = ['seen'];
export const SOURCE_OBJECT_TYPE_ID = ['sourceCrmObjectTypeId'];
export const STATUS = ['status'];
export const THREAD_ID = ['threadId'];
export const VID = ['vid'];
export const VISITOR = ['visitor'];
export const VISITOR_EMAIL = ['visitor', 'email'];
export const LATEST_MESSAGE_PREVIEW = ['latestMessagePreview'];