import { createReducer } from '@reduxjs/toolkit';
import { omnibusFulfilled } from '../../../omnibus/public';
import { fetchCustomViewsAndThreadlists, pollViews } from '../views/viewsSlice';
const initialState = false;
export const fallbackModeReducer = createReducer(initialState, builder => {
  builder.addCase(fetchCustomViewsAndThreadlists.fulfilled, (state, action) => {
    return action.payload.isFallbackModeEnabled;
  }).addCase(pollViews.fulfilled, (state, action) => {
    return action.payload.isFallbackModeEnabled;
  }).addCase(omnibusFulfilled, (state, action) => {
    return action.payload.omnibus.isFallbackModeEnabled;
  });
});

/**
 * This selector can be used to determine if fallback mdoe has been enabled in the
 * BE. Fallback mode is when Custom Views are down and we can only serve threads
 * through Thread Lists.
 */
export const selectIsInFallbackMode = state => state.findAndFilterData.fallbackModeEnabled;