import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { setTracker, setBeaconTracker
// @ts-expect-error module not typed
} from '../../usage-tracking/clients/usageTracker';

// @ts-expect-error module not typed
import { provideThreadView } from '../decorators/provideThreadView';

/**
 * @description This component keeps the current trackers in sync
 * for use by communicator-composition components that can appear
 * in contexts where there's no thread available.
 */
const ThreadViewTrackingManager = ({
  beaconTracker,
  tracker
}) => {
  useEffect(() => {
    setTracker(tracker);
  }, [tracker]);
  useEffect(() => {
    setBeaconTracker(beaconTracker);
  }, [beaconTracker]);
  return null;
};
ThreadViewTrackingManager.displayName = 'ThreadViewTrackingManager';
ThreadViewTrackingManager.propTypes = {
  beaconTracker: PropTypes.object.isRequired,
  tracker: PropTypes.object.isRequired
};
export const ThreadViewTrackingManagerInnerStore = provideThreadView(ThreadViewTrackingManager);
export default ThreadViewTrackingManager;