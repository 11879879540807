import { once } from '../../utils/once';
const getGlobalCallback = once(reporter => () => {
  reporter.captureEvent('received-presence-message');
});

/**
 * @description Count received presence messages
 */
export const countReceivedPresenceMessages = ({
  channel,
  reporter
}) => {
  const callback = getGlobalCallback(reporter);
  channel.presence.unsubscribe(callback);
  channel.presence.subscribe('enter', callback);
  channel.presence.subscribe('leave', callback);
  channel.presence.subscribe('present', callback);
  channel.presence.subscribe('update', callback);
};