export function toAgentRecord({
  assignedActor
}) {
  if (assignedActor) {
    const parts = assignedActor.split('-');
    return {
      agent: {
        userId: parseInt(parts[1], 10)
      }
    };
  }
  return {
    agent: ''
  };
}