export const commands = {
  inbox: {
    changeTone: 'inbox_selection_change_tone_v1',
    draft: 'inbox_draft_message_v1',
    write: 'inbox_write_for_me_v1',
    expand: 'inbox_selection_expand_v1',
    rewrite: 'inbox_selection_rewrite_v1',
    shorten: 'inbox_selection_shorten_v1'
  },
  crm: {
    changeTone: 'crm_selection_change_tone_v1',
    draft: 'crm_draft_message_v1',
    write: 'crm_write_for_me_v1',
    expand: 'crm_selection_expand_v1',
    rewrite: 'crm_selection_rewrite_v1',
    shorten: 'crm_selection_shorten_v1'
  }
};