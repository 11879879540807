import { combineReducers } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import auth from './authSlice';
import actions from './actionsSlice';
export function useAppDispatch() {
  return useDispatch();
}
export function useAppSelector(selector) {
  return useSelector(selector);
}
export const rootReducer = combineReducers({
  auth,
  actions
});