import { connect } from 'react-redux';
import VerifyFbmPrompt from '../components/VerifyFbmPrompt';
import { getFbmInstall } from '../actions/getFbmInstall';
import { getPageName, getRequestStatus, getPageLink } from '../selectors/verifyFbmInstallSelectors';
const mapStateToProps = state => ({
  pageName: getPageName(state),
  requestStatus: getRequestStatus(state),
  pageLink: getPageLink(state)
});
const mapDispatchToProps = {
  getFbmInstall
};
export default connect(mapStateToProps, mapDispatchToProps
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof VerifyFbmPrompt' is not a... Remove this comment to see the full error message
)(VerifyFbmPrompt);