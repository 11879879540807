import enviro from 'enviro';
import Raven from 'raven-js';
import Logger, { Level } from './internal/Logger';
import { throwCommandError } from './internal/throwCommandError';
import { isCommandTopic } from './isCommandTopic';
import devLogger from 'react-utils/devLogger';
import { createMetricsFactory } from 'metrics-js';
const Metrics = createMetricsFactory('crm-message-bus', {
  library: 'crm-message-bus'
});
const INTERNAL_SOURCE_ID = 'MessageBusInternal';
function generateKey() {
  return `${Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)}`;
}
function generateUniqueKeyFor(keyedMap) {
  let key;
  do {
    key = generateKey();
  } while (keyedMap[key] !== undefined);
  return key;
}

// By wrapping the callback we get a unique instance of the callback that can be identified later.
// This handles the case of the same function being subscribed multiple times
function createWrappedCallback(callback) {
  return data => {
    return callback(data);
  };
}
function handleCommandSubscribed(topic, commandsWithSubscribers) {
  Logger.logTopic(Level.DEBUG, topic, `handleCommandSubscribed ${topic}`);
  if (!isCommandTopic(topic)) {
    return;
  }
  if (commandsWithSubscribers.has(topic)) {
    throwCommandError(`MessageBusError: There is already a subscriber registered to ${topic}. Commands can only have one handler.`);
  }
  commandsWithSubscribers.add(topic);
}
function handleCommandUnsubscribed(topic, commandsWithSubscribers) {
  Logger.logTopic(Level.DEBUG, topic, `handleCommandUnsubscribed ${topic}`);
  if (!isCommandTopic(topic)) {
    return;
  }
  commandsWithSubscribers.delete(topic);
}

/**
 * @deprecated use the messageBus variable (for the global instance) exported from this file instead of creating your own with new MessageBus().
 * If you're writing a test, use getMessageBusTestHarness() from 'crm-message-bus/test-utils'.
 */
export class MessageBus {
  constructor(options = {}) {
    this.topicToHandlers = {};
    this.unsubscribeMap = {};
    this.relayMap = {};
    this.bubbleUp = options.bubbleUp;
    this.commandsWithSubscribers = new Set();
    this.commandsWithSenders = new Set();
    this.currentTimeoutIds = new Set();
    Logger.log(Level.DEBUG, 'instantiated');
    if (!MessageBus.singletonInstance) {
      MessageBus.singletonInstance = this;
    }
    return MessageBus.singletonInstance;
  }

  // Global set of Commands, across all instaces of the MessageBus in the BubbleUp chain, that have handlers
  // Local set of Commands that have senders applied to them

  // used to clean up timeouts that have been set up on unmount

  generateUniqueUnsubscribeKey() {
    return generateUniqueKeyFor(this.unsubscribeMap);
  }
  generateUniqueRelayKey() {
    return generateUniqueKeyFor(this.relayMap);
  }
  unsubscribe(key) {
    const unsubscribeData = this.unsubscribeMap[key];
    if (unsubscribeData) {
      const {
        topic,
        callbackWrapper
      } = unsubscribeData;
      const currentCallbacks = this.topicToHandlers[topic];
      if (topic && currentCallbacks) {
        const filteredCallbacks = currentCallbacks.filter(cb => cb !== callbackWrapper);
        if (filteredCallbacks.length) {
          this.topicToHandlers[topic] = filteredCallbacks;
        } else {
          delete this.topicToHandlers[topic];
        }
      }
    }
    delete this.unsubscribeMap[key];
  }

  /**
   * NOTE: This is purely for Development purposes.
   * Called by useSendCrmMessageTopic to verify that a subscriber gets initialized
   * if there is code that could potentially call a command. The subscriber may not
   * immediately be available, but should be initialized by the end of the initial page
   * load. So we'll defer until idle before checking.
   */
  registerCommandSender(topic, messageContext) {
    const isFirstSenderOfTopic = !this.commandsWithSenders.has(topic);
    this.commandsWithSenders.add(topic);
    if (!isFirstSenderOfTopic || this.commandsWithSubscribers.has(topic)) {
      return;
    }
    if (enviro.isProd() && enviro.deployed()) {
      return;
    }
    const timeoutId = setTimeout(() => {
      if (!this.commandsWithSubscribers.has(topic)) {
        throwCommandError(`The Command "${topic}" could be sent but has no subscriber. Commands must have exactly one subscriber. This message could be sent by the sender with messagingId "${messageContext.messagingId}".`);
      }
      this.currentTimeoutIds.delete(timeoutId);
    }, 2000);
    this.currentTimeoutIds.add(timeoutId);
  }
  registerCommandSubscriber(topic) {
    if (isCommandTopic(topic) && this.bubbleUp) {
      // Iframes could be unmounted without dispatching cleanup messages so we don't want commands
      // to be handled there. Additionally, opening modals/panels is hard to do inside an iframe.
      throwCommandError('Commands must be handled by the top-most instance of the CRM Message Bus.');
    }
    if (isCommandTopic(topic) && this.commandsWithSubscribers.has(topic)) {
      throwCommandError(`MessageBusError: There can only be one subscriber to a Command message topic. Topic: ${topic}`);
    } else if (isCommandTopic(topic)) {
      this.publish('INTERNAL_REGISTER_COMMAND_SUBSCRIBED', {
        envelope: {
          sourceId: INTERNAL_SOURCE_ID
        },
        data: {
          topic
        }
      });
    }
  }
  subscribe(topic, callback) {
    Logger.logTopic(Level.DEBUG, topic, `subscribe: ${topic}`);
    if (isCommandTopic(topic)) {
      this.registerCommandSubscriber(topic);
    }
    const wrappedCallback = createWrappedCallback(callback);
    const topicHandlers = this.topicToHandlers[topic] || (this.topicToHandlers[topic] = []);
    topicHandlers.push(wrappedCallback);
    const unsubscribeKey = this.generateUniqueUnsubscribeKey();
    this.unsubscribeMap[unsubscribeKey] = {
      topic,
      callbackWrapper: wrappedCallback
    };
    return () => {
      Logger.logTopic(Level.DEBUG, topic, `unsubscribe: ${topic}`);
      if (isCommandTopic(topic)) {
        this.publish('INTERNAL_REGISTER_COMMAND_UNSUBSCRIBED', {
          envelope: {
            sourceId: INTERNAL_SOURCE_ID
          },
          data: {
            topic
          }
        });
      }
      return this.unsubscribe(unsubscribeKey);
    };
  }
  unbindRelay(key) {
    Logger.log(Level.DEBUG, `unbindRelay: ${key}`);
    delete this.relayMap[key];
  }
  setBubbleUpFn(bubbleUp) {
    if (this.bubbleUp) {
      devLogger.warn({
        key: 'MessageBus:OverwritingBubbleUpFunction',
        message: 'Overwriting existing bubbleUp function'
      });
      Raven.captureException(new Error('Overwriting existing bubbleUp function'));
    }
    this.bubbleUp = bubbleUp;
  }

  // The primary usecase of a Relay is to send a message down into an iFrame.
  bindRelay(relay) {
    const relayKey = this.generateUniqueRelayKey();
    this.relayMap[relayKey] = relay;
    Logger.log(Level.DEBUG, `bindRelay: ${relayKey}`);
    return () => this.unbindRelay(relayKey);
  }
  publish(topic, envelopeData) {
    Logger.log(Level.DEBUG, `publish: ${topic}`, envelopeData);
    // Exclude internal publish events from metrics
    if (envelopeData.envelope.sourceId !== INTERNAL_SOURCE_ID) {
      Metrics.counter('message-publish', {
        topic
      }).increment();
    }
    if (this.bubbleUp) {
      Logger.logTopic(Level.DEBUG, topic, `bubbleUp: ${topic}`, envelopeData);
      this.bubbleUp(topic, envelopeData);
    } else {
      this.deliver(topic, envelopeData);
    }
  }
  deliver(topic, envelopeData) {
    const topicHandlers = this.topicToHandlers[topic] || [];
    Logger.logTopic(Level.DEBUG, topic, `deliver: ${topic} to ${topicHandlers.length} handlers`, envelopeData);
    if (topic === 'INTERNAL_REGISTER_COMMAND_SUBSCRIBED') {
      const envelope = envelopeData;
      handleCommandSubscribed(envelope.data.topic, this.commandsWithSubscribers);
    }
    if (topic === 'INTERNAL_REGISTER_COMMAND_UNSUBSCRIBED') {
      const envelope = envelopeData;
      handleCommandUnsubscribed(envelope.data.topic, this.commandsWithSubscribers);
    }
    topicHandlers.forEach((handler, idx) => {
      setTimeout(() => {
        try {
          handler(envelopeData);
        } catch (e) {
          Logger.logTopic(Level.ERROR, topic, `handler[${idx}]: ${topic}`, envelopeData, e);
          Raven.captureException(e);
        }
      }, 0);
    });
    Object.values(this.relayMap).forEach((relay, idx) => {
      setTimeout(() => {
        try {
          Logger.logTopic(Level.DEBUG, topic, `relay[${idx}]: ${topic}`, envelopeData);
          relay(topic, envelopeData);
        } catch (e) {
          Logger.logTopic(Level.ERROR, topic, `relay[${idx}]: ${topic}`, envelopeData, e);
          Raven.captureException(e);
        }
      }, 0);
    });
  }
  size() {
    return Object.entries(this.topicToHandlers).filter(([__k, v]) => v && v.length > 0).length;
  }
  cleanup() {
    this.currentTimeoutIds.forEach(timeoutId => clearTimeout(timeoutId));

    // unsubscribe remaining subscribers
    Object.keys(this.unsubscribeMap).forEach(key => {
      this.unsubscribe(key);
    });

    // unbind remaining relays
    Object.keys(this.relayMap).forEach(key => {
      this.unbindRelay(key);
    });

    // reset some of the local state
    this.commandsWithSubscribers = new Set();
    this.commandsWithSenders = new Set();
    this.currentTimeoutIds = new Set();
    this.bubbleUp = undefined;
  }
}
export const messageBus = new MessageBus();