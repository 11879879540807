import { useReduxAsyncFetcher } from 'conversations-async-data/async-data/hooks/useReduxAsyncFetcher';
import { fetchCrmContact } from '../actions/fetchCrmContact';
import { getAsyncContact } from '../selectors/getAsyncContact';
export const useContact = ({
  threadId
}) => {
  const {
    asyncData,
    retryFailed
  } = useReduxAsyncFetcher({
    action: fetchCrmContact,
    selector: getAsyncContact,
    id: threadId,
    idTransform: id => ({
      threadId: id
    })
  });
  return {
    asyncContact: asyncData,
    retryFailed
  };
};