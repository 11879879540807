'use es6';

import { handleActions, combineActions } from 'redux-actions';
import invariant from 'react-utils/invariant';
import { FETCH_PROMPT, DISMISS_PROMPT, PROMPT_WAS_SHOWN } from '../constants/asyncActionTypes';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { requestFailedWithError } from 'conversations-async-data/async-data/operators/requestFailedWithError';
import UserPromptData, { updateDismissedAt, updateViewedCount } from '../schema/UserPromptData';
import * as promptTypes from '../constants/promptTypes';
const promptDismissalsInitialState = IndexedAsyncData({
  idInvariant: id => invariant(Object.keys(promptTypes).includes(id), `signature prompt dismissal id must be one of ${promptTypes}, given: ${id}`),
  name: 'promptDismissals',
  notSetValue: AsyncData({
    data: UserPromptData()
  })
});
const promptDismissals = handleActions({
  [FETCH_PROMPT.STARTED]: (state, {
    payload: {
      requestArgs
    }
  }) => updateEntry(requestArgs.promptType, requestStarted, state),
  [FETCH_PROMPT.SUCCEEDED]: (state, {
    payload: {
      requestArgs,
      data
    }
  }) => updateEntry(requestArgs.promptType, requestSucceededWithOperator(() => data), state),
  [combineActions(DISMISS_PROMPT.SUCCEEDED, DISMISS_PROMPT.FAILED)]: (state, {
    payload: {
      requestArgs
    }
  }) => updateEntry(requestArgs.promptType, requestSucceededWithOperator(updateDismissedAt(() => 1)), state),
  [PROMPT_WAS_SHOWN.SUCCEEDED]: (state, {
    payload: {
      requestArgs
    }
  }) => updateEntry(requestArgs.promptType, requestSucceededWithOperator(updateViewedCount(val => val + 1)), state),
  [combineActions(FETCH_PROMPT.FAILED, PROMPT_WAS_SHOWN.FAILED, DISMISS_PROMPT.FAILED)]: (state, {
    payload: {
      requestArgs,
      error
    }
  }) => updateEntry(requestArgs.promptType, requestFailedWithError(error), state)
}, promptDismissalsInitialState);
export default promptDismissals;