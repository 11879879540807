import { is } from 'immutable';
import get from 'transmute/get';
export const getAssignmentRemovalProps = (overlay, {
  activeAgent,
  assigneeLabel,
  initiatingAgentLabel
}) => {
  const initiatingAgent = get('initiatingAgent', overlay);
  const assignee = get('assignee', overlay);

  /* active user is initiating agent */
  if (is(initiatingAgent, activeAgent)) {
    if (is(assignee, activeAgent)) {
      /* self assignment */
      return {
        message: 'conversations-inbox-ui.thread-list-member-overlay.assignmentRemoval.activeAgent.self'
      };
    } else if (assignee) {
      /* assigned to someone else */
      return {
        message: 'conversations-inbox-ui.thread-list-member-overlay.assignmentRemoval.activeAgent.otherAgent',
        options: {
          assigneeLabel
        }
      };
    } else {
      /* you unassigned */
      return {
        message: 'conversations-inbox-ui.thread-list-member-overlay.assignmentRemoval.activeAgent.unassigned'
      };
    }

    /* initiatingAgent is someone else */
  } else if (initiatingAgent) {
    if (is(assignee, activeAgent)) {
      /* someone assigned thread to active user */
      return {
        message: 'conversations-inbox-ui.thread-list-member-overlay.assignmentRemoval.otherAgent.activeAgent',
        options: {
          initiatingAgentLabel
        }
      };
    } else if (is(assignee, initiatingAgent)) {
      /* someone assigned to themselves */
      return {
        message: 'conversations-inbox-ui.thread-list-member-overlay.assignmentRemoval.otherAgent.self',
        options: {
          initiatingAgentLabel
        }
      };
    } else if (assignee) {
      /* someone assigned to a different person */
      return {
        message: 'conversations-inbox-ui.thread-list-member-overlay.assignmentRemoval.otherAgent.otherAgent',
        options: {
          initiatingAgentLabel,
          assigneeLabel
        }
      };
    } else {
      /* thread was unassigned */
      return {
        message: 'conversations-inbox-ui.thread-list-member-overlay.assignmentRemoval.otherAgent.unassigned',
        options: {
          initiatingAgentLabel
        }
      };
    }
  } else {
    if (is(assignee, activeAgent)) {
      /* thread was assigned to active agent */
      return {
        message: 'conversations-inbox-ui.thread-list-member-overlay.assignmentRemoval.unknown.activeAgent'
      };
    } else if (assignee) {
      /* thread was assigned to someone else */
      return {
        message: 'conversations-inbox-ui.thread-list-member-overlay.assignmentRemoval.unknown.otherAgent',
        options: {
          assigneeLabel
        }
      };
    } else {
      /* thread was unassigned for some other reason */
      return {
        message: 'conversations-inbox-ui.thread-list-member-overlay.assignmentRemoval.unknown.unassigned'
      };
    }
  }
};