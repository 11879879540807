// @ts-expect-error module not typed

import { Map as ImmutableMap } from 'immutable';
import { createSelector } from 'reselect';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { getEntry } from 'conversations-async-data/indexed-async-data/operators/getters';
// @ts-expect-error module not typed
import { getCurrentThreadInboxId } from '../../thread-details/selectors/getCurrentThreadInboxId';
import { getIndexedAsyncConnectedAccounts } from 'conversations-thread-data/connected-accounts/public';
export const getCurrentAsyncConnectedAccounts = createSelector([getIndexedAsyncConnectedAccounts, getCurrentThreadInboxId], (indexedAsyncConnectedAccounts, inboxId) => inboxId ? getEntry(inboxId, indexedAsyncConnectedAccounts) : new AsyncData({
  data: ImmutableMap()
}));