import invariant from 'react-utils/invariant';
import { getFromAddress, getInReplyToId
// @ts-expect-error module not typed
} from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { getAttachments } from 'conversations-message-history/common-message-format/operators/getAttachments';
// @ts-expect-error module not typed
import { setAttachments } from 'conversations-message-history/common-message-format/operators/commonMessageFormatSetters';
import { getCommonMessage, getMetadata, getReplyId } from './commonReplyGetters';
import { setCommonMessage, setReplyMetadata
// @ts-expect-error module not typed
} from './commonReplySetters';
import { mergeCommonMessageAttachments } from './mergeCommonMessageAttachments';
/**
 * @describe Resolves potential metadata conflicts on email replies
 * by overwriting the source reply's metadata with the newer reply's
 * metadata, but maintaining all other existing state. Conflicts can
 * only be resolved for replies with the same id (otherwise, you'd be
 * potentially mixing state of different replies).
 * @argument {CommonReply} sourceReply some reply representing existing state
 * @argument {CommonReply} newerReply a reply with potentially conflicting metadata
 */
export const resolveEmailReplyConflicts = (sourceReply, newerReply) => {
  invariant(getReplyId(sourceReply) === getReplyId(newerReply), 'Tried to resolve email reply conflicts between replies with different ids');
  const sourceMessage = getCommonMessage(sourceReply);
  const newerMessage = getCommonMessage(newerReply);
  const sourceInReplyToId = getInReplyToId(sourceMessage);
  const newerInReplyToId = getInReplyToId(newerMessage);
  const sourceFromAddress = getFromAddress(sourceMessage);
  const newerFromAddress = getFromAddress(newerMessage);
  const sourceAttachments = sourceMessage && getAttachments(sourceMessage);
  const newerAttachments = newerMessage && getAttachments(newerMessage);
  const inReplyToMessageChanged = sourceInReplyToId !== newerInReplyToId;
  const fromAddressMismatch = Boolean(!sourceFromAddress && newerFromAddress);
  if (inReplyToMessageChanged || fromAddressMismatch) {
    const updatedSourceReply = setReplyMetadata(getMetadata(newerReply), sourceReply);
    const mergedAttachments = mergeCommonMessageAttachments(sourceAttachments, newerAttachments);
    const newMessageWithAttachments = mergedAttachments ? setAttachments(mergedAttachments, newerMessage) : newerMessage;
    return setCommonMessage(newMessageWithAttachments, updatedSourceReply);
  }
  return sourceReply;
};