import http from 'hub-http/clients/apiClient';
const appealStateBase = '/vetting/public/v2/account-verification/state/scope/calling-access';
export let fetchCallingAppealState = () => {
  return http.get(appealStateBase, {
    query: {
      clienttimeout: 30000
    },
    timeout: 30000
  });
};
export const setFetchCallingAppealStateForTesting = f => {
  fetchCallingAppealState = f;
};