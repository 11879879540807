'use es6';

// These constants should be kept in sync with `HubSpot/CrmRecordCards`
// https://git.hubteam.com/HubSpot/CrmRecordCards/blob/master/CrmRecordCardsBase/src/main/java/com/hubspot/crm/record/cards/base/CardLocation.java
export const BASIC_SIDEBAR = 'BASIC_SIDEBAR';
export const CONVERSATIONS_INBOX = 'CONVERSATIONS_INBOX';
export const CRM_RECORD_LEFT = 'CRM_RECORD_LEFT';
export const CRM_RECORD_RIGHT = 'CRM_RECORD_RIGHT';
export const CRM_PREVIEW = 'CRM_PREVIEW';
export const FEEDBACK_LIST = 'FEEDBACK_LIST';
export const HELPDESK = 'HELPDESK';
export const SALES_ACTIVITIES = 'SALES_ACTIVITIES';
export const INTEGRATION_EMBED = 'INTEGRATION_EMBED';