import { useCallback, useEffect, useMemo, useRef } from 'react';
import { IFrameContextHandler, MessageTypes } from '../utils/IFrameContextHandler';
import usePrevious from './usePrevious';
const defaultCloseReturn = {
  error: null
};
export function useIframedApplication({
  isOpen,
  onClose,
  initializeArgs
}) {
  const contextHandler = useRef(null);
  const previouslyOpen = usePrevious(isOpen);
  useEffect(() => {
    // if we don't have a contextHandler, we can't do anything and the IFrame is not ready
    // or has some other problem we can't handle here.
    if (!contextHandler.current) {
      return;
    }
    const justOpened = !previouslyOpen && isOpen;
    const justClosed = previouslyOpen && !isOpen;
    if (justOpened) {
      contextHandler.current.send(MessageTypes.INIT_MODAL, initializeArgs);
    } else if (justClosed) {
      contextHandler.current.send(MessageTypes.HIDE_MODAL, undefined);
    }
  }, [isOpen, previouslyOpen, initializeArgs]);
  const handleClose = useCallback(changes => {
    onClose(changes);
  }, [onClose]);
  const getHostIFrameHandler = useCallback(hostContext => new IFrameContextHandler(hostContext), []);
  return useMemo(() => ({
    isOpen,
    contextHandler: contextHandler.current,
    handleMessage: message => {
      if (contextHandler.current) {
        contextHandler.current.receive(message);
      }
    },
    handleClose,
    handleError: error => {
      console.error(error);
      handleClose(Object.assign({}, defaultCloseReturn, {
        error
      }));
    },
    handleOnReady: newContext => {
      const handler = getHostIFrameHandler(newContext).on(MessageTypes.MODAL_HIDDEN, changes => {
        handleClose(changes);
      });
      contextHandler.current = handler;
    }
  }), [getHostIFrameHandler, handleClose, isOpen]);
}