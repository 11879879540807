import { getProperty as getRecordProperty } from 'customer-data-objects/record/ObjectRecordAccessors';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import { CLOSED_DATE, CREATE_DATE, ORIGINATING_THREAD_ID, OWNER, PIPELINE_ID, PIPELINE_STAGE_ID, PRIORITY, SUBJECT, TICKET_ID, TIME_TO_CLOSE_SLA_AT, TIME_TO_CLOSE_SLA_STATUS, TIME_TO_FIRST_RESPONSE_SLA_AT, TIME_TO_FIRST_RESPONSE_SLA_STATUS, TIME_TO_NEXT_RESPONSE_SLA_AT, TIME_TO_NEXT_RESPONSE_SLA_STATUS } from '../constants/ticketPropertyKeys';
//Check if the ticket is of the deprecated type.
const isTicketRecord = ticket => Boolean(get('objectId', ticket));

//Get the property from the unmanipulated GraphQL object.
//Properties can be in the defaultProperties map or the properties array.
const getProperty = (ticket, property) => ticket && (getIn(['defaultProperties', property, 'value'], ticket) || ticket.properties && (ticket.properties.find(prop => prop.name === property) || {}).value);
// Ticket Base Getters
export const getPortalId = get('portalId');
export const getObjectId = ticket => isTicketRecord(ticket) ? get('objectId', ticket) : get('id', ticket);
export const getProperties = get('properties');

// Ticket Property Getters
const getTicketProperty = property => ticket => isTicketRecord(ticket) ? getRecordProperty(ticket, property) : getProperty(ticket, property);
export const getTicketId = getTicketProperty(TICKET_ID);
export const getHsPipelineId = getTicketProperty(PIPELINE_ID);
export const getSubject = getTicketProperty(SUBJECT);
export const getOriginatingThreadId = getTicketProperty(ORIGINATING_THREAD_ID);
export const getPriority = getTicketProperty(PRIORITY);
export const getCreateDate = getTicketProperty(CREATE_DATE);
export const getCloseDate = getTicketProperty(CLOSED_DATE);
export const getPipelineStageId = getTicketProperty(PIPELINE_STAGE_ID);
export const getOwner = getTicketProperty(OWNER);
export const getTimeToCloseSlaAt = getTicketProperty(TIME_TO_CLOSE_SLA_AT);
export const getTimeToCloseSlaStatus = getTicketProperty(TIME_TO_CLOSE_SLA_STATUS);
export const getTimeToFirstResponseSlaAt = getTicketProperty(TIME_TO_FIRST_RESPONSE_SLA_AT);
export const getTimeToFirstResponseSlaStatus = getTicketProperty(TIME_TO_FIRST_RESPONSE_SLA_STATUS);
export const getTimeToNextResponseSlaAt = getTicketProperty(TIME_TO_NEXT_RESPONSE_SLA_AT);
export const getTimeToNextResponseSlaStatus = getTicketProperty(TIME_TO_NEXT_RESPONSE_SLA_STATUS);
export const isTicketClosed = ticket => Boolean(getCloseDate(ticket));