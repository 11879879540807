import getIn from 'transmute/getIn';
import { createSelector } from 'reselect';
import { getEntry } from 'conversations-async-data/indexed-async-data/operators/getters';
import { getVisitorIdentification } from '../operators/getVisitorIdentification';
import { IS_IDENTIFIED } from '../constants/KeyPaths';
const getThreadIdFromProps = (_, {
  threadId
}) => threadId;
export const getIdentificationAsyncData = createSelector([getVisitorIdentification, getThreadIdFromProps], (visitorIdentification, threadId) => {
  return getEntry(threadId, visitorIdentification);
});
export const getIsIdentifiedOperator = asyncData => {
  return getIn([...IS_IDENTIFIED], asyncData);
};
export const getIsIdentified = createSelector([getIdentificationAsyncData], asyncData => getIsIdentifiedOperator(asyncData));