import PanelState from '../records/PanelState';
import { CHECK_CLOSE_TICKET } from '../actions/ActionTypes';
function panelState(state = new PanelState(), action) {
  switch (action.type) {
    case CHECK_CLOSE_TICKET:
      {
        return state.set('closeTicketCheckedByTicketId', state.closeTicketCheckedByTicketId.set(action.payload.ticketId, action.payload.isChecked));
      }
    default:
      return state;
  }
}
export default panelState;