import { createAction } from 'redux-actions';
import DeprecatedActionTypes from '../constants/DeprecatedActionTypes';
import { getFocusedViewMemberThreadId } from '../focused-view-member/selectors/getFocusedViewMemberThreadId';
export const setAppInBackground = createAction(DeprecatedActionTypes.APP_IN_BACKGROUND);
export const setAppInForeground = createAction(DeprecatedActionTypes.APP_IN_FOREGROUND, () => ({}), syncSeenStatusForThreadId => ({
  syncSeenStatusForThreadId
}));
export function setWindowVisible(isVisible) {
  return (dispatch, getState) => {
    if (isVisible) {
      const syncSeenStatusForThreadId = getFocusedViewMemberThreadId(getState());
      dispatch(setAppInForeground(syncSeenStatusForThreadId));
    } else {
      dispatch(setAppInBackground());
    }
  };
}