'use es6';

import { getRecipientId, getRecipientType } from 'conversations-message-history/legacy-recipients/operators/recipientLocatorGetters';
import { getAgentTypeFromSenderType } from '../../senders/operators/getAgentTypeFromSenderType';

/**
 * @param {Object} options
 * @param {Set<RecipientLocator>} options.recipientLocators
 * @returns {Array<Object>} an array of objects, each of which
 * contains an agentType and an array of agentIds to fetch for
 * that type
 */
export const buildAgentRequestData = ({
  recipientLocators
}) => {
  const keyedRequestObjects = recipientLocators.reduce((accumulator, recipientLocator) => {
    const recipientType = getRecipientType(recipientLocator);
    const agentType = getAgentTypeFromSenderType(recipientType);
    const agentId = getRecipientId(recipientLocator);
    return Object.assign({}, accumulator, {
      [agentType]: {
        agentType,
        agentIds: [...(accumulator[agentType] && accumulator[agentType].agentIds || []), agentId]
      }
    });
  }, {});
  return Object.keys(keyedRequestObjects).reduce((accumulator, key) => [...accumulator, keyedRequestObjects[key]], []);
};