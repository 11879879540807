'use es6';

import { gql } from '@apollo/client';
export const IntegrationCardFragments = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"IntegrationCardFragments\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"IntegrationCard\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"salesSidebarItem\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"InlineFragment\",\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"LinkedSalesObjectSidebarItem\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"iconUri\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"appId\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"appName\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"title\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"appAccessLevel\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"developerFlagState\"}}]}}]}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "IntegrationCardFragments"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "IntegrationCard"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "salesSidebarItem"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "LinkedSalesObjectSidebarItem"
              }
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "iconUri"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "appId"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "appName"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "objectTypeId"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "title"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "appAccessLevel"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "developerFlagState"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
});