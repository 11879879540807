'use es6';

import getIn from 'transmute/getIn';
import { CHATFLOW_CONTEXT, CHATFLOW_ID, CONNECTED_ACCOUNT_CONTEXT, MARKETING_EMAIL_CONTEXT, FORWARDED_EMAIL_CONTEXT, CONNECTED_OBJECT_SOURCE_ID, CONTEXTS, ENTRY_URL, ENTRY_URL_CONTEXT, FORM_CONTEXT, HIDDEN_FROM_VISITOR, ID, FROM_NAME, FROM_EMAIL, ORIGINAL_SENDER_NAME, ORIGINAL_SENDER_EMAIL, MESSAGE_DELETED_STATUS, TIMESTAMP, TYPE, URL, SUBJECT, CAMPAIGN_ID, CAMPAIGN_GROUP_ID, CUSTOM_CONTEXT, CUSTOM_CONTEXT_VALUE } from '../constants/keyPaths';
export const getTimestamp = getIn(TIMESTAMP);
export const getContexts = getIn(CONTEXTS);
export const getEntryUrl = getIn(ENTRY_URL);
export const getHiddenFromVisitor = getIn(HIDDEN_FROM_VISITOR);
export const getMessageDeletedStatus = getIn(MESSAGE_DELETED_STATUS);
export const getChatflowId = getIn(CHATFLOW_ID);

// individual context operators
export const getType = getIn(TYPE);
export const getId = getIn(ID);
export const getUrl = getIn(URL);
export const getConnectedObjectSourceId = getIn(CONNECTED_OBJECT_SOURCE_ID);
export const getSubject = getIn(SUBJECT);
export const getCampaignId = getIn(CAMPAIGN_ID);
export const getCampaignGroupId = getIn(CAMPAIGN_GROUP_ID);
export const getFromName = getIn(FROM_NAME);
export const getFromEmail = getIn(FROM_EMAIL);
export const getOriginalSenderName = getIn(ORIGINAL_SENDER_NAME);
export const getOriginalSenderEmail = getIn(ORIGINAL_SENDER_EMAIL);

// individual context type getters
export const getEntryUrlContext = getIn(ENTRY_URL_CONTEXT);
export const getFormContext = getIn(FORM_CONTEXT);
export const getChatflowContext = getIn(CHATFLOW_CONTEXT);
export const getConnectedAccountContext = getIn(CONNECTED_ACCOUNT_CONTEXT);
export const getMarketingEmailContext = getIn(MARKETING_EMAIL_CONTEXT);
export const getForwardedEmailContext = getIn(FORWARDED_EMAIL_CONTEXT);
export const getCustomContext = getIn(CUSTOM_CONTEXT);
export const getCustomContextValue = getIn(CUSTOM_CONTEXT_VALUE);