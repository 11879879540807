import set from 'transmute/set';
// @ts-expect-error module not typed
import { getTimestamp } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
const IMG_SRC_REGEX = /(<img[^>]*[\s]+src=[\s]*")([^"]*)("[^>]*>)/gim;
export const getAlteredImgSrcCommonMessage = (richText, originalMessage) => {
  if (!richText) {
    return originalMessage;
  }
  const alteredRichText = richText.replace(IMG_SRC_REGEX, (match, openingCaptureGroup, imgSrcCaptureGroup, closingCaptureGroup) => {
    const randomQuery = new Date().toISOString();

    /**
     * this puts our altered src value between everything that was before and
     * after the original src value
     */
    return `${openingCaptureGroup}${imgSrcCaptureGroup.indexOf('?') > -1 ? `${imgSrcCaptureGroup}&refresh=${randomQuery}` : `${imgSrcCaptureGroup}?refresh=${randomQuery}`}${closingCaptureGroup}`;
  });
  return set('richText', alteredRichText, originalMessage).set('timestamp', getTimestamp(originalMessage) + 1);
};