'use es6';

import { getName, getExtension } from './fileMetadataGetters';
export const displayName = fileMetadata => {
  const name = getName(fileMetadata);
  const extension = getExtension(fileMetadata);
  if (!extension) {
    return name;
  }
  return `${name}.${extension}`;
};