import getIn from 'transmute/getIn';
/*  @ts-expect-error Jun-5-2024, 11:9UTC FixMe: Complete Migration to TypeScript  */
import { toSubject } from 'customer-data-sidebar/graphQL/parse';
import { FEEDBACK_SUBMISSION, TICKET } from 'customer-data-objects/constants/ObjectTypes';

/*  @ts-expect-error Jun-5-2024, 11:9UTC FixMe: Complete Migration to TypeScript  */
export const toTicket = gqlCrmObject => toSubject(TICKET, gqlCrmObject);
/*  @ts-expect-error Jun-5-2024, 11:9UTC FixMe: Complete Migration to TypeScript  */
export const toFeedbackSubmission = gqlCrmObject => toSubject(FEEDBACK_SUBMISSION, gqlCrmObject);

/*  @ts-expect-error Jun-5-2024, 11:9UTC FixMe: Complete Migration to TypeScript  */
export const parse = (objectType, data) => {
  const subjectData = data.crmObject;
  const subject = subjectData && toSubject(objectType, subjectData);
  let items;
  let countObject;
  let associationsById = {};
  if (subjectData) {
    const feedbackSubmissionData = getIn(['defaultAssociations', 'feedbackSubmissionAssociation', 'edges'], subjectData);

    /*  @ts-expect-error Jun-5-2024, 11:9UTC FixMe: Complete Migration to TypeScript  */
    if (feedbackSubmissionData && feedbackSubmissionData.length) {
      /*  @ts-expect-error Jun-5-2024, 11:9UTC FixMe: Complete Migration to TypeScript  */
      items = feedbackSubmissionData.map(edge => toFeedbackSubmission(edge.node));
    }
    countObject = {
      count: getIn(['defaultAssociations', 'count', 'edges', 'length'], subjectData) || 0,
      hasMore: getIn(['defaultAssociations', 'count', 'pageInfo', 'hasNextPage'], subjectData)
    };

    /*  @ts-expect-error Jun-5-2024, 11:9UTC FixMe: Complete Migration to TypeScript  */
    associationsById = feedbackSubmissionData.reduce( /*  @ts-expect-error Jun-5-2024, 11:9UTC FixMe: Complete Migration to TypeScript  */
    (byId, feedbackSubmissionResponse) => {
      const ticketAssociations = getIn(['node', 'defaultAssociations', 'toTickets', 'edges'], feedbackSubmissionResponse);

      /*  @ts-expect-error Jun-5-2024, 11:9UTC FixMe: Complete Migration to TypeScript  */
      const associationLookups = ticketAssociations.map(edge => toTicket(edge.node));
      if (associationLookups.length) {
        /*  @ts-expect-error Jun-5-2024, 11:9UTC FixMe: Complete Migration to TypeScript  */
        byId[getIn(['node', 'id'], feedbackSubmissionResponse)] = associationLookups;
      }
      return byId;
    }, {});
  }
  return {
    associationsById,
    countObject,
    items,
    currentOwnerId: data.viewer.ownerId,
    subject
  };
};

/*  @ts-expect-error Jun-5-2024, 11:9UTC FixMe: Complete Migration to TypeScript  */
export const parseCardTitle = (__objectType, data) => {
  const subjectData = data.crmObject;
  let countObject;
  if (subjectData) {
    countObject = {
      count: getIn(['defaultAssociations', 'count', 'edges', 'length'], subjectData) || 0,
      hasMore: getIn(['defaultAssociations', 'count', 'pageInfo', 'hasNextPage'], subjectData)
    };
  }
  return {
    countObject
  };
};