import { getSenders, getRecipients } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getDeliveryIdentifierValue } from 'conversations-message-history/common-message-format/operators/deliveryIdentifierGetters';
import { getSingleDeliveryIdentifier } from 'conversations-message-history/common-message-format/operators/recipientGetters';
import { getDeliveryIdentifier } from 'conversations-message-history/common-message-format/operators/senderGetters';
/**
 *
 *
 * @description Given a Common Message targeting WhatsApp, this function returns
 *              both the sender's and recipient's phone number. This operator is only
 *              designed to work for WA messages
 */
export const getWAPhoneNumbersForMessage = message => {
  const senders = getSenders(message);
  const senderDeliveryIdentifier = getDeliveryIdentifier(senders && senders.first());
  const senderPhoneNumber = getDeliveryIdentifierValue(senderDeliveryIdentifier);
  const recipients = getRecipients(message);
  const recipientDeliveryIdentifier = getSingleDeliveryIdentifier(recipients && recipients.first());
  const recipientPhoneNumber = getDeliveryIdentifierValue(recipientDeliveryIdentifier);
  return {
    senderPhoneNumber,
    recipientPhoneNumber
  };
};