import I18n from 'I18n';
import { responderToOption } from 'conversations-internal-schema/responders/operators/responderToOption';
import { fetchAssignees } from 'conversations-inbox-lib/responder/public/clients';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
export const buildOptions = ({
  assignees = [],
  botIcon,
  hasMore,
  offsetCount,
  optionTransformation,
  previousOptions = [],
  currentUserOption
}) => {
  const UNASSIGNED_USER_ID = -1;
  const BOT_USER_ID = -2;
  const defaultOptions = [{
    text: I18n.text('conversations-inbox-ui.thread-search-and-filtering.filter-sidebar.filter-options.assignee.unassigned'),
    value: UNASSIGNED_USER_ID
  }, {
    avatar: botIcon,
    text: I18n.text('conversations-inbox-ui.thread-search-and-filtering.filter-sidebar.filter-options.assignee.bot'),
    value: BOT_USER_ID
  }, currentUserOption];

  // In the api response, assignable users always come before unassignable users
  const hasNotReachedUnassignableUsers = Boolean(assignees.length && assignees[assignees.length - 1].assignable === true);
  hasMore = hasMore && hasNotReachedUnassignableUsers;
  const assigneeOptions = assignees.map(responderToOption).filter(assignee => !assignee.disabled && assignee.value !== currentUserOption.value).map(optionTransformation);
  let allOptions = previousOptions.concat(assigneeOptions);
  if (!previousOptions || !previousOptions.length) allOptions = defaultOptions.concat(allOptions);
  return {
    options: allOptions,
    pagination: {
      hasMore,
      offsetCount,
      options: allOptions
    }
  };
};
export const loadOptions = ({
  searchQuery,
  botIcon,
  callback,
  inboxId,
  currentUserOption,
  pagination,
  optionTransformation
}) => {
  let offsetCount = -1;
  let previousOptions = [];
  if (pagination) {
    offsetCount = pagination.offsetCount;
    previousOptions = pagination.options;
  }
  return fetchAssignees({
    inboxId,
    searchQuery,
    offset: {
      group: 'INBOX',
      index: offsetCount
    }
  }).then(({
    results: assignees,
    hasMore,
    offset
  }) => {
    //There's some strange behavior with the backend response. If the previous request
    //requested exactly the last remaining assignees and no more than that,
    //the response will contain an offet of {group: "REMAINDER", index: 0}.
    //Requesting any more than the exact number of remaining assignees makes
    //the index: -1. The value being 0 seems to break the built in hasMore
    //functionality. To work around this, calculate the offset if the load
    //more button still shows up under these circumstances.
    const shouldCalculateOffset =
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    offset.group === 'REMAINDER' && offset.index <= 0;
    const builtOptions = buildOptions({
      assignees,
      botIcon,
      hasMore,
      offsetCount: shouldCalculateOffset ? offsetCount += assignees.length : offset.index,
      optionTransformation,
      previousOptions,
      currentUserOption
    });
    callback(null, builtOptions);
    return builtOptions;
  }).catch(error => {
    reportError({
      error
    });
  });
};