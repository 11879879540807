import { getFileAttachments, getAttachmentWithType } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { setAttachment } from 'conversations-message-history/common-message-format/operators/commonMessageSetters';
import { getPlainTextForCmf, getId
// @ts-expect-error module not typed
} from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { setId, setTimestamp, setText, setRichText
// @ts-expect-error module not typed
} from 'conversations-message-history/common-message-format/operators/commonMessageFormatSetters';
import { getFileIds } from 'conversations-message-history/common-message-format/operators/fileAttachmentGetters';
import FileAttachment from 'conversations-message-history/common-message-format/records/FileAttachment';
import { List, Map as ImmutableMap } from 'immutable';
import MessagePositionDescriptor from 'conversations-message-history/common-message-format/records/MessagePositionDescriptor';
import { LAST_OUTGOING_CHANNEL_MESSAGE_ID } from 'conversations-message-history/common-message-format/constants/messagePositionDescriptorKeyPaths';
import { removeFileAttachmentFromCommonMessage } from './removeFileAttachmentFromCommonMessage';
import { generateUuid } from 'conversations-message-history/util/generateUuid';
import { generateUniqueClientTimestamp } from 'conversations-message-history/util/timestamps';
import { MESSAGE_POSITION_DESCRIPTOR } from 'conversations-message-history/common-message-format/constants/attachmentTypes';
export const splitCMFByAttachments = originalCommonMessage => {
  const plainText = getPlainTextForCmf(originalCommonMessage);
  const fileAttachment = getFileAttachments(originalCommonMessage);
  if (!fileAttachment || fileAttachment.fileIds.size === 1 && !plainText) {
    return [originalCommonMessage];
  }
  const messagePositionDescriptor = getAttachmentWithType(MESSAGE_POSITION_DESCRIPTOR, originalCommonMessage);
  const fileIds = getFileIds(fileAttachment);

  /**
   * create new messages, each with only 1 file attachment with 1 file ID
   */
  const splitMessages = fileIds.map(fileId => {
    const cmfWithNewId = setId(generateUuid(), originalCommonMessage);
    const cmfWithNewTimestamp = setTimestamp(generateUniqueClientTimestamp('split-cmfs'), cmfWithNewId);
    const cmfWithNoText = setText('', cmfWithNewTimestamp);
    const cmfWithNoRichText = setRichText('', cmfWithNoText);
    const newFileAttachment = new FileAttachment({
      fileIds: List([fileId])
    });
    const cmfWithFileAttachment = setAttachment(newFileAttachment, cmfWithNoRichText);
    return cmfWithFileAttachment;
  }).toArray();

  /**
   * if our original message has text, include a message with that text
   */
  if (plainText) {
    const cmfWithOriginalMessageText = removeFileAttachmentFromCommonMessage(originalCommonMessage);
    splitMessages.unshift(cmfWithOriginalMessageText);
  }
  if (messagePositionDescriptor) {
    /**
     * add message position descriptors to each message where
     * each one points to the previous message
     */
    const splitMessagesWithDescriptors = splitMessages.map((message, index) => {
      /**
       * leave the first message unchanged
       */
      if (index === 0) {
        return message;
      }
      const newMessagePositionDescriptor = new MessagePositionDescriptor({
        descriptors: ImmutableMap({
          [LAST_OUTGOING_CHANNEL_MESSAGE_ID]: getId(splitMessages[index - 1])
        })
      });
      const cmfWithMessagePositionDescriptor = setAttachment(newMessagePositionDescriptor, message);
      return cmfWithMessagePositionDescriptor;
    }, [splitMessages[0]]);
    return splitMessagesWithDescriptors;
  }
  return splitMessages;
};