import { createSelector } from 'reselect';
import { getUserId } from '../../selectors/userSelectors/getUserId';
import { getIsConversationsAdmin } from '../../selectors/userSelectors/getIsConversationsAdmin';
import { getCurrentInbox } from 'conversations-inbox-lib/unified-inbox/public/selectors';
import { getUserTeamIds } from '../../selectors/userSelectors/getUserTeamIds';
import { EVERYONE } from 'conversations-internal-schema/inbox/constants/InboxVisibilityTypes';
import { getTeamIds, getUserIds, getVisibility } from 'conversations-internal-schema/inbox/operators/inboxGetters';
import { Set as ImmutableSet } from 'immutable';
export const getIsAdminNotMemberOfInbox = createSelector([getUserId, getCurrentInbox, getUserTeamIds, getIsConversationsAdmin], (userId, currentInbox, teamIdsOfUser, isConversationsAdmin) => {
  const userIds = getUserIds(currentInbox);
  const teamIds = getTeamIds(currentInbox);
  const isMemberOfInbox = getVisibility(currentInbox) === EVERYONE || userIds && userIds.contains(userId);
  const isMemberOfTeam = ImmutableSet(teamIdsOfUser).intersect(ImmutableSet(teamIds)).size > 0;
  return isConversationsAdmin && !isMemberOfInbox && !isMemberOfTeam;
});