import getIn from 'transmute/getIn';
import formatName from 'I18n/utils/formatName';
export const getFirstname = getIn(['firstname', 'value']);
export const getLastname = getIn(['lastname', 'value']);
export const getName = getIn(['name', 'value']);
export const getEmail = getIn(['email', 'value']);
export const getFormattedName = additionalProperties => {
  const name = getName(additionalProperties);
  const firstName = getFirstname(additionalProperties);
  const lastName = getLastname(additionalProperties);
  const email = getEmail(additionalProperties);
  return formatName({
    firstName,
    lastName,
    name,
    email
  });
};