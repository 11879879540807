import getIn from 'transmute/getIn';
import { REJECTED } from '../constants/portalSuspension';
export const getAsyncAccountVerificationStatus = getIn(['asyncCallingAccessVerification', 'status']);
export const getAsyncAccountVerificationSuspended = getIn(['asyncCallingAccessVerification', 'data', 'suspended']);
export const getAsyncAccountVerificationCanAppeal = getIn(['asyncCallingAccessVerification', 'data', 'canAppeal']);
export const getAsyncAccountVerificationAppealState = getIn(['asyncCallingAccessVerification', 'data', 'appealState']);
export const getHasAppealed = getIn(['hasAppealed']);
export const getAsyncCallingAccessScopesStatus = getIn(['asyncCallingAccessScopes', 'status']);
export const getAsyncCallingAccessScopesHasCallingAccessScope = getIn(['asyncCallingAccessScopes', 'data', 'hasCallingAccessScope']);
export const getAsyncCallingAccessScopesHasJitaUserScope = getIn(['asyncCallingAccessScopes', 'data', 'hasJitaUserScope']);
export const getShouldBlockCalling = callingAccess => {
  return getAsyncCallingAccessScopesHasJitaUserScope(callingAccess) || getAsyncAccountVerificationSuspended(callingAccess) || !getAsyncCallingAccessScopesHasCallingAccessScope(callingAccess);
};
export const getIsAppealStateRejected = callingAccess => {
  const appealState = getAsyncAccountVerificationAppealState(callingAccess);
  return appealState === REJECTED;
};