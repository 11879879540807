import { Record } from 'immutable';
export default Record({
  active: false,
  createdBy: null,
  createdAt: 0,
  primaryFlowId: null,
  inboxId: null,
  connectedBy: null,
  invalidAt: 0,
  deletedAt: 0,
  lastWebhookReceivedAt: 0,
  portalId: null,
  pageId: null,
  pageName: null,
  avatarUrl: null,
  persistentMenu: [],
  greetingText: null,
  hasPageCta: null,
  legalConsentText: null,
  legalNoticeText: null,
  legalBasisPolicy: null
}, 'MessengerConnection');