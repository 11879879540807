// gimme: conversations-inbox-ui 06/05/2020 8722c66a58dcba4df92bee8f284215022188a305
'use es6';

import http from 'conversations-http/clients/http';
const DEFAULT_HISTORY_COUNT = 30;
const DESCENDING = 'DESCENDING';
const SOFT_DELETED_ONLY = 'SOFT_DELETED_ONLY';
export const getDeletedMessages = ({
  threadId
}) => {
  return http.get(`messages/v3/history/${threadId}/agent`, {
    query: {
      expectedResponseType: 'WRAPPER_V2',
      sortDirection: DESCENDING,
      limit: DEFAULT_HISTORY_COUNT,
      allowDeletedThread: true,
      deletedMessagesFilter: SOFT_DELETED_ONLY
    }
  });
};