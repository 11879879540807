/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { List } from 'immutable';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import { buildAggregateDataReporter } from './buildAggregateDataReporter';
export class UsageTrackerError extends Error {}
const logDebug = (...args) => {
  try {
    if (window.localStorage.getItem('TRACKING_DEBUG')) {
      // eslint-disable-next-line no-console
      console.log('TRACKING_DEBUG: ', ...args);
    }
  } catch (error) {
    // do nothing
  }
};
export const createAsyncTracker = (config, loader = () => import( /* webpackChunkName: "async-usage-tracker" */'usage-tracker'), usageTracker) => {
  logDebug('Creating async tracker with config: ', config);
  const asyncTracker = {
    onLoadEventQueue: List(),
    propertiesToSetOnLoad: {},
    tracker: usageTracker,
    loadingPromise: null,
    track(event, properties) {
      if (this.tracker) {
        this.tracker.track(event, properties);
      } else {
        this.onLoadEventQueue = this.onLoadEventQueue.push([event, properties]); // push as a full array
      }
      return this.loadingPromise;
    },
    setProperties(properties) {
      if (this.tracker) {
        this.tracker.setProperties(properties);
      } else {
        this.propertiesToSetOnLoad = Object.assign({}, this.propertiesToSetOnLoad || {}, properties);
      }
      return this.loadingPromise;
    },
    setThreadContext({
      channel
    }) {
      this.setProperties({
        channel
      });
    },
    clone(overrides = {}) {
      return createAsyncTracker(Object.assign({}, config, overrides), loader, this.tracker);
    },
    initialize() {
      this.loadingPromise = loader();
      return this.loadingPromise.then(({
        createTracker
      }) => {
        this.tracker = createTracker(config);
        this.onLoadEventQueue.forEach(([event, properties]) => this.track(event, properties));
        if (this.propertiesToSetOnLoad) {
          this.tracker.setProperties(this.propertiesToSetOnLoad);
        }
      }).catch(error => {
        reportError({
          error
        });
      });
    }
  };
  asyncTracker.initialize();
  return asyncTracker;
};
export const createTrackEvent = getTracker => (event, type = 'trackEvent') => {
  let errorMessage = undefined;
  if (typeof event !== 'object') {
    errorMessage = `${type} expects an object, got ${typeof event}`;
  } else if (!Object.prototype.hasOwnProperty.call(event, 'key') || !Object.prototype.hasOwnProperty.call(event, 'properties')) {
    errorMessage = `${type} expects an object with 'key' and 'properties'`;
  }
  if (errorMessage) {
    window.setTimeout(() => {
      throw new UsageTrackerError(errorMessage);
    }, 0);
    return;
  }
  logDebug('tracking event', event.key, event.properties);
  getTracker().track(event.key, event.properties);
};
export const buildTrackerCallbacks = () => {
  const reporter = buildAggregateDataReporter();
  reporter.start();
  return {
    onError(error) {
      reporter.captureEvent(`[error] ${error}`);
    }
  };
};