import PortalIdParser from 'PortalIdParser';
import { getIscSupportedHubId } from '../../constants/IscFeedback';

// Note these are prod surveys and won't render in QA
const ADMIN_SURVEY_ID = 650;
const AGENT_SURVEY_ID = 651;
function launchSurvey(surveyId) {
  const windowWithHsFeedback = window;
  const notInIscHub = PortalIdParser.get() !== getIscSupportedHubId();
  if (windowWithHsFeedback && windowWithHsFeedback.hsFeedback && windowWithHsFeedback.hsFeedback.loadSurvey && notInIscHub) {
    windowWithHsFeedback.hsFeedback.loadSurvey('CSAT', surveyId);
  }
}
export function launchAdminSurvey() {
  launchSurvey(ADMIN_SURVEY_ID);
}
export function launchAgentSurvey() {
  launchSurvey(AGENT_SURVEY_ID);
}