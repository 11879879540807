import { useChannelInstances } from './useChannelInstances';
import { isLoading, isSucceeded } from 'conversations-async-data/async-data/operators/statusComparators';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { getChannelInstancesForChannelId } from '../operators/getChannelInstancesForChannelId';
export const useChannelInstancesForChannel = ({
  inboxId,
  channelId,
  deferred = false
}) => {
  const {
    asyncChannelInstances
  } = useChannelInstances({
    inboxId,
    deferred
  });
  let channelInstances = null;
  if (channelId && isSucceeded(asyncChannelInstances)) {
    const instances = getData(asyncChannelInstances);
    const genericChannelId = typeof channelId === 'string' ? parseInt(channelId, 10) : channelId;
    channelInstances = getChannelInstancesForChannelId(instances, genericChannelId);
  }
  return {
    isLoading: isLoading(asyncChannelInstances),
    channelInstances
  };
};