import { makeDraftKey } from '../operators/makeDraftKey';
import { serializeDraft } from '../operators/serializeDraft';
import { purgeDrafts } from './purgeDrafts';
import { saveToLocalStorage } from './saveToLocalStorage';
const FIVE_HOURS_IN_MS = 1000 * 60 * 60 * 5;
export function saveDraft({
  draft,
  serializeData,
  deserializeData,
  localStorageKeyPrefix,
  emptyChecker,
  currentVersion
}) {
  const serializedDraft = serializeDraft(draft, serializeData);
  const draftKey = makeDraftKey(draft, localStorageKeyPrefix);
  if (draftKey) {
    saveToLocalStorage(draftKey, serializedDraft).catch(error => {
      if (error && error instanceof DOMException && (error.name === 'QuotaExceededError' || error.code === DOMException.QUOTA_EXCEEDED_ERR)) {
        purgeDrafts({
          deserializeData,
          emptyChecker,
          localStorageKeyPrefix,
          ageLimitInMs: FIVE_HOURS_IN_MS,
          currentVersion
        });
      }
    });
  }
}