import { useQuery } from 'data-fetching-client';
import { fetchConnectedEmailAccount, fetchConnectedEmailByChannelInstanceId } from '../queries/conversations-email.queries';
export const useConnectedEmailAccount = id => {
  const {
    data,
    error,
    loading
  } = useQuery(fetchConnectedEmailAccount, {
    variables: {
      id
    }
  });
  return {
    connectedEmailAccount: data && data.connectedEmailAccount,
    error,
    loading
  };
};
export const useConnectedEmailAccountByChannelInstanceId = channelInstanceId => {
  const {
    data,
    error,
    loading
  } = useQuery(fetchConnectedEmailByChannelInstanceId, {
    variables: {
      channelInstanceId
    }
  });
  return {
    connectedEmailAccount: data && data.connectedEmailAccount,
    error,
    loading
  };
};