import { useReduxAsyncFetcher } from 'conversations-async-data/async-data/hooks/useReduxAsyncFetcher';
import { fetchForm } from '../actions/fetchForm';
import { getAsyncFormById } from '../selectors/formsSelectors';
export const useAsyncForm = ({
  formId
}) => {
  const {
    asyncData: asyncForm
  } = useReduxAsyncFetcher({
    action: fetchForm,
    selector: getAsyncFormById,
    id: formId,
    idTransform: id => ({
      formId: id
    })
  });
  return {
    asyncForm
  };
};