import get from 'transmute/get';
import getIn from 'transmute/getIn';
import { TYPE } from '../../common-message-format/constants/keyPaths';
import { LOCATION } from '../../common-message-format/constants/attachmentTypes';
import { getAttachments } from '../../common-message-format/operators/getAttachments';
export const isLocationAttachment = attachment => getIn(TYPE, attachment) === LOCATION;
export const getLocationAttachment = message => {
  const attachments = getAttachments(message);
  if (!attachments || attachments.size === 0) return null;
  return attachments.find(isLocationAttachment) || null;
};
export const getAddress = get('address');
export const getLatitude = get('latitude');
export const getLongitude = get('longitude');
export const getName = get('name');
export const getUrl = get('url');