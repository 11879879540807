// TODO: move to composer-data

// TODO: move to composer-data
import invariant from 'conversations-thread-data/utils/invariant';
import { hasBouncedRecipient } from '../operators/hasBouncedRecipient';
export const bouncedContact = ({
  reply,
  emailDeliverabilityStatuses
}) => {
  invariant(Boolean(reply), 'reply is required for bouncedContact disabling strategy');
  invariant(Boolean(emailDeliverabilityStatuses), 'emailDeliverabilityStatuses is required for bouncedContact disabling strategy');
  return hasBouncedRecipient(reply, emailDeliverabilityStatuses) ? {
    disabled: true,
    sendDisabledReason: 'BOUNCED_CONTACT'
  } : null;
};