'use es6';

import map from 'transmute/map';
import { createSelector } from 'reselect';
import { AGENT } from 'conversations-message-history/common-message-format/constants/legacySenderTypes';
import { setActor } from 'conversations-message-history/common-message-format/operators/setActor';
import { getSenders } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { setSenders } from 'conversations-message-history/common-message-format/operators/commonMessageSetters';
import { getUserId } from 'conversations-thread-data/auth/public/selectors';
const getCommonMessage = (_state, {
  message
}) => message;
export const updateCMFSenders = createSelector([getUserId, getCommonMessage], (userId, message) => {
  const senders = map(sender => setActor({
    senderId: userId,
    senderType: AGENT,
    sender
  }), getSenders(message));
  return setSenders(senders)(message);
});