import { TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
export const sortByPriority = (vm1PropertyValue, vm2PropertyValue) => {
  const priorities = {
    LOW: 0,
    MEDIUM: 1,
    HIGH: 2
  };
  const priority1 = priorities[vm1PropertyValue];
  const priority2 = priorities[vm2PropertyValue];
  if (priority1 < priority2) {
    return -1;
  } else if (priority2 < priority1) {
    return 1;
  } else return 0;
};
const sortBySortTimestamp = (vm1SortTimestamp, vm2SortTimestamp) => vm1SortTimestamp < vm2SortTimestamp ? -1 : 1;
const sortByObjectId = (vm1ObjectId, vm2ObjectId) => vm1ObjectId < vm2ObjectId ? -1 : 1;
export const sortHelpDeskViewMembers = ({
  sortDirection,
  sortOverride,
  property
}) => (vm1, vm2) => {
  const SORT_DIRECTION_ADJUSTED_VALUE = sortDirection === 'ascending' ? 1 : -1;

  //Parse out the value of the sort property.
  const vm1Prop = property && vm1.objectPropertyMap[TICKET_TYPE_ID][property];
  const vm2Prop = property && vm2.objectPropertyMap[TICKET_TYPE_ID][property];

  //Parse out value for sent view timestamp
  const vm1SentStamp = vm1.objectPropertyMap[TICKET_TYPE_ID]['hs_last_message_sent_at'];
  const vm2SentStamp = vm2.objectPropertyMap[TICKET_TYPE_ID]['hs_last_message_sent_at'];

  //If there's no sort property defined, sort by sort timestamp,
  //or by sent timestamp if in the sent view.
  if (!property) {
    if (vm1.sortTimestamp && vm2.sortTimestamp) {
      return sortBySortTimestamp(vm1.sortTimestamp, vm2.sortTimestamp) * -1;
    }
    return sortBySortTimestamp(+vm1SentStamp, +vm2SentStamp) * -1;
  }

  //If the values of the sort properties are equal,
  //and no custom sort exists, just sort by objectID.
  if (vm1Prop === vm2Prop && !sortOverride || !vm1Prop && !vm2Prop) {
    return sortByObjectId(vm1.objectKey.objectId, vm2.objectKey.objectId) * -1;
  }

  //If either HDVM is missing the sort property, put that HDVM last.
  if (!vm1Prop) {
    return 1;
  }
  if (!vm2Prop) {
    return -1;
  }

  //Handle specific properties that aren't simple string or number comparisons.
  if (sortOverride) {
    return SORT_DIRECTION_ADJUSTED_VALUE * sortOverride(vm1Prop, vm2Prop, vm1.objectPropertyMap[TICKET_TYPE_ID], vm2.objectPropertyMap[TICKET_TYPE_ID], property);
  }

  //If the value of sort property is a string representing a number, sort numerically.
  //Comparing +variable === +variable is a very reliable and fast way to check if a variable
  //is either a number or a string representation of a number.
  // eslint-disable-next-line no-self-compare
  if (+vm1Prop === +vm1Prop && +vm2Prop === +vm2Prop) {
    return +vm1Prop < +vm2Prop ? -SORT_DIRECTION_ADJUSTED_VALUE : SORT_DIRECTION_ADJUSTED_VALUE;
  }

  //Otherwise, use string comparisons.
  return vm1Prop.toLocaleLowerCase() < vm2Prop.toLocaleLowerCase() ? -SORT_DIRECTION_ADJUSTED_VALUE : SORT_DIRECTION_ADJUSTED_VALUE;
};