import { createStack } from 'hub-http';
import * as core from 'hub-http/middlewares/core';
import { formatResponse } from './middlewares';
import HubapiStack from 'hub-http/stacks/hubapi';

/** @deprecated Prefer chatSpotV1Stack */
export const chatSpotV1ApiStack = createStack(core.defaultTo('timeout', 5000), core.base('chatspot/v1/api/'), HubapiStack, formatResponse);
export const chatSpotV1StreamingStack = createStack( /** A long timeout because streaming can take a long time */
core.defaultTo('timeout', 25000), core.base('chatspot-streaming/v1/'), HubapiStack);
export const chatSpotV1Stack = createStack(core.defaultTo('timeout', 5000), core.base('chatspot/v1/'), HubapiStack, formatResponse);
export const chatSpotV1HighVolumeStack = createStack(core.defaultTo('timeout', 5000), core.base('chatspot-high-volume/v1/'), HubapiStack);