'use es6';

import { handleActions } from 'redux-actions';
import { updateEntries } from 'conversations-async-data/indexed-async-data/operators/updateEntries';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestOutOfSync } from 'conversations-async-data/async-data/operators/requestOutOfSync';
import { deleteEntry } from 'conversations-async-data/indexed-async-data/operators/deleteEntry';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { senderLocatorInvariant } from '../invariants/senderLocatorInvariant';
import { SENDERS_FETCH } from '../constants/asyncActionTypes';
import { RECIPIENTS_FETCH } from '../../legacy-recipients/constants/asyncActionTypes';
import { CLEAR_SENDER } from '../constants/actionTypes';
import { VISITORS_INVALIDATED } from '../../realtime/public/constants';
import { VISITOR } from 'conversations-message-history/common-message-format/constants/legacySenderTypes';
import { buildSenderLocator } from 'conversations-message-history/senders/operators/buildSenderLocator';
import { limitToCount } from 'conversations-async-data/indexed-async-data/eviction-strategies/limitToCount';
import { SENDERS_ASYNC_DATA_CACHE_SIZE } from '../../common/public/constants/CacheSize';
const initialState = IndexedAsyncData({
  name: 'senders',
  notSetValue: AsyncData(),
  idInvariant: senderLocatorInvariant,
  idTransform: key => key,
  evict: limitToCount(SENDERS_ASYNC_DATA_CACHE_SIZE)
});
export default handleActions({
  [VISITORS_INVALIDATED](state) {
    const senderLocator = buildSenderLocator(VISITOR, null);
    return updateEntry(senderLocator, requestOutOfSync, state);
  },
  [CLEAR_SENDER](state, action) {
    const {
      senderLocator
    } = action.payload;
    return deleteEntry(senderLocator, state);
  },
  [SENDERS_FETCH.STARTED](state, action) {
    const {
      senderLocators
    } = action.payload.requestArgs;
    return updateEntries(senderLocators, (__senderLocator, asyncData) => requestStarted(asyncData), state);
  },
  [SENDERS_FETCH.SUCCEEDED](state, action) {
    const {
      data,
      requestArgs
    } = action.payload;
    const {
      senderLocators
    } = requestArgs;
    return updateEntries(senderLocators, (senderLocator, asyncData) => requestSucceededWithOperator(() => data.get(senderLocator) || null, asyncData), state);
  },
  [SENDERS_FETCH.FAILED](state, action) {
    const {
      senderLocators
    } = action.payload.requestArgs;
    return updateEntries(senderLocators, (__senderLocator, asyncData) => requestFailed(asyncData), state);
  },
  [RECIPIENTS_FETCH.STARTED](state, action) {
    const {
      recipientLocators
    } = action.payload.requestArgs;
    return updateEntries(recipientLocators, (__senderLocator, asyncData) => requestStarted(asyncData), state);
  },
  [RECIPIENTS_FETCH.SUCCEEDED](state, action) {
    const {
      data,
      requestArgs
    } = action.payload;
    const {
      recipientLocators
    } = requestArgs;
    return updateEntries(recipientLocators, (senderLocator, asyncData) => requestSucceededWithOperator(() => data.get(senderLocator) || null, asyncData), state);
  },
  [RECIPIENTS_FETCH.FAILED](state, action) {
    const {
      recipientLocators
    } = action.payload.requestArgs;
    return updateEntries(recipientLocators, (__senderLocator, asyncData) => requestFailed(asyncData), state);
  }
}, initialState);