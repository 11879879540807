import get from 'transmute/get';
import getIn from 'transmute/getIn';
import { AI_ASSISTANT, ATTACHMENT_FILE_TYPES, AT_MENTIONS, CAN_START_CONVERSATION, CHANNEL_TYPE, CHECK_CONNECTED_ACCOUNTS, CONVERSATION_SUMMARY, DELIVERY_IDENTIFIER, DELIVERY_IDENTIFIER_TYPE, EDITOR_CONFIG, EDITOR_TYPING_INDICATOR, FIELDS, FILETYPE_TO_OVERRIDE, FILE_ATTACHMENTS, FORMAT_STYLES, ICON, LABEL, INLINE_IMAGES, INLINE_STYLES, INSERT_TEMPLATES, IS_VISIBLE, KEY, LIMITS, MAX_ATTACHMENT_COUNT, MAX_CONTENT_IN_BYTES, MAX_FILE_ATTACHMENTS_SIZE_IN_BYTES, MAX_FILE_SIZE, MAX_TOTAL_ATTACHMENTS_SIZE_IN_BYTES, MAX_TOTAL_FILE_LIMIT, MAX_TOTAL_FILE_SIZE_LIMIT, MESSAGE_METADATA, PLACEHOLDER, PLAIN_TEXT_HYPERLINKS, RECIPIENTS, RECIPIENTS_FIELDS, RECIPIENT_FIELD, RECIPIENT_FIELD_NAME, RECIPIENT_KEY, REPLY_TO_SPECIFIC_MESSAGES, REQUIRED, REQUIRE_CHATFLOW_CONNECTION_STATUS, REQUIRED_MESSAGE_POSITION_DESCRIPTOR, RICH_TEXT, SENDERS, SENDER_DELIVERY_IDENTIFIER_TYPE, SUBJECT, SUBMIT_BUTTON_TEXT, SUBMIT_ON_ENTER, SUPPORT_ASSOCIATIONS, TEXT_STYLES, TIME_LIMIT, TYPING_INDICATOR, USER_DEFAULT_FONT, URL_FRAGMENT, PAYMENT_LINKS, MAX_RECIPIENT_COUNT_NEW, MAX_RECIPIENT_COUNT_REPLY, INVOICES } from '../constants/keyPaths';
export const getCanStartConversation = getIn(CAN_START_CONVERSATION);
export const getChannelCapabilities = get('channelCapabilities');
export const getChannelType = getIn(CHANNEL_TYPE);
export const getEditingCapabilities = get('editingCapabilities');
export const getIcon = getIn(ICON);
export const getLabel = getIn(LABEL);
export const getLimits = getIn(LIMITS);
export const getSupportAssociations = getIn(SUPPORT_ASSOCIATIONS);
export const getRequireChatFlowConnectionStatus = getIn(REQUIRE_CHATFLOW_CONNECTION_STATUS);
export const getFields = getIn(FIELDS);
export const getCheckConnectedAccounts = getIn(CHECK_CONNECTED_ACCOUNTS);
export const getTypingIndicator = getIn(TYPING_INDICATOR);
export const getMessageMetadata = getIn(MESSAGE_METADATA);
export const getSenders = getIn(SENDERS);
export const getRecipients = getIn(RECIPIENTS);
export const getRecipientsFields = getIn(RECIPIENTS_FIELDS);
export const getRecipientsField = getIn(RECIPIENT_FIELD);
export const getRecipientFieldName = getIn(RECIPIENT_FIELD_NAME);
export const getRecipientKey = getIn(RECIPIENT_KEY);
export const getRecipientMaxNew = getIn(MAX_RECIPIENT_COUNT_NEW);
export const getRecipientMaxReply = getIn(MAX_RECIPIENT_COUNT_REPLY);
export const getDeliveryIdentifier = getIn(DELIVERY_IDENTIFIER);
export const getDeliveryIdentifierType = getIn(DELIVERY_IDENTIFIER_TYPE);
export const getSubject = getIn(SUBJECT);
export const getKey = getIn(KEY);
export const getIsVisible = getIn(IS_VISIBLE);
export const getEditorConfig = getIn(EDITOR_CONFIG);
export const getRichTextEnabled = getIn(RICH_TEXT);
export const getInlineStyles = getIn(INLINE_STYLES);
export const getTextStyles = getIn(TEXT_STYLES);
export const getFormatStyles = getIn(FORMAT_STYLES);
export const getSubmitOnEnterEnabled = getIn(SUBMIT_ON_ENTER);
export const getUserDefaultFontEnabled = getIn(USER_DEFAULT_FONT);
export const getUrlFragment = getIn(URL_FRAGMENT);
export const getAtMentionEnabled = getIn(AT_MENTIONS);
export const getConversationSummaryEnabled = getIn(CONVERSATION_SUMMARY);
export const getAiAssistantEnabled = getIn(AI_ASSISTANT);
export const getPlainTextHyperlinksEnabled = getIn(PLAIN_TEXT_HYPERLINKS);
export const getEditorPlaceholder = getIn(PLACEHOLDER);
export const getEditorSubmitButtonText = getIn(SUBMIT_BUTTON_TEXT);
export const getEditorTypingIndicator = getIn(EDITOR_TYPING_INDICATOR);
export const getEditorMaxContentInBytes = getIn(MAX_CONTENT_IN_BYTES);
export const getEditorInlineImages = getIn(INLINE_IMAGES);
export const getInsertTemplates = getIn(INSERT_TEMPLATES);
export const getPaymentLinks = getIn(PAYMENT_LINKS);
export const getInvoices = getIn(INVOICES);
export const getRequiredMessagePositionDescriptor = getIn(REQUIRED_MESSAGE_POSITION_DESCRIPTOR);
export const addCapability = (capability, capabilities) => {
  if (capabilities.indexOf(capability) === -1) capabilities.push(capability);
  return capabilities;
};
export const getReplyToSpecificMessages = getIn(REPLY_TO_SPECIFIC_MESSAGES);
export const getFileAttachments = getIn(FILE_ATTACHMENTS);
export const getMaxAttachmenSizeBytes = getIn(MAX_FILE_ATTACHMENTS_SIZE_IN_BYTES);
export const getAttachmentCount = getIn(MAX_ATTACHMENT_COUNT);
export const getMaxTotalAttachmentSizeBytes = getIn(MAX_TOTAL_ATTACHMENTS_SIZE_IN_BYTES);
export const getAttachmentFileTypes = getIn(ATTACHMENT_FILE_TYPES);
export const getMaxFileSize = getIn(MAX_FILE_SIZE);
export const getMaxFileLimit = getIn(MAX_TOTAL_FILE_LIMIT);
export const getMaxTotalFileSizeLimit = getIn(MAX_TOTAL_FILE_SIZE_LIMIT);
export const getFileTypeToOverride = getIn(FILETYPE_TO_OVERRIDE);
export const getRequired = getIn(REQUIRED);
export const getSendersDeliveryIdentifierType = getIn(SENDER_DELIVERY_IDENTIFIER_TYPE);
export const getWarningStateTimeLimit = getIn(TIME_LIMIT);