import { publishCommonMessage } from './publishCommonMessage';
// @ts-expect-error module not typed
import { publishEmailMessage } from './publishEmailMessage';
import { getGenericChannelId } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { EMAIL_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
import { getMessageType } from 'conversations-message-history/configured-message-type/operators/configuredMessageTypeGetters';
export function publishMessage(commonMessage, publishOptions = {}) {
  return dispatch => {
    const commonMessageGenericChannelId = getGenericChannelId(commonMessage) || getMessageType(commonMessage);
    if (commonMessageGenericChannelId === EMAIL_GENERIC_CHANNEL_ID) {
      dispatch(publishEmailMessage(commonMessage, publishOptions));
    } else {
      dispatch(publishCommonMessage(commonMessage, publishOptions));
    }
  };
}