import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { evaluateMemberAndCreatePatch } from 'find-and-filter-data/view-member-optimistic-updates/public';
import { getViewFilterBranch, isCustomView } from 'find-and-filter-data/views-schema/public';
import { useConversationIdByThreadId } from '../../crm-integration/hooks/useConversationIdByThreadId';
import { isInSearchAndFilterMode as getIsInSearchAndFilterMode } from 'conversations-inbox-lib/thread-search-and-filtering/public/selectors';
import { useView } from 'find-and-filter-data/views-data/public';
import { selectMemberByThreadId } from 'find-and-filter-data/view-members-data/public';
import { getStatusToggle } from '../../thread-list-member-sidebar/selectors/getStatusToggle';
import { toTicket } from 'svh-tickets-lib/tickets/public/utils';
import { getCurrentInboxId } from 'conversations-inbox-lib/unified-inbox/public/selectors';
import { useFetchAssociatedTickets } from 'conversations-inbox-lib/tickets/fetch/useFetchAssociatedTicket';

/**
 * @description This hook returns a callback that can be passed changes
 * which may result in a ThreadListMember losing its membership to the specific
 * view.
 */
export const useMemberViewMembershipListener = ({
  threadId,
  viewId
}) => {
  const dispatch = useDispatch();
  const inboxId = useSelector(getCurrentInboxId);
  const view = useView(inboxId, viewId);
  const currentThreadListFilterStatus = useSelector(getStatusToggle);
  const threadListFilterStatus = currentThreadListFilterStatus === 'STARTED' || currentThreadListFilterStatus === 'ENDED' ? currentThreadListFilterStatus : undefined;
  const viewFilterProperties = view && getViewFilterBranch(view, threadListFilterStatus);
  const isInSearchAndFilterMode = useSelector(getIsInSearchAndFilterMode);
  const threadListMember = useSelector(state => selectMemberByThreadId(state, {
    threadId,
    viewId
  }));
  const {
    conversationId
  } = useConversationIdByThreadId({
    threadId
  });
  const {
    associatedTicket
  } = useFetchAssociatedTickets({
    conversationId
  });
  const ticketRecord = associatedTicket && toTicket(associatedTicket);

  /**
   * @description Provide this operator with thread details or the ticket and
   * any differences the object has gone through. Based on those changes the
   * hook will re-evaluate list membership and remove the TLM if appropriate.
   *
   * @param differences The changed properties of the threadDetails record
   * @param threadDetails The ThreadDetails of the focused thread
   * @param ticket The associated ticket for the ThreadListMember
   * @returns {void}
   */
  const evaluateListMembership = useCallback(({
    differences,
    threadDetails,
    ticket
  }) => {
    if (!isCustomView(view)) return;
    if (!isInSearchAndFilterMode) {
      evaluateMemberAndCreatePatch({
        differences,
        dispatch,
        threadId,
        threadListMemberOrDetails: threadDetails || threadListMember,
        ticket: ticket || ticketRecord,
        viewFilterProperties: viewFilterProperties || null
      });
    }
  }, [isInSearchAndFilterMode, dispatch, threadId, threadListMember, ticketRecord, view, viewFilterProperties]);
  return {
    evaluateListMembership
  };
};