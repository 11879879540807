import { LOAD_TICKET_EXTERNAL_PROPERTIES } from './ActionTypes';
import { getTicketExternalProperties } from '../utils/ZorseGrrTaggingApi';
export function loadTicketExternalProperties() {
  return dispatch => {
    dispatch({
      type: LOAD_TICKET_EXTERNAL_PROPERTIES,
      meta: 'request'
    });
    return getTicketExternalProperties().then(payload => dispatch({
      type: LOAD_TICKET_EXTERNAL_PROPERTIES,
      meta: 'response',
      payload
    }), payload => dispatch({
      type: LOAD_TICKET_EXTERNAL_PROPERTIES,
      error: true,
      payload
    }));
  };
}