export const TO = 'to';
export const FROM = 'from';
export const DATE = 'date';
export const DURATION = 'duration';
const senderHeaderPopoverTranslationKeys = {
  TO,
  FROM,
  DATE,
  DURATION
};
export default senderHeaderPopoverTranslationKeys;