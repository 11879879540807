import { localStorage } from 'conversations-platform/localStorage';
import { deserializeDraft } from '../operators/deserializeDraft';
export function restoreDraft(draftKey, deserializeData) {
  try {
    const rawDraft = localStorage.getItem(draftKey);
    if (rawDraft) {
      const deserializedDraft = deserializeDraft(rawDraft, deserializeData);
      return deserializedDraft;
    }
  } catch (error) {
    return null;
  }
  return null;
}