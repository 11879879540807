import { useReduxAsyncFetcher } from 'conversations-async-data/async-data/hooks/useReduxAsyncFetcher';
import { fetchTicketDataForThread } from '../actions/fetchTicketDataForThread';
import { getAsyncTicketData } from '../selectors/getAsyncTicketData';
export const useTicketData = ({
  threadId
}) => {
  const {
    asyncData,
    retryFailed
  } = useReduxAsyncFetcher({
    action: fetchTicketDataForThread,
    selector: getAsyncTicketData,
    id: threadId,
    idTransform: id => ({
      threadId: id
    })
  });
  return {
    asyncTicketData: asyncData,
    retryFailed
  };
};