// TODO: move to composer-data

import { isFailed } from 'conversations-async-data/async-data/operators/statusComparators';
export const channelInstancesFailed = ({
  asyncChannelInstances
}) => {
  return isFailed(asyncChannelInstances) ? {
    disabled: true,
    sendDisabledReason: 'INSTANCES_FAILED_TO_LOAD'
  } : null;
};