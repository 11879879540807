import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestSucceeded } from 'conversations-async-data/async-data/operators/requestSucceeded';
import { Map as ImmutableMap } from 'immutable';
import { handleActions } from 'redux-actions';
import { FETCH_AGENT_CAPACITY_TYPES, FETCH_PORTAL_CAPACITY_LIMITS_TYPES, UPDATE_CAPACITY_LIMIT } from '../constants/capacityLimitsActionTypes';
const initialState = {
  agentCapacity: new AsyncData({
    data: ImmutableMap()
  }),
  portalLimits: new AsyncData({
    data: ImmutableMap()
  })
};
const updateAgentCapacity = (agentCapacity, payload) => agentCapacity.update('data', data => {
  let nextData = data;
  if (data) {
    nextData = data.set(payload.requestArgs.type, payload.data.capacityLoad);
  }
  return nextData;
});
const updatePortalLimits = (portalLimits, payload) => portalLimits.update('data', data => {
  let nextData = data;
  if (data) {
    nextData = data.set(payload.requestArgs.type, payload.data);
  }
  return nextData;
});
const updateState = (state, update) => Object.assign({}, state, update);
const updateAllCapacity = (state, update) => {
  const requestArgs = {
    agentId: update.userId,
    type: update.capacityType
  };
  const agentCapacity = updateAgentCapacity(state.agentCapacity, {
    data: {
      capacityLoad: update.capacityLoad
    },
    requestArgs
  });
  const portalLimits = updatePortalLimits(state.portalLimits, {
    data: {
      isEnabled: update.capacityLimitsEnabled,
      capacityLimit: update.capacityLimit,
      capacityType: update.capacityType
    },
    requestArgs
  });
  return updateState(state, {
    agentCapacity,
    portalLimits
  });
};
export const capacityLimits = handleActions({
  [FETCH_AGENT_CAPACITY_TYPES.STARTED]: state => updateState(state, {
    agentCapacity: requestStarted(state.agentCapacity)
  }),
  [FETCH_AGENT_CAPACITY_TYPES.SUCCEEDED]: (state, {
    payload
  }) => {
    const update = updateAgentCapacity(state.agentCapacity, payload);
    return updateState(state, {
      agentCapacity: requestSucceeded(update)
    });
  },
  [FETCH_PORTAL_CAPACITY_LIMITS_TYPES.STARTED]: state => updateState(state, {
    portalLimits: requestStarted(state.portalLimits)
  }),
  [FETCH_PORTAL_CAPACITY_LIMITS_TYPES.SUCCEEDED]: (state, {
    payload
  }) => {
    const update = updatePortalLimits(state.portalLimits, payload);
    return updateState(state, {
      portalLimits: requestSucceeded(update)
    });
  },
  [UPDATE_CAPACITY_LIMIT]: (state, {
    payload
  }) => updateAllCapacity(state, payload)
}, initialState);