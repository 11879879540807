import { createSlice } from '@reduxjs/toolkit';
import { makeActionsRecord } from '../utils/subscriptionUtils';
const initialState = Object.assign({
  objectId: -1,
  subscription: null,
  error: null
}, makeActionsRecord(() => ({
  isOpen: false
})));
export const actionsSlice = createSlice({
  name: 'subscription-actions',
  initialState,
  reducers: {
    setObjectId(state, action) {
      const objectId = typeof action.payload === 'number' ? action.payload : Number(action.payload);
      if (isNaN(objectId) || typeof objectId !== 'number') {
        throw new Error(`subscription-actions received an invalid objectId: '${action.payload}'`);
      }
      if (objectId !== state.objectId) {
        state.objectId = objectId;
        state.subscription = null;
      }
      return state;
    },
    setSubscription(state, action) {
      state.subscription = action.payload;
      return state;
    },
    setError(state, action) {
      state.error = action.payload ? {
        type: action.payload.name
      } : null;
    },
    openActionModal(state, action) {
      state[action.payload].isOpen = true;
      return state;
    },
    closeActionModal(state, action) {
      state[action.payload].isOpen = false;
      return state;
    }
  }
});
export const {
  setObjectId,
  openActionModal,
  closeActionModal,
  setSubscription
} = actionsSlice.actions;
export default actionsSlice.reducer;