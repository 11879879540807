import { getLastDOMNodeMessageId } from './getLastDOMNodeMessageId';
import { getDOMNodeByMessageId } from './getDOMNodeByMessageId';
export const getMessageHistoryScrollingSnapshot = current => {
  const lastMessageId = getLastDOMNodeMessageId();
  if (!current || !lastMessageId) return null;
  const lastMessage = getDOMNodeByMessageId(lastMessageId);
  if (!lastMessage) {
    return null;
  }
  const prevScrollTop = current.getScrollDistanceFromTop();
  const {
    offsetTop: lastMessageOffsetTop
  } = lastMessage;
  return {
    lastMessageId,
    lastMessageOffsetTop,
    prevScrollTop
  };
};