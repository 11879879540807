export const getCopyCitation = ({
  channel
} = {}) => ({
  key: 'copyReplyRecommendationSource',
  properties: {
    action: 'Copy reply recommendation source',
    channel
  }
});
export const getOpenCitation = ({
  channel
} = {}) => ({
  key: 'openReplyRecommendationSource',
  properties: {
    action: 'Open reply recommendation source',
    channel
  }
});
export const getViewCitationList = ({
  channel
} = {}) => ({
  key: 'viewReplyRecommendationSource',
  properties: {
    action: 'View reply recommendation source',
    channel
  }
});