import { useCallback } from 'react';
import { isLoading } from 'conversations-async-data/async-data/operators/statusComparators';
import { getRecipientFieldName } from 'conversations-thread-data/channel-configuration/public/operators';
import { useChannelConfigurations } from 'conversations-thread-data/channel-configuration/public/hooks';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { getReplyWithNextRecipients } from '../operators/getReplyWithNextRecipients';
export function useUpdateRecipients({
  onChange,
  reply,
  recipientField
}) {
  const fieldName = recipientField && getRecipientFieldName(recipientField);
  const {
    asyncChannelConfigurations
  } = useChannelConfigurations();
  const channelConfigurations = getData(asyncChannelConfigurations);
  const updateRecipients = useCallback(nextIdentifiers => {
    const nextReply = getReplyWithNextRecipients({
      channelConfigurations,
      reply,
      fieldName,
      nextIdentifiers
    });
    onChange(nextReply);
  }, [onChange, reply, fieldName, channelConfigurations]);
  return {
    updateRecipients,
    isLoading: isLoading(asyncChannelConfigurations)
  };
}