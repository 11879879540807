import get from 'transmute/get';
import getIn from 'transmute/getIn';
import { getId as getIdFromContact, getProperty } from 'customer-data-objects/record/ObjectRecordAccessors';
import { ASSOCIATED_COMPANY_ID, ASSOCIATED_COMPANY_NAME, EMAIL, PHONE, OWNER, TEAM, FIRST_NAME, LAST_NAME, JOB_TITLE, COMPANY, VID, PORTAL_ID, CANONICAL_VID, ADDITIONAL_EMAILS, MERGED_VIDS } from '../constants/ContactKeys';
function getAssociatedCompanyProperty(record, property) {
  if (property === undefined) return undefined;
  if (!record) return undefined;
  const propertyValue = getIn(['associated-company', 'properties', property, 'value'], record);
  if (propertyValue === undefined) return undefined;
  return propertyValue;
}
const getContactProperty = property => contact => getProperty(contact, property);
const getAssociatedContactCompanyProperty = property => contact => getAssociatedCompanyProperty(contact, property);
export const getFirstName = getContactProperty(FIRST_NAME);
export const getLastName = getContactProperty(LAST_NAME);
export const getJobTitle = getContactProperty(JOB_TITLE);
export const getCompany = getContactProperty(COMPANY);
export const getEmail = getContactProperty(EMAIL);
export const getPhone = getContactProperty(PHONE);
export const getAdditionalEmailsProperty = getContactProperty(ADDITIONAL_EMAILS);
export const getMergedVids = get(MERGED_VIDS);
export const getAssociatedCompanyId = getContactProperty(ASSOCIATED_COMPANY_ID);
export const getAssociatedCompanyName = getAssociatedContactCompanyProperty(ASSOCIATED_COMPANY_NAME);
export const getOwner = getContactProperty(OWNER);
export const getTeam = getContactProperty(TEAM);
export const getVid = contact => get(VID, contact);
export const getCanonicalVid = get(CANONICAL_VID);
export const getPortalId = get(PORTAL_ID);
export const getId = getIdFromContact;
export const getAdditionalEmails = contact => {
  const additionalEmails = getAdditionalEmailsProperty(contact);
  return additionalEmails ? additionalEmails.split(';') : null;
};
export const getAllEmails = contact => {
  const primaryEmail = getEmail(contact);
  return [...(primaryEmail ? [primaryEmail] : []), ...(getAdditionalEmails(contact) || [])];
};
export const getAllVids = contact => {
  const vid = getVid(contact);
  const mergedVids = getMergedVids(contact);
  if (mergedVids && mergedVids.length) {
    return [...mergedVids, vid];
  }
  return [vid];
};