import { buildError } from '../../error-reporting/builders/buildError';
import promiseTimeout from 'hs-promise-utils/timeout';
import { PROMISE_TIMEOUT_MS } from '../constants/defaults';
import { ATTACHED, FAILED } from '../constants/channelStates';
import { buildRequestTimeoutError } from '../../errors/builders/buildRequestTimeoutError';

/**
 * @description Wait until a channel is attached
 */
export function waitForAttach(channels, channelId) {
  const channel = channels.get(channelId);
  return promiseTimeout(new Promise((resolve, reject) => {
    if (channel.state === ATTACHED) {
      resolve(undefined);
    } else {
      channel.once(ATTACHED, resolve);
      channel.once(FAILED, errorInfo => {
        reject(buildError('PubSub channel attachment failure', Object.assign({
          channelId
        }, errorInfo)));
      });
    }
  }), PROMISE_TIMEOUT_MS, buildRequestTimeoutError('PubSub channel attachment timeout', {
    channelId,
    channelState: channel.state
  }));
}