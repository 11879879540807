/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import _objectDestructuringEmpty from "@babel/runtime/helpers/esm/objectDestructuringEmpty";
import { Map as ImmutableMap } from 'immutable';
import { AsyncCalleeOmnibusResult } from '../records/CalleeOmnibusResult';
import { getOrCreateUniversalCallingStore } from 'calling-global-api/universal-calling-store/UniversalCallingStore';
import { addCallee, deleteCallee } from 'calling-lifecycle-internal/callees/operators/calleesOperators';
import { fetchSingleCalleeClient } from 'calling-lifecycle-internal/callees/clients/calleesClient';
import { CallableObject } from 'calling-lifecycle-internal/callees/records/CalleesRecordsTyped';
import { refreshCalleeOmnibusData } from '../operators/getCalleeOmnibusData';
import { FAILED, SUCCEEDED } from 'calling-lifecycle-internal/async-flux-utils/constants/apiLoadingStates';
import { ADD_CALLABLE_ASSOCIATION, REMOVE_CALLABLE_ASSOCIATION, UPDATE_CALLEE_PHONE_NUMBER } from 'calling-global-api/constants/EventListeners';
let calleeOmnibusStore;
export const getSubjectKey = ({
  subjectId,
  objectTypeId
}) => {
  if (!subjectId || !objectTypeId) {
    return null;
  }
  return `${subjectId}_${objectTypeId}`;
};
export const setCalleeOminbusStoreForTesting = store => {
  calleeOmnibusStore = store;
};
export const parseRawState = state => {
  const omnibusInformation = Object.assign({}, (_objectDestructuringEmpty(state), state));
  const parsedOmnibusInformation = {};
  if (omnibusInformation) {
    Object.keys(omnibusInformation).forEach(key => {
      if (!omnibusInformation[key]) return;
      parsedOmnibusInformation[key] = new AsyncCalleeOmnibusResult(omnibusInformation[key]);
    });
  }
  return ImmutableMap(Object.assign({}, parsedOmnibusInformation));
};
export function addCallableObject(callableObject, {
  objectId,
  objectTypeId
}) {
  const subjectKey = getSubjectKey({
    subjectId: objectId,
    objectTypeId
  });
  const state = calleeOmnibusStore.getState();
  const callees = state.getIn([subjectKey, 'data', 'callableObjectAndAssociations']);
  if (callees) {
    const updatedCallees = addCallee(callableObject, callees);
    calleeOmnibusStore.setState(state.setIn([subjectKey, 'data', 'callableObjectAndAssociations'], updatedCallees, state));
  }
}
export function onRefreshCallees({
  objectId,
  objectTypeId
}) {
  refreshCalleeOmnibusData({
    objectId,
    objectTypeId
  }).then(result => {
    if (!result) return;
    const state = calleeOmnibusStore.getState();
    calleeOmnibusStore.setState(state.setIn([getSubjectKey({
      subjectId: objectId,
      objectTypeId
    }), 'data', 'callableObjectAndAssociations'], result));
  });
}
export function setCalleeOmnibusData({
  fetchCalleeOmnibusPromise,
  subjectId,
  objectTypeId
}) {
  fetchCalleeOmnibusPromise.then(data => {
    const calleeOmnibusResult = new AsyncCalleeOmnibusResult({
      state: SUCCEEDED,
      data
    });
    calleeOmnibusStore.updateState(state => {
      return state.set(getSubjectKey({
        subjectId,
        objectTypeId
      }), calleeOmnibusResult);
    });
  }).catch(error => {
    calleeOmnibusStore.updateState(state => state.set(getSubjectKey({
      subjectId,
      objectTypeId
    }), new AsyncCalleeOmnibusResult({
      state: FAILED,
      error
    })));
  });
}
export function handleAddCallableAssociation(data) {
  const idGetterObject = {
    objectId: data.associationObjectId,
    objectTypeId: data.associationObjectTypeId
  };
  fetchSingleCalleeClient(idGetterObject).then(callee => {
    const callableObject = CallableObject.fromJS(callee);
    addCallableObject(callableObject, data);
  });
}
export function handleRemoveCallableAssociation({
  objectId,
  objectTypeId,
  associationObjectId,
  associationObjectTypeId
}) {
  const subjectKey = getSubjectKey({
    subjectId: objectId,
    objectTypeId
  });
  const state = calleeOmnibusStore.getState();
  const callees = state.getIn([subjectKey, 'data', 'callableObjectAndAssociations']);
  if (callees) {
    const updatedCallees = deleteCallee({
      associationObjectId,
      associationObjectTypeId
    }, callees);
    calleeOmnibusStore.setState(state.setIn([subjectKey, 'data', 'callableObjectAndAssociations'], updatedCallees, state));
  }
}
export const getOrCreateCalleeOmnibusStore = ({
  subjectId,
  objectTypeId
}) => {
  let initialState = ImmutableMap();
  const subjectKey = getSubjectKey({
    subjectId,
    objectTypeId
  });
  if (subjectKey) {
    initialState = initialState.set(subjectKey, new AsyncCalleeOmnibusResult());
  }
  const addAssociationCallback = props => handleAddCallableAssociation(props);
  const updateCalleeNumberCallback = props => onRefreshCallees(props);
  calleeOmnibusStore = getOrCreateUniversalCallingStore({
    name: 'calleeOmnibus',
    initialState,
    parseRawState,
    version: 1,
    // this needs to update with AsyncCalleeOmnibusResult,
    eventListeners: [{
      event: ADD_CALLABLE_ASSOCIATION,
      callback: addAssociationCallback
    }, {
      event: REMOVE_CALLABLE_ASSOCIATION,
      callback: handleRemoveCallableAssociation
    }, {
      event: UPDATE_CALLEE_PHONE_NUMBER,
      callback: updateCalleeNumberCallback
    }]
  });
  return calleeOmnibusStore;
};