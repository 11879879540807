'use es6';

import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import { isThreadComment } from 'conversations-message-history/comment-message/operators/cmComparators';
import { isCommonMessageFormat, isEmailCMF } from 'conversations-message-history/common-message-format/operators/cmfComparators';
import { getTopLevelType } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { publishEmail } from '../../../email/actions/publishEmail';
import { publishMessageWithErrorHandling } from '../../public/actions/publishMessageWithErrorHandling';
import { getUnpublishedMessage } from '../operators/getUnpublishedMessage';
const reportUnsupported = message => {
  const ERROR_NAME = 'UNSUPPORTED_REPUBLISH_MESSAGE';
  reportError({
    error: new Error(`${ERROR_NAME}: ${getTopLevelType(message)}`),
    fingerprint: ['{{ default }}', ERROR_NAME],
    tags: {
      isVisibleToUser: false
    }
  });
};
export const republishMessage = ({
  message,
  threadId,
  channel
}) => {
  return dispatch => {
    const updatedMessage = getUnpublishedMessage(message, threadId);
    switch (true) {
      case isEmailCMF(updatedMessage):
        dispatch(publishEmail(updatedMessage));
        break;
      case isThreadComment(updatedMessage):
      case isCommonMessageFormat(updatedMessage):
        dispatch(publishMessageWithErrorHandling({
          channel,
          message: updatedMessage,
          threadId
        }));
        break;
      default:
        reportUnsupported(message);
    }
  };
};