import { Record } from 'immutable';
import { EMAIL } from '../constants/ContactType';
class Escalation extends Record({
  agentId: 0,
  ticketId: 0,
  type: undefined,
  fields: {},
  revision: 0
}, 'Escalation') {
  get contactPreferences() {
    return this.fields.contactPreferences;
  }
  setContactPreferences(contactPreferences) {
    return this.set('fields', Object.assign({}, this.fields, {
      contactPreferences
    }));
  }
  get customerEmail() {
    return this.fields.customerEmail;
  }
  setCustomerEmail(customerEmail) {
    return this.set('fields', Object.assign({}, this.fields, {
      customerEmail
    }));
  }
  get hubspotOwnerEmail() {
    return this.fields.hubspotOwnerEmail;
  }
  setHubspotOwnerEmail(hubspotOwnerEmail) {
    return this.set('fields', Object.assign({}, this.fields, {
      hubspotOwnerEmail
    }));
  }
  get notes() {
    return this.fields.notes;
  }
  setNotes(notes) {
    return this.set('fields', Object.assign({}, this.fields, {
      notes
    }));
  }
  get reason() {
    return this.fields.reason ? parseInt(this.fields.reason, 10) : undefined;
  }
  setReason(reason) {
    return this.set('fields', Object.assign({}, this.fields, {
      reason
    }));
  }
}
Escalation.fromJS = obj => {
  const escalation = new Escalation(Object.assign({}, obj, {
    fields: obj.fields || {}
  }));
  return escalation.setContactPreferences(escalation.contactPreferences || EMAIL);
};
export default Escalation;