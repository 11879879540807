import { ACTIVE_TITLE_BAR_HEIGHT, BASE_TITLE_BAR_HEIGHT, THIRD_PARTY_PROVIDER, TWILIO_WIDGET_OPTIONS } from '../../constants/twilioWidgetOptions';
import { CallWidgetStates } from '../constants/CallWidgetStates';
import { CallDirection } from '../../constants/CallDirection';
export const getIsCallIncoming = callStatus => {
  return callStatus === CallWidgetStates.INCOMING_INBOUND_CALL;
};
export const getIsInboundCallActive = callStatus => {
  return callStatus === CallWidgetStates.ANSWERED;
};
export const getShouldShowPreCallState = (status, startCall) => !startCall && [CallWidgetStates.UNINITIALIZED, CallWidgetStates.READY, CallWidgetStates.LOADING, CallWidgetStates.SYNC_TIMEOUT].includes(status);
export const getShouldShowEndCallState = status => [CallWidgetStates.ENDING, CallWidgetStates.ENDED].includes(status);
export const getShouldShowActiveCallState = status => [CallWidgetStates.INITIALIZING_OUTBOUND_CALL, CallWidgetStates.RINGING, CallWidgetStates.ANSWERED].includes(status);
export const getShouldShowActiveInboundCallState = (callStatus, callDirection) => callDirection === CallDirection.INBOUND && getShouldShowActiveCallState(callStatus);
export const getShouldShowEndInboundCallState = (callStatus, callDirection) => callDirection === CallDirection.INBOUND && getShouldShowEndCallState(callStatus);
export const canStartCallFromStatus = status => [CallWidgetStates.UNINITIALIZED, CallWidgetStates.READY, CallWidgetStates.ENDED].includes(status);
export const getTitleBarHeight = callStatus => {
  if (getShouldShowActiveCallState(callStatus)) {
    return ACTIVE_TITLE_BAR_HEIGHT;
  }
  return BASE_TITLE_BAR_HEIGHT;
};
export const getDefaultMiniumDimensions = isTwilioBasedCallProvider => isTwilioBasedCallProvider ? TWILIO_WIDGET_OPTIONS : THIRD_PARTY_PROVIDER;