import { getConversationStatusFromThreadStatus, getStatus, getGenericChannel } from 'find-and-filter-data/view-members-schema/protected';
import pipe from 'transmute/pipe';
const TICKET_PIPELINE_STAGE = 'hs_pipeline_stage';
const TICKET_RESOLUTION = 'hs_resolution';
const TICKET_PRIORITY = 'hs_ticket_priority';
const TICKET_SUBJECT = 'subject';
const TICKET_CONTENT = 'content';
const TICKET_CATEGORY = 'hs_ticket_category';
const TICKET_OWNER = 'hubspot_owner_id';
const TICKET_TIME_TO_CLOSE = 'time_to_close';
const CONVERSATION_CHANNEL = 'hs_conversation_channel';
const CONVERSATION_STATUS = 'hs_conversation_status';
export const SUPPORTED_CONVERSATION_PROPERTIES_MAP = {
  [CONVERSATION_STATUS]: pipe(getStatus,
  // need to convert the thread status from the TLM to a conversation status
  getConversationStatusFromThreadStatus),
  [CONVERSATION_CHANNEL]: getGenericChannel
};
const CONVERSATION_PROPERTY_TO_THREAD_PROPERTY = {
  [CONVERSATION_STATUS]: 'status',
  [CONVERSATION_CHANNEL]: 'originalGenericChannelId'
};

/**
 * The conversation object filters we will do optimistic updates for. Note that
 * any properties added to here should have their matching thread field
 * added to the SUPPORTED_THREAD_OBJ_PROPERTIES array.
 */
export const SUPPORTED_CONVERSATION_OBJ_PROPERTIES = Object.keys(SUPPORTED_CONVERSATION_PROPERTIES_MAP);
/**
 * Note that while we filter for conversation objects, we use threads in the inbox.
 * Because of that any supported conversation obj properties should have their
 * matching thread property listed in this array.
 */
export const SUPPORTED_THREAD_OBJ_PROPERTIES = SUPPORTED_CONVERSATION_OBJ_PROPERTIES.map(prop => CONVERSATION_PROPERTY_TO_THREAD_PROPERTY[prop]);

/**
 * The ticket object filters we will do optimistic updates for.
 */
export const SUPPORTED_TICKET_OBJ_PROPERTIES = [TICKET_CATEGORY, TICKET_CONTENT, TICKET_OWNER, TICKET_PIPELINE_STAGE, TICKET_PRIORITY, TICKET_RESOLUTION, TICKET_SUBJECT, TICKET_TIME_TO_CLOSE];

/**
 * When we apply a TLM update patch, we also attempt to optimistically update
 * the TLM. These properties are the ones we will attempt to replace with the new
 * values. All other values will not be updated.
 */
export const THREAD_LIST_MEMBER_FIELDS_TO_UPDATE = ['assignee', 'removal', 'status'];