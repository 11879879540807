import { getAllRecipientsDeliveryIdentifiers, getFromSenderEmail } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import getEmailAddressFromString from './getEmailAddressFromString';
export const getTotalRecipientsCount = ({
  message
}) => {
  const allUnformattedRecipients = getAllRecipientsDeliveryIdentifiers(message).toArray();
  const allRecipients = allUnformattedRecipients.map(recipientAddressString => getEmailAddressFromString(recipientAddressString));
  let totalRecipients = [...new Set(allRecipients)];
  if (totalRecipients.length) {
    const fromAddress = getFromSenderEmail(message);
    const fromAddressEmail = fromAddress && getEmailAddressFromString(fromAddress);
    // Remove fromEmail from recipients
    totalRecipients = totalRecipients.filter(email => {
      return email !== fromAddressEmail;
    });
  }
  return totalRecipients.length;
};