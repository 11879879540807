import { registerMutation, registerQuery } from 'data-fetching-client';
import http from 'conversations-http/clients/http';
import { markPromptAsShown } from '../../contextual-prompts/clients/markPromptAsShown';
import { INBOX_ONBOARDING_TOUR_PROMPTS_STEPS } from '../constants/OnboardingConstants';
const INBOX_ONBOARDING_STEPS = 'inboxOnboardingSteps';
export const FETCH_INBOX_ONBOARDING_STEPS = registerQuery({
  fieldName: INBOX_ONBOARDING_STEPS,
  fetcher() {
    return http.get(`cv/inbox/settings/v1/user-prompts/unviewedBulk`, {
      query: {
        promptType: INBOX_ONBOARDING_TOUR_PROMPTS_STEPS
      }
    });
  }
});
export const SET_ONBOARDING_STEP_SEEN = registerMutation({
  fieldName: INBOX_ONBOARDING_STEPS,
  fetcher: markPromptAsShown
});