import { Record, Map as ImmutableMap } from 'immutable';
import toJS from 'transmute/toJS';
import { PermissionType } from '../common/PermissionType';
import { generateViewId } from '../common/viewIdOperators';
import { ProcessingState, ParamType } from './customViewTypes';
export class CustomView extends Record({
  channel: '',
  createdAt: -1,
  createdByUserId: null,
  customViewId: -1,
  defaultStatus: 'OPEN',
  defaultViewType: null,
  filterBranch: null,
  id: '',
  isUserEditable: false,
  latestListRefreshEndedAt: null,
  listId: -1,
  name: '',
  objectTypeId: '',
  permissionTargetId: null,
  paramProperty: null,
  paramType: ParamType.NONE,
  permissionType: PermissionType.ALL,
  processingState: ProcessingState.Processing,
  sortByProperty: {},
  sortDirection: 'DESC',
  threadCount: null,
  unreadThreadCount: null
}, 'CustomView') {
  constructor(props) {
    const propsMap = ImmutableMap(props || {});
    const viewId = generateViewId(props);
    const customViewMap = propsMap.merge({
      id: viewId
    })
    // filterBranch is expected to be a JS object for customer-data-filters
    // but .merge converts it to a Map. So here we convert it back to POJO
    .update('filterBranch', filterBranchMap => toJS(filterBranchMap));
    super(customViewMap);
    this.updateCounts = this.updateCounts.bind(this);
  }
  updateCounts({
    threadCount,
    unreadThreadCount
  }) {
    return this.set('threadCount', threadCount).set('unreadThreadCount', unreadThreadCount);
  }
}