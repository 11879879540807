import { batch } from 'react-redux';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { getType } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { CONTACT_ASSOCIATION } from 'conversations-message-history/contact-association-message/constants/messageTypes';

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { deserialize } from 'conversations-message-history/common-message/serializers/messageSerializer';
import { refreshVisitor } from 'find-and-filter-data/visitors/public';
import { getAgentId, getCachedAgentStatus } from 'conversations-inbox-lib/agent-status/public';
import { getVid, getThreadId
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
} from 'conversations-message-history/contact-association-message/operators/contactAssociationGetters';
import { doesThreadMatchFilters } from 'find-and-filter-data/search-and-filter-data/protected';
import { isInSearchAndFilterMode as getIsInSearchAndFilterMode } from 'conversations-inbox-lib/thread-search-and-filtering/public/selectors';
import { client } from 'conversations-inbox-lib/data-fetching-client/public/clients';
import { getCurrentInboxId } from 'conversations-inbox-lib/unified-inbox/public/selectors';
import { getFocusedViewMemberThreadId } from '../../focused-view-member/selectors/getFocusedViewMemberThreadId';
import { updateAgentCapacity } from 'conversations-inbox-lib/capacity-limits/public/actions';
import { CAPACITY_LOAD_UPDATE } from 'conversations-inbox-lib/capacity-limits/public/constants';
const getCurrentAgentId = () =>
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'AgentStatusRecord | undefined' i... Remove this comment to see the full error message
getAgentId(getCachedAgentStatus(client));
const handleCapacityUpdate = message => {
  const actions = [];
  if (getCurrentAgentId() === message.userId) {
    actions.push(updateAgentCapacity(message));
  }
  return actions;
};
const getActionsForMessage = (state, messageObj) => {
  const message = deserialize({
    json: messageObj
  });
  const isInSearchAndFilterMode = getIsInSearchAndFilterMode(state);
  const type = getType(message);
  switch (type) {
    case CAPACITY_LOAD_UPDATE:
      return handleCapacityUpdate(message);
    case CONTACT_ASSOCIATION:
      {
        const actionsList = [refreshVisitor({
          vid: getVid(message),
          threadId: getThreadId(message)
        })];
        if (isInSearchAndFilterMode) {
          const inboxId = getCurrentInboxId(state);
          const threadId = getFocusedViewMemberThreadId(state);
          actionsList.push(doesThreadMatchFilters({
            inboxId,
            threadId
          }));
        }
        return actionsList;
      }
    default:
      return [];
  }
};

/**
 * @description handles a single pubsub message over the live
 * portal channel.
 * @param {Object} message the pubsub message with metadata
 * @param {Object} message.data the actual message
 */
export const handlePortalMessage =
// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'messageObj' implicitly has an 'an... Remove this comment to see the full error message

({
  data: messageObj
}) => (dispatch, getState) => {
  getActionsForMessage(getState(), messageObj).forEach(action => dispatch(action));
};

/**
 * @description handles an array of messages replayed
 * over the portal by dispatching them as a batch.
 * @param {Object[]} messages the pubsub message with metadata
 */
export const handlePortalPlayback = messages => (dispatch, getState) => {
  const actions = messages.reduce(
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'messageObj' implicitly has an 'an... Remove this comment to see the full error message
  (accumulator, {
    data: messageObj
  }) => {
    return accumulator.concat(...getActionsForMessage(getState(), messageObj));
  }, []);
  batch(() => {
    actions.forEach(action => dispatch(action));
  });
};