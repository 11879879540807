import get from 'transmute/get';
const isHumanAgent = agent => {
  return !!get('userId', agent);
};
const isBotAgent = agent => {
  return !!get('id', agent);
};

/**
 * A type signature for an object containing the `agent` attribute. This lets callers determine
 * if the object can be treated as a human or a bot if the record is partial.
 */

export const getAgent = assignee => {
  return get('agent', assignee);
};
export const getAgentId = assignee => {
  const agent = getAgent(assignee);
  if (isHumanAgent(agent)) {
    return get('userId', agent);
  }
  return null;
};
export const getBotId = assignee => {
  const agent = getAgent(assignee);
  if (isBotAgent(agent)) {
    return get('id', agent);
  }
  return null;
};
export const getEmail = assignee => {
  const agent = getAgent(assignee);
  if (isHumanAgent(agent)) {
    return get('email', agent);
  }
  return null;
};
export const getFirstName = assignee => {
  const agent = getAgent(assignee);
  if (isHumanAgent(agent)) {
    return get('firstName', agent);
  }
  return null;
};
export const getLastName = assignee => {
  const agent = getAgent(assignee);
  if (isHumanAgent(agent)) {
    return get('lastName', agent);
  }
  return null;
};
export const getAgentState = get('agentState');
export const getAvatarUrl = get('avatarUrl');
export const getIsActiveAccount = get('isActiveAccount');
export const getIsAssignable = get('isAssignable');
export const isBot = assignee => isBotAgent(getAgent(assignee));
export const isHuman = assignee => isHumanAgent(getAgent(assignee));