const MAX_STRING_LEGNTH = 2000;
const KEYS_TO_OMIT = [
// added by IE
'number', 'description',
// added by safari
'line', 'column'];

/**
 * @description
 * Extract useful metadata from error actions provided that they are primitive values.
 * Strings are truncated at 2000 characters.
 */
export function getExtraErrorData(error) {
  if (!error) return null;
  const keys = Object.keys(error).filter(key => !KEYS_TO_OMIT.includes(key));
  if (!keys.length) return null;
  return keys.reduce((acc, key) => {
    const errorProp = error[key];
    switch (typeof errorProp) {
      case 'boolean':
      case 'number':
        {
          acc[key] = errorProp;
          break;
        }
      case 'string':
        {
          const suffix = errorProp.length > MAX_STRING_LEGNTH ? '...' : '';
          acc[key] = `${errorProp.substr(0, MAX_STRING_LEGNTH)}${suffix}`;
          break;
        }
      case 'function':
        {
          acc[key] = 'function() { /* Function removed */ }';
          break;
        }
      case 'object':
        {
          if (errorProp === null) {
            acc[key] = errorProp;
          } else {
            acc[key] = '/* Object, Error, or Array removed */';
          }
          break;
        }
      default:
        break;
    }
    return acc;
  }, {});
}