import { useCallback } from 'react';
import { useSetHubUserSetting } from './useSetHubUserSetting';
import { SEEN_INBOX_INTERCEPT_SETTINGS_KEY } from './useHasSeenInboxIntercept';
export const useSetSeenInboxInterceptSetting = () => {
  const setHubUserSetting = useSetHubUserSetting();
  return useCallback(value => {
    setHubUserSetting({
      settingsKey: SEEN_INBOX_INTERCEPT_SETTINGS_KEY,
      value: value.toString()
    }).catch(e => {
      throw e;
    });
  }, [setHubUserSetting]);
};