import I18n from 'I18n';
class TaxonomyInputUtils {
  mapPropertiesToOptions({
    properties,
    propertyKey,
    showHelp = false,
    disabled = false,
    priorityValues = []
  }) {
    return properties.map(property => {
      const option = {
        text: I18n.text(`app.taxonomy.property.${propertyKey}.options.text.${property}`),
        value: property,
        disabled
      };
      if (showHelp) option.help = I18n.text(`app.taxonomy.property.${propertyKey}.options.help.${property}`);
      return option;
    }).sortBy(({
      value
    }) => priorityValues.includes(value) ? -1 : 0).toArray();
  }
}
export default new TaxonomyInputUtils();