import getIn from 'transmute/getIn';
export const getCallBody = getIn(['callBody']);
export const getCallDisposition = getIn(['callDisposition']);
export const getCallSummary = getIn(['callSummary']);
export const getCallSummaryExecutionId = getIn(['callSummaryExecutionId']);
export const getCallType = getIn(['activityType']);
export const getCRMObjectId = getIn(['crmObjectId']);
export const getDuration = getIn(['callDuration']);
export const getHasRecording = getIn(['hasRecording']);
export const getHasTranscript = getIn(['hasTranscript']);
export const getIsRecordingDeleted = getIn(['isRecordingDeleted']);
export const getRecordingUrl = getIn(['recordingUrl']);
export const getSuggestedNextBestActions = getIn(['suggestedNextActions']);
export const getTimestamp = getIn(['timestamp']);
export const getTranscriptReady = getIn(['transcriptReady']);
export const getIsCRMObjectDeleted = callMessageResult => getIn(['callBody', 'category'], callMessageResult) === 'OBJECT_NOT_FOUND';
export const isResultSuccessful = callMessageResult => getIn(['@result'], callMessageResult) === 'OK';
export const getResult = callMessageResult => isResultSuccessful(callMessageResult) && getIn(['@ok'], callMessageResult);
export const getHasVoicemail = callMessage => {
  const voicemailResult = getIn(['hasVoicemail'], callMessage);
  return getResult(voicemailResult) === true;
};
export const getShouldRefetchCallMessage = callMessageResult => {
  let shouldRefetch = false;
  if (!isResultSuccessful(callMessageResult)) {
    shouldRefetch = true;
  }
  if (isResultSuccessful(callMessageResult)) {
    const hasRecording = getHasRecording(callMessageResult);
    const hasTranscript = getHasTranscript(callMessageResult);
    if (getResult(hasRecording)) {
      const recordingUrl = getRecordingUrl(callMessageResult);
      shouldRefetch = Boolean(getResult(recordingUrl)) === false;
    }
    if (!shouldRefetch && getResult(hasTranscript)) {
      const transcriptReady = getTranscriptReady(callMessageResult);
      shouldRefetch = Boolean(getResult(transcriptReady)) === false;
    }
  }
  return shouldRefetch;
};
export const getIsCallInProgress = callMessageResult => {
  const callDuration = getResult(getDuration(callMessageResult));
  return callDuration === null;
};