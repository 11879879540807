import { useQuery } from '@apollo/client';
import { CART_TYPE_ID, ORDER_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import OBJECT_QUERY from '../graphql/queries/objectQuery';
const useObjectQuery = ({
  objectTypeId,
  objectId,
  objectProperties,
  contactProperties
}) => useQuery(OBJECT_QUERY, {
  variables: {
    objectTypeId,
    objectId,
    objectProperties,
    contactProperties
  }
});
export const useOrderObjectQuery = ({
  objectId
}) => useObjectQuery({
  objectId,
  objectTypeId: ORDER_TYPE_ID,
  objectProperties: ['hs_external_order_id', 'hs_external_created_date', 'hs_external_order_status', 'hs_shipping_address_phone', 'hs_order_name', 'hs_pipeline', 'hs_pipeline_stage', 'hs_billing_address_firstname', 'hs_billing_address_lastname', 'hs_billing_address_email', 'hs_billing_address_phone', 'hs_billing_address_name'],
  contactProperties: ['phone', 'email']
});
export const useCartObjectQuery = ({
  objectId
}) => useObjectQuery({
  objectId,
  objectTypeId: CART_TYPE_ID,
  objectProperties: ['hs_external_cart_id', 'hs_external_created_date', 'hs_cart_name', 'hs_external_status'],
  contactProperties: ['phone', 'email']
});