/* hs-eslint ignored failing-rules */

import { getApolloContext, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { apolloClientInvariant } from '../../../graphql/invariant/apolloClientInvariant';
import { getAllRequiredValues } from '../operators/pipelineGetters';
import { getObjectId } from '../operators/ticketGetters';
import { fetchThreadTickets } from '../queries/fetchThreadTickets';
import { useTicketPipelines } from './useTicketPipelines';
import { useUpdateCrmObjectPropertiesMutation } from './useUpdateCrmObjectPropertiesMutation';
import { getTicketFromQueryResponse } from '../utils/getTicketFromQueryResponse';

/**
 * @deprecated as a part of https://docs.google.com/document/d/1OkpgZk-SkNu226ynQAKkNTMMGah-179NDFrv_eDd_TM/edit?pli=1#
 * the file we should be using now is https://git.hubteam.com/HubSpot/conversations-inbox/blob/master/conversations-inbox-lib/static/js/tickets/fetch/useFetchAssociatedTicket.ts
 *
 * Returns the associated tickets for the given thread, and provides
 * mechanisms to refresh and update the ticket data.
 */
export const useAssociatedTickets = ({
  apolloClient,
  deferred,
  conversationId,
  queryOptions
}) => {
  const apolloClientFromProvider = useContext(getApolloContext()).client;
  apolloClientInvariant(apolloClient || apolloClientFromProvider);
  const {
    pipelines
  } = useTicketPipelines();
  const {
    loading,
    data,
    error,
    refetch
  } = useQuery(fetchThreadTickets, Object.assign({}, queryOptions, {
    variables: {
      conversationId: Number(conversationId),
      propertyNames: getAllRequiredValues(pipelines)
    },
    client: apolloClient || apolloClientFromProvider,
    notifyOnNetworkStatusChange: true,
    skip: deferred || !conversationId || !pipelines
  }));
  const associatedTicket = getTicketFromQueryResponse(data);
  const ticketId = associatedTicket && getObjectId(associatedTicket);
  const {
    updateCrmObjectProperties: updateTicketProperties
  } = useUpdateCrmObjectPropertiesMutation({
    apolloClient,
    objectType: 'TICKET',
    objectId: ticketId || undefined
  });
  return {
    associatedTicket,
    error,
    loading,
    refetchTickets: refetch,
    updateTicketProperties
  };
};