import { getUsageTrackingChannel } from 'conversations-thread-data/usage-tracking/public/trackerChannelIdMapping';
import { COMPOSE, REPLY_EDITOR } from 'conversations-thread-data/usage-tracking/public/types';
class UsageTrackerError extends Error {}
let coreTracker;
let beaconTracker;
let composeEditorTracker;
let trackingContext = {};
const NOOP = () => null;
export const setTracker = (passedTracker, passedTrackingContext) => {
  coreTracker = passedTracker;
  trackingContext = passedTrackingContext || trackingContext;
};
export const setBeaconTracker = passedTracker => {
  beaconTracker = passedTracker;
};
export const getTracker = () => coreTracker;
export const getBeaconTracker = () => beaconTracker;
export const getTrackingContext = () => {
  return trackingContext;
};
export const trackEvent = (event, getTrackerFn = getTracker) => {
  const tracker = getTrackerFn();
  if (!tracker) return;
  if (typeof event !== 'object') {
    window.setTimeout(() => {
      throw new UsageTrackerError(`trackEvent expects an object, got ${typeof event}`);
    }, 0);
  }
  const {
    availableChannels,
    threadId,
    ticketCount,
    originalGenericChannelId
  } = trackingContext;
  const availableChannelIdsAndNames = availableChannels ? availableChannels.map(channel => getUsageTrackingChannel(channel)) : [];
  const eventWithChannel = Object.assign({}, event, {
    properties: Object.assign({}, event.properties, {
      'communicator-type': trackingContext['communicator-type'],
      threadId,
      availableChannels: availableChannelIdsAndNames
    }, originalGenericChannelId ? {
      channel: getUsageTrackingChannel(originalGenericChannelId)
    } : {}, {
      hasAssociatedTicket: Boolean(ticketCount)
    })
  });
  tracker.track(eventWithChannel.key, eventWithChannel.properties);
};
export const getComposeEditorTracker = ({
  genericChannelId
}) => {
  const channel = getUsageTrackingChannel(genericChannelId);
  const tracker = getTracker();
  if (!tracker) {
    return {
      track: NOOP
    };
  }
  if (!composeEditorTracker) {
    composeEditorTracker = getTracker().clone({
      preserveTrackerEvents: true,
      preserveTrackerProperties: false
    });
  }
  const {
    screen = '',
    subscreen = '',
    namespace = ''
  } = tracker.propertiesToSetOnLoad || {};
  composeEditorTracker.setProperties({
    channel,
    screen,
    subscreen,
    namespace,
    'communicator-type': COMPOSE
  });
  return composeEditorTracker;
};
export const getEditorTrackEvent = ({
  isComposer,
  genericChannelId
}) => {
  let trackEventFn;
  if (isComposer) {
    trackEventFn = event => {
      const tracker = getComposeEditorTracker({
        genericChannelId
      });
      if (genericChannelId && tracker && tracker.track) {
        tracker.track(event.key, event.properties);
      }
    };
  } else {
    const tracker = getTracker();
    if (genericChannelId && trackingContext.genericChannelId !== genericChannelId) {
      trackingContext.genericChannelId = genericChannelId;
    }
    trackingContext['communicator-type'] = REPLY_EDITOR;
    setTracker(tracker, trackingContext);
    trackEventFn = trackEvent;
  }
  return trackEventFn;
};