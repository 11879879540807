import { Record, Set as ImmutableSet } from 'immutable';
import DateRange from './DateRange';
class Filters extends Record({
  dateRange: new DateRange(),
  includeChatAndPhoneTickets: false,
  includeOpenTickets: false,
  languages: ImmutableSet(),
  productAreas: ImmutableSet(),
  resolutions: ImmutableSet(),
  ticketOwners: ImmutableSet()
}, 'Filters') {
  count() {
    let count = 0;
    if (!this.dateRange.equals(new DateRange())) {
      ++count;
    }
    if (!this.languages.isEmpty()) {
      ++count;
    }
    if (!this.productAreas.isEmpty()) {
      ++count;
    }
    if (!this.resolutions.isEmpty()) {
      ++count;
    }
    if (!this.ticketOwners.isEmpty()) {
      ++count;
    }
    return count;
  }
  isEmpty() {
    return this.count() === 0;
  }
}
Filters.fromJS = obj => new Filters(Object.assign({}, obj, {
  dateRange: new DateRange(obj.dateRange),
  languages: ImmutableSet(obj.languages),
  productAreas: ImmutableSet(obj.productAreas),
  resolutions: ImmutableSet(obj.resolutions),
  ticketOwners: ImmutableSet(obj.ticketOwners)
}));
export default Filters;