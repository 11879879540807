import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import Immutable from 'immutable';
import InboxNotificationMiddleware from '../middleware/InboxNotificationMiddleware';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Redu... Remove this comment to see the full error message
import NotificationMiddleware from 'ReduxMessenger/middleware/NotificationMiddleware';
import { inboxErrorMiddleware } from '../error-reporting/middleware/inboxErrorMiddleware';
import { setUsageTrackingProperties } from '../usage-tracking/middleware/setUsageTrackingProperties';
import { realtime } from '../pubsub/middleware/realtime';
import { INITIALIZE_PUBSUB } from '../pubsub/constants/asyncActionTypes';
import { triggerCustomViewsCsatSurvey } from '../custom-views/middleware/triggerCustomViewsCsatSurvey';
const getCompose = () => {
  const shouldEnableDevTools = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  if (shouldEnableDevTools) {
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      maxAge: 200,
      serialize: {
        options: {
          circular: '[CIRCULAR]'
        },
        immutable: Immutable
      },
      // Ably's client is quite large and serializing it slows down redux devtools
      actionSanitizer: action => {
        if (action.type === INITIALIZE_PUBSUB.STARTED) {
          return Object.assign({}, action, {
            payload: {
              client: '<< PUBSUB CLIENT >>'
            }
          });
        }
        return action;
      },
      stateSanitizer: state => {
        if (state.pubSubClient.data) {
          return Object.assign({}, state, {
            pubSubClient: state.pubSubClient.set('data', '<< PUBSUB CLIENT >>')
          });
        }
        return state;
      }
    });
  }
  return compose;
};
export default function configureStore(initialState, rootReducer) {
  const middleware = [thunk, inboxErrorMiddleware, triggerCustomViewsCsatSurvey, InboxNotificationMiddleware, NotificationMiddleware, realtime];
  const composeEnhancers = getCompose();
  const createStoreWithMiddleware = composeEnhancers(applyMiddleware(...middleware, setUsageTrackingProperties))(createStore);
  return createStoreWithMiddleware(rootReducer, initialState);
}