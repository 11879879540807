// gimme: conversations-inbox-ui 06/05/2020 c649276db62b7e955c6e3a2ae36dc769071a71f9
'use es6';

import I18n from 'I18n';
import { createDangerNotification } from 'ReduxMessenger/utils';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { CMF_EMAIL_MESSAGE_FETCH } from 'conversations-thread-data/email-common-message-format/public/constants/asyncActionTypes';
import { fetchEmail } from '../client/fetchEmail';
import { buildCommonMessage } from 'conversations-message-history/common-message-format/operators/buildCommonMessage';
import { buildRequestErrorMetaObject } from 'conversations-error-reporting/error-actions/builders/buildRequestErrorMetaObject';
export const fetchMessageById = createAsyncAction({
  requestFn: fetchEmail,
  actionTypes: CMF_EMAIL_MESSAGE_FETCH,
  toRecordFn: message => buildCommonMessage(message),
  failureMetaActionCreator: ({
    error
  }) => Object.assign({}, buildRequestErrorMetaObject({
    error,
    ignoreStatusCodes: [404]
  }), {
    notification: createDangerNotification(I18n.text('conversations-thread-view.alerts.CMF_EMAIL_MESSAGE_FETCH_FAILED.title'))
  })
});