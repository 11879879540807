import { createMessengeConnectionMap } from '../builders/createMessengerConnectionMap';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { silenceErrorAlert } from 'conversations-error-reporting/error-actions/builders/silenceErrorAlert';
import { fetchMessengerConnections } from '../clients/MessengerConnectionApi';
import { GetMessengerConnectionsActionTypes } from '../action-types/MessengerConnectionsActionTypes';
export const getMessengerConnections = createAsyncAction({
  actionTypes: GetMessengerConnectionsActionTypes,
  requestFn: fetchMessengerConnections,
  toRecordFn: createMessengeConnectionMap,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type '{}'.
  failureMetaActionCreator: ({
    error
  }) => silenceErrorAlert({
    error
  })
});