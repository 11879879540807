import { List as ImmutableList } from 'immutable';
import get from 'transmute/get';
import { MENTIONS } from 'conversations-message-history/comment-message/constants/attachmentTypes';
const getMentionAttachment = get('userIds');
export const getMentionUserIds = message => {
  const attachments = get('attachments', message);
  if (attachments) {
    const mentionAttachment = attachments.find(attachmentObject => Boolean(attachmentObject && get('@type', attachmentObject) === MENTIONS));
    const mentionUserIds = getMentionAttachment(mentionAttachment);
    return ImmutableList(mentionUserIds);
  }
  return ImmutableList();
};