import { OrderedMap, Map as ImmutableMap } from 'immutable';
import { fetch } from '../utils/apolloClient';
import { FETCH_UNIVERSAL_ENGAGEMENT_ASSOCIATIONS } from './fetchUniversalEngagementAssociations';
import { universalAssociationOptionComparator, parseUniversalEngagementAssociations
// @ts-expect-error module not typed
} from 'universal-associations-select/helpers/parseUniversalEngagementAssociations';
import { ObjectTypeFromIds } from 'customer-data-objects/constants/ObjectTypeIds';
import { TICKET } from 'customer-data-objects/constants/ObjectTypes';
export const getAssociations = ({
  objectTypeId,
  objectId
}) => {
  const variables = {
    isTicket: ObjectTypeFromIds[objectTypeId] === TICKET,
    subjectObjectId: objectId,
    subjectObjectTypeId: objectTypeId
  };
  return fetch({
    query: FETCH_UNIVERSAL_ENGAGEMENT_ASSOCIATIONS,
    partialRefetch: true,
    variables
  }).then(result => {
    const {
      data
    } = result;
    const parsedRecords = data && parseUniversalEngagementAssociations({
      data,
      variables
    });
    let parsedAssociations = parsedRecords && parsedRecords.size ? parsedRecords : OrderedMap();
    parsedAssociations = parsedAssociations.map(record => {
      const defaultOptions = record.get('associationOptionRecords');
      const sortedDefaults = defaultOptions.sort(universalAssociationOptionComparator);
      return record.set('associationOptionRecords', sortedDefaults);
    });
    return ImmutableMap({
      associations: parsedAssociations
    });
  });
};