import { fromJS } from 'immutable';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { fetchFbmInstalls } from '../clients/fetchFbmInstall';
import { FETCH_FBM_INSTALL } from '../constants/asyncActionTypes';
import { buildRequestErrorMetaObject } from 'conversations-error-reporting/error-actions/builders/buildRequestErrorMetaObject';
export const getFbmInstall = createAsyncAction({
  requestFn: fetchFbmInstalls,
  actionTypes: FETCH_FBM_INSTALL,
  toRecordFn: fromJS,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type '{}'.
  failureMetaActionCreator: ({
    error
  }) => buildRequestErrorMetaObject({
    error
  })
});