import { useIndexedAsyncHydratedEmailAddresses } from 'composer-data/email-metadata/public/hooks';
import { VISITOR } from 'conversations-message-history/common-message-format/constants/legacySenderTypes';
import { HS_EMAIL_ADDRESS } from 'conversations-message-history/common-message-format/constants/deliveryIdentifierTypes';
import { convertRecipientToType, getDeliveryIdentifiers, getRecipients } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getAllRecipients } from 'conversations-thread-data/common-reply-metadata/public/operators/getAllRecipients';
import { getRecipientsFields } from 'conversations-thread-data/channel-configuration/public/operators';
import { ChannelCapabilitiesConfigContext } from 'conversations-thread-view/channel-capabilities/context/ChannelCapabilitiesConfigContext';
import { Set as ImmutableSet } from 'immutable';
import { useContext, useMemo } from 'react';
import { getAllGenericRecipients } from '../operators/getAllGenericRecipients';
import { useContactsByVid } from 'composer-data/contacts/public/hooks';
export function useAllRecipients({
  commonMessage,
  useWhatsAppCompose
}) {
  const {
    capabilitiesBlob
  } = useContext(ChannelCapabilitiesConfigContext);
  const recipientsFields = useMemo(() => getRecipientsFields(capabilitiesBlob) || [], [capabilitiesBlob]);
  const recipients = getRecipients(commonMessage);
  const deliveryIdentifiers = getDeliveryIdentifiers(recipients, HS_EMAIL_ADDRESS);
  const {
    indexedAsyncEmailAddresses
  } = useIndexedAsyncHydratedEmailAddresses({
    emailAddresses: ImmutableSet(deliveryIdentifiers),
    deferred: useWhatsAppCompose
  });
  const contactIds = recipients.map(recipient => {
    const {
      actorType,
      actorId
    } = convertRecipientToType(recipient);
    return actorType === VISITOR && actorId && parseInt(actorId, 10);
  }).filter(Boolean).toJS();
  const {
    contacts
  } = useContactsByVid({
    vids: contactIds,
    deferred: !useWhatsAppCompose
  });
  const allRecipients = useMemo(() => useWhatsAppCompose ? getAllGenericRecipients(recipientsFields, commonMessage, contacts) : getAllRecipients(recipientsFields, commonMessage, indexedAsyncEmailAddresses), [useWhatsAppCompose, recipientsFields, commonMessage, indexedAsyncEmailAddresses, contacts]);
  return {
    allRecipients
  };
}