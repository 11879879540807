import { Record } from 'immutable';
class ActionStates extends Record({
  couldNotFindTicket: false,
  hasErroredLoadingTicket: false,
  hasErroredLoadingTicketExternalProperties: false,
  isClosingConversation: false,
  isClosingTicket: false,
  isEditingSubject: false,
  isLoadingTicket: false,
  isLoadingTicketExternalProperties: false,
  isSavingProductArea: false,
  isSavingResolution: false,
  isSavingRoadblock: false,
  isSavingSubject: false
}) {
  get isLoading() {
    return this.isLoadingTicket || this.isLoadingTicketExternalProperties;
  }
  get hasErroredLoading() {
    return this.hasErroredLoadingTicket || this.hasErroredLoadingTicketExternalProperties;
  }
  get isSaving() {
    return this.isSavingProductArea || this.isSavingResolution || this.isSavingRoadblock || this.isSavingSubject;
  }
}
export default ActionStates;