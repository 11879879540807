'use es6';

import { Record } from 'immutable';
import UnassignedRoutingRules from '../../routing-rules/records/UnassignedRoutingRules';
import { DISTRIBUTIONS } from '../../routing-rules/constants/distributions';
export default Record({
  agentId: null,
  channelInstanceId: null,
  connected: null,
  createdAt: null,
  deletedAt: null,
  displayGlobally: null,
  email: null,
  emailAccountType: null,
  friendlyFromName: null,
  id: null,
  inboxId: null,
  includeAgentInFromName: null,
  isActive: null,
  isDefault: null,
  portalId: null,
  resolvedFromName: null,
  routingRules: new UnassignedRoutingRules({
    rotationMethod: DISTRIBUTIONS.RANDOM
  }),
  sendFromEmail: null,
  forwardDetectionSetting: {
    settingName: 'FORWARDING_DETECTION_SETTING',
    settingValue: true
  },
  ticketRulesId: null,
  ticketRulesInput: null
}, 'ConnectedEmailAccount');