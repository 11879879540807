'use es6';

import { Record } from 'immutable';
import get from 'transmute/get';
import set from 'transmute/set';
const MESSAGE_WAS_SENT = 'messageWasSent';
const PROMPT_SHOWN_IN_SESSION = 'promptShownInSession';
const SessionData = Record({
  [MESSAGE_WAS_SENT]: false,
  [PROMPT_SHOWN_IN_SESSION]: false
}, 'SessionData');
export const getMessageWasSent = get(MESSAGE_WAS_SENT);
export const getPromptShownInSession = get(PROMPT_SHOWN_IN_SESSION);
export const setMessageWasSent = set(MESSAGE_WAS_SENT);
export const setPromptShownInSession = set(PROMPT_SHOWN_IN_SESSION);
export default SessionData;