import { threadsUpdated } from 'find-and-filter-data/view-member-optimistic-updates/public';
import { batch } from 'react-redux';
import { selectIsMessageAlreadyApplied } from '../latest-omnibus-timestamp/selectIsMessageAlreadyApplied';
import { viewMemberUpdated } from './viewMemberUpdated';
const getActionsForMessageObj = ({
  currentAgentId,
  message,
  sortState
}) => {
  switch (message['@type']) {
    case 'THREADS_UPDATED':
      {
        return [threadsUpdated({
          message,
          currentAgentId
        })];
      }
    case 'VIEW_UPDATE':
      {
        return [viewMemberUpdated({
          message,
          currentAgentId,
          sortState
        })];
      }
    default:
      return [];
  }
};

/**
 * @description Handles a single view message over a live channel
 */
export const handleViewChannelMessage = ({
  currentAgentId,
  message,
  sortState
}) => dispatch => {
  getActionsForMessageObj({
    currentAgentId,
    message: message.data,
    sortState
  }).forEach(action => dispatch(action));
};

/**
 * @description Handles a batch of playback view messages by bundling the
 * handler actions into a single batch action.
 * @param {Array<Object>} messages
 */
export const handleViewChannelMessages = ({
  currentAgentId,
  messages,
  sortState
}) => (dispatch, getState) => {
  const actions = messages.filter(({
    timestamp
  }) => selectIsMessageAlreadyApplied(getState(), {
    timestamp
  }) === false).flatMap(message => getActionsForMessageObj({
    currentAgentId,
    message: message.data,
    sortState
  }));
  batch(() => {
    actions.forEach(action => dispatch(action));
  });
};