import { List, Record } from 'immutable';
import TypicalResponseTime from '../../typical-response-time/records/TypicalResponseTime';
import OfficeHour from '../../office-hour/records/OfficeHour';
import buildDefaultOfficeHours from '../../office-hour/factories/buildDefaultOfficeHours';
import { OFFICE_HOURS } from '../constants/AvailabilityStrategies';
import { AWAY_MESSAGE, SHOW_NEXT_OFFICE_HOURS_TIME } from '../constants/AwayStrategies';
class OfficeHoursConfig extends Record({
  '@type': OFFICE_HOURS,
  officeHours: buildDefaultOfficeHours(),
  awayMessage: null,
  awayInOfficeHoursStrategy: AWAY_MESSAGE,
  outsideOfficeHoursMessage: null,
  outsideOfficeHoursStrategy: SHOW_NEXT_OFFICE_HOURS_TIME,
  typicalResponseTime: TypicalResponseTime()
}, 'OfficeHoursConfig') {
  constructor(properties = {}) {
    properties = properties || {};
    super(Object.assign({}, properties, {
      typicalResponseTime: properties.typicalResponseTime ? TypicalResponseTime(properties.typicalResponseTime) : null,
      officeHours: properties.officeHours ? List(properties.officeHours.map(OfficeHour)) : buildDefaultOfficeHours()
    }));
  }
}
export default OfficeHoursConfig;