module.exports = {
  "inlineEditInteraction": {
    "name": "inline edit interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "text-selected",
          "menu-open",
          "menu-selection",
          "tiny-mce-toolbar-menu-selection",
          "tone-selection",
          "click-generate",
          "click-try-again",
          "preview-window-rewrite",
          "insert-content",
          "preview-window-close",
          "preview-window-previous",
          "preview-window-next",
          "click-settings-link"
        ]
      },
      "actionType": {
        "type": [
          "slash-command",
          "selection-command"
        ]
      },
      "selected": {
        "isOptional": true,
        "type": "string"
      },
      "choice": {
        "isOptional": true,
        "type": [
          "friendly",
          "professional",
          "witty",
          "heartfelt",
          "educational"
        ]
      },
      "command": {
        "type": "string",
        "isOptional": true
      },
      "text": {
        "type": "string",
        "isOptional": true
      },
      "result": {
        "type": "string",
        "isOptional": true
      },
      "usageDescription": {
        "type": "string",
        "isOptional": true
      },
      "inline": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      },
      "regenerateClickCount": {
        "type": "number",
        "isOptional": true
      },
      "usingBrandVoice": {
        "type": "boolean",
        "isOptional": true
      },
      "userEditedResult": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "content-assistance-lib"
  },
  "contentGenerationModalInteraction": {
    "name": "content generation modal interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "open-modal",
          "close-modal",
          "click-generate",
          "click-generate-more",
          "click-trigger-button",
          "fetch-results",
          "choose-result",
          "video-started",
          "video-completed",
          "turn-on-content-assistant",
          "click-maybe-later",
          "click-settings-link",
          "click-go-back"
        ]
      },
      "usageDescription": {
        "type": "string"
      },
      "text": {
        "type": "string",
        "isOptional": true
      },
      "approximateTokenCount": {
        "type": "number",
        "isOptional": true
      },
      "results": {
        "type": "array",
        "isOptional": true
      },
      "completionTokens": {
        "type": "number",
        "isOptional": true
      },
      "promptTokens": {
        "type": "number",
        "isOptional": true
      },
      "result": {
        "type": "string",
        "isOptional": true
      },
      "videoId": {
        "type": "number",
        "isOptional": true
      },
      "command": {
        "type": "string",
        "isOptional": true
      },
      "choice": {
        "isOptional": true,
        "type": "string"
      },
      "regenerateClickCount": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "content-assistance-lib"
  },
  "aiButtonInteraction": {
    "name": "aiButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "featureId": {
        "type": "string",
        "isOptional": true
      },
      "featureCategoryId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copilotActionButtonInteraction": {
    "name": "copilotActionButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copyMessageInteraction": {
    "name": "inlineFeedbackInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_copied"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "feedbackInteraction": {
    "name": "inlineFeedbackInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_upvoted",
          "prompt_upvote_removed",
          "prompt_downvoted",
          "prompt_downvote_removed"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copilotActionButtonPageView": {
    "name": "aiComponentsView",
    "class": "view",
    "properties": {
      "action": [
        "page_view"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "toggled-away-mode": {
    "class": "interaction",
    "name": "interaction",
    "properties": {
      "action": [
        "toggled away mode"
      ],
      "awayMode": [
        "on",
        "off"
      ],
      "trigger": [
        "manual",
        "workingHours",
        "outOfOffice"
      ]
    },
    "namespace": "routing"
  },
  "clicked-agent-status-prompt-modal": {
    "class": "interaction",
    "name": "interaction",
    "properties": {
      "action": [
        "clicked on \"Set myself to available\" button",
        "clicked on \"Cancel\" button"
      ]
    },
    "namespace": "routing"
  },
  "applied-filter": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "applied filter"
      ],
      "filter-type": "string",
      "filter-value": "string",
      "filter-count": "number",
      "filter-results-count": "number",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "changed-inbox": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "changed inbox"
      ],
      "inbox-name": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "changed-view": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "changed view"
      ],
      "view": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "cleared-filter": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "cleared filter"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "click-to-change-inbox": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "click to change inbox"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-alert-banner-cta": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "clicked alert banner cta"
      ],
      "cta-action": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-assignee-with-search": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "clicked assignee with search"
      ],
      "search-term": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "checked-assignee-deactivated-checkbox": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "checked deactivated user assignee checkbox"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-contact-with-search": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "clicked contact with search"
      ],
      "search-term": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-blank-slate-cta": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "clicked blank slate cta"
      ],
      "cta-action": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-inbox-settings": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "clicked inbox settings"
      ],
      "setting": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-modal-cta": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": "string",
      "cta-action": "string",
      "subscreen2": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-search": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "clicked search"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "enter-key-pressed-in-search": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "Enter Key pressed in search"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-to-filter": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "clicked to filter"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "collapsable-sidebar": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "toggled sidebar collapse"
      ],
      "sidebarType": [
        "contact sidebar",
        "inbox view sidebar"
      ],
      "value": [
        "collapsed",
        "expanded"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "load-more-threads": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "load more threads"
      ],
      "conversation-count": "number",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "opened-thread": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "opened thread"
      ],
      "index": "number",
      "isFiltering": "boolean",
      "isSearching": "boolean",
      "hasAttachedSLA": "boolean",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "searched": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "searched"
      ],
      "isEmailSearch": "boolean",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-fallback": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "clicked fallback"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "reply-editor-resized": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "reply editor resized"
      ],
      "windowHeight": "number",
      "newHeight": "number",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-exit-search-button": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "exit search"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-status-toggle": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "clicked status toggle"
      ],
      "status": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "sorted": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "sorted"
      ],
      "sort-order": "string",
      "sort-by": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "view-record": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "view record"
      ],
      "record-type": [
        "contact",
        "ticket",
        "deal",
        "company",
        "custom"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-refresh-inbox-button": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "clicked refresh inbox button"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "closed-refresh-inbox-prompt": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "closed refresh inbox banner"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "show-refresh-inbox-prompt": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "show refresh inbox banner"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "changed-custom-view-order": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "custom view order changed via drag and drop"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "editReplyRecommendation": {
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "Clicks to edit reply recommendation"
      ],
      "inResponseToMessageId": {
        "type": "string"
      }
    }
  },
  "renderReplyRecommendation": {
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "Systems displays reply recommendation"
      ],
      "inResponseToMessageId": {
        "type": "string"
      }
    }
  },
  "composerSettingsMenuOpened": {
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "Composer settings menu opened"
      ]
    }
  },
  "aiAssistantsSettingsLinkClicked": {
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "AI Assistants settings link clicked"
      ]
    }
  },
  "replyRecsSettingsLinkClicked": {
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "Reply Recommendations settings link clicked"
      ]
    }
  },
  "viewReplyRecommendationSource": {
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "View reply recommendation source"
      ]
    }
  },
  "copyReplyRecommendationSource": {
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "Copy reply recommendation source"
      ]
    }
  },
  "openReplyRecommendationSource": {
    "namespace": "help-desk-app",
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "Open reply recommendation source"
      ]
    }
  },
  "openContentAssistantMenu": {
    "name": "inline edit interaction",
    "class": "interaction",
    "namespace": "content-assistance-lib",
    "properties": {
      "action": {
        "type": [
          "menu-open"
        ]
      },
      "actionType": {
        "type": [
          "selection-command",
          "slash-command",
          "highlight-icon"
        ]
      },
      "inApp": [
        "Inbox"
      ],
      "version": {
        "isOptional": true,
        "type": "string"
      }
    }
  },
  "selectContentAssistantOption": {
    "name": "inline edit interaction",
    "class": "interaction",
    "namespace": "content-assistance-lib",
    "properties": {
      "action": {
        "type": [
          "menu-selection"
        ]
      },
      "actionType": {
        "type": [
          "selection-command",
          "slash-command",
          "highlight-icon"
        ]
      },
      "choice": {
        "isOptional": true,
        "type": "string"
      },
      "command": {
        "isOptional": true,
        "type": "string"
      },
      "inApp": [
        "Inbox"
      ],
      "selected": {
        "type": "string"
      },
      "version": {
        "isOptional": true,
        "type": "string"
      }
    }
  },
  "insert-knowledge-article": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "insert knowledge base article from popover"
      ],
      "channel": "string",
      "editorType": {
        "type": [
          "fullscreen",
          "tray"
        ],
        "isOptional": true
      },
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "insert-video": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "insert video from popover"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-to-open-insert-popover": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "clicked to open insert popover"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-to-open-link-popover": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "model": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "clicked to open link popover"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "insert-link": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "model": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "insert link from popover"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-to-send-email": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "model": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "clicked to send email"
      ],
      "email-type": [
        "send now"
      ],
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-to-toggle-previous-message-history": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "clicked to toggle previous message history"
      ],
      "direction": "string",
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "communicator-type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "search-all-knowledge-articles": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "Search all knowledge articles"
      ],
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "search-knowledge-base": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "Search specific knowledge base"
      ],
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "navigate-knowledge-base": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "Click into specific knowledge base"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "communicator-type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "knowledge-base-manager": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "Clicked manage button"
      ],
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "summary-requested": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "Clicked AI summary button"
      ],
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "summary-generated": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "AI summary generated"
      ],
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "attachment-insert-failed": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "attachment insert failed"
      ],
      "channel": "string",
      "type": "string",
      "size": "number",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-attachment-button": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "clicked attachment button"
      ],
      "channel": "string",
      "editorType": {
        "type": [
          "fullscreen",
          "tray"
        ],
        "isOptional": true
      },
      "communicator-type": [
        "reply-editor",
        "compose"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clipboard": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "copied text",
        "cut text",
        "pasted text"
      ],
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "insert-attachment": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "insert attachment"
      ],
      "channel": "string",
      "extension": "string",
      "size": "number",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "insert-document": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "insert document from popover"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "insert-meeting": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "insert meeting from popover"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "insert-quote": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "insert quote from popover"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "insert-snippet": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "insert snippet",
        "insert snippet from popover"
      ],
      "channel": "string",
      "editorType": {
        "type": [
          "fullscreen",
          "tray"
        ],
        "isOptional": true
      },
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "insert-template": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "insert template from popover"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "insert-image": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "insert inline image"
      ],
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "drag-and-drop-file": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-expand-more-button": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "clicked expand more button"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "model": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "to-recipient-added": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "to recipient added"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "model": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "to-recipient-removed": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "to recipient removed"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "model": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "all-to-recipients-removed": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "All to recipients removed"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "model": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "cc-recipient-added": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "cc recipient added"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "model": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "cc-recipient-removed": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "cc recipient removed"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "model": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "all-cc-recipients-removed": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "All cc recipients removed"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "model": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "bcc-recipient-added": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "bcc recipient added"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "model": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "bcc-recipient-removed": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "bcc recipient removed"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "model": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "all-bcc-recipients-removed": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "All bcc recipients removed"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "model": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "from-address-changed": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "From address changed"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "model": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "search-for-recipients": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "Search for to recipients"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "model": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "wa-banner-interaction": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "view full conversation",
        "view full conversation within 24hr",
        "view full conversation after 24hr",
        "Start new conversation within 24hr"
      ],
      "channel": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "model": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "subject-changed": {
    "class": "interaction",
    "name": "communicator-interaction",
    "properties": {
      "action": [
        "Subject changed"
      ],
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "model": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "did-show-shepherd": {
    "class": "interaction",
    "name": "did-show-shepherd",
    "properties": {
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "did-show-shepherd"
      ],
      "shepherdName": "string",
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-to-close-shepherd": {
    "class": "interaction",
    "name": "clicked-to-close-shepherd",
    "properties": {
      "action": [
        "clicked-to-close-shepherd"
      ],
      "shepherdName": "string",
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-modal-cta-from-thread-view": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": "string",
      "cta-action": "string",
      "subscreen2": "string",
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "onboarding-step-from-thread-view": {
    "class": "interaction",
    "name": "pageview",
    "properties": {
      "action": "string",
      "step-name": "string",
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-fbm-grant-permissions": {
    "class": "interaction",
    "name": "manage-settings",
    "properties": {
      "action": [
        "clicked fbm grant permissions"
      ],
      "connected": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "fbm-grant-permissions": {
    "class": "interaction",
    "name": "manage-settings",
    "properties": {
      "action": [
        "fbm grant permissions"
      ],
      "state": [
        "opted-in",
        "not now"
      ],
      "connected": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "conversations-onboarding-prompt": {
    "class": "interaction",
    "name": "manage-settings",
    "properties": {
      "action": "string",
      "role": [
        "admin",
        "rep"
      ],
      "type": [
        "EMAIL_SIGNATURE",
        "CONVERSATIONS_REPORTS"
      ],
      "connected": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "delete-comment": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "delete comment"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "added-comment": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "added comment"
      ],
      "at-mentions-count": "number",
      "channel": "string",
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "reply-and-close-clicked": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "reply and close"
      ],
      "channel": "string",
      "ticket associated": "boolean",
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "opened-owner-dropdown": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "owner dropdown opened"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "searched-owner-dropdown": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "owner dropdown search used"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "assigned-thread": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "assigned thread"
      ],
      "assignee": [
        "assigned",
        "unassigned",
        "self-assigned",
        "team-assigned"
      ],
      "channel": "string",
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "click-to-assign": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "click to assign"
      ],
      "channel": "string",
      "current-assignee": [
        "assigned",
        "unassigned",
        "self-assigned"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "sent-message": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "sent message"
      ],
      "timeToReply": {
        "type": "number"
      },
      "channel": "string",
      "messageChannel": "number",
      "communicator-type": [
        "compose",
        "floating-reply",
        "mobile-editor",
        "reply-editor"
      ],
      "reply-type": {
        "isOptional": true,
        "type": [
          "forward",
          "forward-email-reply"
        ]
      },
      "numberOfTicketsAssociated": "number",
      "source": {
        "type": "string",
        "isOptional": true
      },
      "editorType": {
        "type": [
          "fullscreen",
          "tray"
        ],
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "sent-message-chat-activation-user": {
    "class": "activation",
    "name": "chat-user-activation",
    "properties": {
      "action": [
        "sent message"
      ],
      "communicator-type": [
        "compose",
        "floating-reply",
        "mobile-editor",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "sent-message-fbm-activation-user": {
    "class": "activation",
    "name": "fbm-user-activation",
    "properties": {
      "action": [
        "sent message"
      ],
      "communicator-type": [
        "compose",
        "floating-reply",
        "mobile-editor",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "sent-message-whatsapp-activation-user": {
    "class": "activation",
    "name": "whatsapp-user-activation",
    "properties": {
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "sent message"
      ],
      "communicator-type": [
        "compose",
        "floating-reply",
        "mobile-editor",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "sent-message-chat-activation-portal": {
    "class": "activation",
    "name": "chat-portal-activation",
    "properties": {
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "sent message"
      ],
      "userId": "number",
      "communicator-type": [
        "compose",
        "floating-reply",
        "mobile-editor",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "sent-message-fbm-activation-portal": {
    "class": "activation",
    "name": "fbm-portal-activation",
    "properties": {
      "action": [
        "sent message"
      ],
      "userId": "number",
      "communicator-type": [
        "compose",
        "floating-reply",
        "mobile-editor",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "sent-message-whatsapp-activation-portal": {
    "class": "activation",
    "name": "whatsapp-portal-activation",
    "properties": {
      "action": [
        "sent message"
      ],
      "userId": "number",
      "communicator-type": [
        "compose",
        "floating-reply",
        "mobile-editor",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "communicator": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": "string",
      "channel": "string",
      "type": "string",
      "communicator-type": [
        "compose",
        "reply-editor"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "associated-crm-object-in-communicator": {
    "class": "interaction",
    "name": "crm-interaction",
    "properties": {
      "action": [
        "associated crm object"
      ],
      "association-type": [
        "TICKET",
        "DEAL",
        "COMPANY",
        "CONTACT"
      ],
      "channel": "string",
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "unassociated-crm-object-in-communicator": {
    "class": "interaction",
    "name": "crm-interaction",
    "properties": {
      "action": [
        "unassociated crm object"
      ],
      "association-type": [
        "TICKET",
        "DEAL",
        "COMPANY",
        "CONTACT"
      ],
      "channel": "string",
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-move-to-inbox": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "clicked to move conversations"
      ],
      "channel": "string",
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-move-to-inbox-cancel-button": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "clicked the cancel button to move conversation to another inbox"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-move-to-inbox-confirm-button": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "clicked the confirm button to move conversation to another inbox"
      ],
      "type": [
        "KEEP_ASSIGNEE",
        "INBOX_USERS",
        "UNASSIGNED",
        "USERS_AND_TEAMS",
        "OWNER"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-move-to-inbox-assign-to-dropdown": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "clicked on the assign to dropdown"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-move-to-inbox-assign-to-dropdown-option": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "clicked on one of the assign to dropdown options"
      ],
      "type": [
        "KEEP_ASSIGNEE",
        "INBOX_USERS",
        "UNASSIGNED",
        "USERS_AND_TEAMS",
        "OWNER"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-move-to-inbox-comment-input": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "clicked on the optional comment text input"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "move-to-inbox-added-comment": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "added a comment to the conversation handoff"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "updated-conversation": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "updated conversation"
      ],
      "channel": "string",
      "update-type": "string",
      "location": {
        "isOptional": true,
        "type": [
          "thread view",
          "thread list"
        ]
      },
      "association": {
        "isOptional": true,
        "type": [
          "ticket",
          "none"
        ]
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-to-view-ticket-record": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "Clicked to View Ticket Record"
      ],
      "source": [
        "Thread Header",
        "Thread Body"
      ],
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "updated-ticket-pipeline-from-thread-view": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "updated ticket pipeline from thread view"
      ],
      "pipeline": "string",
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "updated-ticket-stage-from-thread-view": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "updated ticket stage from thread view"
      ],
      "stage": "string",
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "closed-ticket-from-thread-view": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "closed ticket from thread view"
      ],
      "stage": "string",
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "channel-switched": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "action": [
        "Channel switched"
      ],
      "currentChannel": "string",
      "switchedChannel": "string",
      "os": {
        "type": "string",
        "isOptional": true
      },
      "nextChannel": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "threadId": {
        "type": "string",
        "isOptional": true
      },
      "availableChannels": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "hasAssociatedTicket": {
        "type": "string",
        "isOptional": true
      },
      "access": {
        "type": "string",
        "isOptional": true
      },
      "admin": {
        "type": "string",
        "isOptional": true
      },
      "hostAppName": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "fileManagerLib": {
    "name": "File Manager Lib",
    "class": "usage",
    "properties": {
      "componentName": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerSetPanelExploreFiles": {
    "name": "Explore Files",
    "class": "interaction",
    "properties": {
      "panel": "string",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerExploreFiles": {
    "name": "Explore Files",
    "class": "interaction",
    "namespace": "file-picker",
    "version": "v2",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "fileManagerImageOptimization": {
    "name": "Change image optimization setting",
    "class": "interaction",
    "properties": {
      "setting": "string",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerManageFiles": {
    "name": "Manage Files",
    "class": "interaction",
    "properties": {
      "count": {
        "type": "number",
        "isOptional": true
      },
      "fileSource": {
        "type": [
          "AI_GENERATED",
          "CANVA",
          "SHUTTERSTOCK"
        ],
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerManageFolders": {
    "name": "Manage Folders",
    "class": "interaction",
    "namespace": "file-picker",
    "version": "v2",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "fileManagerBrowseShutterstock": {
    "name": "Browse Shutterstock",
    "class": "interaction",
    "namespace": "file-picker",
    "version": "v2",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "fileManagerAlert": {
    "name": "Alert interaction",
    "class": "interaction",
    "namespace": "file-picker",
    "version": "v2",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "fileManagerBulkImport": {
    "name": "Bulk Import Images",
    "class": "interaction",
    "properties": {
      "action": "string",
      "imageCount": {
        "type": "number",
        "isOptional": true
      },
      "isImageFile": {
        "type": "boolean",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerLibErrorDisplay": {
    "name": "Error display",
    "class": "view",
    "properties": {
      "type": "string",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerVideoInteraction": {
    "name": "video interaction",
    "class": "interaction",
    "properties": {
      "videoTitle": {
        "type": "string",
        "isOptional": true
      },
      "language": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerUpload": {
    "name": "upload file",
    "class": "interaction",
    "properties": {
      "size": {
        "type": "number",
        "isOptional": true
      },
      "errorType": {
        "type": "string",
        "isOptional": true
      },
      "statusCode": {
        "type": "number",
        "isOptional": true
      },
      "videoDuration": {
        "type": "number",
        "isOptional": true
      },
      "videoHostingProvider": {
        "type": [
          "mux"
        ],
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerUploadDeduplication": {
    "name": "check upload duplicates",
    "class": "interaction",
    "properties": {
      "hasData": {
        "type": "boolean",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "size": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerVideoPQL": {
    "name": "Video PQL",
    "class": "interaction",
    "properties": {
      "alert": {
        "type": "string",
        "isOptional": true
      },
      "limit": {
        "type": "number",
        "isOptional": true
      },
      "quantityRemaining": {
        "type": "number",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerImageGeneration": {
    "name": "image generation",
    "class": "interaction",
    "properties": {
      "errorCount": {
        "type": "number",
        "isOptional": true
      },
      "isFeedbackPositive": {
        "type": "boolean",
        "isOptional": true
      },
      "feedbackText": {
        "type": "string",
        "isOptional": true
      },
      "resolution": {
        "type": "string",
        "isOptional": true
      },
      "style": {
        "type": "string",
        "isOptional": true
      },
      "correlationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "adobeExpressInteraction": {
    "name": "adobe express",
    "class": "interaction",
    "namespace": "file-manager",
    "version": "v2",
    "properties": {
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "count": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "deployableName": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "isEmbedded": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "clicked-move-to-help-desk": {
    "class": "interaction",
    "name": "helpdesk-interaction",
    "properties": {
      "action": [
        "clicked move to help desk"
      ],
      "shouldCreateNewTicket": "boolean",
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "completed-move-to-help-desk": {
    "class": "interaction",
    "name": "helpdesk-interaction",
    "properties": {
      "action": [
        "completed move to help desk"
      ],
      "createdNewTicket": "boolean",
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "view-ticket-record-from-readonly-ticket-card": {
    "class": "interaction",
    "name": "helpdesk-interaction",
    "properties": {
      "action": [
        "view ticket record from readonly ticket card"
      ],
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "manually-created-ticket-in-inbox": {
    "class": "interaction",
    "name": "helpdesk-interaction",
    "properties": {
      "actions": [
        "manually create ticket in inbox"
      ],
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-to-change-ticket-property": {
    "class": "interaction",
    "name": "ticket-toolbar",
    "properties": {
      "action": "string",
      "propertyName": "string",
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "view-sla-config-modal": {
    "class": "interaction",
    "name": "pageview",
    "properties": {
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "viewed configure SLAs modal"
      ],
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "click-set-up-slas": {
    "class": "interaction",
    "name": "pageview",
    "properties": {
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "clicked to configure SLAs"
      ],
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "dismissed-sla-config-modal": {
    "class": "interaction",
    "name": "pageview",
    "properties": {
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "clicked to dismiss the SLA configuration modal"
      ],
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "view-inbox-sla-tour-popover": {
    "class": "interaction",
    "name": "pageview",
    "properties": {
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "viewed Inbox SLA tour"
      ],
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "dismiss-inbox-sla-tour-popover": {
    "class": "interaction",
    "name": "pageview",
    "properties": {
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "clicked to dismiss Inbox SLA tour"
      ],
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-to-view-contact-record": {
    "class": "interaction",
    "name": "detail-header",
    "properties": {
      "action": "string",
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-to-view-company-record": {
    "class": "interaction",
    "name": "detail-header",
    "properties": {
      "action": "string",
      "source": "string",
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-to-copy-ticket-number": {
    "class": "interaction",
    "name": "detail-header",
    "properties": {
      "action": "string",
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-to-toggle-contextual-sidebar": {
    "class": "interaction",
    "name": "detail-header",
    "properties": {
      "action": "string",
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "applied-filters": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "applied filters"
      ],
      "creation-type": [
        "new",
        "update"
      ],
      "group": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "updateType": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "added-view-details": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "added view details"
      ],
      "creation-type": [
        "new",
        "update"
      ],
      "group": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "updateType": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "click-to-create-view": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "clicked create view"
      ],
      "group": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "updateType": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "click-to-edit-view": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "clicked edit view"
      ],
      "group": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "updateType": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "click-to-delete-view": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "clicked delete view"
      ],
      "group": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "updateType": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "view-creation-completed": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "view creation completed"
      ],
      "creation-time": "number",
      "group": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "updateType": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "view-creation-started": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "view creation started"
      ],
      "creation-type": [
        "new",
        "update"
      ],
      "objects-used": "string",
      "view-type": [
        "team view",
        "agent view"
      ],
      "filter-properties": "string",
      "group": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "updateType": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "view-deleted": {
    "class": "interaction",
    "name": "inbox-interaction",
    "properties": {
      "action": [
        "view deleted"
      ],
      "deleted-after-days": "number",
      "group": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "updateType": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-to-connect-channel": {
    "class": "interaction",
    "name": "connect-channel",
    "properties": {
      "action": [
        "clicked to connect channel"
      ],
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-to-connect-fbm": {
    "class": "interaction",
    "name": "connect-chat",
    "properties": {
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "clicked to connect"
      ],
      "channel": [
        "Facebook Messenger"
      ],
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "viewed-alert": {
    "class": "view",
    "name": "viewed alert",
    "properties": {
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "alertType": [
        "tracking code not installed"
      ],
      "action": "string",
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "disconnected-email-account-send": {
    "class": "interaction",
    "name": "thread-interaction",
    "properties": {
      "os": {
        "type": "string",
        "isOptional": true
      },
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "emailSentTo": "string",
      "emailToReconnect": "string",
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "onboarding-step": {
    "class": "interaction",
    "name": "pageview",
    "properties": {
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "action": "string",
      "step-name": "string",
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "initial-pageview": {
    "class": "view",
    "name": "initial-pageview",
    "namespace": "conversations",
    "properties": {
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "initial-threadid-pageview": {
    "class": "view",
    "name": "initial-threadid-pageview",
    "namespace": "conversations",
    "properties": {
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "inbox-zero-state": {
    "class": "view",
    "name": "pageview",
    "properties": {
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "inbox zero state"
      ],
      "zero-state-name": "string",
      "subscreen": "string",
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "opened-inbox-settings": {
    "class": "interaction",
    "properties": {
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "connected": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "opened inbox settings"
      ],
      "setting": "string",
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "name": "manage-settings",
    "namespace": "conversations"
  },
  "bulk-thread-action": {
    "class": "interaction",
    "name": "bulk-thread-action",
    "properties": {
      "action": "string",
      "batchThreadCount": "number",
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-to-connect-email": {
    "class": "interaction",
    "name": "connect-email",
    "properties": {
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "clicked to connect email"
      ],
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "associated-visitor-to-contact": {
    "class": "interaction",
    "name": "crm-interaction",
    "properties": {
      "action": [
        "associated visitor to contact"
      ],
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "created-new-contact": {
    "class": "interaction",
    "name": "crm-interaction",
    "properties": {
      "action": [
        "created new contact"
      ],
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-to-associate-crm-object": {
    "class": "interaction",
    "name": "crm-interaction",
    "properties": {
      "action": [
        "clicked to associate crm object"
      ],
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-to-create-crm-object": {
    "class": "interaction",
    "name": "crm-interaction",
    "properties": {
      "action": [
        "clicked to create crm object"
      ],
      "object-type": [
        "DEAL",
        "TICKET"
      ],
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-view-all": {
    "class": "interaction",
    "name": "sidebar-interaction",
    "properties": {
      "action": [
        "clicked view all"
      ],
      "object": "string",
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-view-more-properties": {
    "class": "interaction",
    "name": "sidebar-interaction",
    "properties": {
      "action": [
        "clicked view more properties"
      ],
      "object": "string",
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "created-crm-object": {
    "class": "interaction",
    "name": "crm-interaction",
    "properties": {
      "action": [
        "created crm object"
      ],
      "object-type": "string",
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "disassociate-thread-ticket": {
    "class": "interaction",
    "name": "sidebar-interaction",
    "properties": {
      "action": [
        "disassociate thread ticket"
      ],
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "edited-contact-record": {
    "class": "interaction",
    "name": "crm-interaction",
    "properties": {
      "action": [
        "edited contact record"
      ],
      "crm-property-edited": "string",
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "edit-property-settings": {
    "class": "interaction",
    "name": "sidebar-interaction",
    "properties": {
      "action": [
        "edit property settings"
      ],
      "objectType": "string",
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "opened-contact-record": {
    "class": "interaction",
    "name": "crm-interaction",
    "properties": {
      "action": [
        "opened contact record"
      ],
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "unassociated-crm-object": {
    "class": "interaction",
    "name": "crm-interaction",
    "properties": {
      "action": [
        "unassociated crm object"
      ],
      "association-type": [
        "TICKET",
        "DEAL",
        "COMPANY",
        "CONTACT"
      ],
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "os": {
        "type": "string",
        "isOptional": true
      },
      "from": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-to-connect-chat": {
    "class": "interaction",
    "name": "connect-chat",
    "properties": {
      "isBeforeUnload": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "clicked to connect"
      ],
      "channel": [
        "Livechat"
      ],
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  }
};