'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import { getId, getRecipientType } from 'conversations-message-history/legacy-recipients/operators/getters';
import { buildRecipientLocator } from 'conversations-message-history/legacy-recipients/operators/buildRecipientLocator';
export const indexRecipients = (recipients = List()) => {
  return recipients.reduce((accumulator, recipient) => {
    const recipientId = getId(recipient);
    const recipientType = getRecipientType(recipient);
    const recipientLocator = buildRecipientLocator(recipientType, recipientId);
    return accumulator.set(recipientLocator, recipient);
  }, ImmutableMap());
};