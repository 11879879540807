import set from 'transmute/set';
import setIn from 'transmute/setIn';
export const setMetadataSubject = set('subject');
export const setMetadataFrom = set('from');
export const setMetadataFromAddress = setIn(['from', 'email']);
export const setMetadataFromName = setIn(['from', 'name']);
export const setMetadataOriginalSenderEmail = setIn(['originalSender', 'email']);
export const setMetadataOriginalSenderName = setIn(['originalSender', 'name']);
export const setMetadataConnectedAccountAddress = set('connectedAccountAddress');
export const setMetadataHasReplies = set('hasReplies');
export const setMetadataPreviousRepliesHtml = set('previousRepliesHtml');
export const setMetadataPreviousRepliesPlainText = set('previousRepliesPlainText');
export const setMetadataIsMemberOfForwardedSubthread = set('memberOfForwardedSubthread');
export const setMetadataIsForward = set('forward');
export const setMetadataClipStatus = set('clipStatus');
export const setMetadataTemplateId = set('templateId');