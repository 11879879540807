import { useChannelInstanceById, useChannelInstancesForChannel } from '../../../channel-instances/public/hooks';
import { getChannelInstanceId, getGenericChannelId } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getChannelId, getIsActive, getIsAuthorized } from '../../../channel-instances/public/operators';
export function useSendFromChannelInstance({
  channelId,
  deferred,
  inboxId,
  inReplyToMessage
}) {
  const channelInstanceId = inReplyToMessage ? getChannelInstanceId(inReplyToMessage) : null;
  const {
    channelInstance: messageChannelInstance,
    isLoading: isChannelInstanceLoading
  } = useChannelInstanceById({
    channelInstanceId,
    deferred
  });
  const {
    channelInstances,
    isLoading: areChannelInstancesLoading
  } = useChannelInstancesForChannel({
    inboxId: deferred || !inboxId ? 0 : inboxId,
    channelId,
    deferred
  });
  const isLoading = isChannelInstanceLoading || areChannelInstancesLoading;
  const canReplyOnSameChannelInstance = messageChannelInstance && getChannelId(messageChannelInstance) === channelId;
  const canUseRandomChannelInstance = !inReplyToMessage || getGenericChannelId(inReplyToMessage) !== channelId;
  if (canReplyOnSameChannelInstance) {
    return {
      channelInstanceToSendFrom: messageChannelInstance,
      isLoading
    };
  }
  if (canUseRandomChannelInstance && channelInstances) {
    return {
      channelInstanceToSendFrom: channelInstances.find(instance => getIsActive(instance) && getIsAuthorized(instance)),
      isLoading
    };
  }
  return {
    channelInstanceToSendFrom: null,
    isLoading
  };
}