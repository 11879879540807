export const QUOTES_UPDATE_STARTED = 'QUOTES_UPDATE_STARTED';
export const QUOTES_UPDATE_FAILED = 'QUOTES_UPDATE_FAILED';
export const QUOTES_DELETE_STARTED = 'QUOTES_DELETE_STARTED';
export const QUOTES_DELETE_FAILED = 'QUOTES_DELETE_FAILED';
export const QUOTES_DELETE_SUCCEEDED = 'QUOTES_DELETE_SUCCEEDED';
export const QUOTES_CLONE_STARTED = 'QUOTES_CLONE_STARTED';
export const QUOTES_CLONE_FAILED = 'QUOTES_CLONE_FAILED';
export const QUOTES_CLONE_SUCCEEDED = 'QUOTES_CLONE_SUCCEEDED';
export const QUOTES_APPROVE_STARTED = 'QUOTES_APPROVE_STARTED';
export const QUOTES_APPROVE_FAILED = 'QUOTES_APPROVE_FAILED';
export const QUOTES_APPROVE_SUCCEEDED = 'QUOTES_APPROVE_SUCCEEDED';
export const QUOTES_REQUEST_CHANGES_STARTED = 'QUOTES_REQUEST_CHANGES_STARTED';
export const QUOTES_REQUEST_CHANGES_FAILED = 'QUOTES_REQUEST_CHANGES_FAILED';
export const QUOTES_REQUEST_CHANGES_SUCCEEDED = 'QUOTES_REQUEST_CHANGES_SUCCEEDED';
export const QUOTES_RECALL_SUCCEEDED = 'QUOTES_RECALL_SUCCEEDED';