import { gql } from '@apollo/client';
export const PAYMENTS_ELIGIBILITY_FRAGMENT = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"PaymentsEligibilityFragment\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"Query\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"paymentsEligibility\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"chargesEnabled\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"errorCode\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"payoutsEnabled\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"processorType\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"status\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"underwritingStatus\"}}]}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "PaymentsEligibilityFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "Query"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "paymentsEligibility"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chargesEnabled"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "errorCode"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "payoutsEnabled"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "processorType"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "status"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "underwritingStatus"
            }
          }]
        }
      }]
    }
  }]
});