export const reduceInboxArrayToSelectOptions = (options, inbox) => {
  const inboxId = inbox.id;
  const inboxName = inbox.name;
  const option = {
    text: inboxName,
    value: inboxId
  };
  return [...options, option];
};
export const getInboxOptions = (inboxes, currentInboxId) => {
  if (inboxes === null) {
    return [];
  }
  return inboxes.filter(inbox => inbox.id !== currentInboxId).reduce(reduceInboxArrayToSelectOptions, []);
};