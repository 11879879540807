import { getUsageTrackingChannel } from 'conversations-thread-data/usage-tracking/public/trackerChannelIdMapping';
export const getClickedExpandMoreButton = ({
  genericChannelId
} = {}) => ({
  key: 'clicked-expand-more-button',
  properties: {
    action: 'clicked expand more button',
    channel: genericChannelId && getUsageTrackingChannel(genericChannelId)
  }
});
export const getRecipientAdded = ({
  genericChannelId,
  recipientType
} = {}) => ({
  key: `${recipientType}-recipient-added`,
  properties: {
    action: `${recipientType} recipient added`,
    channel: genericChannelId && getUsageTrackingChannel(genericChannelId)
  }
});
export const getRecipientRemoved = ({
  genericChannelId,
  recipientType
} = {}) => ({
  key: `${recipientType}-recipient-removed`,
  properties: {
    action: `${recipientType} recipient removed`,
    channel: genericChannelId && getUsageTrackingChannel(genericChannelId)
  }
});
export const getAllRecipientsRemoved = ({
  genericChannelId,
  recipientType
} = {}) => ({
  key: `all-${recipientType}-recipients-removed`,
  properties: {
    action: `All ${recipientType} recipients removed`,
    channel: genericChannelId && getUsageTrackingChannel(genericChannelId)
  }
});
export const getFromAddressChanged = ({
  genericChannelId
} = {}) => ({
  key: `from-address-changed`,
  properties: {
    action: `From address changed`,
    channel: genericChannelId && getUsageTrackingChannel(genericChannelId)
  }
});
export const searchForRecipient = ({
  contactProperty
} = {}) => ({
  key: `search-for-recipients`,
  properties: {
    action: `Search for to recipients`,
    channel: contactProperty
  }
});
export const waBannerInteraction = ({
  action
} = {}) => ({
  key: `wa-banner-interaction`,
  properties: {
    action
  }
});
export const getSubjectChanged = ({
  genericChannelId
} = {}) => ({
  key: 'subject-changed',
  properties: {
    action: 'Subject changed',
    channel: genericChannelId && getUsageTrackingChannel(genericChannelId)
  }
});