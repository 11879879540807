'use es6';

export const TASK_BODY = 'hs_task_body';
export const TASK_STATUS = 'hs_task_status';
export const TASK_FOR_OBJECT_TYPE = 'hs_task_for_object_type';
export const TASK_SUBJECT = 'hs_task_subject';
export const TASK_TYPE = 'hs_task_type';
export const TASK_REMINDERS = 'hs_task_reminders';
export const TASK_PRIORITY = 'hs_task_priority';
export const TASK_DUE_DATE = 'hs_timestamp';
export const SEND_DEFAULT_REMINDER = 'hs_task_send_default_reminder';
export const QUEUE_MEMBERSHIP_IDS = 'hs_queue_membership_ids';
export const TASK_COMPLETION_DATE = 'hs_task_completion_date';
export const TASK_SEQUENCE_STEP_ENROLLMENT_ID = 'hs_task_sequence_step_enrollment_id';

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}