const _unique = definitions => {
  const names = {};
  return definitions.filter(definition => {
    if (definition.kind !== 'FragmentDefinition') {
      return true;
    }
    const name = definition.name.value;
    if (names[name]) {
      return false;
    } else {
      names[name] = true;
      return true;
    }
  });
};
import { gql } from '@apollo/client';
import PaymentObjectPropertyFields from './PaymentObjectPropertyFields';
export default ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"PaymentObjectRefundPropertyFields\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"DefaultCommercePaymentProperties\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"hs_refunds_amount\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"PaymentObjectPropertyFields\"}}]}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"hs_net_amount\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"PaymentObjectPropertyFields\"}}]}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"hs_latest_status\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"PaymentObjectPropertyFields\"}}]}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"hs_latest_status_updated_date\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"PaymentObjectPropertyFields\"}}]}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"hs_lastmodifieddate\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"PaymentObjectPropertyFields\"}}]}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"hs_updated_by_user_id\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"PaymentObjectPropertyFields\"}}]}}]}}]}", {
  kind: "Document",
  definitions: _unique([{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "PaymentObjectRefundPropertyFields"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "DefaultCommercePaymentProperties"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "hs_refunds_amount"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "PaymentObjectPropertyFields"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "hs_net_amount"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "PaymentObjectPropertyFields"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "hs_latest_status"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "PaymentObjectPropertyFields"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "hs_latest_status_updated_date"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "PaymentObjectPropertyFields"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "hs_lastmodifieddate"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "PaymentObjectPropertyFields"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "hs_updated_by_user_id"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "PaymentObjectPropertyFields"
            }
          }]
        }
      }]
    }
  }].concat(PaymentObjectPropertyFields.definitions))
});