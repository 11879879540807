import { handleActions } from 'redux-actions';
import { CLICKED_TO_FORWARD, CLEAR_COMMUNICATOR_STATE } from '../constants/actionTypes';
import { THREAD_ID_CHANGED } from 'conversations-thread-data/thread-details/public/constants';
const initialState = {
  isReply: true,
  replyAll: true,
  message: null,
  open: false
};
export default handleActions({
  [CLICKED_TO_FORWARD](__state, action) {
    const {
      message,
      replyAll,
      isReply
    } = action.payload;
    return {
      isReply,
      replyAll,
      message,
      open: true
    };
  },
  // close the forward communicator if the compose one opens
  [CLEAR_COMMUNICATOR_STATE]() {
    return initialState;
  },
  [THREAD_ID_CHANGED](state) {
    return state.open ? initialState : state;
  }
}, initialState);