'use es6';

import memoize from 'transmute/memoize';
import { removeOutlookLineBreaks } from './removeOutlookLineBreaks';
import { removeStyles } from './removeStyles';
import { fixImages } from './fixImages';
import { replaceGmailDivs } from './replaceGmailDivs';
import { sanitizeEmail } from './sanitizeEmail';
export const cleanEmail = memoize(text => {
  const transformers = [removeOutlookLineBreaks, replaceGmailDivs, removeStyles, fixImages];
  return sanitizeEmail(text, transformers);
});