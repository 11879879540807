import formatName from 'I18n/utils/formatName';
import { getFirstName, getLastName, getEmail, getVid } from 'conversations-inbox-lib/contacts/public/operators';
export const getFullName = (firstName, lastName) => {
  return formatName({
    firstName,
    lastName
  });
};
export const contactToOption = contact => ({
  text: getFullName(getFirstName(contact), getLastName(contact)) || getEmail(contact),
  value: getVid(contact),
  contact,
  help: getEmail(contact)
});