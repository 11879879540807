import { useAuth } from '../hooks/useAuth';

/**
 * Returns true if the current authed user has the provided scope
 */
export const useHasScope = scope => {
  const {
    user: {
      scopes
    }
  } = useAuth();
  return scopes.includes(scope);
};