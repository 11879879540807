import { useEffect, useRef, useState } from 'react';
import { ONE_DAY, ONE_HOUR, ONE_MINUTE } from '../constants/Dates';
/**
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
const useInterval = (func, delay) => {
  const intervalFunc = useRef();
  useEffect(() => {
    intervalFunc.current = func;
  }, [func]);
  useEffect(() => {
    let intervalId = null;
    if (delay !== null) intervalId = setInterval(() => {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      intervalFunc.current();
    }, delay);
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [delay]);
};

/**
 * @description Returns a Number duration which is the length of time since `timestamp`.
 * If this duration is < 1 hr, the duration will be updated on a 1 minute interval,
 * if this duration is < 1 day, the duration will update on a 1 hour interval,
 * otherwise duration will not update.
 *
 * @param {Number} timestamp - the timestamp to calculate a duration with
 * @returns {Number} the duration between now and the given timestamp
 */
export const useRelativeDurationWithInterval = timestamp => {
  const [duration, setDuration] = useState(Date.now() - timestamp);
  // run interval every minute if <1hr, every hour if <1 day, else don't run
  const intervalLength = duration > ONE_DAY ? null : duration > ONE_HOUR ? ONE_HOUR : ONE_MINUTE;
  useInterval(() => {
    setDuration(Date.now() - timestamp);
  }, intervalLength);
  return duration;
};