import { UNKNOWN } from 'conversations-internal-schema/responders/constants/AgentStates';
export const convertResponderToGqlObject = responder => {
  const {
    agentState,
    assignable,
    avatar,
    email,
    firstName,
    lastName,
    userId
  } = responder;
  return {
    agent: {
      email,
      firstName,
      lastName,
      userId,
      __typename: 'HubSpotUser'
    },
    agentState,
    avatarUrl: avatar,
    id: btoa(`${userId}`),
    isAssignable: assignable,
    __typename: 'Assignee'
  };
};
export const convertResponderToDataFetchingClientObject = responder => {
  const {
    agentState,
    assignable,
    avatar,
    email,
    firstName,
    lastName,
    userId
  } = responder;
  return {
    agent: {
      email: email || '',
      firstName,
      lastName,
      userId: userId || 0
    },
    agentState: agentState || UNKNOWN,
    avatarUrl: avatar,
    id: btoa(`${userId}`),
    isAssignable: assignable
  };
};