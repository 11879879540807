import { getId } from 'find-and-filter-data/views-schema/public';

/**
 * Sorts a CustomView[] according to the order of ids in orderedViewIds. Any
 * remaining views that were not present in orderedViewIds are appended after
 * the ordered views.
 */

export function sortViews(allViews, orderedViewIds) {
  if (!Array.isArray(orderedViewIds) || orderedViewIds.length === 0 || allViews.length <= 1) {
    return allViews;
  }
  const remainingViews = new Set(allViews);
  const orderedViews = [];
  for (const orderedViewId of orderedViewIds) {
    const orderedView = allViews.find(view => getId(view) === orderedViewId);
    if (orderedView && remainingViews.has(orderedView)) {
      orderedViews.push(orderedView);
      remainingViews.delete(orderedView);
    }
  }
  return orderedViews.concat(...remainingViews);
}