import { getData } from 'conversations-async-data/async-data/operators/getters';
import { isLoading } from 'conversations-async-data/async-data/operators/statusComparators';
// @ts-expect-error module not typed

import { getEmailMetadataAttachment } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { setAttachment, setChannelInstanceId, setSenders } from 'conversations-message-history/common-message-format/operators/commonMessageSetters';
import { setMetadataConnectedAccountAddress, setMetadataFrom } from 'conversations-message-history/common-message-format/operators/emailMetadataSetters';
import Sender from 'conversations-message-history/common-message-format/records/Sender';
import { getUserId } from 'conversations-thread-data/auth/public/selectors';
import { useChannelInstances } from 'conversations-thread-data/channel-instances/public/hooks';
import { getChannelInstanceById, getInstanceDeliveryIdentifier } from 'conversations-thread-data/channel-instances/public/operators';
import { getCommonMessage } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
// @ts-expect-error module not typed
import { setCommonMessage } from 'conversations-thread-data/common-reply/public/operators/commonReplySetters';
import { useConnectedAccounts } from 'conversations-thread-data/connected-accounts/public';
import { getFromAddressChanged } from '../../../usage-tracking/events/metadataEditorInteractions';
import { List as ImmutableList, Map as ImmutableMap } from 'immutable';
import { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import get from 'transmute/get';
import pipe from 'transmute/pipe';
import { EditorTrackerContext } from '../../../usage-tracking/context/EditorTrackerContext';
function updateFromEmail(connectedAccount) {
  return function (commonMessage) {
    const emailMetadataAttachment = getEmailMetadataAttachment(commonMessage);
    if (!emailMetadataAttachment || !connectedAccount) {
      return commonMessage;
    }
    const sendFromAddress = get('sendFromEmail', connectedAccount) || get('email', connectedAccount);
    const name = get('resolvedFromName', connectedAccount) || get('friendlyFromName', connectedAccount);
    const connectedAccountAddress = get('email', connectedAccount);
    const newEmailMetadata = pipe(setMetadataFrom(ImmutableMap({
      email: sendFromAddress,
      name
    })), setMetadataConnectedAccountAddress(connectedAccountAddress))(emailMetadataAttachment);
    return setAttachment(newEmailMetadata, commonMessage);
  };
}
export function useUpdateFromAccount({
  inboxId,
  onChange,
  reply,
  senderField
}) {
  const userId = useSelector(getUserId);
  const {
    asyncChannelInstances
  } = useChannelInstances({
    inboxId
  });
  const channelInstances = getData(asyncChannelInstances);
  const emailMetadataAttachment = getEmailMetadataAttachment(getCommonMessage(reply));
  const {
    asyncConnectedAccounts
  } = useConnectedAccounts({
    inboxId,
    deferred: !emailMetadataAttachment
  });
  const connectedAccounts = getData(asyncConnectedAccounts);
  const {
    trackEvent
  } = useContext(EditorTrackerContext);
  const updateFromAccount = useCallback(({
    target: {
      value: newChannelInstanceId
    }
  }) => {
    const replyMessage = getCommonMessage(reply);
    const channelInstance = getChannelInstanceById({
      channelInstances,
      id: newChannelInstanceId
    });
    const instanceDeliveryIdentifier = channelInstance && getInstanceDeliveryIdentifier(channelInstance);
    const newConnectedAccount = connectedAccounts && connectedAccounts.find(account => account.channelInstanceId === newChannelInstanceId);
    const instanceSenders = ImmutableList([Sender({
      actorId: `A-${userId}`,
      deliveryIdentifier: instanceDeliveryIdentifier,
      senderField
    })]);
    const newMessage = pipe(setSenders(instanceSenders), setChannelInstanceId(newChannelInstanceId), updateFromEmail(newConnectedAccount))(replyMessage);
    const newReply = setCommonMessage(newMessage, reply);
    trackEvent(getFromAddressChanged());
    onChange(newReply);
  }, [onChange, reply, userId, channelInstances, senderField, connectedAccounts, trackEvent]);
  return {
    updateFromAccount,
    loading: isLoading(asyncChannelInstances)
  };
}