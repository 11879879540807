'use es6';

import quickFetch from 'quick-fetch';
import { ApolloLink, Observable } from '@apollo/client';
import { createBatchedHubHttpLink } from 'apollo-link-hub-http';
import { createStack } from 'hub-http';
import { defaultTo } from 'hub-http/middlewares/core';
import hubapiStack from 'hub-http/stacks/hubapi';
import { getEarlyRequesterAsPromise } from './getEarlyRequesterAsPromise';
import Raven from 'raven-js';
export const earlyRequestLink = new ApolloLink((operation, forward) => {
  const {
    operationName,
    variables
  } = operation;
  const cardLocation = variables.cardLocation || '';
  // See: https://git.hubteam.com/HubSpot/CRM/blob/9621b95809e669f84172b06015331a4b42ee68f4/crm_ui/static/js/graphql-early-requester/profileGraphQLEarlyRequester.js#L51-L52
  const requestName = operationName + cardLocation + variables.objectType + variables.subjectId;
  const earlyGraphQLRequest = quickFetch.getRequestStateByName(requestName);
  if (earlyGraphQLRequest && !earlyGraphQLRequest.error) {
    operation.setContext({
      earlyRequest: getEarlyRequesterAsPromise(requestName)
    });
  }
  return forward(operation);
});
export const errorMonitoringLink = new ApolloLink((operation, forward) => {
  return new Observable(observer => {
    const sub = forward(operation).subscribe({
      next(result) {
        observer.next(result);
      },
      error(err) {
        const {
          operationName,
          variables
        } = operation;
        const isMutation = operation.query.definitions && operation.query.definitions.some(definition => definition.kind === 'OperationDefinition' && definition.operation === 'mutation');
        Raven.captureException(err, {
          extra: {
            errorCode: err.errorCode,
            operationName,
            isMutation
          }
        });
        if (err.errorCode === 'TIMEOUT') {
          Raven.captureException(err, {
            extra: {
              errorCode: err.errorCode,
              operationName,
              isMutation,
              // only include variables if query is not a mutation.
              // Mutations can contain PII/GDPR-sensitive data
              variables: isMutation ? 'mutation' : JSON.stringify(variables)
            }
          });
          console.error('crmGraphQLTimeout', {
            operationName
          });
        }
        observer.error(err);
      },
      complete() {
        observer.complete();
      }
    });
    return () => {
      if (sub) sub.unsubscribe();
    };
  });
});
export const apiLink = opts => {
  const apiStack = createStack(defaultTo('timeout', 180000), hubapiStack);
  return ApolloLink.from([earlyRequestLink, errorMonitoringLink, createBatchedHubHttpLink(apiStack)(opts)]);
};