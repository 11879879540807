import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { deleteEntry } from 'conversations-async-data/indexed-async-data/operators/deleteEntry';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { FETCH_TICKET_DATA } from '../constants/asyncActionTypes';
import { NAVIGATE_TO_THREAD } from 'conversations-inbox-lib/pubsub/public/constants';
const initialState = new IndexedAsyncData({
  name: 'ticketData',
  notSetValue: new AsyncData({
    data: null
  })
});
export default handleActions({
  [FETCH_TICKET_DATA.STARTED]: (state, action) => {
    const {
      threadId
    } = action.payload.requestArgs;
    return updateEntry(threadId, requestStarted, state);
  },
  [FETCH_TICKET_DATA.SUCCEEDED](state, action) {
    const {
      data,
      requestArgs
    } = action.payload;
    const {
      threadId
    } = requestArgs;
    return updateEntry(threadId, requestSucceededWithOperator(() => data), state);
  },
  [FETCH_TICKET_DATA.FAILED](state, action) {
    const {
      threadId
    } = action.payload.requestArgs;
    return updateEntry(threadId, requestFailed, state);
  },
  [NAVIGATE_TO_THREAD](state, action) {
    const {
      threadId
    } = action.payload;
    return deleteEntry(threadId, state);
  }
}, initialState);