import { useReduxAsyncFetcher } from 'conversations-async-data/async-data/hooks/useReduxAsyncFetcher';
import { deferredAsyncDataSelector } from '../../utils/deferredAsyncDataSelector';
import { noOpAction } from '../../utils/noOpAction';
import { fetchChatflowConnectionStatus } from '../actions/fetchChatflowConnectionStatus';
import { getChatflowConnectionStatus } from '../selectors/getChatflowConnectionStatus';
export const useChatFlowConnectionStatus = ({
  chatflowId = -1,
  deferred
}) => {
  const {
    asyncData: asyncChatFlowConnectionStatus
  } = useReduxAsyncFetcher({
    action: deferred ? noOpAction : fetchChatflowConnectionStatus,
    // @ts-expect-error deferred async data selector doesn't return narrowly typed data
    selector: deferred ? deferredAsyncDataSelector : getChatflowConnectionStatus,
    id: chatflowId,
    idTransform: id => ({
      chatflowId: id
    })
  });
  return {
    asyncChatFlowConnectionStatus
  };
};