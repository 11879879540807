import http from 'hub-http/clients/apiClient';
import { registerQuery, useQuery } from 'data-fetching-client';
const DEFAULT_FONT_QUERY = registerQuery({
  fieldName: 'defaultFont',
  fetcher() {
    return http.get(`cursus/settings/portal?settingName=EMAIL_EDITOR_DEFAULT_FONT`).then(({
      value
    }) => {
      try {
        return JSON.parse(value);
      } catch (e) {
        return undefined;
      }
    });
  }
});
export function useDefaultFont({
  deferred
} = {}) {
  const {
    data,
    loading,
    error
  } = useQuery(DEFAULT_FONT_QUERY, {
    skip: deferred
  });
  return {
    defaultFont: data && data.defaultFont,
    loading,
    error
  };
}