import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import I18n from 'I18n';
import { fromJS } from 'immutable';
import { MOVE_TO_INBOX } from '../constants/asyncActionTypes';
import { moveToInbox as moveToInboxClient } from '../clients/moveToInbox';
export const moveToInbox = createAsyncAction({
  requestFn: moveToInboxClient,
  actionTypes: MOVE_TO_INBOX,
  toRecordFn: fromJS,
  // @ts-expect-error createAsyncAction thinks that the args are just a general `object`, which doesn't guarantee a requestArgs
  successMetaActionCreator: ({
    requestArgs: {
      newInboxName
    }
  }) => ({
    notification: {
      type: 'success',
      titleText: I18n.text('conversations-thread-view.moveToInboxPrompt.successTitle'),
      message: newInboxName ? I18n.text('conversations-thread-view.moveToInboxPrompt.success', {
        inboxName: newInboxName
      }) : I18n.text('conversations-thread-view.moveToInboxPrompt.unknownInboxSuccess')
    }
  })
});