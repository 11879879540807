// original: https://git.hubteam.com/HubSpot/conversations-internal-schema/blob/1729733ab4b5cfdd5950af37c67157f5deb9589b/static/js/team/operators/getKeyAddress.js
import { List as ImmutableList, Map as ImmutableMap } from 'immutable';
export const getKeyAddress = (nodeId, tree) => {
  // TODO: change this into a simple array since it is convert using .toJS on return anyways
  let result = ImmutableList();
  const rootNode = ImmutableMap({
    childTeams: tree
  });
  const buildKeyAddress = (optionValue, node, keyAddress = ImmutableList()) => {
    const nodeKey = node.getIn(['data', 'id']);
    if (nodeKey === optionValue) {
      result = keyAddress;
    } else {
      node.get('childTeams').forEach(childTeam => {
        buildKeyAddress(optionValue, childTeam, keyAddress.push(childTeam.getIn(['data', 'id'])));
      });
    }
  };

  // @ts-expect-error rootNode is a Partial<TeamTreeNode> so it doesn't type 100%
  buildKeyAddress(nodeId, rootNode);
  return result.toJS();
};