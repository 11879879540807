/**
 * Disables the properties in the original properties list that are in the propertiesToDisable list.
 * If the map contains top level property groups, it'll loop through the properties in each group
 * and disable any across all groups that match the property names in the propertiesToDisable list.
 * @param {ImmutableMap} originalFields - The original properties list.
 * @param {Array<string>} propertiesToDisable - The list of property names to disable.
 */
export const disableStrategyProperties = ({
  originalFields,
  propertiesToDisable
}) => {
  const propertySet = new Set(propertiesToDisable);
  return originalFields.map(property => {
    //If the property is a group, loop through the properties in the group and disable any that match the property names in the propertiesToDisable list.
    if (property.get('properties')) {
      return property.update('properties', properties => {
        return properties.map(nestedProperty => {
          if (propertySet.has(nestedProperty.get('name'))) {
            return nestedProperty.set('disabled', true);
          }
          return nestedProperty;
        });
      });
    }
    //If the property is not a group, disable the property if it matches the property names in the propertiesToDisable list.
    else if (propertySet.has(property.get('name'))) {
      return property.set('disabled', true);
    }
    return property;
  }).toMap();
};