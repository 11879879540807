import { createSelector } from 'reselect';
// @ts-expect-error ThreadHistory is not typed

// @ts-expect-error file not typed
import { getCurrentThreadHistory } from 'conversations-thread-data/thread-histories/public/selectors/getCurrentThreadHistory';
// @ts-expect-error getLatestAgentMessage is not typed
import { getLatestAgentMessage } from 'conversations-message-history/thread-history/operators/getLatestAgentMessage';
// @ts-expect-error getLatestAgentMessage is not typed
import { getCurrentGenericChannelId } from '../../tabbed-editors/selectors/getCurrentGenericChannelId';
import { getFilteredUnpublishedMessages } from '../../unified-thread-history/selectors/getFilteredUnpublishedMessages';
import { getMessage } from 'conversations-message-history/unpublished-messages/operators/getters';
export const getLatestAgentCommonMessageByGenericChannelId = createSelector([getCurrentThreadHistory, getCurrentGenericChannelId, getFilteredUnpublishedMessages], (threadHistory, currentGenericChannelId, unpublishedMessages) => {
  if (!unpublishedMessages.isEmpty()) {
    return getMessage(unpublishedMessages.last());
  }
  return threadHistory && getLatestAgentMessage(threadHistory, currentGenericChannelId);
});