/**
 * Diff two objects by their primitive keys
 *
 * @param {Object} a
 * @param {Object} b
 * @returns {Object}
 */
export const diffByKeys = (a, b) => {
  const isExcludedKey = key => !(key in b);
  return Object.keys(a).reduce((acc, key) => {
    if (isExcludedKey(key)) {
      acc[key] = a[key];
    }
    return acc;
  }, {});
};