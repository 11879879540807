/**
 * @deprecated prefer { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics
 */
export const UPDATE_ASSOCIATIONS = 'UPDATE_ASSOCIATIONS';
/**
 * @deprecated prefer { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics
 */
export const UPDATE_OBJECT = 'UPDATE_OBJECT';
/**
 * @deprecated prefer { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics
 */
export const PREVIEW_OBJECT = 'PREVIEW_OBJECT';
/**
 * @deprecated prefer { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics
 */
export const OPEN_OBJECT_BUILDER = 'OPEN_OBJECT_BUILDER';
/**
 * @deprecated prefer { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics
 */
export const OPEN_UPDATE_ASSOCIATION_MODAL = 'OPEN_UPDATE_ASSOCIATION_MODAL';
/**
 * @deprecated prefer { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics
 */
export const OPEN_IFRAME_MODAL = 'OPEN_IFRAME_MODAL';
/**
 * @deprecated prefer { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics
 */
export const SHOW_ALERT = 'SHOW_ALERT';
/**
 * @deprecated prefer { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics
 */
export const sample = 'sample';
/**
 * @deprecated prefer { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics
 */
export const sample2 = 'sample2';