// TODO: move to composer-data

import { isLoading } from 'conversations-async-data/async-data/operators/statusComparators';
export const channelInstancesLoading = ({
  asyncChannelInstances
}) => {
  return isLoading(asyncChannelInstances) ? {
    disabled: true,
    sendDisabledReason: 'CONNECTING_TO_HUBSPOT'
  } : null;
};