// gimme: conversations-thread-view 06/30/2021 cfef6b9a8b3badc65b082ffd722b737fa8d16bdf
import get from 'transmute/get';
export const getInsertAttachment = ({
  channel,
  file,
  editorType
}) => ({
  key: 'insert-attachment',
  properties: {
    action: 'insert attachment',
    channel,
    size: get('size', file),
    extension: get('extension', file),
    editorType
  }
});
export const getAttachmentInsertFailed = ({
  channel,
  file,
  editorType
}) => ({
  key: 'attachment-insert-failed',
  properties: {
    action: 'attachment insert failed',
    channel,
    size: get('size', file),
    type: get('originalType', file) || get('type', file),
    editorType
  }
});
export const getClickedAttachmentButton = ({
  channel,
  editorType
} = {}) => ({
  key: 'clicked-attachment-button',
  properties: {
    action: 'clicked attachment button',
    channel,
    editorType
  }
});
export const insertedTemplate = ({
  channel
} = {}) => ({
  key: 'insert-template',
  properties: {
    action: 'insert template from popover',
    channel
  }
});
export const insertedDocument = ({
  channel
} = {}) => ({
  key: 'insert-document',
  properties: {
    action: 'insert document from popover',
    channel
  }
});
export const insertedKnowledgeArticle = ({
  channel,
  editorType
} = {}) => ({
  key: 'insert-knowledge-article',
  properties: {
    action: 'insert knowledge base article from popover',
    channel,
    editorType
  }
});
export const insertedMeeting = ({
  channel
} = {}) => ({
  key: 'insert-meeting',
  properties: {
    action: 'insert meeting from popover',
    channel
  }
});
export const insertedQuote = ({
  channel
} = {}) => ({
  key: 'insert-quote',
  properties: {
    action: 'insert quote from popover',
    channel
  }
});
export const insertedVideo = ({
  channel
} = {}) => ({
  key: 'insert-video',
  properties: {
    action: 'insert video from popover',
    channel
  }
});
export const insertedSnippet = ({
  channel,
  editorType
} = {}) => ({
  key: 'insert-snippet',
  properties: {
    action: 'insert snippet from popover',
    channel,
    editorType
  }
});
export const clickedToOpenInsertPopover = ({
  channel
} = {}) => ({
  key: 'clicked-to-open-insert-popover',
  properties: {
    action: 'clicked to open insert popover',
    channel
  }
});
export const clickedToOpenLinkPopover = ({
  channel
} = {}) => ({
  key: 'clicked-to-open-link-popover',
  properties: {
    action: 'clicked to open link popover',
    channel
  }
});
export const insertedLink = ({
  channel
} = {}) => ({
  key: 'insert-link',
  properties: {
    action: 'insert link from popover',
    channel
  }
});
export const insertedImage = () => ({
  key: 'insert-image',
  properties: {
    action: 'insert inline image'
  }
});
export const clickedToSendEmail = () => ({
  key: 'clicked-to-send-email',
  properties: {
    action: 'clicked to send email',
    'email-type': 'send now'
  }
});
export const getClickedToTogglePreviousMessageHistory = ({
  direction
}) => ({
  key: 'clicked-to-toggle-previous-message-history',
  properties: {
    action: 'clicked to toggle previous message history',
    direction
  }
});
export const pastedText = () => ({
  key: 'clipboard',
  properties: {
    action: 'pasted text'
  }
});
export const droppedFile = () => ({
  key: 'drag-and-drop-file',
  properties: {
    action: 'dropped file'
  }
});
export const kbInteractions = {
  knowledgeBaseManager: {
    key: 'knowledge-base-manager',
    properties: {
      action: 'Clicked manage button'
    }
  },
  navigateKnowledgeBase: {
    key: 'navigate-knowledge-base',
    properties: {
      action: 'Click into specific knowledge base'
    }
  },
  searchAllKnowledgeArticles: {
    key: 'search-all-knowledge-articles',
    properties: {
      action: 'Search all knowledge articles'
    }
  },
  searchKnowledgeBase: {
    key: 'search-knowledge-base',
    properties: {
      action: 'Search specific knowledge base'
    }
  }
};
export const contentAssistantInteractions = {
  openContentAssistantMenu: {
    key: 'openContentAssistantMenu',
    properties: {
      action: 'menu-open',
      actionType: 'selection-command',
      inApp: 'Inbox'
    }
  },
  selectContentAssistantOption: {
    key: 'selectContentAssistantOption',
    properties: {
      action: 'menu-selection',
      actionType: 'selection-command',
      inApp: 'Inbox'
    }
  },
  summaryRequested: {
    key: 'summary-requested',
    properties: {
      action: 'Clicked AI summary button'
    }
  },
  summaryGenerated: {
    key: 'summary-generated',
    properties: {
      action: 'AI summary generated'
    }
  }
};
export const contentAssistantV2Interactions = {
  openContentAssistantMenu: {
    key: 'openContentAssistantMenu',
    properties: {
      action: 'menu-open',
      actionType: 'selection-command',
      inApp: 'Inbox',
      version: 'CAv2'
    }
  },
  openSlashMenu: {
    key: 'openContentAssistantMenu',
    properties: {
      action: 'menu-open',
      actionType: 'slash-command',
      inApp: 'Inbox',
      version: 'CAv2'
    }
  },
  openHighlightIconMenu: {
    key: 'openContentAssistantMenu',
    properties: {
      action: 'menu-open',
      actionType: 'highlight-icon',
      inApp: 'Inbox',
      version: 'CAv2'
    }
  },
  selectContentAssistantOption: {
    key: 'selectContentAssistantOption',
    properties: {
      action: 'menu-selection',
      actionType: 'selection-command',
      inApp: 'Inbox',
      version: 'CAv2'
    }
  },
  selectSlashCommandOption: {
    key: 'selectContentAssistantOption',
    properties: {
      action: 'menu-selection',
      actionType: 'slash-command',
      inApp: 'Inbox',
      version: 'CAv2'
    }
  },
  selectHighlightIconOption: {
    key: 'selectContentAssistantOption',
    properties: {
      action: 'menu-selection',
      actionType: 'highlight-icon',
      inApp: 'Inbox',
      version: 'CAv2'
    }
  }
};