'use es6';

import { LAST_VIEWED_INBOX_V2 } from '../constants/localStorageKeys';
export const updateLocalStorageLastViewedInboxes = lastViewedInboxes => {
  try {
    // eslint-disable-next-line hs-react-native/no-web-globals
    localStorage.setItem(LAST_VIEWED_INBOX_V2, JSON.stringify(lastViewedInboxes.toJS()));
  } catch (e) {
    // Do nothing.
  }
};