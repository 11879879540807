export const getNavigateToTicketRecord = isThreadHeader => ({
  key: 'clicked-to-view-ticket-record',
  properties: {
    action: 'Clicked to View Ticket Record',
    source: isThreadHeader ? 'Thread Header' : 'Thread Body'
  }
});
export const getChangeTicketPriority = propertyName => ({
  key: 'clicked-to-change-ticket-property',
  properties: {
    action: 'Clicked to change the ticket property',
    propertyName
  }
});