import { arePojoSendersEqual } from 'conversations-message-history/senders/operators/arePojoSendersEqual';
export const getFriendlyNameRecordForSender = ({
  friendlyNames,
  genericChannelId,
  sender
}) => {
  return friendlyNames.find(friendlyName => {
    if (genericChannelId && genericChannelId !== friendlyName.genericChannelId) return false;
    const sender1 = friendlyName.genericSender;
    const sender2 = sender;
    return arePojoSendersEqual(sender1, sender2);
  });
};