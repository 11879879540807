import { makeInvoice } from './invoice';
import { getInvoiceAssociatedObjectProperty, getInvoiceProperty, makeNewProperty, mapProperties, unmapProperties } from './property';
const getLineItemId = (invoiceObjectId, lineItemObjectId) =>
// For new invoices, line items still have ids that are associated with the deal or quote.
// Set those ids as null so that line items are recreated for the invoice.
!invoiceObjectId || !lineItemObjectId || Number(lineItemObjectId) < 0 ? null : Number(lineItemObjectId);
const sanitizeLineItemProperties = (invoice, lineItem) => Object.assign({}, lineItem.properties, {
  hs_line_item_currency_code: makeNewProperty('hs_line_item_currency_code', getInvoiceAssociatedObjectProperty(lineItem, 'hs_line_item_currency_code') || getInvoiceProperty(invoice, 'hs_currency'))
});
const mapAssociatedLineItem = (invoice, lineItem) => Object.assign({}, lineItem, {
  objectId: getLineItemId(invoice.objectId, lineItem.objectId),
  properties: mapProperties(sanitizeLineItemProperties(invoice, lineItem))
});
const mapAssociatedObject = associatedObject => Object.assign({}, associatedObject, {
  properties: mapProperties(associatedObject.properties)
});
const mapAssociatedObjects = invoice => ({
  INVOICE_TO_COMPANY: invoice.associatedObjects.INVOICE_TO_COMPANY.map(mapAssociatedObject),
  INVOICE_TO_CONTACT: invoice.associatedObjects.INVOICE_TO_CONTACT.map(mapAssociatedObject),
  INVOICE_TO_DEAL: invoice.associatedObjects.INVOICE_TO_DEAL.map(mapAssociatedObject),
  INVOICE_TO_LINE_ITEM: invoice.associatedObjects.INVOICE_TO_LINE_ITEM.map(lineItem => mapAssociatedLineItem(invoice, lineItem)),
  INVOICE_TO_QUOTE: invoice.associatedObjects.INVOICE_TO_QUOTE.map(mapAssociatedObject),
  INVOICE_TO_DISCOUNT: invoice.associatedObjects.INVOICE_TO_DISCOUNT.map(mapAssociatedObject),
  INVOICE_TO_FEE: invoice.associatedObjects.INVOICE_TO_FEE.map(mapAssociatedObject),
  INVOICE_TO_TAX: invoice.associatedObjects.INVOICE_TO_TAX.map(mapAssociatedObject)
});
const unmapAssociatedObject = associatedObject => Object.assign({}, associatedObject, {
  properties: unmapProperties(associatedObject.properties)
});
const unmapAssociatedObjects = associatedObjects => ({
  INVOICE_TO_COMPANY: associatedObjects.INVOICE_TO_COMPANY ? associatedObjects.INVOICE_TO_COMPANY.map(unmapAssociatedObject) : [],
  INVOICE_TO_CONTACT: associatedObjects.INVOICE_TO_CONTACT ? associatedObjects.INVOICE_TO_CONTACT.map(unmapAssociatedObject) : [],
  INVOICE_TO_DEAL: associatedObjects.INVOICE_TO_DEAL ? associatedObjects.INVOICE_TO_DEAL.map(unmapAssociatedObject) : [],
  INVOICE_TO_LINE_ITEM: associatedObjects.INVOICE_TO_LINE_ITEM ? associatedObjects.INVOICE_TO_LINE_ITEM.map(unmapAssociatedObject) : [],
  INVOICE_TO_QUOTE: associatedObjects.INVOICE_TO_QUOTE ? associatedObjects.INVOICE_TO_QUOTE.map(unmapAssociatedObject) : [],
  INVOICE_TO_DISCOUNT: associatedObjects.INVOICE_TO_DISCOUNT ? associatedObjects.INVOICE_TO_DISCOUNT.map(unmapAssociatedObject) : [],
  INVOICE_TO_FEE: associatedObjects.INVOICE_TO_FEE ? associatedObjects.INVOICE_TO_FEE.map(unmapAssociatedObject) : [],
  INVOICE_TO_TAX: associatedObjects.INVOICE_TO_TAX ? associatedObjects.INVOICE_TO_TAX.map(unmapAssociatedObject) : []
});
export const createInvoice = response => makeInvoice(Object.assign({}, response, {
  properties: unmapProperties(response.properties),
  associatedObjects: unmapAssociatedObjects(response.associatedObjects)
}));
export const createInvoiceServiceRequest = invoice => Object.assign({}, invoice, {
  associatedObjects: mapAssociatedObjects(invoice),
  objectId: invoice.objectId,
  properties: mapProperties(invoice.properties)
});