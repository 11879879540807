import http from 'hub-http/clients/apiClient';
import { OrderedMap, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import memoize from 'transmute/memoize';
import { FETCH_UNIVERSAL_ENGAGEMENT_ASSOCIATIONS_DATA } from 'universal-associations-select/hooks/useFetchUniversalEngagementAssociations';
import { fetch } from 'universal-associations-select/api/apolloClient';
import { parseUniversalEngagementAssociations, AUTO_ASSOCIATIONS_HUBSPOT_DEFINED_OBJECT_TYPE_ID_ALLOWLIST } from './parseUniversalEngagementAssociations';
import { TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
// @ts-expect-error untyped
import EngagementsV2UniversalAssociationRecord from '../records/EngagementsV2UniversalAssociationRecord';
const getSelectedAssociations = memoize(associations => associations.reduce((acc, record) => {
  const selectedAssociationOptionRecords = record.get('associationOptionRecords').filter(option => option.get('isSelected'));
  if (!selectedAssociationOptionRecords.size) {
    return acc;
  }
  const toObjectTypeId = record.get('toObjectTypeId');
  const objectIds = selectedAssociationOptionRecords ? ImmutableSet(selectedAssociationOptionRecords.keySeq()) : ImmutableSet();
  const recordsMap = selectedAssociationOptionRecords || OrderedMap();
  const engagementsV2UniversalAssociation = ImmutableMap({
    [toObjectTypeId]: EngagementsV2UniversalAssociationRecord({
      associationOptionRecordsMap: recordsMap,
      associationSpec: ImmutableMap({
        associationCategory: record.get('associationCategory'),
        associationTypeId: record.get('associationTypeId')
      }),
      objectIds
    })
  });
  return acc.concat(engagementsV2UniversalAssociation);
}, ImmutableMap()));
const fetchAndParseAutoAssociations = ({
  engagementOrInteractionType,
  subjectObjectId,
  subjectObjectTypeId,
  shouldPreselectConfiguredAutoAssociations
}) => {
  if (!shouldPreselectConfiguredAutoAssociations) {
    return fetch({
      query: FETCH_UNIVERSAL_ENGAGEMENT_ASSOCIATIONS_DATA,
      fetchPolicyOverride: 'cache-and-network',
      variables: {
        engagementObjectTypeId: engagementOrInteractionType,
        engagementExists: false,
        isTicket: subjectObjectTypeId === TICKET_TYPE_ID,
        objectTypeId: subjectObjectTypeId,
        subjectId: subjectObjectId
      }
    }).then(({
      data
    }) => {
      const associations = parseUniversalEngagementAssociations({
        data,
        variables: {
          subjectObjectId,
          subjectObjectTypeId
        }
      });
      return getSelectedAssociations(associations).map(association => {
        return association.get('objectIds');
      }).toJS();
    }).catch(error => {
      throw error;
    });
  }
  const isSupportedObjectType = AUTO_ASSOCIATIONS_HUBSPOT_DEFINED_OBJECT_TYPE_ID_ALLOWLIST.includes(subjectObjectTypeId) || subjectObjectTypeId.startsWith('2-');
  if (!isSupportedObjectType) {
    return Promise.resolve({
      [subjectObjectTypeId]: [subjectObjectId]
    });
  }
  return http.post('/crm-engagement-auto-associations/v3', {
    data: {
      subjectObjectTypeId,
      subjectObjectId,
      engagementType: engagementOrInteractionType,
      includedObjects: {}
    }
  }).then(data => {
    const autoAssociations = data.autoAssociations;
    return Object.entries(autoAssociations).reduce((acc, [objectTypeId, associations]) => {
      const objectIds = associations.reduce((validAssociations, association) => {
        if (association.canUserView && association.currentUserCanCommunicate) {
          validAssociations.push(String(association.objectId));
        }
        return validAssociations;
      }, []);
      if (objectIds.length) {
        acc[objectTypeId] = objectIds;
      }
      return acc;
    }, {});
  }).catch(error => {
    throw error;
  });
};
export { getSelectedAssociations, fetchAndParseAutoAssociations };