import { useMemo } from 'react';
import { pickStandardViews, pickCollapsedViews } from '../operators/pickViews';
import { isThreadList, isPrivateUserCustomView, isPrivateTeamCustomView, isSharedCustomView } from 'find-and-filter-data/views-schema/public';
function organizeViews(views) {
  const standardViews = pickStandardViews(views);
  const collapsedViews = pickCollapsedViews(views);
  const userGeneratedViews = views.filter(view => !standardViews.includes(view) && !collapsedViews.includes(view) && !isThreadList(view));
  const userViews = userGeneratedViews.filter(isPrivateUserCustomView);
  const teamViews = userGeneratedViews.filter(isPrivateTeamCustomView);
  const publicViews = userGeneratedViews.filter(isSharedCustomView);
  return {
    primaryViews: standardViews.toArray(),
    collapsedViews: collapsedViews.toArray(),
    userGeneratedViews: userGeneratedViews.toArray(),
    userViews: userViews.toArray(),
    teamViews: teamViews.toArray(),
    publicViews: publicViews.toArray()
  };
}

/**
 * @description This hook handles which views need to be rendered in what order
 * and what location in the NavigationSidebar
 *
 * Note: this hook should only return JS arrays, not Immutable.Lists. These are
 * directly mapped to React components
 */
export default function useNavigationSidebarViewsManager(views) {
  return useMemo(() => organizeViews(views), [views]);
}