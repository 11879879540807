import { useCallback, useEffect, useRef, useState } from 'react';
import { isSucceeded } from 'conversations-async-data/async-data/operators/statusComparators';
import { useThreadDetails as useThreadDetailsCore } from '../../thread-details-feature/public/hooks/useThreadDetails';
import { isClosing } from 'conversations-thread-data/thread-details/public/operators';
import { triggerWootricsSurvey } from '../../lib/triggerWootricsSurvey';
import { getData } from 'conversations-async-data/async-data/operators/getters';

// @ts-expect-error module not typed

const useTriggerWootricsOnClose = ({
  asyncThreadDetails,
  onTriggerWootrics = () => {}
}) => {
  // create a flag that's true while the thread is closing, but
  // only in the instance of the hook that triggered the close
  // use this to trigger the wootrics survey when the thread
  // is successfully closed.
  const [closeTriggered, setCloseTriggered] = useState(false);
  const isThreadClosing = isClosing(asyncThreadDetails);
  const isThreadPrevClosingRef = useRef(isThreadClosing);
  useEffect(() => {
    isThreadPrevClosingRef.current = isThreadClosing;
  }, [isThreadClosing]);
  const isThreadSucceeded = isSucceeded(asyncThreadDetails);
  const closeSucceeded = closeTriggered && isThreadPrevClosingRef.current && isThreadSucceeded;
  const closeCompleted = closeTriggered && !isThreadClosing && isThreadPrevClosingRef.current;
  const triggerWootrics = closeSucceeded;
  useEffect(() => {
    if (closeCompleted) setCloseTriggered(false);
  }, [closeCompleted, setCloseTriggered]);
  useEffect(() => {
    if (triggerWootrics) {
      triggerWootricsSurvey();
      if (onTriggerWootrics) {
        onTriggerWootrics(getData(asyncThreadDetails));
      }
    }
  }, [triggerWootrics, onTriggerWootrics, asyncThreadDetails]);
  return {
    closeTriggered: () => setCloseTriggered(true)
  };
};
export const useThreadDetailsWithWootrics = ({
  deferred,
  threadId,
  onTriggerWootrics
}) => {
  const {
    assignToCurrentAgent,
    asyncAssignmentStatus,
    asyncFilterChangeRequest,
    asyncMoveStatus,
    asyncThreadDetails,
    blockThread,
    closeThread,
    markAsSpam,
    moveToInbox,
    openThread,
    retryFailed,
    unblockThread,
    unmarkAsSpam,
    updateAssignment
  } = useThreadDetailsCore({
    deferred: deferred || !threadId,
    threadId
  });
  const {
    closeTriggered
  } = useTriggerWootricsOnClose({
    asyncThreadDetails,
    onTriggerWootrics
  });

  // decorate imperative API with tracking where necessary
  const wrappedClosedThread = useCallback(() => {
    closeThread();
    closeTriggered();
  }, [closeThread, closeTriggered]);
  return {
    assignToCurrentAgent,
    asyncAssignmentStatus,
    asyncFilterChangeRequest,
    asyncMoveStatus,
    asyncThreadDetails,
    blockThread,
    closeThread: wrappedClosedThread,
    markAsSpam,
    moveToInbox,
    openThread,
    retryFailed,
    unblockThread,
    unmarkAsSpam,
    updateAssignment
  };
};