import I18n from 'I18n';
import formatName from 'I18n/utils/formatName';
import { getFormattedFriendlyName } from 'conversations-message-history/friendly-names/operators/getFormattedFriendlyName';
import { getDefaultedFriendlyName } from 'conversations-message-history/friendly-names/operators/getDefaultedFriendlyName';
import { getSenderId } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getFriendlySenderNameFallback } from './getFriendlySenderNameFallback';

/**
 * @description Return's the sender's friendly name and metadata from sender data
 */
export const getFriendlySenderName = ({
  auth,
  friendlyData,
  sender
}) => {
  const unknownSenderName = I18n.text('conversations-thread-view.threadHistory.unknownSender.default');
  // Necessary for edge cases where sender is null despite being required
  if (!sender) return unknownSenderName;
  const {
    resolvedFriendlyName,
    resolutionSource
  } = friendlyData || {};
  const formattedFriendlyName = getFormattedFriendlyName({
    resolvedFriendlyName,
    resolutionSource
  });
  if (formattedFriendlyName) return formattedFriendlyName;
  const actorId = getSenderId(sender);
  // Use logged-in user's name as fallback for outgoing live messages
  const senderIsLoggedInUser = auth && `${actorId}` === `${auth.user.user_id}`;
  const agentName = senderIsLoggedInUser ? formatName({
    firstName: auth.user.first_name,
    lastName: auth.user.last_name
  }) : '';
  if (agentName) return agentName;
  return getDefaultedFriendlyName({
    resolvedFriendlyName,
    resolutionSource
  }) || getFriendlySenderNameFallback(sender);
};