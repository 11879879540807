'use es6';

import { getSenderType } from '../../common-message-format/operators/commonMessageFormatGetters';
import { getFromSenderEmail } from '../../common-message-format/operators/commonMessageGetters';
import { VISITOR } from '../../common-message-format/constants/legacySenderTypes';
import { getEmail } from './getters';
export const senderEmailDoesNotMatchVisitor = ({
  sender,
  message
}) => {
  const senderType = getSenderType(message);
  const fromAddress = getFromSenderEmail(message);
  return sender && senderType === VISITOR && fromAddress && getEmail(sender) !== fromAddress;
};