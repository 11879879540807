import http from 'conversations-http/clients/http';
var ObfuscationOptions;
(function (ObfuscationOptions) {
  ObfuscationOptions[ObfuscationOptions["REDACT_PHONE_NUMBER"] = 0] = "REDACT_PHONE_NUMBER";
  ObfuscationOptions[ObfuscationOptions["REDACT_LAST_NAME"] = 1] = "REDACT_LAST_NAME";
})(ObfuscationOptions || (ObfuscationOptions = {}));
export const fetchFriendlyName = ({
  messageId,
  obfuscationOption
}) => {
  return http.post(`conversations-visitors/v1/friendly-names/${messageId}`, {
    query: {
      obfuscationOption
    }
  });
};