import I18n from 'I18n';
export const ONE_HOUR_IN_MILLISECONDS = 1000 * 60 * 60;
export const ONE_DAY = ONE_HOUR_IN_MILLISECONDS * 24;

/**
 * Returns a number parsed from a timestamp string in UTC milliseconds (all HS services return timestamps in UTC milliseconds)
 * @param timestamp
 * @returns number or null
 * @example
 * const timestamp = '1593648000000';
 * const date = parseDate(timestamp);
 * console.log(date); 1593648000000
 * const nothing = parseDate(null);
 * console.log(nothing); null
 */
export function parseDate(timestamp) {
  if (!timestamp) {
    return null;
  }
  const date = parseInt(timestamp, 10);
  if (isNaN(date)) {
    return null;
  }
  return date;
}
export function isDate(date) {
  return date instanceof Date;
}
export function toTimestamp(timestamp) {
  if (isDate(timestamp)) {
    return timestamp.getTime();
  }
  return timestamp;
}

/**
 * Returns a new timestamp with the timezone offset removed (in UTC)
 * @returns number
 * */
export function getUTCDateTime(dateTime = new Date()) {
  return toTimestamp(dateTime);
}

/**
 * Returns a new timestamp with the timezone offset and time removed (in UTC)
 * @returns number
 * */
export function getUTCDate(dateTime = new Date()) {
  const date = toTimestamp(dateTime);
  return I18n.moment.utc(date).hour(0).minute(0).second(0).millisecond(0).valueOf();
}

/**
 * Returns the amount of days between two dates
 * @param t1 DateLike should be the earlier date
 * @param t2 DateLike should be the later date
 * @returns number
 *
 */
export function getDateDifference(t1, t2) {
  t1 = toTimestamp(t1);
  t2 = toTimestamp(t2);
  return I18n.moment.utc(getUTCDate(t2)).diff(I18n.moment.utc(getUTCDate(t1)), 'days');
}