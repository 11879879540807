// gimme: conversations-inbox-ui 06/05/2020 c649276db62b7e955c6e3a2ae36dc769071a71f9
'use es6';

import { getMessageIdForCmf } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { isMessageSoftDeleted } from 'conversations-message-history/common-message-format/operators/isMessageSoftDeleted';
import { fetchMessageById } from '../../email-common-message-format/actions/fetchMessageById';
import { getCurrentThreadId } from 'conversations-thread-data/thread-details/public/selectors';
export const fetchMessageReplies = message => (dispatch, getState) => {
  const threadId = getCurrentThreadId(getState());
  const messageId = getMessageIdForCmf(message);
  const includeDeleted = isMessageSoftDeleted(message);
  dispatch(fetchMessageById({
    includeReplies: true,
    messageId,
    threadId,
    includeDeleted
  }));
};