import { connect } from 'react-redux';
import InstallCodePaneWrapper from '../components/InstallCodePaneWrapper';
import { installCodePaneIsVisible } from '../selectors/installCodePaneIsVisible';
import { hideInstallCodePane } from '../actions/showInstallCodePane';
import { fetchInstallStatus } from '../actions/fetchInstallStatus';
const mapStateToProps = state => ({
  paneIsVisible: installCodePaneIsVisible(state)
});
const mapDispatchToProps = {
  hideInstallCodePane,
  fetchInstallStatus
};
export default connect(mapStateToProps, mapDispatchToProps
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof InstallCodePaneWrapper' i... Remove this comment to see the full error message
)(InstallCodePaneWrapper);