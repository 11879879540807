import setIn from 'transmute/setIn';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { getCurrentViewId } from '../../thread-list/selectors/getCurrentViewId';
import { getCurrentInboxId } from 'conversations-inbox-lib/unified-inbox/public/selectors';
import { getSortOrder } from '../selectors/getSortOrder';
import { getSortOrderArray } from '../local-storage/getSortOrderArray';
import { setSortOrderArray } from '../local-storage/setSortOrderArray';
import { setRealtimeSortOrder } from '../actions/setRealtimeSortOrder';
import { useInboxViewMembers } from 'find-and-filter-data/view-members-data/public';
import { getThreadListMemberFilters } from '../../thread-list-member/selectors/getThreadListMemberFilters';
export const useSortOrder = () => {
  const sortOrderValue = useSelector(getSortOrder);
  const inboxId = useSelector(getCurrentInboxId);
  const currentViewId = useSelector(getCurrentViewId);
  const viewFilters = useSelector(getThreadListMemberFilters);
  const dispatch = useDispatch();
  const {
    refetch
  } = useInboxViewMembers({
    inboxId,
    skip: true,
    sortDirection: sortOrderValue,
    threadStatus: viewFilters.threadStatus,
    viewId: currentViewId || undefined
  });
  const setSortOrder = useCallback(sortOrder => {
    const sortOrderArray = getSortOrderArray();
    const updatedSortOrder = setIn(
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'st... Remove this comment to see the full error message
    [inboxId, currentViewId], sortOrder, sortOrderArray);
    setSortOrderArray(JSON.stringify(updatedSortOrder));
    dispatch(setRealtimeSortOrder(updatedSortOrder));
    refetch({
      sortDirection: sortOrder
    });
  }, [dispatch, inboxId, currentViewId, refetch]);
  return {
    sortOrder: sortOrderValue,
    setSortOrder
  };
};