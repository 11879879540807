import { getLatestReceivedTimestamp, getSeen } from 'find-and-filter-data/view-members-schema/protected';
import isUndefined from 'transmute/isUndefined';

/**
 * @description The backend can't always serve information
 * on whether an update has been seen yet. If a member
 * being merged into an existing one has a more recent timestamp,
 * set return a seen value of false. Otherwise, use the seen
 * value defined on the incoming message (if one is defined).
 * @param currentMember
 * @param nextPartialMember
 */
export function resolveSeenFromPartial(currentMember, nextPartialMember) {
  const originalTimestamp = getLatestReceivedTimestamp(currentMember);
  const nextTimestamp = getLatestReceivedTimestamp(nextPartialMember);
  const originalSeen = getSeen(currentMember);
  const nextSeen = getSeen(nextPartialMember);

  // The next member represents a newer value. The default
  // expectation here is that the user hasn't seen this new
  // value yet.
  if (nextTimestamp && originalTimestamp && nextTimestamp > originalTimestamp) {
    return false;
  }

  // Only return the next seen value if the partial defines
  // one (can be null).
  if (!isUndefined(nextSeen)) {
    return Boolean(nextSeen);
  }
  return originalSeen;
}