import { useHubUserSetting } from './useHubUserSetting';
export const SEEN_INBOX_INTERCEPT_SETTINGS_KEY = 'HelpDesk:HasSeenInboxIntercept';
export const useHasSeenInboxIntercept = () => {
  const {
    data,
    loading
  } = useHubUserSetting({
    settingsKey: SEEN_INBOX_INTERCEPT_SETTINGS_KEY,
    defaultValue: 'false'
  });
  if (loading || !data) {
    return true;
  }
  return data === 'true';
};