/* hs-eslint ignored failing-rules */
/* eslint-disable no-duplicate-imports */

'use es6';

import { Set as ImmutableSet, Map as ImmutableMap } from 'immutable';
import { getIsMemberOfForwardedSubthread } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { getId } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { isCommonMessageFormat, isForwardableEmailOrFormCMF } from 'conversations-message-history/common-message-format/operators/cmfComparators';
import { LAST_COMMON_MESSAGE, LAST_NON_FWD_MESSAGE, LAST_READ_RECEIPT_MESSAGE } from 'conversations-message-history/constants/messagePositionDescriptors';
import { isWorkflowGeneratedUserMessage } from 'conversations-message-history/workflow-generated-user-message/operators/isWorkflowGeneratedUserMessage';
export const getAdditionalPositionDescriptors = messages => {
  let descriptors = ImmutableMap();

  // Find descriptor for last message that can display read receipts
  // Currently, CommonMessages and WorkflowGeneratedUserMessages support read receipts
  const lastReadReceiptMessage = messages.findLast(message => isCommonMessageFormat(message) || isWorkflowGeneratedUserMessage(message));

  // Find descriptor for last commom message that is not FWD
  const lastNonFwdCommonMessage = messages.findLast(message => isForwardableEmailOrFormCMF(message) && !getIsMemberOfForwardedSubthread(message));
  const lastCommonMessage = messages.findLast(message => isCommonMessageFormat(message));
  const lastReadReceiptMessageId = getId(lastReadReceiptMessage);
  if (lastReadReceiptMessageId) {
    descriptors = descriptors.update(lastReadReceiptMessageId, (immutableSet = ImmutableSet()) => immutableSet.add(LAST_READ_RECEIPT_MESSAGE));
  }
  const lastNonFwdCommonMessageId = getId(lastNonFwdCommonMessage);
  if (lastNonFwdCommonMessageId) {
    descriptors = descriptors.update(lastNonFwdCommonMessageId, (immutableSet = ImmutableSet()) => immutableSet.add(LAST_NON_FWD_MESSAGE));
  }
  const lastCommonMessageId = getId(lastCommonMessage);
  if (lastCommonMessageId) {
    descriptors = descriptors.update(lastCommonMessageId, (immutableSet = ImmutableSet()) => immutableSet.add(LAST_COMMON_MESSAGE));
  }
  return descriptors;
};