'use es6';

import * as AgentTypes from 'conversations-message-history/common-message-format/constants/agentTypes';
import * as SenderTypes from 'conversations-message-history/common-message-format/constants/legacySenderTypes';
const agentToSenderMapping = {
  [AgentTypes.HUMAN]: SenderTypes.AGENT,
  [AgentTypes.BOT]: SenderTypes.BOT
};
export const getSenderTypeFromAgentType = agentType => {
  return agentToSenderMapping[agentType];
};