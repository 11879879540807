'use es6';

import quickFetch from 'quick-fetch';
export const getEarlyRequesterAsPromise = name => {
  const earlyGraphQLRequest = quickFetch.getRequestStateByName(name);
  if (!earlyGraphQLRequest) return null;
  return new Promise((resolve, reject) => {
    earlyGraphQLRequest.whenFinished(result => {
      resolve(result);
      quickFetch.removeEarlyRequest(name);
    });
    earlyGraphQLRequest.onError(() => {
      reject(earlyGraphQLRequest.request);
      quickFetch.removeEarlyRequest(name);
    });
  });
};