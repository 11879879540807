import { useQuery, useLazyQuery } from 'data-fetching-client';
import { CONTACT_FETCHING_FIELD_NAME, FETCH_CONTACTS, FETCH_CONTACTS_BY_VID } from '../queries/contactFetchingQueries';
export const useFetchContacts = () => {
  const [fetchContacts, {
    data,
    loading,
    error
  }] = useLazyQuery(FETCH_CONTACTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
  const handleFetchContacts = ({
    email
  }) => {
    fetchContacts({
      variables: {
        query: email
      }
    });
  };
  return {
    fetchContacts: handleFetchContacts,
    data: data && data[CONTACT_FETCHING_FIELD_NAME].contacts,
    loading,
    error
  };
};
export const useFetchContactsByVid = ({
  vid,
  portalId,
  skip
}) => useQuery(FETCH_CONTACTS_BY_VID, {
  variables: {
    vid,
    portalId
  },
  skip
});