import { getEmailReplySubject } from './getEmailReplySubject';
import { getEmailForwardSubject } from './getEmailForwardSubject';
export const buildEmailMetadataWithSubject = (attachments, inReplyToMessage, additionalProps) => {
  const {
    associatedTicket,
    isForward,
    channelInstances,
    connectedAccounts,
    userFullName
  } = additionalProps;
  let builtSubject;
  if (inReplyToMessage) {
    builtSubject = isForward ? getEmailForwardSubject(inReplyToMessage) : getEmailReplySubject({
      message: inReplyToMessage
    });
  } else {
    builtSubject = getEmailReplySubject({
      associatedTicket,
      channelInstances,
      connectedAccounts,
      userFullName
    });
  }
  if (builtSubject) attachments['subject'] = builtSubject;
  return attachments;
};