import { getChannel, getViewInternalType, isThreadList } from 'find-and-filter-data/views-schema/public';
import { Map as ImmutableMap } from 'immutable';
import { createSelector } from 'reselect';
import { AsyncStatus } from '../../../common/public';
const selectViewsState = state => state.findAndFilterData.views;

/**
 * @description This function decouples ThreadLists that have been
 * been duplicated as CustomViews and prioritizes the ThreadList objects.
 * When Default CustomViews are stable this will prioritize the CustomView and
 * eventually this will be deleted when we no longer support ThreadLists
 */
export function removeDuplicatedThreadLists(views) {
  const threadListTypes = new Set();
  views.forEach(view => {
    if (isThreadList(view) && getViewInternalType(view)) {
      threadListTypes.add(getViewInternalType(view));
    }
  });
  return views.filter(view => !threadListTypes.has(getViewInternalType(view)) || isThreadList(view)).toMap();
}

/**
 * A selector to determine if we have successfully retrieved views.
 */
export const selectHasViewsFetchFailed = createSelector([selectViewsState], viewsState => {
  return viewsState.status === AsyncStatus.FAILED;
});
export const selectIsViewsFetchInProgress = createSelector([selectViewsState], viewsState => {
  return viewsState.status === AsyncStatus.INITIAL_FETCH_LOADING;
});
export const selectViewsStatus = createSelector([selectViewsState], viewsState => viewsState.status);

/**
 * A selector that returns the mapped views.
 */
export const selectViews = createSelector([selectViewsState], viewsState => {
  // convert to Immutable for downstream dependencies
  // TODO - remove ImmutableMap creation here
  return removeDuplicatedThreadLists(ImmutableMap(viewsState.data));
});
const getViewIdFromProps = (state, {
  viewId
}) => viewId;

/**
 * @description `selectViewRealtimeChannel` will take a viewId and return the
 * realtime channel for that particular view.
 */
export const selectViewRealtimeChannel = createSelector([selectViewsState, getViewIdFromProps], (viewsState, viewId) => {
  const view = viewsState.data[viewId];
  return getChannel(view);
});
export const selectPendingFetchViewsParams = createSelector([selectViewsState], viewsState => viewsState.pendingFetchParams);
export const selectLastResolvedFetchViewsParams = createSelector([selectViewsState], viewsState => viewsState.lastResolvedFetchParams);