import { UUID } from 'shared-worker-versioning/lib/utils/UUID';
import { CROSS_TAB_MESSAGE_IDENTIFIER } from '../constants/CrossTabMessageKey';
import { WEB_WORKER_VERSION } from '../constants/WebWorkerVersion';
export function addBaseMessageProperties(messageRelayType) {
  return {
    format: CROSS_TAB_MESSAGE_IDENTIFIER,
    id: UUID(messageRelayType),
    metadata: {
      messageRelayType
    },
    version: WEB_WORKER_VERSION
  };
}