import { isLoading } from 'conversations-async-data/async-data/operators/statusComparators';
// @ts-expect-error module not typed
import { fileAttachmentIdsInMessage } from 'conversations-message-history/common-message-format/operators/fileAttachmentIdsInMessage';
export const getAsyncAttachmentState = ({
  message,
  loadedFileAttachments,
  indexedAsyncFileAttachments
}) => {
  const asyncAttachmentState = {
    isLoadingAttachment: false,
    isMissingAttachment: false
  };
  return fileAttachmentIdsInMessage(message).reduce((state, id) => {
    if (state.isMissingAttachment && state.isLoadingAttachment) return state;

    // loadedFileAttachments *only* includes files *with* metadata
    // the request can be SUCCEEDED with no file metadata present
    const loadedAttachment = loadedFileAttachments.get(`${id}`);
    if (loadedAttachment) return state;

    // if a file id is not found in indexedAsyncFileAttachments
    // a request has not been made yet, so set isLoading: true
    // this handles the gap between when a message finishes loading
    // but it has not yet resolved its file attachments
    const asyncAttachment = indexedAsyncFileAttachments.get(`${id}`);
    const isLoadingAttachment = isLoading(asyncAttachment);
    if (asyncAttachment && !isLoadingAttachment) {
      state.isMissingAttachment = true;
    } else {
      state.isLoadingAttachment = true;
    }
    return state;
  }, asyncAttachmentState);
};