import { Record } from 'immutable';
export const UserInfo = Record({
  partner: false,
  verified: false,
  created_at: 0,
  user_id: 0,
  lang_enabled: false,
  last_name: '',
  teams: [],
  emails: [],
  locale: '',
  first_name: '',
  email: '',
  roles: [],
  scopes: []
}, 'UserInfo');