import { EDITOR_STATE_DRAFT_VERSION, EDITOR_STATE_DRAFT_KEY_PREFIX } from '../constants/editorStateDraftConstants';
import { deserializeEditorState } from '../operators/deserializeEditorStateDraft';
import { getRawContentFromEditorState } from '../operators/getRawContentFromEditorState';
import { editorDraftHasContent } from '../operators/editorDraftHasContent';
import { useDraft } from './useDraft';
export const useEditorStateDraft = id => {
  return useDraft({
    id,
    localStorageKeyPrefix: EDITOR_STATE_DRAFT_KEY_PREFIX,
    currentVersion: EDITOR_STATE_DRAFT_VERSION,
    deserialize: deserializeEditorState,
    serialize: getRawContentFromEditorState,
    emptyChecker: editorDraftHasContent
  });
};