'use es6';

import memoize from 'transmute/memoize';
import { cleanEmail } from '../../email/sanitize/cleanEmail';

/**
 * @description Given a raw html string, evaluate the content
 * to determine if there are inline images
 * This can be used to determine if html should be rendered
 * for the sake of inline images
 */
export const hasImageContent = memoize(html => {
  if (!html) return null;
  const container = document.createElement('div');
  container.innerHTML = cleanEmail(html);
  document.body.appendChild(container);
  const images = container.querySelectorAll('img');
  document.body.removeChild(container);
  return images && images.length > 0;
});