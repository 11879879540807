module.exports = {
  "qualifiedLeadsSidebarCardView": {
    "name": "QL sidebar card view",
    "class": "view",
    "namespace": "realtime-reps-qualified-leads",
    "version": "1",
    "properties": {
      "action": "string",
      "applicationName": "string",
      "leadContactVid": "number"
    }
  }
};