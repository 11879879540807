import { getAssignees } from './getAssignees';
import { searchAssignees } from './searchAssignees';
export const fetchAssignees = ({
  inboxId,
  searchQuery,
  offset
}) => {
  if (searchQuery) {
    return searchAssignees({
      inboxId,
      searchQuery,
      offset
    });
  }
  return getAssignees({
    inboxId,
    offset
  });
};