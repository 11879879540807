'use es6';

import pipe from 'transmute/pipe';
import Sender from 'conversations-message-history/senders/records/Sender';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { getAgentSenders } from '../clients/getAgentSenders';
import { SENDERS_FETCH } from '../constants/asyncActionTypes';
import { indexSenders } from '../operators/indexSenders';
export const fetchAgentSenders = createAsyncAction({
  requestFn: getAgentSenders,
  actionTypes: SENDERS_FETCH,
  toRecordFn: pipe(sendersObjArr => sendersObjArr.map(Sender), indexSenders)
});