import http from 'conversations-http/clients/http';
const AGENT = 'AGENT';
export const createFileMapping = ({
  threadId,
  fileId
}) => {
  return http.post(`conversations-threads/v2/attachment/map/thread/${threadId}`, {
    data: [fileId],
    query: {
      senderType: AGENT
    }
  });
};