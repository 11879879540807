import { buildThreadListMember } from 'find-and-filter-data/view-members-schema/protected';
import { List } from 'immutable';
import get from 'transmute/get';
import map from 'transmute/map';
import setIn from 'transmute/setIn';
const getSeenByAgentIds = get('seenByAgentIds');
const buildThreadAdded = currentAgentId => addedThreadListMember => {
  let threadListMember = buildThreadListMember(addedThreadListMember);
  const seenAgentIds = getSeenByAgentIds(addedThreadListMember);
  if (!!seenAgentIds && seenAgentIds.includes(currentAgentId)) {
    threadListMember = setIn(['seen'], true, threadListMember);
  }
  return threadListMember;
};
export const buildThreadsAdded = (added, currentAgentId) => {
  return List(map(buildThreadAdded(currentAgentId), added));
};