import http from 'conversations-http/clients/http';
import { mapUsersResponseToAtMentionUsers } from '../operators/mapUsersResponseToAtMentionUsers';
import Raven from 'raven-js';
const getUserOutOfOfficeData = userIds => http.put('conversations-assignments/v1/out-of-office/statuses', {
  data: userIds
});
const getUsers = ({
  inboxId
}) => http.get(`messages/v2/users`, {
  query: {
    inboxId,
    includeSecondaryTeamMembers: true
  }
});
export const getAtMentionsCapabilityData = async ({
  inboxId
}) => {
  try {
    const users = await getUsers({
      inboxId
    });
    const userIds = [...users.routableUsers, ...users.freeUsers, ...users.adminUsers].map(user => user.id);
    const usersWithOOOHours = await getUserOutOfOfficeData(userIds);
    return {
      users: mapUsersResponseToAtMentionUsers(users, usersWithOOOHours)
    };
  } catch (e) {
    Raven.captureException(e);
    return {
      users: []
    };
  }
};