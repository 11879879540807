/* eslint-disable-next-line conversations/no-private-internals-import */
import { usePojoGenericChannels } from '../../../generic-channels/_internal/hooks/usePojoGenericChannels';
export const useChannelName = genericChannelId => {
  const {
    data: genericChannels
  } = usePojoGenericChannels();
  const genericChannel = genericChannels && genericChannels.find(channel => channel.genericChannel.id === genericChannelId);
  const {
    displayName: channelName
  } = genericChannel || {};
  return channelName;
};