'use es6';

import { getMessage } from '../../unpublished-messages/operators/getters';
import { COMMON_MESSAGE } from '../../common-message-format/constants/messageTypes';
import { TYPING } from '../../typing-indicator/constants/messageTypes';
import { getType } from '../../common-message-format/operators/commonMessageFormatGetters';
import { areFromSameSender } from './areFromSameSender';
import { areNMsApart } from './areNMsApart';
import { getGenericChannelIdForMessage } from '../../common-message-format/operators/commonMessageGetters';
const areCombinationOfTypes = (messageTypeA, messageTypeB, targetTypeA, targetTypeB) => {
  return messageTypeA === targetTypeA && messageTypeB === targetTypeB || messageTypeA === targetTypeB && messageTypeB === targetTypeA;
};
const areGroupableTypes = (messageA, messageB) => {
  const typeA = getType(messageA);
  const typeB = getType(messageB);
  return typeA === typeB || areCombinationOfTypes(typeA, typeB, COMMON_MESSAGE, TYPING);
};
const areSameChannel = (messageA, messageB) => {
  const channelA = getGenericChannelIdForMessage(messageA);
  const channelB = getGenericChannelIdForMessage(messageB);
  return channelA === channelB;
};
const ONE_MINUTE_MILLISECONDS = 60 * 1000;
const areOneMinuteApart = areNMsApart(ONE_MINUTE_MILLISECONDS);
export const areMessagesGrouped = (message, adjacentMessage) => {
  message = getMessage(message) || message;
  adjacentMessage = getMessage(adjacentMessage) || adjacentMessage;
  return areFromSameSender(message, adjacentMessage) && areGroupableTypes(message, adjacentMessage) && areSameChannel(message, adjacentMessage) && !areOneMinuteApart(message, adjacentMessage);
};