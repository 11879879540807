import { useDataFetchingClient } from 'data-fetching-client';
import { useAblyChannel } from '../../pubsub/hooks/useAblyChannel';
import { getCurrentPortalChannel } from '../../pubsub/utils';
import { AWAY_MODE_CHANGE } from '../constants';
import { updateAgentStatusFromRealtimeMessage } from '../operators/updateAgentStatusFromRealtimeMessage';
import { GET_AGENT_STATUS } from '../queries/agentStatusQueries';
import Raven from 'raven-js';

// This hook is meant to be used alongside useAgentStatus, or with fetch calls to the agent-status endpoint
export const useRealtimeAgentStatus = (agentId, workspace) => {
  const portalChannel = getCurrentPortalChannel();
  const dfcClient = useDataFetchingClient();
  useAblyChannel({
    channel: portalChannel,
    type: AWAY_MODE_CHANGE,
    onMessage: message => {
      if (agentId === message.agentId) {
        updateAgentStatusFromRealtimeMessage({
          client: dfcClient,
          message,
          workspace
        });
      }
    },
    onPlayback: messages => {
      messages.forEach(message => {
        if (agentId === message.agentId) {
          updateAgentStatusFromRealtimeMessage({
            client: dfcClient,
            message,
            workspace
          });
        }
      });
    },
    onReconnect: () => {
      dfcClient.refetchQueries({
        include: [GET_AGENT_STATUS]
      }).catch(e => Raven.captureException(e));
    }
  });
};