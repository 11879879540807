const FORM = 'FORM';
const FEEDBACK = 'FEEDBACK';
const FORM_OBJECT_ID = '0-15';
const FEEDBACK_OBJECT_ID = '0-19';
const sourceType = {
  [FORM_OBJECT_ID]: FORM,
  [FEEDBACK_OBJECT_ID]: FEEDBACK
};

/**
 * Translates a sourceObjectTypeId into a publish source
 *
 * @param {string} [sourceObjectTypeId]
 * @returns {string | undefined}
 */
export const getMessagePublishSourceByObjectId = (sourceObjectTypeId = null) => sourceObjectTypeId ? sourceType[sourceObjectTypeId] : undefined;