import http from 'conversations-http/clients/http';
import { convertFiltersToSearchArgs } from './convertFiltersToSearchArgs';
export const doesThreadMatchFilters = ({
  threadId,
  filterValues,
  inboxId
}) => {
  const {
    assignment = null,
    endTimestamp = null,
    hideFiltered = true,
    searchQuery = null,
    startTimestamp = null,
    genericChannelIds = null,
    threadStatus = null,
    vids = null
  } = convertFiltersToSearchArgs(filterValues);
  return http.put(`messages/v2/threadlist/member/${threadId}/details/match`, {
    data: {
      assignment,
      endTimestamp,
      hideFiltered,
      inboxId,
      searchQuery,
      startTimestamp,
      genericChannelIds,
      threadStatus,
      vids
    }
  });
};