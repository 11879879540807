import get from 'transmute/get';
import { CustomViewStatus } from './customViewTypes';
export function getCustomViewIdForStatusChange(statusChange) {
  return get('customViewId', statusChange);
}
export function getCustomViewMessageStatus(statusChange) {
  return get('status', statusChange);
}

/**
 * @description Given a CustomViewStatusChange message, this function determines
 * if the custom view is now in a processing completed state.
 */
export function isProcessingCompletedMessage(statusChange) {
  const status = getCustomViewMessageStatus(statusChange);
  return status === CustomViewStatus.CreatedAndComplete || status === CustomViewStatus.UpdatedAndComplete;
}