import { TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import get from 'transmute/get';
import set from 'transmute/set';
import { getIsUserEditable, isDefaultView, getListProcessingState, getCreatedByUserId, getLatestListRefreshEndedAt, getObjectTypeId } from '../custom-views/customViewOperators';
import { ProcessingState } from '../custom-views/customViewTypes';
import { getViewInternalType } from './getViewInternalType';
import { generateViewId } from './viewIdOperators';
const getProperty = property => view => get(property, view);

/**
 * @description Returns the PubSub channel for the given view
 */
export const getChannel = getProperty('channel');

/**
 * @description Returns ID for the given view
 */
export const getId = view => {
  // ids are only generated for the immutable records
  const generatedId = get('id', view);
  return generatedId || generateViewId(view);
};

/**
 * @description Returns the display name
 */
export const getDisplayName = getProperty('name');

/**
 * @description Returns the direction the view is sorted in
 */
export const getSortDirection = getProperty('sortDirection');
/**
 * @description Returns the thread count for the view - returns null for views
 * we don't render a thread count for.
 */
export const getThreadCount = getProperty('threadCount');
/**
 * @description Returns the unread thread count for the view - returns null for
 * views we don't render a thread count for.
 */
export const getUnreadThreadCount = getProperty('unreadThreadCount');

/**
 * @description returns whether the given view is a CustomView
 */
export const isCustomView = view => {
  return view ? 'customViewId' in view : false;
};

/**
 * @description returns whether the given view is a ThreadList
 */
export const isThreadList = view => {
  return view ? 'threadListId' in view : false;
};
export function isFilteredView(view) {
  return getViewInternalType(view) === 'FILTERED';
}
export function isTrashView(view) {
  return getViewInternalType(view) === 'TRASH';
}
export function isSentView(view) {
  return getViewInternalType(view) === 'SENT';
}
export function isAllOpenView(view) {
  return getViewInternalType(view) === 'ALL_CONVERSATIONS';
}
export function isAllOpenTicketView(view) {
  return getViewInternalType(view) === 'ALL_OPEN_TICKETS';
}
export function isAssignedToMeView(view) {
  return getViewInternalType(view) === 'ASSIGNED_TO_ME';
}
export function isUnassignedView(view) {
  return getViewInternalType(view) === 'UNASSIGNED';
}
export function isAllClosedView(view) {
  return getViewInternalType(view) === 'ALL_CLOSED';
}
export function isUserEditable(view) {
  return Boolean(view) && isCustomView(view) && getIsUserEditable(view);
}

/**
 * @description Returns true if the given view can be edited by a user, false if
 * not or if the input is a thread or null.
 */
export function isUserGeneratedCustomView(view) {
  return Boolean(view) && isCustomView(view) && isDefaultView(view);
}

/**
 * @description Returns true if the given custom views associated list processing
 * state is `Processing`, false if not, or if the input is a thread or null.
 */
export function isProcessing(view) {
  return Boolean(view) && isCustomView(view) && getListProcessingState(view) === ProcessingState.Processing;
}

/**
 * @description Returns true if the given custom views associated list processing
 * state is `Completed`, false if not, or if the input is a thread or null.
 */
export function isCompleted(view) {
  return Boolean(view) && isCustomView(view) && getListProcessingState(view) === ProcessingState.Completed;
}

/**
 * @description Returns true if the given view was created by the given userId,
 * false if not or if the view provided is a thread or null
 */
export function isCreatedByUser(view, userId) {
  return Boolean(view) && isCustomView(view) && getCreatedByUserId(view) === userId;
}

/**
 * @description Returns true if the given view is a newly created view that is still
 * processing, false if not, or if the provided view is a thread or null.
 */
export function isNewlyCreatedProcessingView(view) {
  return Boolean(view) && isCustomView(view) && !getLatestListRefreshEndedAt(view);
}
export const isTicketCustomView = view => {
  if (isThreadList(view)) return false;
  return getObjectTypeId(view) === TICKET_TYPE_ID;
};
export const setChannel = set('channel');
export const setId = set('id');
export const setDisplayName = set('name');
export const setSortDirection = set('sortDirection');
export const setThreadCount = set('threadCount');
export const setUnreadThreadCount = set('unreadThreadCount');