export const CALLING_MINUTES_PER_PORTAL = 'calling-minutes-per-portal';
export const CALLING_MINUTES_PER_SEAT = 'calling-minutes-per-seat';
export let CallingLimitType;
(function (CallingLimitType) {
  CallingLimitType["FREE"] = "free";
  CallingLimitType["STARTER"] = "starter";
  CallingLimitType["PRO"] = "pro";
})(CallingLimitType || (CallingLimitType = {}));
export const CALLING_LIMITS = {
  [CallingLimitType.FREE]: {
    type: CallingLimitType.FREE,
    warningThreshold: 5
  },
  [CallingLimitType.STARTER]: {
    type: CallingLimitType.STARTER,
    warningThreshold: 50
  },
  [CallingLimitType.PRO]: {
    type: CallingLimitType.PRO,
    warningThreshold: 100
  }
};