import compose from 'transmute/compose';
import get from 'transmute/get';
import { isSucceeded as getIsSucceeded, isFailed, isOutOfSync, isStarted } from 'conversations-async-data/async-data/operators/statusComparators';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { useProductInfo } from './useProductInfo';
import { useProductInfoOutOfSync } from './useProductInfoOutOfSync';
import either from 'transmute/either';
/* --------- Helper fns for hook ------------ */
const getIsFetching = either(isOutOfSync, isStarted);
const getHubSalesProductData = asyncData => getData(asyncData);
const add = (a, b) => a + b;
const sum = arr => arr.reduce(add, 0);
const mapToCurrentSeats = salesProducts => salesProducts.map(salesProduct => get('currentSeatCount', salesProduct));
const getSalesProducts = record => get('salesProducts', record);
const getCurrentSeatCount = asyncData => compose(sum, mapToCurrentSeats, getSalesProducts, getHubSalesProductData)(asyncData);
const lengthReducer = (total, current) => {
  return total + current.length;
};
const sumLengths = assignedUserIds => assignedUserIds.reduce(lengthReducer, 0);
const mapToAssignedUserIds = salesProductSeatAssignments => salesProductSeatAssignments.map(assignment => get('assignedUserIds', assignment));
const getSalesProductSeatAssignments = record => get('salesProductSeatAssignments')(record);
const getCurrentAssignedSeatCount = asyncData => compose(sumLengths, mapToAssignedUserIds, getSalesProductSeatAssignments, getHubSalesProductData)(asyncData);
const getUnassignedSeatCount = asyncData => getCurrentSeatCount(asyncData) - getCurrentAssignedSeatCount(asyncData);

/* --------- End Helper fns for hook ------------ */

export const useUnassignedSeats = () => {
  const [state, dispatch] = useProductInfo();
  useProductInfoOutOfSync(dispatch);
  const isSucceeded = getIsSucceeded(state);
  const isFetching = getIsFetching(state);
  const unassignedSeatCount = isSucceeded ? getUnassignedSeatCount(state) : null;
  return {
    isFetching,
    isFailed: isFailed(state),
    unassignedSeatCount
  };
};