import { useReduxAsyncFetcher } from 'conversations-async-data/async-data/hooks/useReduxAsyncFetcher';
import { fetchChatflow } from '../actions/fetchChatflow';
import { getAsyncChatFlowById } from '../selectors/chatflowSelectors';
export const useChatflows = ({
  chatflowId
}) => {
  const {
    asyncData: asyncChatflow
  } = useReduxAsyncFetcher({
    action: fetchChatflow,
    selector: getAsyncChatFlowById,
    id: chatflowId,
    idTransform: id => ({
      chatflowId: id
    })
  });
  return {
    asyncChatflow
  };
};