import { QUARANTINED } from 'conversations-message-history/common-message-format/constants/statusTypes';
// @ts-expect-error module not typed
import { fileAttachmentIdsInMessage } from 'conversations-message-history/common-message-format/operators/fileAttachmentIdsInMessage';
import { FILES, UNSUPPORTED_CONTENT } from 'conversations-message-history/common-message-format/constants/attachmentTypes';
import { formatCommonAttachmentData } from './formatCommonAttachmentData';
import { formatResolvedAttachmentsData } from './formatResolvedAttachmentsData';
export const formatDirectReplyData = ({
  data,
  isMissingFileAttachment = false,
  resolvedFileAttachments,
  senderName
}) => {
  const isQuarantined = data.messageStatus === QUARANTINED;
  const messageText = data.truncatedText;
  const previewAttachment = data.attachments && data.attachments.first();
  const previewAttachmentIsUnsupportedContent = previewAttachment ? previewAttachment['@type'] === UNSUPPORTED_CONTENT : false;
  if (!previewAttachment || previewAttachmentIsUnsupportedContent) {
    return {
      attachmentPreview: {
        additionalAttachmentCount: 0,
        hasMissingAttachment: previewAttachmentIsUnsupportedContent
      },
      isQuarantined,
      messageText,
      senderName
    };
  }
  const previewAttachmentIsFile = previewAttachment['@type'] === FILES;

  // Grab the file attachment ids if they exist
  const fileIdsInMessage = fileAttachmentIdsInMessage(data);
  const messageAttachmentCount = data.attachments ? data.attachments.size : 0;
  const fileIdsInMessageCount = fileIdsInMessage && fileIdsInMessage.size;
  const resolvedFileAttachmentCount = resolvedFileAttachments && resolvedFileAttachments.size;

  // Subtracting:
  // -1 if including the '[@TYPE]: FILES' attachment
  // -1 for the attachment getting previewed
  const additionalAttachmentCount = fileIdsInMessageCount ? fileIdsInMessageCount + messageAttachmentCount - 2 : messageAttachmentCount - 1;
  const attachmentPreview = {
    additionalAttachmentCount,
    hasMissingAttachment: isMissingFileAttachment
  };
  if (previewAttachmentIsFile && resolvedFileAttachmentCount) {
    const {
      icon,
      name,
      type,
      url
    } = formatResolvedAttachmentsData(resolvedFileAttachments);
    Object.assign(attachmentPreview, {
      icon,
      name,
      type,
      url
    });
  }
  if (previewAttachment && !previewAttachmentIsFile) {
    const {
      icon,
      type
    } = formatCommonAttachmentData(previewAttachment);
    Object.assign(attachmentPreview, {
      icon,
      type
    });
  }
  return {
    attachmentPreview,
    isQuarantined,
    messageText,
    senderName
  };
};