import forEach from 'transmute/forEach';
import { useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getInitialThreadViewState } from 'conversations-inbox-lib/initialThreadView/public/selectors';
import { useTicketRemovalWatcher } from '../../tickets/hooks/useTicketRemovalWatcher';
// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'currentThreadId' implicitly has a... Remove this comment to see the full error message
export const useThreadViewRefSyncing = ({
  threadId: currentThreadId
}) => {
  const threadViewRef = useRef(null);
  const isMount = useRef(true);
  const initialThreadView = useSelector(getInitialThreadViewState);

  // When the omnibus data change (hard refresh of
  // the entire app), hard-overwrite thread view
  // data with the new omnibus data.

  // NOTE: this will overwrite the thread history
  // for the specified threadId.
  useEffect(() => {
    if (!isMount.current && initialThreadView && initialThreadView.threadDetails && initialThreadView.threadHistory && initialThreadView.threadId && threadViewRef.current && threadViewRef.current.hardRefresh) {
      threadViewRef.current.hardRefresh({
        threadDetails: initialThreadView.threadDetails,
        threadHistory: initialThreadView.threadHistory,
        omnibusThreadId: initialThreadView.threadId
      });
    }
  }, [initialThreadView]);

  // manually notify the thread view on ticket disassociations
  const onTicketsRemoved = useCallback(ticketIds => {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    if (threadViewRef.current.ticketDisassociated) {
      forEach(ticketId =>
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      threadViewRef.current.ticketDisassociated({
        threadId: currentThreadId,
        ticketId
      }), ticketIds);
    }
  }, [currentThreadId]);
  useTicketRemovalWatcher({
    threadId: currentThreadId,
    onTicketsRemoved
  });
  useEffect(() => {
    isMount.current = false;
  });
  return threadViewRef;
};