'use es6';

import { createAction } from 'redux-actions';
import { MESSAGE_REFRESH } from '../constants/asyncActionTypes';
export const handleMessageRefresh = createAction(MESSAGE_REFRESH, ({
  messageId,
  threadId
}) => ({
  messageId,
  threadId
}));