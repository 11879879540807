import { registerMutation } from 'data-fetching-client';
import apiClient from 'hub-http/clients/apiClient';
// @ts-expect-error dependency missing types
import { catchAndRethrowNetworkError } from 'crm_data/bCommerce/monitoring/errorUtils';

// API docs: https://tools.hubteam.com/api-catalog/services/PaymentsSubscriptionsService/v1/spec/internal#operations-Subscriptions-post-%2Fpayments-subscriptions%2Fv1%2Fsubscriptions%2Fcrm%2F%7BobjectId%7D%2Funpause
export function resumeSubscription({
  objectId,
  proposedNextBillingDate
}) {
  return apiClient.post(`payments-subscriptions/v1/subscriptions/crm/${objectId}/unpause`, {
    data: {
      proposedNextBillingDate
    }
  }).catch(catchAndRethrowNetworkError);
}
export const RESUME_SUBSCRIPTION = registerMutation({
  fieldName: 'response',
  args: ['objectId', 'proposedNextBillingDate'],
  fetcher: resumeSubscription
});