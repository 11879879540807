export const getNavigateToContactRecord = () => ({
  key: 'clicked-to-view-contact-record',
  properties: {
    action: 'Clicked to view Contact Record'
  }
});
export const getNavigateToCompanyRecord = isTicketHeader => ({
  key: 'clicked-to-view-company-record',
  properties: {
    action: 'Clicked to view Company Record',
    source: isTicketHeader ? 'Ticket Header' : 'Thread Header'
  }
});
export const getClickToCopyTicketNumber = () => ({
  key: 'clicked-to-copy-ticket-number',
  properties: {
    action: 'Clicked to copy the ticket number'
  }
});
export const getClickToToggleContextualSidebar = () => ({
  key: 'clicked-to-toggle-contextual-sidebar',
  properties: {
    action: 'Clicked to toggle Contextual Sidebar'
  }
});