/* eslint-disable hs-react-native/no-web-globals */

export function getItem(key, initialValue) {
  try {
    const localStorageValue = window.localStorage.getItem(key);
    if (typeof localStorageValue === 'string') {
      return localStorageValue;
    }
    if (typeof initialValue === 'string') {
      window.localStorage.setItem(key, initialValue);
      return initialValue;
    }
    return null;
  } catch (error) {
    return typeof initialValue === 'string' ? initialValue : null;
  }
}
export const setItem = (key, value) => {
  try {
    window.localStorage.setItem(key, value);
  } catch (error) {
    // do nothing
  }
};
export const removeItem = key => {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    //do nothing
  }
};