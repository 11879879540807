import { GLOBAL_NAME } from '../constants/Globals';
export const getWindowPathName = () => window.location.pathname;
export const getOrigin = () => {
  const origin = window.location.origin ||
  // IE
  `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
  let url = window.location.href;
  if (url.indexOf('//') === 0) {
    url = `${window.location.protocol}${url}`;
  } else if (url.indexOf('/') === 0) {
    return origin;
  }
  const parts = url.split('://');
  if (parts.length > 1) {
    return `${parts[0]}://${parts[1].split('/')[0]}`;
  }
  return origin;
};
export const addMessageEventListener = callback => {
  window.addEventListener('message', callback);
};
export const postParentMessage = (message, origin) => {
  window.parent.postMessage(message, origin);
};
export const postMessageToSourceIframe = (replyToMessage, message, origin) => {
  const iframe = document.querySelector(`iframe[src*="${replyToMessage.from}"]`);
  if (iframe) {
    iframe.contentWindow.postMessage(message, origin);
  }
};
export const getApiMethodFromGlobal = method => window.top ? window.top[GLOBAL_NAME][method] : undefined;
export const getIsHsCallingGlobalReady = () => window.top ? window.top[GLOBAL_NAME] && !!window.top[GLOBAL_NAME].isReady : false;
export const enqueueHSCallingGlobalMethod = callback => {
  if (!window.top) return;
  window.top.hsCallingOnReady = window.top.hsCallingOnReady || [];
  window.top.hsCallingOnReady.push(callback);
};
export const getWindowLocation = () => {
  return window.location;
};
export const getParentWindowLocationHref = () => {
  try {
    return window.parent.location.href;
  } catch (e) {
    return false;
  }
};
export const getReferrer = () => {
  try {
    return document.referrer;
  } catch (e) {
    return false;
  }
};
export const getParentWindowLocation = () => {
  return window.parent.location;
};
export const getHasWindowTopAccess = () => {
  try {
    return getWindowLocation() === getParentWindowLocation();
  } catch (e) {
    return false;
  }
};