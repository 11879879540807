export const getViewFromPathname = (pathname, isViewIdValid) => {
  const match = pathname.match(/view\/([a-zA-Z\d]+)/);
  if (!match || match.length < 2 || !isViewIdValid) {
    return null;
  }
  return match[1];
};
export const getTicketFromPathname = pathname => {
  const match = pathname.match(/ticket\/(\d+)/);
  if (!match || match.length < 2) {
    return undefined;
  }
  return Number(match[1]);
};
export const getThreadFromPathname = pathname => {
  const match = pathname.match(/thread\/(\d+)/);
  if (!match || match.length < 2) {
    return undefined;
  }
  return match[1];
};