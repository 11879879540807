import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestOutOfSync } from 'conversations-async-data/async-data/operators/requestOutOfSync';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { fetchProductInfoActionTypes } from '../constants/productInfoActionTypes';
import { useReducerWithThunks } from './useReducerWithThunks';

// @ts-expect-error ts-migrate(2348) FIXME: Value of type 'new <AsyncDataValue>(t?: Partial<As... Remove this comment to see the full error message
export const initialAsyncState = AsyncData({
  data: undefined
});
export const productInfoReducer = (state, action) => {
  switch (action.type) {
    case fetchProductInfoActionTypes.STARTED:
      return requestStarted(state);
    case fetchProductInfoActionTypes.SUCCEEDED:
      return requestSucceededWithOperator(() => {
        // this is a recreaction of
        // https://git.hubteam.com/HubSpot/ui-addon-conversations-settings/blob/9a215fa853411af86c2d4b47c46882a881dcccc2/static/js/utils/assert.ts
        if (action.payload === undefined) {
          throw new Error('value must be defined');
        }
        return action.payload.data;
      }, state);
    case fetchProductInfoActionTypes.FAILED:
      return requestFailed(state);
    case fetchProductInfoActionTypes.OUT_OF_SYNC:
      return requestOutOfSync(state);
    default:
      return state;
  }
};
export const useProductInfoReducer = () => useReducerWithThunks(productInfoReducer, initialAsyncState);