'use es6';

import { buildError } from 'conversations-error-reporting/error-reporting/builders/buildError';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import memoize from 'transmute/memoize';
import { buildSanitizer } from './buildSanitizer';
import { sanitizeConfig } from './SanitizeConfig';
import { secureDocument } from './secureDocument';
import { removeGmailLineBreak } from './removeGmailLineBreak';
const {
  body: element
} = secureDocument;
export const sanitizeEmail = memoize((plainContent, transformers) => {
  const div = secureDocument.createElement('div');
  try {
    element.innerHTML = plainContent;
    const newConfig = Object.assign({}, sanitizeConfig);
    newConfig.transformers = transformers;
    const sanitizedEmail = buildSanitizer(newConfig).clean_node(element).cloneNode(true);
    div.appendChild(sanitizedEmail);
    removeGmailLineBreak(div);
  } catch (e) {
    const error = buildError(`Error sanitizing email message: ${e.message}`, e);
    reportError({
      error
    });
  }
  return div.innerHTML;
});