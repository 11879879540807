'use es6';

import { List } from 'immutable';
import getIn from 'transmute/getIn';
import { getHubSpotCallingProvider, getTwilioCallingProvider } from './callProviderOperators';
function addTwilioBasedWidgets(result, hasTwilioConnect) {
  result = result.push(getHubSpotCallingProvider());
  if (hasTwilioConnect) {
    result = result.push(getTwilioCallingProvider());
  }
  return result;
}
export function getCallProviders({
  hasTwilioConnect
}) {
  let providers = List();
  providers = addTwilioBasedWidgets(providers, hasTwilioConnect);
  return providers;
}

// omnibusResponse url: /twilio/v1/settings
export default function getCallProvidersFromOmnibus(omnibusResponse) {
  const initialLoadSettings = getIn(['initialLoadSettings'], omnibusResponse);
  const hasTwilioConnect = initialLoadSettings.connectNumbers && initialLoadSettings.connectNumbers.length > 0;
  return getCallProviders({
    hasTwilioConnect
  });
}