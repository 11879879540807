import http from 'conversations-http/clients/http';
import { registerQuery, useQuery } from 'data-fetching-client';
const FETCH_SESSION_QUOTA_QUERY = registerQuery({
  fieldName: 'sessionQuota',
  args: ['genericChannelId', 'senderDeliveryIdentifier', 'recipientDeliveryIdentifiers'],
  fetcher({
    genericChannelId,
    senderDeliveryIdentifier,
    recipientDeliveryIdentifiers
  }) {
    return http.put('conversations-channels/v1/quota', {
      data: {
        genericChannelId,
        senderDeliveryIdentifier,
        recipientDeliveryIdentifiers
      }
    });
  }
});
export function useSessionQuota({
  genericChannelId,
  senderDeliveryIdentifier,
  recipientDeliveryIdentifiers,
  skip
}) {
  const invalidArgs = !senderDeliveryIdentifier || !recipientDeliveryIdentifiers || !recipientDeliveryIdentifiers.length;
  const {
    data,
    loading,
    error
  } = useQuery(FETCH_SESSION_QUOTA_QUERY, {
    variables: {
      genericChannelId,
      senderDeliveryIdentifier,
      recipientDeliveryIdentifiers: recipientDeliveryIdentifiers !== null && recipientDeliveryIdentifiers !== void 0 && recipientDeliveryIdentifiers[0] ? [recipientDeliveryIdentifiers[0]] : []
    },
    skip: skip || invalidArgs
  });
  return {
    sessionQuota: data === null || data === void 0 ? void 0 : data.sessionQuota,
    loading,
    error
  };
}