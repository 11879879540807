'use es6';

import memoize from 'transmute/memoize';
import { OrderedMap } from 'immutable';
const objectNameMatchesSearchText = memoize((searchText, associationOptionRecord) => {
  const primaryDisplayLabelLowerCase = associationOptionRecord.get('primaryDisplayLabel').toLowerCase();
  const secondaryDisplayLabel = associationOptionRecord.get('secondaryDisplayLabel');
  const searchTextLowerCase = searchText.toLowerCase();
  return primaryDisplayLabelLowerCase.includes(searchTextLowerCase) || secondaryDisplayLabel && secondaryDisplayLabel.toLowerCase().includes(searchTextLowerCase);
});
const getAssociationRecordsThatMatchTheSearchText = memoize((searchText, association) => {
  const matchingOptionRecords = association.get('associationOptionRecords').filter(objectNameMatchesSearchText.bind(this, searchText));
  return association.set('associationOptionRecords', matchingOptionRecords);
});
const getAssociationsThatMatchSearchText = memoize((searchText, associationRecords) => {
  const hasSearchText = !!searchText;
  if (!hasSearchText) {
    return associationRecords;
  }
  return associationRecords.map(getAssociationRecordsThatMatchTheSearchText.bind(this, searchText));
});
const getSelectedAssociations = memoize(association => {
  const selectedAssociations = association.get('associationOptionRecords').filter(associationOptionRecord => associationOptionRecord.get('isSelected'));
  return association.set('associationOptionRecords', selectedAssociations);
});
export const filterAssociations = memoize((associationRecords, searchText) => {
  const associationsThatMatchSearchText = getAssociationsThatMatchSearchText(searchText, associationRecords);
  const nonEmptyAssociations = associationsThatMatchSearchText.filter(association => association.get('associationOptionRecords').size > 0);
  if (!nonEmptyAssociations.size) {
    return OrderedMap();
  }
  const selectedAssociations = nonEmptyAssociations.map(getSelectedAssociations);
  const selectedNonEmptyAssociations = selectedAssociations.filter(association => association.get('associationOptionRecords').size > 0);
  return selectedNonEmptyAssociations;
});