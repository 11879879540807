import { debugLog } from './fullstory-debug';
import { FullstoryAPI } from './fullstoryAPI';
import { deprioritize } from './utils';
export function enhanceTrackerWithFullstory(usageTrackerInstance) {
  return Object.assign({}, usageTrackerInstance, {
    clone: (...args) => {
      return enhanceTrackerWithFullstory(usageTrackerInstance.clone(...args));
    },
    track: (...args) => {
      if (FullstoryAPI.isInitialized()) {
        deprioritize(() => {
          try {
            FullstoryAPI.event('amplitudeEvent', Object.assign({
              eventName: args[0] || 'unknownEvent'
            }, typeof args[1] === 'object' ? args[1] : {}));
          } catch (e) {
            debugLog('Fullstory event tracking failed', e);
          }
        }, 500);
      }
      return usageTrackerInstance.track(...args);
    }
  });
}