import get from 'transmute/get';
import getIn from 'transmute/getIn';
import { CONTACT } from '../../common-message-format/constants/attachmentTypes';
import { TYPE } from '../../common-message-format/constants/keyPaths';
import { getAttachments } from '../../common-message-format/operators/getAttachments';
export const isContactAttachment = attachment => getIn(TYPE, attachment) === CONTACT;
export const getContactAttachment = message => {
  const attachments = getAttachments(message);
  if (!attachments || attachments.size === 0) return null;
  return attachments.find(isContactAttachment) || null;
};
export const getContactAttachments = message => {
  const attachments = getAttachments(message);
  if (!attachments || attachments.size === 0) return null;
  const contacts = attachments.filter(isContactAttachment);
  if (!contacts || contacts.size === 0) return null;
  return contacts;
};
export const getContactProfile = get('contactProfile');
export const getFirstName = getIn(['name', 'firstName']);
export const getMiddleName = getIn(['name', 'middleName']);
export const getLastName = getIn(['name', 'lastName']);
export const getCompany = getIn(['org', 'company']);
export const getPhoneNumbers = get('phones');
export const getPhoneNumber = get('phone');
export const getEmails = get('emails');
export const getEmail = get('email');
export const getUrls = get('urls');
export const getAddresses = get('addresses');
export const getUrl = get('url');
export const getType = get('type');

// address getters

export const getStreet = get('street');
export const getCity = get('city');
export const getState = get('state');
export const getZip = get('zip');
export const getCountry = get('country');
export const getCountryCode = get('countryCode');