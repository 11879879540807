import { registerQuery } from 'data-fetching-client';
import { fetchInboxActiveMigrationStatus, fetchChannelsActiveMigrationStatus, fetchMigrationStatus, fetchAllMigrationRecords, fetchMigrationResults } from '../clients/help-desk-migration.api';
export const fetchMigrationStatusQuery = registerQuery({
  args: ['channelInstanceId'],
  fetcher: fetchMigrationStatus,
  fieldName: 'helpdeskMigrationStatus'
});
export const fetchInboxActiveMigrationStatusQuery = registerQuery({
  args: ['inboxId'],
  fetcher: fetchInboxActiveMigrationStatus,
  fieldName: 'helpdeskInboxActiveMigrationStatus'
});
export const fetchChannelsActiveMigrationStatusQuery = registerQuery({
  args: ['channelInstanceIds'],
  fetcher: fetchChannelsActiveMigrationStatus,
  fieldName: 'helpdeskChannelsMigrationStatus'
});
export const fetchAllMigrationRecordsQuery = registerQuery({
  fetcher: fetchAllMigrationRecords,
  fieldName: 'helpdeskAllMigrationRecords'
});
export const fetchMigrationResultsQuery = registerQuery({
  args: ['channelInstanceId'],
  fetcher: fetchMigrationResults,
  fieldName: 'helpdeskMigrationResults'
});