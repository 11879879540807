import { getIsPublished, getIsPendingApproval } from 'quotes-ui-lib/utils/status';
import PortalIdParser from 'PortalIdParser';
import { getQuoteProperty, getQuoteId, getDealId } from 'quotes-ui-lib/utils/quote';
import { getFullUrl } from 'hubspot-url-utils';
export function getEditOrApproveQuotePath(quote) {
  const quoteId = getQuoteId(quote);
  return `/${getIsPendingApproval(quote) ? 'approve' : 'edit'}/${quoteId}`;
}
export function getQuoteUrl(quote) {
  if (getIsPublished(quote)) {
    return getQuoteProperty(quote, 'hs_quote_link');
  }
  return `/quotes/${PortalIdParser.get()}${getEditOrApproveQuotePath(quote)}`;
}
export function getQuoteDetailsUrl(quote) {
  return `/quotes/${PortalIdParser.get()}/details/${getQuoteId(quote)}`;
}
export function getQuoteUrlViaDeal(quote) {
  return getIsPublished(quote) ? getQuoteProperty(quote, 'hs_quote_link') : getUpdateQuoteUrlViaDeal(quote);
}
export function getQuoteIndexURL(quote) {
  return getIsPublished(quote) ? getQuoteProperty(quote, 'hs_quote_link') : `/quotes/${PortalIdParser.get()}${getEditOrApproveQuotePath(quote)}`;
}
function getUpdateQuoteUrlViaDeal(quote) {
  const getRootPath = () => `/contacts/${PortalIdParser.get()}`;
  const getRootPathFromWindow = () => {
    const hubspotBasePathnameRegex = /^\/[\w-]+\/\d+/;
    const parts = window.location.pathname.match(hubspotBasePathnameRegex);
    if (parts) {
      //manual override for crm-events iframe
      if (parts[0].includes('crm-events')) {
        return parts[0].replace('crm-events', 'contacts');
      }
      //manual override for crm-record-extension-pane iframe
      if (parts[0].includes('crm-record-extension-pane-ui')) {
        return parts[0].replace('crm-record-extension-pane-ui', 'contacts');
      }
      return parts[0];
    }
    return null;
  };
  const baseUrl = () => {
    return getRootPathFromWindow() || getRootPath();
  };
  const quoteId = getQuoteId(quote);
  const dealId = getDealId(quote);
  const queryParam = getIsPendingApproval(quote) ? 'approve' : 'edit';
  return `${baseUrl()}/deal/${dealId}/?quoteId=${quoteId}&${queryParam}=true`;
}
export function getFullQuoteUrlViaDeal(quote) {
  return fullQuoteUrlBuilder(quote, getUpdateQuoteUrlViaDeal(quote));
}
export function getFullQuoteUrl(quote) {
  return fullQuoteUrlBuilder(quote, `/quotes/${PortalIdParser.get()}${getEditOrApproveQuotePath(quote)}`);
}
export function getQuoteSigningUrl(quote, signatureDetails) {
  const {
    signatureId,
    signatureToken
  } = signatureDetails;
  const signingUrl = new URL(`${getFullUrl('app')}/quotes-esignature-container`);
  signingUrl.searchParams.append('signatureId', signatureId);
  signingUrl.searchParams.append('signatureToken', signatureToken);
  signingUrl.searchParams.append('redirectTo', encodeURIComponent(getQuoteUrl(quote)));
  signingUrl.searchParams.append('locale', getQuoteProperty(quote, 'hs_locale'));
  signingUrl.searchParams.append('language', getQuoteProperty(quote, 'hs_language'));
  signingUrl.searchParams.append('skipStartSigningModal', 'true');
  return signingUrl.toString();
}
function fullQuoteUrlBuilder(quote, urlToUse) {
  return getIsPublished(quote) ? getQuoteProperty(quote, 'hs_quote_link') : `${getFullUrl('app')}${urlToUse}`;
}