import { getCompanyLink } from '../../../external-urls/public';
export const getMatchedCompanyLink = ({
  associatedCompanyId,
  associatedCompanyName,
  company
}) => {
  const stripCompanyName = companyName => {
    return companyName.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
  };
  const shouldMatchCompany = associatedCompanyId && stripCompanyName(company) === stripCompanyName(associatedCompanyName) || !company && associatedCompanyName;
  return shouldMatchCompany ? getCompanyLink(associatedCompanyId) : '';
};