/**
 * @description Check if the client has capabilities on `channels`
 */
export function hasCapability(auth, channels, capabilityValidatorFunc) {
  try {
    if (!auth.tokenDetails || auth.tokenDetails && auth.tokenDetails.expires < Date.now()) {
      return false;
    }
    const capability = JSON.parse(auth.tokenDetails.capability);
    if (capabilityValidatorFunc) {
      return capabilityValidatorFunc(Object.keys(capability), channels);
    }
    return channels.every(channel => !!capability[channel]);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('hasCapability check failed', e);
    return false;
  }
}