import { getLatestMessageTimestamp, getLatestReceivedTimestamp } from './threadDetailsGetters';
// @ts-expect-error module not typed

const getIsLastestMessageFromVisitor = threadDetails => {
  const latestReceivedTimestamp = getLatestReceivedTimestamp(threadDetails);
  const latestMessageTimestamp = getLatestMessageTimestamp(threadDetails);
  return latestReceivedTimestamp === latestMessageTimestamp;
};
const getTimeDiffFromNowInSeconds = timestamp => {
  const currentTimestamp = new Date().getTime();
  return Math.floor((currentTimestamp - timestamp) / 1000);
};

/**
 * The amount of time, in seconds, since the latest message in history.
 * Return -1 if the latest message in history was from the agent, and not a visitor.
 *
 * @param {ThreadDetails} threadDetails
 * @returns {number}
 */
export const getAgentTimeToReply = threadDetails => getIsLastestMessageFromVisitor(threadDetails) ? getTimeDiffFromNowInSeconds(getLatestReceivedTimestamp(threadDetails)) : -1;