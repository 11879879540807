import { useQuery, registerQuery } from 'data-fetching-client';
import { fetchContactPermissions } from '../clients/fetchContactPermissions';
const FETCH_CONTACT_PERMISSIONS_QUERY_NAME = 'fetchContactPermissions';
const FETCH_CONTACT_PERMISSIONS_QUERY = registerQuery({
  fieldName: FETCH_CONTACT_PERMISSIONS_QUERY_NAME,
  args: ['emailAddresses'],
  fetcher: fetchContactPermissions
});
export function useContactPermissions({
  emailAddresses,
  deferred
}) {
  const {
    data,
    loading,
    error
  } = useQuery(FETCH_CONTACT_PERMISSIONS_QUERY, {
    variables: {
      emailAddresses
    },
    skip: deferred
  });
  const contactPermissions = data && data[FETCH_CONTACT_PERMISSIONS_QUERY_NAME];
  return {
    contactPermissions,
    loading,
    error
  };
}