import { createAction } from 'redux-actions';
import { FILE_UPLOAD_PROGRESS } from '../constants/actionTypes';
const MAX_UPLOAD_PROGRESS_PERCENTAGE = 95;
export const fileUploadProgress = createAction(FILE_UPLOAD_PROGRESS, ({
  fileUpload,
  loaded,
  total
}) => ({
  fileUpload,
  progress: loaded / total * MAX_UPLOAD_PROGRESS_PERCENTAGE
}));