import { useEffect, useState } from 'react';
import { getAssociatedDiscounts, getAssociatedInvoices, getAssociatedPaymentSchedules } from '../api/CrmSearchApi';
const useHasAssociatedObjects = ({
  quoteId,
  portalId,
  skip
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hasInvoices, setHasInvoices] = useState(false);
  const [hasPaymentSchedules, setHasPaymentSchedules] = useState(false);
  const [hasMoreThanOneDiscount, setHasMoreThanOneDiscount] = useState(false);
  useEffect(() => {
    if (skip || !quoteId || !portalId) {
      setLoading(false);
      setError(false);
      setHasPaymentSchedules(false);
      setHasMoreThanOneDiscount(false);
    } else {
      setLoading(true);
      Promise.all([getAssociatedInvoices(quoteId, portalId), getAssociatedPaymentSchedules(quoteId, portalId), getAssociatedDiscounts(quoteId, portalId)]).then(([invoices, paymentSchedules, discounts]) => {
        setHasInvoices(invoices.length > 0);
        setHasPaymentSchedules(paymentSchedules.length > 0);
        setHasMoreThanOneDiscount(discounts.length > 1);
      }).catch(() => setError(true)).finally(() => setLoading(false));
    }
  }, [portalId, quoteId, skip]);
  return {
    hasInvoices,
    hasPaymentSchedules,
    hasMoreThanOneDiscount,
    loading,
    error
  };
};
export default useHasAssociatedObjects;