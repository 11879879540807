export const INIT_MODAL = 'INIT';
export const HIDE_MODAL = 'HIDE_MODAL';
export const MODAL_HIDDEN = 'MODAL_HIDDEN';
export const MessageTypes = Object.freeze({
  INIT_MODAL,
  HIDE_MODAL,
  MODAL_HIDDEN
});

/** EmbeddedContext is passed to us from the iframe and acts as a messenger between this application and the iframe host.
 *
 * We don't manage the data in this object, it's passed to us and we only use it to send and receive messages */

/** HostMessage represents the data that comes out of HostContext.sendMessage on the receiving end.
 * We only really care about the payload type and values */

/** IFrameContextHandler is a wrapper around HostContext that provides a type-safe API for sending and receiving messages.
 * It also provides a way to register callbacks for specific message types, instead of needing to write type guards or do
 * explicit checking of message types. */
export class IFrameContextHandler {
  constructor(context) {
    this.callbacks = {};
    this.send = (type, payload) => {
      this.context.sendMessage(type, payload);
    };
    this.on = (type, callback) => {
      this.callbacks[type] = callback;
      return this;
    };
    this.receive = message => {
      const {
        type
      } = message.payload;
      const callback = this.callbacks[type];
      if (callback) {
        callback(message.payload);
      }
    };
    this.context = context;
  }
}