import { Record } from 'immutable';
// eslint-disable-next-line hs-react-native/no-dark-mode-incompatible-code
import { FLINT } from 'HubStyleTokens/colors';
const QuickReplyTheme = Record({
  width: 'auto',
  minHeight: 36,
  borderColor: FLINT,
  whiteSpace: 'normal',
  lineHeight: '1rem',
  color: FLINT
}, 'QuickReplyTheme');
export default QuickReplyTheme;