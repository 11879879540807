'use es6';

import { Map as ImmutableMap } from 'immutable';
import uniqueId from 'transmute/uniqueId';
import * as Atom from 'atom';
export const TOKEN_PREFIX = 'unsaved-';
export const universalEditableAreas = Atom.atom(ImmutableMap());
export const previewEditableAreas = Atom.atom(ImmutableMap());
export function register(editableAreas, subscription) {
  const unsavedToken = uniqueId(TOKEN_PREFIX);
  Atom.swap(editableAreas, current => current.set(unsavedToken, subscription));
  return unsavedToken;
}
export function update(editableAreas, unsavedToken, subscription) {
  Atom.swap(editableAreas, current => current.set(unsavedToken, subscription));
  return unsavedToken;
}
export function unregister(editableAreas, unsavedToken) {
  Atom.swap(editableAreas, current => current.remove(unsavedToken));
}