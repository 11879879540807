'use es6';

import { INTEGRATOR } from 'conversations-message-history/common-message-format/constants/legacySenderTypes';
export const buildSenderObjectsFromIntegratorsResponse = (integrators = []) => {
  return integrators.map(integrator => {
    const {
      name,
      appId,
      iconUrl
    } = integrator;
    return {
      avatar: iconUrl,
      firstName: name,
      senderType: INTEGRATOR,
      id: appId
    };
  });
};