// gimme: conversations-inbox-ui 05/26/2020 741c62dc71ca742adbc6d64d61d30de7cb46539f
'use es6';

import { FB_MESSENGER_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
import { getTimestamp } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { getLatestVisitorMessage } from 'conversations-message-history/thread-history/operators/getLatestVisitorMessage';
import { createSelector } from 'reselect';
import { getFocusedThreadOriginalGenericChannelId } from '../../thread-details/selectors/getFocusedThreadOriginalGenericChannelId';
import { hasProvidedFbmAgentConsent } from 'conversations-thread-data/settings/selectors/hasProvidedFbmAgentConsent';
import { hasDismissedTwentyFourWarning } from '../local-storage/fbm24PlusOneLocalStorage';
import { getCurrentThreadHistory } from 'conversations-thread-data/thread-histories/public/selectors/getCurrentThreadHistory';
const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
const SEVEN_DAYS_IN_MS = ONE_DAY_IN_MS * 7;
export const showTwentyFourHourWarning = createSelector([getFocusedThreadOriginalGenericChannelId, hasProvidedFbmAgentConsent], (originalGenericChannelId, providedFbmAgentConsent) => {
  if (originalGenericChannelId !== FB_MESSENGER_GENERIC_CHANNEL_ID || providedFbmAgentConsent) {
    return false;
  }
  return !hasDismissedTwentyFourWarning();
});

/**
 *
 * The visitor has not replied in over 24 hours.
 * The agent cannot publish any additional replies.
 * FKA: showTwentyFourPlusOneLimitActive
 *
 */
export const hasReachedTwentyFourHourLimit = createSelector([getFocusedThreadOriginalGenericChannelId, getCurrentThreadHistory, hasProvidedFbmAgentConsent], (originalGenericChannelId, threadHistory, providedFbmAgentConsent) => {
  if (originalGenericChannelId !== FB_MESSENGER_GENERIC_CHANNEL_ID || providedFbmAgentConsent) {
    return false;
  }
  const latestVisitorMessage = getLatestVisitorMessage(threadHistory, originalGenericChannelId);
  return getTimestamp(latestVisitorMessage) < Date.now() - ONE_DAY_IN_MS;
});

/**
 * If no consent has been given, show nothing.
 * Otherwise, show a limit reached alert.
 */
export const show7DayRuleLimitReached = createSelector([getFocusedThreadOriginalGenericChannelId, getCurrentThreadHistory, hasProvidedFbmAgentConsent], (originalGenericChannelId, threadHistory, providedFbmAgentConsent) => {
  if (originalGenericChannelId !== FB_MESSENGER_GENERIC_CHANNEL_ID || !providedFbmAgentConsent) {
    return false;
  }
  const latestVisitorMessage = getLatestVisitorMessage(threadHistory, originalGenericChannelId);
  return getTimestamp(latestVisitorMessage) < Date.now() - SEVEN_DAYS_IN_MS;
});

/**
 * This selector takes into account both possible states that should result in the agent being unable to reply.
 */
export const hasReachedFbmReplyLimit = createSelector([hasReachedTwentyFourHourLimit, show7DayRuleLimitReached], (reachedTwentyFourLimit, sevenDayLimitReached) => {
  return Boolean(reachedTwentyFourLimit || sevenDayLimitReached);
});