import { Set as ImmutableSet } from 'immutable';
import { getMessages, getDirectReplies
// @ts-expect-error module not found
} from 'conversations-message-history/thread-history/operators/getters';
// @ts-expect-error module not found
import { fileAttachmentIdsInMessage } from 'conversations-message-history/common-message-format/operators/fileAttachmentIdsInMessage';
export const getFileIdsFromHistory = history => {
  const messages = getMessages(history);
  const directReplyToMessages = getDirectReplies(history);
  const combinedMessages = messages.concat(directReplyToMessages);
  return combinedMessages.reduce((accumulator, message) => {
    return accumulator.union(fileAttachmentIdsInMessage(message));
  }, ImmutableSet());
};