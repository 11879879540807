'use es6';

import { useMemo } from 'react';
import { useVisitors } from '../../visitor/hooks/useVisitors';
import { getValidRecipientIdsFromRecipientLocators } from '../operators/getValidRecipientIdsFromRecipientLocators';
import { buildRecipientsFromVisitorsResponse } from '../operators/buildRecipientsFromVisitorsResponse';
export const useIndexedAsyncVisitorRecipients = ({
  recipientLocators,
  vid
}) => {
  // get exhaustive list of all vids present in thread
  const vids = useMemo(() => {
    const recipientIds = getValidRecipientIdsFromRecipientLocators(recipientLocators);
    return vid && !isNaN(vid) ? recipientIds.add(vid) : recipientIds;
  }, [recipientLocators, vid]);
  const {
    asyncVisitors
  } = useVisitors({
    vids
  });
  const visitorRecipients = useMemo(() => {
    return buildRecipientsFromVisitorsResponse(asyncVisitors);
  }, [asyncVisitors]);
  return {
    visitorRecipients
  };
};