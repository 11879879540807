import enviro from 'enviro';
import once from '../utils/once';
import IframeMessaging from '../utils/IframeMessaging';
import { addEventListener } from '../event-registration/addEventListener';
import { logError } from '../utils/logError';
const registeredStores = [];
export class UniversalCallingStore {
  constructor({
    name: _name,
    initialState,
    parseRawState,
    version: _version,
    eventListeners
  }) {
    this._makeEmbedMessage = (payload = {}) => ({
      message: {
        callingStoreInfo: {
          fromEmbed: this._isInIframe(),
          name: this._name,
          origin: IframeMessaging._EMBED_ID
        },
        payload
      }
    });
    this._handleEmbedMessage = (messageEvent, {
      v: version,
      id
    }) => {
      if (!messageEvent.type || !messageEvent.data || !messageEvent.data.message || !messageEvent.data.message.callingStoreInfo || !messageEvent.data.message.callingStoreInfo.name || !this._IFRAME_EVENT_TYPES.includes(messageEvent.type)) {
        return;
      }
      const {
        data: {
          message: {
            callingStoreInfo: {
              name,
              fromEmbed,
              origin
            },
            payload
          } = {}
        } = {},
        type
      } = messageEvent;
      if (this._version !== version) {
        logError(new Error(`Universal Calling Store attempted message with incorrect version ${version} for parent ${this._version}`), {
          extra: {
            name,
            version,
            type
          }
        });

        // Updates all instance listeners to have the hasVersionError property.
        this.hasVersionError = true;
        this.triggerSetStateEvent({
          embedMessage: fromEmbed
        });
        return;
      }
      if (type === this._EMBED_REGISTRATION_EVENT) {
        const sourceFrame = document.querySelector(`iframe[src*="${origin}"]`);
        this._embedInstances[id] = sourceFrame;
      } else if (type === this._EMBED_UPDATE_EVENT) {
        this.setState(this._parseRawState(payload), {
          embedMessage: true
        });
      }
    };
    this._isInIframe = once(() => {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    });
    this._logDebug = ({
      message,
      data
    }) => {
      if (!this._debug) return;
      const DEBUG_BG_COLOR = this._isInIframe() ? '#ec9d3b' : '#90d289';
      const DEBUG_COLOR = '#33475b';

      /* eslint-disable no-console */
      console.groupCollapsed(`%c Calling Store ${this._name} : ${message} %c`, `background-color:${DEBUG_BG_COLOR};color:white;padding:.15em .25em`, `color:#${DEBUG_COLOR}`);
      console.log(`%c Event: %c${message}`, 'color:#99acc2', 'color:#33475b');
      if (data && typeof data === 'object') {
        console.log(data);
      } else if (data) {
        console.log(data);
      }
      console.groupEnd();
      /* eslint-enable no-console */
    };
    this.setState = (nextState, options = {}) => {
      if (this._state !== nextState) {
        this._logDebug({
          message: 'setState',
          data: {
            before: this._state,
            after: nextState,
            options
          }
        });
        this._state = nextState;
        this.triggerSetStateEvent(options);
      }
      return this;
    };
    this.updateState = (fn, options = {}) => {
      const nextState = fn(this._state);
      if (this._state !== nextState) {
        this._logDebug({
          message: 'setState',
          data: {
            before: this._state,
            after: nextState,
            options
          }
        });
        this._state = nextState;
        this.triggerSetStateEvent(options);
      }
      return this;
    };
    this.getState = () => {
      return this._state;
    };
    this.addListener = fn => {
      this._stateListeners.push(fn);
      this._logDebug({
        message: 'addListener',
        data: fn
      });
    };
    this.removeListener = fn => {
      const indexToRemove = this._stateListeners.indexOf(fn);
      if (indexToRemove >= 0) {
        this._stateListeners.splice(indexToRemove, 1);
      }
      this._logDebug({
        message: 'removeListener',
        data: fn
      });
    };
    this.triggerSetStateEvent = ({
      embedMessage = false
    } = {}) => {
      this._logDebug({
        message: 'triggerSetStateEvent',
        data: `triggering state updates on ${this._stateListeners.length} listeners`
      });
      this._stateListeners.forEach(fn => {
        fn({
          instance: this
        });
      });
      if (embedMessage) return;
      const embedKeys = Object.keys(this._embedInstances);
      if (embedKeys.length > 0 || this._isInIframe()) {
        const state = this.getState();
        const rawState = state && typeof state.toJS === 'function' ? state.toJS() : state;
        if (this._isInIframe()) {
          IframeMessaging.sendMessageToHost(this._makeEmbedMessage(rawState), undefined, this._EMBED_UPDATE_EVENT);
          return;
        }
        embedKeys.forEach(embedKey => {
          const embedInstance = embedKey && this._embedInstances[embedKey];
          if (!embedInstance) {
            return;
          }
          IframeMessaging.sendMessageToEmbedInstance(embedInstance, this._makeEmbedMessage(rawState), this._EMBED_UPDATE_EVENT);
        });
      }
    };
    this._name = _name;
    this._version = _version;
    this._embedInstances = {};
    this._debug = enviro.debug('universal-calling-store');
    this._stateListeners = [];
    this._state = initialState;
    this._parseRawState = parseRawState;
    this._EMBED_REGISTRATION_EVENT = this._makeEventKey('CALLING_STORE_EMBED_REGISTRATION');
    this._EMBED_UPDATE_EVENT = this._makeEventKey('CALLING_STORE_EMBED_UPDATE');
    this._IFRAME_EVENT_TYPES = [this._EMBED_REGISTRATION_EVENT, this._EMBED_UPDATE_EVENT];
    IframeMessaging.registerEventTypeHandlers({
      [this._EMBED_REGISTRATION_EVENT]: this._handleEmbedMessage,
      [this._EMBED_UPDATE_EVENT]: this._handleEmbedMessage
    });
    this.hasVersionError = false;
    this._logDebug({
      message: 'initialized store'
    });
    registeredStores.push(this._name);
    if (this._isInIframe()) {
      IframeMessaging.sendMessageToHost(this._makeEmbedMessage(), undefined, this._EMBED_REGISTRATION_EVENT);
    }

    // TODO: How do we stop listening?
    if (eventListeners && eventListeners.length) {
      eventListeners.forEach(({
        event,
        callback
      }) => {
        this._logDebug({
          message: `Listening for ${event} event.`
        });
        addEventListener(event, callback);
      });
    }
  }
  _makeEventKey(key) {
    return `${key}_${this._name.toUpperCase()}`;
  }
}
const makeStoreKey = (name, version) => {
  return `${name}_v${version}`;
};
export function getOrCreateUniversalCallingStore({
  name,
  initialState,
  parseRawState,
  version,
  eventListeners
}) {
  if (!window.hsCalling) window.hsCalling = {};
  if (!window.hsCalling.universalStores) window.hsCalling.universalStores = {};
  if (!version) {
    throw new Error(`[Calling Global API Error] expected ${name} store to be versioned, instead recieved ${version}`);
  }
  if (typeof parseRawState !== 'function') {
    throw new Error(`[Calling Global API Error] expected parseRawState to be a function, instead recieved ${typeof parseRawState}`);
  }
  const versionedName = makeStoreKey(name, version);
  if (window.hsCalling.universalStores[versionedName]) {
    return window.hsCalling.universalStores[versionedName];
  }
  window.hsCalling.universalStores[versionedName] = new UniversalCallingStore({
    name,
    initialState,
    parseRawState,
    version,
    eventListeners
  });
  return window.hsCalling.universalStores[versionedName];
}