import { CONNECTED, DISCONNECTED, FAILED, SUSPENDED, INITIALIZED, CONNECTING } from '../constants/connectionStates';
import { noop } from '../../utils/noop';
export function bindConnectionLifeCycleCallbacks(connection, {
  onDisconnect = noop,
  onFailure = noop,
  onConnect = noop,
  onConnecting = noop,
  onSuspended = noop
}) {
  let initialConnection = true;
  let previousState = INITIALIZED;
  let previousConnectionId = '';
  connection.on(CONNECTED, () => {
    const recoveringWithNewConnectionId = connection.id !== previousConnectionId && previousState === DISCONNECTED;
    onConnect({
      connectionWasSuspended: recoveringWithNewConnectionId || previousState === SUSPENDED,
      reconnected: !initialConnection
    });
    initialConnection = false;
    previousState = CONNECTED;
    previousConnectionId = connection.id || '';
  });
  connection.on(DISCONNECTED, () => {
    initialConnection = false;
    previousState = DISCONNECTED;
    onDisconnect();
  });
  connection.on(SUSPENDED, () => {
    previousState = SUSPENDED;
    onSuspended();
  });
  connection.on(FAILED, () => {
    previousState = FAILED;
    onFailure();
  });
  connection.on(CONNECTING, () => onConnecting({
    reconnecting: !initialConnection
  }));
}