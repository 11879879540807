import Track from '../utils/EscalationsTracker';
import { LOAD_ESCALATION, LOAD_ESCALATION_META, SAVE_ESCALATION, SUBMIT_ESCALATION } from './ActionTypes';
import { convertEscalation, getEscalationByConversation, getEscalationByTicket, getEscalationMetaByConversation, getEscalationMetaByTicket, updateEscalation } from '../utils/EscalationsApi';
export function loadEscalation({
  conversationId,
  ticketId
}) {
  const getEscalation = conversationId ? () => getEscalationByConversation(conversationId) : () => getEscalationByTicket(ticketId);
  return dispatch => {
    dispatch({
      type: LOAD_ESCALATION,
      payload: conversationId || ticketId,
      meta: 'request'
    });
    return getEscalation().then(escalation => {
      Track.ticketId = escalation.ticketId;
      Track.conversationId = escalation.conversationId;
      return dispatch({
        type: LOAD_ESCALATION,
        payload: escalation,
        meta: 'response'
      });
    }, error => dispatch({
      type: LOAD_ESCALATION,
      payload: error,
      meta: 'response',
      error: true
    }));
  };
}
export function saveEscalation(escalation, field) {
  return dispatch => {
    dispatch({
      type: SAVE_ESCALATION,
      payload: {
        data: escalation,
        field
      },
      meta: 'request'
    });
    return updateEscalation(escalation).then(() => dispatch({
      type: SAVE_ESCALATION,
      payload: {
        data: escalation,
        field
      },
      meta: 'response'
    }), error => dispatch({
      type: SAVE_ESCALATION,
      payload: error,
      meta: 'response',
      error: true
    }));
  };
}
export function submitEscalation(escalation) {
  return dispatch => {
    dispatch({
      type: SUBMIT_ESCALATION,
      payload: escalation,
      meta: 'request'
    });
    return convertEscalation(escalation).then(() => dispatch({
      type: SUBMIT_ESCALATION,
      payload: escalation,
      meta: 'response'
    }), error => {
      dispatch({
        type: SUBMIT_ESCALATION,
        payload: error,
        meta: 'response',
        error: true
      });
      throw error;
    });
  };
}
export function loadEscalationMeta({
  conversationId,
  ticketId
}) {
  const getEscalationMeta = conversationId ? () => getEscalationMetaByConversation(conversationId) : () => getEscalationMetaByTicket(ticketId);
  return dispatch => {
    dispatch({
      type: LOAD_ESCALATION_META,
      meta: 'request'
    });
    return getEscalationMeta().then(escalationMeta => dispatch({
      type: LOAD_ESCALATION_META,
      payload: escalationMeta,
      meta: 'response'
    }), error => dispatch({
      type: LOAD_ESCALATION_META,
      payload: error,
      meta: 'response',
      error: true
    }));
  };
}