'use es6';

import { Map as ImmutableMap } from 'immutable';
import { fetchAgentRecipients } from '../actions/fetchAgentRecipients';
import { makeGetIndexedAsyncAgentBotRecipients } from '../selectors/getIndexedAsyncAgentBotRecipients';
import { useReduxIndexedAsyncFetcher } from 'conversations-async-data/indexed-async-data/hooks/useReduxIndexedAsyncFetcher';
import { useMemo } from 'react';
const idsTransform = ids => ({
  recipientLocators: ids
});
export const useIndexedAsyncAgentBotRecipients = ({
  recipientLocators
}) => {
  const selector = useMemo(() => makeGetIndexedAsyncAgentBotRecipients(), []);
  const agentBotRecipients = useReduxIndexedAsyncFetcher({
    action: fetchAgentRecipients,
    selector,
    ids: recipientLocators,
    idsTransform
  });
  if (agentBotRecipients && agentBotRecipients.entries) {
    return {
      agentBotRecipients: agentBotRecipients.entries
    };
  }
  return {
    agentBotRecipients: ImmutableMap()
  };
};