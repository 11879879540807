import I18n from 'I18n';
export function sendDisabledReasonToErrorMessage(sendDisabledReason, options) {
  switch (sendDisabledReason) {
    case 'ACCOUNT_INACTIVE':
      return I18n.text('composer-ui.disable-send.ACCOUNT_INACTIVE');
    case 'ACCOUNTS_FAILED_TO_LOAD':
      return I18n.text('composer-ui.disable-send.ACCOUNTS_FAILED_TO_LOAD');
    case 'ACCOUNT_DISCONNECTED':
      return I18n.text('composer-ui.disable-send.ACCOUNT_DISCONNECTED', {
        email: options.email
      });
    case 'ATTACHMENT_LIMIT_EXCEEDED':
      return I18n.text('composer-ui.disable-send.ATTACHMENT_LIMIT_EXCEEDED');
    case 'BOUNCED_CONTACT':
      return I18n.text('composer-ui.disable-send.BOUNCED_CONTACT');
    case 'FACEBOOK_LIMIT_REACHED':
      return I18n.text('composer-ui.disable-send.FACEBOOK_LIMIT_REACHED');
    case 'FILE_UPLOAD_ERROR':
      return I18n.text('composer-ui.disable-send.FILE_UPLOAD_ERROR');
    case 'FILE_TYPE_ERROR':
      return I18n.text('composer-ui.disable-send.FILE_TYPE_ERROR');
    case 'FROM_ACCOUNT_SUSPENDED':
      return I18n.text('composer-ui.disable-send.FROM_ACCOUNT_SUSPENDED');
    case 'INSTANCES_FAILED_TO_LOAD':
      return I18n.text('composer-ui.disable-send.INSTANCES_FAILED_TO_LOAD');
    case 'INVALID_EMAIL_ADDRESS':
      return I18n.text('composer-ui.disable-send.INVALID_EMAIL_ADDRESS');
    case 'MESSAGING_NOT_TRUSTED':
      return I18n.text('composer-ui.disable-send.MESSAGING_NOT_TRUSTED');
    case 'MESSENGER_DISCONNECTED':
      return I18n.text('composer-ui.disable-send.MESSENGER_DISCONNECTED');
    case 'MISSING_ACCOUNTS':
      return I18n.text('composer-ui.disable-send.MISSING_ACCOUNTS');
    case 'MISSING_DELIVERY_IDENTIFIERS':
      return I18n.text('composer-ui.disable-send.MISSING_DELIVERY_IDENTIFIERS');
    case 'MISSING_INSTANCES':
      return I18n.text('composer-ui.disable-send.MISSING_INSTANCES');
    case 'NEW_RECIPIENT_LIMIT_EXCEEDED':
      return I18n.text('composer-ui.disable-send.NEW_RECIPIENT_LIMIT_EXCEEDED', {
        maxRecipientNew: options.maxRecipientNew
      });
    case 'NO_CONTACT_PERMISSIONS':
      return I18n.text('composer-ui.disable-send.NO_CONTACT_PERMISSIONS');
    case 'NO_CONTENT':
      return I18n.text('composer-ui.disable-send.NO_CONTENT');
    case 'NO_RECIPIENT':
      return I18n.text('composer-ui.disable-send.NO_RECIPIENT');
    case 'OVER_CHARACTER_LIMIT':
      return I18n.text('composer-ui.disable-send.OVER_CHARACTER_LIMIT', {
        characterLimit: options.characterLimit
      });
    case 'REPLY_RECIPIENT_LIMIT_EXCEEDED':
      return I18n.text('composer-ui.disable-send.REPLY_RECIPIENT_LIMIT_EXCEEDED', {
        maxRecipientReply: options.maxRecipientReply
      });
    case 'TEST_THREAD':
      return I18n.text('composer-ui.disable-send.TEST_THREAD');
    case 'UPLOADING_FILE':
      return I18n.text('composer-ui.disable-send.UPLOADING_FILE');
    case 'WHATS_APP_LIMIT_REACHED':
      return I18n.text('composer-ui.disable-send.WHATS_APP_LIMIT_REACHED');
    case 'SMS_BILLING_LIMIT_REACHED':
      return I18n.text('composer-ui.disable-send.SMS_BILLING_LIMIT_REACHED');
    case 'ABLY_CHANNEL_MISMATCH':
      return I18n.text('composer-ui.disable-send.ABLY_CHANNEL_MISMATCH');
    case 'CONNECTING_TO_HUBSPOT':
    default:
      return I18n.text('composer-ui.disable-send.CONNECTING_TO_HUBSPOT');
  }
}