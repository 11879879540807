import { getLocalStorageValue } from './localStorage';

/** Set this in localStorage to override the iframe url for local dev
 * @example window.localStorage.setItem('subscriptions-admin:iframe-host-url', 'local.hubspotqa.com')
 */
const IFRAME_HOST_LOCAL_STORAGE_KEY = 'iframe-host-url';

/**
 * Returns the host url for the iframe. `app.hubspot.com` or `app.hubspotqa.com`

 * If an override is found in localStorage, it will be used instead.
 * @returns string the host url for the iframe
 */
export function getHostUrl(appName) {
  if (appName) {
    try {
      const override = getLocalStorageValue(`${appName}:${IFRAME_HOST_LOCAL_STORAGE_KEY}`);
      if (override) {
        return override;
      }
    } catch (_) {} // eslint-disable-line no-empty
  }
  const host = window.location.host;
  return host.includes('local') ? host.replace('local', 'app') : host;
}
export function getIFrameSrc(appName, appSrc) {
  return `https://${getHostUrl(appName)}/${appSrc}`;
}