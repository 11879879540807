function hashFunction(...args) {
  if (args.length === 1) {
    return JSON.stringify(args[0]);
  }
  return JSON.stringify(args);
}
function memoizedN(cache, operation, expiration, ...args) {
  const key = hashFunction(...args);
  if (!cache[key]) {
    cache[key] = operation(...args);
    if (expiration) {
      setTimeout(() => {
        delete cache[key];
      }, expiration);
    }
  }
  return cache[key];
}
export default function memoizeWithTimeout(operation, expiration) {
  const cache = {};
  const memoizer = memoizedN;
  const result = memoizer.bind(null, cache, operation, expiration);
  return result;
}