import { useReduxIndexedAsyncFetcher } from 'conversations-async-data/indexed-async-data/hooks/useReduxIndexedAsyncFetcher';
import { Set as ImmutableSet } from 'immutable';
import { fetchFileAttachments as fetchFileAttachmentsAction } from '../actions/fetchFileAttachments';
import { getIndexedAsyncFileAttachments } from '../selectors/getIndexedAsyncFileAttachments';
export const useFileAttachments = ({
  fileAttachmentIds
}) => {
  const {
    entries
  } = useReduxIndexedAsyncFetcher({
    action: fetchFileAttachmentsAction,
    selector: getIndexedAsyncFileAttachments,
    ids: ImmutableSet(fileAttachmentIds),
    idsTransform: ids => ({
      fileAttachmentIds: ids
    })
  });
  return {
    fileAttachments: entries
  };
};