import { debugLog } from './fullstory-debug';
// @ts-expect-error fullstory script is JS
import { mountFullstory } from './fullstory-script';
import { disableActivityTracker, enableActivityTracker } from './activityTracker';
function isFullstoryScriptPresent() {
  return Boolean(fs());
}
function fs() {
  return window[window._fs_namespace];
}
function makeSafe(fn) {
  return function (...args) {
    try {
      return {
        error: null,
        result: fn(...args)
      };
    } catch (e) {
      debugLog(e);
      return {
        error: e,
        result: null
      };
    }
  };
}
function makeSafeFSCall(fsFunctionName) {
  return (...args) => {
    debugLog(`FS.${fsFunctionName} called with args:`, ...args);
    if (!fs()) {
      debugLog('FullStory is not loaded, please ensure the init function is invoked before calling FullStory API functions');
      return {
        result: null,
        error: new Error('FullStory is not loaded, please ensure the init function is invoked before calling FullStory API functions')
      };
    }
    if (!fs()[fsFunctionName] || typeof fs()[fsFunctionName] !== 'function') {
      debugLog(`FS.${fsFunctionName} is not defined within this context.  If you think this is an issue, please report to the reporting-datasets team.`);
      return {
        result: null,
        error: new Error(`FS.${fsFunctionName} is not defined within this context.  If you think this is an issue, please report to the reporting-datasets team.`)
      };
    }
    try {
      return {
        result: fs()[fsFunctionName](...args),
        error: null
      };
    } catch (e) {
      debugLog(`FS.${fsFunctionName} call failed with args:`, ...args);
      return {
        error: e,
        result: null
      };
    }
  };
}
export const FullstoryAPI = {
  /** Anonymizes a user's session, use to anonymize a user that was previously identified  */
  anonymize: () => makeSafeFSCall('anonymize')(),
  /** Consent allows consent-required HTML elements to be captured by the FS script.  This is a manual process right now, reach out if your app uses this so we can talk about use cases */
  consent: consents => makeSafeFSCall('consent')(consents),
  /** Used to capture custom events. Look for a DOG usageTracking package that will combine this event call with our UsageTracker APIs */
  event: (eventName, eventProperties) => makeSafeFSCall('event')(eventName, eventProperties),
  /** Used to identify a user from their user ID.  This allows us to track a user's journey between apps that utilize FS. */
  identify: (userId, userVariables) => {
    FullstoryAPI.event('identify', userVariables);
    return makeSafeFSCall('identify')(userId, userVariables);
  },
  /** Check to see if the FS script is present */
  isInitialized: isFullstoryScriptPresent,
  /** Shutdown stops the fullstory process */
  shutdown: () => {
    makeSafeFSCall('shutdown')();
    makeSafe(disableActivityTracker)();
  },
  /** Mount the FS script tag (with error handling). Not that some of the FS functions will not be immediately usable until the FS script src loads. */
  initialize: () => {
    debugLog('initializing fullstory script');
    if (FullstoryAPI.isInitialized()) {
      debugLog('fullstory script already initialized, aborting initialization');
      return;
    }
    try {
      mountFullstory();
      makeSafe(enableActivityTracker)();
    } catch (e) {
      debugLog('Issue setting up fullstory', e);
    }
  },
  /** Adds a new event listener to the onStart lifecycle of the FS snippet script. */
  onReady: readyCallback => {
    debugLog('setting onReady callback');
    const FS = fs();
    if (!FS) {
      debugLog('onReady callback set before initialization.');
      return;
    }
    // FS is a more complex callable object that can be called like this, but to keep things simple, typed as a record for other use cases
    FS('observe', {
      type: 'start',
      // treat the callback as dangerous to salvage the session in case of misconfiguration
      callback: makeSafe(readyCallback)
    });
  },
  /** Returns the SessionURL if available.  Use to link fullstory Session with other reporting tools, like newRelic, sentry, and amplitude. */
  getCurrentSessionURL: now => {
    return makeSafeFSCall('getCurrentSessionURL')(now);
  },
  /** Logs a message at the configured level to the Fullstory session console. */
  log: (logLevel, message) => {
    return makeSafeFSCall('log')(logLevel, message);
  },
  setPageVars: pageVariables => {
    return makeSafeFSCall('setVars')('page', pageVariables);
  }
};