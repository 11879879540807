import { Set as ImmutableSet } from 'immutable';
import { useCallback } from 'react';
import { useIndexedAsyncFetcher } from 'conversations-async-data/indexed-async-data/hooks/useIndexedAsyncFetcher';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { UNINITIALIZED } from 'conversations-async-data/async-data/constants/asyncStatuses';
import { useThreadDataDispatch, useThreadDataSelector } from '../../../common/ThreadViewDataStore';
import { fetchChannelInstances } from '../actions/fetchChannelInstances';
import { getIndexedAsyncChannelInstances } from '../selectors/getIndexedAsyncChannelInstances';
export const useChannelInstances = ({
  inboxId,
  deferred = false
}) => {
  const dispatch = useThreadDataDispatch();
  const fetcher = useCallback(({
    ids
  }) => dispatch(fetchChannelInstances({
    inboxIds: ids
  })), [dispatch]);
  const indexedAsyncData = useThreadDataSelector(getIndexedAsyncChannelInstances);
  const {
    entries,
    retryFailed
  } = useIndexedAsyncFetcher({
    fetcher,
    deferred: deferred || !inboxId,
    indexedAsyncData,
    ids: inboxId ? ImmutableSet([`${inboxId}`]) : ImmutableSet([])
  });
  return {
    asyncChannelInstances: inboxId ? entries.get(`${inboxId}`) : new AsyncData({
      status: UNINITIALIZED
    }),
    retryFailed
  };
};