import { createMetricsFactory } from 'metrics-js';
import once from 'shared-worker-versioning/lib/utils/once';
import { WEB_WORKER_VERSION } from '../constants/WebWorkerVersion';
import { getIsCrossTabMessagingSupported } from './getIsCrossTabMessagingSupported';
export const getMetricsFactory = once(() => {
  return createMetricsFactory('calling-cross-tab-library', {
    library: 'calling-cross-tab-library',
    dimensions: {
      crossTabMessagingSupport: `${getIsCrossTabMessagingSupported()}`,
      connectionVersion: WEB_WORKER_VERSION
    }
  });
});