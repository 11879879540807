import emptyFunction from 'react-utils/emptyFunction';
import { logCallingError } from 'calling-error-reporting/report/error';

// Copied from https://git.hubteam.com/HubSpotProtected/subscription-sidebar-cards/blob/master/card-subscription-highlight-lib/static/js/utils/feedback.ts

// Safety wrapper around `window.hsFeedback`
// https://product.hubteam.com/docs/sales-payments-docs/fe_surveys.html#csat-survey-implementation
function getFeedbackInstance() {
  if ('hsFeedback' in window) {
    return window.hsFeedback;
  }
  logCallingError({
    errorMessage: 'Error loading Call Summary CSAT Survey',
    tags: {
      component: 'call-summary'
    }
  });
  return {
    loadSurvey: emptyFunction
  };
}
export function loadCSATSurvey(id) {
  getFeedbackInstance().loadSurvey('CSAT', id);
}