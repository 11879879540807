'use es6';

import formatName from 'I18n/utils/formatName';

// https://git.hubteam.com/HubSpot/customer-data-email/blob/c81ffa5f59558e898278d4580d002c0f3973fa87/conversations-message-history/schema/email/EmailAddressRecord.js
export const getInfoFromEmailAddressRecord = emailRecord => {
  if (!emailRecord) {
    return {
      name: null,
      email: null
    };
  }
  const name = emailRecord.getFriendlyName(formatName);
  const email = emailRecord.getSendAsAddress();
  return {
    name,
    email
  };
};