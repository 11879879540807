import { List, fromJS } from 'immutable';
import { produce } from 'immer';
import PortalIdParser from 'PortalIdParser';
import transmuteGet from 'transmute/get';
import get from 'hs-lodash/get';
import set from 'hs-lodash/set';
import merge from 'hs-lodash/merge';
import { getId, getProperty, setProperty } from 'customer-data-objects/model/ImmutableModel';
import QuoteContact from 'customer-data-objects/quote/QuoteContactRecord';
import QuoteCompany from 'customer-data-objects/quote/QuoteCompanyRecord';
import { BOOLEAN_PROPERTIES, COMPANY_RECORD_TO_SENDER_COMPANY_MAP, DEFAULT_QUOTE_ASSOCIATED_OBJECTS, INTEGER_PROPERTIES, QUOTE_ASSOCIATIONS } from 'customer-data-objects/quote/constants/properties';
import { QUOTE } from 'customer-data-objects/constants/ObjectTypes';
export function isImmutableQuote(quote) {
  return typeof quote.setIn === 'function';
}
export function makeQuote(partialQuote = {}) {
  const defaultQuote = {
    quote: {
      isDeleted: false,
      objectId: null,
      objectType: QUOTE,
      portalId: PortalIdParser.get(),
      properties: {}
    },
    associations: {
      QUOTE_TO_DEAL: [],
      QUOTE_TO_LINE_ITEM: [],
      QUOTE_TO_CONTACT: [],
      QUOTE_TO_COMPANY: [],
      QUOTE_TO_QUOTE_TEMPLATE: [],
      QUOTE_TO_PAYMENT_SCHEDULE: []
    },
    quoteAssociatedObjects: {
      additionalFees: [],
      lineItems: [],
      contactSigners: [],
      userSigners: [],
      recipientCompany: null,
      recipientContacts: [],
      paymentSchedule: null,
      quoteTemplate: null,
      quoteFields: []
    }
  };
  return merge(defaultQuote, partialQuote);
}
function makeQuoteProperty(property) {
  return Object.assign({
    name: undefined,
    value: undefined,
    source: null,
    sourceId: null,
    timestamp: null,
    updatedByUserId: null
  }, property);
}
export function getHasDeal(quote) {
  if (isImmutableQuote(quote)) {
    const isDealIdInAssocationsArray = !!getQuoteAssociation(quote, QUOTE_ASSOCIATIONS.QUOTE_TO_DEAL).first();
    const isDealOnQuoteAssociatedObject = !!quote.getIn(['quote', 'associatedObjects', QUOTE_ASSOCIATIONS.QUOTE_TO_DEAL]);
    return isDealIdInAssocationsArray || isDealOnQuoteAssociatedObject;
  } else {
    const isDealIdInAssocationsArray = !!getQuoteAssociation(quote, QUOTE_ASSOCIATIONS.QUOTE_TO_DEAL)[0];
    const isDealOnQuoteAssociatedObject = !!get(quote, ['quote', 'associatedObjects', QUOTE_ASSOCIATIONS.QUOTE_TO_DEAL]);
    return isDealIdInAssocationsArray || isDealOnQuoteAssociatedObject;
  }
}
export function inboundPropertiesToQuoteObject(inboundDbRecord, propertyMapper, constructor) {
  const defaultProperties = new constructor();
  const isList = List.isList(propertyMapper);
  return propertyMapper.reduce((quoteProperties, value, key) => {
    const propertyKey = isList ? value : key;
    const inboundPropertyValue = getProperty(inboundDbRecord, value);
    return quoteProperties.set(propertyKey, inboundPropertyValue === undefined ? defaultProperties.get(propertyKey) : inboundPropertyValue);
  }, defaultProperties);
}
export function getQuoteCompany(CRMCompany) {
  return inboundPropertiesToQuoteObject(CRMCompany, COMPANY_RECORD_TO_SENDER_COMPANY_MAP.keySeq().toList(), QuoteCompany).set('companyId', String(getId(CRMCompany)));
}
export function getQuoteSenderContact(quote) {
  // Returns a QuoteContact record by extracting the inboundDB sender contact
  // properties from a quote
  if (isImmutableQuote(quote)) {
    return QuoteContact({
      firstname: getQuoteProperty(quote, 'hs_sender_firstname') || '',
      lastname: getQuoteProperty(quote, 'hs_sender_lastname') || '',
      email: getQuoteProperty(quote, 'hs_sender_email') || '',
      phone: getQuoteProperty(quote, 'hs_sender_phone') || '',
      jobtitle: getQuoteProperty(quote, 'hs_sender_jobtitle') || ''
    });
  } else {
    return {
      firstname: getQuoteProperty(quote, 'hs_sender_firstname') || '',
      lastname: getQuoteProperty(quote, 'hs_sender_lastname') || '',
      email: getQuoteProperty(quote, 'hs_sender_email') || '',
      phone: getQuoteProperty(quote, 'hs_sender_phone') || '',
      jobtitle: getQuoteProperty(quote, 'hs_sender_jobtitle') || ''
    };
  }
}
export function getQuoteId(quote) {
  if (isImmutableQuote(quote)) {
    return getId(quote.quote);
  }
  return quote.quote.objectId;
}
export function getDealId(quote) {
  if (isImmutableQuote(quote)) {
    return quote.associations[QUOTE_ASSOCIATIONS.QUOTE_TO_DEAL].first();
  }
  return quote.associations[QUOTE_ASSOCIATIONS.QUOTE_TO_DEAL][0];
}
export function getQuoteProperty(quote, property) {
  let value;
  const isAssociationProperty = Object.prototype.hasOwnProperty.call(DEFAULT_QUOTE_ASSOCIATED_OBJECTS, property);
  if (isImmutableQuote(quote)) {
    value = isAssociationProperty ? transmuteGet(property, quote.quoteAssociatedObjects) : getProperty(quote.quote, property);
  } else {
    value = isAssociationProperty ? get(quote.quoteAssociatedObjects, property) : get(quote.quote, ['properties', property, 'value']);
  }
  if (INTEGER_PROPERTIES.has(property)) {
    return value || value === 0 ? parseInt(value, 10) : null;
  } else if (BOOLEAN_PROPERTIES.has(property)) {
    return value === 'true' || value === true;
  }
  return value;
}
export function getQuoteAssociation(quote, associationType) {
  if (isImmutableQuote(quote)) {
    return quote.getIn(['associations', associationType]);
  }
  return get(quote, ['associations', associationType]);
}
export function setQuoteAssociations(quote, associations) {
  if (isImmutableQuote(quote)) {
    return quote.update('associations', currentAssociations => currentAssociations.merge(associations));
  } else {
    return produce(quote, draft => {
      draft.associations = Object.assign({}, draft.associations, associations);
    });
  }
}
export function setQuoteProperty(quote, propertyPath, value, source, sourceId) {
  // propertyPath can be a String or Array - to set nested properties within the
  // quoteAssociatedObjects object
  const isArray = Array.isArray(propertyPath);
  const topLevelProperty = isArray ? transmuteGet(0, propertyPath) : propertyPath;
  const isAssociationProperty = Object.prototype.hasOwnProperty.call(DEFAULT_QUOTE_ASSOCIATED_OBJECTS, topLevelProperty);
  if (isImmutableQuote(quote)) {
    if (isAssociationProperty) {
      const additionalPropertiesUpdatePath = isArray ? ['quoteAssociatedObjects'].concat(propertyPath) : ['quoteAssociatedObjects', propertyPath];
      return quote.setIn(additionalPropertiesUpdatePath, value);
    }
    if (isArray) {
      throw new Error('Use a string for propertyPath to set quote inboundDB properties');
    }
    return quote.update('quote', currentQuote => setProperty(currentQuote, propertyPath, value, source, sourceId));
  } else {
    if (isAssociationProperty) {
      const additionalPropertiesUpdatePath = isArray ? ['quoteAssociatedObjects'].concat(propertyPath) : ['quoteAssociatedObjects', propertyPath];
      return produce(quote, draft => {
        set(draft, additionalPropertiesUpdatePath, value);
      });
    }
    if (isArray) {
      throw new Error('Use a string for propertyPath to set quote inboundDB properties');
    }
    return produce(quote, draft => {
      if (draft.quote.properties[propertyPath]) {
        draft.quote.properties[propertyPath].value = value;
      } else {
        draft.quote.properties[propertyPath] = makeQuoteProperty({
          name: propertyPath,
          value
        });
      }
      if (source !== undefined) {
        draft.quote.properties[propertyPath].source = source;
      }
      if (sourceId !== undefined) {
        draft.quote.properties[propertyPath].sourceId = sourceId;
      }
    });
  }
}
export function mergeQuoteProperties(quote, updates) {
  if (isImmutableQuote(quote)) {
    const updatesMap = fromJS(updates);
    const inboundDbUpdates = updatesMap.filterNot((value, key) => Object.prototype.hasOwnProperty.call(DEFAULT_QUOTE_ASSOCIATED_OBJECTS, key));
    const additionalPropertyUpdates = updatesMap.filter((value, key) => Object.prototype.hasOwnProperty.call(DEFAULT_QUOTE_ASSOCIATED_OBJECTS, key));
    const updatedQuote = inboundDbUpdates.reduce((currentQuote, updatedValue, key) => {
      return setProperty(currentQuote, key, updatedValue);
    }, quote.quote);
    const updatedQuoteAdditionalProperties = quote.quoteAssociatedObjects.merge(additionalPropertyUpdates);
    return quote.merge({
      quote: updatedQuote,
      quoteAssociatedObjects: updatedQuoteAdditionalProperties
    });
  } else {
    return Object.entries(updates).reduce((acc, [propertyPath, value]) => {
      return setQuoteProperty(acc, propertyPath, value);
    }, quote);
  }
}