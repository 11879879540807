import { createSelector } from 'reselect';
import { getCurrentViewId } from '../../thread-list/selectors/getCurrentViewId';
import { selectViewMembersWithUpdates } from 'find-and-filter-data/view-members-data/public';
const getState = state => state;

//TODO ideally we'd be able to get this data from the useViewMembers hook
//and not expose implementation details via a selector like this, but
//this will make the migration to the newer functionality easier.
export const getThreadListMembersMap = createSelector([getState, getCurrentViewId], (state, viewId) => {
  return selectViewMembersWithUpdates(state, {
    viewId
  });
});