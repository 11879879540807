import { Map as ImmutableMap } from 'immutable';
import { handleActions } from 'redux-actions';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { FETCH_FROM_OMNI_BUS as omnibusActionTypes, POLL_OMNIBUS } from 'conversations-inbox-lib/omnibus/public/constants';
import { buildZeroStateFromOmnibus } from '../../omnibus/operators/buildZeroStateFromOmnibus';
const initialState = new AsyncData({
  data: ImmutableMap()
});
export default handleActions({
  [omnibusActionTypes.SUCCEEDED](state, action) {
    return requestSucceededWithOperator(() => {
      const zeroState = buildZeroStateFromOmnibus(action.payload.data);
      return zeroState;
    }, state);
  },
  [POLL_OMNIBUS.SUCCEEDED](state, action) {
    const alreadyInitialized = getData(state) !== getData(initialState);
    if (alreadyInitialized) return state;
    return requestSucceededWithOperator(() => {
      const zeroState = buildZeroStateFromOmnibus(action.payload.data);
      return zeroState;
    }, state);
  }
}, initialState);