import tracker from './usageTracker';
class ZorseTicketInspectorTracker {
  filtersApplied(payload) {
    this._trackUsage('filters-applied', {
      payload
    });
  }
  filtersCleared() {
    this._trackUsage('filters-cleared');
  }
  filtersOpened() {
    this._trackInteraction('filters-opened');
  }
  filtersClosed() {
    this._trackInteraction('filters-closed');
  }
  moreResultsClosed() {
    this._trackInteraction('more-results-closed');
  }
  moreResultsOpened() {
    this._trackInteraction('more-results-opened');
  }
  moreTicketsFetchInitiated() {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'undefined... Remove this comment to see the full error message
    this._trackUsage('more-tickets-fetched', {
      state: 'initiated'
    });
  }
  moreTicketsFetchSucceeded() {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'undefined... Remove this comment to see the full error message
    this._trackUsage('more-tickets-fetched', {
      state: 'succeeded'
    });
  }
  ticketOpened() {
    this._trackInteraction('ticket-opened');
  }
  ticketsSearchInitiated(source) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'undefined... Remove this comment to see the full error message
    this._trackUsage('tickets-searched', {
      source,
      state: 'initiated'
    });
  }
  ticketsSearchSucceeded(source) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'undefined... Remove this comment to see the full error message
    this._trackUsage('tickets-searched', {
      source,
      state: 'succeeded'
    });
  }
  viewInZorseOpened() {
    this._trackInteraction('view-in-zorse-opened');
  }
  _trackInteraction(action, {
    payload = undefined,
    source = undefined,
    state = undefined
  } = {}) {
    tracker.track('zorseTicketSearchInteraction', {
      screen: 'conversations',
      action,
      payload,
      source,
      state
    });
  }
  _trackUsage(action, {
    payload = undefined,
    source = undefined,
    state = undefined
  } = {}) {
    tracker.track('zorseTicketSearchUsage', {
      screen: 'conversations',
      action,
      payload,
      source,
      state
    });
  }
}
export default new ZorseTicketInspectorTracker();