'use es6';

import { useMemo } from 'react';
import { AGENT, BOT, VISITOR, INTEGRATOR } from 'conversations-message-history/common-message-format/constants/legacySenderTypes';
import { getSenderType } from 'conversations-message-history/senders/operators/senderLocatorGetters';
import { useIndexedAsyncAgentBotSenders } from './useIndexedAsyncAgentBotSenders';
import { useIndexedAsyncIntegratorSenders } from './useIndexedAsyncIntegratorSenders';
import { useIndexedAsyncVisitorSenders } from './useIndexedAsyncVisitorSenders';
export const useSenders = ({
  senderLocators,
  vid
}) => {
  const visitorSenderLocators = useMemo(() => senderLocators.filter(senderLocator => getSenderType(senderLocator) === VISITOR), [senderLocators]);
  const integratorSenderLocators = useMemo(() => senderLocators.filter(senderLocator => getSenderType(senderLocator) === INTEGRATOR), [senderLocators]);
  const agentAndBotSenderLocators = useMemo(() => senderLocators.filter(senderLocator => getSenderType(senderLocator) === AGENT || getSenderType(senderLocator) === BOT), [senderLocators]);
  const {
    visitorSenders
  } = useIndexedAsyncVisitorSenders({
    senderLocators: visitorSenderLocators,
    vid
  });
  const {
    agentBotSenders
  } = useIndexedAsyncAgentBotSenders({
    senderLocators: agentAndBotSenderLocators
  });
  const {
    integratorSenders
  } = useIndexedAsyncIntegratorSenders({
    senderLocators: integratorSenderLocators
  });
  const senders = useMemo(() => {
    return agentBotSenders.merge(visitorSenders, integratorSenders);
  }, [agentBotSenders, visitorSenders, integratorSenders]);
  return {
    senders
  };
};