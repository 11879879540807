// @ts-expect-error module not typed

import { getVisitor } from './threadDetailsGetters';
// @ts-expect-error module not typed
import { getEmail } from 'conversations-internal-schema/visitor/operators/visitorGetters';
export const COOL_ROBOT_EMAIL = 'coolrobot@hubspot.com';
export const MARIA_SAMPLE_CONTACT_EMAIL = 'emailmaria@hubspot.com';
export const SAMPLE_CONTACT_EMAILS = [COOL_ROBOT_EMAIL, MARIA_SAMPLE_CONTACT_EMAIL];
export const isTestThread = threadDetails => {
  const visitor = getVisitor(threadDetails);
  return SAMPLE_CONTACT_EMAILS.includes(getEmail(visitor));
};