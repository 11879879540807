/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectVisitorsState } from './visitorSelectors';
import { fetchVisitor } from './visitorsSlice';
export function useVisitor({
  skip = false,
  vid
}) {
  const dispatch = useDispatch();
  const visitors = useSelector(selectVisitorsState);
  const visitorData = vid && visitors[vid];
  const visitor = visitorData && visitorData.visitor || null;
  const loading = visitorData && visitorData.loading || false;
  useEffect(() => {
    if (vid && vid > 0 && !visitor && skip === false && loading === false) {
      dispatch(fetchVisitor({
        vid: vid
      }));
    }
  }, [dispatch, loading, vid, skip, visitor]);
  if (!vid) return {
    data: null,
    loading: false
  };
  return {
    data: visitor || null,
    loading
  };
}