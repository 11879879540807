import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"];
import { useThreadListMemberDetails } from './useThreadListMemberDetails';
export const useThreadVisitor = ({
  threadId
}) => {
  const _useThreadListMemberD = useThreadListMemberDetails({
      threadId
    }),
    {
      data: threadDetails
    } = _useThreadListMemberD,
    rest = _objectWithoutPropertiesLoose(_useThreadListMemberD, _excluded);
  return Object.assign({
    data: threadDetails && threadDetails.visitor
  }, rest);
};