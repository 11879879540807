import I18n from 'I18n';
import { createAction } from 'redux-actions';
import { SEND_EMAIL_COMPOSITION_FAILED } from '../constants/actionTypes';
function getFailureAlert({
  errorType
}) {
  switch (errorType) {
    case 'EmailSendingApiError.RATE_LIMITED':
      return {
        message: I18n.text(`conversations-inbox-ui.alerts.EMAIL_COMPOSITION_RATE_LIMIT.description`),
        titleText: I18n.text(`conversations-inbox-ui.alerts.EMAIL_COMPOSITION_RATE_LIMIT.title`)
      };
    case 'EmailSendingApiError.TOO_MANY_RECIPIENTS':
      return {
        message: I18n.text('conversations-inbox-ui.alerts.EMAIL_TOO_MANY_RECIPIENTS.description'),
        titleText: I18n.text('conversations-inbox-ui.alerts.EMAIL_TOO_MANY_RECIPIENTS.title')
      };
    default:
      return null;
  }
}
export const sendEmailCompositionFailed = createAction(SEND_EMAIL_COMPOSITION_FAILED, () => ({}), error => {
  const alert = error && error.responseJSON && getFailureAlert(error.responseJSON);
  return alert ? {
    error: {
      silent: true
    },
    notification: {
      message: alert.message,
      titleText: alert.titleText,
      type: 'danger',
      closeable: true,
      timeout: 3000
    }
  } : {};
});