'use es6';

import { compose } from 'redux';
import Immutable from 'immutable';
export const getCompose = () => {
  const shouldEnableDevTools = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  if (shouldEnableDevTools) {
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      name: 'Encapsulated Thread View',
      maxAge: 200,
      serialize: {
        options: {
          circular: '[CIRCULAR]'
        },
        immutable: Immutable
      }
    });
  }
  return compose;
};