import { SUPPORTED_THREAD_OBJ_PROPERTIES, SUPPORTED_TICKET_OBJ_PROPERTIES, THREAD_LIST_MEMBER_FIELDS_TO_UPDATE } from './optimisticUpdates';
const arePropertiesIncluded = allowedProperties => properties => {
  return properties.filter(prop => allowedProperties.includes(prop)).length > 0;
};

/**
 * @description This operator when given an array of ticket properties will return
 * whether any of those properties are included in SUPPORTED_TICKET_OBJ_PROPERTIES.
 * @param {string[]} properties The properties to compare and see if any are allowedProperties
 * @returns {boolean}
 */
export const arePropertiesSupportedTicketProps = arePropertiesIncluded(SUPPORTED_TICKET_OBJ_PROPERTIES);

/**
 * @description This operator when given an array of TLM properties will return
 * whether any of those properties are included in SUPPORTED_THREAD_OBJ_PROPERTIES.
 * @param {string[]} properties The properties to compare and see if any are allowedProperties
 * @returns {boolean}
 */
export const arePropertiesSupportedThreadProps = arePropertiesIncluded(SUPPORTED_THREAD_OBJ_PROPERTIES);

/**
 * @description This operator will return whether the given property is a property
 * that we support for optimistically updating the TLM object with when applying
 * a TLM update patch.
 *
 * @param {string} property
 * @returns boolean
 */
export const isThreadListMemberFieldToUpdate = property => THREAD_LIST_MEMBER_FIELDS_TO_UPDATE.includes(property);