import { getFromSenderEmail } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
// TODO: move to composer-data
import { getCommonMessage } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
// TODO: move to composer-data

// TODO: move to composer-data

import { findAccountByEmail, isAccountHubspotHosted } from '../../../connected-accounts/public/operators';
export const isEmailSuspended = ({
  connectedAccounts,
  hostedEmailEnabled,
  reply
}) => {
  if (hostedEmailEnabled) {
    return false;
  }
  if (connectedAccounts && reply) {
    const commonMessage = getCommonMessage(reply);
    const email = getFromSenderEmail(commonMessage);
    const fromAccount = findAccountByEmail(connectedAccounts, email);
    return isAccountHubspotHosted(fromAccount);
  }
  return false;
};