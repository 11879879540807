import { convertToPlaintext } from 'hubspot-prosemirror/prosemirror/ProsemirrorUtils';
import { isProsemirrorState } from '../../../prosemirror/protected/operators';
const DEFAULT_MAX_MESSAGE_LENGTH = 2500;
const getTrimmedEditorStateContent = editorState => {
  return isProsemirrorState(editorState) ? convertToPlaintext(editorState).trim() : '';
};
const getEditorStateSize = editorState => {
  const content = getTrimmedEditorStateContent(editorState);
  try {
    // eslint-disable-next-line compat/compat
    const encodedContent = new TextEncoder().encode(content);
    return encodedContent.length;
  } catch (error) {
    // TextEncoder is not supported in IE11, in that case return the content length
    return content.length;
  }
};
export const maxMessageLength = ({
  editorState,
  maxLength = DEFAULT_MAX_MESSAGE_LENGTH,
  maxSize
}) => {
  if (maxSize) {
    return getEditorStateSize(editorState) > maxSize ? {
      disabled: true,
      sendDisabledReason: 'OVER_CHARACTER_LIMIT',
      options: {
        characterLimit: maxSize
      }
    } : null;
  }
  const trimmedMessageLength = getTrimmedEditorStateContent(editorState).length;
  if (typeof trimmedMessageLength === 'number' && trimmedMessageLength > maxLength) {
    return {
      disabled: true,
      sendDisabledReason: 'OVER_CHARACTER_LIMIT',
      options: {
        characterLimit: maxLength
      }
    };
  }
  return null;
};