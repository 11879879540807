// @ts-expect-error module not typed

// @ts-expect-error untyped
import { HUBSPOT_HOSTED } from 'conversations-internal-schema/connected-email-account/constants/EmailAccountTypes';
import get from 'transmute/get';
import { useAuthorizedEmailDomains } from './useAuthorizedEmailDomains';
function addressMatchesDomain({
  sendFromEmail,
  domain
}) {
  return sendFromEmail.match(new RegExp(`@${domain}`, 'i'));
}
function isDomainFullyAuthenticatedAndUsedForSending({
  domain,
  emailSendingDomainState: {
    isDkimConnected,
    isDmarcConnected
  }
}, sendFromEmail) {
  return isDkimConnected && isDmarcConnected && addressMatchesDomain({
    sendFromEmail,
    domain
  });
}
function isAccountSendingFromFullyAuthenticatedDomain(account, authorizedDomains) {
  const email = get('email', account);
  const sendFromEmail = get('sendFromEmail', account);
  const hasEmailAlias = sendFromEmail && email !== sendFromEmail;
  const isHubSpotHosted = get('emailAccountType', account) === HUBSPOT_HOSTED;
  const requiresAuthorizedDomain = isHubSpotHosted && hasEmailAlias;
  return !requiresAuthorizedDomain || !authorizedDomains || authorizedDomains.some(domain => isDomainFullyAuthenticatedAndUsedForSending(domain, sendFromEmail));
}
export function useAccountsWithUnauthenticatedDomains({
  connectedEmailAccounts
}) {
  const {
    emailDomains
  } = useAuthorizedEmailDomains();
  return {
    unauthorizedAccounts: connectedEmailAccounts ? connectedEmailAccounts.filter(account => !isAccountSendingFromFullyAuthenticatedDomain(account, emailDomains)) : []
  };
}