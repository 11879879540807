import { Record } from 'immutable';
import APIResponse from '../../records/APIResponse';
export const CallingAdminSettingsResult = Record({
  portalId: 0,
  lastUpdatedBy: 0,
  hubSpotCallingEnabled: true,
  callOutcomeRequired: false,
  createdAt: '',
  updatedAt: ''
}, 'CallingAdminSettingsResult');
class CallingAdminSettings extends APIResponse {
  constructor(props) {
    if (!props) {
      super(props);
      return;
    }
    super(Object.assign({}, props, {
      result: props.result ? new CallingAdminSettingsResult(props.result) : null
    }));
  }
}
export default CallingAdminSettings;