import I18n from 'I18n';
import { DEFAULT_AGENT, DEFAULT_AUTOMATED, DEFAULT_CUSTOMER, DEFAULT_EXTERNAL, DEFAULT_INTEGRATOR, DEFAULT_LLM, DEFAULT_UNKNOWN } from '../constants/friendlyNameResolutionSources';

/**
 * Provides default friendly names to replace BE defaults when they can't be i18ned properly by the BE (e.g. can't use user language preference)
 * These should match the BE values located here: https://git.hubteam.com/HubSpot/cv-visitor-resolution/blob/35632d13e7db83ca3977c09a7df6db892548898a/cv-visitor-resolution-data/src/main/resources/lang/en.lyaml
 */
export const getDefaultedFriendlyName = ({
  resolvedFriendlyName,
  resolutionSource
}) => {
  if (resolutionSource === DEFAULT_AGENT) return I18n.text('conversations-message-history.friendlyNames.DEFAULT_AGENT');
  if (resolutionSource === DEFAULT_AUTOMATED) return I18n.text('conversations-message-history.friendlyNames.DEFAULT_AUTOMATED');
  if (resolutionSource === DEFAULT_CUSTOMER) return I18n.text('conversations-message-history.friendlyNames.DEFAULT_CUSTOMER');
  if (resolutionSource === DEFAULT_EXTERNAL) return I18n.text('conversations-message-history.friendlyNames.DEFAULT_EXTERNAL');
  if (resolutionSource === DEFAULT_INTEGRATOR) return I18n.text('conversations-message-history.friendlyNames.DEFAULT_INTEGRATOR');
  if (resolutionSource === DEFAULT_LLM) return I18n.text('conversations-message-history.friendlyNames.DEFAULT_LLM');
  if (resolutionSource === DEFAULT_UNKNOWN) return I18n.text('conversations-message-history.friendlyNames.DEFAULT_UNKNOWN');
  return resolvedFriendlyName;
};