/**
 * When call-widget-addon or calling-global-api are used in an iframe
 * we need to create a message wrapper to use our top level window.hsCalling api
 */

import { UUID } from 'shared-worker-versioning/lib/utils/UUID';
import APIMethods from '../methods/APIMethods';
import { logJSAPIPageAction } from './logPageAction';
import { addMessageEventListener, getOrigin, getWindowPathName, postParentMessage, postMessageToSourceIframe, getWindowLocation } from './windowUtils';
export const IFRAME_HOST = 'HOST';
export const MSG_FORMAT_HS_CALLING_API = '__hs-calling-api';
export const MSG_FORMAT_VERSION = 1;
export const HS_CALLING_UTILS_MESSAGE = 'HS_CALLING_UTILS_MESSAGE';
export class IframeMessagingHelper {
  constructor() {
    this.registerEventTypeHandlers = handlers => {
      Object.keys(handlers).forEach(type => {
        if (this._EVENT_TYPE_HANDLERS[type]) {
          return;
        }
        this._EVENT_TYPE_HANDLERS[type] = handlers[type];
      });
    };
    this._handleCallingJSAPIMessage = messageEvent => {
      let messageHandled = false;
      const {
        payload,
        id
      } = messageEvent.data || {};
      const method = payload && payload.data && payload.data.method;
      if (method) {
        const {
          options
        } = payload.data;
        const apiMethodObject = APIMethods[method];
        const apiMethod = apiMethodObject && apiMethodObject.default || apiMethodObject;
        const optionsParser = apiMethodObject && apiMethodObject.parser;
        if (typeof apiMethod !== 'function') {
          return messageHandled;
        }
        const parsedOptions = typeof optionsParser === 'function' ? optionsParser(options) : options;
        apiMethod(parsedOptions).then((response = {}) => {
          this.sendMessageToEmbed(messageEvent.data, {
            response
          });
        })
        // TODO: handle error
        .catch(() => void 0);
        messageHandled = true;
      } else if (typeof this._CALLBACKS[id] === 'function') {
        this._CALLBACKS[id](payload.data.response);
        delete this._CALLBACKS[id];
        messageHandled = true;
      }
      return messageHandled;
    };
    this._handleInboundMessage = messageEvent => {
      const {
        format,
        v,
        payload,
        to,
        from
      } = messageEvent.data || {};
      let messageHandled = false;
      if (format === MSG_FORMAT_HS_CALLING_API && v > 0 && v <= this._VERSION) {
        const {
          type
        } = payload || {};
        if (type === HS_CALLING_UTILS_MESSAGE) {
          messageHandled = this._handleCallingJSAPIMessage(messageEvent);
        } else if (this._EVENT_TYPE_HANDLERS[payload.type]) {
          this._EVENT_TYPE_HANDLERS[payload.type](payload, messageEvent.data);
          messageHandled = true;
        }
        if (!messageHandled) {
          logJSAPIPageAction({
            type,
            destination: to,
            origin: from,
            embedId: this._EMBED_ID,
            location: getWindowLocation().href
          });
        }
      }
      return messageHandled;
    };
    this.createMessage = (data, {
      to,
      from
    }, type) => ({
      id: UUID(),
      format: MSG_FORMAT_HS_CALLING_API,
      from,
      to,
      v: this._VERSION,
      payload: {
        type: type || HS_CALLING_UTILS_MESSAGE,
        data
      }
    });
    this.createReplyMessage = (replyToMessage, data, type) => Object.assign({}, this.createMessage(data, {
      from: replyToMessage.to,
      to: replyToMessage.from
    }, type), {
      id: replyToMessage.id
    });
    this.sendMessageToEmbed = (replyToMessage, data, callback, type) => {
      const message = this.createReplyMessage(replyToMessage, data, type);
      if (callback) {
        this._CALLBACKS[message.id] = callback;
      }
      postMessageToSourceIframe(replyToMessage, message, this._WINDOW_ORIGIN);
    };
    this.sendMessageToEmbedInstance = (iframe, data, type) => {
      if (iframe && iframe.contentWindow) {
        const message = this.createMessage(data, {
          to: this._EMBED_ID,
          from: IFRAME_HOST
        }, type);
        iframe.contentWindow.postMessage(message, this._WINDOW_ORIGIN);
      }
    };
    this.sendMessageToHost = (data, callback, type) => {
      const message = this.createMessage(data, {
        to: IFRAME_HOST,
        from: this._EMBED_ID
      }, type);
      if (callback) {
        this._CALLBACKS[message.id] = callback;
      }
      postParentMessage(message, this._WINDOW_ORIGIN);
    };
    this._EMBED_ID = getWindowPathName();
    this._WINDOW_ORIGIN = getOrigin();
    this._CALLBACKS = {};
    this._EVENT_TYPE_HANDLERS = {};
    this._VERSION = MSG_FORMAT_VERSION;
    addMessageEventListener(this._handleInboundMessage);
  }
}
export default new IframeMessagingHelper();