import PropertyRecord from '../records/PropertyRecord';
import ThreadRecord from '../records/ThreadRecord';
import { FETCH_PROPERTIES_FOR_THREAD, MARK_THREAD_AS_GHOSTED, PATCH_PROPERTY_FOR_THREAD } from '../actions/ActionTypes';
const processLoadPropertiesForThread = (state, action) => {
  if (action.meta === 'request') {
    return new ThreadRecord({
      loading: true
    });
  } else if (action.meta === 'response') {
    return ThreadRecord.fromJS(action.payload);
  }
  return state.set('loading', false);
};
const processPatchPropertyForThread = (state, action) => {
  if (action.meta === 'request') {
    return state.set('loading', true);
  } else if (action.meta === 'response') {
    let tempState = state;
    action.payload.forEach(property => {
      tempState = tempState.setIn(['properties', property.name], new PropertyRecord(property));
    });
    return tempState;
  }
  return state.set('loading', false);
};
const threadProperties = (state = new ThreadRecord(), action) => {
  switch (action.type) {
    case FETCH_PROPERTIES_FOR_THREAD:
      return processLoadPropertiesForThread(state, action);
    case MARK_THREAD_AS_GHOSTED:
      return state.set('ghosted', action.value);
    case PATCH_PROPERTY_FOR_THREAD:
      return processPatchPropertyForThread(state, action);
    default:
      return state;
  }
};
export default threadProperties;