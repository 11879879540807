import { getLatestReceivedTimestamp } from 'find-and-filter-data/view-members-schema/protected';
import get from 'transmute/get';
export const hasLatestReceivedTSIncreased = (prevThreadListMembers, nextThreadListMembers) => {
  return nextThreadListMembers && nextThreadListMembers.some((nextThread, threadId) => {
    const prevThread = get(threadId, prevThreadListMembers);
    if (getLatestReceivedTimestamp(prevThread) === undefined && getLatestReceivedTimestamp(nextThread) ||
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    getLatestReceivedTimestamp(nextThread) >
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    getLatestReceivedTimestamp(prevThread)) {
      return true;
    }
    return false;
  });
};