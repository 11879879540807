export const mapAssociationsResponseToSupplementalObjects = associationsResponse => ({
  supplementalObjects: (associationsResponse || []).reduce((ids, association) => {
    const {
      type,
      id
    } = association;
    return ids[type] === undefined || ids[type] < id ? Object.assign({}, ids, {
      [type]: id
    }) : ids;
  }, {})
});