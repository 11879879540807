import http from 'conversations-http/clients/http';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import ThreadHistory from 'conversations-message-history/thread-history/records/ThreadHistory';
import { getTicketSubject } from '../operators/thread/getTicketSubject';
import { getTicketContent } from '../operators/thread/getTicketContent';
import { EMAIL_GENERIC_CHANNEL_ID, FB_MESSENGER_GENERIC_CHANNEL_ID, FORMS_GENERIC_CHANNEL_ID, LIVE_CHAT_GENERIC_CHANNEL_ID, WHATSAPP_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
import { getGenericChannel } from 'find-and-filter-data/view-members-schema/protected';
const mapSourceToCRMProperties = originalGenericChannelId => {
  switch (originalGenericChannelId) {
    case EMAIL_GENERIC_CHANNEL_ID:
      return 'EMAIL';
    case FB_MESSENGER_GENERIC_CHANNEL_ID:
      return 'FACEBOOK_MESSENGER';
    case FORMS_GENERIC_CHANNEL_ID:
      return 'FORMS';
    case LIVE_CHAT_GENERIC_CHANNEL_ID:
      return 'CHAT';
    case WHATSAPP_GENERIC_CHANNEL_ID:
      return 'WHATS_APP';
    default:
      return originalGenericChannelId;
  }
};
const fetchThreadListMemberMetadata = ({
  threadId
}) => {
  const url = `conversations-threads/v1/threads/${threadId}`;
  return http.get(url);
};

/**
 * GQL Candidate
 *
 * There's no reason to fetch thread history messages again if ThreadView already
 * has fetched them. This request could be handled through data-fetching-client
 * so we can access the Apollo cache results or through apollo entirely once the
 * ThreadView begins fetching ThreadHistory via GQL.
 */
const fetchFirstMessagesInThreadHistory = ({
  threadId
}) => http.get(`messages/v3/history/${threadId}/agent`, {
  query: {
    limit: 5,
    sortDirection: 'ASCENDING'
  }
});
export const getTicketDetailsForThread = ({
  threadId
}) => Promise.all([fetchThreadListMemberMetadata({
  threadId
}), fetchFirstMessagesInThreadHistory({
  threadId
})]).then(([threadListMember, history]) => {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'JSON' is not assignable to param... Remove this comment to see the full error message
  const originalGenericChannelId = getGenericChannel(threadListMember);
  const threadHistory = new ThreadHistory(history);
  const ticketData = {
    source: mapSourceToCRMProperties(originalGenericChannelId),
    ticketContent: getTicketContent(originalGenericChannelId, threadHistory),
    ticketSubject: getTicketSubject(originalGenericChannelId, threadHistory)
  };
  return ticketData;
});