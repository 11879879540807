import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { asyncDataInvariant } from '../invariants/asyncDataInvariant';
import { useAsyncFetcher } from './useAsyncFetcher';
const defaultIdTransform = id => ({
  id
});

/**
 * @description a redux hook built around `useAsyncFetcher` that
 * can be passed a selector and fetching action, and will manage
 * connecting to the store to get data, as well as wiring up the
 * action to a dispatcher.
 * It delegates data fetching logic to `useAsyncFetcher` by passing
 * in a pre-bound fetcher along with the selected AsyncData record.
 * @param {Object} options
 * @param {Function} options.action an action (thunk) creator that
 * will trigger the async data to be fetched when called.
 * @param {Any} options.id an optional id to be passed to the
 * selector and action creator.
 * @param {Function} [options.idTransform] a transformer
 * function to mutate how the id is passed to selectors and
 * actions
 * @param {Function} options.selector a selector that returns
 * a valid AsyncData record. It is an error for this selector
 * to return a non-AsyncData record.
 * @param {boolean} options.deferred if true, the fetcher will not fire
 * regardless of status. defaults to false
 * @returns {Object}
 */
export const useReduxAsyncFetcher = ({
  action,
  id,
  idTransform = defaultIdTransform,
  selector,
  deferred = false
}) => {
  const hasId = useMemo(() => id !== null && id !== undefined, [id]);
  const dispatch = useDispatch();
  const asyncData = useSelector(state => hasId ? selector(state, idTransform(id)) : selector(state, undefined));
  asyncDataInvariant(asyncData);
  const fetcher = useCallback(() => dispatch(hasId ? action(idTransform(id)) : action()), [action, dispatch, hasId, id, idTransform]);
  const {
    retryFailed
  } = useAsyncFetcher({
    asyncData,
    deferred,
    fetcher
  });
  return {
    asyncData,
    retryFailed
  };
};