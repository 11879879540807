'use es6';

import { fetchCallees } from 'calling-lifecycle-internal/callees/clients/calleesClient';
import { CalleesRecord } from 'calling-lifecycle-internal/callees/records/CalleesRecordsTyped';
export function refreshCalleeOmnibusData({
  objectId,
  objectTypeId
}) {
  return fetchCallees({
    objectId,
    objectTypeId
  }).then(response => {
    if (response['@result'] === 'OK') {
      return CalleesRecord.fromJS(response);
    }
    return null;
  });
}