'use es6';

import { Record } from 'immutable';
import get from 'transmute/get';
import update from 'transmute/update';
const DISMISSED_AT = 'dismissedAt';
const VIEWED_COUNT = 'viewedCount';
const UserPromptData = Record({
  [DISMISSED_AT]: 0,
  [VIEWED_COUNT]: 0
}, 'UserPromptData');
export const getViewedCount = get(VIEWED_COUNT);
export const getDismissedAt = get(DISMISSED_AT);
export const updateDismissedAt = update(DISMISSED_AT);
export const updateViewedCount = update(VIEWED_COUNT);
export default UserPromptData;