import Raven from 'raven-js';
const DAY_IN_MS = 24 * 60 * 60 * 1000;

// eslint-disable-next-line hubspot-dev/no-declarations

window.setFullstoryDebugMode = function (debug) {
  try {
    if (debug) {
      localStorage.setItem('data-ops-fullstory', new Date().getTime().toString());
    } else {
      localStorage.removeItem('data-ops-fullstory');
    }
  } catch (e) {
    Raven.captureException(e);
  }
};
export function getDebugMode() {
  try {
    const storageValue = localStorage.getItem('data-ops-fullstory');
    const debugExpiryTime = storageValue ? parseInt(storageValue, 10) + DAY_IN_MS : null;
    return debugExpiryTime && debugExpiryTime > new Date().getTime();
  } catch (e) {
    return false;
  }
}
export function debugLog(...args) {
  if (getDebugMode()) {
    console.log('Data-Ops-Fullstory: ', ...args);
  }
}