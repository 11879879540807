import { useCurrentReplyRecommendation } from './useCurrentReplyRecommendation';
import { makeVar, useReactiveVar } from '@apollo/client';
const isReplyRecOpenVar = makeVar(false);
export const useReplyRecommendationsConfig = ({
  threadId
}) => {
  const isReplyRecOpen = useReactiveVar(isReplyRecOpenVar);
  const {
    currentReplyRecommendation
  } = useCurrentReplyRecommendation({
    threadId
  });
  return {
    isReplyRecOpen,
    setIsReplyRecOpen: isReplyRecOpenVar,
    hasReplyRec: !!currentReplyRecommendation
  };
};