import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestOutOfSync } from 'conversations-async-data/async-data/operators/requestOutOfSync';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { updateAsyncData } from 'conversations-async-data/async-data/operators/updateAsyncData';
import { Map as ImmutableMap } from 'immutable';
import { handleActions } from 'redux-actions';
import set from 'transmute/set';
import { FETCH_CONNECTED_ACCOUNTS, TOGGLE_CONNECTED_ACCOUNT } from '../constants/asyncActionTypes';
import { getIndexId } from '../operators/getIndexId';
import { INVALIDATE_CONNECTED_ACCOUNTS } from '../constants/actionTypes';

// @ts-expect-error ts-migrate(2348) FIXME: Value of type 'new <AsyncDataValue>(t?: Partial<As... Remove this comment to see the full error message
const initialState = AsyncData({
  data: ImmutableMap()
});
export default handleActions({
  [INVALIDATE_CONNECTED_ACCOUNTS](state) {
    return requestOutOfSync(state);
  },
  [FETCH_CONNECTED_ACCOUNTS.STARTED](state) {
    return requestStarted(state);
  },
  [FETCH_CONNECTED_ACCOUNTS.FAILED](state) {
    return requestFailed(state);
  },
  [FETCH_CONNECTED_ACCOUNTS.SUCCEEDED](state, action) {
    const {
      data
    } = action.payload;
    return requestSucceededWithOperator(() => data, state);
  },
  [TOGGLE_CONNECTED_ACCOUNT.SUCCEEDED](state, action) {
    const {
      data: account
    } = action.payload;
    return updateAsyncData(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    data => set(getIndexId(account), account, data), state);
  }
}, initialState);