import { useMemo } from 'react';
import get from 'hs-lodash/get';
import keyBy from 'hs-lodash/keyBy';
const mapToProperty = ({
  name,
  value
}) => ({
  name,
  value
});
const getContactProperties = data => {
  const edges = get(data, ['crmObject', 'defaultAssociations', 'toContacts', 'edges'], []);
  return edges.length ? edges[0].node.properties.map(mapToProperty) : [];
};
const getObjectProperties = data => {
  const properties = get(data, ['crmObject', 'properties'], []);
  return properties.map(mapToProperty);
};
const useProperties = data => useMemo(() => {
  const contactProperties = getContactProperties(data);
  const objectProperties = getObjectProperties(data);
  const properties = [...contactProperties, ...objectProperties];
  return keyBy(properties, 'name');
}, [data]);
export default useProperties;