import formatName from 'I18n/utils/formatName';
import { ACTIVE } from '../constants/activation-status';
import { getAgentId, getBotId, getFirstName, getLastName } from '../operators/assignees';
import { createSearchMoreSection, createTeamsSection, createUnassignSection, createUsersSection } from './reassignment-sections';
export const buildAssigneeOptions = (response, pagination) => {
  const newAssignees = Array.isArray(response.assignees) ? response.assignees : [];
  const allAssignees = pagination.assignees.concat(newAssignees);
  const newPagination = {
    assignees: allAssignees,
    hasMore: Boolean(response.hasMore),
    offset: allAssignees.length
  };
  const assigneeOptions = newPagination.assignees.filter(assignee => assignee !== null && assignee.activationStatus === ACTIVE).map(assignee => {
    const option = {
      text: '',
      value: 0,
      responder: assignee
    };
    if (assignee) {
      option.text = formatName({
        firstName: getFirstName(assignee),
        lastName: getLastName(assignee)
      });
      option.value = getAgentId(assignee) || getBotId(assignee) || 0;
    }
    return option;
  });
  return {
    assigneeOptions,
    pagination: newPagination
  };
};
const getAssigneeOptions = ({
  currentUserId,
  filterValue,
  teams,
  users,
  isUngatedForHelpDeskManualAssignmentScopeFiltering
}) => {
  const currentAgentIndex = users.findIndex(option => `${option.value}` === `${currentUserId}`);
  const options = [];
  let userOptions = users;
  if (currentAgentIndex !== -1) {
    options.push(users[currentAgentIndex]);
    userOptions = [...users.slice(0, currentAgentIndex), ...users.slice(currentAgentIndex + 1)];
  }
  return options.concat(createTeamsSection(teams, filterValue, isUngatedForHelpDeskManualAssignmentScopeFiltering), createUsersSection(userOptions));
};
export const buildReassignmentOptions = ({
  currentUserId,
  filterValue,
  hasMore,
  teams,
  users,
  isUngatedForHelpDeskManualAssignmentScopeFiltering
}) => {
  const options = filterValue.length ? [] : [createUnassignSection()];
  if (teams.length || users.length) {
    options.push(...getAssigneeOptions({
      currentUserId,
      filterValue,
      teams,
      users,
      isUngatedForHelpDeskManualAssignmentScopeFiltering
    }));
  }
  if (hasMore) {
    options.push(createSearchMoreSection());
  }
  return options;
};