import { useCallback, useMemo } from 'react';
import { getRoutingRuleTypeForEventTracking } from '../_internal/operators/getRoutingRuleTypeForEventTracking';
import { getClickedMoveToInboxAssignToDropdown, getClickedMoveToInboxAssignToDropdownOption } from '../../usage-tracking/utils/threadInteraction';
// @ts-expect-error module not typed
import { trackEvent } from '../../usage-tracking/clients/usageTracker';
import { buildRequiredRoutingRulesPropsFromQueries } from '../_internal/operators/buildRequiredRoutingRulesPropsFromQueries';
import { useFetchTeamUsers, useFetchUsersAndTeamsHierarchy } from 'conversations-thread-data/move-to-inbox/public/hooks';
import isEmpty from 'transmute/isEmpty';
import { useFetchUserInfo } from 'conversations-thread-data/auth/public/hooks';
import { buildTeamsSet } from 'routing-ui/routing-rules/operators/buildTeamsSet';
import { USERS_AND_TEAMS } from 'conversations-internal-schema/inbox/constants/InboxVisibilityTypes';
import { assertIsDefined } from 'ui-addon-conversations-settings/utils/assert';
import { filterUnroutableTeams } from 'routing-ui/routing-rules/operators/filterUnroutableTeams';
import { getIsUngatedForHelpDesk, getIsUngatedForHelpDeskRoutingScopeFiltering } from 'conversations-thread-data/auth/public/selectors';
import { useInboxes } from 'conversations-thread-data/inboxes/public/hooks';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { useSelector } from 'react-redux';
import { canKeepAssigneeOnNewInbox } from 'conversations-thread-data/move-to-inbox/public/operators';
import { isInboxHelpdesk } from 'conversations-internal-schema/inbox/operators/inboxGetters';
export const useRoutingRules = ({
  assigneeId,
  selectedInboxId,
  routingRuleDefinition,
  setRoutingRuleDefinition
}) => {
  const isUngatedForHelpDesk = useSelector(getIsUngatedForHelpDesk);
  const isUngatedForHelpDeskRoutingScopeFiltering = useSelector(getIsUngatedForHelpDeskRoutingScopeFiltering);
  const {
    asyncInboxes
  } = useInboxes(isUngatedForHelpDesk);
  const inboxData = getData(asyncInboxes);
  const {
    data: userInfoData,
    loading: userInfoDataIsLoading
  } = useFetchUserInfo();
  const {
    data: hierarchyData,
    loading: hierarchyDataIsLoading
  } = useFetchUsersAndTeamsHierarchy({
    inboxId: selectedInboxId,
    skip: !selectedInboxId
  });
  const {
    data: teamUsersData,
    loading: teamUsersDataIsLoading
  } = useFetchTeamUsers({
    // should not fetch unless selectedInboxId is defined, pass empty string as placeholder
    inboxId: selectedInboxId || '',
    agentIds: userInfoData ? [userInfoData.userInfo.user.user_id] : [],
    skip: isEmpty(selectedInboxId) || isEmpty(userInfoData)
  });
  const handleRoutingRuleOnChange = useCallback(routingRule => {
    const type = getRoutingRuleTypeForEventTracking(routingRule);
    trackEvent(getClickedMoveToInboxAssignToDropdownOption({
      type
    }));
    setRoutingRuleDefinition(routingRule);
  }, [setRoutingRuleDefinition]);
  const handleRoutingRuleOnOpen = useCallback(evt => {
    const isOpen = evt.target.value;
    if (isOpen) {
      trackEvent(getClickedMoveToInboxAssignToDropdown());
    }
  }, []);
  const hierarchyResponse = (hierarchyData || {}).usersAndTeamsHierarchy;
  const teamUsersResponse = (teamUsersData || {}).users;
  const userInfoResponse = (userInfoData || {}).userInfo;
  const eligibleTeams = useMemo(() => {
    if (!inboxData || !hierarchyResponse || !selectedInboxId) return [];
    const selectedInbox = inboxData.find(ibx => ibx.id === selectedInboxId);
    assertIsDefined(selectedInbox);
    const teamIds = selectedInbox.teamIds;
    const visibility = selectedInbox.visibility;
    const visibleTeams = visibility === USERS_AND_TEAMS && !isInboxHelpdesk(selectedInbox) ? new Set(teamIds) : buildTeamsSet(hierarchyResponse);
    return filterUnroutableTeams(hierarchyResponse, visibleTeams);
  }, [hierarchyResponse, inboxData, selectedInboxId]);
  const baseRoutingRulesProps = useMemo(() => buildRequiredRoutingRulesPropsFromQueries({
    hierarchyResponse: eligibleTeams,
    isLoadingUsersAndTeams: hierarchyDataIsLoading || userInfoDataIsLoading || teamUsersDataIsLoading,
    routingRules: routingRuleDefinition,
    onRuleUpdate: handleRoutingRuleOnChange,
    teamUsersResponse,
    userInfoResponse,
    isUngatedForHelpDeskRoutingScopeFiltering
  }), [eligibleTeams, handleRoutingRuleOnChange, hierarchyDataIsLoading, isUngatedForHelpDeskRoutingScopeFiltering, routingRuleDefinition, teamUsersDataIsLoading, teamUsersResponse, userInfoDataIsLoading, userInfoResponse]);
  const canKeepAssignee = useMemo(() => canKeepAssigneeOnNewInbox({
    assigneeId,
    teamUsersResponse
  }), [assigneeId, teamUsersResponse]);
  return {
    handleRoutingRuleOnOpen,
    baseRoutingRulesProps,
    userInfoDataIsLoading,
    canKeepAssignee
  };
};