'use es6';

import http from 'hub-http/clients/apiClient';
const BASE_URL = 'rep-chat-assignment/preferences';
class UserPreferencesApi {
  getUserPreferences() {
    return http.get(BASE_URL);
  }
  saveUserPreferences(userPreferences) {
    return http.post(BASE_URL, {
      data: userPreferences
    });
  }
}
export default UserPreferencesApi;