import { useCallback } from 'react';
// @ts-expect-error Legacy code isn't typed
import { usePermissionsContext } from 'customer-data-sidebar/universal/context/PermissionsContext';
// @ts-expect-error Legacy code isn't typed
import withGateOverride from 'crm_data/gates/withGateOverride';
export function useGetIsUngated() {
  const {
    gates
  } = usePermissionsContext();
  return useCallback(gateName => withGateOverride(gateName, gates.includes(gateName)), [gates]);
}