import { fromJS, List } from 'immutable';
import { buildCommonMessage } from 'conversations-message-history/common-message-format/operators/buildCommonMessage';
import { getEditorStateFromRawContent } from '../../../editor-state/protected/operators';
import CommonReply from 'conversations-thread-data/common-reply/public/records/CommonReply';
import { buildMetadataFromJS } from 'composer-data/email-metadata/public/operators';
export const deserializeCommonReplyDraft = rawDraft => {
  try {
    const parsedDraft = JSON.parse(rawDraft);
    const {
      attachments,
      commonMessage,
      metadata,
      rawContent,
      replyId = null,
      timestamp = null,
      version
    } = parsedDraft;
    const deserializedAttachments = fromJS(attachments) || List();
    const deserializedMetadata = buildMetadataFromJS(metadata);
    const deserializedCommonMessage = commonMessage && buildCommonMessage(commonMessage) || null;
    if (rawContent) {
      const editorState = getEditorStateFromRawContent(rawContent);
      if (editorState) {
        return CommonReply({
          attachments: deserializedAttachments,
          commonMessage: deserializedCommonMessage,
          editorState,
          metadata: deserializedMetadata,
          replyId,
          timestamp,
          version: version && parseInt(version, 10) || null
        });
      }
    }
    return null;
  } catch (e) {
    return null;
  }
};