/* hs-eslint ignored failing-rules */

import { silenceErrorAlert } from 'conversations-error-reporting/error-actions/builders/silenceErrorAlert';
import { RESUBSCRIBE } from '../constants/actionTypes';
import { DEFAULT_CLIENT_KEY } from '../constants/clientKeys';
import { getPubSubClient } from '../selectors/pubSubClientGetters';
import { getSubscriptions } from '../selectors/subscriptionGetters';
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'subscriptions' implicitly has an 'any' ... Remove this comment to see the full error message
const resubscribeStarted = (subscriptions, clientKey = DEFAULT_CLIENT_KEY) => ({
  type: RESUBSCRIBE.STARTED,
  payload: {
    clientKey,
    subscriptions
  }
});
const resubscribeSucceeded = (
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'subscriptions' implicitly has an 'any' ... Remove this comment to see the full error message
subscriptions, clientKey = DEFAULT_CLIENT_KEY) => ({
  type: RESUBSCRIBE.SUCCEEDED,
  payload: {
    clientKey,
    subscriptions
  }
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
const resubscribeFailed = (error, clientKey = DEFAULT_CLIENT_KEY) => ({
  type: RESUBSCRIBE.FAILED,
  payload: {
    clientKey,
    error
  },
  meta: silenceErrorAlert()
});

/**
 * Resubscribe to the current subscriptions.
 */
export const resubscribe = (clientKey = DEFAULT_CLIENT_KEY) => (dispatch, getState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
  const client = getPubSubClient(getState(), {
    clientKey
  });
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
  const subscriptions = getSubscriptions(getState(), {
    clientKey
  });
  dispatch(resubscribeStarted(subscriptions, clientKey));
  client.updateSubscriptions(subscriptions).then(result => {
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    if (result === client.updateSubscriptions.DEBOUNCED) {
      // An out of date subscription update was skipped in favor of a more recent update.
      return;
    }
    dispatch(resubscribeSucceeded(result, clientKey));
  }, error => {
    dispatch(resubscribeFailed(error, clientKey));
  }).catch(err => {
    setTimeout(() => {
      dispatch(resubscribeFailed(err, clientKey));
    });
  });
};