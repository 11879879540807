/**
 * Type guard for None
 * @param nullable any value that is Nullable
 * @returns boolean
 *
 * isNone(null) -> true
 * isNone(undefined) -> true
 * isNone('') -> false
 * isNone(0) -> false
 * isNone(false) -> false
 * isNone({}) -> false
 * isNone([]) -> false */
export function isNone(nullable) {
  return nullable === null || nullable === undefined;
}

/**
 * Type guard for T
 * @param nullable any value that is Nullable
 * @returns boolean
 *
 * isSome(null) -> false
 * isSome(undefined) -> false
 * isSome('') -> true
 * isSome(0) -> true
 * isSome(false) -> true
 * isSome({}) -> true
 * isSome([]) -> true */
export function isSome(nullable) {
  return !isNone(nullable);
}
export const keys = Object.keys;
export const values = Object.values;
export const entries = Object.entries;
export const isNumeric = value => isSome(value) && !isNaN(value) && typeof value === 'number';
export function isError(error) {
  return error instanceof Error;
}
export function isString(x) {
  return typeof x === 'string';
}
export function isObject(x) {
  return isSome(x) && typeof x === 'object';
}
export const isNumericAndEqual = (a, b) => isNumeric(a) && isNumeric(b) && a === b;
export const isPositive = num => isNumeric(num) && num > 0;
export function parseNumber(value) {
  if (isNone(value) || value.trim() === '') {
    return null;
  }
  const parsed = Number(value);
  if (isNumeric(parsed)) {
    return parsed;
  }
  return null;
}
export function parseNumberOrThrow(value) {
  const parsed = parseNumber(value);
  if (isNone(parsed)) {
    throw new Error(`Could not parse number from ${value}`);
  }
  return parsed;
}
export function getValue(property) {
  var _property$value;
  return (_property$value = property === null || property === void 0 ? void 0 : property.value) !== null && _property$value !== void 0 ? _property$value : null;
}