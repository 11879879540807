import pipe from 'transmute/pipe';
const clientIdRegex = /^(?:AGENT|VISITOR)-(.*)$/;
const parseData = message => Object.assign({}, message, {
  data: JSON.parse(message.data)
});
const formatObject = message => ({
  data: message.data,
  timestamp: message.timestamp,
  clientId: message.clientId
});
const preferVendorTimestamp = message => {
  const {
    data,
    timestamp
  } = message;
  const hasDataTimestamp = data && typeof data.timestamp === 'number';
  const isFromApi = data && data.clientType === 'API';
  if (timestamp && hasDataTimestamp && !isFromApi) {
    data.timestamp = timestamp;
  }
  return Object.assign({}, message, {
    data
  });
};
const verifyClientId = message => {
  if (!message.clientId) {
    throw new Error('malformed message');
  }
  const {
    clientId
  } = message;
  const clientIdMatch = clientIdRegex.exec(clientId);
  if (clientIdMatch) {
    const clientIdNumber = clientIdMatch[1];
    const parsedClientIdNumber = parseInt(clientIdNumber, 10);
    if (isNaN(parsedClientIdNumber)) {
      throw new Error('malformed message');
    }
  }
  return message;
};

/**
 * @description prepare a message for a conversations app by parsing it,
 * correcting its timestamp, and formating it for backward compatability
 *
 * @param {Object} options
 * @param {Object} options.message a pubsub message
 * @returns {Object} prepared message
 *
 */
export const prepareMessage = ({
  message
}) => {
  return pipe(parseData, verifyClientId, preferVendorTimestamp, formatObject)(message);
};