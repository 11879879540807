import { sourceIconsMap } from 'conversations-message-history/constants/sourceIcons';
/**
 * @description Given a genericChannelId resolve an icon to render for it. This
 * function also takes source to handle genericChannelId being null - when that
 * property can no longer be null we can remove source from this function.
 *
 * Icons here: https://tools.hubteamqa.com/ui-library/styles/icons
 */

const FALLBACK_ICON = 'inbox';
export const getIconForChannel = genericChannelId => {
  return genericChannelId && genericChannelId in sourceIconsMap ? sourceIconsMap[genericChannelId] : FALLBACK_ICON;
};