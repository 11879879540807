'use es6';

import { secureDocument } from './secureDocument';
export const REPLY_CLASSLIST = [
// Gmail
'gmail_extra', 'gmail_quote',
// HubSpot
'hs_reply', 'x_hs_reply_wrap'];
const OFFICE_365_REPLY = 'divRplyFwdMsg';
const isReply = (classList, id) => id === OFFICE_365_REPLY || classList && classList.contains && REPLY_CLASSLIST.some(className => classList.contains(className));
export const removeReply = ({
  node
}) => {
  const {
    classList,
    id,
    attributes = []
  } = node;
  if (isReply(classList, id)) {
    const span = secureDocument.createElement('span');
    Object.keys(attributes).forEach(key => {
      const attribute = attributes[key];
      span.setAttribute(attribute.nodeName, attribute.nodeValue);
    });
    span.innerHTML = '';
    span.setAttribute('data-email-reply', '');
    return {
      node: span
    };
  }
  return null;
};