import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { blockThread } from '../actions/blockThread';
import { unblockThread } from '../actions/unblockThread';
import { markAsSpam } from '../actions/markAsSpam';
import { unmarkAsSpam } from '../actions/unmarkAsSpam';
import { SPAM, BLOCK } from 'conversations-message-history/filtered-change-message/constants/filterTypes';
export const useThreadFilteringActions = ({
  threadId
}) => {
  const dispatch = useDispatch();
  const handleBlockThread = useCallback(({
    contactDeletion = false
  }) => {
    dispatch(blockThread({
      contactDeletion,
      filtered: true,
      filteringType: BLOCK,
      threadId
    }));
  }, [dispatch, threadId]);
  const handleUnblockThread = useCallback(() => {
    dispatch(unblockThread({
      threadId,
      filtered: false,
      filteringType: BLOCK,
      contactDeletion: false
    }));
  }, [dispatch, threadId]);
  const handleMarkAsSpam = useCallback(() => {
    dispatch(markAsSpam({
      threadId,
      filtered: true,
      filteringType: SPAM,
      contactDeletion: false
    }));
  }, [dispatch, threadId]);
  const handleUnmarkAsSpam = useCallback(() => {
    dispatch(unmarkAsSpam({
      threadId,
      filtered: false,
      filteringType: SPAM,
      contactDeletion: false
    }));
  }, [dispatch, threadId]);
  return {
    blockThread: handleBlockThread,
    unblockThread: handleUnblockThread,
    markAsSpam: handleMarkAsSpam,
    unmarkAsSpam: handleUnmarkAsSpam
  };
};