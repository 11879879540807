import { fetchMeetings } from './fetchMeetings';
export const meetingsApiWrapper = apiClient => ({
  fetchRenderedContent({
    id
  }) {
    return fetchMeetings({
      httpClient: apiClient
    }).then(results => {
      const meeting = results.find(el => el.value === id);
      if (meeting) {
        return {
          htmlBody: `<a href="${meeting.url}" target="_blank">${meeting.text || meeting.url}</a>`
        };
      }
      return {
        htmlBody: ''
      };
    });
  },
  searchArticles({
    query,
    count
  }) {
    return fetchMeetings({
      httpClient: apiClient
    }).then(results => {
      const queryResults = query ? results.filter(res => res.text && res.text.includes(query)) : results;
      return count ? queryResults.slice(0, count) : queryResults;
    });
  }
});