import { Record, Map as ImmutableMap } from 'immutable';
import { generateViewId } from '../common/viewIdOperators';
export class ThreadList extends Record({
  channel: '',
  id: '',
  name: '',
  sortDirection: 'DESC',
  threadCount: null,
  unreadThreadCount: null,
  '@type': null,
  internalName: null,
  orderType: 'LATEST_VISITOR_RESPONSE',
  threadListId: -1,
  threadListType: null
}, 'ThreadList') {
  constructor(props) {
    const propsMap = ImmutableMap(props || {});
    const viewId = generateViewId(props);
    super(propsMap.merge({
      id: viewId
    }));
  }
}