import { Seq } from 'immutable';
import { getPageId } from '../operators/getters';
import MessengerConnection from '../records/MessengerConnection';
export const createMessengeConnectionMap = raw => {
  const connections = Seq(raw);
  const connectionTuples = connections.map(connection => [
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
  getPageId(connection),
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
  MessengerConnection(connection)]);
  return Seq.Keyed(connectionTuples).toMap();
};