import { Set as ImmutableSet } from 'immutable';
import { useReduxIndexedAsyncFetcher } from 'conversations-async-data/indexed-async-data/hooks/useReduxIndexedAsyncFetcher';
import { deferredIndexedAsyncDataSelector } from '../../utils/deferredIndexedAsyncDataSelector';
import { noOpAction } from '../../utils/noOpAction';
import { fetchVisitors } from '../actions/fetchVisitors';
import { getIndexedAsyncVisitors } from '../selectors/getIndexedAsyncVisitors';
const idsTransform = ids => ({
  vids: ids
});

/** @deprecated use useVisitorsBatch instead */
export const useVisitors = ({
  vids = ImmutableSet(),
  deferred = false
}) => {
  const {
    entries
  } = useReduxIndexedAsyncFetcher({
    // @ts-expect-error useReduxIndexedAsyncFetcher is typed in such a way that it _requires_ the action to be curried
    action: deferred ? noOpAction : fetchVisitors,
    selector: deferred ? deferredIndexedAsyncDataSelector : getIndexedAsyncVisitors,
    ids: vids,
    idsTransform
  });
  return {
    asyncVisitors: entries
  };
};