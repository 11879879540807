'use es6';

import { combineReducers } from 'redux';
import { capabilitiesData } from 'conversations-thread-data/channel-capabilities/public/reducers';
import { connectedAccounts } from 'conversations-thread-data/connected-accounts/public';
import contextualPrompts from './contextual-prompts/reducers/contextualPrompts';
import currentGenericChannelId from './tabbed-editors/reducers/currentGenericChannelId';
import { fileAttachments } from 'composer-data/attachments/public/reducers';
import { floatingForwardCommunicator } from 'composer-data/email/public/reducers';
import forms from 'conversations-thread-data/forms/reducers/forms';
import senders from 'conversations-thread-data/senders/reducers/senders';
import visitors from 'conversations-thread-data/visitor/reducers/visitors';
import inlineImageStatus from './email-inline-images/reducers/inlineImageStatus';
const threadViewRootReducer = combineReducers({
  capabilitiesData,
  connectedAccounts,
  contextualPrompts,
  currentGenericChannelId,
  fileAttachments,
  floatingForwardCommunicator,
  forms,
  inlineImageStatus,
  senders,
  visitors
});
export default threadViewRootReducer;