import { CLEAR_SEARCH_TERMS, ENTER_SEARCH_TERMS } from '../actions/ActionTypes';
export default function searchTerms(state = '', action) {
  switch (action.type) {
    case CLEAR_SEARCH_TERMS:
      return '';
    case ENTER_SEARCH_TERMS:
      return action.payload;
    default:
      return state;
  }
}