/**
 * @description strip vendor fields out of presence messages and
 * index them by clientId
 * @param {Object} options
 * @param {Object} options.message a presence message with at least
 * a clientId and data field
 * @returns {Object} prepared message
 *
 */
export const preparePresenceMessages = ({
  messages
}) => {
  return messages.reduce((accumulator, message) => {
    const {
      clientId,
      data,
      timestamp
    } = message;
    return Object.assign({}, accumulator, {
      [clientId]: {
        clientId,
        data,
        timestamp
      }
    });
  }, {});
};