// SUSPENSION APPEAL STATUS
export const PENDING = 'PENDING';
export const ACCEPTED = 'ACCEPTED';
export const REJECTED = 'REJECTED';
export const UNINITIATED = 'UNINITIATED';

// SUSPENSION REASON TYPES
export const BLACKLISTING = 'BLACKLISTING';
export const BOUNCES = 'BOUNCES';
export const CANCELLATIONS = 'CANCELLATIONS';
export const DEFAULT = 'DEFAULT';
export const EHAWK = 'EHAWK';
export const HEALTH_SCORE = 'HEALTH_SCORE';
export const NONPAYMENT = 'NONPAYMENT';
export const OVERRIDE = 'OVERRIDE';
export const OVERRIDE_PHISHING = 'OVERRIDE_PHISHING';
export const OVERRIDE_SPAM = 'OVERRIDE_SPAM';
export const OVERRIDE_UNSUBSCRIBE_REASONS = 'OVERRIDE_UNSUBSCRIBE_REASONS';
export const OVERRIDE_UNSUBSCRIBES = 'OVERRIDE_UNSUBSCRIBES';
export const PHISHING = 'PHISHING';
export const SPAM = 'SPAM';
export const SPAMTRAP = 'SPAMTRAP';
export const UNSUBSCRIBES = 'UNSUBSCRIBES';