/**
 * Should be used for universal API loading states on stores when using flux
 */
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import memoize from 'transmute/memoize';
import { LOADING, SUCCEEDED, FAILED, UPDATING, UNINITIALIZED } from '../constants/apiLoadingStates';
export const isUninitialized = memoize(data => !data || get('state', data) === UNINITIALIZED);
export const isLoading = memoize(data => !data || get('state', data) === LOADING);
export const isSucceeded = memoize(data => !!data && get('state', data) === SUCCEEDED);
export const isFailed = memoize(data => !!data && get('state', data) === FAILED);
export const isUpdating = memoize(data => !!data && get('state', data) === UPDATING);
export const getData = memoize(data => !!data && get('data', data));
export const getInData = memoize((keys, data) => data && getIn(['data', ...keys], data));