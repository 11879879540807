import once from 'transmute/once';
import events from 'conversations-inbox-ui/events.yaml'; // eslint-disable-line
import { buildTrackerCallbacks, createTrackEvent, createAsyncTracker } from 'svh-workspace-ui-lib/usage-tracking/public/clients';
const platform = 'desktop-app';
const {
  onError
} = buildTrackerCallbacks();
const defaultProperties = {
  channel: undefined,
  namespace: 'conversations',
  platform,
  screen: 'Inbox',
  subscreen: 'unknown'
};
export const getUsageTracker = once(() => createAsyncTracker({
  events,
  properties: defaultProperties,
  onError
}));
export const getBeaconUsageTracker = once(() => createAsyncTracker({
  events,
  properties: defaultProperties,
  onError
}));
export const trackEvent = createTrackEvent(getUsageTracker);
const beforeUnloadTracker = createTrackEvent(getBeaconUsageTracker);
export const trackEventBeforeUnload = event => {
  beforeUnloadTracker(event, 'trackEventBeforeUnload');
};