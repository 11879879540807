import { combineReducers } from 'redux';
import filterOptions from './filterOptions';
import filters from './filters';
import searchTerms from './searchTerms';
import tickets from './tickets';
export default combineReducers({
  filterOptions,
  filters,
  searchTerms,
  tickets
});