import I18n from 'I18n';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { UNMARK_AS_SPAM } from '../constants/asyncActionTypes';
import { setFilterStatus } from '../clients/setFilterStatus';
export const unmarkAsSpam = createAsyncAction({
  requestFn: setFilterStatus,
  actionTypes: UNMARK_AS_SPAM,
  toRecordFn: () => null,
  successMetaActionCreator: () => ({
    notification: {
      type: 'success',
      titleText: I18n.text('conversations-thread-view.alerts.titles.success'),
      message: I18n.text('conversations-thread-view.alerts.unmark-thread-spam.message')
    }
  })
});