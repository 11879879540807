import { getFilterBranch } from '../custom-views/customViewOperators';
import { buildThreadListFilterBranch } from '../thread-lists/buildThreadListFilterBranch';
/**
 * @description This function will return a FilterBranch for the given view. If
 * the view is a ThreadList this will use the given threadStatusFilter parameter
 * to generate a FilterBranch that matches the given thread status i.e. `STARTED`
 * will produce a FilterBranch that accepts all open threads.
 *
 * threadStatusFilter is optional, if it's not provided then getViewFilterBranch
 * will return null when given a ThreadList.
 */
export function getViewFilterBranch(view, threadStatusFilter) {
  if ('threadListId' in view && threadStatusFilter) {
    return buildThreadListFilterBranch(view, threadStatusFilter);
  } else {
    return getFilterBranch(view);
  }
}