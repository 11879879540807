import { registerQuery } from 'data-fetching-client';
import { fetchChatflowByChannelInstanceId, fetchChatflowsForInbox, fetchLiveChatflows } from '../clients/chatflows.api';
export const fetchChatflowsQuery = registerQuery({
  args: ['limit', 'offset', 'inboxId'],
  fetcher: fetchLiveChatflows,
  fieldName: 'liveChatflows'
});
export const fetchChatflowsForInboxQuery = registerQuery({
  args: ['inboxId'],
  fetcher: fetchChatflowsForInbox,
  fieldName: 'liveChatflows'
});
export const fetchChatflowByChannelInstanceIdQuery = registerQuery({
  args: ['channelInstanceId'],
  fetcher: fetchChatflowByChannelInstanceId,
  fieldName: 'chatflow'
});