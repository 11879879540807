import { AGENT, BOT, SYSTEM, VISITOR, INTEGRATOR } from '../../common-message-format/constants/legacySenderTypes';
import RecipientLocator from '../records/RecipientLocator';
const recipientTypes = [AGENT, BOT, SYSTEM, VISITOR, INTEGRATOR];
export const buildRecipientLocator = (recipientType, recipientId) => {
  if (!recipientType.includes(recipientType)) {
    // eslint-disable-next-line
    console.warn(`buildRecipientLocator received invalid recipient type ${recipientType} expected one of: ${recipientTypes}`);
  }
  return RecipientLocator({
    recipientType,
    recipientId: recipientId ? `${recipientId}` : null
  });
};