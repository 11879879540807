import { getCurrentInboxId } from 'conversations-inbox-lib/unified-inbox/public/selectors';
import { hasConversationsInteract } from '../../auth/selectors/hasConversationsInteract';
import { pollViews } from 'find-and-filter-data/views-data/public';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { checkNetwork } from 'conversations-http/network/checkNetwork';
const INTERVAL = 1000 * 60 * 3; // 3 Minutes

export const pollThreadLists = () => (dispatch, getState) => {
  const inboxId = getCurrentInboxId(getState());
  const hasInteract = hasConversationsInteract(getState());
  if (inboxId !== null && inboxId !== undefined &&
  // Only poll thread list if user has inbox access. Prevents 403 from expired JITA
  hasInteract) {
    dispatch(pollViews({
      inboxId
    })).finally(() => setTimeout(() => dispatch(pollThreadLists()), INTERVAL)).catch(error => {
      return checkNetwork().then(({
        online
      }) => {
        if (online) reportError({
          error,
          tags: {
            isVisibleToUser: false
          }
        });
      }).catch(() => {});
    });
  }
};