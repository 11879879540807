import { combineReducers } from 'redux';
import { bulkOperationsSlice } from '../../bulk-operations/protected';
import { latestOmnibusTimestampSlice } from '../../realtime-updates/protected';
import { filterResultsSlice, filterValuesSlice, sortOrderSlice } from '../../search-and-filter-data/protected';
import { viewCountsSlice, viewUnreadStatusSlice } from '../../view-counts-and-unread/protected';
import { viewMembersUpdatesSlice } from '../../view-member-optimistic-updates/protected';
import { helpDeskViewMembersSlice, viewMembersSlice } from '../../view-members-data/protected';
import { fallbackModeReducer, spamThreadListSlice, viewsSlice } from '../../views-data/protected';
import { visitorsSlice } from '../../visitors/protected';

/**
 * @description This describes the shape of the Redux state - assumes that
 * the findAndFilterData reducer is in the root of the store.
 */

/**
 * The findAndFilterData rootReducer - this should sit in the root level of your
 * Redux store until we can create our own Redux store. Please do not modify the name.
 */
export const findAndFilterData = combineReducers({
  bulkOperations: bulkOperationsSlice.reducer,
  fallbackModeEnabled: fallbackModeReducer,
  helpDeskViewMembers: helpDeskViewMembersSlice.reducer,
  latestOmnibusTimestamp: latestOmnibusTimestampSlice.reducer,
  searchAndFilter: combineReducers({
    filterResults: filterResultsSlice.reducer,
    filterValues: filterValuesSlice.reducer,
    sortOrder: sortOrderSlice.reducer
  }),
  spamThreadList: spamThreadListSlice.reducer,
  viewCounts: viewCountsSlice.reducer,
  viewMembers: viewMembersSlice.reducer,
  viewMembersUpdates: viewMembersUpdatesSlice.reducer,
  viewUnreadStatus: viewUnreadStatusSlice.reducer,
  views: viewsSlice.reducer,
  visitors: visitorsSlice.reducer
});