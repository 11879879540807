export const ID = ['id'];
export const TYPE = ['@type'];
export const URL = ['url'];
export const CONNECTED_OBJECT_SOURCE_ID = ['connectedObjectSourceId'];
export const SUBJECT = ['subject'];
export const CAMPAIGN_ID = ['emailCampaignId'];
export const CAMPAIGN_GROUP_ID = ['emailCampaignGroupId'];
export const FROM_NAME = ['from', 'name'];
export const FROM_EMAIL = ['from', 'email'];
export const ORIGINAL_SENDER_NAME = ['originalSender', 'name'];
export const ORIGINAL_SENDER_EMAIL = ['originalSender', 'email'];
export const TIMESTAMP = ['timestamp'];
export const CONTEXTS = ['contexts'];
export const ENTRY_URL = ['contexts', 'ENTRY_URL', 'url'];
export const CHATFLOW_ID = ['contexts', 'CHATFLOW', 'id'];
export const ENTRY_URL_CONTEXT = ['contexts', 'ENTRY_URL'];
export const FORM_CONTEXT = ['contexts', 'CONNECTED_CRM_OBJECT'];
export const CHATFLOW_CONTEXT = ['contexts', 'CHATFLOW'];
export const CONNECTED_ACCOUNT_CONTEXT = ['contexts', 'CONNECTED_ACCOUNT'];
export const MARKETING_EMAIL_CONTEXT = ['contexts', 'MARKETING_EMAIL_REPLY'];
export const FORWARDED_EMAIL_CONTEXT = ['contexts', 'FORWARDED_EMAIL_UPDATE'];
export const CUSTOM_CONTEXT = ['contexts', 'CUSTOM'];
export const CUSTOM_CONTEXT_VALUE = ['contexts', 'CUSTOM', 'value'];
export const HIDDEN_FROM_VISITOR = ['hiddenFromVisitor'];
export const MESSAGE_DELETED_STATUS = ['messageDeletedStatus'];