export const getReplyAndCloseClicked = ({
  ticketAssociated
}) => ({
  key: 'reply-and-close-clicked',
  properties: {
    action: 'reply and close',
    'ticket associated': ticketAssociated
  }
});
export const getNavigateToTicketRecord = isThreadHeader => ({
  key: 'clicked-to-view-ticket-record',
  properties: {
    action: 'Clicked to View Ticket Record',
    source: isThreadHeader ? 'Thread Header' : 'Thread Body'
  }
});