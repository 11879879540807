import { useContext, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TourContext from 'ui-shepherd-react/contexts/TourContext';
import { ONBOARDING_TOUR_ID } from '../constants/OnboardingConstants';
import { useOnboardingSteps } from '../hooks/useOnboardingSteps';
import { getVerifyInstall } from '../../verify-install/operators/getVerifyInstall';
import { getVerifyFbmInstall } from '../../verify-fbm-install/operators/getFbmLocationParams';
export function useInitOnboardingTour() {
  const tourContext = useContext(TourContext);
  const {
    updateSeenSteps
  } = useOnboardingSteps();

  /**
   * Create stable refs to objects that shouldn't cause effects to re-run
   */
  const tourRef = useRef(tourContext.tour);
  useEffect(() => {
    tourRef.current = tourContext.tour;
  }, [tourContext.tour]);
  const updateSeenStepsRef = useRef(updateSeenSteps);
  useEffect(() => {
    updateSeenStepsRef.current = updateSeenSteps;
  }, [updateSeenSteps]);

  /**
   * Activate the tour
   */
  useEffect(() => {
    const tour = tourRef.current;
    if (tour && tour.getConfig() && tour.getConfig().steps.length !== 0) {
      tour.activate(ONBOARDING_TOUR_ID);
      tour.subscribe(nextStep => {
        if (nextStep) updateSeenStepsRef.current(nextStep);
      });
    }
  }, []);

  /**
   * Start the tour
   */
  const location = useLocation();
  // check for these query params. If these are active we should render the
  // onboarding tour after the query param is cleared
  const hasVerifyInstallModalsOpen = Boolean(getVerifyInstall(location) === 'true' || getVerifyFbmInstall(location));
  const deferStartingOnboarding = hasVerifyInstallModalsOpen;
  useEffect(() => {
    const tour = tourRef.current;
    if (tour && tour.getConfig() && tour.getConfig().steps.length !== 0 && !deferStartingOnboarding) {
      tour.start(ONBOARDING_TOUR_ID);
    }
  }, [deferStartingOnboarding]);
}