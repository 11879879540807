'use es6';

import { UTK } from '../constants/senderIdKeys';
import { getSenderKey } from './commonMessageFormatGetters';
export const getSenderIdOrVid = ({
  message,
  vid,
  senderId
}) => {
  const senderKey = getSenderKey(message);
  if (senderKey === UTK) {
    return vid;
  }
  return senderId;
};