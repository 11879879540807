import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchCallDisposition } from '../clients/fetchCallDispositions';
export const FETCH_CALL_DISPOSITIONS = registerQuery({
  fieldName: 'callDispositions',
  fetcher: fetchCallDisposition
});
export const useFetchCallDispositions = () => {
  const {
    loading,
    error,
    data
  } = useQuery(FETCH_CALL_DISPOSITIONS);
  if (loading || error || !data) {
    return {
      dispositionsList: null,
      loading
    };
  }
  const callDispositions = data.callDispositions.callDispositions;
  const dispositionsList = callDispositions.map(disposition => {
    return {
      text: disposition.label,
      value: disposition.uid
    };
  });
  return {
    dispositionsList,
    loading
  };
};