import Track from '../tracking/ZorseTicketInspectorTracker';
import { APPLY_FILTERS, CLEAR_FILTERS } from './ActionTypes';
import { convertDateRangeToPresetId } from '../util/DateRangeConverter';
export function applyFilters(filters) {
  return dispatch => {
    const payload = filters.toJS();
    payload['dateRange'] = filters.dateRange.isEmpty() ? undefined : convertDateRangeToPresetId(filters.dateRange);
    Track.filtersApplied(JSON.stringify(payload));
    dispatch({
      type: APPLY_FILTERS,
      payload: filters
    });
  };
}
export function clearFilters() {
  return dispatch => {
    Track.filtersCleared();
    dispatch({
      type: CLEAR_FILTERS
    });
  };
}