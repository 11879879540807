'use es6';

import http from 'hub-http/clients/apiClient';
const BASE_URL = 'rep-chat-assignment/chats';
class ChatAssignmentsApi {
  getUnassignedChatsCount(inboxId) {
    return http.get(`${BASE_URL}/${inboxId}/count`);
  }
  assignNextApplicableChat(inboxId) {
    return http.post(`${BASE_URL}/${inboxId}/assign-next`);
  }
}
export default ChatAssignmentsApi;