import { getIsUngatedForUserDefaultFonts } from 'conversations-thread-data/auth/public/selectors';
import { useSelector } from 'react-redux';
import { useDefaultFont } from './useDefaultFont';
import { OrderedSet } from 'immutable';
import get from 'transmute/get';
export function useFontSettings({
  deferred
} = {}) {
  const isUngatedForUserDefaultFonts = useSelector(getIsUngatedForUserDefaultFonts);
  const {
    loading,
    defaultFont
  } = useDefaultFont({
    deferred: !isUngatedForUserDefaultFonts || deferred
  });
  const hasFontSettings = isUngatedForUserDefaultFonts && defaultFont && Object.keys(defaultFont).length;
  const fontSettings = hasFontSettings ? {
    defaultStyle: OrderedSet([get('font', defaultFont), get('size', defaultFont), `color|$|${get('color', defaultFont)}`]),
    fontColor: get('color', defaultFont),
    fontSize: `${get('size', defaultFont).replace('FONT-SIZE-', '')}pt`,
    fontStyle: get('font', defaultFont)
  } : undefined;
  return {
    fontSettings,
    loading: isUngatedForUserDefaultFonts && loading
  };
}