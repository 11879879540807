'use es6';

import formatName from 'I18n/utils/formatName';
import { getProperty } from './ImmutableModel';
function getContactName(contact) {
  if (!contact) {
    return null;
  }
  const firstName = getProperty(contact, 'firstname');
  const lastName = getProperty(contact, 'lastname');
  return formatName({
    firstName,
    lastName
  }) || getProperty(contact, 'email');
}
export function formatContactName(contact, defaultName) {
  return getContactName(contact) || defaultName;
}