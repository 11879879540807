'use es6';

import { fetchAgentSenders } from '../actions/fetchAgentSenders';
import { makeGetIndexedAsyncAgentBotSenders } from '../selectors/getIndexedAsyncAgentBotSenders';
import { useReduxIndexedAsyncFetcher } from 'conversations-async-data/indexed-async-data/hooks/useReduxIndexedAsyncFetcher';
import { useMemo } from 'react';
const idsTransform = ids => ({
  senderLocators: ids
});
export const useIndexedAsyncAgentBotSenders = ({
  senderLocators
}) => {
  const selector = useMemo(() => makeGetIndexedAsyncAgentBotSenders(), []);
  const {
    entries
  } = useReduxIndexedAsyncFetcher({
    action: fetchAgentSenders,
    selector,
    ids: senderLocators,
    idsTransform
  });
  return {
    agentBotSenders: entries
  };
};