import http from 'hub-http/clients/apiClient';
export function getDraftBriefByContact(contactVid) {
  return http.get(`qualified-leads/drafts/brief/${contactVid}`);
}
export function getSubmissionBriefsForCompanyByContact(contactVid) {
  return http.get(`qualified-leads/submissions/brief/by-contact-vid/${contactVid}`, {
    query: {
      includeAllSubmissionsForSameCompany: true
    }
  });
}
export function getIsNewDraft(contactVid) {
  return http.get(`qualified-leads/drafts/${contactVid}/validate-revision`, {
    query: {
      revision: 0
    }
  });
}