import http from 'conversations-http/clients/http';
import Raven from 'raven-js';
const CHANNEL_INSTANCES_LIMIT = 100;
export const fetchChannelInstances = ({
  inboxIds,
  offset,
  prevResults = []
}) => http.get(`/conversations-channels/v1/channel-instances/inbox/${inboxIds && inboxIds.first()}/paged`, {
  query: {
    limit: CHANNEL_INSTANCES_LIMIT,
    offset
  }
}).then(({
  results,
  hasMore,
  offset: offsetKey
}) => {
  const currentResults = [...prevResults, ...results];

  /* Recursively fetch channel instances from the paginated API */
  if (hasMore) {
    return fetchChannelInstances({
      inboxIds,
      offset: offsetKey,
      prevResults: currentResults
    });
  }
  return Promise.resolve(currentResults);
}).catch(err => {
  Raven.captureException(err, {
    tags: {
      isVisibleToUser: 'false'
    }
  });
});