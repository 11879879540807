import { DOMParser, DOMSerializer } from 'prosemirror-model';
import { replaceHardBreaksWithParagraphs } from './replaceHardBreaksWithParagraphs';

/**
 * A DOMParser variant that cleans up incoming HTML from the clipboard
 */
export class HSClipboardParser extends DOMParser {
  constructor(...args) {
    super(...args);
    this.parseClipboardText = (text, $context, __plain) => {
      const marks = $context.marks();
      const serializer = DOMSerializer.fromSchema(this.schema);
      const dom = document.createElement('div');
      const lineSplitRegExp = /(?:\r\n?|\n)+/g;
      let result = lineSplitRegExp.exec(text);
      let startOfSliceIndex = 0;

      /*
      Instead of splitting the text on line breaks, we have to inspect each break and decide
      whether to insert another <p> tag. This implementation inserts up to 1 extra <p> tag
      if the break has at least 2 newlines.
      */

      while (result) {
        const {
          0: match,
          index
        } = result;
        const block = text.slice(startOfSliceIndex, index);
        startOfSliceIndex = index + match.length;
        const p = dom.appendChild(document.createElement('p'));
        if (block) {
          p.appendChild(serializer.serializeNode(this.schema.text(block, marks)));
        }
        if (this.countChars(match, '\n') >= 2) {
          dom.appendChild(document.createElement('p'));
        }
        result = lineSplitRegExp.exec(text);
      }
      const lastBlock = text.slice(startOfSliceIndex);
      if (lastBlock) {
        const p = dom.appendChild(document.createElement('p'));
        p.appendChild(serializer.serializeNode(this.schema.text(lastBlock, marks)));
      }
      return this.parseSlice(dom, {
        preserveWhitespace: true,
        context: $context
      });
    };
  }
  parse(dom, options) {
    const cleanDom = this.cleanDomNode(dom);
    return super.parse(cleanDom, options);
  }
  parseSlice(dom, options) {
    const cleanDom = this.cleanDomNode(dom);
    return super.parseSlice(cleanDom, options);
  }

  /**
   * Clean up HTML nodes before they are passed to the schema parser
   */
  cleanDomNode(dom) {
    replaceHardBreaksWithParagraphs(dom);

    /* Add further transform steps here */

    return dom;
  }

  /**
   * Internal helper. Counts the occurrences of a substring inside of a search string.
   */
  countChars(inString, char) {
    let pos = 0;
    let count = 0;
    // eslint-disable-next-line no-cond-assign
    while ((pos = inString.indexOf(char, pos) + 1) > 0) {
      count++;
    }
    return count;
  }

  /**
   * Parse a string of plain text into a ProseMirror Slice
   *
   * Assigned as an arrow function so that it remains bound to "this" and can be passed around
   * as a callback.
   *
   * Text is first converted to DOM nodes, since that is the input to the parseSlice() method.
   * Working with DOM nodes is also a little easier to structure.
   *
   * The behavior of this function is to split the text up by paragraph, convert each paragraph
   * to a P element, wrap all the paragraphs in a DIV element, then pass the DIV to parseSlice().
   *
   * When splitting the text by paragraph, look for repeated paragraph breaks and convert those to
   * an extra empty paragraph between full paragraphs. As of {07-18-2024}, our implementation adds
   * spacing to paragraphs by inserting an empty paragraph between. The text parsing must also look
   * for multiple blank lines and infer that an empty paragraph is needed.
   *
   * ---
   *
   * Commentary:
   *
   * Handling paragraph breaks this way is quirky and complicates formatting code.
   * The typical way to represent a paragraph break is with CSS margin between P elements.
   * Instead, our approach inserts empty P elements to simulate the paragraph break. We have
   * to account for this when reading content back into the state.
   *
   *    - azerilli 07-18-2024
   *
   * @param text String of clipboard text to parse
   * @param $context Information about the insertion point for the Slice we return
   * @param __plain True when pasting as plain text
   * @returns
   */

  static fromSchema(schema) {
    return new HSClipboardParser(schema, DOMParser.fromSchema(schema).rules);
  }
}