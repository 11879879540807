import { getConnectedAccountAddress
// @ts-expect-error module not typed
} from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { getAllRecipientsDeliveryIdentifiers, getFromSenderEmail } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import getEmailAddressFromString from './getEmailAddressFromString';
export const getUniqueRecipientsCount = ({
  message
}) => {
  const allUnformattedRecipients = getAllRecipientsDeliveryIdentifiers(message).toArray();
  const allRecipient = allUnformattedRecipients.map(recipientAddressString => getEmailAddressFromString(recipientAddressString));
  let uniqueRecipients = [...new Set(allRecipient)];
  if (uniqueRecipients.length >= 1) {
    const connectedAccountAddress = getConnectedAccountAddress(message);
    const connectedAccount = connectedAccountAddress ? getEmailAddressFromString(connectedAccountAddress) : null;
    const fromAddress = getFromSenderEmail(message);
    const fromAddressEmail = fromAddress && getEmailAddressFromString(fromAddress);

    // Remove connectedAccount and fromEmail from recipients
    uniqueRecipients = uniqueRecipients.filter(email => email !== connectedAccount && email !== fromAddressEmail);
  }
  return uniqueRecipients.length;
};