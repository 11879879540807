import { once } from '../../utils/once';
import { REALTIME_API_V1 } from '../constants/realtimeApiVersions';
const getGlobalCallback = once(reporter => () => {
  reporter.captureEvent('received-message');
});

/**
 * @description Count received messages
 */
export const countReceivedMessages = ({
  channel,
  reporter
}) => {
  const callback = getGlobalCallback(reporter);
  channel.unsubscribe(REALTIME_API_V1, callback);
  channel.subscribe(REALTIME_API_V1, callback);
};