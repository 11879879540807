import http from 'conversations-http/clients/http';
const API_AGENT_MANUAL = 'API_AGENT_MANUAL';
export const assignResponder = ({
  actorId,
  threadId
}) => http.post(`conversations-assignments/v1/assignments/${threadId}`, {
  data: {
    actorId,
    assignmentMethod: API_AGENT_MANUAL,
    shouldReassign: true,
    shouldStartThreadIfStartable: false
  }
});