/* eslint-disable no-console */
import { buildError } from 'conversations-error-reporting/error-reporting/builders/buildError';

/**
 * Retry calls to an API once.
 *
 * @param {Function} func async function to call.  This function must return a promise
 * @returns {Promise}
 */
export const retryOnce = func => {
  return (...args) => {
    return func(...args).catch(error => {
      if (error) {
        console.warn(`Async call threw exception '${error.message}'. Retrying...`);
        console.warn(error);
      }
      return func(...args);
    }).catch(error => {
      throw buildError(`Async call failed twice. Reason: ${error.message}`, error, {
        originalStack: error.stack
      });
    });
  };
};