import compose from 'transmute/compose';
import sortBy from 'transmute/sortBy';
import { getOrderedByFieldTimestamp } from '../../../view-members-schema/protected';
// Descending sort order needs to have the list of thread list members reversed, so it is multipliied by -1 here
// Ascendng just returns the timestamp as a sort key
const sortBasedOnSortOrder = (timestamp, sortOrder) => {
  if (sortOrder === 'ASC') {
    return timestamp;
  } else {
    return timestamp * -1;
  }
};

/**
 * Sorts ThreadListMembers in order based on the `orderType` timestamp
 * @param {List<ThreadListMember>} viewMembers
 * @param {OrderByTypes} orderType
 */
export const sortThreadListMembers = (viewMembers, orderType, sortOrder) => sortBy(compose(timestamp => sortBasedOnSortOrder(timestamp, sortOrder), getOrderedByFieldTimestamp(orderType)), viewMembers);