import { createSelector } from 'reselect';
import get from 'transmute/get';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { getEntry } from 'conversations-async-data/indexed-async-data/operators/getters';
export const getChatflows = get('chatflows');
// @ts-expect-error reselect typing issue
export const getChatflowData = createSelector(getChatflows, getData);
export const getChatflowIdFromProps = (state, {
  chatflowId
}) => chatflowId;
export const getAsyncChatFlowById = createSelector([getChatflows, getChatflowIdFromProps], (chatflows, chatflowId) => getEntry(chatflowId, chatflows));