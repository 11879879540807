module.exports = {
  "callUsage_startCall": {
    "name": "start call",
    "class": "usage",
    "properties": {
      "action": [
        "start call"
      ],
      "callMedium": {
        "type": "string"
      },
      "provider": {
        "type": "string"
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "location": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberType": {
        "type": "string",
        "isOptional": true
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "callingExtensions_interaction": {
    "name": "calling extensions interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "provider_name": {
        "type": "string",
        "isOptional": true
      },
      "application_id": {
        "type": "number",
        "isOptional": true
      },
      "sdk_version": {
        "type": "string",
        "isOptional": true
      },
      "call_direction": {
        "type": "string",
        "isOptional": true
      },
      "unknownMessageType": {
        "type": "string",
        "isOptional": true
      },
      "location": {
        "type": "string",
        "isOptional": true
      },
      "isExtensionsInboundCallingEnabledInLocalStorage": {
        "type": "boolean",
        "isOptional": true
      },
      "callIdentifier": {
        "type": "string",
        "isOptional": true
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "callingExtensions_initialLoadTime": {
    "name": "calling extensions initial load time",
    "class": "interaction",
    "properties": {
      "action": "string",
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "provider_name": {
        "type": "string",
        "isOptional": true
      },
      "application_id": {
        "type": "number",
        "isOptional": true
      },
      "sdk_version": {
        "type": "string",
        "isOptional": true
      },
      "call_direction": {
        "type": "string",
        "isOptional": true
      },
      "unknownMessageType": {
        "type": "string",
        "isOptional": true
      },
      "location": {
        "type": "string",
        "isOptional": true
      },
      "isExtensionsInboundCallingEnabledInLocalStorage": {
        "type": "boolean",
        "isOptional": true
      },
      "callIdentifier": {
        "type": "string",
        "isOptional": true
      },
      "version": [
        "1",
        "2"
      ],
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  },
  "communicator_timelineInteraction": {
    "name": "timeline interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "message": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "channel": {
        "type": "string",
        "isOptional": true
      },
      "activity": {
        "type": "string",
        "isOptional": true
      },
      "status": {
        "type": "string",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "widgetName": {
        "type": "string",
        "isOptional": true
      },
      "averageMosScore": {
        "type": "string",
        "isOptional": true
      },
      "is_inverse_registration": {
        "type": "boolean",
        "isOptional": true
      },
      "issues": {
        "type": "string",
        "isOptional": true
      },
      "comments": {
        "type": "string",
        "isOptional": true
      },
      "callDuration": {
        "type": "number",
        "isOptional": true
      },
      "callToCountry": {
        "type": "string",
        "isOptional": true
      },
      "startCallSla": {
        "type": "number",
        "isOptional": true
      },
      "provider_name": {
        "type": "string",
        "isOptional": true
      },
      "callType": {
        "type": "string",
        "isOptional": true
      },
      "outcomeType": {
        "type": "string",
        "isOptional": true
      },
      "hasVoicemail": {
        "type": "boolean",
        "isOptional": true
      },
      "portal-age-days": {
        "type": "number",
        "isOptional": true
      },
      "portal-age-months": {
        "type": "number",
        "isOptional": true
      },
      "user-age-days": {
        "type": "number",
        "isOptional": true
      },
      "user-age-months": {
        "type": "number",
        "isOptional": true
      },
      "usage-portal-first-week": {
        "type": "boolean",
        "isOptional": true
      },
      "chrome-extension-installed": {
        "type": "boolean",
        "isOptional": true
      },
      "user_was_invited": {
        "type": "boolean",
        "isOptional": true
      },
      "opted-into-new-record": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "calling"
  }
};