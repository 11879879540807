import I18n from 'I18n';
import { buildErrorMetaObject } from 'conversations-error-reporting/error-actions/builders/buildErrorMetaObject';
import { publishMessage } from 'conversations-thread-data/pubsub/public/actions/publishMessage';
import { createAction } from 'redux-actions';
const showErrorNotification = createAction('SHOW_MESSAGE_PUBLISH_ERROR_NOTIFICATION', () => {}, () => {
  return buildErrorMetaObject({
    titleText: I18n.text('conversations-thread-view.publish-message.closeThreadError.title'),
    message: I18n.text('conversations-thread-view.publish-message.closeThreadError.message')
  });
});
export const publishMessageWithErrorHandling = args => dispatch => {
  const {
    options,
    channel,
    message,
    transient,
    threadId
  } = args;
  const allowRetry = !(options && options.closeOnSuccess);
  const onError = allowRetry ? undefined : () => dispatch(showErrorNotification({
    allowRetry
  }));
  dispatch(publishMessage({
    channel,
    message,
    threadId,
    transient,
    options,
    onError
  }));
};