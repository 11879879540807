'use es6';

import { logCallingError } from 'calling-error-reporting/report/error';
export const LOCAL_SETTINGS_PREFIX = 'LocalSettings:Calling:';
function getPrefixedKey(key, prefix) {
  return `${prefix}${key}`;
}
export function setSetting(key, value, prefix = LOCAL_SETTINGS_PREFIX) {
  if (!window.localStorage) {
    return undefined;
  }
  try {
    if (typeof value === 'string') {
      window.localStorage.setItem(getPrefixedKey(key, prefix), value);
    } else {
      window.localStorage.setItem(getPrefixedKey(key, prefix), JSON.stringify(value));
    }
    return value;
  } catch (error) {
    const localStorageKeyLengths = Object.keys(window.localStorage).map(currKey => {
      const keyLength = (window.localStorage.getItem(currKey) || '').length;
      if (keyLength < 1000) {
        return null;
      }
      return `${currKey}(${keyLength})`;
    });

    // Example: Storage quota exceeded while setting foo(20). Items(length) follows: bar(300),baz(3000)
    const errorMessage = `Storage quota exceeded while setting ${getPrefixedKey(key, prefix)}(${value.length}). Items(length) follows: ${localStorageKeyLengths.filter(entry => entry !== null).join()}`;
    logCallingError({
      errorMessage,
      extraData: {
        error
      }
    });
    return undefined;
  }
}
export function getSetting(key, fallback, parser, prefix = LOCAL_SETTINGS_PREFIX) {
  if (!window.localStorage) {
    return fallback;
  }
  try {
    const entry = window.localStorage.getItem(getPrefixedKey(key, prefix));
    if (!entry) {
      if (fallback) {
        setSetting(key, fallback);
      }
      return fallback;
    } else if (entry.startsWith('{')) {
      const result = JSON.parse(entry);
      return typeof parser === 'function' ? parser(result) : result;
    } else if (entry === 'null' || entry === 'undefined') {
      setSetting(key, fallback);
      return fallback;
    }
    return entry;
  } catch (error) {
    setSetting(key, fallback);
    logCallingError({
      errorMessage: 'Parsing LocalStorage value failed',
      extraData: {
        error
      }
    });
    return fallback;
  }
}