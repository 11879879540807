import { getPropertyValue as getValue, getDatePropertyValue, getPipelineData, getFirstNotEmptyValue } from './transformValueUtils';
export const getOrderHighlightFieldValues = ({
  properties,
  pipelines
}) => {
  const pipelineId = getValue(properties, 'hs_pipeline', '');
  const stageId = getValue(properties, 'hs_pipeline_stage', '');
  const pipelineData = getPipelineData({
    pipelines,
    pipelineId,
    stageId
  });
  return {
    createdDate: getDatePropertyValue(properties, 'hs_external_created_date'),
    email: getFirstNotEmptyValue(properties, ['email', 'hs_billing_address_email']),
    phone: getFirstNotEmptyValue(properties, ['phone', 'hs_billing_address_phone', 'hs_shipping_address_phone']),
    firstName: getValue(properties, 'hs_billing_address_firstname'),
    lastName: getValue(properties, 'hs_billing_address_lastname'),
    pipeline: pipelineData.label,
    stage: pipelineData.stage,
    billedCustomerName: getValue(properties, 'hs_billing_address_name')
  };
};