// TODO: move to composer-data
import { getCommonMessage } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
import { getRecipients } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
// TODO: move to composer-data

import { PREVIOUSLY_BOUNCED } from '../../../email/public/constants';
import { getSingleDeliveryIdentifier } from 'conversations-message-history/common-message-format/operators/recipientGetters';
import { getDeliveryIdentifierValue } from 'conversations-message-history/common-message-format/operators/deliveryIdentifierGetters';
export const hasBouncedRecipient = (reply, deliverabilityStatuses) => {
  const commonMessage = getCommonMessage(reply);
  const genericRecipients = getRecipients(commonMessage);
  if (!genericRecipients) {
    return false;
  }
  return genericRecipients.some(recipient => {
    const deliveryIdentifier = getSingleDeliveryIdentifier(recipient);
    const deliveryIdentifierValue = getDeliveryIdentifierValue(deliveryIdentifier);
    const status = deliverabilityStatuses[deliveryIdentifierValue];
    return Boolean(status && status === PREVIOUSLY_BOUNCED);
  });
};