import { combineActions, handleActions } from 'redux-actions';
import DeprecatedActionTypes from '../../constants/DeprecatedActionTypes';
import { NAVIGATE_TO_THREAD } from 'conversations-inbox-lib/pubsub/public/constants';
import { SET_CURRENT_THREAD_LIST } from 'conversations-inbox-lib/views/public/constants';
import { UPDATE_INSTALL_CODE_PANE_VISIBLE } from '../constants/actionTypes';
const initialState = {
  isVisible: false
};
const reducer = {
  [combineActions(DeprecatedActionTypes.SET_CURRENT_CHANNEL, NAVIGATE_TO_THREAD, SET_CURRENT_THREAD_LIST).toString()]() {
    return initialState;
  },
  [UPDATE_INSTALL_CODE_PANE_VISIBLE](state, action) {
    return {
      isVisible: action.payload.visible
    };
  }
};
export default handleActions(reducer, initialState);