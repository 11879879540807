import { handleActions } from 'redux-actions';
import update from 'transmute/update';
import { List } from 'immutable';
import { FAILED, OUT_OF_SYNC, STARTED, UNINITIALIZED } from 'conversations-async-data/async-data/constants/asyncStatuses';
import { SET_CURRENT_INBOX, fetchInboxActionTypes } from '../constants/unifiedInboxActionTypes';
import { buildUnifiedInboxFromOmnibus } from '../../../omnibus/public/operators';
import { FETCH_FROM_OMNI_BUS, POLL_OMNIBUS } from '../../../omnibus/public/constants';
import { buildUnifiedInboxFromInboxesRequest } from '../operators/buildUnifiedInboxFromInboxesRequest';
import { getLastViewedInboxFromLocalStorage } from '../../../utils/last-viewed-view-and-inbox/getLastViewedInboxFromLocalStorage';
const inboxId = getLastViewedInboxFromLocalStorage();
export const initialValue = {
  inboxes: List(),
  currentInbox: undefined,
  inboxRequestStatus: UNINITIALIZED,
  lastAccessedInboxId: inboxId || null
};
export const unifiedInbox = handleActions({
  [fetchInboxActionTypes.SUCCEEDED](___state, action) {
    return buildUnifiedInboxFromInboxesRequest(action);
  },
  [(fetchInboxActionTypes.STARTED, FETCH_FROM_OMNI_BUS.STARTED)](state) {
    return update('inboxRequestStatus', () => STARTED, state);
  },
  [fetchInboxActionTypes.FAILED](state) {
    return update('inboxRequestStatus', () => FAILED, state);
  },
  [FETCH_FROM_OMNI_BUS.SUCCEEDED](state, action) {
    return buildUnifiedInboxFromOmnibus(action.payload.data);
  },
  [POLL_OMNIBUS.SUCCEEDED](state, action) {
    const newState = buildUnifiedInboxFromOmnibus(action.payload.data);
    const omnibusReturnedData = Boolean(newState.inboxes.count() && newState.currentInbox && newState.lastAccessedInboxId);
    if (!omnibusReturnedData) {
      return state;
    }
    if (state.inboxRequestStatus === OUT_OF_SYNC) {
      return newState;
    }
    return {
      inboxes: state.inboxes.count() ? state.inboxes : newState.inboxes,
      currentInbox: state.currentInbox || newState.currentInbox,
      inboxRequestStatus: newState.inboxRequestStatus,
      lastAccessedInboxId: state.lastAccessedInboxId || newState.lastAccessedInboxId
    };
  },
  [SET_CURRENT_INBOX](state, action) {
    return update('currentInbox', () => action.payload, state);
  }
}, initialValue);