import { useQuery } from 'data-fetching-client';
import { TEAM_USERS_QUERY } from '../queries/fetchTeamUsers';
export const useFetchTeamUsers = ({
  inboxId,
  agentIds,
  skip
}) => useQuery(TEAM_USERS_QUERY, {
  variables: {
    inboxId,
    agentIds
  },
  skip
});