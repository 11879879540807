import { useMutation, registerMutation } from 'data-fetching-client';
import http from 'conversations-http/clients/http';
const MESSAGE_HIDING = 'messageHidingMutation';
const MESSAGE_HIDING_MUTATION = registerMutation({
  fieldName: MESSAGE_HIDING,
  args: ['isHidden', 'messageId', 'userId'],
  fetcher: ({
    isHidden,
    messageId,
    userId
  }) => {
    return http.put(`conversations-threads/v1/message/${messageId}/isHidden`, {
      query: {
        isHidden,
        userId
      }
    });
  }
});
export function useMessageHidingMutation() {
  return useMutation(MESSAGE_HIDING_MUTATION);
}