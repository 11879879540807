import { getAttachments, getCommonMessage, getEditorState, getMetadata, getReplyId, getVersion, getVid } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
import { getRawContentFromEditorState } from '../../../editor-state/protected/operators';
export const serializeCommonReplyDraft = commonReply => {
  const attachments = getAttachments(commonReply);
  const commonMessage = getCommonMessage(commonReply);
  const metadata = getMetadata(commonReply);
  const editorState = getEditorState(commonReply);
  const replyId = getReplyId(commonReply);
  const version = getVersion(commonReply);
  const vid = getVid(commonReply);
  return JSON.stringify({
    attachments: attachments.toJS(),
    commonMessage: commonMessage.toJS(),
    metadata: metadata && metadata.toJS(),
    rawContent: getRawContentFromEditorState(editorState),
    replyId,
    timestamp: Date.now(),
    vid,
    version
  });
};