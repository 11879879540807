import { Record } from 'immutable';
import TicketsPage from '../models/TicketsPage';
import { CLEAR_FILTERS, CLEAR_SEARCH_TERMS, CLEAR_TICKETS, SEARCH_MORE_TICKETS, SEARCH_TICKETS } from '../actions/ActionTypes';
export const TicketsState = Record({
  data: new TicketsPage(),
  hasNoResults: false,
  isLoading: false
}, 'Tickets Reducer State');
function tickets(state = new TicketsState(), action) {
  switch (action.type) {
    case CLEAR_FILTERS:
    case CLEAR_SEARCH_TERMS:
      return state.set('hasNoResults', false);
    case CLEAR_TICKETS:
      return new TicketsState();
    case SEARCH_TICKETS:
      return state.merge({
        data: action.meta === 'response' ? TicketsPage.fromJS(action.payload) : new TicketsPage(),
        hasNoResults: action.meta === 'response' && !action.payload.results.length,
        isLoading: action.meta === 'request'
      });
    case SEARCH_MORE_TICKETS:
      return state.merge({
        data: action.meta === 'response' ? TicketsPage.concat(state.data, TicketsPage.fromJS(action.payload)) : state.data,
        isLoading: action.meta === 'request'
      });
    default:
      return state;
  }
}
export default tickets;