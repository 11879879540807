'use es6';

import { fromJS } from 'immutable';
import get from 'transmute/get';
import { WAITING_FOR_CONCURRENT_DOWNLOAD, ENQUEUED, RETRYING_DOWNLOAD } from 'conversations-message-history/email-message/constants/emailImageStatus';
const hasImageDownloadSucceeded = get('allDownloadedSuccessfully');
const getImageStates = get('states');
const getState = get('state');
export const getShouldDisplayInlineMessageBanner = messageInlineImageStatus => {
  let shouldDisplayBanner;
  let numberOfProcessingImages;
  const pendingImages = fromJS(messageInlineImageStatus);
  if (hasImageDownloadSucceeded(pendingImages)) {
    shouldDisplayBanner = false;
    numberOfProcessingImages = 0;
  } else {
    const filteredIds = getImageStates(pendingImages).filter(image => {
      const currentState = getState(image);
      return currentState === WAITING_FOR_CONCURRENT_DOWNLOAD || currentState === ENQUEUED || currentState === RETRYING_DOWNLOAD;
    });
    if (filteredIds.size > 0) {
      shouldDisplayBanner = true;
      numberOfProcessingImages = filteredIds.size;
    } else {
      shouldDisplayBanner = false;
      numberOfProcessingImages = 0;
    }
  }
  return {
    shouldDisplayBanner,
    numberOfProcessingImages
  };
};