import { useState, useCallback } from 'react';
import { AsyncStatusType } from '../types/AsyncData';
export function useAsyncData(defaultData) {
  const [status, setStatus] = useState(AsyncStatusType.UNINITIALIZED);
  const [data, setData] = useState(defaultData || null);
  const [error, setError] = useState(null);
  const updateAsyncData = useCallback(updates => {
    if ('data' in updates && updates.data !== undefined) setData(updates.data);
    if ('error' in updates && updates.error !== undefined) setError(updates.error);
    if ('status' in updates && updates.status !== undefined) setStatus(updates.status);
  }, []);
  return {
    data,
    error,
    status,
    updateAsyncData
  };
}