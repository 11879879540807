'use es6';

import getIn from 'transmute/getIn';
import { TYPE } from '../../common-message-format/constants/keyPaths';
import { CALL_METADATA } from '../../common-message-format/constants/attachmentTypes';
import { getAttachments } from '../../common-message-format/operators/getAttachments';
export const isCallMetadataAttachment = attachment => getIn(TYPE, attachment) === CALL_METADATA;
export const getCallMetadataAttachment = message => {
  const attachments = getAttachments(message);
  if (!attachments || attachments.size === 0) return null;
  return attachments.find(isCallMetadataAttachment) || null;
};
export const getCallId = getIn(['callId']);
export const getDuration = getIn(['callDurationMs']);
export const getFromNumber = getIn(['fromNumber']);
export const getToNumber = getIn(['toNumber']);
export const getFromNumberExt = getIn(['fromNumberExt']);
export const getToNumberExt = getIn(['toNumberExt']);
export const getCalleeCrmObjectId = getIn(['calleeCrmObjectId']);
export const getCalleeCrmObjectTypeId = getIn(['calleeCrmObjectTypeId']);