import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
export const fetchWhatsAppSubscription = ({
  phoneNumber
}) => {
  return http.get(`/subscriptions/v1/status/WHATSAPP/${encodeURIComponent(phoneNumber)}/by-key/WHATSAPP`, {
    query: {
      portalId: PortalIdParser.get()
    }
  });
};