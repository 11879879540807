import { AI_CHATBOT } from '../constants/RoutingTypes';
import RoutingRuleBase from './RoutingRuleBase';
import set from 'transmute/set';
import { setFallback } from '../utils/buildRoutingRules';
export default class AiChatbotRoutingRules extends RoutingRuleBase({
  '@type': AI_CHATBOT,
  fallback: null,
  aiChatbotId: null
}, 'AiChatbotRoutingRules') {
  constructor(options) {
    const aiChatbotId = options ? options.aiChatbotId : null;
    super(set('aiChatbotId', aiChatbotId, setFallback(options)));
  }
}
export const buildAiChatbotRoutingRules = options => new AiChatbotRoutingRules(options);