import { gql, useMutation } from '@apollo/client';
const SendPaymentBankTransferAuthorizationCopyEmail = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"mutation\",\"name\":{\"kind\":\"Name\",\"value\":\"SendPaymentBankTransferAuthorizationCopyEmail\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"SendPaymentBankTransferAuthorizationCopyEmailInput\"}}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"alias\":{\"kind\":\"Name\",\"value\":\"response\"},\"name\":{\"kind\":\"Name\",\"value\":\"sendPaymentBankTransferAuthorizationCopyEmail\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"httpStatus\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"userErrors\"}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "SendPaymentBankTransferAuthorizationCopyEmail"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "input"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "SendPaymentBankTransferAuthorizationCopyEmailInput"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        alias: {
          kind: "Name",
          value: "response"
        },
        name: {
          kind: "Name",
          value: "sendPaymentBankTransferAuthorizationCopyEmail"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "input"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "input"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "httpStatus"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "userErrors"
            }
          }]
        }
      }]
    }
  }]
});
export function useSendAuthorizationCopyMutation({
  invoiceId,
  onSuccess,
  onError
}) {
  return useMutation(SendPaymentBankTransferAuthorizationCopyEmail, {
    variables: {
      input: {
        invoiceId
      }
    },
    onCompleted({
      response
    }) {
      if (response.userErrors.length) {
        onError();
        return;
      }
      onSuccess();
    },
    onError
  });
}