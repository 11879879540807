import { createReactNodeView } from './ReactNodeView';
export function buildNodeViewsFromConfig(viewConfig, handleCreatePortal) {
  const views = {};
  for (const key in viewConfig) {
    if (key) {
      views[key] = (node, view, getPos, decorations) => {
        const {
          component,
          updateFn
        } = viewConfig[key];
        return createReactNodeView({
          node,
          view,
          getPos,
          decorations,
          component,
          updateFn,
          onCreatePortal: handleCreatePortal
        });
      };
    }
  }
  return views;
}