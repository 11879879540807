/* hs-eslint ignored failing-rules */
/* eslint-disable filenames/match-exported */
/* eslint-disable filenames/no-index */

import * as Ably from 'ably/noencryption';
import { enforceDefaultClientOptions } from './enforceDefaultClientOptions';
const HubSpotAbly = {
  Rest: Ably.Rest,
  msgpack: Ably.msgpack,
  Realtime: enforceDefaultClientOptions(Ably.Realtime),
  Types: Ably.Types
};
export default HubSpotAbly;