import http from 'hub-http/clients/apiClient';
export const getCallMetadata = ({
  callId
}) => {
  return http.get(`calls/v1/call-message/${callId}`, {
    query: {
      clienttimeout: 14000
    }
  }).then(response => {
    return {
      response,
      callId
    };
  }).catch(error => ({
    error,
    callId
  }));
};