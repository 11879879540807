import ChatFilterOptions from 'conversations-internal-schema/constants/ChatFilterOptions';
import { getAllVids, getAssignee as getAssignedAgentId, getEndTime, getStartTime, getStatus as getThreadStatus, getUnassigned, getVid as getFilterContact, getGenericChannel as getFilterGenericChannel, setStatus } from '../../search-and-filter-schema/protected';
import { getAssignee, getLatestMessageTimestamp, getRemoval, getStatus, getThreadId, getVid, getGenericChannel } from '../../view-members-schema/protected';
// PUBSUB V2 HACK: Operators...
const filterRemoved = (member, focusedThreadId) => getThreadId(member) === focusedThreadId || !getRemoval(member);
const filterStatus = (member, filters) => {
  const threadStatus = getThreadStatus(filters);
  if (!threadStatus) return true;
  const memberStatus = getStatus(member);
  return !!memberStatus && threadStatus.includes(memberStatus);
};
const filterAssignee = (member, filters) => {
  const assignee = getAssignee(member);
  const assignedAgentId = getAssignedAgentId(filters);
  if (!assignedAgentId && !getUnassigned(filters)) {
    return true;
  } else if (getUnassigned(filters)) {
    return !assignee;
  } else if (!assignee) {
    return false;
  } else {
    const agentId = assignee && assignee.agentId;
    return !!agentId && assignedAgentId === agentId;
  }
};
const filterDate = (member, filters) => {
  const latestMessageTimestamp = getLatestMessageTimestamp(member);
  const startTime = getStartTime(filters);
  const afterStart = !startTime || !latestMessageTimestamp || latestMessageTimestamp > startTime;
  const endTime = getEndTime(filters);
  const beforeEnd = !endTime || !latestMessageTimestamp || latestMessageTimestamp < endTime;
  return afterStart && beforeEnd;
};
const filterContact = (member, filters) => {
  const contact = getFilterContact(filters);
  if (!contact) return true;
  const memberVid = getVid(member);
  const vids = getAllVids(contact);
  return vids.includes(memberVid);
};
const filterChannel = (member, filters) => {
  const genericChannelIds = getFilterGenericChannel(filters);
  if (!genericChannelIds) return true;
  const memberGenericChannelId = getGenericChannel(member);
  return !!memberGenericChannelId && genericChannelIds.includes(memberGenericChannelId);
};
export const filterViewMembers = (viewMembers, filters, focusedThreadId = null,
// doesn't filter focused member if passed
isInTrashView = false, searchText = '') => {
  const chatFilters = isInTrashView ? setStatus([ChatFilterOptions.SOFT_DELETED], filters) : filters;
  if (searchText) {
    return viewMembers.filter(member => {
      const isDeleted = getStatus(member) === ChatFilterOptions.SOFT_DELETED;
      return isDeleted === isInTrashView;
    });
  }
  return viewMembers.filter(member => {
    // never filter out the selected thread
    if (`${focusedThreadId}` === `${getThreadId(member)}`) {
      return true;
    }
    return filterAssignee(member, chatFilters) && filterRemoved(member, focusedThreadId) && filterStatus(member, chatFilters) && filterDate(member, chatFilters) && filterContact(member, chatFilters) && filterChannel(member, chatFilters);
  });
};