import get from 'transmute/get';
import { createSelector } from 'reselect';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { getEntry } from 'conversations-async-data/indexed-async-data/operators/getters';
import { DEFAULT_CLIENT_KEY } from '../constants/clientKeys';
export const getIndexedAsyncSubscriptions = get('subscriptions');
export const getClientKeyFromProps = (__state, {
  clientKey = DEFAULT_CLIENT_KEY
} = {}) => clientKey;
export const getAsyncSubscriptions = createSelector([getClientKeyFromProps, getIndexedAsyncSubscriptions], getEntry);
export const getSubscriptions = createSelector(
// @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
[getAsyncSubscriptions], getData);