// adapted from: https://git.hubteam.com/HubSpot/conversations-internal-schema/blob/24c061ac8fe4f3aee9b0a5f69a2d760a19563fd5/static/js/routing-rules/constants/RoutingTypes.ts
//    NOTE: the type `RoutingType` was moved to "../types/RoutingRulesTypes"
export const UNASSIGNED = 'UNASSIGNED';
export const USERS_AND_TEAMS = 'USERS_AND_TEAMS';
export const OWNER = 'OWNER';
export const INBOX_USERS = 'INBOX_USERS';
export const BOT = 'BOT';
export const KEEP_ASSIGNEE = 'KEEP_ASSIGNEE';
export const AI_CHATBOT = 'AI_CHATBOT';
export const ROUTING_RULE_TYPES = {
  AI_CHATBOT,
  BOT,
  INBOX_USERS,
  OWNER,
  UNASSIGNED,
  USERS_AND_TEAMS
};