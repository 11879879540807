export let ToWorkerMessageTypes;
(function (ToWorkerMessageTypes) {
  ToWorkerMessageTypes["UPDATE_WIDGET_DETAILS"] = "UPDATE_WIDGET_DETAILS";
  ToWorkerMessageTypes["WEBWORKER_APPDATA_RESPONSE"] = "WEBWORKER_APPDATA_RESPONSE";
  ToWorkerMessageTypes["FROM_WORKER_STANDALONE_HEARTBEAT_RESPONSE"] = "FROM_WORKER_STANDALONE_HEARTBEAT_RESPONSE";
  ToWorkerMessageTypes["FROM_WORKER_ACTIVE_CALL_CONNECTION_PING_RESPONSE"] = "FROM_WORKER_ACTIVE_CALL_CONNECTION_PING_RESPONSE";
})(ToWorkerMessageTypes || (ToWorkerMessageTypes = {}));
export function isUpdateWidgetDetailsMessage(message) {
  return message && message.type === ToWorkerMessageTypes.UPDATE_WIDGET_DETAILS;
}
export function isFromWorkerStandaloneHeartbeatResponse(message) {
  return message && message.type === ToWorkerMessageTypes.FROM_WORKER_STANDALONE_HEARTBEAT_RESPONSE;
}
export function isFromWorkerActiveCallConnectionPingResponse(message) {
  return message && message.type === ToWorkerMessageTypes.FROM_WORKER_ACTIVE_CALL_CONNECTION_PING_RESPONSE;
}