import { getThreadRemovalChanges, getThreadRemovalType } from 'find-and-filter-data/realtime-view-member/public';
import InboxChangeRemovalOverlay from '../records/InboxChangeRemovalOverlay';
import UnknownRemovalOverlay from '../records/UnknownRemovalOverlay';
const INBOX_CHANGE = 'INBOX_CHANGE';
export const buildOverlayFromMultipleRemovalType = removal => {
  const changes = getThreadRemovalChanges(removal);
  const inboxRemoval = changes.find(change => getThreadRemovalType(change) === INBOX_CHANGE);

  // some heavy lifting to typescript these overlay classes, so just casting as any below here for now
  if (inboxRemoval) {
    return InboxChangeRemovalOverlay(inboxRemoval);
  }
  return UnknownRemovalOverlay(removal);
};