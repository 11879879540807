import { useCallback } from 'react';
import { useAsyncFetcher } from 'conversations-async-data/async-data/hooks/useAsyncFetcher';
import { fetchProductInfo } from '../actions/fetchProductInfo';
import { useProductInfoReducer } from './useProductInfoReducer';
export const useProductInfo = () => {
  const [state, dispatch] = useProductInfoReducer();
  const fetcher = useCallback(() => dispatch(fetchProductInfo()), [dispatch]);
  useAsyncFetcher({
    asyncData: state,
    fetcher
  });
  return [state, dispatch];
};