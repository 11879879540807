import { createSelector } from 'reselect';
import { getEntry } from 'conversations-async-data/indexed-async-data/operators/getters';
import { getIndexedAsyncConnectedAccounts } from './getIndexedAsyncConnectedAccounts';
import invariant from '../../../utils/invariant';
function inboxIdInvariant(inboxId) {
  invariant(!!inboxId, `Props with 'inboxId' must be a defined, got ${inboxId}`);
}
const getInboxIdFromProps = (__state, {
  inboxId
} = {}) => {
  inboxIdInvariant(inboxId);
  return inboxId;
};
export const getAsyncConnectedAccounts = createSelector([getInboxIdFromProps, getIndexedAsyncConnectedAccounts], getEntry);