import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { updateExistingEntry } from 'conversations-async-data/indexed-async-data/operators/updateExistingEntry';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { requestOutOfSync } from 'conversations-async-data/async-data/operators/requestOutOfSync';
import { getThreadId } from 'conversations-thread-data/thread-details/public/operators';
import { THREAD_ID_CHANGED } from 'conversations-thread-data/thread-details/public/constants';
import { assignmentStatuses, moveStatuses, spamFilteringAndBlocking } from 'conversations-thread-data/thread-actions/public/reducers';
import { threadListMemberDetails as baseThreadListMemberDetails } from '../../thread-details-feature/public/reducers';
import { currentThreadId } from 'conversations-thread-data/thread-details/public/reducers';
import { REFRESH_STORE_DATA, INITIALIZE_STORE_DATA } from 'conversations-thread-data/store/public/constants';
const threadListMemberDetails = (state, action) => {
  const baseState = baseThreadListMemberDetails(state, action);
  switch (action.type) {
    case INITIALIZE_STORE_DATA:
      {
        const {
          threadDetails
        } = action.payload.omnibusData;
        const threadId = getThreadId(threadDetails);
        return !threadId || !threadDetails ? baseState : updateEntry(threadId, requestSucceededWithOperator(() => threadDetails), /*  @ts-expect-error Jun-5-2024, 19:34UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
        baseState);
      }
    case REFRESH_STORE_DATA:
      {
        const {
          threadDetails
        } = action.payload;
        const threadId = getThreadId(threadDetails);
        return threadId ? updateEntry(threadId, requestSucceededWithOperator(() => threadDetails), /*  @ts-expect-error Jun-5-2024, 19:34UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
        baseState) : baseState;
      }
    case THREAD_ID_CHANGED:
      {
        const {
          threadId
        } = action.payload;
        return updateExistingEntry(threadId, requestOutOfSync, baseState);
      }
    default:
      {
        return baseState;
      }
  }
};
export { assignmentStatuses, currentThreadId, moveStatuses, spamFilteringAndBlocking, threadListMemberDetails };