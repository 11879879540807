'use es6';

import { fromJS } from 'immutable';
import { deleteMessageInThread as deleteMessageInThreadClient } from '../clients/deleteMessageInThread';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { DELETE_THREAD_MESSAGE } from 'conversations-thread-data/thread-deletion/public/constants/asyncActionTypes';
export const deleteMessageInThread = createAsyncAction({
  requestFn: deleteMessageInThreadClient,
  actionTypes: DELETE_THREAD_MESSAGE,
  toRecordFn: fromJS
});