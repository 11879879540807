import { useMutation, gql } from '@apollo/client';
import { SUBSCRIPTION_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { addAlert } from '../utils/addAlert';
export const UPDATE_SUBSCRIPTION_NAME = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"mutation\",\"name\":{\"kind\":\"Name\",\"value\":\"UpdateSubscriptionName\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"updateInput\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"UpdateCrmObjectPropertiesInput\"}}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"alias\":{\"kind\":\"Name\",\"value\":\"response\"},\"name\":{\"kind\":\"Name\",\"value\":\"updateCrmObjectProperties\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"updateInput\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"updatedObject\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"InlineFragment\",\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"PaymentsSubscription\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"defaultProperties\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"hs_name\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"value\"}}]}}]}}]}}]}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "UpdateSubscriptionName"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "updateInput"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "UpdateCrmObjectPropertiesInput"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        alias: {
          kind: "Name",
          value: "response"
        },
        name: {
          kind: "Name",
          value: "updateCrmObjectProperties"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "input"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "updateInput"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "updatedObject"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "InlineFragment",
                typeCondition: {
                  kind: "NamedType",
                  name: {
                    kind: "Name",
                    value: "PaymentsSubscription"
                  }
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "defaultProperties"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "hs_name"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "Field",
                            name: {
                              kind: "Name",
                              value: "id"
                            }
                          }, {
                            kind: "Field",
                            name: {
                              kind: "Name",
                              value: "value"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
});
export function useUpdateSubscriptionName(objectId, updatedName) {
  return useMutation(UPDATE_SUBSCRIPTION_NAME, {
    variables: {
      updateInput: {
        objectType: SUBSCRIPTION_TYPE_ID,
        objectId: parseInt(objectId, 10),
        properties: [{
          name: 'hs_name',
          value: updatedName
        }]
      }
    },
    fetchPolicy: 'network-only',
    onError() {
      addAlert({
        titleKey: 'subscriptions.alertMessages.mutationFailedTitle',
        messageKey: 'subscriptions.alertMessages.mutationFailedMessage',
        type: 'danger'
      });
    },
    onCompleted() {
      addAlert({
        titleKey: 'subscriptions.alertMessages.mutationSucceeded',
        type: 'success'
      });
    }
  });
}