export const BOTS_WRITE = 'bots-write';
export const CONVERSATIONS_ACCESS = 'conversations-access';
export const CONVERSATIONS_ADMIN = 'conversations-admin';
export const CONVERSATIONS_MESSAGE_HIDING = 'conversations-message-hiding';

/**
 * Users can be added to a routing pool (round-robin) only
 * if they have `conversations-routing`
 */
export const CONVERSATIONS_ROUTING = 'conversations-routing';
export const CONVERSATIONS_NO_BRANDING = 'conversations-no-branding';
export const SERVICE_ACCESS = 'service-access';
export const FEEDBACK_SCOPE = 'service-feedback-access';
export const TICKETS_SCOPE = 'tickets-access';
export const CONTACT_LISTS_ACCESS = 'contacts-lists-access';
export const CONVERSATIONS_MULTI_INBOX = 'conversations-multi-inbox';
export const CONVERSATIONS_INTERACT = 'conversations-interact';
export const EMAIL_WRITE = 'email-write';
export const FORMS_WRITE = 'forms-write';