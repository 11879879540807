'use es6';

import { secureDocument } from './secureDocument';
export const SIGNATURE_CLASSLIST = [
// HubSpot
'hs_signature'];
const isSignature = classList => classList && classList.contains && SIGNATURE_CLASSLIST.some(className => classList.contains(className));
export const removeSignature = ({
  node
}) => {
  const {
    classList,
    attributes = []
  } = node;
  if (isSignature(classList)) {
    const span = secureDocument.createElement('span');
    Object.keys(attributes).forEach(key => {
      const attribute = attributes[key];
      span.setAttribute(attribute.nodeName, attribute.nodeValue);
    });
    span.innerHTML = '';
    span.setAttribute('data-email-signature', '');
    return {
      node: span
    };
  }
  return null;
};