export default class CallingCrossTabClientError extends Error {
  constructor({
    message,
    // CriticalFailures are basically saying nothing will work if this happens.
    // We can sort on this in NR and Sentry
    criticalFailure,
    details
  }) {
    super(message);
    this.details = {};
    this.name = 'CallingCrossTabClientError';
    if ('captureStackTrace' in Error) {
      Error.captureStackTrace(this, this.constructor);
    }
    this.details = details || {};
    this.criticalFailure = Boolean(criticalFailure);
  }
}