import Raven from 'raven-js';
import CallingExtensionsError from './CallingExtensionsError';
const CALL_PROVIDER = 'callProvider';
const CREATE_ENGAGEMENT_ID = 'createEngagementId';
const UPDATE_ENGAGEMENT_ID = 'updateEngagementId';
const ENTRY = 'entry';
const FALLBACK = 'fallback';
const SDK_VERSION = 'sdkVersion';
const DEFAULTS = {
  extra: {},
  tags: {},
  fingerprint: [],
  level: 'error'
};
export const logError = (message, attributes = {}) => {
  const options = Object.assign({}, DEFAULTS, attributes);
  const error = new CallingExtensionsError({
    message,
    actualError: attributes.error || undefined,
    callProvider: attributes.extra && attributes.extra[CALL_PROVIDER] || undefined,
    sdkVersion: attributes.extra && attributes.extra[SDK_VERSION] || undefined
  });
  Raven.captureException(error, options);

  // eslint-disable-next-line no-console
  console.debug(`Error reported with`, message, Object.assign({}, options.extra));
};