import isBoolean from 'hs-lodash/isBoolean';
// TODO: move to composer-data
import invariant from 'conversations-thread-data/utils/invariant';
export const connectingPubSub = ({
  canSubmit
}) => {
  invariant(isBoolean(canSubmit), 'canSubmit is required for connectingPubSub disabling strategy');
  return !canSubmit ? {
    disabled: true,
    sendDisabledReason: 'CONNECTING_TO_HUBSPOT'
  } : null;
};