import { useMemo } from 'react';
import PortalIdParser from 'PortalIdParser';
import { useObjectTypeSupportsCustomization } from './internal/useObjectTypeSupportsCustomization';
import { isIdle, isLoading, isRejected } from '../common/types/FetchStatus';
const objectTypeIdLabelMap = {
  '0-1': 'contacts',
  '0-2': 'companies',
  '0-3': 'deals',
  '0-5': 'tickets'
};
export const useGetPreviewCustomizationSettingsUrl = objectTypeId => {
  const objectTypeSupportsCustomizationState = useObjectTypeSupportsCustomization(objectTypeId, 'CRM_OBJECT_PREVIEW');

  // This param is used when building links to the settings page to provide a backlink to records
  const eschref = `${window.location.pathname}${window.location.search}`;
  const previewCustomizationSettingsLink = useMemo(() => {
    const baseSettingsUrl = `/sales-products-settings/${PortalIdParser.get()}/${objectTypeIdLabelMap[objectTypeId] || `object/${objectTypeId}`}/preview-customization`;
    return `${baseSettingsUrl}?eschref=${encodeURIComponent(eschref)}`;
  }, [eschref, objectTypeId]);
  if (isIdle(objectTypeSupportsCustomizationState) || isLoading(objectTypeSupportsCustomizationState) || isRejected(objectTypeSupportsCustomizationState) || !objectTypeSupportsCustomizationState.data) {
    return null;
  }
  return previewCustomizationSettingsLink;
};