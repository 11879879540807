import memoize from 'transmute/memoize';

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { getMessages } from 'conversations-message-history/thread-history/operators/getMessages';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { isEmailCMF } from 'conversations-message-history/common-message-format/operators/cmfComparators';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { isFromVisitor } from 'conversations-message-history/common-message-format/operators/senderTypeComparators';
export const findFirstVisitorEmail = memoize(threadHistory => {
  const messages = getMessages(threadHistory);
  return messages.find(message => isEmailCMF(message) && isFromVisitor(message));
});