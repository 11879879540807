import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import http from 'conversations-http/clients/http';
import { formatVisitors } from '../formatters/Visitors';
export function fetchVisitors(vidList = []) {
  return http.get('messages/v2/visitor/batch', {
    query: {
      vid: vidList
    }
  }).then(formatVisitors).catch(error => {
    reportError({
      error
    });
    return error;
  });
}