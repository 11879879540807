import { getIsDefault, getDisplayGlobally
// @ts-expect-error module not typed
} from 'conversations-internal-schema/connected-email-account/operators/connectedEmailAccountGetters';
// @ts-expect-error module not typed

import { getChannelId, getId, getIsActive } from '../../../channel-instances/public/operators';
import { EMAIL_GENERIC_CHANNEL_ID } from '../../../generic-channels/public/constants';
import { findAccountByChannelInstanceId } from './findAccountByChannelInstanceId';
export const getFallbackAccount = ({
  connectedAccounts,
  channelInstances
}) => {
  const defaultAccount = connectedAccounts && connectedAccounts.find(getIsDefault);
  if (getDisplayGlobally(defaultAccount)) return defaultAccount;
  let fallbackAccount;
  const fallbackInstance = channelInstances && channelInstances.find(instance => {
    const isActiveEmailInstance = getChannelId(instance) === EMAIL_GENERIC_CHANNEL_ID && getIsActive(instance);
    if (!isActiveEmailInstance) return false;

    // ensure there is a matching connected account
    fallbackAccount = findAccountByChannelInstanceId({
      connectedAccounts,
      channelInstanceId: getId(instance)
    });

    // we want to ensure the fallback is not the default account,
    // since the default is always `isActive: true`
    return fallbackAccount && !getIsDefault(fallbackAccount);
  });
  if (fallbackInstance && fallbackAccount) return fallbackAccount;
  return connectedAccounts && connectedAccounts.first();
};