// TODO: move to composer-data
import { replyHasRecipient } from 'conversations-thread-data/common-reply/public/operators/replyHasRecipient';
// TODO: move to composer-data

// TODO: move to composer-data
import invariant from 'conversations-thread-data/utils/invariant';
export const noRecipient = ({
  reply
}) => {
  invariant(Boolean(reply), 'reply is required for noRecipient disabling strategy');
  return !replyHasRecipient(reply) ? {
    disabled: true,
    sendDisabledReason: 'NO_RECIPIENT'
  } : null;
};