/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { getFocusedViewMemberThreadId } from '../../focused-view-member/selectors/getFocusedViewMemberThreadId';
import { fetchFromOmnibus } from 'conversations-inbox-lib/omnibus/public/actions';
import { getThreadListMemberFilters } from '../../thread-list-member/selectors/getThreadListMemberFilters';
export const syncWithOmnibus = () => (dispatch, getState) => {
  const threadId = getFocusedViewMemberThreadId(getState());
  const {
    inboxId,
    sortDirection,
    threadStatus,
    viewId
  } = getThreadListMemberFilters(getState());
  dispatch(fetchFromOmnibus({
    inboxId,
    silent: true,
    sortDirection,
    threadId,
    threadStatus: threadStatus,
    viewId
  }));
};