import { registerMutation, registerQuery, useLazyQuery, useMutation } from 'data-fetching-client';
import { HubUserAttributesClient } from 'frontend-preferences-client';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
const hubUserAttributesClient = HubUserAttributesClient.forCaller('composer-ui');
const SMS_REPLY_COUNT_THRESHOLD = 3;
const SMS_REPLY_COUNT = 'SMS-inbox-reply-count';
const fetchSeenSmsCSATState = () => {
  return hubUserAttributesClient.batchFetch({
    [SMS_REPLY_COUNT]: '0'
  });
};
const writeSmsReplyCount = ({
  replyCount
}) => {
  return hubUserAttributesClient.write(SMS_REPLY_COUNT, replyCount.toString()).then(val => {
    return {
      smsCSATState: {
        [SMS_REPLY_COUNT]: val.toString()
      }
    };
  });
};
const FETCH_SMS_CSAT_STATE_QUERY = registerQuery({
  fieldName: 'smsCSATState',
  fetcher: fetchSeenSmsCSATState
});
const WRITE_SMS_REPLY_COUNT_MUTATION = registerMutation({
  fieldName: 'writeSmsReplyCount',
  fetcher: writeSmsReplyCount,
  args: ['replyCount']
});
export const useSmsCSATState = () => {
  var _data$smsCSATState, _data$smsCSATState2;
  const [getSmsCSATState, {
    data,
    called,
    loading,
    error
  }] = useLazyQuery(FETCH_SMS_CSAT_STATE_QUERY, {
    fetchPolicy: 'network-only'
  });
  if (!called) {
    getSmsCSATState();
  }
  const [writeSmsReplyCountMutation] = useMutation(WRITE_SMS_REPLY_COUNT_MUTATION, {
    onCompleted: () => {
      getSmsCSATState();
    }
  });
  const smsReplyCount = data && (_data$smsCSATState = data.smsCSATState) !== null && _data$smsCSATState !== void 0 && _data$smsCSATState[SMS_REPLY_COUNT] ? parseInt((_data$smsCSATState2 = data.smsCSATState) === null || _data$smsCSATState2 === void 0 ? void 0 : _data$smsCSATState2[SMS_REPLY_COUNT], 10) : 0;
  return {
    incrementSmsReplyCount: () => {
      writeSmsReplyCountMutation({
        variables: {
          replyCount: smsReplyCount + 1
        }
      }).catch(incrementError => {
        reportError({
          error: incrementError,
          componentData: {
            componentName: 'BaseReplyEditor - updateHubUserAttributes'
          }
        });
      });
    },
    shouldDisplaySmsCSAT: smsReplyCount >= SMS_REPLY_COUNT_THRESHOLD,
    loading,
    error
  };
};