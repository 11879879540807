// @ts-expect-error no types
import { fetchDocuments } from './fetchDocuments';
import { renderDocument } from './renderDocuments';
export const documentsApiWrapper = (httpClient, contactEmail) => ({
  fetchRenderedContent({
    id
  }) {
    return renderDocument({
      httpClient,
      contentId: id,
      contactEmail
    }).then(linkInfo => {
      const {
        url,
        text
      } = linkInfo;
      return {
        htmlBody: `<a href="${url}">${text}</a>`
      };
    });
  },
  searchArticles({
    query,
    count
  }) {
    return fetchDocuments({
      httpClient,
      queryParams: {
        query
      }
    }).then(results => {
      const finalResults = count ? results.slice(0, count) : results;
      return finalResults.map(item => {
        return Object.assign({}, item, {
          value: item.id
        });
      });
    });
  }
});