import http from 'conversations-http/clients/http';
import { CAPACITY_LIMITS_URL, CAPACITY_LOAD_URL } from '../constants/capacityTypes';
export const fetchPortalCapacityLimit = ({
  type
}) => http.get(`${CAPACITY_LIMITS_URL}/${type}`).catch(err => {
  if (err.headers['x-hubspot-notfound']) {
    return Promise.resolve({
      isEnabled: false
    });
  } else {
    throw err;
  }
});
export const fetchAgentCapacity = ({
  agentId,
  type
}) => http.get(`${CAPACITY_LOAD_URL}/${type}/${agentId}`);