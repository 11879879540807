import { useCallback, useState } from 'react';
import { fromJS } from 'immutable';
import { useAsyncFetcher } from '../../../async-data/hooks/useAsyncFetcher';
import { requestStarted } from '../../../async-data/operators/requestStarted';
import { requestFailedWithError } from '../../../async-data/operators/requestFailedWithError';
import { requestSucceededWithOperator } from '../../../async-data/operators/requestSucceededWithOperator';
import { isUninitialized } from '../../../async-data/operators/statusComparators';
export const useLocalAsyncFetcher = _ref => {
  let {
    initialAsyncData,
    fetcher: dataFetcher,
    requestArgs,
    toRecordFn = fromJS,
    deferred = false
  } = _ref;
  // Locally manage the data
  const [asyncData, setAsyncData] = useState(initialAsyncData);

  // Fetch
  const fetcher = useCallback(() => {
    setAsyncData(requestStarted(asyncData));
    dataFetcher(requestArgs).then(resp => {
      setAsyncData(requestSucceededWithOperator(() => toRecordFn(resp), asyncData));
    }, error => {
      setAsyncData(currentState =>
      // Some curried functions have a typing issue requiring this casting.
      requestFailedWithError(error, currentState));
    }).catch(error => {
      setAsyncData(currentState =>
      // Some curried functions have a typing issue requiring this casting.
      requestFailedWithError(error, currentState));
    });
  }, [dataFetcher, asyncData, toRecordFn, requestArgs]);
  const {
    retryFailed
  } = useAsyncFetcher({
    asyncData,
    fetcher,
    deferred: deferred || !isUninitialized(asyncData)
  });
  return {
    asyncData,
    retryFailed
  };
};