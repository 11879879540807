/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */

import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import { useHostAppCallbacksContext } from '../context/HostAppCallbacksContext';
import { useFetchInvoiceProperties } from './useFetchInvoiceProperties';
import { wait } from '../utils/wait';
import { useMutation, registerMutation } from 'data-fetching-client';
import I18n from 'I18n';
import { deletePayment } from '../api/paymentObjectApi';
import { useState } from 'react';
export const DELETE_PAYMENT_OBJECT = registerMutation({
  fieldName: 'deletePaymentObject',
  args: ['objectId'],
  fetcher: deletePayment
});
const useDeletePayment = ({
  associations,
  onClose
}) => {
  const [deletePaymentButtonLoading, setDeletePaymentButtonLoading] = useState(false);
  const {
    deleteAppCRMObject,
    closeSidebar
  } = useHostAppCallbacksContext();
  const hasInvoice = associations.toInvoices.edges.length > 0;
  const invoiceBalance = hasInvoice ? associations.toInvoices.edges[0].node.defaultProperties.hs_balance_due.value : '';
  const invoiceId = hasInvoice ? associations.toInvoices.edges[0].node.id : '';
  const {
    refetch
  } = useFetchInvoiceProperties(invoiceId);
  const makeUpdatesPostDeletion = ({
    updatedInvoiceId,
    updatedInvoiceProperties
  }) => {
    deleteAppCRMObject({
      updatedInvoiceId,
      updatedInvoiceProperties
    });
    closeSidebar();
    if (updatedInvoiceId && !updatedInvoiceProperties) {
      FloatingAlertStore.addAlert({
        title: I18n.text('cardPaymentHighlight.paymentHighlight.sidebarActions.delete.deleteTimeoutAlertTitle'),
        message: I18n.text('cardPaymentHighlight.paymentHighlight.sidebarActions.delete.deleteFailedAlertMessage'),
        type: 'danger'
      });
    } else {
      FloatingAlertStore.addAlert({
        titleText: I18n.text('cardPaymentHighlight.paymentHighlight.sidebarActions.delete.deleteSuccessAlertTitle'),
        message: I18n.text('cardPaymentHighlight.paymentHighlight.sidebarActions.delete.deleteSuccessAlertMessage'),
        type: 'success'
      });
    }
    onClose();
    setDeletePaymentButtonLoading(false);
  };
  const pollForInvoiceBalanceChange = async () => {
    let updatedInvoiceDefaultProperties = (await refetch()).data.crmObject.defaultProperties;
    let updatedInvoiceBalance = updatedInvoiceDefaultProperties.hs_balance_due.value;
    let i = 0;
    const MAX_RETRIES = 10;
    while (updatedInvoiceBalance === invoiceBalance && i <= MAX_RETRIES) {
      updatedInvoiceDefaultProperties = (await refetch()).data.crmObject.defaultProperties;
      updatedInvoiceBalance = updatedInvoiceDefaultProperties.hs_balance_due.value;
      await wait(1000);
      i += 1;
    }
    if (i >= MAX_RETRIES) return undefined;
    return updatedInvoiceDefaultProperties;
  };
  const [deletePaymentMutation] = useMutation(DELETE_PAYMENT_OBJECT, {
    onCompleted: async () => {
      if (hasInvoice) {
        const updatedInvoiceDefaultProperties = await pollForInvoiceBalanceChange();
        makeUpdatesPostDeletion({
          updatedInvoiceId: invoiceId,
          updatedInvoiceProperties: updatedInvoiceDefaultProperties
        });
      } else {
        setTimeout(() => {
          // The timeout is needed here as there is a gql bug with refetching deleted objects too quickly: https://git.hubteam.com/HubSpot/CRM-Issues/issues/4543#issuecomment-1241115
          makeUpdatesPostDeletion({});
        }, 3000);
      }
    },
    onError: () => {
      FloatingAlertStore.addAlert({
        titleText: I18n.text('cardPaymentHighlight.paymentHighlight.sidebarActions.delete.deleteFailedAlertTitle'),
        message: I18n.text('cardPaymentHighlight.paymentHighlight.sidebarActions.delete.deleteFailedAlertMessage'),
        type: 'danger'
      });
      setDeletePaymentButtonLoading(false);
    }
  });
  return {
    deletePaymentMutation: options => {
      setDeletePaymentButtonLoading(true);
      return deletePaymentMutation(options);
    },
    deletePaymentButtonLoading
  };
};
export default useDeletePayment;