import { useCallback, useLayoutEffect, useRef, useEffect } from 'react';
import { OUT_OF_SYNC, UNINITIALIZED } from '../constants/asyncStatuses';
import { getStatus } from '../operators/getters';
import { asyncDataInvariant } from '../invariants/asyncDataInvariant';
import { isFailed } from '../operators/statusComparators';
/**
 * @description a hook for automatically fetching and
 * re-fetching async data. It takes the async data it
 * should manage fetching for, as well as a fetcher method,
 * which is generally a pre-bound async action dispatcher.
 *
 * THIS HOOK IS NOT PART OF THIS LIBRARY'S PUBLIC API AND
 * IS AN IMPLEMENTATION DETAIL OF OTHER HOOKS. ITS INTERFACE
 * MAY CHANGE WITHOUT WARNING.
 * @param {Object} options
 * @param {AsyncData} options.asyncData
 * @param {Function} options.fetcher bound async action dispatcher
 * @param {boolean} options.deferred if true, the fetcher will not fire
 * regardless of status. defaults to false
 * @returns {Object}
 */

export const useAsyncFetcher = ({
  asyncData,
  fetcher,
  deferred = false
}) => {
  asyncDataInvariant(asyncData);
  /**
   * Trigger the fetcher if the status changes to UNINITIALZED
   * or OUT_OF_SYNC.
   */
  const status = getStatus(asyncData);
  const shouldFetch = !deferred && (status === UNINITIALIZED || status === OUT_OF_SYNC);
  useLayoutEffect(() => {
    let timeoutId;
    if (shouldFetch) {
      /**
       * Putting the fetch in a timeout ensures that each
       * request can update the entire react tree, including
       * other async fetchers before another fetch runs.
       */
      timeoutId = setTimeout(() => {
        fetcher();
      });
    }
    /**
     * Removes this hooks fetch from the queue if fetcher or
     * shouldFetch have updated before the fetch ran. This
     * would most likely happen as a result of another fetch's
     * timeout callback executing first.
     */
    return () => {
      clearTimeout(timeoutId);
    };
  }, [fetcher, shouldFetch]);
  const failed = isFailed(asyncData);
  const failedRef = useRef(failed);
  useEffect(() => {
    failedRef.current = failed;
  }, [failed]);
  /**
   * Only allow a retry if the async data is FAILED
   */
  const retryFailed = useCallback(() => {
    if (failedRef.current) {
      failedRef.current = false;
      fetcher();
    }
  }, [fetcher]);

  /**
   * Inject a method for re-triggering the fetch if the
   * async status is FAILED.
   */
  return {
    retryFailed
  };
};