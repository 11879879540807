import get from 'transmute/get';
import { isCreatedByUser, isProcessing, CustomViewStatus, getCustomViewIdForStatusChange, getCustomViewMessageStatus, generateViewId } from 'find-and-filter-data/views-schema/public';
import { customViewUpdated, selectViews } from 'find-and-filter-data/views-data/public';
import { getUserId } from '../../selectors/userSelectors/getUserId';
import { launchAdminSurvey } from '../operators/customViewsCsatSurvey';
const SHOW_SURVEY_AFTER_MS = 5 * 1000;
export const triggerCustomViewsCsatSurvey = store => next => action => {
  if (action.type === customViewUpdated.type) {
    const statusChange = action.payload;
    const customViewId = getCustomViewIdForStatusChange(statusChange);
    const viewId = generateViewId({
      customViewId
    });
    const customView = get(viewId, selectViews(store.getState()));
    const createdByThisUser = customView && isCreatedByUser(customView, getUserId(store.getState()));
    const isNewlyCreated = customView && isProcessing(customView) && getCustomViewMessageStatus(statusChange) === CustomViewStatus.CreatedAndComplete;
    if (createdByThisUser && isNewlyCreated) {
      setTimeout(() => {
        launchAdminSurvey();
      }, SHOW_SURVEY_AFTER_MS);
    }
  }
  return next(action);
};