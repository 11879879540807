export const EVERY_DAY = 'EVERY_DAY';
export const MON_FRI = 'MON_FRI';
export const SAT_SUN = 'SAT_SUN';
export const WEEKDAYS = 'WEEKDAYS';
export const WEEKENDS = 'WEEKENDS';
export const MONDAY = 'MONDAY';
export const TUESDAY = 'TUESDAY';
export const WEDNESDAY = 'WEDNESDAY';
export const THURSDAY = 'THURSDAY';
export const FRIDAY = 'FRIDAY';
export const SATURDAY = 'SATURDAY';
export const SUNDAY = 'SUNDAY';
export const ALL_DAY_TYPES = [EVERY_DAY, MON_FRI, SAT_SUN, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY];