// For specific documentation on the Sanitize.js library, config, or transformers, see: https://github.com/gbirke/Sanitize.js
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'sani... Remove this comment to see the full error message
import Sanitize from 'sanitize';

// createHTMLDocument MUST be passed a title or else sanitized content will break in Internet Explorer: https://issues.hubspotcentral.com/browse/CRM-17134
export const secureDocument = document.implementation.createHTMLDocument('sanitize');
const ALLOWED_ELEMENTS = ['a', 'b', 'blockquote', 'br', 'caption', 'cite', 'code', 'dd', 'dl', 'dt', 'em', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'i', 'li', 'ol', 'p', 'pre', 'q', 'small', 'strike', 'strong', 'sub', 'sup', 'u', 'ul', 'div', 'span', 's'];
export const LlmSanitizerConfig = {
  elements: ALLOWED_ELEMENTS,
  dom: secureDocument,
  attributes: {
    __ALL__: ['style', 'align', 'valign', 'color', 'width', 'height'],
    a: ['href', 'title', 'target'],
    blockquote: ['cite'],
    div: ['dir'],
    ol: ['start', 'type'],
    q: ['cite'],
    span: ['data-at-mention', 'data-owner-id', 'data-mention-id', 'data-mention-name', 'contenteditable', 'data-email-reply', 'data-timestamp', 'data-value'],
    ul: ['type']
  },
  add_attributes: {
    a: {
      rel: 'nofollow noopener noreferrer',
      target: '_blank'
    }
  },
  protocols: {
    a: {
      href: ['ftp', 'http', 'https', 'mailto', 'tel', 'mms', 'sms', Sanitize.RELATIVE]
    },
    blockquote: {
      cite: ['http', 'https', Sanitize.RELATIVE]
    },
    q: {
      cite: ['http', 'https', Sanitize.RELATIVE]
    }
  }
};