import get from 'transmute/get';
import { List as ImmutableList } from 'immutable';
// @ts-expect-error module not typed

import EmailMetadata from 'conversations-message-history/common-message-format/records/EmailMetadata';
// @ts-expect-error module not typed
import { getInfoFromEmailAddressRecord } from 'conversations-thread-data/common-reply/public/operators/getInfoFromEmailAddressRecord';
// @ts-expect-error module not typed
import { buildDeliveryIdentifier } from 'conversations-message-history/common-message-format/operators/buildDeliveryIdentifier';
import { HS_EMAIL_ADDRESS } from 'conversations-message-history/common-message-format/constants/deliveryIdentifierTypes';
import { buildCMFSender } from 'conversations-message-history/common-message-format/operators/buildCMFSender';
import { AGENT } from 'conversations-message-history/common-message-format/constants/legacySenderTypes';
import { buildCommonMessage } from 'conversations-message-history/common-message-format/operators/buildCommonMessage';
import { WEB } from 'conversations-message-history/common-message-format/constants/clientTypes';
import { OUTGOING } from 'conversations-message-history/common-message-format/constants/messageDirections';
import { buildRecipients } from 'conversations-message-history/common-message-format/operators/buildRecipients';
import { TO } from 'conversations-message-history/common-message-format/constants/recipientFields';
const _buildCMFEmailMetadataAttachment = ({
  to,
  fromEmailAddressRecord
}) => {
  const toAddresses = to ? to.map(toRecord => toRecord.address).toList() : undefined;
  const connectedAccountAddress = get('address', fromEmailAddressRecord);
  return new EmailMetadata({
    to: toAddresses,
    from: getInfoFromEmailAddressRecord(fromEmailAddressRecord),
    connectedAccountAddress
  });
};
const _buildCMFSenders = ({
  fromEmailAddressRecord,
  userId
}) => {
  const deliveryIdentifier = fromEmailAddressRecord && fromEmailAddressRecord.address ? buildDeliveryIdentifier({
    deliveryIdentifierType: HS_EMAIL_ADDRESS,
    deliveryIdentifierValue: fromEmailAddressRecord.address
  }) : null;
  if (deliveryIdentifier) {
    return ImmutableList([buildCMFSender({
      senderField: 'FROM',
      deliveryIdentifier,
      senderId: userId,
      senderType: AGENT
    })]);
  }
  return ImmutableList();
};
export const buildCommonMessageForEmptyReply = ({
  to,
  fromEmailAddressRecord,
  channelInstanceId,
  userId
}) => {
  const cmfAttachments = ImmutableList([_buildCMFEmailMetadataAttachment({
    fromEmailAddressRecord
  })]);
  const recipientsPojo = to && to.size ? to.map(toRecord => ({
    recipientField: TO,
    singleDeliveryIdentifier: buildDeliveryIdentifier({
      deliveryIdentifierType: HS_EMAIL_ADDRESS,
      deliveryIdentifierValue: toRecord.address
    })
  })) : [];
  const cmfRecipients = buildRecipients(ImmutableList(recipientsPojo));
  const cmfSenders = _buildCMFSenders({
    fromEmailAddressRecord,
    userId
  });
  return buildCommonMessage({
    clientType: WEB,
    attachments: cmfAttachments.toArray(),
    direction: OUTGOING,
    recipients: cmfRecipients,
    senders: cmfSenders,
    channelInstanceId
  });
};