import { Map as ImmutableMap } from 'immutable';
import { convertRecipientToType, getGenericChannelId, getRecipients } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getRecipientFieldName, getRecipientKey } from 'conversations-thread-data/channel-configuration/public/operators';
import { getRecipientField, getSingleDeliveryIdentifier } from 'conversations-message-history/common-message-format/operators/recipientGetters';
import { GenericIdentifierRecord } from 'conversations-thread-data/recipients/public/GenericIdentifierRecord';
import { getDeliveryIdentifierType, getDeliveryIdentifierValue } from 'conversations-message-history/common-message-format/operators/deliveryIdentifierGetters';
import { getEmailAddressRecordFromString } from 'conversations-thread-data/common-reply-metadata/public/operators/getEmailAddressRecordFromString';
import { getProperty } from 'customer-data-objects/objects/ObjectAccessors';
function buildGenericIdentiferRecordFromRecipient(recipient, genericChannelId, contact) {
  const deliveryIdentifer = getSingleDeliveryIdentifier(recipient);
  const deliveryIdentifierValue = getDeliveryIdentifierValue(deliveryIdentifer);
  const emailAddressRecord = deliveryIdentifierValue ? getEmailAddressRecordFromString(deliveryIdentifierValue).toJS() : null;
  const id = contact && contact['canonical-vid'];
  const firstName = contact && getProperty(contact, 'firstname');
  const lastName = contact && getProperty(contact, 'lastname');
  return new GenericIdentifierRecord(Object.assign({}, emailAddressRecord, {
    id,
    firstName,
    lastName,
    crmObjectId: id,
    deliveryIdentifierType: getDeliveryIdentifierType(deliveryIdentifer),
    deliveryIdentifierValue,
    genericChannelId
  }));
}
export function getAllGenericRecipients(recipientFields, commonMessage, contacts) {
  const messageRecipients = getRecipients(commonMessage);
  const recipientsMap = recipientFields.reduce((acc, recipientField) => {
    const recipientFieldName = getRecipientFieldName(recipientField);
    const recipientKey = getRecipientKey(recipientField);
    if (!recipientKey) return acc;
    const recipientsForField = messageRecipients.filter(recipient => getRecipientField(recipient) === recipientFieldName);
    const genericIdentifiersForField = recipientsForField.map(recipient => {
      const {
        actorId
      } = convertRecipientToType(recipient);
      const contactForRecipient = contacts && actorId ? contacts[actorId] : undefined;
      return buildGenericIdentiferRecordFromRecipient(recipient, getGenericChannelId(commonMessage), contactForRecipient);
    }).toList();
    return acc.set(recipientKey, genericIdentifiersForField);
  }, ImmutableMap());
  const allRecipients = recipientsMap.valueSeq().flatten(true).toList();
  return recipientsMap.set('all', allRecipients);
}