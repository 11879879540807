import ActionStates from '../records/ActionStates';
import { CANCEL_SUBJECT_EDIT, CLOSE_CONVERSATION, CLOSE_TICKET, LOAD_TICKET, LOAD_TICKET_EXTERNAL_PROPERTIES, SAVE_PRODUCT_AREA, SAVE_RESOLUTION, SAVE_ROADBLOCK, SAVE_SUBJECT, START_SUBJECT_EDIT } from '../actions/ActionTypes';
const STATUS_NOT_FOUND = 404;
function actionStates(state = new ActionStates(), action) {
  switch (action.type) {
    case CLOSE_CONVERSATION:
      {
        return state.set('isClosingConversation', action.meta === 'request');
      }
    case CLOSE_TICKET:
      {
        return state.set('isClosingTicket', action.meta === 'request');
      }
    case LOAD_TICKET:
      {
        return state.merge({
          couldNotFindTicket: action.error && action.payload.status === STATUS_NOT_FOUND,
          hasErroredLoadingTicket: action.error && action.payload.status !== STATUS_NOT_FOUND,
          isLoadingTicket: action.meta === 'request'
        });
      }
    case LOAD_TICKET_EXTERNAL_PROPERTIES:
      {
        return state.merge({
          hasErroredLoadingTicketExternalProperties: action.error,
          isLoadingTicketExternalProperties: action.meta === 'request'
        });
      }
    case SAVE_PRODUCT_AREA:
      {
        return state.set('isSavingProductArea', action.meta === 'request');
      }
    case SAVE_RESOLUTION:
      {
        return state.set('isSavingResolution', action.meta === 'request');
      }
    case SAVE_ROADBLOCK:
      {
        return state.set('isSavingRoadblock', action.meta === 'request');
      }
    case SAVE_SUBJECT:
      {
        return state.merge({
          isEditingSubject: action.meta === 'request',
          isSavingSubject: action.meta === 'request'
        });
      }
    case CANCEL_SUBJECT_EDIT:
      {
        return state.set('isEditingSubject', false);
      }
    case START_SUBJECT_EDIT:
      {
        return state.set('isEditingSubject', true);
      }
    default:
      return state;
  }
}
export default actionStates;