import { UNASSIGNED, ASSIGNED_TO_BOT, ASSIGNEE } from '../constants/FilterKeys';
import { UNASSIGNED_USER_ID } from '../constants/UnassignedUserId';
import { BOT_USER_ID } from '../constants/BotUserId';
const updateAssigneeFilter = (filters, userId) => {
  let unassignedFilterValue = null;
  let assignedToBot = null;
  let assigneeFilterValue = userId;
  if (userId && userId > 0) {
    assignedToBot = false;
    unassignedFilterValue = false;
  }
  if (userId === UNASSIGNED_USER_ID) {
    assignedToBot = false;
    unassignedFilterValue = true;
    assigneeFilterValue = null;
  }
  if (userId === BOT_USER_ID) {
    assignedToBot = true;
    assigneeFilterValue = null;
    unassignedFilterValue = false;
  }
  return filters.set(UNASSIGNED, unassignedFilterValue).set(ASSIGNED_TO_BOT, assignedToBot).set(ASSIGNEE, assigneeFilterValue);
};
export default updateAssigneeFilter;