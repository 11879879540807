import { assignResponder } from './assignResponder';
import { unassignResponder } from './unassignResponder';
export const updateAssignment = ({
  threadId,
  responder
}) => {
  return responder ? assignResponder({
    threadId,
    actorId: `A-${responder.userId}`
  }) : unassignResponder({
    threadId
  });
};