import { List, Set as ImmutableSet } from 'immutable';
import { useEffect, useRef } from 'react';
import { useConversationIdByThreadId } from '../../crm-integration/hooks/useConversationIdByThreadId';
import { getTicketId } from 'svh-tickets-lib/tickets/public/operators';
import { useFetchAssociatedTickets } from 'conversations-inbox-lib/tickets/fetch/useFetchAssociatedTicket';
/**
 *
 * @description Return the values removed from a list
 * when that list changes, ignoring changes that coincide
 * with the key changing.
 * @param {Immutable.List} list the list to diff changes on
 * @param {any} ignoreKey a key whose change indicates that
 * changes to the list should be ignored
 */
const useDiff = (list, ignoreKey) => {
  const listRef = useRef(list);
  const ignoreKeyRef = useRef(ignoreKey);
  useEffect(() => {
    ignoreKeyRef.current = ignoreKey;
  }, [ignoreKey]);
  useEffect(() => {
    listRef.current = list;
  }, [list]);
  return ignoreKey === ignoreKeyRef.current ? listRef.current.toSet().subtract(list) : ImmutableSet();
};
/**
 * @description This hooks enables an escape-hatch for notifying
 * the thread view of ticket disassociations. It takes a declarative
 * pattern (selector) for watching associated ticket ids and
 * converts it into an imperative callback.
 */
export const useTicketRemovalWatcher = ({
  threadId,
  onTicketsRemoved
}) => {
  const {
    conversationId
  } = useConversationIdByThreadId({
    threadId
  });
  const {
    associatedTicket
  } = useFetchAssociatedTickets({
    conversationId
  });
  const associatedTicketIds = associatedTicket ? List([getTicketId(associatedTicket)]) : List();

  // get removed ticket ids, ignoring changes that occur due
  // to the threadId changing.
  const removedTicketIds = useDiff(associatedTicketIds, threadId);
  useEffect(() => {
    if (removedTicketIds.size) {
      onTicketsRemoved(removedTicketIds);
    }
  }, [removedTicketIds, threadId, onTicketsRemoved]);
};