import { getForwardCommunicatorState } from 'composer-data/email/public/selectors';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { isLoading } from 'conversations-async-data/async-data/operators/statusComparators';
import { THREAD_COMMENT } from 'conversations-message-history/comment-message/constants/messageTypes';
import { getGenericChannelId } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { useChannelConfigurations } from 'conversations-thread-data/channel-configuration/public/hooks';
import { isGenericChannelId } from 'conversations-thread-data/channel-configuration/public/types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { geConfigKeyFromUrlFragment, getUrlFragmentFromConfigKey } from '../../../editor-utilities/protected/utils';
import { useAllowedMessageChannelIds } from './useAllowedMessageChannelIds';
function isEditorConfigKeyAllowed(editorConfigKey, allowedMessageChannelIds) {
  return editorConfigKey === THREAD_COMMENT || editorConfigKey === 'UNSET' || !allowedMessageChannelIds || allowedMessageChannelIds.includes(editorConfigKey);
}
function getCurrentChannelId({
  editorFragment,
  defaultGenericChannelId,
  allowedMessageChannelIds
}) {
  const cleanEditorFragment = editorFragment && editorFragment.charAt(editorFragment.length - 1) === '?' ? editorFragment.slice(0, -1) : editorFragment;
  const fragmentEditorKey = Number(cleanEditorFragment) || geConfigKeyFromUrlFragment({
    fragment: cleanEditorFragment
  });
  const isFragmentEditorKeyAllowed = fragmentEditorKey && isEditorConfigKeyAllowed(fragmentEditorKey, allowedMessageChannelIds);
  if (!allowedMessageChannelIds || isFragmentEditorKeyAllowed) {
    return fragmentEditorKey;
  }
  const isDefaultGenericChannelIdAllowed = defaultGenericChannelId && isEditorConfigKeyAllowed(defaultGenericChannelId, allowedMessageChannelIds);
  if (isDefaultGenericChannelIdAllowed) return defaultGenericChannelId;
  const fallbackEditorConfigKey = allowedMessageChannelIds && allowedMessageChannelIds[0] || THREAD_COMMENT;
  return fallbackEditorConfigKey;
}
function getCurrentConfiguration({
  currentEditorConfigKey,
  editorConfigurations
}) {
  const isFrameworkChannel = isGenericChannelId(currentEditorConfigKey) && currentEditorConfigKey > 1010;
  if (isFrameworkChannel) {
    return editorConfigurations.get('UNSET');
  }
  return editorConfigurations.get(currentEditorConfigKey);
}
export function useCurrentEditorConfiguration({
  defaultGenericChannelId,
  editorFragment,
  onChannelChange,
  originalGenericChannelId,
  threadId
}) {
  const {
    asyncChannelConfigurations
  } = useChannelConfigurations();
  const editorConfigurations = getData(asyncChannelConfigurations);
  const {
    allowedMessageChannelIds,
    loading: loadingBEConfigurations
  } = useAllowedMessageChannelIds({
    originalGenericChannelId
  });
  const isLoadingConfigurations = isLoading(asyncChannelConfigurations) || loadingBEConfigurations;
  const currentEditorConfigKey = getCurrentChannelId({
    editorFragment,
    defaultGenericChannelId,
    allowedMessageChannelIds
  });
  const currentConfiguration = isLoadingConfigurations || !editorConfigurations ? null : getCurrentConfiguration({
    currentEditorConfigKey,
    editorConfigurations
  });
  useEffect(() => {
    if (isLoadingConfigurations) return;
    const fragment = getUrlFragmentFromConfigKey({
      editorConfigKey: currentEditorConfigKey
    });
    if (editorFragment !== fragment) onChannelChange({
      fragment
    });
  }, [isLoadingConfigurations, currentEditorConfigKey, editorFragment, onChannelChange, threadId]);
  const forwardCommunicatorState = useSelector(getForwardCommunicatorState);
  useEffect(() => {
    const {
      message: communicatorMessage
    } = forwardCommunicatorState;
    if (!communicatorMessage) return;
    const messageChannelId = getGenericChannelId(communicatorMessage);
    const isMessageChannelAllowed = allowedMessageChannelIds.includes(messageChannelId);
    if (isMessageChannelAllowed) {
      const fragment = getUrlFragmentFromConfigKey({
        editorConfigKey: messageChannelId
      });
      if (fragment) onChannelChange({
        fragment
      });
    }
  }, [forwardCommunicatorState, allowedMessageChannelIds, onChannelChange]);
  return {
    currentConfiguration,
    currentEditorConfigKey,
    isLoadingConfigurations
  };
}