import CallingProvider from 'calling-internal-common/call-provider/records/CallingProvider';
import PortalIdParser from 'PortalIdParser';
import { MOCK_UI } from '../constants/StaticExternalProviderNames';
import { getSetting } from '../../local-settings/localSettings';
import { getFullUrl } from 'hubspot-url-utils';
import once from '../../utils/once';
const MOCK_UI_KEY = 'installMockUi';
export let MOCK_UI_VALUES;
(function (MOCK_UI_VALUES) {
  MOCK_UI_VALUES["APP"] = "app";
  MOCK_UI_VALUES["LOCAL"] = "local";
  MOCK_UI_VALUES["APP_WITH_CUSTOM_OBJECTS"] = "app:supportsCustomObjects";
  MOCK_UI_VALUES["LOCAL_WITH_CUSTOM_OBJECTS"] = "local:supportsCustomObjects";
})(MOCK_UI_VALUES || (MOCK_UI_VALUES = {}));
export const isValidMockUiValue = value => {
  return Object.values(MOCK_UI_VALUES).includes(value);
};
export const getUrlPrefix = value => {
  return value.startsWith('app') ? MOCK_UI_VALUES.APP : MOCK_UI_VALUES.LOCAL;
};
export const getSupportsCustomObjects = value => {
  return value.includes('supportsCustomObjects');
};
export const getMockUiCallingProvider = once(value => {
  return new CallingProvider({
    name: MOCK_UI,
    supportsCustomObjects: getSupportsCustomObjects(value),
    url: `${getFullUrl(getUrlPrefix(value))}/calling-integration-sdk-test-app/${PortalIdParser.get()}`
  });
});

/**
 * Retrives and validates the Mock UI value from local storage.
 * @returns a Mock UI value if valid, null otherwise
 */
export const getMockUiValue = () => {
  const setting = getSetting(MOCK_UI_KEY);
  return isValidMockUiValue(setting) ? setting : null;
};