'use es6';

import { handleActions } from 'redux-actions';
import { GENERIC_CHANNEL_ID_CHANGED } from '../constants/actionTypes';
const initialState = null;
export default handleActions({
  [GENERIC_CHANNEL_ID_CHANGED]: (__state, action) => {
    const {
      channelId
    } = action.payload;
    return channelId;
  }
}, initialState);