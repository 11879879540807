// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message

import { getData } from 'conversations-async-data/async-data/operators/getters';
import { useConversationIdByThreadId } from '../../crm-integration/hooks/useConversationIdByThreadId';
import { getIdFromStage, getPipelineStageId, getStageIsClosed } from 'svh-tickets-lib/tickets/public/operators';
import { useTicketStagesForThread } from 'svh-tickets-lib/tickets/public/hooks';
import { useFetchAssociatedTickets } from 'conversations-inbox-lib/tickets/fetch/useFetchAssociatedTicket';
export const useIsAssociatedTicketOpen = ({
  threadId
}) => {
  const {
    conversationId
  } = useConversationIdByThreadId({
    threadId
  });
  const {
    asyncTicketStages
  } = useTicketStagesForThread({
    conversationId
  });
  const ticketStages = getData(asyncTicketStages);
  const {
    associatedTicket
  } = useFetchAssociatedTickets({
    conversationId
  });
  if (associatedTicket && ticketStages) {
    const pipelineStagedId = getPipelineStageId(associatedTicket);
    const ticketStage = ticketStages.find(stage => getIdFromStage(stage) === pipelineStagedId);
    return !getStageIsClosed(ticketStage);
  }
  return null;
};