import Aggregator from './Aggregator';
import { SUCCESS } from './constants/asyncDataPointResults';
const getBin = (value, edges) => {
  const found = edges.find((edge, j) => value > edge && value < edges[j + 1]);
  if (found === undefined) {
    return `${edges[edges.length - 1]}+`;
  }
  const index = edges.indexOf(found);
  if (index >= 0) {
    return `${edges[index]}-${edges[index + 1] - 1}`;
  }
  return `${edges[edges.length - 1]}+`;
};
export class BucketAggregator extends Aggregator {
  constructor(name, edges = [0, 250, 500, 1000, 2500, 5000]) {
    super(name, edges);
  }

  /**
   * Return data prefixed with the metric name.
   *
   * Eg: 'min' becomes `${name}-min`
   * @returns {Object} data data prefixed with the metric name
   */
  getAggregateData() {
    if (this.metric.success === 0 && this.metric.failure === 0) return {};
    const binsAggregate = Object.keys(this.metric.bins).reduce((acc, bin) => {
      acc[`${this.name}-${bin}`] = this.metric.bins[bin];
      return acc;
    }, {});
    return Object.assign({
      [`${this.name}-min`]: this.metric.min,
      [`${this.name}-max`]: this.metric.max,
      [`${this.name}-failure`]: this.metric.failure,
      [`${this.name}-success`]: this.metric.success
    }, binsAggregate);
  }

  /**
   * @param {Object} dataPoint
   * @param {number} dataPoint.startTime
   * @param {number} dataPoint.endTime
   * @param {number} dataPoint.duration
   * @param {('SUCCESS'|'FAILURE')} dataPoint.result
   */
  addDataPoint(dataPoint) {
    const {
      metric
    } = this;
    const {
      result,
      duration
    } = dataPoint;
    if (result === SUCCESS) {
      metric.success++;
      const bin = getBin(duration, this.edges);
      metric.bins[bin] = (metric.bins[bin] || 0) + 1;
      metric.min = duration < metric.min ? duration : metric.min;
      metric.max = duration > metric.max ? duration : metric.max;
    } else {
      metric.failure++;
    }
  }
}