// eslint-disable-next-line hs-react-native/no-dark-mode-incompatible-code
import { CALYPSO } from 'HubStyleTokens/colors';
import { List, Record } from 'immutable';
import { buildNewConfigRecordForStrategy } from '../../availability/factories/buildNewConfigRecordForStrategy';
import { RIGHT_ALIGNED } from '../../widget-location/constants/WidgetLocations';
import { EVERYONE } from '../constants/InboxVisibilityTypes';
class Inbox extends Record({
  accentColor: CALYPSO,
  autoCreated: null,
  availabilityStrategy: null,
  availabilityStrategyConfig: null,
  channels: List(),
  createdAt: null,
  createdBy: null,
  id: null,
  lastEditedAt: null,
  lastEditedBy: null,
  name: '',
  portalId: null,
  sendChatTranscriptsAutomatically: true,
  showHsBranding: false,
  slackNotificationChannelId: null,
  slackNotificationsEnabled: false,
  showPreviousConversations: true,
  teamIds: List(),
  userIds: List(),
  visibility: EVERYONE,
  transcriptSendFromConnectedAccountId: null,
  widgetLocation: RIGHT_ALIGNED,
  workspaceFlag: null
}, 'Inbox') {
  constructor(properties = {}) {
    super(Object.assign({}, properties, {
      availabilityStrategyConfig: buildNewConfigRecordForStrategy(properties),
      teamIds: List(properties.teamIds || []),
      userIds: List(properties.userIds || [])
    }));
  }
}
export default Inbox;