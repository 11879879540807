import { getEntries } from 'conversations-async-data/indexed-async-data/operators/getters';
import { getChannelInstanceById as getChannelInstanceByIdOperator } from '../operators/getChannelInstanceById';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { getIndexedAsyncChannelInstances } from './getIndexedAsyncChannelInstances';
export const getChannelInstanceById = (state, {
  channelInstanceId
}) => {
  let channelInstance = null;
  const asyncChannelInstances = getIndexedAsyncChannelInstances(state);
  const allMappedChannelInstances = getEntries(asyncChannelInstances);
  allMappedChannelInstances.forEach(channelInstanceListByInbox => {
    const data = getData(channelInstanceListByInbox);
    if (data) {
      const validResult = getChannelInstanceByIdOperator({
        channelInstances: data,
        id: channelInstanceId
      });
      if (validResult) {
        channelInstance = validResult;
        return false;
      }
    }
    return true;
  });
  return channelInstance;
};