'use es6';

import { useEffect, useState } from 'react';

/**
 * Hook to debounce a rapidly changing value in a functional component
 *
 * @example
 * function MyUISelect({search}) {
 *   const [results, setResults] = useState(null);
 *   const debouncedSearch = useDebounce(search, 350);
 *
 *   useEffect(() => {
 *     searchForData(debouncedSearch).then(setResults);
 *   }, [debouncedSearch, setResults]);
 *
 *   return <UISelect {...searchProps} />
 * }
 *
 * @param {<T>any} value The rapidly changing value to debounce
 * @param {Number} delayMs The delay between state changes
 * @return {T} The debounced value
 */
export function useDebounce(value, delayMs = 250) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delayMs);
    return () => clearTimeout(handler);
  }, [delayMs, value]);
  return debouncedValue;
}