module.exports = {
  "/live-messages/:portalId/inbox": {
    "success": {
      "inbox zero state": [
        "INBOX_ZERO_STATE_SUCCESS"
      ],
      "no conversations zero state": [
        "CHAT_FEED_ZERO_STATE_SUCCESS",
        "THREAD_LIST_ZERO_STATE"
      ],
      "missing permisson": [
        "MISSING_PERMISSION_SUCCESS"
      ]
    },
    "error": [
      "INBOX_REQUEST_STATUS_FAILED",
      "INBOX_TOP_LEVEL_FAILURE"
    ]
  },
  "/live-messages/:portalId/inbox/:threadId": {
    "success": {
      "inbox": [
        "INBOX_SUCCESS",
        "CHAT_FEED_SUCCESS",
        "THREAD_LIST_SUCCESS"
      ],
      "inbox zero state": [
        "INBOX_ZERO_STATE_SUCCESS"
      ],
      "no conversations zero state": [
        "CHAT_FEED_ZERO_STATE_SUCCESS",
        "THREAD_LIST_ZERO_STATE"
      ],
      "missing permisson": [
        "MISSING_PERMISSION_SUCCESS"
      ]
    },
    "error": [
      "THREAD_LIST_ERROR",
      "INBOX_REQUEST_STATUS_FAILED",
      "INBOX_TOP_LEVEL_FAILURE"
    ]
  },
  "/live-messages": {},
  "/live-messages/:portalId": {}
};