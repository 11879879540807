import { useSelector } from 'react-redux';
import { DEFAULT_CLIENT_KEY } from '../constants/clientKeys';
import { isFailed, isOnline, getFailureState, canPublish, isRetrying } from '../selectors/healthCheckSelectors';
/**
 * @typedef healthCheck
 * @type {Object}
 * @property {boolean} canPublish - true if connected and subscribed
 * @property {string||null} failureState - failure state
 * @property {boolean} isFailed - true if the connection has failed in any way
 * @property {boolean} isOnline - true if the connection is online
 * @property {boolean} isRetrying - true if the connection is retrying
 */
/**
 * A hook to provide the pub sub connection state to a react component
 *
 * @returns {healthCheck}
 */
export const usePubSubHealthCheck = (clientKey = DEFAULT_CLIENT_KEY) => {
  return {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
    canPublish: useSelector(state => canPublish(state, {
      clientKey
    })),
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
    failureState: useSelector(state => getFailureState(state, {
      clientKey
    })),
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
    isFailed: useSelector(state => isFailed(state, {
      clientKey
    })),
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
    isOnline: useSelector(state => isOnline(state, {
      clientKey
    })),
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
    isRetrying: useSelector(state => isRetrying(state, {
      clientKey
    }))
  };
};