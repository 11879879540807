import CallingProvider from 'calling-internal-common/call-provider/records/CallingProvider';
import FromNumber from 'calling-internal-common/calls-v1-settings/records/InitialLoadSettingsRecords/FromNumber';
import { SET_CALLING_PROVIDER_SETTING } from '../constants/MethodNames';
import { callingAPIMethodWithQueue } from '../utils/hsCallingUtils';
export function parser(callProviderSettings) {
  return {
    callingProvider: new CallingProvider(callProviderSettings.callingProvider),
    fromNumber: new FromNumber(callProviderSettings.fromNumber)
  };
}
export function flatten(callProviderSettings) {
  return {
    callingProvider: callProviderSettings.callingProvider && callProviderSettings.callingProvider.toJS(),
    fromNumber: callProviderSettings.fromNumber && callProviderSettings.fromNumber.toJS()
  };
}
export default function setCallingProviderSettings(callProviderSettings) {
  return callingAPIMethodWithQueue(SET_CALLING_PROVIDER_SETTING, callProviderSettings, flatten);
}