'use es6';

import filterNot from 'transmute/filterNot';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import reduce from 'transmute/reduce';
import omit from 'transmute/omit';
import ContactRecord from 'customer-data-objects/contact/ContactRecord';
import CompanyRecord from 'customer-data-objects/company/CompanyRecord';
import DealRecord from 'customer-data-objects/deal/DealRecord';
import FeedbackSubmissionRecord from 'customer-data-objects/feedback/FeedbackSubmissionRecord';
import TicketRecord from 'customer-data-objects/ticket/TicketRecord';
import { COMPANY, CONTACT, DEAL, FEEDBACK_SUBMISSION, TICKET } from 'customer-data-objects/constants/ObjectTypes';
import idMappings from 'customer-data-objects/constants/ObjectIds';
import { normalizeAccessLevel } from 'customer-data-objects/property/AccessLevel';
const toRecordPropertiesFromObject = (acc, property, name) => {
  const {
    value,
    source,
    sourceId,
    timestamp
  } = property || {};
  acc[name] = {
    value,
    source,
    sourceId,
    timestamp
  };
  return acc;
};
const toRecordPropertiesFromList = (acc, property) => {
  const {
    name,
    value,
    source,
    sourceId,
    timestamp
  } = property || {};
  acc[name] = {
    value,
    source,
    sourceId,
    timestamp
  };
  return acc;
};
const RecordType = {
  [COMPANY]: CompanyRecord,
  [CONTACT]: ContactRecord,
  [DEAL]: DealRecord,
  [FEEDBACK_SUBMISSION]: FeedbackSubmissionRecord,
  [TICKET]: TicketRecord
};
export const toSubject = (objectType, gqlCrmObject) => {
  let recordReadyProperties = {};
  if (gqlCrmObject.defaultProperties) {
    const recordProperties = omit(['Symbol(id)', '__typename'], gqlCrmObject.defaultProperties);
    recordReadyProperties = reduce(recordReadyProperties, toRecordPropertiesFromObject, recordProperties);
  }
  if (gqlCrmObject.favoriteProperties) {
    const withoutDeletedProperties = filterNot(property => !get('definition', property) || getIn(['definition', 'deleted'], property), gqlCrmObject.favoriteProperties);
    recordReadyProperties = reduce(recordReadyProperties, toRecordPropertiesFromList, withoutDeletedProperties);
  }
  return RecordType[objectType].fromJS({
    [idMappings[objectType][0]]: gqlCrmObject.id,
    properties: recordReadyProperties
  });
};

// Copied from ../universal/parse.js to preserve code firewall
export const toPropertyPermissions = propertyEntries => propertyEntries.reduce((propertyMap, {
  definition,
  permission
}) => {
  if (definition && permission) {
    propertyMap[definition.name] = normalizeAccessLevel(permission.accessLevel);
  }
  return propertyMap;
}, {});
export const toCompany = gqlCrmObject => toSubject(COMPANY, gqlCrmObject);
export const toContact = gqlCrmObject => toSubject(CONTACT, gqlCrmObject);
export const toDeal = gqlCrmObject => toSubject(DEAL, gqlCrmObject);
export const toTicket = gqlCrmObject => toSubject(TICKET, gqlCrmObject);