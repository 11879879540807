'use es6';

import { Set as ImmutableSet } from 'immutable';
import { getType } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { CRM_OBJECT_WORKFLOW_EMAIL_SENT } from 'conversations-message-history/crm-object-workflow-email-sent/constants/messageTypes';
import { getRecipientVid } from 'conversations-message-history/crm-object-workflow-email-sent/operators/crmObjectWorkflowEmailSentGetters';
import { getMessages } from 'conversations-message-history/thread-history/operators/getters';
import { COMMON_MESSAGE } from 'conversations-message-history/common-message-format/constants/messageTypes';
import { getRecipients, convertRecipientToType
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
} from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { buildRecipientLocator } from 'conversations-message-history/legacy-recipients/operators/buildRecipientLocator';
import { getCallMetadataAttachment } from 'conversations-message-history/call/operators/callMetadataAttachmentGetters';
export const getRecipientsFromHistory = history => {
  const messages = getMessages(history);
  return messages.reduce((accumulator, message) => {
    switch (getType(message)) {
      case CRM_OBJECT_WORKFLOW_EMAIL_SENT:
        {
          const recipientVid = getRecipientVid(message);
          return recipientVid ? accumulator.add(recipientVid) : accumulator;
        }
      default:
        return accumulator;
    }
  }, ImmutableSet());
};
export const getRecipientLocatorsFromHistory = history => {
  const messages = getMessages(history);
  return messages.reduce((accumulator, message) => {
    switch (getType(message)) {
      case COMMON_MESSAGE:
        {
          // only do this for calling messages to avoid edge cases
          const isCalling = Boolean(getCallMetadataAttachment(message));
          if (!isCalling) {
            return accumulator;
          }
          // get recipients
          const recipients = getRecipients(message);
          const recipient = recipients && recipients.get(0);
          const {
            actorType,
            actorId
          } = convertRecipientToType(recipient);
          const recipientKey = actorId && actorType && buildRecipientLocator(actorType, actorId);
          return recipientKey ? accumulator.add(recipientKey) : accumulator;
        }
      default:
        return accumulator;
    }
  }, ImmutableSet());
};