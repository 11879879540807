import { Map as ImmutableMap } from 'immutable';
import { handleActions } from 'redux-actions';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { GetMessengerConnectionsActionTypes } from '../action-types/MessengerConnectionsActionTypes';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';

// @ts-expect-error ts-migrate(2348) FIXME: Value of type 'new <AsyncDataValue>(t?: Partial<As... Remove this comment to see the full error message
const initialState = AsyncData({
  data: ImmutableMap()
});
export default handleActions({
  [GetMessengerConnectionsActionTypes.STARTED](state) {
    return requestStarted(state);
  },
  [GetMessengerConnectionsActionTypes.FAILED](state) {
    return requestFailed(state);
  },
  [GetMessengerConnectionsActionTypes.SUCCEEDED](state, action) {
    return requestSucceededWithOperator(() => action.payload.data, state);
  }
}, initialState);