import { OrderedMap } from 'immutable';
import getIn from 'transmute/getIn';
import { selectViewMemberPatches } from '../../../view-member-optimistic-updates/public';
import { getMembers } from '../../../view-members-schema/protected';
import { applyViewMemberUpdates } from '../applyViewMemberUpdates';

//This selector replaces the now deprecated getThreadListMembersMap selector from inbox-ui.
//Ideally we will eventually delete it and always rely on the return value of useViewMembers
//to get our updated information.
export const selectThreadListMembersWithUpdates = (state, {
  viewId
}) => {
  if (!viewId) return OrderedMap();
  const viewMembersPageState = getIn(['findAndFilterData', 'viewMembers', 'data'], state);
  const viewMembersPage = viewMembersPageState.get(viewId);
  const viewMembers = getMembers(viewMembersPage);
  const viewMemberUpdates = selectViewMemberPatches(state);
  return applyViewMemberUpdates({
    viewMemberUpdates,
    viewMembers
  });
};