'use es6';

import memoize from 'transmute/memoize';
import { cleanEmail } from '../../email/sanitize/cleanEmail';

/**
 * @description Given a raw html string, return the texted
 * computed by rendering it in the dom. This can be used to
 * compare rich vs. plain text, as well as determine whether
 * any content would even be rendered for given html.
 */
export const getEvaluatedTextContent = memoize(html => {
  if (!html) return null;
  const container = document.createElement('div');
  container.innerHTML = cleanEmail(html);
  document.body.appendChild(container);
  const text = container.innerText;
  document.body.removeChild(container);
  return text.trim();
});