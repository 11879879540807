import { viewMemberDeleted } from './viewMemberDeleted';

/**
 * @description Handles a single view message over a live channel
 */
export const deleteViewChannelMessage = ({
  currentAgentId,
  removedMembers,
  viewKey
}) => dispatch => {
  dispatch(viewMemberDeleted({
    currentAgentId,
    removedMembers,
    viewKey
  }));
};