// Editing capabilities
export const INSERT_CORE = 'INSERT_CORE';
export const INSERT_TEMPLATES = 'INSERT_TEMPLATES';
export const PAYMENT_LINKS = 'PAYMENT_LINKS';
export const INVOICES = 'INVOICES';
export const MODEL_SMART_COMPOSE = 'MODEL_SMART_COMPOSE';
export const SUBMIT_ON_ENTER = 'SUBMIT_ON_ENTER';
export const INLINE_IMAGE = 'IMAGES';
export const USER_DEFAULT_FONT = 'USER_DEFAULT_FONT';
export const CONTENT_ASSISTANT = 'CONTENT_ASSISTANT';
export const AI_ASSISTANT = 'AI_ASSISTANT';

// Channel Capabilities
export const AI_SUMMARY = 'AI_SUMMARY';
export const AT_MENTIONS = 'AT_MENTIONS';
export const ATTACHMENTS = 'ATTACHMENTS';
export const IMAGES = 'IMAGES';
export const FORMATTED_HYPERLINKS = 'FORMATTED_HYPERLINKS';
export const PLAIN_TEXT = 'PLAIN_TEXT';
export const RICH_TEXT = 'RICH_TEXT';