import { getData } from 'conversations-async-data/async-data/operators/getters';
import { buildContactRecipients } from 'conversations-message-history/common-message-format/operators/buildCommonMessageRecipients';
import { getMessageDirection, getRecipients, getSenders } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getDeliveryIdentifier } from 'conversations-message-history/common-message-format/operators/senderGetters';
import { getDeliveryIdentifierValue, getDeliveryIdentifierType } from 'conversations-message-history/common-message-format/operators/deliveryIdentifierGetters';
import { OUTGOING } from 'conversations-message-history/common-message-format/constants/messageDirections';
const getSenderDeliveryIdentifierValues = inReplyToMessage => {
  const senders = inReplyToMessage ? getSenders(inReplyToMessage) : null;
  const senderDeliveryIdentifier = senders && senders.first() ? getDeliveryIdentifier(senders.first()) : undefined;
  const senderDeliveryIdentifierType = senderDeliveryIdentifier && getDeliveryIdentifierType(senderDeliveryIdentifier);
  const senderDeliveryIdentifierValue = senderDeliveryIdentifier && getDeliveryIdentifierValue(senderDeliveryIdentifier);
  return {
    senderDeliveryIdentifierType,
    senderDeliveryIdentifierValue
  };
};
export const getInitialRecipients = ({
  asyncCommonMessage,
  vid
}) => {
  const inReplyToMessage = getData(asyncCommonMessage) || undefined;
  const replyingToOwnMessage = inReplyToMessage && getMessageDirection(inReplyToMessage) === OUTGOING;
  if (replyingToOwnMessage) {
    return getRecipients(inReplyToMessage);
  }
  const {
    senderDeliveryIdentifierType,
    senderDeliveryIdentifierValue
  } = getSenderDeliveryIdentifierValues(inReplyToMessage);
  return buildContactRecipients({
    type: senderDeliveryIdentifierType || '',
    value: senderDeliveryIdentifierValue || '',
    vid
  });
};