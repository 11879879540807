import { getTimestampOrderTypeFromSortBy } from '../custom-views/getTimestampOrderTypeFromSortBy';
import { getTimestampOrderType } from '../thread-lists/threadListOperators';
/**
 * @description Given a view this operator will return the timestamp ViewOrderBy
 * the the view is being sorted on.
 *
 * This will return LATEST_VISITOR_RESPONSE by default if the given view is missing
 * a sortBy or orderType which is the backend default as well.
 */
export const getViewOrderedBy = view => {
  if (view && 'threadListId' in view) {
    return getTimestampOrderType(view);
  }
  return getTimestampOrderTypeFromSortBy(view);
};