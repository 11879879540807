import TypicalResponseTime from '../records/TypicalResponseTime';
import { FEW_MINUTES } from '../constants/StandardResponses';
import { MINUTES } from '../constants/Units';
const buildDefaultTypicalResponseTime = () => TypicalResponseTime({
  enabled: true,
  usingCustomResponse: false,
  standardResponse: FEW_MINUTES,
  customResponseQuantity: 5,
  customResponseUnit: MINUTES
});
export default buildDefaultTypicalResponseTime;