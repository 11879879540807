// Example wildcard token: thread:v2:prod:*:4532150:15680:*
const wildCardCapabilityRegexp = new RegExp(/^thread:v2:(?:prod|qa):\*:\d+:\d+:\*$/);

/*
 * @description Validates whether threadChannels Ids are authorized based on authorizedChannelIds
 *
 * The ably auth token maintains a list of authorized channels - either by full channelId or a wildcard
 * channelId for thread channels.
 *
 * Note that the visitor widget thead channels do not have wildcard auth enabled.
 *
 * channel format:
 *
 *  Thread channel format: thread:v2:prod:{shared|private}:{portalId}:{inboxId}:{threadId}
 *    Example: thread:v2:prod:shared:4532150:15680:67f19f7e-234c-4939-9b7d-896e505fb0c7
 *
 * @param authorizedChannels List of authorized channels
 * @param channelIds channelIds to validate
 */

export function channelCapabilityValidator(authorizedChannelIds, threadChannelIds) {
  try {
    // Check for wildcard capability
    const wildCardThreadIds = authorizedChannelIds.filter(channelId => wildCardCapabilityRegexp.test(channelId));
    if (wildCardThreadIds && wildCardThreadIds.length) {
      // Construct a regexp with wildcard tokens and replaces * with \w+
      const wildCardThreadIdRegexps = wildCardThreadIds.map(wildCardThreadId => {
        const regexpStr = wildCardThreadId.replace(/\*/g, '\\w+');
        return new RegExp(regexpStr);
      });

      // Ensure every thread channel matches some wildCard Auth
      return threadChannelIds.every(channelId => wildCardThreadIdRegexps.some(regexp => regexp.test(channelId)));
    }
    return false;
  } catch (e) {
    return false;
  }
}