import { EditorState } from 'prosemirror-state';
import { useCallback, useState } from 'react';
import { getCommonMessage, getEditorState } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
import { emailReplyHistoryClass, schema } from 'hubspot-prosemirror/prosemirror/ProsemirrorSchema';
import { getEditorStateFromHtml } from 'hubspot-prosemirror/prosemirror/ProsemirrorUtils';
import { editorStateHasContent } from '../operators/editorStateHasContent';
import { useShouldIncludeEmailReplies } from './useShouldIncludeEmailReplies';
export function useBaseEditorState(reply, draft) {
  const shouldIncludeEmailReplies = useShouldIncludeEmailReplies(getCommonMessage(reply));
  const [state, setState] = useState(() => {
    const editorStateFromReply = getEditorState(reply);
    if (editorStateFromReply && editorStateHasContent(editorStateFromReply)) {
      return editorStateFromReply;
    } else if (draft && draft.data) {
      return draft.data;
    }
    return shouldIncludeEmailReplies ? getEditorStateFromHtml(`<p/><div class="${emailReplyHistoryClass}"></div>`) : EditorState.create({
      schema
    });
  });
  const resetState = useCallback(() => {
    if (shouldIncludeEmailReplies) {
      setState(getEditorStateFromHtml(`<p/><div class="${emailReplyHistoryClass}"></div>`));
    } else {
      setState(EditorState.create({
        schema
      }));
    }
  }, [shouldIncludeEmailReplies]);
  return [state, setState, resetState];
}