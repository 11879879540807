'use es6';

import { combineReducers } from 'redux';
import { pubSubClient } from 'conversations-internal-pub-sub/redux/reducers/pubSubClient';
import { subscriptions } from 'conversations-internal-pub-sub/redux/reducers/subscriptions';
import { associations } from 'conversations-thread-data/associations/public/reducers';
import { auth } from 'conversations-thread-data/auth/public/reducers';
import cachedResponders from './reassignment/reducers/cachedResponders';
import chatflowConnectionStatus from 'conversations-thread-data/chatflows/reducers/chatflowConnectionStatus';
import chatflows from 'conversations-thread-data/chatflows/reducers/chatflows';
import deletedMessagesVisibility from './thread-deletion/reducers/deletedMessagesVisibility';
import hostedEmailAppealState from './appeals/reducers/hostedEmailAppealState';
import { hydratedEmailAddresses } from 'composer-data/email-metadata/public/reducers';
import inboxThreadHistories from './thread-histories/reducers/inboxThreadHistories';
import messageTruncatedReplyRequestState from './common-message-reply/reducers/messageTruncatedReplyRequestState';
import publishing from 'conversations-thread-data/pubsub/public/reducers/publishing';
import senders from 'conversations-thread-data/senders/reducers/senders';
import * as threadDetailsReducers from './thread-details/reducers/decoratedReducers';
import conversationIdByThreadId from './thread-details/reducers/conversationIdByThreadId';
import { threadPresence } from 'conversations-thread-data/presence/public/reducers';
import threadViewRootReducer from './threadViewRootReducer';
import typingIndicators from './typing-indicators/reducers/typingIndicators';
import userSettings from 'conversations-thread-data/settings/reducers/userSettings';
import zorseGrrTagging from 'zorse-grr-tagging/reducers/zorseGrrTagging';
import { filterRules } from 'conversations-thread-data/thread-details/public/reducers';
import seenNewFeatureShepherds from './shepherds/reducers/seenNewFeatureShepherds';
import zorseInboxes from './thread-details/reducers/zorseInboxes';
import zorseCrmComponents from 'zorse-crm-components-lib/reducers/zorseCrmComponents';
import threadStatusChangeInProgress from './thread-toolbar/reducers/threadStatusChangeInProgress';
export const encapsulatedThreadViewRootReducer = combineReducers(Object.assign({}, threadDetailsReducers, {
  associations,
  auth,
  cachedResponders,
  chatflowConnectionStatus,
  chatflows,
  conversationIdByThreadId,
  deletedMessagesVisibility,
  hostedEmailAppealState,
  filterRules,
  hydratedEmailAddresses,
  messageTruncatedReplyRequestState,
  seenNewFeatureShepherds,
  publishing,
  pubSubClient,
  senders,
  subscriptions,
  threadHistories: inboxThreadHistories,
  threadPresence,
  threadViewRootReducer,
  threadStatusChangeInProgress,
  typingIndicators,
  userSettings,
  zorseGrrTagging,
  zorseInboxes,
  zorseCrmComponents
}));
export default encapsulatedThreadViewRootReducer;