const SET_START_BUG = "Failed to execute 'setStart' on 'Range'";

/**
 * @description returns true if the crash error is generally
 * indicative of an issue that will be resolved by automatically
 * reloading the editor.
 * @param {Error} error
 * @returns {Boolean}
 */
export const isPotentiallyRecoverableEditorCrash = error => {
  return error.message.includes(SET_START_BUG);
};