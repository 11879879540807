'use es6';

import { localStorage } from 'conversations-platform/localStorage';
import { makeCommonReplyDraftKey } from '../operators/makeCommonReplyDraftKey';
import { serializeCommonReplyDraft } from '../operators/serializeCommonReplyDraft';
import { evictCommonReplyDraft } from './evictCommonReplyDraft';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
export const saveCommonReplyAsDraft = commonReply => {
  const serializedDraft = serializeCommonReplyDraft(commonReply);
  const draftKey = makeCommonReplyDraftKey(commonReply);
  try {
    localStorage.setItem(draftKey, serializedDraft);
  } catch (error) {
    if (error && error instanceof DOMException && (error.name === 'QuotaExceededError' || error.code === DOMException.QUOTA_EXCEEDED_ERR)) {
      // eslint-disable-next-line no-console
      console.error(error);
      evictCommonReplyDraft();
    } else {
      reportError({
        error: new Error('FAILED TO WRITE DRAFT'),
        extra: {
          error
        }
      });
    }
  }
};