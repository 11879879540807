export const CRM_COLLAPSED_WIDTH = 54;
export const CRM_BREAK_POINT = 1000;
export const CRM_FULL_WIDTH = (() => {
  const windowWidth = window.innerWidth;
  const percentageWidth = Math.floor(windowWidth * 0.22);
  if (percentageWidth < 280) {
    return 280;
  }
  if (percentageWidth > 320) {
    return 320;
  }
  return percentageWidth;
})();
export const CRM_SIDE_PANEL_WIDTH = CRM_FULL_WIDTH + 40;