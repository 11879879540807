'use es6';

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { apiLink } from './hubHttpLink';
import possibleTypes from './possibleTypes.json';
export const createCache = () => new InMemoryCache({
  /*
   * From the docs:
   * If true, result objects read from the cache will be canonized, which means deeply-equal objects will also be ===
   * (literally the same object), allowing much more efficient comparison of past/present results.
   *
   * See also: https://www.apollographql.com/docs/react/api/cache/InMemoryCache/#canonizeresults
   *
   * This is incredibly important when using these query results as props to memoized components or deps in react
   * hooks, and can cause infinite loops or other strange bugs if not set. crm-index-ui depends heavily on this setting.
   */
  canonizeResults: true,
  possibleTypes,
  typePolicies: {
    // CardContainers aren't guaranteed to have a unique ID because of how their ids are generated.
    // Until this is resolved, the safest thing to do is not normalize them so they are not considered individual
    // cache items, which prevents the cache collisions.
    // https://www.apollographql.com/docs/react/caching/cache-configuration/#disabling-normalization
    CardContainer: {
      keyFields: false
    },
    CrmObject: {
      fields: {
        userPermissions: {
          merge: true
        }
      }
    },
    DefaultCrmObject: {
      fields: {
        defaultAssociations: {
          merge: true
        }
      }
    },
    Cart: {
      fields: {
        defaultAssociations: {
          merge: true
        },
        defaultProperties: {
          merge: true
        }
      }
    },
    Quote: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    Contact: {
      fields: {
        associations: {
          merge: true
        },
        defaultProperties: {
          merge: true
        }
      }
    },
    FeedbackSubmission: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    Engagement: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    Deal: {
      fields: {
        associations: {
          merge: true
        },
        defaultProperties: {
          merge: true
        }
      }
    },
    Company: {
      fields: {
        associations: {
          merge: true
        },
        defaultProperties: {
          merge: true
        }
      }
    },
    Order: {
      fields: {
        associations: {
          merge: true
        },
        defaultProperties: {
          merge: true
        }
      }
    },
    Ticket: {
      fields: {
        associations: {
          merge: true
        },
        defaultProperties: {
          merge: true
        },
        pipelineInfo: {
          merge: true
        }
      }
    },
    LineItem: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    Conversation: {
      fields: {
        associations: {
          merge: true
        },
        defaultProperties: {
          merge: true
        }
      }
    },
    ConversationInbox: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    QuoteModule: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    QuoteModuleField: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    QuoteTemplate: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    PaymentLink: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    PaymentsSubscription: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    PaymentSchedule: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    PaymentScheduleInstallment: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    CommercePayment: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    Invoice: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    Discount: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    DiscountCode: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    DiscountTemplate: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    Fee: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    Tax: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    PermissionsTesting: {
      fields: {
        defaultProperties: {
          merge: true
        }
      }
    },
    PropertyDefinition: {
      keyFields: ['name', 'objectTypeId']
    },
    Pipeline: {
      keyFields: ['pipelineId']
    },
    PipelineStage: {
      keyFields: ['stageId']
    },
    // All objects/types share a global default properties card with `cardId: 0`.
    // To avoid collisions, include objectType and subjectId in the cache key.
    //
    // The same card and cardId (ex: the contacts-to-companies association card)
    // can be queried for different subjects. We don't want to share card data for
    // subject A with card data for subject B, _even for the same cardId_, so include
    // the objectType and subjectId in the cache key.
    //
    // In order to support this, all queries for `CrmRecordCard` must also fetch:
    // ```
    // crmObject {
    //   __typename
    //   id
    // }
    // ```
    CrmRecordCard: {
      keyFields: ['cardId', 'crmObject', ['__typename', 'id']]
    },
    PropertyValue: {
      merge: true
    }
  }
});
export const client = new ApolloClient({
  link: apiLink({
    uri: 'graphql/crm'
  }),
  cache: createCache()
});
export const fetch = operation => {
  return client.query(operation);
};