import { getFromAddress, getSubject, getConnectedAccountAddress } from 'conversations-message-history/common-message-format/operators/emailMetadataGetters';
import { getEmailMetadata } from 'conversations-message-history/common-message-format/operators/getEmailMetadata';
// TODO: move to composer-data
import invariant from 'conversations-thread-data/utils/invariant';
import toJS from 'transmute/toJS';
import { getId, getFileIds, getInReplyToId, getRichText, getPlainText
// @ts-expect-error module not typed
} from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { getTORecipients, getBCCRecipients, getCCRecipients } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
export const getEmailSendDataFromMessage = (emailMessage, options) => {
  const emailMetadata = getEmailMetadata(emailMessage);
  invariant(!!emailMetadata, 'Cannot send email message without metadata');
  const fileIds = getFileIds(emailMessage);
  const {
    templateId,
    associations,
    isForwarded,
    isVisitorAssociatedToThread,
    includeChatHistory,
    alreadyIncludesPreviousReplies
  } = options || {};
  const toAddressesFromRecipients = toJS(getTORecipients(emailMessage));
  const bccAddressesFromRecipients = toJS(getBCCRecipients(emailMessage));
  const ccAddressesFromRecipients = toJS(getCCRecipients(emailMessage));
  const emailMetadataFromAddress = getFromAddress(emailMessage);
  return Object.assign({
    conversationMessageId: getId(emailMessage),
    attachments: fileIds ? fileIds.toArray() : [],
    from: {
      email: emailMetadataFromAddress
    },
    // FIXME
    to: toAddressesFromRecipients,
    bcc: bccAddressesFromRecipients,
    cc: ccAddressesFromRecipients,
    subject: getSubject(emailMessage),
    // FIXME
    inReplyToCmId: getInReplyToId(emailMessage),
    inReplyToDisposition: isForwarded ? 'FORWARD' : 'REPLY',
    html: getRichText(emailMessage),
    plainText: getPlainText(emailMessage),
    connectedAccountAddress: getConnectedAccountAddress(emailMessage),
    // FIXME
    templateId,
    associations: associations || []
  }, !isVisitorAssociatedToThread && {
    associateVisitorRequest: {
      email: toAddressesFromRecipients[0],
      marketable: false
    }
  }, {
    alreadyIncludesPreviousReplies: !!alreadyIncludesPreviousReplies,
    includeChatHistory: !!includeChatHistory,
    isForward: !!isForwarded,
    appendSignature: true
  });
};