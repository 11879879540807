import get from 'transmute/get';
import { HUBSPOT, TWILIO } from 'calling-internal-common/call-provider/constants/ProviderNames';
export const TWILIO_BASED_PROVIDERS = [TWILIO, HUBSPOT];
export function getIsTwilioBasedCallProvider(widgetInfo) {
  if (!widgetInfo) {
    return false;
  }
  const widgetName = get('name', widgetInfo);
  return TWILIO_BASED_PROVIDERS.includes(widgetName);
}
export function getIsProviderHubSpot(widgetInfo) {
  const widgetName = get('name', widgetInfo);
  return widgetName === HUBSPOT;
}