import enviro from 'enviro';
const CONVERSATIONS_DEBUG = 'conversations';
function buildDevLogger(methodName) {
  return function (...args) {
    if (enviro.isQa() || enviro.debug(CONVERSATIONS_DEBUG) || !enviro.deployed()) {
      // eslint-disable-next-line no-console
      console[methodName](...args);
    }
  };
}
export const error = buildDevLogger('error');
export const info = buildDevLogger('info');
export const log = buildDevLogger('log');
export const trace = buildDevLogger('trace');
export const warn = buildDevLogger('warn');