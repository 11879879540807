import { createSelector } from 'reselect';
// @ts-expect-error file not typed
import { getTimestamp } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { getLatestVisitorMessage } from 'conversations-message-history/thread-history/operators/getLatestVisitorMessage';
// @ts-expect-error file not typed
import { getCurrentThreadHistory } from './getCurrentThreadHistory';
const getGenericChannelIdFromProps = (_state, {
  genericChannelId
}) => genericChannelId;
export const getLatestVisitorMessageTimestampByChannelId = createSelector([getCurrentThreadHistory, getGenericChannelIdFromProps], (threadHistory, genericChannelId) => {
  const latestVisitorMessage = getLatestVisitorMessage(threadHistory, genericChannelId);
  return getTimestamp(latestVisitorMessage);
});