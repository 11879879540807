import { Map as ImmutableMap, List as ImmutableList, fromJS } from 'immutable';
import http from 'hub-http/clients/apiClient';
import Raven from 'raven-js';
export const defaultApiWrapper = apiClient => ({
  fetchRenderedContent({
    id,
    contactEmail,
    contactVid,
    objectType,
    subjectId,
    supplementalObjects
  }) {
    const subjectAsSupplementalObject = objectType && subjectId && subjectId !== 'null' && subjectId !== 'undefined' ? {
      [objectType]: subjectId
    } : {};
    return apiClient.post('snippets/v1/render', {
      data: {
        snippetId: id,
        toEmail: contactEmail,
        contactId: contactVid,
        supplementalObjects: Object.assign({}, supplementalObjects, subjectAsSupplementalObject)
      }
    }).catch(error => {
      Raven.captureException(error);
    });
  },
  // @ts-expect-error ts-migrate FIXME
  fetchUnrenderedContent({
    id
  }) {
    return apiClient.get(`snippets/v1/snippets/${id}`);
  },
  // @ts-expect-error ts-migrate FIXME
  searchSnippets({
    count,
    offset,
    query
  }) {
    return apiClient.post('salescontentsearch/v2/search', {
      data: {
        contentTypesToHydrate: ['SNIPPET'],
        filters: [{
          field: 'content_type',
          values: ['SNIPPET']
        }],
        limit: count,
        offset,
        query
      }
    }).then(response => fromJS(response)).catch(error => {
      Raven.captureException(error);
    });
  }
});
export const fetchAllSnippets = () => http.get('snippets/v1/snippets').then(response => ImmutableMap({
  hasMore: false,
  offset: 0,
  count: response.length,
  total: response.length,
  results: ImmutableList(response).map(snippet => fromJS(snippet))
}));
export const searchSnippets = ({
  count,
  offset,
  query
}) => http.post('salescontentsearch/v2/search', {
  data: {
    contentTypesToHydrate: ['SNIPPET'],
    filters: [{
      field: 'content_type',
      values: ['SNIPPET']
    }],
    limit: count,
    offset,
    query
  }
}).then(response => fromJS(response));