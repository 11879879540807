import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { reloadThreadsData } from '../../threads-bulk-update/actions/reloadThreadsData';
import { exitSearchAndFilterMode } from 'conversations-inbox-lib/thread-search-and-filtering/public/actions';
import { useFilterValues, useSortOrder } from 'find-and-filter-data/search-and-filter-data/public';
export const useExitSearchAndFilterMode = () => {
  const dispatch = useDispatch();
  const {
    clearAllFilterValuesAndSearch
  } = useFilterValues();
  const {
    clearSortOrder
  } = useSortOrder();
  const onExitSearchAndFilterMode = useCallback(() => {
    dispatch(exitSearchAndFilterMode());
    clearAllFilterValuesAndSearch();
    clearSortOrder();
    dispatch(reloadThreadsData());
  }, [clearAllFilterValuesAndSearch, dispatch, clearSortOrder]);
  return {
    onExitSearchAndFilterMode
  };
};