import { handleActions } from 'redux-actions';
import { updateEntries } from 'conversations-async-data/indexed-async-data/operators/updateEntries';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { FETCH_AGENTS } from '../constants/asyncActionTypes';

// @ts-expect-error ts-migrate(2348) FIXME: Value of type 'new <Key, AsyncDataValue>(t?: Parti... Remove this comment to see the full error message
const initialState = IndexedAsyncData({
  name: 'agents',
  notSetValue: new AsyncData()
});
const agents = handleActions({
  [FETCH_AGENTS.STARTED](state, action) {
    const {
      agentIds
    } = action.payload.requestArgs;
    return updateEntries(agentIds, (__id, asyncData) => requestStarted(asyncData), state);
  },
  [FETCH_AGENTS.SUCCEEDED](state, action) {
    const {
      data,
      requestArgs
    } = action.payload;
    const {
      agentIds
    } = requestArgs;
    return updateEntries(agentIds, (id, asyncData) => requestSucceededWithOperator(() => data.get(id), asyncData), state);
  },
  [FETCH_AGENTS.FAILED](state, action) {
    const {
      agentIds
    } = action.payload.requestArgs;
    return updateEntries(agentIds, (__id, asyncData) => requestFailed(asyncData), state);
  }
}, initialState);
export default agents;