'use es6';

import { setMessageDeletedStatus, setStatus } from '../../common-message-format/operators/commonMessageFormatSetters';
import Status from '../../common-message-format/records/Status';
import { MESSAGE_DELETED_STATUS, STATUS } from '../constants/updateTypes';
export const updateMessageWithUpdates = (message, updates) => {
  return updates.reduce((updatedMessage, update, updateType) => {
    switch (updateType) {
      case STATUS:
        return setStatus(Status(update), updatedMessage);
      case MESSAGE_DELETED_STATUS:
        return setMessageDeletedStatus(update, updatedMessage);
      default:
        return message;
    }
  }, message);
};