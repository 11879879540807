import { useEffect } from 'react';
export class StaleDataNotifier {
  constructor() {
    this.subscribers = new Set();
  }
  subscribe(callback) {
    this.subscribers.add(callback);
    return () => {
      this.subscribers.delete(callback);
    };
  }
  broadcast(notification) {
    this.subscribers.forEach(cb => cb(notification));
  }
}
export const staleDataNotifier = new StaleDataNotifier();
export function hasMatchingStaleObject({
  objectId,
  objectTypeId,
  notification
}) {
  return ((notification === null || notification === void 0 ? void 0 : notification.objects) || []).some(o => o.objectId === objectId && o.objectTypeId === objectTypeId);
}
export function useStaleDataNotifier(callback) {
  useEffect(() => staleDataNotifier.subscribe(callback), [callback]);
}