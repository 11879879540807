import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchContactsById } from '../clients/fetchContactsByVid';
const fetchContactsByIdQuery = registerQuery({
  fieldName: 'contacts',
  args: ['vids'],
  fetcher: fetchContactsById
});
export function useContactsByVid({
  vids,
  deferred
}) {
  const {
    data,
    loading,
    error
  } = useQuery(fetchContactsByIdQuery, {
    skip: deferred,
    variables: {
      vids
    }
  });
  return {
    contacts: data && data.contacts,
    loading,
    error
  };
}