import PortalIdParser from 'PortalIdParser';
import http from 'conversations-http/clients/http';
const CONVERSATIONS = 50011;

// this interface is the used parts of the response as described in:
// https://tools.hubteam.com/api-catalog/services/EmailApiWeb/v1/spec/internal#operations-Contact_Predict_Drop-put-%2Femail%2Fv1%2Fcontacts%2F%7Bemail%7D%2Fsubscriptions%2Fpredict-drop
export function fetchEmailDeliverabilityStatus({
  emailAddress
}) {
  return http.put(`email/v1/contacts/${encodeURIComponent(emailAddress)}/subscriptions/predict-drop`, {
    data: {
      is_transactional: true,
      portal_id: PortalIdParser.get(),
      app_id: CONVERSATIONS
    }
  });
}