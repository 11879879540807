import { EMAIL_GENERIC_CHANNEL_ID, LIVE_CHAT_GENERIC_CHANNEL_ID, FB_MESSENGER_GENERIC_CHANNEL_ID, WHATSAPP_GENERIC_CHANNEL_ID, FORMS_GENERIC_CHANNEL_ID, CUSTOMER_PORTAL_GENERIC_CHANNEL_ID, COMMENTS_GENERIC_CHANNEL_ID, FEEDBACK_GENERIC_CHANNEL_ID, CALLING_GENERIC_CHANNEL_ID, SMS_GENERIC_CHANNEL_ID } from '../common-message-format/constants/genericChannelIds';
import { THREAD_COMMENT } from '../comment-message/constants/messageTypes';
import { ENGAGEMENT_DETAILS_MESSAGE } from '../engagement-details-message/constants/engagementDetailsMessageType';
/**
 * @description Supported icons to render for a given generic channel ID
 */

/**
 * @description The icon to render for channels we haven't made an intentional
 * decision on.
 */
export const FALLBACK_ICON = 'messages';

/**
 * @description A mapping of GenericChannelIds to their respective Icon value
 * to render.
 */
export const sourceIconsMap = {
  [EMAIL_GENERIC_CHANNEL_ID]: 'email',
  [LIVE_CHAT_GENERIC_CHANNEL_ID]: 'messages',
  [FB_MESSENGER_GENERIC_CHANNEL_ID]: 'socialFacebookMessenger',
  [FORMS_GENERIC_CHANNEL_ID]: 'forms',
  [WHATSAPP_GENERIC_CHANNEL_ID]: 'socialWhatsapp',
  [COMMENTS_GENERIC_CHANNEL_ID]: 'messages',
  [THREAD_COMMENT]: 'messages',
  [ENGAGEMENT_DETAILS_MESSAGE]: 'messages',
  [CUSTOMER_PORTAL_GENERIC_CHANNEL_ID]: FALLBACK_ICON,
  [FEEDBACK_GENERIC_CHANNEL_ID]: FALLBACK_ICON,
  [CALLING_GENERIC_CHANNEL_ID]: 'calling',
  [SMS_GENERIC_CHANNEL_ID]: 'sms'
};