import { getSubject as getCMFSubject, getHasMetadata as getCMFHasMetadata
// @ts-expect-error module not typed
} from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { getTORecipients } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getCommonMessage } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
export const getIsRequiredMetaDataInfoMissing = reply => {
  const commonMessage = getCommonMessage(reply);
  const hasMetadata = getCMFHasMetadata(commonMessage);
  if (hasMetadata) {
    const toRecipients = getTORecipients(commonMessage);
    const subject = getCMFSubject(commonMessage) || '';
    return Boolean(toRecipients && toRecipients.size < 1 || !subject);
  }
  return false;
};