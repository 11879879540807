import { useEffect } from 'react';
import { initMetric } from '../operators/initMetric';
import { stopTimer } from '../operators/stopTimer';
export function useMetricStartTimer(metricName, dimensions) {
  useEffect(() => {
    initMetric(metricName, dimensions);
    return () => {
      stopTimer(metricName, 'incomplete');
    };
  }, [metricName, dimensions]);
}