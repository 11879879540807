export let SendingState;
(function (SendingState) {
  SendingState[SendingState["NEW_CONVERSATION"] = 0] = "NEW_CONVERSATION";
  SendingState[SendingState["OPEN_CONVERSATION_WITHIN_24HR"] = 1] = "OPEN_CONVERSATION_WITHIN_24HR";
  SendingState[SendingState["OPEN_CONVERSATION_PAST_24HR"] = 2] = "OPEN_CONVERSATION_PAST_24HR";
  SendingState[SendingState["CLOSED_CONVERSATION_WITHIN_24HR"] = 3] = "CLOSED_CONVERSATION_WITHIN_24HR";
  SendingState[SendingState["CLOSED_CONVERSATION_PAST_24HR"] = 4] = "CLOSED_CONVERSATION_PAST_24HR";
  SendingState[SendingState["CONTACT_NOT_RESPONDED"] = 5] = "CONTACT_NOT_RESPONDED";
  SendingState[SendingState["SENDER_UNAVAILABLE"] = 6] = "SENDER_UNAVAILABLE";
  SendingState[SendingState["LIMIT_REACHED"] = 7] = "LIMIT_REACHED";
})(SendingState || (SendingState = {}));