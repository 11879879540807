import formatName from 'I18n/utils/formatName';
import { generateUuid } from 'conversations-message-history/util/generateUuid';
import I18n from 'I18n';
import { HS_EMAIL_ADDRESS } from 'conversations-message-history/common-message-format/constants/deliveryIdentifierTypes';
import { EMAIL_GENERIC_CHANNEL_ID, WHATSAPP_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
export const genericChannelIdToIcon = {
  [EMAIL_GENERIC_CHANNEL_ID]: 'email',
  [WHATSAPP_GENERIC_CHANNEL_ID]: 'socialWhatsapp'
};
export function isGroupedGenericOptions(option) {
  return 'options' in option;
}
export const getGenericIdentifierOptions = (genericRecords, {
  clearable = false,
  getValidationData,
  formatter,
  emailBlocklistStatus
} = {}) => {
  const groupedRecords = genericRecords.reduce((groups, item) => {
    if (item.crmObjectId && item.address && item.deliveryIdentifierType && item.deliveryIdentifierValue) {
      return Object.assign({}, groups, {
        [item.crmObjectId]: [...(groups[item.crmObjectId] || []), item]
      });
    } else {
      const uuid = generateUuid();
      return Object.assign({}, groups, {
        [uuid]: [...(groups[uuid] || []), item]
      });
    }
  }, {});
  const finalOptions = [];
  for (const [__key, records] of Object.entries(groupedRecords)) {
    if (records.length) {
      const firstName = records[0].get('firstName');
      const lastName = records[0].get('lastName');
      const name = formatName({
        firstName,
        lastName
      });
      const groupedOption = {
        text: name || `<${I18n.text('composer-ui.metadata-editor.generic-recipient-search-input.noNameContact')}>`,
        options: []
      };
      records.forEach(genericRecord => {
        const address = genericRecord.get('address');
        const optionValue = genericRecord.deliveryIdentifierValue || address;
        if (optionValue) {
          const eachOption = {
            name,
            address: optionValue,
            text: `${name} ${optionValue}`,
            dropdownText: optionValue,
            value: optionValue,
            clearableValue: clearable,
            icon: genericRecord.genericChannelId ? genericChannelIdToIcon[genericRecord.genericChannelId] : undefined,
            isEmailLoggingBlocked: emailBlocklistStatus === null || emailBlocklistStatus === void 0 ? void 0 : emailBlocklistStatus[optionValue]
          };
          if (getValidationData) {
            const validationData = getValidationData(optionValue, genericRecord.deliveryIdentifierType || HS_EMAIL_ADDRESS);
            eachOption.validationData = validationData;
            eachOption.tagUse = !validationData.isValid ? 'danger' : 'default';
          }
          if (formatter) {
            eachOption.dropdownText = formatter(optionValue, genericRecord.deliveryIdentifierType || HS_EMAIL_ADDRESS);
          }
          groupedOption.options.push(eachOption);
        }
      });
      finalOptions.push(groupedOption);
    }
  }
  return finalOptions;
};