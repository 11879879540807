import I18n from 'I18n';
import { getMetadataSubject } from 'conversations-message-history/common-message-format/operators/emailMetadataGetters';
import get from 'transmute/get';
//TODO support generic view members
export function getDisplaySubjectLine(threadListMember) {
  const latestMessagePreview = get('latestMessagePreview', threadListMember);
  if (latestMessagePreview) {
    const emailMetadata = get('emailMetadata', latestMessagePreview);
    if (emailMetadata) {
      const emailMetadataSubject = emailMetadata && getMetadataSubject(emailMetadata);
      return emailMetadataSubject || I18n.text('find-and-filter-data.view-members.noEmailSubject');
    }
  }
  return null;
}