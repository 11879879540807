export const BLACK_LIST = 'BLACK_LIST'; // Blocklist rule (“always filter emails from…”)
export const AUTOMATED_RESPONSE_EMAIL = 'AUTOMATED_RESPONSE_EMAIL';
export const MARKETING_EMAIL = 'MARKETING_EMAIL';
export const ROLE_BASED_EMAIL_ADDRESS = 'ROLE_BASED_EMAIL_ADDRESS';
export const SUBSCRIPTION_EMAIL = 'SUBSCRIPTION_EMAIL';
export const EMAIL_TO_SELF = 'EMAIL_TO_SELF';
export const HUBSPOT_CONFIG_FILTERED_ADDRESS = 'HUBSPOT_CONFIG_FILTERED_ADDRESS'; // emails sent from noreply@hubspot.com rule
export const HUBSPOT_EMAIL = 'HUBSPOT_EMAIL'; // marketing emails sent from the same portal rule
export const PROVIDER_MARKED_SPAM = 'PROVIDER_MARKED_SPAM';
export const HUBSPOT_NOTIFICATION_EMAIL = 'HUBSPOT_NOTIFICATION_EMAIL';
export const providerRules = [PROVIDER_MARKED_SPAM];
export const autoNonConfigurableRules = [EMAIL_TO_SELF, HUBSPOT_CONFIG_FILTERED_ADDRESS, HUBSPOT_EMAIL, HUBSPOT_NOTIFICATION_EMAIL];
export const manualConfigurableRules = [BLACK_LIST];
export const autoConfigurableRules = [AUTOMATED_RESPONSE_EMAIL, MARKETING_EMAIL, ROLE_BASED_EMAIL_ADDRESS, SUBSCRIPTION_EMAIL];