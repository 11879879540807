/**
 * Unsubscribe and subscribe in a transaction
 *
 * @param {Object} options
 * @param {Object} options.vendor vendor api wrapper
 * @param {Object<String, Object>} options.toAdd subscriptions to add.
 * Mapping of channel IDs to callback objects.
 * @param {Object<String, Object>} options.toRemove subscriptions to remove
 * Mapping of channel IDs to callback objects.
 * @returns {Promise}
 *
 */
export const transactSubscriptions = ({
  vendor,
  toAdd = {},
  toRemove = {},
  reauthorize = () => {}
}) => {
  return Promise.all(Object.keys(toRemove).map(channelId => {
    const {
      onMessage
    } = toRemove[channelId];
    return vendor.unsubscribe({
      channelId,
      onMessage
    });
  }))
  /* ABLY TODO: Remove call to reauthorize when
   * wildcard auth is supported in the visitor widget */.then(() => reauthorize(Object.keys(toAdd))).then(() => Promise.all(Object.keys(toAdd).map(channelId => {
    const {
      onMessage,
      onPlayback
    } = toAdd[channelId];
    return vendor.subscribe({
      channelId,
      playbackMessages: true,
      onMessage,
      onPlayback
    });
  })));
};