import http from 'conversations-http/clients/http';
import { Map as ImmutableMap } from 'immutable';
export function indexContactPermissionsFromResponse(contactPermissions = {
  resultsByEmail: {
    hits: {},
    misses: []
  }
}) {
  const {
    hits,
    misses
  } = contactPermissions.resultsByEmail;
  const result = Object.keys(hits).reduce((acc, emailAddress) => {
    const {
      canCommunicate,
      canDelete
    } = hits[emailAddress];
    return acc.set(emailAddress, {
      canCommunicate: !!canCommunicate,
      canDelete: !!canDelete
    });
  }, ImmutableMap());

  // User can communicate with all of the manually entered emails w/o contact
  // User should not be able to delete contacts by default
  return misses.reduce((acc, emailAddress) => acc.set(emailAddress, {
    canCommunicate: true,
    canDelete: false
  }), result);
}
export function fetchContactPermissions({
  emailAddresses
}) {
  return http.post('crm-permissions/v1/check-permissions/emails', {
    data: {
      emails: emailAddresses
    }
  }).then(indexContactPermissionsFromResponse);
}