import { createBrowserHistory } from 'history';
import invariant from 'react-utils/invariant';
import { once } from '../../lib/hsUnderscore';
/**
 * Becaause of the `once` wrapping the default export,
 * we need a named export to test against (since that will
 * prevent anything past a single call...)
 */
export const HistorySingletonTestExportDoNotUse = () => {
  let history;
  let urlStack;
  const init = () => {
    history = createBrowserHistory();
    urlStack = [history.location.pathname];

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'location' implicitly has an 'any' type.
    history.listen((location, action) => {
      const {
        pathname
      } = location;
      if (action === 'PUSH') {
        urlStack = [pathname, ...urlStack];
      } else if (action === 'REPLACE') {
        urlStack[0] = pathname;
      } else if (action === 'POP') {
        // backStack should never be an empty array. This would
        // indicate that there is no 'current' path
        invariant(urlStack.length, 'history is broken. Pathname stack is somehow empty.');
        const [prevUrl, ...backStack] = urlStack;
        // going back
        if (backStack.length && backStack[0] === pathname) {
          urlStack = backStack;
        } else {
          // going forward
          urlStack = [pathname, prevUrl, ...backStack];
        }
      }
      try {
        if (window.localStorage.getItem('ROUTING_DEBUG')) {
          // eslint-disable-next-line no-console
          console.log('ROUTING_DEBUG', `[${action}]`, location);
        }
      } catch (error) {
        //do nothing
      }
    });
  };
  init();
  return {
    instance() {
      return history;
    },
    resetWithUrl(initialUrl) {
      history.go(-history.length);
      history.replace(initialUrl);
      urlStack = [history.location.pathname];
    },
    getBackSize() {
      return urlStack.length - 1;
    }
  };
};

/**
 * @description a singleton representing the browser history.
 * The extra tracking using a stack is to figure out whether
 * there's previous history to go back to, since the 'history'
 * BrowserHistory API doesn't expose such information.
 */
const HistorySingleton = once(HistorySingletonTestExportDoNotUse);
export default HistorySingleton;