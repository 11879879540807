import { getApolloContext, useMutation, useQuery } from '@apollo/client';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import { useContext, useCallback } from 'react';
import { FetchTicketQuery, updateTicket } from './updateTicket';
import { updateTicketForOptimisticResponse } from './updateTicketForOptimisticResponse';
import { apolloClientInvariant } from 'conversations-thread-data/graphql/invariant/apolloClientInvariant';
import I18n from 'I18n';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
export const useUpdateTicketProperties = ({
  ticketId
}) => {
  const apolloClientFromProvider = useContext(getApolloContext()).client;
  apolloClientInvariant(apolloClientFromProvider);
  const objectType = 'TICKET';
  const [handleUpdateCrmObjectProperties] = useMutation(updateTicket, {
    context: {
      hubHttpOptions: {
        headers: {
          'X-Properties-Source': 'CONVERSATIONS'
        }
      }
    },
    onError: error => {
      reportError({
        error
      });
    }
  });
  const {
    data
  } = useQuery(FetchTicketQuery, {
    variables: {
      ticketId
    },
    client: apolloClientFromProvider,
    notifyOnNetworkStatusChange: true,
    skip: !apolloClientFromProvider || !ticketId
  });
  const updateTicketProperties = useCallback(async ({
    onError,
    onSuccess,
    properties
  }) => {
    if (!ticketId) {
      const error = `Missing objectId`;
      if (typeof onError === 'function') onError(error);
      return Promise.reject(new Error(error));
    }
    let newObject = undefined;
    if (data && data.crmObject) {
      newObject = updateTicketForOptimisticResponse({
        ticket: data.crmObject,
        properties
      });
    }
    const responseData = await handleUpdateCrmObjectProperties({
      variables: {
        propertyUpdatesInput: {
          objectType,
          objectId: ticketId,
          properties
        }
      },
      optimisticResponse: newObject ? {
        updateResponse: {
          updatedObject: newObject,
          userErrors: []
        }
      } : undefined,
      onCompleted: onSuccess,
      onError: error => {
        if (typeof onError === 'function') {
          onError(error.message);
        }
      }
    });
    if (responseData.errors) {
      FloatingAlertStore.addAlert({
        message: I18n.text('conversations-inbox-lib.ticketStatus.saveFailure'),
        type: 'danger'
      });
    }
    return responseData;
  }, [handleUpdateCrmObjectProperties, objectType, ticketId, data]);
  return {
    updateTicketProperties
  };
};