import get from 'transmute/get';
import { ASSIGNED_TO_BOT, HIDE_FILTERED, STATUS, ASSIGNEE, START_TIME, END_TIME, DATE_TYPE, UNASSIGNED, VID, SEARCH, HIDE_TRASH, GENERIC_CHANNEL } from '../constants/FilterKeys';
export const getStatus = get(STATUS);
export const getGenericChannel = get(GENERIC_CHANNEL);
export const getAssignee = get(ASSIGNEE);
export const getStartTime = get(START_TIME);
export const getEndTime = get(END_TIME);
export const getDateType = get(DATE_TYPE);
export const getUnassigned = get(UNASSIGNED);
export const getVid = get(VID);
export const getSearch = get(SEARCH);
export const getHideTrash = get(HIDE_TRASH);
export const getAssignedToBot = get(ASSIGNED_TO_BOT);
export const getHideFiltered = get(HIDE_FILTERED);