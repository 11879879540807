import { Record } from 'immutable';
class LoadingState extends Record({
  isLoadingEscalation: false,
  isLoadingEscalationMeta: false
}, 'LoadingState') {
  get isLoading() {
    return this.isLoadingEscalation || this.isLoadingEscalationMeta;
  }
}
LoadingState.fromJS = obj => new LoadingState(Object.assign({}, obj));
export default LoadingState;