'use es6';

import * as InstallStatusAPI from 'ui-addon-scriptloader/installStatus/InstallStatusAPI';
import InstallStatusStore from 'ui-addon-scriptloader/installStatus/InstallStatusStore';
import { PENDING } from 'ui-addon-scriptloader/installStatus/InstallStatusConstants';
import defer from 'hs-promise-utils/defer';
import { INSTALL_TYPE } from './InstallStatusConstants';
let deferred = defer();
export function fetchInstallStatus(installType = INSTALL_TYPE.BOTH) {
  const currentStatus = InstallStatusStore.get();
  if (currentStatus === PENDING) {
    return deferred.promise;
  }
  InstallStatusStore.set(PENDING);
  deferred = defer();
  InstallStatusAPI.fetchInstallStatus(installType).then(newStatus => {
    InstallStatusStore.set(newStatus);
    deferred.resolve({
      success: true
    });
  }).catch(() => {
    deferred.resolve({
      success: false
    });
  });
  return deferred.promise;
}