'use es6';

import { OrderedMap } from 'immutable';
import { CONTACT_TYPE_ID, COMPANY_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { getObjectId, getObjectTypeId } from './calleesOperators';
export const createPropertyKey = ({
  objectTypeId,
  objectId
}) => `${objectTypeId}_${objectId}`;
export const getRequiredCalleeProperties = ({
  callableObjects,
  propertyKeySeq,
  searchResults = OrderedMap()
}) => {
  return callableObjects.merge(searchResults).reduce((acc, object) => {
    const objectTypeId = getObjectTypeId(object);
    const objectId = getObjectId(object);
    const key = createPropertyKey({
      objectTypeId,
      objectId
    });
    if (propertyKeySeq.includes(key)) {
      return acc;
    }
    if (objectTypeId === COMPANY_TYPE_ID) {
      acc.companyIds.push(getObjectId(object));
    } else if (objectTypeId === CONTACT_TYPE_ID) {
      acc.contactIds.push(getObjectId(object));
    }
    acc.keys.push(key);
    return acc;
  }, {
    contactIds: [],
    companyIds: [],
    keys: []
  });
};