import tracker from '../usageTracker';
class QualifiedLeadSidebarCardTracker {
  constructor() {
    this.applicationName = '';
    this.leadContactVid = 0;
  }
  viewFullHistory() {
    tracker.track('qualifiedLeadsSidebarCardView', {
      action: 'view-full-history',
      applicationName: this.applicationName,
      leadContactVid: this.leadContactVid
    });
  }
}
export default new QualifiedLeadSidebarCardTracker();