import { OrderedMap, Record } from 'immutable';
import { SortableOffset } from './Offset';
export const HAS_MORE = 'hasMore';
export const MEMBERS = 'indexedMembers';
export const PAGE_OFFSET = 'offset';
export const TOTAL_COUNT = 'totalCount';
export const OFFSET_TIMESTAMP = ['offset', 'offsetTimestamp'];
export const OFFSET_ID = ['offset', 'offsetId'];
export const LAST_APPLIED_UPDATE_TIMESTAMP = 'latestAppliedPlaybackMessageTimestamp';
export class HelpDeskViewMembersPage extends Record({
  hasMore: false,
  indexedMembers: OrderedMap(),
  offset: SortableOffset(),
  totalCount: 0,
  latestAppliedPlaybackMessageTimestamp: 0
}, 'HelpDeskViewMembersPage') {
  constructor(properties = {}) {
    super(Object.assign({}, properties, {
      offset: SortableOffset(properties.offset)
    }));
  }
}
export default HelpDeskViewMembersPage;