'use es6';

import { createSelector } from 'reselect';
import { AGENT, BOT } from 'conversations-message-history/common-message-format/constants/legacySenderTypes';
import { getSenderType } from 'conversations-message-history/senders/operators/senderLocatorGetters';
import { getIndexedAsyncSenders } from './getIndexedAsyncSenders';
const getSenderLocatorsFromProps = (state, {
  senderLocators
}) => senderLocators;
export const makeGetIndexedAsyncAgentBotSenders = () => {
  return createSelector([getIndexedAsyncSenders, getSenderLocatorsFromProps], (senders, senderLocators) => {
    return senders.updateIn(['entries'], dataEntries => {
      return dataEntries.filter((_, senderLocator) => (getSenderType(senderLocator) === AGENT || getSenderType(senderLocator) === BOT) && senderLocators.has(senderLocator));
    });
  });
};