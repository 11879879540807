import { useEffect, useState } from 'react';
import { fetchConnectedInboxes } from '../api/ConnectedInboxesAPI';
import { getUserInfo } from '../api/UserAPI';
import { getInvoiceSettings } from '../api/SettingsAPI';
import { fetchConnectedAccounts } from '../api/ConnectedAccountsAPI';
import { getPersonalEmailFromValues, getTeamFromValues } from '../utils/invoiceEmail';
const getSorterFromValues = email => fromValue => fromValue.email.toLowerCase() === email.toLowerCase() && !fromValue.disabled ? -1 : 0;
const useFromValues = onError => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFromValue, setSelectedFromValue] = useState(null);
  const [invoiceSettings, setInvoiceSettings] = useState(null);
  const [fromValues, setFromValues] = useState([]);
  const allowOtherEmails = !!invoiceSettings && invoiceSettings.allowOtherEmail;
  const defaultEmailSetting = invoiceSettings && invoiceSettings.defaultEmail;
  useEffect(() => {
    Promise.all([getUserInfo(), fetchConnectedInboxes(), fetchConnectedAccounts(), getInvoiceSettings()]).then(([{
      email: userEmail
    }, connectedInboxResponse, connectedAccountsResponse, invoiceSettingsResponse]) => {
      const defaultEmail = invoiceSettingsResponse.defaultEmail;
      const personalEmailFromValues = getPersonalEmailFromValues(connectedInboxResponse.connectedInboxes);
      const teamEmailFromValues = getTeamFromValues(connectedAccountsResponse, defaultEmail);
      const derivedFromValues = [...personalEmailFromValues, ...teamEmailFromValues].sort(getSorterFromValues(userEmail));
      if (invoiceSettingsResponse.defaultEmail) {
        const defaultEmailFromValue = derivedFromValues.find(fromValue => fromValue.accountId === defaultEmail);
        setSelectedFromValue(defaultEmailFromValue || {
          email: defaultEmail,
          disabled: true,
          shared: true,
          conversationsInboxName: null,
          accountId: defaultEmail
        });
      } else {
        const defaultInitialFromValue = derivedFromValues[0] || {
          email: userEmail,
          disabled: true,
          shared: false,
          conversationsInboxName: null
        };
        setSelectedFromValue(defaultInitialFromValue);
      }
      setFromValues(derivedFromValues);
      setInvoiceSettings(invoiceSettingsResponse);
    }).catch(error => onError({
      error
    })).finally(() => setIsLoading(false));
  }, [onError]);
  return {
    isLoading,
    fromValues,
    selectedFromValue,
    setSelectedFromValue,
    allowOtherEmails,
    defaultEmail: defaultEmailSetting
  };
};
export default useFromValues;