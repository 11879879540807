import { useEffect, useMemo } from 'react';
import PortalIdParser from 'PortalIdParser';
import { useFetchUserInfo } from 'conversations-thread-data/auth/public/hooks';
import { getFriendlySenderName } from '../operators/getFriendlySenderName';
import { getFriendlyNameRecordForSender } from '../operators/getFriendlyNameRecordForSender';
import { findIndividualSenderPojo } from 'conversations-message-history/common-message-format/operators/findIndividualSenderPojo';
import { GENERIC_SENDER_NAME_FIELD, CONTACT } from 'conversations-message-history/friendly-names/constants/friendlyNameResolutionSources';
import { useLiveFriendlyName } from './useLiveFriendlyName';
import { useFetchContactsByVid } from 'conversations-thread-data/contact-fetching/hooks/useFetchContacts';
import { INCOMING } from 'conversations-message-history/common-message-format/constants/messageDirections';
import { useDispatch } from 'react-redux';
import { updateFriendlyNames } from 'conversations-thread-data/friendly-names/public/actions';
export const useFriendlyNameForMessage = ({
  friendlyNames,
  message,
  threadId,
  vid
}) => {
  const portalId = PortalIdParser.get();
  const {
    loading: isLoading,
    error
  } = useFetchContactsByVid({
    vid,
    portalId,
    skip: vid === undefined
  });
  const isDeletedContact = error !== undefined;
  const senders = message && message.senders && message.senders.toJS();
  const genericChannelId = message && message.genericChannelId;
  const sender = senders && findIndividualSenderPojo(senders);
  const friendlyData = getFriendlyNameRecordForSender({
    friendlyNames,
    genericChannelId,
    sender
  });
  const {
    data: auth
  } = useFetchUserInfo();
  const friendlyName = getFriendlySenderName({
    auth: auth && auth.userInfo,
    friendlyData,
    sender
  });
  const messageId = message && message.id || '';
  const shouldFetchFriendlyNames = Boolean(messageId && !friendlyData);
  const {
    data: newFriendlyNames
  } = useLiveFriendlyName({
    messageId,
    shouldFetch: shouldFetchFriendlyNames,
    threadId
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (newFriendlyNames && newFriendlyNames.length) {
      dispatch(updateFriendlyNames({
        newFriendlyNames,
        threadId
      }));
    }
  }, [newFriendlyNames, dispatch, threadId]);
  const resolutionSource = friendlyData && friendlyData.resolutionSource;
  const isNamedUnknownVisitor = resolutionSource === GENERIC_SENDER_NAME_FIELD;
  const isUnknownVisitor = message && message.direction === INCOMING && resolutionSource !== CONTACT;
  return useMemo(() => ({
    isLoading,
    friendlyName,
    isNamedUnknownVisitor,
    isUnknownVisitor,
    isDeletedContact
  }), [isLoading, friendlyName, isNamedUnknownVisitor, isUnknownVisitor, isDeletedContact]);
};