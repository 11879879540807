'use es6';

import curry from 'transmute/curry';
import pipe from 'transmute/pipe';
import { threadHistoryInvariant } from '../invariants/threadHistoryInvariant';
import { applyDeletesToThreadHistory } from './applyDeletesToThreadHistory';
import { applyUpdateToThreadHistory } from './applyUpdateToThreadHistory';
export const applyMessageUpdatesToThreadHistory = curry((updates, threadHistory) => {
  threadHistoryInvariant(threadHistory);
  return pipe(applyDeletesToThreadHistory(updates), applyUpdateToThreadHistory(updates))(threadHistory);
});