import { handleActions } from 'redux-actions';
import ErrorState from '../records/ErrorState';
import { CLEAR_ERRORS, SAVE_ESCALATION } from '../actions/ActionTypes';
export default handleActions({
  [CLEAR_ERRORS]: () => new ErrorState(),
  [SAVE_ESCALATION]: (state, {
    error,
    payload
  }) => error ? state.merge({
    hasConflictError: state.hasConflictError || payload.status === 409,
    hasUnknownError: state.hasUnknownError || payload.status !== 409
  }) : state
}, new ErrorState());