import { getAttachments, getCommonMessage } from './commonReplyGetters';
import { removeFileIdFromMessage } from '../../_internal/operators/removeFileIdFromMessage';
import { setCommonMessage, setFileAttachments
// @ts-expect-error module not typed
} from './commonReplySetters';
export const removeAttachmentFromCommonReply = ({
  fileId,
  commonReply
}) => {
  const attachments = getAttachments(commonReply);
  const nextAttachments = attachments.delete(attachments.findIndex(attachment => Boolean(attachment && attachment.get('id') === fileId)));
  const currentMessage = getCommonMessage(commonReply);
  const nextMessage = removeFileIdFromMessage(fileId, currentMessage);
  const replyWithMessage = setCommonMessage(nextMessage, commonReply);
  return setFileAttachments(nextAttachments, replyWithMessage);
};