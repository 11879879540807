'use es6';

import DeliveryIdentifier from '../records/DeliveryIdentifier';
export const buildDeliveryIdentifier = ({
  deliveryIdentifierType,
  deliveryIdentifierValue
}) => {
  return DeliveryIdentifier({
    type: deliveryIdentifierType,
    value: deliveryIdentifierValue
  });
};