// @ts-expect-error module not typed
import { usersToOptionsWithFreeDisabledAndHiddenDeletedUsers } from 'conversations-internal-schema/sales-users/operators/usersToOptionsWithFreeDisabledAndHiddenDeletedUsers';
import { List } from 'immutable';
import { buildSalesTeamsMap } from 'routing-ui/team/operators/buildSalesTeamMap';
import { teamTreeToOptions } from 'routing-ui/team/operators/teamTreeToOptions';
import { UserInfo as UserInfoRecord } from 'ui-addon-conversations-settings/auth/records/UserInfo';
import { RoutingTeam } from 'routing-ui/users-and-teams/records/Team';
export const buildRequiredRoutingRulesPropsFromQueries = ({
  isLoadingUsersAndTeams,
  onRuleUpdate,
  routingRules,
  teamUsersResponse,
  userInfoResponse,
  hierarchyResponse = [],
  isUngatedForHelpDeskRoutingScopeFiltering
}) => {
  const baseProps = {
    currentUser: userInfoResponse ? UserInfoRecord(userInfoResponse.user) : UserInfoRecord(),
    isLoadingUsersAndTeams,
    hasRoutingAccess: true,
    onRuleUpdate,
    routingRules
  };
  const teamTree = buildSalesTeamsMap(hierarchyResponse, team => new RoutingTeam(team));
  const teams = teamUsersResponse ? teamTreeToOptions(teamTree, isUngatedForHelpDeskRoutingScopeFiltering) : [];
  const visibleUsers = teamUsersResponse ? teamUsersResponse.filter(user => !user.hidden) : [];
  const users = usersToOptionsWithFreeDisabledAndHiddenDeletedUsers(List(visibleUsers));
  return Object.assign({}, baseProps, {
    teamTree,
    teams,
    users
  });
};