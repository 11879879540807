module.exports = {
  "watch-video": {
    "properties": {
      "action": [
        "watch video"
      ],
      "channel": "string"
    },
    "namespace": "conversations",
    "class": "interaction",
    "name": "thread-interaction"
  },
  "listen-to-audio": {
    "properties": {
      "action": [
        "listen to audio"
      ],
      "channel": "string"
    },
    "namespace": "conversations",
    "class": "interaction",
    "name": "thread-interaction"
  },
  "click-attachment": {
    "properties": {
      "action": [
        "click attachment"
      ],
      "channel": "string"
    },
    "namespace": "conversations",
    "class": "interaction",
    "name": "thread-interaction"
  },
  "download-image": {
    "properties": {
      "action": [
        "download image"
      ],
      "channel": "string"
    },
    "namespace": "conversations",
    "class": "interaction",
    "name": "thread-interaction"
  },
  "download-audio": {
    "properties": {
      "action": [
        "download audio"
      ],
      "channel": "string"
    },
    "namespace": "conversations",
    "class": "interaction",
    "name": "thread-interaction"
  },
  "download-video": {
    "properties": {
      "action": [
        "download video"
      ],
      "channel": "string"
    },
    "namespace": "conversations",
    "class": "interaction",
    "name": "thread-interaction"
  },
  "copy-message": {
    "properties": {
      "action": [
        "copy message"
      ],
      "channel": "string"
    },
    "namespace": "conversations",
    "class": "interaction",
    "name": "thread-interaction"
  },
  "message-reply": {
    "properties": {
      "action": [
        "message reply"
      ],
      "channel": "string"
    },
    "namespace": "conversations",
    "class": "interaction",
    "name": "thread-interaction"
  },
  "message-reply-all": {
    "properties": {
      "action": [
        "message reply all"
      ],
      "channel": "string"
    },
    "namespace": "conversations",
    "class": "interaction",
    "name": "thread-interaction"
  },
  "message-forward": {
    "properties": {
      "action": [
        "message forward"
      ],
      "channel": "string"
    },
    "namespace": "conversations",
    "class": "interaction",
    "name": "thread-interaction"
  },
  "message-hide": {
    "properties": {
      "action": [
        "message hide"
      ],
      "channel": "string"
    },
    "namespace": "conversations",
    "class": "interaction",
    "name": "thread-interaction"
  },
  "message-unhide": {
    "properties": {
      "action": [
        "message unhide"
      ],
      "channel": "string"
    },
    "namespace": "conversations",
    "class": "interaction",
    "name": "thread-interaction"
  },
  "view-message-actions": {
    "properties": {
      "action": [
        "view message actions"
      ],
      "channel": "string"
    },
    "namespace": "conversations",
    "class": "interaction",
    "name": "thread-interaction"
  },
  "contact-promotion": {
    "properties": {
      "action": [
        "contact promotion button clicked",
        "completion of step one in the flow",
        "completion of step two in the flow - new contact created",
        "completion of step two in the flow - user reviewed existing contact"
      ],
      "channel": "string"
    },
    "namespace": "conversations",
    "class": "interaction",
    "name": "thread-interaction"
  }
};