export const ASSIGNED_TO_ME = 'ASSIGNED_TO_ME';
export const FORMS = 'FORMS';
export const ALL_OPEN = 'ALL_CONVERSATIONS';
export const UNASSIGNED = 'UNASSIGNED';
export const ASSIGNED_TO_BOT = 'ASSIGNED_TO_BOT';
export const CHAT = 'CHAT';
export const EMAIL = 'EMAIL';
export const MESSENGER = 'MESSENGER';
export const FILTERED = 'FILTERED';
export const TRASH = 'TRASH';
export const TICKET = 'TICKET';
export const SENT = 'SENT';