import { useReduxAsyncFetcher } from 'conversations-async-data/async-data/hooks/useReduxAsyncFetcher';
import { fetchFilterRulesByThreadId } from '../actions/fetchFilterRulesByThreadId';
import { getAsyncFilterRulesByThreadId } from '../selectors/getAsyncFilterRulesByThreadId';
export const useFilterRules = ({
  threadId
}) => {
  const {
    asyncData
  } = useReduxAsyncFetcher({
    action: fetchFilterRulesByThreadId,
    selector: getAsyncFilterRulesByThreadId,
    id: threadId,
    idTransform: id => ({
      threadId: id
    })
  });
  return {
    asyncFilterRules: asyncData
  };
};