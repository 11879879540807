import { getOrCreateUniversalCallingStore } from 'calling-global-api/universal-calling-store/UniversalCallingStore';
import { AsyncStatusType } from 'calling-lifecycle-internal/utils/types/AsyncData';
const parseRawState = state => {
  return state;
};
const initialState = {
  asyncCallingAccessScopes: {
    status: AsyncStatusType.UNINITIALIZED,
    data: {
      hasCallingAccessScope: false,
      hasJitaUserScope: false
    },
    error: null
  },
  asyncCallingAccessVerification: {
    status: AsyncStatusType.UNINITIALIZED,
    data: {
      appealState: null,
      canAppeal: false,
      suspended: false
    },
    error: null
  },
  hasAppealed: false
};
const getOrCreateCallingAccessStore = () => {
  // return the created store with version
  return getOrCreateUniversalCallingStore({
    name: 'callingAccess',
    initialState,
    parseRawState,
    version: 1,
    eventListeners: []
  });
};
export default getOrCreateCallingAccessStore;