import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { FETCH_CURRENT_CRM_OWNER } from '../constants/asyncActionTypes';
const initialState = new AsyncData({
  data: null
});
export default handleActions({
  [FETCH_CURRENT_CRM_OWNER.STARTED](state) {
    return requestStarted(state);
  },
  [FETCH_CURRENT_CRM_OWNER.SUCCEEDED](state, action) {
    const {
      data: ownerRecord
    } = action.payload;
    return requestSucceededWithOperator(() => ownerRecord, state);
  },
  [FETCH_CURRENT_CRM_OWNER.FAILED](state) {
    return requestFailed(state);
  }
}, initialState);