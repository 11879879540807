import tracker from '../../shared/utils/usageTracker';
import { convertToTrackingFields } from './EscalationFieldsConverter';
class EscalationsTracker {
  constructor() {
    this.applicationName = '';
    this.conversationId = 0;
    this.ticketId = 0;
  }
  openPanel() {
    this._trackInteraction('open-escalation-panel');
  }
  closePanel({
    isCancelled = false
  }) {
    if (isCancelled) {
      this._trackInteraction('cancel-escalation');
    } else {
      this._trackInteraction('close-escalation-panel');
    }
  }
  submitEscalation(escalation) {
    this._trackUsage('submit-escalation', convertToTrackingFields(escalation));
  }
  _trackInteraction(action) {
    tracker.track('realtimeRepsEscalationsPanelInteraction', {
      action,
      applicationName: this.applicationName,
      conversationId: this.conversationId,
      ticketId: this.ticketId
    });
  }
  _trackUsage(action, data = {}) {
    tracker.track('realtimeRepsEscalationsPanelUsage', Object.assign({
      action,
      applicationName: this.applicationName,
      conversationId: this.conversationId,
      ticketId: this.ticketId
    }, data));
  }
}
export default new EscalationsTracker();