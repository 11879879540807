import http from 'conversations-http/clients/http';
import { fetchContactByVid } from '../../contacts/clients/fetchContactClients';
const fetchThreadListMemberDetails = ({
  threadId
}) => {
  const url = `conversations-threads/v1/threads/${threadId}`;
  return http.get(url, {
    query: {
      allowDeleted: true
    }
  });
};
export const getContact = ({
  threadId
}) => fetchThreadListMemberDetails({
  threadId
}).then(({
  vid
}) => fetchContactByVid(vid));