export const Triggers = {
  MANUAL: 'manual',
  WORKING_HOURS: 'workingHours',
  OUT_OF_OFFICE: 'outOfOffice'
};
export const getToggledAwayMode = ({
  awayMode,
  trigger
}) => ({
  key: 'toggled-away-mode',
  properties: {
    action: 'toggled away mode',
    awayMode: awayMode ? 'on' : 'off',
    trigger
  }
});
export const clickedConfirmOnAgentStatusPrompt = () => ({
  key: 'clicked-agent-status-prompt-modal',
  properties: {
    action: 'clicked on "Set myself to available" button'
  }
});
export const clickedCancelOnAgentStatusPrompt = () => ({
  key: 'clicked-agent-status-prompt-modal',
  properties: {
    action: 'clicked on "Cancel" button'
  }
});