/* hs-eslint ignored failing-rules */
/* eslint-disable react-hooks/exhaustive-deps */

'use es6';

import { useEffect } from 'react';

/**
 * the equivelant of class components' componentDidMount but as a hook to be used by functional components
 *
 * passing in an empty array causes us to only call it on mount and unmount
 * for more info see the react docs on useEffect https:reactjs.org/docs/hooks-effect.html
 * usually passing in an empty array causes an eslint error, so it's been abstracted here so
 * no eslint ignores were added in, see https:git.hubteam.com/HubSpot/customer-data-sidebar/pull/758#discussion_r920947
 */

const useComponentDidMount = onMount => {
  useEffect(onMount, []);
};
export default useComponentDidMount;