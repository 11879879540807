import { fireCustomEvent } from 'shared-worker-versioning/embed-utility/connection/utils/fireCustomEvent';
export const CALLING_CROSS_TAB_DEPENDENCIES_LOADED = 'CALLING_CROSS_TAB_DEPENDENCIES_LOADED';
export const CALLING_CROSS_TAB_DEPENDENCIES_FAILED = 'CALLING_CROSS_TAB_DEPENDENCIES_FAILED';
export const triggerAsyncFailedEvent = () => {
  fireCustomEvent(CALLING_CROSS_TAB_DEPENDENCIES_FAILED, window);
};
export const triggerAsyncLoadEvent = () => {
  fireCustomEvent(CALLING_CROSS_TAB_DEPENDENCIES_LOADED, window);
};
export const addAsyncFailedEventListener = cb => {
  window.addEventListener(CALLING_CROSS_TAB_DEPENDENCIES_FAILED, cb);
};
export const removeAsyncFailedEventListener = cb => {
  window.removeEventListener(CALLING_CROSS_TAB_DEPENDENCIES_FAILED, cb);
};
export const addAsyncLoadEventListener = cb => {
  window.addEventListener(CALLING_CROSS_TAB_DEPENDENCIES_LOADED, cb);
};
export const removeAsyncLoadEventListener = cb => {
  window.removeEventListener(CALLING_CROSS_TAB_DEPENDENCIES_LOADED, cb);
};