'use es6';

import getIn from 'transmute/getIn';
import { AUDIT, FILTERED_CHANGE_INFO, IS_FILTERED, FILTER_TYPE } from '../constants/keyPaths';
import { getAgentIdFromAudit } from '../../audit/operators/getAgentIdFromAudit';
export const getAudit = getIn(AUDIT);
export const getFilteredChangeInfo = getIn(FILTERED_CHANGE_INFO);
export const getMessageIsFiltered = getIn(IS_FILTERED);
export const getMessageFilterType = getIn(FILTER_TYPE);
export const getMessageAgentId = message => {
  const audit = getAudit(message);
  return getAgentIdFromAudit(audit);
};
export const getAgentId = message => {
  return getMessageAgentId(message);
};
export const getFilterType = message => {
  return getMessageFilterType(message);
};
export const getIsFiltered = message => {
  return getMessageIsFiltered(message);
};