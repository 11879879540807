import http from 'conversations-http/clients/http';
export const bulkFetchValidatedViewMembers = ({
  objectIds,
  objectTypeId,
  viewId,
  quickFilters
}) => {
  return http.post('conversations-custom-views-realtime/v1/realtime/members', {
    data: {
      objectIds,
      objectTypeId,
      customViewId: viewId,
      quickFilters
    }
  });
};