export const getIsPastRefreshThreshold = ({
  date,
  threshold
}) => {
  if (!date) {
    return false;
  }
  if (threshold === 0) {
    return true;
  }
  // Get the current date and time
  const now = new Date();

  // Get the time difference in milliseconds
  const timeDifference = now - date;

  // Convert the time difference to hours
  const hoursDifference = timeDifference / (1000 * 60 * 60);

  // Check if the difference is greater than threshold hours
  return hoursDifference > threshold;
};