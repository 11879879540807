// gimme: conversations-inbox-ui 05/29/2020 ac3b21fbd6fae7fa05bb91d7af7c3197deeb7ccc
'use es6';

import { createAction } from 'redux-actions';
import { REFRESH_STORE_DATA } from '../constants/actionTypes';
export const refreshStoreData = createAction(REFRESH_STORE_DATA, ({
  threadDetails,
  threadHistory,
  threadId
}) => ({
  threadDetails,
  threadHistory,
  threadId
}));