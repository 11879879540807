module.exports = {
  "zorseGrrTaggingPanelInteraction": {
    "name": "Zorse GRR tagging panel interaction",
    "class": "interaction",
    "namespace": "realtime-reps-zorse-grr-tagging",
    "version": "1",
    "properties": {
      "action": "string",
      "conversationId": "number",
      "state": {
        "type": [
          "initiated",
          "succeeded",
          "failed"
        ],
        "isOptional": true
      }
    }
  },
  "zorseGrrTaggingPanelUsage": {
    "name": "Zorse GRR tagging panel usage",
    "class": "usage",
    "namespace": "realtime-reps-zorse-grr-tagging",
    "version": "1",
    "properties": {
      "action": "string",
      "conversationId": "number",
      "state": {
        "type": [
          "initiated",
          "succeeded",
          "failed"
        ],
        "isOptional": true
      }
    }
  }
};