'use es6';

import { Map as ImmutableMap, Record, OrderedMap } from 'immutable';
import PropertyValueRecord from 'customer-data-objects/property/PropertyValueRecord';
import get from 'transmute/get';
import { createPropertyKey } from '../operators/getPropertyKeys';
import { getAssociatedObjects, getResults } from '../operators/calleesOperators';
export class PhoneNumberPropertyMetadata extends Record({
  extension: null,
  phoneNumber: null,
  phoneNumberType: null,
  possibleNumber: false,
  regionCode: null,
  state: null,
  usnumber: false,
  validNumber: false,
  wasRegionInferred: false,
  wasParseResultEquivalentWithNoInference: null
}, 'PhoneNumberPropertyMetadata') {
  // The response from this can be passed to twilio to dial a number + extension
  get toNumberString() {
    const extension = this.get('extension');
    const phoneNumber = this.get('phoneNumber');
    return extension ? `${phoneNumber} ext ${extension}` : phoneNumber;
  }
}
export class PhoneNumberProperty extends Record({
  propertyName: null,
  label: null,
  hubspotDefined: false,
  value: null,
  metadata: new PhoneNumberPropertyMetadata()
}, 'PhoneNumberProperty') {
  constructor(attributes = {}) {
    super(Object.assign({}, attributes, {
      metadata: new PhoneNumberPropertyMetadata(
      // this property comes from the BE as validatedPhoneNumber
      // to avoid confusion we assign it to metadata here
      attributes.validatedPhoneNumber || attributes.metadata || {})
    }));
  }
}
export class CallableObject extends Record({
  objectId: null,
  objectTypeId: null,
  legacyObjectType: null,
  name: null,
  phoneNumberProperties: OrderedMap(),
  additionalProperties: ImmutableMap(),
  optOutOfCommunications: ImmutableMap(),
  crmObjectSubscriptionStatus: ImmutableMap(),
  canCommunicate: null
}, 'CallableObject') {
  static createReferenceKey(callableObject) {
    return createPropertyKey({
      objectTypeId: get('objectTypeId', callableObject),
      objectId: get('objectId', callableObject)
    });
  }
  constructor(attributes = {}) {
    const phoneNumberPropertiesArray = attributes.phoneNumberProperties || [];
    const phoneNumberProperties = phoneNumberPropertiesArray.reduce((orderedMap, property) => {
      const {
        propertyName
      } = property;
      const phoneNumberProperty = new PhoneNumberProperty(property);
      return orderedMap.set(propertyName, phoneNumberProperty);
    }, OrderedMap());
    const additionalProperties = new ImmutableMap(attributes.additionalProperties || {}).map(additionalProperty => {
      return new PropertyValueRecord(additionalProperty);
    });
    const optOutOfCommunications = new ImmutableMap(attributes.optOutOfCommunications || {});
    const crmObjectSubscriptionStatus = new ImmutableMap(attributes.crmObjectSubscriptionStatus || {});
    super(Object.assign({}, attributes, {
      phoneNumberProperties,
      additionalProperties,
      optOutOfCommunications,
      crmObjectSubscriptionStatus
    }));
  }
  static fromJS({
    objectId,
    objectTypeId,
    legacyObjectType,
    name,
    phoneNumberProperties,
    additionalProperties,
    optOutOfCommunications,
    crmObjectSubscriptionStatus,
    canCommunicate
  }) {
    const record = new CallableObject({
      objectId,
      objectTypeId,
      legacyObjectType,
      name,
      canCommunicate
    });
    const phoneNumberPropertiesParsed = OrderedMap(phoneNumberProperties).map(property => {
      return new PhoneNumberProperty(property);
    });
    const additionalPropertiesParsed = new ImmutableMap(additionalProperties || {}).map(additionalProperty => {
      return new PropertyValueRecord(additionalProperty);
    });
    const optOutOfCommunicationsParsed = new ImmutableMap(optOutOfCommunications || {});
    const crmObjectSubscriptionStatusParsed = new ImmutableMap(crmObjectSubscriptionStatus || {});
    return record.set('phoneNumberProperties', phoneNumberPropertiesParsed).set('additionalProperties', additionalPropertiesParsed).set('optOutOfCommunications', optOutOfCommunicationsParsed).set('crmObjectSubscriptionStatus', crmObjectSubscriptionStatusParsed);
  }
}
export class AssociatedObjects extends Record({
  hasMore: false,
  results: null
}, 'AssociatedObjects') {
  constructor(attributes = {}) {
    super(Object.assign({}, attributes, {
      results: (attributes.results || []).reduce((orderedMap, result) => {
        const callableObject = new CallableObject(result);
        return orderedMap.set(CallableObject.createReferenceKey(callableObject), callableObject);
      }, OrderedMap())
    }));
  }
  static fromJS(attributes = {}) {
    const record = new AssociatedObjects({
      hasMore: attributes.hasMore
    });
    const results = OrderedMap(attributes.results).map(callee => CallableObject.fromJS(callee));
    return record.set('results', results);
  }
}
export class AssociatedObjectType extends Record({
  objectType: null,
  associatedObjects: null
}, 'AssociatedObjectType') {
  constructor(attributes = {}) {
    super(Object.assign({}, attributes, {
      associatedObjects: new AssociatedObjects(attributes.associatedObjects)
    }));
  }
  static fromJS({
    objectType,
    associatedObjects
  }) {
    const result = new AssociatedObjectType({
      objectType
    });
    return result.set('associatedObjects', AssociatedObjects.fromJS(associatedObjects));
  }
}
export class CalleesRecord extends Record({
  fromObject: null,
  associatedObjectsPage: null
}, 'CalleesRecord') {
  constructor(attributes = {}) {
    super(Object.assign({}, attributes, {
      fromObject: attributes.fromObject ? new CallableObject(attributes.fromObject) : null,
      associatedObjectsPage: (attributes.associatedObjectsPage || []).reduce((orderedMap, associatedObjectType) => {
        const objectTypeId = associatedObjectType.objectTypeId;
        const newAssociatedObjectType = new AssociatedObjectType(associatedObjectType);

        // Do not replace existingAssociatedObjectType if number of associations is one to many.
        const existingAssociatedObjectType = orderedMap.get(objectTypeId);
        if (existingAssociatedObjectType && getResults(getAssociatedObjects(existingAssociatedObjectType)).size > 1) {
          return orderedMap;
        }
        return orderedMap.set(objectTypeId, newAssociatedObjectType);
      }, OrderedMap())
    }));
  }

  // The constructor is parsing from an API use this to convert a preformatted object to this record
  static fromJS(attributes = {}) {
    let record = new CalleesRecord();
    if (attributes.fromObject) {
      record = record.set('fromObject', CallableObject.fromJS(attributes.fromObject));
    }
    if (attributes.associatedObjectsPage) {
      record = record.set('associatedObjectsPage', Object.keys(attributes.associatedObjectsPage).reduce((orderedMap, associatedObjectTypeId) => {
        return orderedMap.set(associatedObjectTypeId, AssociatedObjectType.fromJS(attributes.associatedObjectsPage[associatedObjectTypeId]));
      }, OrderedMap()));
    }
    return record;
  }
}