import { IN_SLA, BREACHED, SLA_WARNING, RESOLVED_IN_SLA, RESOLVED_OUT_OF_SLA } from './SLAProperties';
/**
 * UITag takes color in the 'use' param. By convention, that param takes color names in lowercase and with dashes
 * between words.
 *
 * This is different from how UIIcon works, hence the two different maps.
 */
export const SLAStatusToTagColorMap = {
  [IN_SLA]: 'default',
  [BREACHED]: 'candy-apple',
  [SLA_WARNING]: 'marigold',
  [RESOLVED_IN_SLA]: 'default',
  [RESOLVED_OUT_OF_SLA]: 'default'
};
/**
 * UIIcon takes color in the 'color' param. By convention, that param takes color hashes from
 * the HubspotStyleTokens/colors object. This object's keys are uppercase with underscores between words.
 *
 * This is different from how UITag works, hence the two different maps.
 */
export const SLAStatusToIconColorMap = {
  [IN_SLA]: 'DEFAULT',
  [BREACHED]: 'CANDY_APPLE',
  [SLA_WARNING]: 'MARIGOLD',
  [RESOLVED_IN_SLA]: 'DEFAULT',
  [RESOLVED_OUT_OF_SLA]: 'DEFAULT'
};