import { LIVE_CHAT_GENERIC_CHANNEL_ID, FB_MESSENGER_GENERIC_CHANNEL_ID, EMAIL_GENERIC_CHANNEL_ID, WHATSAPP_GENERIC_CHANNEL_ID, SMS_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
import { THREAD_COMMENT } from 'conversations-message-history/comment-message/constants/messageTypes';
// TODO: move to composer-data

import { connectingPubSub } from '../strategies/connectingPubSub';
import { accountsFailed } from '../strategies/accountsFailed';
import { accountInactive } from '../strategies/accountInactive';
import { noRecipient } from '../strategies/noRecipient';
import { uploadingFile } from '../strategies/uploadingFile';
import { testThread } from '../strategies/testThread';
import { accountsLoading } from '../strategies/accountsLoading';
import { accountsMissing } from '../strategies/accountsMissing';
import { bouncedContact } from '../strategies/bouncedContact';
import { contactPermission } from '../strategies/contactPermission';
import { uploadingFileError } from '../strategies/uploadingFileError';
import { invalidEmails } from '../strategies/invalidEmails';
import { fbmReplyLimit } from '../strategies/fbmReplyLimit';
import { messengerDisconnected } from '../strategies/messengerDisconnected';
import { fromAccountSuspended } from '../strategies/fromAccountSuspended';
import { attachmentLimitExceeded } from '../strategies/attachmentLimitExceeded';
import { whatsAppReplyLimit } from '../strategies/whatsAppReplyLimit';
import { whatsAppMessagingTrusted } from '../strategies/whatsAppMessagingTrusted';
import { recipientLimitExceeded } from '../strategies/recipientLimitExceeded';
import { ablySubscriptionMismatch } from '../strategies/ablySubscriptionMismatch';
import { unsetChannelCapabilities } from '../strategies/unsetChannelCapabilities';
import { channelInstancesLoading } from '../strategies/channelInstancesLoading';
import { channelInstancesFailed } from '../strategies/channelInstancesFailed';
import { channelInstancesMissing } from '../strategies/channelInstancesMissing';
import { missingDeliveryIdentifiers } from '../strategies/missingDeliveryIdentifiers';
import { smsBillingLimit } from '../strategies/smsBillingLimit';
const GENERIC_DISABLING_STRATEGIES = [testThread, connectingPubSub, uploadingFileError, uploadingFile, channelInstancesLoading, channelInstancesFailed, channelInstancesMissing];
const EMAIL_DISABLING_STRATEGIES = [...GENERIC_DISABLING_STRATEGIES, accountsFailed, accountsLoading, accountsMissing, accountInactive, noRecipient, bouncedContact, contactPermission, fromAccountSuspended, invalidEmails, attachmentLimitExceeded, recipientLimitExceeded];
const FACEBOOK_DISABLING_STRATEGIES = [...GENERIC_DISABLING_STRATEGIES, messengerDisconnected, fbmReplyLimit, ablySubscriptionMismatch];
const WHATSAPP_DISABLING_STRATEGIES = [...GENERIC_DISABLING_STRATEGIES, whatsAppReplyLimit, whatsAppMessagingTrusted, missingDeliveryIdentifiers, ablySubscriptionMismatch];
const SMS_DISABLING_STRATEGIES = [...GENERIC_DISABLING_STRATEGIES, missingDeliveryIdentifiers, smsBillingLimit, ablySubscriptionMismatch];
const LIVE_CHAT_AND_COMMENT_DISABLING_STRATEGIES = [...GENERIC_DISABLING_STRATEGIES, attachmentLimitExceeded, ablySubscriptionMismatch];
const UNSET_DISABLING_STRATEGIES = [unsetChannelCapabilities];
export const buildDisabledPropsForChannel = ({
  channelId
}) => {
  switch (channelId) {
    case EMAIL_GENERIC_CHANNEL_ID:
      {
        return EMAIL_DISABLING_STRATEGIES;
      }
    case FB_MESSENGER_GENERIC_CHANNEL_ID:
      {
        return FACEBOOK_DISABLING_STRATEGIES;
      }
    case WHATSAPP_GENERIC_CHANNEL_ID:
      {
        return WHATSAPP_DISABLING_STRATEGIES;
      }
    case SMS_GENERIC_CHANNEL_ID:
      {
        return SMS_DISABLING_STRATEGIES;
      }
    case LIVE_CHAT_GENERIC_CHANNEL_ID:
    case THREAD_COMMENT:
      {
        return LIVE_CHAT_AND_COMMENT_DISABLING_STRATEGIES;
      }
    case 'UNSET':
      {
        return UNSET_DISABLING_STRATEGIES;
      }
    default:
      {
        return GENERIC_DISABLING_STRATEGIES;
      }
  }
};