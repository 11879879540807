import { createMetricsFactory } from 'metrics-js';
const Metrics = createMetricsFactory('ably-hubspot-js');

/**
 * Report specific errors to Grafana for customer Kepler alerting rules.
 */
export function keplerAlerting(error) {
  switch (error.code) {
    case 40160:
      Metrics.counter('permissions-error-40160').increment();
      break;
    default:
      return;
  }
}