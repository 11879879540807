import PortalIdParser from 'PortalIdParser';
import { getPortalId, getTicketId } from 'svh-tickets-lib/tickets/public/operators';
import { getTicketProfileUrl } from '../../../external-urls/public';
export const getTicketRecordLink = ticket => {
  const portalId = getPortalId(ticket) || PortalIdParser.get();
  if (!portalId) {
    throw new Error('PortalID is undefined in getTicketRecordLink');
  }
  return getTicketProfileUrl({
    portalId,
    ticketId: getTicketId(ticket)
  });
};