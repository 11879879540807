import { useReduxAsyncFetcher } from 'conversations-async-data/async-data/hooks/useReduxAsyncFetcher';
import { getAsyncAssociations } from '../selectors/getAsyncAssociations';
import { fetchAssociations } from '../actions/fetchAssociations';
import { noOpAction } from '../../../utils/noOpAction';
import { deferredAsyncDataSelector } from '../../../utils/deferredAsyncDataSelector';
export const useAssociations = ({
  threadId,
  deferred = false
}) => {
  const {
    asyncData: asyncAssociations
  } = useReduxAsyncFetcher({
    action: deferred ? noOpAction : fetchAssociations,
    selector: deferred ? deferredAsyncDataSelector : getAsyncAssociations,
    id: threadId,
    idTransform: id => ({
      threadId: id
    })
  });
  return {
    asyncAssociations
  };
};