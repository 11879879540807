// gimme: conversations-inbox-ui 06/10/2020 24a2e66917cc934433dd111db7e06fd02943ca6a

import curry from 'transmute/curry';
import pipe from 'transmute/pipe';
import { getThreadId } from 'conversations-message-history/unpublished-messages/operators/getters';
import { getEntries } from 'conversations-async-data/indexed-async-data/operators/getters';
import { getData } from 'conversations-async-data/async-data/operators/getters';
export const getUnpublishedMessagesMatchingThreadId = curry((threadId, unpublishedMessages) => {
  return getEntries(unpublishedMessages).filter(message => {
    const messageThreadId = pipe(getData, getThreadId)(message);
    return messageThreadId === threadId;
  });
});