const before = function before(times, func) {
  let memo;
  return function (...args) {
    if (--times > 0 && func) {
      memo = func.apply(this, args);
    }
    if (times <= 1) func = null;
    return memo;
  };
};
export const once = fn => before(2, fn);
export const isObject = object => {
  return object != null && typeof object === 'object';
};
export const deepEqual = (object1, object2) => {
  const areObjects = isObject(object1) && isObject(object2);
  if (!areObjects) {
    if (object2 == null && object1 == null) {
      return true;
    }
    return object1 === object2;
  }
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    if (areObjects && !deepEqual(val1, val2)) {
      return false;
    }
  }
  return true;
};