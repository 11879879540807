import { List as ImmutableList, Map as ImmutableMap } from 'immutable';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { getEmailReplyRecipients, buildEmailFromAddress } from '../../../email-metadata/public/operators';
// @ts-expect-error module not typed

import { getVisitor } from 'conversations-thread-data/thread-details/public/operators';
// @ts-expect-error module not typed
import { getEmail } from 'conversations-internal-schema/visitor/operators/visitorGetters';
import { getEmail as getEmailFromConnectedAccount, getSendFromEmail
// @ts-expect-error module not typed
} from 'conversations-internal-schema/connected-email-account/operators/connectedEmailAccountGetters';
import { getEmailAddressRecordFromString } from 'conversations-thread-data/common-reply-metadata/public/operators/getEmailAddressRecordFromString';
export const buildEmailMetadataForRecipients = ({
  channelInstances = null,
  connectedAccounts = null,
  asyncCommonMessage,
  threadDetails = null,
  isForward = false,
  replyAll = false,
  visitorEmail = ''
}) => {
  if (!channelInstances || !connectedAccounts || !threadDetails) {
    return ImmutableMap();
  }
  const commonMessage = getData(asyncCommonMessage);
  const from = buildEmailFromAddress({
    channelInstances,
    connectedAccounts,
    replyingToMessage: commonMessage
  }) || '';
  let recipientMeta = {
    to: ImmutableList(),
    cc: ImmutableList(),
    bcc: ImmutableList()
  };
  if (commonMessage) {
    const connectedAccountAddresses = connectedAccounts.map(connectedAccount => getEmailFromConnectedAccount(connectedAccount)).concat(connectedAccounts.map(connectedAccount => getSendFromEmail(connectedAccount))).filter(Boolean).toList();
    const {
      to,
      cc,
      bcc
    } = isForward ? {
      to: ImmutableList(),
      cc: ImmutableList(),
      bcc: ImmutableList()
    } : getEmailReplyRecipients(from, commonMessage, connectedAccountAddresses, threadDetails, replyAll);
    recipientMeta = Object.assign({}, recipientMeta, {
      to: to || ImmutableList(),
      cc: cc || ImmutableList(),
      bcc: bcc || ImmutableList()
    });
  } else {
    const visitor = getVisitor(threadDetails);
    const toEmail = visitorEmail || visitor && getEmail(visitor);
    recipientMeta = Object.assign({}, recipientMeta, {
      to: toEmail ? ImmutableList([getEmailAddressRecordFromString(toEmail)]) : ImmutableList()
    });
  }
  return ImmutableMap({
    to: recipientMeta.to,
    cc: recipientMeta.cc,
    bcc: recipientMeta.bcc
  });
};