import PortalIdParser from 'PortalIdParser';
const getRootPath = () => `/contacts/${PortalIdParser.get()}`;
const getRootPathFromWindow = () => {
  const hubspotBasePathnameRegex = /^\/[\w-]+\/\d+/;
  const parts = window.location.pathname.match(hubspotBasePathnameRegex);
  if (parts) {
    //manual override for crm-events iframe
    if (parts[0].includes('crm-events')) {
      return parts[0].replace('crm-events', 'contacts');
    }
    //manual override for crm-record-extension-pane iframe
    if (parts[0].includes('crm-record-extension-pane-ui')) {
      return parts[0].replace('crm-record-extension-pane-ui', 'contacts');
    }
    return parts[0];
  }
  return null;
};
const baseUrl = (includeBaseUrl = true) => {
  if (!includeBaseUrl) {
    return '';
  }
  return getRootPathFromWindow() || getRootPath();
};
export const allPropertiesHistory = ({
  objectTypeId,
  objectId
}) => `${baseUrl()}/record/${encodeURIComponent(objectTypeId)}/${objectId}/history`;