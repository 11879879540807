'use es6';

import compact from 'hs-lodash/compact';
import map from 'transmute/map';
import { DRAFT_KEY_PREFIX } from '../constants/draftKeyPrefix';
const keyPattern = new RegExp(`${DRAFT_KEY_PREFIX}_([a-zA-Z0-9_.-]+)`);
const matchDraftKey = key => keyPattern.exec(key);

/**
 * @description Get regex match objects for all keys in the store
 * that represent common reply drafts. Matches contain
 * the entire local storage key at the 0th index and the replyId
 * at the second index.
 * @returns {array} matches array
 * @argument {object} storageObj a plain js object (likely localStorage)
 */
export const getDraftKeyMatches = storageObj => compact(map(matchDraftKey, Object.keys(storageObj)));