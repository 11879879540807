import http from 'conversations-http/clients/http';
import { registerQuery } from 'data-fetching-client';
export const CONTACT_FETCHING_FIELD_NAME = 'contacts';
export const FETCH_CONTACTS = registerQuery({
  fieldName: CONTACT_FETCHING_FIELD_NAME,
  args: ['query'],
  fetcher({
    query
  }) {
    return http.post('contacts/search/v1/search/contacts', {
      query: {
        showListMemberships: true,
        resolveOwner: false,
        showSourceMetadata: false,
        identityProfileMode: 'none',
        showPastListMemberships: false,
        formSubmissionMode: 'none',
        showPublicToken: false,
        propertyMode: 'value_only',
        showAnalyticsDetails: false,
        resolveAssociations: false
      },
      data: {
        offset: 0,
        count: 100,
        properties: ['email', 'firstName', 'lastName'],
        sorts: [{
          property: 'firstName',
          order: 'ASC'
        }, {
          property: 'lastName',
          order: 'ASC'
        }, {
          property: 'email',
          order: 'ASC'
        }],
        query
      }
    });
  }
});
export const FETCH_CONTACTS_BY_VID = registerQuery({
  fieldName: CONTACT_FETCHING_FIELD_NAME,
  args: ['vid', 'portalId'],
  fetcher({
    vid,
    portalId
  }) {
    return http.get(`contacts/v1/contact/vid/${vid}/identity-profiles`, {
      query: {
        portalId
      }
    });
  }
});