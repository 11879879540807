// @ts-expect-error module not found
import EngagementsV2UniversalAssociationRecord from 'universal-associations-select/records/EngagementsV2UniversalAssociationRecord';
import { Map as ImmutableMap, Set as ImmutableSet, OrderedMap } from 'immutable';
export const getEngagementsV2UniversalAssociationRecord = (associationRecord, associationOptionRecordsMap) => {
  const toObjectTypeId = associationRecord.get('toObjectTypeId');
  let objectIds = ImmutableSet();
  let recordsMap = OrderedMap();
  if (associationOptionRecordsMap) {
    objectIds = ImmutableSet(associationOptionRecordsMap.keySeq());
    recordsMap = associationOptionRecordsMap;
  }
  return ImmutableMap({
    [toObjectTypeId]: EngagementsV2UniversalAssociationRecord({
      associationOptionRecordsMap: recordsMap,
      associationSpec: ImmutableMap({
        associationCategory: associationRecord.get('associationCategory'),
        associationTypeId: associationRecord.get('associationTypeId')
      }),
      objectIds
    })
  });
};