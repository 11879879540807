import { useEffect, useRef } from 'react';
import getIn from 'transmute/getIn';
import { getTicketId } from 'svh-tickets-lib/tickets/public/operators';
import { deepEqual } from '../../lib/hsUnderscore';
const getPropertyValue = (obj, key) => getIn(['defaultProperties', key, 'value'], obj) || obj.properties && (obj.properties.find(prop => prop.name === key) || {}).value;
const getPropertyKeys = ticket => {
  const defaultPropertiesKeys = Object.keys(getIn(['defaultProperties'], ticket) || {});
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  const propertiesKeys = (getIn(['properties'], ticket) || []).map(property => property.name);
  return [...new Set(defaultPropertiesKeys.concat(propertiesKeys))];
};

/**
 * @description useTicketWatcher will detect and report changes to the given ticket
 * object. Edges going from falsy->truthy or between different ticket objects are
 * ignored.
 */
export const useTicketWatcher = ({
  ticket,
  onChange
}) => {
  const prevTicket = useRef(ticket);
  useEffect(() => {
    if (ticket && prevTicket.current && prevTicket.current !== ticket && getTicketId(prevTicket.current) === getTicketId(ticket)) {
      const keys = getPropertyKeys(ticket);
      const differences = keys.filter(key => {
        return !deepEqual(getPropertyValue(prevTicket.current, key), getPropertyValue(ticket, key));
      });
      onChange(differences, ticket);
    }
  }, [ticket, onChange]);
  useEffect(() => {
    prevTicket.current = ticket;
  }, [ticket]);
};