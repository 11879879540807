'use es6';

import { localStorage } from 'conversations-platform/localStorage';
import { deserializeCommonReplyDraft } from '../operators/deserializeCommonReplyDraft';
export const restoreCommonReplyFromDraft = draftKey => {
  try {
    const rawDraft = localStorage.getItem(draftKey);
    const commonReply = deserializeCommonReplyDraft(rawDraft);
    return commonReply || null;
  } catch (error) {
    return null;
  }
};