import { ACCOUNT_MANAGER, DELIVERABILITY, PRIVACY } from '../constants/EscalationType';
export function convertToTrackingFields(escalation) {
  const trackingFields = {
    escalatedTo: escalation.type
  };
  switch (escalation.type) {
    case ACCOUNT_MANAGER:
    case DELIVERABILITY:
    case PRIVACY:
      return Object.assign({}, trackingFields, {
        escalationReason: escalation.reason
      });
    default:
      return trackingFields;
  }
}