import { ADD_AN_OBJECT, ADD_AN_OBJECT_LABEL, FEATURED, GENERATE, GENERATE_LABEL, HUBSPOT_HOW_TO, HUBSPOT_HOW_TO_LABEL, PREPARE, PREPARE_LABEL, REPORTING, REPORTING_LABEL, RESEARCH, RESEARCH_LABEL, SUMMARIZE, SUMMARIZE_LABEL } from '../../constants/SuggestedPromptGroups';
/** Featured Templates are already in the correct order */
const toFeaturedTemplateGroup = featuredTemplates => {
  return featuredTemplates && featuredTemplates.length > 0 && featuredTemplates.map(featuredTemplate => {
    return Object.assign({}, featuredTemplate, {
      group: FEATURED
    });
  }) || [];
};
const getTemplateByCategory = (promptTemplates, category) => {
  return promptTemplates.filter(template => {
    return template.group && template.group.toLowerCase() === category.toLowerCase();
  });
};
export const getGroupedSuggestedPrompts = (promptTemplates, featuredTemplates) => {
  return {
    [FEATURED]: toFeaturedTemplateGroup(featuredTemplates),
    [GENERATE]: getTemplateByCategory(promptTemplates, GENERATE_LABEL),
    [SUMMARIZE]: getTemplateByCategory(promptTemplates, SUMMARIZE_LABEL),
    [RESEARCH]: getTemplateByCategory(promptTemplates, RESEARCH_LABEL),
    [REPORTING]: getTemplateByCategory(promptTemplates, REPORTING_LABEL),
    [ADD_AN_OBJECT]: getTemplateByCategory(promptTemplates, ADD_AN_OBJECT_LABEL),
    [HUBSPOT_HOW_TO]: getTemplateByCategory(promptTemplates, HUBSPOT_HOW_TO_LABEL),
    [PREPARE]: getTemplateByCategory(promptTemplates, PREPARE_LABEL)
  };
};
export const getAllGroupedTemplates = promptTemplates => {
  return Object.values(promptTemplates).reduce((acc, templates) => {
    return [...acc, ...templates];
  }, []);
};