import { getMembers } from 'find-and-filter-data/view-members-schema/protected';
import { createSelector } from 'reselect';
import get from 'transmute/get';
const MEMBERS = 'indexedMembers';
export const selectFilterResults = state => {
  const findAndFilterData = get('findAndFilterData', state);
  const searchAndFilterState = get('searchAndFilter', findAndFilterData);
  return get('filterResults', searchAndFilterState);
};
export const selectFilterResultsThreadListPageData = createSelector([selectFilterResults], filterResults => {
  return filterResults.data;
});
export const selectFilterResultsMembers = createSelector([selectFilterResultsThreadListPageData], filterResults => {
  return filterResults && getMembers(filterResults);
});