import events from 'products-highlight-card-lib/events.yaml';
import { createTracker } from 'usage-tracker';
import Raven from 'raven-js';
const tracker = createTracker({
  events,
  properties: {
    namespace: 'productshighlightcardlib'
  },
  lastKnownEventProperties: ['screen', 'subscreen'],
  onError: err => {
    Raven.captureMessage('products-highlight-card-lib tracker event error', {
      extra: {
        message: err.message
      }
    });
  }
});
export const BeaconUsageTracker = tracker.clone({});
export default tracker;