'use es6';

import { createAction } from 'redux-actions';
import { HISTORY_MESSAGE_RECEIVED } from '../constants/actionTypes';
export const historyMessageReceived = createAction(HISTORY_MESSAGE_RECEIVED, ({
  message,
  threadId
}) => ({
  message,
  threadId
}));