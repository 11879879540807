// For V3 gates: https://product.hubteam.com/docs/gating/next-gen/usage.html#frontend-usage
import { usePermissionsContext } from '../context/PermissionsContext';

/**
 * Returns true if the current authed user has the provided gate
 */
export const useHasGate = gate => {
  const {
    gates
  } = usePermissionsContext();
  return gates.has(gate);
};
export function useHasBillingWizardGate() {
  return useHasGate('Payments:Subscriptions:CreateSubscription');
}