export const TemplateMappings = {
  // Copilot Sandbox only used for testing
  '/copilot-sandbox': ['news', 'summarize_contact', 'add_note_to_contact'],
  '/prospecting': ['discover_companies_age_&_size', 'discover_companies_industry_&_location', 'discover_companies_size_&_location', 'discover_companies_size_&_technology', 'discover_companies_technology_&_location', 'enhance_sales_team_performance', 'initial_outreach_email', 'lead_reactivation__email'],
  '/ads': ['blog_generator', 'translate_text', 'blog_title_generator', 'build_b2b_buyer_persona_profiles', 'craft_compelling_elevator_pitch', 'freeform_images'],
  '/analytics': ['object_count', 'summary_reporting'],
  '/contacts/:id/objects/0-48': ['add_note_to_contact', 'add_ticket', 'company_profile', 'competitors', 'news', 'summarize_company', 'summarize_contact', 'technology_used'],
  '/marketing/:id/campaigns': [],
  '/chatflows': [],
  '/playlists': [],
  '/commerce': ['ask_hubspot_academy', 'knowledgebase_search'],
  '/contacts/:id/record/0-2/': ['competitors', 'prepare_for_a_meeting_with_a_company', 'funding_rounds', 'show_engagements_with_company', 'initial_outreach_email', 'lead_reactivation__email', 'news', 'technology_used'],
  '/contacts/:id/objects/0-2/views/all/list': ['add_company', 'company_profile', 'news', 'object_count', 'summarize_company', 'summary_reporting'],
  '/contacts/:id/record/0-1': ['summarize_contact', 'add_note_to_contact', 'prepare_for_a_meeting_with_a_contact', 'add_ticket', 'show_engagements_with_contact', 'initial_outreach_email', 'lead_reactivation__email', 'summarize_contact', 'update_contact'],
  '/contacts/:id/objects/0-1/views/all/list': ['summarize_contact', 'add_note_to_contact', 'summary_reporting'],
  '/events': [],
  '/settings/:id/customer-portal': ['design_customer_feedback_survey'],
  '/reports-dashboard': ['object_count', 'summary_reporting'],
  '/data-model-overview/': ['object_count'],
  '/data-quality': ['object_count'],
  '/reporting-datasets/': ['object_count', 'summary_reporting'],
  '/contacts/:id/record/0-3/': ['summarize_deal_record', 'add_ticket', 'summarize_company'],
  '/contacts/:id/objects/0-3/views/all/list': ['add_deal', 'object_count', 'summary_reporting'],
  '/design-manager': ['anime_style', 'cinematic_style', 'comic_book_image', 'digital_art_stylw', 'freeform_images', 'photograph_style', 'pixel_art_image', 'retro_film_style', 'sketch_image'],
  '/documents/': ['craft_a_linkedin_article', 'translate_text', 'craft_compelling_elevator_pitch', 'freeform_images'],
  '/feedback': ['design_customer_feedback_survey', 'translate_text'],
  '/files/': ['anime_style', 'cinematic_style', 'comic_book_image', 'digital_art_stylw', 'freeform_images', 'pixel_art_image', 'sketch_image'],
  '/forecasting/': ['enhance_sales_team_performance', 'summary_reporting'],
  '/forms': ['design_customer_feedback_survey'],
  '/goals/': ['enhance_sales_team_performance'],
  '/live-messages/': ['add_deal', 'add_note_to_contact', 'add_ticket', 'modify_content_length', 'summarize_contact'],
  '/integrations-settings/': [],
  '/contacts/:id/objects/0-53/views/all/list': [],
  '/knowledge/': ['generate_knowledge_base_articles'],
  '/website/:id/pages/landing/': [],
  '/contacts/:id/lists/': ['object_count'],
  '/contacts/:id/objectLists/': ['object_count'],
  '/meetings/': [],
  '/payment-links/': [],
  '/commerce/': [],
  '/playbooks/': ['enhance_sales_team_performance', 'object_count'],
  '/contacts/:id/objects/0-7/views/all/list': ['object_count', 'translate_text', 'summary_reporting'],
  '/prospecting/': ['add_note_to_contact', 'article_summary', 'company_profile', 'competitors', 'discover_companies', 'funding_rounds', 'news_subscription', 'technology_used'],
  '/quotes/': ['translate_text'],
  '/content-strategy/': ['popular_companies'],
  '/sequences/': ['object_count'],
  '/snippets/': ['add_note_to_contact'],
  '/social/': ['craft_a_linkedin_article'],
  '/0-69/': ['object_count', 'summary_reporting'],
  '/target-accounts': ['add_company', 'summarize_company', 'summary_reporting'],
  '/tasks/': [],
  '/templates/': ['initial_outreach_email', 'lead_reactivation__email'],
  '/contacts/:id/record/0-5/': ['summarize_ticket_record', 'add_company', 'add_contact', 'add_deal', 'add_note_to_contact', 'summary_reporting'],
  '/contacts/:id/objects/0-5/views/all/list': ['add_note_to_contact', 'add_ticket', 'summary_reporting', 'update_contact'],
  '/user-guide': ['ask_hubspot_academy', 'knowledgebase_search'],
  '/workflows': ['generate_a_workflow'],
  WEB_INTERACTIVE: ['design_customer_feedback_survey', 'freeform_images', 'modify_content_length'],
  SITE_PAGE: ['translate_text'],
  BLOG_POST: ['blog_generator', 'blog_title_generator', 'translate_text', 'modify_content_length', 'build_b2b_buyer_persona_profiles', 'business_mission_statement_generator', 'craft_a_linkedin_article', 'craft_compelling_elevator_pitch'],
  KNOWLEDGE_ARTICLE: ['generate_knowledge_base_articles', 'generate_knowledge_base_article_title', 'generate_knowledge_base_article_subtitle', 'generate_a_meta_description', 'modify_content_length', 'translate_text'],
  LANDING_PAGE: ['build_b2b_buyer_persona_profiles', 'business_mission_statement_generator', 'craft_compelling_elevator_pitch', 'freeform_images', 'professional_biography'],
  MARKETING_EMAIL: ['generate_paragraph', 'generate_heading'],
  MARKETING_SMS: ['generate_sms']
};