import { buildRecipients } from 'conversations-message-history/common-message-format/operators/buildRecipients';
import { getEmailMetadataAttachment } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { updateEmailSubject } from 'conversations-thread-data/common-reply/public/operators/updateEmailSubject';
import { setRecipients } from 'conversations-message-history/common-message-format/operators/commonMessageSetters';
const setDefaultSubject = ({
  defaultMessageValue,
  commonMessage
}) => {
  const {
    subject
  } = defaultMessageValue;
  if (!subject) return commonMessage;
  const emailMetadataAttachment = getEmailMetadataAttachment(commonMessage);
  if (emailMetadataAttachment) {
    const commonMessageWithSubject = subject ? updateEmailSubject(subject, commonMessage) : commonMessage;
    return commonMessageWithSubject;
  }
  return commonMessage;
};
const setDefaultRecipients = ({
  defaultMessageValue,
  commonMessage
}) => {
  const {
    recipients
  } = defaultMessageValue;
  if (!recipients || recipients.length === 0) return commonMessage;
  const recipientsPojoWithDeprecatedDeliveryIdentifier = recipients.map(recipient => Object.assign({}, recipient, {
    deliveryIdentifier: []
  }));
  return setRecipients(buildRecipients(recipientsPojoWithDeprecatedDeliveryIdentifier), commonMessage);
};
export const buildEmptyMessageDefaults = ({
  commonMessage,
  defaultMessageValue
}) => {
  if (!defaultMessageValue) return commonMessage;
  const commonMessagageWithSubject = setDefaultSubject({
    defaultMessageValue,
    commonMessage
  });
  const commonMessageWithRecipients = setDefaultRecipients({
    defaultMessageValue,
    commonMessage: commonMessagageWithSubject
  });
  return commonMessageWithRecipients;
};