import getIn from 'transmute/getIn';
import get from 'transmute/get';
import { generateViewId } from 'find-and-filter-data/views-schema/public';
export const getCurrentInboxId = getIn(['currentInboxId']);
export const getCurrentCustomViewId = getIn(['currentCustomViewId']);
export const getCurrentThreadListId = getIn(['currentThreadListId']);
export const getCurrentThreadListType = getIn(['threadListType']);
export const getInboxes = getIn(['inboxes']);
export const getRecentHistory = getIn(['thread', 'recentHistory']);
export const getThreadAttachments = getIn(['thread', 'recentHistory', 'attachments']);
export const getThread = getIn(['thread']);
export const getThreadId = getIn(['thread', 'threadId']);
export const getAssociatedTicketId = omnibus => {
  const associatedTicketIds = getIn(['thread', 'associatedTicketIds'], omnibus);
  return associatedTicketIds && associatedTicketIds.length > 0 ? associatedTicketIds.get(0) : null;
};
export const getThreadListMembers = getIn(['threadListMembers']);
export const getZeroState = getIn(['zeroState']);
export const getRequestErrorTypes = omnibusResponse => {
  return get('requestErrorTypes', omnibusResponse);
};
export const getErrorUpstreams = omnibusResponse => omnibusResponse.errorUpstreams;
export const getLastAppliedPlaybackMessageTimestamp = getIn(['latestPlaybackMessageTimestamp']);

/**
 * @description Returns the current view ID from omnibus whether it's a CustomView
 * or a ThreadList
 */
export const getCurrentViewId = omnibus => {
  const customViewId = getCurrentCustomViewId(omnibus);
  const threadListId = getCurrentThreadListId(omnibus);
  const threadListType = getCurrentThreadListType(omnibus);
  if (customViewId || threadListId && threadListType) {
    const currentViewId = generateViewId({
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'string |... Remove this comment to see the full error message
      customViewId,
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'string |... Remove this comment to see the full error message
      threadListId,
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'ThreadLi... Remove this comment to see the full error message
      threadListType
    });
    return currentViewId;
  }
  return null;
};