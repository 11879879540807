import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUninitialized } from 'conversations-async-data/async-data/operators/statusComparators';
import { updateSubscriptions as updateSubscriptionsAction } from '../actions/subscriptions';
import { initializePubSubWithVendor } from '../actions/initializePubSubWithVendor';
import { getAsyncPubSubClient } from '../selectors/pubSubClientGetters';
import { DEFAULT_CLIENT_KEY } from '../constants/clientKeys';
/**
 * @description A declarative API for using a pubsub client
 * @param {Object} connectionConfig
 * @param {object} connectionConfig.clientOptions
 * @param {lifeCycleHooks} connectionConfig.lifeCycleHooks - Connection life cycle callbacks
 * @param {function} connectionConfig.resolveVendor- A resolver function to load vendor (can be code split)
 */
export const usePubSubClient = ({
  clientOptions,
  lifecycleHooks,
  resolveVendor,
  clientKey = DEFAULT_CLIENT_KEY
}) => {
  const dispatch = useDispatch();
  const asyncPubSubClient = useSelector(state =>
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
  getAsyncPubSubClient(state, {
    clientKey
  }));
  const [initializationTriggered, setInitializationTriggered] = useState(false);

  // set up the client if it hasn't been initialized already. Because of
  // the redux backing, initialization will only happen once, even between
  // remounts of components using this hook.
  useEffect(() => {
    if (isUninitialized(asyncPubSubClient) && !initializationTriggered) {
      setInitializationTriggered(true);
      dispatch(initializePubSubWithVendor({
        clientOptions,
        lifecycleHooks,
        resolveVendor,
        clientKey
      }));
    }
  }, [asyncPubSubClient, clientKey, clientOptions, dispatch, initializationTriggered, lifecycleHooks, resolveVendor, setInitializationTriggered]);

  // expose API for imperatively updating subscriptions
  const updateSubscriptions = useCallback(subscriptions => {
    dispatch(updateSubscriptionsAction(subscriptions, clientKey));
  }, [clientKey, dispatch]);
  return {
    asyncPubSubClient,
    updateSubscriptions
  };
};