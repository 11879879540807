import get from 'transmute/get';
import { createSelector } from 'reselect';
import { getFilterResults } from './getFilterResults';
const getThreadIdFromProps = (state, {
  threadId
}) => threadId;
export const getFilteredThreadListMemberWithThreadId = createSelector([getThreadIdFromProps, getFilterResults], (threadId, filterResults) => {
  const indexedMembers = filterResults.data.indexedMembers;
  return get(
  // @ts-expect-error FIXME: type of threadId in ThreadListMemberProps should get corrected
  threadId, indexedMembers);
});