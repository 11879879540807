import http from 'conversations-http/clients/http';
export const getThreadVisitorIdentification = ({
  emails,
  threadId
}) => {
  return http.get(`/visitor-identification/v1/identified-entity/is-identified`, {
    query: {
      entityId: threadId,
      email: emails,
      identifiedEntityType: 'THREAD'
    }
  });
};