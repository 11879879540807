'use es6';

import getIn from 'transmute/getIn';
import { EXPIRES_AT, URL, NAME, EXTENSION, TYPE, SIZE, WIDTH, HEIGHT } from '../constants/fileMetadataKeyPaths';
export const getExpiresAt = getIn(EXPIRES_AT);
export const getUrl = getIn(URL);
export const getName = getIn(NAME);
export const getExtension = getIn(EXTENSION);
export const getType = getIn(TYPE);
export const getSize = getIn(SIZE);
export const getWidth = getIn(WIDTH);
export const getHeight = getIn(HEIGHT);