// gimme: conversations-inbox-ui 05/26/2020 741c62dc71ca742adbc6d64d61d30de7cb46539f
'use es6';

import pipe from 'transmute/pipe';
import { getEntry } from 'conversations-async-data/indexed-async-data/operators/getters';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { getThreadHistories } from 'conversations-thread-data/thread-histories/public/selectors/getters';
export const getThreadHistory = (state, props) => {
  const {
    threadId
  } = props;
  return pipe(getThreadHistories, getEntry(threadId), getData)(state);
};