import { getChannelTypeByGenericId } from 'ui-addon-conversations-settings/help-desk-migration/operators/getChannelTypeByGenericId';
// we only support live chat channel (channelId 1000), email channel (channelId 1002) and form channel (channelId 1003)
const getMigrationChannel = (channelInstanceId, channelInstances) => {
  const activeChannel = channelInstances.find(channel => {
    const isMigrationChannel = channel.channelId === 1000 || channel.channelId === 1002 || channel.channelId === 1003;
    return isMigrationChannel && channel.id === channelInstanceId;
  });
  if (!activeChannel) {
    return null;
  }
  return {
    channelInstanceId,
    name: activeChannel.name ? activeChannel.name : '',
    type: activeChannel.channelId !== null ? getChannelTypeByGenericId(activeChannel.channelId) || '' : ''
  };
};
export const getInboxActiveMigrationInfo = (channelInstances, inboxMigrationState) => {
  const migrationStates = inboxMigrationState.migrationStates;
  if (migrationStates === null || migrationStates === undefined || migrationStates.length === 0) {
    return null;
  }
  const activeMigrationChannelInstanceId = migrationStates.channelInstanceId;
  const activeChannel = getMigrationChannel(activeMigrationChannelInstanceId, channelInstances);
  return activeChannel;
};