import CallingProvider from 'calling-internal-common/call-provider/records/CallingProvider';
import setIn from 'transmute/setIn';
import { List } from 'immutable';
import { getSetting } from '../../local-settings/localSettings';
import { getIn } from '../../utils/getIn';
const overrideKey = 'CallingExtensions';
const OLD_LOCAL_SETTINGS_PREFIX = 'LocalSettings:Sales:';
export function getOverrideValue() {
  const overrideValue = getSetting(overrideKey);
  const oldOverrideValue = getSetting(overrideKey, null, undefined, OLD_LOCAL_SETTINGS_PREFIX);
  if (overrideValue || oldOverrideValue) {
    const {
      name,
      url,
      options,
      height,
      width,
      isReady,
      supportsCustomObjects
    } = overrideValue || oldOverrideValue;
    const widgetInfo = {
      name,
      url,
      isReady: isReady === undefined ? true : isReady,
      height: options ? options.height : height,
      width: options ? options.width : width,
      supportsCustomObjects
    };
    return new CallingProvider(widgetInfo);
  }
  return null;
}
export default function applyLocalStorageOverrides(widgetList) {
  const localStorageWidget = getOverrideValue();
  if (localStorageWidget && widgetList) {
    // Find the widget with name
    const widgetIndex = widgetList.findIndex(widget => {
      if (widget) {
        return widget.get('name') === localStorageWidget.get('name');
      }
      return false;
    });
    if (widgetIndex > -1) {
      // If the widget exists, override the settings
      const widgetIndexStr = String(widgetIndex);
      let widget = getIn([widgetIndexStr], widgetList);
      widget = widget.mergeWith((oldValue, override) => {
        return override === undefined ? oldValue : override;
      }, localStorageWidget);
      widgetList = setIn([widgetIndexStr], widget, widgetList);
    } else {
      widgetList = widgetList.push(localStorageWidget);
    }
  }
  return widgetList || List();
}