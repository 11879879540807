import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { getConnectedAccounts as requestFn } from '../client/getConnectedAccounts';
import { FETCH_CONNECTED_ACCOUNTS as actionTypes } from '../constants/asyncActionTypes';
import { indexConnectedAccounts } from '../operators/indexConnectedAccounts';
import { buildRequestErrorMetaObject } from 'conversations-error-reporting/error-actions/builders/buildRequestErrorMetaObject';
export const fetchConnectedAccountsForInbox = createAsyncAction({
  requestFn,
  actionTypes,
  toRecordFn: indexConnectedAccounts,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type '{}'.
  failureMetaActionCreator: ({
    error
  }) => buildRequestErrorMetaObject({
    error
  })
});