import { OrderedMap } from 'immutable';
import Raven from 'raven-js';
const extractDataForErrorReport = page => {
  if (!page) {
    return {
      indexedMembersIsAnOrderedMap: null,
      totalCount: null
    };
  }
  const indexedMembers = page.get('indexedMembers', null);
  return {
    indexedMembersIsAnOrderedMap: indexedMembers && OrderedMap.isOrderedMap(page.indexedMembers),
    totalCount: page.get('totalCount', null)
  };
};
export default (({
  oldPage,
  newPage,
  totalCountFromPayload,
  jsError
}) => {
  Raven.captureException(Error(`Unable to merge oldPage and newPage`), {
    extra: {
      jsError,
      payloadObj: {
        totalCount: totalCountFromPayload
      },
      oldPageObj: oldPage && extractDataForErrorReport(oldPage),
      newPageObj: newPage && extractDataForErrorReport(newPage)
    }
  });
});