import { buildError } from '../../error-reporting/builders/buildError';
export const performGetPresenceMessages = ({
  channel
}) => {
  return new Promise((resolve, reject) => {
    channel.presence.get((errorInfo, messages) => {
      if (errorInfo) {
        const error = buildError(`PubSub get presence messages failure: ${errorInfo.code}`, Object.assign({
          channelName: channel.name
        }, errorInfo));
        reject(error);
      } else {
        resolve(messages || []);
      }
    });
  });
};