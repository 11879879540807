import { getId } from 'find-and-filter-data/views-schema/public';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from '../../../local-storage/hooks/useLocalStorage';
export let AccordionState;
(function (AccordionState) {
  AccordionState["OPEN"] = "OPEN";
  AccordionState["CLOSED"] = "CLOSED";
})(AccordionState || (AccordionState = {}));
export function useCustomViewAccordion({
  views,
  currentViewId,
  storageKey
}) {
  const currentViewIsInList = useMemo(() => views.some(view => getId(view) === currentViewId), [views, currentViewId]);
  const initialValue = currentViewIsInList ? AccordionState.OPEN : AccordionState.CLOSED;
  const [storageState, updateStorageState] = useLocalStorage({
    key: storageKey,
    initialValue
  });
  const [accordionState, updateInternalAccordionState] = useState(currentViewIsInList ? AccordionState.OPEN : storageState);
  useEffect(() => {
    if (currentViewIsInList) {
      updateInternalAccordionState(AccordionState.OPEN);
    }
  }, [currentViewIsInList]);
  const toggleAccordionState = useCallback(() => {
    const nextState = accordionState === AccordionState.OPEN ? AccordionState.CLOSED : AccordionState.OPEN;
    updateInternalAccordionState(nextState);
    updateStorageState(nextState);
  }, [accordionState, updateStorageState, updateInternalAccordionState]);
  return [accordionState, toggleAccordionState];
}