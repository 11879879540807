import http from 'conversations-http/clients/http';
import { convertAssignableHumanToAssignee, convertRPCHumanToAssignee } from '../../operators/assignees';
import { chirpClient } from '../../../shared/utils/chirp-client';
import { getAssigneesRpc } from '../../../chirp/cv/assignments/rpc/AssigneeService';

/**
 * Check out the Schema:
 * [Schema](https://tools.hubteam.com/api-catalog/services/cv-inbox-settings-service/v1/spec/internal)
 */

export const searchAssigneesRpc = async ({
  objectTypeId,
  offset,
  searchQuery,
  workspaceId
}) => {
  const {
    results,
    hasMore
  } = await chirpClient.call(getAssigneesRpc, {
    request: {
      inboxId: workspaceId,
      limit: 50,
      objectTypeId,
      offset: offset || 0,
      searchQuery: searchQuery || null,
      userIdToExclude: null
    }
  });
  return {
    assignees: results ? results.map(result => convertRPCHumanToAssignee(result)) : null,
    hasMore
  };
};
export const searchAssignees = async queryParams => {
  const query = {
    workspaceId: queryParams.workspaceId,
    searchQuery: queryParams.searchQuery || null,
    offset: queryParams.offset,
    limit: 50
  };
  const {
    results,
    hasMore
  } = await http.get(`cv/inbox/settings/v1/assignee-search`, {
    query
  });
  return {
    assignees: results ? results.map(result => convertAssignableHumanToAssignee(result)) : null,
    hasMore
  };
};
const checkIsRPCParams = queryParams => Boolean(queryParams.objectTypeId);
export const fetchAssignees = queryParams => {
  return checkIsRPCParams(queryParams) ? searchAssigneesRpc(queryParams) : searchAssignees(queryParams);
};