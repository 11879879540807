export const FEATURED = 'FEATURED';
export const GENERATE = 'GENERATE';
export const FAVORITES = 'FAVORITES';
export const SUMMARIZE = 'SUMMARIZE';
export const RESEARCH = 'RESEARCH';
export const REPORTING = 'REPORTING';
export const ADD_AN_OBJECT = 'ADD_AN_OBJECT';
export const HUBSPOT_HOW_TO = 'HUBSPOT_HOW_TO';
export const PREPARE = 'PREPARE';
export const FEATURED_LABEL = 'Featured';
export const GENERATE_LABEL = 'Generate';
export const SUMMARIZE_LABEL = 'Summarize';
export const RESEARCH_LABEL = 'Research';
export const REPORTING_LABEL = 'Reporting';
export const ADD_AN_OBJECT_LABEL = 'Add an object';
export const HUBSPOT_HOW_TO_LABEL = 'HubSpot How-To';
export const PREPARE_LABEL = 'Prepare';
export const TEMPLATE_CATEGORIES = {
  [FEATURED]: {
    iconName: 'location',
    title: 'aiComponentsUiLibrary.components.prompts.promptTemplates.categories.featured.title',
    use: 'tertiary-light'
  },
  [FAVORITES]: {
    iconName: 'favoriteHollow',
    title: 'aiComponentsUiLibrary.components.prompts.promptTemplates.categories.favorites.title',
    use: 'tertiary-light'
  },
  [GENERATE]: {
    iconName: 'insertImage',
    title: 'aiComponentsUiLibrary.components.prompts.promptTemplates.categories.generate.title'
  },
  [SUMMARIZE]: {
    iconName: 'bulletDiamond',
    title: 'aiComponentsUiLibrary.components.prompts.promptTemplates.categories.summarize.title'
  },
  [RESEARCH]: {
    iconName: 'companies',
    title: 'aiComponentsUiLibrary.components.prompts.promptTemplates.categories.research.title'
  },
  [REPORTING]: {
    iconName: 'generateChart',
    title: 'aiComponentsUiLibrary.components.prompts.promptTemplates.categories.reporting.title'
  },
  [ADD_AN_OBJECT]: {
    iconName: 'table',
    title: 'aiComponentsUiLibrary.components.prompts.promptTemplates.categories.addAnObject.title'
  },
  [HUBSPOT_HOW_TO]: {
    iconName: 'sprocket',
    title: 'aiComponentsUiLibrary.components.prompts.promptTemplates.categories.hubspotHowTo.title'
  },
  [PREPARE]: {
    iconName: 'meetings',
    title: 'aiComponentsUiLibrary.components.prompts.promptTemplates.categories.prepare.title'
  }
};