import http from 'hub-http/clients/apiClient';
import { getQuickFetchOrMakeRequest } from '../earlyRequester/getQuickFetchOrMakeRequest';
export async function fetchQuoteSetting(quoteSetting, earlyRequesterKey = '') {
  const fetch = () => http.get(`/inbounddbquotes/v1/quote-settings/setting-value/${quoteSetting}`);
  let data;
  if (earlyRequesterKey.length) {
    data = await getQuickFetchOrMakeRequest(earlyRequesterKey, fetch);
  } else {
    data = await fetch();
  }
  return data.quoteSettingValue;
}
export function updateQuoteSetting({
  key,
  value
}) {
  return http.put('/inbounddbquotes/v1/quote-settings', {
    data: {
      quoteSettingKey: key,
      value
    }
  });
}