import { useMutation, gql } from '@apollo/client';
const SendPaymentReceiptEmail = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"mutation\",\"name\":{\"kind\":\"Name\",\"value\":\"SendPaymentReceiptEmail\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"SendPaymentReceiptEmailInput\"}}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"alias\":{\"kind\":\"Name\",\"value\":\"response\"},\"name\":{\"kind\":\"Name\",\"value\":\"sendPaymentReceiptEmail\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"httpStatus\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"userErrors\"}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "SendPaymentReceiptEmail"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "input"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "SendPaymentReceiptEmailInput"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        alias: {
          kind: "Name",
          value: "response"
        },
        name: {
          kind: "Name",
          value: "sendPaymentReceiptEmail"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "input"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "input"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "httpStatus"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "userErrors"
            }
          }]
        }
      }]
    }
  }]
});
const SendPaymentRefundEmail = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"mutation\",\"name\":{\"kind\":\"Name\",\"value\":\"SendPaymentRefundEmail\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"SendPaymentRefundEmailInput\"}}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"alias\":{\"kind\":\"Name\",\"value\":\"response\"},\"name\":{\"kind\":\"Name\",\"value\":\"sendPaymentRefundEmail\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"httpStatus\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"userErrors\"}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "SendPaymentRefundEmail"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "input"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "SendPaymentRefundEmailInput"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        alias: {
          kind: "Name",
          value: "response"
        },
        name: {
          kind: "Name",
          value: "sendPaymentRefundEmail"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "input"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "input"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "httpStatus"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "userErrors"
            }
          }]
        }
      }]
    }
  }]
});

// GQL response structure

export const useSendPaymentEmailMutation = ({
  selectedEmailId,
  objectId,
  emails,
  onSuccess,
  onError
}) => {
  const paymentReceiptEmailMutation = useMutation(SendPaymentReceiptEmail, {
    variables: {
      input: {
        toEmailAddress: emails[0],
        ccEmailAddresses: emails.slice(1),
        paymentObjectId: objectId
      }
    },
    onCompleted: ({
      response
    }) => {
      if (response.userErrors.length) {
        onError();
        return;
      }
      onSuccess();
    },
    onError
  });
  const paymentRefundEmailMutation = useMutation(SendPaymentRefundEmail, {
    variables: {
      input: {
        refundId: selectedEmailId,
        toEmailAddress: emails[0],
        ccEmailAddresses: emails.slice(1)
      }
    },
    onCompleted: ({
      response
    }) => {
      if (response.userErrors.length) {
        onError();
        return;
      }
      onSuccess();
    },
    onError
  });
  return selectedEmailId === objectId ? paymentReceiptEmailMutation : paymentRefundEmailMutation;
};