import { useMemo } from 'react';
import { registerQuery, useQuery } from 'data-fetching-client';
import fetchContentAssistanceSettings from '../clients/fetchContentAssistanceSettings';
const FETCH_CONTENT_ASSISTANCE_SETTINGS = registerQuery({
  fieldName: 'contentAssistanceSettings',
  fetcher: fetchContentAssistanceSettings
});
export const useContentAssistance = () => {
  const {
    data,
    error,
    loading
  } = useQuery(FETCH_CONTENT_ASSISTANCE_SETTINGS);
  const customerAnalysisEnabled = useMemo(() => {
    if (loading || error || !data) {
      return false;
    }
    return data.contentAssistanceSettings.customerAnalysisEnabled;
  }, [data, loading, error]);
  return {
    customerAnalysisEnabled
  };
};