import styled from 'styled-components';
const animationConfig = 'cubic-bezier(0.76, 0, 0.24, 1)';
export const StyledAIBaseLoadingButtonAnimation = styled.span.withConfig({
  displayName: "AILoadingButtonAnimations__StyledAIBaseLoadingButtonAnimation",
  componentId: "sc-1kmrndq-0"
})([".ai-loading-children{margin-left:0.5em;display:inline-block;vertical-align:bottom;&.animate{animation:6s 0.25s forwards labelAnim ", ";}}.ai-icons{display:inline-block;position:relative;bottom:1px;line-height:1em;max-height:1em;vertical-align:middle;&.animate{animation:6s 0.25s forwards iconsAnim ", ";}}.ai-loading-icon{position:relative;opacity:", ";&.animate{animation:6s 0.25s forwards sparkleAnim ", ";}}.ai-refresh-icon{opacity:", ";position:absolute;&.animate{animation:6s 0.25s forwards regenAnim ", ";}}@keyframes iconsAnim{0%{left:0;}11.6666666667%{left:calc(50% - 7px);}80%{left:calc(50% - 7px);}91.6666666667%{left:0;}}@keyframes regenAnim{0%{opacity:", ";}11.6666666667%{opacity:0;}80%{opacity:0;}91.6666666667%{opacity:1;}}@keyframes sparkleAnim{0%{transform:rotate(0deg);opacity:", ";}11.6666666667%{opacity:1;}15%{transform:rotate(0deg);}23.3333333333%{transform:rotate(90deg);}36.6666666667%{transform:rotate(90deg);}45%{transform:rotate(180deg);}58.3333333333%{transform:rotate(180deg);}66.6666666667%{transform:rotate(270deg);}80%{opacity:1;}91.6666666667%{transform:rotate(270deg);opacity:0;}}@keyframes labelAnim{0%{transform:translateX(0px);opacity:1;}11.6666666667%{transform:translateX(30px);opacity:0;}80%{transform:translateX(30px);opacity:0;}91.6666666667%{transform:translateX(0px);opacity:1;}}"], animationConfig, animationConfig, ({
  isRegenerate
}) => isRegenerate ? 0 : 1, animationConfig, ({
  isRegenerate
}) => isRegenerate ? 1 : 0, animationConfig, ({
  isRegenerate
}) => isRegenerate ? 1 : 0, ({
  isRegenerate
}) => isRegenerate ? 0 : 1);