import pipe from 'transmute/pipe';
import filter from 'transmute/filter';
import { getEmailMetadata } from 'conversations-message-history/common-message-format/operators/getEmailMetadata';
// @ts-expect-error module not typed
import { getMessages } from 'conversations-message-history/thread-history/operators/getters';
import { isMessagePublished } from 'conversations-message-history/common-message-format/operators/isMessagePublished';
// @ts-expect-error module not typed

export const findLatestSuccessfulEmail = pipe(getMessages,
// @ts-expect-error pipe's type inference is not correct
filter(getEmailMetadata), filter(isMessagePublished), emails => emails && emails.last());