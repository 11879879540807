import { Set as ImmutableSet } from 'immutable';
import { getUpdatedAt } from '../../async-data/operators/getters';
const DEFAULT_LIMIT = 100;
/**
 * @description A configurable eviction strategy creator that
 * limits the number of AsyncData entries to a specific count,
 * evicting the least recently updated entries first.
 *
 * Note: THIS EXPECTS `entries` TO BE A MAP OF `AsyncData` records.
 *
 * @param {Number} limit
 * @returns {Function} eviction strategy for AsyncData Entries
 */
export const limitToCount = (limit = DEFAULT_LIMIT) => entries => {
  if (entries.size < limit) return ImmutableSet();
  // sorts the entries with the most recently updated ones and ones
  // with `null` updatedAt values at the end of the list, then pulls
  // any overflow ones off the front to return for eviction.
  return entries.sort((a, b) => {
    const updatedA = getUpdatedAt(a);
    const updatedB = getUpdatedAt(b);
    if (updatedA === null) return 1;
    if (updatedB === null) return -1;
    if (updatedA > updatedB) return 1;
    if (updatedB > updatedA) return -1;
    return 0;
  }).take(entries.size - limit).reduce((accumulator, __entry, id) => accumulator.add(id), ImmutableSet());
};