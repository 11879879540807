import I18n from 'I18n';
import { getDynamicNamespace } from 'conversations-infrastructure/i18n/getDynamicNamespace';
import { getActorInfo } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { ACTOR_TYPES } from 'conversations-message-history/common-message-format/constants/actorTypes';
export const getFriendlySenderNameFallback = sender => {
  const [actorType] = getActorInfo(sender);
  const unknownSenderFallback = I18n.text(getDynamicNamespace({
    translationOptions: [...ACTOR_TYPES, 'default'],
    prefix: 'conversations-thread-view.threadHistory.unknownSender'
  }, actorType && ACTOR_TYPES.includes(actorType) ? actorType : 'default'));
  return unknownSenderFallback;
};