// this file is duplicated from: https://git.hubteam.com/HubSpot/conversations-internal-schema/blob/e5266dfa1be0a5514fd143fbdf92355c1fc10f4b/static/js/routing-rules/records/RoutingRuleBase.ts
import { Record as ImmutableRecord } from 'immutable';
import get from 'transmute/get';
import setIn from 'transmute/setIn';
import { DISTRIBUTIONS } from '../constants/distributions';
import { DISTRIBUTION, ROTATION_METHOD } from '../constants/keyPaths';
const ensureShouldFilterToAvailable = options => {
  const shouldFilterToAvailable = get('shouldFilterToAvailable', options);
  if (typeof shouldFilterToAvailable === 'boolean') {
    return options;
  }
  return setIn(['shouldFilterToAvailable'], true, options);
};
const ensureDistribution = options => {
  let distribution = get('distribution', options) || get('rotationMethod', options);
  let rotationMethod = distribution;
  if (distribution === DISTRIBUTIONS.LEAST_BUSY) {
    distribution = DISTRIBUTIONS.BALANCED;
  } else if (distribution !== DISTRIBUTIONS.BALANCED) {
    distribution = DISTRIBUTIONS.RANDOM;
    rotationMethod = DISTRIBUTIONS.RANDOM;
  } else {
    rotationMethod = DISTRIBUTIONS.LEAST_BUSY;
  }
  return setIn(ROTATION_METHOD, rotationMethod, setIn(DISTRIBUTION, distribution, options));
};
const ensureOptionalPropsPopulated = options => ensureDistribution(ensureShouldFilterToAvailable(options));

// Taking this approach from: https://github.com/facebook/immutable-js/issues/334#issuecomment-226097016
const RoutingRuleBase = (defaultValues, name) => {
  const realDefaults = Object.assign({}, defaultValues, {
    distribution: DISTRIBUTIONS.RANDOM,
    rotationMethod: DISTRIBUTIONS.RANDOM,
    shouldFilterToAvailable: true
  });
  return class RoutingRuleBaseFactory extends ImmutableRecord(realDefaults, name) {
    constructor(options) {
      let finalOptions;
      if (options) {
        finalOptions = ensureOptionalPropsPopulated(options);
      }
      super(finalOptions);
    }
  };
};
export default RoutingRuleBase;