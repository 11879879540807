export let UserPromptType;
(function (UserPromptType) {
  UserPromptType["SET_AVAILABILITY"] = "SET_AVAILABILITY";
  UserPromptType["ENABLE_SLA_IN_INBOX"] = "ENABLE_SLA_IN_INBOX";
  UserPromptType["CONVERSATIONS_REPORTS"] = "CONVERSATIONS_REPORTS";
  UserPromptType["INBOX_SLA_TOUR"] = "INBOX_SLA_TOUR";
  UserPromptType["WHATS_APP_UPGRADE_PROMPT"] = "WHATS_APP_UPGRADE_PROMPT";
  UserPromptType["CAPACITY_LIMITS"] = "CAPACITY_LIMITS";
  UserPromptType["CONTENT_ASSISTANT_ONBOARDING"] = "CONTENT_ASSISTANT_ONBOARDING";
  UserPromptType["CONVERSATION_SUMMARY_ONBOARDING"] = "CONVERSATION_SUMMARY_ONBOARDING";
  UserPromptType["EMAIL_AVAILABILITY"] = "EMAIL_AVAILABILITY";
  UserPromptType["VIEWS"] = "VIEWS";
  UserPromptType["SEARCH_AND_FILTER_MODE_BUTTON"] = "SEARCH_AND_FILTER_MODE_BUTTON";
  UserPromptType["AVAILABILITY_STATUS"] = "AVAILABILITY_STATUS";
  UserPromptType["CRM_CONTACT_CARD"] = "CRM_CONTACT_CARD";
})(UserPromptType || (UserPromptType = {}));