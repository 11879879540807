import { handleActions } from 'redux-actions';
import HostedEmailAccess from '../records/HostedEmailAccess';
import { SET_HOSTED_FROM_ADDRESS, SET_CAN_SHOW_SUSPENSION_ALERT } from '../constants/actionTypes';
const initialState = new HostedEmailAccess();
export default handleActions({
  [SET_HOSTED_FROM_ADDRESS]: (state, action) => {
    const {
      fromAddress
    } = action.payload;
    return state.set('fromAddress', fromAddress);
  },
  [SET_CAN_SHOW_SUSPENSION_ALERT]: (state, action) => {
    const {
      canShowAlert
    } = action.payload;
    return state.set('canShowAlert', canShowAlert);
  }
}, initialState);