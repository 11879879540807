'use es6';

import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { stringIdInvariant } from 'conversations-async-data/indexed-async-data/invariants/stringIdInvariant';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { deleteEntry } from 'conversations-async-data/indexed-async-data/operators/deleteEntry';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { limitToCount } from 'conversations-async-data/indexed-async-data/eviction-strategies/limitToCount';
import { TRUNCATED_MESSAGES_ASYNC_DATA_CACHE_SIZE } from 'conversations-thread-data/common/public/constants/CacheSize';
import { CMF_EMAIL_MESSAGE_FETCH } from 'conversations-thread-data/email-common-message-format/public/constants/asyncActionTypes';
const initialState = IndexedAsyncData({
  idInvariant: stringIdInvariant,
  name: 'messageTruncatedReplyRequestState',
  notSetValue: AsyncData({
    data: null
  }),
  evict: limitToCount(TRUNCATED_MESSAGES_ASYNC_DATA_CACHE_SIZE)
});
export default handleActions({
  [CMF_EMAIL_MESSAGE_FETCH.STARTED](state, action) {
    const {
      requestArgs: {
        messageId
      }
    } = action.payload;
    return updateEntry(messageId, requestStarted, state);
  },
  [CMF_EMAIL_MESSAGE_FETCH.FAILED](state, action) {
    const {
      requestArgs: {
        messageId
      }
    } = action.payload;
    return updateEntry(messageId, requestFailed, state);
  },
  [CMF_EMAIL_MESSAGE_FETCH.SUCCEEDED](state, action) {
    const {
      requestArgs: {
        messageId
      }
    } = action.payload;
    return deleteEntry(messageId, state);
  }
}, initialState);