import http from 'hub-http/clients/apiClient';
export function fetchFilterOptions() {
  return http.get('zorse-ticket-inspector/filter-options');
}
export function searchTickets(searchTerms, filters) {
  return http.post('zorse-ticket-inspector/search', {
    data: {
      filters,
      searchTerms
    }
  });
}
export function searchMoreTickets(searchTerms, filters, lastId) {
  return http.post('zorse-ticket-inspector/search', {
    data: {
      filters,
      lastId,
      searchTerms
    }
  });
}