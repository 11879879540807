import { List as ImmutableList } from 'immutable';
import get from 'transmute/get';
import { buildEmailMetadataForRecipients } from './buildEmailMetadataForRecipients';
import { buildInitialEmailRecipients } from 'conversations-message-history/common-message-format/operators/buildCommonMessageRecipients';
// @ts-expect-error module not typed

export const getInitialRecipientsForEmail = ({
  asyncCommonMessage,
  channelInstances,
  connectedAccounts,
  threadDetails,
  replyAll,
  visitorEmail,
  isForward
}) => {
  const recipientEmailMetadata = buildEmailMetadataForRecipients({
    channelInstances,
    connectedAccounts,
    asyncCommonMessage,
    threadDetails,
    replyAll,
    visitorEmail,
    isForward
  });
  return buildInitialEmailRecipients({
    to: recipientEmailMetadata && get('to', recipientEmailMetadata) || ImmutableList(),
    cc: recipientEmailMetadata && get('cc', recipientEmailMetadata) || ImmutableList(),
    bcc: recipientEmailMetadata && get('bcc', recipientEmailMetadata) || ImmutableList()
  }).toList();
};