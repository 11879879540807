import http from 'conversations-http/clients/http';
// @ts-expect-error module not typed
import { buildAgentRequestData } from '../operators/buildAgentRequestData';
// @ts-expect-error module not typed
import { buildSenderObjectsFromAgentsResponse } from '../operators/buildSenderObjectsFromAgentsResponse';
export const getAgentSenders = ({
  senderLocators
}) => {
  const data = buildAgentRequestData({
    senderLocators
  });
  return http.put('messages/v2/message/agents/batch', {
    data,
    query: {
      accessType: 'INTERNAL',
      shouldHydrateActivationStatus: true
    }
  }).then(buildSenderObjectsFromAgentsResponse);
};