'use es6';

export const CREATE_DATE = 'hs_createdate';
export const LAST_MODIFIED_DATE = 'hs_lastmodifieddate';
export const OWNER_ID = 'hubspot_owner_id';
export const TEAM_ID = 'hubspot_team_id';
export const OWNER_ASSIGNED_DATE = 'hubspot_owner_assigneddate';
export const ENGAGEMENT_TYPE = 'hs_engagement_type';
// This corresponds to the objectId returned with an object
export const OBJECT_ID = 'hs_object_id';
// this property is used like a foreign key to relate back to a sequence or workflow
export const ENGAGEMENT_ID = 'hs_unique_id';
export const ENGAGEMENT_TIMESTAMP = 'hs_timestamp';
export const ACTIVITY_TYPE = 'hs_activity_type';
export const ENGAGEMENT_SOURCE = 'hs_engagement_source';
export const ENGAGEMENT_SOURCE_ID = 'hs_engagement_source_id';
export const AT_MENTIONED_OWNERS = 'hs_at_mentioned_owner_ids';
export const CREATED_BY = 'hs_created_by';
export const MODIFIED_BY = 'hs_modified_by';
export const ATTACHMENT_IDS = 'hs_attachment_ids';
export const ALL_ACCESSIBLE_TEAM_IDS = 'hs_all_accessible_team_ids';

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
if (!!module && !!module.exports) {
  module.exports.default = Object.assign({}, module.exports);
}