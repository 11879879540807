import { useSelector } from 'react-redux';
import { isInSearchAndFilterMode as getIsInSearchAndFilterMode } from 'conversations-inbox-lib/thread-search-and-filtering/public/selectors';
import { selectFilteredThreadListMemberWithThreadId } from 'find-and-filter-data/search-and-filter-data/protected';
import { getThreadListMemberByThreadId } from '../../thread-list-member/selectors/getThreadListMemberByThreadId';
import { getInitialThreadViewState } from 'conversations-inbox-lib/initialThreadView/public/selectors';
import { getConversationId } from 'find-and-filter-data/view-members-schema/protected';
export const useConversationIdByThreadId = ({
  threadId
}) => {
  const threadListMemberRealtime = useSelector(state => getThreadListMemberByThreadId(state, {
    threadId
  }));
  const threadListMemberFilterResult = useSelector(state => selectFilteredThreadListMemberWithThreadId(state, {
    threadId
  }));
  const threadViewStateFromOmnibus = useSelector(getInitialThreadViewState);
  const threadDetailsFromOmnibus = threadViewStateFromOmnibus ? threadViewStateFromOmnibus.threadDetails : undefined;
  const isInSearchAndFilterMode = useSelector(getIsInSearchAndFilterMode);
  const threadListMember = isInSearchAndFilterMode ? threadListMemberFilterResult : threadListMemberRealtime || threadDetailsFromOmnibus;
  return {
    conversationId: getConversationId(threadListMember)
  };
};