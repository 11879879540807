import { createSelector } from 'reselect';
import { WHATSAPP_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
// @ts-expect-error commonMessageFormatGetters is not typed
import { getTimestamp } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { getLatestVisitorMessage } from 'conversations-message-history/thread-history/operators/getLatestVisitorMessage';
// @ts-expect-error getFocusedThreadOriginalGenericChannelId is not typed
import { getFocusedThreadOriginalGenericChannelId } from '../../thread-details/selectors/getFocusedThreadOriginalGenericChannelId';
// @ts-expect-error file not typed
import { getCurrentThreadHistory } from 'conversations-thread-data/thread-histories/public/selectors/getCurrentThreadHistory';
const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

/**
 *
 * The visitor has not replied in over 24 hours.
 * The agent cannot publish any additional replies.
 *
 */
export const hasReachedWhatsAppReplyLimit = createSelector([getFocusedThreadOriginalGenericChannelId, getCurrentThreadHistory], (originalGenericChannelId, threadHistory) => {
  if (originalGenericChannelId !== WHATSAPP_GENERIC_CHANNEL_ID) return false;
  const latestVisitorMessage = getLatestVisitorMessage(threadHistory, originalGenericChannelId);
  return getTimestamp(latestVisitorMessage) < Date.now() - ONE_DAY_IN_MS;
});
const getChannelIdFromProps = (___state, {
  editorConfigurationKey
} = {}) => {
  return editorConfigurationKey || null;
};
export const getCurrentChannelWALimitReached = createSelector([getChannelIdFromProps, getCurrentThreadHistory], (editorConfigurationKey, threadHistory) => {
  if (editorConfigurationKey !== WHATSAPP_GENERIC_CHANNEL_ID) return false;
  const latestVisitorMessage = getLatestVisitorMessage(threadHistory, WHATSAPP_GENERIC_CHANNEL_ID);
  return getTimestamp(latestVisitorMessage) < Date.now() - ONE_DAY_IN_MS;
});