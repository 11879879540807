'use es6';

export const BLACKLISTED_STYLES = ['position', 'top', 'bottom', 'left', 'right', 'z-index', 'clear', 'float', 'background-image', 'white-space'];
export const removeStyles = ({
  node
}) => {
  if (node && node.style && node.style.length > 0 && node.style.removeProperty) {
    BLACKLISTED_STYLES.forEach(style => node.style.removeProperty(style));
    return {
      node
    };
  }
  return null;
};