import enviro from 'enviro';
import I18n from 'I18n';
import { CALLING_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
import { useChannelInstanceById } from 'conversations-thread-data/channel-instances/public/hooks';
import { getOriginalChannelInstanceId, getOriginalGenericChannelId } from 'conversations-thread-data/thread-details/public/operators';
export const useShouldLoadInboxCallingCSAT = threadDetails => {
  const originalGenericChannelId = getOriginalGenericChannelId(threadDetails);
  const channelInstanceId = getOriginalChannelInstanceId(threadDetails);
  const {
    channelInstance
  } = useChannelInstanceById({
    /*  @ts-expect-error Jun-5-2024, 19:34UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
    channelInstanceId
  });
  const channelCreatedAt = channelInstance && channelInstance.createdAt;
  const daysSinceChannelCreated = channelCreatedAt && I18n.moment().diff(I18n.moment(channelCreatedAt), 'days');
  const shouldLoadInboxCallingCSAT = enviro.isProd() && originalGenericChannelId === CALLING_GENERIC_CHANNEL_ID && daysSinceChannelCreated && daysSinceChannelCreated > 1;
  return {
    shouldLoadInboxCallingCSAT
  };
};