'use es6';

import { createAction } from 'redux-actions';
import { READ_RECEIPT_RECEIVED } from '../constants/actionTypes';
export const readReceiptReceived = createAction(READ_RECEIPT_RECEIVED, ({
  message,
  threadId
}) => ({
  message,
  threadId
}));