import { List } from 'immutable';
import Inbox from 'conversations-internal-schema/inbox/records/Inbox';
import { SUCCEEDED } from 'conversations-async-data/async-data/constants/asyncStatuses';
//@ts-expect-error no types
import { getInboxId } from 'conversations-internal-schema/inbox/operators/getInboxId';
export const buildUnifiedInboxFromInboxesRequest = action => {
  const inboxes = List(action.payload.data.map(inbox => new Inbox(inbox)));
  const currentInbox = inboxes.find(inbox => {
    return `${getInboxId(inbox)}` === `${action.payload.requestArgs.inboxId}`;
  });
  return {
    inboxes,
    inboxRequestStatus: SUCCEEDED,
    currentInbox,
    lastAccessedInboxId: action.payload.requestArgs.inboxId
  };
};