import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSearch, SEARCH } from '../../search-and-filter-schema/protected';
import { clearFilterResults } from './filterResultsSlice';
import { selectHasFiltersSet as getselectHasFiltersSet } from './selectHasFiltersSet';
import { useFilterValues } from './useFilterValues';
export const useSearchValue = () => {
  const {
    clearSearchText,
    filterValues,
    setFilterValue
  } = useFilterValues();
  const searchValue = getSearch(filterValues);
  const selectHasFiltersSet = useSelector(getselectHasFiltersSet);
  const dispatch = useDispatch();
  const setSearchValue = useCallback(searchText => {
    setFilterValue(SEARCH, searchText);
  }, [setFilterValue]);
  const clearSearchValue = useCallback(() => {
    clearSearchText();
    if (!selectHasFiltersSet) {
      dispatch(clearFilterResults());
    }
  }, [clearSearchText, dispatch, selectHasFiltersSet]);
  return {
    searchValue,
    setSearchValue,
    clearSearchValue
  };
};