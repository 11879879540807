import { getThreadId, getSeen } from 'find-and-filter-data/view-members-schema/protected';
import { OrderedMap } from 'immutable';
import curry from 'transmute/curry';
import setIn from 'transmute/setIn';
import { indexThreadListMembers } from '../../../view-members-data/protected';
import { getThreadRemovalMembers } from '../removal/threadRemovalGetters';
import { setThreadRemovalMembers } from '../removal/threadRemovalSetters';
import { getThreadUpdateMembers } from '../threadUpdateGetters';
import { mergeIndexedViewMembers } from './mergeIndexedViewMembers';
export const setThreadUpdateMembers = setIn(['members']);
const setSeen = setIn(['seen']);
const resolveSeen = (currentMember, addedMember) => {
  const addedSeenValue = getSeen(addedMember);
  const shouldUseCurrentMemberSeenValue = addedSeenValue === null && currentMember;
  return shouldUseCurrentMemberSeenValue ? setSeen(getSeen(currentMember), addedMember) : addedMember;
};

/**
 * Merges added members into the indexed map of ThreadListMembers
 * @param addedMembers
 * @param currentMembers
 */
export const mergeAdded = curry((addedMembers, currentMembers) => {
  const addedMembersWithNonNullSeen = addedMembers.map(addedMember => {
    const threadId = getThreadId(addedMember);
    const currentMember = currentMembers.get(`${threadId}`);
    return resolveSeen(currentMember, addedMember);
  }).toList();
  return currentMembers.merge(indexThreadListMembers(addedMembersWithNonNullSeen));
});

/**
 * Merges updated members into the indexed map of ThreadListMembers, creating
 * new ones if needed.
 * @param updatedMembers
 * @param currentMembers
 */
export const mergeUpdated = curry((incomingMemberChanges, currentMembers) => {
  const partialMembersWithUpdates = incomingMemberChanges.reduce((acc, update) => {
    const membersWithUpdates = getThreadUpdateMembers(update).map(partialMember => setIn(['update'], setThreadUpdateMembers(null, update), partialMember));
    const indexedMembers = indexThreadListMembers(membersWithUpdates);
    return acc.merge(indexedMembers);
  }, OrderedMap());
  const merged = mergeIndexedViewMembers(currentMembers, partialMembersWithUpdates);
  return merged;
});
export const mergeRemovals = curry((removals, currentMembers) => {
  const partialMembersWithRemovals = removals.reduce((accumulator, removal) => {
    const members = getThreadRemovalMembers(removal);
    const indexedMembersWithRemovals = members.reduce((membersAccumulator, partialMember) => {
      const threadId = getThreadId(partialMember);
      const partialMemberWithRemoval = setIn(['removal'],
      // @ts-expect-error members is typed to not be null, but leaving this to avoid logic changes
      setThreadRemovalMembers(null, removal), partialMember);
      return membersAccumulator.set(`${threadId}`, partialMemberWithRemoval);
    }, OrderedMap());
    return accumulator.merge(indexedMembersWithRemovals);
  }, OrderedMap());
  const merged = mergeIndexedViewMembers(currentMembers, partialMembersWithRemovals);
  return merged;
});