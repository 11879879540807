import { useEffect } from 'react';
import { useThreadDataDispatch, useThreadDataSelector } from '../../../common/ThreadViewDataStore';
import { fetchChannelInstanceById } from '../actions/fetchChannelInstanceById';
import { getChannelInstanceById } from '../selectors/getChannelInstanceById';
import { getIsChannelInstanceFetchedById } from '../selectors/getIsChannelInstanceFetchedById';
import { getIsChannelInstanceFailedById } from '../selectors/getIsChannelInstanceFailedById';
export const useChannelInstanceById = ({
  channelInstanceId,
  deferred = false
}) => {
  const dispatch = useThreadDataDispatch();
  const channelInstance = useThreadDataSelector(state => getChannelInstanceById(state, {
    channelInstanceId
  }));
  const isLoading = useThreadDataSelector(state => getIsChannelInstanceFetchedById(state, {
    channelInstanceId
  }));
  const isFailed = useThreadDataSelector(state => getIsChannelInstanceFailedById(state, {
    channelInstanceId
  }));
  const shouldFetch = !channelInstance && !!channelInstanceId && !deferred && !isLoading;
  useEffect(() => {
    if (shouldFetch && !isFailed) {
      dispatch(fetchChannelInstanceById({
        channelInstanceId
      }));
    }
  }, [dispatch, shouldFetch, channelInstanceId, isLoading, isFailed]);
  return {
    channelInstance,
    isLoading,
    isFailed
  };
};