import { getRecipients } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';

// TODO: move to composer-data

// TODO: move to composer-data
import { getCommonMessage } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
// TODO: move to composer-data
import invariant from 'conversations-thread-data/utils/invariant';
export const recipientLimitExceeded = ({
  reply,
  isNewEmailComposition,
  maxRecipientNew,
  maxRecipientReply
}) => {
  invariant(Boolean(reply), 'reply is required for checking for recipient count');
  const commonMessage = getCommonMessage(reply);
  const recipients = getRecipients(commonMessage); // remove once commonMessageGetters is converted to ts and returns correct types

  if (isNewEmailComposition) {
    const shouldDisableNew = maxRecipientNew && recipients.size > maxRecipientNew;
    return shouldDisableNew ? {
      disabled: true,
      sendDisabledReason: 'NEW_RECIPIENT_LIMIT_EXCEEDED',
      options: {
        maxRecipientNew
      }
    } : null;
  }
  const shouldDisableReply = maxRecipientReply && recipients.size > maxRecipientReply;
  return shouldDisableReply ? {
    disabled: true,
    sendDisabledReason: 'REPLY_RECIPIENT_LIMIT_EXCEEDED',
    options: {
      maxRecipientReply
    }
  } : null;
};