import { getEmail, getDisplayGlobally, getFriendlyFromName, getResolvedFromName, getSendFromEmail
// @ts-expect-error module not typed
} from 'conversations-internal-schema/connected-email-account/operators/connectedEmailAccountGetters';
import { getId, getInstanceDeliveryIdentifier, getIsActive } from 'conversations-thread-data/channel-instances/public/operators';
import { getDeliveryIdentifierValue } from 'conversations-message-history/common-message-format/operators/deliveryIdentifierGetters';
export const getChannelInstanceOptions = ({
  connectedAccounts,
  channelInstances
}) => {
  if (!channelInstances) return [];
  const dropdownOptions = channelInstances.reduce((accumulator, instance) => {
    const deliveryIdentifier = getInstanceDeliveryIdentifier(instance);
    const address = getDeliveryIdentifierValue(deliveryIdentifier);
    const disabled = !getIsActive(instance);
    const connectedAccount = connectedAccounts && connectedAccounts.find(account => {
      const connectedEmail = getEmail(account);
      return connectedEmail && connectedEmail.toLowerCase() === address.toLowerCase();
    });
    if (connectedAccount && !getDisplayGlobally(connectedAccount)) {
      return accumulator;
    }
    const friendlyName = connectedAccount ? getResolvedFromName(connectedAccount) || getFriendlyFromName(connectedAccount) : null;
    const displayAddress = connectedAccount && getSendFromEmail(connectedAccount) || address;
    const text = friendlyName ? `${friendlyName} ( ${displayAddress} )` : displayAddress;
    return [...accumulator, {
      id: getId(instance),
      disabled,
      dropdownText: displayAddress,
      text,
      value: address
    }];
  }, []);

  // sort alphabetically w/ disabled options last
  return dropdownOptions.sort((optionA, optionB) => {
    if (optionA.disabled === optionB.disabled) {
      return optionA.dropdownText < optionB.dropdownText ? -1 : 1;
    }
    return optionA.disabled ? 1 : -1;
  });
};