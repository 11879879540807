import { Record } from 'immutable';
const ChannelInstance = Record({
  channelId: null,
  createdAt: null,
  deletedAt: null,
  id: null,
  inboxId: null,
  isActive: false,
  isAuthorized: false,
  name: null,
  portalId: null,
  routingRulesId: null,
  ticketRulesId: null,
  instanceDeliveryIdentifier: undefined,
  instanceDeliveryIdentifierType: null,
  instanceDeliveryIdentifierValue: null
}, 'ChannelInstance');
export default ChannelInstance;