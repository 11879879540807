import { Map as ImmutableMap } from 'immutable';
import { handleActions } from 'redux-actions';
import * as RequestStatusTypes from 'conversations-internal-schema/constants/RequestStatusTypes';
import { FETCH_FBM_INSTALL } from '../constants/asyncActionTypes';
const initialState = ImmutableMap({
  config: null,
  requestStatus: RequestStatusTypes.UNINITIALIZED
});
export default handleActions({
  [FETCH_FBM_INSTALL.STARTED](state) {
    return state.set('requestStatus', RequestStatusTypes.PENDING);
  },
  [FETCH_FBM_INSTALL.SUCCEEDED](state, action) {
    return state.merge({
      requestStatus: RequestStatusTypes.SUCCEEDED,
      config: action.payload.data
    });
  },
  [FETCH_FBM_INSTALL.FAILED](state) {
    return state.set('requestStatus', RequestStatusTypes.FAILED);
  }
}, initialState);