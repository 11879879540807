import PortalIdParser from 'PortalIdParser';
import { createIFramedApplication } from '../utils/createIFramedApplication';

/**
 * The following is an example and not meant to be exported
 * It shows setting up an iframed app and adding custom events
 *
 * @example
 * type CustomClosePayload = { updateId: number };
 * type CustomInitPayload = { customData: { id: number } };
 *
 * const someAppWithCustomEvents = createIframeApp<
 *   CustomInitPayload,
 *   CustomClosePayload,
 *   {
 *     CUSTOM_EVENT: { customData: Record<string, string> };
 *   } & DefaultSendableEvents<CustomClosePayload>,
 *   {
 *     OTHER_CUSTOM_EVENT: boolean;
 *   } & DefaultReceivableEvents<CustomInitPayload>
 * >({
 *   getIframeSrc: () => '/custom-app/url',
 *   appName: 'some-custom-ui',
 *   id: 'another-object-admin',
 * });
 *
 * const { getEmbeddedIFrameHandler, getHostIFrameHandler } =
 *   someAppWithCustomEvents.iframeHandlers;
 *
 * getEmbeddedIFrameHandler({} as any).on('OTHER_CUSTOM_EVENT', (eventBool) =>
 *   eventBool ? console.log('truthy') : console.log('falsy')
 * );
 *
 * getHostIFrameHandler({} as any)
 *   .on('CUSTOM_EVENT', (evt) => evt.customData.thing)
 *   .on(MessageTypes.MODAL_HIDDEN, (closePayload) => {
 *     console.log(
 *       'error' in closePayload ? closePayload.error : closePayload.updateId
 *     );
 *   });
 */

export const subscriptionsAdminIframeApp = createIFramedApplication({
  getIframeSrcPath: () => `subscriptions-admin/${PortalIdParser.get()}/embedded`,
  appName: 'subscriptions-admin-ui',
  id: 'subscriptions-admin-iframe'
});
export const billingAdminIframeApp = createIFramedApplication({
  getIframeSrcPath: () => `billing-wizard-ui/${PortalIdParser.get()}/embedded`,
  appName: 'billing-wizard-ui',
  id: 'commerce-billing-admin'
});