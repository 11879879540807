import { getGenericChannelIdForMessage } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getMessageType } from 'conversations-message-history/configured-message-type/operators/configuredMessageTypeGetters';
import { isMessagePublished } from 'conversations-message-history/common-message-format/operators/isMessagePublished';
// @ts-expect-error cmfComparators is not typed
import { isCommonMessageFormat } from 'conversations-message-history/common-message-format/operators/cmfComparators';
// @ts-expect-error cmComparators is not typed
import { isThreadComment } from 'conversations-message-history/comment-message/operators/cmComparators';
import { getUsedGenericChannelId } from 'conversations-thread-data/thread-details/public/operators';
// @ts-expect-error getLatestGenericChannelUsed is not typed
import { getLatestGenericChannelUsed } from './getLatestGenericChannelUsed';
/**
 * @description Returns the genericChannelId of the latest message in
 * ThreadHistory (including comment messages). If no messages are passed in,
 * it will derive this from the genericChannelsUsed field within ThreadDetails.
 */

export const getLatestMessageEditorKey = ({
  messages,
  channelsUsed
}) => {
  const latestPublishedMessage = messages && messages.findLast(message => (isCommonMessageFormat(message) || isThreadComment(message)) && isMessagePublished(message));
  if (!latestPublishedMessage) {
    return getUsedGenericChannelId(getLatestGenericChannelUsed(channelsUsed));
  }
  const messageIdentifier = isCommonMessageFormat(latestPublishedMessage) ? getGenericChannelIdForMessage(latestPublishedMessage) : getMessageType(latestPublishedMessage);
  return messageIdentifier;
};