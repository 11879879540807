import { Record } from 'immutable';
import { FUNNEL_STAGE, FUNNEL_STAGE_OVERRIDE_VALUES } from '../constants/ConversationsProperties';
class PropertyRecord extends Record({
  name: undefined,
  value: undefined,
  revisionCode: 0
}, 'PropertyRecord') {
  shouldResetOtherProperties() {
    return this.name === FUNNEL_STAGE &&
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
    FUNNEL_STAGE_OVERRIDE_VALUES.includes(this.value);
  }
}
export default PropertyRecord;