import Raven from 'raven-js';
import { useEffect, useState } from 'react';
import { fetchThreadDetails } from '../clients/fetchThreadDetails';
import { fetchChannelQuota } from '../clients/fetchChannelQuota';
import { WHATSAPP_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
export default function useWAThreadDetails(props) {
  const {
    sender,
    recipient
  } = props;
  const [threadDetails, setThreadDetails] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (sender && recipient) {
      setLoading(true);
      fetchChannelQuota({
        genericChannelId: WHATSAPP_GENERIC_CHANNEL_ID,
        sender,
        recipient
      }).then(resp => {
        if (resp.threadId) {
          fetchThreadDetails({
            threadId: resp.threadId
          }).then(details => {
            setThreadDetails(Object.assign({
              details
            }, resp));
            setLoading(false);
          }).catch(e => {
            Raven.captureException(e);
            setLoading(false);
          });
        } else {
          setThreadDetails(resp);
          setLoading(false);
        }
      }).catch(e => {
        Raven.captureException(e);
        setLoading(false);
      });
    }
  }, [sender, recipient]);
  return {
    threadDetails,
    loading
  };
}