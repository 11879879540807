import HistorySingleton from '../records/HistorySingleton';
import { replace } from './replace';
import { push } from './push';
export const setLocation = ({
  location,
  preserveSearch = true,
  preserveHash = false,
  replace: replacePath = false
}) => dispatch => {
  const history = HistorySingleton().instance();
  let search = '';
  let hash = '';
  if (preserveSearch) {
    search = history.location.search || '';
  }
  if (preserveHash) {
    hash = history.location.hash || '';
  }
  const nextPath = `${location}${search}${hash}`;
  if (replacePath) {
    dispatch(replace(nextPath));
  } else {
    dispatch(push(nextPath));
  }
};