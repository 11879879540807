const capitalizeFirstLetter = value => {
  const firstLetter = value.charAt(0).toUpperCase();
  const remainingLetters = value.slice(1);
  return `${firstLetter}${remainingLetters}`;
};
const tzDisplayName = tzLabel => {
  /*
   * Formatting the display label of the timezone because by default
   * it comes with the region in the text
   * tzLabel looks like "UTC -04:00 America Boa Vista"
   */

  const [utc, offset, __continent, ...country] = tzLabel.split(' ');
  return [utc, offset, ...country].join(' ');
};
export const formatTimezoneList = timezoneList => {
  const tzByRegion = [];
  const lookup = {};
  const separator = '/';
  for (const tzOption of timezoneList) {
    const [tzRegion] = tzOption.value.split(separator);
    let tzRegionObj = lookup[tzRegion];
    if (!tzRegionObj) {
      tzRegionObj = {
        options: [],
        text: capitalizeFirstLetter(tzRegion),
        value: tzRegion
      };
      tzByRegion.push(tzRegionObj);
      lookup[tzRegion] = tzRegionObj;
    }
    tzRegionObj.options = [...tzRegionObj.options, {
      text: tzDisplayName(tzOption.label),
      value: tzOption.value
    }];
  }
  return tzByRegion;
};