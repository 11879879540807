'use es6';

import { getRecordName } from '../../../util/getRecordName';
import invariant from 'react-utils/invariant';
import memoize from 'transmute/memoize';
import { getPlainText, getRichText } from '../../../common-message-format/operators/commonMessageFormatGetters';
import CommonMessage from '../../../common-message-format/records/CommonMessage';
import InitialMessage from '../../../initial-message/records/InitialMessage';
import TypicalResponseTimeMessage from '../../../typical-response-time/records/TypicalResponseTimeMessage';
import { getEvaluatedTextContent } from './getEvaluatedTextContent';
import { getHasReplies } from '../../../common-message-format/operators/emailMetadataGetters';
import PartialMessage from '../../../partial-message/records/PartialMessage';
const supportedRecordTypes = [CommonMessage, InitialMessage, TypicalResponseTimeMessage, PartialMessage];
export const hasTextContent = memoize(message => {
  setTimeout(() => {
    invariant(supportedRecordTypes.some(recordType => message instanceof recordType), 'hasTextContent: Unsupported message record type %s supplied', getRecordName(message));
  }, 0);
  const hasReplies = getHasReplies(message);
  const html = getRichText(message) || getPlainText(message);
  return hasReplies || Boolean(html && html.length && getEvaluatedTextContent(html));
});