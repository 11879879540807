import { createAction } from 'redux-actions';
import { buildRequestErrorMetaObject } from 'conversations-error-reporting/error-actions/builders/buildRequestErrorMetaObject';
import I18n from 'I18n';
const LIMIT_REACHED_ERROR = 'Maximum custom views reached.';
export const CREATE_CUSTOM_VIEW_FAILED = 'CREATE_CUSTOM_VIEW_FAILED';
export const createViewError = createAction(CREATE_CUSTOM_VIEW_FAILED, error => ({
  error
}), error => {
  if (error.message.includes(LIMIT_REACHED_ERROR)) {
    return buildRequestErrorMetaObject({
      error,
      titleText: I18n.text('conversations-inbox-ui.custom-views.createWizard.serverError.title'),
      message: I18n.text('conversations-inbox-ui.custom-views.createWizard.serverError.message')
    });
  }
  return error;
});