import { COMPOSITION_THREAD_ID } from 'conversations-thread-data/communicator/constants/compositionThreadId';
import Raven from 'raven-js';
import { createFormDataForFileUpload } from '../util/createFormDataForFileUpload';
import { createFileMapping } from './createFileMapping';
import { postFile } from './postFile';
export const uploadFile = ({
  file,
  access,
  onProgress = () => {},
  threadId
}) => {
  const formData = createFormDataForFileUpload({
    file,
    access
  });
  const postFilePromise = postFile({
    formData,
    onProgress
  });
  const requestWithXhr = Object.assign(new Promise((resolve, reject) => {
    postFilePromise.then(uploadedFile => {
      const fileId = uploadedFile.id;
      const shouldCreateFileMapping = threadId !== COMPOSITION_THREAD_ID;
      if (shouldCreateFileMapping) {
        return createFileMapping({
          fileId,
          threadId
        }).then(() => resolve(uploadedFile)).catch(err => {
          Raven.captureException(new Error(`Create file mapping failed: ${err.message}`), {
            extra: {
              error: err
            }
          });
        });
      }
      resolve(uploadedFile);
      return Promise.resolve();
    }).catch(error => {
      reject(error);
    });
  }), {
    mutableXhrWrapper: {}
  });
  // set mutableXhrWrapper object synchronously on the returned request
  // the mutation to set the xhr value will happen asynchronously in the withXhr callback in postFile
  requestWithXhr.mutableXhrWrapper = postFilePromise.mutableXhrWrapper;
  return requestWithXhr;
};