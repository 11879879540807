import I18n from 'I18n';
import { getDynamicNamespace } from 'conversations-infrastructure/i18n/getDynamicNamespace';
import { CONTACT, LOCATION, QUICK_REPLIES, UNSUPPORTED_CONTENT } from 'conversations-message-history/common-message-format/constants/attachmentTypes';
const FALLBACK_ICON = 'warning';
const attachmentTypeIcons = {
  [CONTACT]: 'contacts',
  [LOCATION]: 'location',
  [QUICK_REPLIES]: 'questionAnswer'
};
export const formatCommonAttachmentData = attachment => {
  const knownTypes = Object.keys(attachmentTypeIcons);
  const type = knownTypes.includes(attachment['@type']) ? attachment['@type'] : UNSUPPORTED_CONTENT;
  const friendlyType = I18n.text(getDynamicNamespace({
    prefix: 'conversations-inbox-message-history.attachments.type',
    translationOptions: [...knownTypes, UNSUPPORTED_CONTENT],
    defaultOption: UNSUPPORTED_CONTENT
  }, type));
  const icon = attachmentTypeIcons[type] || FALLBACK_ICON;
  return {
    icon,
    name: null,
    type: friendlyType,
    url: null
  };
};