import promiseTimeout from 'hs-promise-utils/timeout';
import { buildRequestTimeoutError } from '../../errors/builders/buildRequestTimeoutError';
import { PROMISE_TIMEOUT_MS } from '../constants/defaults';
import { CONNECTED, FAILED } from '../constants/connectionStates';
import { statusChangeToError } from '../operators/statusChangeToError';
export function waitForConnection(connection) {
  return promiseTimeout(new Promise((resolve, reject) => {
    if (connection.state === CONNECTED) {
      resolve(undefined);
    } else {
      connection.once(CONNECTED, resolve);
      connection.once(FAILED, statusChange => reject(statusChangeToError('PubSub connection failed', statusChange)));
    }
  }), PROMISE_TIMEOUT_MS, buildRequestTimeoutError('PubSub wait for connection timeout'));
}