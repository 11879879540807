import { Map as ImmutableMap } from 'immutable';
import makeObjectRecord from '../record/makeObjectRecord';
import ObjectIds from '../constants/ObjectIds';
import { FOLDER } from '../constants/ObjectTypes';
const FolderRecord = makeObjectRecord({
  idKey: ObjectIds[FOLDER],
  objectType: FOLDER,
  recordName: 'FolderRecord',
  defaults: {
    folderId: null,
    objectTypeId: null,
    portalId: null,
    name: '',
    createDate: null,
    createdBy: null,
    updatedBy: null,
    properties: ImmutableMap()
  }
}, {
  primary: ['name']
});
FolderRecord.fromJS = json => {
  const stringId = Object.prototype.hasOwnProperty.call(json, 'folderId') ? String(json.folderId) : null;
  return FolderRecord(Object.assign({}, json, {
    folderId: stringId
  }));
};
export default FolderRecord;