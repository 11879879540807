import { List } from 'immutable';
import getIn from 'transmute/getIn';
import { getCurrentInboxChannels } from '../selectors/getCurrentInboxChannels';
import { fetchViews, selectViewRealtimeChannel } from 'find-and-filter-data/views-data/public';
import { handleInboxChannelMessage, handleViewChannelMessage, handleViewChannelMessages } from 'find-and-filter-data/realtime-updates/public';
import { getCurrentViewId } from '../../thread-list/selectors/getCurrentViewId';
import { getAsyncPubSubClient } from 'conversations-internal-pub-sub/redux/selectors/pubSubClientGetters';
import { isConnected } from 'conversations-internal-pub-sub/redux/operators/pubSubStatusComparators';
import { presenceUpdated } from 'conversations-thread-data/presence/public/actions';
import { updateSubscriptions } from 'conversations-internal-pub-sub/redux/actions/subscriptions';
import { getAgentToVisitorChannelName } from 'conversations-thread-data/thread-details/public/operators';
import { NAVIGATE_TO_THREAD } from 'conversations-inbox-lib/pubsub/public/constants';
import { FETCH_FROM_OMNI_BUS } from 'conversations-inbox-lib/omnibus/public/constants';
import { getCurrentPortalChannel } from '../selectors/getCurrentPortalChannel';
import { handlePortalMessage, handlePortalPlayback } from '../actions/handlePortalMessage';
import { PUBSUB_READY, PUBSUB_RECONNECTED } from 'conversations-internal-pub-sub/redux/constants/actionTypes';
import { hasInsufficientJitaPermissions } from '../../unified-inbox/selectors/hasInsufficientJitaPermissions';
import { getFocusedViewMemberThreadId } from '../../focused-view-member/selectors/getFocusedViewMemberThreadId';
import { getFocusedThreadDetails } from '../../thread-details/selectors/getFocusedThreadDetails';
import { SET_CURRENT_INBOX } from 'conversations-inbox-lib/unified-inbox/public/constants';
import { fetchViewMembers } from 'find-and-filter-data/view-members-data/protected';
import { getUserId } from '../../selectors/userSelectors/getUserId';
import { triggerBulkUpdateFailureAlert } from '../../threads-bulk-update/actions/triggerBulkUpdateFailureAlert';
import { isInSearchAndFilterMode as getIsInSearchAndFilterMode } from 'conversations-inbox-lib/thread-search-and-filtering/public/selectors';
import { fetchSearchAndFilterResults, selectFilterValues, selectSortOrder } from 'find-and-filter-data/search-and-filter-data/protected';
import { FilterKey } from 'find-and-filter-data/search-and-filter-schema/protected';
import { bulkUpdateSucceededNotification } from '../../threads-bulk-update/operators/bulkUpdateSucceededNotification';
import { reloadThreadsData } from '../../threads-bulk-update/actions/reloadThreadsData';
import { FETCH_THREAD_LIST_MEMBER_DETAILS } from '../../thread-details/constants/asyncActionTypes';
import { detailsFetchedOnFocusedThread } from '../operators/detailsFetchedOnFocusedThread';
import { handleThreadMessage } from '../actions/handleThreadMessage';
import { getCurrentInboxId } from 'conversations-inbox-lib/unified-inbox/public/selectors';
const applyHandlers = (channels, handlers) => channels.reduce((acc, channel) => {
  if (channel) acc[channel] = handlers;
  return acc;
}, {});
const TARGET_ACTION_TYPES = List([PUBSUB_READY, PUBSUB_RECONNECTED, FETCH_FROM_OMNI_BUS.SUCCEEDED, NAVIGATE_TO_THREAD, SET_CURRENT_INBOX, FETCH_THREAD_LIST_MEMBER_DETAILS.SUCCEEDED, fetchViews.fulfilled.type,
/**
 * When a user changes views we want to update subscriptions. We update on TLM_FETCH
 * instead of SET_CURRENT_THREAD_LIST because the BE will apply Ably Playback
 * messages and return the timestamp of the last applied message in the
 * TLM Page response. We wait for that response and if it is succeeded or failed
 * and is fetching an initial page of members(i.e. not pagination) then we update
 * subscriptions.
 */
fetchViewMembers.fulfilled.type, fetchViewMembers.rejected.type]);
export const realtime = store => {
  const onPortalMessage = message => {
    store.dispatch(handlePortalMessage(message));
  };
  const onPortalMessages = messages => {
    store.dispatch(handlePortalPlayback(messages));
  };
  const onInboxMessage = ({
    message,
    publishContext
  }) => {
    store.dispatch(handleInboxChannelMessage({
      currentAgentId: getUserId(store.getState()),
      message,
      onBulkUpdateFailed() {
        store.dispatch(triggerBulkUpdateFailureAlert());
      },
      onBulkUpdateSuccess(threadIds) {
        const isInSearchAndFilterMode = getIsInSearchAndFilterMode(store.getState());
        if (isInSearchAndFilterMode) {
          const filterValues = selectFilterValues(store.getState());
          const sortOrder = selectSortOrder(store.getState());
          const filterKey = FilterKey({
            filterValues,
            sortOrder
          });
          const inboxId = getCurrentInboxId(store.getState());
          store.dispatch(fetchSearchAndFilterResults({
            filterKey,
            inboxId
          }));
        } else {
          store.dispatch(reloadThreadsData());
        }
        store.dispatch(bulkUpdateSucceededNotification(threadIds.length));
      },
      publishContext
    }));
  };
  const onThreadListMessage = message => {
    store.dispatch(handleViewChannelMessage({
      currentAgentId: getUserId(store.getState()),
      message
    }));
  };
  const onThreadListMessages = messages => {
    // ! b/c handleThreadListMessages can only be called when subscribed to a view
    // i.e. there is always a viewId at this point
    const currentViewId = getCurrentViewId(store.getState());
    store.dispatch(handleViewChannelMessages({
      currentAgentId: getUserId(store.getState()),
      currentViewId,
      messages
    }));
  };
  const onThreadMessage = message => {
    handleThreadMessage(message);
  };
  return next => action => {
    const nextState = next(action);
    const asyncPubSubClient = getAsyncPubSubClient(store.getState());
    if (!TARGET_ACTION_TYPES.includes(action.type) || !isConnected(asyncPubSubClient) || hasInsufficientJitaPermissions(store.getState())) {
      return nextState;
    }
    if (!detailsFetchedOnFocusedThread(action, store)) {
      return nextState;
    }
    if ([fetchViewMembers.fulfilled.type, fetchViewMembers.rejected.type].includes(action.type)) {
      const offsetId = getIn(['meta', 'arg', 'offsetId'], action);
      const isPaginatedFetch = offsetId !== undefined && offsetId > 0;
      /**
       * We only update subscriptions if a new page of TLM is fetched because
       * that indicates a new view. So if TLM fetchs succeed/fail for paginated
       * fetches, we don't want to update subscriptions.
       */
      if (isPaginatedFetch) {
        return nextState;
      }
    }
    const portalChannel = getCurrentPortalChannel();
    const inboxChannels = getCurrentInboxChannels(store.getState());
    const viewId = getCurrentViewId(store.getState());
    const viewChannel = selectViewRealtimeChannel(store.getState(), {
      viewId
    });
    const threadId = getFocusedViewMemberThreadId(store.getState());
    const threadDetails = getFocusedThreadDetails(store.getState());
    const agentToVisitorThreadChannel = /*  @ts-expect-error Jun-7-2024, 15:40UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
    getAgentToVisitorChannelName(threadDetails);
    const subscriptions = Object.assign({
      [portalChannel]: {
        onMessage: onPortalMessage,
        onPlayback: onPortalMessages
      }
    }, applyHandlers(inboxChannels, {
      onMessage: (message, publishContext) => onInboxMessage({
        message,
        publishContext
      })
    }), applyHandlers([viewChannel], {
      onMessage: onThreadListMessage,
      onPlayback: onThreadListMessages
    }), applyHandlers([agentToVisitorThreadChannel], {
      onMessage: onThreadMessage,
      onPresence: message => threadId && store.dispatch(presenceUpdated({
        threadId,
        message
      }))
    }));
    store.dispatch(updateSubscriptions(subscriptions));
    return nextState;
  };
};