import Raven from 'raven-js';
import { useState, useEffect, useCallback } from 'react';
import { UserAttributesClient } from 'frontend-preferences-client';
const userAttributesClient = UserAttributesClient.forCaller('composer-ui');
export function useShepherdDismissState(props) {
  const {
    attributeKey
  } = props;
  const [isDismissed, setIsDismissed] = useState(true);
  useEffect(() => {
    userAttributesClient.fetch(attributeKey, '[]').then(value => {
      setIsDismissed(value === 'true');
    }).catch(error => {
      Raven.captureMessage('User Attribute Read Error', {
        extra: {
          attributeKey,
          error
        }
      });
    });
  }, [attributeKey, setIsDismissed]);
  const setDismissed = useCallback(() => {
    setIsDismissed(true);
    userAttributesClient.write(attributeKey, 'true').catch(error => {
      Raven.captureMessage('User Attribute Write Error', {
        extra: {
          attributeKey,
          error
        }
      });
    });
  }, [attributeKey, setIsDismissed]);
  return {
    isDismissed,
    setDismissed
  };
}