module.exports = {
  "unassignedQueueInteraction": {
    "name": "Unassigned Queue Card Interaction",
    "class": "interaction",
    "namespace": "rep-chat-assignment",
    "version": "1",
    "properties": {
      "action": "string"
    }
  },
  "unassignedQueueUsage": {
    "name": "Unassigned Queue Card Usage",
    "class": "usage",
    "properties": {
      "threadId": {
        "type": "number",
        "isOptional": true
      },
      "action": "string"
    },
    "namespace": "rep-chat-assignment",
    "version": "1"
  }
};