import Raven from 'raven-js';
import { SUCCESS, FAILURE } from '../metrics/constants/asyncDataPointResults';
import { getExtraErrorData } from '../error-reporting/getExtraErrorData';
export const profilePromise = (reporter, name, operation) => (...args) => {
  const startTime = Date.now();
  function callback(result) {
    const endTime = Date.now();
    reporter.captureEvent(name, {
      startTime,
      endTime,
      duration: endTime - startTime,
      result: result instanceof Error ? FAILURE : SUCCESS
    });
    if (result instanceof Error) {
      const extraError = getExtraErrorData(result);
      const {
        channelName,
        code,
        errorSource,
        statusCode,
        message
      } = extraError || {};
      Raven.capturePageEvent('realtime-errors', {
        extra: {
          opName: name,
          code,
          channelName,
          errorSource,
          statusCode,
          message
        }
      });
      return Promise.reject(result);
    }
    return Promise.resolve(result);
  }
  return operation(...args).then(callback, callback);
};