import { Set as ImmutableSet } from 'immutable';
import { createSelector } from 'reselect';
import { buildSenderLocator } from 'conversations-message-history/senders/operators/buildSenderLocator';
import { VISITOR } from 'conversations-message-history/common-message-format/constants/legacySenderTypes';
import { getThreadPresence } from './getThreadPresence';
const getThreadIdFromProps = (__state, {
  threadId
}) => threadId;
const VISITOR_CLIENT_ID_REGEX = new RegExp(`^(${VISITOR})-(\\d+)`);
export const getPresentVisitorLocators = createSelector([getThreadIdFromProps, getThreadPresence], (threadId, entries) => {
  if (!threadId || !entries) return ImmutableSet();
  const presenceEntries = entries[threadId] || {};
  return ImmutableSet(Object.keys(presenceEntries).reduce((accumulator, clientId) => {
    const match = (clientId || '').match(VISITOR_CLIENT_ID_REGEX);
    if (!match) return accumulator;
    const [, senderType, senderId] = match;
    if (!senderType || !senderId) return accumulator;
    return [...accumulator, buildSenderLocator(senderType, Number(senderId))];
  }, []));
});