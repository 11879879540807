import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import { mapProperties } from '../utils/property';
export const sendInvoiceEmail = ({
  invoice,
  from,
  toContacts,
  ccContacts,
  informRecipientsOfUpdate
}) => http.post('invoices/v1/email/send', {
  data: {
    portalId: PortalIdParser.get(),
    emailTemplateType: 'INVOICE_SEND',
    invoiceIds: [invoice.objectId],
    fromEmail: from.email,
    fromName: from.name,
    toContacts: toContacts.map(contact => ({
      objectId: contact.objectId,
      objectTypeId: contact.objectTypeId,
      portalId: contact.portalId,
      properties: mapProperties(contact.properties)
    })),
    ccContacts: ccContacts.map(contact => ({
      objectId: contact.objectId,
      objectTypeId: contact.objectTypeId,
      portalId: contact.portalId,
      properties: mapProperties(contact.properties)
    })),
    informRecipientsOfUpdate
  }
});