import { handleActions } from 'redux-actions';
import { FETCH_THREAD_LIST_IDENTIFICATION } from '../constants/asyncActionTypes';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { requestFailedWithOperator } from 'conversations-async-data/async-data/operators/requestFailedWithOperator';
import { getIdentified } from '../operators/getIdentified';

/**
 * TODO: We should replace this reducer with data-fetching-client
 */

// @ts-expect-error ts-migrate(2348) FIXME: Value of type 'new <Key, AsyncDataValue>(t?: Parti... Remove this comment to see the full error message
const initialState = IndexedAsyncData({
  name: 'visitorIdentification',
  notSetValue: new AsyncData({
    data: {
      isIdentified: false
    }
  })
});
export default handleActions({
  [FETCH_THREAD_LIST_IDENTIFICATION.STARTED]: (state, action) => {
    const {
      threadId
    } = action.payload.requestArgs;
    return updateEntry(threadId, requestStarted, state);
  },
  [FETCH_THREAD_LIST_IDENTIFICATION.SUCCEEDED]: (state, action) => {
    const {
      threadId
    } = action.payload.requestArgs;
    const payloadData = {
      isIdentified: getIdentified(action.payload.data)
    };
    return updateEntry(threadId, requestSucceededWithOperator(() => payloadData), state);
  },
  [FETCH_THREAD_LIST_IDENTIFICATION.FAILED]: (state, action) => {
    const {
      threadId
    } = action.payload.requestArgs;
    const payloadData = {
      isIdentified: false
    };
    return updateEntry(threadId, requestFailedWithOperator(() => payloadData), state);
  }
}, initialState);