/**
 * java enum: https://git.hubteam.com/HubSpotProtected/InboundDbQuotes/blob/master/InboundDbQuotesCore/src/main/java/com/hubspot/inbounddbquotes/core/errors/ESignError.java
 */
export const esignErrorCategory = {
  INVALID_ESIGN_REQUEST: 'ESignError.INVALID_ESIGN_REQUEST',
  NO_REMAINING_SIGNATURES: 'ESignError.NO_REMAINING_SIGNATURES',
  LACKS_ESIGN_ACCESS: 'ESignError.LACKS_ESIGN_ACCESS',
  NO_CONTACT_SIGNERS_ON_PUBLISH: 'ESignError.NO_CONTACT_SIGNERS_ON_PUBLISH',
  NO_CONTACT_SIGNERS_ON_CLONE: 'ESignError.NO_CONTACT_SIGNERS_ON_CLONE',
  MAX_KEYS_FOR_ESIGN_REQUESTS: 'ESignError.MAX_KEYS_FOR_ESIGN_REQUESTS',
  USER_NOT_FOUND: 'ESignError.USER_NOT_FOUND',
  HUB_USER_SIGNER_NOT_FOUND_ON_QUOTE_USER_SIGNERS: 'ESignError.HUB_USER_SIGNER_NOT_FOUND_ON_QUOTE_USER_SIGNERS',
  RATE_LIMITED_FOR_SIGNATURE_DETAILS_GENERATION: 'ESignError.RATE_LIMITED_FOR_SIGNATURE_DETAILS_GENERATION',
  NO_SIGNING_DETAILS_FOUND: 'ESignError.NO_SIGNING_DETAILS_FOUND',
  SIGNER_ALREADY_SIGNED: 'ESignError.SIGNER_ALREADY_SIGNED',
  QUOTE_ARCHIVED: 'ESignError.QUOTE_ARCHIVED',
  QUOTE_NOT_READY_FOR_SIGNING: 'ESignError.QUOTE_NOT_READY_FOR_SIGNING'
};