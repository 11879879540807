import { CallingLimitHigh, CallingLimitMedium } from '../constants/AccessLevels';
import { isScoped, someScoped } from './ScopeOperators';
import { CallingLimitType, CALLING_LIMITS, CALLING_MINUTES_PER_PORTAL } from '../constants/CallingLimits';
export const getCallingLimits = scopes => {
  if (isScoped(scopes, CallingLimitMedium)) {
    return CALLING_LIMITS[CallingLimitType.STARTER];
  } else if (someScoped(scopes, [CallingLimitHigh, 'super-user'])) {
    return CALLING_LIMITS[CallingLimitType.PRO];
  } else {
    return CALLING_LIMITS[CallingLimitType.FREE];
  }
};
export const getCallingMinutesPerPortal = limits => limits.get(CALLING_MINUTES_PER_PORTAL);