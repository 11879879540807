const emailStringRegex = /(.+) <(.+)>/;

/**
 * @description extract the email address from the string containing either
 * email address only or name and email address both.
 * @param {string} emailString string containing either email or name+email
 * @returns {string} email address only
 */
export const getEmailAddressFromString = emailString => {
  let address = emailString;
  const match = emailString.match(emailStringRegex);
  if (match) {
    [,, address] = match;
  }
  return address.toLowerCase();
};
export default getEmailAddressFromString;