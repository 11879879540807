import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { silenceErrorAlert } from 'conversations-error-reporting/error-actions/builders/silenceErrorAlert';
import { FETCH_TICKET_DATA } from '../constants/asyncActionTypes';
import { getTicketDetailsForThread } from '../clients/getTicketDetailsForThread';
export const fetchTicketDataForThread = createAsyncAction({
  requestFn: getTicketDetailsForThread,
  actionTypes: FETCH_TICKET_DATA,
  toRecordFn: ticketDataResponse => ticketDataResponse,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type '{}'.
  failureMetaActionCreator: ({
    error
  }) => silenceErrorAlert({
    error
  })
});