/**
 * Throttle calls to an API.  The first call must complete before the subsequent call is started.
 */
export function throttleApi(func) {
  let previousCall = Promise.resolve(null);
  return (...args) => {
    const callNext = () => func(...args);
    previousCall = previousCall.then(callNext, callNext);
    return previousCall;
  };
}