'use es6';

import { useCallback } from 'react';
import devLogger from 'react-utils/devLogger';
import { useMutation } from '@apollo/client';
import { setCardOrder } from './useUpdateCardOrderQueries';
import { useCardLocationContext } from '../context/CardLocationContext';

// exported for unit testing purposes only
export const _updateCachedCardOrder = ({
  orderedCards,
  updateQuery
}) => {
  updateQuery(cachedSidebarData => Object.assign({}, cachedSidebarData, {
    crmObject: Object.assign({}, cachedSidebarData.crmObject, {
      cards: orderedCards
    })
  }));
};

/**
 * Update the card order of a sidebar.
 *
 * @example
 *
 * const handleOrderChange = useUpdateCardOrder({
 *  objectType,
 *  updateQuery
 * })
 *
 * Note: this is part of greater logic around DnD. To see more of the logic, check out:
 * UniversalSidebarCards (https://git.hubteam.com/HubSpot/customer-data-sidebar/blob/master/static/js/universal/UniversalSidebarCards.js)
 * and
 * UniversalSidebarCardDnDWrapper (https://git.hubteam.com/HubSpot/customer-data-sidebar/blob/master/static/js/universal/UniversalSidebarCardDnDWrapper.js)
 *
 * @param {Object}                           options
 * @param {String}                           options.objectType            the type of the subject whose sidebar order is being changed
 * @param {String}                           options.updateQuery           the graphql query used to update the cache
 */

export const useUpdateCardOrder = ({
  objectType,
  updateQuery
}) => {
  const {
    cardLocation
  } = useCardLocationContext();
  const [setCardOrderMutation] = useMutation(setCardOrder);
  return useCallback(orderedCards => {
    const orderedCardIds = orderedCards.map(({
      cardId
    }) => cardId);
    setCardOrderMutation({
      variables: {
        cardOrderInput: {
          objectTypeId: objectType,
          location: cardLocation,
          order: orderedCardIds
        }
      }
    }).catch(err => {
      devLogger.warn({
        message: `Failed to save card order: ${err.message}`,
        key: `useUpdateCardOrder::handleOrderChange`
      });
    });
    _updateCachedCardOrder({
      orderedCards,
      updateQuery
    });
  }, [cardLocation, objectType, setCardOrderMutation, updateQuery]);
};