/**
 * Exposes the full backend response for the Generic Channels metadata and capabilities. Prefer `useChannelCapability` where possible, especially for individual channels, even if it requires multiple calls for different capabilities in the same component.
 *
 * This includes all the channels available in the portal. It requires consumers to individually handle the loading and error states.
 * Do not use this hook to configure the editor itself - `useEditorCapability` should be used to configure features for the current editor.
 */
export { useAsyncGenericChannelsWithCapabilities } from '../_internal/hooks/useAsyncGenericChannelsWithCapabilities';

/**
 * @description Used within the editor for determining what features are available for the currently active editor configuration.
 * Can only be used as a child of EditorCapabilitiesContext via EditorCapabilitiesProvider, which guarantees that the active configuration is valid.
 * Uses the capabilities for a given channel from the backend and defines the configurations and features available for non-channel configurations (like comments).
 */
export { useEditorCapability } from '../_internal/hooks/useEditorCapability';
export { useEditorConfigurationKey } from '../_internal/hooks/useEditorConfigurationKey';