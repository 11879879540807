import { createSelector } from 'reselect';
import get from 'transmute/get';
import { setStatus } from 'find-and-filter-data/search-and-filter-schema/protected';
import { isCustomView, isTrashView, isAllOpenView } from 'find-and-filter-data/views-schema/public';
import { getStatusToggle } from '../../thread-list-member-sidebar/selectors/getStatusToggle';
import { selectFilterValues } from 'find-and-filter-data/search-and-filter-data/protected';
import { selectViews } from 'find-and-filter-data/views-data/public';
import { getCurrentViewId } from './getCurrentViewId';

/**
 * @description This selector returns the threadStatus to use to filter the
 * threadListMembers for the current view.
 *
 * @returns {ChatFilterOptions} threadStatus
 */
export const getViewThreadStatus = createSelector([getCurrentViewId, selectViews, getStatusToggle], (currentViewId, views, statusToggle) => {
  const currentView = get(currentViewId, views);
  // for custom views do no FE thread status filtering
  if (!currentView || isCustomView(currentView)) return null;

  // for trash view filter to only soft deleted threads
  if (isTrashView(currentView)) return 'SOFT_DELETED';

  // for all open, filter to only open
  if (isAllOpenView(currentView)) return 'STARTED';

  // for all other views respect statusToggle
  return statusToggle;
});
/**
 * @description This selector returns the current filters with an adjusted threadStatus
 * based on getViewThreadStatus which accounts for the current view and any overrides
 * we may apply. (Trash ThreadList => threadStatus is SOFT_DELETED)
 *
 * Note that ideally we would update the chat filters in redux so we can just use
 * getFilterValues and getStatusToggle directly. This selector is an in-between
 * step to make that change more easily in the future.
 */
export const getViewThreadStatusAdjustedFilters = createSelector([getViewThreadStatus, selectFilterValues], (threadStatus, filters) => setStatus(threadStatus && [threadStatus], filters));