import http from 'conversations-http/clients/http';
export const validateViewMemberMembershipService = ({
  filterGroups,
  objectTypeId,
  timestamp,
  viewId,
  viewMemberId
}) => http.post('conversations-custom-views-quick-filters/check-quick-filters', {
  data: {
    customViewId: viewId,
    filterGroups,
    objectId: viewMemberId,
    objectTypeId,
    timestamp
  }
});