export function fireCustomEvent(type, root, data) {
  let event;
  const detail = data || {};
  try {
    event = new CustomEvent(type, {
      detail
    });
  } catch (e) {
    event = document.createEvent('CustomEvent');
    event.initCustomEvent(type, false, false, detail);
  }
  root.dispatchEvent(event);
}