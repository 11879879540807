import { useReduxAsyncFetcher } from 'conversations-async-data/async-data/hooks/useReduxAsyncFetcher';
import { fetchOwnerByUserId } from '../actions/fetchOwnerByUserId';
import { getAsyncOwnerByUserId } from '../selectors/getAsyncOwnerByUserId';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { isLoading } from 'conversations-async-data/async-data/operators/statusComparators';
export const useOwnerByUserId = ({
  userId
}) => {
  const {
    asyncData
  } = useReduxAsyncFetcher({
    action: fetchOwnerByUserId,
    selector: getAsyncOwnerByUserId,
    id: userId,
    idTransform: id => ({
      userId: id
    })
  });
  return {
    ownerId: getData(asyncData),
    ownerIsLoading: isLoading(asyncData)
  };
};