export const getEditedContactRecord = ({
  fieldName
}) => ({
  key: 'edited-contact-record',
  properties: {
    action: 'edited contact record',
    'crm-property-edited': fieldName
  }
});
export const getOpenedContactRecord = () => ({
  key: 'opened-contact-record',
  properties: {
    action: 'opened contact record'
  }
});
export const getClickedViewAll = ({
  objectType
}) => ({
  key: 'clicked-view-all',
  properties: {
    action: 'clicked view all',
    object: objectType
  }
});
export const getClickedViewMoreProperties = ({
  objectType
}) => ({
  key: 'clicked-view-more-properties',
  properties: {
    action: 'clicked view more properties',
    object: objectType
  }
});
export const getClickedToCreateCrmObject = ({
  objectType
}) => ({
  key: 'clicked-to-create-crm-object',
  properties: {
    action: 'clicked to create crm object',
    'object-type': objectType
  }
});
export const getClickedToAssociateCrmObject = () => ({
  key: 'clicked-to-associate-crm-object',
  properties: {
    action: 'clicked to associate crm object'
  }
});
export const getCreatedCrmObject = ({
  objectType
}) => ({
  key: 'created-crm-object',
  properties: {
    action: 'created crm object',
    'object-type': objectType
  }
});
export const getUnassociatedCrmObject = ({
  associationType
}) => ({
  key: 'unassociated-crm-object',
  properties: {
    action: 'unassociated crm object',
    'association-type': associationType
  }
});
export const getAssociatedVisitorToContact = () => ({
  key: 'associated-visitor-to-contact',
  properties: {
    action: 'associated visitor to contact'
  }
});
export const getCreatedNewContact = () => ({
  key: 'created-new-contact',
  properties: {
    action: 'created new contact'
  }
});