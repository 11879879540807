export const isValidHex = hex => {
  if (hex && typeof hex === 'string') {
    return /^#([0-9A-F]{6}|[0-9A-F]{3})$/i.test(hex);
  }
  return false;
};
export const convertColorBitToHex = color => {
  let hex = Number(color).toString(16);
  if (hex.length < 2) {
    hex = `0${hex}`;
  }
  return hex;
};
export const convertRgbToHex = ({
  r,
  g,
  b
}) => {
  const red = convertColorBitToHex(r);
  const green = convertColorBitToHex(g);
  const blue = convertColorBitToHex(b);
  const hex = `#${red}${green}${blue}`;
  if (!isValidHex(hex)) {
    throw new Error('RGB value passed to convertRgbToHex results in invalid hex value.');
  }
  return hex;
};
export const RGB_PATTERN = /rgba?\((\d+), (\d+), (\d+),? ?(\d\.?\d?)?\)/;
export const convertRgbStringToHex = rgb => {
  const match = rgb.match(RGB_PATTERN);
  if (!match) {
    throw new Error('Invalid RGB string passed to convertRgbStringToHex');
  }
  const [__fullMatch, r, g, b, __a] = match;
  return convertRgbToHex({
    r,
    g,
    b
  });
};