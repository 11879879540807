import { createSelector } from 'reselect';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { isStarted } from 'conversations-async-data/async-data/operators/statusComparators';
import ChatFilterOptions from 'conversations-internal-schema/constants/ChatFilterOptions';
import { SOFT_DELETED } from 'conversations-message-history/common-message-format/constants/messageDeleteStatus';
import { getThreadListMembersBatchUpdates } from '../operators/threadListMembersBatchUpdatesGetters';
import { getStatus } from 'find-and-filter-data/view-members-schema/protected';
import { getViewMemberId } from 'find-and-filter-data/view-members-schema/public';
export const getSelectedConversations = createSelector(
// @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
getThreadListMembersBatchUpdates, getData);
export const makeGetIsInBatchUpdatesList = () => createSelector(getSelectedConversations, (state, props) => props.threadListMember, (selectedConversations, threadListMember) => {
  const threadId = +getViewMemberId(threadListMember);
  return selectedConversations.includes(threadId);
});
export const getIsInBatchUpdatesList = makeGetIsInBatchUpdatesList();
export const getBatchThreadCount = createSelector(getSelectedConversations, conversations => conversations.count());
export const getIsBatchUpdating = createSelector(getThreadListMembersBatchUpdates, isStarted);
export const areOpenThreadsSelected = createSelector(getSelectedConversations, (state, props) => props.threadListMembers, (selectedConversations, threadListMembers) => {
  const selectedMembers = threadListMembers.filter(threadListMember => selectedConversations.includes(+getViewMemberId(threadListMember)));
  return selectedMembers.some(threadListMember => getStatus(threadListMember) === ChatFilterOptions.STARTED);
});
export const areClosedThreadsSelected = createSelector(getSelectedConversations, (state, props) => props.threadListMembers, (selectedConversations, threadListMembers) => {
  const selectedMembers = threadListMembers.filter(threadListMember => selectedConversations.includes(+getViewMemberId(threadListMember)));
  return selectedMembers.some(threadListMember => getStatus(threadListMember) === ChatFilterOptions.ENDED);
});
export const areDeletedThreadsSelected = createSelector(getSelectedConversations, (state, props) => props.threadListMembers, (selectedConversations, threadListMembers) => {
  const selectedMembers = threadListMembers.filter(threadListMember => selectedConversations.includes(+getViewMemberId(threadListMember)));
  return selectedMembers.some(threadListMember => getStatus(threadListMember) === SOFT_DELETED);
});