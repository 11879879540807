import { Map as ImmutableMap } from 'immutable';
import { handleActions } from 'redux-actions';
import { USER_SETTINGS_FETCH, UPDATE_USER_SETTINGS } from '../constants/asyncActionTypes';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
const initialState = new AsyncData({
  data: ImmutableMap({
    browserNotificationEnabled: false
  })
});
export default handleActions({
  [USER_SETTINGS_FETCH.SUCCEEDED](state, action) {
    const {
      data
    } = action.payload;
    return requestSucceededWithOperator(() => data, state);
  },
  [UPDATE_USER_SETTINGS.SUCCEEDED](state, action) {
    const data = action.payload.requestArgs;
    return requestSucceededWithOperator(() => data, state);
  }
}, initialState);