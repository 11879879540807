import PortalIdParser from 'PortalIdParser';
import { useCallback, useEffect, useState } from 'react';
const NAME_SPACE = 'content-assistance-lib';
const PORTAL_ID = PortalIdParser.get();
export const getLocalStorageItem = key => {
  // eslint-disable-next-line hubspot-dev/no-unsafe-storage
  const storedItem = window.localStorage.getItem(key);
  if (typeof storedItem === 'string') {
    return JSON.parse(storedItem);
  }
  return storedItem;
};
export const setLocalStorageItem = (key, value) => {
  // eslint-disable-next-line hubspot-dev/no-unsafe-storage
  window.localStorage.setItem(key, JSON.stringify(value));
};
export const getStorageKey = (userId, appName = '', forceAppName = false) => {
  const optionalAppNameInKey = forceAppName ? `-app:${appName}` : '';
  return `key:${NAME_SPACE}portalId:${PORTAL_ID}-userId:${userId}${optionalAppNameInKey}`;
};
export default function useHasSeenInfoModal({
  appName,
  userId,
  modalName = 'ContentAssistantInfoModal',
  forceAppName = false
}) {
  // Initially set to true, so that the modal doesn't show
  const [hasSeenInfoModal, setHasSeenInfoModal] = useState(true);
  const OLD_STORAGE_KEY = `key:${NAME_SPACE}app:${appName}:portalId:${PORTAL_ID}-userId:${userId}:Modal${modalName}`;
  // See thread https://hubspot.slack.com/archives/C04KJNE38R2/p1687871941612029?thread_ts=1687813481.625399&cid=C04KJNE38R2
  const NEW_STORAGE_KEY = getStorageKey(userId, appName, forceAppName);
  const setHasSeenInfoModalToTrue = useCallback(() => {
    setHasSeenInfoModal(true);
    try {
      setLocalStorageItem(NEW_STORAGE_KEY, true);
    } catch (e) {
      console.error('There was an error storing your hasViewedModal flag', e);
    }
  }, [NEW_STORAGE_KEY]);
  useEffect(() => {
    try {
      const oldBoolValue = getLocalStorageItem(OLD_STORAGE_KEY);
      const newBoolValue = Boolean(getLocalStorageItem(NEW_STORAGE_KEY));
      if (oldBoolValue) {
        // Update the new storage key
        setHasSeenInfoModalToTrue();
        return;
      }
      setHasSeenInfoModal(newBoolValue);
    } catch (e) {
      console.error('There was an error retrieving hasViewedModal flag', e);
    }
  }, [NEW_STORAGE_KEY, OLD_STORAGE_KEY, setHasSeenInfoModalToTrue]);
  return [hasSeenInfoModal, setHasSeenInfoModalToTrue];
}