import { useState, useEffect, useCallback } from 'react';
import { addVisibilityChangeListener } from 'conversations-visibility-tools/visibility/addVisibilityChangeListener';
import { removeVisibilityChangeListener } from 'conversations-visibility-tools/visibility/removeVisibilityChangeListener';
import { isHidden } from 'conversations-visibility-tools/visibility/isHidden';
import { setProductInfoOutOfSync } from '../actions/setProductInfoOutOfSync';
export const useProductInfoOutOfSync = dispatch => {
  const [isVisibleState, setIsVisibleState] = useState(isHidden());
  const visibilityChangeListener = useCallback(({
    isVisible
  }) => {
    if (isVisible !== isVisibleState) {
      dispatch(setProductInfoOutOfSync());
    }
    setIsVisibleState(isVisible);
  }, [dispatch, isVisibleState]);
  useEffect(() => {
    addVisibilityChangeListener(visibilityChangeListener);
    return () => removeVisibilityChangeListener(visibilityChangeListener);
  }, [visibilityChangeListener]);
};