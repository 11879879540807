import get from 'transmute/get';
import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { stringIdInvariant } from 'conversations-async-data/indexed-async-data/invariants/stringIdInvariant';
import { updateEntries } from 'conversations-async-data/indexed-async-data/operators/updateEntries';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { limitToCount } from 'conversations-async-data/indexed-async-data/eviction-strategies/limitToCount';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { HYDRATED_EMAIL_ADDRESS_ASYNC_DATA_CACHE_SIZE } from 'conversations-thread-data/common/public/constants/CacheSize';
import { EMAIL_ADDRESS_FETCH } from '../constants/asyncActionTypes';
const initialState = new IndexedAsyncData({
  idInvariant: stringIdInvariant,
  name: 'hydratedEmailAddresses',
  notSetValue: new AsyncData(),
  evict: limitToCount(HYDRATED_EMAIL_ADDRESS_ASYNC_DATA_CACHE_SIZE)
});
export default handleActions({
  [EMAIL_ADDRESS_FETCH.STARTED]: (state, action) => {
    const {
      emailAddresses
    } = action.payload.requestArgs;
    return updateEntries(emailAddresses, (__emailAddress, asyncData) => requestStarted(asyncData), state);
  },
  [EMAIL_ADDRESS_FETCH.SUCCEEDED]: (state, action) => {
    const {
      emailAddresses
    } = action.payload.requestArgs;
    const {
      data
    } = action.payload;
    return updateEntries(emailAddresses, (emailAddress, asyncData) => {
      const emailAddressRecord = get(emailAddress, data);
      return requestSucceededWithOperator(() => emailAddressRecord || null, asyncData);
    }, state);
  },
  [EMAIL_ADDRESS_FETCH.FAILED]: (state, action) => {
    const {
      emailAddresses
    } = action.payload.requestArgs;
    return updateEntries(emailAddresses, (__emailAddress, asyncData) => requestFailed(asyncData), state);
  }
}, initialState);