import { useState } from 'react';
import invariant from 'react-utils/invariant';
const getInitialState = (key, initialValue) => {
  try {
    const value = window.localStorage.getItem(key) || initialValue;
    window.localStorage.setItem(key, value);
    return value;
  } catch (error) {
    return initialValue;
  }
};
export const useLocalStorage = ({
  key,
  initialValue
}) => {
  invariant(!!key, 'A key must be set when using local storage');
  const [item, setValue] = useState(() => getInitialState(key, initialValue));
  const setItem = value => {
    setValue(value);
    try {
      window.localStorage.setItem(key, value);
    } catch (error) {
      // do nothing
    }
  };
  const removeItem = () => {
    setValue(null);
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      //do nothing
    }
  };
  return [item, setItem, removeItem];
};