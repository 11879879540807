'use es6';

import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import { isThreadComment } from 'conversations-message-history/comment-message/operators/cmComparators';
import { SENT } from 'conversations-message-history/common-message-format/constants/statusTypes';
import { buildCommonMessage } from 'conversations-message-history/common-message-format/operators/buildCommonMessage';
import { isCommonMessageFormat } from 'conversations-message-history/common-message-format/operators/cmfComparators';
import { getTopLevelType } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { setMessageStatus } from 'conversations-message-history/common-message-format/operators/commonMessageFormatSetters';
export const getUnpublishedMessage = message => {
  switch (true) {
    case isCommonMessageFormat(message):
      return setMessageStatus(SENT, buildCommonMessage(message));
    case isThreadComment(message):
      return message;
    default:
      reportError({
        error: new Error(`UNRESOLVED_REPUBLISH_MESSAGE: ${getTopLevelType(message)}`),
        fingerprint: ['{{ default }}', 'UNRESOLVED_REPUBLISH_MESSAGE'],
        tags: {
          isVisibleToUser: true
        }
      });
      return null;
  }
};