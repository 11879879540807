import { List } from 'immutable';
import OfficeHour from '../records/OfficeHour';
import { MON_FRI } from '../constants/DayTypes';
const NINE_AM_MINUTES = 9 * 60;
const FIVE_PM_MINUTES = 17 * 60;
const buildDefaultOfficeHours = () => List([OfficeHour({
  day: MON_FRI,
  startMinute: NINE_AM_MINUTES,
  endMinute: FIVE_PM_MINUTES
})]);
export default buildDefaultOfficeHours;