export const getQueryParams = location => {
  if (!location || !location.search) return null;
  return new URLSearchParams(location.search);
};
export const getQueryParamFromLocation = (paramName, location) => {
  if (!location) return null;
  const urlParams = getQueryParams(location);
  if (!urlParams) return null;
  return urlParams.get(paramName);
};
export const getMessageIdFromLocation = location => {
  return getQueryParamFromLocation('messageId', location);
};