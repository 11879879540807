'use es6';

import { Record } from 'immutable';
export default class ZorseInboxes extends Record({
  supportInboxId: 0,
  iscInboxId: 0
}, 'ZorseInboxes') {
  get isEmpty() {
    return !this.supportInboxId || !this.iscInboxId;
  }
}