import get from 'transmute/get';
// @ts-expect-error module not typed
import EmailAddressRecord from 'customer-data-email/schema/email/EmailAddressRecord';
import { getProperty } from 'customer-data-objects/record/ObjectRecordAccessors';
export const mapContactsToEmailAddressRecords = contacts => {
  return contacts.reduce((acc, contact) => {
    const address = getProperty(contact, 'email');
    const additionalEmails = getProperty(contact, 'hs_additional_emails');
    const firstName = getProperty(contact, 'firstname');
    const lastName = getProperty(contact, 'lastname');
    const id = get('vid', contact);
    const emailAddressRecord = new EmailAddressRecord({
      address,
      addressId: `${address} ${address}`,
      firstName: getProperty(contact, 'firstname'),
      id: get('vid', contact),
      lastName: getProperty(contact, 'lastname')
    });
    acc.push(emailAddressRecord);
    if (additionalEmails && additionalEmails.length > 0) {
      additionalEmails.split(';').forEach(additionalEmail => {
        const additionalEmailRecord = new EmailAddressRecord({
          address: additionalEmail,
          addressId: `${additionalEmail} ${additionalEmail}`,
          firstName,
          id,
          lastName
        });
        acc.push(additionalEmailRecord);
      });
    }
    return acc;
  }, []);
};