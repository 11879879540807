import { useCallback, useState, useEffect } from 'react';
import { setVid, setAttachments
// @ts-expect-error module not typed
} from 'conversations-thread-data/common-reply/public/operators/commonReplySetters';
import { getAttachments, getReplyId, getVid } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
import { CommonReplyRecord } from 'conversations-thread-data/common-reply/public/records/CommonReply';
// @ts-expect-error module not typed
import { useCommonReplyDraft } from './useCommonReplyDraft';
const defaultConflictResolver = (currentReply, __incomingEmptyReply) => currentReply;

/**
 * @description provides email reply state constructed from
 * the thread details and history, resolving metadata conflicts
 * that arise as a result of new messages appearing in the history
 * (email replies should always be with respect to the most recent
 * messsage in the history). Saves changes to email replies as
 * drafts.
 */

export const useCommonReply = ({
  emptyReply,
  reconcileReplies = defaultConflictResolver,
  saveDebounceTime = 1000
}) => {
  const replyId = getReplyId(emptyReply);
  const vid = getVid(emptyReply);
  const [draftReply, saveDraft, clearDraft] = useCommonReplyDraft(replyId, saveDebounceTime);
  const draftReplyWithVid = draftReply ? setVid(vid, draftReply) : draftReply;
  const [reply, setReplyState] = useState(draftReplyWithVid || emptyReply);
  useEffect(() => {
    setReplyState(currentReply => {
      const emptyReplyId = getReplyId(emptyReply);
      if (emptyReplyId === getReplyId(currentReply)) {
        return reconcileReplies(currentReply, emptyReply);
      }
      return draftReplyWithVid ? reconcileReplies(draftReplyWithVid, emptyReply) : emptyReply;
    });
  }, [emptyReply, draftReplyWithVid, reconcileReplies]);

  // This useEffect handles the case of loading attachments when forwarding
  // existing email which have attachments. Attachments are being resolved
  // in useEmptyReply.
  useEffect(() => {
    setReplyState(currentReply => {
      const currentAttachments = getAttachments(currentReply);
      const newAttachments = getAttachments(emptyReply);
      const currentReplyId = getReplyId(currentReply);
      const newReplyId = getReplyId(emptyReply);
      if (currentReplyId === newReplyId && currentAttachments.size < newAttachments.size) {
        return setAttachments(newAttachments, currentReply);
      }
      return currentReply;
    });
  }, [emptyReply]);
  useEffect(() => {
    if (vid !== getVid(reply)) {
      setReplyState(setVid(vid, reply));
    }
  }, [reply, setReplyState, vid]);
  const setReply = useCallback(replyOrSetter => {
    if (typeof replyOrSetter === 'function') {
      setReplyState(currentReply => {
        const nextReply = replyOrSetter(currentReply);
        saveDraft(nextReply);
        return nextReply;
      });
    } else if (replyOrSetter instanceof CommonReplyRecord) {
      // Check for correct CommonReplyRecord type or other onChange events bubble up and get set
      setReplyState(replyOrSetter);
      saveDraft(replyOrSetter);
    }
  }, [saveDraft, setReplyState]);
  const resetReply = useCallback(() => {
    setReplyState(emptyReply);
    clearDraft();
  }, [setReplyState, clearDraft, emptyReply]);
  return [reply, setReply, resetReply];
};