import http from 'conversations-http/clients/http';

/**
 * Check out the Schema:
 * [Schema](https://tools.hubteam.com/api-catalog/services/MessagesService/v2/spec/internal#operations-Agent_Assignee_Search-put-%2Fmessages%2Fv2%2Fagent%2Fassignment%2Fassignees%2Fsearch)
 */

export const searchAssignees = ({
  inboxId,
  searchQuery,
  offset
}) => {
  return http.get(`cv/inbox/settings/v1/assignee-search`, {
    query: {
      workspaceId: inboxId,
      limit: 100,
      searchQuery,
      offset: offset && offset.index && offset.index > 0 ? offset.index : 0
    }
  });
};