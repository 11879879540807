import Raven from 'raven-js';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
export const copyToClipboard = text => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).catch(error => {
      Raven.captureException(error);
    });
  } else {
    // TODO remove this work around when navigator.clipboard.writeText is fully supported by all browsers
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.focus();
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  }
};
export const copyWithFloatingMessage = (text, floatingMessage) => {
  copyToClipboard(text);
  FloatingAlertStore.addAlert({
    message: floatingMessage
  });
};