'use es6';

import * as AgentTypes from 'conversations-message-history/common-message-format/constants/agentTypes';
import * as SenderTypes from 'conversations-message-history/common-message-format/constants/legacySenderTypes';
const senderToAgentMapping = {
  [SenderTypes.AGENT]: AgentTypes.HUMAN,
  [SenderTypes.BOT]: AgentTypes.BOT
};
export const getAgentTypeFromSenderType = senderType => {
  return senderToAgentMapping[senderType];
};