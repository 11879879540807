import http from 'hub-http/clients/apiClient';
const BASE_URL = 'zorse-grr-tagging';
const getConversationsUrl = conversationId => `${BASE_URL}/conversations/${conversationId}`;
const getTicketsByConversationUrl = conversationId => `${BASE_URL}/tickets/by-conversation/${conversationId}`;
const getTicketsUrl = ticketId => `${BASE_URL}/tickets/${ticketId}`;
const getTicketPropertiesUrl = () => `${BASE_URL}/ticket-properties`;
export function getTicketByConversation(conversationId) {
  return http.get(getTicketsByConversationUrl(conversationId));
}
export function getTicketExternalProperties() {
  return http.get(getTicketPropertiesUrl());
}
export function resolveConversation(conversationId) {
  return http.post(`${getConversationsUrl(conversationId)}/resolve`);
}
export function resolveTicket(ticketId) {
  return http.post(`${getTicketsUrl(ticketId)}/resolve`);
}
export function updateProductArea(ticketId, productAreaId) {
  return http.patch(`${getTicketsUrl(ticketId)}/product-area/${productAreaId}`);
}
export function updateResolution(ticketId, resolutionId) {
  return http.patch(`${getTicketsUrl(ticketId)}/resolution/${resolutionId}`);
}
export function updateRoadblock(ticketId, roadblockId) {
  return http.patch(`${getTicketsUrl(ticketId)}/roadblock/${roadblockId}`);
}
export function updateSubject(ticketId, subject) {
  return http.patch(`${getTicketsUrl(ticketId)}/subject`, {
    data: subject,
    headers: {
      'content-type': 'text/plain'
    }
  });
}