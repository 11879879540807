/**
 * This function will take a ticket and merge in the values from an array of passed
 * in properties. Due to the size of the object, the way updateCrmObjectProperties is
 * called, and the different places the value will be updated this function is out
 * of control and could use a refactor.
 *
 * @ticket Ticket fetched from the apollo cache containing __typeNames and ids
 * @properties The properties to be merged into ticket
 */
export const updateTicketForOptimisticResponse = ({
  ticket,
  properties
}) => {
  const allProperties = ticket.allProperties ? ticket.allProperties.map(property => {
    const matchingProperty = properties.find(prop => prop.name === property.name);
    if (matchingProperty) {
      return Object.assign({}, property, {
        value: matchingProperty.value
      });
    }
    return property;
  }) : [];
  const subject = properties.find(prop => prop.name === 'subject');
  const time_to_close = properties.find(prop => prop.name === 'time_to_close');
  const hubspot_owner_id = properties.find(prop => prop.name === 'hubspot_owner_id');
  const closed_date = properties.find(prop => prop.name === 'closed_date');
  const defaultProperties = Object.assign({}, ticket.defaultProperties, {
    subject: {
      id: ticket.defaultProperties.subject.id,
      value: subject ? subject.value : ticket.defaultProperties.subject.value
    },
    time_to_close: {
      id: ticket.defaultProperties.time_to_close.id,
      value: time_to_close ? time_to_close.value : ticket.defaultProperties.time_to_close.value
    },
    hubspot_owner_id: {
      id: ticket.defaultProperties.hubspot_owner_id.id,
      value: hubspot_owner_id ? hubspot_owner_id.value : ticket.defaultProperties.hubspot_owner_id.value
    },
    closed_date: {
      id: ticket.defaultProperties.closed_date.id,
      value: closed_date ? closed_date.value : ticket.defaultProperties.closed_date.value
    }
  });
  const hs_pipeline_stage = properties.find(prop => prop.name === 'hs_pipeline_stage');
  let newPipelineInfo = ticket.pipelineInfo;
  if (ticket.pipelineInfo && ticket.pipelineInfo.currentStage) {
    const currentStage = hs_pipeline_stage ? hs_pipeline_stage.value : ticket.pipelineInfo.currentStage.stageId;
    newPipelineInfo = Object.assign({}, ticket.pipelineInfo, {
      currentStage: Object.assign({}, ticket.pipelineInfo.currentStage, {
        stageId: currentStage
      })
    });
  }
  const newObject = Object.assign({}, ticket, {
    allProperties,
    defaultProperties,
    pipelineInfo: newPipelineInfo
  });
  return newObject;
};