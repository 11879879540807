import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { deleteViewChannelMessage, viewMemberUpserted } from '../../../realtime-updates/public';
import { validateViewMemberMembershipService } from '../help-desk-view-member/validateViewMemberMembershipService';
export const useViewMemberValidation = () => {
  const dispatch = useDispatch();
  const validateViewMemberMembership = useCallback(({
    currentAgentId,
    filterGroups,
    objectTypeId,
    message,
    sortState,
    viewId,
    viewMemberId,
    hasRetried = false
  }) => {
    const viewMember = message.data.upserted.find(vm => {
      return viewMemberId === vm.objectKey.objectId;
    });
    const timestamp = viewMember && viewMember.changeTimestamp ? viewMember.changeTimestamp : 0;
    validateViewMemberMembershipService({
      filterGroups,
      objectTypeId,
      timestamp,
      viewId,
      viewMemberId
    }).then(res => {
      const response = res.result;
      switch (response) {
        case 'ERROR':
          //???
          break;
        case 'MATCH':
          {
            //View Member matches filter criteria
            //Add or update it
            dispatch(viewMemberUpserted({
              currentAgentId,
              message: Object.assign({}, message.data, {
                // In theory, realtime messages can contain multiple
                // tickets, in which case we only want to upsert the ticket
                // that we just validated. This is a "quick fix" and
                // this entire hook/endpoint will be made obsolete by
                // https://issues.hubspotcentral.com/browse/CT-18587
                upserted: [viewMember]
              }),
              sortState
            }));
            break;
          }
        case 'NO_MATCH':
          {
            //View Member doesn't match filter criteria
            //Remove it
            dispatch(deleteViewChannelMessage({
              currentAgentId,
              removedMembers: message.data.upserted,
              viewKey: message.data.viewKey
            }));
            break;
          }
        case 'RETRY_EXCEPTION':
          {
            //Retry Once
            if (!hasRetried) {
              validateViewMemberMembership({
                currentAgentId,
                filterGroups,
                objectTypeId,
                message,
                sortState,
                viewId,
                viewMemberId,
                hasRetried: true
              });
            }
            break;
          }
        case 'UNKNOWN_VIEW':
          //Maybe handle the same as MATCH?
          break;
        default:
          break;
      }
    }).catch(e => {
      void e;
    });
  }, [dispatch]);
  return {
    validateViewMemberMembership
  };
};