import getIn from 'transmute/getIn';
import ContactRecord from 'customer-data-objects/contact/ContactRecord';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { FETCH_CONTACT } from '../constants/asyncActionTypes';
import { getContact } from '../clients/getContact';
import { silenceErrorAlert } from 'conversations-error-reporting/error-actions/builders/silenceErrorAlert';
const ERROR_CODES = [404, 0];
export const fetchCrmContact = createAsyncAction({
  requestFn: getContact,
  actionTypes: FETCH_CONTACT,
  toRecordFn: ContactRecord,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type '{}'.
  failureMetaActionCreator: ({
    error
  }) => {
    const isObjectNotFound = getIn(['responseJSON', 'category'])(error) === 'OBJECT_NOT_FOUND';
    return silenceErrorAlert({
      ignoreStatusCodes: ERROR_CODES,
      error,
      isVisibleToUser: !isObjectNotFound
    });
  }
});