// original: https://git.hubteam.com/HubSpot/conversations-internal-schema/blob/1729733ab4b5cfdd5950af37c67157f5deb9589b/static/js/team/operators/deselectNodeOnTree.js

import { getKeyAddress } from './getKeyAddress';
import { setDataOnNodeAtAddress } from './setDataOnNodeAtAddress';
export const deselectNodeOnTree = (optionNode, tree) => {
  const address = getKeyAddress(optionNode, tree);
  let updatedTree = tree;
  while (address.length) {
    updatedTree = setDataOnNodeAtAddress(address, {
      selected: false
    }, updatedTree);
    address.pop();
  }
  return updatedTree;
};