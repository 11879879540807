import { handleActions } from 'redux-actions';
import { INITIALIZE_STORE_DATA } from '../../../store/public/constants';
const initialState = {};
export const auth = handleActions({
  [INITIALIZE_STORE_DATA](state, action) {
    const {
      auth: data
    } = action.payload;
    if (state && Object.keys(state).length) {
      return state;
    }
    return data;
  }
}, initialState);