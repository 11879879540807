// gimme: conversations-inbox-ui 05/26/2020 741c62dc71ca742adbc6d64d61d30de7cb46539f
'use es6';

import { fetchMessageHistory } from './fetchMessageHistory';
import { getDeletedMessagesVisibility } from '../../thread-deletion/selectors/getDeletedMessagesVisibility';
import { shouldShowDeletedMessages } from '../../thread-deletion/operators/shouldShowDeletedMessages';
export const fetchAgentHistory = ({
  threadId,
  offsetTimestamp,
  offsetOrdinal,
  includeDeletedMessages
}) => (dispatch, getState) => {
  const forceShowDeletedMessages = shouldShowDeletedMessages({
    deletedMessages: getDeletedMessagesVisibility(getState()),
    threadId
  });
  const showDeleted = includeDeletedMessages || forceShowDeletedMessages;
  dispatch(fetchMessageHistory({
    threadId,
    offsetTimestamp,
    offsetOrdinal,
    allowDeletedThread: showDeleted,
    includeDeletedMessages: showDeleted
  }));
};