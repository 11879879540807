import { handleActions } from 'redux-actions';
import EscalationMeta from '../records/EscalationMeta';
import { LOAD_ESCALATION_META } from '../actions/ActionTypes';
export default handleActions({
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'Action<Esc... Remove this comment to see the full error message
  [LOAD_ESCALATION_META]: (state, {
    error,
    meta,
    payload
  }) => !error && meta === 'response' ? EscalationMeta.fromJS(payload) : state
}, new EscalationMeta());