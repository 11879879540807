import http from 'conversations-http/clients/http';
export const fetchPromptData = ({
  promptType,
  noCache = false
}) => http.get(`cv/inbox/settings/v1/user-prompts`, {
  query: Object.assign({
    promptType
  }, noCache ? {
    noCache: Math.random()
  } : {})
});
export const markPromptAsShown = ({
  promptType
}) => http.put(`cv/inbox/settings/v1/user-prompts/viewed`, {
  query: {
    promptType
  }
});
export const dismissPrompt = ({
  promptType
}) => http.put(`cv/inbox/settings/v1/user-prompts/dismissed`, {
  query: {
    promptType
  }
});