import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { FETCH_FORM } from '../constants/asyncActionTypes';
import { stringIdInvariant } from 'conversations-async-data/indexed-async-data/invariants/stringIdInvariant';
import { limitToCount } from 'conversations-async-data/indexed-async-data/eviction-strategies/limitToCount';
import { FORM_ASYNC_DATA_CACHE_SIZE } from '../../common/public/constants/CacheSize';
const initialState = new IndexedAsyncData({
  name: 'forms',
  idInvariant: stringIdInvariant,
  notSetValue: new AsyncData({
    data: null
  }),
  evict: limitToCount(FORM_ASYNC_DATA_CACHE_SIZE)
});
export default handleActions({
  [FETCH_FORM.STARTED]: (state, action) => {
    const {
      formId
    } = action.payload.requestArgs;
    return updateEntry(formId, requestStarted, state);
  },
  [FETCH_FORM.SUCCEEDED](state, action) {
    const {
      data,
      requestArgs
    } = action.payload;
    const {
      formId
    } = requestArgs;
    return updateEntry(formId, requestSucceededWithOperator(() => fromJS(data)), state);
  },
  [FETCH_FORM.FAILED](state, action) {
    const {
      formId
    } = action.payload.requestArgs;
    return updateEntry(formId, requestFailed, state);
  }
}, initialState);