import { getIsForward } from 'conversations-message-history/common-message-format/operators/emailMetadataGetters';
export const getRecipientsIconName = ({
  allRecipients,
  commonMessageReply,
  replyAll
}) => {
  const allRecipientsValue = allRecipients && allRecipients.getIn(['all']);
  const hasMultipleRecipients = replyAll && allRecipientsValue && allRecipientsValue.size > 1;
  const isForward = commonMessageReply && getIsForward(commonMessageReply);
  return isForward ? 'forward' : hasMultipleRecipients ? 'replyAll' : 'reply';
};