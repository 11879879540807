import get from 'transmute/get';
import { List } from 'immutable';
import { trackEvent } from '../../usage-tracking/clients/usageTracker';
import { getAllVids } from 'conversations-inbox-lib/contacts/public/operators';
import { getAppliedFilter } from 'conversations-inbox-lib/usage-tracking/inboxInteractionEvents';
import { DEFAULT_CHAT_FILTERS } from 'find-and-filter-data/search-and-filter-data/protected';
export const trackAppliedFilters = (updatedFilters, filterResultsCount) => {
  const filterTypes = [];
  const filterValues = [];
  updatedFilters.toSeq().toMap().map((value, filterType) => {
    //Allow only the filter values we explcitly care about
    if (filterType === 'threadStatus' || filterType === 'genericChannel' || filterType === 'assignedAgentId' || filterType === 'vid' || filterType === 'searchQuery' || filterType === 'endTimestamp' || filterType === 'unassigned' && value || filterType === 'assignedToBot' && value || filterType === 'hideTrash' && !value || filterType === 'hideFiltered' && !value) {
      const prevValue = get(filterType, DEFAULT_CHAT_FILTERS);
      if (prevValue !== value) {
        let filterValue = value;
        if (filterType === 'vid') {
          filterValue = getAllVids(filterValue).join(',');
          filterType = 'contact';
        }
        if (filterType === 'endTimestamp') {
          filterType = 'date';
        }
        if (filterType === 'searchQuery') {
          filterValue = filterValue.includes('@') ? 'isEmailSearch' : 'isNotEmailSearch';
        }
        if (List.isList(filterValue)) {
          filterValue = filterValue.join(',');
        }
        filterTypes.push(filterType);
        filterValues.push(filterValue);
      }
    }
    return null;
  });
  trackEvent(getAppliedFilter({
    filterType: filterTypes.join(','),
    filterValue: filterValues.join(','),
    filterCount: filterValues.length,
    filterResultsCount
  }));
};