import http from 'conversations-http/clients/http';
import { registerMutation } from 'data-fetching-client';
const fetcher = request => {
  return http.put(`conversations-tickets/v2/handoff`, {
    data: request.handoffRequest
  });
};
export const MOVE_ASSIGN_AND_PUBLISH_MUTATION_NAME = 'moveAssignAndPublish';
export const MOVE_ASSIGN_AND_PUBLISH_MUTATION = registerMutation({
  fieldName: MOVE_ASSIGN_AND_PUBLISH_MUTATION_NAME,
  args: ['handoffRequest'],
  fetcher
});