import { useQuery } from 'data-fetching-client';
import { useMemo } from 'react';
import { singleHubUserSettingQuery } from '../queries/hub-user-attributes.queries';

/**
 * Example use case "I want an alert that a user will see once, and as they log into other portals, they will see that alert again. Dismissal state will not be shared between portals"
 *
 * You are required to provide a defaultValue in cases of error or no value found
 */
export const useHubUserSetting = ({
  settingsKey,
  defaultValue
}) => {
  const result = useQuery(singleHubUserSettingQuery, {
    variables: {
      settingsKey,
      defaultValue
    }
  });
  const memoizedValue = useMemo(() => {
    const {
      data,
      loading
    } = result;
    return {
      data: data && data.singleHubUserSettingQuery,
      loading
    };
  }, [result]);
  return memoizedValue;
};