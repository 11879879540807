// gimme: conversations-inbox-ui 06/10/2020 24a2e66917cc934433dd111db7e06fd02943ca6a
'use es6';

import { getCurrentThreadId } from 'conversations-thread-data/thread-details/public/selectors';
import { restoreMessage } from '../../thread-deletion/actions/restoreMessage';
export const restoreCommonMessage = ({
  messageId
}) => (dispatch, getState) => {
  const threadId = getCurrentThreadId(getState());
  dispatch(restoreMessage({
    messageId,
    threadId
  }));
};