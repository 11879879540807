import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"];
import { Record, Map as ImmutableMap, List } from 'immutable';
// @ts-expect-error module not typed
import { CalleesRecord } from 'calling-lifecycle-internal/callees/records/CalleesRecords';
import { AsyncDataRecord } from 'calling-lifecycle-internal/universal-calling-store/records/AsyncDataRecord';
import CallingProvider from 'calling-internal-common/call-provider/records/CallingProvider';
import { getIn } from '../../utils/getIn';
const isSuccessfulResult = result => result && result['@result'] === 'OK';
export const createInstalledCallingSettings = ({
  installedCallingApplications
}) => ImmutableMap({
  installedCallingApplications: List(installedCallingApplications.map(application => new CallingProvider(application)))
});
const parseInstalledCallingSettings = installedCallingSettings => {
  let installedCallingSettingsParsed = ImmutableMap({
    error: installedCallingSettings && installedCallingSettings.error
  });
  if (installedCallingSettings && installedCallingSettings.result) {
    installedCallingSettingsParsed = createInstalledCallingSettings({
      installedCallingApplications: installedCallingSettings.result.results
    });
  } else if (installedCallingSettings && installedCallingSettings.installedCallingApplications) {
    installedCallingSettingsParsed = createInstalledCallingSettings(installedCallingSettings);
  }
  return installedCallingSettingsParsed;
};
const parseCallableObjectAndAssociations = callableObjectAndAssociations => {
  let callableObjectAndAssociationsParsed = null;
  if (isSuccessfulResult(callableObjectAndAssociations)) {
    callableObjectAndAssociationsParsed = new CalleesRecord(callableObjectAndAssociations);
  } else if (callableObjectAndAssociations && callableObjectAndAssociations.fromObject) {
    callableObjectAndAssociationsParsed = CalleesRecord.fromJS(callableObjectAndAssociations);
  }
  return callableObjectAndAssociationsParsed || new CalleesRecord();
};
const parseSelectedThirdPartyProvider = callingProviderSettings => {
  return getIn(['result', 'selectedThirdPartyCallingProvider'], callingProviderSettings, null);
};
export class CalleeOmnibusResult extends Record({
  callableObjectAndAssociations: null,
  installedCallingSettings: ImmutableMap(),
  selectedThirdPartyProvider: null
}, 'CalleeOmnibusResult') {
  constructor(args = {}) {
    const {
      callableObjectAndAssociations,
      installedCallingSettings,
      callingProviderSettings
    } = args;
    super({
      callableObjectAndAssociations: parseCallableObjectAndAssociations(callableObjectAndAssociations),
      installedCallingSettings: parseInstalledCallingSettings(installedCallingSettings),
      selectedThirdPartyProvider: parseSelectedThirdPartyProvider(callingProviderSettings)
    });
  }
}
export class AsyncCalleeOmnibusResult extends AsyncDataRecord({
  data: new CalleeOmnibusResult()
}, 'AsyncCalleeOmnibusResult') {
  constructor(_ref = {}) {
    let {
        data
      } = _ref,
      options = _objectWithoutPropertiesLoose(_ref, _excluded);
    options.data = new CalleeOmnibusResult(data || {});
    super(options);
  }
}