const _unique = definitions => {
  const names = {};
  return definitions.filter(definition => {
    if (definition.kind !== 'FragmentDefinition') {
      return true;
    }
    const name = definition.name.value;
    if (names[name]) {
      return false;
    } else {
      names[name] = true;
      return true;
    }
  });
};
import { gql, useQuery } from '@apollo/client';
import { PROPERTY_FIELDS_FRAGMENT } from '../../fragments/SubscriptionFragments';
import { getValue } from '../../utils/common';
import { useRetry } from '../../hooks/useRetry';
import { handleError } from '../../utils/errorHandling';
const CANCELLATION_QUERY = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"FetchCancellationProperties\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectId\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"Long\"}}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"crmObject\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectId\"}}},{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"type\"},\"value\":{\"kind\":\"StringValue\",\"value\":\"0-69\",\"block\":false}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"InlineFragment\",\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"PaymentsSubscription\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"defaultProperties\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"hs_payment_method\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"PropertyFieldsFragment\"}}]}}]}}]}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: _unique([{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "FetchCancellationProperties"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "objectId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Long"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "crmObject"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "id"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "objectId"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "type"
          },
          value: {
            kind: "StringValue",
            value: "0-69",
            block: false
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "PaymentsSubscription"
              }
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "defaultProperties"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "hs_payment_method"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "PropertyFieldsFragment"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }].concat(PROPERTY_FIELDS_FRAGMENT.definitions))
});
export function isCancellationQueryValid(data) {
  var _data$crmObject, _data$crmObject$defau;
  return Boolean((data === null || data === void 0 || (_data$crmObject = data.crmObject) === null || _data$crmObject === void 0 ? void 0 : _data$crmObject.__typename) === 'PaymentsSubscription' && ((_data$crmObject$defau = data.crmObject.defaultProperties) === null || _data$crmObject$defau === void 0 ? void 0 : _data$crmObject$defau.hs_payment_method));
}
export function useCancellationQuery(objectId) {
  const query = useQuery(CANCELLATION_QUERY, {
    variables: {
      objectId
    },
    fetchPolicy: 'cache-and-network'
  });
  const startRetrying = useRetry({
    retry: () => {
      query.refetch().catch(handleError);
    },
    shouldRetry: () => Boolean(!isCancellationQueryValid(query.data) || query.error),
    onFailedAfterRetries: () => {
      throw new Error('useCancellationQuery failed to finish loading after retries');
    }
  });
  if (query.loading) {
    return Object.assign({}, query, {
      data: null
    });
  }
  if (!query.data) {
    return Object.assign({}, query, {
      data: null
    });
  }
  if (!isCancellationQueryValid(query === null || query === void 0 ? void 0 : query.data) || query.error) {
    startRetrying();
    return Object.assign({}, query, {
      data: null
    });
  }
  const {
    crmObject: {
      defaultProperties: {
        hs_payment_method
      }
    }
  } = query.data;
  const paymentMethod = getValue(hs_payment_method) || '';
  return Object.assign({}, query, {
    data: {
      paymentMethod
    }
  });
}