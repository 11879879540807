import Raven from 'raven-js';
export const safeParse = data => {
  let parsedData;
  try {
    parsedData = JSON.parse(data);
  } catch (error) {
    Raven.captureException(error);
    throw new Error(error);
  }
  return parsedData;
};