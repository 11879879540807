import get from 'transmute/get';
export const getQuickReplies = quickRepliesAttachment => {
  return get('quickReplies', quickRepliesAttachment);
};
export const isReplyWithLink = quickReply => {
  return get('valueType', quickReply) === 'URL';
};
export const getURLTypeReplies = quickReplies => {
  return quickReplies.filter(isReplyWithLink);
};
export const getTextTypeReplies = quickReplies => {
  return quickReplies.filterNot(isReplyWithLink);
};