export const EMAIL = 'email';
export const LOGGED_EMAIL = 'logged-email';
export const NOTE = 'note';
export const CALL = 'call';
export const LOGGED_CALL = 'logged-call';
export const SCHEDULE = 'schedule';
export const LOGGED_MEETING = 'logged-meeting';
export const TASK = 'task';
export const LINKED_IN = 'linked-in';
export const LINKED_IN_MESSAGE = 'linked-in-message';
export const LINKED_IN_CONNECT = 'linked-in-connect';
export const WHATS_APP = 'whats-app';
export const LOGGED_LINKED_IN_MESSAGE = 'logged-linked-in-message';
export const LOGGED_POSTAL_MAIL = 'logged-postal-mail';
export const LOGGED_SMS = 'logged-sms';
export const LOGGED_WHATS_APP = 'logged-whats-app';
export const ENROLLED_IN_SEQUENCE = 'enrolled-in-sequence';
export const INTERACTIONS = [EMAIL, LOGGED_EMAIL, NOTE, CALL, LOGGED_CALL, SCHEDULE, LOGGED_MEETING, TASK, LINKED_IN, LINKED_IN_MESSAGE, LINKED_IN_CONNECT, WHATS_APP, LOGGED_LINKED_IN_MESSAGE, LOGGED_POSTAL_MAIL, LOGGED_SMS, LOGGED_WHATS_APP, ENROLLED_IN_SEQUENCE];

/**
 * @deprecated Use Interaction
 */

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
// @ts-expect-error module/module.exports aren't currently supported
if (!!module && !!module.exports) {
  // @ts-expect-error module/module.exports aren't currently supported
  module.exports.default = Object.assign({}, module.exports);
}