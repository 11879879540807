import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { omnibusFulfilled } from 'find-and-filter-data/omnibus/public';
import { threadsBulkUpdated } from 'find-and-filter-data/realtime-updates/public';
import { fetchViewMembers } from 'find-and-filter-data/view-members-data/protected';
import { fetchViews, pollViews } from 'find-and-filter-data/views-data/public';
const initialState = {
  data: {}
};
export const bulkOperationsSlice = createSlice({
  name: 'bulkOperations',
  initialState,
  extraReducers: builder => {
    builder.addCase(threadsBulkUpdated, (state, action) => {
      const {
        failedThreadIds,
        successfulThreadIds,
        updateType,
        timestamp
      } = action.payload.message;
      failedThreadIds.concat(successfulThreadIds).forEach(threadId => {
        state.data[threadId] = {
          updateType,
          timestamp
        };
      });
    }).addCase(fetchViewMembers.pending, (state, action) => {
      const isInitialFetch = !action.meta.arg.offsetId;
      if (isInitialFetch) {
        state.data = {};
      }
    }).addCase('FETCH_SEARCH_AND_FILTER_RESULTS_STARTED', (state, action) => {
      const isInitialFetch = !action.payload.requestArgs.offsetId;
      if (isInitialFetch) {
        state.data = {};
      }
    }).addMatcher(isAnyOf(fetchViews.pending, pollViews.pending, omnibusFulfilled), state => {
      state.data = {};
    });
  },
  reducers: {}
});
export function selectBulkOperations(state) {
  return state.findAndFilterData.bulkOperations.data;
}