import promiseTimeout from 'hs-promise-utils/timeout';
import { buildError } from '../../error-reporting/builders/buildError';
import { buildRequestTimeoutError } from '../../errors/builders/buildRequestTimeoutError';
import { PROMISE_TIMEOUT_MS } from '../constants/defaults';
export function reauthorize(auth) {
  return promiseTimeout(new Promise((resolve, reject) => {
    auth.authorize(undefined, undefined, errorInfo => {
      if (errorInfo) {
        reject(buildError(`PubSub reauthorization failure: ${errorInfo.code}`, errorInfo));
      }
      resolve();
    });
  }), PROMISE_TIMEOUT_MS, buildRequestTimeoutError('PubSub reauthorization timeout'));
}