export const DEBUG_CALLING_SHARED_WORKER = 'DEBUG_CALLING_SHARED_WORKER';
const debuggingObj = {
  messageDebug: false,
  localEmbed: false
};
let hasRun = false;
export const resetHasRun = () => {
  hasRun = false;
};
export const getIsDebugging = () => {
  if (hasRun) {
    return debuggingObj;
  }
  hasRun = true;
  try {
    if (localStorage && localStorage.getItem(DEBUG_CALLING_SHARED_WORKER) === 'true') {
      debuggingObj.messageDebug = true;
      debuggingObj.localEmbed = true;
      return debuggingObj;
    }
    const callingSharedWorkerDebug = localStorage && JSON.parse(localStorage.getItem(DEBUG_CALLING_SHARED_WORKER) || '{}');
    if (callingSharedWorkerDebug) {
      const {
        messageDebug,
        localEmbed
      } = callingSharedWorkerDebug;
      debuggingObj.messageDebug = messageDebug === true;
      debuggingObj.localEmbed = localEmbed === true;
    }
    return debuggingObj;
  } catch (e) {
    return debuggingObj;
  }
};