const CONVERSATIONS_HIDDEN_SENSITIVE_FOLDER_PATH = 'conversations_hidden_files/Sensitive Files';
const CONVERSATIONS_HIDDEN_FOLDER_PATH = 'conversations_hidden_files';
export const createFormDataForFileUpload = ({
  file,
  access = 'HIDDEN_IN_APP_PRIVATE_NOT_INDEXABLE'
}) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('options', JSON.stringify({
    access
  }));
  formData.append('folderPath', access === 'HIDDEN_IN_APP_SENSITIVE' ? CONVERSATIONS_HIDDEN_SENSITIVE_FOLDER_PATH : CONVERSATIONS_HIDDEN_FOLDER_PATH);
  return formData;
};