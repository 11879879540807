import FromNumber from 'calling-internal-common/calls-v1-settings/records/InitialLoadSettingsRecords/FromNumber';
import { getSetting, setSetting } from '../local-settings/localSettings';
import { CALL_FROM_BROWSER } from 'calling-internal-common/constants/CallMethods';
import get from 'transmute/get';
import CallingProvider from 'calling-internal-common/call-provider/records/CallingProvider';
import { getHubSpotCallingProvider } from '../call-provider/operators/callProviderOperators';
export const PROVIDER = 'selectedCallProvider';
export const setPersistedCallProvider = callProvider => setSetting(PROVIDER, callProvider);
export const getPersistedCallProvider = () => getSetting(PROVIDER, getHubSpotCallingProvider(), data => {
  let {
    width,
    height
  } = data;
  if (data.options) {
    width = data.options.width;
    height = data.options.height;
    delete data.options;
  }
  return new CallingProvider(Object.assign({}, data, {
    width,
    height
  }));
});
export const METHOD = 'selectedCallMethod';
export const setPersistedCallMethod = callMethod => setSetting(METHOD, callMethod);
export const getPersistedCallMethod = () => getSetting(METHOD, CALL_FROM_BROWSER);
export const FROM_NUMBER = 'selectedFromNumber';
export const CONNECT_FROM_NUMBER = 'selectedConnectFromNumber';
export const setPersistedFromNumber = selectedFromNumber => setSetting(FROM_NUMBER, selectedFromNumber);
export const setPersistedConnectFromNumber = selectedFromNumber => setSetting(CONNECT_FROM_NUMBER, selectedFromNumber);
export const getPersistedFromNumber = (fromNumberKey = FROM_NUMBER) => getSetting(fromNumberKey, null, properties => new FromNumber(properties));
export const getPersistedFromNumberWithFallback = ({
  fromNumberKey,
  fromNumbers
}) => {
  // this get function seems to only return undefined
  const defaultNumber = fromNumbers && get(0, fromNumbers);
  const defaultFromNumber = defaultNumber || null;
  const storedFromNumber = getPersistedFromNumber(fromNumberKey);
  if (!storedFromNumber) {
    setSetting(fromNumberKey, defaultFromNumber);
    return defaultFromNumber;
  }
  const currentFriendlyName = get('friendlyName', storedFromNumber);
  const hasCurrentFromNumber = fromNumbers && fromNumbers.find(fromNumber => {
    const friendlyName = get('friendlyName', fromNumber);
    return friendlyName === currentFriendlyName;
  });
  if (hasCurrentFromNumber) {
    return hasCurrentFromNumber;
  }

  // User deleted the current saved fromNumber
  setSetting(fromNumberKey, defaultFromNumber);
  return defaultFromNumber;
};
export const GATES = 'Gates';
export const getGatesFromStorage = () => getSetting(GATES, [], gates => gates && gates.split(',') || []);