import { getEditorStateFromRawContent } from './getEditorStateFromRawContent';
export const deserializeEditorState = rawContent => {
  try {
    if (rawContent) {
      const editorState = getEditorStateFromRawContent(rawContent);
      if (editorState) {
        return editorState;
      }
    }
    return null;
  } catch (e) {
    return null;
  }
};