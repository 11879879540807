import { List as ImmutableList } from 'immutable';
import Raven from 'raven-js';
import { getEmailMetadataAttachment, getFileAttachments, getTORecipients, getCCRecipients, getBCCRecipients } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getFileIds } from 'conversations-message-history/common-message-format/operators/fileAttachmentGetters';
export const logCommonMessageSubmit = (commonMessage, options) => {
  let commonMessageSubmitData = Object.assign({}, options);
  const emailMetadataAttachment = getEmailMetadataAttachment(commonMessage);
  if (emailMetadataAttachment) {
    const toCount = getTORecipients(commonMessage).size;
    const ccCount = getCCRecipients(commonMessage).size;
    const bccCount = getBCCRecipients(commonMessage).size;
    commonMessageSubmitData = Object.assign({
      toCount,
      ccCount,
      bccCount
    }, commonMessageSubmitData);
  }
  const fileAttachments = getFileAttachments(commonMessage);
  if (fileAttachments) {
    const fileIds = getFileIds(fileAttachments) || ImmutableList();
    commonMessageSubmitData['fileAttachmentCount'] = fileIds.size;
  }
  Raven.capturePageEvent('commonMessageSubmit', {
    extra: commonMessageSubmitData
  });
};