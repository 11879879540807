import { getData } from 'conversations-async-data/async-data/operators/getters';
import { isSucceeded } from 'conversations-async-data/async-data/operators/statusComparators';

// TODO: move to composer-data

// TODO: move to composer-data

// TODO: move to composer-data
import invariant from 'conversations-thread-data/utils/invariant';
import { isEmailSuspended } from '../operators/isEmailSuspended';
export const fromAccountSuspended = ({
  asyncConnectedAccounts,
  hostedEmailEnabled,
  reply
}) => {
  if (hostedEmailEnabled) {
    return null;
  }
  invariant(Boolean(asyncConnectedAccounts), 'asyncConnectedAccounts is required for fromAccountSuspended disabling strategy');
  invariant(Boolean(reply), 'reply is required for fromAccountSuspended disabling strategy');
  const connectedAccounts = isSucceeded(asyncConnectedAccounts) && getData(asyncConnectedAccounts);
  if (connectedAccounts && isEmailSuspended({
    connectedAccounts,
    hostedEmailEnabled,
    reply
  })) {
    return {
      disabled: true,
      sendDisabledReason: 'FROM_ACCOUNT_SUSPENDED'
    };
  }
  return null;
};