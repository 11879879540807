/*
BELOW_LIMIT:       limitPercentage < 70
APPROACHING_LIMIT: 70 <= limitPercentage < 90
TOUCHING_LIMIT:    90 <= limitPercentage < 100
LIMITED:           limitPecentage >= 100
*/

export const BELOW_LIMIT = 'BELOW_LIMIT';
export const APPROACHING_LIMIT = 'APPROACHING_LIMIT';
export const TOUCHING_LIMIT = 'TOUCHING_LIMIT';
export const LIMITED = 'LIMITED';