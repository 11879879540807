import http from 'hub-http/clients/apiClient';
export function fetchBlocklistCheckEmails({
  emailsToCheck
}) {
  return http.post('engagements-emails/v1/blocklist/check-emails', {
    data: {
      emailsToCheck,
      blocklistFilter: 'PORTAL_ONLY'
    }
  });
}