import I18n from 'I18n';
import buildDefaultTypicalResponseTime from '../../typical-response-time/factories/buildDefaultTypicalResponseTime';
import * as AvailabilityStrategies from '../constants/AvailabilityStrategies';
import AlwaysAvailableConfig from '../records/AlwaysAvailableConfig';
import TeamMembersConfig from '../records/TeamMembersConfig';
import OfficeHoursConfig from '../records/OfficeHoursConfig';
export const buildNewConfigRecordForStrategy = settings => {
  switch (settings.availabilityStrategy) {
    case AvailabilityStrategies.ALWAYS:
      return new AlwaysAvailableConfig(Object.assign({
        typicalResponseTime: buildDefaultTypicalResponseTime()
      }, settings.availabilityStrategyConfig));
    case AvailabilityStrategies.TEAM_MEMBERS:
      return new TeamMembersConfig(Object.assign({
        typicalResponseTime: buildDefaultTypicalResponseTime()
      }, settings.availabilityStrategyConfig, {
        awayMessage: settings.availabilityStrategyConfig && settings.availabilityStrategyConfig.awayMessage || I18n.text('conversations-internal-schema.availability.awayMessage.defaultMessageText')
      }));
    case AvailabilityStrategies.OFFICE_HOURS:
      return new OfficeHoursConfig(Object.assign({
        typicalResponseTime: buildDefaultTypicalResponseTime(),
        outsideOfficeHoursMessage: I18n.text('conversations-internal-schema.availability.outsideOfficeHoursMessage.defaultMessageText')
      }, settings.availabilityStrategyConfig, {
        awayMessage: settings.availabilityStrategyConfig && settings.availabilityStrategyConfig.awayMessage || I18n.text('conversations-internal-schema.availability.awayMessage.defaultMessageText')
      }));
    default:
      return null;
  }
};