import PortalIdParser from 'PortalIdParser';
const IFRAME_HOST_LOCAL_STORAGE_KEY = 'invoices-editor-iframe-host-url';
export function getHostUrl() {
  let localOverride;
  try {
    localOverride = localStorage[IFRAME_HOST_LOCAL_STORAGE_KEY];
  } catch (e) {
    // Do nothing
  }
  if (localOverride) {
    return localOverride;
  }
  const host = window.location.host;
  return host.includes('local') ? host.replace('local', 'app') : host;
}
export function getPathname() {
  return `/invoices/${PortalIdParser.get()}`;
}
export function getIframeSrc() {
  return `https://${getHostUrl()}${getPathname()}/embedded`;
}