// gimme: conversations-inbox-ui 05/26/2020 741c62dc71ca742adbc6d64d61d30de7cb46539f

import { createAction } from 'redux-actions';
// constant directory has been migrated to thread-data, the remaining the this directory will be moved over soon
import { PUBLISH_EMAIL } from 'conversations-thread-data/email/public/constants/asyncActionTypes';
export const publishEmailFailed = createAction(PUBLISH_EMAIL.FAILED, ({
  error,
  message,
  threadId,
  channel,
  allowRetry,
  transient
}) => ({
  error,
  message,
  threadId,
  channel,
  allowRetry,
  transient
}));