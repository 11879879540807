export const UUID = (prefix = '') => {
  let uuid = '';
  let r;
  for (let i = 0; i < 32; i++) {
    // eslint-disable-next-line no-bitwise
    r = Math.random() * 16 | 0;
    if (i === 8 || i === 12 || i === 16 || i === 20) {
      uuid += '-';
    }
    // eslint-disable-next-line no-bitwise
    uuid += (i === 12 ? 4 : i === 16 ? r & 3 | 8 : r).toString(16);
  }
  return `${prefix}_${uuid}`;
};