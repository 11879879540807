import { Record } from 'immutable';
import TypicalResponseTime from '../../typical-response-time/records/TypicalResponseTime';
import { TEAM_MEMBERS } from '../constants/AvailabilityStrategies';
import { AWAY_MESSAGE } from '../constants/AwayStrategies';
export default class TeamMembersConfig extends Record({
  '@type': TEAM_MEMBERS,
  awayMessage: null,
  teamMembersAvailabilityStrategy: AWAY_MESSAGE,
  typicalResponseTime: TypicalResponseTime()
}, 'TeamMembersConfig') {
  constructor(properties = {}) {
    properties = properties || {};
    super(Object.assign({}, properties, {
      typicalResponseTime: properties.typicalResponseTime ? TypicalResponseTime(properties.typicalResponseTime) : null
    }));
  }
}