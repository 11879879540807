/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { OrderedMap } from 'immutable';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRealtimeViewMemberUpdates } from '../../../view-member-optimistic-updates/public';
import { getHasMore, getMembers, getOffsetId, getOffsetTimestamp, getTotalCount } from '../../../view-members-schema/protected';
import { applyViewMemberUpdates } from '../applyViewMemberUpdates';
import { selectHasViewMembersFailed, selectIsViewMembersPageLoading, selectViewMembersPageById } from '../viewMemberSelectors';
import { fetchViewMembers } from '../viewMembersSlice';
const noop = () => {};
export const useViewMembers = ({
  inboxId,
  limit,
  skip = false,
  sortDirection,
  threadStatus,
  viewId
}) => {
  const dispatch = useDispatch();
  const failed = useSelector(selectHasViewMembersFailed);
  const loading = useSelector(selectIsViewMembersPageLoading);
  const viewMembersPage = useSelector(state => selectViewMembersPageById(state, {
    viewId
  }));
  const {
    viewMemberUpdates
  } = useRealtimeViewMemberUpdates();
  // Initializing this as an empty string so hasViewIdChanged
  // evaluates to true on the first run
  const cachedViewId = useRef('');
  const hasViewIdChanged = cachedViewId.current !== viewId;
  // Fetch the view members for the provided inboxId and viewId if they haven't
  // already been and are not currently being fetched.
  if (viewId && hasViewIdChanged && !viewMembersPage && !loading && !failed && !skip) {
    dispatch(fetchViewMembers({
      inboxId,
      limit,
      sortDirection,
      threadStatus,
      viewId
    }));
    cachedViewId.current = viewId;
  }
  //Define functions to make subsequent requests and to refetch view members.
  const hasMore = viewMembersPage ? getHasMore(viewMembersPage) : false;
  const offsetId = viewMembersPage ? getOffsetId(viewMembersPage) : undefined;
  const offsetTimestamp = viewMembersPage ? getOffsetTimestamp(viewMembersPage) : undefined;
  const totalCount = viewMembersPage ? getTotalCount(viewMembersPage) : 0;
  const rawViewMembers = viewMembersPage ? getMembers(viewMembersPage) : undefined;

  //If there's no viewId, don't even bother continuing.
  if (!viewId) {
    return {
      error: new Error('inboxId and viewId must be provided to fetch view members'),
      fetchMore: noop,
      hasMore,
      isInitialPageReceived: false,
      loading: false,
      failed,
      refetch: noop,
      totalCount,
      viewMembers: rawViewMembers || OrderedMap()
    };
  }
  const isInitialPageReceived = Boolean(rawViewMembers);
  const fetchMore = () => {
    if (hasMore) {
      dispatch(fetchViewMembers({
        inboxId,
        limit,
        offsetId,
        offsetTimestamp,
        sortDirection,
        threadStatus,
        viewId
      }));
    }
  };
  const refetch = ({
    inboxId: refetchInboxId = inboxId,
    limit: refetchLimit = limit,
    sortDirection: refetchSortDirection = sortDirection,
    threadStatus: refetchThreadStatus = threadStatus,
    viewId: refetchViewId = viewId
  } = {}) => {
    dispatch(fetchViewMembers({
      inboxId: refetchInboxId,
      limit: refetchLimit,
      sortDirection: refetchSortDirection,
      threadStatus: refetchThreadStatus,
      viewId: refetchViewId
    }));
  };
  const viewMembers = applyViewMemberUpdates({
    viewMemberUpdates,
    viewMembers: rawViewMembers
  });
  return {
    error: null,
    fetchMore,
    hasMore,
    isInitialPageReceived,
    loading,
    refetch,
    totalCount,
    viewMembers,
    failed
  };
};