/*
 * Sample serverId: frontend.433a.2.us-east-1-A.i-0156b63288d9d7941.e7daU7N6AArZ3e
 * ^(?:\w+\.)+      frontend.4332.2.
 * ([\w-]+)  us-east-1-A <-- captured
 * .*        .i-0156b63288d9d7941.e7daU7N6AArZ3e
 */
const regex = new RegExp(/^(?:\w+\.)+([\w+-]+).*/);
export const getConnectionMetadata = function getConnectionMetadata(connection) {
  try {
    // ConnectionManager is a non-documented prop that doesn't exist in ably's types. We
    // spoke with ably dev team and they recommented parsing this prop to get region.
    // Putting this under a try/catch to account for ably changing this w/o notification.
    const connectionDetails = connection.connectionManager.connectionDetails;
    if (connectionDetails) {
      const {
        serverId
      } = connectionDetails;
      const result = regex.exec(serverId);
      if (result) {
        return {
          'ably-region': result[1]
        };
      }
    }
    return {};
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to get ably region. ', error);
    return {};
  }
};