'use es6';

import { getAttachments } from './getAttachments';
import { INLINE_IMAGES } from '../constants/attachmentTypes';
import { List } from 'immutable';
import get from 'transmute/get';
export const getHasInlineImageAttachments = message => {
  const attachments = getAttachments(message) || List();
  const inlineImage = attachments.find(attachment => {
    return get('@type', attachment) === INLINE_IMAGES;
  });
  return !!inlineImage;
};