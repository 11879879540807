import enviro from 'enviro';
import PortalIdParser from 'PortalIdParser';

/**
 * This should be returned from the BE via the
 * omnibus endpoint eventually, but for now we
 * can construct it since the format is known.
 */
export const getCurrentPortalChannel = () => {
  const portalId = PortalIdParser.get();
  const env = enviro.isProd() ? 'prod' : 'qa';
  return `${env}.messages.portal-update-${portalId}`;
};