import { isSucceeded } from 'conversations-async-data/async-data/operators/statusComparators';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import { pollOmnibus as pollOmnibusAction } from 'conversations-inbox-lib/omnibus/public/actions';
import { getErrorUpstreams } from 'conversations-inbox-lib/omnibus/public/operators';
import { getOmnibusErrorUpstreams } from 'conversations-inbox-lib/omnibus/public/selectors';
import { getLastViewedInboxFromLocalStorage } from 'conversations-inbox-lib/utils/last-viewed-view-and-inbox/getLastViewedInboxFromLocalStorage';
import { getLastViewedViewFromLocalStorage } from 'conversations-inbox-lib/utils/last-viewed-view-and-inbox/getLastViewedViewFromLocalStorage';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConnectedAccountsAsyncData } from '../../connected-accounts/selectors/getConnectedAccountsAsyncData';
import { getThreadListMemberFilters } from '../../thread-list-member/selectors/getThreadListMemberFilters';
import { setupWithOmnibus } from '../actions/setupWithOmnibus';
const MAX_TIMEOUT = 11000;
const TWO_SECONDS_IN_MS = 2000;
const INITIAL_RETRIES = 5;
const getTimeout = (initialTimeout, currentRetries) => initialTimeout - currentRetries * TWO_SECONDS_IN_MS;
// this should increase the timeout by one second for each retry,
// so that the initial retry happens fast but we aren't hitting the endpoint too hard

export const useOmnibusPolling = (initialThreadId, defaultTimeout = MAX_TIMEOUT) => {
  const dispatch = useDispatch();
  const retriesRef = useRef(INITIAL_RETRIES);
  const connectedAccountsFetched = isSucceeded(useSelector(getConnectedAccountsAsyncData));
  const isOmnibusSetUp = connectedAccountsFetched;
  const failedUpstreams = useSelector(getOmnibusErrorUpstreams);
  const shouldPollOmnibus = failedUpstreams.length > 0 && retriesRef.current > 0;
  const [threadId] = useState(initialThreadId);
  const inboxId = getLastViewedInboxFromLocalStorage();
  const viewId = getLastViewedViewFromLocalStorage();
  const {
    sortDirection,
    threadStatus
  } = useSelector(getThreadListMemberFilters);
  useEffect(() => {
    let timeoutKey;
    if (shouldPollOmnibus) {
      const pollOmnibus = () => setTimeout(() => {
        retriesRef.current = retriesRef.current - 1;
        dispatch(pollOmnibusAction({
          inboxId,
          sortDirection,
          threadId: threadId || null,
          threadStatus,
          viewId,
          silent: true
        })).then(action => {
          if (!isOmnibusSetUp) {
            dispatch(setupWithOmnibus(action));
          }
          if (getErrorUpstreams(action.payload.data).length === 0 || retriesRef.current === 0) {
            clearTimeout(timeoutKey);
          } else {
            timeoutKey = pollOmnibus();
          }
        }).catch(error => reportError({
          error
        }));
      }, getTimeout(defaultTimeout, retriesRef.current));
      timeoutKey = pollOmnibus();
    }
    return () => clearTimeout(timeoutKey);
  }, [isOmnibusSetUp, shouldPollOmnibus, retriesRef, inboxId, sortDirection, threadId, threadStatus, viewId, dispatch, defaultTimeout]);
};