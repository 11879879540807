import { ChatSpotV1ClientApi } from '../clients/ChatSpotApi';
export const sendFeedbackApi = ({
  messageId,
  options,
  feedbackMessage,
  vote
}) => {
  return ChatSpotV1ClientApi.put(`chat-messages/${messageId}/feedback`, {
    data: {
      vote,
      feedbackGeneric: options && options.join(),
      feedbackFreeForm: feedbackMessage
    }
  });
};