import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["customViews", "isFallbackModeEnabled", "latestPlaybackMessageTimestamp", "threadListMembers", "threadLists"];
/**
 * @description This function will accept the omnibus object and will split it
 * into the Find & Filter Omnibus and the remaining omnibus object. This returns
 * a tuple with the findAndFilterOmnibus first and the remaining omnibus second.
 */
export function parseOmnibus(omnibus) {
  const {
      customViews,
      isFallbackModeEnabled,
      latestPlaybackMessageTimestamp,
      threadListMembers,
      threadLists
    } = omnibus,
    remainingOmnibus = _objectWithoutPropertiesLoose(omnibus, _excluded);
  const findAndFilterOmnibus = {
    customViews,
    isFallbackModeEnabled,
    latestPlaybackMessageTimestamp,
    threadLists,
    threadListMembers
  };
  return [findAndFilterOmnibus, remainingOmnibus];
}