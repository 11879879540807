import * as DatePresets from 'UIComponents/dates/dateRangePresets';
import { beginningOfTime, daysAgo, nextDay, now, toMomentUTC
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'UICo... Remove this comment to see the full error message
} from 'UIComponents/core/SimpleDate';
export const CustomDatePresets = {
  ALL_TIME: {
    presetId: 'ALL_TIME',
    getValue: tz => ({
      startDate: beginningOfTime(),
      endDate: now(tz)
    })
  },
  TODAY: {
    presetId: 'TODAY',
    getValue: tz => ({
      startDate: now(tz),
      endDate: nextDay(now(tz))
    })
  },
  LAST_365_DAYS: {
    presetId: 'LAST_365_DAYS',
    getValue: tz => ({
      startDate: daysAgo(364, tz),
      endDate: now(tz)
    })
  },
  CUSTOM_DATE_RANGE: {
    presetId: 'CUSTOM_DATE_RANGE',
    getValue: () => ({
      startDate: null,
      endDate: null
    })
  }
};
export const DATE_RANGES = [CustomDatePresets.ALL_TIME, CustomDatePresets.TODAY, DatePresets.THIS_WEEK, DatePresets.LAST_WEEK, DatePresets.THIS_MONTH, DatePresets.LAST_MONTH, DatePresets.THIS_YEAR, DatePresets.LAST_YEAR, DatePresets.LAST_THIRTY_DAYS, CustomDatePresets.LAST_365_DAYS, CustomDatePresets.CUSTOM_DATE_RANGE];
export function convertDateRangeToPresetId(dateRange) {
  if (!dateRange.startDate && !dateRange.endDate) {
    return CustomDatePresets.ALL_TIME.presetId;
  }
  const startDate = parseInt(dateRange.startDate, 10);
  const endDate = parseInt(dateRange.endDate, 10);
  for (const range of DATE_RANGES) {
    const rangeValue = range.getValue('utc');
    if (toMomentUTC(rangeValue.startDate).valueOf() === startDate && toMomentUTC(rangeValue.endDate).valueOf() === endDate) {
      return range.presetId;
    }
  }
  return CustomDatePresets.CUSTOM_DATE_RANGE.presetId;
}