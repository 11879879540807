// @ts-expect-error module not typed
import EmailAddressPattern from 'PatternValidationJS/patterns/EmailAddress';
import { getCommonMessage } from './commonReplyGetters';
import { getRecipients } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getDeliveryIdentifierValue } from 'conversations-message-history/common-message-format/operators/deliveryIdentifierGetters';
import { getSingleDeliveryIdentifier } from 'conversations-message-history/common-message-format/operators/recipientGetters';
export const isEmailInvalid = reply => {
  const commonMessage = getCommonMessage(reply);
  const recipients = getRecipients(commonMessage);
  return recipients && recipients.some(recipient => {
    const recipientEmailAddress = getDeliveryIdentifierValue(getSingleDeliveryIdentifier(recipient));
    return !EmailAddressPattern.test(recipientEmailAddress);
  });
};