import * as devLogger from '../logger/devLogger';
import { setExtraContext } from './RavenWrapper';

/**
 * Create a logger that buffers the maxLines most recent logs and sends them to sentry in
 * extra data if an exception occurs.
 *
 * Use this judiciously; there is a 200kb cap on individual event payloads.
 *
 * https://docs.sentry.io/enriching-error-data/context/?platform=browser#extra-context
 *
 * @example
 * const logger = createSentryLogger({ maxLines: 5, sentryDataKey: 'testDebugLogs' });
 *
 * logger.log('this is a test');
 * // Sentry context is now { testDebugLogs: [['this is a test']] }
 * logger.log('this can capture useful info', 123);
 * // Sentry context is now { testDebugLogs: [['this is a test'], ['this can capture useful info', 123]] }
 */
export function createSentryLogger({
  maxLines = 25,
  sentryDataKey
}) {
  if (maxLines > 100) {
    throw new Error('Max lines cannot exceed 100');
  }
  if (typeof sentryDataKey !== 'string') {
    throw new Error('`sentryDataKey` is required');
  }
  const lines = [];
  function buildLogger(methodName) {
    return function (...line) {
      const timestamp = new Date().toISOString();
      lines.push([timestamp, ...line]);
      if (lines.length > maxLines) {
        lines.shift();
      }
      setExtraContext({
        [sentryDataKey]: lines
      });
      devLogger[methodName](...line);
    };
  }
  return {
    error: buildLogger('error'),
    info: buildLogger('info'),
    log: buildLogger('log'),
    trace: buildLogger('trace'),
    warn: buildLogger('warn')
  };
}