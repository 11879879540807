import { getApolloContext, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { fetchThreadTicket } from './fetchThreadTickets';
import { apolloClientInvariant } from 'conversations-thread-data/graphql/invariant/apolloClientInvariant';
import { useTicketPipelines } from 'svh-tickets-lib/tickets/public/hooks';
import getIn from 'transmute/getIn';
import { getAllRequiredValues } from 'svh-tickets-lib/tickets/public/operators';

/**
 * Returns the associated tickets for the given thread, and provides
 * mechanisms to refresh the ticket data.
 */
export const useFetchAssociatedTickets = ({
  conversationId,
  deferred,
  queryOptions
}) => {
  const apolloClientFromProvider = useContext(getApolloContext()).client;
  apolloClientInvariant(apolloClientFromProvider);
  const {
    pipelines
  } = useTicketPipelines();
  const {
    loading,
    data,
    error,
    refetch
  } = useQuery(fetchThreadTicket, Object.assign({}, queryOptions, {
    variables: {
      conversationId: Number(conversationId),
      propertyNames: getAllRequiredValues(pipelines)
    },
    client: apolloClientFromProvider,
    notifyOnNetworkStatusChange: true,
    skip: deferred || !conversationId || !pipelines
  }));
  const associatedTicket = getIn(['crmObject', 'associations', 'conversationToTicketV2', 'edges', 0, 'node'], data);
  return {
    associatedTicket,
    error,
    loading,
    refetchTickets: refetch
  };
};