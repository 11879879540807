import { useSelector } from 'react-redux';

// @ts-expect-error module not typed
import { getInReplyToId } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { getGenericChannelId } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getIsUngatedForEmailForwardHistory } from 'conversations-thread-data/auth/public/selectors';
import { EMAIL_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
export function useShouldIncludeEmailReplies(commonMessage) {
  const isUngated = useSelector(getIsUngatedForEmailForwardHistory);
  return Boolean(isUngated && getGenericChannelId(commonMessage) === EMAIL_GENERIC_CHANNEL_ID && getInReplyToId(commonMessage));
}