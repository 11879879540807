export const deprioritize = (fn, timeout) => {
  if ('requestIdleCallback' in window) {
    try {
      // eslint-disable-next-line no-restricted-globals
      return requestIdleCallback(fn, {
        timeout
      });
    } catch (_unused) {
      return setTimeout(fn, timeout);
    }
  }
  return setTimeout(fn, timeout);
};
export function throttle(func, wait) {
  let timeoutID = null;
  let lastArgs = null;
  const throttledFunction = (...args) => {
    lastArgs = args;
    if (timeoutID === null) {
      func(...args);
      timeoutID = setTimeout(() => {
        timeoutID = null;
        if (lastArgs) {
          throttledFunction(...lastArgs);
          lastArgs = null;
        }
      }, wait);
    }
  };
  return throttledFunction;
}