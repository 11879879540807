import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { FETCH_FROM_OMNI_BUS, POLL_OMNIBUS } from '../../../omnibus/public/constants';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { buildInitialThreadViewStateFromOmnibus } from '../operators/buildInitialThreadViewStateFromOmnibus';
import { isSucceeded } from 'conversations-async-data/async-data/operators/statusComparators';
const initialState = new AsyncData({
  data: null
});
export const initialThreadView = handleActions({
  [FETCH_FROM_OMNI_BUS.STARTED](state) {
    return requestStarted(state);
  },
  [FETCH_FROM_OMNI_BUS.SUCCEEDED](state, action) {
    const omnibus = action.payload.data;
    const newData = buildInitialThreadViewStateFromOmnibus(omnibus);
    return requestSucceededWithOperator(() => newData, state);
  },
  [FETCH_FROM_OMNI_BUS.FAILED](state) {
    return requestFailed(state);
  },
  [POLL_OMNIBUS.STARTED](state) {
    if (isSucceeded(state)) return state;
    return requestStarted(state);
  },
  [POLL_OMNIBUS.SUCCEEDED](state, action) {
    if (isSucceeded(state)) return state;
    const omnibus = action.payload.data;
    const newData = buildInitialThreadViewStateFromOmnibus(omnibus);
    return requestSucceededWithOperator(() => newData, state);
  }
}, initialState);