import TicketExternalProperties from '../records/models/TicketExternalProperties';
import { LOAD_TICKET_EXTERNAL_PROPERTIES } from '../actions/ActionTypes';
function ticketExternalProperties(state = new TicketExternalProperties(), action) {
  switch (action.type) {
    case LOAD_TICKET_EXTERNAL_PROPERTIES:
      return action.meta === 'response' ? TicketExternalProperties.fromJS(action.payload) : state;
    default:
      return state;
  }
}
export default ticketExternalProperties;