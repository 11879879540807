import { registerQuery, registerMutation } from 'data-fetching-client';
import { markPromptAsShown, fetchPromptData, dismissPrompt } from './userPrompts.api';
export const fetchPromptDataQuery = registerQuery({
  fieldName: 'fetchPromptData',
  fetcher: fetchPromptData
});
export const markPromptAsShownMutation = registerMutation({
  fieldName: 'markPromptAsShown',
  fetcher: markPromptAsShown
});
export const dismissPromptMutation = registerMutation({
  fieldName: 'dismissPrompt',
  fetcher: dismissPrompt
});