import get from 'transmute/get';
import set from 'transmute/set';
import setIn from 'transmute/setIn';
import curry from 'transmute/curry';
import { AGENT_TO_AGENT_CHANNEL_DESCRIPTOR, AGENT_TO_AGENT_CHANNEL_NAME, AGENT_TO_VISITOR_CHANNEL_DESCRIPTOR, AGENT_TO_VISITOR_CHANNEL_NAME, ASSIGNEE, ASSOCIATED_TICKET_IDS, CRM_CONVERSATION_OBJECT_ID, CHANNELS, FILTERED_SOURCE, HAS_FILE_ATTACHMENT, INBOX_ID, LATEST_MESSAGE_TIMESTAMP, LATEST_RECEIVED_TIMESTAMP, LATEST_SENT_TIMESTAMP, SEEN, STATUS, THREAD_ID, VID, VISITOR, VISITOR_EMAIL, ORIGINAL_GENERIC_CHANNEL_ID, LATEST_MESSAGE_PREVIEW } from '../constants/threadDetailsKeyPaths';

// Core setters
export const setAgentToAgentChannelDescriptor = setIn(AGENT_TO_AGENT_CHANNEL_DESCRIPTOR);
export const setAgentToAgentChannelName = setIn(AGENT_TO_AGENT_CHANNEL_NAME);
export const setAgentToVisitorChannelDescriptor = setIn(AGENT_TO_VISITOR_CHANNEL_DESCRIPTOR);
export const setAgentToVisitorChannelName = setIn(AGENT_TO_VISITOR_CHANNEL_NAME);
export const setAssignee = setIn(ASSIGNEE);
export const setAssociatedTicketIds = setIn(ASSOCIATED_TICKET_IDS);
export const setChannels = setIn(CHANNELS);
export const setCrmConversationId = setIn(CRM_CONVERSATION_OBJECT_ID);
export const setFilteredSource = setIn(FILTERED_SOURCE);
export const setHasFileAttachment = setIn(HAS_FILE_ATTACHMENT);
export const setInboxId = setIn(INBOX_ID);
export const setLatestMessageTimestamp = setIn(LATEST_MESSAGE_TIMESTAMP);
export const setLatestReceivedTimestamp = setIn(LATEST_RECEIVED_TIMESTAMP);
export const setLatestSentTimestamp = setIn(LATEST_SENT_TIMESTAMP);
export const setSeen = setIn(SEEN);
export const setOriginalGenericChannelId = setIn(ORIGINAL_GENERIC_CHANNEL_ID);
export const setStatus = setIn(STATUS);
export const safeSetStatus = curry((status, threadDetails) => threadDetails ? setStatus(status, threadDetails) : threadDetails);
export const setThreadId = setIn(THREAD_ID);
export const setVid = setIn(VID);
export const setVisitor = setIn(VISITOR);
export const setVisitorEmail = setIn(VISITOR_EMAIL);
export const setPreview = setIn(LATEST_MESSAGE_PREVIEW);

// Non-primative setters
export const setChannelDescriptor = curry((channelDescriptor, threadDetails) => {
  const type = get('type', channelDescriptor);
  return threadDetails.updateIn(CHANNELS, set(type, channelDescriptor));
});