import { buildDisabledPropsForChannel, buildEditorDisabledPropsGetter } from 'composer-data/disable-send/public/operators';
import { noContent } from '../strategies/noContent';
import { maxMessageLength } from '../strategies/maxMessageLength';
export const buildDisabledValidator = ({
  channelId
}) => {
  const strategies = [...buildDisabledPropsForChannel({
    channelId
  }),
  // noContent and maxMessageLength should be applied to all channels but are not safe to use on mobile because they require "editorState"
  // "editorState" is different for mobile v. web so we have different validation for each platform
  noContent, maxMessageLength];
  return buildEditorDisabledPropsGetter(...strategies);
};