import { TASK_CREATE } from '../../constants/CopilotInAppActionTypes';
import { normalizeObjectType } from './utils/normalizeObjectType';
import { parseObjectId } from './utils/parseObjectId';
export const taskCreate = action => {
  return {
    data: Object.assign({
      intent: TASK_CREATE,
      task: action.task
    }, action.objectId && action.objectTypeId ? {
      associated_object: {
        object_id: parseObjectId(action.objectId),
        object_type: normalizeObjectType(action.objectTypeId)
      }
    } : {}),
    text: action.text || action.task
  };
};