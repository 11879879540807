// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'hubspot.bender' or its corresp... Remove this comment to see the full error message
import bender from 'hubspot.bender';
import { getFullUrl } from 'hubspot-url-utils';
import http from 'conversations-http/clients/http';
import Raven from 'raven-js';
export const ASSET_PATH = 'https://static.hsappstatic.net/conversations-inbox-ui';
export const RUNTIME_ASSET_PATH = 'https://static.hsappstatic.net/svh-workspace-dlb';
const ASSETS = ['bundles/inbox.js', 'sass/inbox.css'];
const RUNTIME_ASSETS = ['bundles/runtime.production.js'];
const THIRTY_MINS = 1000 * 60 * 30;
let fetchVersionInterval;
let lastCachedInboxVersion;
let lastCachedRuntimeVersion;
const prefetchAsset = (staticDomain, buildVersion, assetPath) => {
  const prefetchLink = document.createElement('link');
  prefetchLink.href = `${staticDomain}/static-${buildVersion}/${assetPath}`;
  prefetchLink.rel = 'prefetch';
  prefetchLink.as = 'script';
  // @ts-expect-error ts-migrate(2551) FIXME: Property 'crossorigin' does not exist on type 'HTM... Remove this comment to see the full error message
  prefetchLink.crossorigin = 'anonymous';
  document.head.appendChild(prefetchLink);
};
const getStaticVersionNumber = versionString => {
  if (versionString && versionString.indexOf('static-') === 0) {
    return versionString.split('static-')[1];
  }
  return 'static';
};
const precacheAssets = buildVersion => {
  ASSETS.forEach(assetPath => {
    prefetchAsset(ASSET_PATH, buildVersion, assetPath);
  });
};
const precacheRuntimeAssets = buildVersion => {
  RUNTIME_ASSETS.forEach(assetPath => {
    prefetchAsset(RUNTIME_ASSET_PATH, buildVersion, assetPath);
  });
};
const shouldPrecache = (latestVersion, currentVersion, cachedVersion) => {
  return !isNaN(latestVersion) && !isNaN(currentVersion) && latestVersion !== currentVersion && latestVersion !== cachedVersion;
};
const stopPoll = () => {
  clearInterval(fetchVersionInterval);
};
export const reset = () => {
  fetchVersionInterval = null;
  lastCachedInboxVersion = null;
  lastCachedRuntimeVersion = null;
};
export const startPrecachePoll = () => {
  Raven.setExtraContext({
    'precaching-enabled': true
  });
  if (fetchVersionInterval) {
    stopPoll();
  }
  const currentInboxVersion = getStaticVersionNumber(bender.currentProjectVersion);
  const currentRuntimeVersion = getStaticVersionNumber(bender.depVersions['svh-workspace-dlb']);
  const isLocal = currentInboxVersion === 'static';
  fetchVersionInterval = isLocal ? null : setInterval(() => {
    http.get(`${getFullUrl('app')}/inbox-version.json?date=${Date.now()}`).then(staticConf => {
      const inboxVersion = staticConf.build;
      const runtimeVersion = staticConf.deps['svh-workspace-dlb'];
      if (shouldPrecache(inboxVersion, currentInboxVersion, lastCachedInboxVersion)) {
        precacheAssets(inboxVersion);
        lastCachedInboxVersion = inboxVersion;
      }
      if (shouldPrecache(runtimeVersion, currentRuntimeVersion, lastCachedRuntimeVersion)) {
        precacheRuntimeAssets(runtimeVersion);
        lastCachedRuntimeVersion = runtimeVersion;
      }
    }).catch(() => {
      stopPoll();
      Raven.setExtraContext({
        'precaching-failed': true
      });
    });
  }, THIRTY_MINS);
};