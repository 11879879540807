// @ts-expect-error module not typed
import EmailAddressPattern from 'PatternValidationJS/patterns/EmailAddress';
import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchEmailDeliverabilityStatus } from '../clients/fetchEmailDeliverabilityStatus';
function fetchAllEmailDeliverabilityStatuses({
  emailAddresses
}) {
  const addressResponses = emailAddresses.map(emailAddress => {
    if (!EmailAddressPattern.test(emailAddress)) {
      return Promise.resolve({
        recipient: emailAddress,
        sendResult: 'MALFORMED_EMAIL'
      });
    }
    return fetchEmailDeliverabilityStatus({
      emailAddress
    });
  });
  return Promise.all(addressResponses).then(responses => {
    const flattenedStatuses = {};
    responses.forEach(({
      recipient,
      sendResult
    }) => {
      flattenedStatuses[recipient] = sendResult;
    });
    return flattenedStatuses;
  });
}
const query = registerQuery({
  fieldName: 'emailDeliverabilityStatuses',
  args: ['emailAddresses'],
  fetcher: fetchAllEmailDeliverabilityStatuses
});
export function useEmailDeliverabilityStatuses({
  emailAddresses,
  deferred
}) {
  const {
    data,
    loading,
    error
  } = useQuery(query, {
    skip: deferred,
    variables: {
      emailAddresses
    }
  });
  const deliverabilityStatuses = data ? data.emailDeliverabilityStatuses : {};
  return {
    deliverabilityStatuses,
    loading,
    error
  };
}