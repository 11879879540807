import pipe from 'transmute/pipe';
import { setMetadataFrom, setMetadataConnectedAccountAddress } from 'conversations-message-history/common-message-format/operators/emailMetadataSetters';
import { setAttachment } from 'conversations-message-history/common-message-format/operators/commonMessageSetters';

// @ts-expect-error module not typed

import { getEmailMetadataAttachment } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getEmailAddressRecordFromConnectedAccount } from '../../../common-reply-metadata/public/operators/getEmailAddressRecordFromConnectedAccount';
// @ts-expect-error module not typed
import { getInfoFromEmailAddressRecord } from '../../public/operators/getInfoFromEmailAddressRecord';
// @ts-expect-error module not typed

export const updateFromEmail = (nextConnectedAccount, commonMessage) => {
  const emailMetadataAttachment = getEmailMetadataAttachment(commonMessage);
  if (!emailMetadataAttachment) {
    return commonMessage;
  }
  const addressRecord = getEmailAddressRecordFromConnectedAccount(nextConnectedAccount);
  const fromAddress = getInfoFromEmailAddressRecord(addressRecord);
  const updatedAttachments = pipe(setMetadataFrom(fromAddress), setMetadataConnectedAccountAddress(addressRecord.address))(emailMetadataAttachment);
  return setAttachment(updatedAttachments, commonMessage);
};