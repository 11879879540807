import { getData } from 'conversations-async-data/async-data/operators/getters';
// @ts-expect-error module not typed
import { getEmailMessageId } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
// @ts-expect-error module not typed
import { isCommonMessageFormat } from 'conversations-message-history/common-message-format/operators/cmfComparators';
export const getInReplyToId = ({
  asyncCommonMessage
}) => {
  const inReplyToMessage = getData(asyncCommonMessage);
  const emailMessageId = inReplyToMessage && getEmailMessageId(inReplyToMessage);
  return inReplyToMessage && isCommonMessageFormat(inReplyToMessage) ? emailMessageId : null;
};