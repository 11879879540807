import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { fromJS } from 'immutable';
import { useSelector } from 'react-redux';
import { fetchThreadMetadata } from '../clients/fetchThreadMetadata';
import { useFetchWithDefaultData } from '../../common/hooks/useFetchWithDefaultData';
import { LIVE_CHAT_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { getThreadListMemberByThreadId } from '../../thread-list-member/selectors/getThreadListMemberByThreadId';
import { SUCCEEDED } from 'conversations-async-data/async-data/constants/asyncStatuses';
import { getGenericChannel } from 'find-and-filter-data/view-members-schema/protected';
export const useThreadOriginalGenericChannelId = ({
  threadId
}) => {
  // this is not always available if the focused thread is not in
  // the thread list, if it's not we want to useThreadMetadata
  const threadListMember = useSelector(state => getThreadListMemberByThreadId(state, {
    threadId
  }));
  const preloadedData = threadListMember && getGenericChannel(threadListMember) && new AsyncData({
    data: threadListMember,
    status: SUCCEEDED
  });
  const {
    asyncData
  } = useFetchWithDefaultData({
    requestFn: fetchThreadMetadata,
    toRecordFn: fromJS,
    requestArgs: {
      threadId
    },
    preloadedData: preloadedData || undefined
  });
  const threadMetadata = getData(asyncData);
  return {
    isLiveChat: getGenericChannel(threadMetadata) === LIVE_CHAT_GENERIC_CHANNEL_ID,
    originalGenericChannelId: getGenericChannel(threadMetadata)
  };
};