import I18n from 'I18n';
import formatShortDate from 'I18n/utils/formatShortDate';
export const isSegmentActive = segment => I18n.moment().isBetween(segment.startTimestamp, segment.endTimestamp, undefined, '[)' // inclusive of start, not end
);
export const getCurrentOOOSegment = outOfOfficeHours => {
  return outOfOfficeHours.find(isSegmentActive);
};
export const excludeCurrentOOOSegment = outOfOfficeHours => {
  return outOfOfficeHours.filter(segment => !isSegmentActive(segment));
};
export const endCurrentOOOSegment = outOfOfficeHours => {
  const currentSegment = getCurrentOOOSegment(outOfOfficeHours);
  if (currentSegment) {
    return [...excludeCurrentOOOSegment(outOfOfficeHours), {
      startTimestamp: currentSegment.startTimestamp,
      endTimestamp: Date.now()
    }];
  }
  return outOfOfficeHours;
};
export const getOutOfOfficeAtMentionLabel = (status = {
  isOutOfOffice: false,
  nextOutOfOfficeEventTimestamp: null
}) => {
  const {
    isOutOfOffice,
    nextOutOfOfficeEventTimestamp
  } = status;
  if (isOutOfOffice && nextOutOfOfficeEventTimestamp) {
    return I18n.text('routing-ui.agent-status.outOfOfficeAtMentionLabel', {
      returnDate: formatShortDate(nextOutOfOfficeEventTimestamp, 'userTz')
    });
  }
  return '';
};