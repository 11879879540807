import http from 'conversations-http/clients/http';
// @ts-expect-error module not typed
import { buildSenderObjectsFromIntegratorsResponse } from '../operators/buildSenderObjectsFromIntegratorsResponse';
import { getSenderId } from 'conversations-message-history/senders/operators/senderLocatorGetters';
export const getIntegratorSenders = ({
  senderLocators
}) => {
  const appId = getSenderId(senderLocators.first());
  return http.get('apps/v1/applications/app-ids/details/', {
    query: {
      appId
    }
  }).then(buildSenderObjectsFromIntegratorsResponse);
};