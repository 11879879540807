import { ADD_CALLABLE_ASSOCIATION, GET_CAPABILITIES, OPEN_WIDGET, PRELOAD, REMOVE_CALLABLE_ASSOCIATION, SET_CALLING_PROVIDER_SETTING } from '../constants/MethodNames';
import { getHasWindowTopAccess, getWindowLocation, getParentWindowLocationHref, getReferrer } from './windowUtils';
import IframeMessaging from './IframeMessaging';
import { enqueueHSCallingGlobalMethod, getApiMethodFromGlobal, getIsHsCallingGlobalReady } from './windowUtils';
import { logJSAPIPageAction } from './logPageAction';
const windowTopAccess = (resolve, method, options) => {
  if (!getIsHsCallingGlobalReady()) {
    enqueueHSCallingGlobalMethod(() => windowTopAccess(resolve, method, options));
  } else {
    const apiMethod = getApiMethodFromGlobal(method);
    if (typeof apiMethod !== 'function') {
      throw new Error(`calling-global-api does not contain the method "${method}"`);
    }
    resolve(apiMethod(options));
  }
};
const ALLOWED_IFRAME_METHODS = [OPEN_WIDGET, GET_CAPABILITIES, PRELOAD, SET_CALLING_PROVIDER_SETTING, ADD_CALLABLE_ASSOCIATION, REMOVE_CALLABLE_ASSOCIATION];
export const callingAPIMethodWithQueue = (method, options, flattenOptions) => {
  const data = options || {};
  return new Promise(resolve => {
    if (getHasWindowTopAccess()) {
      windowTopAccess(resolve, method, data);
      return;
    }

    // We need to close some of the methods from being used in an iframe until we can support them
    if (!ALLOWED_IFRAME_METHODS.includes(method)) {
      const location = getWindowLocation().href;
      const parentLocation = getParentWindowLocationHref();
      const referrer = getReferrer();
      logJSAPIPageAction({
        method,
        location,
        parentLocation,
        referrer
      });
      return;
    }
    const flattenedData = typeof flattenOptions === 'function' ? flattenOptions(data) : data;
    IframeMessaging.sendMessageToHost({
      method,
      options: flattenedData
    }, resolve);
  });
};