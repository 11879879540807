import get from 'transmute/get';
export const getAccentColor = inbox => get('accentColor', inbox);
export const getCreatedAt = inbox => get('createdAt', inbox);
export const getId = inbox => get('id', inbox);
export const getName = inbox => get('name', inbox);
export const getSendChatTranscriptsAutomatically = inbox => get('sendChatTranscriptsAutomatically', inbox);
export const getShowingHsBranding = inbox => get('showHsBranding', inbox);
export const getSlackChannelId = inbox => get('slackNotificationChannelId', inbox);
export const getTeamIds = inbox => get('teamIds', inbox);
export const getTranscriptSendFromConnectedAccountId = inbox => get('transcriptSendFromConnectedAccountId', inbox);
export const getUserIds = inbox => get('userIds', inbox);
export const getVisibility = inbox => get('visibility', inbox);
export const getWorkspaceFlag = inbox => get('workspaceFlag', inbox);
export const isInboxHelpdesk = inbox => {
  return getWorkspaceFlag(inbox) === 'HELPDESK';
};