// gimme: conversations-inbox-ui 06/05/2020 c649276db62b7e955c6e3a2ae36dc769071a71f9
'use es6';

import http from 'conversations-http/clients/http';
import { ALL, NOT_DELETED } from 'conversations-thread-data/thread-deletion/public/constants/DeletedFilterTypes';
export const fetchEmail = ({
  includeReplies = true,
  messageId,
  threadId,
  includeDeleted = false
}) => {
  const url = `conversations-threads/v1/message/${threadId}/${messageId}`;
  const deletedFilter = includeDeleted ? ALL : NOT_DELETED;
  return http.get(url, {
    query: {
      includeReplies,
      deletedFilter
    }
  });
};