import { ORIGINAL_FROM, FROM } from '../constants/senderFields';
import { getSenderField } from './senderGetters';
export const findIndividualSenderPojo = senders => {
  if (senders.length === 1) return senders[0];
  const senderFieldMap = {};
  senders.reduce((fieldMap, sender) => {
    const senderField = getSenderField(sender);
    if (senderField && !fieldMap[senderField]) {
      fieldMap[senderField] = sender;
    }
    return fieldMap;
  }, senderFieldMap);
  return senderFieldMap[FROM] || senderFieldMap[ORIGINAL_FROM] || senders[0];
};