import { useOrderObjectQuery } from './useObjectQuery';
import useProperties from './useProperties';
import useUserPermissions from './useUserPermissions';
function useOrderHighlightProperties(objectId) {
  const {
    data,
    loading,
    error
  } = useOrderObjectQuery({
    objectId
  });
  const properties = useProperties(data);
  const userPermissions = useUserPermissions(data);
  return {
    data: {
      properties,
      userPermissions
    },
    loading,
    error
  };
}
export default useOrderHighlightProperties;