export const INVOICE_PAYMENT_VALIDATION_ERRORS = {
  RECURRING_LINE_ITEMS_UNSUPPORTED: 'InvoicePaymentValidationError.RECURRING_LINE_ITEMS_UNSUPPORTED',
  FUTURE_START_DATE_LINE_ITEMS_UNSUPPORTED: 'InvoicePaymentValidationError.FUTURE_START_DATE_LINE_ITEMS_UNSUPPORTED',
  INVALID_LINE_ITEM_QUANTITY_FRACTIONAL: 'InvoicePaymentValidationError.INVALID_LINE_ITEM_QUANTITY_FRACTIONAL',
  UNSUPPORTED_CURRENCIES: 'InvoicePaymentValidationError.UNSUPPORTED_CURRENCIES',
  MULTIPLE_CURRENCIES_NOT_SUPPORTED: 'InvoicePaymentValidationError.MULTIPLE_CURRENCIES_NOT_SUPPORTED',
  INVALID_LINE_ITEM_QUANTITY_MAX: 'InvoicePaymentValidationError.INVALID_LINE_ITEM_QUANTITY_MAX'
};
export const INVOICE_ASSOCIATED_OBJECT_VALIDATION_ERRORS = {
  LINE_ITEM_SIZE_EXCEEDED: 'InvoiceAssociatedObjectValidationError.LINE_ITEM_SIZE_EXCEEDED',
  LINE_ITEM_CURRENCY_MISMATCH: 'InvoiceAssociatedObjectValidationError.LINE_ITEM_CURRENCY_MISMATCH',
  LINE_ITEM_REQUIRED_PROPERTY_MISSING: 'InvoiceAssociatedObjectValidationError.LINE_ITEM_REQUIRED_PROPERTY_MISSING'
};
export const EDIT_FINALIZED_INVOICE_ERRORS = {
  PORTAL_EDIT_SETTING_OFF: 'EditFinalizedInvoiceError.PORTAL_EDIT_SETTING_OFF',
  HAS_COMMERCE_PAYMENT_ASSOCIATIONS: 'EditFinalizedInvoiceError.HAS_COMMERCE_PAYMENT_ASSOCIATIONS',
  HAS_PENDING_PAYMENTS: 'EditFinalizedInvoiceError.HAS_PENDING_PAYMENTS',
  CANNOT_UPDATE_INVOICE_TO_STATUS: 'InvoiceValidationError.CANNOT_UPDATE_INVOICE_TO_STATUS'
};
export const COMMENTS_MAX_CHARACTER_LIMIT = 5000;
export const INVOICE_PROPERTY_VALIDATION_ERRORS = {
  INVALID_VALUE_FOR_PROPERTY_DEFINITION: 'InvoicePropertyValidationError.INVALID_VALUE_FOR_PROPERTY_DEFINITION'
};