import { default as ThreadListMember } from './thread-list-member/ThreadListMember';
const DEFAULT_VIEW_MEMBER = new ThreadListMember().toJS();

//TODO support generic view members.
/**
 * @description This function will return whether the given property is a property
 * on a ViewMember record.
 * @param maybeViewMemberProperty
 */
export function isViewMemberProperty(maybeViewMemberProperty) {
  return Object.prototype.hasOwnProperty.call(DEFAULT_VIEW_MEMBER, maybeViewMemberProperty);
}