import { restoreDraft } from './restoreDraft';
import { getData, getId, getVersion } from '../operators/draftGetters';
import { clearDraft } from './clearDraft';
import { hasDraftExpired } from '../operators/hasDraftExpired';
import { getDraftKeyMatches } from '../operators/getDraftKeyMatches';
const getMatches = localStorageKey => {
  try {
    return localStorage ? getDraftKeyMatches(localStorage, localStorageKey) : [];
  } catch (error) {
    return [];
  }
};
export function purgeDrafts({
  deserializeData,
  emptyChecker,
  localStorageKeyPrefix,
  currentVersion,
  ageLimitInMs
}) {
  const draftMatchesInLocalStorage = getMatches(localStorageKeyPrefix);
  draftMatchesInLocalStorage.forEach(draftMatch => {
    try {
      const [localStorageKey] = draftMatch;
      const draft = restoreDraft(localStorageKey, deserializeData);
      if (!draft) {
        clearDraft(localStorageKey);
        return;
      }
      const data = getData(draft);
      const savedId = getId(draft);
      const expired = hasDraftExpired(draft, ageLimitInMs);
      const deprecated = getVersion(draft) !== currentVersion;
      const isEmpty = data !== null ? emptyChecker(data) : true;
      if (!savedId || expired || deprecated || isEmpty) {
        clearDraft(localStorageKey);
      }
    } catch (e) {
      return;
    }
  });
}