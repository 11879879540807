import enviro from 'enviro';
import Raven from 'raven-js';
// @ts-expect-error module not typed
import * as eventLogger from 'customer-data-ui-utilities/eventLogging/eventLogger';
import { createClientError } from './clients/createClientError';
import { hasEngagementId } from './errorTypes';
function _logToConsole(errorMessage, extraData = {}, type = 'error') {
  /* eslint-disable no-console */
  if (enviro.debug('calling-widget-debug') && typeof console.group === 'function') {
    console.groupCollapsed(`%c Call widget ${type} %c`, `background-color:#${type === 'error' ? 'c51212' : '516f90'};color:white;padding:.15em .25em`, `color:#33475b`);
    console.log(`%c Message: %c${errorMessage}`, 'color:#99acc2', 'color:#33475b');
    console.log(`%c Extra Data: `, extraData, 'color:#99acc2', 'color:#33475b');
    console.error(extraData.error);
    console.groupEnd();
    /* eslint-enable no-console */
  }
}
export function logCallingError({
  errorMessage,
  error,
  extraData,
  tags
}) {
  _logToConsole(errorMessage || error.message, extraData);
  if (error || extraData && extraData.error && extraData.error instanceof Error) {
    const errorToReport = error || extraData.error;
    extraData.errorMessage = extraData.errorMessage || errorMessage;
    return eventLogger.logError({
      error: errorToReport,
      extraData,
      tags
    });
  }
  return eventLogger.logError({
    error: new Error(`[Calling]: ${errorMessage}`),
    extraData,
    tags
  });
}
const updateLogOptions = options => {
  options.tags = options.tags || {};
  options.tags['communicatorType'] = 'CALL';
};
function sendToClientError({
  engagementId,
  code,
  message
}) {
  return createClientError(engagementId, code, message, 'CRM');
}
export const logSentryInfo = options => {
  updateLogOptions(options);
  const {
    error,
    fingerprint,
    tags,
    extraData
  } = options;
  if (error) {
    _logToConsole(error.message, {
      error
    }, 'info');
    Raven.captureException(error, {
      fingerprint,
      tags,
      extra: extraData,
      level: 'info'
    });
  }
};
export const logError = options => {
  updateLogOptions(options);
  const {
    error,
    extraData
  } = options;

  // Send to Sentry + New Relic
  eventLogger.logError(options);
  const clientErrorPayload = {
    message: error.message
  };
  if (extraData) {
    const {
      code,
      engagementId
    } = extraData;

    // message: No transport available to send or receive messages (1.9.5 error introduced)
    if (code === 31009) {
      return;
    }
    if (engagementId) {
      Object.assign(clientErrorPayload, {
        code,
        engagementId
      });
    }
  }
  // Send to Twilio BE
  _logToConsole(error.message, {
    error
  });
  if (hasEngagementId(clientErrorPayload)) {
    sendToClientError(clientErrorPayload).catch(err => {
      // ignore errors if the user doesn't have calling permissions.
      if (err && err.responseJSON && err.responseJSON.message && err.responseJSON.message.includes('This app-cookie does not have proper permissions!')) {
        return;
      }
      logCallingError({
        errorMessage: 'Client Error reporting failed',
        extraData: {
          error: err
        }
      });
    });
  }
};
export const logPageAction = options => {
  updateLogOptions(options);
  // Send to Sentry and New Relic
  eventLogger.logPageAction(options);
};
const isNetworkError = networkError => networkError && networkError.xhr;
export const logNetworkError = ({
  error,
  networkError,
  extraData,
  tags
}) => {
  const info = {
    error,
    extraData: extraData || {},
    tags: tags || {}
  };
  if (isNetworkError(networkError)) {
    info.extraData.status = networkError.status;
    info.extraData.statusText = networkError.statusText;
    logSentryInfo(info);
  }
  return networkError;
};