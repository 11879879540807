import { debugLog } from './fullstory-debug';
import { deprioritize, throttle } from './utils';
const lastActiveStorageKey = 'DataOpsFullstory:lastActivityTimestamp';
function setActive() {
  try {
    localStorage.setItem(lastActiveStorageKey, Date.now().toString());
  } catch (error) {
    debugLog('Failed to set item in localStorage: ', error);
  }
}
const throttledSetActive = throttle(() => deprioritize(setActive, 1000), 4000);
function getLastActiveTimestamp() {
  try {
    const lastActive = localStorage.getItem(lastActiveStorageKey);
    return lastActive ? parseInt(lastActive, 10) : null;
  } catch (error) {
    debugLog('Failed to get item from localStorage: ', error);
    return null;
  }
}
export function getIsActiveInSessionTimeWindow() {
  try {
    const lastActiveTimestamp = getLastActiveTimestamp();
    if (!lastActiveTimestamp) {
      return false;
    }
    return Date.now() - lastActiveTimestamp < 30 * 60 * 1000;
  } catch (e) {
    return false;
  }
}
export function enableActivityTracker() {
  // Set the initial active timestamp
  throttledSetActive();
  document.addEventListener('mousemove', throttledSetActive);
  document.addEventListener('keydown', throttledSetActive);
  document.addEventListener('scroll', throttledSetActive);
}
export function disableActivityTracker() {
  document.removeEventListener('mousemove', throttledSetActive);
  document.removeEventListener('keydown', throttledSetActive);
  document.removeEventListener('scroll', throttledSetActive);
}