import { useQuery } from 'data-fetching-client';
import { fetchConnectedForms } from '../queries/cv-forms-integration-legacy.queries';
import { useMemo } from 'react';
export const useConnectedForm = id => {
  const {
    data,
    error,
    loading
  } = useQuery(fetchConnectedForms);
  return useMemo(() => ({
    connectedForm: data && data.connectedForms.find(form => form.id === id),
    error,
    loading
  }), [data, error, id, loading]);
};
export const useConnectedFormByChannelInstanceId = channelInstanceId => {
  const {
    data,
    error,
    loading
  } = useQuery(fetchConnectedForms);
  return useMemo(() => ({
    connectedForm: data && data.connectedForms.find(form => form.channelInstanceId === channelInstanceId),
    error,
    loading
  }), [channelInstanceId, data, error, loading]);
};