import { CLOSE_THREAD } from '../actions/ActionTypes';
const processCloseThread = (__, action) => {
  return {
    isSavingThread: action.meta === 'request',
    hasError: !!action.error
  };
};
const actionStates = (state = {
  isSavingThread: false,
  hasError: false
}, action) => {
  switch (action.type) {
    case CLOSE_THREAD:
      return processCloseThread(state, action);
    default:
      return state;
  }
};
export default actionStates;