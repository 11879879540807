import PortalIdParser from 'PortalIdParser';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import { uploadFile } from 'composer-data/file-uploads/public/clients';
import { getFullUrl } from 'hubspot-url-utils';

// https://git.hubteam.com/HubSpot/draft-plugins/blob/57077627b72ffd178249fe0f91f67f0123abd734/static/js/utils/convertDataURItoFile.js
function convertDataURItoFile(dataURI, fileName) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new File([ia], fileName, {
    type: mimeString,
    lastModified: Date.now()
  });
  return blob;
}
function getSignedSrc(fileManagerId) {
  const portalId = PortalIdParser.get();
  return `${getFullUrl('api')}/filemanager/api/v2/files/${fileManagerId}/signed-url-redirect?portalId=${portalId}`;
}
const base64Regex = /(data:image\/[^;]+;base64[^"]+)/g;
export const uploadBase64Images = ({
  fileManagerAccess,
  threadId,
  html
}) => {
  try {
    const element = document.createElement('div');
    element.innerHTML = html;
    const imgs = element.getElementsByTagName('img');
    const toAwait = [];
    const awaitIndexes = [];
    for (let i = 0; i < imgs.length; i++) {
      const urlValue = imgs[i].getAttribute('src');
      if (urlValue && urlValue.match(base64Regex)) {
        const file = convertDataURItoFile(urlValue, 'image');
        toAwait.push(uploadFile({
          access: fileManagerAccess,
          file,
          threadId
        }));
        awaitIndexes.push(i);
      }
    }
    return Promise.all(toAwait).then(results => {
      for (let i = 0; i < results.length; i++) {
        imgs[awaitIndexes[i]].setAttribute('src', getSignedSrc(results[i].id));
      }
      return element.innerHTML;
    }).catch(() => {
      return html;
    });
  } catch (error) {
    reportError({
      error: error
    });
    return Promise.resolve(html);
  }
};