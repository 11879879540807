import { AUDIO, IMG, MOVIE } from 'conversations-message-history/common-message-format/constants/fileAttachmentTypes';
export const downloadEventConfig = {
  [IMG]: 'download-image',
  [AUDIO]: 'download-audio',
  [MOVIE]: 'download-video'
};
export const downloadActionConfig = {
  [IMG]: 'download image',
  [AUDIO]: 'download audio',
  [MOVIE]: 'download video'
};