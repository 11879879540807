import { List } from 'immutable';
import { createSelector } from 'reselect';
import { getSortOrder } from '../../thread-list-member-sidebar/selectors/getSortOrder';
import { sortViewMembers } from 'find-and-filter-data/view-members-data/public';
export const selectOrderByTypeFromProps = (state, {
  viewOrderBy
}) => viewOrderBy;
export const selectViewMembersMapFromProps = (state, {
  viewMembers
}) => viewMembers;

/**
 * @description If `selectSortedViewMembers` is being used, viewOrderBy must be
 * provided as a prop. If this selector is composed in other selectors, ensure
 * that `selectOrderByTypeFromProps` is also included so that TS will correctly
 * ensure you must provide viewOrderBy as a prop in that composed selector as well.
 */
export const selectSortedViewMembers = createSelector([selectViewMembersMapFromProps, getSortOrder, selectOrderByTypeFromProps], (membersMap, sortOrder, viewOrderBy) => {
  const membersList = membersMap ? membersMap.toList() : List();
  return sortViewMembers(membersList, viewOrderBy, sortOrder);
});