import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { GET_TICKET_SOURCES } from '../constants/asyncActionTypes';
const initialState = new AsyncData({
  data: null
});
export default handleActions({
  [GET_TICKET_SOURCES.STARTED]: state => {
    return requestStarted(state);
  },
  [GET_TICKET_SOURCES.FAILED]: state => {
    return requestFailed(state);
  },
  [GET_TICKET_SOURCES.SUCCEEDED]: (state, action) => {
    const {
      data
    } = action.payload;
    return requestSucceededWithOperator(() => data, state);
  }
}, initialState);