import http from 'conversations-http/clients/http';
export const checkUserHasViewMemberPermissions = ({
  objectIds,
  objectTypeId,
  viewId
}) => {
  return http.post('conversations-custom-views/v1/view-members/object-ids', {
    data: {
      objectIds,
      objectTypeId,
      customViewId: viewId
    }
  });
};