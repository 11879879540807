'use es6';

import { gql } from '@apollo/client';
export const UserPermissionsFields = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"UserPermissionsFields\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"CrmObject\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"userPermissions\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"currentUserCanView\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"currentUserCanEdit\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"currentUserCanCommunicate\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"currentUserCanDelete\"}}]}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "UserPermissionsFields"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "CrmObject"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "userPermissions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "currentUserCanView"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "currentUserCanEdit"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "currentUserCanCommunicate"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "currentUserCanDelete"
            }
          }]
        }
      }]
    }
  }]
});