import { CONNECTED, DISCONNECTED, FAILED, SUSPENDED, CONNECTING } from '../constants/connectionStates';
import { SUCCESS, FAILURE } from '../../metrics/constants/asyncDataPointResults';
export function setupConnectionReporting(connection, reporter) {
  let startTime = null;
  let initialConnection = true;
  connection.on(({
    current
  }) => {
    switch (current) {
      case CONNECTING:
        {
          startTime = Date.now();
          break;
        }
      case CONNECTED:
        {
          if (startTime) {
            const endTime = Date.now();
            reporter.captureEvent(initialConnection ? 'connect' : 'reconnect', {
              startTime,
              endTime,
              duration: endTime - startTime,
              result: SUCCESS
            });
          }
          initialConnection = false;
          startTime = null;
          break;
        }
      case DISCONNECTED:
        {
          if (startTime) {
            const endTime = Date.now();
            reporter.captureEvent(initialConnection ? 'connect' : 'reconnect', {
              startTime,
              endTime,
              duration: endTime - startTime,
              result: FAILURE
            });
          } else {
            reporter.captureEvent('disconnected');
          }
          startTime = null;
          initialConnection = false;
          break;
        }
      case FAILED:
        {
          reporter.captureEvent('connection-failed');
          startTime = null;
          initialConnection = false;
          break;
        }
      case SUSPENDED:
        {
          reporter.captureEvent('connection-suspended');
          startTime = null;
          initialConnection = false;
          break;
        }
      default:
        startTime = null;
        initialConnection = false;
        break;
    }
  });
}