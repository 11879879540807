import { createAction } from '@reduxjs/toolkit';
/**
 * @description This action should be dispatched when a InboxUpdate realtime message
 * is received.
 */
export const inboxUpdated = createAction('realtime/InboxUpdate', ({
  message,
  currentAgentId
}) => {
  return {
    payload: {
      currentAgentId,
      message
    }
  };
});