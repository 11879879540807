import pipe from 'transmute/pipe';
import getIn from 'transmute/getIn';
import setIn from 'transmute/setIn';
import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestOutOfSync } from 'conversations-async-data/async-data/operators/requestOutOfSync';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { DISASSOCIATE_CONTACT, FETCH_CONTACT } from '../constants/asyncActionTypes';
import { refreshVisitor } from 'find-and-filter-data/visitors/public';
import { NAVIGATE_TO_THREAD } from 'conversations-inbox-lib/pubsub/public/constants';
import { requestFailedWithError } from 'conversations-async-data/async-data/operators/requestFailedWithError';
import { updateAsyncData } from 'conversations-async-data/async-data/operators/updateAsyncData';
import { OPTIMISTIC_CONTACT_ASSOCIATION } from '../constants/ActionTypes';
import { FIRST_NAME, LAST_NAME, KNOWN_CONTACT } from 'conversations-inbox-lib/contacts/public/constants';
const initialState = new IndexedAsyncData({
  name: 'contacts',
  notSetValue: new AsyncData({
    data: null
  })
});
export default handleActions({
  [FETCH_CONTACT.STARTED]: (state, action) => {
    const {
      threadId
    } = action.payload.requestArgs;
    return updateEntry(threadId, requestStarted, state);
  },
  [FETCH_CONTACT.SUCCEEDED](state, action) {
    const {
      data,
      requestArgs
    } = action.payload;
    const {
      threadId
    } = requestArgs;
    return updateEntry(threadId, requestSucceededWithOperator(() => data), state);
  },
  [FETCH_CONTACT.FAILED](state, action) {
    const {
      threadId
    } = action.payload.requestArgs;
    const errorMessage = getIn(['payload', 'error', 'responseJSON', 'category'], action);
    const error = errorMessage ? new Error(errorMessage) : null;
    if (error) {
      return updateEntry(threadId, requestFailedWithError(error), state);
    }
    return updateEntry(threadId, requestFailed, state);
  },
  [NAVIGATE_TO_THREAD](state, action) {
    const {
      threadId
    } = action.payload;
    return updateEntry(threadId, requestOutOfSync, state);
  },
  [refreshVisitor.pending.type](state, action) {
    try {
      return updateEntry(action.meta.arg.threadId, requestOutOfSync, state);
    } catch (e) {
      return state;
    }
  },
  [OPTIMISTIC_CONTACT_ASSOCIATION](state, action) {
    const {
      threadId,
      firstName,
      lastName
    } = action.payload;
    return updateEntry(threadId, updateAsyncData(data => {
      return pipe(setIn(['properties', FIRST_NAME, 'value'], firstName), setIn(['properties', LAST_NAME, 'value'], lastName), setIn([KNOWN_CONTACT], true))(data);
    }), state);
  },
  [DISASSOCIATE_CONTACT.SUCCEEDED](state, action) {
    try {
      const {
        threadId
      } = action.payload.data;
      return updateEntry(threadId, requestOutOfSync, state);
    } catch (e) {
      return state;
    }
  }
}, initialState);