import I18n from 'I18n';
import formatPhoneNumber from 'I18n/utils/formatPhoneNumber';
import { GENERIC_SENDER_NAME_FIELD, RAW_PHONE_NUMBER } from '../constants/friendlyNameResolutionSources';

/**
 * Returns a formatted friendly name based on the resolution source.
 * If the name is from an integrator, it prefixes the name with "Maybe: ".
 * If the name is from a phone number, it formats it as an i18n format phone number.
 */
export const getFormattedFriendlyName = ({
  resolvedFriendlyName,
  resolutionSource
}) => {
  if (!resolvedFriendlyName) return '';
  if (!resolutionSource) return resolvedFriendlyName;
  if (resolutionSource === GENERIC_SENDER_NAME_FIELD) {
    return I18n.text('conversations-message-history.friendlyNames.maybeName', {
      name: resolvedFriendlyName
    });
  }
  if (resolutionSource === RAW_PHONE_NUMBER) {
    return formatPhoneNumber(resolvedFriendlyName, '');
  }
  return resolvedFriendlyName;
};