// @ts-expect-error dependency missing types
import EmailAddressPattern from 'PatternValidationJS/patterns/EmailAddress';
export const createNewEmailOption = (email, name = '') => {
  const isEmailValid = EmailAddressPattern.test(email.trim());
  const text = name && email ? `${name} (${email})` : name || email;
  return {
    name,
    address: email,
    text,
    value: email,
    validationData: {
      isEmailValid
    },
    tagUse: !isEmailValid ? 'danger' : 'default'
  };
};