import enviro from 'enviro';
import { createTracker } from 'usage-tracker';
import events from '../../events/events.yaml';
import once from 'transmute/once';
const QUOTES_NAMESPACE = 'quotes-admin';
const trackerArgs = {
  properties: {
    namespace: QUOTES_NAMESPACE
  },
  onError(err) {
    console.error(err);
  },
  lastKnownEventProperties: ['screen', 'subscreen'],
  debug: () => enviro.debug('quotes-ui-lib')
};
export const getTracker = once(() => createTracker(Object.assign({}, trackerArgs, {
  events
})));
export const QuotesLogger = {
  log: (eventName, eventProps = {}) => {
    getTracker().track(eventName, eventProps);
  }
};
export default QuotesLogger;