import { List as ImmutableList } from 'immutable';
import get from 'transmute/get';
import { FILES } from 'conversations-message-history/common-message-format/constants/attachmentTypes';
function isFileAttachment(attachment) {
  return get('@type', attachment) === FILES;
}
export function mergeCommonMessageAttachments(sourceAttachments, newerAttachments) {
  const sourceFileAttachment = sourceAttachments && sourceAttachments.find(isFileAttachment);
  if (!sourceFileAttachment) {
    return newerAttachments;
  }
  return newerAttachments ? newerAttachments.filterNot(isFileAttachment).toList().push(sourceFileAttachment) : ImmutableList([sourceFileAttachment]);
}