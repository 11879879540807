import { createAction } from '@reduxjs/toolkit';
/**
 * @description This action should be dispatched when a ViewDelete realtime message
 * is received.
 */
export const viewMemberDeleted = createAction('realtime/ViewDelete', ({
  currentAgentId,
  removedMembers,
  viewKey
}) => {
  return {
    payload: {
      currentAgentId,
      removedMembers,
      viewKey
    }
  };
});