import { useEffect, useState } from 'react';
import { getHasBillingRead } from '../operators/getHasBillingRead';
export const useShowFooter = user => {
  const [showFooter, setShowFooter] = useState(false);
  const hasBillingRead = getHasBillingRead(user);
  useEffect(() => {
    if (hasBillingRead) setShowFooter(hasBillingRead);
  }, [hasBillingRead]);
  const dismissFooter = () => setShowFooter(false);
  return {
    showFooter,
    dismissFooter
  };
};