import { useCartObjectQuery } from './useObjectQuery';
import useProperties from './useProperties';
import useUserPermissions from './useUserPermissions';
function useCartHighlightProperties(objectId) {
  const {
    data,
    loading,
    error
  } = useCartObjectQuery({
    objectId
  });
  const properties = useProperties(data);
  const userPermissions = useUserPermissions(data);
  return {
    data: {
      properties,
      userPermissions
    },
    loading,
    error
  };
}
export default useCartHighlightProperties;