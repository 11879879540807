const _unique = definitions => {
  const names = {};
  return definitions.filter(definition => {
    if (definition.kind !== 'FragmentDefinition') {
      return true;
    }
    const name = definition.name.value;
    if (names[name]) {
      return false;
    } else {
      names[name] = true;
      return true;
    }
  });
};
import { gql } from '@apollo/client';
import { PipelineKeyFields, PipelineStageKeyFields } from 'hubspot-apollo-client/fragments';
export const ticketPipelinesQuery = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"fetchTicketPipelines\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"allPipelines\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"objectType\"},\"value\":{\"kind\":\"StringValue\",\"value\":\"0-5\",\"block\":false}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"PipelineKeyFields\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"displayOrder\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"permission\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"accessLevel\"}}]}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"pipelineId\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"label\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"stages\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"PipelineStageKeyFields\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"label\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"metadata\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"propertyValueRequirements\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"isValueRequired\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"propertyName\"}}]}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"stageId\"}}]}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: _unique([{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "fetchTicketPipelines"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "allPipelines"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "objectType"
          },
          value: {
            kind: "StringValue",
            value: "0-5",
            block: false
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "PipelineKeyFields"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "displayOrder"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "permission"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "accessLevel"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "pipelineId"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "label"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "stages"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "PipelineStageKeyFields"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "label"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "metadata"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "propertyValueRequirements"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "isValueRequired"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "propertyName"
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "stageId"
                }
              }]
            }
          }]
        }
      }]
    }
  }].concat(PipelineKeyFields.definitions, PipelineStageKeyFields.definitions))
});