import { fetchThreadListMemberDetails } from 'conversations-thread-data/thread-details/public/actions';
import { ConversationHandoffErrorContext } from '../context/ConversationHandoffErrorContext';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
export const useHandleFormSubmit = ({
  onConfirm,
  onReject,
  threadId
}) => {
  const dispatch = useDispatch();
  const {
    updateThreadErrors
  } = useContext(ConversationHandoffErrorContext);
  const getThreadListMemberDetails = () => {
    dispatch(fetchThreadListMemberDetails({
      threadId
    }));
  };
  const handleFormSubmitCompleted = () => {
    getThreadListMemberDetails();
    onConfirm();
  };
  const handleFormSubmitError = error => {
    getThreadListMemberDetails();
    updateThreadErrors(threadId, error);
    onReject();
  };
  return {
    handleFormSubmitCompleted,
    handleFormSubmitError
  };
};