export * from './_internal/removal/threadRemovalGetters';
//TODO ideally these won't be exposed publicly in the future.
export {
/**
 * @deprecated - use find-and-filter-data/view-member-optimistic-updates/public directly
 */
threadsUpdated,
/**
 * @deprecated - use find-and-filter-data/view-member-optimistic-updates/public directly
 */
viewMemberPatched } from '../view-member-optimistic-updates/public';