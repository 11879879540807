export const WIDGET_MESSAGE_VERSION = 1;
export const BASE_TITLE_BAR_HEIGHT = 48;
export const ACTIVE_TITLE_BAR_HEIGHT = 64;
export const THIRD_PARTY_SUBHEADER_HEIGHT = 32;
export const TWILIO_WIDGET_OPTIONS = {
  height: 385,
  width: 450
};
export const THIRD_PARTY_PROVIDER = {
  height: 319,
  width: 450
};