import Raven from 'raven-js';
function setOnlineStatus() {
  Raven.setExtraContext({
    'user-online': window.navigator.onLine
  });
}
function setVisibility() {
  Raven.setExtraContext({
    visibility: document.visibilityState
  });
}
function trackCacheStatus(name, timing) {
  const fetchStart = timing.fetchStart;
  const responseEnd = timing.responseEnd;
  Raven.setExtraContext({
    [`warm-${name}`]: responseEnd - fetchStart < 100
  });
}
function trackRuntime() {
  Raven.setExtraContext({
    'using-runtime': true
  });
  window.performance.getEntries().forEach(t => {
    if (t instanceof PerformanceResourceTiming) {
      if (t.initiatorType === 'script' && t.name.indexOf('bundles/runtime.production.js') > -1) {
        trackCacheStatus('runtime', t);
      } else if (t.name.indexOf('js/inbox.js') > -1) {
        trackCacheStatus('inbox', t);
      }
    }
  });
}
export function setupListeners() {
  setVisibility();
  setOnlineStatus();
  trackRuntime();
  document.addEventListener('visibilitychange', setVisibility);
  window.addEventListener('offline', setOnlineStatus);
  window.addEventListener('online', setOnlineStatus);
}