import { CLOSED, CLOSING, CONNECTED, CONNECTING, DISCONNECTED, FAILED, INITIALIZED, SUSPENDED } from '../constants/connectionStates';
import { waitForConnection } from './waitForConnection';
export function ensureConnection(connection) {
  switch (connection.state) {
    case INITIALIZED:
    case CLOSED:
    case FAILED:
      {
        connection.connect();
        return waitForConnection(connection);
      }
    case CONNECTED:
      {
        return Promise.resolve();
      }
    case CONNECTING:
    case DISCONNECTED:
    case SUSPENDED:
      {
        return waitForConnection(connection);
      }
    case CLOSING:
      {
        return Promise.reject(new Error('PubSub connection closing'));
      }
    default:
      {
        return Promise.reject(new Error('Unknown PubSub status'));
      }
  }
}