import xor from 'hs-lodash/xor';
import union from 'hs-lodash/union';
import { useCallback, useEffect, useMemo, useState } from 'react';
import http from 'conversations-http/clients/http';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
function fetchCustomViewSettings(workspaceId) {
  return http.get('conversations-custom-views/v1/settings/users', {
    query: {
      workspaceId
    }
  });
}
function postCustomViewSettings(req) {
  return http.post('conversations-custom-views/v1/settings/users', {
    data: req
  });
}
const mapToStrings = arr => arr ? arr.map(String) : [];
const mapToNumbers = arr => arr ? arr.map(Number) : [];
const defaultSettings = {
  starredViews: [],
  viewGroupOrder: {},
  viewGroupOrderV2: {},
  viewQuickFilters: []
};
function sortViews(starredViews, order) {
  const stars = new Set(starredViews);
  const starredOrder = [];
  const otherOrder = [];
  if (!order) {
    return [];
  }
  for (const id of order) {
    const section = stars.has(id) ? starredOrder : otherOrder;
    section.push(id);
  }

  // Sort starred views before others
  return [...starredOrder, ...otherOrder];
}
export default function useCustomViewUserSettings(workspaceId) {
  const [loading, updateLoading] = useState(true);
  const [settings, _updateSettings] = useState(defaultSettings);
  const {
    starredViews,
    viewGroupOrder,
    viewQuickFilters
  } = settings;

  // Convert starredViews to a Set for faster lookup
  const stars = useMemo(() => new Set(mapToStrings(starredViews)), [starredViews]);

  // Map number ids to strings for compatibility with UI
  const order = useMemo(() => ({
    PRIVATE: mapToStrings(sortViews(starredViews, viewGroupOrder.PRIVATE)),
    PUBLIC: mapToStrings(sortViews(starredViews, viewGroupOrder.PUBLIC)),
    TEAM: mapToStrings(sortViews(starredViews, viewGroupOrder.TEAM))
  }), [starredViews, viewGroupOrder]);
  useEffect(() => {
    fetchCustomViewSettings(workspaceId).then(response => {
      if (response.settings) {
        _updateSettings(response.settings);
      }
    }).catch(error => reportError({
      error
    })).finally(() => updateLoading(false));
  }, [workspaceId]);
  const updateSettings = useCallback(updatedSettings => {
    postCustomViewSettings({
      settings: updatedSettings,
      workspaceId
    }).catch(error => {
      reportError({
        error
      });
    });
  }, [workspaceId]);
  const memoizedUpdateOrder = useCallback((viewGroup, updatedOrder) => {
    _updateSettings(currentSettings => {
      const updatedSettings = Object.assign({}, currentSettings, {
        viewGroupOrder: Object.assign({}, currentSettings.viewGroupOrder, {
          [viewGroup]: sortViews(currentSettings.starredViews, mapToNumbers(updatedOrder))
        }),
        viewGroupOrderV2: currentSettings.viewGroupOrderV2
      });
      updateSettings(updatedSettings);
      return updatedSettings;
    });
  }, [updateSettings]);
  const memoizedUpdateViewFilters = useCallback((viewFilterId, filters) => {
    _updateSettings(currentSettings => {
      const updatedViewFilters = currentSettings.viewQuickFilters && currentSettings.viewQuickFilters.filter(({
        customViewId
      }) => Number(viewFilterId) !== customViewId);
      updatedViewFilters.push({
        customViewId: Number(viewFilterId),
        quickFilters: filters
      });
      const updatedSettings = {
        viewGroupOrder: currentSettings.viewGroupOrder,
        viewGroupOrderV2: currentSettings.viewGroupOrderV2,
        starredViews: currentSettings.starredViews,
        viewQuickFilters: updatedViewFilters
      };
      updateSettings(updatedSettings);
      return updatedSettings;
    });
  }, [updateSettings]);
  const memoizedToggleStar = useCallback((viewId, viewGroup) => {
    _updateSettings(currentSettings => {
      const numViewId = Number(viewId);
      const updatedStarredViews = xor(currentSettings.starredViews, [numViewId]);
      const currentOrder = union(currentSettings.viewGroupOrder[viewGroup], [numViewId]);
      const updatedSettings = {
        viewGroupOrder: Object.assign({}, currentSettings.viewGroupOrder, {
          [viewGroup]: sortViews(updatedStarredViews, currentOrder)
        }),
        viewGroupOrderV2: currentSettings.viewGroupOrderV2,
        starredViews: updatedStarredViews,
        viewQuickFilters: currentSettings.viewQuickFilters
      };
      updateSettings(updatedSettings);
      return updatedSettings;
    });
  }, [updateSettings]);
  return {
    loading,
    order,
    stars,
    toggleStar: memoizedToggleStar,
    updateOrder: memoizedUpdateOrder,
    viewQuickFilters,
    updateViewFilters: memoizedUpdateViewFilters
  };
}