import I18n from 'I18n';
export function getPlaceholderText(placeholder, hasAiAssistance) {
  switch (placeholder) {
    case 'Write a comment and @mention teammates. This is only visible to your team.':
      return I18n.text('composer-ui.prosemirror.placeholders.comment');
    default:
      if (hasAiAssistance) {
        return I18n.text('composer-ui.prosemirror.placeholders.defaultAI');
      }
      return I18n.text('composer-ui.prosemirror.placeholders.default');
  }
}