import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchDashboards } from '../api/dashboard';
import { CREATE_DASHBOARD_LINK, GROSS_PAYMENT_DASHBOARD_LINK } from '../utils/constants';
export const GET_DASHBOARD_INFO = registerQuery({
  fieldName: 'dashboardInfo',
  fetcher: fetchDashboards
});
export function useGetGrossPaymentLink() {
  var _data$dashboardInfo, _data$dashboardInfo$d, _data$dashboardInfo2;
  const {
    data,
    loading
  } = useQuery(GET_DASHBOARD_INFO);
  if (loading || !data || (data === null || data === void 0 || (_data$dashboardInfo = data.dashboardInfo) === null || _data$dashboardInfo === void 0 || (_data$dashboardInfo = _data$dashboardInfo.dashboards) === null || _data$dashboardInfo === void 0 ? void 0 : _data$dashboardInfo.length) === 0) {
    return {
      loading,
      link: CREATE_DASHBOARD_LINK
    };
  }
  const dashboards = ((_data$dashboardInfo$d = data === null || data === void 0 || (_data$dashboardInfo2 = data.dashboardInfo) === null || _data$dashboardInfo2 === void 0 ? void 0 : _data$dashboardInfo2.dashboards) !== null && _data$dashboardInfo$d !== void 0 ? _data$dashboardInfo$d : []).filter(a => a.template === 'gross-payment-revenue' && a.active);
  if (dashboards.length === 0) {
    return {
      loading,
      link: CREATE_DASHBOARD_LINK
    };
  }
  const dashboard = dashboards.sort((a, b) => {
    var _b$updatedAt, _a$updatedAt;
    return ((_b$updatedAt = b.updatedAt) !== null && _b$updatedAt !== void 0 ? _b$updatedAt : 0) - ((_a$updatedAt = a.updatedAt) !== null && _a$updatedAt !== void 0 ? _a$updatedAt : 0);
  })[0];
  return {
    loading,
    link: `${GROSS_PAYMENT_DASHBOARD_LINK}${dashboard.id}`
  };
}