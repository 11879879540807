import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearFilterResults } from './filterResultsSlice';
import { actions } from './filterValuesSlice';
import { selectFilterValues } from './selectFilterValues';
import { selectHasSearchSet } from './selectHasSearchSet';
export const useFilterValues = () => {
  const filterValues = useSelector(selectFilterValues);
  const hasSearchTerm = useSelector(selectHasSearchSet);
  const dispatch = useDispatch();
  const setFilterValue = useCallback((filterKey, filterValue) => {
    dispatch(actions.setFilterValue({
      filterKey,
      filterValue
    }));
  }, [dispatch]);
  const setFilterValues = useCallback(newValues => {
    dispatch(actions.setFilterValues(newValues));
  }, [dispatch]);
  const clearFilterValues = useCallback(() => {
    dispatch(actions.clearFilterValues());
    if (!hasSearchTerm) {
      dispatch(clearFilterResults());
    }
  }, [dispatch, hasSearchTerm]);
  const clearSearchText = useCallback(() => {
    dispatch(actions.clearSearchValue());
  }, [dispatch]);
  const clearAllFilterValuesAndSearch = useCallback(() => {
    dispatch(actions.clearSearchAndFilterValues());
  }, [dispatch]);
  return {
    filterValues,
    setFilterValue,
    setFilterValues,
    clearFilterValues,
    clearSearchText,
    clearAllFilterValuesAndSearch
  };
};