import enviro from 'enviro';
import PortalIdParser from 'PortalIdParser';
import Raven from 'raven-js';
export const getCurrentPortalChannel = () => {
  const portalId = PortalIdParser.get();
  const env = enviro.isProd() ? 'prod' : 'qa';
  return `${env}.messages.portal-update-${portalId}`;
};
export const safelyParseJson = jsonString => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    Raven.captureException(error);
    return {};
  }
};