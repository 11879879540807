// @ts-expect-error module not typed
import { getFileAttachments } from 'conversations-message-history/common-message-format/operators/getFileAttachments';
import FileAttachment from 'conversations-message-history/common-message-format/records/FileAttachment';
import { getFileIds } from 'conversations-message-history/common-message-format/operators/fileAttachmentGetters';
// @ts-expect-error module not typed
import { setFileIds } from 'conversations-message-history/common-message-format/operators/fileAttachmentSetters';
import { setAttachment } from 'conversations-message-history/common-message-format/operators/commonMessageSetters';
export const updateFileIdsInMessage = (message, updater) => {
  const fileAttachments = getFileAttachments(message) || FileAttachment();
  const fileIds = getFileIds(fileAttachments);
  const nextFileIds = updater(fileIds);
  const nextFileIdsUnique = nextFileIds.toSet().toList();
  const nextFileAttachments = setFileIds(nextFileIdsUnique, fileAttachments);
  return setAttachment(nextFileAttachments, message);
};