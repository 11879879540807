import { Record } from 'immutable';
export const CallMessageResult = Record({
  '@ok': '',
  '@result': null
}, 'CallMessageResult');
export const CallMessageResultWithErrorDetails = Record({
  '@ok': '',
  '@result': null,
  category: null,
  subCategory: null
}, 'CallMessageResult');
export default class CallMessage extends Record({
  '@result': '',
  activityType: CallMessageResult(),
  callBody: CallMessageResultWithErrorDetails(),
  callDisposition: CallMessageResult(),
  callDuration: CallMessageResult(),
  callSummary: CallMessageResult(),
  callSummaryExecutionId: CallMessageResult(),
  crmObjectId: null,
  hasRecording: CallMessageResult(),
  hasTranscript: CallMessageResultWithErrorDetails(),
  hasVoicemail: CallMessageResult(),
  isRecordingDeleted: CallMessageResult(),
  recordingUrl: CallMessageResultWithErrorDetails(),
  transcriptReady: CallMessageResult(),
  suggestedNextActions: CallMessageResult()
}, 'CallMessage') {
  constructor(properties = {}) {
    const activityType = CallMessageResult(properties.activityType);
    const callBody = CallMessageResultWithErrorDetails(properties.callBody);
    const callDuration = CallMessageResult(properties.callDuration);
    const callDisposition = CallMessageResult(properties.callDisposition);
    const callSummary = CallMessageResult(properties.callSummary);
    const callSummaryExecutionId = CallMessageResult(properties.callSummaryExecutionId);
    const hasRecording = CallMessageResult(properties.hasRecording);
    const hasTranscript = CallMessageResultWithErrorDetails(properties.hasTranscript);
    const suggestedNextActions = CallMessageResult(properties.suggestedNextActions);
    const hasVoicemail = CallMessageResult(properties.hasVoicemail);
    const isRecordingDeleted = CallMessageResult(properties.isRecordingDeleted);
    const recordingUrl = CallMessageResultWithErrorDetails(properties.recordingUrl);
    const transcriptReady = CallMessageResult(properties.transcriptReady);
    super(Object.assign({}, properties, {
      activityType,
      callBody,
      callDisposition,
      callDuration,
      callSummary,
      callSummaryExecutionId,
      hasRecording,
      hasTranscript,
      hasVoicemail,
      isRecordingDeleted,
      recordingUrl,
      transcriptReady,
      suggestedNextActions
    }));
  }
}