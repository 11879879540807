export const noContent = ({
  hasContent
}) => {
  if (!hasContent) {
    return {
      disabled: true,
      sendDisabledReason: 'NO_CONTENT'
    };
  }
  return null;
};