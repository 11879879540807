/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { fetchConnectedAccountsForInbox } from '../../connected-accounts/actions/fetchConnectedAccountsForInbox';
import { fetchViews } from 'find-and-filter-data/views-data/public';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { getInboxId } from 'conversations-internal-schema/inbox/operators/getInboxId';
import { trackEvent } from '../../usage-tracking/clients/usageTracker';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { getInboxName } from 'conversations-internal-schema/inbox/operators/getInboxName';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { getChangedInbox } from 'conversations-inbox-lib/usage-tracking/inboxInteractionEvents';
import { getCurrentInboxId } from 'conversations-inbox-lib/unified-inbox/public/selectors';
import { setCurrentInbox } from 'conversations-inbox-lib/unified-inbox/public/actions';
export const selectInbox = inbox => (dispatch, getState) => {
  const inboxId = getInboxId(inbox);
  const currentInboxId = getCurrentInboxId(getState());
  if (inboxId === currentInboxId) return;
  trackEvent(getChangedInbox({
    inboxName: getInboxName(inbox)
  }));
  dispatch(setCurrentInbox(inbox));
  dispatch(fetchConnectedAccountsForInbox({
    inboxId
  }));
  dispatch(fetchViews({
    inboxId
  }));
};
export default selectInbox;