/**
 * File copied from
 * https://git.hubteam.com/HubSpot/conversations-inbox/blob/7bb3b952ce74938908dd090946a68f527fd0328d/conversations-inbox-ui/static/js/agentStatus/operators/statusModalOperators.ts
 */

import { getUpdatedAt } from './getters';
const MODAL_DISMISSED_SESSION_STORAGE_KEY = 'AGENT_STATUS_MODAL_DISMISSED';
export const setDismissAgentStatusModalSessionStorage = () => {
  try {
    window.sessionStorage.setItem(MODAL_DISMISSED_SESSION_STORAGE_KEY, Date.now().toString());
  } catch (e) {
    // do nothing
  }
};
export const getWasModalDismissedThisSession = agentStatus => {
  try {
    if (window.sessionStorage) {
      const value = window.sessionStorage.getItem(MODAL_DISMISSED_SESSION_STORAGE_KEY) || '';
      const parsedVal = Number.parseInt(value, 10);
      const validatedParsedVal = isNaN(parsedVal) ? 0 : parsedVal;
      const statusLastUpdated = getUpdatedAt(agentStatus) || 0;
      return statusLastUpdated < validatedParsedVal;
    }
    return false;
  } catch (e) {
    return false;
  }
};