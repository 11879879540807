import { useQuery } from 'data-fetching-client';
import { CONTENT_ASSISTANCE_SETTINGS_QUERY } from '../../_internal/queries/fetchContentAssistanceSettings';
export const useContentAssistanceSettings = () => {
  const {
    loading,
    error,
    data
  } = useQuery(CONTENT_ASSISTANCE_SETTINGS_QUERY);
  const settings = data && data.contentAssistanceSettings || {
    contentAssistanceEnabled: false,
    customerAnalysisEnabled: false
  };
  return Object.assign({
    loading,
    error,
    data
  }, settings);
};