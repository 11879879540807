// gimme: conversations-inbox-ui 06/10/2020 24a2e66917cc934433dd111db7e06fd02943ca6a
'use es6';

import http from 'conversations-http/clients/http';
import { NOT_DELETED } from 'conversations-message-history/common-message-format/constants/messageDeleteStatus';
export const restoreMessage = ({
  messageId,
  threadId
}) => {
  const url = `conversations-threads/v1/message/${threadId}/${messageId}`;
  return http.patch(url, {
    data: {
      messageDeletedStatus: NOT_DELETED
    }
  });
};