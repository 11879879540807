import Filters from '../models/Filters';
import { APPLY_FILTERS, CLEAR_FILTERS } from '../actions/ActionTypes';
export default function filters(state = new Filters(), action) {
  switch (action.type) {
    case APPLY_FILTERS:
      return action.payload;
    case CLEAR_FILTERS:
      return new Filters({
        includeChatAndPhoneTickets: state.includeChatAndPhoneTickets,
        includeOpenTickets: state.includeOpenTickets
      });
    default:
      return state;
  }
}