'use es6';

import { useCallback, useContext } from 'react';
import { useCardLocationContext } from 'customer-data-sidebar/universal/context/CardLocationContext';
import { QueryContext } from 'customer-data-sidebar/universal/context/QueryContext';
import { del as sharedAPIDelete } from 'crm_data/inboundDB/sharedAPI';
import addMutationResultAlert from '../alertHelpers/addMutationResultAlert';
import { useDeleteCrmObjectFromCache } from './useDeleteCrmObjectFromCache';

/**
 * Create callback to delete an object via the inbounddb-objects API.
 * Updates the GraphQL cache manually to reflect the delete.
 *
 * @example
 *
 * function CrmObjectActionsDropdown({ objectId, objectType }) {
 *
 *   ...
 *
 *   const handleDeleteCrmObject = useDeleteCrmObject({
 *    fromObjectType,
 *    fromObjectId,
 *    toObjectType: objectTypeId,
 *    toObjectId: objectId,
 *    onSuccess: handleDeleteSuccess,
 *    onError: handleDeleteError,
 *  });
 *
 *   ...
 *
 *   return (
 *     ...
 *      <UIButton onClick={handleDeleteCrmObject}>
 *         Delete
 *      </UIButton>
 *     ...
 *   )
 * }
 *
 * @param {Object}                                        options
 * @param {ObjectType}                                    options.fromObjectType      the type of object to disassociate from, usually the subject in a record page
 * @param {Number}                                        options.fromObjectId        the ID of the object to disassociate from, usually the subject in a record page
 * @param {ObjectType}                                    options.toObjectType        the type of the object to be disassociated from the "fromObject", i.e. the type of the object in a sidebar chicklet
 * @param {Number}                                        options.toObjectId          the ID of the object to be disassociated from the "fromObject", i.e. the ID of the object in a sidebar chicklet
 * @param {Function}                                      options.onSuccess           callback for when the association request is queued on the server
 * @param {Function}                                      options.onError             callback for when the association request fails
 *
 * @return {Function} handleDeleteCrmObject
 */
export const useDeleteCrmObject = ({
  fromObjectId,
  fromObjectType,
  toObjectId,
  toObjectType,
  onSuccess,
  onError
}) => {
  const {
    client
  } = useContext(QueryContext);
  const {
    parentCardLocation
  } = useCardLocationContext();
  const BASE_URI = 'inbounddb-objects/v1/crm-objects';
  const handleUpdateCache = useDeleteCrmObjectFromCache({
    cardLocation: parentCardLocation,
    fromObjectType,
    fromObjectId,
    toObjectId: +toObjectId,
    toObjectType
  });
  const handleDeleteCrmObject = useCallback(() => {
    sharedAPIDelete(`${BASE_URI}/${encodeURIComponent(toObjectType)}/${+toObjectId}`).then(() => {
      addMutationResultAlert({
        titleKey: 'universalSidebar.crmObjectActionsDropdown.successfullyDeleted'
      });
      handleUpdateCache(client);
      if (typeof onSuccess === 'function') onSuccess();
    }).catch(error => {
      addMutationResultAlert({
        titleKey: 'universalSidebar.crmObjectActionsDropdown.errorDeleting',
        mutationFailed: true
      });
      if (typeof onError === 'function') onError();
      throw error;
    });
  }, [toObjectType, toObjectId, handleUpdateCache, onSuccess, onError, client]);
  return handleDeleteCrmObject;
};