import http from 'conversations-http/clients/http';
import { getInboxChannelsTableUrl } from 'conversations-external-deep-links/urls';
import invariant from '../../lib/invariant';
export function sendDisconnectedEmail({
  disconnectedAccountEmail,
  recipientUserId,
  inboxId
}) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 8 arguments, but got 2.
  invariant(!isNaN(parseInt(inboxId, 10)), `InboxId must be a Number, got ${inboxId}`);
  return http.post(`conversations-email/v1/connected-accounts/send-disconnected-email`, {
    data: {
      disconnectedAccountEmail,
      recipientUserId,
      buttonLink: `${getInboxChannelsTableUrl({
        inboxId
      })}`
    }
  });
}