'use es6';

import getIn from 'transmute/getIn';
import { ALL_EMAILS, AVATAR, CANONICAL_VID, CONTACT, EMAIL, FIRST_NAME, LAST_NAME, VID } from '../constants/keyPaths';
import formatName from 'I18n/utils/formatName';
export const getAllEmails = getIn(ALL_EMAILS);
export const getFirstName = getIn(FIRST_NAME);
export const getLastName = getIn(LAST_NAME);
export const getEmail = getIn(EMAIL);
export const getAvatar = getIn(AVATAR);
export const getIsContact = getIn(CONTACT);
export const getCanonicalVid = getIn(CANONICAL_VID);
export const getVid = getIn(VID);
export const getFullName = visitor => {
  return formatName({
    firstName: getFirstName(visitor),
    lastName: getLastName(visitor)
  });
};