async function formatClipboardItem(clipboardItem) {
  const typeToBlob = {};
  await Promise.all(clipboardItem.types.map(type => clipboardItem.getType(type).then(blob => {
    var _blob$type;
    typeToBlob[type] = {
      size: blob.size
    };
    return (_blob$type = blob.type) !== null && _blob$type !== void 0 && _blob$type.startsWith('text') ? blob.slice(0, 16384).text() : undefined;
  }).then(text => {
    typeToBlob[type].textPreview = text;
  }).catch(e => {
    typeToBlob[type] || (typeToBlob[type] = {});
    typeToBlob[type].error = e.message;
  })));
  return typeToBlob;
}
export async function readClipboardData(navigator = window.navigator) {
  if (!(navigator !== null && navigator !== void 0 && navigator.clipboard)) {
    throw new Error('Clipboard API not available');
  }

  // eslint-disable-next-line compat/compat
  const clipboardItems = await navigator.clipboard.read();
  const resolvedClipboardItems = await Promise.all(clipboardItems.map(formatClipboardItem));
  return resolvedClipboardItems;
}