'use es6';

import getIn from 'transmute/getIn';
import { fetchCvCrmObject } from '../clients/fetchCvCrmObject';
const getThreadIdFromCrmObject = getIn(['properties', 'hs_thread_id', 'value']);

/**
 *
 * @param {object} params
 * @param {string} params.cvObjectId
 *
 * @returns {Promise} a promise that resolves with the corresponding threadId
 */
export const threadIdFromCVObjectId = ({
  cvObjectId
}) => {
  return fetchCvCrmObject({
    cvObjectId
  }).then(cvObject => {
    const threadId = getThreadIdFromCrmObject(cvObject);
    return threadId;
  });
};