import { buildError } from '../../error-reporting/builders/buildError';

/*
 * @description Request history from Ably
 *
 * @param options Options to pass to Ably's history method See: https://www.ably.io/documentation/realtime/history
 */
export function fetchHistory(channel, options = {}) {
  return new Promise((resolve, reject) => {
    channel.history(options, (historyError, resultPage) => {
      if (historyError) {
        const error = buildError(`PubSub history error.  Reason: ${historyError.message}`, historyError);
        reject(error);
      } else {
        resolve(resultPage ? resultPage.items : []);
      }
    });
  });
}