import { createAction } from 'redux-actions';
import { CLICKED_TO_FORWARD } from '../constants/actionTypes';
export const clickedToForward = createAction(CLICKED_TO_FORWARD, ({
  message,
  replyAll,
  isReply
} = {}) => ({
  message,
  replyAll,
  isReply
}));