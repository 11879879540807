import { buildThreadListMember } from 'find-and-filter-data/view-members-schema/protected';
import get from 'transmute/get';
import toJS from 'transmute/toJS';
import { applyPartialToViewMember } from './applyPartialToViewMember';

/**
 * @description Given a Map of indexed thread list members and
 * a map of indexed partial members, merge into existing
 * members using mergeIndexedViewMembers or build new members if
 * they don't exist in the source map.
 * @argument indexedViewMembers
 * @argument indexedPartialMembers
 */
export function mergeIndexedViewMembers(indexedViewMembers, indexedPartialMembers) {
  return indexedPartialMembers.reduce((indexedMembers, partialMember, threadId) => {
    const existingMember = get(threadId, indexedMembers);
    const nextMember = existingMember ? applyPartialToViewMember(existingMember, partialMember) : buildThreadListMember(toJS(partialMember));
    return indexedMembers.set(threadId, nextMember);
  }, indexedViewMembers);
}