import { HS_EMAIL_ADDRESS } from 'conversations-message-history/common-message-format/constants/deliveryIdentifierTypes';
import { getDeliveryIdentifierType, getDeliveryIdentifierValue } from 'conversations-message-history/common-message-format/operators/deliveryIdentifierGetters';
import { getSingleDeliveryIdentifier } from 'conversations-message-history/common-message-format/operators/recipientGetters';
import { getRecipientsFromCommonMessage } from '../../../common-reply/public/operators/commonReplyGetters';
import { hasContactPermissions } from './hasContactPermissions';
export const hasRecipientsWithoutPermission = (reply, contactPermissions) => {
  const recipients = getRecipientsFromCommonMessage(reply);
  if (!recipients || !contactPermissions) {
    return false;
  }
  return recipients.some(recipient => {
    const deliveryIdentifier = getSingleDeliveryIdentifier(recipient);
    if (getDeliveryIdentifierType(deliveryIdentifier) === HS_EMAIL_ADDRESS) {
      const emailAddress = getDeliveryIdentifierValue(deliveryIdentifier);
      return !hasContactPermissions(emailAddress, contactPermissions);
    }
    return false;
  });
};