import { CLEAR_SEARCH_TERMS, ENTER_SEARCH_TERMS } from './ActionTypes';
export function clearSearchTerms() {
  return dispatch => {
    dispatch({
      type: CLEAR_SEARCH_TERMS
    });
  };
}
export function enterSearchTerms(searchTerms) {
  return dispatch => {
    dispatch({
      type: ENTER_SEARCH_TERMS,
      payload: searchTerms
    });
  };
}