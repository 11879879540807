import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { threadsUpdated, selectViewMemberPatches, viewMemberPatched } from './viewMembersUpdatesSlice';
export const useRealtimeViewMemberUpdates = () => {
  const viewMemberUpdates = useSelector(selectViewMemberPatches);
  const dispatch = useDispatch();

  /**
   * @description
   * Optimistically updates view members.
   */
  const patch = useCallback(({
    viewMemberPatch
  }) => {
    return dispatch(viewMemberPatched(viewMemberPatch));
  }, [dispatch]);

  /**
   * @description
   * Handles ThreadsUpdated pubsub messages and merges the updates into
   * the cached view members.
   */
  const update = ({
    message
  }) => {
    dispatch(threadsUpdated(message));
  };
  return {
    patch,
    update,
    viewMemberUpdates
  };
};