import { useEffect } from 'react';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { fetchChannelConfiguration } from '../clients/fetchChannelConfiguration';
import { useLocalAsyncFetcher } from 'conversations-async-data/local-async-fetcher/public';
import { buildChannelConfigurationMap } from '../operators/buildChannelConfigurationMap';
import { getData } from 'conversations-async-data/async-data/operators/getters';
let localAsyncData = new AsyncData();
export const useChannelConfigurations = () => {
  const {
    asyncData,
    retryFailed
  } = useLocalAsyncFetcher({
    initialAsyncData: localAsyncData,
    fetcher: fetchChannelConfiguration,
    toRecordFn: buildChannelConfigurationMap
  });
  useEffect(() => {
    if (localAsyncData && getData(localAsyncData)) return;
    localAsyncData = asyncData;
  }, [asyncData]);
  return {
    asyncChannelConfigurations: asyncData,
    retryFailed
  };
};