import isBoolean from 'hs-lodash/isBoolean';
// TODO: move to composer-data
import invariant from 'conversations-thread-data/utils/invariant';
export const whatsAppMessagingTrusted = ({
  isMessagingTrusted
}) => {
  invariant(isBoolean(isMessagingTrusted), 'hasReachedWhatsAppLimit is required for whatsAppReplyLimit disabling strategy');
  return isMessagingTrusted ? null : {
    disabled: true,
    sendDisabledReason: 'MESSAGING_NOT_TRUSTED'
  };
};