/**
 * File copied from settings-ui-user-record https://git.hubteam.com/HubSpot/settings-ui-user-record/blob/ad0964c871c56027f6155caa82281bd0cf42ee55/settings-ui-user-record/static/js/records/CrmObject.ts
 */

export const getParsedJsonPropertyValue = property => {
  if (!property) return undefined;
  const propValue = property.value;
  try {
    return JSON.parse(propValue);
  } catch (_unused) {
    return undefined;
  }
};