import { handleActions } from 'redux-actions';
import { ENTER_SEARCH_AND_FILTER_MODE } from '../actions/enterSearchAndFilterMode';
import { EXIT_SEARCH_AND_FILTER_MODE } from '../actions/exitSearchAndFilterMode';
const initialState = false;
const isInSearchAndFilterMode = handleActions({
  [ENTER_SEARCH_AND_FILTER_MODE]() {
    return true;
  },
  [EXIT_SEARCH_AND_FILTER_MODE]() {
    return initialState;
  }
}, initialState);
export default isInSearchAndFilterMode;