import { Map as ImmutableMap } from 'immutable';
import { HUMAN } from 'conversations-inbox-lib/agent/public/constants';
const getAgentInfo = agent => {
  let agentType;
  let userId;
  if (typeof agent === 'string') {
    [userId, agentType = HUMAN] = agent.split('-');
    userId = parseInt(userId, 10);
  } else if (ImmutableMap.isMap(agent)) {
    userId = agent.get('userId');
    agentType = agent.get('agentType') || HUMAN;
  }
  return {
    userId,
    agentType
  };
};
export const formatAgentRequestData = agents => {
  const data = agents.reduce((arr, agent) => {
    const {
      userId,
      agentType
    } = getAgentInfo(agent);
    if (userId && agentType) {
      if (!arr[agentType]) {
        arr[agentType] = {
          agentIds: [],
          agentType
        };
      }
      arr[agentType].agentIds.push(userId);
    }
    return arr;
  }, {});
  return Object.keys(data).map(key => data[key]);
};