import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectSortOrder } from './selectSortOrder';
import { actions } from './sortOrder';
export const useSortOrder = () => {
  const _sortOrder = useSelector(selectSortOrder);
  const dispatch = useDispatch();
  const setSortOrder = useCallback(sortOrder => {
    dispatch(actions.setSortOrder({
      sortOrder
    }));
  }, [dispatch]);
  const clearSortOrder = useCallback(() => {
    dispatch(actions.clearSortOrder());
  }, [dispatch]);
  return {
    sortOrder: _sortOrder,
    setSortOrder,
    clearSortOrder
  };
};