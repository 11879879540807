import { buildAudit } from 'conversations-message-history/audit/operators/buildAudit';
import { AssignmentRemoval, FilterChangeRemoval, HardDeletionRemoval, InboxChangeRemoval, MultipleRemoval, ThreadStatusRemoval, TicketAssociationRemoval, UnknownRemoval } from 'find-and-filter-data/realtime-view-member-schema/public';
import { buildPartialThreadListMembers } from '../../../view-members-schema/protected';
import { buildThreadRemovals } from './buildThreadRemovals';
export const buildThreadRemoval = attributes => {
  const {
    audit,
    members,
    ['@type']: type
  } = attributes;
  switch (type) {
    case 'ASSIGNMENT':
      {
        const {
          assignee
        } = attributes;
        return AssignmentRemoval({
          ['@type']: type,
          assignee,
          audit: audit ? buildAudit(audit) : null,
          members: buildPartialThreadListMembers(members)
        });
      }
    case 'FILTER_CHANGE':
      {
        const {
          filtered
        } = attributes;
        return FilterChangeRemoval({
          ['@type']: type,
          audit: audit ? buildAudit(audit) : null,
          filtered,
          members: buildPartialThreadListMembers(members)
        });
      }
    case 'THREAD_STATUS':
      {
        const {
          status
        } = attributes;
        return ThreadStatusRemoval({
          ['@type']: type,
          audit: audit ? buildAudit(audit) : null,
          members: buildPartialThreadListMembers(members),
          status
        });
      }
    case 'TICKET_ASSOCIATION':
      {
        return TicketAssociationRemoval({
          ['@type']: type,
          audit: audit ? buildAudit(audit) : null,
          members: buildPartialThreadListMembers(members)
        });
      }
    case 'HARD_DELETION':
      {
        return HardDeletionRemoval({
          ['@type']: type,
          audit: audit ? buildAudit(audit) : null,
          members: buildPartialThreadListMembers(members)
        });
      }
    case 'INBOX_CHANGE':
      {
        const {
          inboxId
        } = attributes;
        return InboxChangeRemoval({
          ['@type']: type,
          audit: audit ? buildAudit(audit) : null,
          members: buildPartialThreadListMembers(members),
          inboxId
        });
      }
    case 'MULTIPLE':
      {
        const {
          changes
        } = attributes;
        return MultipleRemoval({
          ['@type']: type,
          audit: audit ? buildAudit(audit) : null,
          changes: buildThreadRemovals(changes),
          members: buildPartialThreadListMembers(members)
        });
      }
    case 'UNKNOWN':
    default:
      {
        return UnknownRemoval({
          audit: audit ? buildAudit(audit) : null,
          members: buildPartialThreadListMembers(members)
        });
      }
  }
};