import http from 'conversations-http/clients/http';
export const fetchResponder = agentId => {
  return http.get(`messages/v2/message/agents/${agentId}`, {
    query: {
      accessType: 'INTERNAL',
      agentType: 'HUMAN'
    }
  });
};
export const fetchWorkspaceResponder = (agentId, workspaceId) => http.get(`cv/inbox/settings/v1/assignee-search/${agentId}`, {
  query: {
    agentType: 'HUMAN',
    workspaceId
  }
});