const EIGHT_HOURS_IN_MS = 1000 * 60 * 60 * 8;
export const getIsDueInLessThanEightHours = (dueDate, currentTime) => {
  const timeLeftUntilDueDate = dueDate - currentTime.valueOf();
  return timeLeftUntilDueDate <= EIGHT_HOURS_IN_MS;
};
export const getIsDueTomorrow = (currentTime, dueDate) => {
  const tomorrow = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate() + 1);
  return dueDate.toDateString() === tomorrow.toDateString();
};
export const dueDateBreached = (currentTime, dueDate) => dueDate - currentTime.valueOf() < 0;
export const isDueToday = (currentTime, dueDate) => currentTime.toDateString() === dueDate.toDateString();