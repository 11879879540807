import { handleActions } from 'redux-actions';
import { SET_CURRENT_INBOX } from 'conversations-inbox-lib/unified-inbox/public/constants';
import { SET_CURRENT_THREAD_LIST } from 'conversations-inbox-lib/views/public/constants';
import { getDefaultThreadListFromViews } from 'conversations-inbox-lib/views/public/operators';
import { getId } from 'find-and-filter-data/views-schema/public';
import { getCurrentViewId } from 'conversations-inbox-lib/omnibus/public/operators';
import { FETCH_FROM_OMNI_BUS as omnibusActionTypes, POLL_OMNIBUS } from 'conversations-inbox-lib/omnibus/public/constants';
import { fetchViews } from 'find-and-filter-data/views-data/public';
const initialState = null;
export const currentViewId = handleActions({
  [SET_CURRENT_INBOX]() {
    return null;
  },
  [omnibusActionTypes.SUCCEEDED](state, action) {
    return getCurrentViewId(action.payload.data);
  },
  [POLL_OMNIBUS.SUCCEEDED](state, action) {
    const alreadyInitialized = state !== initialState;
    if (alreadyInitialized) return state;
    return getCurrentViewId(action.payload.data);
  },
  [SET_CURRENT_THREAD_LIST](state, action) {
    return getId(action.payload.threadList);
  },
  [fetchViews.fulfilled.type](state, action) {
    const views = action.payload.views;
    const currentViewExists = state !== null && views[state];
    if (currentViewExists) return state;

    /**
     * We only store one inbox's "last" view per portal, so when we change
     * inboxes in a portal there is no "last" view to render so just render
     * the initial view
     */
    return getId(getDefaultThreadListFromViews(views));
  }
}, initialState);