import { useQuery, registerQuery } from 'data-fetching-client';
import { fetchThreadListMemberDetails } from '../clients/fetchThreadListMemberDetails';
const FETCH_THREAD_LIST_MEMBER_DETAILS = 'fetchThreadListMemberDetails';
const FETCH_THREAD_LIST_MEMBER_DETAILS_QUERY = registerQuery({
  fieldName: FETCH_THREAD_LIST_MEMBER_DETAILS,
  args: ['threadId'],
  fetcher: fetchThreadListMemberDetails
});
export function useThreadListMemberDetails({
  threadId
}) {
  const {
    data,
    loading,
    error
  } = useQuery(FETCH_THREAD_LIST_MEMBER_DETAILS_QUERY, {
    variables: {
      threadId
    }
  });
  return {
    data: data && data[FETCH_THREAD_LIST_MEMBER_DETAILS],
    loading,
    error
  };
}