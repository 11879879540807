export const ASSIGNED_TO_BOT = 'assignedToBot';
export const ASSIGNEE = 'assignedAgentId';
export const CHANNEL_INSTANCE_ID = 'channelInstanceId';
export const HIDE_FILTERED = 'hideFiltered';
export const END_TIME = 'endTimestamp';
export const GENERIC_CHANNEL = 'genericChannel';
export const START_TIME = 'startTimestamp';
export const STATUS = 'threadStatus';
export const UNASSIGNED = 'unassigned';
export const DATE_TYPE = 'dateType';
export const VID = 'vid';
export const SEARCH = 'searchQuery';
export const HIDE_TRASH = 'hideTrash';