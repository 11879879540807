import { Record } from 'immutable';
const Ticket = Record({
  body: '',
  channel: null,
  createdAt: 0,
  goal: null,
  productArea: null,
  resolution: null,
  roadblock: null,
  title: null,
  url: ''
}, 'Ticket');
Ticket.fromJS = obj => new Ticket(Object.assign({}, obj));
export default Ticket;