import curry from 'transmute/curry';
import AsyncData from '../../async-data/AsyncData';
import { getData } from '../../async-data/operators/getters';
import { indexedDataInvariant } from '../invariants/indexedDataInvariant';
import { operatorInvariant } from '../invariants/operatorInvariant';
import { applyIdInvariant } from './applyIdInvariant';
import { applyIdTransform } from './applyIdTransform';
import { getEntries } from './getters';
import { updateEntry } from './updateEntry';
/**
 * Updates an entry in IndexedAsyncData only if that entry already exists and has data. If the entry does not
 * exist or has no data, the original IndexedAsyncData is returned.
 *
 * Entries that are not an AsyncData instance will not count as an existing entry.
 *
 * @param {Any} id an id that passes the id invariant
 * @param {Function} operator operator to apply to data receives `asyncData` as argument
 * @param {IndexedAsyncData} indexedData IndexedAsyncData to update the entry in
 * @returns {IndexedAsyncData}
 */
export const updateExistingEntry = curry((id, operator, indexedData) => {
  indexedDataInvariant(indexedData);
  applyIdInvariant(id, indexedData);
  operatorInvariant(operator);
  const key = applyIdTransform(id, indexedData);
  const existingEntry = getEntries(indexedData).get(key);
  if (!(existingEntry instanceof AsyncData) || getData(existingEntry) === null) {
    return indexedData;
  }
  return updateEntry(id, operator, indexedData);
});