import get from 'transmute/get';
import getIn from 'transmute/getIn';
export const hasMoreAssignees = response => Boolean(getIn(['crmObjectsSearch', 'results', 0, 'possibleAssignees', 'pageInfo', 'hasNextPage'], response));
export const parseAssigneesFromGqlResponse = response => {
  const edges = getIn(['crmObjectsSearch', 'results', 0, 'possibleAssignees', 'edges'], response);
  if (!edges) {
    return null;
  }
  return edges.map(edge => get('node', edge));
};
export const parseCurrentAssigneeFromGqlResponse = response => getIn(['crmObject', 'currentAssignee'], response);
export const parseUpdatedCurrentAssigneeFromGqlResponse = response => getIn(['assignConversationAssignee', 'updatedAssignee'], response);