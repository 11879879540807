import { getTimeToCloseSlaAt, getTimeToCloseSlaStatus, getTimeToFirstResponseSlaAt, getTimeToFirstResponseSlaStatus, getTimeToNextResponseSlaAt, getTimeToNextResponseSlaStatus } from '../../operators/ticketGetters';
import { FIRST_RESPONSE_SLA, CLOSE_TICKET_SLA, NEXT_RESPONSE_SLA } from '../constants/SLAProperties';
export const ticketHasTimeToCloseSLA = ticket => Boolean(getTimeToCloseSlaAt(ticket) && getTimeToCloseSlaStatus(ticket));
export const ticketHasTimeToFirstResponseSLA = ticket => Boolean(getTimeToFirstResponseSlaAt(ticket) && getTimeToFirstResponseSlaStatus(ticket));
export const ticketHasTimeToNextResponseSLA = ticket => Boolean(getTimeToNextResponseSlaAt(ticket) && getTimeToNextResponseSlaStatus(ticket));
const createFirstResponseSLAFromTicket = ticket => ({
  category: FIRST_RESPONSE_SLA,
  status: getTimeToFirstResponseSlaStatus(ticket),
  dueDate: Number.parseInt(getTimeToFirstResponseSlaAt(ticket), 10),
  priority: 1
});
const createNextResponseSLAFromTicket = ticket => ({
  category: NEXT_RESPONSE_SLA,
  status: getTimeToNextResponseSlaStatus(ticket),
  dueDate: Number.parseInt(getTimeToNextResponseSlaAt(ticket), 10),
  priority: 2
});
const createTimeToCloseSLAFromTicket = ticket => ({
  category: CLOSE_TICKET_SLA,
  status: getTimeToCloseSlaStatus(ticket),
  dueDate: Number.parseInt(getTimeToCloseSlaAt(ticket), 10),
  priority: 3
});
export const getSLAsFromTicket = ticket => {
  const returnedSLAs = {};
  if (ticketHasTimeToFirstResponseSLA(ticket)) {
    returnedSLAs[FIRST_RESPONSE_SLA] = createFirstResponseSLAFromTicket(ticket);
  }
  if (ticketHasTimeToNextResponseSLA(ticket)) {
    returnedSLAs[NEXT_RESPONSE_SLA] = createNextResponseSLAFromTicket(ticket);
  }
  if (ticketHasTimeToCloseSLA(ticket)) {
    returnedSLAs[CLOSE_TICKET_SLA] = createTimeToCloseSLAFromTicket(ticket);
  }
  return returnedSLAs;
};