'use es6';

import invariant from 'react-utils/invariant';
import setIn from 'transmute/setIn';
import { ACTOR_ID } from '../constants/keyPaths';
import { getActorFromSenderType } from './senderActorMapping';
import * as legacySenderTypes from '../constants/legacySenderTypes';
const legacySenderTypesArray = Object.keys(legacySenderTypes);
export const setActor = ({
  senderId,
  senderType,
  sender
}) => {
  invariant(legacySenderTypesArray.includes(senderType), `Invalid sender type. Should be one of ${legacySenderTypesArray.join(' | ')}. Received %s`, senderType);
  const actorType = getActorFromSenderType(senderType);
  const actorId = `${actorType}-${senderId}`;
  return setIn(ACTOR_ID, actorId, sender);
};