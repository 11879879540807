// copied from: https://git.hubteam.com/HubSpot/ui-addon-conversations-settings/blob/3c89aee1836bb1efd278f39f1bb316d4f77c6c81/static/js/users-and-teams/records/Team.ts
import { fromJS, Iterable as ImmutableIterable, List as ImmutableList } from 'immutable';
import { TeamBase } from '../../team/records/TeamBase';
import TeamUser from './TeamUser';

// This type allows the values passed in when creating a `RoutingTeam` to have childTeams
// that are `Immutable.List` or `Array` of `RoutingTeam` or a `RoutingTeamProps` object
const checkIsImmutable = options => ImmutableIterable.isIterable(options) && ImmutableIterable.isKeyed(options);
const updateUsers = values => {
  if (!values) {
    return undefined;
  }
  return values.update('users', ImmutableList(), users => users.map(user => new TeamUser(user)));
};
const RoutingTeamBase = TeamBase({
  numRoutableUsersInHierarchy: null,
  users: ImmutableList()
}, 'RoutingTeam');
export class RoutingTeam extends RoutingTeamBase {
  constructor(values) {
    let finalValues;
    if (checkIsImmutable(values)) {
      finalValues = values;
    } else {
      finalValues = fromJS(values);
    }
    super(updateUsers(finalValues));
  }
}
// TODO: remove the following when all dependencies are updated to use the new `RoutingTeam` type
const Team = RoutingTeam;
export default Team;