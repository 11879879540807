import { createAction } from 'redux-actions';
import { START_HEARTBEAT } from '../constants/actionTypes';
import BackoffOnFailureSingleton from '../strategies/BackoffOnFailureSingleton';
import { sendHeartbeat } from './sendHeartbeat';
import { hasConversationsInteract } from '../../auth/selectors/hasConversationsInteract';
const startHeartbeatAction = createAction(START_HEARTBEAT);
export const startHeartbeat = () => (dispatch, getState) => {
  const hasInteract = hasConversationsInteract(getState());
  // Only send heartbeat if user has inbox access. Prevents 403 from expired JITA
  if (!hasInteract) {
    return;
  }

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
  const heartbeatDispatch = sendHeartbeat(dispatch, getState);
  const heartbeat = BackoffOnFailureSingleton(heartbeatDispatch);
  dispatch(startHeartbeatAction());
  heartbeat.start();
};