'use es6';

import { Map as ImmutableMap } from 'immutable';
import reduce from 'transmute/reduce';
import { buildRecipientLocator } from 'conversations-message-history/legacy-recipients/operators/buildRecipientLocator';
import Recipient from 'conversations-message-history/legacy-recipients/records/LegacyRecipient';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { updateData } from 'conversations-async-data/async-data/operators/setters';
import { VISITOR } from 'conversations-message-history/common-message-format/constants/legacySenderTypes';
export const buildRecipientsFromVisitorsResponse = (visitorsResponse = ImmutableMap()) => {
  return reduce(ImmutableMap(), (accumulator, asyncVisitor, visitorKey) => {
    const recipientLocator = buildRecipientLocator(VISITOR, visitorKey);
    if (!getData(asyncVisitor)) {
      const updatedVisitor = updateData(() => Recipient(), asyncVisitor);
      return accumulator.set(recipientLocator, updatedVisitor);
    }
    const updatedVisitor = updateData(visitor => {
      const {
        avatar,
        vid,
        email,
        firstName,
        lastName
      } = visitor ? visitor.toJS() : {};
      return Recipient({
        avatar,
        email,
        firstName,
        id: vid,
        lastName,
        recipientType: VISITOR
      });
    }, asyncVisitor);
    return accumulator.set(recipientLocator, updatedVisitor);
  }, visitorsResponse);
};