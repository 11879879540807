import I18n from 'I18n';
import unescapedText from 'I18n/utils/unescapedText';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { isFormCMF } from 'conversations-message-history/common-message-format/operators/cmfComparators';
import { getSubject } from 'conversations-message-history/common-message-format/operators/emailMetadataGetters';
import { getTicketId, getSubject as getTicketSubject } from 'svh-tickets-lib/tickets/public/operators';
import { getFriendlyNameFromFallbackAccount } from './getFriendlyNameFromFallbackAccount';
export const getEmailReplySubject = (props = {}) => {
  const {
    message,
    associatedTicket,
    channelInstances,
    connectedAccounts,
    userFullName
  } = props;
  if (message) {
    const replySubject = getSubject(message) || '';
    const prefix = I18n.text('composer-data.email-metadata.subject-prefixes.reply', {
      subject: replySubject
    });
    const lowerCaseReplySubject = replySubject.toLowerCase();
    const lowerCasePrefix = prefix.toLowerCase();
    return isFormCMF(message) || lowerCaseReplySubject.indexOf(lowerCasePrefix) === 0 ? replySubject : `${prefix}${replySubject}`;
  }
  if (associatedTicket) {
    const ticketId = associatedTicket && getTicketId(associatedTicket);
    const ticketSubject = associatedTicket && getTicketSubject(associatedTicket);
    return ticketSubject && ticketId ? `${ticketSubject} (#${ticketId})` : '';
  }
  if (connectedAccounts && channelInstances) {
    const friendlyFromName = getFriendlyNameFromFallbackAccount({
      channelInstances,
      connectedAccounts
    });
    const agentName = friendlyFromName && friendlyFromName.length > 0 ? friendlyFromName : userFullName;
    return unescapedText('composer-data.email-metadata.subject-prefixes.conversation-with-agent', {
      agentName
    });
  }
  return null;
};