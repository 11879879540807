import { initializePubSubStarted, initializePubSubSucceeded, initializePubSubFailed, pubSubReady, pubSubReconnected, pubSubDisconnected, pubSubReconnecting, pubSubSuspended } from './asyncPubSubClientActions';
import { buildConversationsPubSubFromVendor } from '../../conversations-pub-sub/builders/buildConversationsPubSubFromVendor';
import { DEFAULT_CLIENT_KEY } from '../constants/clientKeys';
const noop = () => {};
const DEFAULT_LIFECYCLE_HOOKS = {
  onConnect: noop,
  onConnecting: noop,
  onDisconnect: noop,
  onFailure: noop,
  onSuspended: noop
};

/**
 * @typedef lifeCycleHooks
 * @type {Object}
 * @property {function} onConnect - Called when the client has connected
 * @property {function} onConnecting - Called when the client is connecting
 * @property {function} onDisconnect - Called when the client has disconnected
 * @property {function} onSuspended - Called when the client becomes suspended
 * @property {function} onFailure - Called when the client fails (not recoverable)
 */

/**
 * Initialize a connection
 *
 * @param {Object} connectionConfig - connection configuration
 * @param {lifeCycleHooks} connectionConfig.lifeCycleHooks - Connection life cycle callbacks
 * @param {function} connectionConfig.resolveVendor- A resolver function to load vendor (can be code split)
 */
export const initializePubSubWithVendor = ({
  lifecycleHooks = DEFAULT_LIFECYCLE_HOOKS,
  resolveVendor,
  clientOptions = {},
  clientKey = DEFAULT_CLIENT_KEY
}) => dispatch => {
  dispatch(initializePubSubStarted(clientKey));
  lifecycleHooks = Object.assign({}, DEFAULT_LIFECYCLE_HOOKS, lifecycleHooks);
  return resolveVendor().then(vendor => {
    // set up conversations pubsub redux behavior
    // using the vendor lifecycle callbacks
    if (vendor) {
      vendor.registerLifecycleHooks(Object.assign({}, lifecycleHooks, {
        onConnect(params) {
          if (params.reconnected) {
            dispatch(pubSubReconnected(clientKey));
          } else {
            dispatch(pubSubReady(clientKey));
          }
          lifecycleHooks.onConnect(params);
        },
        onConnecting({
          reconnecting
        }) {
          if (reconnecting) {
            dispatch(pubSubReconnecting(clientKey));
          }
          lifecycleHooks.onConnecting({
            reconnecting
          });
        },
        onDisconnect() {
          dispatch(pubSubDisconnected(clientKey));
          lifecycleHooks.onDisconnect();
        },
        onSuspended() {
          dispatch(pubSubSuspended(clientKey));
          lifecycleHooks.onSuspended();
        }
      }));
    }

    // build/setup the conversations pubsub client using
    // the newly created vendor instance.
    const client = buildConversationsPubSubFromVendor(vendor, clientOptions);
    dispatch(initializePubSubSucceeded(client, clientKey));
    if (client.isConnected()) {
      dispatch(pubSubReady(clientKey));
    } else {
      client.connect();
    }
  }).catch(error => {
    dispatch(initializePubSubFailed(error, clientKey));
  });
};