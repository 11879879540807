import get from 'transmute/get';
import { AGENT_ID, AWAY_MODE, PORTAL_ID, UPDATED_BY_AGENT_ID, UPDATED_AT } from '../constants/keyPaths';
export const getAgentId = agentStatus => {
  return get(AGENT_ID, agentStatus);
};
export const getAwayMode = agentStatus => {
  return get(AWAY_MODE, agentStatus);
};
export const getPortalId = agentStatus => {
  return get(PORTAL_ID, agentStatus);
};
export const getUpdatedByAgentId = agentStatus => {
  return get(UPDATED_BY_AGENT_ID, agentStatus);
};
export const getUpdatedAt = agentStatus => {
  return get(UPDATED_AT, agentStatus);
};
export const getAgentUpdatedOwnStatus = agentStatus => {
  const agentId = getAgentId(agentStatus);
  const updatedByAgentId = getUpdatedByAgentId(agentStatus);
  return agentId === updatedByAgentId;
};