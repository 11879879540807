import getIn from 'transmute/getIn';
import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { getSelectedAssociations } from './getSelectedAssociations';
export const getObjectId = getIn(['objectId']);
export const getObjectTypeId = getIn(['objectTypeId']);
export const getDefaultAssociationsWithCallee = ({
  callableObject,
  uasAssociations
}) => {
  if (!callableObject) return null;
  const calleeId = `${getObjectId(callableObject)}`;
  const calleeObjectTypeId = getObjectTypeId(callableObject);
  const associations = uasAssociations.get('associations');
  if (!associations.size) {
    return ImmutableMap({
      [calleeObjectTypeId]: ImmutableSet([calleeId])
    });
  }
  const v2AssociationsData = getSelectedAssociations(associations);
  let associationsByTypeId = v2AssociationsData.map(association => {
    return association.get('objectIds');
  });
  const calleeObjectTypeAssociations = associationsByTypeId.get(calleeObjectTypeId);
  if (!calleeObjectTypeAssociations) {
    associationsByTypeId = associationsByTypeId.set(calleeObjectTypeId, ImmutableSet([calleeId]));
  } else if (!calleeObjectTypeAssociations.has(calleeId)) {
    associationsByTypeId = associationsByTypeId.update(calleeObjectTypeId, set => set.add(calleeId));
  }
  return associationsByTypeId;
};
export const getDefaultAutoAssociationsWithCallee = ({
  callableObject,
  autoAssociations
}) => {
  if (!callableObject) return null;
  const calleeId = `${getObjectId(callableObject)}`;
  const calleeObjectTypeId = getObjectTypeId(callableObject);
  if (!autoAssociations || !Object.keys(autoAssociations).length) {
    return {
      [calleeObjectTypeId]: [calleeId]
    };
  }
  let associationsByTypeId = Object.assign({}, autoAssociations);
  const calleeObjectTypeAssociations = associationsByTypeId[calleeObjectTypeId];
  if (!calleeObjectTypeAssociations) {
    associationsByTypeId = Object.assign({}, associationsByTypeId, {
      [calleeObjectTypeId]: [calleeId]
    });
  } else if (!calleeObjectTypeAssociations.includes(calleeId)) {
    associationsByTypeId = Object.assign({}, associationsByTypeId, {
      [calleeObjectTypeId]: Array(...new Set([...calleeObjectTypeAssociations, calleeId]))
    });
  }
  return associationsByTypeId;
};