import { getApolloContext, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { fetchConversationsOnAssociatedTicket } from '../queries/fetchConversationsOnAssociatedTicketQuery';
import { apolloClientInvariant } from '../../../graphql/invariant/apolloClientInvariant';
import { getCountOfConversationsFromResponse } from '../utils/getCountOfConversationsFromResponse';
export const useCountOfConversationsOnAssociatedTicket = ({
  conversationId,
  skip = false
}) => {
  const apolloClientFromProvider = useContext(getApolloContext()).client;
  apolloClientInvariant(apolloClientFromProvider);
  const {
    data,
    loading,
    error
  } = useQuery(fetchConversationsOnAssociatedTicket, {
    client: apolloClientFromProvider,
    variables: {
      conversationId: Number(conversationId)
    },
    skip: !conversationId || skip
  });
  return {
    loading,
    error,
    totalConversationsCount: data ? getCountOfConversationsFromResponse(data) : data
  };
};