import { List as ImmutableList } from 'immutable';
import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { FETCH_FILTER_RULES_BY_THREAD_ID } from '../constants/asyncActionTypes';
import { limitToCount } from 'conversations-async-data/indexed-async-data/eviction-strategies/limitToCount';
import { FILTER_RULES_ASYNC_DATA_CACHE_SIZE } from '../../../common/public/constants/CacheSize';
const initialState = new IndexedAsyncData({
  name: 'filterRules',
  notSetValue: new AsyncData({
    data: ImmutableList()
  }),
  evict: limitToCount(FILTER_RULES_ASYNC_DATA_CACHE_SIZE)
});
export const filterRules = handleActions({
  [FETCH_FILTER_RULES_BY_THREAD_ID.STARTED]: (state, action) => {
    const {
      threadId
    } = action.payload.requestArgs;
    return updateEntry(threadId, requestStarted, state);
  },
  [FETCH_FILTER_RULES_BY_THREAD_ID.SUCCEEDED](state, action) {
    const {
      data,
      requestArgs
    } = action.payload;
    const {
      threadId
    } = requestArgs;
    return updateEntry(threadId, requestSucceededWithOperator(() => data), state);
  },
  [FETCH_FILTER_RULES_BY_THREAD_ID.FAILED](state, action) {
    const {
      threadId
    } = action.payload.requestArgs;
    return updateEntry(threadId, requestFailed, state);
  }
}, initialState);