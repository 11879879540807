/* Message history when replying/forwarding a message. Large data, but very low count */
const EMAIL_HISTORY_COUNT = 3;
export const EMAIL_FORWARD_HISTORY_DATA_CACHE_SIZE = EMAIL_HISTORY_COUNT;
export const PREVIOUS_MESSAGE_HISTORY_DATA_CACHE_SIZE = EMAIL_HISTORY_COUNT;
export const CONTACT_PERMISSIONS_ASYNC_DATA_CACHE_SIZE = 500;

/* Message Limit Data - Small data, but should be refreshed every time the thread changes */
export const MESSAGE_LIMIT_DATA_ASYNC_DATA_CACHE_SIZE = 1;
const DEFAULT_ASYNC_DATA_CACHE_SIZE = 25;

/* The following caches are key'd off threadIds. Data can be large */
export const THREAD_DETAILS_ASYNC_DATA_CACHE_SIZE = DEFAULT_ASYNC_DATA_CACHE_SIZE;
export const ASSIGNMENT_CHANGE_STATUS_ASYNC_DATA_CACHE_SIZE = THREAD_DETAILS_ASYNC_DATA_CACHE_SIZE;
export const THREAD_ASSOCIATIONS_ASYNC_DATA_CACHE_SIZE = THREAD_DETAILS_ASYNC_DATA_CACHE_SIZE;
export const FILTER_RULES_ASYNC_DATA_CACHE_SIZE = THREAD_DETAILS_ASYNC_DATA_CACHE_SIZE;
export const VISITORS_ASYNC_DATA_CACHE_SIZE = THREAD_DETAILS_ASYNC_DATA_CACHE_SIZE;

/* Agent/Bot/Integrator Senders. Limited growth, and small data record */
export const SENDERS_ASYNC_DATA_CACHE_SIZE = DEFAULT_ASYNC_DATA_CACHE_SIZE * 4;

/* Email recipients - account for multiple recipients.  Small data record */
const EMAIL_RECIPIENT_SIZE = 500; // 500 is the recipient limit for GMail / Outlook
export const HYDRATED_EMAIL_ADDRESS_ASYNC_DATA_CACHE_SIZE = EMAIL_RECIPIENT_SIZE;

/* The following are key'd off file/message and count sould be generally low. Small data record*/
export const FILE_ATTACHMENTS_ASYNC_DATA_CACHE_SIZE = DEFAULT_ASYNC_DATA_CACHE_SIZE;
export const TRUNCATED_MESSAGES_ASYNC_DATA_CACHE_SIZE = DEFAULT_ASYNC_DATA_CACHE_SIZE;
export const INLINE_IMAGE_STATUS_ASYNC_DATA_CACHE_SIZE = DEFAULT_ASYNC_DATA_CACHE_SIZE;

/* Form context message */
export const FORM_ASYNC_DATA_CACHE_SIZE = DEFAULT_ASYNC_DATA_CACHE_SIZE;