import { CALL, CONVERSATION_SESSION, EMAIL, FORWARDED_EMAIL, INCOMING_EMAIL, MEETING, NOTE, POSTAL_MAIL, TASK, WHATS_APP, SMS, LINKEDIN_MESSAGE } from 'customer-data-objects/engagement/EngagementTypes';
import { CALL_TYPE_ID, COMMUNICATION_TYPE_ID, CONVERSATION_SESSION_TYPE_ID, EMAIL_TYPE_ID, MEETING_EVENT_TYPE_ID, NOTE_TYPE_ID, POSTAL_MAIL_TYPE_ID, TASK_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
// Values taken from https://git.hubteam.com/HubSpot/IdentityBase/blob/master/src/main/protobuf/contacts.proto
const EngagementTypeToObjectTypeId = new Map([[CALL, CALL_TYPE_ID], [CONVERSATION_SESSION, CONVERSATION_SESSION_TYPE_ID], [EMAIL, EMAIL_TYPE_ID], [FORWARDED_EMAIL, EMAIL_TYPE_ID], [INCOMING_EMAIL, EMAIL_TYPE_ID], [LINKEDIN_MESSAGE, COMMUNICATION_TYPE_ID], [MEETING, MEETING_EVENT_TYPE_ID], [NOTE, NOTE_TYPE_ID], [POSTAL_MAIL, POSTAL_MAIL_TYPE_ID], [SMS, COMMUNICATION_TYPE_ID], [TASK, TASK_TYPE_ID], [WHATS_APP, COMMUNICATION_TYPE_ID]]);
export const getObjectTypeIdFromEngagementType = ({
  engagementType
}) => {
  const objectTypeId = EngagementTypeToObjectTypeId.get(engagementType);
  if (!objectTypeId) {
    throw new Error('Engagement type does not map to valid objectTypeId');
  }
  return objectTypeId;
};