import http from 'conversations-http/clients/http';
import { registerQuery } from 'data-fetching-client';
const ZORSE_SUPPORT_INBOXES = 'zorseSupportInboxes';
/**
 * @description This request gets the inbox IDs for the Support and ISC inboxes
 * in portal 53.
 */
export const GET_ZORSE_SUPPORT_INBOXES = registerQuery({
  fieldName: ZORSE_SUPPORT_INBOXES,
  fetcher() {
    return http.get('conversations/taxonomy/v1/inbox');
  }
});