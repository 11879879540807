import getIn from 'transmute/getIn';
const getStatus = update => getIn(['member', 'status'], update) || getIn(['statusUpdate', 'currentThreadStatus'], update);
const getPreviousStatus = update => getIn(['member', 'previous', 'status'], update) || getIn(['statusUpdate', 'previousThreadStatus'], update);
function getThreadCountOperation(current, previous) {
  if (current === 'SOFT_DELETED') return 'REMOVE';
  if (previous === 'SOFT_DELETED' && current === 'STARTED') return 'ADD';
  if (previous === 'STARTED' && current === 'ENDED') return 'REMOVE';
  if (previous === 'ENDED' && current === 'STARTED') return 'ADD';
  return null;
}

/**
 * @description Given a InboxMemberChange, this operator will return an
 * operation type based on whether that update results in an ADD or REMOVE operation
 * to a view. This operator will return null if it cannot compute the operation
 * or if the operation computed doesn't affect counts.
 */
export function getUpdatedInOperationType(update) {
  const current = getStatus(update);
  const previous = getPreviousStatus(update);
  return getThreadCountOperation(current, previous);
}