import { Record } from 'immutable';
class SavingState extends Record({
  contactPreferences: false,
  customerEmail: false,
  reason: false,
  submission: false,
  type: false,
  notes: false
}, 'SavingState') {}
SavingState.fromJS = obj => new SavingState(Object.assign({}, obj));
export default SavingState;