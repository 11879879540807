import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
export const alertError = ({
  error,
  message,
  titleText
}) => {
  FloatingAlertStore.addAlert({
    message,
    titleText,
    type: 'danger'
  });
  reportError({
    error
  });
};