export const getCountOfConversationsFromResponse = response => {
  const toTicketAssociations = response.crmObject.associations.conversationToTicketV2;
  if (!toTicketAssociations) {
    return 0;
  }
  const ticket = toTicketAssociations.edges[0];
  if (!ticket) {
    return 0;
  }
  const toConversationsAssocaitions = ticket.node.associations.ticketToConversationV2;
  if (!toConversationsAssocaitions) {
    return 0;
  }
  return toConversationsAssocaitions.edges.length;
};