import isBoolean from 'hs-lodash/isBoolean';
// TODO: move to composer-data
import invariant from 'conversations-thread-data/utils/invariant';
export const testThread = ({
  isTestThread
}) => {
  invariant(isBoolean(isTestThread), 'isTestThread is required for testThread disabling strategy');
  return isTestThread ? {
    disabled: true,
    sendDisabledReason: 'TEST_THREAD'
  } : null;
};