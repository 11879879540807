import { getEntries } from 'conversations-async-data/indexed-async-data/operators/getters';
import { ALLOWED_FILE_TYPES } from 'conversations-internal-schema/file-metadata/constants/allowedFileTypes';
import { getLocalId } from 'conversations-internal-schema/file-upload/operators/fileUploadGetters';
import FileUploadRecord from 'conversations-internal-schema/file-upload/records/FileUploadRecord';
import { EMAIL_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
import { fromJS } from 'immutable';
import { useCallback, useEffect, useReducer, useRef } from 'react';
import { useEditorConfigurationKey } from '../../../channel-capabilities/public/hooks';
import { fileUploadFailed } from '../actions/fileUploadFailed';
import { fileUploadProgress } from '../actions/fileUploadProgress';
import { fileUploadStarted } from '../actions/fileUploadStarted';
import { fileUploadSucceeded } from '../actions/fileUploadSucceeded';
import { removeFileUpload } from '../actions/removeFileUpload';
import { uploadFile } from '../clients/uploadFile';
import { buildFileSizeError, buildUnsupportedError } from '../operators/errorBuilders';
import { initialState, reducer } from '../reducers/fileUploads';
import { isUnsupportedFileType } from '../util/isUnsupportedFileType';
// a bit weird to have this global, but this ensures
// that all call-sites of the hook can abort upload
// requests initiated by other call-sites.
const uploadRequests = {};
export const useFileUploads = ({
  maxFileSize,
  fileTypeToOverride = ALLOWED_FILE_TYPES,
  onUpload = () => {},
  onError = () => {},
  threadId
} = {}) => {
  // use local state with useReducer for separate fileuploads per editor
  const [state, dispatch] = useReducer(reducer, initialState);
  const indexedAsyncFileUploads = getEntries(state);
  const onUploadRef = useRef(onUpload);
  const indexedAsyncFileUploadsRef = useRef(indexedAsyncFileUploads);
  const editorConfigurationKey = useEditorConfigurationKey();
  useEffect(() => {
    indexedAsyncFileUploadsRef.current = indexedAsyncFileUploads;
    onUploadRef.current = onUpload;
  }, [onUpload, indexedAsyncFileUploads]);
  const handleRemoveFileUpload = useCallback(fileUpload => {
    const abortableRequest = uploadRequests[getLocalId(fileUpload)];

    // https://product.hubteam.com/docs/frontend/docs/hub-http.html#aborting-requests
    const xhr = abortableRequest && abortableRequest.xhr;
    const canAbort = xhr && xhr.readyState !== 4 && typeof xhr.abort === 'function';
    if (canAbort) xhr.abort();
    dispatch(removeFileUpload({
      fileUpload
    }));
  }, [dispatch]);
  const handleUploadFile = useCallback(file => {
    const fileToUpload = new FileUploadRecord({
      file
    });
    const dispatchRemoveFileUpload = () => handleRemoveFileUpload(fileToUpload);
    dispatch(fileUploadStarted({
      fileUpload: fileToUpload
    }));
    if (typeof maxFileSize !== 'undefined' && file.size > maxFileSize) {
      const sizeError = buildFileSizeError({
        maxFileSize
      });
      dispatch(fileUploadFailed({
        fileUpload: fileToUpload,
        error: sizeError
      }));
      onError(sizeError, file, dispatchRemoveFileUpload);
      return;
    }
    if (isUnsupportedFileType(file, fileTypeToOverride)) {
      const unsupportedError = buildUnsupportedError();
      dispatch(fileUploadFailed({
        fileUpload: fileToUpload,
        error: unsupportedError
      }));
      onError(unsupportedError, file, dispatchRemoveFileUpload);
      return;
    }
    const access = editorConfigurationKey === EMAIL_GENERIC_CHANNEL_ID ? 'HIDDEN_IN_APP_SENSITIVE' : 'HIDDEN_IN_APP_PRIVATE_NOT_INDEXABLE';
    const abortableRequest = uploadFile({
      file,
      access,
      onProgress: ({
        loaded,
        total
      }) => {
        dispatch(fileUploadProgress({
          fileUpload: fileToUpload,
          loaded,
          total
        }));
      },
      threadId: threadId
    });
    uploadRequests[getLocalId(fileToUpload)] = abortableRequest.mutableXhrWrapper;
    abortableRequest.then(uploadedFile => {
      if (indexedAsyncFileUploadsRef.current.has(getLocalId(fileToUpload))) {
        onUploadRef.current(fromJS(uploadedFile));
        dispatch(fileUploadSucceeded({
          fileUpload: fileToUpload
        }));
      }
    }).catch(error => {
      // @ts-expect-error it does exist - shouldn't dispatch failure if the request was aborted
      if (error.errorCode === 'ABORT') return;
      dispatch(fileUploadFailed({
        fileUpload: fileToUpload,
        error
      }));
      onError(error, file, dispatchRemoveFileUpload);
    }).finally(() => {
      delete uploadRequests[getLocalId(fileToUpload)];
    });
  }, [handleRemoveFileUpload, maxFileSize, onError, threadId, fileTypeToOverride, editorConfigurationKey]);
  return {
    indexedAsyncFileUploads,
    removeFileUpload: handleRemoveFileUpload,
    uploadFile: handleUploadFile
  };
};