/* hs-eslint ignored failing-rules */
/* eslint-disable react-hooks/exhaustive-deps */

'use es6';

import debounce from 'hs-lodash/debounce';
import { useEffect, useCallback, useMemo, useState } from 'react';
import { getPrunedRepliesFromDrafts } from '../local-storage/getPrunedRepliesFromDrafts';
import { saveCommonReplyAsDraft } from '../local-storage/saveCommonReplyAsDraft';
import { clearCommonReplyDraft } from '../local-storage/clearCommonReplyDraft';
import { makeDraftKeyFromReplyId } from '../operators/makeDraftKeyFromReplyId';
import { getReplyId } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;
const getDrafts = () => getPrunedRepliesFromDrafts({
  ageLimitInMs: ONE_DAY_IN_MS
});

/**
 * @description a hook that provides an API for getting,
 * saving, and clearing common reply drafts. Saves are
 * debounced to prevent local-storage thrashing, and any
 * un-flushed saves are flushed whenever the component
 * unmounts or the debounced save method is re-recreated
 * due to the timeout changing.
 * @param {String} replyId
 * @param {Number} saveTimeout
 */
export const useCommonReplyDraft = (replyId, saveTimeout) => {
  const [drafts, setDrafts] = useState(getDrafts());
  const draft = useMemo(() => drafts.get(replyId), [drafts, replyId]);
  const debouncedSave = useCallback(debounce(replyToSave => {
    const replyIdToSave = getReplyId(replyToSave);
    if (!replyIdToSave.includes('null')) {
      saveCommonReplyAsDraft(replyToSave);
      setDrafts(currentDrafts => currentDrafts.set(replyIdToSave, replyToSave));
    }
  }, saveTimeout), [setDrafts, saveTimeout]);
  useEffect(() => {
    debouncedSave.flush();
    return debouncedSave.flush;
  }, [debouncedSave]);
  const clear = useCallback(() => {
    debouncedSave.cancel();
    clearCommonReplyDraft(makeDraftKeyFromReplyId(replyId));
    setDrafts(currentDrafts => currentDrafts.delete(replyId));
  }, [debouncedSave, replyId]);
  return [draft, debouncedSave, clear];
};