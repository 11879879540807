import PortalIdParser from 'PortalIdParser';
import { getPathname, getSearch } from './makeEarlyRequestDataHelpers';
export const makeEarlyRequestData = () => {
  const LAST_VIEWED_INBOX_V2 = 'LocalSettings:Conversations:hsLastViewedInbox:v2';
  const SORT_ORDER = 'LocalSettings:Conversations:hsSortOrder';
  const SORT_DESCENDING = 'DESC';
  const inboxThreadIdRegex = /live-messages\/\d+\/inbox\/(\d+)/;
  const helpDeskthreadIdRegex = /helpdesk\/\d+\/conversation\/(\d+)/;
  const inboxIdRegex = /\?.*inbox-id=(\d+).*/;
  const portalId = PortalIdParser.get();
  const threadIdMatches = getPathname().match(inboxThreadIdRegex) || getPathname().match(helpDeskthreadIdRegex);
  const inboxIdMatches = getSearch().match(inboxIdRegex);
  let threadId = threadIdMatches ? threadIdMatches[1] : null;
  const inboxIdFromPath = inboxIdMatches ? inboxIdMatches[1] : null;
  let lastViewed = {};
  try {
    lastViewed = JSON.parse(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    window.localStorage.getItem(LAST_VIEWED_INBOX_V2)).lastViewed;
  } catch (e) {
    // Do nothing.
  }
  let inboxId = null;
  let threadListId = null;
  let threadListType = null;
  let viewId = null;
  if (inboxIdFromPath) {
    inboxId = inboxIdFromPath;
    threadId = null;
  } else {
    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
    const forPortal = lastViewed[portalId];
    if (forPortal) {
      inboxId = forPortal.inboxId || null;
      viewId = forPortal.viewId || '';
      const splitThreadListKeys = viewId.split('-');
      if (splitThreadListKeys.length === 2) {
        threadListType = splitThreadListKeys[0];
        threadListId = splitThreadListKeys[1];
      }
    }
  }
  let sortOrder = SORT_DESCENDING;
  try {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    const sortOrderArray = JSON.parse(window.localStorage.getItem(SORT_ORDER));
    if (sortOrderArray && inboxId && viewId) {
      sortOrder = sortOrderArray[inboxId][viewId];
    }
  } catch (e) {
    // Do nothing.
  }
  const threadStatus = 'STARTED';
  return {
    inboxId,
    threadId,
    customViewId: threadListId === null && threadListType === null && viewId ? viewId : null,
    sortDirection: sortOrder || SORT_DESCENDING,
    threadList: threadListId === null || threadListType === null ? null : {
      threadListId,
      threadListType
    },
    threadStatus
  };
};