// @ts-expect-error Not typed
import { set } from 'hub-http/helpers/update';
import {
// @ts-expect-error Not typed
withResponseHandlers,
// @ts-expect-error Not typed
buildErrorResponse,
// @ts-expect-error Not typed
withRetry,
// @ts-expect-error Not typed
withTracking,
// @ts-expect-error Not typed
buildRequestError,
// @ts-expect-error Not typed
resolveTimeout } from 'hub-http/adapters/adapterUtils';
// @ts-expect-error Not typed
import { ensureStaticAppInfo } from 'hub-http/middlewares/staticAppInfo';
import { setMockAuth } from 'hub-http/middlewares/mockAuth';
import { createStack } from 'hub-http/index';
import { reportDomain, reportStatusCode } from 'hub-http/tracking/trackRequests';
// @ts-expect-error Not typed
import { Metrics } from 'hub-http/tracking/metrics';
import { parseUrl } from 'hub-http/helpers/url';
/**
 * HubSpot's backend stack uses the `clienttimeout` that we set in query params
 * via the `timeoutInQuery` middleware to set a server-side deadline for the
 * request thread: https://git.hubteam.com/HubSpot/bootstrap/blob/f401d5284321d50fb3b9e829ba364fe86e2814e9/bootstrap-rest-parent/bootstrap-rest-shared/src/main/java/com/hubspot/bootstrap/rest/jersey/filters/DeadlineHandler.java#L35-L46
 * This allows Java services to abort expensive operations if insufficient time
 * remains to complete the work.
 *
 * However, using the same duration client-side does not account for the
 * the server using 100% of the alotted time. High network latency can time out
 * the request even if the server attempted to return a successful response
 * within the timeout window.
 *
 * Any round trip network latency above 500ms is likely too slow to load the
 * app anyway, and probably will far exceed the set timeout regardless.
 */

function parseSseEventResponse(message) {
  const cleanedMessage = message.replaceAll('\n', '').replaceAll('event: message', '').replaceAll('event: done', '');
  const spiltMsg = cleanedMessage.split('data: {');
  const data = '{'.concat(spiltMsg[spiltMsg.length - 1]);
  return JSON.parse(data);
}
const createSource = options => {
  const xhr = new XMLHttpRequest();
  const {
    onProgress,
    onError,
    onClose
  } = options;
  const newHeaders = Object.assign({}, options.headers, {
    Accept: 'text/event-stream'
  });
  options = set('headers', newHeaders)(options);
  reportDomain(options.url || '');
  xhr.open(options.method || 'GET', options.url || '', true);
  xhr.timeout = resolveTimeout(options.timeout);
  xhr.withCredentials = !!options.withCredentials;
  if (options.responseType) {
    xhr.responseType = options.responseType;
  }
  if (typeof options.withXhr === 'function') {
    options.withXhr(xhr);
  }
  Object.keys(options.headers || {}).forEach(headerName => {
    if (options.headers && options.headers[headerName]) {
      xhr.setRequestHeader(headerName, options.headers[headerName]);
    }
  });
  const sendTime = performance.now();
  xhr.addEventListener('error', () => {
    reportStatusCode({
      url: xhr.responseURL,
      sendTime,
      statusCode: xhr.status,
      statusDesc: 'NETWORKERROR'
    });
    withResponseHandlers(buildErrorResponse(xhr, 'Network request failed', 'NETWORKERROR'), options).then(onError).catch();
  });
  xhr.addEventListener('timeout', () => {
    reportStatusCode({
      url: xhr.responseURL,
      sendTime,
      statusCode: xhr.status,
      statusDesc: 'TIMEOUT'
    });
    withResponseHandlers(buildErrorResponse(xhr, 'Request timeout', 'TIMEOUT'), options).then(onError).catch();
  });
  xhr.addEventListener('abort', () => {
    reportStatusCode({
      url: xhr.responseURL,
      sendTime,
      statusCode: xhr.status,
      statusDesc: 'ABORT'
    });
    withResponseHandlers(buildErrorResponse(xhr, 'Request aborted', 'ABORT'), options).then(onError).catch();
  });
  xhr.addEventListener('progress', () => {
    try {
      const event = parseSseEventResponse(xhr.response);
      if (event) {
        onProgress(event);
      }
    } catch (_unused) {
      onError(new Error(`could not parse ${xhr.response}`));
    }
  });
  xhr.addEventListener('loadend', () => {
    try {
      const finalEvent = parseSseEventResponse(xhr.response);
      if (finalEvent) {
        onClose(xhr.status, finalEvent);
      }
    } catch (_unused2) {
      onClose(xhr.status);
    }
  });
  xhr.addEventListener('readystatechange', () => {
    if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
      // response errors such as 404 don't fire the error event listener, so
      // handling errors here.
      reportStatusCode({
        url: xhr.responseURL,
        sendTime,
        statusCode: xhr.status,
        statusDesc: 'NETWORKERROR'
      });
      onError(new Error(`Request failed with status ${xhr.status}`), true);
    }
  });
  xhr.send(typeof options.data === 'undefined' ? null : options.data);
};
const handleRequestErrors = reason => Promise.reject(buildRequestError(reason));
const essentialMiddleware = createStack(withTracking, ensureStaticAppInfo);
export let _originalClientImplCalled = false;
let mockAuth = false;
const createClientImpl = optionMiddleware => {
  _originalClientImplCalled = true;
  const client = (url, options) => {
    const parsed = parseUrl(url);
    if (parsed.hostname && parsed.hostname.match(/^api(-[a-z]{2}\d{1})?\.hubspot(qa)?\.com/)) {
      Metrics.counter('hardcoded-api-hubspot-domain').increment();
    }
    return withRetry(Object.assign({}, options, {
      url
    }), o => optionMiddleware(o).then(essentialMiddleware).then(createSource).catch(handleRequestErrors));
  };
  const responseWithMethod = method => (url, options) => client(url, Object.assign({}, options, {
    method
  }));
  const withMethod = method => (url, options) => responseWithMethod(method)(url, options).catch(handleRequestErrors);
  return Object.assign(client, {
    get: withMethod('GET'),
    post: withMethod('POST'),
    put: withMethod('PUT')
  });
};

/**
 * Should only be invoked by the fe test runner
 */
export const enableMockAuth = () => {
  mockAuth = true;
};
const sseClient = optionMiddleware => createClientImpl(createStack(setMockAuth(mockAuth), optionMiddleware));
export default sseClient;