import { client } from 'conversations-inbox-lib/data-fetching-client/public/clients';
import { convertResponderToDataFetchingClientObject } from './convertResponderToGqlObject';
import { FETCH_CURRENT_ASSIGNEE_QUERY } from 'conversations-thread-data/assignees/public/queries';
export const updateStateWithUpdateAssigneeFromTeamResponse = (responder, threadId) => {
  const updatedAssignee = convertResponderToDataFetchingClientObject(responder);
  client.writeQuery({
    query: FETCH_CURRENT_ASSIGNEE_QUERY,
    variables: {
      threadId: `${threadId}`
    },
    data: {
      fetchCurrentAssignee: updatedAssignee
    }
  });
};