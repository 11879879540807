import filter from 'transmute/filter';
import pipe from 'transmute/pipe';

// @ts-expect-error isConversationalMessage is not typed
import { isConversationalMessage } from '../../common-message/operators/isConversationalMessage';
// @ts-expect-error senderTypeComparators is not typed
import { isFromVisitor } from '../../common-message-format/operators/senderTypeComparators';
import { getGenericChannelIdForMessage } from '../../common-message-format/operators/commonMessageGetters';

// @ts-expect-error ThreadHistory is not typed

// @ts-expect-error getters is not typed
import { getMessages } from './getters';

/**
 * find the last conversational message from the visitor in a thread history
 *
 * @param {ThreadHistory} threadHistory
 * @returns {MessageRecord}
 */
export const getLatestVisitorMessage = (threadHistory, genericChannelId) => {
  if (!threadHistory) return null;
  const visitorMessages = pipe(getMessages, filter(message => isFromVisitor(message) && isConversationalMessage(message)))(threadHistory);
  if (genericChannelId) {
    return visitorMessages.findLast(message => getGenericChannelIdForMessage(message) === genericChannelId);
  }
  return visitorMessages && visitorMessages.last();
};