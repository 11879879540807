import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { silenceErrorAlert } from 'conversations-error-reporting/error-actions/builders/silenceErrorAlert';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import I18n from 'I18n';
import formatName from 'I18n/utils/formatName';
import { ASSIGNMENT_CHANGE } from '../constants/asyncActionTypes';
import { updateAssignment as updateAssignmentClient } from '../clients/updateAssignment';
import { toAgentRecord } from '../utils/toAgentRecord';
const assignedToCurrentAsigneeErrorType = 'CANNOT_ASSIGN_TO_EXISTING_AGENT';
export const updateAssignment = createAsyncAction({
  requestFn: updateAssignmentClient,
  actionTypes: ASSIGNMENT_CHANGE,
  toRecordFn: toAgentRecord,
  successMetaActionCreator: action => {
    // @ts-expect-error createAsyncAction thinks that "action" is just a general `object`, which doesn't guarantee a requestArgs field
    const {
      requestArgs
    } = action;
    const {
      responder,
      showNotification
    } = requestArgs;
    if (showNotification) {
      const fullName = responder ? formatName({
        firstName: responder.firstName,
        lastName: responder.lastName,
        email: responder.email
      }) : null;
      return {
        notification: {
          type: 'success',
          titleText: I18n.text('conversations-thread-view.reassignment.alert.title'),
          message: responder ? I18n.text('conversations-thread-view.reassignment.alert.ownerMessage', {
            ownerFullName: fullName
          }) : I18n.text('conversations-thread-view.reassignment.alert.noOwnerMessage')
        }
      };
    }
    return undefined;
  },
  failureMetaActionCreator: action => {
    // @ts-expect-error createAsyncAction thinks that "action" is just a general `object`, which doesn't guarantee a requestArgs or error field
    const {
      requestArgs,
      error
    } = action;
    const {
      showNotification
    } = requestArgs;
    const {
      responseJSON
    } = error;
    if (responseJSON && responseJSON.errorType === assignedToCurrentAsigneeErrorType) {
      // happens in certain lag situations and in some bot transitions
      // harmless since the intent is met, i.e. tried to assign to agent 2,
      // current assigned agent ends up being agent 2
      reportError({
        error,
        fingerprint: ['{{ default }}', assignedToCurrentAsigneeErrorType],
        tags: {
          isVisibleToUser: false
        }
      });
      return silenceErrorAlert();
    }
    if (showNotification) {
      return {
        notification: {
          type: 'danger',
          titleText: I18n.text('conversations-thread-view.reassignment.changeOwnerFailed.title'),
          message: I18n.text('conversations-thread-view.reassignment.changeOwnerFailed.description')
        }
      };
    }
    return undefined;
  }
});