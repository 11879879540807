import { useQuery } from 'data-fetching-client';
import { GET_THREAD_META_DATA } from '../queries/threadQueries';
export function useThreadMetaData({
  threadId,
  allowDeleted = false
}) {
  const {
    data,
    loading
  } = useQuery(GET_THREAD_META_DATA, {
    variables: {
      threadId,
      allowDeleted
    }
  });
  if (loading || !data) {
    return {
      loading,
      startedAt: null,
      closedAt: null,
      vid: null
    };
  }
  return {
    loading,
    startedAt: data.thread.startedAt,
    closedAt: data.thread.closedAt,
    vid: data.thread.vid
  };
}