// gimme: conversations-inbox-ui 06/10/2020 24a2e66917cc934433dd111db7e06fd02943ca6a
'use es6';

import pipe from 'transmute/pipe';
import { getThreadHistories } from 'conversations-thread-data/thread-histories/public/selectors/getters';
import { getEntry } from 'conversations-async-data/indexed-async-data/operators/getters';
import { isFailed } from 'conversations-async-data/async-data/operators/statusComparators';
export const threadHistoryFailed = (state, props) => {
  const {
    threadId
  } = props;
  const asyncThreadHistory = pipe(getThreadHistories, getEntry(threadId))(state);
  return isFailed(asyncThreadHistory);
};