// AvailableSendStatus

export const TEMPLATE = 'TEMPLATE';
export const FREE_FORM_AND_TEMPLATE = 'FREE_FORM_AND_TEMPLATE';
export const NONE = 'NONE';
export const ALL = 'ALL';

// LimitStatus

export const BELOW_LIMIT = 'BELOW_LIMIT';
export const APPROACHING_LIMIT = 'APPROACHING_LIMIT';
export const TOUCHING_LIMIT = 'TOUCHING_LIMIT';
export const LIMITED_BUT_IN_CONVERSATION = 'LIMITED_BUT_IN_CONVERSATION';
export const LIMITED = 'LIMITED';