/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */

// @ts-expect-error module not typed
import { setSender } from 'conversations-message-history/common-message-format/operators/commonMessageFormatSetters';
import { createAction } from 'redux-actions';
// @ts-expect-error module not typed
import { getCMFSender } from '../../publish-message-feature/public/selectors/getCMFSender';
import { createEmailThread as createEmailThreadClient } from 'composer-data/email/public/clients';
import { CREATE_EMAIL_THREAD } from 'conversations-thread-data/email/public/constants';
import { getEmailSendDataFromMessage } from 'composer-data/email/public/operators';
import { publishEmailFailed } from './publishEmailFailed';
// @ts-expect-error module not typed
import { updateCMFSenders } from '../../publish-message-feature/public/selectors/updateCMFSenders';
// @ts-expect-error module not typed
import { getClientType } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { Metrics } from '../../common/Metrics';
const publishEmailStarted = createAction(CREATE_EMAIL_THREAD.STARTED);
const publishEmailSucceeded = createAction(CREATE_EMAIL_THREAD.SUCCEEDED);
export const createEmailThread = function createEmailThread(commonMessage, {
  options
}) {
  return (dispatch, getState) => {
    const state = getState();
    const sender = getCMFSender(state);
    const updatedMessage = updateCMFSenders(state, {
      message: commonMessage
    });
    options.isVisitorAssociatedToThread = true;
    dispatch(publishEmailStarted());
    const data = getEmailSendDataFromMessage(setSender(sender, updatedMessage), Object.assign({}, options));
    const clientType = getClientType(commonMessage);
    Metrics.counter('publish-email-compose', {
      clientType
    }).increment();
    return new Promise((resolve, reject) => createEmailThreadClient(data).then(results => {
      const threadId = `${results.id}`;
      dispatch(publishEmailSucceeded({
        threadId
      }));
      resolve({
        threadId
      });
    }).catch(error => {
      dispatch(publishEmailFailed({
        error,
        transient: true
      }));
      reject(error);
    }));
  };
};