import http from 'conversations-http/clients/http';
export const deleteThread = ({
  threadId,
  hardDelete
}) => {
  const url = `conversations-threads/v1/threads/${threadId}`;
  return http.delete(url, {
    query: {
      hardDelete
    }
  });
};