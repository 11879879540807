import tracker from './usageTracker';
const MAIN_SCREEN = 'main';
class ZorseGrrTaggingTracker {
  constructor() {
    this.conversationId = 0;
  }
  checkCloseTicket() {
    this._trackInteraction('check-close-ticket', MAIN_SCREEN);
  }
  closePanel() {
    this._trackInteraction('close-panel', MAIN_SCREEN);
  }
  editSubject(state) {
    this._trackUsage('edit-subject', MAIN_SCREEN, state);
  }
  openPanel() {
    this._trackInteraction('open-panel', MAIN_SCREEN);
  }
  resolveConversation(state) {
    this._trackUsage('resolve-conversation', MAIN_SCREEN, state);
  }
  resolveTicket(state) {
    this._trackUsage('resolve-ticket', MAIN_SCREEN, state);
  }
  uncheckCloseTicket() {
    this._trackInteraction('uncheck-close-ticket', MAIN_SCREEN);
  }
  _trackInteraction(action, screen, state) {
    tracker.track('zorseGrrTaggingPanelInteraction', {
      action,
      conversationId: this.conversationId,
      screen,
      state
    });
  }
  _trackUsage(action, screen, state) {
    tracker.track('zorseGrrTaggingPanelUsage', {
      action,
      conversationId: this.conversationId,
      screen,
      state
    });
  }
}
export default new ZorseGrrTaggingTracker();