/* hs-eslint ignored failing-rules */
/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useMemo, useState } from 'react';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { useAsyncFetcher } from 'conversations-async-data/async-data/hooks/useAsyncFetcher';
import { requestFailedWithError } from 'conversations-async-data/async-data/operators/requestFailedWithError';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
// @ts-expect-error ThreadHistory is not typed

import { buildCommonMessage } from 'conversations-message-history/common-message-format/operators/buildCommonMessage';
// @ts-expect-error getters is not typed
import { getMessages } from 'conversations-message-history/thread-history/operators/getters';
import { fetchHistoryMessageById } from '../clients/fetchHistoryMessageById';
import { getMessageById } from '../operators/getMessageById';
import invariant from '../../../utils/invariant';
const initialAsyncData = new AsyncData();
export const useCommonMessageWithId = ({
  deferred,
  messageId,
  threadHistory,
  threadId
}) => {
  if (!deferred) {
    invariant(!!messageId, 'messageId not provided');
    invariant(!!threadHistory, 'threadHistory not provided');
    invariant(!!threadId, 'threadId not provided');
  }
  const messages = getMessages(threadHistory);
  const inReplyToMessage = messageId ? getMessageById(messageId, messages) : null;
  const [messageFetchedId, setMessageFetchedId] = useState(null);
  const [asyncCommonMessage, setAsyncCommonMessage] = useState(initialAsyncData);
  useEffect(() => {
    if (messageFetchedId && messageFetchedId !== messageId) {
      setMessageFetchedId(null);
      setAsyncCommonMessage(initialAsyncData);
    }
  }, [messageFetchedId, messageId, initialAsyncData]);
  const fetcher = useCallback(() => {
    const hasFetchingArgs = !!(messageId && threadId);
    const hasDataAlready = Boolean(inReplyToMessage);
    const skipFetching = deferred || hasDataAlready || !hasFetchingArgs;
    if (skipFetching) return;
    setAsyncCommonMessage(requestStarted(asyncCommonMessage));
    fetchHistoryMessageById({
      messageId,
      threadId
    }).then(resp => {
      setMessageFetchedId(messageId);
      setAsyncCommonMessage(requestSucceededWithOperator(() => buildCommonMessage(resp), asyncCommonMessage));
    }, error => {
      setAsyncCommonMessage(requestFailedWithError(error, asyncCommonMessage));
    }).catch(err => {
      reportError({
        error: err,
        tags: {
          isVisibleToUser: false
        }
      });
    });
  }, [asyncCommonMessage, deferred, messageId, threadId, inReplyToMessage]);
  const {
    retryFailed
  } = useAsyncFetcher({
    asyncData: asyncCommonMessage,
    fetcher
  });
  const finalAsyncCommonMessage = useMemo(() => {
    return inReplyToMessage ? requestSucceededWithOperator(() => inReplyToMessage, initialAsyncData) : asyncCommonMessage;
  }, [inReplyToMessage, asyncCommonMessage]);
  return {
    asyncCommonMessage: finalAsyncCommonMessage,
    retryFailed
  };
};