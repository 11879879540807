/**
 * We don't currently support:
 * Mobile Phones, Tablets, and Safari.
 *
 * Alot of our errors come from these browsers, since the experience would be
 * lack luster and in some cases not work, we should just reject them as UNSUPPORTED.
 */

export const getIsUserAgentSupported = () => {
  const UA = navigator.userAgent;

  // Eventhough this won't catch all mobile UAs its fine for our implementation.
  // This should at least reduce errors we recieve from non-supported
  // browsers and devices.
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(UA);
  if (isMobile) return false;
  const lowercasedUA = UA ? navigator.userAgent.toLowerCase() : '';

  // isSafari
  if (lowercasedUA.includes('safari/') && !lowercasedUA.includes('chrome/') && !lowercasedUA.includes('edge/')) {
    return false;
  }
  return true;
};