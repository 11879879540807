import { ACCOUNT_MANAGER, BILLING, CONTRACT_MANAGER, DELIVERABILITY, PAYMENT, PRIVACY } from '../constants/EscalationType';
export function isSubmittable({
  escalation,
  errorState,
  savingState,
  outOfTheOffice
}) {
  if (!escalation.type || errorState.hasConflictError || savingState.submission) {
    return false;
  }
  switch (escalation.type) {
    case ACCOUNT_MANAGER:
      return !!(escalation.reason && escalation.notes && !outOfTheOffice.loading);
    case CONTRACT_MANAGER:
      return !!(escalation.reason && escalation.notes && escalation.fields.hubspotOwnerEmail);
    case DELIVERABILITY:
    case PRIVACY:
      return !!(escalation.reason && escalation.notes);
    case BILLING:
      return !!escalation.notes;
    case PAYMENT:
      return !!(escalation.contactPreferences && escalation.customerEmail && escalation.notes);
    default:
      return false;
  }
}