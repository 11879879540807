'use es6';

import { THREAD_COMMENT } from 'conversations-message-history/comment-message/constants/messageTypes';
import { buildCommentMessage } from 'conversations-message-history/comment-message/operators/buildCommentMessage';
import { setSender } from 'conversations-message-history/common-message-format/operators/commonMessageFormatSetters';
import { publishMessageWithErrorHandling } from '../../public/actions/publishMessageWithErrorHandling';
import { getCMFSender } from '../selectors/getCMFSender';
import { updateCMFSenders } from '../selectors/updateCMFSenders';
export const publishCommentMessage = ({
  message,
  threadId,
  channel
}) => {
  return (dispatch, getState) => {
    const state = getState();
    const sender = getCMFSender(state);
    const updatedMessage = updateCMFSenders(state, {
      message
    });
    const commonMessageWithSender = setSender(sender, updatedMessage);
    const commentMessage = buildCommentMessage(Object.assign({}, commonMessageWithSender.toJS(), {
      '@type': THREAD_COMMENT
    }));
    dispatch(publishMessageWithErrorHandling({
      channel,
      message: commentMessage,
      threadId
    }));
  };
};