import { CALLING_EXTENSIONS_INBOUND_CALLING, CALLING_THIRD_PARTY_CHANNELS } from 'calling-internal-common/constants/CallingGates';
import { getSettingString } from './localSettingsOperators';
export const SUPPORTS_INBOUND_CALLING_KEY = 'supportsInboundCalling';
const SUPPORTS_INBOUND_CALLING_VALUE = 'true';
export const isExtensionsInboundCallingEnabledInLocalStorage = () => {
  return getSettingString(SUPPORTS_INBOUND_CALLING_KEY) === SUPPORTS_INBOUND_CALLING_VALUE;
};

/**
 * True if at least one installed calling provider supports inbound calling.
 * @param callingProviders
 * @returns boolean
 */
export const isExtensionsInboundCallingEnabledInApi = callingProviders => {
  return Boolean(callingProviders && callingProviders.some(provider => provider.supportsInboundCalling === true));
};

/**
 * Toggles the calling extensions inbound calling experience.
 * @param gates
 * @param callingProvider
 * @param callingProviders
 * @returns boolean
 */
export const isExtensionsInboundCallingEnabled = (gates, callingProvider, callingProviders) => {
  const isUngated = gates && (gates.includes(CALLING_THIRD_PARTY_CHANNELS) || gates.includes(CALLING_EXTENSIONS_INBOUND_CALLING));
  const selectedProviderSupportsInboundCalling = callingProvider && callingProvider.get('supportsInboundCalling') === true;
  return Boolean(isUngated || selectedProviderSupportsInboundCalling || isExtensionsInboundCallingEnabledInLocalStorage() || isExtensionsInboundCallingEnabledInApi(callingProviders));
};