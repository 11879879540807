import { getError } from 'conversations-async-data/async-data/operators/getters';
import { UNSUPPORTED_TYPE } from '../../../file-uploads/public/constants';
export const uploadingFileError = ({
  indexedAsyncFileUploads
}) => {
  const fileUploadWithError = indexedAsyncFileUploads && indexedAsyncFileUploads.find(asyncData => Boolean(getError(asyncData)));
  const fileUploadError = getError(fileUploadWithError);
  const isFileTypeError = fileUploadError && fileUploadError.message === UNSUPPORTED_TYPE;
  return fileUploadWithError ? {
    disabled: true,
    sendDisabledReason: isFileTypeError ? 'FILE_TYPE_ERROR' : 'FILE_UPLOAD_ERROR'
  } : null;
};