// gimme: conversations-inbox-ui 06/08/2020 ca5488eab7b1a4dcc47522489dc22f2341ecc7f3
'use es6';

import { Map as ImmutableMap } from 'immutable';
import { combineActions, handleActions } from 'redux-actions';
import set from 'transmute/set';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import omit from 'transmute/omit';
import { getSender } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { THREAD_ID_CHANGED } from 'conversations-thread-data/thread-details/public/constants';
import { HISTORY_MESSAGE_RECEIVED, TYPING_MESSAGE_RECEIVED, TYPING_MESSAGE_EXPIRED } from 'conversations-thread-data/thread-view-realtime/public/constants/actionTypes';
const initialState = ImmutableMap();

/**
 * @description A simple reducer decorator that clears existing
 * timeouts for actions that remove or overwrite existing indicators.
 * Separated out to keep side-effect logic out of the core reducer impl.
 * @param {Function} reducer the typing indicators reducer
 */
const timeoutDecorator = reducer => (state, action) => {
  switch (action.type) {
    case TYPING_MESSAGE_RECEIVED:
    case HISTORY_MESSAGE_RECEIVED:
    case TYPING_MESSAGE_EXPIRED:
      {
        const {
          message
        } = action.payload;
        const sender = getSender(message);
        const existingTimeoutId = getIn([sender, 'timeoutId'], state);
        if (typeof existingTimeoutId === 'number') {
          clearTimeout(existingTimeoutId);
        }
        break;
      }
    case THREAD_ID_CHANGED:
      {
        state.valueSeq().forEach(entry => {
          const timeoutId = get('timeoutId', entry);
          if (typeof timeoutId === 'number') {
            clearTimeout(timeoutId);
          }
        });
        break;
      }
    default:
      break;
  }
  return reducer(state, action);
};
const typingIndicators = handleActions({
  [TYPING_MESSAGE_RECEIVED]: (state, action) => {
    const {
      message,
      timeoutId
    } = action.payload;
    const sender = getSender(message);
    return set(sender, ImmutableMap({
      message,
      timeoutId
    }), state);
  },
  [combineActions(HISTORY_MESSAGE_RECEIVED, TYPING_MESSAGE_EXPIRED)]: (state, action) => {
    const {
      message
    } = action.payload;
    const sender = getSender(message);
    return omit([sender], state);
  },
  [THREAD_ID_CHANGED]: () => {
    return initialState;
  }
}, initialState);
export default timeoutDecorator(typingIndicators);