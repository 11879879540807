import { areEmailAddressesEqual

// TODO: move to composer-data
} from 'conversations-thread-data/connected-accounts/public';
// @ts-expect-error module not typed
import { getEmail } from 'conversations-internal-schema/connected-email-account/operators/connectedEmailAccountGetters';
export const findAccountByEmail = (accounts, lookupEmail) => {
  if (accounts && lookupEmail) {
    return accounts.find(account => areEmailAddressesEqual(getEmail(account), lookupEmail));
  }
  return null;
};