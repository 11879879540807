/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { useCallback, useEffect, useRef } from 'react';
import produce from 'immer';
import { logNetworkError } from 'calling-error-reporting/report/error';
import { createTimeoutPromise } from '../../utils/timeoutPromise';
import { useAsyncData } from '../../utils/hooks/useAsyncData';
import { AsyncStatusType } from '../../utils/types/AsyncData';
import { fetchCallsV1SettingsUsage } from '../client/fetchCallsV1SettingsUsage';
import { getMinutesUsage, getNumbersUsage } from '../operators/CallsV1SettingsUsageGetters';
const getCallsV1SettingsUsage = () => createTimeoutPromise(fetchCallsV1SettingsUsage(), 600).then(rawData => produce({}, () => rawData)).catch(networkError => {
  const error = new Error('Failed to fetch calls v1 settings usage');
  logNetworkError({
    error,
    networkError,
    tags: {
      requestName: 'calls-v1-settings-usage'
    }
  });
  throw networkError;
});
const DEFAULT_CALLS_V1_SETTINGS_USAGE = {
  hubspotPurchasedPhoneNumberUsage: {
    totalLimit: 0,
    usage: 0,
    portalUsageUnderLimit: true
  },
  minutesUsage: {
    totalLimit: 0,
    usage: 0,
    portalUsageUnderLimit: true,
    graceLimit: 0,
    usageResetTime: ''
  }
};

/**
 * A hook providing minutes and numbers usage data from /calling-orchestration/v1/settings/usage
 * and several usage components that consume the data.
 */
export const useHubSpotCallingUsage = ({
  hasCallingMinutes,
  userHasPaidSeat
}) => {
  const {
    data,
    status,
    updateAsyncData
  } = useAsyncData(DEFAULT_CALLS_V1_SETTINGS_USAGE);
  const isMounted = useRef(false);
  const fetchData = useCallback(() => {
    updateAsyncData({
      status: AsyncStatusType.STARTED
    });
    return getCallsV1SettingsUsage().then(result => {
      if (isMounted.current) {
        updateAsyncData({
          data: result,
          status: AsyncStatusType.SUCCEEDED
        });
      }
      return result;
    }).catch(() => {
      if (isMounted.current) {
        updateAsyncData({
          status: AsyncStatusType.FAILED
        });
      }
    });
  }, [updateAsyncData]);
  useEffect(() => {
    isMounted.current = true;
    if (hasCallingMinutes && status === AsyncStatusType.UNINITIALIZED && userHasPaidSeat) {
      fetchData();
    }
    return () => {
      isMounted.current = false;
    };
  }, [hasCallingMinutes, userHasPaidSeat, fetchData, status]);
  return {
    minutesUsage: getMinutesUsage(data),
    numbersUsage: getNumbersUsage(data),
    refetchHubSpotCallingUsage: fetchData
  };
};