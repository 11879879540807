'use es6';

import { localStorage } from 'conversations-platform/localStorage';
import { Map as ImmutableMap } from 'immutable';
import reduce from 'transmute/reduce';
import { getReplyId, getTimestamp, getVersion } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
import { setReplyId } from 'conversations-thread-data/common-reply/public/operators/commonReplySetters';
import CommonReply from 'conversations-thread-data/common-reply/public/records/CommonReply';
import { getDraftKeyMatches } from '../operators/getDraftKeyMatches';
import { clearCommonReplyDraft } from './clearCommonReplyDraft';
import { restoreCommonReplyFromDraft } from './restoreCommonReplyFromDraft';
export const getMatches = () => {
  try {
    return localStorage ? getDraftKeyMatches(localStorage) : [];
  } catch (error) {
    return [];
  }
};

/**
 * @description Restores CommonReplies from local storage, hydrating
 * any missing replyIds using the key, and omitting and deleting any
 * replies that are older than the passed age limit.
 * @param {object} config
 * @param {number} config.ageLimitInMs the maximum age of a draft
 * before it should be pruned from localstorage.
 * @returns {Map} An immutable map of restored common reply records
 * keyed by the replyId.
 */
export const getPrunedRepliesFromDrafts = ({
  ageLimitInMs = Date.now()
}) => {
  const matches = getMatches();
  const restoredByDraftId = reduce({}, (accumulator, match) => {
    const [localStorageKey, replyId] = match;
    let restored = restoreCommonReplyFromDraft(localStorageKey);
    if (!restored) {
      return accumulator;
    }
    if (!getReplyId(restored)) {
      restored = setReplyId(replyId, restored);
    }
    const expired = getTimestamp(restored) < Date.now() - ageLimitInMs;
    const deprecated = getVersion(restored) !== CommonReply.version;
    // Prune expired drafts from the store
    if (expired || deprecated) {
      clearCommonReplyDraft(localStorageKey);
      return accumulator;
    }
    return Object.assign({}, accumulator, {
      [getReplyId(restored)]: restored
    });
  }, matches);
  return ImmutableMap(restoredByDraftId);
};