'use es6';

import { useCallback } from 'react';
import { FETCH_UNIVERSAL_SIDEBAR } from 'customer-data-sidebar/universal/queries';
import { ObjectTypesToIds } from 'customer-data-objects/constants/ObjectTypeIds';

// helper to remove custom object chicklets from the Apollo cache
// after the objects have been deleted. Does not use a mutation.
export const useDeleteCrmObjectFromCache = ({
  cardLocation,
  fromObjectType,
  fromObjectId,
  toObjectType,
  toObjectId
}) => {
  return useCallback(client => {
    const queryVariables = {
      objectType: fromObjectType,
      subjectId: +fromObjectId,
      cardLocation
    };

    // if an object is deleted from the preview sidebar,
    // we need to delete it from the universal sidebar cache of the
    // object it's associated with. Using QueryContext will
    // attempt to delete from the inner preview query.
    try {
      const cachedCardsData = client.readQuery({
        query: FETCH_UNIVERSAL_SIDEBAR,
        variables: queryVariables
      });
      const toObjectTypeId = ObjectTypesToIds[toObjectType] || toObjectType;
      const associationCard = cachedCardsData.crmObject.cards.find(card => !!card.configuration.associationDefinitions && card.configuration.associationDefinitions[0].toObjectTypeId === toObjectTypeId);
      if (!associationCard) {
        return;
      }
      const primaryEdge = associationCard.associatedObjects.primaryEdge;
      const updatedAssociationCard = Object.assign({}, associationCard, {
        associatedObjects: Object.assign({}, associationCard.associatedObjects, {
          primaryEdge: primaryEdge && primaryEdge.node.id !== toObjectId ? primaryEdge : null,
          secondaryEdges: associationCard.associatedObjects.secondaryEdges.filter(edge => edge.node.id !== toObjectId)
        }),
        count: Object.assign({}, associationCard.count, {
          edges: associationCard.count.edges.filter(edge => edge.node.id !== toObjectId)
        })
      });
      const updatedCards = cachedCardsData.crmObject.cards.map(card => card === associationCard ? updatedAssociationCard : card);
      client.writeQuery({
        query: FETCH_UNIVERSAL_SIDEBAR,
        variables: queryVariables,
        data: Object.assign({}, cachedCardsData, {
          crmObject: Object.assign({}, cachedCardsData.crmObject, {
            cards: updatedCards
          })
        })
      });
    } catch (e) {
      // if the right sidebar query doesn't exist, do nothing
      // as there's nothing to remove from the cache
    }
  }, [fromObjectType, fromObjectId, cardLocation, toObjectType, toObjectId]);
};