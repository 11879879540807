'use es6';

import { gql } from '@apollo/client';
export const CrmExtensionCardFragment = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"CrmExtensionCardFragment\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"CrmExtensionCard\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"crmExtensionItem\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"appId\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"appName\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"title\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"iconUri\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"singleColorIconUri\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"targetFunction\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"extensionArtifactUrl\"}}]}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "CrmExtensionCardFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "CrmExtensionCard"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "crmExtensionItem"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "appId"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "appName"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "iconUri"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "singleColorIconUri"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "targetFunction"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "extensionArtifactUrl"
            }
          }]
        }
      }]
    }
  }]
});