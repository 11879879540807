import PortalIdParser from 'PortalIdParser';
const IFRAME_HOST_LOCAL_STORAGE_KEY = 'quotes-admin-iframe-host-url';
export function getHostUrl() {
  const host = window.location.host;
  const hostUrl = host.includes('local') ? host.replace('local', 'app') : host;
  try {
    const localOverride = localStorage.getItem(IFRAME_HOST_LOCAL_STORAGE_KEY);
    return localOverride || hostUrl;
  } catch (e) {
    return hostUrl;
  }
}
export function getPathname() {
  return `/quotes/${PortalIdParser.get()}`;
}
export function getIframeSrc() {
  return `https://${getHostUrl()}${getPathname()}/embedded`;
}