import { Map as ImmutableMap } from 'immutable';
import { createSelector } from 'reselect';
import { getScopes } from './getScopes';
export const getScopesAsMap = createSelector(getScopes, scopes => {
  const scopesAsMap = ImmutableMap();
  if (scopes) {
    return scopes.reduce((accumulator, scope) => {
      return accumulator.set(scope, scope);
    }, scopesAsMap);
  }
  return scopesAsMap;
});