import { fromJS, List, Map as ImmutableMap, Record } from 'immutable';
import AccountManager from './AccountManager';
import ContractManager from './ContractManager';
import EscalationReason from './EscalationReason';
import { ACCOUNT_MANAGER, CONTRACT_MANAGER, DELIVERABILITY, PRIVACY } from '../constants/EscalationType';
const defaultEscalationMetaProps = {
  accountManager: undefined,
  contractManager: undefined,
  customerEmail: '',
  escalationReasonsByType: ImmutableMap(),
  isStarterPortal: false
};
class EscalationMeta extends Record(defaultEscalationMetaProps, 'EscalationMeta') {
  get accountManagerEscalationReasons() {
    return this.escalationReasonsByType.get(ACCOUNT_MANAGER, List());
  }
  get contractManagerEscalationReasons() {
    return this.escalationReasonsByType.get(CONTRACT_MANAGER, List());
  }
  get deliverabilityEscalationReasons() {
    return this.escalationReasonsByType.get(DELIVERABILITY, List());
  }
  get privacyEscalationReasons() {
    return this.escalationReasonsByType.get(PRIVACY, List());
  }
}
EscalationMeta.fromJS = obj => new EscalationMeta({
  accountManager: obj.accountManager ? AccountManager.fromJS(obj.accountManager) : null,
  contractManager: obj.contractManager ? ContractManager.fromJS(obj.contractManager) : null,
  escalationReasonsByType: fromJS(obj.escalationReasonsByType).map(value => value.map(reason => EscalationReason.fromJS(reason.toJS())).sortBy(reason => reason.description)),
  isStarterPortal: obj.isStarterPortal
});
export default EscalationMeta;