'use es6';

import getIn from 'transmute/getIn';
// @ts-expect-error module not typed
import { getCallProviders } from 'calling-lifecycle-internal/call-provider/operators/getCallProvidersFromOmnibus';
import { getInstalledCallingProviders } from 'calling-integration-sdk-support/external-provider/operators/getInstalledCallingProviders';
export const getInstalledCallingSettings = getIn(['installedCallingSettings']);
export const getInstalledCallingApplications = getIn(['installedCallingSettings', 'installedCallingApplications']);
export const getPhoneNumbers = getIn(['phoneNumbers']);
export const getHubspotSubAccountNumbers = getIn(['HUBSPOT_SUBACCOUNT']);
export const getConnectAccountNumbers = getIn(['CONNECT_ACCOUNT']);
export const getHubspotProvidedNumbers = getIn(['HUBSPOT_PORTAL_SUBACCOUNT']);
export const getHubSpotCallingEnabled = getIn(['callingAdminSettings', 'hubSpotCallingEnabled']);

// omnibusData url: /twilio/v1/callees/omnibus/${objectTypeId}/${objectId}
export function getCallProvidersFromOmnibusResult(omnibusData) {
  const installedCallingApplications = getInstalledCallingApplications(omnibusData);
  const fromNumbers = getPhoneNumbers(omnibusData);
  const connectAccountNumbers = getConnectAccountNumbers(fromNumbers);
  const hasTwilioConnect = connectAccountNumbers && connectAccountNumbers.size > 0;
  const installedCallingProviders = getInstalledCallingProviders({
    installedCallingApplications,
    isAircallInstalled: false
  });
  const callingProviders = getCallProviders({
    hasTwilioConnect
  }).concat(installedCallingProviders);
  return callingProviders;
}