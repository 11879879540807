'use es6';

import { getId, getEmail } from './salesUsersGetters';
import { getIsRoutable, getIsUserInPortal, getCanViewInbox } from './userGetters';
import { isErroredRoutingUser } from './isErroredRoutingUser';
import getText from './getText';
import { Iterable } from 'immutable';
export const toOptionWithHiddenDeletedUser = user => {
  if (Iterable.isIterable(user)) {
    return {
      text: getText(user),
      value: getId(user),
      email: getEmail(user),
      disabled: !getIsRoutable(user),
      isFreeUser: !getIsRoutable(user),
      routable: getIsRoutable(user),
      userInPortal: getIsUserInPortal(user),
      canViewInbox: getCanViewInbox(user),
      hidden: !getIsUserInPortal(user) || !getCanViewInbox(user),
      tagUse: isErroredRoutingUser(user) ? 'error' : 'default'
    };
  }
  return {
    text: getText(user),
    value: user.id,
    email: user.email,
    disabled: !user.routable,
    isFreeUser: !user.routable,
    routable: user.routable,
    userInPortal: user.userInPortal,
    canViewInbox: user.canViewInbox,
    hidden: !user.userInPortal || !user.canViewInbox,
    tagUse: isErroredRoutingUser(user) ? 'error' : 'default'
  };
};