import { EditorState } from 'prosemirror-state';
import { schema } from 'hubspot-prosemirror/prosemirror/ProsemirrorSchema';
import { List, Record } from 'immutable';
import { getCMFSyncedReply } from '../../_internal/operators/getCMFSyncedReply';

/**
 * When changing this record or any of the related metadata record,
 * make sure to change the version so that old draft versions are
 * not used.
 */

const VERSION = 14;
export const CommonReplyRecord = Record({
  attachments: List(),
  // This is tightly coupled to how we upload/render attachments. It does get converted to CommonMessage#attachments but is not necessarily 1:1 with that field.
  commonMessage: null,
  editorState: EditorState.create({
    schema
  }),
  version: VERSION,
  // used when restored from a draft
  metadata: null,
  timestamp: null,
  // used when restored from a draft
  replyId: null,
  vid: null // used to set up recipients metadata
}, 'CommonReplyRecord');
function CommonReply(options = {}) {
  const baseRecord = CommonReplyRecord(options);
  return baseRecord.commonMessage ? baseRecord : getCMFSyncedReply(baseRecord);
}
Object.assign(CommonReply, CommonReplyRecord);
export default CommonReply;
CommonReply.version = VERSION;