import getIn from 'transmute/getIn';
import { REPLY_RECOMMENDATIONS_HISTORY } from '../constants/keyPaths';
// @ts-expect-error module not typed
import { getId } from '../../common-message-format/operators/commonMessageFormatGetters';
import { isMessagePublished } from '../../common-message-format/operators/isMessagePublished';
// @ts-expect-error module not typed
import { isCommonMessageFormat } from '../../common-message-format/operators/cmfComparators';
// @ts-expect-error module not typed
import { getMessages } from '../../thread-history/operators/getters';
// @ts-expect-error module not typed

export const getReplyRecommendationsHistory = getIn(REPLY_RECOMMENDATIONS_HISTORY);
export const getReplyRecommendationBody = recommendation => {
  const {
    text
  } = recommendation || {};
  return text || '';
};

// this will be removed after all dependants use getCurrentReplyRecommendationObject
export const getCurrentReplyRecommendation = ({
  replyRecommendationsHistory,
  lastConversationalMessage
}) => {
  const lastConversationalMessageId = getId(lastConversationalMessage);
  const [matchedReplyRecommendation] = replyRecommendationsHistory[lastConversationalMessageId] || [];
  if (matchedReplyRecommendation) {
    return getReplyRecommendationBody(matchedReplyRecommendation);
  }
  return '';
};
export const getCurrentReplyRecommendationObject = ({
  replyRecommendationsHistory,
  lastConversationalMessage
}) => {
  const lastConversationalMessageId = getId(lastConversationalMessage);
  const [matchedReplyRecommendation] = replyRecommendationsHistory[lastConversationalMessageId] || [];

  // matchedReplyRecommendation can be undefined here (despite ts not realizing), so return null instead
  if (matchedReplyRecommendation) return matchedReplyRecommendation;
  return null;
};
export const getLastConversationalMessage = threadHistory => {
  const messages = threadHistory && getMessages(threadHistory);
  const lastConversationalMessage = messages ? messages.findLast(message => {
    return isMessagePublished(message) && isCommonMessageFormat(message);
  }) : {};
  return lastConversationalMessage;
};

// TODO: remove once consumers switch to identical `getCurrentReplyRecommendation`
export const getCurrentReplyRec = ({
  replyRecommendationsHistory,
  lastConversationalMessage
}) => {
  const lastConversationalMessageId = getId(lastConversationalMessage);
  const [matchedReplyRecommendations] = replyRecommendationsHistory[lastConversationalMessageId] || [];
  if (matchedReplyRecommendations) {
    const {
      text: replyBody
    } = matchedReplyRecommendations || {};
    return replyBody;
  }
  return '';
};