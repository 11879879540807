import { useEffect, useState, useCallback } from 'react';
import { isHidden } from 'conversations-visibility-tools/visibility/isHidden';
import { addVisibilityChangeListener } from 'conversations-visibility-tools/visibility/addVisibilityChangeListener';
import { removeVisibilityChangeListener } from 'conversations-visibility-tools/visibility/removeVisibilityChangeListener';
export const useWindowVisibility = () => {
  const [isAppInForeground, setIsAppInForeground] = useState(!isHidden());
  const visibilityChangeListener = useCallback(({
    isVisible
  }) => {
    setIsAppInForeground(isVisible);
  }, [setIsAppInForeground]);
  useEffect(() => {
    addVisibilityChangeListener(visibilityChangeListener);
    return () => {
      removeVisibilityChangeListener(visibilityChangeListener);
    };
  }, [visibilityChangeListener]);
  return isAppInForeground;
};