import { List } from 'immutable';
import Inbox from 'conversations-internal-schema/inbox/records/Inbox';
import { SUCCEEDED } from 'conversations-internal-schema/constants/RequestStatusTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { getInboxId } from 'conversations-internal-schema/inbox/operators/getInboxId';
import { getInboxes, getCurrentInboxId } from './omnibusGetters';
export const buildUnifiedInboxFromOmnibus = omnibus => {
  const inboxes = List(getInboxes(omnibus).map(inbox => new Inbox(inbox)));
  const currentInbox = inboxes.find(inbox => `${getInboxId(inbox)}` === `${getCurrentInboxId(omnibus)}`);
  return {
    inboxRequestStatus: SUCCEEDED,
    inboxes,
    currentInbox,
    lastAccessedInboxId: getCurrentInboxId(omnibus)
  };
};