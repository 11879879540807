import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isInSearchAndFilterMode, getPreviouslyFocusedThreadId } from 'conversations-inbox-lib/thread-search-and-filtering/public/selectors';
import { clearPreviouslyFocusedThreadId } from 'conversations-inbox-lib/thread-search-and-filtering/public/actions';
import { getThreadId } from 'find-and-filter-data/view-members-schema/protected';
/**
 * @description This hook will automatically navigate to the first threadListMember
 * whenever threadListMembers are cleared and fetched, as long as it's not already
 * focused.
 */
export const useAutoSelectThreadListMember = ({
  focusedThreadId,
  isLoadingThreadListMembers,
  navigateToThread,
  threadListMembers
}) => {
  /**
   * We use loading state in this hook instead of threadListMembers.size because we
   * want to avoid scenarios where an empty thread list receives a new thread and
   * the new thread is auto selected - only using count would select the new thread.
   */
  const prevLoadingThreadListMembers = useRef(isLoadingThreadListMembers);
  const dispatch = useDispatch();
  const inSearchAndFilterMode = useSelector(isInSearchAndFilterMode);
  const previouslyFocusedThreadId = useSelector(getPreviouslyFocusedThreadId);
  const canAutoSelectThread = threadListMembers.size > 0 && !inSearchAndFilterMode;
  useEffect(() => {
    // previously we were loading members, now we are done
    const doneFetchingThreadListMembers = prevLoadingThreadListMembers.current === true && isLoadingThreadListMembers === false;
    if (canAutoSelectThread && doneFetchingThreadListMembers) {
      const threadId = previouslyFocusedThreadId || getThreadId(threadListMembers.first());
      if (!focusedThreadId) {
        navigateToThread({
          threadId
        });
        dispatch(clearPreviouslyFocusedThreadId());
      }
    }
  }, [canAutoSelectThread, dispatch, focusedThreadId, isLoadingThreadListMembers, navigateToThread, previouslyFocusedThreadId, threadListMembers]);
  useEffect(() => {
    prevLoadingThreadListMembers.current = isLoadingThreadListMembers;
  }, [isLoadingThreadListMembers]);
};