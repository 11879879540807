import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { updateEntries } from 'conversations-async-data/indexed-async-data/operators/updateEntries';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { Set as ImmutableSet, List } from 'immutable';
import { handleActions } from 'redux-actions';
import { FETCH_CHANNEL_INSTANCES, FETCH_CHANNEL_INSTANCE_BY_ID } from '../constants/asyncActionTypes';
const initialState = {
  currentlyFetchingInstanceIds: ImmutableSet(),
  failedInstanceIds: ImmutableSet(),
  instancesByInboxId: new IndexedAsyncData({
    name: 'channelInstances',
    notSetValue: new AsyncData({
      data: null
    })
  })
};
const reducerMap = {
  [FETCH_CHANNEL_INSTANCES.STARTED](state, action) {
    const {
      requestArgs
    } = action.payload;
    const {
      inboxIds
    } = requestArgs;
    return Object.assign({}, state, {
      instancesByInboxId: updateEntries(inboxIds, (__inboxId, asyncData) => requestStarted(asyncData), state.instancesByInboxId)
    });
  },
  [FETCH_CHANNEL_INSTANCES.FAILED](state, action) {
    const {
      requestArgs
    } = action.payload;
    const {
      inboxIds
    } = requestArgs;
    return Object.assign({}, state, {
      instancesByInboxId: updateEntries(inboxIds, (__inboxId, asyncData) => requestFailed(asyncData), state.instancesByInboxId)
    });
  },
  [FETCH_CHANNEL_INSTANCES.SUCCEEDED](state, action) {
    const {
      data,
      requestArgs
    } = action.payload;
    const {
      inboxIds
    } = requestArgs;
    return Object.assign({}, state, {
      instancesByInboxId: updateEntries(inboxIds, (__inboxId, asyncData) => requestSucceededWithOperator(() => data, asyncData), state.instancesByInboxId)
    });
  },
  [FETCH_CHANNEL_INSTANCE_BY_ID.STARTED](state, action) {
    const {
      requestArgs
    } = action.payload;
    const {
      channelInstanceId
    } = requestArgs;
    return Object.assign({}, state, {
      currentlyFetchingInstanceIds: state.currentlyFetchingInstanceIds.add(channelInstanceId)
    });
  },
  [FETCH_CHANNEL_INSTANCE_BY_ID.FAILED]({
    currentlyFetchingInstanceIds,
    failedInstanceIds,
    instancesByInboxId
  }, action) {
    const {
      requestArgs
    } = action.payload;
    const {
      channelInstanceId
    } = requestArgs;
    return {
      instancesByInboxId,
      failedInstanceIds: failedInstanceIds.add(channelInstanceId),
      currentlyFetchingInstanceIds: currentlyFetchingInstanceIds.delete(channelInstanceId)
    };
  },
  [FETCH_CHANNEL_INSTANCE_BY_ID.SUCCEEDED]({
    currentlyFetchingInstanceIds,
    failedInstanceIds,
    instancesByInboxId
  }, {
    payload: {
      requestArgs,
      data
    }
  }) {
    const instancesStillFetching = currentlyFetchingInstanceIds.remove(requestArgs.channelInstanceId);
    const {
      id,
      inboxId
    } = data;
    if (!id || !inboxId) {
      return {
        failedInstanceIds: failedInstanceIds.add(requestArgs.channelInstanceId),
        currentlyFetchingInstanceIds: instancesStillFetching,
        instancesByInboxId
      };
    }
    const addInstanceToExistingList = instances => {
      if (instances) {
        const isDataInList = instances.some(instance => instance.id === data.id);
        return isDataInList ? instances : instances.push(data);
      }
      return List([data]);
    };
    const result = updateEntry(`${inboxId}`, requestSucceededWithOperator(addInstanceToExistingList), instancesByInboxId);
    return {
      failedInstanceIds: failedInstanceIds.remove(data.id),
      instancesByInboxId: result,
      currentlyFetchingInstanceIds: instancesStillFetching
    };
  }
};
const channelInstances = handleActions(reducerMap, initialState);
export { channelInstances };