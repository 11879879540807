// @ts-expect-error module not typed
import { setSender } from 'conversations-message-history/common-message-format/operators/commonMessageFormatSetters';
import { getAgentToVisitorChannelName, getThreadId } from 'conversations-thread-data/thread-details/public/operators';
import { publishMessageWithErrorHandling } from '../../public/actions/publishMessageWithErrorHandling';
// @ts-expect-error module not typed
import { getFocusedThreadDetails } from '../../../thread-details/selectors/getFocusedThreadDetails';
import { splitCMFByAttachments } from '../operators/splitCMFByAttachments';
// @ts-expect-error module not typed
import { getCMFSender } from '../selectors/getCMFSender';
// @ts-expect-error module not typed
import { updateCMFSenders } from '../selectors/updateCMFSenders';
export const publishCommonMessage = (commonMessage, {
  splitAttachments,
  options
}) => (dispatch, getState) => {
  const state = getState();
  const threadDetails = getFocusedThreadDetails(state);
  const sender = getCMFSender(state);
  const updatedMessage = updateCMFSenders(state, {
    message: commonMessage
  });
  const updatedMessageWithSender = setSender(sender, updatedMessage);
  const messages = splitAttachments ? splitCMFByAttachments(updatedMessageWithSender) : [updatedMessageWithSender];
  messages.forEach(message => {
    dispatch(publishMessageWithErrorHandling({
      /*  @ts-expect-error Jun-5-2024, 19:34UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
      channel: getAgentToVisitorChannelName(threadDetails),
      message,
      /*  @ts-expect-error Jun-5-2024, 19:34UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
      threadId: getThreadId(threadDetails),
      options
    }));
  });
};