import quickFetch from 'quick-fetch';
export const getQuickFetchOrMakeRequest = (name, makeRequest) => {
  const earlyMetaRequest = quickFetch.getRequestStateByName(name);
  if (earlyMetaRequest) {
    return new Promise((resolve, reject) => {
      earlyMetaRequest.whenFinished(result => {
        resolve(result);
        quickFetch.removeEarlyRequest(name);
      });
      earlyMetaRequest.onError(() => {
        // retry the request
        makeRequest().then(resolve).catch(reject);
        quickFetch.removeEarlyRequest(name);
      });
    });
  } else {
    return makeRequest();
  }
};