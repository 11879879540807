'use es6';

export const fixImages = ({
  node,
  node_name
}) => {
  if (node && node_name === 'img' && node.attributes) {
    if (node.attributes['data-original-height']) {
      node.setAttribute('height', node.attributes['data-original-height'].value);
    }
    if (node.attributes['data-original-width']) {
      node.setAttribute('width', node.attributes['data-original-width'].value);
    }
    if (node.attributes['data-full-size-src']) {
      node.setAttribute('src', node.attributes['data-full-size-src'].value);
    }
    return {
      node
    };
  }
  return null;
};