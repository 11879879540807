import PortalIdParser from 'PortalIdParser';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import ResolvedAttachmentRecord from 'conversations-internal-schema/resolved-attachment/records/ResolvedAttachmentRecord';
import { getFullUrl } from 'hubspot-url-utils';
import { Map as ImmutableMap } from 'immutable';
import { getFileAttachments } from '../clients/getFileAttachments';
import { FILE_ATTACHMENTS_FETCH } from '../constants/asyncActionTypes';
export const fetchFileAttachments = createAsyncAction({
  requestFn: getFileAttachments,
  actionTypes: FILE_ATTACHMENTS_FETCH,
  toRecordFn: response => response.reduce((acc, attachment) => {
    var _attachment$meta;
    const isSensitive = attachment === null || attachment === void 0 || (_attachment$meta = attachment.meta) === null || _attachment$meta === void 0 ? void 0 : _attachment$meta.sensitive;
    const url = isSensitive ? `${getFullUrl('api')}/filemanager/api/v2/files/${attachment.id}/signed-url-redirect?portalId=${PortalIdParser.get()}` : attachment.url;
    return acc.set(`${attachment.id}`, new ResolvedAttachmentRecord({
      fileId: attachment.id,
      fileMetadata: {
        expiresAt: -1,
        extension: attachment.extension,
        height: attachment.height,
        name: attachment.name,
        size: attachment.size,
        type: attachment.type,
        url,
        width: attachment.width
      }
    }));
  }, ImmutableMap())
});