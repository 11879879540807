import { getPresentVisitorLocators } from '../selectors/getPresentVisitorLocators';
import { useSelector } from 'react-redux';
import { isSenderPresent } from 'conversations-message-history/senders/operators/isSenderPresent';
import { VISITOR } from 'conversations-message-history/common-message-format/constants/legacySenderTypes';
export const useVisitorIsPresent = ({
  threadId,
  vid
}) => {
  const presentSenderLocators = useSelector(state => getPresentVisitorLocators(state, {
    threadId
  }));
  const visitorIsPresent = Boolean(vid && isSenderPresent(vid, VISITOR, presentSenderLocators));
  return {
    visitorIsPresent
  };
};