// TODO: move to composer-data

// TODO: move to composer-data
import { isEmailInvalid } from 'conversations-thread-data/common-reply/public/operators/isEmailInvalid';
// TODO: move to composer-data
import invariant from 'conversations-thread-data/utils/invariant';
export const invalidEmails = ({
  reply
}) => {
  invariant(Boolean(reply), 'reply is required for checking for invalid emails');
  return isEmailInvalid(reply) ? {
    disabled: true,
    sendDisabledReason: 'INVALID_EMAIL_ADDRESS'
  } : null;
};