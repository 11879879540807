import { fromJS, Record, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import ExternalProperty from './ExternalProperty';
class TicketExternalProperties extends Record({
  productAreas: ImmutableSet(),
  resolutions: ImmutableSet(),
  roadblocks: ImmutableSet(),
  productAreaToRoadblocksMapping: ImmutableMap()
}) {}
TicketExternalProperties.fromJS = ({
  productAreas,
  resolutions,
  roadblocks,
  productAreaToRoadblocksMapping
}) => new TicketExternalProperties({
  productAreas: productAreas ? ImmutableSet(productAreas.map(ExternalProperty.fromJS)) : ImmutableSet(),
  resolutions: resolutions ? ImmutableSet(resolutions.map(ExternalProperty.fromJS)) : ImmutableSet(),
  roadblocks: roadblocks ? ImmutableSet(roadblocks.map(ExternalProperty.fromJS)) : ImmutableSet(),
  productAreaToRoadblocksMapping: fromJS(productAreaToRoadblocksMapping)
});
export default TicketExternalProperties;