import { useQuery } from 'data-fetching-client';
import { useSelector } from 'react-redux';
import { LIVE_CHAT_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
import { getThreadListMemberByThreadId } from '../../thread-list-member/selectors/getThreadListMemberByThreadId';
import { getCurrentInboxId } from 'conversations-inbox-lib/unified-inbox/public/selectors';
import { hasTaxonomyWriteAccess } from '../selectors/hasTaxonomyWriteAccess';
import { GET_ZORSE_SUPPORT_INBOXES } from '../queries/useTaxonomyFlowQueries';
import { getGenericChannel } from 'find-and-filter-data/view-members-schema/protected';
export function useTaxonomyFlow(threadId) {
  const inboxId = useSelector(getCurrentInboxId);
  const hasScope = useSelector(hasTaxonomyWriteAccess);
  const threadListMember = useSelector(state => getThreadListMemberByThreadId(state, {
    threadId
  }));
  const source = getGenericChannel(threadListMember);
  const {
    data,
    loading,
    error
  } = useQuery(GET_ZORSE_SUPPORT_INBOXES, {
    skip: !hasScope
  });
  const iscInboxId = data && data.zorseSupportInboxes.iscInboxId;
  return {
    error,
    loading,
    usesTaxonomyFlow: hasScope && inboxId === iscInboxId && source === LIVE_CHAT_GENERIC_CHANNEL_ID
  };
}