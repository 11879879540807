import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { stringIdInvariant } from 'conversations-async-data/indexed-async-data/invariants/stringIdInvariant';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { FETCH_PREVIOUS_MESSAGE_HISTORY } from '../constants/asyncActionTypes';
import { updateEntries } from 'conversations-async-data/indexed-async-data/operators/updateEntries';
import { limitToCount } from 'conversations-async-data/indexed-async-data/eviction-strategies/limitToCount';
import { PREVIOUS_MESSAGE_HISTORY_DATA_CACHE_SIZE } from '../../../common/public/constants/CacheSize';
const initialState = new IndexedAsyncData({
  name: 'previousMessageHistories',
  evict: limitToCount(PREVIOUS_MESSAGE_HISTORY_DATA_CACHE_SIZE),
  idInvariant: stringIdInvariant,
  notSetValue: new AsyncData({
    data: null
  })
});
const reducerMap = {
  [FETCH_PREVIOUS_MESSAGE_HISTORY.STARTED](state, action) {
    const {
      requestArgs
    } = action.payload;
    const {
      threadIds
    } = requestArgs;
    return updateEntries(threadIds, (__threadId, asyncData) => requestStarted(asyncData), state);
  },
  [FETCH_PREVIOUS_MESSAGE_HISTORY.FAILED](state, action) {
    const {
      requestArgs
    } = action.payload;
    const {
      threadIds
    } = requestArgs;
    return updateEntries(threadIds, (__threadId, asyncData) => requestFailed(asyncData), state);
  },
  [FETCH_PREVIOUS_MESSAGE_HISTORY.SUCCEEDED](state, action) {
    const {
      data,
      requestArgs
    } = action.payload;
    const {
      threadIds
    } = requestArgs;
    return updateEntries(threadIds, (__threadId, asyncData) => requestSucceededWithOperator(() => data, asyncData), state);
  }
};
const previousMessageHistories = handleActions(reducerMap, initialState);
export { previousMessageHistories };