/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsViewsFetchInProgress, selectHasViewsFetchFailed, selectViews } from './viewSelectors';
import { fetchCustomViewsAndThreadlists } from './viewsSlice';

/**
 * @description `useCustomViewsAndThreadlists` is a hook to get a cached map of views for a specific
 * inbox. If views haven't been fetched yet this will fetch and cache them.
 *
 * @returns
 * `isInitializingViews` - if views are being fetched and no stale views available
 * for the given inboxId.
 *
 * `views` - a map of CustomView & ThreadLists, indexed by viewId
 *
 * NOTE: This hook will eventually be deprecated with full support of Default CustomViews,
 * as well as support for Threadlists. When this happens, useCustomViews should be the singular
 * endpoint used to fetch views
 */
export function useCustomViewsAndThreadlists(inboxId) {
  const isLoading = useSelector(selectIsViewsFetchInProgress);
  const hasFailed = useSelector(selectHasViewsFetchFailed);
  const views = useSelector(selectViews);
  const viewsSize = views.size;
  const dispatch = useDispatch();
  useEffect(() => {
    if (inboxId && !viewsSize && !isLoading) {
      dispatch(fetchCustomViewsAndThreadlists({
        inboxId
      }));
    }
  }, [dispatch, inboxId, viewsSize, isLoading]);
  return {
    isLoading,
    hasFailed,
    views
  };
}

/**
 * @description `useView` is a hook to get a specific view by the viewId for the
 * given inboxId
 */
export function useView(inboxId, viewId) {
  const {
    views
  } = useCustomViewsAndThreadlists(inboxId);
  if (!viewId) return undefined;
  return views.get(viewId);
}