import { List as ImmutableList, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { useMemo } from 'react';
import { useFileAttachments } from './useFileAttachments';
import { getFileIds } from 'conversations-message-history/common-message-format/operators/fileAttachmentGetters';
// @ts-expect-error module not typed
import { getFileAttachments } from 'conversations-message-history/common-message-format/operators/getFileAttachments';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { getFileMetadata, getFileId
// @ts-expect-error module not typed
} from 'conversations-internal-schema/resolved-attachment/operators/resolvedAttachmentGetters';
import { getName, getType, getSize, getHeight, getWidth, getExtension, getUrl
// @ts-expect-error module not typed
} from 'conversations-internal-schema/file-metadata/operators/fileMetadataGetters';
import { isLoading, isSucceeded } from 'conversations-async-data/async-data/operators/statusComparators';
const buildFileAttachment = file => {
  const fileMetaData = getFileMetadata(file);
  return ImmutableMap({
    id: parseInt(getFileId(file), 10),
    name: getName(fileMetaData),
    size: getSize(fileMetaData),
    type: getType(fileMetaData),
    height: getHeight(fileMetaData),
    width: getWidth(fileMetaData),
    extension: getExtension(fileMetaData),
    thumbnailUrl: getUrl(fileMetaData)
  });
};
export const useResolvedFileAttachments = ({
  asyncCommonMessage,
  deferred
}) => {
  const message = getData(asyncCommonMessage);
  const fileAttachmentIds = useMemo(() => {
    if (deferred) {
      return ImmutableSet();
    }
    const fileAttachments = getFileAttachments(message);
    const fIds = getFileIds(fileAttachments);
    return fIds ? ImmutableSet(fIds) : ImmutableSet();
  }, [message, deferred]);
  const {
    fileAttachments: asyncFileAttachmentsResult
  } = useFileAttachments({
    fileAttachmentIds
  });
  const isLoaded = fileAttachmentIds.size === 0 || asyncFileAttachmentsResult.count(isLoading) === 0;
  const attachments = useMemo(() => {
    if (fileAttachmentIds.size === 0 || !isLoaded) {
      return ImmutableList();
    }
    return asyncFileAttachmentsResult.filter((value, key) => {
      return fileAttachmentIds.has(typeof key === 'string' ? parseInt(key, 10) : key) && isSucceeded(value);
    }).valueSeq().map(getData).map(buildFileAttachment).toList();
  }, [asyncFileAttachmentsResult, fileAttachmentIds, isLoaded]);
  return [attachments, isLoaded];
};