import { handleActions } from 'redux-actions';
import SavingState from '../records/SavingState';
import { LOAD_ESCALATION, SAVE_ESCALATION, SUBMIT_ESCALATION } from '../actions/ActionTypes';
export default handleActions({
  [LOAD_ESCALATION]: () => new SavingState(),
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'Action<Sav... Remove this comment to see the full error message
  [SAVE_ESCALATION]: (state, {
    error,
    meta,
    payload
  }) => !error ? state.set(payload.field, meta === 'request') : state,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'Action<Sav... Remove this comment to see the full error message
  [SUBMIT_ESCALATION]: (state, {
    meta
  }) => state.set('submission', meta === 'request')
}, new SavingState());