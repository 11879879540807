import { getUpdatedInOperationType } from './getUpdatedInOperationType';
import { getViewIdFromUpdate } from './getViewIdFromUpdate';
function buildUpdate(operation, update, viewId) {
  return {
    operation,
    update,
    threadId: update && update.threadId,
    viewId
  };
}
export function buildUpdatesFromThreadListsUpdates(threadListType, updates, agentId) {
  return updates.reduce(({
    operations,
    unreadOperations
  }, update) => {
    const addedToOperations = update.addedTo.map(id => buildUpdate('ADD', update, getViewIdFromUpdate({
      id,
      threadListType
    })));
    const removedFromOperations = update.removedFrom.map(id => buildUpdate('REMOVE', update, getViewIdFromUpdate({
      id,
      threadListType
    })));
    const updatedInOperationType = getUpdatedInOperationType(update);
    const updatedInOperations = updatedInOperationType && update.updatedIn.map(id => buildUpdate(updatedInOperationType, update, getViewIdFromUpdate({
      id,
      threadListType
    })));

    // if the update has already been seen, don't track it as unread
    const isSeen = agentId && update.seenByAgentIds && update.seenByAgentIds.includes(agentId);
    // if thread is being removed, do not "track" the thread as unread
    const isThreadBeingRemoved = updatedInOperationType === 'REMOVE';
    const unreadUpdatedInOperations = !isSeen && !isThreadBeingRemoved && update.updatedIn.map(id => buildUpdate('TRACK_UNREAD', update, getViewIdFromUpdate({
      id,
      threadListType
    })));
    return {
      operations: operations.concat(addedToOperations, removedFromOperations, updatedInOperations || []),
      unreadOperations: unreadOperations.concat(unreadUpdatedInOperations || [])
    };
  }, {
    operations: [],
    unreadOperations: []
  });
}