import { generateViewId } from 'find-and-filter-data/views-schema/public';
import { getUpdatedInOperationType } from './getUpdatedInOperationType';
function buildUpdate(operation, update, viewId) {
  return {
    operation,
    update,
    objectKey: update && update.objectKey,
    viewId
  };
}
export function buildUpdatesFromInboxUpdate(updates, agentId) {
  return updates.reduce(({
    operations,
    unreadOperations
  }, update) => {
    const addedToOperations = update.addedTo.map(viewKey => buildUpdate('ADD', update, generateViewId(viewKey)));
    const removedFromOperations = update.removedFrom.map(viewKey => buildUpdate('REMOVE', update, generateViewId(viewKey)));
    const updatedInOperationType = getUpdatedInOperationType(update);
    const updatedInOperations = updatedInOperationType && update.updatedIn.map(viewKey => buildUpdate(updatedInOperationType, update, generateViewId(viewKey)));

    // if the update has already been seen, don't track it as unread
    const isSeen = agentId && update.seenByAgentIds && update.seenByAgentIds.includes(agentId);
    // if thread is being removed, do not "track" the thread as unread
    const isThreadBeingRemoved = updatedInOperationType === 'REMOVE';
    const unreadUpdatedInOperations = !isSeen && !isThreadBeingRemoved && update.updatedIn.map(viewKey => buildUpdate('TRACK_UNREAD', update, generateViewId(viewKey)));
    return {
      operations: operations.concat(addedToOperations, removedFromOperations, updatedInOperations || []),
      unreadOperations: unreadOperations.concat(unreadUpdatedInOperations || [])
    };
  }, {
    operations: [],
    unreadOperations: []
  });
}