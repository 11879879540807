import { createSentryLogger } from 'ably-hubspot-js/sentry/createSentryLogger';
import { AblyRealtimeWrapper } from 'ably-hubspot-js/ably/api-wrappers/AblyRealtimeWrapper';
import { getLogLevel } from 'ably-hubspot-js/ably/operators/getLogLevel';
import { publishVerifier } from 'conversations-internal-pub-sub/conversations-pub-sub/operators/publishVerifier';
import { buildAggregateReporter } from 'ably-hubspot-js/metrics/builders/buildAggregateReporter';
import { getToken } from '../clients/getToken';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
const ablyLogger = createSentryLogger({
  maxLines: 100,
  sentryDataKey: 'ablyLogs'
});
const getReporter = (() => {
  let reporter = null;
  return () => {
    if (reporter) return reporter;
    reporter = buildAggregateReporter();
    reporter.start();
    return reporter;
  };
})();
const resolveRealtime = () => {
  return import( /* webpackChunkName: "conversations-ably-realtime" */
  'ably-hubspot-js').then(Ably => Ably.default.Realtime);
};

// Default values for disconnected/suspended retry are 15s and 30s
// Add 10s variation to avoid client reconnects storming the ably servers
const variation = Math.floor(Math.random() * 10) + 1;
const disconnectedRetryTimeout = (10 + variation) * 1000; //  10-20 seconds
const suspendedRetryTimeout = (20 + variation) * 1000; // 20-30 seconds

/**
 * @description returns an Ably Vendor that can be used by the ConversationsPubSub
 * client. Internally, this will only ever create a single instance of
 * the ably realtime vendor within the conversations-inbox application.
 */
export const resolveVendor = (() => {
  let vendor;
  return async getCurrentInboxId => {
    if (!vendor) {
      const reporter = getReporter();
      const clientOptions = {
        authCallback: (__tokenParams, callback) => {
          const inboxId = getCurrentInboxId();
          getToken(inboxId).then(tokenResponse => callback(null, tokenResponse), error => callback(error)).catch(error => {
            reportError({
              error
            });
            callback(error);
          });
        },
        disconnectedRetryTimeout,
        suspendedRetryTimeout
      };
      const AblyRealtime = await resolveRealtime();
      const realtime = new AblyRealtime(Object.assign({}, clientOptions, {
        autoConnect: false,
        log: {
          level: getLogLevel(),
          handler: ablyLogger.log
        },
        queryTime: true
      }));
      vendor = new AblyRealtimeWrapper(realtime, reporter, publishVerifier);
    }
    return vendor;
  };
})();