import get from 'transmute/get';
import { isThreadListMember } from '../thread-list-member/isThreadListMember';
import { isThreadKey } from './isThreadKey';

/**
 * @description This operator will return a unique ID to index ViewMembers on.
 * For Inbox this will be a string threadId, and for Help Desk this will be
 * the objectId.
 *
 * Note we convert the ID to string to avoid any weirdness when using keys from
 * POJOs where keys are cast to string.
 */
export function getViewMemberId(viewMember) {
  if (isThreadListMember(viewMember)) {
    const threadId = get('threadId', viewMember);
    return `${threadId}`;
  } else {
    const objectKey = viewMember.objectKey;
    const threadId = objectKey.threadId;
    const objectId = objectKey.objectId;
    return threadId ? `${threadId}` : `${objectId}`;
  }
}
export function getIdFromObjectKey(objectKey) {
  if (!isThreadKey(objectKey)) {
    return `${objectKey.objectId}`;
  }
  return objectKey.threadId ? `${objectKey.threadId}` : `${objectKey.objectId}`;
}