import { ChannelTypes } from 'conversations-internal-schema/constants/ChannelTypes';
import { EMAIL_GENERIC_CHANNEL_ID, FORMS_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
import { DISTRIBUTIONS } from '../constants/distributions';
/**
 * Given a channel type or geneeric channel ID, return the distribution type needed for that channel
 *  * Channel type strings should use [the values from `conversations-internal-schema/constants/ChannelTypes`](https://git.hubteam.com/HubSpot/conversations-internal-schema/blob/5495707ead479b04ad02ad1e39408b584b060cf6/static/js/constants/ChannelTypes.ts)
 *  * Generic channel IDs [can be found in `conversations-message-history/common-message-format/constants/genericChannelIds`](https://git.hubteam.com/HubSpot/conversations-message-history/blob/0833da361b805ff77c5667b2f38d71ed7c6d4d86/static/js/common-message-format/constants/genericChannelIds.ts#L1-L10)
 *
 * @param channelType - A channel type string or generic channel ID
 * @returns The distribution type for the specified channel type, which default to 'RANDOM'
 */
export const getChannelRoutingDistribution = channelType => {
  switch (channelType) {
    case ChannelTypes.EMAIL:
    case EMAIL_GENERIC_CHANNEL_ID:
    case ChannelTypes.FORMS:
    case FORMS_GENERIC_CHANNEL_ID:
      return DISTRIBUTIONS.RANDOM;
    default:
      return DISTRIBUTIONS.BALANCED;
  }
};