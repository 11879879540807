import { createSelector } from 'reselect';
import { isStarted, isFailed as isFailedOperator, isSucceeded } from 'conversations-async-data/async-data/operators/statusComparators';
import { CODE_SPLIT_FAILED_TO_LOAD, DISCONNECTED_FROM_PUBSUB, SUBSCRIPTIONS_FAILED } from '../constants/failureStates';
import { isDisconnected, isReconnecting, isResubscribing, isConnected, isSuspended } from '../operators/pubSubStatusComparators';
import { getAsyncPubSubClient } from './pubSubClientGetters';
import { getAsyncSubscriptions } from './subscriptionGetters';

/**
 * Get the failure state of the pub sub client
 *
 * @returns {string||boolean} If the client has failed the return value will be the failure state
 */
export const getFailureState = createSelector([getAsyncPubSubClient, getAsyncSubscriptions], (asyncPubSubClient, asyncSubscriptions) => {
  if (isFailedOperator(asyncPubSubClient)) {
    return CODE_SPLIT_FAILED_TO_LOAD;
  }
  if (isDisconnected(asyncPubSubClient) || isReconnecting(asyncPubSubClient) || isSuspended(asyncPubSubClient)) {
    return DISCONNECTED_FROM_PUBSUB;
  }
  if (isFailedOperator(asyncSubscriptions) || isResubscribing(asyncSubscriptions)) {
    return SUBSCRIPTIONS_FAILED;
  }
  return null;
});

/**
 * Check if the client is online
 *
 * @returns {boolean} true if the client is online
 */
export const isOnline = createSelector([getAsyncPubSubClient, getAsyncSubscriptions], (asyncPubSubClient, asyncSubscriptions) => {
  return isConnected(asyncPubSubClient) && (isStarted(asyncSubscriptions) || isSucceeded(asyncSubscriptions));
});

/**
 * Check if the client is failed
 *
 * @returns {boolean} true if the client is failed
 */
export const isFailed = createSelector([getFailureState], failureState => !!failureState);

/**
 * Check if the client can publish
 *
 * @returns {boolean} true if the client can publish
 */
export const canPublish = createSelector([getAsyncPubSubClient, getAsyncSubscriptions], (asyncPubSubClient, asyncSubscriptions) => {
  return isConnected(asyncPubSubClient) && isSucceeded(asyncSubscriptions);
});

/**
 * Check if the client is retrying
 *
 * @returns {boolean} true if the client can retrying
 */
export const isRetrying = createSelector([getAsyncPubSubClient, getAsyncSubscriptions], (asyncPubSubClient, asyncSubscriptions) => {
  if (isResubscribing(asyncSubscriptions)) return true;
  if (isReconnecting(asyncPubSubClient)) return true;
  return false;
});