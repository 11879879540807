import { Record } from 'immutable';
export const MANUAL_BLOCK = 'MANUAL_BLOCK';
export const MANUAL_SPAM = 'MANUAL_SPAM';
export const SYSTEM_RULES = 'SYSTEM_RULES';
export const NOT_FILTERED = 'NOT_FILTERED';
export const MANUAL = 'MANUAL';
export let SLAStatus;
(function (SLAStatus) {
  SLAStatus["IN_SLA"] = "IN_SLA";
  SLAStatus["BREACHED"] = "BREACHED";
  SLAStatus["SLA_WARNING"] = "SLA_WARNING";
  SLAStatus["RESOLVED_IN_SLA"] = "RESOLVED_IN_SLA";
  SLAStatus["RESOLVED_OUTSIDE_SLA"] = "RESOLVED_OUTSIDE_SLA";
})(SLAStatus || (SLAStatus = {}));
export let SLAType;
(function (SLAType) {
  SLAType["TIME_TO_CLOSE"] = "TIME_TO_CLOSE";
  SLAType["TIME_TO_RESPOND"] = "TIME_TO_RESPOND";
})(SLAType || (SLAType = {}));
const ThreadListMember = Record({
  assignee: null,
  associatedTicketIds: [],
  channels: null,
  cvCrmObjectId: null,
  filteredSource: 'MANUAL',
  goalDetails: null,
  latestMessagePreview: null,
  latestMessageTimestamp: null,
  latestReceivedTimestamp: null,
  latestSentTimestamp: null,
  originalChannelInstanceId: -1,
  originalGenericChannelId: -1,
  previous: null,
  removal: null,
  seen: null,
  seenByAgentIds: null,
  sortTimestamp: null,
  status: null,
  threadId: null,
  update: null,
  vid: null,
  visitor: null
}, 'ThreadListMember');
export default ThreadListMember;

/**
 * @deprecated directly replace with ThreadListMemberProps
 */