import getIn from 'transmute/getIn';
import { getPropertyValueRequirementsFromStage } from './pipelineStageGetters';
export const getAccessLevelFromPipeline = getIn(['permission', 'accessLevel']);
export const getDisplayOrderFromPipeline = getIn(['displayOrder']);
export const getIdFromPipeline = getIn(['pipelineId']);
export const getLabelFromPipeline = getIn(['label']);
export const getStagesFromPipeline = getIn(['stages']);
export const getAllRequiredValues = pipelines => {
  //Put all the stages across all pipelines into an array.
  if (!pipelines) return [];
  const stages = pipelines.reduce((acc, pipeline) => acc.concat(getStagesFromPipeline(pipeline)), []);

  //Put the names of all required fields across all stages into a set (to remove duplicates).
  if (!stages) return [];
  const requiredValues = stages.reduce((acc, stage) => {
    const propertyValueRequirements = getPropertyValueRequirementsFromStage(stage);
    (propertyValueRequirements || []).forEach(req => acc.add(req.propertyName));
    return acc;
  }, new Set());
  return Array.from(requiredValues);
};