const safelyParseJson = jsonString => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return {};
  }
};
export const publishVerifier = (publishingMessageId, realtimeMessage) => {
  const topLevelIdsMatch = publishingMessageId && publishingMessageId === realtimeMessage.id;
  const realtimeMessageDataObject = safelyParseJson(realtimeMessage.data);
  const dataIdMatch = publishingMessageId && publishingMessageId === realtimeMessageDataObject.id;
  return topLevelIdsMatch || dataIdMatch;
};