import I18n from 'I18n';
const OBJECT_MAPPINGS = {
  COMPANY: ['company name', 'company domain'],
  CONTACT: ['contact name', 'contact email'],
  DEAL: ['deal name'],
  TICKET: ['ticket name']
};
const PROPERTY_MAPPINGS = {
  name: ['company name', 'contact name', 'deal name', 'ticket name'],
  domain: ['company url', 'company domain'],
  email: ['contact email']
};
const getResult = response => {
  return response && response.data && response.data.results;
};
const formatParameterLabel = parameterLabel => {
  return parameterLabel.split('')[0].toUpperCase() + parameterLabel.toLowerCase().split('').slice(1).join('');
};
const parseParameterKey = parameterKey => {
  const [unformattedParameterLabel, parameterId] = parameterKey.split(':');
  return {
    parameterId,
    parameterLabel: formatParameterLabel(unformattedParameterLabel)
  };
};
const constructPrompt = prompt => {
  const PARAMETER_REGEX = /\[*\[(.*?)\]\]*/g;
  const promptSections = [];
  let areParametersMapped = true;
  prompt.split(/[.*?]/g).forEach((section, promptIndex) => {
    if (section.trim() !== '') {
      promptSections[promptIndex] = {
        optional: false,
        text: '',
        inputs: []
      };
      let sectionString = '';
      let isSectionOptional = false;
      section.split(/(\[*\[.*?\]\]*)/g).forEach((block, index) => {
        if (block.match(PARAMETER_REGEX)) {
          const parameter = block.replace(/[[\]]/g, '');
          const [parameterKey, defaultValue] = parameter.split('|');
          const optional = !!parameter.endsWith('|');
          const {
            parameterId,
            parameterLabel
          } = parseParameterKey(parameterKey);
          let crmObjectType;
          Object.entries(OBJECT_MAPPINGS).forEach(([objectType, parameters]) => {
            if (parameters.includes(parameter.toLowerCase())) {
              crmObjectType = objectType;
            }
          });
          let crmPropertyType;
          Object.entries(PROPERTY_MAPPINGS).forEach(([propertyType, parameters]) => {
            if (parameters.includes(parameter.toLowerCase())) {
              crmPropertyType = propertyType;
            }
          });
          if (!parameterId) {
            areParametersMapped = false;
          }
          if (optional) {
            isSectionOptional = true;
          } else if (!optional && isSectionOptional) {
            isSectionOptional = false;
          }

          // Use the parameter ids as the id if the template is mapped, otherwise
          // automatically generate ids with the indicies
          const id = parameterId || `${promptIndex}${index}`;
          promptSections[promptIndex].inputs.push({
            id,
            placeholder: optional ? `${parameterLabel} (optional)` : parameterLabel,
            optional,
            defaultValue,
            objectType: crmObjectType,
            propertyType: crmPropertyType
          });
          sectionString = sectionString.concat(`{{${id}}}`);
        } else {
          sectionString = sectionString + block;
        }
      });
      promptSections[promptIndex].text = sectionString.concat('.');
      promptSections[promptIndex].optional = isSectionOptional;
    }
  });
  return {
    areParametersMapped,
    promptSections
  };
};
const massageTemplateDataType = template => {
  const {
    areParametersMapped,
    promptSections
  } = constructPrompt(template.command);
  return {
    id: template.id,
    header: template.header,
    group: template.category,
    prompt: promptSections,
    legacyPrompt: template.command,
    createdAt: template.createdAt,
    description: template.description,
    hubSpotRequired: template.hubSpotRequired,
    intent: template.intent,
    isFeatured: template.isFeatured,
    isFavorite: template.isFavorite,
    isMapped: Boolean(areParametersMapped && template.intent),
    isNew: template.isNew,
    favoriteId: template.favoriteId,
    mySqlId: template.mySqlId
  };
};
const toPromptTemplate = templates => {
  return templates.map(message => {
    const dateMoment = I18n.moment(Number(message.createdAt));
    const isNew = dateMoment.isAfter(I18n.moment().subtract(2, 'weeks').startOf('day'));
    return massageTemplateDataType(Object.assign({}, message, {
      isFavorite: false,
      isNew
    }));
  });
};
const sortPromptTemplates = templates => {
  return templates.sort((template1, template2) => {
    if (template1.isNew && !template2.isNew) {
      return -1;
    } else if (template2.isNew && !template1.isNew) {
      return 1;
    }
    if (template1.header < template2.header) {
      return -1;
    }
    if (template1.header > template2.header) {
      return 1;
    }
    return 0;
  });
};
export const getPromptTemplates = response => {
  const promptTemplatesResponse = getResult(response);
  const promptTemplates = toPromptTemplate(promptTemplatesResponse);
  const sortedPromptTemplates = sortPromptTemplates(promptTemplates);
  return sortedPromptTemplates;
};