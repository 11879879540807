import { createAsyncActionTypes } from 'conversations-async-data/async-action/createAsyncActionTypes';
/**
 * @description similar to createAsyncAction, but the promise it returns
 * throws when an error occurs, effectively mimicking the client's failure
 * behavior.
 */
export const createAsyncActionWithErrors = ({
  requestFn,
  actionName,
  toRecordFn,
  successMetaActionCreator = () => {},
  failureMetaActionCreator = () => {}
}) => {
  const {
    STARTED,
    SUCCEEDED,
    FAILED
  } = createAsyncActionTypes(actionName);
  return requestArgs => dispatch => {
    dispatch({
      type: STARTED,
      payload: {
        requestArgs
      }
    });
    return requestFn(requestArgs).then(response => {
      const payload = {
        requestArgs,
        data: toRecordFn(response)
      };
      dispatch({
        type: SUCCEEDED,
        payload,
        meta: successMetaActionCreator(payload)
      });
      return payload.data;
    }).catch(error => {
      const payload = {
        requestArgs,
        error
      };
      dispatch({
        type: FAILED,
        payload,
        meta: failureMetaActionCreator(payload)
      });
      return error;
    });
  };
};