import { PATCH_PROPERTY_FOR_THREAD, RESOLVE_UPDATE_PROPERTY_ERROR } from '../actions/ActionTypes';
const initialState = {
  hasPropertyUpdateError: false,
  isUpdatingProperty: false
};
const propertyUpdateStatus = (state = initialState, action) => {
  switch (action.type) {
    case PATCH_PROPERTY_FOR_THREAD:
      return {
        hasPropertyUpdateError: !!action.error,
        isUpdatingProperty: action.meta === 'request'
      };
    case RESOLVE_UPDATE_PROPERTY_ERROR:
      return Object.assign({}, state, {
        hasPropertyUpdateError: false
      });
    default:
      return state;
  }
};
export default propertyUpdateStatus;