const _unique = definitions => {
  const names = {};
  return definitions.filter(definition => {
    if (definition.kind !== 'FragmentDefinition') {
      return true;
    }
    const name = definition.name.value;
    if (names[name]) {
      return false;
    } else {
      names[name] = true;
      return true;
    }
  });
};
import { gql } from '@apollo/client';
import PaymentObjectPropertyValueFields from './PaymentObjectPropertyValueFields';
import PaymentObjectPropertyDefinitionFields from './PaymentObjectPropertyDefinitionFields';
export default ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"PaymentObjectPropertyFields\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"PropertyValue\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"PaymentObjectPropertyValueFields\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"definition\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"PaymentObjectPropertyDefinitionFields\"}}]}}]}}]}", {
  kind: "Document",
  definitions: _unique([{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "PaymentObjectPropertyFields"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "PropertyValue"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "FragmentSpread",
        name: {
          kind: "Name",
          value: "PaymentObjectPropertyValueFields"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "definition"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "PaymentObjectPropertyDefinitionFields"
            }
          }]
        }
      }]
    }
  }].concat(PaymentObjectPropertyValueFields.definitions, PaymentObjectPropertyDefinitionFields.definitions))
});