import http from 'conversations-http/clients/http';
export const fetchThreadListMemberDetails = ({
  threadId,
  includeDeletedHistory = false,
  historyLimit = 30
}) => http.get(`messages/v2/threadlist/member/${threadId}/details`, {
  query: {
    expectedResponseType: 'WRAPPER_V2',
    includeDeletedHistory,
    historyLimit
  }
});