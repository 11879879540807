'use es6';

import { secureDocument } from './secureDocument';
export const replaceGmailDivs = ({
  node,
  node_name
}) => {
  if (node_name === 'div' && node && node.classList && node.classList.contains('gmail_default')) {
    const span = secureDocument.createElement('span');
    Object.keys(node.attributes).forEach(key => {
      const attribute = node.attributes[key];
      span.setAttribute(attribute.nodeName, attribute.nodeValue);
    });
    span.innerHTML = node.innerHTML.trim();
    return {
      node: span
    };
  }
  return null;
};