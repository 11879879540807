import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { FETCH_CHATFLOW } from '../constants/asyncActionTypes';
const initialState = new IndexedAsyncData({
  name: 'chatflows',
  notSetValue: new AsyncData({
    data: null
  })
});
export default handleActions({
  [FETCH_CHATFLOW.STARTED]: (state, action) => {
    const {
      chatflowId
    } = action.payload.requestArgs;
    return updateEntry(chatflowId, requestStarted, state);
  },
  [FETCH_CHATFLOW.SUCCEEDED](state, action) {
    const {
      data,
      requestArgs
    } = action.payload;
    const {
      chatflowId
    } = requestArgs;
    return updateEntry(chatflowId, requestSucceededWithOperator(() => data), state);
  },
  [FETCH_CHATFLOW.FAILED](state, action) {
    const {
      chatflowId
    } = action.payload.requestArgs;
    return updateEntry(chatflowId, requestFailed, state);
  }
}, initialState);