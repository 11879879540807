import { makeVar, useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';
const expandedEditorState = makeVar(false);
const editorTargetRef = makeVar(null);
const editorKey = makeVar('default');
function open(key = 'default') {
  expandedEditorState(true);
  editorKey(key);
}
function close(key = 'default') {
  if (key === editorKey()) {
    expandedEditorState(false);
  }
}
function setRef(ref) {
  editorTargetRef(ref);
}
export function usePopOutEditorState(key = 'default') {
  const isOpen = useReactiveVar(expandedEditorState);
  const ref = useReactiveVar(editorTargetRef);
  const storedKey = useReactiveVar(editorKey);

  // Pre-bind the open and close handlers
  const _close = useCallback(() => close(key), [key]);
  const _open = useCallback(() => open(key), [key]);
  if (storedKey === key) {
    return {
      isOpen,
      open: _open,
      close: _close,
      ref,
      setRef,
      key: storedKey
    };
  } else {
    return {
      isOpen: false,
      open: _open,
      close: _close,
      ref: null,
      setRef,
      key: storedKey
    };
  }
}