import { getBeaconUsageTracker } from '../clients/usageTracker';
export function trackLinkClick({
  event,
  href,
  name = '',
  meta = {}
}) {
  event.preventDefault();
  const newTab = event.metaKey || event.ctrlKey;
  const usageTracker = getBeaconUsageTracker();

  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  return usageTracker.track(name, meta).finally(() => {
    const target = newTab ? '_blank' : '_self';
    window.open(href, target);
  });
}