import { Map as ImmutableMap } from 'immutable';
import memoize from 'transmute/memoize';
import { getEngagementsV2UniversalAssociationRecord } from './EngagementsV2UASHelpers';
export const getSelectedAssociations = memoize(associations => associations.reduce((acc, record) => {
  const selectedAssociationOptionRecords = record.get('associationOptionRecords').filter(option => option.get('isSelected'));
  if (!selectedAssociationOptionRecords.size) {
    return acc;
  }
  const engagementsV2UniversalAssociation = getEngagementsV2UniversalAssociationRecord(record, selectedAssociationOptionRecords);
  return acc.concat(engagementsV2UniversalAssociation);
}, ImmutableMap()));