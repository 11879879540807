import http from 'hub-http/clients/apiClient';
const BASE_URL = 'conversations/taxonomy/v1';
export const getPropertiesMeta = () => {
  return http.get(`${BASE_URL}/properties/meta`);
};
export const getPropertiesForThread = threadId => {
  return http.get(`${BASE_URL}/properties/${threadId}`);
};
export const patchPropertyForThread = (threadId, properties) => {
  return http.patch(`${BASE_URL}/properties/${threadId}/multi`, {
    data: properties
  });
};
export const resolveThread = threadId => {
  return http.post(`${BASE_URL}/conversations/${threadId}/resolve`);
};
export const fetchStatus = threadId => {
  return http.get(`${BASE_URL}/conversations/${threadId}/status`);
};