import { useCallback, useMemo } from 'react';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { useReduxIndexedAsyncFetcher } from 'conversations-async-data/indexed-async-data/hooks/useReduxIndexedAsyncFetcher';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { getIndexedAsyncCapabilitiesData } from '../selectors/getIndexedAsyncCapabilitiesData';
import { fetchCapabilitiesData } from '../actions/fetchCapabilitiesData';
import { deriveCompoundStatus } from '../operators/deriveCompoundStatus';
import { getCapabilityFromKey } from '../operators/getCapabilityFromKey';
import { buildCapabilityKeySet } from '../operators/buildCapabilityKeySet';
import { buildBaseCapabilitiesConfig } from '../operators/buildBaseCapabilitiesConfig';
/**
 * @description This hook represents the data-fullfilment layer of the
 * capabilities editors. It manages fetching things like @-mentionable
 * users and .
 */
export const useAsyncCapabilitiesConfig = (capabilitiesConfig, inboxId, threadId, contactVid, contactEmail, auth) => {
  const capabilityKeys = useMemo(() => {
    const capabilities = Object.keys(capabilitiesConfig);
    return buildCapabilityKeySet(capabilities, inboxId, threadId);
  }, [capabilitiesConfig, threadId, inboxId]);
  const idsTransform = useCallback(capabilitiesToFetch => ({
    capabilityKeys: capabilitiesToFetch
  }), []);
  const {
    entries
  } = useReduxIndexedAsyncFetcher({
    ids: capabilityKeys,
    idsTransform,
    action: fetchCapabilitiesData,
    selector: getIndexedAsyncCapabilitiesData
  });
  const baseCapabilitiesConfig = useMemo(() => buildBaseCapabilitiesConfig(capabilitiesConfig, threadId, contactVid, contactEmail, auth), [capabilitiesConfig, threadId, contactVid, contactEmail, auth]);
  return useMemo(() => {
    return new AsyncData({
      data: entries.mapEntries(entry => {
        const [capabilityKey, asyncCapabilitiesData] = entry;
        const capability = getCapabilityFromKey(capabilityKey);
        const baseConfig = capability && baseCapabilitiesConfig[capability] || {};
        const capabilitiesData = getData(asyncCapabilitiesData) || {};
        const finalConfig = Object.assign({}, baseConfig, capabilitiesData);
        return [capability, finalConfig];
      }).toJS(),
      status: deriveCompoundStatus(entries.valueSeq().toArray())
    });
  }, [entries, baseCapabilitiesConfig]);
};