import { ASSIGN_TO_TEAM_SECTION, ASSIGN_TO_USER_SECTION, SEARCH_MORE_OPTION, UNASSIGN_OPTION } from '../constants/reassignment-dropdown';
import { getIsAssignable } from '../operators/assignees';
import { buildTeamTreeOptions } from '../operators/buildTeamTreeOptions';
export const createSearchMoreSection = () => ({
  disabled: true,
  sectionId: SEARCH_MORE_OPTION,
  text: 'Search more'
});
export const createUnassignSection = () => ({
  options: [{
    sectionId: 'EMPTY_OPTION'
  }],
  sectionId: UNASSIGN_OPTION,
  text: 'Unassign',
  value: 'unassign'
});
const filterTeamsBySearch = (teams, filterValue) => {
  const lowerSearch = filterValue.trim().toLowerCase();
  return teams.filter(team => team.title && team.title.trim().toLowerCase().includes(lowerSearch));
};
const markIsTeam = option => Object.assign({}, option, {
  isTeam: true
});
export const createTeamsSection = (teams, filterValue, isUngatedForHelpDeskManualAssignmentScopeFiltering) => ({
  options: filterTeamsBySearch(buildTeamTreeOptions(teams, isUngatedForHelpDeskManualAssignmentScopeFiltering), filterValue).map(markIsTeam),
  sectionId: ASSIGN_TO_TEAM_SECTION,
  text: 'Assign to team'
});
const filterAssignableAgent = user => getIsAssignable(user.responder);
export const createUsersSection = users => ({
  options: users.filter(filterAssignableAgent),
  sectionId: ASSIGN_TO_USER_SECTION,
  text: 'Assign to user'
});