import http from 'hub-http/clients/apiClient';
export const fetchCalleeSearchResults = ({
  objectTypeId,
  objectId,
  searchText,
  timestamp
}) => {
  const url = 'calls/v1/callee-search';
  return http.get(`${url}/${objectTypeId}/${objectId}/`, {
    query: {
      query: searchText,
      clienttimeout: 14000,
      maxResults: 50
    }
  }).then(response => {
    return {
      response,
      timestamp,
      searchText
    };
  }).catch(error => ({
    error,
    timestamp,
    searchText
  }));
};