'use es6';

import { insertDateMessages } from './insertDateMessages';
import { getType } from '../../common-message-format/operators/commonMessageFormatGetters';

/**
 * @description Inserts date messages into a list of
 * history messages, and filters out messages that are not present in the
 * historyConfig.
 */
export const insertDateMessagesWithHistoryConfig = (messageList, historyConfig = {}, displayInitialMessageDate = true) => {
  const messageFilter = message => {
    const messageType = getType(message);
    return historyConfig[messageType];
  };
  return insertDateMessages(messageList, displayInitialMessageDate, messageFilter);
};