// @ts-expect-error module not typed
import EmailAddressRecord from 'customer-data-email/schema/email/EmailAddressRecord';
import { getCanonicalVid, getEmail, getFirstName, getLastName
// @ts-expect-error module not typed
} from 'conversations-internal-schema/visitor/operators/visitorGetters';
import { areEmailAddressesEqual } from '../../../connected-accounts/public';
import { extractInfoFromEmailString } from './extractInfoFromEmailString';

/**
 * @description email addresses that come back in the thread history
 * are represented by a string that is sometimes just the email but
 * sometimes contains both a name and an email in the format of:
 * 'Full Name <email@email.com>'. Since we can't reliably parse names
 * into first/last due to I18n changes, we can store the entire name
 * in the firstName slot, and it will display correcly when used in
 * the email communicator. When the email is sent, it should reconstruct
 * the name using a `lastName` if one is present, otherwise it should just
 * use the firstName.
 * @param {String} emailString
 * @returns {EmailAddressRecord}
 */
export const getEmailAddressRecordFromString = (emailString, visitor) => {
  let address = emailString;
  let firstName;
  let lastName;
  let id;
  const bracketInfo = extractInfoFromEmailString(emailString);
  const {
    friendlyName
  } = bracketInfo;
  ({
    address
  } = bracketInfo);
  if (areEmailAddressesEqual(getEmail(visitor), address)) {
    address = getEmail(visitor);
    firstName = getFirstName(visitor);
    id = getCanonicalVid(visitor);
    lastName = getLastName(visitor);
  } else if (friendlyName) {
    firstName = friendlyName;
  }
  return new EmailAddressRecord({
    address,
    firstName,
    lastName,
    id
  });
};