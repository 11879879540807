import { buildError } from 'conversations-error-reporting/error-reporting/builders/buildError';
import { FILE_SIZE, UNSUPPORTED_TYPE } from '../constants/errorTypes';
export const buildFileSizeError = ({
  maxFileSize
}) => buildError(FILE_SIZE, {
  attachmentErrorType: FILE_SIZE,
  maxFileSize
});
export const buildUnsupportedError = () => buildError(UNSUPPORTED_TYPE, {
  attachmentErrorType: UNSUPPORTED_TYPE
});