import { useCallback } from 'react';
import { sendChatTranscriptClient } from '../clients/sendChatTranscriptClient';
export const useSendChatTranscript = ({
  threadId
}) => {
  const sendChatTranscript = useCallback(({
    recipient,
    subject
  }) => {
    return sendChatTranscriptClient({
      threadId,
      recipient,
      subject
    });
  }, [threadId]);
  return {
    sendChatTranscript
  };
};