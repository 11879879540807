import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"];
import { Record } from 'immutable';
import { CalleesRecord } from 'calling-lifecycle-internal/callees/records/CalleesRecordsTyped';
import { AsyncDataRecord } from 'calling-lifecycle-internal/universal-calling-store/records/AsyncDataRecord';
const isSuccessfulResult = result => result && result['@result'] === 'OK';
const parseCallableObjectAndAssociations = callableObjectAndAssociations => {
  let callableObjectAndAssociationsParsed = null;
  if (isSuccessfulResult(callableObjectAndAssociations) && callableObjectAndAssociations) {
    callableObjectAndAssociationsParsed = CalleesRecord.fromJS(callableObjectAndAssociations);
  } else if (callableObjectAndAssociations && callableObjectAndAssociations.fromObject) {
    callableObjectAndAssociationsParsed = CalleesRecord.fromJS(callableObjectAndAssociations);
  }
  return callableObjectAndAssociationsParsed || new CalleesRecord();
};
export class CalleeOmnibusResult extends Record({
  callableObjectAndAssociations: undefined
}, 'CalleeOmnibusResult') {
  constructor(args = {}) {
    const {
      callableObjectAndAssociations
    } = args;
    super({
      callableObjectAndAssociations: parseCallableObjectAndAssociations(callableObjectAndAssociations)
    });
  }
}
export class AsyncCalleeOmnibusResult extends AsyncDataRecord({
  data: new CalleeOmnibusResult()
}, 'AsyncCalleeOmnibusResult') {
  constructor(_ref = {}) {
    let {
        data
      } = _ref,
      options = _objectWithoutPropertiesLoose(_ref, _excluded);
    options.data = new CalleeOmnibusResult(data || {});
    super(options);
  }
}