import { useApolloClient } from '@apollo/client';
import { getTracker } from 'commerce-subscription-lib/public/tracking';
import { useMemo } from 'react';
import getIn from 'transmute/getIn';
import { FETCH_SUBSCRIPTION_HIGHLIGHT } from '../hooks/useSubscriptionHighlight';
const tracker = getTracker('crm-sidebar');
const processorPath = ['crmObject', 'defaultProperties', 'hs_processor_type', 'value'];
export function useTracker(objectId) {
  const client = useApolloClient();
  const cache = client.cache.readQuery({
    query: FETCH_SUBSCRIPTION_HIGHLIGHT,
    variables: {
      objectId
    }
  });
  const processor = getIn(processorPath, cache) || 'hs_payments';
  return useMemo(() => ({
    trackUsage: action => trackUsage(action, processor),
    trackInteraction: action => trackInteraction(action, processor),
    trackSubscriptionChange: event => tracker('subscriptionChange', Object.assign({}, event, {
      type: 'merchant-usage',
      paymentType: processor
    }))
  }), [processor]);
}
function trackUsage(action, paymentType) {
  tracker('usageEvent', {
    type: 'merchant-usage',
    subType: 'highlight-card',
    action,
    paymentType
  });
}
function trackInteraction(action, paymentType) {
  tracker('interactionEvent', {
    type: 'merchant-interaction',
    subType: 'highlight-card',
    action,
    paymentType
  });
}
export default tracker;