import Raven from 'raven-js';
import { IS_KITCHEN_SINK } from './constants';
import { isError, isObject, isString } from './common';
export function handleError(error, options) {
  console.error('Error caught', error);
  if (IS_KITCHEN_SINK) {
    return error;
  }
  if (isError(error)) {
    Raven.captureException(error, options);
    return error;
  }
  if (isString(error)) {
    const err = new Error(error);
    Raven.captureException(err, options);
    return err;
  }
  if (isObject(error)) {
    const err = new Error(`Stringified error: ${JSON.stringify(error)}`);
    Raven.captureException(err, options);
    return err;
  }
  const err = new Error(`Unknown error: ${String(error)}`);
  Raven.captureException(err, options);
  return err;
}