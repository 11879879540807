import http from 'conversations-http/clients/http';
export const fetchPromptData = ({
  promptType,
  noCache = false
}) => http.get(`cv/inbox/settings/v1/user-prompts`, {
  query: Object.assign({
    promptType
  }, noCache ? {
    noCache: Math.random()
  } : {})
});