/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { useMutation, useQuery } from 'data-fetching-client';
import { useMemo, useCallback } from 'react';
import AgentStatus from '../records/AgentStatus';
import { GET_AGENT_STATUS, UPDATE_AWAY_MODE } from '../queries/agentStatusQueries';
import { Triggers, getToggledAwayMode } from '../utils/event-handlers';
export const useAgentStatus = (usageTracker, workspace) => {
  const {
    data,
    loading,
    error,
    refetch
  } = useQuery(GET_AGENT_STATUS, {
    variables: {
      workspace: workspace || ''
    }
  });
  const trackUsage = useCallback(usageProperties => usageTracker && usageTracker.track(usageProperties.key, usageProperties.properties), [usageTracker]);
  const [updateAwayModeQuery, {
    loading: mutationLoading,
    error: updateAwayModeError
  }] = useMutation(UPDATE_AWAY_MODE, {
    update(cache, {
      data: mutationResultData
    }) {
      if (mutationResultData) {
        cache.writeQuery({
          query: GET_AGENT_STATUS,
          data: {
            agentStatus: mutationResultData.awayMode
          },
          variables: {
            workspace: workspace || ''
          }
        });
      }
    }
  });
  const toggleAwayMode = useCallback(awayMode => {
    if (!mutationLoading) {
      const optimisticResponse = data && data.agentStatus ? {
        awayMode: Object.assign({}, data.agentStatus, {
          updatedByAgentId: data.agentStatus.agentId,
          awayMode
        })
      } : undefined;
      updateAwayModeQuery({
        variables: {
          awayMode,
          workspace: workspace || ''
        },
        optimisticResponse
      });
      trackUsage(getToggledAwayMode({
        awayMode,
        trigger: Triggers.MANUAL
      }));
    }
  }, [mutationLoading, data, updateAwayModeQuery, workspace, trackUsage]);
  const agentStatus = useMemo(() => {
    return data && AgentStatus(data.agentStatus);
  }, [data]);
  return {
    agentStatus,
    error,
    loadingAgentStatus: loading,
    toggleAwayMode,
    refetchAgentStatus: refetch,
    updateAwayModeError
  };
};