import getIn from 'transmute/getIn';
import { getProperty } from 'customer-data-objects/record/ObjectRecordAccessors';
// @ts-expect-error module not typed
import EmailAddressRecord from 'customer-data-email/schema/email/EmailAddressRecord';
import { GenericIdentifierRecord } from 'conversations-thread-data/recipients/public/GenericIdentifierRecord';
import { EMAIL_GENERIC_CHANNEL_ID, WHATSAPP_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
import { HS_EMAIL_ADDRESS, HS_PHONE_NUMBER } from 'conversations-message-history/common-message-format/constants/deliveryIdentifierTypes';
export const GENERIC_CRM_PROPERTIES = ['email', 'hs_whatsapp_phone_number'];
const contactPropertyToDeliveryIdentifier = {
  ['email']: HS_EMAIL_ADDRESS,
  ['hs_whatsapp_phone_number']: HS_PHONE_NUMBER
};
const CRM_PROP_TO_GENERIC_CHANNEL_ID = {
  email: EMAIL_GENERIC_CHANNEL_ID,
  hs_whatsapp_phone_number: WHATSAPP_GENERIC_CHANNEL_ID
};
export const mapContactsToObjectRecord = (contacts, deliveryIdentifier) => {
  return contacts.reduce((acc, contact) => {
    const address = getProperty(contact, deliveryIdentifier);
    const additionalEmails = getProperty(contact, 'hs_additional_emails');
    const firstName = getProperty(contact, 'firstname');
    const lastName = getProperty(contact, 'lastname');
    const id = getIn(['objectId'], contact);
    const emailAddressRecord = new EmailAddressRecord({
      address,
      addressId: `${address} ${address}`,
      firstName,
      id,
      lastName
    });
    acc.push(emailAddressRecord);
    if (additionalEmails && additionalEmails.length > 0) {
      additionalEmails.split(';').forEach(additionalEmail => {
        const additionalEmailRecord = new EmailAddressRecord({
          address: additionalEmail,
          addressId: `${additionalEmail} ${additionalEmail}`,
          firstName,
          id,
          lastName
        });
        acc.push(additionalEmailRecord);
      });
    }
    return acc;
  }, []);
};
export const mapContactsToGenericObjectRecord = contacts => {
  return contacts.reduce((acc, contact) => {
    const address = getProperty(contact, 'email');
    const additionalEmails = getProperty(contact, 'hs_additional_emails');
    const firstName = getProperty(contact, 'firstname');
    const lastName = getProperty(contact, 'lastname');
    const id = getIn(['objectId'], contact);
    const addGenericRecords = initialProps => {
      GENERIC_CRM_PROPERTIES.forEach(dtype => {
        const dval = getProperty(contact, dtype);
        initialProps.deliveryIdentifierType = contactPropertyToDeliveryIdentifier[dtype] || dtype;
        initialProps.deliveryIdentifierValue = dval;
        initialProps.genericChannelId = CRM_PROP_TO_GENERIC_CHANNEL_ID[dtype];
        if (dval) acc.push(new GenericIdentifierRecord(initialProps));
      });
    };
    const recordProps = {
      address,
      addressId: `${address} ${address}`,
      firstName,
      id,
      lastName,
      crmObjectId: contact.objectId
    };
    addGenericRecords(recordProps);
    if (additionalEmails && additionalEmails.length > 0) {
      additionalEmails.split(';').forEach(additionalEmail => {
        const additionalEmailRecordProps = {
          address: additionalEmail,
          addressId: `${additionalEmail} ${additionalEmail}`,
          firstName,
          id,
          lastName,
          crmObjectId: contact.objectId,
          genericChannelId: EMAIL_GENERIC_CHANNEL_ID,
          deliveryIdentifierType: HS_EMAIL_ADDRESS,
          deliveryIdentifierValue: additionalEmail
        };
        acc.push(new GenericIdentifierRecord(additionalEmailRecordProps));
      });
    }
    return acc;
  }, []);
};