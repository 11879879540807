import http from 'conversations-http/clients/http';
import { registerQuery } from 'data-fetching-client';
export const USERS_AND_TEAMS_HIERARCHY_QUERY_NAME = 'usersAndTeamsHierarchy';
export const USERS_AND_TEAMS_HIERARCHY_QUERY = registerQuery({
  fieldName: USERS_AND_TEAMS_HIERARCHY_QUERY_NAME,
  args: ['inboxId'],
  fetcher({
    inboxId
  }) {
    return http.get('messages/v2/users/teams/hierarchy', {
      query: {
        inboxId
      }
    });
  }
});