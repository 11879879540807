import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { silenceErrorAlert } from 'conversations-error-reporting/error-actions/builders/silenceErrorAlert';
import { GET_TICKET_SOURCES } from '../constants/asyncActionTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { fetchPropertyGroups } from 'crm_data/properties/PropertiesAPI';
export const fetchTicketSources = createAsyncAction({
  requestFn: fetchPropertyGroups,
  toRecordFn: obj => obj,
  actionTypes: GET_TICKET_SOURCES,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type '{}'.
  failureMetaActionCreator: ({
    error
  }) => silenceErrorAlert({
    error
  })
});