import { getHasSharedWorkerSupport } from 'shared-worker-versioning/lib/utils/getHasSharedWorkerSupport';
import { UnsupportedReason } from '../constants/UnsupportedReason';
import { getIsIframed } from './getIsIframed';
import { getIsUserAgentSupported } from './getIsUserAgentSupported';
import { getAppDomain } from './getAppDomain';

/**
 * We don't support a few different experiences
 * 1. Using this in an embed - instead connect the embed to the parent and talk to this through postMessage.
 * 2. Mobile user agents - Even through some have Shared Worker support they don't work well in this experience.
 * 3. Safari - Safari just started support for Shared Workers in v16, however they implemented it differently than other browsers
 *    and don't execute code sent until the user activates the tab or window.
 * 4. No Shared Worker Support - This just complicates things a lot and opens us up to more issues that are hard to debug.
 */

let hasSupport;
export const getIsCrossTabMessagingSupported = () => {
  if (hasSupport === undefined) {
    hasSupport = getHasSharedWorkerSupport() && getIsUserAgentSupported();
  }
  return hasSupport;
};
let unsupportedReason;
export const getUnsupportedReason = () => {
  if (typeof unsupportedReason === 'string') {
    return unsupportedReason;
  }
  unsupportedReason = UnsupportedReason.INAPPLICABLE;
  if (!getIsCrossTabMessagingSupported()) {
    unsupportedReason = getIsUserAgentSupported() ? UnsupportedReason.MISSING_SHARED_WORKER_SUPPORT : UnsupportedReason.UNSUPPORTED_USER_AGENT;
  } else if (getIsIframed()) {
    unsupportedReason = UnsupportedReason.EMBEDDED;
  } else if (!getAppDomain().isAppDomain) {
    unsupportedReason = UnsupportedReason.NON_APP_DOMAIN;
  }
  return unsupportedReason;
};
export const __resetCachedValues = () => {
  unsupportedReason = undefined;
  hasSupport = undefined;
};