// gimme: conversations-inbox-ui 06/04/2020 fe0726e5c2d15427039f33c1c9f173eb16627331
'use es6';

export const getClickedAlertBannerCta = ({
  ctaAction
}) => ({
  key: 'clicked-alert-banner-cta',
  properties: {
    action: 'clicked alert banner cta',
    'cta-action': ctaAction
  }
});