import { parse, parseCardTitle } from './pastFeedbackCardParser';
import { FETCH_FEEDBACK_CARD_TITLE, FETCH_FEEDBACK_CARD } from './pastFeedbackCardQueries';
const DEFAULT_SHOW_COUNT = 1;
export const FeedbackCardTitleGqlConfig = {
  /*  @ts-expect-error Jun-5-2024, 11:9UTC FixMe: Complete Migration to TypeScript  */
  getGraphQLQueryVariables: ({
    objectType,
    subjectId
  }) => ({
    objectType,
    subjectId: parseInt(subjectId, 10)
  }),
  parse: parseCardTitle,
  query: FETCH_FEEDBACK_CARD_TITLE
};
export default {
  /*  @ts-expect-error Jun-5-2024, 11:9UTC FixMe: Complete Migration to TypeScript  */
  getGraphQLQueryVariables: ({
    objectType,
    maxItemsToShow,
    subjectId
  }) => ({
    limit: maxItemsToShow || DEFAULT_SHOW_COUNT,
    objectType,
    subjectId: parseInt(subjectId, 10)
  }),
  parse,
  query: FETCH_FEEDBACK_CARD
};