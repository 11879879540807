import { getUsageTrackingChannel } from 'conversations-thread-data/usage-tracking/public/trackerChannelIdMapping';
export const getUpdatedConversation = ({
  genericChannelId,
  hasAssociatedTicket,
  updateType,
  location
}) => ({
  key: 'updated-conversation',
  properties: {
    action: 'updated conversation',
    channel: getUsageTrackingChannel(genericChannelId),
    hasAssociatedTicket,
    'update-type': updateType,
    location
  }
});
export const getAddedComment = ({
  genericChannelId,
  atMentionsCount,
  hasAssociatedTicket
}) => ({
  key: 'added-comment',
  properties: {
    action: 'added comment',
    channel: genericChannelId ? getUsageTrackingChannel(genericChannelId) : undefined,
    'at-mentions-count': atMentionsCount,
    hasAssociatedTicket
  }
});
//Used only in conversations-inbox-ui
export const getAssignedThread = ({
  assignee,
  genericChannelId,
  hasAssociatedTicket
}) => ({
  key: 'assigned-thread',
  properties: {
    action: 'assigned thread',
    assignee,
    channel: getUsageTrackingChannel(genericChannelId),
    hasAssociatedTicket
  }
});
export const getClickToAssign = ({
  currentAssignee,
  genericChannelId,
  hasAssociatedTicket
}) => ({
  key: 'click-to-assign',
  properties: {
    action: 'click to assign',
    'current-assignee': currentAssignee,
    channel: getUsageTrackingChannel(genericChannelId),
    hasAssociatedTicket
  }
});
export const getClickedMoveToInbox = ({
  genericChannelId,
  hasAssociatedTicket
}) => ({
  key: 'clicked-move-to-inbox',
  properties: {
    action: 'clicked to move conversations',
    channel: getUsageTrackingChannel(genericChannelId),
    hasAssociatedTicket
  }
});
export const getClickedMoveToInboxCancel = () => ({
  key: 'clicked-move-to-inbox-cancel-button',
  properties: {
    action: 'clicked the cancel button to move conversation to another inbox'
  }
});
export const getClickedMoveToInboxConfirm = ({
  type
}) => ({
  key: 'clicked-move-to-inbox-confirm-button',
  properties: {
    action: 'clicked the confirm button to move conversation to another inbox',
    type
  }
});
export const getClickedMoveToInboxAssignToDropdown = () => ({
  key: 'clicked-move-to-inbox-assign-to-dropdown',
  properties: {
    action: 'clicked on the assign to dropdown'
  }
});
export const getClickedMoveToInboxAssignToDropdownOption = ({
  type
}) => ({
  key: 'clicked-move-to-inbox-assign-to-dropdown-option',
  properties: {
    action: 'clicked on one of the assign to dropdown options',
    type
  }
});
export const getClickedMoveToInboxCommentInput = () => ({
  key: 'clicked-move-to-inbox-comment-input',
  properties: {
    action: 'clicked on the optional comment text input'
  }
});
export const getMoveToInboxAddedComment = () => ({
  key: 'move-to-inbox-added-comment',
  properties: {
    action: 'added a comment to the conversation handoff'
  }
});
export const getDeleteComment = ({
  hasAssociatedTicket
} = {
  hasAssociatedTicket: false
}) => {
  return {
    key: 'delete-comment',
    properties: {
      action: 'delete comment',
      hasAssociatedTicket
    }
  };
};
export const getSentMessage = ({
  genericChannelId,
  communicatorType,
  hasAssociatedTicket,
  replyType,
  timeToReply,
  messageChannel,
  numberOfTicketsAssociated,
  source,
  editorType
}) => {
  return {
    key: 'sent-message',
    properties: {
      action: 'sent message',
      hasAssociatedTicket,
      timeToReply,
      channel: getUsageTrackingChannel(genericChannelId),
      messageChannel,
      'communicator-type': communicatorType,
      'reply-type': replyType,
      source,
      numberOfTicketsAssociated,
      editorType
    }
  };
};
export const getUpdatedTicketPipelineFromThreadView = ({
  pipeline
}) => ({
  key: 'updated-ticket-pipeline-from-thread-view',
  properties: {
    action: 'updated ticket pipeline from thread view',
    pipeline
  }
});
export const getUpdatedTicketStageFromThreadView = ({
  stage
}) => ({
  key: 'updated-ticket-stage-from-thread-view',
  properties: {
    action: 'updated ticket stage from thread view',
    stage
  }
});
export const getClosedTicketFromThreadView = ({
  stage
}) => ({
  key: 'closed-ticket-from-thread-view',
  properties: {
    action: 'closed ticket from thread view',
    stage
  }
});
export const getOpenedOwnerDropdown = () => ({
  key: 'opened-owner-dropdown',
  properties: {
    action: 'owner dropdown opened'
  }
});
export const getSearchedOwnerDropdown = () => ({
  key: 'searched-owner-dropdown',
  properties: {
    action: 'owner dropdown search used'
  }
});