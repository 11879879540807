import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enterSearchAndFilterMode } from 'conversations-inbox-lib/thread-search-and-filtering/public/actions';
import { getFocusedViewMemberThreadId } from '../../focused-view-member/selectors/getFocusedViewMemberThreadId';
export const useEnterSearchAndFilterMode = () => {
  const focusedThreadListMemberThreadId = useSelector(getFocusedViewMemberThreadId);
  const dispatch = useDispatch();
  const onEnterSearchAndFilterMode = useCallback(() => {
    dispatch(enterSearchAndFilterMode(focusedThreadListMemberThreadId));
  }, [focusedThreadListMemberThreadId, dispatch]);
  return {
    onEnterSearchAndFilterMode
  };
};