import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { silenceErrorAlert } from 'conversations-error-reporting/error-actions/builders/silenceErrorAlert';
import { getOwnerByUserId as requestFn } from '../clients/getOwnerByUserId';
import identity from 'transmute/identity';
import { FETCH_OWNER_BY_USER_ID as actionTypes } from '../constants/asyncActionTypes';
export const fetchOwnerByUserId = createAsyncAction({
  requestFn,
  actionTypes,
  toRecordFn: identity,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type '{}'.
  failureMetaActionCreator: ({
    error
  }) => silenceErrorAlert({
    error
  })
});