import { getEmailMetadataAttachment } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { setAttachment } from 'conversations-message-history/common-message-format/operators/commonMessageSetters';
import { getMetadataSubject, getMetadataTemplateId } from 'conversations-message-history/common-message-format/operators/emailMetadataGetters';
import { getCommonMessage } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
import { setMetadataTemplateId, setMetadataSubject } from 'conversations-message-history/common-message-format/operators/emailMetadataSetters';
// @ts-expect-error module not typed
import { setCommonMessage } from 'conversations-thread-data/common-reply/public/operators/commonReplySetters';
export const mergeInsertMetadata = (insertMetadata = {}, reply) => {
  const {
    subject,
    templateId
  } = insertMetadata;
  const commonMessage = getCommonMessage(reply);
  const emailMetadataAttachments = getEmailMetadataAttachment(commonMessage);
  if (!emailMetadataAttachments) {
    return reply;
  }
  const emailMetadataSubject = getMetadataSubject(emailMetadataAttachments);
  const emailMetadataTemplateId = getMetadataTemplateId(emailMetadataAttachments);
  let updatedEmailMetadataAttachments = emailMetadataAttachments;
  if (subject && !emailMetadataSubject) {
    // Do not replace the subject if already set when user picks a template
    updatedEmailMetadataAttachments = setMetadataSubject(subject, updatedEmailMetadataAttachments);
  }
  if (templateId && !emailMetadataTemplateId) {
    // Metadata takes only one templateId, but templates in Inbox works closer to snippets where user can insert multiple templates
    // Pick the first template id user picked.
    updatedEmailMetadataAttachments = setMetadataTemplateId(templateId, updatedEmailMetadataAttachments);
  }
  return emailMetadataAttachments === updatedEmailMetadataAttachments ? reply : setCommonMessage(setAttachment(updatedEmailMetadataAttachments, commonMessage), reply);
};