import { createAction } from '@reduxjs/toolkit';
/**
 * @description This action is used to encompass the Find & Filter omnibus ownership.
 * This action should be dispatched when omnibus is successfully fetched with only
 * the slice of omnibus that is owned by Find & Filter.
 */
export const omnibusFulfilled = createAction('omnibus/fulfilled', (omnibus, currentViewId, inboxId) => {
  return {
    payload: {
      currentViewId,
      inboxId,
      omnibus
    }
  };
});