// @ts-expect-error module not typed
import * as ImmutableAPI from 'crm_data/api/ImmutableAPI';
import { logNetworkError } from 'calling-error-reporting/report/error';
export const updateUniversalAssociation = ({
  engagementId,
  objectId,
  updatedAssociationRecord,
  isDisassociation
}) => {
  const associationTypeId = updatedAssociationRecord.get('associationTypeId');
  const associationCategory = updatedAssociationRecord.get('associationCategory');
  const associationToUpdate = {
    fromObjectId: engagementId,
    toObjectId: objectId,
    associationCategory,
    associationTypeId
  };
  const endpoint = isDisassociation ? 'associations-writes/v1/associations/delete' : 'associations-writes/v1/associations';
  return ImmutableAPI.put(endpoint, [associationToUpdate]).catch(networkError => {
    logNetworkError({
      error: new Error('Error updating associated records to engagement in conversations thread.'),
      networkError,
      extraData: {
        associationToUpdate
      }
    });
    throw networkError;
  });
};