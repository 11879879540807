import { logCallingError, logSentryInfo } from 'calling-error-reporting/report/error';
const isNetworkError = networkError => networkError && networkError.xhr;
export const logNetworkError = ({
  error,
  networkError,
  extraData,
  tags
}) => {
  const info = {
    error,
    extraData: extraData || {},
    tags: tags || {}
  };
  if (isNetworkError(networkError)) {
    info.extraData.status = networkError.status;
    info.extraData.statusText = networkError.statusText;
    logSentryInfo(info);
  } else {
    logCallingError({
      error,
      extraData: info.extraData,
      tags: info.tags
    });
  }
};