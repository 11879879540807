import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
export function addAlert({
  title,
  message,
  type
}) {
  return FloatingAlertStore.addAlert({
    titleText: title,
    message,
    type
  });
}