export const ChannelTypes = {
  EMAIL: 'EMAIL',
  CHAT: 'LIVE_CHAT',
  FACEBOOK_MESSENGER: 'FACEBOOK_MESSENGER',
  FORMS: 'FORMS',
  WHATS_APP: 'WHATS_APP',
  WHATSAPP: 'WHATSAPP',
  SMS: 'SMS',
  CALLING: 'CALLING',
  CUSTOM: 'CUSTOM'
};