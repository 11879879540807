import { trackEvent } from '../../usage-tracking/clients/usageTracker';
import { setLocation } from './setLocation';
import { clickedToConnectEmail, clickedToConnectChat, clickedToConnectMessenger, clickedToConnectChannel, clickedToConnectCalling } from '../constants/Events';
const connectTypes = {
  ['EMAIL']: clickedToConnectEmail,
  ['CHAT']: clickedToConnectChat,
  ['MESSENGER']: clickedToConnectMessenger,
  ['CALLING']: clickedToConnectCalling
};
export const trackConnectChannel = ({
  threadListType,
  handler,
  href = ''
}) => evt => {
  if (evt) {
    evt.preventDefault();
  }
  const event = threadListType && connectTypes[threadListType] || clickedToConnectChannel;
  if (event) {
    trackEvent(event);
  }
  if (handler) {
    handler();
  } else if (href) {
    setLocation(href);
  }
};