'use es6';

import memoize from 'transmute/memoize';
import { propertyLabelTranslatorWithIsHubSpotDefined } from 'property-translator/propertyTranslator';
import { getLabel, getHubSpotDefined } from './calleesOperators';
const RECORD_PROPERTY_NAMESPACE = 'recordProperties';
export const getPropertyLabel = memoize(propertyDefinition => {
  const label = getLabel(propertyDefinition);
  const isHubspotDefined = getHubSpotDefined(propertyDefinition);
  return propertyLabelTranslatorWithIsHubSpotDefined({
    label,
    isHubspotDefined,
    nameSpaceKey: RECORD_PROPERTY_NAMESPACE
  });
});