import { ConnectionTypes } from 'calling-cross-tab-library/types/ConnectionTypes';
import once from 'shared-worker-versioning/lib/utils/once';
import { UUID } from 'shared-worker-versioning/lib/utils/UUID';
/**
 * Only access at the host app level. The embed uses a different way of getting this information.
 */
export const getCallingWidgetConfig = once(() => {
  if (!window || !window.callingWidgetConfiguration) {
    window.callingWidgetConfiguration = {
      broadcastMessageHandlers: [],
      isLoadingStarted: false
    };
  }
  const config = window.callingWidgetConfiguration;
  if (!config.broadcastMessageHandlers) {
    config.broadcastMessageHandlers = [];
  }
  if (typeof config.isLoadingStarted === 'undefined') {
    config.isLoadingStarted = false;
  }
  if (!config.connectionId) config.connectionId = UUID('callingConnectionId');
  if (!config.connectionType) config.connectionType = ConnectionTypes.EXTERNAL;
  return window.callingWidgetConfiguration;
});