import { useState } from 'react';
import { useSelector } from 'react-redux';
import { isSucceeded } from 'conversations-async-data/async-data/operators/statusComparators';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { getIsDisabledForAcceptanceTest } from '../../new-feature-onboarding/operators/getIsDisabledForAcceptanceTest';
import useHasSeenInfoModal from 'content-assistance-lib/hooks/useUserAttribute';
import { isConversationSummaryUngatedSelector } from '../../selectors/gateSelectors';
import { getUserId } from '../../selectors/userSelectors/getUserId';
import { UserPromptType } from '../../contextual-prompts/constants/userPromptTypes';
import { usePromptDismissal } from '../../contextual-prompts/hooks/usePromptDismissals';
import { getDismissedAt } from '../../contextual-prompts/operators/userPromptDataGetters';
export function useAIOnboardingPopoverState() {
  const [loading, setLoading] = useState(false);
  /*
  The AI Popover is singular now, replacing a 2-page onboarding modal:
  Content Assistant and Conversation Summary.
  In order to keep backwards-compatiblity and keep changes to a minumum,
  state management of both pages is retained.
  */
  const disabledForAcceptanceTest = useSelector(getIsDisabledForAcceptanceTest);
  const userId = useSelector(getUserId);
  const [hasSeenContentAssistant, setHasSeenContentAssistant] = useHasSeenInfoModal({
    appName: 'conversation-inbox-ui',
    userId
  });
  const conversationSummaryUngated = useSelector(isConversationSummaryUngatedSelector);
  const conversationSummaryOnboardingPrompt = usePromptDismissal(UserPromptType.CONVERSATION_SUMMARY_ONBOARDING);
  const setHasSeenConversationSummary = () => {
    setLoading(true);
    return conversationSummaryOnboardingPrompt.dismissPrompt().catch(error => {
      reportError({
        error
      });
    }).finally(() => {
      setLoading(false);
    });
  };
  const conversationSummaryOnboardingPromptData = getData(conversationSummaryOnboardingPrompt.asyncPromptData);
  const hasSeenConversationSummary = getDismissedAt(conversationSummaryOnboardingPromptData) > 0;
  const hasResolvedRequests = isSucceeded(conversationSummaryOnboardingPrompt.asyncPromptData);
  const shouldShowAIOnboardingPopover = !disabledForAcceptanceTest && conversationSummaryUngated && !loading && hasResolvedRequests && (!hasSeenContentAssistant || !hasSeenConversationSummary);
  const setHasSeenAIOnboardingPopover = () => {
    setHasSeenContentAssistant();
    setHasSeenConversationSummary().catch(error => {
      reportError({
        error
      });
    });
  };
  const AIOnboardingPopoverState = {
    shouldShowAIOnboardingPopover,
    setHasSeenAIOnboardingPopover
  };
  return AIOnboardingPopoverState;
}
export default useAIOnboardingPopoverState;