'use es6';

import { fullCommonMessageClient } from '../../../messages/fullCommonMessageClient';
// constant directory has been migrated to thread-data, the remaining the this directory will be moved over soon
import { FETCH_OVERSIZED_COMMON_MESSAGE } from '../constants/asyncActionTypes';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { buildCommonMessage } from 'conversations-message-history/common-message-format/operators/buildCommonMessage';
export const fetchOversizedCommonMessage = createAsyncAction({
  requestFn: fullCommonMessageClient,
  actionTypes: FETCH_OVERSIZED_COMMON_MESSAGE,
  toRecordFn: response => buildCommonMessage(response)
});