import { getData, getId, getVersion } from './draftGetters';
export function serializeDraft(draft, serializeData) {
  const id = getId(draft);
  const version = getVersion(draft);
  const data = getData(draft);
  const timestamp = Date.now();
  const serializedData = data ? serializeData(data) : '';
  return JSON.stringify({
    id,
    version,
    timestamp,
    data: serializedData
  });
}