'use es6';

import { handleActions } from 'redux-actions';
import { FETCH_EMAIL_SIGNATURE } from '../constants/asyncActionTypes';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { requestFailedWithError } from 'conversations-async-data/async-data/operators/requestFailedWithError';
const emailSignaturesInitialState = IndexedAsyncData({
  idInvariant: () => null,
  name: 'emailSignatureIsSetup',
  notSetValue: AsyncData({
    data: false
  })
});
const emailSignatureIsSetup = handleActions({
  [FETCH_EMAIL_SIGNATURE.STARTED]: (state, {
    payload: {
      requestArgs
    }
  }) => updateEntry(requestArgs.emailAddress, requestStarted, state),
  [FETCH_EMAIL_SIGNATURE.SUCCEEDED]: (state, {
    payload: {
      data
    }
  }) => updateEntry(data.emailAddress, requestSucceededWithOperator(() => !!data.signature), state),
  [FETCH_EMAIL_SIGNATURE.FAILED]: (state, {
    payload: {
      requestArgs,
      error
    }
  }) => updateEntry(requestArgs.emailAddress, requestFailedWithError(error), state)
}, emailSignaturesInitialState);
export default emailSignatureIsSetup;