'use es6';

import { fetchDeletedMessages } from './fetchDeletedMessages';
import { showDeletedMessagesForThreadId } from './showDeletedMessagesForThreadId';
import { getThreadHistory } from '../../thread-histories/selectors/getThreadHistory';
export const viewDeletedMessages = ({
  threadId
}) => (dispatch, getState) => {
  dispatch(showDeletedMessagesForThreadId({
    threadId
  }));
  const threadHistory = getThreadHistory(getState(), {
    threadId
  });
  dispatch(fetchDeletedMessages({
    threadId,
    threadHistory
  }));
};