import { Map as ImmutableMap } from 'immutable';
import memoizeLast from 'transmute/memoizeLast';
import { AT_MENTIONS, INSERT_CORE, INSERT_TEMPLATES } from '../../../channel-configuration/public/constants';
import { COMPOSITION_THREAD_ID } from '../../../communicator/constants/compositionThreadId';
import { getAtMentionsCapabilityData } from './getAtMentionsCapabilityData';
import { getInsertCapabilitiesData } from './getInsertCapabilitiesData';
import { getCapabilityFromKey } from '../operators/getCapabilityFromKey';
import { getCapabilityKeyContext } from '../operators/getCapabilityKeyContext';
export const getInsertCapabilitiesDataMemoized = memoizeLast(threadId => {
  if (threadId === COMPOSITION_THREAD_ID) {
    return Promise.resolve({
      supplementalObjects: {}
    });
  }
  return getInsertCapabilitiesData({
    threadId
  });
});
export const getCapabilitiesData = ({
  capabilityKeys
}) => {
  const capabilityKeysArray = capabilityKeys.toArray();
  const clients = {
    [AT_MENTIONS]: getAtMentionsCapabilityData,
    [INSERT_CORE]: ({
      threadId
    }) => getInsertCapabilitiesDataMemoized(threadId),
    [INSERT_TEMPLATES]: ({
      threadId
    }) => getInsertCapabilitiesDataMemoized(threadId)
  };
  return Promise.all(capabilityKeysArray.map(capabilityKey => {
    const capability = getCapabilityFromKey(capabilityKey);
    const capabilityKeyContext = getCapabilityKeyContext(capabilityKey);
    // @ts-expect-error clients doesn't need to have a client for every capability, that's handled below
    const client = capability && clients[capability];
    return client ? client(capabilityKeyContext) : {};
  })).then(results => {
    return capabilityKeysArray.reduce((accumulator, capabilityKey, index) => accumulator.set(capabilityKey, results[index]), ImmutableMap());
  });
};