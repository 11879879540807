import { useState, useRef, useMemo, useCallback } from 'react';
import debounce from 'transmute/debounce';
import { useLazyQuery, useMutation } from '@apollo/client';
import { getInvoiceProperty } from 'invoices-ui-lib/utils/property';
import { FETCH_PAYMENT_RECORDS } from '../graphQL/commercePaymentsQuery';
import { ASSOCIATE_PAYMENT_TO_INVOICE } from '../graphQL/commercePaymentsMutation';
const DEBOUNCE_DELAY = 300;
const PAGE_SIZE = 10;
export const getPaymentRecordTotal = payment => {
  const hsInitialAmount = payment.properties.find(property => property.name === 'hs_initial_amount');
  return +hsInitialAmount.value;
};
const reducePaymentRecordTotal = (total, selectedPaymentRecord) => total + getPaymentRecordTotal(selectedPaymentRecord);
const useApplyPayments = (invoice, onSuccess, onFailure) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selections, setSelections] = useState([]);
  const [selectionsToDisplay, setSelectionsToDisplay] = useState([]);
  const invoiceObjectId = invoice.objectId;
  const balanceDue = +getInvoiceProperty(invoice, 'hs_balance_due');
  const currencyCode = getInvoiceProperty(invoice, 'hs_currency');
  const balanceSelected = useMemo(() => selections.reduce(reducePaymentRecordTotal, 0), [selections]);
  const selectedPaymentRecordIds = useMemo(() => new Set(selections.map(selection => selection.id)), [selections]);
  const isSelected = id => selectedPaymentRecordIds.has(id);
  const [refetch, {
    data,
    loading: loadingQuery
  }] = useLazyQuery(FETCH_PAYMENT_RECORDS, {
    variables: {
      query: searchQuery,
      filterGroups: {
        filters: []
      },
      count: PAGE_SIZE,
      offset: 0
    },
    onError: onFailure
  });
  const [associatePaymentToInvoiceMutation, {
    loading: loadingMutation
  }] = useMutation(ASSOCIATE_PAYMENT_TO_INVOICE);
  const [options, total, offset] = useMemo(() => {
    return [data ? data.crmObjectsSearch.results : [], data && data.crmObjectsSearch.total, data && data.crmObjectsSearch.offset];
  }, [data]);
  const debouncedSearch = useRef(debounce(DEBOUNCE_DELAY, (variables, newSelections) => {
    refetch({
      variables
    });
    setSelectionsToDisplay(newSelections);
  })).current;
  const filterGroups = useMemo(() => {
    const inInvoiceCurrencyFilter = {
      property: 'hs_currency_code',
      operator: 'EQ',
      value: currencyCode
    };
    const isSuccessfulPaymentFilter = {
      property: 'hs_latest_status',
      operator: 'EQ',
      value: 'succeeded'
    };
    // There is a slight lag between when a payment is associated to an invoice and when
    // the hs_has_associated_invoice property is set on the payment object, meaning a quick user could
    // apply an invoice-associated payment as an existing payment to another invoice. This prevents that.
    const isNotSourcedFromInvoiceFilter = {
      property: 'hs_payment_source_app',
      operator: 'NEQ',
      value: 'INVOICE'
    };
    const notSelectedFilter = {
      property: 'hs_object_id',
      operator: 'NOT_IN',
      values: [...selectedPaymentRecordIds]
    };
    const hasNoAssociatedInvoice = {
      property: 'hs_has_associated_invoice',
      operator: 'EQ',
      value: 'false'
    };
    return selectedPaymentRecordIds.size > 0 ? {
      filters: [inInvoiceCurrencyFilter, isSuccessfulPaymentFilter, isNotSourcedFromInvoiceFilter, notSelectedFilter, hasNoAssociatedInvoice]
    } : {
      filters: [inInvoiceCurrencyFilter, isSuccessfulPaymentFilter, isNotSourcedFromInvoiceFilter, hasNoAssociatedInvoice]
    };
  }, [currencyCode, selectedPaymentRecordIds]);
  const handleOnSearch = value => {
    debouncedSearch({
      query: value,
      filterGroups,
      offset: 0
    }, selections);
    setSearchQuery(value);
  };
  const handleOnSelect = (checked, selection) => {
    if (checked) {
      setSelections(previousSelections => [...previousSelections, selection]);
    } else {
      setSelections(previousSelections => previousSelections.filter(existingSelection => existingSelection.id !== selection.id));
    }
  };
  const handleOnPageChange = value => {
    debouncedSearch({
      offset: value * PAGE_SIZE - PAGE_SIZE,
      filterGroups
    }, selections);
  };
  const handleOnConfirm = useCallback(() => {
    Promise.all(selections.map(selection => associatePaymentToInvoiceMutation({
      variables: {
        invoiceObjectId,
        commercePaymentObjectId: selection.id
      }
    }))).then(() => onSuccess(selections.length)).catch(onFailure);
  }, [associatePaymentToInvoiceMutation, invoiceObjectId, onSuccess, onFailure, selections]);
  const currentPage = offset && Math.ceil(offset / PAGE_SIZE);
  const pageCount = total && Math.ceil(total / PAGE_SIZE);
  return {
    currentPage,
    pageCount,
    total,
    balanceDue,
    balanceSelected,
    loadingQuery,
    loadingMutation,
    options,
    selections,
    selectionsToDisplay,
    isSelected,
    handleOnSearch,
    handleOnSelect,
    handleOnPageChange,
    handleOnConfirm
  };
};
export default useApplyPayments;