import { reportError } from '../../error-reporting/reportError';
/**
 * @description Combines several sets of callbacks into
 * a single set of callbacks.
 * @param {Object} keyedLifecycleHooks an object mapping
 * keys to lifecycleHook objects
 */
export function combineLifecycleHooks(keyedLifecycleHooks) {
  const groupedHooks = Object.keys(keyedLifecycleHooks).reduce((accumulator, key) => {
    const lifeCycleHooks = keyedLifecycleHooks[key];
    Object.keys(lifeCycleHooks).forEach(hookKey => {
      accumulator[hookKey] = accumulator[hookKey] ? [...accumulator[hookKey], lifeCycleHooks[hookKey]] : [lifeCycleHooks[hookKey]];
    });
    return accumulator;
  }, {});
  return Object.keys(groupedHooks).reduce((accumulator, hookKey) => {
    return Object.assign({}, accumulator, {
      [hookKey](...args) {
        groupedHooks[hookKey].forEach(hook => {
          try {
            hook(...args);
          } catch (error) {
            reportError({
              error: error
            });
          }
        });
      }
    });
  }, {});
}