import { useSelector } from 'react-redux';
import { selectViewCountOffsets, selectViewCounts } from './counts/viewCountsSelectors';
import { getViewCountAndStatus } from './getViewCountAndStatus';
import { selectViewUnreadCountOffsets, selectViewUnreadTrackedThreads } from './unread/viewUnreadStatusSelectors';

/**
 * @description This hook returns a realtime total thread count for the given
 * view and the views unread status.
 */
export const useViewCountAndStatus = view => {
  const countOffsets = useSelector(selectViewCountOffsets);
  const unreadCountOffsets = useSelector(selectViewUnreadCountOffsets);
  const declarativeCountsMap = useSelector(selectViewCounts);
  const trackedThreads = useSelector(selectViewUnreadTrackedThreads);
  return getViewCountAndStatus(view, {
    countOffsets,
    declarativeCountsMap,
    unreadCountOffsets,
    trackedThreads
  });
};