import { useReduxAsyncFetcher } from 'conversations-async-data/async-data/hooks/useReduxAsyncFetcher';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { fetchAgentCapacity, fetchPortalCapacityLimit } from '../actions/capacityLimits';
import { selectAllAgentCapacities, selectAllPortalLimits, selectIsFetching, selectAtCapacity, selectLimitsEnabled } from '../selectors/capacityLimits';
export const useAtCapacityLimit = ({
  agentId,
  type,
  deferred = false
}) => {
  const idTransform = useCallback(() => ({
    agentId,
    type
  }), [agentId, type]);
  const limitsEnabled = useSelector(state => selectLimitsEnabled(state, type));
  const atCapacity = useSelector(state => selectAtCapacity(state, type));
  const isFetching = useSelector(selectIsFetching) && !deferred;
  useReduxAsyncFetcher({
    action: fetchPortalCapacityLimit,
    id: type,
    idTransform,
    deferred: deferred || typeof agentId !== 'number',
    selector: selectAllPortalLimits
  });
  useReduxAsyncFetcher({
    action: fetchAgentCapacity,
    id: type,
    idTransform,
    deferred: deferred || !limitsEnabled || typeof agentId !== 'number',
    selector: selectAllAgentCapacities
  });
  return {
    atCapacity: !isFetching && atCapacity
  };
};