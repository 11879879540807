'use es6';

import reduce from 'transmute/reduce';
import { Set as ImmutableSet } from 'immutable';
import { getSenderId } from 'conversations-message-history/senders/operators/senderLocatorGetters';
export const getValidSenderIdsFromSenderLocators = senderLocators => {
  return reduce(ImmutableSet(), (accumulator, senderLocator) => {
    const senderId = getSenderId(senderLocator);
    // Avoiding senderIds which are not valid numbers. Specifically, for avoiding utk(s) of live chats.
    if (!senderId || isNaN(senderId)) {
      return accumulator;
    }
    return accumulator.add(senderId);
  }, senderLocators || ImmutableSet());
};