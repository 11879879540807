import { createAction } from 'redux-actions';
import { SET_FRIENDLY_NAMES } from '../constants/actionTypes';
export const setFriendlyNames = createAction(SET_FRIENDLY_NAMES, ({
  friendlyNames,
  threadId
}) => {
  return {
    friendlyNames,
    threadId
  };
});