import { buildError } from '../../error-reporting/builders/buildError';
import { buildRequestTimeoutError } from '../../errors/builders/buildRequestTimeoutError';
import promiseTimeout from 'hs-promise-utils/timeout';
import { PROMISE_TIMEOUT_MS } from '../constants/defaults';

/**
 * Performs an explicit attach to an ably channel
 */
export function attach({
  channel
}) {
  return promiseTimeout(new Promise((resolve, reject) => {
    channel.attach(errorInfo => {
      if (errorInfo) {
        const error = buildError(`PubSub channel attach failure: ${errorInfo.code}`, Object.assign({
          channelName: channel.name,
          errorSource: 'ably-attach'
        }, errorInfo));
        reject(error);
      } else {
        resolve();
      }
    });
  }), PROMISE_TIMEOUT_MS, buildRequestTimeoutError('PubSub channel attach timeout', {
    channelName: channel.name
  }));
}