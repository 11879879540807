'use es6';

import http from 'conversations-http/clients/http';
import { buildAgentRequestData } from '../operators/buildAgentRequestData';
import { buildRecipientObjectsFromAgentsResponse } from '../operators/buildRecipientObjectsFromAgentsResponse';
export const getAgentRecipients = ({
  recipientLocators
}) => {
  const data = buildAgentRequestData({
    recipientLocators
  });
  return http.put('messages/v2/message/agents/batch', {
    data,
    query: {
      accessType: 'INTERNAL',
      shouldHydrateActivationStatus: true
    }
  }).then(buildRecipientObjectsFromAgentsResponse);
};