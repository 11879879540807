import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { navigateToThread as navigateToThreadAction } from '../../pubsub/actions/navigateToThread';
export const useNavigateToThread = () => {
  const dispatch = useDispatch();
  const navigateToThread = useCallback(({
    threadId
  }) => {
    dispatch(navigateToThreadAction({
      threadId
    }));
  }, [dispatch]);
  return {
    navigateToThread
  };
};