// gimme: conversations-inbox-ui 05/26/2020 741c62dc71ca742adbc6d64d61d30de7cb46539f
'use es6';

import http from 'conversations-http/clients/http';
import { ALL, SKIP_DELETED } from 'conversations-thread-data/thread-deletion/public/constants/DeletedFilterTypes';
const DEFAULT_HISTORY_COUNT = 30;
const DESCENDING = 'DESCENDING';
export function getMessageHistory({
  threadId,
  offsetTimestamp = Date.now(),
  offsetOrdinal = 0,
  allowDeletedThread = false,
  includeDeletedMessages = false
}) {
  const deletedMessagesFilter = includeDeletedMessages ? ALL : SKIP_DELETED;
  return http.get(`messages/v3/history/${threadId}/agent`, {
    query: {
      expectedResponseType: 'WRAPPER_V2',
      sortDirection: DESCENDING,
      limit: DEFAULT_HISTORY_COUNT,
      offsetTimestamp,
      offsetOrdinal,
      allowDeletedThread,
      deletedMessagesFilter
    }
  });
}