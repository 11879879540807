import { setAttachments
// @ts-expect-error module not typed
} from 'conversations-message-history/common-message-format/operators/commonMessageFormatSetters';
import { getCommonMessage } from '../../public/operators/commonReplyGetters';
// @ts-expect-error module not typed
import { setCommonMessage } from '../../public/operators/commonReplySetters';
import { buildFileAttachmentsFromReply } from './buildFileAttachmentsFromReply';
import CommonMessage from 'conversations-message-history/common-message-format/records/CommonMessage';
export const getCMFSyncedReply = reply => {
  const existingCommonMessage = getCommonMessage(reply);
  const cmfAttachments = buildFileAttachmentsFromReply({
    commonReply: reply
  });
  if (existingCommonMessage) {
    return setCommonMessage(setAttachments(cmfAttachments, existingCommonMessage), reply);
  }
  const commonMessage = new CommonMessage({});
  return setCommonMessage(setAttachments(cmfAttachments, commonMessage), reply);
};