/* hs-eslint ignored failing-rules */

// gimme: conversations-inbox-ui 06/05/2020 8722c66a58dcba4df92bee8f284215022188a305
'use es6';

import { getMessages } from 'conversations-message-history/thread-history/operators/getters';
import ThreadHistory from 'conversations-message-history/thread-history/records/ThreadHistory';
import { createAction } from 'redux-actions';
import { getDeletedMessages } from '../clients/getDeletedMessages';
import { FETCH_DELETED_MESSAGES } from 'conversations-thread-data/thread-deletion/public/constants/asyncActionTypes';
import { mergeMessagesIntoHistory } from '../operators/mergeMessagesIntoHistory';
const fetchDeletedMessagesStarted = createAction(FETCH_DELETED_MESSAGES.STARTED);
const fetchDeletedMessagesSucceeded = createAction(FETCH_DELETED_MESSAGES.SUCCEEDED);
const fetchDeletedMessagesFailed = createAction(FETCH_DELETED_MESSAGES.FAILED);
export const fetchDeletedMessages = ({
  threadId,
  threadHistory: nonDeletedHistory
}) => dispatch => {
  dispatch(fetchDeletedMessagesStarted({
    threadId
  }));
  getDeletedMessages({
    threadId
  }).then(deletedHistory => {
    const messages = getMessages(new ThreadHistory(deletedHistory));
    const newHistory = mergeMessagesIntoHistory({
      messages,
      threadHistory: nonDeletedHistory
    });
    dispatch(fetchDeletedMessagesSucceeded({
      threadHistory: newHistory,
      threadId
    }));
  }).catch(error => {
    dispatch(fetchDeletedMessagesFailed({
      threadId,
      error
    }));
  });
};