import PortalIdParser from 'PortalIdParser';
import http from 'hub-http/clients/apiClient';
export const makeInvoiceSearchQuery = ({
  count,
  offset,
  query
}) => {
  return {
    count,
    filterGroups: [{
      filters: [{
        property: 'hs_invoice_source',
        operator: 'EQ',
        value: 'native_invoice'
      }, {
        property: 'hs_invoice_status',
        values: ['open', 'paid', 'voided'],
        operator: 'IN'
      }]
    }],
    objectTypeId: '0-53',
    offset,
    query,
    portalId: PortalIdParser.get(),
    requestOptions: {
      allPropertiesFetchMode: 'latest_version',
      includeAllValues: true
    },
    associationPreviews: {
      INVOICE_TO_CONTACT: {
        properties: ['firstName', 'lastName', 'email']
      }
    },
    sorts: [{
      order: 'DESC',
      direction: 1,
      property: 'hs_createdate',
      sortKey: 'hs_createdate'
    }]
  };
};
export const defaultApiWrapper = apiClient => ({
  searchInvoices({
    count,
    offset,
    query
  }) {
    return apiClient.post('crm-search/search', {
      data: Object.assign({}, makeInvoiceSearchQuery({
        count,
        offset,
        query
      }))
    });
  },
  fetchRenderedContent({
    id
  }) {
    return apiClient.get(`invoices/v1/invoices/${id}/with-associated-objects`).then(invoice => {
      const invoiceName = invoice.properties.find(property => property.name === 'hs_number').value;
      const domain = invoice.properties.find(property => property.name === 'hs_domain').value;
      const slug = invoice.properties.find(property => property.name === 'hs_slug').value;
      const invoiceUrl = `https://${domain}/${slug}`;
      return {
        body: invoiceName,
        htmlBody: `<a href=${invoiceUrl}>${invoiceName}</a>`
      };
    });
  }
});
export const searchInvoices = ({
  count,
  offset,
  query
}) => {
  return http.post('crm-search/search', {
    data: Object.assign({}, makeInvoiceSearchQuery({
      count,
      offset,
      query
    }))
  });
};