import { BET_CUSTOMER_SUMMARY_CARD, CONVERSATIONS_CONTACT_TICKETS_CARD, CONVERSATIONS_DEAL_CARD, PAST_CONVERSATIONS_CARD, QUALIFIED_LEADS_CARD, CONVERSATIONS_THREAD_TICKET_CARD, TOOL_LINKS_CARD, PROPERTIES_CARD, ZORSE_CONVERSATIONS_TAXONOMY_CARD, ZORSE_MOVE_INBOX_CARD
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
} from 'customer-data-sidebar/universal/UniversalSidebarCardTypes';
import { getTicketButtons } from './getTicketButtons';
export const getSidebarCardsProps = ({
  associatedTicket,
  canEditCurrentContact,
  conversationId,
  handleEditPropertySettingsClicked,
  hasTicketsWriteAccess,
  openCreateTicketModal,
  onTicketDisassociationSuccess,
  subjectId,
  threadId,
  vid
}) => {
  const ticketCardButtons = getTicketButtons({
    createDisabled: !(canEditCurrentContact || hasTicketsWriteAccess),
    onCreateClick: openCreateTicketModal
  });
  return {
    [BET_CUSTOMER_SUMMARY_CARD]: {
      threadId
    },
    [CONVERSATIONS_CONTACT_TICKETS_CARD]: {
      associatedTicket,
      cardButtons: ticketCardButtons,
      conversationId,
      threadId: `${threadId}`
    },
    [CONVERSATIONS_DEAL_CARD]: {
      conversationId: +threadId
    },
    [PAST_CONVERSATIONS_CARD]: {
      currentThreadId: threadId,
      vid
    },
    [QUALIFIED_LEADS_CARD]: {
      appName: 'conversations',
      chatThreadId: Number(threadId),
      contactVid: Number(subjectId)
    },
    [CONVERSATIONS_THREAD_TICKET_CARD]: {
      // for disassociating the ticket only from the thread we set the conversation
      // as the subjectId for the withRemoveAssociation HOC
      subjectId: `${threadId}`,
      // we still pass in the conversationId separately because these are two
      // separate props in the withRemoveAssociation HOC
      threadId: `${threadId}`,
      //The conversationId is needed because the function that wraps this component
      //and provides the disassociate ticket button needs to know which
      //conversation to disassociate from. For v1 associations, it uses a thread
      //ID and for v2 it uses a conversation ID (though it always looks for a
      //variable called 'conversationId'). This function lives in customer-
      //data-sidebar. We will be able to remove this entire prop when all users
      //are ungated for ticket filtering.
      conversationId,
      createDisabled: !(canEditCurrentContact || hasTicketsWriteAccess),
      onCreateClick: openCreateTicketModal,
      onDisassociationSuccess: onTicketDisassociationSuccess,
      shouldDisassociateConversation: true,
      shouldDisassociateRecord: false
    },
    [TOOL_LINKS_CARD]: {
      threadId
    },
    [PROPERTIES_CARD]: {
      onEditPropertiesClick: handleEditPropertySettingsClicked
    },
    [ZORSE_CONVERSATIONS_TAXONOMY_CARD]: {
      threadId
    },
    [ZORSE_MOVE_INBOX_CARD]: {
      threadId: Number(threadId)
    }
  };
};