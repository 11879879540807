export function buildEditorDisabledPropsGetter(...strategies) {
  return function (options) {
    const [firstStrategy, ...restStrategies] = strategies;
    if (!firstStrategy || typeof firstStrategy !== 'function') {
      return {
        disabled: false
      };
    }
    return firstStrategy(options) || buildEditorDisabledPropsGetter(...restStrategies)(options);
  };
}