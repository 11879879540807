import pipe from 'transmute/pipe';
import { buildThreadsAdded } from '../addition/buildThreadsAdded';
import { buildThreadRemovals } from '../removal/buildThreadRemovals';
import { buildThreadUpdates } from './buildThreadUpdates';
import { mergeAdded, mergeUpdated, mergeRemovals } from './mergeThreadUpdates';

/**
 * @description This function will take a realtime ThreadsUpdated message and
 * apply all the changes to the given map of ViewMembers, returning a new map
 * with all those applied changes.
 *
 * @param viewMembers
 * @param viewMembersUpdated
 * @param currentAgentId Needed to resolve seen correctly for added ViewMembers
 */
export function mergeViewMembersWithUpdates(viewMembers, viewMembersUpdated, currentAgentId) {
  const added = buildThreadsAdded(viewMembersUpdated.added, currentAgentId);
  const updated = buildThreadUpdates(viewMembersUpdated.updated);
  const removals = buildThreadRemovals(viewMembersUpdated.removed);
  const updatedMembers = pipe(mergeAdded(added), mergeUpdated(updated), mergeRemovals(removals))(viewMembers);
  return updatedMembers;
}