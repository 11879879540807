import { isThreadListMember } from '../thread-list-member/isThreadListMember';
import { getSeen, getSeenByAgentIds } from './threadListMemberGetters';
const isThreadListMemberSeen = (member, currentAgentId) => {
  const seen = getSeen(member);
  const seenByAgentIds = getSeenByAgentIds(member);
  const isConstructedFromViewMember = seen === undefined || seen === null;
  return isConstructedFromViewMember ?
  //If constructed from generic view member, list of seen by agent IDs should be available
  Boolean(seenByAgentIds && seenByAgentIds.includes(currentAgentId)) : seen;
};
const isViewMemberSeen = (member, currentAgentId) => member.seenByAgentIds.includes(currentAgentId);
export function isMemberSeen(member, currentAgentId) {
  return isThreadListMember(member) ? isThreadListMemberSeen(member, currentAgentId) : isViewMemberSeen(member, currentAgentId);
}