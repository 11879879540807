import { isApolloError } from '@apollo/client';
const getHandoffServerError = error => {
  const networkError = isApolloError(error) ? error.networkError : undefined;
  return networkError && networkError.responseJSON ? networkError.responseJSON : {
    message: error.message,
    errorType: 'HANDOFF_ERROR'
  };
};
export const buildHandoffError = (originalError, variables) => {
  const error = getHandoffServerError(originalError);
  const {
    errorType
  } = error;
  const {
    newInboxId,
    threadId,
    handoffAssignmentRequest,
    destinationInboxName
  } = variables;
  switch (errorType) {
    case 'ASSIGNMENT_ERROR':
      return {
        assignThreadError: {
          error,
          variables: {
            threadId: threadId,
            data: handoffAssignmentRequest.assignmentByRoutingRuleDefinitionRequest
          }
        },
        unassignThreadError: {
          error,
          variables: {
            threadId: threadId
          }
        },
        variables: {
          destinationInboxName: destinationInboxName
        }
      };
    case 'COMMENTING_ERROR':
      return {
        commentError: error,
        variables: {
          destinationInboxName: destinationInboxName
        }
      };
    default:
      // HANDOFF_ERROR
      return {
        moveToInboxError: {
          error,
          variables: {
            newInboxId: newInboxId,
            threadId: threadId
          }
        }
      };
  }
};