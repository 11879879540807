import { selectLatestOmnibusTimestamp } from './latestOmnibusTimestampSlice';

/**
 * The timestamps we receive on the THREAD_LISTS_UPDATED message and the omnibusPlaybackTimestamp
 * are not always identical - there can be a minor amount of variation between them.
 * As such we include a margin of error when comparing timestamps
 */
const MARGIN_OF_ERROR_MS = 15;

/**
 * @description This selector will take the timestamp for a realtime message and
 * will return whether that realtime message has already been applied by checking
 * to see if the latestOmnibusTimestamp occurs after the given timestamp.
 */
export function selectIsMessageAlreadyApplied(state, {
  timestamp
}) {
  const latestOmnibusTimestamp = selectLatestOmnibusTimestamp(state);
  return timestamp - MARGIN_OF_ERROR_MS <= latestOmnibusTimestamp + MARGIN_OF_ERROR_MS;
}