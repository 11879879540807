import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  data: 'DESC'
};
export const sortOrderSlice = createSlice({
  name: 'sortOrder',
  initialState,
  reducers: {
    clearSortOrder() {
      return initialState;
    },
    setSortOrder(state, {
      payload: {
        sortOrder
      }
    }) {
      state.data = sortOrder;
    }
  }
});
export const {
  actions,
  getInitialState
} = sortOrderSlice;
export default sortOrderSlice.reducer;