'use es6';

import { connect } from 'react-redux';
import pipe from 'transmute/pipe';
import ThreadWithDeletedMessagesBanner from './ThreadWithDeletedMessagesBanner';
import { getNumSoftDeletedMessages } from '../selectors/getNumSoftDeletedMessages';
import { hardDeleteMessagesInThread } from '../actions/hardDeleteMessagesInThread';
import { shouldShowDeletedMessagesBanner } from '../selectors/shouldShowDeletedMessagesBanner';
import { viewDeletedMessages } from '../actions/viewDeletedMessages';
import { provideThreadView } from '../../thread-view/decorators/provideThreadView';
const mapDispatchToProps = {
  onClickViewMessages: viewDeletedMessages,
  onClickDeleteMessages: hardDeleteMessagesInThread
};
const mapStateToProps = (state, props) => {
  return {
    numSoftDeletedMessages: getNumSoftDeletedMessages(state, props),
    shouldShowDeletedMessagesBanner: shouldShowDeletedMessagesBanner(state, props)
  };
};
export default pipe(connect(mapStateToProps, mapDispatchToProps), provideThreadView)(ThreadWithDeletedMessagesBanner);