'use es6';

import { getId } from 'conversations-message-history/senders/operators/getters';
export function chooseAssignmentMessage({
  assignorName,
  assigneeName,
  assignorSender,
  assigneeSender,
  activeAgentId,
  date
}) {
  const assignorId = `${getId(assignorSender)}`;
  const assigneeId = `${getId(assigneeSender)}`;
  const activeAgentIsAssignor = activeAgentId === assignorId;
  const activeAgentIsAssignee = activeAgentId === assigneeId;
  if (activeAgentIsAssignor) {
    if (activeAgentIsAssignee) {
      return {
        i18nKey: 'conversations-inbox-message-history.assignment.activeAgentSelfAssignment',
        options: {
          date
        },
        testId: 'assignment-self'
      };
    }
    if (assigneeName) {
      return {
        i18nKey: 'conversations-inbox-message-history.assignment.activeAgentAssignor.default',
        options: {
          assigneeName,
          date
        },
        testId: 'assignment-default'
      };
    }
    return {
      i18nKey: 'conversations-inbox-message-history.assignment.activeAgentAssignor.unknownAssignee',
      options: {
        date
      },
      testId: 'assignment-unknown'
    };
  }
  if (activeAgentIsAssignee) {
    if (assignorName) {
      return {
        i18nKey: 'conversations-inbox-message-history.assignment.activeAgentAssignee.default',
        options: {
          assignorName,
          date
        },
        testId: 'assignee-default'
      };
    }
    return {
      i18nKey: 'conversations-inbox-message-history.assignment.activeAgentAssignee.unknownAssignor',
      options: {
        date
      }
    };
  }
  const nonActiveAgentSelfAssignment = assignorName && assignorId === assigneeId;
  if (nonActiveAgentSelfAssignment) {
    return {
      i18nKey: 'conversations-inbox-message-history.assignment.activeAgentUninvolved.selfAssignment',
      options: {
        assignorName,
        date
      },
      testId: 'assignment-nonactive-self'
    };
  }
  const nonActiveAgentAssignorAndAssignee = assignorName && assigneeName;
  if (nonActiveAgentAssignorAndAssignee) {
    return {
      i18nKey: 'conversations-inbox-message-history.assignment.activeAgentUninvolved.default',
      options: {
        assignorName,
        assigneeName,
        date
      }
    };
  }
  const nonActiveAgentAssigneeWithoutAssignor = !assignorName && assigneeName;
  if (nonActiveAgentAssigneeWithoutAssignor) {
    return {
      i18nKey: 'conversations-inbox-message-history.assignment.activeAgentUninvolved.unknownAssignor',
      options: {
        assigneeName,
        date
      }
    };
  }
  const nonActiveAgentAssignorWithoutAssignee = assignorName && !assigneeName;
  if (nonActiveAgentAssignorWithoutAssignee) {
    return {
      i18nKey: 'conversations-inbox-message-history.assignment.activeAgentUninvolved.unknownAssignee',
      options: {
        assignorName,
        date
      },
      testId: 'assignment-nonactive-unknown'
    };
  }
  return {
    i18nKey: 'conversations-inbox-message-history.assignment.activeAgentUninvolved.unknownBoth',
    options: {
      date
    }
  };
}