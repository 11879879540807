import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["__typename"];
export function isDeal(data) {
  var _data$crmObject;
  return (data === null || data === void 0 || (_data$crmObject = data.crmObject) === null || _data$crmObject === void 0 ? void 0 : _data$crmObject.__typename) === 'Deal';
}
export function isInvoice(data) {
  var _data$crmObject2;
  return (data === null || data === void 0 || (_data$crmObject2 = data.crmObject) === null || _data$crmObject2 === void 0 ? void 0 : _data$crmObject2.__typename) === 'Invoice';
}
export function isQuote(data) {
  var _data$crmObject3;
  return (data === null || data === void 0 || (_data$crmObject3 = data.crmObject) === null || _data$crmObject3 === void 0 ? void 0 : _data$crmObject3.__typename) === 'Quote';
}
export function getProperty(property, data, fallback = null) {
  var _data$defaultProperti, _data$defaultProperti2, _data$defaultProperti3;
  // @ts-expect-error cannot narrow defaultProperties[property]
  // because 'CRMObject' is a union
  if ((data === null || data === void 0 || (_data$defaultProperti = data.defaultProperties) === null || _data$defaultProperti === void 0 || (_data$defaultProperti = _data$defaultProperti[property]) === null || _data$defaultProperti === void 0 ? void 0 : _data$defaultProperti.value) === '') {
    return null;
  }
  // @ts-expect-error cannot narrow defaultProperties[property]
  // because 'CRMObject' is a union
  return (_data$defaultProperti2 = data === null || data === void 0 || (_data$defaultProperti3 = data.defaultProperties) === null || _data$defaultProperti3 === void 0 || (_data$defaultProperti3 = _data$defaultProperti3[property]) === null || _data$defaultProperti3 === void 0 ? void 0 : _data$defaultProperti3.value) !== null && _data$defaultProperti2 !== void 0 ? _data$defaultProperti2 : fallback;
}
export function getProperties(data) {
  if (!(data !== null && data !== void 0 && data.defaultProperties)) {
    return {};
  }
  const _data$defaultProperti4 = data.defaultProperties,
    properties = _objectWithoutPropertiesLoose(_data$defaultProperti4, _excluded);
  return Object.keys(properties).reduce((acc, property) => {
    acc[property] = getProperty(property, data);
    return acc;
  }, {});
}