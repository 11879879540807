/*  @ts-expect-error Jun-5-2024, 11:9UTC FixMe: Complete Migration to TypeScript  */
import { makeAsyncTitle, makeAsyncCard } from 'customer-data-sidebar/async';
import { withUniversalSidebarCardQuery } from './universal/withUniversalSidebarCardQuery';
import { default as FeedbackCardContentGqlConfig, FeedbackCardTitleGqlConfig } from './pastFeedbackGqlConfig';
export default {
  Content: withUniversalSidebarCardQuery(makeAsyncCard({
    loader: () => import( /* webpackChunkName: "past-feedback-card-content" */'card-past-feedback-lib/crm_ui/sidebar/layouts/card/containers/PastFeedbackCardContentContainer')
  }), {
    gqlConfig: FeedbackCardContentGqlConfig,
    key: 'FeedbackCard'
  }),
  Title: withUniversalSidebarCardQuery(makeAsyncTitle({
    loader: () => import( /* webpackChunkName: "past-feedback-card-title" */'card-past-feedback-lib/crm_ui/sidebar/layouts/card/components/PastFeedbackCardTitle')
  }), {
    gqlConfig: FeedbackCardTitleGqlConfig,
    key: 'FeedbackCardTitle'
  })
};