import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestFailedWithOperator } from 'conversations-async-data/async-data/operators/requestFailedWithOperator';
import { GET_CONTACTS } from '../constants/asyncActionTypes';
import { UNINITIALIZED } from 'conversations-async-data/async-data/constants/asyncStatuses';
import { requestStateUpdate } from 'conversations-async-data/async-data/operators/requestStateUpdate';
const initialState = new AsyncData({
  data: null
});
export default handleActions({
  [GET_CONTACTS.STARTED](state) {
    return requestStarted(state);
  },
  [GET_CONTACTS.SUCCEEDED](state, action) {
    return requestSucceededWithOperator(() => action.payload.data, state);
  },
  [GET_CONTACTS.FAILED](state) {
    return requestFailedWithOperator(() => null, state);
  },
  [GET_CONTACTS.UNINITIALIZED](state) {
    return requestStateUpdate(UNINITIALIZED, () => null, state);
  }
}, initialState);