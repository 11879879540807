import http from 'hub-http/clients/apiClient';
import { Map as ImmutableMap, List, fromJS } from 'immutable';
const baseUrl = 'knowledge-content/v1/knowledge-articles';
const baseUrlV3 = 'knowledge-content/v2/knowledge-article';
const filterPublished = articles => articles.filter(a => a.currentState === 'PUBLISHED');
const formatResponse = response => {
  // @ts-expect-error ts-migrate FIXME some props don't exist
  const {
    objects,
    offset,
    count,
    total,
    hasMore
  } = response;
  return ImmutableMap({
    hasMore: hasMore || false,
    offset: offset || 0,
    count: count || objects.length,
    total: total || objects.length,
    results: List(filterPublished(objects)).map(article => fromJS(article))
  });
};
export const defaultApiWrapper = (apiClient, useVersion, kbId) => ({
  fetchRenderedContent({
    id,
    version: kbVersion
  }) {
    return kbVersion === '3' ? getArticleV3(apiClient, id) : getArticle(apiClient, id);
  },
  async searchArticles({
    count,
    offset,
    query
  }) {
    if (kbId) {
      const response = useVersion === '3' ? await getArticlesV3({
        apiClient,
        count,
        kbId,
        offset,
        query
      }) : await getArticles(apiClient, count, offset, query, kbId);
      //  @ts-expect-error passed all the types that it needs
      return formatResponse(response);
    }
    if (useVersion === '3') {
      const response = await getArticles(apiClient, count, offset, query);
      const responseV3 = await getArticlesV3({
        apiClient,
        count,
        offset,
        query
      });
      return formatResponse({
        //  @ts-expect-error passed all the types that it needs
        objects: [...response.objects, ...responseV3.objects]
      });
    }
    return formatResponse(await getArticles(apiClient, count, offset, query));
  }
});
export const searchArticles = ({
  count,
  offset,
  query
}) => {
  return http.get(baseUrl, {
    query: {
      limit: count,
      offset,
      name__icontains: query,
      order: '-updated',
      state__eq: 'PUBLISHED',
      archived: false
    }
  }).then(formatResponse);
};
async function getArticle(apiClient, id) {
  const results = await apiClient.get(baseUrl, {
    query: {
      id
    }
  });
  const article = results.objects[0];
  const {
    absoluteUrl,
    title
  } = article;
  return {
    body: title,
    htmlBody: `<a href=${absoluteUrl}>${title}</p>`
  };
}
async function getArticleV3(apiClient, id) {
  const {
    values: article
  } = await apiClient.get(`${baseUrlV3}/${id}`);
  return {
    body: article.hs_name,
    htmlBody: `<a href=${article.hs_absolute_url}>${article.hs_name}</p>`
  };
}
async function getArticles(apiClient, count, offset, query, kbId) {
  const response = await apiClient.get(baseUrl, {
    query: Object.assign({
      limit: count,
      offset,
      name__icontains: query,
      order: '-updated',
      state__eq: 'PUBLISHED',
      archived: false
    }, kbId ? {
      contentGroupId: kbId
    } : {})
  });
  response.objects = response.objects.map(a => Object.assign({}, a, {
    version: '2'
  }));
  return response;
}
async function getArticlesV3({
  apiClient,
  count,
  kbId,
  offset,
  query
}) {
  const response = await apiClient.get(baseUrlV3, {
    query: Object.assign({
      hs_archived_in_dashboard__eq: 0,
      hs_name__icontains: query,
      hs_published_at__gt: 0,
      limit: count,
      offset,
      order: '-hs_updated_at'
    }, kbId ? {
      hs_group_id__eq: kbId
    } : {})
  });
  const mapped = response.results.map(({
    values: article
  }) => {
    return {
      currentState: article.hs_state,
      contentGroupId: article.hs_group_id,
      id: article.hs_id,
      title: article.hs_name,
      version: '3'
    };
  });
  return {
    objects: mapped
  };
}