import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["__typename"];
import { createSlice } from '@reduxjs/toolkit';
const INITIAL_STATE = {
  paymentsEligibility: {
    payoutsEnabled: false,
    chargesEnabled: false,
    processorType: 'NONE',
    status: 'ACCOUNT_EXISTS',
    underwritingStatus: null,
    errorCode: null
  },
  userPermissions: {
    currentUserCanEdit: false,
    currentUserCanView: false,
    currentUserCanDelete: false
  }
};
export const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    setPermissions(state, action) {
      const {
        currentUserCanDelete,
        currentUserCanEdit,
        currentUserCanView
      } = action.payload.crmObject.userPermissions;
      state.userPermissions = {
        currentUserCanDelete,
        currentUserCanEdit,
        currentUserCanView
      };
      const _action$payload$payme = action.payload.paymentsEligibility,
        rest = _objectWithoutPropertiesLoose(_action$payload$payme, _excluded);
      state.paymentsEligibility = rest;
      return state;
    }
  }
});
export const {
  setPermissions
} = authSlice.actions;
export default authSlice.reducer;