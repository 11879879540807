import { Record } from 'immutable';
import TypicalResponseTime from '../../typical-response-time/records/TypicalResponseTime';
import { ALWAYS } from '../constants/AvailabilityStrategies';
class AlwaysAvailableConfig extends Record({
  '@type': ALWAYS,
  typicalResponseTime: TypicalResponseTime()
}, 'AlwaysAvailableConfig') {
  constructor(properties = {}) {
    properties = properties || {};
    super(Object.assign({}, properties, {
      typicalResponseTime: properties.typicalResponseTime ? TypicalResponseTime(properties.typicalResponseTime) : null
    }));
  }
}
export default AlwaysAvailableConfig;