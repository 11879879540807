import { FETCH_OUT_OF_THE_OFFICE_STATUS } from './ActionTypes';
import { fetchOutOfTheOfficeStatus } from '../utils/api/OutOfTheOfficeApi';
export const fetchOutOfTheOfficeStatusInfo = hubspotterId => {
  return dispatch => {
    dispatch({
      type: FETCH_OUT_OF_THE_OFFICE_STATUS,
      meta: 'request'
    });
    return fetchOutOfTheOfficeStatus(hubspotterId).then(payload => dispatch({
      type: FETCH_OUT_OF_THE_OFFICE_STATUS,
      meta: 'response',
      payload
    }), payload => dispatch({
      type: FETCH_OUT_OF_THE_OFFICE_STATUS,
      error: true,
      payload
    }));
  };
};