import http from 'conversations-http/clients/http';
export const fetchThreadListMemberWithTimeline = ({
  threadId,
  ticketId
}) => http.get(`cv-tickets-timeline/v3/ticket/timeline/thread/${threadId}`, {
  query: {
    expectedResponseType: 'WRAPPER_V2',
    includeDeletedHistory: false,
    historyLimit: 30,
    ticketId
  }
});