'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import { getId, getSenderType } from 'conversations-message-history/senders/operators/getters';
import { buildSenderLocator } from 'conversations-message-history/senders/operators/buildSenderLocator';
export const indexSenders = (senders = List()) => {
  return senders.reduce((accumulator, sender) => {
    const senderId = getId(sender);
    const senderType = getSenderType(sender);
    const senderLocator = buildSenderLocator(senderType, senderId);
    return accumulator.set(senderLocator, sender);
  }, ImmutableMap());
};