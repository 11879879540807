import { createSelector } from 'reselect';
import get from 'transmute/get';
import { selectFilterResults } from './selectFilterResults';
const getThreadIdFromProps = (state, {
  threadId
}) => threadId;
export const selectFilteredThreadListMemberWithThreadId = createSelector([getThreadIdFromProps, selectFilterResults], (threadId, filterResults) => {
  const indexedMembers = filterResults.data.indexedMembers;
  return get(
  // @ts-expect-error FIXME: type of threadId in ThreadListMemberProps should get corrected
  threadId, indexedMembers);
});