// copied from: https://git.hubteam.com/HubSpot/ui-addon-conversations-settings/blob/3c89aee1836bb1efd278f39f1bb316d4f77c6c81/static/js/users-and-teams/operators/getTextFromSearchObject.ts

export const getTextFromSearchObject = word => {
  const {
    text: {
      props: {
        children: [{
          props: {
            children: text
          }
        }]
      }
    }
  } = word;
  return text;
};