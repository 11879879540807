import http from 'conversations-http/clients/http';
import { getSortOrder, getFilterValues } from '../../search-and-filter-schema/protected';
import { convertFiltersToSearchArgs } from './convertFiltersToSearchArgs';
const DEFAULT_LIMIT = 100;
export const fetchFilteredThreadListMembers = ({
  filterKey,
  offsetTimestamp = null,
  offsetId = null,
  inboxId
}) => {
  const {
    assignment = null,
    channelInstanceId = null,
    endTimestamp = null,
    hideFiltered = true,
    searchQuery = null,
    startTimestamp = null,
    genericChannelIds = null,
    threadStatus = null,
    vids = null
  } = convertFiltersToSearchArgs(getFilterValues(filterKey));
  return http.put('messages/v2/threadlist/members/page', {
    query: {
      limit: DEFAULT_LIMIT,
      offsetTimestamp,
      offsetId,
      maxPreviewLength: 200
    },
    data: {
      assignment,
      originalChannelInstanceId: channelInstanceId,
      endTimestamp,
      hideFiltered,
      inboxId,
      searchQuery,
      sortDirection: getSortOrder(filterKey),
      startTimestamp,
      genericChannelIds,
      threadStatus,
      vids
    }
  });
};