import I18n from 'I18n';
import { getSubject } from 'conversations-message-history/common-message-format/operators/emailMetadataGetters';
// @ts-expect-error module not typed
import { isFormCMF } from 'conversations-message-history/common-message-format/operators/cmfComparators';
export const getEmailForwardSubject = message => {
  if (message) {
    const replySubject = getSubject(message);
    if (!replySubject) {
      return null;
    }
    const prefix = I18n.text('composer-data.email-metadata.subject-prefixes.forward');
    const lowerCaseReplySubject = replySubject.toLowerCase();
    const lowerCasePrefix = prefix.toLowerCase();
    return isFormCMF(message) || lowerCaseReplySubject.indexOf(lowerCasePrefix) === 0 ? replySubject : `${prefix}${replySubject}`;
  }
  return null;
};