import isBoolean from 'hs-lodash/isBoolean';
// TODO: move to composer-data
import invariant from 'conversations-thread-data/utils/invariant';
export const whatsAppReplyLimit = ({
  hasReachedWhatsAppLimit
}) => {
  invariant(isBoolean(hasReachedWhatsAppLimit), 'hasReachedWhatsAppLimit is required for whatsAppReplyLimit disabling strategy');
  return hasReachedWhatsAppLimit ? {
    disabled: true,
    sendDisabledReason: 'WHATS_APP_LIMIT_REACHED'
  } : null;
};