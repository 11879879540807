import promiseDone from 'hs-promise-utils/promiseDone';
const INITIAL_DELAY = 0;
const DELAY_INTERVAL = 60000;
const MAX_DELAY = 300000;
const noop = () => {};
export default class BackoffOnFailure {
  constructor(sendHeartbeat, {
    initialDelay = INITIAL_DELAY,
    delayInterval = DELAY_INTERVAL,
    maxDelay = MAX_DELAY
  } = {}) {
    this.initialDelay = initialDelay;
    this.delayInterval = delayInterval;
    this.maxDelay = maxDelay;
    this.sendHeartbeat = sendHeartbeat;
    this.timeout = null;
  }
  computeNextDelay(delay, hasFailed) {
    let nextDelay = delay;
    if (hasFailed && delay < this.maxDelay) {
      nextDelay += this.delayInterval;
      nextDelay = nextDelay > this.maxDelay ? this.maxDelay : nextDelay;
    } else if (!hasFailed) {
      nextDelay = this.delayInterval;
    }
    return nextDelay;
  }
  stop() {
    window.clearTimeout(this.timeout);
    this.timeout = null;
  }
  scheduleHeartbeat(delay, onSuccess, onFailure) {
    let hasFailed = false;
    this.timeout = window.setTimeout(() => {
      promiseDone(this.sendHeartbeat().then(() => {
        onSuccess();
      }).catch(() => {
        hasFailed = true;
        onFailure();
      }).finally(() => {
        if (this.timeout) {
          this.scheduleHeartbeat(this.computeNextDelay(delay, hasFailed), onSuccess, onFailure);
        }
      }));
    }, delay);
  }
  start(onSuccess = noop, onFailure = noop) {
    this.stop();
    this.scheduleHeartbeat(this.initialDelay, onSuccess, onFailure);
  }
}