import { isViewMemberProperty } from 'find-and-filter-data/view-members-schema/public';
import reduce from 'transmute/reduce';
import set from 'transmute/set';
import { resolveSeenFromPartial } from './resolveSeenFromPartial';

/**
 * @description This function will merge the new properties from the partial member
 * param into the existing viewMember record, and will also update the `seen` status
 * based on the partialMember update.
 * @param viewMember
 * @param partialMember
 */
export function applyPartialToViewMember(viewMember, partialMember) {
  const isMemberSeen = resolveSeenFromPartial(viewMember, partialMember);
  const merged = reduce(viewMember,
  // @ts-expect-error The reduce function's aggregator type is incorrect; it does not supply keys as the third function parameter
  (memberRecord, value, key) => {
    return isViewMemberProperty(key) ? set(key, value, memberRecord) : memberRecord;
  }, partialMember);
  return set('seen', isMemberSeen, merged);
}