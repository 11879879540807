import { buildAudit } from 'conversations-message-history/audit/operators/buildAudit';
import { ThreadUpdate } from 'find-and-filter-data/realtime-view-member-schema/public';
import { buildPartialThreadListMembers } from '../../../view-members-schema/protected';
export const buildThreadUpdate = attributes => {
  const {
    audit,
    members
  } = attributes;
  return ThreadUpdate({
    audit: buildAudit(audit),
    members: buildPartialThreadListMembers(members)
  });
};