'use es6';

import { secureDocument } from './secureDocument';
const isOutlookEmailWithChildren = (node, nodeName) => nodeName === 'p' && node && node.classList && node.classList.contains('MsoNormal') && node.children && node.children.length;
const isEmptyBrokenParagraph = node => node && !node.children.legnth && node.tagName.toLowerCase() === 'o:p' && node.innerHTML.includes('&nbsp;');
const isSpanWithBrokenParagraph = child => child && child.tagName.toLowerCase() === 'span' && isEmptyBrokenParagraph(child.firstElementChild);

// https://issues.hubspotcentral.com/browse/CRMMAIL-3503
const hasImage = node => !!node.getElementsByTagName('img').length;
export const removeOutlookLineBreaks = ({
  node,
  node_name
}) => {
  if (isOutlookEmailWithChildren(node, node_name)) {
    const child = node.firstElementChild;
    if (isEmptyBrokenParagraph(child) || isSpanWithBrokenParagraph(child)) {
      if (hasImage(node)) {
        node.removeChild(child);
        return removeOutlookLineBreaks({
          node,
          node_name: 'p'
        });
      }
      return {
        node: secureDocument.createElement('br')
      };
    }
    const div = secureDocument.createElement('div');
    Object.keys(node.attributes).forEach(key => {
      const attribute = node.attributes[key];
      div.setAttribute(attribute.nodeName, attribute.nodeValue);
    });
    div.innerHTML = node.innerHTML.trim().replace(/&nbsp;/g, '');
    return {
      node: div
    };
  }
  return null;
};