// gimme: conversations-inbox-ui 05/26/2020 741c62dc71ca742adbc6d64d61d30de7cb46539f
'use es6';

import { useContext } from 'react';
import { getAsyncThreadHistory } from '../selectors/getAsyncThreadHistory';
import { fetchAgentHistory } from '../actions/fetchAgentHistory';
import { useReduxAsyncFetcher } from 'conversations-async-data/async-data/hooks/useReduxAsyncFetcher';
import { noOpAction } from 'conversations-thread-data/utils/noOpAction';
import { DeletedMessagesContext } from '../../thread-deletion/context/DeletedMessagesContext';
import { deferredAsyncDataSelector } from 'conversations-thread-data/utils/deferredAsyncDataSelector';
export const useThreadHistory = ({
  deferred = false,
  threadId
}) => {
  const {
    showDeletedMessages
  } = useContext(DeletedMessagesContext);
  const {
    asyncData
  } = useReduxAsyncFetcher({
    action: deferred ? noOpAction : fetchAgentHistory,
    selector: deferred ? deferredAsyncDataSelector : getAsyncThreadHistory,
    id: threadId,
    idTransform: id => ({
      threadId: id,
      includeDeletedMessages: showDeletedMessages
    })
  });
  return {
    asyncThreadHistory: asyncData
  };
};