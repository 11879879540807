import { Metrics } from '../util/Metrics';
export const logDuplicatePendingMetricError = ({
  metricName,
  originalDimensions,
  newDimensions
}) => {
  Metrics.counter('duplicate metric initiated', Object.assign({
    metricName
  }, originalDimensions && {
    originalDimensions: JSON.stringify(originalDimensions)
  }, newDimensions && {
    newDimensions: JSON.stringify(newDimensions)
  }));
};