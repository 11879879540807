export const getUpdatedTicketPipeline = ({
  pipeline
}) => ({
  key: 'updated-ticket-pipeline-from-thread-view',
  properties: {
    action: 'updated ticket pipeline from thread view',
    pipeline
  }
});
export const getUpdatedTicketStage = ({
  stage
}) => ({
  key: 'updated-ticket-stage-from-thread-view',
  properties: {
    action: 'updated ticket stage from thread view',
    stage
  }
});
export const getClosedTicket = ({
  stage
}) => ({
  key: 'closed-ticket-from-thread-view',
  properties: {
    action: 'closed ticket from thread view',
    stage
  }
});