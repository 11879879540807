export const getInboxZeroState = ({
  subscreen,
  zeroStateName
}) => ({
  key: 'inbox-zero-state',
  properties: {
    action: 'inbox zero state',
    'zero-state-name': zeroStateName,
    subscreen
  }
});
export const clickedToConnectEmail = {
  key: 'clicked-to-connect-email',
  properties: {
    action: 'clicked to connect email'
  }
};
export const clickedToConnectChat = {
  key: 'clicked-to-connect-chat',
  properties: {
    action: 'clicked to connect',
    channel: 'Livechat'
  }
};
export const clickedToConnectMessenger = {
  key: 'clicked-to-connect-fbm',
  properties: {
    action: 'clicked to connect',
    channel: 'Facebook Messenger'
  }
};
export const clickedToConnectChannel = {
  key: 'clicked-to-connect-channel',
  properties: {
    action: 'clicked to connect channel'
  }
};
export const clickedToConnectCalling = {
  key: 'clicked-to-connect-calling',
  properties: {
    action: 'clicked to connect calling'
  }
};