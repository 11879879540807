import { getId, getThreadCount, getUnreadThreadCount } from 'find-and-filter-data/views-schema/public';
import { getRealtimeViewCount } from './counts/getRealtimeViewCount';
import { isViewUnread } from './unread/isViewUnread';
export function getViewCountAndStatus(view, {
  countOffsets,
  declarativeCountsMap,
  unreadCountOffsets,
  trackedThreads
}) {
  const viewId = getId(view);
  const count = getRealtimeViewCount(viewId, getThreadCount(view), countOffsets);
  const declarativeCount = declarativeCountsMap.get(viewId, undefined);
  const hasUnread = isViewUnread(viewId, getUnreadThreadCount(view), unreadCountOffsets, trackedThreads);
  return {
    count: declarativeCount === undefined ? count : declarativeCount,
    hasUnread
  };
}