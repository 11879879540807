import isPortalSpecificObjectType from 'customer-data-objects/crmObject/isPortalSpecificObjectType';
import { useCrmLinksContext } from '../../common/context/internal/CrmLinksContext';
import { FetchStatuses, isResolved, isRejected } from '../../common/types/FetchStatus';

/**
 * Fetches `crmPageEditor` FAS data and determines whether the page editor is available for the
 * location and objectTypeId provided. Note: there are some locations that aren't directly customizable but
 * are indirectly customizable. This hook will return false for those locations.
 *
 * Example: A user can customize the middle column (CRM_RECORD_MIDDLE) indirectly via the full record page
 * editor (CRM_RECORD_PAGE). This hook returns false for CRM_RECORD_MIDDLE but true for CRM_RECORD_PAGE.
 *
 *
 * @param objectTypeId
 * @param location
 * @returns True if object type and location combination are available in the page editor.
 */
export const useObjectTypeSupportsCustomization = (objectTypeId, location) => {
  const allAppSettingsState = useCrmLinksContext();
  if (isPortalSpecificObjectType(objectTypeId)) {
    return {
      status: FetchStatuses.RESOLVED,
      data: true
    };
  }
  if (isResolved(allAppSettingsState)) {
    const appSettings = allAppSettingsState.data.get(objectTypeId);

    // If a Framework App Settings entry does not exist for the given object type
    // or its `enabled` value is false, or location is not present it does not support a record page.
    // If `enabled` is true and location is present, it does.
    const pageEditorSettings = appSettings && appSettings.crmPageEditor && appSettings.crmPageEditor.metadata;
    const pageEditorSettingsEnabled = pageEditorSettings && pageEditorSettings.enabled && pageEditorSettings.enabled.value;
    const pageEditorSettingsLocations = pageEditorSettings && pageEditorSettings.locations && pageEditorSettings.locations.value;
    const canCustomizeObjectType = pageEditorSettingsEnabled && pageEditorSettingsLocations && pageEditorSettingsLocations.includes(location);
    return {
      status: FetchStatuses.RESOLVED,
      data: !!canCustomizeObjectType
    };
  }
  if (isRejected(allAppSettingsState)) {
    return {
      status: FetchStatuses.REJECTED,
      data: null,
      error: allAppSettingsState.error
    };
  }
  return {
    status: allAppSettingsState.status,
    data: null
  };
};