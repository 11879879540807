import { useMemo } from 'react';
import { registerQuery, useQuery } from 'data-fetching-client';
import fetchUser from '../clients/fetchUser';
const FETCH_USER = registerQuery({
  fieldName: 'user',
  args: ['userId'],
  fetcher: fetchUser
});
export const useUser = ({
  userId
}) => {
  const {
    data,
    error,
    loading
  } = useQuery(FETCH_USER, {
    variables: {
      userId: userId
    },
    skip: !userId
  });
  const user = useMemo(() => {
    if (loading || error || !data || data.user.length < 1) {
      return null;
    }
    return data.user[0];
  }, [data, loading, error]);
  return {
    user,
    loading,
    error
  };
};