import { ALLOWED_FILE_TYPES } from 'conversations-internal-schema/file-metadata/constants/allowedFileTypes';
import { UNSUPPORTED_TYPE } from '../constants/errorTypes';
function checkExtensionForMimetype(fileName) {
  try {
    const fileParts = fileName === null || fileName === void 0 ? void 0 : fileName.split('.');
    if (fileParts.length < 2) return UNSUPPORTED_TYPE;
    const extension = fileParts.pop();
    switch (extension) {
      case 'dwg':
        return 'image/vnd.dwg';
      default:
        return UNSUPPORTED_TYPE;
    }
  } catch (e) {
    return UNSUPPORTED_TYPE;
  }
}
function isMimetypeAllowed(mimetype, allowedMimetypes) {
  try {
    return allowedMimetypes.some(allowedMimetype => {
      if (allowedMimetype === mimetype) return true;
      const matchAll = allowedMimetype.match(/^([a-z]+)\/\*$/);
      const group = matchAll === null || matchAll === void 0 ? void 0 : matchAll[1];
      return group && mimetype.startsWith(group);
    });
  } catch (e) {
    return false;
  }
}
export function isUnsupportedFileType(file, allowedMimetypes = ALLOWED_FILE_TYPES) {
  const isFileTypeKnown = !!file.type;
  const fileMimetype = isFileTypeKnown ? file.type : checkExtensionForMimetype(file.name);
  const isFileTypeUnsupported = fileMimetype === UNSUPPORTED_TYPE || !isMimetypeAllowed(fileMimetype, allowedMimetypes);
  return isFileTypeUnsupported;
}