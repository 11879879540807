// original: https://git.hubteam.com/HubSpot/conversations-internal-schema/blob/1729733ab4b5cfdd5950af37c67157f5deb9589b/static/js/team/operators/apiTeamSelectionToTree.js
import { Map as ImmutableMap } from 'immutable';
import { getId } from './getters';
const updateNode = (node, selection, isParentSelected = false) => {
  if (!node || !node.size) {
    return node;
  }
  const teamId = getId(node.get('data')) || 0;
  const selected = isParentSelected || selection.includes(teamId);
  const updatedNode = node.set('selected', selected);
  if (updatedNode.get('childTeams') && updatedNode.get('childTeams').size) {
    // @ts-expect-error TypedMap['update'] is not well-typed
    return updatedNode.update('childTeams', childTeams => {
      return childTeams.map(childNode => {
        return updateNode(childNode, selection, selected);
      });
    });
  }
  return updatedNode;
};
export const apiTeamSelectionToTree = (selection, tree) => {
  return tree.reduce((updatedMap, node, key) => {
    return updatedMap.set(key, updateNode(node, selection));
  }, ImmutableMap({}));
};