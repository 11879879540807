'use es6';

import { handleActions } from 'redux-actions';
import { MESSAGE_SENT } from '../constants/actionTypes';
import { THREAD_ID_CHANGED } from 'conversations-thread-data/thread-details/public/constants';
import { PROMPT_WAS_SHOWN } from '../constants/asyncActionTypes';
import SessionData, { setMessageWasSent, setPromptShownInSession } from '../schema/SessionData';
const sessionData = handleActions({
  [MESSAGE_SENT]: setMessageWasSent(true),
  [THREAD_ID_CHANGED]: setMessageWasSent(false),
  [PROMPT_WAS_SHOWN.SUCCEEDED]: setPromptShownInSession(true)
}, SessionData());
export default sessionData;