import get from 'transmute/get';
import { getSortByProperty } from './customViewOperators';
import { ConversationSortByProperties } from './customViewTypes';
/**
 * @description Returns the timestamp ViewOrderBy that aligns with the custom views
 * sortBy.propertyName. In the future we will remove the ViewOrderBy concept and
 * use sortBy for ThreadLists as well, but for now this will map sortBy properties
 * to ViewOrderBy.
 *
 * Returns LATEST_VISITOR_RESPONSE by default, which is the backend behaviour as
 * well.
 */
export const getTimestampOrderTypeFromSortBy = customView => {
  const sortBy = customView && getSortByProperty(customView);
  const propertyName = sortBy && get('propertyName', sortBy);
  switch (propertyName) {
    case ConversationSortByProperties.LatestMessageTimestamp:
      return 'LATEST_MESSAGE';
    case ConversationSortByProperties.LatestSentTimestamp:
      return 'LATEST_AGENT_RESPONSE';
    case ConversationSortByProperties.LatestReceivedTimestamp:
    default:
      return 'LATEST_VISITOR_RESPONSE';
  }
};