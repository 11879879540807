export const CREATE_DEAL_SUCCESS = 'CREATE_DEAL_SUCCESS';
export const CREATE_DEAL_FAILED = 'CREATE_DEAL_FAILED';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_FAILED = 'CREATE_TICKET_FAILED';
export const ASSOCIATE_DEAL = 'ASSOCIATE_DEAL';
export const ASSOCIATE_DEAL_SUCCEEDED = 'ASSOCIATE_DEAL_SUCCEEDED';
export const ASSOCIATE_DEAL_FAILED = 'ASSOCIATE_DEAL_FAILED';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_CONTACT_SUCCEEDED = 'UPDATE_CONTACT_SUCCEEDED';
export const UPDATE_CONTACT_FAILED = 'UPDATE_CONTACT_FAILED';
export const GET_FAVORITE_CONTACT_PROPERTIES = 'GET_FAVORITE_CONTACT_PROPERTIES';
export const GET_FAVORITE_CONTACT_PROPERTIES_SUCCEEDED = 'GET_FAVORITE_CONTACT_PROPERTIES_SUCCEEDED';
export const GET_FAVORITE_CONTACT_PROPERTIES_FAILED = 'GET_FAVORITE_CONTACT_PROPERTIES_FAILED';
export const SAVE_CRM_USER_SETTINGS = 'SAVE_CRM_USER_SETTINGS';
export const SAVE_CRM_USER_SETTINGS_SUCCEEDED = 'SAVE_CRM_USER_SETTINGS_SUCCEEDED';
export const SAVE_CRM_USER_SETTINGS_FAILED = 'SAVE_CRM_USER_SETTINGS_FAILED';
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const OPTIMISTIC_CONTACT_ASSOCIATION = 'OPTIMISTIC_CONTACT_ASSOCIATION';