import { useApolloClient } from '@apollo/client';
import I18n from 'I18n';
import { useCallback, useMemo } from 'react';
import { getAssigneeFromCache } from 'conversations-thread-data/assignees/public/operators';
import { getAssociatedTicketIds } from 'conversations-thread-data/thread-details/public/operators';
import { getAssignedThread } from 'conversations-thread-view/usage-tracking/utils/threadInteraction';
import { trackEvent } from '../../usage-tracking/clients/usageTracker';
import { alertError } from '../operators/alertError';
import { startReassignPerf } from '../operators/performance-tracking';
const alertReassignmentError = error => {
  alertError({
    error,
    message: I18n.text('conversations-inbox-ui.reassignment.reassignmentError.message'),
    titleText: I18n.text('conversations-inbox-ui.reassignment.reassignmentError.title')
  });
};
const useAssignToTeam = ({
  routeToTeam,
  threadId,
  unassignThread
}) => useCallback(teamUpdate => {
  return unassignThread({
    variables: {
      threadId
    }
  }).then(() => routeToTeam({
    variables: {
      threadId,
      data: teamUpdate
    }
  })).catch(alertReassignmentError);
}, [routeToTeam, threadId, unassignThread]);
const useAssignToUser = ({
  reassignThread,
  threadId
}) => {
  const apolloClient = useApolloClient();
  return useCallback(assignee => {
    const assigneeId = `${assignee.id}`;
    const updatedAssignee = getAssigneeFromCache(apolloClient, {
      assigneeId: btoa(assigneeId)
    });
    startReassignPerf();
    return reassignThread({
      optimisticResponse: {
        assignConversationAssignee: {
          updatedAssignee,
          userErrors: null
        }
      },
      variables: {
        actorId: `A-${assigneeId}`,
        threadId
      }
    }).catch(alertReassignmentError);
  }, [apolloClient, reassignThread, threadId]);
};
const useUnassign = ({
  threadId,
  unassignThread
}) => useCallback(() => {
  startReassignPerf();
  return unassignThread({
    optimisticResponse: {
      unassignConversationAssignee: {
        updatedAssignee: null,
        userErrors: null
      }
    },
    variables: {
      threadId
    }
  }).catch(alertReassignmentError);
}, [threadId, unassignThread]);
export const useReassignment = ({
  genericChannelId,
  reassignThread,
  routeToTeam,
  threadDetails,
  threadId,
  unassignThread
}) => {
  const assignToTeam = useAssignToTeam({
    routeToTeam,
    threadId,
    unassignThread
  });
  const assignToUser = useAssignToUser({
    reassignThread,
    threadId
  });
  const unassign = useUnassign({
    threadId,
    unassignThread
  });
  const hasAssociatedTicket = useMemo(() => {
    const associatedTicketIds = getAssociatedTicketIds(threadDetails);
    return associatedTicketIds ? associatedTicketIds.size > 0 : false;
  }, [threadDetails]);
  return useCallback((type, payload) => {
    trackEvent(getAssignedThread({
      assignee: type,
      genericChannelId,
      hasAssociatedTicket
    }));
    if (type === 'team-assigned') {
      return assignToTeam(payload);
    } else if (type !== 'unassigned') {
      return assignToUser(payload);
    }
    return unassign();
  }, [assignToTeam, assignToUser, genericChannelId, hasAssociatedTicket, unassign]);
};