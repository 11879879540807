import Raven from 'raven-js';
export function getRefundDisabledReasonsMessageKey(refundDisabledReason, paymentMethod) {
  const getPaymentProcessingTranslation = paymentMethodType => {
    switch (paymentMethodType) {
      case 'ach':
        return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.paymentProcessing.ach';
      case 'bacs':
        return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.paymentProcessing.bacs';
      case 'pads':
        return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.paymentProcessing.pads';
      case 'sepa':
        return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.paymentProcessing.sepa';
      default:
        return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.paymentProcessing.default';
    }
  };
  const getRefundDeadlineExceededTranslation = paymentMethodType => {
    switch (paymentMethodType) {
      case 'ach':
      case 'bacs':
      case 'pads':
      case 'sepa':
        return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.refundDeadlineExceeded.180Days';
      default:
        return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.refundDeadlineExceeded.default';
    }
  };
  switch (refundDisabledReason) {
    case 'payment_processing':
      return getPaymentProcessingTranslation(paymentMethod);
    case 'refund_processing':
      return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.refundProcessing';
    case 'fully_refunded':
      return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.fullyRefunded';
    case 'refund_deadline_exceeded':
      return getRefundDeadlineExceededTranslation(paymentMethod);
    case 'payment_failed':
      return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.paymentFailed';
    default:
      Raven.captureMessage('Refund disabled reason not listed.');
      return 'cardPaymentHighlight.paymentHighlight.sidebarActions.refundPayment.refundDisabledReason.default';
  }
}