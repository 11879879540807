import http from 'hub-http/clients/apiClient';
import { registerQuery, useQuery } from 'data-fetching-client';
const FetchAutoAssociationsForActivity = registerQuery({
  fieldName: 'autoAssociationsData',
  args: ['subjectObjectTypeId', 'subjectObjectId', 'engagementType', 'includedObjects'],
  fetcher: ({
    subjectObjectTypeId,
    subjectObjectId,
    engagementType,
    includedObjects
  }) => http.post('/crm-engagement-auto-associations/v3', {
    data: {
      subjectObjectTypeId,
      subjectObjectId,
      engagementType,
      includedObjects
    }
  })
});
export const useFetchAutoAssociations = ({
  subjectObjectTypeId,
  subjectObjectId,
  engagementType,
  shouldFetch
}) => {
  return useQuery(FetchAutoAssociationsForActivity, {
    variables: {
      subjectObjectTypeId: subjectObjectTypeId || '',
      // NOTE: query is skipped if subjectObjectTypeId is undefined
      subjectObjectId: subjectObjectId || '',
      // NOTE: query is skipped if subjectObjectId is undefined
      engagementType,
      includedObjects: {}
    },
    fetchPolicy: 'network-only',
    skip: !shouldFetch || !subjectObjectTypeId || !subjectObjectId || !engagementType
  });
};