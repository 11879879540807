// original: https://git.hubteam.com/HubSpot/conversations-internal-schema/blob/1729733ab4b5cfdd5950af37c67157f5deb9589b/static/js/team/operators/treeToSelectedValues.js
import { List as ImmutableList } from 'immutable';
const traverse = (node, key, list) => {
  if (node.get('selected')) {
    list.push(parseInt(key, 10));
  }
  node.get('childTeams').forEach((childTeam, childKey) => {
    traverse(childTeam, childKey, list);
  });
};
export const treeToSelectedValues = teamTree => {
  const list = [];
  if (!teamTree || !teamTree.size) {
    return ImmutableList(list);
  }
  teamTree.forEach((node, key) => {
    traverse(node, key, list);
  });
  return ImmutableList(list);
};