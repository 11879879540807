import http from 'conversations-http/clients/http';
import { HELPDESK } from '../../shared/constants/workspaces';
const MESSAGES_API_PREFIX = 'cv/inbox/settings/v1';
export function fetchAgentStatus(workspace) {
  // This endpoint is actually a combination of get and create. If agentStatus does not exist for the current user , BE will inserts a new object into the database.
  return http.post(`${MESSAGES_API_PREFIX}/agent/status`, {
    data: {},
    headers: workspace === HELPDESK ? {
      'X-Properties-Source': 'HELP_DESK'
    } : {}
  });
}
export function updateAwayMode(awayMode, workspace) {
  return http.put(`${MESSAGES_API_PREFIX}/agent/status/away-mode`, {
    data: awayMode,
    headers: workspace === HELPDESK ? {
      'X-Properties-Source': 'HELP_DESK'
    } : {}
  });
}