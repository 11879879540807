import { combineReducers } from 'redux';
import actionStates from '../taxonomy/reducers/actionStates';
import errorState from '../escalations/reducers/errorState';
import escalation from '../escalations/reducers/escalation';
import escalationMeta from '../escalations/reducers/escalationMeta';
import loadingState from '../escalations/reducers/loadingState';
import outOfTheOffice from '../shared/reducers/outOfTheOffice';
import propertiesMeta from '../taxonomy/reducers/propertiesMeta';
import propertyUpdateStatus from '../taxonomy/reducers/propertyUpdateStatus';
import savingState from '../escalations/reducers/savingState';
import threadProperties from '../taxonomy/reducers/threadProperties';
import toolLinks from '../tools/reducers/toolLinks';
export default combineReducers({
  actionStates,
  errorState,
  escalation,
  escalationMeta,
  loadingState,
  outOfTheOffice,
  propertiesMeta,
  propertyUpdateStatus,
  savingState,
  threadProperties,
  toolLinks
});