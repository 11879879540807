// @ts-expect-error dependency missing types
import { catchAndRethrowNetworkError } from 'crm_data/bCommerce/monitoring/errorUtils';
import http from 'hub-http/clients/apiClient';
import { handleError } from '../../utils/errorHandling';
export const fetchDashboards = () => {
  return http.get('dashboard/v2/dashboard').catch(e => {
    handleError(e, {
      stacktrace: true
    });
    return null;
  }).catch(catchAndRethrowNetworkError);
};