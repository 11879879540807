import { connect } from 'react-redux';
import { push } from '../routing/actions/push';
import { setWindowVisible } from '../actions/WindowActions';
import { getGates } from '../selectors/gateSelectors';
import Application from '../components/Application';
import { hasConnectedEmailDataAccess as hasConnectedEmailDataAccessSelector } from '../selectors/userSelectors/hasConnectedEmailDataAccess';
import { getUserEmail } from '../selectors/userSelectors/getUserEmail';
import { getPortalDomain } from '../selectors/getPortalDomain';
import { getFocusedViewMemberThreadId } from '../focused-view-member/selectors/getFocusedViewMemberThreadId';
const mapStateToProps = state => {
  return {
    gates: getGates(state),
    hasConnectedEmailDataAccess: hasConnectedEmailDataAccessSelector(state),
    currentUserEmail: getUserEmail(state),
    portalDomain: getPortalDomain(state),
    focusedViewMemberThreadId: getFocusedViewMemberThreadId(state)
  };
};
const mapDispatchToProps = {
  push,
  setWindowVisible
};
export default connect(mapStateToProps, mapDispatchToProps
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof Application' is not assig... Remove this comment to see the full error message
)(Application);