import http from 'hub-http/clients/apiClient';
import toolsHubSpotterClient from '../../shared/client/toolsHubSpotterClient';
const BASE_URL = 'zorse/v2/escalation-drafts';
const INTERNAL = '-internal';
const INTERNAL_URL_PATTERN = new RegExp('(private|tools|local)(-[a-z]{2,4}[0-9])?.hubteam(qa)?.com');
function isInternal() {
  return INTERNAL_URL_PATTERN.test(window.location.href);
}
function getHttpClient() {
  return isInternal() ? toolsHubSpotterClient : http;
}
function getUrl() {
  return isInternal() ? BASE_URL + INTERNAL : BASE_URL;
}
export function getEscalationByConversation(conversationId) {
  return getHttpClient().get(`${getUrl()}/by-conversation/${conversationId}`);
}
export function getEscalationByTicket(ticketId) {
  return getHttpClient().get(`${getUrl()}/by-ticket/${ticketId}`);
}
export function getEscalationMetaByConversation(conversationId) {
  return getHttpClient().get(`${getUrl()}/meta/by-conversation/${conversationId}`);
}
export function getEscalationMetaByTicket(ticketId) {
  return getHttpClient().get(`${getUrl()}/meta/by-ticket/${ticketId}`);
}
export function updateEscalation(escalation) {
  return getHttpClient().put(getUrl(), {
    data: escalation
  });
}
export function convertEscalation(escalation) {
  return getHttpClient().post(`${getUrl()}/convert`, {
    data: escalation
  });
}