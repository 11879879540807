import { useMutation, useQuery } from 'data-fetching-client';
import { FETCH_INBOX_ONBOARDING_STEPS, SET_ONBOARDING_STEP_SEEN } from '../queries/onboardingStepsQueries';
import { useCallback, useRef } from 'react';
import { INBOX_ONBOARDING_TOUR, INBOX_ONBOARDING_TOUR_PROMPTS_MAP } from '../constants/OnboardingConstants';
import { getIsDisabledForAcceptanceTest } from '../operators/getIsDisabledForAcceptanceTest';
import { DONE } from 'ui-shepherd-react/constants/TourStates';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
export function useOnboardingSteps() {
  const {
    data,
    loading
  } = useQuery(FETCH_INBOX_ONBOARDING_STEPS);
  const [mutate, {
    loading: mutationLoading
  }] = useMutation(SET_ONBOARDING_STEP_SEEN);
  const currentPromptRef = useRef();
  const unseenSteps = !data || !data.inboxOnboardingSteps || loading ? [] : data.inboxOnboardingSteps.map(step => INBOX_ONBOARDING_TOUR_PROMPTS_MAP.get(step));
  const hasUserSeenOnboarding = (data && unseenSteps ? unseenSteps.length === 0 : true) || getIsDisabledForAcceptanceTest();
  const updateSeenSteps = useCallback(nextStep => {
    if (currentPromptRef.current && INBOX_ONBOARDING_TOUR.includes(currentPromptRef.current) && currentPromptRef.current !== DONE) {
      const prompType = currentPromptRef.current;
      mutate({
        variables: {
          promptType: prompType
        }
      }).catch(error => {
        reportError({
          error
        });
        return;
      });
    }
    currentPromptRef.current = nextStep;
  }, [currentPromptRef, mutate]);
  return {
    hasUserSeenOnboarding,
    loading: !data || loading || mutationLoading,
    unseenSteps,
    updateSeenSteps
  };
}