// @ts-expect-error module not typed
import { getChannelInstanceId } from 'conversations-internal-schema/connected-email-account/operators/connectedEmailAccountGetters';
export const findAccountByChannelInstanceId = ({
  connectedAccounts,
  channelInstanceId
}) => {
  if (!channelInstanceId) return null;
  return connectedAccounts && connectedAccounts.find(account => {
    return getChannelInstanceId(account) === channelInstanceId;
  });
};