/* hs-eslint ignored failing-rules */
/* eslint-disable no-prototype-builtins */

'use es6';

import { once } from 'underscore';
import { createTracker } from 'usage-tracker';
import events from 'ui-addon-conversations-settings/events.yaml'; // eslint-disable-line
import { enhanceTrackerWithFullstory } from 'data-ops-fullstory';
const properties = {
  namespace: 'conversations',
  screen: 'Settings',
  subscreen: 'unknown',
  platform: 'desktop-app'
};
const makeUsageTracker = () => enhanceTrackerWithFullstory(createTracker({
  events,
  properties
}));
const makeBeaconUsageTracker = () => createTracker({
  events,
  properties
});
export const getUsageTracker = once(makeUsageTracker);
export const getBeaconUsageTracker = once(makeBeaconUsageTracker);
export const trackEvent = event => {
  if (typeof event !== 'object') {
    throw new Error(`trackEvent expects an object, got ${typeof event}`);
  } else if (!event.hasOwnProperty('key') || !event.hasOwnProperty('properties')) {
    throw new Error('trackEvent expects an object with `key` and `properties`');
  }
  getUsageTracker().track(event.key, event.properties);
};
export const trackEventBeforeUnload = event => {
  if (typeof event !== 'object') {
    throw new Error(`trackEventBeforeUnload expects an object, got ${typeof event}`);
  } else if (!event.hasOwnProperty('key') || !event.hasOwnProperty('properties')) {
    throw new Error('trackEventBeforeUnload expects an object with `key` and `properties`');
  }
  getBeaconUsageTracker().track(event.key, event.properties);
};