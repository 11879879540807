import { combineActions, handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { NAVIGATE_TO_THREAD } from '../../../pubsub/public/constants';
import { SET_CURRENT_THREAD_LIST } from '../../../views/public/constants';
import { FETCH_FROM_OMNI_BUS } from '../constants/asyncActionTypes';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { updateData } from 'conversations-async-data/async-data/operators/setters';
import { getErrorUpstreams, getRequestErrorTypes } from '../operators/omnibusGetters';
const initialState = new AsyncData({
  data: {
    requestErrorTypes: [],
    errorUpstreams: []
  }
});
export const omnibusErrors = handleActions({
  [FETCH_FROM_OMNI_BUS.STARTED](state) {
    return requestStarted(state);
  },
  [FETCH_FROM_OMNI_BUS.SUCCEEDED](state, action) {
    const {
      data
    } = action.payload;
    const newRequestErrors = getRequestErrorTypes(data);
    const newErrorUpstreams = getErrorUpstreams(data);
    return requestSucceededWithOperator(() => ({
      requestErrorTypes: newRequestErrors,
      errorUpstreams: newErrorUpstreams
    }), state);
  },
  [FETCH_FROM_OMNI_BUS.FAILED](state) {
    return requestFailed(state);
  },
  [combineActions(NAVIGATE_TO_THREAD, SET_CURRENT_THREAD_LIST).toString()](state) {
    const data = getData(state);
    if (data) {
      return updateData(() => ({
        requestErrorTypes: [],
        errorUpstreams: data.errorUpstreams
      }), state);
    }
    return state;
  }
}, initialState);