import http from 'hub-http/clients/apiClient';
const baseUrl = 'sales-checkout/v2/session-templates';
const queryUrl = `${baseUrl}/query`;
const eligibilityUrl = 'payments/v1/payment-accounts/eligibility';
export let PaymentsEligibilityStatus;
(function (PaymentsEligibilityStatus) {
  PaymentsEligibilityStatus["ELIGIBLE"] = "ELIGIBLE";
  PaymentsEligibilityStatus["INELIGIBLE"] = "INELIGIBLE";
  PaymentsEligibilityStatus["ACCOUNT_EXISTS"] = "ACCOUNT_EXISTS";
})(PaymentsEligibilityStatus || (PaymentsEligibilityStatus = {}));
export let PaymentsEligibilityUnderwritingStatus;
(function (PaymentsEligibilityUnderwritingStatus) {
  PaymentsEligibilityUnderwritingStatus["IN_KYC"] = "IN_KYC";
  PaymentsEligibilityUnderwritingStatus["IN_UNDERWRITING"] = "IN_UNDERWRITING";
  PaymentsEligibilityUnderwritingStatus["APPROVED"] = "APPROVED";
  PaymentsEligibilityUnderwritingStatus["REJECTED"] = "REJECTED";
})(PaymentsEligibilityUnderwritingStatus || (PaymentsEligibilityUnderwritingStatus = {}));
export const searchLinks = ({
  count,
  offset,
  query
}) => {
  return http.get(queryUrl, {
    query: {
      limit: count,
      indexOffset: offset,
      searchTerm: query
    }
  }).then(response => {
    const {
      sessionTemplateResponses: results,
      indexOffset,
      totalCount
    } = response;
    return {
      hasMore: results.length !== totalCount,
      offset: indexOffset || 0,
      count: results.length,
      total: totalCount,
      results
    };
  });
};
export const eligibility = () => {
  return http.get(eligibilityUrl);
};
export const defaultApiWrapper = (apiClient, referrerParam) => ({
  fetchRenderedContent({
    id
  }) {
    return apiClient.get(`${baseUrl}/${id}`).then(paymentLink => {
      const {
        checkoutUrl,
        name
      } = paymentLink;
      return {
        body: name,
        htmlBody: `<a target="_blank" href=${checkoutUrl}?referrer=${referrerParam}>${name}</a>`
      };
    });
  },
  searchLinks,
  eligibility
});