import { fromJS } from 'immutable';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { silenceErrorAlert } from 'conversations-error-reporting/error-actions/builders/silenceErrorAlert';
import { FETCH_THREAD_LIST_IDENTIFICATION as actionTypes } from '../constants/asyncActionTypes';
import { getThreadVisitorIdentification as requestFn } from '../clients/getThreadVisitorIdentification';
export const fetchThreadVisitorIdentification = createAsyncAction({
  requestFn,
  actionTypes,
  toRecordFn: fromJS,
  // Opting for silence strategy since failure would mean a false negative, which is acceptable
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type '{}'.
  failureMetaActionCreator: ({
    error
  }) => silenceErrorAlert({
    error
  })
});
export const NOOP_ACTION = {
  type: 'noop',
  payload: {}
};