/* stylelint-disable comment-empty-line-before */

import { FLINT, GYPSUM, GREAT_WHITE, EERIE, CALYPSO_LIGHT, OLAF, CALYPSO, MARIGOLD_MEDIUM, PANTERA, FLAT_EARTH } from 'HubStyleTokens/colors';
import UIIconCircle from 'UIComponents/icon/UIIconCircle';
import UICheckbox from 'UIComponents/input/UICheckbox';
import SkeletonCircle from 'conversations-skeleton-state/components/SkeletonCircle';
import styled, { css } from 'styled-components';
const HEIGHT = '106px';
const LEFT_WIDTH = '30px';
export const theme = {
  AVATAR_SIZE: 30,
  TEXT_COLOR: FLAT_EARTH,
  COMMENT_COLOR: MARIGOLD_MEDIUM,
  ICON_SIZE: 14,
  FONT_SIZE: 12,
  DEFAULT_WEIGHT: 400,
  DEMI_BOLD: 500,
  BOLD: 600,
  BOLDEST: 700
};
export const PreviewCardLeftSidebar = styled.div.withConfig({
  displayName: "PreviewCardStyles__PreviewCardLeftSidebar",
  componentId: "m1ryjz-0"
})(["display:flex;flex:0 0;flex-direction:column;align-items:center;justify-content:space-between;width:", ";margin-right:9px;"], LEFT_WIDTH);
export const PreviewCardCheckbox = styled(UICheckbox).withConfig({
  displayName: "PreviewCardStyles__PreviewCardCheckbox",
  componentId: "m1ryjz-1"
})(["margin:5px;", ""], ({
  checked
}) => checked && css(["&&&{display:block !important;}"]));
export const PreviewCardIconAvatar = styled(UIIconCircle).withConfig({
  displayName: "PreviewCardStyles__PreviewCardIconAvatar",
  componentId: "m1ryjz-2"
})(["", ""], ({
  checked
}) => checked && css(["display:none !important;"]));
export const PreviewCardBody = styled.div.withConfig({
  displayName: "PreviewCardStyles__PreviewCardBody",
  componentId: "m1ryjz-3"
})(["width:100%;"]);
export const PreviewCardTimestampWrapper = styled.div.withConfig({
  displayName: "PreviewCardStyles__PreviewCardTimestampWrapper",
  componentId: "m1ryjz-4"
})(["color:", " !important;font-size:", "px;font-weight:", ";white-space:nowrap;margin-left:2px;"], PANTERA, theme.FONT_SIZE, theme.DEFAULT_WEIGHT);
export const PreviewCardSourceIconWrapper = styled.div.withConfig({
  displayName: "PreviewCardStyles__PreviewCardSourceIconWrapper",
  componentId: "m1ryjz-5"
})(["display:flex;align-items:center;margin-left:6px;max-width:", "px;"], theme.ICON_SIZE);
export const PreviewCardNameWrapper = styled.span.withConfig({
  displayName: "PreviewCardStyles__PreviewCardNameWrapper",
  componentId: "m1ryjz-6"
})(["", " ", ""], ({
  isUnread
}) => isUnread && css(["font-weight:", ";font-size:16px;color:", ";"], theme.BOLDEST, theme.TEXT_COLOR), ({
  isUnknownContact
}) => isUnknownContact && css(["font-size:16px;color:", ";"], EERIE));
export const PreviewCardHeaderWrapper = styled.div.withConfig({
  displayName: "PreviewCardStyles__PreviewCardHeaderWrapper",
  componentId: "m1ryjz-7"
})(["display:flex;align-items:center;justify-content:space-between;width:100%;margin-top:4px;font-size:16px;line-height:22px;color:", ";padding-left:6px;", ""], theme.TEXT_COLOR, ({
  isUnread
}) => css(["font-weight:", " !important;"], isUnread ? theme.BOLDEST : theme.DEFAULT_WEIGHT));
export const PreviewCardContentWrapper = styled.div.withConfig({
  displayName: "PreviewCardStyles__PreviewCardContentWrapper",
  componentId: "m1ryjz-8"
})(["display:flex;flex-direction:column;width:100%;color:", ";margin-top:6px;"], theme.TEXT_COLOR);
export const PreviewCardContentTextWrapper = styled.div.withConfig({
  displayName: "PreviewCardStyles__PreviewCardContentTextWrapper",
  componentId: "m1ryjz-9"
})(["word-break:break-word;font-weight:", ";font-size:14px;line-height:24px;max-width:100%;padding-left:6px;", ";", ";", ";"], theme.DEFAULT_WEIGHT, ({
  isComment
}) => isComment && css(["border-radius:12px;background-color:", ";padding:0px 6px 0px 6px;"], theme.COMMENT_COLOR), ({
  hasSLA
}) => hasSLA && css(["max-width:90%;margin-right:30px;"]), ({
  hasNoConversation
}) => hasNoConversation && css(["color:", ";"], EERIE));
export const PreviewCardContentHeaderWrapper = styled.div.withConfig({
  displayName: "PreviewCardStyles__PreviewCardContentHeaderWrapper",
  componentId: "m1ryjz-10"
})(["width:100%;display:flex;flex-direction:row;margin-bottom:4px;font-weight:", ";font-size:15px;line-height:20px;padding-left:6px;", " ", ""], theme.DEFAULT_WEIGHT, ({
  isUnread
}) => isUnread && css(["font-weight:", ";"], theme.BOLD), ({
  isUnknownContact
}) => isUnknownContact && css(["color:", ";"], EERIE));
export const PreviewCardWrapper = styled.div.withConfig({
  displayName: "PreviewCardStyles__PreviewCardWrapper",
  componentId: "m1ryjz-11"
})(["background-color:", ";display:flex;flex-direction:row;flex-shrink:0;height:", ";width:100%;cursor:pointer;border-bottom:solid 1px ", ";border-left:solid 4px transparent;padding:12px 12px 12px 8px;", "{display:none !important;}", "{display:block !important;}&:hover{", "{display:", " !important;}", "{display:", " !important;}", "{display:none !important;}", " > *{color:", " !important;}}&:hover,&:focus{background-color:", " !important;}", " ", " ", ""], OLAF, HEIGHT, GREAT_WHITE, PreviewCardCheckbox, PreviewCardIconAvatar, PreviewCardCheckbox, ({
  disabled
}) => disabled ? 'none' : 'block', PreviewCardIconAvatar, ({
  hideBulkSelectCheckbox
}) => hideBulkSelectCheckbox ? 'block' : 'none', SkeletonCircle, PreviewCardLeftSidebar, EERIE, CALYPSO_LIGHT, ({
  disabled
}) => disabled && css(["&&{color:", ";cursor:not-allowed;opacity:0.25;}"], FLINT), ({
  isSelected
}) => isSelected && css(["&&&{background-color:", " !important;border-left-color:", ";}"], CALYPSO_LIGHT, CALYPSO), ({
  isUnread
}) => !isUnread && css(["&&{background-color:", ";}"], GYPSUM));