import { combineActions, handleActions } from 'redux-actions';
import { SET_CURRENT_THREAD_LIST } from 'conversations-inbox-lib/views/public/constants';
import { NAVIGATE_TO_INBOX, NAVIGATE_TO_THREAD } from 'conversations-inbox-lib/pubsub/public/constants';
import { SET_CURRENT_INBOX } from 'conversations-inbox-lib/unified-inbox/public/constants';
import { FETCH_FROM_OMNI_BUS, POLL_OMNIBUS } from 'conversations-inbox-lib/omnibus/public/constants';
import { getThreadId as getOmnibusThreadId } from 'conversations-inbox-lib/omnibus/public/operators';
import { ENTER_SEARCH_AND_FILTER_MODE } from 'conversations-inbox-lib/thread-search-and-filtering/public/constants';
import { SET_REALTIME_SORT_ORDER, SET_STATUS_TOGGLE } from '../../thread-list-member-sidebar/constants/actionTypes';
import { setFilterValue } from 'find-and-filter-data/search-and-filter-data/protected';
const initialState = null;
const focusedViewMember = handleActions({
  [combineActions(NAVIGATE_TO_INBOX, SET_CURRENT_THREAD_LIST, SET_CURRENT_INBOX, ENTER_SEARCH_AND_FILTER_MODE, setFilterValue.type, SET_REALTIME_SORT_ORDER, SET_STATUS_TOGGLE).toString()]() {
    return initialState;
  },
  [FETCH_FROM_OMNI_BUS.SUCCEEDED](state, action) {
    const threadId = getOmnibusThreadId(action.payload.data);
    if (threadId) return String(threadId);
    return state;
  },
  [POLL_OMNIBUS.SUCCEEDED](state, action) {
    const alreadyInitialized = state !== initialState;
    if (alreadyInitialized) return state;
    const threadId = getOmnibusThreadId(action.payload.data);
    if (threadId) return String(threadId);
    return state;
  },
  [NAVIGATE_TO_THREAD](state, action) {
    const {
      threadId
    } = action.payload;
    return threadId ? String(threadId) : initialState;
  }
}, initialState);
export default focusedViewMember;