import { SLAStatusToTagColorMap, SLAStatusToIconColorMap } from '../constants/SLAColorConstants';
/**
 * UITag takes color in the 'use' param. By convention, that param takes color names in lowercase and with dashes
 * between words.
 *
 * This is different from how UIIcon works, hence the two different helper functions and return types.
 */
export const getSLATagColorFromStatus = slaStatus => SLAStatusToTagColorMap[slaStatus];

/**
 * UIIcon takes color in the 'color' param. By convention, that param takes color hashes from
 * the HubspotStyleTokens/colors object. This object's keys are uppercase with underscores between words.
 *
 * This is different from how UITag works, hence the two different helper functions and return types.
 */
export const getSLAIconColorFromStatus = slaStatus => SLAStatusToIconColorMap[slaStatus];