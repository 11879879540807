import { handleActions } from 'redux-actions';
import { THREAD_ID_CHANGED } from '../../_internal/constants/actionTypes';
const initialState = null;
export const currentThreadId = handleActions({
  [THREAD_ID_CHANGED]: (__state, action) => {
    const {
      threadId
    } = action.payload;
    return threadId;
  }
}, initialState);