import http from 'conversations-http/clients/http';
import { registerQuery, useQuery } from 'data-fetching-client';

// https://tools.hubteam.com/api-catalog/services/EmailMtaSendingService/v1/spec/internal#operations-Auth_Domains-get-%2Femail-mta-sending%2Fv1%2Fauth-domains%2Factive-domains-with-state

const FETCH_EMAIL_DOMAINS_FIELD_NAME = 'fetchEmailDomains';
const FETCH_EMAIL_DOMAINS_QUERY = registerQuery({
  fieldName: FETCH_EMAIL_DOMAINS_FIELD_NAME,
  fetcher() {
    return http.get('/email-mta-sending/v1/auth-domains/active-domains-with-state');
  }
});
export function useAuthorizedEmailDomains() {
  const {
    data,
    loading,
    error
  } = useQuery(FETCH_EMAIL_DOMAINS_QUERY);
  const emailDomains = data && data[FETCH_EMAIL_DOMAINS_FIELD_NAME];
  return {
    emailDomains,
    loading,
    error
  };
}