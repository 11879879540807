import isEmpty from 'transmute/isEmpty';
import { assertIsDefined } from 'ui-addon-conversations-settings/utils/assert';
export const canKeepAssigneeOnNewInbox = ({
  assigneeId,
  teamUsersResponse
}) => {
  if (isEmpty(assigneeId)) {
    return true;
  }
  if (isEmpty(teamUsersResponse)) {
    return false;
  }
  assertIsDefined(teamUsersResponse);
  const inboxUser = teamUsersResponse.find(user => user.id === assigneeId);
  const canViewInbox = (inboxUser || {}).canViewInbox;
  return !!canViewInbox;
};