import { buildError } from '../../error-reporting/builders/buildError';
import { expectMessage } from './expectMessage';
export function verifyPublish(channels, channelId, messageId, publishVerifier = (publishingMessageId, realtimeMessage) => {
  return realtimeMessage.id === publishingMessageId;
}) {
  return expectMessage(channels, channelId, realtimeMessage => publishVerifier(messageId, realtimeMessage)).catch(error => {
    throw buildError(`PubSub publish verification failed. Reason: ${error.message}`, error, {
      channelId,
      messageId
    });
  });
}