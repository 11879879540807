import { useFirstMessageIdForChannel } from 'conversations-thread-view/thread-history/hooks/useFirstMessageIdForChannel';
import { CALLING_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
import { useCommonMessageWithId } from 'conversations-thread-data/thread-history/public/hooks';
// @ts-expect-error module not typed
import { useThreadHistory } from 'conversations-thread-view/thread-histories/hooks/useThreadHistory';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { getSenderDeliveryIdentifierValue, getSenders } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
export const useVisitorPhoneNumber = ({
  threadId
}) => {
  const messageId = useFirstMessageIdForChannel({
    threadId,
    genericChannelId: CALLING_GENERIC_CHANNEL_ID
  });
  const threadHistory = useThreadHistory({
    threadId
  });
  const {
    asyncCommonMessage
  } = useCommonMessageWithId({
    messageId,
    threadHistory,
    threadId,
    deferred: Boolean(!messageId)
  });
  const commonMessage = getData(asyncCommonMessage);
  const senders = getSenders(commonMessage);
  const firstSender = senders && senders.get(0);
  const visitorNumber = firstSender && getSenderDeliveryIdentifierValue(firstSender);
  return visitorNumber;
};