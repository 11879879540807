export const CART_HIGHLIGHT_CARD = 'cart-highlight-card';
export const CART_HIGHLIGHT_DETAILS = 'cart-highlight-details';
export const HIGHLIGHT_CARD_ACTIONS_LIST = 'highlight-card-actions-list';
export const HIGHLIGHT_CARD_ACTION_ALL_PROPERTIES = 'highlight-card-action-all-properties';
export const HIGHLIGHT_CARD_ACTION_PROPERTIES_HISTORY = 'highlight-card-action-properties-history';
export const HIGHLIGHT_CARD_LOADER = 'highlight-card-loader';
export const HIGHLIGHT_TITLE = 'highlight-title';
export const HIGHLIGHT_CREATED_DATE = 'highlight-created-date';
export const HIGHLIGHT_STATUS = 'highlight-status';
export const HIGHLIGHT_STAGE = 'highlight-stage';
export const HIGHLIGHT_PIPELINE = 'highlight-pipeline';
export const HIGHLIGHT_PHONE = 'highlight-phone';
export const HIGHLIGHT_CONTACT_EMAIL = 'highlight-contact-email';
export const ORDER_HIGHLIGHT_CARD = 'order-highlight-card';
export const ORDER_HIGHLIGHT_DETAILS = 'order-highlight-details';
export const PROFILE_SETTINGS_ACTIONS_BTN = 'profile-settings-actions-btn';