import { Map as ImmutableMap } from 'immutable';
import { getViewInternalType } from 'find-and-filter-data/views-schema/public';
import { DEFAULT_VIEW } from '../constants/ViewPlacement';
import get from 'transmute/get';
function isDefaultView(view, defaultView) {
  return getViewInternalType(view) === defaultView;
}

/**
 * @description Given a map of views this operator will return the "default" view.
 * This is the default view to load into when no other view is selected.
 */
export function getDefaultViewFromViews(views, defaultView = DEFAULT_VIEW) {
  if (ImmutableMap.isMap(views)) {
    return views.find(view => isDefaultView(view, defaultView));
  }
  return Object.values(views).find(view => isDefaultView(view, defaultView));
}
export function getDefaultThreadListFromViews(views) {
  return get('DEFAULT-1', views);
}