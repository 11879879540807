import { useEffect, useState } from 'react';
import { AblyRealtimeWrapper } from 'ably-hubspot-js/ably/api-wrappers/AblyRealtimeWrapper';
import { AggregateDataReporter } from 'ably-hubspot-js/metrics/AggregateDataReporter';
import Ably from 'ably-hubspot-js';
import http from 'conversations-http/clients/http';
import Raven from 'raven-js';
import { safelyParseJson } from '../utils';
const publishVerifier = (publishingMessageId, realtimeMessage) => {
  const topLevelIdsMatch = !!publishingMessageId && publishingMessageId === realtimeMessage.id;
  const realtimeMessageDataObject = safelyParseJson(realtimeMessage.data);
  const dataIdMatch = !!publishingMessageId && publishingMessageId === realtimeMessageDataObject.id;
  return topLevelIdsMatch || dataIdMatch;
};
export const getAblyClient = () => {
  const realtime = new Ably.Realtime({
    authCallback: (_tokenParams, callback) => {
      http.get('messages/v2/pubsub/token/agent', {
        query: {
          inboxId: null,
          noCache: Math.random()
        }
      }).then(tokenResponse => callback('', tokenResponse)).catch(err => {
        callback(err, '');
        setTimeout(() => {
          throw err;
        });
      });
    }
  });
  return new AblyRealtimeWrapper(realtime, new AggregateDataReporter(), publishVerifier);
};
export const usePubSub = () => {
  const [client, setClient] = useState(null);
  useEffect(() => {
    if (!client) {
      const ablyClient = getAblyClient();
      ablyClient.connect().then(() => {
        setClient(ablyClient);
      }).catch(error => {
        Raven.captureException(error);
      });
    }
    return () => {
      if (client) {
        client.close();
      }
    };
  }, [client]);
  return client;
};