const emailStringRegex = /(.+) <(.+)>/;
export const extractInfoFromEmailString = emailString => {
  let address = emailString;
  let friendlyName;
  const match = emailString.match(emailStringRegex);
  if (match) [, friendlyName, address] = match;
  return {
    friendlyName,
    address
  };
};