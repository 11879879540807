// import Raven from 'raven-js';

import { ensureConnection } from './ensureConnection';
import { bindConnectionLifeCycleCallbacks } from './bindConnectionLifeCycleCallbacks';
import { performPublish } from './performPublish';
import { performSubscribe } from './performSubscribe';
import { performPresenceSubscribe } from './performPresenceSubscribe';
import { performUnsubscribe } from './performUnsubscribe';
import { performPresenceUnsubscribe } from './performPresenceUnsubscribe';
import { countReceivedMessages } from './countReceivedMessages';
import { waitForAttach } from './waitForAttach';
import { reauthorize } from './reauthorize';
import { hasCapability } from './hasCapability';
import { hasTokenExpired } from './hasTokenExpired';
import { reportAndCheckNetworkOnAblyTimeout } from './reportAndCheckNetworkOnAblyTimeout';
import { throttleApi } from '../../utils/throttleApi';
import { profilePromise } from '../../metrics/profilePromise';
import { setupConnectionReporting } from './setupConnectionReporting';
import { combineLifecycleHooks } from './combineLifecycleHooks';
import { CONNECTED } from '../constants/connectionStates';
import { countReceivedPresenceMessages } from './countReceivedPresenceMessages';
import { performGetPresenceMessages } from './performGetPresenceMessages';
import { getConnectionMetadata } from '../operators/getConnectionMetadata';
const generateLifecycleHooksKey = (() => {
  let key = 0;
  return () => `lifecycle-hooks-${key++}`;
})();

/**
 * @see {@link https://github.com/ably/ably-common/blob/main/protocol/errors.json}

// Disabling tracking the capability error until post-ably upgrade and when permissioning can be prioritized

const captureCapabilityError = (
  error: AblyTypes.ErrorInfo,
  channelId: string
) => {
  if (error.code !== 40160) return; // action not permitted;
  Raven.captureMessage('CAPABILITY_ERROR', {
    extra: {
      channelId,
      now: Date.now(),
    },
  });
};
 */

export class AblyRealtimeWrapper {
  constructor(realtime, reporter, publishVerifier) {
    this.realtime = realtime;
    this.keyedLifecycleHooks = {};
    this.lifecycleHooks = {};
    this.reporter = reporter;
    this.publishVerifier = publishVerifier;
    const throttledPublish = throttleApi(this.publish.bind(this));
    this.publish = profilePromise(this.reporter, 'publish', throttledPublish);
    this.subscribe = profilePromise(this.reporter, 'subscribe', this.subscribe.bind(this));
    this.subscribePresence = profilePromise(this.reporter, 'subscribe-presence', this.subscribePresence.bind(this));
    this._profiledReauthorize = profilePromise(this.reporter, 'reauthorize', reauthorize);
    this.realtime.connection.on(this.updateReporter.bind(this));
    setupConnectionReporting(this.realtime.connection, this.reporter);
  }
  updateReporter({
    current,
    previous
  }) {
    if (previous !== CONNECTED && current === CONNECTED) {
      const connectionMetadata = getConnectionMetadata(this.realtime.connection);
      this.reporter.setAdditionalContext(connectionMetadata);
    }
  }
  registerLifecycleHooks(lifecycleHooks) {
    const key = generateLifecycleHooksKey();
    this.keyedLifecycleHooks = Object.assign({}, this.keyedLifecycleHooks, {
      [key]: lifecycleHooks
    });
    this.lifecycleHooks = combineLifecycleHooks(this.keyedLifecycleHooks);
    bindConnectionLifeCycleCallbacks(this.realtime.connection, this.lifecycleHooks);
    return key;
  }
  connect() {
    this.realtime.connection.connect();

    // Temporarily return a resolved promise to keep backward compat
    return Promise.resolve();
  }
  close() {
    this.realtime.connection.off();
    this.realtime.connection.close();
  }
  getConnectionId() {
    return this.realtime.connection.id || null;
  }
  isConnected() {
    return this.realtime.connection.state === CONNECTED;
  }

  /**
   * Checks whether the token has expired.
   */
  hasTokenExpired() {
    return hasTokenExpired(this.realtime.auth);
  }

  /**
   * Ensures that the token hasen't expired
   */
  ensureValidToken() {
    if (this.hasTokenExpired()) {
      return this.reauthorize();
    }
    return Promise.resolve();
  }

  /**
   * Reauthorize Ably client
   *
   * @param channels - channels to reauthorize on. If omited, the client will always
   *  reauthorize.  If provided, the client will reauthorize unless it already has capabilities on channels.
   * @param capabilityValidator capabilityValidator allow applications to pass in a custom validator for their channels
   */
  reauthorize(channels, capabilityValidatorFunc) {
    if (channels && hasCapability(this.realtime.auth, channels, capabilityValidatorFunc)) {
      return Promise.resolve();
    }
    return this._profiledReauthorize(this.realtime.auth).catch(reportAndCheckNetworkOnAblyTimeout);
  }

  /**
   * Publish a message on a channel
   */
  publish({
    channelId,
    data,
    messageId
  }) {
    const {
      connection,
      channels
    } = this.realtime;
    return ensureConnection(connection).then(() => this.ensureValidToken()).then(() => waitForAttach(channels, channelId)).then(() => performPublish(channels, {
      channelId,
      data,
      messageId,
      publishVerifier: this.publishVerifier
    })).catch(reportAndCheckNetworkOnAblyTimeout);
  }

  /**
   * Subscribe to a list of channels or channel groups
   */
  subscribe({
    channelId,
    onMessage,
    onPlayback,
    playbackMessages
  }) {
    const channel = this.realtime.channels.get(channelId);
    return ensureConnection(this.realtime.connection).then(() => this.ensureValidToken()).then(() => performSubscribe({
      channel,
      onMessage,
      onPlayback,
      playbackMessages,
      reporter: this.reporter
    })).then(() => countReceivedMessages({
      channel,
      reporter: this.reporter
    }))
    // .catch((error: AblyTypes.ErrorInfo) => {
    //   captureCapabilityError(error, channelId);
    //   throw error;
    // })
    .catch(reportAndCheckNetworkOnAblyTimeout);
  }

  /**
   *
   * Subscribe to presence messages on a channel
   */
  subscribePresence({
    channelId,
    onMessage
  }) {
    const channel = this.realtime.channels.get(channelId);
    return ensureConnection(this.realtime.connection).then(() => this.ensureValidToken()).then(() => performPresenceSubscribe({
      channel,
      onMessage
    })).then(() => countReceivedPresenceMessages({
      channel,
      reporter: this.reporter
    }))
    // .catch((error: AblyTypes.ErrorInfo) => {
    //   captureCapabilityError(error, channelId);
    //   throw error;
    // })
    .catch(reportAndCheckNetworkOnAblyTimeout);
  }

  /**
   * Unsubscribe from a channel
   */
  unsubscribe({
    channelId,
    onMessage
  }) {
    return ensureConnection(this.realtime.connection).then(() => this.ensureValidToken()).then(() => performUnsubscribe(this.realtime.channels, channelId, onMessage)).catch(reportAndCheckNetworkOnAblyTimeout);
  }

  /**
   * Unsubscribe from presence messages on a channel
   */
  unsubscribePresence({
    channelId,
    onMessage
  }) {
    return ensureConnection(this.realtime.connection).then(() => this.ensureValidToken()).then(() => performPresenceUnsubscribe(this.realtime.channels, channelId, onMessage)).catch(reportAndCheckNetworkOnAblyTimeout);
  }

  /**
   * Query the current presence messages
   */
  getPresenceMessages({
    channelId
  }) {
    const channel = this.realtime.channels.get(channelId);
    return performGetPresenceMessages({
      channel
    });
  }
}