/**
 * @description Updates any subscriptions that are just a single
 * handler function to be an object containing that function as
 * an `onMessage` handler.
 * @param {Object<String, Object|Function>} subscriptions an object
 * mapping channel IDs to either a single message handler or an
 * object containing an `onMessage` handler.
 * @returns {Object<String, Object>}
 */
export const transformLegacySubscriptions = subscriptions => {
  return Object.keys(subscriptions).reduce((accumulator, channel) => {
    const subscriptionObjectOrHandler = accumulator[channel];
    return typeof subscriptionObjectOrHandler === 'function' ? Object.assign({}, accumulator, {
      [channel]: {
        onMessage: subscriptionObjectOrHandler
      }
    }) : accumulator;
  }, subscriptions);
};