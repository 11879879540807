import { getDefaultViewType } from '../custom-views/customViewOperators';
import { getInternalName } from '../thread-lists/threadListOperators';

/**
 * @description Returns the view type. If it's a CustomView, this is the
 * defaultViewType or 'user created - team view', if it is a ThreadList then
 * this is the internalName.
 */
export const getViewUsageTrackingName = view => {
  return 'customViewId' in view ? getDefaultViewType(view) || 'user created - team view' : getInternalName(view);
};