import { handleActions } from 'redux-actions';
import { CLEAR_PREVIOUSLY_FOCUSED_THREAD_ID } from '../actions/clearPreviouslyFocusedThreadId';
import { ENTER_SEARCH_AND_FILTER_MODE } from '../actions/enterSearchAndFilterMode';
const initialState = null;
const previouslyFocusedThreadId = handleActions({
  [ENTER_SEARCH_AND_FILTER_MODE](_, action) {
    return action.payload.previouslyFocusedThreadId;
  },
  [CLEAR_PREVIOUSLY_FOCUSED_THREAD_ID]() {
    return initialState;
  }
}, initialState);
export default previouslyFocusedThreadId;