import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { FAILED, STARTED, SUCCEEDED } from 'conversations-async-data/async-data/constants/asyncStatuses';
const queryResultToAsyncStatus = ({
  loading,
  error
}) => {
  let status;
  if (loading) {
    status = STARTED;
  } else if (error) {
    status = FAILED;
  } else {
    status = SUCCEEDED;
  }
  return status;
};
const queryErrorResultToError = ({
  error
}) => {
  if (!error) {
    return null;
  }
  return error;
};
export const queryResultToAsyncData = ({
  data,
  loading,
  error
}) => {
  return new AsyncData({
    data,
    status: queryResultToAsyncStatus({
      loading,
      error
    }),
    error: queryErrorResultToError({
      error
    })
  });
};