import { generateViewId } from 'find-and-filter-data/views-schema/public';
export function getViewIdFromUpdate({
  id,
  threadListType
}) {
  if (threadListType) {
    return generateViewId({
      threadListId: id,
      threadListType
    });
  } else {
    return generateViewId({
      customViewId: id
    });
  }
}