// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'refe... Remove this comment to see the full error message
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
import { List } from 'immutable';
import Responder from 'conversations-internal-schema/responders/records/Responder';
import formatName from 'I18n/utils/formatName';
export const formatResponder = responder => {
  const {
    userId,
    agentType,
    firstName,
    lastName
  } = responder;
  return new ReferenceRecord({
    id: `${userId}-${agentType}`,
    label: formatName({
      firstName,
      lastName
    }),
    referencedObject: new Responder(responder)
  });
};
export const formatResponders = responders => {
  // @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
  return new List(responders.map(responder => formatResponder(responder)));
};