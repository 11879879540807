import http from 'conversations-http/clients/http';
import { buildIndexedViews } from './buildIndexedViews';

/**
 * @description Fetches all views for the given inbox.
 */
export function fetchViewsService({
  inboxId
}) {
  return http.get(`messages/v2/inbox/${inboxId}/views-and-threadlists`).then(({
    hydratedThreadLists,
    hydratedCustomViews,
    isFallbackModeEnabled
  }) => {
    const views = buildIndexedViews({
      customViews: hydratedCustomViews,
      threadLists: hydratedThreadLists
    });
    return {
      isFallbackModeEnabled,
      views
    };
  });
}