import { contactToOption } from './contactToOption';
import { searchContacts } from 'conversations-inbox-lib/thread-search-and-filtering/public/clients';
export const buildOptions = ({
  backendOffset = 0,
  contacts = [],
  hasMore,
  optionTransformation,
  previousBackendOffset = 0,
  previousOptions = []
}) => {
  let allOptions = previousOptions.concat(contacts.map(contactToOption));
  if (optionTransformation) allOptions = allOptions.map(optionTransformation);
  return {
    options: allOptions,
    pagination: {
      hasMore,
      offset: previousBackendOffset + contacts.length,
      backendOffset,
      options: allOptions
    }
  };
};
export const loadOptions = ({
  searchQuery,
  callback,
  pagination,
  optionTransformation
}) => {
  // Require some search searchQuery to display result options
  if (searchQuery && searchQuery.length) {
    let previousBackendOffset = 0;
    let previousOptions = [];
    if (pagination) {
      previousBackendOffset = pagination.backendOffset;
      previousOptions = pagination.options;
    }
    return searchContacts({
      searchQuery,
      offset: previousBackendOffset
    }).then(({
      contacts,
      ['has-more']: hasMore,
      offset
    }) => {
      const builtOptions = buildOptions({
        backendOffset: offset,
        contacts,
        hasMore,
        optionTransformation,
        previousBackendOffset,
        previousOptions
      });
      callback(null, builtOptions);
      return builtOptions;
    });
  } else {
    callback(null);
    return Promise.resolve({
      options: []
    });
  }
};