import { createStack } from 'hub-http';
import promiseClient from 'hub-http/adapters/promiseClient';
import { retry, onResponseError } from 'hub-http/middlewares/core';
import hubapiStack from 'hub-http/stacks/hubapi';
import { getProperty } from 'customer-data-objects/model/ImmutableModel';
export default promiseClient(createStack(hubapiStack, retry(response => response && response.responseJSON && response.responseJSON.quote && !getProperty(response.responseJSON.quote, 'hs_quote_link'), {
  reason: 'Quote not yet published',
  maxRetries: 10,
  delay: 300
}), /* eslint-disable @typescript-eslint/no-misused-promises */
onResponseError(response => {
  if (response.retry.exceededRetries) {
    // If we exceed retries that means the quote is not yet published, so we'll return it as is
    return Promise.resolve(response);
  }
  return Promise.reject(response);
})));