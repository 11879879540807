'use es6';

import { useCallback } from 'react';
import devLogger from 'react-utils/devLogger';
import { useMutation } from '@apollo/client';
import CrmLogger from 'customer-data-tracking/loggers/CrmLogger';
import { collapseCard } from './useUpdateCardOpenQueries';

/**
 * Update the card collapse state of a sidebar. This state is universal (collapse in one sidebar will show in all sidebars).
 *
 * @example
 *
 * const handleCardOpen = useUpdateCardOpen({
 *   closedCards,
 *   objectType,
 * });
 *
 * @param {Object}                           options
 * @param {String}                           options.objectType            the type of the subject whose sidebar cards are being changed
 */

export const useUpdateCardOpen = ({
  objectType
}) => {
  const [collapseCardMutation] = useMutation(collapseCard);
  return useCallback(({
    cardId,
    isOpen,
    legacyCardName
  }) => {
    collapseCardMutation({
      variables: {
        input: {
          cardId,
          collapsed: !isOpen,
          objectTypeId: objectType
        }
      }
    }).catch(err => {
      devLogger.warn({
        message: `Failed to save card collapsed state: ${err.message}`,
        key: `CardsSidebar::handleClosedCardsChange`
      });
    });
    CrmLogger.log('sidebar-interaction', {
      action: 'expand/collapse individual side card',
      subAction: isOpen ? 'expand' : 'collapse',
      card: legacyCardName
    });
  }, [collapseCardMutation, objectType]);
};