import { fromJS } from 'immutable';
import http from 'hub-http/clients/apiClient';
const TWILIO_URL_PREFIX = 'twilio/v1';
export function createClientError(engagementId, errorCode, message, source) {
  const url = `${TWILIO_URL_PREFIX}/client-errors/`;
  const data = {
    engagementId,
    errorCode,
    errorMessage: message,
    timestamp: new Date().getTime(),
    issueSource: source
  };
  return http.post(url, {
    data,
    timeout: 20000,
    query: {
      clienttimeout: 20000
    }
  }).then(fromJS);
}