module.exports = {
  "pageView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "productshighlightcardlib"
  },
  "productPanelUsage": {
    "name": "Product panel",
    "properties": {
      "action": [
        "Create product",
        "Edit product",
        "Clone product"
      ],
      "subAction": [
        "Save",
        "Save and add another"
      ],
      "propertyName": {
        "type": "string"
      }
    },
    "namespace": "products",
    "class": "usage"
  },
  "productExport": {
    "name": "Products export",
    "properties": {
      "action": [
        "Export success"
      ],
      "subAction": [
        "Export specific objects",
        "Export all objects"
      ],
      "containsSearchQuery": {
        "type": "boolean"
      }
    },
    "namespace": "products",
    "class": "usage"
  },
  "deleteObject": {
    "properties": {
      "action": [
        "Delete product"
      ]
    },
    "namespace": "products",
    "name": "Products usage",
    "class": "usage"
  },
  "bulkDeleteObjects": {
    "properties": {
      "action": [
        "Bulk delete"
      ],
      "containsSearchQuery": {
        "type": "boolean"
      },
      "allItems": {
        "type": "boolean"
      },
      "deleteCount": {
        "type": "number"
      }
    },
    "namespace": "products",
    "name": "Products usage",
    "class": "usage"
  }
};