import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { requestSucceeded } from 'conversations-async-data/async-data/operators/requestSucceeded';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { MOVE_TO_INBOX } from '../constants/asyncActionTypes';
const initialState = new IndexedAsyncData({
  name: 'moveStatuses',
  notSetValue: new AsyncData()
});
export const moveStatuses = handleActions({
  [MOVE_TO_INBOX.STARTED]: (state, action) => {
    const {
      threadId
    } = action.payload.requestArgs;
    return updateEntry(threadId, requestStarted, state);
  },
  [MOVE_TO_INBOX.SUCCEEDED](state, action) {
    const {
      threadId
    } = action.payload.requestArgs;
    return updateEntry(threadId, requestSucceeded, state);
  },
  [MOVE_TO_INBOX.FAILED](state, action) {
    const {
      threadId
    } = action.payload.requestArgs;
    return updateEntry(threadId, requestFailed, state);
  }
}, initialState);