'use es6';

import { getDynamicNamespace } from 'conversations-infrastructure/i18n/getDynamicNamespace';
import { isMarkAsSpamMessage, isBlockMessage, isUnblockMessage, isUnmarkAsSpamMessage } from 'conversations-message-history/filtered-change-message/operators/filteredChangeComparators';
const getI18nLabel = message => {
  switch (true) {
    case isBlockMessage(message):
      return 'blocked';
    case isUnblockMessage(message):
      return 'unblocked';
    case isMarkAsSpamMessage(message):
      return 'markedAsSpam';
    case isUnmarkAsSpamMessage(message):
      return 'unmarkedAsSpam';
    default:
      return 'default';
  }
};
export const chooseFilteredChangeMessage = ({
  message,
  isSelfMarker,
  dateString,
  marker
}) => {
  const prefix = isSelfMarker ? 'conversations-inbox-message-history.filtered-change.labels.you' : 'conversations-inbox-message-history.filtered-change.labels.agent';
  return {
    i18nKey: getDynamicNamespace({
      prefix,
      translationOptions: ['blocked', 'unblocked', 'markedAsSpam', 'unmarkedAsSpam', 'default'],
      defaultOption: 'default'
    }, getI18nLabel(message)),
    options: {
      marker,
      dateString
    }
  };
};