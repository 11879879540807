'use es6';

import curry from 'transmute/curry';
import { isCommonMessageFormat } from '../../common-message-format/operators/cmfComparators';
import { isThreadComment } from '../../comment-message/operators/cmComparators';
import { updateMessageWithUpdates } from '../../message-updates/operators/updateMessageWithUpdates';
import { threadHistoryInvariant } from '../invariants/threadHistoryInvariant';
import { getMessageFromThreadHistory } from './getMessageFromThreadHistory';
import { updateMessageInThreadHistory } from './updateMessageInThreadHistory';
export const applyUpdateToThreadHistory = curry((updates, threadHistory) => {
  threadHistoryInvariant(threadHistory);
  let updatedThreadHistory = threadHistory;
  updates.forEach((update, messageKey) => {
    const messageToUpdate = getMessageFromThreadHistory(messageKey, threadHistory);
    if (messageToUpdate && (isCommonMessageFormat(messageToUpdate) || isThreadComment(messageToUpdate))) {
      const updatedMessage = updateMessageWithUpdates(messageToUpdate, update);
      updatedThreadHistory = updateMessageInThreadHistory(messageKey, updatedMessage)(updatedThreadHistory);
    }
  });
  return updatedThreadHistory;
});