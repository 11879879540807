import { TWILIO, HUBSPOT } from 'calling-internal-common/call-provider/constants/ProviderNames';
import { TWILIO_WIDGET_OPTIONS } from '../../constants/twilioWidgetOptions';
import CallingProvider from 'calling-internal-common/call-provider/records/CallingProvider';
const {
  width,
  height
} = TWILIO_WIDGET_OPTIONS;
export const getTwilioCallingProvider = () => new CallingProvider({
  name: TWILIO,
  width,
  height,
  supportsCustomObjects: true,
  supportsInboundCalling: false,
  supportsChannelConnection: false
});
export const getHubSpotCallingProvider = () => new CallingProvider({
  name: HUBSPOT,
  width,
  height,
  supportsCustomObjects: true,
  supportsInboundCalling: false,
  supportsChannelConnection: false
});