'use es6';

import http from 'hub-http/clients/apiClient';
export const fetchSingleCalleeClient = ({
  objectId,
  objectTypeId
}) => {
  const url = '/calls/v1/callees/hydrate-one';
  return http.get(`${url}/${objectTypeId}/${objectId}`);
};
export const fetchCallees = ({
  objectId,
  objectTypeId
}) => {
  const url = '/calls/v1/callees';
  return http.get(`${url}/${objectTypeId}/${objectId}`);
};