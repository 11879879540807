import { useQuery } from 'data-fetching-client';
import { GET_INBOX_BUSINESS_HOURS_RETURN_TIME } from '../queries/businessHoursQueries';
export function useInboxBusinessHoursReturnTime({
  inboxId
}) {
  const {
    data
  } = useQuery(GET_INBOX_BUSINESS_HOURS_RETURN_TIME, {
    variables: {
      inboxId
    }
  });
  return data && data.inboxBusinessHoursReturnTime && data.inboxBusinessHoursReturnTime['@ok'];
}