import I18n from 'I18n';
import { CUSTOMER_PORTAL_GENERIC_CHANNEL_ID, EMAIL_GENERIC_CHANNEL_ID, FORMS_GENERIC_CHANNEL_ID, LIVE_CHAT_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
import { getVisitorDisplayName } from 'find-and-filter-data/visitors/public';
const MAX_PROPERTY_CHARACTER = 200;
const truncateContent = (content, limit) => {
  if (content && content.length > limit) {
    return content.substring(0, limit);
  }
  return content;
};
export const buildAriaLabel = (source, subject, preview, visitor) => {
  const sender = visitor ? getVisitorDisplayName(visitor) : I18n.text('svh-workspace-ui-lib.preview-card.ariaLabel.unknownSender');
  switch (source) {
    case CUSTOMER_PORTAL_GENERIC_CHANNEL_ID:
    case EMAIL_GENERIC_CHANNEL_ID:
    case FORMS_GENERIC_CHANNEL_ID:
      {
        const emailSubject = subject || I18n.text('svh-workspace-ui-lib.preview-card.noEmailSubject');
        if (source === FORMS_GENERIC_CHANNEL_ID) {
          return I18n.text('svh-workspace-ui-lib.preview-card.ariaLabel.form', {
            sender,
            subject: emailSubject
          });
        } else {
          // Display email text for Email and Customer Portal channels
          return I18n.text('svh-workspace-ui-lib.preview-card.ariaLabel.email', {
            sender,
            subject: emailSubject
          });
        }
      }
    default:
      {
        const channel = source === LIVE_CHAT_GENERIC_CHANNEL_ID ? I18n.text('svh-workspace-ui-lib.preview-card.ariaLabel.liveChat') : I18n.text('svh-workspace-ui-lib.preview-card.ariaLabel.fbMessenger');
        return I18n.text('svh-workspace-ui-lib.preview-card.ariaLabel.nonEmail', {
          channel,
          sender,
          context: truncateContent(preview, MAX_PROPERTY_CHARACTER) || I18n.text('svh-workspace-ui-lib.preview-card.ariaLabel.emptyContext')
        });
      }
  }
};