import { isThreadListMemberFiltered } from 'find-and-filter-data/search-and-filter-data/public';
import AssignmentRemovalOverlay from '../records/AssignmentRemovalOverlay';
import FilterChangeRemovalOverlay from '../records/FilterChangeRemovalOverlay';
import ThreadStatusRemovalOverlay from '../records/ThreadStatusRemovalOverlay';
import UnknownRemovalOverlay from '../records/UnknownRemovalOverlay';
import FilterMisalignmentOverlay from '../records/FilterMisalignmentOverlay';
import TicketAssociationRemovalOverlay from '../records/TicketAssociationRemovalOverlay';
import HardDeletionRemovalOverlay from '../records/HardDeletionRemovalOverlay';
import InboxChangeRemovalOverlay from '../records/InboxChangeRemovalOverlay';
import { getAgentLocatorFromAudit } from './getAgentLocatorFromAudit';
import { buildOverlayFromMultipleRemovalType } from './buildOverlayFromMultipleRemovalType';
import { getAssignee, getPrevious, getRemoval, getStatus } from 'find-and-filter-data/view-members-schema/protected';
import { getThreadRemovalAudit, getThreadRemovalType } from 'find-and-filter-data/realtime-view-member/public';
export const buildOverlayFromThreadListMember = (member, filters) => {
  const removal = getRemoval(member);
  const previous = getPrevious(member);
  if (removal) {
    const audit = getThreadRemovalAudit(removal);
    switch (getThreadRemovalType(removal)) {
      case 'ASSIGNMENT':
        {
          const assignee = getAssignee(member);
          return AssignmentRemovalOverlay({
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'AgentLocatorRecord | null' is not assignable... Remove this comment to see the full error message
            assignee,
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(Record<AgentLocatorProps> & Readonly<AgentL... Remove this comment to see the full error message
            initiatingAgent: getAgentLocatorFromAudit(audit)
          });
        }
      case 'FILTER_CHANGE':
        return FilterChangeRemovalOverlay(removal);
      case 'THREAD_STATUS':
        return ThreadStatusRemovalOverlay(removal);
      case 'TICKET_ASSOCIATION':
        return TicketAssociationRemovalOverlay(removal);
      case 'HARD_DELETION':
        return HardDeletionRemovalOverlay(removal);
      case 'INBOX_CHANGE':
        return InboxChangeRemovalOverlay(removal);
      case 'MULTIPLE':
        return buildOverlayFromMultipleRemovalType(removal);
      case 'UNKNOWN':
      default:
        return UnknownRemovalOverlay(removal);
    }
  } else if (isThreadListMemberFiltered(member, filters)) {
    if (previous && getStatus(previous)) {
      // status changed
      return ThreadStatusRemovalOverlay({
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChatFilterOptionsType | null' is not assigna... Remove this comment to see the full error message
        status: getStatus(member)
      });
    }
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Map<string, any> | null' is not assignable t... Remove this comment to see the full error message
    return FilterMisalignmentOverlay({
      previous
    });
  }
  return null;
};