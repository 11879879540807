import get from 'transmute/get';
import { MESSAGE_HEADER, QUICK_REPLIES } from '../../common-message-format/constants/attachmentTypes';
import { getPlainText, getRichText
// @ts-expect-error module not typed
} from '../../common-message-format/operators/commonMessageFormatGetters';
export const getAttachments = message => {
  return get('attachments', message);
};
export const getFilteredAttachmentByType = ({
  attachments,
  type
}) => {
  return attachments.find(attachment => {
    return get('@type', attachment) === type;
  });
};
export const getHeaderAttachment = message => {
  return getFilteredAttachmentByType({
    attachments: getAttachments(message),
    type: MESSAGE_HEADER
  });
};
export const getQuickRpliesAttachment = message => {
  return getFilteredAttachmentByType({
    attachments: getAttachments(message),
    type: QUICK_REPLIES
  });
};
export const getMessageText = message => {
  const plainText = getPlainText(message);
  const richText = getRichText(message);
  return richText && richText.length > 0 ? richText : plainText;
};
export const isPlainText = message => {
  const richText = getRichText(message);
  return !(richText && richText.length > 0);
};