import { ChatSpotV1StreamingClient } from 'ai-components-ui-library/clients/ChatSpotApi';
export const sendActionApi = ({
  data,
  onProgress,
  onClose,
  onError
}) => {
  return ChatSpotV1StreamingClient.post('api/action', {
    data,
    onProgress,
    onClose,
    onError
  });
};