'use es6';

import { gql } from '@apollo/client';
export const WebsiteActivityCardFragments = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"WebsiteActivityCardFragments\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"WebsiteActivityCard\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"cardId\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"crmObject\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"InlineFragment\",\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"Contact\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"defaultProperties\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"first_conversion_date\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"value\"}}]}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"hs_analytics_first_visit_timestamp\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"value\"}}]}}]}}]}}]}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "WebsiteActivityCardFragments"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "WebsiteActivityCard"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "cardId"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "crmObject"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "Contact"
              }
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "defaultProperties"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "first_conversion_date"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "id"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "value"
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "hs_analytics_first_visit_timestamp"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "id"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "value"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
});