import Raven from 'raven-js';
import { useCallback, useEffect, useState } from 'react';
import { uploadFile } from '../../draft-transplant/api/FileManagerApi';
import { getSignedSrc } from '../../draft-transplant/utils/Images';
import { HIDDEN_IN_APP_PRIVATE_NOT_INDEXABLE } from '../../draft-transplant/utils/constants';
export function useImageUploadHandler(props) {
  const {
    updateImage,
    attrs
  } = props;
  const [progress, setProgress] = useState(null);
  const [signedUrl, setSignedUrl] = useState(null);
  useEffect(() => {
    if (signedUrl && attrs.current.src !== signedUrl) {
      updateImage({
        image: {
          src: signedUrl,
          isTemporary: false,
          uploadPercent: 100,
          fileToUpload: null
        }
      });
    } else if (progress && attrs.current.uploadPercent !== progress) {
      updateImage({
        image: {
          uploadPercent: progress
        }
      });
    }
  }, [progress, signedUrl, updateImage, attrs]);
  const onProgress = useCallback(progressData => {
    if (!progressData.lengthComputable) {
      return;
    }
    const percentComplete = parseInt(`${progressData.loaded / progressData.total}`, 10) * 100;
    setProgress(percentComplete);
  }, [setProgress]);
  useEffect(() => {
    if (attrs.current.isTemporary && attrs.current.fileToUpload) {
      uploadFile(attrs.current.fileToUpload, {
        access: attrs.current.fileManagerAccess || HIDDEN_IN_APP_PRIVATE_NOT_INDEXABLE,
        onProgress
      }).then(data => {
        const {
          id
        } = data.objects[0];
        setSignedUrl(getSignedSrc(id));
      }).catch(error => {
        console.warn('Failed to upload file');
        Raven.captureException(error);
      });
    }
  }, [setSignedUrl, onProgress, attrs]);
}