import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"];
import { registerQuery, useQuery } from 'data-fetching-client';
import http from 'conversations-http/clients/http';
const GET_VISITORS_QUERY_NAME = 'visitors';
const GET_VISITORS_BATCH = registerQuery({
  fieldName: GET_VISITORS_QUERY_NAME,
  args: ['vids'],
  fetcher({
    vids
  }) {
    return http.get('messages/v2/visitor/batch', {
      query: {
        vid: vids
      }
    });
  }
});
export const useVisitorsBatch = ({
  vids
}) => {
  const _useQuery = useQuery(GET_VISITORS_BATCH, {
      variables: {
        vids
      }
    }),
    {
      data
    } = _useQuery,
    rest = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  return Object.assign({
    data: data && data[GET_VISITORS_QUERY_NAME]
  }, rest);
};