import { gql } from '@apollo/client';
export const AssigneeFragment = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"FragmentDefinition\",\"name\":{\"kind\":\"Name\",\"value\":\"AssigneeFragment\"},\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"Assignee\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"agent\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"InlineFragment\",\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"HubSpotUser\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"userId\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"firstName\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"email\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"lastName\"}}]}},{\"kind\":\"InlineFragment\",\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"Bot\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}}]}}]}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"avatarUrl\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"isAssignable\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"agentState\"}}]}}]}", {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "AssigneeFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "Assignee"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "agent"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "HubSpotUser"
              }
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "userId"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "firstName"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "email"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "lastName"
                }
              }]
            }
          }, {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "Bot"
              }
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatarUrl"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "isAssignable"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "agentState"
        }
      }]
    }
  }]
});
export const getAssigneeFromCache = (apolloClient, {
  assigneeId
}) => apolloClient.readFragment({
  id: `Assignee:${assigneeId}`,
  fragment: AssigneeFragment
});