import { liftTarget } from 'prosemirror-transform';
export function buildBlockLiftTransaction(state, nodeType) {
  const {
    from,
    to
  } = state.selection;
  const tr = state.tr;
  const nodeDetails = [];
  tr.doc.nodesBetween(from, to, (node, pos, parent) => {
    if (parent && parent.type === nodeType) {
      nodeDetails.push({
        node,
        pos
      });
      return false;
    }
    return true;
  });
  nodeDetails.forEach(npos => {
    const mappedPosition = tr.mapping.map(npos.pos);
    const range = tr.doc.resolve(mappedPosition).blockRange(tr.doc.resolve(mappedPosition + npos.node.nodeSize));
    const target = range && liftTarget(range);
    if (typeof target === 'number' && range) {
      tr.lift(range, target).scrollIntoView();
    }
  });
  return tr;
}