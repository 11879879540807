import { useEffect, useState } from 'react';

/**
 * This hook sets up an interval to update the returned "currentTime" object every {interval} seconds.
 * The goal of this is to force the calling component to update regularly, so that it counts down to dueDate in real time.
 *
 * @param {number} interval the frequency whith which "now" updates.
 * @returns {Date} the current time in date object format
 */
const TEN_SECONDS_IN_MS = 10000;
export const useCurrentTime = (interval = TEN_SECONDS_IN_MS) => {
  const [now, setNow] = useState(Date.now());
  const currentTime = new Date(now);
  useEffect(() => {
    const clockInterval = setInterval(() => {
      setNow(Date.now());
    }, interval);
    return () => clearInterval(clockInterval);
  }, [setNow, interval]);
  return {
    currentTime
  };
};