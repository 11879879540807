import { EMAIL_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { getSubject } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { findFirstVisitorEmail } from '../../../thread-histories/operators/findFirstVisitorEmail';
export const getTicketSubject = (source, history) => {
  if (source === EMAIL_GENERIC_CHANNEL_ID) {
    const firstVisitorEmail = findFirstVisitorEmail(history);
    return firstVisitorEmail ? getSubject(firstVisitorEmail) : '';
  }
  return '';
};