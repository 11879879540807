// gimme: conversations-inbox-ui 06/04/2020 c274e8ac5510b62b817eb74db200bc9802a2508d
'use es6';

export const getClickedModalCta = ({
  ctaAction,
  subscreen2 = null
}) => ({
  key: 'clicked-modal-cta-from-thread-view',
  properties: {
    action: 'clicked modal cta',
    'cta-action': ctaAction,
    subscreen2
  }
});