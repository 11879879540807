import { STARTED, UNINITIALIZED } from 'conversations-async-data/async-data/constants/asyncStatuses';
import { createSelector } from 'reselect';
export const selectCapacityLimits = state => state.capacityLimits;
export const selectAllAgentCapacities = state => selectCapacityLimits(state).agentCapacity;
export const selectAllPortalLimits = state => selectCapacityLimits(state).portalLimits;
const checkIsFetching = partState => {
  const status = partState.get('status');
  return !status || [STARTED, UNINITIALIZED].includes(status);
};
export const selectIsFetching = state => {
  const agentCapacity = selectAllAgentCapacities(state);
  const portalLimits = selectAllPortalLimits(state);
  return checkIsFetching(agentCapacity) || checkIsFetching(portalLimits);
};
export const selectLimitsEnabled = (state, type) => {
  const portalLimits = selectAllPortalLimits(state);
  if (checkIsFetching(portalLimits)) {
    return false;
  }
  const data = portalLimits.get('data');
  let limitsEnabled = false;
  if (data) {
    const portalLimit = data.get(type);
    limitsEnabled = portalLimit ? Boolean(portalLimit.isEnabled) : false;
  }
  return limitsEnabled;
};
const selectAgentCapacity = (state, type) => {
  const data = selectAllAgentCapacities(state).get('data');
  let agentCapacity = 0;
  if (data) {
    agentCapacity = data.get(type);
  }
  return agentCapacity || 0;
};
const selectPortalCapacityLimit = (state, type) => {
  const data = selectAllPortalLimits(state).get('data');
  if (data) {
    const portalLimit = data.get(type);
    if (portalLimit && portalLimit.isEnabled) {
      return portalLimit.capacityLimit || 0;
    }
  }

  // Returning null here indicates that there is no portal limit data or that
  // portal limits aren't enabled
  return null;
};
export const selectAtCapacity = createSelector([selectAgentCapacity, selectPortalCapacityLimit], (agentCapacity, portalLimit) =>
// `null` means that there are no portal limits set
// so the agent can never be at capacity
portalLimit !== null && agentCapacity >= portalLimit);