// gimme: conversations-inbox-ui 05/26/2020 741c62dc71ca742adbc6d64d61d30de7cb46539f
'use es6';

import { createAction } from 'redux-actions';
import Raven from 'raven-js';
import { getThreadId, getAgentToVisitorChannelName } from 'conversations-thread-data/thread-details/public/operators';
import { getFocusedThreadDetails } from '../../thread-details/selectors/getFocusedThreadDetails';
// constant directory has been migrated to thread-data, the remaining the this directory will be moved over soon
import { PUBLISH_EMAIL } from 'conversations-thread-data/email/public/constants/asyncActionTypes';
import { publishEmailFailed } from './publishEmailFailed';
import { closeThread } from 'conversations-thread-data/thread-actions/public/clients';
import { getIsCurrentVisitorAContactAssociatedToCurrentThread } from '../../thread-details/selectors/getIsCurrentVisitorAContactAssociatedToCurrentThread';
import { getEmailSendDataFromMessage } from 'composer-data/email/public/operators';
import { sendEmailReply } from 'composer-data/email/public/clients';
import { RATE_LIMITED } from 'conversations-thread-data/email/public/constants/failureErrorTypes';
// @ts-expect-error module not typed
import { getClientType } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { Metrics } from '../../common/Metrics';
const publishEmailStarted = createAction(PUBLISH_EMAIL.STARTED);
const publishEmailSucceeded = createAction(PUBLISH_EMAIL.SUCCEEDED);
export const publishEmail = (message, options = {}) => (dispatch, getState) => {
  const state = getState();
  const threadListMemberDetails = getFocusedThreadDetails(state);
  const threadId = getThreadId(threadListMemberDetails);
  const channel = getAgentToVisitorChannelName(threadListMemberDetails);
  const isVisitorAssociatedToThread = getIsCurrentVisitorAContactAssociatedToCurrentThread(state);
  // appending to options so we dont have to add an additional argument to the operator
  options.isVisitorAssociatedToThread = isVisitorAssociatedToThread;
  dispatch(publishEmailStarted({
    message,
    threadId,
    channel
  }));
  const data = getEmailSendDataFromMessage(message, options);
  const clientType = getClientType(message);
  Metrics.counter('publish-email-reply', {
    clientType
  }).increment();
  return sendEmailReply({
    data,
    threadId
  }).then(() => {
    dispatch(publishEmailSucceeded({
      message,
      threadId,
      channel
    }));
    if (options.closeOnSuccess) {
      return closeThread({
        threadId
      }).catch(error => {
        const {
          message: errorMessage,
          status,
          responseText
        } = error;
        Raven.captureException(new Error('Failed to close thread after sending email.'), {
          extra: {
            errorMessage,
            status,
            responseText
          },
          tags: {
            isVisibleToUser: true
          }
        });
      });
    } else {
      return Promise.resolve();
    }
  }).catch(error => {
    const {
      message: errorMessage,
      status,
      responseText
    } = error;
    if (!(responseText && responseText.includes(RATE_LIMITED))) {
      let errorMsg = '';
      let responseObj = {};
      try {
        responseObj = responseText && JSON.parse(responseText);
        errorMsg = responseObj.errorType ? responseObj.errorType : responseObj.message;
      } catch (e) {
        // do nothing
      }
      Raven.captureException(new Error(`Failed to send email. Status: ${status}, ${errorMsg}`), {
        fingerprint: ['{{ default }}', status],
        extra: {
          errorMessage,
          status,
          responseText
        },
        tags: {
          isVisibleToUser: true
        }
      });
    }
    dispatch(publishEmailFailed({
      allowRetry: !options.closeOnSuccess,
      error,
      message,
      threadId,
      channel
    }));
  });
};