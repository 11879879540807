'use es6';

import { handleActions } from 'redux-actions';
import ZorseInboxes from '../records/ZorseInboxes';
import { FETCH_ZORSE_INBOXES } from '../constants/asyncActionTypes';
export default handleActions({
  [FETCH_ZORSE_INBOXES.STARTED]: state => state,
  [FETCH_ZORSE_INBOXES.SUCCEEDED]: (_, {
    payload: {
      data
    }
  }) => new ZorseInboxes(Object.assign({}, data)),
  [FETCH_ZORSE_INBOXES.FAILED]: state => state
}, new ZorseInboxes());