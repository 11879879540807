'use es6';

import { Map as ImmutableMap } from 'immutable';
import reduce from 'transmute/reduce';
import { buildSenderLocator } from 'conversations-message-history/senders/operators/buildSenderLocator';
import Sender from 'conversations-message-history/senders/records/Sender';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { updateData } from 'conversations-async-data/async-data/operators/setters';
import { VISITOR } from 'conversations-message-history/common-message-format/constants/legacySenderTypes';
export const buildSendersFromVisitorsResponse = (visitorsResponse = ImmutableMap()) => {
  return reduce(ImmutableMap(), (accumulator, asyncVisitor, visitorKey) => {
    const senderLocator = buildSenderLocator(VISITOR, visitorKey);
    if (!getData(asyncVisitor)) {
      const updatedVisitor = updateData(() => Sender(), asyncVisitor);
      return accumulator.set(senderLocator, updatedVisitor);
    }
    const updatedVisitor = updateData(visitor => {
      const {
        avatar,
        vid,
        email,
        firstName,
        lastName
      } = visitor ? visitor.toJS() : {};
      return Sender({
        avatar,
        email,
        firstName,
        id: vid,
        lastName,
        senderType: VISITOR
      });
    }, asyncVisitor);
    return accumulator.set(senderLocator, updatedVisitor);
  }, visitorsResponse);
};