import formatName from 'I18n/utils/formatName';
// @ts-expect-error module not typed

export const getEmailAddressOptions = (emailAddressRecords, {
  clearable = false,
  getValidationData,
  emailBlocklistStatus
} = {}) => {
  return emailAddressRecords.filter(emailAddressRecord => emailAddressRecord.get('address')).map(emailAddressRecord => {
    const firstName = emailAddressRecord.get('firstName');
    const lastName = emailAddressRecord.get('lastName');
    const address = emailAddressRecord.get('address');
    const name = formatName({
      firstName,
      lastName
    });
    const text = name && address ? `${name} (${address})` : name || address;
    const eachEmailOptions = {
      name,
      address,
      text,
      value: emailAddressRecord.get('address'),
      clearableValue: clearable
    };

    // This is only executed for selected option tags
    if (getValidationData) {
      const validationData = getValidationData(address);
      eachEmailOptions.validationData = validationData;
      eachEmailOptions.tagUse = !validationData.isEmailValid ? 'danger' : 'default';
    }
    if (emailBlocklistStatus) {
      eachEmailOptions.isEmailLoggingBlocked = emailBlocklistStatus[address];
    }
    return eachEmailOptions;
  }).toArray();
};