// TODO: move to composer-data
import invariant from 'conversations-thread-data/utils/invariant';
import { LIMITED } from 'conversations-thread-data/sms/public/constants';
export const smsBillingLimit = ({
  billingLimitStatus
}) => {
  invariant(Boolean(billingLimitStatus), 'billingLimitStatus is required for smsBillingLimit disabling strategy');
  return billingLimitStatus === LIMITED ? {
    disabled: true,
    sendDisabledReason: 'SMS_BILLING_LIMIT_REACHED'
  } : null;
};