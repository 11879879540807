import concat from 'transmute/concat';
import updateIn from 'transmute/updateIn';
import compose from 'transmute/compose';
import { getCommonMessage } from './commonReplyGetters';
import { SENT } from 'conversations-message-history/common-message-format/constants/statusTypes';
import { setAttachment } from 'conversations-message-history/common-message-format/operators/commonMessageSetters';
import { buildCommonMessage } from 'conversations-message-history/common-message-format/operators/buildCommonMessage';
import { ATTACHMENTS } from 'conversations-message-history/common-message-format/constants/keyPaths';
// @ts-expect-error module not typed
import { buildStatus } from 'conversations-message-history/common-message-format/operators/buildStatus';
// @ts-expect-error module not typed
import { autoLink } from '../../_internal/operators/autoLink';
import { buildAtMentionAttachments } from './buildAtMentionAttachments';
import { buildMessagePositionDescriptor } from '../../../common-reply-metadata/public/operators/buildMessagePositionDescriptor';
export const buildPublishableCommonMessage = (commonReply, {
  html,
  plainText
}, {
  latestAgentCommonMessageId = null,
  requireMessagePositionDescriptor = false
}) => {
  const baseCommonMessage = getCommonMessage(commonReply);
  const atMentionAttachments = buildAtMentionAttachments({
    html
  });
  const richText = autoLink(html);
  const status = buildStatus({
    messageStatus: SENT
  });
  const commonMessageProps = baseCommonMessage ? baseCommonMessage.toJS() : {};
  const message = buildCommonMessage(Object.assign({}, commonMessageProps, {
    id: null,
    timestamp: null,
    status,
    richText,
    text: plainText
  }));
  return compose(commonMessage => updateIn(ATTACHMENTS, concat(atMentionAttachments), commonMessage), commonMessage => requireMessagePositionDescriptor && latestAgentCommonMessageId ? setAttachment(buildMessagePositionDescriptor(latestAgentCommonMessageId), commonMessage) : commonMessage)(message);
};