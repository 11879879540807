/* IDs should correspond to https://git.hubteam.com/HubSpot/cv-common/blob/master/cv-common-core/src/main/java/com/hubspot/cv/common/core/HubSpotGenericChannel.java */
import { EMAIL_GENERIC_CHANNEL_ID, FB_MESSENGER_GENERIC_CHANNEL_ID, LIVE_CHAT_GENERIC_CHANNEL_ID, COMMENTS_GENERIC_CHANNEL_ID, WHATSAPP_GENERIC_CHANNEL_ID, FEEDBACK_GENERIC_CHANNEL_ID, FORMS_GENERIC_CHANNEL_ID, CUSTOMER_PORTAL_GENERIC_CHANNEL_ID, CALLING_GENERIC_CHANNEL_ID, SMS_GENERIC_CHANNEL_ID } from '../../generic-channels/public/constants';
import { THREAD_COMMENT } from 'conversations-message-history/comment-message/constants/messageTypes';
export const getUsageTrackingChannel = editorConfigKey => {
  switch (editorConfigKey) {
    case EMAIL_GENERIC_CHANNEL_ID:
      return `Email (${EMAIL_GENERIC_CHANNEL_ID})`;
    case FB_MESSENGER_GENERIC_CHANNEL_ID:
      return `Facebook Messenger (${FB_MESSENGER_GENERIC_CHANNEL_ID})`;
    case LIVE_CHAT_GENERIC_CHANNEL_ID:
      return `Live Chat (${LIVE_CHAT_GENERIC_CHANNEL_ID})`;
    case COMMENTS_GENERIC_CHANNEL_ID:
      return `Comments (${COMMENTS_GENERIC_CHANNEL_ID})`;
    case THREAD_COMMENT:
      return `Comments (${THREAD_COMMENT})`;
    case WHATSAPP_GENERIC_CHANNEL_ID:
      return `WhatsApp (${WHATSAPP_GENERIC_CHANNEL_ID})`;
    case FEEDBACK_GENERIC_CHANNEL_ID:
      return `Feedback (${FEEDBACK_GENERIC_CHANNEL_ID})`;
    case FORMS_GENERIC_CHANNEL_ID:
      return `Forms (${FORMS_GENERIC_CHANNEL_ID})`;
    case CUSTOMER_PORTAL_GENERIC_CHANNEL_ID:
      return `Customer Portal (${CUSTOMER_PORTAL_GENERIC_CHANNEL_ID})`;
    case CALLING_GENERIC_CHANNEL_ID:
      return `Calling (${CALLING_GENERIC_CHANNEL_ID})`;
    case SMS_GENERIC_CHANNEL_ID:
      return `SMS (${SMS_GENERIC_CHANNEL_ID})`;
    case 'UNSET':
      return `No channel set`;
    default:
      return `${editorConfigKey}`;
  }
};