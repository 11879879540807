export const FIRST_NAME = 'firstname';
export const LAST_NAME = 'lastname';
export const JOB_TITLE = 'jobtitle';
export const COMPANY = 'company';
export const ASSOCIATED_COMPANY_ID = 'associatedcompanyid';
export const ASSOCIATED_COMPANY_NAME = 'name';
export const EMAIL = 'email';
export const PHONE = 'phone';
export const OWNER = 'hubspot_owner_id';
export const TEAM = 'hubspot_team_id';
export const VID = 'vid';
export const PORTAL_ID = 'portal-id';
export const CANONICAL_VID = 'canonical-vid';
export const ADDITIONAL_EMAILS = 'hs_additional_emails';
export const KNOWN_CONTACT = 'is-contact';
export const MERGED_VIDS = 'merged-vids';