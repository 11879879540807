import http from 'conversations-http/clients/http';
import { GENERIC_CRM_PROPERTIES, mapContactsToObjectRecord, mapContactsToGenericObjectRecord } from '../operators/mapContactsToObjectRecord';
const getRecipientSearchValues = queryString => {
  const lowerCaseString = queryString.toLowerCase();
  return lowerCaseString.split(/\s/);
};

// https://tools.hubteam.com/api-catalog/services/CrmSearchService/v1/spec/internal#operations-Search-post-%2Fcrm-search%2Fsearch

const SEARCH_URL = 'crm-search/search';
const DEFAULT_PROPERTIES = ['firstname', 'lastname'];
const ADDITIONAL_EMAIL_PROPERTIES = [...DEFAULT_PROPERTIES, 'hs_additional_emails'];
const ALL_SEARCHABLE_PROPERTIES = [...ADDITIONAL_EMAIL_PROPERTIES, ...GENERIC_CRM_PROPERTIES];
const defaultFiltersForFuzzyNameMatching = (searchTerm, queries) => {
  const defaultFilters = [{
    filters: [{
      property: 'firstname',
      value: `${searchTerm}*`,
      operator: 'EQ'
    }]
  }, {
    filters: [{
      property: 'lastname',
      value: `${searchTerm}*`,
      operator: 'EQ'
    }]
  }];
  if (queries.length > 1) {
    const filterForFirstAndLast = {
      filters: [{
        property: 'firstname',
        value: `${queries[0]}*`,
        operator: 'EQ'
      }, {
        property: 'lastname',
        value: `${queries[1]}*`,
        operator: 'EQ'
      }]
    };
    defaultFilters.push(filterForFirstAndLast);
  }
  if (queries.length) {
    const filterWithFullQueries = {
      filters: [{
        property: 'firstname',
        values: queries,
        operator: 'IN'
      }, {
        property: 'lastname',
        values: queries,
        operator: 'IN'
      }]
    };
    defaultFilters.push(filterWithFullQueries);
  }
  return defaultFilters;
};
const getDefaultProperties = contactProperty => {
  switch (contactProperty) {
    case undefined:
    case 'unset':
      return ALL_SEARCHABLE_PROPERTIES;
    case 'email':
      return ['email', ...ADDITIONAL_EMAIL_PROPERTIES];
    default:
      return [contactProperty, ...DEFAULT_PROPERTIES];
  }
};
const makeDeliveryIdentifierFilters = (contactProperty, searchTerm) => {
  const propFilter = property => ({
    filters: [{
      property,
      value: `${searchTerm}*`,
      operator: 'EQ'
    }]
  });
  return !contactProperty || contactProperty === 'unset' ? GENERIC_CRM_PROPERTIES.map(di => propFilter(di)) : [propFilter(contactProperty)];
};
export const postRecipientSearch = ({
  searchTerm,
  deliveryIdentifier,
  query
}) => {
  const defaultProperties = getDefaultProperties(deliveryIdentifier);
  const safeSearchTerm = searchTerm.replace(/"/g, "'");
  const queries = getRecipientSearchValues(safeSearchTerm);
  return http.post(SEARCH_URL, {
    query,
    data: {
      objectTypeId: 'CONTACT',
      count: 30,
      requestOptions: {
        properties: defaultProperties
      },
      filterGroups: [{
        filters: [{
          property: deliveryIdentifier,
          value: `${safeSearchTerm}*`,
          operator: 'EQ'
        }]
      }, ...defaultFiltersForFuzzyNameMatching(safeSearchTerm, queries)]
    }
  }).then(({
    results
  }) => {
    return mapContactsToObjectRecord(results, deliveryIdentifier);
  });
};
export const postRecipientGenericSearch = ({
  searchTerm,
  contactProperty,
  query
}) => {
  const properties = getDefaultProperties(contactProperty);
  const safeSearchTerm = searchTerm.replace(/"/g, "'");
  const queries = getRecipientSearchValues(safeSearchTerm);
  return http.post(SEARCH_URL, {
    query,
    data: {
      objectTypeId: 'CONTACT',
      count: 30,
      requestOptions: {
        properties
      },
      filterGroups: [...makeDeliveryIdentifierFilters(contactProperty, safeSearchTerm), ...defaultFiltersForFuzzyNameMatching(safeSearchTerm, queries)]
    }
  }).then(({
    results
  }) => {
    return mapContactsToGenericObjectRecord(results);
  });
};