'use es6';

import { getSenderId, getSenderType } from 'conversations-message-history/senders/operators/senderLocatorGetters';
import { getAgentTypeFromSenderType } from '../operators/getAgentTypeFromSenderType';

/**
 * @param {Object} options
 * @param {Set<SenderLocator>} options.senderLocators
 * @returns {Array<Object>} an array of objects, each of which
 * contains an agentType and an array of agentIds to fetch for
 * that type
 */
export const buildAgentRequestData = ({
  senderLocators
}) => {
  const keyedRequestObjects = senderLocators.reduce((accumulator, senderLocator) => {
    const senderType = getSenderType(senderLocator);
    const agentType = getAgentTypeFromSenderType(senderType);
    const agentId = getSenderId(senderLocator);
    return Object.assign({}, accumulator, {
      [agentType]: {
        agentType,
        agentIds: [...(accumulator[agentType] && accumulator[agentType].agentIds || []), agentId]
      }
    });
  }, {});
  return Object.keys(keyedRequestObjects).reduce((accumulator, key) => [...accumulator, keyedRequestObjects[key]], []);
};