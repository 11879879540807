// gimme: conversations-inbox-ui 06/10/2020 24a2e66917cc934433dd111db7e06fd02943ca6a

import sortBy from 'transmute/sortBy';
import { getMessage } from 'conversations-message-history/unpublished-messages/operators/getters';
import { getUnpublishedMessagesForThreadId } from './getUnpublishedMessagesForThreadId';
// @ts-expect-error commonMessageFormatGetters is not typed
import { getTimestamp } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { createSelector } from 'reselect';
export const getSortedUnpublishedMessagesForThreadId = createSelector([getUnpublishedMessagesForThreadId], unpublishedMessages => {
  return sortBy(message => {
    return getTimestamp(getMessage(message));
  }, unpublishedMessages);
});