import Ably from 'ably-hubspot-js';
import promiseTimeout from 'hs-promise-utils/timeout';
import { buildError } from '../../error-reporting/builders/buildError';
import { buildRequestTimeoutError } from '../../errors/builders/buildRequestTimeoutError';
import { verifyPublish } from './verifyPublish';
import { REALTIME_API_V1 } from '../constants/realtimeApiVersions';
import { PROMISE_TIMEOUT_MS } from '../constants/defaults';
import { reportError } from '../../error-reporting/reportError';
const {
  Message
} = Ably.Realtime;
export function performPublish(channels, {
  channelId,
  data,
  messageId,
  publishVerifier
}) {
  const channel = channels.get(channelId);
  const message = Message.fromEncoded({
    id: messageId,
    data,
    name: REALTIME_API_V1
  });
  const publishFlowPromise = new Promise((resolve, reject) => {
    const publishVerification = verifyPublish(channels, channelId, messageId, publishVerifier).catch(error => reject(error));
    new Promise((resolvePublish, rejectPublish) => {
      channel.publish(message, errorInfo => {
        if (errorInfo) {
          rejectPublish(buildError(`PubSub publish failure: ${errorInfo.code}`, Object.assign({
            channelId
          }, errorInfo)));
        } else {
          resolvePublish(message);
        }
      });
    }).then(() => publishVerification).then(results => resolve(results), error => reject(buildError(error.message, Object.assign({
      channelId
    }, error)))).catch(err => {
      reportError({
        error: err
      });
    });
  });
  return promiseTimeout(publishFlowPromise, PROMISE_TIMEOUT_MS, buildRequestTimeoutError('PubSub publish timeout', {
    channelId,
    messageId,
    data
  }));
}