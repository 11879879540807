import { updateCachedAgentStatus } from '../clients/cache';
/**
 * @description This function takes in realtime AgentAvailabilityMessage message from
 * pubsub and update the apollo cache being used in data-fecthing-client
 * @param client Apollo Data fetching client
 * @param message realtime AgentAvailabilityMessage message
 */
export const updateAgentStatusFromRealtimeMessage = ({
  client,
  message,
  workspace
}) => {
  const {
    agentId,
    awayMode,
    updatedByAgentId,
    timestamp
  } = message;
  const agentStatus = {
    agentId,
    awayMode,
    portalId: null,
    updatedByAgentId,
    updatedAt: timestamp
  };
  updateCachedAgentStatus({
    agentStatus,
    client,
    workspace
  });
};