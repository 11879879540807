import http from 'conversations-http/clients/http';
import PortalIdParser from 'PortalIdParser';
import { formatAgentRequestData } from '../operators/formatAgentRequestData';
import { INTERNAL } from '../constants/AccessTypes';
export function fetchAgentResponders(agents) {
  const data = formatAgentRequestData(agents);
  return http.put('messages/v2/message/agents/batch', {
    data,
    query: {
      accessType: INTERNAL,
      portalId: PortalIdParser.get()
    }
  });
}