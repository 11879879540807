import Track from '../../shared/utils/trackers/ZorseCrmComponentsTracker';
import { CLOSE_THREAD, FETCH_PROPERTIES_META, FETCH_PROPERTIES_FOR_THREAD, MARK_THREAD_AS_GHOSTED, PATCH_PROPERTY_FOR_THREAD, RESOLVE_UPDATE_PROPERTY_ERROR } from './ActionTypes';
import { getPropertiesMeta, getPropertiesForThread, patchPropertyForThread, resolveThread } from '../utils/api/ConversationsTaxonomyApi';
import { PRODUCT_INTEREST, TIER_FIT } from '../constants/ConversationsProperties';
export const fetchPropertiesMeta = () => {
  return dispatch => {
    dispatch({
      type: FETCH_PROPERTIES_META,
      meta: 'request'
    });
    return getPropertiesMeta().then(payload => dispatch({
      type: FETCH_PROPERTIES_META,
      meta: 'response',
      payload
    }), payload => dispatch({
      type: FETCH_PROPERTIES_META,
      error: true,
      payload
    }));
  };
};
export const fetchPropertiesForThread = threadId => {
  return dispatch => {
    dispatch({
      type: FETCH_PROPERTIES_FOR_THREAD,
      meta: 'request'
    });
    return getPropertiesForThread(threadId).then(payload => dispatch({
      type: FETCH_PROPERTIES_FOR_THREAD,
      meta: 'response',
      payload
    }), payload => dispatch({
      type: FETCH_PROPERTIES_FOR_THREAD,
      error: true,
      payload
    }));
  };
};
export const setPropertyForThread = (threadId, property, threadRecord) => {
  let newProperties;
  if (property.shouldResetOtherProperties()) {
    newProperties = [property, threadRecord.properties.get(PRODUCT_INTEREST).set('value', ['NOT_APPLICABLE']), threadRecord.properties.get(TIER_FIT).set('value', 'NOT_APPLICABLE')];
  } else {
    newProperties = [property];
  }
  return dispatch => {
    dispatch({
      type: PATCH_PROPERTY_FOR_THREAD,
      meta: 'request'
    });
    Track.updateThreadProperty(threadId, property.name, property.value);
    return patchPropertyForThread(threadId, newProperties).then(payload => dispatch({
      type: PATCH_PROPERTY_FOR_THREAD,
      meta: 'response',
      payload
    }), payload => dispatch({
      type: PATCH_PROPERTY_FOR_THREAD,
      error: true,
      payload
    }));
  };
};
export const closeThread = (threadId, threadRecord) => {
  return dispatch => {
    dispatch({
      type: CLOSE_THREAD,
      meta: 'request'
    });
    Track.resolveConversation(threadId, threadRecord);
    return resolveThread(threadId).then(() => dispatch({
      type: CLOSE_THREAD,
      meta: 'response'
    }), () => dispatch({
      type: CLOSE_THREAD,
      error: true
    }));
  };
};
export const markThreadAsGhosted = (threadId, value) => {
  return dispatch => {
    Track.markThreadAsGhosted(threadId, value);
    dispatch({
      type: MARK_THREAD_AS_GHOSTED,
      value
    });
  };
};
export const resolveUpdatePropertyError = () => {
  return dispatch => dispatch({
    type: RESOLVE_UPDATE_PROPERTY_ERROR
  });
};