import { useQuery } from 'data-fetching-client';
import { fetchInboxActiveMigrationStatusQuery, fetchChannelsActiveMigrationStatusQuery, fetchMigrationStatusQuery } from '../queries/help-desk-migration.queries';
import { CHANNEL_MIGRATION_STATES } from '../constants/channel-migration';
export const useMigrationState = ({
  channelInstanceId,
  notifyOnNetworkStatusChange,
  onCompleted,
  onError,
  skip
}) => {
  const {
    data,
    error,
    loading,
    refetch,
    startPolling,
    stopPolling
  } = useQuery(fetchMigrationStatusQuery, {
    notifyOnNetworkStatusChange,
    onCompleted: response => {
      if (onCompleted) {
        onCompleted(response.helpdeskMigrationStatus);
      }
    },
    onError,
    skip,
    variables: {
      channelInstanceId
    }
  });
  let isChannelBeingMigrated = false;
  let migrationStatus = CHANNEL_MIGRATION_STATES.NOT_STARTED;
  let moveConversations = false;
  if (data) {
    ({
      migrationStatus,
      moveConversations
    } = data.helpdeskMigrationStatus);
    isChannelBeingMigrated = migrationStatus === CHANNEL_MIGRATION_STATES.SCHEDULED || migrationStatus === CHANNEL_MIGRATION_STATES.STARTED;
  }
  return {
    error,
    isChannelBeingMigrated,
    loading,
    migrationStatus,
    moveConversations,
    refetch,
    startPolling,
    stopPolling
  };
};
export const useMigrationStateForInbox = (inboxId, skip) => {
  const {
    data,
    error,
    loading
  } = useQuery(fetchInboxActiveMigrationStatusQuery, {
    variables: {
      inboxId
    },
    skip
  });
  return {
    error,
    loading,
    migrationStates: data ? data.helpdeskInboxActiveMigrationStatus : []
  };
};
export const useMigrationStateForChannels = (channelInstanceIds, skip) => {
  const {
    data,
    error,
    loading
  } = useQuery(fetchChannelsActiveMigrationStatusQuery, {
    variables: {
      channelInstanceIds
    },
    skip
  });
  return {
    error,
    loading,
    migrationStates: data ? data.helpdeskChannelsMigrationStatus : []
  };
};

// migrationStates is an array of channels that are being migrated
// if the array is empty, it means there is no active migration for the inbox
export const useHasActiveMigrationForInbox = (inboxId, skip) => {
  const {
    migrationStates
  } = useMigrationStateForInbox(inboxId, skip);
  // when there is no active migration, migrationStates is an empty array
  // when there is an active migration, migrationStates is the object of the channel being migrated
  return migrationStates.length !== 0;
};
export const useHasActiveMigrationForChannels = (channelInstanceIds, skip) => {
  const {
    migrationStates
  } = useMigrationStateForChannels(channelInstanceIds, skip);
  if (migrationStates === null || migrationStates === undefined) {
    return false;
  }
  // migrationChannel is null when the channel is not being migrated
  // and is an object with migrationStatus when the channel is being migrated
  for (const migrationChannel of Object.values(migrationStates)) {
    if (migrationChannel === null || migrationChannel === undefined) {
      continue;
    }
    if (migrationChannel.migrationStatus === CHANNEL_MIGRATION_STATES.SCHEDULED || migrationChannel.migrationStatus === CHANNEL_MIGRATION_STATES.STARTED) {
      return true;
    }
  }
  return false;
};