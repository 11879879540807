'use es6';

import setIn from 'transmute/setIn';
import PortalIdParser from 'PortalIdParser';
import { LastViewedInbox, LAST_VIEWED } from '../records/LastViewedInboxes';
export const updateLastViewedInbox = ({
  inboxId,
  viewId
}, lastViewedInboxes) => {
  if (!inboxId) {
    return lastViewedInboxes;
  }
  const portalId = PortalIdParser.get();
  return setIn([LAST_VIEWED, `${portalId}`], LastViewedInbox({
    inboxId,
    viewId: viewId || null
  }))(lastViewedInboxes);
};