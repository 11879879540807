// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Redu... Remove this comment to see the full error message
import { removeNotification } from 'ReduxMessenger/actions/NotificationActions';
import { bulkUpdateReceived } from 'find-and-filter-data/bulk-operations/public';
import { THREAD_UNAVAILABLE_ALERT, BULK_UPDATE_FAILURE_ALERT } from '../constants/AlertTypes';
import { getThreadListMemberFilters } from '../../thread-list-member/selectors/getThreadListMemberFilters';
export const reloadThreadsData = () => (dispatch, getState) => {
  const {
    inboxId,
    sortDirection,
    threadStatus,
    viewId
  } = getThreadListMemberFilters(getState());
  if (inboxId !== null && viewId !== null) {
    dispatch(bulkUpdateReceived({
      inboxId,
      sortDirection,
      threadStatus: threadStatus,
      viewId
    }));
  }
  dispatch(removeNotification(THREAD_UNAVAILABLE_ALERT));
  dispatch(removeNotification(BULK_UPDATE_FAILURE_ALERT));
};