import { combineActions, handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { deleteEntry } from 'conversations-async-data/indexed-async-data/operators/deleteEntry';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { FETCH_CONVERSATION_ID_BY_THREAD_ID } from '../constants/asyncActionTypes';
import { limitToCount } from 'conversations-async-data/indexed-async-data/eviction-strategies/limitToCount';
import { MARK_AS_SPAM, UNMARK_AS_SPAM } from 'conversations-thread-data/thread-actions/public/constants';
const CV_LIMIT = 25;
const initialState = new IndexedAsyncData({
  evict: limitToCount(CV_LIMIT),
  name: 'conversationIdByThreadId',
  notSetValue: new AsyncData({
    data: null
  })
});
export default handleActions({
  [FETCH_CONVERSATION_ID_BY_THREAD_ID.STARTED]: (state, action) => {
    const {
      threadId
    } = action.payload.requestArgs;
    return updateEntry(threadId, requestStarted, state);
  },
  [FETCH_CONVERSATION_ID_BY_THREAD_ID.SUCCEEDED](state, action) {
    const {
      data,
      requestArgs
    } = action.payload;
    const {
      threadId
    } = requestArgs;
    return updateEntry(threadId, requestSucceededWithOperator(() => data), state);
  },
  [FETCH_CONVERSATION_ID_BY_THREAD_ID.FAILED](state, action) {
    const {
      threadId
    } = action.payload.requestArgs;
    return updateEntry(threadId, requestFailed, state);
  },
  [combineActions(MARK_AS_SPAM.SUCCEEDED, UNMARK_AS_SPAM.SUCCEEDED).toString()](state, action) {
    const {
      threadId
    } = action.payload.requestArgs;
    return deleteEntry(threadId, state);
  }
}, initialState);