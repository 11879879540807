import { useContext, useMemo } from 'react';
import { getEditorConfig, getFileAttachments, getEditorPluginsCapabilities } from 'conversations-thread-data/channel-configuration/public/operators';
// TODO: move to composer-ui
import { ChannelCapabilitiesConfigContext } from 'conversations-thread-view/channel-capabilities/context/ChannelCapabilitiesConfigContext';
import { useProsemirrorEditorConfig } from './useProsemirrorEditorConfig';
/**
 * @deprecated
 * This hook has been deprecated in favor of useProsemirrorConfigFromCapabilities
 * We no longer want to use the FE capabilities blob to derive capabilities
 * Delete this hook once each CapabilityConfig has been migrated over to the new hook
 */
export const useEditorCapabilities = ({
  renderExistingFileSelect,
  isUngatedForConversationSummary,
  isUngatedForAiAssistant,
  threadId
}) => {
  const {
    capabilitiesBlob
  } = useContext(ChannelCapabilitiesConfigContext);
  const fileAttachmentsConfig = getFileAttachments(capabilitiesBlob) || null;
  const editorConfig = useMemo(() => {
    return getEditorConfig(capabilitiesBlob);
  }, [capabilitiesBlob]);
  const derivedCapabilities = useMemo(() => {
    return getEditorPluginsCapabilities(editorConfig, fileAttachmentsConfig, isUngatedForConversationSummary, isUngatedForAiAssistant);
  }, [editorConfig, fileAttachmentsConfig, isUngatedForConversationSummary, isUngatedForAiAssistant]);
  const capabilitiesConfig = useProsemirrorEditorConfig(derivedCapabilities, {
    editorConfig,
    fileAttachmentsConfig,
    renderExistingFileSelect,
    threadId
  });
  return capabilitiesConfig;
};