import { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Raven from 'raven-js';
// use to instantiate new session data entries
const newSessionData = {
  totalDuration: 0,
  totalEvents: 0,
  eventsAfterOneMinute: 0,
  durationAfterOneMinute: 0
};
const processLongTask = (entry, allSessionData, inputKey) => {
  if (!inputKey) return allSessionData;
  const {
    startTime,
    duration
  } = entry;

  // branch report bucketing based on whether issue occured after the 1 minute mark
  // from previous experience, there's some initial load churn and high CPU usage
  // that fades around 20s in.  This way, we can separate long tasks due to initial load
  // issues vs. "long standing" issues that pop up later in a session
  const isAfterOneMinute = startTime > 60 * 1000;
  const sessionData = allSessionData[inputKey] || newSessionData;
  return Object.assign({}, allSessionData, {
    [inputKey]: {
      durationAfterOneMinute: isAfterOneMinute ? sessionData.durationAfterOneMinute + duration : sessionData.durationAfterOneMinute,
      eventsAfterOneMinute: isAfterOneMinute ? sessionData.eventsAfterOneMinute + 1 : sessionData.eventsAfterOneMinute,
      totalDuration: sessionData.totalDuration + duration,
      totalEvents: sessionData.totalEvents + 1
    }
  });
};

/**
 * TODO: move this into a PerformancePlugin and set state
 */
// eslint-disable-next-line no-restricted-syntax
let allSessionData = {};

// handles NR reporting

// eslint-disable-next-line no-restricted-syntax
let shouldLog = false;
window.addEventListener('visibilitychange', () => {
  // you can use: `window.dispatchEvent(new Event('visibilitychange'))` to test this output
  if (document.visibilityState === 'hidden') {
    console.debug(allSessionData);
    if (!shouldLog) return;
    Object.keys(allSessionData).forEach(inputName => {
      Raven.capturePageEvent('hsRtePerformanceReport', {
        extra: Object.assign({
          inputName
        }, allSessionData[inputName])
      });
    });
  }
});
const lastEventTimeStampByInput = {};
// eslint-disable-next-line no-restricted-syntax
let lastTextInputUsed = '';
// eslint-disable-next-line no-restricted-syntax
let perfObs;

// Set up perf Observer once, you only need one instance, otherwise
// performance issues (from measuring the perf!) will ensue
const setUpPerfObserver = () => {
  if (window.PerformanceObserver) {
    try {
      // once per mount
      if (!perfObs) {
        // Setup Listener

        perfObs = new window.PerformanceObserver(list => {
          const entries = list.getEntries();
          entries.forEach(entry => {
            const lastInputTimeStamp = lastTextInputUsed ? lastEventTimeStampByInput[lastTextInputUsed] : 0;
            if (lastInputTimeStamp && entry.startTime < lastInputTimeStamp && entry.startTime + entry.duration > lastInputTimeStamp) {
              allSessionData = processLongTask(entry, allSessionData, lastTextInputUsed);
            }
          });
        });
        perfObs.observe({
          entryTypes: ['longtask']
        });
      }
    } catch (e) {
      // not all browsers suport longtask or PerformanceObserver.prototype.observe so catch if any of the above fails
      // for more information see https://git.hubteam.com/HubSpot/customer-data-rte/pull/120
    }
  }
};
export const usePerformanceMonitoring = ({
  performanceTrackingConfig,
  ref
}) => {
  const editorInstance = ref && ref.current;
  const {
    instanceKey
  } = performanceTrackingConfig || {};
  useEffect(setUpPerfObserver, []);
  useEffect(() => {
    if (instanceKey) shouldLog = true;
  }, [instanceKey]);
  const registerNewInput = inputKey => {
    return inputEvent => {
      lastTextInputUsed = inputKey;
      lastEventTimeStampByInput[inputKey] = inputEvent.timeStamp;
    };
  };
  const perfEventListener = useRef(() => {});
  useEffect(() => {
    if (!instanceKey || !editorInstance) return () => {};
    const editorEl = ReactDOM.findDOMNode(editorInstance);
    const editorContentsEl = editorEl && editorEl.querySelector && editorEl.querySelector('[data-test-id="rte-content"]');
    if (!editorContentsEl) return () => {};
    perfEventListener.current = registerNewInput(instanceKey);
    editorContentsEl.addEventListener('textInput', perfEventListener.current);

    // cleanup
    return () => {
      return editorContentsEl.removeEventListener('textInput', perfEventListener.current);
    };
  }, [editorInstance, instanceKey]);
};
export const useEditorUsageLogging = () => {
  // once per mount, log that editor was loaded
  useEffect(() => {
    Raven.capturePageEvent('hsRteUsageStats');
  }, []);
};