import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import { localStorage } from 'conversations-platform/localStorage';
export const saveToLocalStorage = (storageKey, data) => {
  return new Promise((resolve, reject) => {
    try {
      localStorage.setItem(storageKey, data);
      resolve();
    } catch (error) {
      if (error && error instanceof DOMException && (error.name === 'QuotaExceededError' || error.code === DOMException.QUOTA_EXCEEDED_ERR)) {
        /**
         * If the quota is exceeded we don't want to send this message out to sentry as there is nothing
         * meaningful that can be done within the scope of this function. Instead the caller of this function
         * should have an appropriate eviction strategy in place. Logging the exception to the console here in
         * case the customer reaches out with a problem that is not yet handled with an appropriate eviction
         * strategy.
         */
        // eslint-disable-next-line no-console
        console.error(error);
      } else if (error instanceof Error) {
        reportError({
          error: new Error('FAILED TO WRITE DRAFT'),
          extra: {
            error
          }
        });
      }
      reject(error);
    }
  });
};