'use es6';

import curry from 'transmute/curry';
import { getNumDeleteStatusUpdatesForType } from '../../message-updates/operators/getNumDeleteStatusUpdatesForType';
import { HARD_DELETED, NOT_DELETED, SOFT_DELETED } from '../../common-message-format/constants/messageDeleteStatus';
import { threadHistoryInvariant } from '../invariants/threadHistoryInvariant';
import { getNumSoftDeletedMessages } from './getters';
import { setNumSoftDeletedMessages } from './setters';
export const applyDeletesToThreadHistory = curry((updates, threadHistory) => {
  threadHistoryInvariant(threadHistory);
  const numHardDeletes = getNumDeleteStatusUpdatesForType(updates, HARD_DELETED) || 0;
  const numSoftDeletes = getNumDeleteStatusUpdatesForType(updates, SOFT_DELETED) || 0;
  const numNonDeletes = getNumDeleteStatusUpdatesForType(updates, NOT_DELETED) || 0;
  if (numHardDeletes || numSoftDeletes || numNonDeletes) {
    const updatedSoftDeletes = getNumSoftDeletedMessages(threadHistory) + numSoftDeletes - (numHardDeletes + numNonDeletes);
    return setNumSoftDeletedMessages(updatedSoftDeletes, threadHistory);
  }
  return threadHistory;
});