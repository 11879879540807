import { isSucceeded } from 'conversations-async-data/async-data/operators/statusComparators';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { getChannelInstanceId } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getDeliveryIdentifierValue } from 'conversations-message-history/common-message-format/operators/deliveryIdentifierGetters';
import { getChannelInstanceById, getInstanceDeliveryIdentifier, getIsActive, getIsAuthorized
// TODO: move to composer-data
} from 'conversations-thread-data/channel-instances/public/operators';
// TODO: move to composer-data
import { getCommonMessage } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
// TODO: move to composer-data

// TODO: move to composer-data

export const accountInactive = ({
  asyncChannelInstances,
  reply
}) => {
  const channelInstances = getData(asyncChannelInstances);
  const commonMessage = getCommonMessage(reply);
  const channelInstanceId = getChannelInstanceId(commonMessage);
  if (!isSucceeded(asyncChannelInstances)) return null;
  const channelInstance = channelInstances && getChannelInstanceById({
    channelInstances,
    id: channelInstanceId
  });
  if (channelInstance) {
    const instanceDeliveryIdentifier = getInstanceDeliveryIdentifier(channelInstance);

    // TODO - use channelInstance.name when available (?)
    const deliveryIdentifierValue = instanceDeliveryIdentifier && getDeliveryIdentifierValue(instanceDeliveryIdentifier);
    if (!getIsActive(channelInstance)) {
      return {
        disabled: true,
        sendDisabledReason: 'ACCOUNT_INACTIVE'
      };
    }
    if (!getIsAuthorized(channelInstance)) {
      return {
        disabled: true,
        sendDisabledReason: 'ACCOUNT_DISCONNECTED',
        options: {
          email: deliveryIdentifierValue
        }
      };
    }
  }
  return null;
};