// gimme: conversations-inbox-ui 06/10/2020 24a2e66917cc934433dd111db7e06fd02943ca6a
'use es6';

import { insertDateMessagesWithHistoryConfig } from 'conversations-message-history/dates/operators/insertDateMessagesWithHistoryConfig';
import { isPresentationalMessage } from 'conversations-message-history/common-message/operators/isPresentationalMessage';
import { getMessages } from 'conversations-message-history/thread-history/operators/getters';
import { createSelector } from 'reselect';
import concat from 'transmute/concat';
import filter from 'transmute/filter';
import map from 'transmute/map';
import merge from 'transmute/merge';
import pipe from 'transmute/pipe';
import { getCurrentChannel } from '../../selectors/conversationSelectors/getCurrentChannel';
import { getCurrentThreadId } from 'conversations-thread-data/thread-details/public/selectors';
import { getTypingIndicatorMessagesList } from '../../typing-indicators/selectors/getTypingIndicatorMessagesList';
import { shouldRenderInHistory } from '../operators/shouldRenderInHistory';
import { getFilteredUnpublishedMessages } from './getFilteredUnpublishedMessages';
import { getThreadHistory } from '../../thread-histories/selectors/getThreadHistory';
import { wrapFailedMessage } from 'conversations-message-history/common-message-format/operators/wrapFailedMessage';
const getHistoryConfigFromProps = (__state, {
  historyConfig
}) => historyConfig;
export const getMergedMessagesList = createSelector([getThreadHistory, getFilteredUnpublishedMessages, getTypingIndicatorMessagesList, getCurrentThreadId, getCurrentChannel, getHistoryConfigFromProps], (fullThreadHistory, unpublishedMessages, typingMessages, threadId, channel, historyConfig) => {
  return pipe(getMessages, merge(unpublishedMessages), messages => messages.toList(), filter(message => isPresentationalMessage(message) && shouldRenderInHistory(message, historyConfig)), messages => insertDateMessagesWithHistoryConfig(messages, historyConfig), map(wrapFailedMessage({
    threadId,
    channel
  })), concat(typingMessages))(fullThreadHistory);
});