'use es6';

import { createSelector } from 'reselect';
import { getGenericChannelsUsed } from 'conversations-thread-data/thread-details/public/operators';
import { getAsyncFocusedThreadDetails } from './getAsyncFocusedThreadDetails';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { getChannelInstanceId } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
export const getAllChannelsUsedWithChannelInstance = createSelector([getAsyncFocusedThreadDetails], threadDetails => {
  const genericChannelsUsed = getGenericChannelsUsed(getData(threadDetails));
  return genericChannelsUsed && genericChannelsUsed.filter(getChannelInstanceId);
});