'use es6';

import { createSelector } from 'reselect';
import { getNumSoftDeletedMessages } from './getNumSoftDeletedMessages';
import { shouldShowDeletedMessages } from '../operators/shouldShowDeletedMessages';
import { getDeletedMessagesVisibility } from './getDeletedMessagesVisibility';
const getThreadIdFromProps = (state, props) => {
  return props.threadId;
};
export const shouldShowDeletedMessagesBanner = createSelector([getNumSoftDeletedMessages, getDeletedMessagesVisibility, getThreadIdFromProps], (numSoftDeletedMessages, deletedMessages, threadId) => {
  const showingDeletedMessages = shouldShowDeletedMessages({
    deletedMessages,
    threadId
  });
  return Boolean(!showingDeletedMessages && numSoftDeletedMessages > 0);
});