import identity from 'transmute/identity';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { buildRequestErrorMetaObject } from 'conversations-error-reporting/error-actions/builders/buildRequestErrorMetaObject';
import { FETCH_ZORSE_INBOXES as actionTypes } from '../constants/asyncActionTypes';
import { getZorseInboxes as requestFn } from '../clients/getZorseInboxes';
export const fetchZorseInboxes = createAsyncAction({
  requestFn,
  actionTypes,
  toRecordFn: identity,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type '{}'.
  failureMetaActionCreator: ({
    error
  }) => buildRequestErrorMetaObject({
    error,
    ignoreStatusCodes: [404],
    // @ts-expect-error ts-migrate(2339) FIXME: 'isVisibleToUser' does not exist in type... Remove this comment to see the full error message.
    isVisibleToUser: false,
    silent: true
  })
});