import I18n from 'I18n';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { BLOCK_THREAD } from '../constants/asyncActionTypes';
import { setFilterStatus } from '../clients/setFilterStatus';
export const blockThread = createAsyncAction({
  requestFn: setFilterStatus,
  actionTypes: BLOCK_THREAD,
  toRecordFn: () => null,
  successMetaActionCreator: () => ({
    notification: {
      type: 'success',
      titleText: I18n.text('conversations-thread-view.alerts.titles.success'),
      message: I18n.text('conversations-thread-view.alerts.block-thread.message')
    }
  })
});