'use es6';

import { List } from 'immutable';
import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { FETCH_SEEN_NEW_FEATURE_SHEPHERDS, UPDATE_SEEN_NEW_FEATURE_SHEPHERDS } from '../constants/asyncActionTypes';
const initialState = AsyncData({
  data: List()
});
export default handleActions({
  [FETCH_SEEN_NEW_FEATURE_SHEPHERDS.STARTED](state) {
    return requestStarted(state);
  },
  [FETCH_SEEN_NEW_FEATURE_SHEPHERDS.FAILED](state) {
    return requestFailed(state);
  },
  [FETCH_SEEN_NEW_FEATURE_SHEPHERDS.SUCCEEDED](state, action) {
    const {
      data
    } = action.payload;
    return requestSucceededWithOperator(() => data, state);
  },
  [UPDATE_SEEN_NEW_FEATURE_SHEPHERDS.SUCCEEDED](state, action) {
    const {
      value
    } = action.payload.requestArgs;
    return requestSucceededWithOperator(() => List(value), state);
  }
}, initialState);