export const composerSettingsMenuOpened = {
  key: 'composerSettingsMenuOpened',
  properties: {
    action: 'Composer settings menu opened',
    platform: 'Helpdesk'
  }
};
export const aiAssistantsSettingsLinkClicked = {
  key: 'aiAssistantsSettingsLinkClicked',
  properties: {
    action: 'AI Assistants settings link clicked',
    platform: 'Helpdesk'
  }
};
export const replyRecsSettingsLinkClicked = {
  key: 'replyRecsSettingsLinkClicked',
  properties: {
    action: 'Reply Recommendations settings link clicked',
    platform: 'Helpdesk'
  }
};