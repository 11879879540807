import { UNINITIALIZED, STARTED, SUCCEEDED, FAILED } from 'conversations-async-data/async-data/constants/asyncStatuses';
import { isLoading, someLoading, someFailed } from 'conversations-async-data/async-data/operators/statusComparators';
import { getData } from 'conversations-async-data/async-data/operators/getters';
const someLoadingWithoutData = (...asyncData) => {
  return asyncData.reduce((accumulator, asyncDatum) => {
    return accumulator || isLoading(asyncDatum) && !getData(asyncDatum);
  }, false);
};
export const deriveCompoundStatus = (asyncDataArray, {
  skipLoadingIfData = false
} = {}) => {
  let derivedReplyStatus = UNINITIALIZED;
  if (skipLoadingIfData && someLoadingWithoutData(...asyncDataArray) || !skipLoadingIfData && someLoading(...asyncDataArray)) {
    derivedReplyStatus = STARTED;
  } else if (someFailed(...asyncDataArray)) {
    derivedReplyStatus = FAILED;
  } else {
    derivedReplyStatus = SUCCEEDED;
  }
  return derivedReplyStatus;
};