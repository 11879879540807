// gimme: conversations-inbox-ui 06/10/2020 24a2e66917cc934433dd111db7e06fd02943ca6a
'use es6';

import { fromJS } from 'immutable';
import { restoreMessage as restoreMessageClient } from '../clients/restoreMessage';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { RESTORE_MESSAGE } from 'conversations-thread-data/thread-deletion/public/constants/asyncActionTypes';
export const restoreMessage = createAsyncAction({
  requestFn: restoreMessageClient,
  actionTypes: RESTORE_MESSAGE,
  toRecordFn: fromJS
});