import { ChannelTypes } from 'conversations-internal-schema/constants/ChannelTypes';
import { LIVE_CHAT_GENERIC_CHANNEL_ID, FB_MESSENGER_GENERIC_CHANNEL_ID, FORMS_GENERIC_CHANNEL_ID, EMAIL_GENERIC_CHANNEL_ID, FEEDBACK_GENERIC_CHANNEL_ID } from '../../public/constants';
export const mapGenericChannelIdToChannelType = channelId => {
  switch (channelId) {
    case LIVE_CHAT_GENERIC_CHANNEL_ID:
      return ChannelTypes.CHAT;
    case FB_MESSENGER_GENERIC_CHANNEL_ID:
      return ChannelTypes.FACEBOOK_MESSENGER;
    case FORMS_GENERIC_CHANNEL_ID:
    case FEEDBACK_GENERIC_CHANNEL_ID:
    case EMAIL_GENERIC_CHANNEL_ID:
      return ChannelTypes.EMAIL;
    default:
      return undefined;
  }
};