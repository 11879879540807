'use es6';

import tracker from './usageTracker';
class RepChatAssignmentTracker {
  takeNextChatClicked(threadId) {
    this._trackUsage('take-next-chat', {
      threadId
    });
  }
  optionsOpened() {
    this._trackInteraction('options-opened', {});
  }
  optionsApplied({
    primaryLanguage,
    secondaryLanguage
  }) {
    this._trackUsage('options-applied', {
      payload: {
        primaryLanguage,
        secondaryLanguage
      }
    });
  }
  optionsClosed() {
    this._trackInteraction('options-closed', {});
  }
  _trackInteraction(action) {
    tracker.track('unassignedQueueInteraction', {
      screen: 'conversations',
      action
    });
  }
  _trackUsage(action, {
    payload = undefined,
    threadId = undefined
  } = {}) {
    tracker.track('unassignedQueueUsage', {
      screen: 'conversations',
      action,
      payload,
      threadId
    });
  }
}
export default new RepChatAssignmentTracker();