import { Record, Map as ImmutableMap, OrderedMap, fromJS, List } from 'immutable';
import indexBy from 'transmute/indexBy';
import get from 'transmute/get';
import Visitor from 'conversations-internal-schema/visitor/records/Visitor';
const transformThreadId = threadId => threadId && threadId.toString();
export class ThreadDetails extends Record({
  assignee: null,
  associatedTicketIds: List(),
  channels: OrderedMap(),
  cvCrmObjectId: null,
  errors: [],
  filteredSource: null,
  genericChannelsUsed: null,
  goalDetails: null,
  inboxId: 0,
  latestMessagePreview: null,
  latestMessageTimestamp: null,
  latestReceivedTimestamp: null,
  latestSentTimestamp: null,
  originalChannelInstanceId: null,
  originalGenericChannelId: 0,
  seen: false,
  sourceCrmObjectTypeId: null,
  status: 'INITIALIZING',
  threadId: 0,
  vid: null,
  visitor: Visitor()
}, 'ThreadDetails') {
  constructor(props = {}) {
    const map = ImmutableMap.isMap(props) ? props : fromJS(Object.assign({}, props));
    const channels = get('channels', props) || [];
    const visitor = get('visitor', props);
    const visitorRecord = visitor && Visitor(fromJS(visitor));
    const detailsMap = map.set('assignee', get('assignee', props)).set('channels', channels && indexBy(channel => get('type', channel), channels)).set('threadId', transformThreadId(get('threadId', props))).set('visitor', visitorRecord).filterNot(value => typeof value === 'undefined');
    super(detailsMap);
  }
}