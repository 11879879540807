export default class CallingExtensionsError extends Error {
  constructor({
    message,
    callProvider,
    actualError,
    extraData,
    sdkVersion
  }) {
    super(`[Calling Extensions]: ${message}`);
    this.name = 'CallingExtensionsError';
    this.callProvider = 'unknown';
    this.sdkVersion = '';
    this.actualError = {};
    this.extraData = {};
    if ('captureStackTrace' in Error) {
      Error.captureStackTrace(this, this.constructor);
    }
    this.callProvider = callProvider || 'unknown';
    this.actualError = actualError || {};
    this.extraData = extraData || {};
    this.sdkVersion = sdkVersion || '';
  }
}