import sortBy from 'transmute/sortBy';
import { getUsedGenericChannelId, getUsedTimestamp } from 'conversations-thread-data/thread-details/public/operators';

/**
 * @description returns information for when a specific generic channel was
 * last used on a thread via the genericChannelsUsed field in threadDetails
 * @param genericChannelsUsed - genericChannelsUsed field from threadDetails
 * @param channelIds - the possible channel ids which should be considered in the latest response
 */
export const getLatestChannelUsedForChannelIds = ({
  genericChannelsUsed,
  channelIds
}) => {
  const hasGenericChannelsUsed = genericChannelsUsed && genericChannelsUsed.size > 0;
  if (!hasGenericChannelsUsed || !channelIds || channelIds.length === 0) return null;
  const getTimestamp = channelUsed => Number(getUsedTimestamp(channelUsed));
  const sortByTimestamp = sortBy(getTimestamp);
  const sortedUsedChannels = sortByTimestamp(genericChannelsUsed);
  return sortedUsedChannels && sortedUsedChannels.findLast(channelUsed => {
    const usedGenericChannelId = getUsedGenericChannelId(channelUsed);
    return channelIds.includes(usedGenericChannelId);
  });
};