// @ts-expect-error Untyped file
import { trackEvent } from '../../usage-tracking/clients/usageTracker';
export const trackHelpDeskInterceptEvent = (action, component) => {
  trackEvent({
    key: 'help-desk-intercept',
    properties: {
      action,
      component
    }
  });
};