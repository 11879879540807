import http from 'conversations-http/clients/http';
import enviro from 'enviro';
import Raven from 'raven-js';
import { buildIndexedViews } from '../views/buildIndexedViews';

/**
 * @description Fetches all custom views for the given inbox.
 */
export function fetchCustomViewsService({
  inboxId
}) {
  return http.get(`conversations-custom-views/v1/custom-views/hydrated/inbox/${inboxId}`);
}
export function fetchIndexedCustomViewsService({
  inboxId
}) {
  return fetchCustomViewsService({
    inboxId
  }).then(({
    latestPlaybackTimestamp,
    hydratedCustomViews
  }) => {
    const views = hydratedCustomViews.reduce((acc, curr) => {
      acc[curr.customViewId] = curr;
      return acc;
    }, {});
    return {
      latestPlaybackTimestamp,
      views
    };
  }).catch(error => {
    Raven.captureException(error, {
      extra: {
        isProd: enviro.isProd(),
        isQa: enviro.isQa(),
        hublet: enviro.getHublet(),
        inboxId
      }
    });
    return error;
  });
}
export function fetchIndexedCustomViewsRecordsService({
  inboxId
}) {
  return fetchCustomViewsService({
    inboxId
  }).then(({
    latestPlaybackTimestamp,
    hydratedCustomViews
  }) => {
    const views = buildIndexedViews({
      customViews: hydratedCustomViews
    });
    return {
      latestPlaybackTimestamp,
      views
    };
  }).catch(error => {
    Raven.captureException(error, {
      extra: {
        isProd: enviro.isProd(),
        isQa: enviro.isQa(),
        hublet: enviro.getHublet(),
        inboxId
      }
    });
    return error;
  });
}