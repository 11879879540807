import enviro from 'enviro';
import { createTracker } from 'usage-tracker';
import events from 'calling-lifecycle-usage-tracker/events.yaml';
import { logCallingError } from 'calling-error-reporting/report/error';
export const _tracker = createTracker({
  events,
  onError(err) {
    console.error(err);
    logCallingError({
      errorMessage: `Calling Usage Tracker - Error`,
      extraData: {
        err
      }
    });
  },
  lastKnownEventProperties: ['screen', 'subscreen'],
  debug: () => enviro.debug('calling-lifecycle-usage-tracker')
});
export const _trackerSendImmediate = _tracker.clone({});
export const CommunicatorLogger = {
  externalData: {},
  init: data => {
    _tracker.setProperties(data);
    _trackerSendImmediate.setProperties(data);
    CommunicatorLogger.externalData = data;
  },
  clone: () => {
    return _tracker.clone({});
  },
  track(evt, evtData, options = {}) {
    const {
      sendImmediate = false
    } = options;
    const tracker = sendImmediate ? _trackerSendImmediate : _tracker;
    tracker.track(evt, evtData);
  },
  logImmediate: (eventName, eventProps = {}, trackerOptions) => CommunicatorLogger.log(eventName, eventProps, Object.assign({}, trackerOptions, {
    sendImmediate: true
  })),
  log: (eventName, eventProps = {}, trackerOptions = {
    sendImmediate: false
  }) => {
    CommunicatorLogger._log(eventName, eventProps, trackerOptions);
  },
  _log(eventName = '', eventProps = {}, trackerOptions = {
    sendImmediate: false
  }) {
    CommunicatorLogger.track(eventName, eventProps, trackerOptions);
  }
};