import { List, Map as ImmutableMap } from 'immutable';
import { getAttachments, getCommonMessage } from './commonReplyGetters';
import { setCommonMessage, setFileAttachments
// @ts-expect-error module not typed
} from './commonReplySetters';
import { addFileIdToMessage } from './addFileIdToMessage';
export const addAttachmentToCommonReply = ({
  attachment,
  commonReply
}) => {
  const immutableAttachment =
  // attachment can come in as a POJO or ImmutableMap
  attachment && ImmutableMap.isMap(attachment) ? attachment : ImmutableMap(attachment);
  const currentAttachments = getAttachments(commonReply);
  const nextAttachments = currentAttachments.size ? currentAttachments.unshift(immutableAttachment) : List([immutableAttachment]);
  const currentMessage = getCommonMessage(commonReply);
  const nextMessage = addFileIdToMessage(immutableAttachment.get('id'), currentMessage);
  const replyWithMessage = setCommonMessage(nextMessage, commonReply);
  return setFileAttachments(nextAttachments, replyWithMessage);
};