import { List as ImmutableList } from 'immutable';
import { providerRules, autoNonConfigurableRules, manualConfigurableRules, autoConfigurableRules } from '../constants/filterRules';

/**
 * @description Get values that are common to both list and array. For the
 * purposes of this component the list values and array values are assumed unique.
 * @param {List} list
 * @param {array} arr
 * @returns {List} containing common values
 */
const getCommonElements = (list, arr) => ImmutableList(list.filter(elem => arr.indexOf(elem) > -1));

/**
 * @description We  want to show only one reason/rule to the user if more than
 * one spam filtering rules are applied to the email except when all rules are
 * part of autoConfigurableRules group.
 *
 * The weight of the rules is as follows:
 * 1. Provider marked spam
 * 2. Auto non configurable [email to self, noreply@hubspot.com email,
 *    marketing email from the same portal]
 * 3. Manually blocked sender
 * 4. Auto configurable [automtaed response email, marketing email, subscription
 *    link email, role-based email]
 * @param {ImmutableList} ruleList List containing currently applied spam rules
 * @returns {array} containing most weighted spam rule[s]
 */
export const getMostWeightedRules = ruleList => {
  // check if the provider marked spam rule is applied for this email to be
  // considered spam
  let mostWeightedRules = getCommonElements(ruleList, providerRules);
  if (mostWeightedRules.size > 0) {
    return ImmutableList([mostWeightedRules.first()]);
  }
  // check if any of the auto non configurable rules are applied for this
  // email to be considered spam
  mostWeightedRules = getCommonElements(ruleList, autoNonConfigurableRules);
  if (mostWeightedRules.size > 0) {
    return ImmutableList([mostWeightedRules.first()]);
  }
  // check if manually configurable system rule is applied for this email
  // to be considered spam
  mostWeightedRules = getCommonElements(ruleList, manualConfigurableRules);
  if (mostWeightedRules.size > 0) {
    return ImmutableList([mostWeightedRules.first()]);
  }
  // if no other higher weighted rule is applied, return list of ALL auto
  // configurable rules
  return getCommonElements(ruleList, autoConfigurableRules);
};