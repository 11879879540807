import { Map as ImmutableMap } from 'immutable';
import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { FETCH_CONNECTED_ACCOUNTS } from '../../_internal/constants/asyncActionTypes';
const initialState = new IndexedAsyncData({
  name: 'connectedAccounts',
  notSetValue: new AsyncData({
    data: ImmutableMap()
  })
});
export const connectedAccounts = handleActions({
  [FETCH_CONNECTED_ACCOUNTS.STARTED](state, action) {
    const {
      requestArgs
    } = action.payload;
    const {
      inboxId
    } = requestArgs;
    return updateEntry(inboxId, requestStarted, state);
  },
  [FETCH_CONNECTED_ACCOUNTS.FAILED](state, action) {
    const {
      requestArgs
    } = action.payload;
    const {
      inboxId
    } = requestArgs;
    return updateEntry(inboxId, requestFailed, state);
  },
  [FETCH_CONNECTED_ACCOUNTS.SUCCEEDED](state, action) {
    const {
      data,
      requestArgs
    } = action.payload;
    const {
      inboxId
    } = requestArgs;
    return updateEntry(inboxId, requestSucceededWithOperator(() => data), state);
  }
}, initialState);