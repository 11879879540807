'use es6';

import { MESSAGE_DELETED_STATUS } from '../constants/updateTypes';
export const getNumDeleteStatusUpdatesForType = (updates, type) => {
  return updates.reduce((count, update) => {
    if (update.get(MESSAGE_DELETED_STATUS) === type) {
      return count + 1;
    }
    return count;
  }, 0);
};