import userInfo from 'hub-http/userInfo';
class Auth {
  createInstance() {
    return new Auth();
  }
  constructor() {
    this.data = {
      user: null,
      gates: null,
      portal: null
    };
    this.initialized = false;
  }
  init() {
    if (this.initialized) {
      throw new Error('Auth Utils: Cannot be initialized twice. Use getInstance() if you ' + 'need to create multiple instances.');
    }
    this.initialized = true;

    // Force auth refresh immediately, since Bill and Caro are going to need to
    // do it anyway (and we want HapiJS to notice EarlyRequestor's API verify
    // call before Bill, Caro, or Aperture do their thing)
    return userInfo().then(({
      user,
      gates,
      portal
    }) => {
      this.data = {
        user,
        gates,
        portal
      };
      return this.data;
    });
  }
  get() {
    return this.data;
  }
}
export default new Auth();