import { ChannelTypes } from 'conversations-internal-schema/constants/ChannelTypes';
import { EMAIL_GENERIC_CHANNEL_ID, FORMS_GENERIC_CHANNEL_ID, LIVE_CHAT_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
import { BOT } from 'routing-ui/routing-rules/constants/RoutingTypes';
export const CHANNEL_MIGRATION_STATES = {
  COMPLETE: 'COMPLETE',
  FAILED: 'FAILED',
  NOT_STARTED: 'NOT_STARTED',
  SCHEDULED: 'SCHEDULED',
  STARTED: 'STARTED',
  STARTING: 'STARTING'
};
export const MIGRATABLE_CHANNEL_TYPES = [BOT, ChannelTypes.CHAT, ChannelTypes.EMAIL, ChannelTypes.FORMS];
export const MIGRATABLE_CHANNEL_IDS = {
  LIVE_CHAT: LIVE_CHAT_GENERIC_CHANNEL_ID,
  EMAIL: EMAIL_GENERIC_CHANNEL_ID,
  FORMS: FORMS_GENERIC_CHANNEL_ID
};