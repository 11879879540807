import { useState, useCallback } from 'react';
import * as QuotesActions from 'quotes-ui-lib/store/QuotesActions';
import QuotesLogger from 'quotes-ui-lib/tracking/quotesLogger';
import { addSuccess, addDanger
// @ts-expect-error untyped module
} from 'customer-data-ui-utilities/alerts/Alerts';
const useDeleteQuote = (quote, trackingLocation, onSuccess) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const deleteQuote = useCallback(() => {
    QuotesLogger.log('quoteInteraction', {
      action: 'Delete quote',
      location: trackingLocation
    });
    setIsLoading(true);
    QuotesActions.deleteQuote(quote).then(() => {
      addSuccess('quotesUiLib.deleteQuoteModal.success');
      if (onSuccess) {
        onSuccess();
      }
    }).catch(() => {
      addDanger('quotesUiLib.deleteQuoteModal.failure');
    }).finally(() => {
      setIsModalOpen(false);
      setIsLoading(false);
    });
  }, [quote, trackingLocation, onSuccess]);
  return [isModalOpen, setIsModalOpen, isLoading, deleteQuote];
};
export default useDeleteQuote;