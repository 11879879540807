import OutOfTheOfficeStatusRecord from '../records/OutOfTheOfficeStatusRecord';
import { FETCH_OUT_OF_THE_OFFICE_STATUS } from '../actions/ActionTypes';
const processOutOfTheOfficeStatus = (state, action) => {
  if (action.meta === 'request') {
    return state.set('loading', true);
  } else if (action.meta === 'response') {
    return new OutOfTheOfficeStatusRecord(action.payload);
  }
  return state;
};
const outOfTheOffice = (state = new OutOfTheOfficeStatusRecord(), action) => {
  switch (action.type) {
    case FETCH_OUT_OF_THE_OFFICE_STATUS:
      return processOutOfTheOfficeStatus(state, action);
    default:
      return state;
  }
};
export default outOfTheOffice;