//@ts-expect-error No type definition yet
import { operatorTypeToOperator } from 'customer-data-filters/converters/objectSeg/ObjectSegOperators';
import { BOOLEAN, ENUMERATION, NUMBER, STRING } from 'customer-data-filters/converters/objectSeg/OperationTypes';
//@ts-expect-error No type definition yet
import * as Operators from 'customer-data-filters/filterQueryFormat/operator/Operators';
import { Map as ImmutableMap } from 'immutable';
const not = operator => args => !operator(args);
const operatorContain = ({
  filterValue,
  value
}) => value.includes(filterValue);
const operatorEqual = ({
  filterValue,
  value
}) => filterValue === value;
const operatorGreater = ({
  filterValue,
  value
}) => value > filterValue;
const operatorIn = ({
  filterValue,
  value
}) => Boolean(filterValue && filterValue.includes(value));
const operatorKnown = ({
  value
}) => value !== null && value !== undefined;
const operatorLess = ({
  filterValue,
  value
}) => value < filterValue;

/**
 * We should only map out Operators that we know we will need to optimize for.
 * Operators have a .toString defined and so this map is indexed by their internal
 * names.
 */
const operatorsToOperationsMap = ImmutableMap({
  [Operators.Equal]: operatorEqual,
  [Operators.NotEqual]: not(operatorEqual),
  [Operators.In]: operatorIn,
  [Operators.NotIn]: not(operatorIn),
  [Operators.Known]: operatorKnown,
  [Operators.NotKnown]: not(operatorKnown),
  [Operators.Greater]: operatorGreater,
  [Operators.Less]: operatorLess,
  [Operators.GreaterOrEqual]: not(operatorLess),
  [Operators.LessOrEqual]: not(operatorGreater),
  [Operators.EqualAny]: operatorEqual,
  [Operators.NotEqualAny]: not(operatorEqual),
  [Operators.Contain]: operatorContain,
  [Operators.NotContain]: not(operatorContain)
});

/**
 * @description This function will take the operation from a Filter and return a
 * function associated with that operation if it exists, otherwise it returns null.

 * @returns The corresponding function for the given operation if it exists.
 */
export const getOperatorFromFilterOperation = (operation, filter) => {
  const {
    operator: operatorType,
    propertyType
  } = operation;
  if ([BOOLEAN, ENUMERATION, NUMBER, STRING].includes(propertyType)) {
    const operatorRecord = operatorTypeToOperator(operatorType, propertyType, filter);
    if (operatorRecord) {
      return operatorsToOperationsMap.get(operatorRecord.toString());
    }
  }
  return null;
};