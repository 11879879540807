import { List as ImmutableList } from 'immutable';
import identity from 'transmute/identity';
import pipe from 'transmute/pipe';
import { getCommonMessage } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
// @ts-expect-error module not typed
import { setCommonMessage } from 'conversations-thread-data/common-reply/public/operators/commonReplySetters';
import { getChannelInstanceId, getEmailMetadataAttachment, getGenericChannelId, getSenders } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { setAttachment, setChannelInstanceId, setSenders } from 'conversations-message-history/common-message-format/operators/commonMessageSetters';
import { setMetadataConnectedAccountAddress, setMetadataFromAddress } from 'conversations-message-history/common-message-format/operators/emailMetadataSetters';
import { getMetadataConnectedAccountAddress, getMetadataFromAddress } from 'conversations-message-history/common-message-format/operators/emailMetadataGetters';
export function reconcileDefaultSendersForChannel(draftReply, emptyReply) {
  const draftMessage = getCommonMessage(draftReply);
  const emptyMessage = getCommonMessage(emptyReply);
  const draftChannelId = getGenericChannelId(draftMessage);
  const emptyChannelId = getGenericChannelId(emptyMessage);
  const areDifferentChannels = draftChannelId !== emptyChannelId;
  const draftChannelInstanceId = getChannelInstanceId(draftMessage);
  const draftSenders = getSenders(draftMessage);
  const areSendersAlreadySet = draftChannelInstanceId && draftSenders && draftSenders.size;
  const shouldKeepDraftReply = areDifferentChannels || areSendersAlreadySet;
  if (shouldKeepDraftReply) return draftReply;
  const emptyChannelInstanceId = getChannelInstanceId(emptyMessage);
  const nextChannelInstanceId = emptyChannelInstanceId || draftChannelInstanceId;
  const emptySenders = getSenders(emptyMessage);
  const nextSenders = emptySenders && emptySenders.size ? emptySenders : draftSenders;
  const emptyEmailMetadataAttachment = getEmailMetadataAttachment(emptyMessage);
  const emptyFromAddress = getMetadataFromAddress(emptyEmailMetadataAttachment);
  const emptyConnectedAccountAddress = getMetadataConnectedAccountAddress(emptyEmailMetadataAttachment);
  const draftEmailMetadataAttachment = getEmailMetadataAttachment(draftMessage);
  const nextEmailMetadataAttachment = pipe(emptyFromAddress ? setMetadataFromAddress(emptyFromAddress) : identity, emptyConnectedAccountAddress ? setMetadataConnectedAccountAddress(emptyConnectedAccountAddress) : identity)(draftEmailMetadataAttachment);
  const reconciledMessage = pipe(setChannelInstanceId(nextChannelInstanceId), setSenders(nextSenders || ImmutableList()), setAttachment(nextEmailMetadataAttachment))(draftMessage);
  return setCommonMessage(reconciledMessage, draftReply);
}