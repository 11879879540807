'use es6';

import { combineReducers } from 'redux';
import emailSignatureIsSetup from './emailSignatureIsSetup';
import promptDismissals from './promptDismissals';
import sessionData from './sessionData';
export default combineReducers({
  emailSignatureIsSetup,
  promptDismissals,
  sessionData
});