import { useState, useCallback } from 'react';
import QuotesLogger from 'quotes-ui-lib/tracking/quotesLogger';
const useConvertToInvoice = (showInvoiceEditor, trackingLocation) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const convertToInvoice = useCallback(() => {
    showInvoiceEditor();
    setIsConfirmModalOpen(false);
    QuotesLogger.log('quoteInteraction', {
      action: 'Click convert to invoice',
      location: trackingLocation
    });
  }, [showInvoiceEditor, trackingLocation]);
  return [isConfirmModalOpen, setIsConfirmModalOpen, convertToInvoice];
};
export default useConvertToInvoice;