import { useCallback, useState } from 'react';
import { parseInAppCopilotAction } from 'copilot-toolkit/actions/parseInAppCopilotAction';
import { sendActionApi } from '../api/ChatSpotV1StreamingApi';
export const useStreamingAction = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState();
  const handleProgress = useCallback(progressEvent => {
    setResponse(() => {
      if (progressEvent.error) {
        return {
          message: progressEvent.message || '',
          messageId: progressEvent.id,
          sections: [{
            id: `${progressEvent.id}`,
            text: progressEvent.response,
            type: 'PLAIN_TEXT'
          }],
          error: progressEvent.error
        };
      } else {
        return {
          message: progressEvent.message || '',
          sections: progressEvent.sections,
          messageId: progressEvent.id,
          threadId: progressEvent.thread_id
        };
      }
    });
  }, []);
  const handleClose = useCallback((_, finalEvent) => {
    /** We only want to set the timestamp when we have finished streaming */
    if (finalEvent) {
      setResponse(prevState => {
        return Object.assign({}, prevState, {
          timestamp: finalEvent.created_at
        });
      });
    }
    setLoading(false);
  }, []);
  const handleError = useCallback(err => {
    setError(err);
    setLoading(false);
  }, []);
  const handleReset = useCallback(() => {
    setResponse(null);
  }, []);
  const sendAction = useCallback(({
    data,
    isRefresh = false
  }) => {
    setLoading(true);
    if (isRefresh) {
      setResponse(() => {
        return {
          message: '',
          messageId: null,
          sections: [],
          timestamp: null
        };
      });
    }
    const messageAction = parseInAppCopilotAction(data.action);
    const requestData = Object.assign({}, data, {
      action: messageAction
    });
    sendActionApi({
      data: requestData,
      onProgress: handleProgress,
      onClose: handleClose,
      onError: handleError
    });
  }, [handleProgress, handleClose, handleError]);
  return {
    sendAction,
    actionLoading: loading,
    actionResponse: response,
    actionError: error,
    onReset: handleReset
  };
};