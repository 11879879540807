import { createSelector } from 'reselect';
import { getCurrentInboxId } from 'conversations-inbox-lib/unified-inbox/public/selectors';
import { getCurrentViewId } from '../../thread-list/selectors/getCurrentViewId';
import { getSortOrder } from '../../thread-list-member-sidebar/selectors/getSortOrder';
import { getViewThreadStatus } from '../../thread-list/selectors/getViewThreadStatus';
export const getThreadListMemberFilters = createSelector([getCurrentInboxId, getCurrentViewId, getSortOrder, getViewThreadStatus], (inboxId, viewId, sortDirection, viewThreadStatus) => {
  return {
    inboxId,
    sortDirection,
    threadStatus: viewThreadStatus,
    viewId
  };
});