// @ts-expect-error module not typed
import EmailAddressPattern from 'PatternValidationJS/patterns/EmailAddress';
import { MALFORMED_EMAIL, PREVIOUSLY_BOUNCED, MISSING_PERMISSION } from '../constants/validationConstants';
import { hasContactPermissions } from 'conversations-thread-data/contact-permissions/public/operators';
export function validateEmail(emailAddress, emailDeliverabilityStatuses, contactPermissions) {
  const isEmailValid = EmailAddressPattern.test(emailAddress);
  if (!isEmailValid) {
    return {
      isEmailValid: false,
      reason: MALFORMED_EMAIL
    };
  }
  const status = emailDeliverabilityStatuses && emailDeliverabilityStatuses[emailAddress];
  const isEmailPreviouslyBounced = status ? status === PREVIOUSLY_BOUNCED : false;
  if (isEmailPreviouslyBounced) {
    return {
      isEmailValid: false,
      reason: PREVIOUSLY_BOUNCED
    };
  }
  if (!hasContactPermissions(emailAddress, contactPermissions)) {
    return {
      isEmailValid: false,
      reason: MISSING_PERMISSION
    };
  }
  return {
    isEmailValid: true,
    reason: undefined
  };
}