'use es6';

import getIn from 'transmute/getIn';
import get from 'transmute/get';
import { AGENT_ID, CONNECTED, CREATED_AT, DELETED_AT, DISPLAY_GLOBALLY, EMAIL_ACCOUNT_TYPE, EMAIL, FRIENDLY_FROM_NAME, ID, INBOX_ID, IS_ACTIVE, IS_DEFAULT, PORTAL_ID, ROUTING_RULES, SEND_FROM_EMAIL, INCLUDE_AGENT_IN_FROM_NAME, RESOLVED_FROM_NAME, FORWARDING_DETECTION_SETTING, TICKET_RULES_ID, CHANNEL_INSTANCE_ID } from '../constants/keyPaths';
export const getAgentId = getIn(AGENT_ID);
export const getConnected = getIn(CONNECTED);
export const getCreatedAt = getIn(CREATED_AT);
export const getDeletedAt = getIn(DELETED_AT);
export const getDisplayGlobally = getIn(DISPLAY_GLOBALLY);
export const getEmail = getIn(EMAIL);
export const getEmailAccountType = getIn(EMAIL_ACCOUNT_TYPE);
export const getFriendlyFromName = getIn(FRIENDLY_FROM_NAME);
export const getId = getIn(ID);
export const getInboxId = getIn(INBOX_ID);
export const getIsActive = getIn(IS_ACTIVE);
export const getIsDefault = getIn(IS_DEFAULT);
export const getPortalId = getIn(PORTAL_ID);
export const getRoutingRules = getIn(ROUTING_RULES);
export const getSendFromEmail = getIn(SEND_FROM_EMAIL);
export const getIncludeAgentInFromName = getIn(INCLUDE_AGENT_IN_FROM_NAME);
export const getResolvedFromName = getIn(RESOLVED_FROM_NAME);
export const getForwardDetectionSetting = getIn(FORWARDING_DETECTION_SETTING);
export const getTicketRulesId = getIn(TICKET_RULES_ID);
export const getChannelInstanceId = get(CHANNEL_INSTANCE_ID);
export const getDomain = connectedEmailAccount => {
  const email = get('email', connectedEmailAccount);
  return email ? email.substring(email.lastIndexOf('@') + 1) : '';
};
export const getLocal = connectedEmailAccount => {
  const email = get('email', connectedEmailAccount);
  return email ? email.substring(0, email.lastIndexOf('@')) : '';
};
export const getSendFromDomain = connectedEmailAccount => {
  const email = get('sendFromEmail', connectedEmailAccount);
  return email ? email.substring(email.lastIndexOf('@') + 1) : '';
};
export const getSendFromLocal = connectedEmailAccount => {
  const email = get('sendFromEmail', connectedEmailAccount);
  return email ? email.substring(0, email.lastIndexOf('@')) : '';
};