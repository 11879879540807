import { getCurrentReplyRecommendationObject, getLastConversationalMessage, getReplyRecommendationsHistory } from 'conversations-message-history/reply-recommendations/operators/getters';
// @ts-expect-error module not typed
import { useThreadHistory } from 'conversations-thread-view/thread-histories/hooks/useThreadHistory';
import { getData } from 'conversations-async-data/async-data/operators/getters';
// @ts-expect-error module not typed
import { getId } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { COMPOSITION_THREAD_ID } from 'conversations-thread-data/communicator/constants/compositionThreadId';
export const useCurrentReplyRecommendation = ({
  threadId
}) => {
  const isCompositionThread = threadId === COMPOSITION_THREAD_ID;
  const finalThreadId = isCompositionThread ? '0' : threadId;
  const {
    asyncThreadHistory
  } = useThreadHistory({
    threadId: finalThreadId,
    deferred: threadId === COMPOSITION_THREAD_ID
  });
  const threadHistory = getData(asyncThreadHistory);
  if (!threadHistory) return {};
  const replyRecommendationsHistory = getReplyRecommendationsHistory(threadHistory);
  const lastConversationalMessage = getLastConversationalMessage(threadHistory);
  const lastConversationalMessageId = getId(lastConversationalMessage);
  return {
    currentReplyRecommendation: getCurrentReplyRecommendationObject({
      replyRecommendationsHistory,
      lastConversationalMessage
    }),
    currentReplyRecInResponseTo: replyRecommendationsHistory[lastConversationalMessageId] ? lastConversationalMessageId : ''
  };
};