import hubHttpClient from 'hub-http/clients/apiClient';
import createImageButton from 'hubspot-prosemirror/draft-transplant/components/createImageButton';
import { useContext } from 'react';
import { useEditorCapability, useEditorConfigurationKey } from 'composer-data/channel-capabilities/public/hooks';
import { insertedImage } from '../../../usage-tracking/events/editorInteractions';
import { EMAIL_GENERIC_CHANNEL_ID, LIVE_CHAT_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
import { EditorTrackerContext } from '../../../usage-tracking/context/EditorTrackerContext';
const NOOP_IMAGE_UPLOADER = () => Promise.resolve({
  objects: [{}]
});
export const useInlineImages = renderExistingFileSelect => {
  const allowInlineImages = useEditorCapability('allowInlineImages');
  const editorConfigurationKey = useEditorConfigurationKey();
  const {
    trackEvent
  } = useContext(EditorTrackerContext);
  const access = editorConfigurationKey === EMAIL_GENERIC_CHANNEL_ID ? 'HIDDEN_IN_APP_SENSITIVE' : undefined;

  // this is meant to be temporary until we configure upload images for live chat
  if (editorConfigurationKey === LIVE_CHAT_GENERIC_CHANNEL_ID) return {};
  if (!allowInlineImages) return {};
  return {
    INLINE_IMAGE: {
      fileManagerAccess: access,
      // @ts-expect-error fix type in EditorConfig ReturnType<typeof createImageButton>
      ImageButton: createImageButton({
        uploadImage: NOOP_IMAGE_UPLOADER,
        useOptionsPopover: true,
        shouldScaleInserts: true,
        FileManager: renderExistingFileSelect,
        httpClient: hubHttpClient,
        drawerType: 'IMAGE',
        fileUploadAccess: access
      }),
      onInsertContent: () => trackEvent(insertedImage())
    }
  };
};