import { registerQuery, useQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
import Raven from 'raven-js';
const CONTACT_OBJECT_TYPE = 'CONTACT';
const COMPANY_OBJECT_TYPE = 'COMPANY';
const CONTACT_OBJECT_TYPE_ID = '0-1';
const COMPANY_OBJECT_TYPE_ID = '0-2';
const fetchCallDetails = registerQuery({
  fieldName: 'callDetails',
  args: ['objectId'],
  fetcher: ({
    objectId
  }) => {
    return http.get(`/engagements/v2/engagements/${objectId}?properties=hs_call_title&properties=hs_call_direction&properties=hs_call_callee_object_id&properties=hs_call_callee_object_type&properties=hs_call_has_voicemail&properties=hs_calls_service_call_id&properties=hs_call_from_number&properties=hs_call_to_number`).then(payload => {
      const callProperties = payload.properties;
      if (callProperties) {
        return {
          callId: callProperties['hs_calls_service_call_id'] && callProperties['hs_calls_service_call_id'].value || '',
          callTitle: callProperties['hs_call_title'] && callProperties['hs_call_title'].value || '',
          callDirection: callProperties['hs_call_direction'] && callProperties['hs_call_direction'].value || '',
          callToNumber: callProperties['hs_call_to_number'] && callProperties['hs_call_to_number'].value || '',
          callFromNumber: callProperties['hs_call_from_number'] && callProperties['hs_call_from_number'].value || '',
          calleeObjectId: callProperties['hs_call_callee_object_id'] && callProperties['hs_call_callee_object_id'].value || '',
          calleeObjectType: callProperties['hs_call_callee_object_type'] && callProperties['hs_call_callee_object_type'].value || '',
          hasVoicemail: callProperties['hs_call_has_voicemail'] ? callProperties['hs_call_has_voicemail'].value === 'true' : false
        };
      }
      return null;
    }).then(callProperties => {
      const calleeObjectTypeId = callProperties && callProperties.calleeObjectType === CONTACT_OBJECT_TYPE ? CONTACT_OBJECT_TYPE_ID : callProperties && callProperties.calleeObjectType === COMPANY_OBJECT_TYPE ? COMPANY_OBJECT_TYPE_ID : null;
      if (calleeObjectTypeId) {
        return http.get(`calls/v1/callees/hydrate-one/${calleeObjectTypeId}/${callProperties && callProperties.calleeObjectId}`).then(payload => {
          return {
            callId: callProperties && callProperties.callId,
            callTitle: callProperties && callProperties.callTitle,
            callDirection: callProperties && callProperties.callDirection,
            callToNumber: callProperties && callProperties.callToNumber,
            callFromNumber: callProperties && callProperties.callFromNumber,
            calleeName: payload && payload['name'] || '',
            hasVoicemail: callProperties && callProperties.hasVoicemail
          };
        });
      }
      return {
        callId: callProperties && callProperties.callId,
        callTitle: callProperties && callProperties.callTitle,
        callDirection: callProperties && callProperties.callDirection,
        callToNumber: callProperties && callProperties.callToNumber,
        callFromNumber: callProperties && callProperties.callFromNumber,
        calleeName: null,
        hasVoicemail: callProperties && callProperties.hasVoicemail
      };
    }).catch(error => {
      Raven.captureException(error);
    });
    return null;
  }
});
export function useFetchCallDetails(objectId) {
  const {
    loading,
    error,
    data
  } = useQuery(fetchCallDetails, {
    variables: {
      objectId
    }
  });
  return {
    loading,
    error,
    data
  };
}