import ChatFilterOptions from 'conversations-internal-schema/constants/ChatFilterOptions';
import { ASSIGNED_TO_BOT, ASSIGNEE, HIDE_FILTERED, START_TIME, END_TIME, VID, SEARCH, STATUS, HIDE_TRASH, UNASSIGNED, GENERIC_CHANNEL, getAllVids, CHANNEL_INSTANCE_ID } from '../../search-and-filter-schema/protected';
export const convertFiltersToSearchArgs = filters => {
  const {
    [ASSIGNED_TO_BOT]: assignedToBot,
    [ASSIGNEE]: agentId,
    [CHANNEL_INSTANCE_ID]: channelInstanceId,
    [HIDE_FILTERED]: hideFiltered,
    [END_TIME]: endTimestamp,
    [GENERIC_CHANNEL]: genericChannelIds,
    [START_TIME]: startTimestamp,
    [STATUS]: status,
    [VID]: contact,
    [SEARCH]: searchText,
    [HIDE_TRASH]: hideTrash,
    [UNASSIGNED]: unassigned
  } = filters;
  let assignment = null;
  if (unassigned) {
    assignment = {
      '@type': 'UNASSIGNED'
    };
  } else if (assignedToBot) {
    assignment = {
      '@type': 'ASSIGNED',
      agentType: 'BOT'
    };
  } else if (agentId) {
    assignment = {
      agentId,
      agentType: 'HUMAN'
    };
  }
  const threadStatusWithoutTrash = status || [ChatFilterOptions.ENDED, ChatFilterOptions.STARTED];
  const threadStatus = hideTrash ? threadStatusWithoutTrash : threadStatusWithoutTrash.concat(ChatFilterOptions.SOFT_DELETED);
  const searchArgs = {
    assignment,
    channelInstanceId: channelInstanceId ? [channelInstanceId] : null,
    endTimestamp,
    hideFiltered,
    startTimestamp,
    genericChannelIds,
    threadStatus,
    searchQuery: searchText && searchText.length ? searchText : null,
    vids: contact ? getAllVids(contact) : []
  };
  return searchArgs;
};