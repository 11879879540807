/* hs-eslint ignored failing-rules */

// gimme: conversations-thread-view 05/26/2020 741c62dc71ca742adbc6d64d61d30de7cb46539f
'use es6';

import I18n from 'I18n';
import { createAction } from 'redux-actions';
import { buildErrorMetaObject } from 'conversations-error-reporting/error-actions/builders/buildErrorMetaObject';
import ThreadHistory from 'conversations-message-history/thread-history/records/ThreadHistory';
import { THREAD_HISTORY } from 'conversations-thread-data/thread-histories/public/constants/asyncActionTypes';
import { getMessageHistory } from '../clients/getMessageHistory';
const started = createAction(THREAD_HISTORY.STARTED);
const succeeded = createAction(THREAD_HISTORY.SUCCEEDED);
const failed = createAction(THREAD_HISTORY.FAILED, null, () => buildErrorMetaObject({
  titleText: I18n.text('conversations-thread-view.threadHistory.error.title'),
  message: I18n.text('conversations-thread-view.threadHistory.error.description')
}));
export const fetchMessageHistory = requestArgs => dispatch => {
  dispatch(started({
    requestArgs
  }));
  getMessageHistory(requestArgs).then(response => {
    const history = new ThreadHistory(response);
    return dispatch(succeeded({
      requestArgs,
      data: {
        history
      }
    }));
  }).catch(error => dispatch(failed({
    requestArgs,
    error
  })));
};