'use es6';

export const AGENT_ID = ['agentId'];
export const CONNECTED = ['connected'];
export const CREATED_AT = ['createdAt'];
export const DELETED_AT = ['deletedAt'];
export const DISPLAY_GLOBALLY = ['displayGlobally'];
export const EMAIL = ['email'];
export const EMAIL_ACCOUNT_TYPE = ['emailAccountType'];
export const FRIENDLY_FROM_NAME = ['friendlyFromName'];
export const ID = ['id'];
export const INBOX_ID = ['inboxId'];
export const INCLUDE_AGENT_IN_FROM_NAME = ['includeAgentInFromName'];
export const IS_ACTIVE = ['isActive'];
export const IS_DEFAULT = ['isDefault'];
export const PORTAL_ID = ['portalId'];
export const RESOLVED_FROM_NAME = ['resolvedFromName'];
export const ROUTING_RULES = ['routingRules'];
export const SEND_FROM_EMAIL = ['sendFromEmail'];
export const FORWARDING_DETECTION_SETTING = ['forwardDetectionSetting', 'settingValue'];
export const TICKET_RULES_ID = ['ticketRulesId'];
export const CHANNEL_INSTANCE_ID = 'channelInstanceId';