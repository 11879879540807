import http from 'conversations-http/clients/http';
import { convertFiltersToSearchArgs } from '../operators/convertFiltersToSearchArgs';
import { getFilterValues, getSortOrder } from '../operators/filterKeyGetters';
const DEFAULT_LIMIT = 100;
export const fetchFilteredThreadListMembers = ({
  filterKey,
  offsetTimestamp = null,
  offsetId = null,
  inboxId
}) => {
  const {
    assignment = null,
    endTimestamp = null,
    hideFiltered = true,
    searchQuery = null,
    startTimestamp = null,
    genericChannelIds = null,
    threadStatus = null,
    vids = null
  } = convertFiltersToSearchArgs(getFilterValues(filterKey));
  return http.put('messages/v2/threadlist/members/page', {
    query: {
      limit: DEFAULT_LIMIT,
      offsetTimestamp,
      offsetId,
      maxPreviewLength: 200
    },
    data: {
      assignment,
      endTimestamp,
      hideFiltered,
      inboxId,
      searchQuery,
      sortDirection: getSortOrder(filterKey),
      startTimestamp,
      genericChannelIds,
      threadStatus,
      vids
    }
  });
};