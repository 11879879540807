import { CustomView, getId, ThreadList } from 'find-and-filter-data/views-schema/public';

/**
 * `buildIndexedViews` will accept arrays of custom views and thread lists and
 * will convert them to their immutable records and then index then by id.
 *
 * This function also decouples ThreadLists that have been
 * been duplicated as CustomViews while also prioritizing the CustomView object.
 * When we are no longer accepting Threadlists, we can remove the
 * filtering and indexing of ThreadLists.
 */

export function buildIndexedViews({
  customViews,
  threadLists = []
}) {
  const views = [].concat(customViews.map(view => new CustomView(view)), threadLists.map(view => new ThreadList(view)));
  // index all views by viewId
  return views.reduce((acc, curr) => {
    acc[getId(curr)] = curr;
    return acc;
  }, {});
}