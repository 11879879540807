'use es6';

import { useEffect, useRef } from 'react';
import { getThreadId } from 'conversations-thread-data/thread-details/public/operators';
import { deepEqual } from '../../lib/hsUnderscore';
/**
 * @description a small hook that detects changes to a threadDetails
 * object. Edges going from a falsy object to a truthy one or between
 * thread details that represent different threads are ignored.
 */

export const useThreadDetailsWatcher = ({
  threadDetails,
  onChange
}) => {
  const prevThreadDetails = useRef(threadDetails);
  useEffect(() => {
    if (prevThreadDetails.current && threadDetails && prevThreadDetails.current !== threadDetails && getThreadId(prevThreadDetails.current) === getThreadId(threadDetails)) {
      const [...keys] = prevThreadDetails.current.keys();
      const differences = keys.filter(key => {
        return !deepEqual(prevThreadDetails.current.get(key), threadDetails.get(key));
      });
      onChange(differences, threadDetails);
    }
  }, [threadDetails, onChange]);
  useEffect(() => {
    prevThreadDetails.current = threadDetails;
  }, [threadDetails]);
};