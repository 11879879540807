import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["onMessage", "onPlayback", "onPresence"];
/* hs-eslint ignored failing-rules */

/* eslint-disable no-console */

import { prepareMessage } from './prepareMessage';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import { preparePresenceMessages } from './preparePresenceMessages';
const NOOP_PUBSUB_ERROR_CODES = [`91005`];
/**
 * @description Decorate callbacks of a subscription to apply timestamps and transform
 * data into a format that apps expect, as well as forward any recognized publishContext
 * values provided by the original caller.
 *
 * @param {Object} options
 * @param {Object<String, Object>} options.subscriptions an object mapping channel
 * ids to objects contiaining callback methods
 * @param {Function} options.getConnectionId get the clients current connection id
 * @returns {Object<channel, decoratedCallback} decorated subscriptions
 */

export const decorateSubscriptionCallbacks = ({
  subscriptions,
  isMessageEcho,
  vendor
}) => {
  const decorated = {};
  Object.keys(subscriptions).forEach(channelId => {
    const _subscriptions$channe = subscriptions[channelId],
      {
        onMessage,
        onPlayback,
        onPresence
      } = _subscriptions$channe,
      rest = _objectWithoutPropertiesLoose(_subscriptions$channe, _excluded);
    const decoratedOnMessage = (message, {
      playback = false
    } = {}) => {
      // Dont echo messages
      if (isMessageEcho(message)) return;
      try {
        onMessage(prepareMessage({
          message
        }), {
          playback
        });
      } catch (error) {
        reportError({
          error: error
        });
        console.error(error);
      }
    };
    const decoratedOnPresence = onPresence ? message => {
      if (isMessageEcho(message)) return;
      // ignore the actual realtime message and and get all the
      // current presence messages.
      vendor.getPresenceMessages({
        channelId
      }).then(messages => {
        try {
          onPresence(preparePresenceMessages({
            messages
          }));
        } catch (error) {
          console.error(error);
        }
      }).catch(error => {
        if (NOOP_PUBSUB_ERROR_CODES.includes(`${error.code}`)) {
          reportError({
            error
          });
        } else {
          reportError({
            error
          });
          console.error(error);
        }
      });
    } : onPresence;
    const decoratedOnPlayback = onPlayback ? messages => {
      try {
        onPlayback(messages.map(message => prepareMessage({
          message
        })));
      } catch (error) {
        reportError({
          error: error
        });
        console.error(error);
      }
    } : onPlayback;

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    decorated[channelId] = Object.assign({}, rest, {
      onMessage: decoratedOnMessage,
      onPlayback: decoratedOnPlayback,
      onPresence: decoratedOnPresence
    });
  });
  return decorated;
};