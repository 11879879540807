import { SET_CAPABILITIES } from '../constants/MethodNames';
import { callingAPIMethodWithQueue } from '../utils/hsCallingUtils';
export default function setCallingCapabilities(capabilities) {
  const {
    customAssociations = true,
    callOutcomes = true,
    callTypes = true,
    callingProviders = true,
    fontStyles = true,
    followupTasks = true
  } = capabilities || {};
  return callingAPIMethodWithQueue(SET_CAPABILITIES, {
    customAssociations,
    callOutcomes,
    callTypes,
    callingProviders,
    fontStyles,
    followupTasks
  });
}