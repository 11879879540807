import getIn from 'transmute/getIn';
import { getActiveCallDetails } from 'calling-cross-tab-library/appData/operators/widgetDetailsOperators';
import { ConnectionTypes } from 'calling-cross-tab-library/types/ConnectionTypes';
import { CallDirection } from 'calling-cross-tab-library/constants/CallDirection';
export const getCallDirection = widgetDetails => {
  const activeCallDetails = getActiveCallDetails(widgetDetails);
  return getIn(['direction'], activeCallDetails);
};
export const getIsInboundCall = widgetDetails => {
  const activeCallDetails = getActiveCallDetails(widgetDetails);
  return (activeCallDetails && activeCallDetails.direction) === CallDirection.INBOUND;
};
export const getIsOutboundCall = widgetDetails => {
  const activeCallDetails = getActiveCallDetails(widgetDetails);
  return (activeCallDetails && activeCallDetails.direction) === CallDirection.OUTBOUND;
};
export const getIsTabCall = widgetDetails => {
  const activeCallDetails = getActiveCallDetails(widgetDetails);
  return (activeCallDetails && activeCallDetails.connectionType) === ConnectionTypes.STANDALONE_CALLING_APP;
};
export const getIsWidgetCall = widgetDetails => {
  const activeCallDetails = getActiveCallDetails(widgetDetails);
  return (activeCallDetails && activeCallDetails.connectionType) === ConnectionTypes.EXTERNAL;
};
export const getHasConnectionType = widgetDetails => {
  const activeCallDetails = getActiveCallDetails(widgetDetails);
  return activeCallDetails && !!activeCallDetails.connectionType;
};