import Raven from 'raven-js';
import { isProsemirrorState } from '../../../prosemirror/protected/operators';
let loggedErrors = 0;
function logNonProsemirrorState(editorState) {
  if (loggedErrors <= 3) return;
  try {
    Raven.captureException(new Error('Received non-Prosemirror editor state to calculate content'), {
      extra: {
        editorState
      }
    });
    loggedErrors++;
  } catch (e) {
    // we tried
  }
}
function hasContentExcludingEmailReplyHistory(editorState) {
  try {
    for (let idx = 0; idx < editorState.doc.content.childCount; idx++) {
      const node = editorState.doc.content.child(idx);
      const nonReplyHistoryNodeHasContent = node.type.name !== 'emailReplyHistory' && node.content.size > 0;
      if (nonReplyHistoryNodeHasContent) return true;
    }
    return false;
  } catch (e) {
    return false;
  }
}
export const editorDraftHasContent = editorState => {
  if (!isProsemirrorState(editorState)) {
    logNonProsemirrorState(editorState);
    return false;
  }
  return hasContentExcludingEmailReplyHistory(editorState);
};