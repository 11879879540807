// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { getFriendlyFromName } from 'conversations-internal-schema/connected-email-account/operators/connectedEmailAccountGetters';
import { getFallbackAccount } from 'conversations-thread-data/connected-accounts/public';
export const getFriendlyNameFromFallbackAccount = ({
  channelInstances,
  connectedAccounts
}) => {
  if (connectedAccounts && channelInstances) {
    const fallbackAccount = getFallbackAccount({
      channelInstances,
      connectedAccounts
    });
    if (fallbackAccount) {
      const friendlyFromName = getFriendlyFromName(fallbackAccount);
      if (friendlyFromName && friendlyFromName.length > 0) return friendlyFromName;
    }
  }
  return null;
};