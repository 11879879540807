// copied from: https://git.hubteam.com/HubSpot/ui-addon-conversations-settings/blob/3c89aee1836bb1efd278f39f1bb316d4f77c6c81/static/js/users-and-teams/operators/searchResults.ts

import { getTextFromSearchObject } from './getTextFromSearchObject';
const filterStrings = (option, input) => option.text.toLowerCase().includes(input.toLowerCase());
const filterObjects = (option, input) => {
  const text = getTextFromSearchObject(option);
  return filterStrings({
    text
  }, input);
};
const checkIsUserOption = option => typeof option.text === 'string';
export const searchResults = (options, input) => {
  return options.filter(option => checkIsUserOption(option) ? filterStrings(option, input) : filterObjects(option, input));
};