export const CLOSED_DATE = 'closed_date';
export const CREATE_DATE = 'createdate';
export const ORIGINATING_THREAD_ID = 'hs_conversations_originating_thread_id';
export const OWNER = 'hubspot_owner_id';
export const PIPELINE_ID = 'hs_pipeline';
export const PIPELINE_STAGE_ID = 'hs_pipeline_stage';
export const PRIORITY = 'hs_ticket_priority';
export const CATEGORY = 'hs_ticket_category';
export const SUBJECT = 'subject';
export const TICKET_ID = 'hs_ticket_id';
export const TIME_TO_CLOSE_SLA_AT = 'hs_time_to_close_sla_at';
export const TIME_TO_CLOSE_SLA_STATUS = 'hs_time_to_close_sla_status';
export const TIME_TO_FIRST_RESPONSE_SLA_AT = 'hs_time_to_first_response_sla_at';
export const TIME_TO_FIRST_RESPONSE_SLA_STATUS = 'hs_time_to_first_response_sla_status';
export const TIME_TO_NEXT_RESPONSE_SLA_AT = 'hs_time_to_next_response_sla_at';
export const TIME_TO_NEXT_RESPONSE_SLA_STATUS = 'hs_time_to_next_response_sla_status';