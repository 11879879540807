import Track from '../../js/utils/ZorseGrrTaggingTracker';
import { CLOSE_CONVERSATION } from './ActionTypes';
import { resolveConversation } from '../utils/ZorseGrrTaggingApi';
import { EventState } from '../types/events';
export function closeConversation(conversationId) {
  return dispatch => {
    Track.resolveConversation(EventState.INITIATED);
    dispatch({
      type: CLOSE_CONVERSATION,
      meta: 'request'
    });
    return resolveConversation(conversationId).then(__ => {
      Track.resolveConversation(EventState.SUCCEEDED);
      return dispatch({
        type: CLOSE_CONVERSATION,
        meta: 'response'
      });
    }, payload => {
      Track.resolveConversation(EventState.FAILED);
      return dispatch({
        type: CLOSE_CONVERSATION,
        error: true,
        payload
      });
    });
  };
}