import Raven from 'raven-js';
import { Metrics } from '../Metrics';
const APP_START_MARKER = 'app-start';
const supportsPerformance = performance => {
  return typeof performance !== 'undefined' && typeof performance.mark === 'function' && typeof performance.clearMarks === 'function' && typeof performance.clearMeasures === 'function' && typeof performance.getEntriesByName === 'function' && typeof performance.measure === 'function';
};
export const clearPerformanceMarksAndMeasures = marker => {
  performance.clearMarks(`${marker}-start`);
  performance.clearMarks(`${marker}-stop`);
  performance.clearMeasures(`${marker}-duration`);
};
export const markAppStartForPerformanceMetrics = () => {
  performance.mark(APP_START_MARKER);
};
export const recordDurationSinceAppStart = (marker, performance = window.performance) => {
  if (!supportsPerformance(performance)) return;
  try {
    const startMarkerEntries = performance.getEntriesByName(APP_START_MARKER);
    if (startMarkerEntries && startMarkerEntries.length) {
      performance.mark(`${marker}-stop`);
      performance.measure(`${marker}-duration`, APP_START_MARKER, `${marker}-stop`);
      const duration = performance.getEntriesByName(`${marker}-duration`)[0].duration;
      Metrics.timer(marker).update(duration);
    }
  } catch (error) {
    Raven.captureException(error);
  } finally {
    clearPerformanceMarksAndMeasures(marker);
  }
};

/**
 *
 * @description This function takes in a marker name and appends '-start' to it. If window.performance or the passed performance
 * prop supports performance, it will create a performance mark with that name.
 * @param {String} marker The name of the marker. eg INBOX-APPLICATION-START
 */
export const startDuration = (marker, performance = window.performance) => {
  if (!supportsPerformance(performance)) return;
  performance.mark(`${marker}-start`);
};

/**
 *
 * @description This function takes in a marker name and checks to see if that
 * marker appended with '-start' has been used. If so, it calculates the duration
 * between when that marker was placed and now, reports it as metric,
 * and then removes the corresponding marks and measures.
 * @param {String} marker The name of the marker. eg INBOX-APPLICATION-START
 */
export const recordDuration = (marker, performance = window.performance) => {
  if (!supportsPerformance(performance)) return;
  try {
    const startMarkerEntries = performance.getEntriesByName(`${marker}-start`);
    if (startMarkerEntries && startMarkerEntries.length) {
      const stopMarkerEntries = performance.getEntriesByName(`${marker}-stop`);
      if (stopMarkerEntries && stopMarkerEntries.length === 0) performance.mark(`${marker}-stop`);
      performance.measure(`${marker}-duration`, `${marker}-start`, `${marker}-stop`);
      const duration = performance.getEntriesByName(`${marker}-duration`)[0].duration;
      Metrics.timer(marker).update(duration);
    }
  } catch (error) {
    Raven.captureException(error);
  } finally {
    clearPerformanceMarksAndMeasures(marker);
  }
};