import I18n from 'I18n';
import formatName from 'I18n/utils/formatName';
import formatPhoneNumber from 'I18n/utils/formatPhoneNumber';
export function getFullName(visitor) {
  return formatName({
    firstName: visitor.firstName,
    lastName: visitor.lastName
  });
}
export function getVisitorWhatsAppDisplayText(visitor) {
  return visitor.whatsAppPhoneNumber && formatPhoneNumber(visitor.whatsAppPhoneNumber, '');
}

/**
 * Given a Visitor, this function will return a string to render as a display
 * name for that visitor, whether it's known or not.
 */
export function getVisitorDisplayName(visitor) {
  return getKnownVisitorDisplayName(visitor) || I18n.text('find-and-filter-data.visitors.unknownVisitor');
}
export function getKnownVisitorDisplayName(visitor) {
  return getFullName(visitor) || visitor.email || getVisitorWhatsAppDisplayText(visitor);
}