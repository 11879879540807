'use es6';

export const getOnboardingStep = ({
  stepName,
  action
}) => ({
  key: 'onboarding-step-from-thread-view',
  properties: {
    action,
    'step-name': stepName.toLowerCase()
  }
});