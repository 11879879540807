import { useMutation } from 'data-fetching-client';
import { useCallback } from 'react';
import { setSingleHubUserSettingMutation } from '../mutations/hub-user-attributes.mutations';
import { singleHubUserSettingQuery } from '../queries/hub-user-attributes.queries';

/**
 * This hook will call frontend-preferences-client.HubUserAttributesClient.write behind data-fetching-client.
 *
 * This hook will refetch useHubUserSetting upon mutation
 */
export const useSetHubUserSetting = () => {
  const [mutate] = useMutation(setSingleHubUserSettingMutation);
  return useCallback(async ({
    settingsKey,
    value
  }) => {
    await mutate({
      variables: {
        settingsKey,
        value
      },
      // https://hubspot.slack.com/archives/C044WPA3F4J/p1701706118224219
      // hubsSettingClient.write and hubsSettingClient.read are read-after-write safe
      // if they were not, we would need a cache.writeQuery here.
      refetchQueries: [singleHubUserSettingQuery],
      awaitRefetchQueries: true
    });
    return null;
  }, [mutate]);
};