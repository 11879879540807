import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchWhatsAppSubscription } from '../clients/fetchWhatsAppSubscription';
const FETCH_WHATSAPP_SUBSCRIPTION_QUERY = registerQuery({
  fieldName: 'whatsAppSubscription',
  args: ['phoneNumber'],
  fetcher: fetchWhatsAppSubscription
});
export function useWhatsAppSubscription({
  deferred,
  phoneNumber
}) {
  const {
    data,
    loading,
    error
  } = useQuery(FETCH_WHATSAPP_SUBSCRIPTION_QUERY, {
    skip: deferred || !phoneNumber,
    variables: {
      phoneNumber
    }
  });
  const whatsAppSubscription = data && data.whatsAppSubscription;
  return {
    whatsAppSubscription,
    loading,
    error
  };
}