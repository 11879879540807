import { createAction } from 'redux-actions';
import { STOP_HEARTBEAT } from '../constants/actionTypes';
import BackoffOnFailureSingleton from '../strategies/BackoffOnFailureSingleton';
import { sendHeartbeat } from './sendHeartbeat';
import { sendOfflineNotice } from './sendOfflineNotice';
import { hasConversationsInteract } from '../../auth/selectors/hasConversationsInteract';
const stopHeartbeatAction = createAction(STOP_HEARTBEAT);
export const stopHeartbeat = () => (dispatch, getState) => {
  const hasInteract = hasConversationsInteract(getState());
  if (!hasInteract) {
    return;
  }

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
  const heartbeatDispatch = sendHeartbeat(dispatch, getState);
  const heartbeat = BackoffOnFailureSingleton(heartbeatDispatch);
  heartbeat.stop();

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  dispatch(sendOfflineNotice());
  dispatch(stopHeartbeatAction());
};