import once from 'transmute/once';
import I18n from 'I18n';
export const getUserRequestedRefundReasons = once(() => [{
  text: I18n.text('cardPaymentHighlight.paymentHighlight.refundReasons.duplicate'),
  value: 'DUPLICATE'
}, {
  text: I18n.text('cardPaymentHighlight.paymentHighlight.refundReasons.fraudulent'),
  value: 'FRAUDULENT'
}, {
  text: I18n.text('cardPaymentHighlight.paymentHighlight.refundReasons.requestedByCustomer'),
  value: 'REQUESTED_BY_CUSTOMER'
}]);
export const ASYNC_PAYMENT_FAILURE = 'ASYNC_PAYMENT_FAILURE';