import { getInitialAppData } from '../appData/constants/InitialAppData';
import { ConnectionStatusTypes } from 'shared-worker-versioning/lib/types/ConnectionStatuses';
import { logError } from '../utils/logError';
import { getUnsupportedReason } from '../utils/getIsCrossTabMessagingSupported';
import CallingCrossTabClientError from '../utils/CallingCrossTabClientError';
export const getNoopCrossTabMessagingInstance = (connectionStatus = ConnectionStatusTypes.UNSUPPORTED) => ({
  isReady: connectionStatus !== ConnectionStatusTypes.UNINITIALIZED,
  appData: getInitialAppData(),
  connectionStatus,
  unsupportedReason: getUnsupportedReason(),
  sendMessage: message => {
    logError(new CallingCrossTabClientError({
      message: `Attempted to send a message type ${message && message.type || 'UNKNOWN'} in a noop instance`
    }), {
      level: 'info'
    });
  },
  initializeFromAsyncLoad: () => {},
  initialize: () => Promise.reject(new Error('Attempted to initialize in an unsupported browser'))
});