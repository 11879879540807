import { createAction } from 'redux-actions';
import { fetchFromOmnibusClient } from '../clients/fetchFromOmnibusClient';
import { POLL_OMNIBUS } from '../constants/asyncActionTypes';
import { buildRequestErrorMetaObject } from 'conversations-error-reporting/error-actions/builders/buildRequestErrorMetaObject';
import { silenceErrorAlert } from 'conversations-error-reporting/error-actions/builders/silenceErrorAlert';
import { omnibusFulfilled, parseOmnibus } from 'find-and-filter-data/omnibus/public';
import { getCurrentViewId } from '../operators/omnibusGetters';
const pollOmnibusStart = createAction(POLL_OMNIBUS.STARTED);
const pollOmnibusSucceed = createAction(POLL_OMNIBUS.SUCCEEDED);
const pollOmnibusFailed = createAction(POLL_OMNIBUS.FAILED, undefined, ({
  error,
  requestArgs
}) => requestArgs.silent ? silenceErrorAlert({
  error
}) : buildRequestErrorMetaObject({
  error
}));
export const pollOmnibus = requestArgs => dispatch => {
  dispatch(pollOmnibusStart({
    requestArgs
  }));
  return fetchFromOmnibusClient(requestArgs).then(omnibus => {
    const [findAndFilterOmnibus, remainingOmnibus] = parseOmnibus(omnibus);
    const viewId = getCurrentViewId(omnibus);
    if (viewId) {
      dispatch(omnibusFulfilled(findAndFilterOmnibus, viewId, requestArgs.inboxId));
    }
    return dispatch(pollOmnibusSucceed({
      data: remainingOmnibus,
      requestArgs
    }));
  }).catch(error => dispatch(pollOmnibusFailed({
    requestArgs,
    error
  })));
};