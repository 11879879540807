import PortalIdParser from 'PortalIdParser';
import { getFullUrl } from 'hubspot-url-utils';
export function getSignedUrl({
  fileId,
  isDownloadRequest = false,
  size
}) {
  const base = getFullUrl('app-api');
  let signedUrl = `${base}/filemanager/api/v2/files/${fileId}/signed-url-redirect?&portalId=${PortalIdParser.get()}`;
  if (size) signedUrl = `${signedUrl}&size=${size}`;
  return isDownloadRequest ? `${signedUrl}&response-content-disposition=attachment ` : signedUrl;
}