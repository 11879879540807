'use es6';

import { createAction } from 'redux-actions';
import { CHANNEL_CHANGE_RECEIVED } from '../constants/actionTypes';
export const channelChangeReceived = createAction(CHANNEL_CHANGE_RECEIVED, ({
  channelChange,
  threadId
}) => ({
  channelChange,
  threadId
}));