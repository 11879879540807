export const registerPredicate = predicate => {
  const internalRegisterPredicate = () => {
    window.hubspot.notifications.registerPreemptPredicate({
      origin: 'CONVERSATIONS',
      predicate
    });
  };
  if (!window.hubspot.notifications) window.hubspot.notifications = {};
  if (window.hubspot.notifications.registerPreemptPredicate) {
    internalRegisterPredicate();
  } else {
    const existingCallbacks = window.hubspot.notifications.onPremptApiSetupCallbacks || [];
    window.hubspot.notifications.onPreemptApiSetupCallbacks = [...existingCallbacks, internalRegisterPredicate];
  }
};
export const deregisterPredicate = predicate => {
  const apiExists = window.hubspot.notifications && window.hubspot.notifications.deregisterPreemptPredicate;
  if (apiExists) {
    window.hubspot.notifications.deregisterPreemptPredicate({
      origin: 'CONVERSATIONS',
      predicate
    });
  }
};