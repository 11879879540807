// original: https://git.hubteam.com/HubSpot/conversations-internal-schema/blob/1729733ab4b5cfdd5950af37c67157f5deb9589b/static/js/team/operators/getters.js
import getIn from 'transmute/getIn';
import { checkIsKeyedIterable } from '../../shared/utils/immutable';
import { CHILD_TEAMS, ID, NAME, NUM_ROUTABLE_USERS_IN_HIERARCHY, ROUTABLE_USER, USERS } from '../constants/keyPaths';
/**
 *
 * @description Return the teams in an array of teams or an `Immutable.List` of teams. If there are no teams, return `undefined`.
 * @returns
 */
export const getChildTeams = team => {
  const childTeams = getIn(CHILD_TEAMS, team);
  if (!childTeams) {
    return undefined;
  }
  const numberOfChildTeams = checkIsKeyedIterable(childTeams) ? childTeams.size : childTeams.length;
  return numberOfChildTeams > 0 ? childTeams : undefined;
};
export const getId = getIn(ID);
export const getName = getIn(NAME);

// These operator on a `users-and-teams` version of `Team` (aka, `TeamWithUsers`)
export const getNumRoutableUsers = getIn(NUM_ROUTABLE_USERS_IN_HIERARCHY);
export const getUsers = getIn(USERS);
export const isTeamUserRoutable = getIn(ROUTABLE_USER);