'use es6';

import { createAction } from 'redux-actions';
import { TYPING_MESSAGE_RECEIVED } from '../constants/actionTypes';
export const typingMessageReceived = createAction(TYPING_MESSAGE_RECEIVED, ({
  timeoutId,
  message
}) => ({
  timeoutId,
  message
}));