import { getFullUrl } from 'hubspot-url-utils';
export const callingUserPreferencesUrl = portalId => {
  return `${getFullUrl('app')}/settings/${portalId}/user-preferences/calling`;
};
export const supportUrl = portalId => {
  return `${getFullUrl('app')}/support/${portalId}/open`;
};
export const callingToolSettings = portalId => {
  return `${getFullUrl('app')}/settings/${portalId}/calling`;
};
export const callingMarketplaceAppsUrl = portalId => {
  return `${getFullUrl('app')}/ecosystem/${portalId}/marketplace/apps/sales/calling`;
};
export const upgradeSalesStarterUrl = portalId => {
  return `${getFullUrl('app')}/pricing/${portalId}/sales`;
};
export const settingsUsersUrl = portalId => {
  return `${getFullUrl('app')}/settings/${portalId}/users`;
};