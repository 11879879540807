import http from 'conversations-http/clients/http';
import { CONVERSATION_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
const DEFAULT_LIMIT = 100;
export const fetchFilteredViewMembersService = ({
  customViewId,
  filterGroups,
  inboxId,
  limit = DEFAULT_LIMIT,
  offsetTimestamp,
  offsetId,
  searchQuery,
  sortDirection = 'DESC'
}) => {
  return http.post('/conversations-custom-views/v1/members/search', {
    data: {
      customViewId,
      filterGroups,
      inboxId,
      limit,
      objectTypeId: CONVERSATION_TYPE_ID,
      offsetTimestamp,
      offsetId,
      maxPreviewLength: 200,
      searchQuery,
      sortDirection,
      includeHiddenFromVisitor: true
    }
  });
};