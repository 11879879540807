import { getProperty } from 'customer-data-objects/record/ObjectRecordAccessors';
import getIn from 'transmute/getIn';
import { SUPPORTED_CONVERSATION_OBJ_PROPERTIES, SUPPORTED_CONVERSATION_PROPERTIES_MAP } from '../should-update/optimisticUpdates';
import { getFilterOperationValue } from './getFilterOperationValue';
import { getOperatorFromFilterOperation } from './getOperatorFromFilterOperation';
const getTicketPropertyGetter = property => ticket => getProperty(ticket, property) || getIn(['defaultProperties', property, 'value'], ticket);

/**
 * @description This function will take the value and return a callback which
 * can be passed a Filter object to compare the given value against.
 *
 * @param {Filter} filter The filter to evaluate with
 * @param {ThreadListMember | ThreadDetails | Ticket} object The object being evaluated
 * @param {'TICKET' | 'CONVERSATION'} objectType The type of the object being evaluated
 * @returns {boolean | null}
 */
const evaluateFilter = (filter, object, objectType) => {
  const {
    operation,
    property
  } = filter;
  const doFilterOperation = getOperatorFromFilterOperation(operation, filter);
  const valueGetter = objectType === 'TICKET' ? getTicketPropertyGetter(property) : SUPPORTED_CONVERSATION_PROPERTIES_MAP[property];
  /**
   * If we don't have an operator for a filter, we should update getOperatorFromFilterOperation
   * to add an operator for the filter operation. If we don't have a value getter,
   * then the filter operation is for an unsupported conversation object property.
   * To add support for it we should add the property in SUPPORTED_CONVERSATION_PROPERTIES_MAP
   */
  if (!doFilterOperation || !valueGetter) return null;
  return doFilterOperation({
    filterValue: getFilterOperationValue(operation),
    value: valueGetter(object)
  });
};
const toEvaluationResult = evaluationBoolResult => evaluationBoolResult ? 'PASSED' : 'FAILED';

/**
 * @description This operator will evaluate the given value against the given
 * filterBranch and return whether the given value passes the filters or
 * not. If the operator is unable to determine if the given value passes it will
 * default to return `true` - this will allow us to fallback on the BE to provide
 * a THREADS_UPDATE message with which to remove the TLM if it needs to be removed.
 *
 * @param {FilterBranch} filterBranch The filter branches to evaluate value against
 * @param {ThreadListMember | ThreadDetails} threadListMember The threadListMember
 * (or details) being evaluated.
 * @param {Ticket | null} ticket The associated ticket to the thread if it exists
 */
export const evaluateFilterBranch = (filterBranch, conversationMember, ticketMember) => {
  // filterBranch.objectType is undefined for CONVERSATION filters
  const filterBranchObjectType = filterBranch.objectType || 'CONVERSATION';

  /**
   * Only evaluate ticket properties when we're evaluating with a ticket
   */
  const ticketRelevantFilters = ticketMember ? filterBranch.filters : [];
  const relevantFilters = filterBranchObjectType === 'CONVERSATION' ? filterBranch.filters.filter(f => SUPPORTED_CONVERSATION_OBJ_PROPERTIES.includes(f.property)) : ticketRelevantFilters;

  /**
   * If we don't have any relevant filters or other branches, Skip
   */
  if (filterBranch.filterBranches.length === 0 && relevantFilters.length === 0) {
    return 'SKIPPED';
  }

  /**
   * We know that all filters will always be AND'd together based off the
   * implementation of the customer-data-filters library. Hence `&&`
   */
  const currentBranchFiltersEvaluationResult = relevantFilters.reduce((result, filter) => {
    const objectToEvaluate = SUPPORTED_CONVERSATION_OBJ_PROPERTIES.includes(filter.property) ? conversationMember : ticketMember;
    return result && evaluateFilter(filter, objectToEvaluate, filterBranchObjectType);
  }, true);

  /**
   * if we didn't have an operator, we cannot evaluate and shouldn't try to create
   * a patch.
   */
  if (currentBranchFiltersEvaluationResult === null) return 'CANNOT_EVALUATE';
  const evaluationResult = relevantFilters.length === 0 ? 'SKIPPED' : toEvaluationResult(currentBranchFiltersEvaluationResult);

  /**
   * For each filter branch, evaluate its results and collect them together based
   * on the filterBranchOperator operation. If there are no branches, this will
   * just resolve to the filters `evaluationResult`
   */
  const passesBranches = filterBranch.filterBranches.reduce((passesFilters, branch) => {
    /**
     * If there is an error during evaluation, do not continue to evaluate
     */
    if (passesFilters === 'CANNOT_EVALUATE') return 'CANNOT_EVALUATE';

    /**
     * If the previous branch was skipped, just continue to evaluate the next
     */
    if (passesFilters === 'SKIPPED') {
      return evaluateFilterBranch(branch, conversationMember, ticketMember);
    }
    if (filterBranch.filterBranchOperator === 'AND') {
      /**
       * AND
       * If it's already failed, Fail && Pass is still Fail
       */
      return passesFilters === 'FAILED' ? 'FAILED' : evaluateFilterBranch(branch, conversationMember, ticketMember);
    } else {
      /**
       * OR
       * If it's already passed, Pass || Fail is still Pass
       */
      return passesFilters === 'PASSED' ? 'PASSED' : evaluateFilterBranch(branch, conversationMember, ticketMember);
    }
  }, evaluationResult);
  return passesBranches;
};