'use es6';

import { fromJS } from 'immutable';
import { hardDeleteMessagesInThread as deleteMesagesInThreadClient } from '../clients/hardDeleteMessagesInThread';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { HARD_DELETE_THREAD_MESSAGES } from 'conversations-thread-data/thread-deletion/public/constants/asyncActionTypes';
export const hardDeleteMessagesInThread = createAsyncAction({
  requestFn: deleteMesagesInThreadClient,
  actionTypes: HARD_DELETE_THREAD_MESSAGES,
  toRecordFn: fromJS
});