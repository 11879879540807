/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { fetchChannelInstances } from '../../../channel-instances/_internal/client/fetchChannelInstances';
import { CALLING_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
import { useEffect, useRef, useCallback, useState } from 'react';
import { SUCCEEDED, FAILED, STARTED, UNINITIALIZED } from 'conversations-async-data/async-data/constants/asyncStatuses';
const defaultData = null;
const defaultChannelLimit = 20;
export const useCallingChannelInstances = (callingChannelLimit = defaultChannelLimit) => {
  const [data, setData] = useState(defaultData);
  const [status, setStatus] = useState(UNINITIALIZED);
  const isMounted = useRef(true);
  const getCallingChannelInstances = useCallback(() => {
    setStatus(STARTED);
    return fetchChannelInstances(CALLING_GENERIC_CHANNEL_ID, callingChannelLimit).then(res => {
      if (isMounted.current) {
        setData(res);
        setStatus(SUCCEEDED);
      }
    }).catch(() => {
      if (isMounted.current) setStatus(FAILED);
    });
  }, [callingChannelLimit]);
  useEffect(() => {
    isMounted.current = true;
    getCallingChannelInstances();
    return () => {
      isMounted.current = false;
    };
  }, [getCallingChannelInstances]);
  return {
    data,
    status,
    refetch: getCallingChannelInstances
  };
};