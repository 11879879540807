import { Record } from 'immutable';
export default Record({
  companyId: null,
  name: '',
  domain: '',
  imageUrl: '',
  address: '',
  address2: '',
  city: '',
  zip: '',
  state: '',
  country: ''
}, 'QuoteCompany');