import { useMutation } from 'data-fetching-client';
import { useCallback, useMemo } from 'react';
import { updateUserPropertiesMutation } from '../api/userObject.queries';
import { HS_OUT_OF_OFFICE_HOURS } from '../constants';
import { getParsedJsonPropertyValue } from '../records/CrmObject';
import { getCrmObjectByUserIdQuery, useUserPropertyValue } from './useUserPropertyValue';
import Raven from 'raven-js';
import { getCurrentOOOSegment } from '../utils/outOfOfficeUtils';
export const useOutOfOfficeHours = (userId, options) => {
  const {
    value: outOfOfficeHours,
    loading
  } = useUserPropertyValue(userId, HS_OUT_OF_OFFICE_HOURS, getParsedJsonPropertyValue);
  const [updateProperty] = useMutation(updateUserPropertiesMutation, {
    refetchQueries: [getCrmObjectByUserIdQuery]
  });
  const updateOutOfOfficeHours = useCallback(updatedHours => {
    updateProperty({
      variables: {
        userId,
        isViewingSelf: !!options.isViewingSelf,
        // this is to use the endpoint that requiers users-write scope since we don't have a use case for non-scoped users to update only themselves.
        userProperties: {
          [HS_OUT_OF_OFFICE_HOURS]: JSON.stringify(updatedHours)
        }
      }
    }).catch(error => {
      Raven.captureException(error);
      throw error;
    });
  }, [options.isViewingSelf, updateProperty, userId]);
  const currentSegment = useMemo(() => outOfOfficeHours && getCurrentOOOSegment(outOfOfficeHours), [outOfOfficeHours]);
  return {
    outOfOfficeHours,
    isLoadingOOOHours: loading,
    updateOutOfOfficeHours,
    currentSegment
  };
};