// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { initializePubSubWithVendor } from 'conversations-internal-pub-sub/redux/actions/initializePubSubWithVendor';
import { startHeartbeat } from '../../presence/actions/startHeartbeat';
import { stopHeartbeat } from '../../presence/actions/stopHeartbeat';
import { syncWithOmnibus } from '../../omnibus/actions/syncWithOmnibus';
import { hasInsufficientJitaPermissions } from '../../unified-inbox/selectors/hasInsufficientJitaPermissions';
import { isMissingInboxPermission } from 'conversations-inbox-lib/unified-inbox/public/selectors';
export const startPubSub = resolveVendor => (dispatch, getState) => {
  if (hasInsufficientJitaPermissions(getState()) || isMissingInboxPermission(getState())) {
    return;
  }
  const lifecycleHooks = {
    onConnect({
      connectionWasSuspended
    }) {
      dispatch(startHeartbeat());
      if (connectionWasSuspended) {
        dispatch(syncWithOmnibus());
      }
    },
    onDisconnect() {
      dispatch(stopHeartbeat());
    },
    onFailure() {
      dispatch(stopHeartbeat());
    }
  };
  dispatch(initializePubSubWithVendor({
    lifecycleHooks,
    resolveVendor
  }));
};