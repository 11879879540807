import Recipient from 'conversations-message-history/common-message-format/records/Recipient';
import DeliveryIdentifier from 'conversations-message-history/common-message-format/records/DeliveryIdentifier';
export function buildRecipientsFromIdentifiers(fieldName, genericIdentifierRecords) {
  const nextRecipients = genericIdentifierRecords.map(identifier => Recipient({
    actorId: identifier.id ? `V-${identifier.id}` : null,
    recipientField: fieldName,
    deliveryIdentifier: DeliveryIdentifier({
      type: identifier.deliveryIdentifierType || undefined,
      value: identifier.deliveryIdentifierValue || undefined
    })
  }));
  return nextRecipients.toList();
}