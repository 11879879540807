import { createStack } from 'hub-http';
import promiseClient from 'hub-http/adapters/promiseClient';
import * as core from 'hub-http/middlewares/core';
import * as hubapi from 'hub-http/middlewares/hubapi';
// @ts-expect-error untyped module
import * as debug from 'hub-http/middlewares/debug';
// @ts-expect-error untyped module
import * as externalAuth from 'hub-http/middlewares/externalAuth';
export default promiseClient(createStack(core.services, hubapi.defaults, debug.allowTimeoutOverride, core.jsonBody, core.httpsOnly, hubapi.hubapi, externalAuth.cookieAuthentication, core.withQuery, debug.rewriteUrl, core.reportOptionsError, hubapi.retryOnError, core.retry(response => response.options.method === 'GET' && response.status === 404 && response.responseJSON && response.responseJSON.errorType === 'NO_PDF_FOUND_ERROR', {
  reason: 'waiting for quote pdf to be generated',
  maxRetries: 12,
  delay: 1000
}), /* eslint-disable @typescript-eslint/no-misused-promises */
core.onResponseError(response => {
  if (response.retry && !response.retry.exceededRetries && response.options.onRetry) {
    response.options.onRetry(response.retry);
  }
  return Promise.reject(response);
}), core.validateStatus, core.jsonResponse));