// Originally from: https://git.hubteam.com/HubSpot/conversations-thread-view/blob/2135c0b3386ff1b4d86e0cb647542f581044fd48/conversations-thread-view/static/js/usage-tracking/utils/threadInteraction.ts#L67-L79
export const getAssignedThread = ({
  assignee,
  hasAssociatedTicket
}) => ({
  key: 'assigned-thread',
  properties: {
    action: 'assigned thread',
    assignee,
    hasAssociatedTicket
  }
});

// Originally from: https://git.hubteam.com/HubSpot/conversations-thread-view/blob/2135c0b3386ff1b4d86e0cb647542f581044fd48/conversations-thread-view/static/js/usage-tracking/utils/threadInteraction.ts#L265-270
export const getOpenedOwnerDropdown = () => ({
  key: 'opened-owner-dropdown',
  properties: {
    action: 'owner dropdown opened'
  }
});

// Originally from: https://git.hubteam.com/HubSpot/conversations-thread-view/blob/2135c0b3386ff1b4d86e0cb647542f581044fd48/conversations-thread-view/static/js/usage-tracking/utils/threadInteraction.ts#L272-277
export const getSearchedOwnerDropdown = () => ({
  key: 'searched-owner-dropdown',
  properties: {
    action: 'owner dropdown search used'
  }
});