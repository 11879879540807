'use es6';

import { Set as ImmutableSet } from 'immutable';
import { getSenderType, getSenderId, getType } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { getSource, getSourceId } from 'conversations-message-history/crm-object-lifecycle-update/operators/crmObjectLifecycleUpdateGetters';
import { INITIAL_MESSAGE } from 'conversations-message-history/initial-message/constants/messageType';
import { THREAD_COMMENT } from 'conversations-message-history/comment-message/constants/messageTypes';
import { THREAD_STATUS_UPDATE } from 'conversations-message-history/thread-status-update/constants/messageTypes';
import { ASSIGNMENT_UPDATE } from 'conversations-message-history/assignment-update-message/constants/messageTypes';
import { CRM_OBJECT_LIFECYCLE_UPDATE } from 'conversations-message-history/crm-object-lifecycle-update/constants/messageTypes';
import { COMMON_MESSAGE } from 'conversations-message-history/common-message-format/constants/messageTypes';
import { FILTERED_CHANGE } from 'conversations-message-history/filtered-change-message/constants/messageTypes';
import { getAgentTypeFromAudit, getAgentIdFromAudit, getAssignedAgentType, getAssignedAgentId } from 'conversations-message-history/assignment-update-message/operators/assignmentGetters';
import { getMessages } from 'conversations-message-history/thread-history/operators/getters';
import { buildSenderLocator } from 'conversations-message-history/senders/operators/buildSenderLocator';
import { getSenderTypeFromAgentType } from './getSenderTypeFromAgentType';
import { generateSenderKeyFromMessageAudit } from 'conversations-message-history/senders/operators/generateSenderKeyFromMessageAudit';
const getAssignmentKeys = (assignorId, assignorType, assigneeId, assigneeType) => {
  const assignorKey = assignorId && assignorType && buildSenderLocator(assignorType, assignorId);
  const assigneeKey = assigneeId && assigneeType && buildSenderLocator(assigneeType, assigneeId);
  return ImmutableSet([assignorKey, assigneeKey].filter(Boolean));
};
export const getSenderLocatorsFromHistory = history => {
  const messages = getMessages(history);
  return messages.reduce((accumulator, message) => {
    switch (getType(message)) {
      case THREAD_COMMENT:
      case INITIAL_MESSAGE:
      case COMMON_MESSAGE:
        {
          const senderId = getSenderId(message);
          const senderType = getSenderType(message);
          const senderKey = senderId && senderType && buildSenderLocator(senderType, senderId);
          return senderKey ? accumulator.add(senderKey) : accumulator;
        }
      case CRM_OBJECT_LIFECYCLE_UPDATE:
        {
          const senderId = getSourceId(message);
          const senderType = getSource(message);
          const senderKey = senderId && senderType && buildSenderLocator(senderType, senderId);
          return senderKey ? accumulator.add(senderKey) : accumulator;
        }
      case ASSIGNMENT_UPDATE:
        {
          const assignorId = getAgentIdFromAudit(message);
          const assignorType = getSenderTypeFromAgentType(getAgentTypeFromAudit(message));
          const assigneeId = getAssignedAgentId(message);
          const assigneeType = getSenderTypeFromAgentType(getAssignedAgentType(message));
          return accumulator.union(getAssignmentKeys(assignorId, assignorType, assigneeId, assigneeType));
        }
      case FILTERED_CHANGE:
      case THREAD_STATUS_UPDATE:
        {
          const threadKey = generateSenderKeyFromMessageAudit(message);
          return threadKey ? accumulator.add(threadKey) : accumulator;
        }
      default:
        return accumulator;
    }
  }, ImmutableSet());
};