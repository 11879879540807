import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["refetch"];
import { useQuery } from '@apollo/client';
import { FETCH_ASSOCIATED_OBJECTS_CARD_ALL_ASSOCIATION_TYPES_BETWEEN_OBJECT_IDS } from '../queries';
import { useState, useEffect, useCallback } from 'react';
export function useGetSpecificAssociationDefinitions({
  allAssociationTypesBetweenObjectIdsData,
  associationTypes,
  objectId
}) {
  const [definitions, setDefinitions] = useState(allAssociationTypesBetweenObjectIdsData && allAssociationTypesBetweenObjectIdsData.allAssociationTypesBetweenObjectIds || []);

  // Prevents hiding and showing the association labels when the data is refetched
  useEffect(() => {
    if (!allAssociationTypesBetweenObjectIdsData || !allAssociationTypesBetweenObjectIdsData.allAssociationTypesBetweenObjectIds) {
      return;
    }
    setDefinitions(allAssociationTypesBetweenObjectIdsData.allAssociationTypesBetweenObjectIds);
  }, [allAssociationTypesBetweenObjectIdsData]);
  const specificAssociationDefinitions = associationTypes.filter(definition => {
    const matchingAssociationType = definitions.find(type => type.toObjectId === objectId);
    return Boolean(matchingAssociationType && matchingAssociationType.associationDefinitions.find(associationTypeBetweenObjectId => associationTypeBetweenObjectId.associationCategory === definition.associationCategory && associationTypeBetweenObjectId.associationTypeId === definition.associationTypeId));
  });
  return specificAssociationDefinitions;
}
export function useFetchAllAssociationTypesBetweenObjectIds({
  fetchPolicy,
  objectId,
  objectTypeId,
  skip,
  toObjectIds,
  toObjectTypeId
}) {
  const _useQuery = useQuery(FETCH_ASSOCIATED_OBJECTS_CARD_ALL_ASSOCIATION_TYPES_BETWEEN_OBJECT_IDS, {
      variables: {
        fromObjectId: objectId,
        fromObjectType: objectTypeId,
        toObjectIds,
        toObjectType: toObjectTypeId
      },
      skip: !toObjectIds.length || skip,
      fetchPolicy: fetchPolicy || 'cache-first'
    }),
    {
      refetch
    } = _useQuery,
    queryResult = _objectWithoutPropertiesLoose(_useQuery, _excluded);

  // sometimes refetch returns undefined instead of a promise, so ensure that this returns a promise
  const handleRefetch = useCallback(async (...args) => {
    return await refetch(...args);
  }, [refetch]);
  return Object.assign({
    refetch: handleRefetch
  }, queryResult);
}