'use es6';

import Raven from 'raven-js';
import env from 'enviro';
import CrmLogger from 'customer-data-tracking/loggers/CrmLogger';
export default function (chunkName) {
  return function asyncComponentErrorHandler(error) {
    const errorMessage = `Failed to load chunk ${chunkName}`;
    if (!env.deployed()) {
      console.warn(errorMessage);
      console.error(error);
      return null;
    }
    Raven.captureMessage(errorMessage, {
      extra: {
        chunkName,
        originalError: error
      },
      fingerprint: ['chunk-load-error', chunkName]
    });
    CrmLogger.log('debugChunkLoadIssues', {
      chunk: chunkName,
      error: error && error.message || `unknown error loading chunk ${chunkName}`
    });
    return null;
  };
}