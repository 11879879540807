'use es6';

import { Map as ImmutableMap } from 'immutable';
import { handleActions } from 'redux-actions';
import set from 'transmute/set';
import { HIDE_DELETED_MESSAGES, SHOW_DELETED_MESSAGES } from '../constants/ActionTypes';
const initialState = ImmutableMap();
const deletedMessagesVisibility = handleActions({
  [SHOW_DELETED_MESSAGES]: (state, action) => {
    const {
      threadId
    } = action.payload;
    return set(threadId, true, state);
  },
  [HIDE_DELETED_MESSAGES]: (state, action) => {
    const {
      threadId
    } = action.payload;
    return set(threadId, false, state);
  }
}, initialState);
export default deletedMessagesVisibility;