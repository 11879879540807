import http from 'conversations-http/clients/http';
import { USER_OBJECT_TYPE_ID } from '../constants';
// CrmSearchService: https://tools.hubteam.com/api-catalog/services/CrmSearchService/v1/spec/internal#operations-Search-post-%2Fcrm-search%2Fsearch
const CRM_SEARCH_API_URL = 'crm-search/search';
export const getCRMObjectByUserId = ({
  userId
}) => http.post(CRM_SEARCH_API_URL, {
  data: {
    count: 1,
    objectTypeId: '0-115',
    requestOptions: {
      allPropertiesFetchMode: 'latest_version',
      includeAllProperties: true
    },
    filterGroups: [{
      filters: [{
        operator: 'EQ',
        property: 'hs_internal_user_id',
        value: String(userId)
      }]
    }]
  }
});
const PROPERTIES_API_URL = `properties/v4/${USER_OBJECT_TYPE_ID}/named`;
export const getPropertyOptions = ({
  propertyName
}) => http.get(`${PROPERTIES_API_URL}/${propertyName}`).then(({
  property
}) => property);
export const updateUserProperties = ({
  userId,
  userProperties,
  isViewingSelf
}) => {
  let USER_OBJECT_ENDPOINT = 'user-objects/v1/app/users/';
  if (!isViewingSelf) {
    USER_OBJECT_ENDPOINT = `${USER_OBJECT_ENDPOINT}${userId}`;
  }
  return http.put(USER_OBJECT_ENDPOINT, {
    data: {
      propertyValues: userProperties
    }
  });
};