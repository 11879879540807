import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { WHATSAPP_GENERIC_CHANNEL_ID } from '../../../generic-channels/public/constants';
import { getLatestVisitorMessageTimestampByChannelId } from '../../../thread-histories/public/selectors/getLatestVisitorMessageTimestampByChannelId';
import { NONE, TEMPLATE } from '../../public/constants';
import { useMessageLimitData } from './useMessageLimitData';
const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
export const useMessageLimit = ({
  threadId,
  currentEditorKey,
  isUngatedForWhatsApp
}) => {
  const lastVisitorMessageTimestamp = useSelector(state => getLatestVisitorMessageTimestampByChannelId(state, {
    genericChannelId: WHATSAPP_GENERIC_CHANNEL_ID
  }));

  // TODO - add this to channel configuration data
  const hasConversationLimits = isUngatedForWhatsApp && currentEditorKey === WHATSAPP_GENERIC_CHANNEL_ID;
  const {
    messageLimitData
  } = useMessageLimitData({
    threadId,
    genericChannelId: WHATSAPP_GENERIC_CHANNEL_ID,
    deferred: !hasConversationLimits
  });
  const newestThreadId = messageLimitData === null || messageLimitData === void 0 ? void 0 : messageLimitData.newestThreadId;
  const show24HrLimit = useMemo(() => {
    if (!lastVisitorMessageTimestamp || !hasConversationLimits) return false;
    const timeSinceLastVisitorMessage = Date.now() - lastVisitorMessageTimestamp;
    return timeSinceLastVisitorMessage >= ONE_DAY_IN_MS;
  }, [lastVisitorMessageTimestamp, hasConversationLimits]);
  const defaultState = {
    shouldDisableEditor: false,
    showTemplateSelector: false,
    messageLimitData: null,
    newestThreadId
  };
  if (hasConversationLimits && !messageLimitData) {
    return defaultState;
  }
  const availableSendStatus = messageLimitData === null || messageLimitData === void 0 ? void 0 : messageLimitData.availableSendStatus;

  // if availableSendStatus is NONE, the editor should be completely disabled
  // regardless of when the last visitor message was sent
  if (hasConversationLimits && availableSendStatus === NONE) {
    return {
      shouldDisableEditor: true,
      showTemplateSelector: false,
      messageLimitData,
      newestThreadId
    };
  }

  // check visitor's last message before messageLimitData
  // messageLimitData could become stale if user leaves the window open
  // ex. visitor sends a message while thread is open, messageLimitData will point to an old session
  if (lastVisitorMessageTimestamp) {
    return {
      shouldDisableEditor: show24HrLimit,
      showTemplateSelector: show24HrLimit,
      messageLimitData: hasConversationLimits ? messageLimitData : null,
      newestThreadId
    };
  }

  // if visitor's last message is not loaded into thread history
  if (hasConversationLimits && availableSendStatus) {
    const enableTemplates = availableSendStatus === TEMPLATE;
    return {
      shouldDisableEditor: enableTemplates,
      showTemplateSelector: enableTemplates,
      messageLimitData,
      newestThreadId
    };
  }
  return defaultState;
};