import promiseTimeout from 'hs-promise-utils/timeout';
import { buildError } from '../../error-reporting/builders/buildError';
import { PROMISE_TIMEOUT_MS } from '../constants/defaults';
import { buildRequestTimeoutError } from '../../errors/builders/buildRequestTimeoutError';

/**
 * @see {@link https://www.ably.io/documentation/realtime/presence#presence-states}
 */
export const performPresenceSubscribe = ({
  channel,
  onMessage
}) => promiseTimeout(new Promise((resolve, reject) => {
  // Ignore 'present' messages. Clients should query the current
  // state on subscribe rather than relying on the present feature
  channel.presence.subscribe('enter', onMessage);
  channel.presence.subscribe('leave', onMessage);
  channel.presence.subscribe('update', onMessage);
  channel.presence.enter({}, errorInfo => {
    if (errorInfo) {
      const error = buildError(`PubSub presence channel attachment failure: ${errorInfo.code}`, Object.assign({
        channelName: channel.name
      }, errorInfo));
      reject(error);
    } else {
      resolve();
    }
  });
}), PROMISE_TIMEOUT_MS, buildRequestTimeoutError('PubSub presence.subscribe timeout', {
  channelName: channel.name
}));