'use es6';

import { createAction } from 'redux-actions';
import { MESSAGES_UPDATED } from '../constants/actionTypes';
export const messagesUpdated = createAction(MESSAGES_UPDATED, ({
  message,
  threadId
}) => ({
  message,
  threadId
}));