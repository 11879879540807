import { registerQuery, registerMutation } from 'data-fetching-client';
import { fetchAgentStatus, updateAwayMode as updateAwayModeClient } from '../clients/agentStatusClient';
export const GET_AGENT_STATUS = registerQuery({
  fieldName: 'agentStatus',
  args: ['workspace'],
  fetcher: ({
    workspace
  }) => fetchAgentStatus(workspace)
});
export const UPDATE_AWAY_MODE = registerMutation({
  fieldName: 'awayMode',
  args: ['awayMode', 'workspace'],
  fetcher({
    awayMode,
    workspace
  }) {
    return updateAwayModeClient(awayMode, workspace);
  }
});