/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */

const DELAY_TIME_MS = 1000;
/**
 * Create a wrapper promise help resolving promises only after a set delay time.
 * @param {Promise} promise
 * @param {number} delayTime
 * @returns {Promise} Wrapper promise object
 */
export const createTimeoutPromise = (promise, delayTime = DELAY_TIME_MS) => {
  const startTime = Date.now();
  return new Promise((resolve, reject) => {
    return promise.then(result => ({
      result
    })).catch(error => ({
      error
    })).then(({
      result,
      error
    }) => {
      const timeElapsed = Date.now() - startTime;
      const remainingDelayTime = delayTime - timeElapsed < 0 ? 0 : delayTime - timeElapsed;
      setTimeout(() => {
        if (result !== undefined) {
          resolve(result);
        } else {
          reject(error);
        }
      }, remainingDelayTime);
    });
  });
};