import { getData } from 'conversations-async-data/async-data/operators/getters';
import { isLoading } from 'conversations-async-data/async-data/operators/statusComparators';
import { useInboxes } from 'conversations-thread-data/inboxes/public/hooks';
import { getAssigneeId, getOriginalGenericChannelId, getAssociatedTicketIds, getCrmConversationId, getInboxId } from 'conversations-thread-data/thread-details/public/operators';
import { useThreadDetails } from '../../thread-details-feature/public/hooks/useThreadDetails';
import { getCurrentThreadId } from 'conversations-thread-data/thread-details/public/selectors';
import { useSelector } from 'react-redux';
import { hasHelpDeskAccess } from 'conversations-thread-data/auth/public/selectors';
export const useConversationInfo = () => {
  const userHasHelpDeskAccess = useSelector(hasHelpDeskAccess);
  const threadId = useSelector(getCurrentThreadId);
  const {
    asyncThreadDetails
  } = useThreadDetails({
    deferred: !threadId,
    threadId: threadId ? `${threadId}` : null
  });
  const threadDetails = getData(asyncThreadDetails);
  const assigneeId = getAssigneeId(threadDetails);
  const currentInboxId = getInboxId(threadDetails);
  const conversationId = getCrmConversationId(threadDetails);
  const {
    asyncInboxes
  } = useInboxes(userHasHelpDeskAccess);
  const inboxData = getData(asyncInboxes);
  const genericChannelId = getOriginalGenericChannelId(threadDetails);
  const associatedTicketIds = getAssociatedTicketIds(threadDetails);
  const loadingConversationInfo = isLoading(asyncInboxes) || isLoading(asyncThreadDetails);
  return {
    assigneeId,
    associatedTicketIds,
    conversationId,
    currentInboxId,
    genericChannelId,
    inboxData,
    loadingConversationInfo,
    threadId
  };
};