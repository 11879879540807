import { fetchHistory } from './fetchHistory';

/**
 * Fetches previous history for a given channel and emits
 * each item individually via the onMessage handler. Attaches
 * publishContext to express that the messages being published
 * are not live.
 */
export function playbackMessages({
  channel,
  onMessage,
  onPlayback
}) {
  return new Promise((resolve, reject) => {
    fetchHistory(channel, {
      direction: 'backwards',
      untilAttach: true
    }).then(historyItems => {
      const publishContext = {
        playback: true
      };
      const orderedHistoryItems = [...historyItems].reverse();
      if (onPlayback) {
        onPlayback(orderedHistoryItems);
      } else if (onMessage) {
        orderedHistoryItems.forEach(item => onMessage(item, publishContext));
      }
      resolve();
    }).catch(reject);
  });
}