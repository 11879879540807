import PortalIdParser from 'PortalIdParser';
import { getFullUrl } from 'hubspot-url-utils';
const baseHubSpotUrl = getFullUrl('app');
export const getSnippetsUrl = portalId => {
  return `${baseHubSpotUrl}/snippets/${portalId}`;
};
export const getNewSnippetsUrl = portalId => {
  return `${baseHubSpotUrl}/snippets/${portalId}/new`;
};
export const getDocumentsUrl = () => {
  const portalId = PortalIdParser.get();
  return `${baseHubSpotUrl}/presentations/${portalId}`;
};
export const getKnowledgeUrl = portalId => {
  return `${baseHubSpotUrl}/knowledge/${portalId}`;
};
export const getPaymentUrl = portalId => {
  return `${baseHubSpotUrl}/payment-links/${portalId}`;
};
export const getMeetingsUrl = () => `${baseHubSpotUrl}/meetings/${PortalIdParser.get()}`;
export const getInvoicesUrl = () => `${baseHubSpotUrl}/contacts/${PortalIdParser.get()}/objects/0-53/views/all/list`;

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
// @ts-expect-error ts-migrate(2580) FIXME: Cannot find name 'module'. Do you need to install ... Remove this comment to see the full error message
if (!!module && !!module.exports) {
  // @ts-expect-error ts-migrate(2580) FIXME: Cannot find name 'module'. Do you need to install ... Remove this comment to see the full error message
  module.exports.default = Object.assign({}, module.exports);
}