// TODO: move to composer-data

import { hasRecipientsWithoutPermission } from 'conversations-thread-data/contact-permissions/public/operators';
// TODO: move to composer-data
import invariant from 'conversations-thread-data/utils/invariant';
export const contactPermission = ({
  reply,
  contactPermissions
}) => {
  invariant(Boolean(reply), 'reply is required for contactPermission disabling strategy');
  return hasRecipientsWithoutPermission(reply, contactPermissions) ? {
    disabled: true,
    sendDisabledReason: 'NO_CONTACT_PERMISSIONS'
  } : null;
};