import { FALLBACK_ICON } from 'conversations-message-history/constants/sourceIcons';
import { getDisplaySubjectLine } from '../getDisplaySubjectLine';
import { isThreadListMember } from '../thread-list-member/isThreadListMember';
import { getAttachmentTypes, getGenericChannel, getIsComment, getIsForwardedEmail, getPreview, getVid, getVisitor, getLatestMessageTimestamp, getLatestReceivedTimestamp, getLatestSentTimestamp } from '../thread-list-member/threadListMemberGetters';
import { isInboxThreadListMember } from './isInboxViewMember';
import { getAssociatedContact, getAssociatedVid, getSource, getAssociatedAttachments, getIsForwarded, getTextPreview, getLatestMessageAccess } from './viewMemberGetters';
export function getViewMemberProps(viewMember) {
  var _viewMember$subject;
  const noSourceIcon = isInboxThreadListMember(viewMember) ? FALLBACK_ICON : 'databases';
  if (isThreadListMember(viewMember)) {
    //TLM props are extracted here still until inbox no longer relies on the type
    const associatedContact = getVisitor(viewMember);
    const vid = getVid(viewMember);
    const source = getGenericChannel(viewMember);
    const preview = getPreview(viewMember);
    const subject = getDisplaySubjectLine(viewMember);
    const isComment = getIsComment(viewMember);
    const attachmentTypes = getAttachmentTypes(viewMember);
    const isForwarded = getIsForwardedEmail(viewMember);
    const latestMessageTimestamp = getLatestMessageTimestamp(viewMember);
    const latestReceivedTimestamp = getLatestReceivedTimestamp(viewMember);
    const latestSentTimestamp = getLatestSentTimestamp(viewMember);
    return {
      associatedContact,
      vid,
      source,
      preview,
      subject,
      noSourceIcon,
      isComment,
      attachmentTypes,
      isForwarded,
      latestMessageTimestamp,
      latestReceivedTimestamp,
      latestSentTimestamp
    };
  }
  const associatedContact = getAssociatedContact(viewMember);
  const vid = getAssociatedVid(viewMember);
  const source = getSource(viewMember);
  const preview = getTextPreview(viewMember);
  const subject = (_viewMember$subject = viewMember.subject) !== null && _viewMember$subject !== void 0 ? _viewMember$subject : null;
  const isComment = viewMember.latestMessageThreadComment;
  const latestMessageAccess = getLatestMessageAccess(viewMember);
  const attachmentTypes = getAssociatedAttachments(viewMember);
  const isForwarded = getIsForwarded(viewMember);
  const latestMessageTimestamp = viewMember.latestMessageTimestamp;
  const latestReceivedTimestamp = viewMember.latestReceivedTimestamp;
  const latestSentTimestamp = viewMember.latestSentTimestamp;
  return {
    associatedContact,
    vid,
    source,
    preview,
    subject,
    noSourceIcon,
    isComment,
    latestMessageAccess,
    attachmentTypes,
    isForwarded,
    latestMessageTimestamp,
    latestReceivedTimestamp,
    latestSentTimestamp
  };
}