export let CallWidgetStates;
(function (CallWidgetStates) {
  CallWidgetStates["UNINITIALIZED"] = "UNINITIALIZED";
  CallWidgetStates["LOADING"] = "LOADING";
  CallWidgetStates["READY"] = "READY";
  CallWidgetStates["INITIALIZING_OUTBOUND_CALL"] = "INITIALIZING_OUTBOUND_CALL";
  CallWidgetStates["INCOMING_INBOUND_CALL"] = "INCOMING_INBOUND_CALL";
  CallWidgetStates["RINGING"] = "RINGING";
  CallWidgetStates["ANSWERED"] = "ANSWERED";
  CallWidgetStates["ENDING"] = "ENDING";
  CallWidgetStates["ENDED"] = "ENDED";
  CallWidgetStates["SYNC_TIMEOUT"] = "SYNC_TIMEOUT";
})(CallWidgetStates || (CallWidgetStates = {}));
export const UNINITIALIZED = CallWidgetStates.UNINITIALIZED;
export const LOADING = CallWidgetStates.LOADING;
export const READY = CallWidgetStates.READY;
export const INITIALIZING_OUTBOUND_CALL = CallWidgetStates.INITIALIZING_OUTBOUND_CALL;
export const INCOMING_INBOUND_CALL = CallWidgetStates.INCOMING_INBOUND_CALL;
export const RINGING = CallWidgetStates.RINGING;
export const ANSWERED = CallWidgetStates.ANSWERED;
export const ENDING = CallWidgetStates.ENDING;
export const ENDED = CallWidgetStates.ENDED;
export const SYNC_TIMEOUT = CallWidgetStates.SYNC_TIMEOUT;