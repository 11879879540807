import { getInstanceDeliveryIdentifier, getId } from './channelInstanceGetters';
import { getDeliveryIdentifierValue } from 'conversations-message-history/common-message-format/operators/deliveryIdentifierGetters';
export const getChannelInstanceIdByDeliveryIdentifier = ({
  deliveryIdentifierValue,
  channelInstances
}) => {
  const firstMatchingInstance = channelInstances.find(instance => {
    const deliveryIdentifier = getInstanceDeliveryIdentifier(instance);
    return getDeliveryIdentifierValue(deliveryIdentifier) === deliveryIdentifierValue;
  });
  return firstMatchingInstance ? getId(firstMatchingInstance) : null;
};