/**
 * File mostly copied from settings-ui-user-record https://git.hubteam.com/HubSpot/settings-ui-user-record/blob/ad0964c871c56027f6155caa82281bd0cf42ee55/settings-ui-user-record/static/js/constants/CrmProperties.ts
 */

export const USER_OBJECT_TYPE_ID = '0-115';
export const HS_CREATEDDATE = 'hs_createdate';
export const HS_LASTMODIFIEDDATE = 'hs_lastmodifieddate';
export const HS_LAST_ACTIVITY_TIME = 'hs_last_activity_time';
export const HS_CREATED_BY_USER_ID = 'hs_created_by_user_id';
export const HS_UPDATED_BY_USER_ID = 'hs_updated_by_user_id';
export const HS_INTERNAL_USER_ID = 'hs_internal_user_id';
export const HS_JOB_TITLE = 'hs_job_title';
export const HS_ADDITIONAL_PHONE = 'hs_additional_phone';
export const HS_MAIN_PHONE = 'hs_main_phone';
export const HS_CALENDAR_SYNC_ENABLED = 'hs_calendar_sync_enabled';
export const HS_CALENDAR_CONNECTION_STATUS = 'hs_calendar_connection_status';
export const HS_MEETINGS_ENABLED = 'hs_meetings_enabled';
export const HS_WORKING_HOURS = 'hs_working_hours';
export const HS_STANDARD_TIME_ZONE = 'hs_standard_time_zone';
export const HS_INVITE_STATUS = 'hs_invite_status';
export const HS_DEACTIVATED = 'hs_deactivated';
export const HS_HUBSPOT_JITA_USER = 'hs_hubspot_jita_user';
export const HS_IS_BOT = 'hs_is_bot';
export const HS_AVAILABILITY_STATUS = 'hs_availability_status';
export const HS_OUT_OF_OFFICE_HOURS = 'hs_out_of_office_hours';
export const CONTACTS_TYPE_ID = '0-1';
export const COMPANIES_TYPE_ID = '0-2';
export const DEALS_TYPE_ID = '0-3';
export const TICKETS_TYPE_ID = '0-5';
export const DAYS = ['MONDAY_TO_FRIDAY', 'SATURDAY_SUNDAY', 'EVERY_DAY', 'SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];