import { useState, useCallback } from 'react';
import { addSuccess, addDanger
// @ts-expect-error untyped-module
} from 'customer-data-ui-utilities/alerts/Alerts';
import QuotesLogger from 'quotes-ui-lib/tracking/quotesLogger';
import { updatePublishedQuote } from 'quotes-ui-lib/api/QuotesApi';
const useArchiveQuote = (quoteId, trackingLocation, onArchiveSuccess) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const archiveQuote = useCallback(() => {
    QuotesLogger.log('updatePublishedQuote', {
      action: 'Archive quote',
      location: trackingLocation
    });
    setIsLoading(true);
    updatePublishedQuote(quoteId, 'hs_archived', 'true').then(() => {
      try {
        if (onArchiveSuccess) {
          onArchiveSuccess();
        }
      } catch (error) {
        console.error(error);
      }
      addSuccess('quotesUiLib.archiveQuoteModal.success');
    }).catch(() => {
      addDanger('quotesUiLib.archiveQuoteModal.failure');
    }).finally(() => {
      setIsModalOpen(false);
      setIsLoading(false);
    });
  }, [quoteId, trackingLocation, onArchiveSuccess]);
  return [isModalOpen, setIsModalOpen, isLoading, archiveQuote];
};
export default useArchiveQuote;