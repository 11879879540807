import { isBot, isHuman } from '../operators/assignees';
export const checkIsHuman = assignee => isHuman(assignee);
export const checkIsBot = assignee => isBot(assignee);
const getAssigneeId = assignee => {
  let id = 0;
  if (assignee) {
    if (checkIsBot(assignee)) {
      ({
        id
      } = assignee.agent);
    } else {
      // we know that an agent can either be a HubSpotUser or Bot
      // at this point we know it's not a Bot, so its a HubSpotUser
      id = assignee.agent.userId;
    }
  }
  return id;
};
export const checkUserInAssignees = (assignees, userId) => assignees.some(assignee => getAssigneeId(assignee) === userId);