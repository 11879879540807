/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { useCallback, useEffect, useRef } from 'react';
import { useAsyncData } from 'calling-lifecycle-internal/utils/hooks/useAsyncData';
import { AsyncStatusType } from 'calling-lifecycle-internal/utils/types/AsyncData';
import { fetchNextBestActions } from '../clients/NextBestActionsAPI';
export const useNextBestActions = ({
  callId,
  callSummary,
  initialNextBestActions,
  isUngatedForNextBestActions,
  shouldShowNextBestActions
}) => {
  const {
    data,
    status,
    updateAsyncData
  } = useAsyncData(initialNextBestActions || []);
  const isMounted = useRef(false);
  const fetchData = useCallback(() => {
    updateAsyncData({
      status: AsyncStatusType.STARTED
    });
    return fetchNextBestActions(callId).then(result => {
      if (isMounted.current) {
        if (result.error) {
          updateAsyncData({
            data: [],
            status: AsyncStatusType.FAILED
          });
        } else {
          updateAsyncData({
            data: result.result || [],
            status: AsyncStatusType.SUCCEEDED
          });
        }
      }
      return result;
    }).catch(() => {
      if (isMounted.current) {
        updateAsyncData({
          data: [],
          status: AsyncStatusType.FAILED
        });
      }
    });
  }, [updateAsyncData, callId]);
  useEffect(() => {
    if (initialNextBestActions) {
      updateAsyncData({
        data: initialNextBestActions,
        status: AsyncStatusType.SUCCEEDED
      });
    }
  }, [initialNextBestActions, updateAsyncData]);
  useEffect(() => {
    isMounted.current = true;
    if (!initialNextBestActions && callSummary && status === AsyncStatusType.UNINITIALIZED && isUngatedForNextBestActions && shouldShowNextBestActions) {
      fetchData();
    }
    return () => {
      isMounted.current = false;
    };
  }, [callSummary, status, isMounted, fetchData, initialNextBestActions, isUngatedForNextBestActions, shouldShowNextBestActions]);
  return {
    nextBestActions: data,
    nextBestActionsLoadingStatus: status,
    refetchNextBestActions: fetchData
  };
};