import { useEffect, useState, useRef } from 'react';
import { useDefaultPipelineInformation, useSavedPipelineInformation } from './usePrefilledPipelineInformation';
export const usePipelineInformation = ({
  ticketId,
  shouldDefaultPipelineStageToClose
}) => {
  const [pipelineInformation, setPipelineInformation] = useState({});
  const {
    defaultPipelineId,
    defaultStatusId,
    defaultLoading
  } = useDefaultPipelineInformation();
  const {
    savedPipelineId,
    savedStatusId,
    savedPipelineStages,
    ticketLoading
  } = useSavedPipelineInformation(ticketId);
  const prevSavedStatusIdRef = useRef(savedStatusId);
  const firstClosedState = savedPipelineStages === null || savedPipelineStages === void 0 ? void 0 : savedPipelineStages.find(stage => stage.metadata.isClosed === 'true');
  useEffect(() => {
    const prevSavedStatusId = prevSavedStatusIdRef.current;
    prevSavedStatusIdRef.current = savedStatusId;
    if (shouldDefaultPipelineStageToClose && firstClosedState && savedPipelineId && prevSavedStatusId === savedStatusId && !ticketLoading) {
      setPipelineInformation({
        hs_pipeline: savedPipelineId,
        hs_pipeline_stage: firstClosedState.stageId
      });
      return;
    }
    if ((savedStatusId || savedPipelineId) && !ticketLoading) {
      setPipelineInformation({
        hs_pipeline: savedPipelineId,
        hs_pipeline_stage: savedStatusId
      });
      return;
    }
    if ((defaultStatusId || defaultPipelineId) && !defaultLoading) {
      setPipelineInformation({
        hs_pipeline: defaultPipelineId,
        hs_pipeline_stage: defaultStatusId
      });
      return;
    }
  }, [defaultPipelineId, defaultStatusId, savedPipelineId, savedStatusId, ticketLoading, defaultLoading, firstClosedState, shouldDefaultPipelineStageToClose]);
  return {
    pipelineInformation,
    setPipelineInformation
  };
};