import { createAction, createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { threadSeen } from 'find-and-filter-data/view-members-data/protected';
import { buildViewMembersPage, mergeIncomingViewMembersPage } from 'find-and-filter-data/view-members-data/public';
import { ViewMembersPage } from 'find-and-filter-data/view-members-schema/public';
import { AsyncStatus } from '../../common/public';
import { doesThreadMatchFilters } from './doesThreadMatchFiltersService';
import { fetchFilteredThreadListMembers } from './fetchFilteredThreadListMembersService';
import { fetchFilteredViewMembersService } from './fetchFilteredViewMembersService';
import { actions } from './filterValuesSlice';
import { DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER } from './useSearchAndFilterViewMembers';
const initialState = {
  data: new ViewMembersPage(),
  loading: false,
  status: AsyncStatus.UNINITIALIZED
};
export const checkThreadMembership = createAsyncThunk('filterResults/checkThreadMembership', ({
  filterValues,
  inboxId,
  threadId
}) => doesThreadMatchFilters({
  filterValues,
  inboxId,
  threadId
}));
export const fetchSearchAndFilterResults = createAsyncThunk('filterResults/fetchFilterResults', ({
  filterKey,
  offsetTimestamp = null,
  offsetId = null,
  inboxId
}) => fetchFilteredThreadListMembers({
  filterKey,
  offsetTimestamp,
  offsetId,
  inboxId
}));
export const fetchSearchAndFilterViewMembers = createAsyncThunk('filterResults/fetchSearchAndFilterViewMembers', ({
  filterGroups,
  inboxId,
  limit,
  offsetTimestamp,
  offsetId,
  searchQuery,
  sortDirection,
  viewId
}) => fetchFilteredViewMembersService(Object.assign({}, viewId !== DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER && {
  customViewId: +viewId
}, {
  filterGroups,
  inboxId,
  limit,
  offsetTimestamp,
  offsetId,
  searchQuery,
  sortDirection
})));
export const clearFilterResults = createAction('filterResults/clearFilterResults');
export const filterResultsSlice = createSlice({
  initialState,
  name: 'filterResults',
  reducers: {},
  extraReducers: builder => {
    builder.addCase(checkThreadMembership.rejected, (state, {
      meta
    }) => {
      const threadId = meta.arg.threadId;
      if (state.data.indexedMembers.has(`${threadId}`)) {
        state.data = state.data.update('indexedMembers', members => members.delete(`${threadId}`)).update('totalCount', count => count - 1);
      }
    }).addCase(threadSeen, (state, {
      payload
    }) => {
      state.data = state.data.updateIn(['indexedMembers', payload.threadId], tlm => {
        if (tlm) {
          return tlm.set('seen', payload.seen);
        }
        return tlm;
      });
    }).addCase(fetchSearchAndFilterResults.pending, (state, {
      meta
    }) => Object.assign({}, state, {
      latestRequestId: meta.requestId,
      loading: true,
      status: meta.arg.offsetId && meta.arg.offsetId > 0 ? AsyncStatus.PAGINATED_FETCH_LOADING : AsyncStatus.INITIAL_FETCH_LOADING
    })).addCase(fetchSearchAndFilterResults.rejected, (state, {
      meta
    }) => {
      //If this request is not the latest request, ignore it.
      if (state.latestRequestId !== meta.requestId) return state;
      return Object.assign({}, state, {
        loading: false,
        status: AsyncStatus.FAILED
      });
    }).addCase(fetchSearchAndFilterResults.fulfilled, (state, {
      meta,
      payload
    }) => {
      //If this request is not the latest request, ignore it.
      if (state.latestRequestId !== meta.requestId) return state;

      //This logic is almost identical to the logic in viewMembersSlice. Consider extracting duplicate
      //functionality once this has been moved to the f&f library.
      if (meta.arg.offsetId && meta.arg.offsetId > 0) {
        const oldPage = state.data;
        const newPage = buildViewMembersPage(payload).set('totalCount', oldPage.totalCount);
        const updatedPage = mergeIncomingViewMembersPage({
          oldPage,
          newPage,
          totalCount: payload.totalCount
        });
        state.data = updatedPage;
      } else {
        state.data = buildViewMembersPage(payload);
      }
      state.loading = false;
      state.status = AsyncStatus.SUCCEEDED;
    }).addMatcher(isAnyOf(clearFilterResults, actions.clearSearchAndFilterValues), () => initialState);
  }
});
export default filterResultsSlice.reducer;