import get from 'transmute/get';
import { getHsPipelineId } from '../operators/ticketGetters';
export const getStagesForTicket = ({
  ticket,
  ticketPipelines
}) => {
  const pipelineId = getHsPipelineId(ticket);
  ticketPipelines = ticketPipelines || [];
  const pipelineForTicket = ticketPipelines.find(ticketPipeline => {
    return get('pipelineId', ticketPipeline) === pipelineId;
  }) || null;
  if (pipelineForTicket) {
    return get('stages', pipelineForTicket);
  }
  return null;
};