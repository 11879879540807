import http from 'conversations-http/clients/http';
export const sendChatTranscriptClient = ({
  threadId,
  recipient,
  subject
}) => http.put(`livechat/v1/transcript/send/thread/${threadId}/manual`, {
  data: {
    recipient,
    subject
  }
});