import get from 'transmute/get';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { useSelector } from 'react-redux';
import { getThreadListMemberByThreadId } from '../../thread-list-member/selectors/getThreadListMemberByThreadId';
import { getAssociatedCompanyId } from 'conversations-inbox-lib/contacts/public/operators';
import { useTicketData } from './useTicketData';
import { useTicketSources } from './useTicketSources';
import { useOwnerByUserId } from './useOwnerByUserId';
import { getUserId } from '../../selectors/userSelectors/getUserId';
import { isLoading } from 'conversations-async-data/async-data/operators/statusComparators';
import { useMemo } from 'react';
import { getAssigneeId } from 'find-and-filter-data/view-members-schema/protected';
const MAX_PROPERTY_CHARACTER = 65536;
const truncateContent = (content, limit) => {
  if (content && content.length > limit) {
    return content.substring(0, limit);
  }
  return content;
};
export const useTicketCreatorProperties = ({
  contact,
  threadId
}) => {
  const currentUserId = useSelector(getUserId);
  const threadDetails = useSelector(state => getThreadListMemberByThreadId(state, {
    threadId
  }));
  const threadAssigneeUserId = getAssigneeId(threadDetails);

  //This hook is currently being provided with the user ID of the selected
  //thread's assignee, or the current user's ID in the case where that thread
  //is unassigned. This is to avoid the client erroring when trying to fetch
  //by an undefined user ID. Adding an owner ID to a record that is populated by
  //the back end will allow us to get rid of this hook and therefore the
  //workaround as well.
  const {
    ownerId,
    ownerIsLoading
  } = useOwnerByUserId({
    userId: threadAssigneeUserId || currentUserId
  });
  const {
    asyncTicketData
  } = useTicketData({
    threadId
  });
  const {
    validSourceTypes,
    asyncTicketSources
  } = useTicketSources();
  const propertiesLoading = isLoading(asyncTicketData) || isLoading(asyncTicketSources) || ownerIsLoading;
  const companyId = getAssociatedCompanyId(contact) || '';
  const preferredDefaultSource = get('source', getData(asyncTicketData));
  const sourceType = validSourceTypes && validSourceTypes.includes(preferredDefaultSource) ? preferredDefaultSource : '';
  return useMemo(() => {
    const content = truncateContent(get('ticketContent', getData(asyncTicketData)), MAX_PROPERTY_CHARACTER);
    const subject = get('ticketSubject', getData(asyncTicketData));
    if (!validSourceTypes) {
      return {
        propertiesLoading,
        ticketCreatorProperties: {
          hs_conversations_originating_thread_id: threadId
        }
      };
    }
    return {
      propertiesLoading,
      ticketCreatorProperties: {
        associatedcompanyid: companyId,
        content,
        subject,
        source_type: sourceType,
        hs_conversations_originating_thread_id: threadId,
        hubspot_owner_id: threadAssigneeUserId ? ownerId : null
      }
    };
  }, [propertiesLoading, threadId, threadAssigneeUserId, ownerId, companyId, asyncTicketData, sourceType, validSourceTypes]);
};