import { useState, useCallback } from 'react';
import QuotesLogger from 'quotes-ui-lib/tracking/quotesLogger';
import { updatePublishedQuote } from 'quotes-ui-lib/api/QuotesApi';
import { addSuccess, addDanger
// @ts-expect-error untyped module
} from 'customer-data-ui-utilities/alerts/Alerts';
const useMarkSigned = (quoteId, trackingLocation, onSuccess) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const markSigned = useCallback(() => {
    QuotesLogger.log('updatePublishedQuote', {
      action: 'Mark as manually signed',
      location: trackingLocation
    });
    setIsLoading(true);
    return updatePublishedQuote(quoteId, 'hs_manually_signed', 'true').then(() => {
      addSuccess('quotesUiLib.markSignedModal.success');
      onSuccess();
    }).catch(() => {
      addDanger('quotesUiLib.markSignedModal.failure');
    }).finally(() => {
      setIsModalOpen(false);
      setIsLoading(false);
    });
  }, [quoteId, trackingLocation, onSuccess]);
  return [isModalOpen, setIsModalOpen, isLoading, markSigned];
};
export default useMarkSigned;