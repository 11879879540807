import get from 'transmute/get';
import set from 'transmute/set';
const getProperty = property => view => get(property, view);

/**
 * @description Returns the internal thread list ID
 */
export const getThreadListId = getProperty('threadListId');

/**
 * @description Returns the type of thread list
 */
export const getType = getProperty('threadListType');

/**
 * @description Returns the internal name for this ThreadList
 */
export const getInternalName = getProperty('internalName');

/**
 * @description Returns the timestamp to order view members based on.
 */
export const getTimestampOrderType = getProperty('orderType');
export const setInternalName = set('internalName');
export const setThreadListId = set('threadListId');