'use es6';

import { BLOCK, SPAM } from '../constants/filterTypes';
import { getFilterType, getIsFiltered } from './filteredChangeMessageGetters';
const isMessageFiltered = message => Boolean(getIsFiltered(message));
const isMessageFilterType = (message, filterType) => {
  const messageFilterType = getFilterType(message);
  return messageFilterType && messageFilterType === filterType;
};
export const isBlockMessage = message => isMessageFiltered(message) && isMessageFilterType(message, BLOCK);
export const isUnblockMessage = message => !isMessageFiltered(message) && isMessageFilterType(message, BLOCK);
export const isMarkAsSpamMessage = message => isMessageFiltered(message) && isMessageFilterType(message, SPAM);
export const isUnmarkAsSpamMessage = message => !isMessageFiltered(message) && isMessageFilterType(message, SPAM);