import { List, fromJS } from 'immutable';
import { USERS_AND_TEAMS } from '../constants/RoutingTypes';
import RoutingRuleBase from './RoutingRuleBase';
class UsersAndTeamsRoutingRules extends RoutingRuleBase({
  '@type': USERS_AND_TEAMS,
  userIds: List(),
  teamIds: List()
}, 'UsersAndTeamsRoutingRules') {
  constructor(options) {
    super(fromJS(options || {}));
  }
}
export const buildUsersAndTeamsRoutingRules = options => new UsersAndTeamsRoutingRules(options);
export default UsersAndTeamsRoutingRules;