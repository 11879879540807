import http from 'conversations-http/clients/http';
import { parseViewId } from 'find-and-filter-data/views-schema/public';
const DEFAULT_LIMIT = 100;
export const fetchFromOmnibusClient = ({
  inboxId,
  limit = DEFAULT_LIMIT,
  silent = false,
  sortDirection,
  threadId,
  threadStatus = 'STARTED',
  viewId,
  forcedFailedUpstreams
}) => {
  const {
    customViewId,
    threadListId,
    threadListType
  } = viewId ? parseViewId(viewId) : {
    customViewId: null,
    threadListId: null,
    threadListType: null
  };
  const threadList = threadListId && threadListType ? {
    threadListId,
    threadListType
  } : null;
  return http.put(`messages/v2/inbox/omnibus`, {
    data: {
      customViewId,
      inboxId,
      limit,
      silent,
      sortDirection,
      threadId,
      threadList,
      threadStatus,
      serviceType: 'FANOUT',
      forcedFailedUpstreams //mocks out failed upstrams for testing locally only
    },
    query: {
      historyLimit: 30,
      membersLimit: 50,
      maxPreviewLength: 200
    }
  });
};