import enviro from 'enviro';

/*
 Ably Log Level

 Local Storage Override:  `ABLY_LOG_LEVEL`

 Note: By default in production we use DEBUG logging.  We do not print this to the console.
 Instead, it is collected and sent to sentry if an error occurs.

 level: a number controlling the verbosity of the output. Valid values are:
 *  0 (no logs)
 *  1 (errors only)
 *  2 (errors plus connection and channel state changes)
 *  3 (high-level debug output)
 *  4 (full debug output)
*/
const ABLY_LOG_LEVEL = 'ABLY_LOG_LEVEL';
const ERRORS_AND_STATE_CHANGES = 2;
const DEBUG = 4;
export const getLogLevel = () => {
  try {
    const logLevel = window.localStorage.getItem(ABLY_LOG_LEVEL);
    const parsedLevel = parseInt(logLevel !== null ? logLevel : 'NaN', 10);
    if (typeof parsedLevel === 'number' && !isNaN(parsedLevel)) {
      // eslint-disable-next-line no-console
      console.warn(`[OVERRIDE] ABLY_LOG_LEVEL = ${parsedLevel}`);
      return parsedLevel;
    }
  } catch (e) {
    /* do nothing */
  }
  return enviro.isQa() || !enviro.deployed() ? ERRORS_AND_STATE_CHANGES : DEBUG;
};