export const getMinutesUsage = callsV1SettingsUsageData => {
  if (!callsV1SettingsUsageData) {
    return null;
  }
  return callsV1SettingsUsageData.minutesUsage;
};
export const getNumbersUsage = callsV1SettingsUsageData => {
  if (!callsV1SettingsUsageData) {
    return null;
  }
  return callsV1SettingsUsageData.hubspotPurchasedPhoneNumberUsage;
};