import http from 'conversations-http/clients/http';
import { registerQuery } from 'data-fetching-client';
const THREAD_FIELD_NAME = 'thread';
export const GET_THREAD_META_DATA = registerQuery({
  fieldName: THREAD_FIELD_NAME,
  args: ['threadId'],
  fetcher({
    threadId,
    allowDeleted = false
  }) {
    return http.get(`conversations-threads/v1/threads/${threadId}`, {
      query: {
        allowDeleted
      }
    });
  }
});