import get from 'transmute/get';
import { areEmailAddressesEqual } from 'conversations-thread-data/connected-accounts/public';
// @ts-expect-error module not typed

export const areEmailAddressRecordsEqual = (recordA, recordB) => {
  const addressA = get('address', recordA);
  const addressB = get('address', recordB);
  const sendAsAddressA = get('sendAsAddress', recordA);
  const sendAsAddressB = get('sendAsAddress', recordB);
  return areEmailAddressesEqual(addressA, addressB) || areEmailAddressesEqual(addressA, sendAsAddressB) || areEmailAddressesEqual(sendAsAddressA, addressB) || areEmailAddressesEqual(sendAsAddressA, sendAsAddressB);
};