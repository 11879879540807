import Raven from 'raven-js';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
export const clearPerformanceMarksAndMeasures = marker => {
  performance.clearMarks(`${marker}-start`);
  performance.clearMarks(`${marker}-stop`);
  performance.clearMeasures(`${marker}-switch`);
};

/**
 *
 * @description This function takes in a marker name and checks to see if that
 * marker appended with '-start' has been used. If so, it calculates the duration
 * between when that marker was placed and now, reports it to LogFetch
 * and then removes the corresponding marks and measures.
 * @param {String} marker The name of the marker. eg conversations-thread-list-row
 */
export const gatherPerformanceMetrics = marker => {
  try {
    const startMarkerEntries = performance.getEntriesByName(`${marker}-start`);
    if (startMarkerEntries && startMarkerEntries.length) {
      const stopMarkerEntries = performance.getEntriesByName(`${marker}-stop`);
      if (stopMarkerEntries && stopMarkerEntries.length === 0) performance.mark(`${marker}-stop`);
      performance.measure(`${marker}-switch`, `${marker}-start`, `${marker}-stop`);
      const duration = performance.getEntriesByName(`${marker}-switch`)[0].duration;
      Raven.setExtraContext({
        [`${marker}-switch`]: duration
      });
    }
  } catch (error) {
    reportError({
      error: new Error(`error recording performance metrics for ${marker}`),
      extra: {
        error
      }
    });
  } finally {
    clearPerformanceMarksAndMeasures(marker);
  }
};