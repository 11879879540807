import { List as ImmutableList } from 'immutable';
import { getRecipientFieldName, getRecipientsFields } from 'conversations-thread-data/channel-configuration/public/operators';
import { getGenericChannelId, getRecipients } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getRecipientField } from 'conversations-message-history/common-message-format/operators/recipientGetters';
import { setAttachments, setGenericChannelId, setRecipients, setSenders, setChannelInstanceId } from 'conversations-message-history/common-message-format/operators/commonMessageSetters';
import pipe from 'transmute/pipe';
import { EMAIL_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
import EmailMetadata from 'conversations-message-history/common-message-format/records/EmailMetadata';
import { identifyGenericChannelIdFromRecipients } from '../operators/identifyGenericChannelIdFromRecipients';
import { buildRecipientsFromIdentifiers } from '../operators/buildRecipientsFromIdentifiers';
import { getCommonMessage } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
import { setCommonMessage, setAttachments as setCommonReplyAttachments
// @ts-expect-error module not typed
} from 'conversations-thread-data/common-reply/public/operators/commonReplySetters';
function buildEmailAttachmentsWithTo({
  identifiers
}) {
  return ImmutableList([EmailMetadata({
    to: identifiers.map(identifier => identifier.deliveryIdentifierValue).filter(Boolean).toList()
  })]);
}
function getDefaultRecipientFieldNameForEditor(channelConfigurations, editorConfigKey) {
  const nextConfiguration = channelConfigurations && channelConfigurations.get(editorConfigKey);
  const nextRecipientsFields = nextConfiguration && getRecipientsFields(nextConfiguration);
  const nextDefaultRecipientFieldName = nextRecipientsFields && nextRecipientsFields[0] && getRecipientFieldName(nextRecipientsFields[0]);
  return nextDefaultRecipientFieldName;
}
export function getReplyWithNextRecipients({
  channelConfigurations,
  reply,
  fieldName,
  nextIdentifiers
}) {
  const commonMessage = getCommonMessage(reply);
  if (!commonMessage) return reply;
  const currentRecipients = getRecipients(commonMessage);
  const recipientsForOtherFields = currentRecipients.filter(recipient => getRecipientField(recipient) !== fieldName);
  const hasOtherRecipients = recipientsForOtherFields && recipientsForOtherFields.size;
  const nextChannelId = identifyGenericChannelIdFromRecipients(nextIdentifiers);
  // TODO: figure out how to check this - if/when this component is used inline in a thread will we need to handle this differently
  const isChangingChannel = getGenericChannelId(commonMessage) !== nextChannelId && (!fieldName || fieldName === 'TO');
  if (!isChangingChannel) {
    const recipientsFromIdentifiers = buildRecipientsFromIdentifiers(fieldName, nextIdentifiers);
    const nextRecipients = hasOtherRecipients ? recipientsForOtherFields.concat(recipientsFromIdentifiers).toList() : recipientsFromIdentifiers;
    return setCommonMessage(setRecipients(nextRecipients, commonMessage), reply);
  }
  const nextDefaultRecipientFieldName = getDefaultRecipientFieldNameForEditor(channelConfigurations, nextChannelId || 'UNSET');
  const nextRecipients = buildRecipientsFromIdentifiers(nextDefaultRecipientFieldName, nextIdentifiers);
  const nextAttachments = nextChannelId === EMAIL_GENERIC_CHANNEL_ID ? buildEmailAttachmentsWithTo({
    identifiers: nextIdentifiers
  }) : ImmutableList();
  const nextMessage = pipe(setRecipients(nextRecipients), setGenericChannelId(nextChannelId), setAttachments(nextAttachments), setSenders(ImmutableList()), setChannelInstanceId(null))(commonMessage);
  return pipe(setCommonReplyAttachments(ImmutableList()), setCommonMessage(nextMessage))(reply);
}