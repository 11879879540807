import http from 'conversations-http/clients/http';
import { UNKNOWN } from 'conversations-internal-schema/responders/constants/AgentStates';
import { registerQuery } from 'data-fetching-client';
export const convertAssignableHumanToAssignee = responder => ({
  agent: {
    email: responder.email || '',
    firstName: responder.firstName === undefined ? null : responder.firstName,
    lastName: responder.lastName === undefined ? null : responder.lastName,
    userId: responder.userId
  },
  agentState: responder.agentState ? responder.agentState : UNKNOWN,
  avatarUrl: responder.avatar === undefined ? null : responder.avatar,
  id: `${responder.userId}`,
  isAssignable: responder.assignable,
  activationStatus: responder.activationStatus
});
export const fetchCurrentAssigneeFromThread = ({
  threadId
}) => {
  return http.get(`cv/inbox/settings/v1/assignee-search/thread/${threadId}`).then(data => convertAssignableHumanToAssignee(data)).catch(err => {
    if (err.headers['x-hubspot-notfound']) {
      return Promise.resolve(null);
    } else {
      throw err;
    }
  });
};
export const FETCH_CURRENT_ASSIGNEE_QUERY_NAME = 'fetchCurrentAssignee';
export const FETCH_CURRENT_ASSIGNEE_QUERY = registerQuery({
  fieldName: FETCH_CURRENT_ASSIGNEE_QUERY_NAME,
  args: ['threadId'],
  fetcher: fetchCurrentAssigneeFromThread
});