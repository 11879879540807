import { useLocation } from 'react-router-dom';
import PortalIdParser from 'PortalIdParser';
import { getThreadFromPathname, getTicketFromPathname, getViewFromPathname } from '../utils/urlParsers';
import { navigateExternally } from '../utils/navigateExternally';
const getHelpDeskUrl = options => {
  const {
    threadId,
    ticketId,
    viewId
  } = options;
  const rootUrl = `/help-desk/${PortalIdParser.get()}`;
  const baseUrl = `${rootUrl}${viewId ? `/view/${viewId}` : ''}`;
  const threadPath = threadId ? `/thread/${threadId}` : '';
  const ticketPath = ticketId ? `/ticket/${ticketId}` : '';
  if (ticketPath) {
    return baseUrl + ticketPath + threadPath;
  } else if (!threadPath) {
    return baseUrl;
  } else {
    return baseUrl + threadPath;
  }
};
export const useRedirectToHelpDesk = () => {
  const location = useLocation();
  const threadId = getThreadFromPathname(location.pathname);
  const ticketId = getTicketFromPathname(location.pathname);
  const viewId = getViewFromPathname(location.pathname, true);
  const helpdeskUrl = getHelpDeskUrl({
    threadId,
    ticketId,
    viewId
  });
  navigateExternally(helpdeskUrl);
  return;
};