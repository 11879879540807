import { GET_AGENT_STATUS } from '../queries/agentStatusQueries';
export function getCachedAgentStatus(client, workspace) {
  const agentStatusCache = client.readQuery({
    query: GET_AGENT_STATUS,
    variables: {
      workspace: workspace || ''
    }
  });
  return agentStatusCache ? agentStatusCache.agentStatus : undefined;
}
export function updateCachedAgentStatus({
  agentStatus,
  client,
  workspace
}) {
  client.writeQuery({
    query: GET_AGENT_STATUS,
    data: {
      agentStatus
    },
    variables: {
      workspace: workspace || ''
    }
  });
}