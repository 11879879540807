// copied from: https://git.hubteam.com/HubSpot/ui-addon-conversations-settings/blob/3c89aee1836bb1efd278f39f1bb316d4f77c6c81/static/js/users-and-teams/operators/getTruncatedGroupOptions.ts

export const getTruncatedGroupOptions = (options, x) => {
  return options.map(group => {
    let paginatedGroupOptions = [];
    if (x > 0) {
      paginatedGroupOptions = group.options.slice(0, x);
      x -= group.options.length;
    }
    return Object.assign({}, group, {
      options: paginatedGroupOptions
    });
  });
};