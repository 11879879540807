import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"];
import { registerQuery, useQuery } from 'data-fetching-client';
import { getPropertyOptions } from '../api/userObject.api';
const userPropertyOptionsQuery = registerQuery({
  fieldName: 'objectPropertyOptions',
  args: ['propertyName'],
  fetcher: getPropertyOptions
});
const usePropertyOptions = propertyName => {
  const _useQuery = useQuery(userPropertyOptionsQuery, {
      variables: {
        propertyName
      }
    }),
    {
      data
    } = _useQuery,
    rest = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  return Object.assign({
    propertyOptions: data ? data.objectPropertyOptions.options : []
  }, rest);
};
export default usePropertyOptions;