export default function once(fn) {
  let didRun = false;
  let result;
  return function onced(...args) {
    if (!didRun) {
      didRun = true;
      result = fn(...args);
    }
    return result;
  };
}