import get from 'transmute/get';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { getFirstName } from 'conversations-internal-schema/sales-users/operators/salesUsersGetters';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { toOption } from 'conversations-internal-schema/sales-users/operators/toOption';
export const mapUsersToOptions = users => {
  return users.valueSeq().filter(user => {
    return !get('disabled', user);
  }).sortBy(user => {
    return getFirstName(user);
  }).map(toOption).toArray();
};