import I18n from 'I18n';
import Visitor from 'conversations-internal-schema/visitor/records/Visitor';
import { LatestMessageAccess } from './LatestMessageAccess';
import { isHelpDeskSearchViewMember, isHelpDeskViewMember } from './isHelpDeskViewMember';
import { isInboxViewMember } from './isInboxViewMember';
export const getAssociatedContact = viewMember => {
  if (isInboxViewMember(viewMember)) {
    return viewMember.visitor && Visitor(viewMember.visitor) || undefined;
  }
  if (!viewMember.associatedContacts.length) {
    return null;
  }
  return Visitor(viewMember.associatedContacts[0]);
};
export const getAssociatedVid = viewMember => {
  if (isInboxViewMember(viewMember)) {
    return viewMember.vid;
  }
  return viewMember.associatedContacts[0] && viewMember.associatedContacts[0].vid || null;
};
export const getSource = viewMember => {
  if (isInboxViewMember(viewMember)) {
    return viewMember.genericChannelId;
  }
  const genericChannelId = viewMember.objectPropertyMap['0-5']['hs_originating_generic_channel_id'];
  return genericChannelId ? +genericChannelId : null;
};
export const getAssociatedAttachments = viewMember => {
  if (isInboxViewMember(viewMember)) {
    return viewMember.attachmentTypes;
  }
  return null;
};
export const getIsForwarded = viewMember => {
  if (isInboxViewMember(viewMember)) {
    return viewMember.forwarded;
  }
  return false;
};
export const getTextPreview = viewMember => {
  if (isInboxViewMember(viewMember)) {
    return viewMember.text;
  }
  return viewMember.associatedConversations[0] ? viewMember.text : I18n.text('find-and-filter-components.preview-card.conversation-not-started');
};
export const getLatestMessageAccess = viewMember => {
  if (isInboxViewMember(viewMember)) {
    return LatestMessageAccess.ACCESS;
  }
  return viewMember.latestMessageAccess;
};
export const getHelpDeskViewMemberProps = (viewMember, property) => {
  if (isInboxViewMember(viewMember)) {
    return '';
  }
  return viewMember.objectPropertyMap['0-5'][property];
};
export const getHelpDeskViewMemberThreadIds = viewMember => {
  if (isInboxViewMember(viewMember)) {
    return [];
  }
  return viewMember.associatedConversations.map(conversation => conversation['@type'] === 'THREAD_KEY' ? conversation.threadId : undefined);
};
export const getPublicThreadChannel = viewMember => {
  const publicChannel = viewMember.pubSubChannels.find(channel => channel.type === 'THREAD_SHARED');
  return publicChannel ? publicChannel.name : null;
};
export const getPrivateThreadChannel = viewMember => {
  const privateChannel = viewMember.pubSubChannels.find(channel => channel.type === 'THREAD_PRIVATE');
  return privateChannel ? privateChannel.name : null;
};
export const getAssigneeAvatarUrl = viewMember => {
  if (isHelpDeskViewMember(viewMember) || isHelpDeskSearchViewMember(viewMember)) {
    var _viewMember$viewMembe;
    return (_viewMember$viewMembe = viewMember.viewMemberOwner) === null || _viewMember$viewMembe === void 0 ? void 0 : _viewMember$viewMembe.avatarUrl;
  }
};
export const getAssigneeFirstName = viewMember => {
  if (isHelpDeskViewMember(viewMember) || isHelpDeskSearchViewMember(viewMember)) {
    var _viewMember$viewMembe2;
    return (_viewMember$viewMembe2 = viewMember.viewMemberOwner) === null || _viewMember$viewMembe2 === void 0 ? void 0 : _viewMember$viewMembe2.firstName;
  }
};
export const getAssigneeLastName = viewMember => {
  if (isHelpDeskViewMember(viewMember) || isHelpDeskSearchViewMember(viewMember)) {
    var _viewMember$viewMembe3;
    return (_viewMember$viewMembe3 = viewMember.viewMemberOwner) === null || _viewMember$viewMembe3 === void 0 ? void 0 : _viewMember$viewMembe3.lastName;
  }
};
export const getIsHelpDeskOnboardingViewMember = viewMember => {
  if (isHelpDeskViewMember(viewMember) || isHelpDeskSearchViewMember(viewMember)) {
    const onBoardingProperty = viewMember.objectPropertyMap['0-5']['hs_help_desk_onboarding_ticket'];
    return onBoardingProperty === 'HELPDESK EXPERIMENT TEST TICKET';
  }
};