import { useMutation } from 'data-fetching-client';
import { MOVE_ASSIGN_AND_PUBLISH_MUTATION } from '../mutations/moveAssignAndPublish';
import { buildHandoffError } from '../operators/buildHandoffError';
export const useMoveAssignAndPublish = ({
  variables,
  onCompleted,
  onError
}) => {
  const [mutate, {
    loading: moveAssignAndPublishLoading
  }] = useMutation(MOVE_ASSIGN_AND_PUBLISH_MUTATION);
  const errorHandler = (error, handoffRequest) => {
    const destinationInboxName = variables.inboxName || null;
    const moveAssignAndPublishError = buildHandoffError(error, Object.assign({}, handoffRequest, {
      destinationInboxName
    }));
    onError(moveAssignAndPublishError);
  };
  const moveAssignAndPublish = () => {
    const {
      comment,
      inboxId,
      routingRule,
      subject,
      threadId,
      pipelineInformation
    } = variables;
    const handoffRequest = {
      comment: comment || undefined,
      handoffAssignmentRequest: routingRule ? {
        assignmentByRoutingRuleDefinitionRequest: {
          routingRuleDefinition: routingRule,
          assignmentMethod: 'SYSTEM_AUTOMATIC',
          shouldReassign: true
        }
      } : undefined,
      newInboxId: inboxId,
      subject,
      threadId,
      additionalProperties: Object.assign({}, pipelineInformation)
    };
    mutate({
      variables: {
        handoffRequest
      }
    }).then(onCompleted).catch(error => errorHandler(error, handoffRequest));
  };
  return {
    moveAssignAndPublish,
    moveAssignAndPublishLoading
  };
};