import { buildTlmFromViewMember } from 'find-and-filter-data/view-members-schema/protected';
import { getViewMemberId, getIdFromObjectKey } from 'find-and-filter-data/view-members-schema/public';
import { OrderedMap } from 'immutable';
import Raven from 'raven-js';
export function mergeUpserted(upsertedMembers, viewMembers, currentAgentId) {
  const indexedRecords = upsertedMembers.reduce((indexedMembers, currViewMember) => {
    const seen = currViewMember.seenByAgentIds.includes(currentAgentId);
    try {
      return indexedMembers.set(`${getViewMemberId(currViewMember)}`, buildTlmFromViewMember(currViewMember, currentAgentId).set('seen', seen));
    } catch (e) {
      Raven.captureException(e, {
        extra: {
          currViewMemberType: typeof currViewMember,
          indexedMembersType: typeof indexedMembers,
          viewMemberId: `${getViewMemberId(currViewMember)}`
        }
      });
      return indexedMembers;
    }
  }, OrderedMap());
  return viewMembers.merge(indexedRecords);
}
export function mergeRemoved(removedMembers, viewMembers) {
  return removedMembers.reduce((members, removed) => {
    const id = `${getIdFromObjectKey(removed.objectKey)}`;
    const candidate = viewMembers.get(id);
    if (!candidate) {
      return members;
    }
    return members.update(id, currentMember => {
      return currentMember.set('removal', {
        '@type': 'UNKNOWN',
        audit: {
          '@type': 'UNKNOWN'
        },
        members: null
      });
    });
  }, viewMembers);
}

/**
 * @description This function will take a realtime ViewUpdate message and
 * apply all the changes to the given map of ViewMembers, returning a new map
 * with all those applied changes.
 *
 * @param viewMembers
 * @param viewMembersUpdated
 * @param currentAgentId Needed to resolve seen correctly for added ViewMembers
 */
export function applyViewUpdateMessage(viewMembers, viewMembersUpdated, currentAgentId) {
  const withUpsertedMembers = mergeUpserted(viewMembersUpdated.upserted, viewMembers, currentAgentId);
  const updatedMembers = mergeRemoved(viewMembersUpdated.removed, withUpsertedMembers);
  return updatedMembers;
}