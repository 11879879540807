import { threadsBulkUpdated } from './threadsBulkUpdated';
export const handleThreadsBulkUpdate = ({
  currentAgentId,
  message,
  onBulkUpdateFailed,
  onBulkUpdateSuccess
}) => dispatch => {
  dispatch(threadsBulkUpdated({
    message
  }));
  if (currentAgentId === message.auditedAgentId) {
    if (message.failedThreadIds.length > 0) {
      onBulkUpdateFailed(message.failedThreadIds);
    } else {
      onBulkUpdateSuccess(message.successfulThreadIds);
    }
  }
};