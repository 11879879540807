import getIn from 'transmute/getIn';
import get from 'transmute/get';
import { getIsForward as getCMFIsForward
// @ts-expect-error module not typed
} from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { COMMON_MESSAGE, EDITOR_STATE, METADATA, REPLY_ID, TIMESTAMP, VERSION, VID, COMMON_MESSAGE_RECIPIENTS } from '../../_internal/constants/keyPaths';
export const getAttachments = get('attachments');
export const getCommonMessage = getIn(COMMON_MESSAGE);
export const getEditorState = getIn(EDITOR_STATE);
export const getMetadata = getIn(METADATA);
export const getReplyId = getIn(REPLY_ID);
export const getTimestamp = getIn(TIMESTAMP);
export const getVersion = getIn(VERSION);
export const getBCC = getIn(['metadata', 'bcc']);
export const getCC = getIn(['metadata', 'cc']);
export const getFrom = getIn(['metadata', 'from']);
export const getFromAddressFromReply = getIn(['metadata', 'from', 'address']);
export const getAgentIdFromReply = getIn(['metadata', 'from', 'id']);
export const getInReplyToMessageId = getIn(['metadata', 'inReplyToMessageId']);
export const getSubject = getIn(['metadata', 'subject']);
export const getTo = getIn(['metadata', 'to']);
export const getVid = getIn(VID);
export const getTemplateId = getIn(['metadata', 'templateId']);
export const getRecipientsFromCommonMessage = getIn(COMMON_MESSAGE_RECIPIENTS);
export const getHasMetadata = commonReply => !!getMetadata(commonReply);
export const getIsForward = getIn(['metadata', 'isForward']);
export const getEmailMetadataAttachmentIsForward = commonReply => {
  const commonMessage = getCommonMessage(commonReply);
  const emailMetadataAttachmentIsForward = getCMFIsForward(commonMessage);
  return emailMetadataAttachmentIsForward;
};