'use es6';

import { getAudit } from '../../common-message-format/operators/commonMessageFormatGetters';
import { getType } from '../../audit/operators/auditGetters';
import { getAgentIdFromAudit } from '../../audit/operators/getAgentIdFromAudit';
import { getAgentTypeFromAudit } from '../../audit/operators/getAgentTypeFromAudit';
import { VISITOR, BOT, AGENT } from '../../common-message-format/constants/legacySenderTypes';
import { MANUAL, BOT_HANDOFF } from '../../audit/constants/auditTypes';
import { buildSenderLocator } from './buildSenderLocator';

// this should replace generateSenderKeyFromStatusMessage by pulling the audit record from the message
export const generateSenderKeyFromMessageAudit = message => {
  const audit = getAudit(message);
  const auditType = getType(audit);
  const agentId = getAgentIdFromAudit(audit);
  if (auditType === MANUAL) {
    const agentType = getAgentTypeFromAudit(audit) !== BOT ? AGENT : BOT;
    return agentId && agentType && buildSenderLocator(agentType, agentId);
  } else if (auditType === BOT_HANDOFF) {
    return agentId && buildSenderLocator(BOT, agentId);
  } else if (auditType === VISITOR) {
    return agentId && buildSenderLocator(VISITOR, agentId);
  }
  return null;
};