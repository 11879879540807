import { metricStore } from '../store';
import { logDuplicatePendingMetricError } from './logDuplicatePendingMetricError';
const addOrOverwriteMetricToStore = (metricName, dimensions) => {
  metricStore[metricName] = {
    startTime: performance.now(),
    dimensions
  };
};
export const initMetric = (metricName, dimensions) => {
  const pendingMetric = metricStore[metricName];
  const isAlreadyInitiated = !!pendingMetric;
  if (isAlreadyInitiated) {
    logDuplicatePendingMetricError({
      metricName,
      originalDimensions: pendingMetric.dimensions,
      newDimensions: dimensions
    });
  }
  addOrOverwriteMetricToStore(metricName, dimensions);
};