import { useQuery } from 'data-fetching-client';
import { OutsideBusinessHoursStatus } from '../constants/OutsideBusinessHoursStatus';
import { GET_INBOX_OUTSIDE_BUSINESS_HOURS } from '../queries/businessHoursQueries';
export function useInboxIsOutsideBusinessHours({
  inboxId
}) {
  const {
    data
  } = useQuery(GET_INBOX_OUTSIDE_BUSINESS_HOURS, {
    variables: {
      inboxId
    }
  });
  return data && data.isInboxOutsideBusinessHours === OutsideBusinessHoursStatus.OUT_OF_OFFICE_HOURS;
}