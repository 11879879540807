import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { handleActions } from 'redux-actions';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { FETCH_GENERIC_ACCOUNTS } from '../constants/asyncActionTypes';
import { updateEntries } from 'conversations-async-data/indexed-async-data/operators/updateEntries';
const initialState = new IndexedAsyncData({
  name: 'genericChannels',
  notSetValue: new AsyncData({
    data: null
  })
});
const reducerMap = {
  [FETCH_GENERIC_ACCOUNTS.STARTED](state, action) {
    const {
      requestArgs
    } = action.payload;
    const {
      inboxIds
    } = requestArgs;
    return updateEntries(inboxIds, (__inboxId, asyncData) => requestStarted(asyncData), state);
  },
  [FETCH_GENERIC_ACCOUNTS.FAILED](state, action) {
    const {
      requestArgs
    } = action.payload;
    const {
      inboxIds
    } = requestArgs;
    return updateEntries(inboxIds, (__inboxId, asyncData) => requestFailed(asyncData), state);
  },
  [FETCH_GENERIC_ACCOUNTS.SUCCEEDED](state, action) {
    const {
      data,
      requestArgs
    } = action.payload;
    const {
      inboxIds
    } = requestArgs;
    return updateEntries(inboxIds, (__inboxId, asyncData) => requestSucceededWithOperator(() => data, asyncData), state);
  }
};
const genericChannels = handleActions(reducerMap, initialState);
export { genericChannels };