import { useAuth } from '../hooks/useAuth';

/**
 * Returns true if the current portal has the provided gate
 */
export function useHasGate(gate) {
  const {
    gates
  } = useAuth();
  return gates.includes(gate);
}
export function useHasBillingWizardGate() {
  return useHasGate('Payments:Subscriptions:CreateSubscription');
}