// mostly copied from https://github.com/HubSpot/transmute/blob/master/src/once.js
export function once(fn) {
  let called = false;
  let result;
  return (...args) => {
    if (!called) {
      called = true;
      result = fn(...args);
    }
    return result;
  };
}