// TODO: Add Types
'use es6';

import Raven from 'raven-js';
import { fromJS } from 'immutable';
import QuoteInboundDbRecord from 'customer-data-objects/quote/QuoteInboundDbRecord';
import { getSelectableQuotes } from './operators';
const QUOTES_URI = 'inbounddbquotes/v1/quotes/by-association';
// eslint-disable-next-line no-restricted-syntax
let cache;
// eslint-disable-next-line no-restricted-syntax
let cachedObjectType;
// eslint-disable-next-line no-restricted-syntax
let cachedObjectId;

/**
 *
 * @param {Object} options
 * @param {Object} options.httpClient - an http client containing a `post` method
 * @param {String} options.objectType - The object type where this plugin
 * is shown. One of [CONTACT, COMPANY, DEAL]
 * @param {String} options.objectId
 * @returns {Promise}
 */
function fetchQuotesByAssociatedObjectType({
  httpClient,
  objectType,
  objectId
}) {
  let promise;
  if (cache && cachedObjectType === objectType && cachedObjectId === objectId) {
    promise = Promise.resolve(cache);
    promise.catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
    return promise;
  }
  promise = httpClient.get(`${QUOTES_URI}/${objectType}/${objectId}`).then(quotes => {
    cache = fromJS(quotes).map(quote => QuoteInboundDbRecord.fromJS(quote));
    cachedObjectType = objectType;
    cachedObjectId = objectId;
    return cache;
  }).catch(error => {
    Raven.captureMessage(`${error.status}: FETCH_QUOTES_BY_ASSOCIATIONS_FAILED`, {
      extra: {
        status: error.status,
        responseText: error.responseText,
        stack: error.stack,
        cache
      },
      fingerprint: [error.status, 'FETCH_QUOTES_BY_ASSOCIATIONS_FAILED']
    });
    return Promise.reject(error);
  });
  promise.catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
  return promise;
}

/**
 *
 * @param {Object} options
 * @param {Object} options.httpClient - an http client containing a `post` method
 * @param {String} options.objectType - The object type where this plugin
 * is shown. One of [CONTACT, COMPANY, DEAL]
 * @param {String} options.objectId
 * @returns {Promise}
 */
export function fetchQuotes({
  httpClient,
  objectType,
  objectId
}) {
  return fetchQuotesByAssociatedObjectType({
    httpClient,
    objectType,
    objectId
  }).then(quotes => {
    return getSelectableQuotes({
      objectType,
      quotes
    });
  });
}