'use es6';

import { fetchIntegratorSenders } from '../actions/fetchIntegratorSenders';
import { getIndexedAsyncIntegratorSenders } from '../selectors/getIndexedAsyncIntegratorSenders';
import { useReduxIndexedAsyncFetcher } from 'conversations-async-data/indexed-async-data/hooks/useReduxIndexedAsyncFetcher';
const idsTransform = ids => ({
  senderLocators: ids
});
export const useIndexedAsyncIntegratorSenders = ({
  senderLocators
}) => {
  const {
    entries
  } = useReduxIndexedAsyncFetcher({
    action: fetchIntegratorSenders,
    selector: getIndexedAsyncIntegratorSenders,
    ids: senderLocators,
    idsTransform
  });
  return {
    integratorSenders: entries
  };
};