'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callProvider", "fromNumber", "callMethod"];
import { Map as ImmutableMap } from 'immutable';
import getIn from 'transmute/getIn';
import { AsyncSettingsOmnibusResult } from '../records/SettingsOmnibusResult';
import { getOrCreateUniversalCallingStore } from 'calling-global-api/universal-calling-store/UniversalCallingStore';
import { getIsProviderHubSpot, getIsTwilioBasedCallProvider } from 'calling-lifecycle-internal/call-provider/operators/getIsTwilioBasedCallProvider';
import CallingProvider from 'calling-internal-common/call-provider/records/CallingProvider';
import FromNumber from 'calling-internal-common/calls-v1-settings/records/InitialLoadSettingsRecords/FromNumber';
import { CONNECT_FROM_NUMBER, FROM_NUMBER, getPersistedCallMethod, getPersistedCallProvider, getPersistedFromNumber, getPersistedFromNumberWithFallback, setPersistedCallProvider } from 'calling-lifecycle-internal/utils/getLocalCallSettings';
import { SET_CALL_METHOD, SET_CALLING_PROVIDER_SETTING } from 'calling-global-api/constants/EventListeners';
import { getData } from 'calling-lifecycle-internal/async-flux-utils/operators/getApiProperties';
import { getConnectAccountNumbers, getHubspotProvidedNumbers, getHubspotSubAccountNumbers, getPhoneNumbers, getCallProvidersFromOmnibusResult, getHubSpotCallingEnabled } from '../operators/settingsOmnibusResultGetters';
import { FAILED, SUCCEEDED } from 'calling-lifecycle-internal/async-flux-utils/constants/apiLoadingStates';
import { getHubSpotCallingProvider } from 'calling-lifecycle-internal/call-provider/operators/callProviderOperators';
import { getIsUngatedForBidirectionalProviderSwitching } from '../../calling-gates/utils/getIsUngatedForBidirectionalProviderSwitching';
import { getCallReceivingPreference } from 'calling-internal-common/calling-orchestration-v1/settings/user/clients/UserSettingsApi';
let settingsOmnibusStore;
export const getSubjectKey = ({
  subjectId,
  objectTypeId
}) => {
  if (!subjectId || !objectTypeId) {
    return null;
  }
  return `${subjectId}_${objectTypeId}`;
};
export const setSettingsOminbusStoreForTesting = store => {
  settingsOmnibusStore = store;
};
export const parseRawState = state => {
  const {
      callProvider,
      fromNumber,
      callMethod
    } = state,
    omnibusInformation = _objectWithoutPropertiesLoose(state, _excluded);
  const parsedOmnibusInformation = {};
  if (omnibusInformation) {
    Object.keys(omnibusInformation).forEach(key => {
      if (!omnibusInformation[key]) return;
      parsedOmnibusInformation[key] = new AsyncSettingsOmnibusResult(omnibusInformation[key]);
    });
  }
  return ImmutableMap(Object.assign({
    callProvider: new CallingProvider(callProvider),
    fromNumber: new FromNumber(fromNumber),
    callMethod
  }, parsedOmnibusInformation));
};
export function handleSetCallProvider(callProvider) {
  let fromNumber = null;
  let callMethod = null;
  if (getIsTwilioBasedCallProvider(callProvider)) {
    const fromNumberKey = getIsProviderHubSpot(callProvider) ? FROM_NUMBER : CONNECT_FROM_NUMBER;
    fromNumber = getPersistedFromNumber(fromNumberKey);
    if (getIsProviderHubSpot(callProvider)) {
      callMethod = getPersistedCallMethod();
    }
  }
  const state = settingsOmnibusStore.getState();
  settingsOmnibusStore.setState(state.merge({
    callProvider,
    fromNumber,
    callMethod
  }));
}
function getCallProviderInfo(result) {
  return {
    appId: result.integrationsPlatformAppId,
    name: result.providerName,
    url: result.url,
    height: result.height,
    width: result.width,
    isReady: result.ready,
    supportsCustomObjects: result.supportsCustomObjects,
    supportsInboundCalling: result.supportsInboundCalling,
    supportsChannelConnection: result.supportsChannelConnection
  };
}
function getProviderFromCallPreferenceData(fetchCallPreferenceData) {
  const {
    callingProviderInfo
  } = fetchCallPreferenceData;
  if (callingProviderInfo['integrationsPlatformAppId']) {
    // its third party provider
    return new CallingProvider(getCallProviderInfo(callingProviderInfo.metadata.result));
  } else {
    return getHubSpotCallingProvider();
  }
}
export function initializeCallProvider(omnibusData, initializingOmnibus = false) {
  const persistedCallProvider = getPersistedCallProvider();

  // If HS calling is enabled and not first withCalleeOmnibus initialization or HubSpot not selected
  if (getHubSpotCallingEnabled(omnibusData) && !initializingOmnibus || !getIsProviderHubSpot(persistedCallProvider)) {
    return;
  }
  const phoneNumbers = getPhoneNumbers(omnibusData);
  const hubspotFromNumbers = getHubspotSubAccountNumbers(phoneNumbers);
  const hubspotProvidedNumbers = getHubspotProvidedNumbers(phoneNumbers);
  // If HubSpot callProvider has registered from numbers, return
  if (hubspotFromNumbers.length || hubspotProvidedNumbers.length) {
    return;
  }
  const callProviders = getCallProvidersFromOmnibusResult(omnibusData);
  // If no other call providers exist, return
  if (callProviders.size <= 1) {
    return;
  }
  const callProvider = callProviders.get(1);
  setPersistedCallProvider(callProvider);
  handleSetCallProvider(callProvider);
}
export function handleSetProviderSettings(providerSettings) {
  handleSetCallProvider(providerSettings.callingProvider);
  handleSetFromNumber(providerSettings.fromNumber);
}
export function initializeFromNumber(phoneNumbers) {
  const state = settingsOmnibusStore.getState();
  const hubspotFromNumbers = getHubspotSubAccountNumbers(phoneNumbers);
  const hubspotProvidedNumbers = getHubspotProvidedNumbers(phoneNumbers);
  const portalAndPersonalNumbers = hubspotFromNumbers.concat(hubspotProvidedNumbers);
  const defaultFromNumber = getPersistedFromNumberWithFallback({
    fromNumberKey: FROM_NUMBER,
    fromNumbers: portalAndPersonalNumbers
  });
  const connectFromNumbers = getConnectAccountNumbers(phoneNumbers);
  const defaultConnectFromNumber = getPersistedFromNumberWithFallback({
    fromNumberKey: CONNECT_FROM_NUMBER,
    fromNumbers: connectFromNumbers
  });
  const isProviderHubSpot = getIsProviderHubSpot(state.get('callProvider'));
  const fromNumber = isProviderHubSpot ? defaultFromNumber : defaultConnectFromNumber;
  settingsOmnibusStore.setState(state.merge({
    fromNumber
  }));
}
function initializeProviderAndFromNumber(fetchCallReceivingPreferencePromiseResult, omnibusData) {
  return fetchCallReceivingPreferencePromiseResult.then(data => {
    const callProviderFromCallPreference = getProviderFromCallPreferenceData(data);
    const persistedCallProvider = getPersistedCallProvider();
    if (getIn(['appId'], persistedCallProvider) !== getIn(['appId'], callProviderFromCallPreference) || getIn(['name'], persistedCallProvider !== getIn(['name'], callProviderFromCallPreference))) {
      // update if provider in localstorage is different from the one returned
      setPersistedCallProvider(callProviderFromCallPreference);
      handleSetCallProvider(callProviderFromCallPreference);
    }
    if (getIsTwilioBasedCallProvider(callProviderFromCallPreference)) {
      const phoneNumbers = getPhoneNumbers(omnibusData);
      initializeFromNumber(phoneNumbers);
    }
  });
}
export function setProviderInSettingsOmnibus({
  fetchCalleeOmnibusPromise,
  gates,
  appIdentifier
}) {
  return fetchCalleeOmnibusPromise.then(data => {
    const settingsOmnibusResult = new AsyncSettingsOmnibusResult({
      state: SUCCEEDED,
      data
    });
    const omnibusData = getData(settingsOmnibusResult);
    if (getIsUngatedForBidirectionalProviderSwitching(gates, appIdentifier)) {
      const fetchCallReceivingPreferencePromise = getCallReceivingPreference();
      initializeProviderAndFromNumber(fetchCallReceivingPreferencePromise, omnibusData);
    }
  });
}
export function setSettingsOmnibusData({
  fetchCalleeOmnibusPromise,
  initializingOmnibus
}) {
  fetchCalleeOmnibusPromise.then(data => {
    // Eventually this entire api call will be deleted, this is to test that the data still gets there.
    const settingsOmnibusResult = new AsyncSettingsOmnibusResult({
      state: SUCCEEDED,
      data
    });
    const omnibusData = getData(settingsOmnibusResult);

    // Everything in here is handled by the webworker

    settingsOmnibusStore.updateState(state => {
      return state.setIn(['asyncSettingsOmnibus', 'data', 'phoneNumbers'], settingsOmnibusResult.getIn(['data', 'phoneNumbers'])).setIn(['asyncSettingsOmnibus', 'data', 'installedCallingSettings'], settingsOmnibusResult.getIn(['data', 'installedCallingSettings'])).setIn(['asyncSettingsOmnibus', 'state'], settingsOmnibusResult.getIn(['state'])).setIn(['asyncSettingsOmnibus', 'data', 'callingAdminSettings'], settingsOmnibusResult.getIn(['data', 'callingAdminSettings']));
    });
    initializeCallProvider(omnibusData, initializingOmnibus);
    if (getIsTwilioBasedCallProvider(settingsOmnibusStore.getState().get('callProvider'))) {
      const phoneNumbers = getPhoneNumbers(omnibusData);
      initializeFromNumber(phoneNumbers);
    }
  }).catch(error => {
    settingsOmnibusStore.updateState(state => state.set('asyncSettingsOmnibus', new AsyncSettingsOmnibusResult({
      state: FAILED,
      error
    })));
  });
}
export function handleSetCallMethod(callMethod) {
  settingsOmnibusStore.updateState(state => state.set('callMethod', callMethod));
}
export function handleSetFromNumber(fromNumber) {
  settingsOmnibusStore.updateState(state => state.set('fromNumber', fromNumber));
}
export const getOrCreateSettingsOmnibusStore = () => {
  const callProvider = getPersistedCallProvider();
  const callMethod = getIsProviderHubSpot(callProvider) ? getPersistedCallMethod() : null;
  let fromNumber = null;
  if (getIsTwilioBasedCallProvider(callProvider)) {
    const fromNumberKey = getIsProviderHubSpot(callProvider) ? FROM_NUMBER : CONNECT_FROM_NUMBER;
    fromNumber = getPersistedFromNumber(fromNumberKey);
  }
  const initialState = ImmutableMap({
    callProvider,
    fromNumber,
    callMethod,
    asyncSettingsOmnibus: new AsyncSettingsOmnibusResult()
  });
  settingsOmnibusStore = getOrCreateUniversalCallingStore({
    name: 'settingsOmnibus',
    initialState,
    parseRawState,
    version: 1,
    // this needs to update with AsyncSettingsOmnibusResult
    eventListeners: [{
      event: SET_CALL_METHOD,
      callback: handleSetCallMethod
    }, {
      event: SET_CALLING_PROVIDER_SETTING,
      callback: handleSetProviderSettings
    }]
  });
  return settingsOmnibusStore;
};