import { createAction } from 'redux-actions';
import { getInboxUrl } from '../../urls/getInboxUrl';
import { NAVIGATE_TO_THREAD } from 'conversations-inbox-lib/pubsub/public/constants';
import { setLocation } from '../../routing/actions/setLocation';
const navigateToThreadAction = createAction(NAVIGATE_TO_THREAD, threadId => ({
  threadId
}), threadId => ({
  syncSeenStatusForThreadId: threadId
}));
export const navigateToThread = ({
  preserveSearch = true,
  threadId = null
}) => dispatch => {
  if (threadId === null) return;
  const url = getInboxUrl(threadId);
  dispatch(setLocation({
    location: url,
    preserveSearch
  }));
  dispatch(navigateToThreadAction(threadId));
};