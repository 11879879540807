import I18n from 'I18n';
import get from 'transmute/get';
import { VISITOR } from '../../common-message-format/constants/legacySenderTypes';
import { AVATAR, DATA, EMAIL, FIRST_NAME, ID, LAST_NAME, SENDER_TYPE, STATUS } from '../constants/keyPaths';
export const getAvatar = get(AVATAR);
export const getEmail = get(EMAIL);
export const getFirstName = get(FIRST_NAME);
export const getId = get(ID);
export const getLastName = get(LAST_NAME);
export const getSenderType = get(SENDER_TYPE);

/**
 * @deprecated use `conversations-async-data/async-data/operators/getters` instead
 */
export const getData = get(DATA);
/**
 * @deprecated use `conversations-async-data/async-data/operators/getters` instead
 */
export const getStatus = get(STATUS);
export const getDisplayName = sender => {
  const firstName = getFirstName(sender);
  const lastName = getLastName(sender);
  const email = getEmail(sender);
  const senderType = getSenderType(sender);
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  if (firstName) {
    return firstName;
  }
  if (email) {
    return email;
  }
  if (senderType === VISITOR) {
    return I18n.text('conversations-message-history.responder.unknownVisitorAvatar');
  }
  return null;
};
export const getNonDefaultSource = sender => {
  const avatarSrc = getAvatar(sender);
  return avatarSrc && !avatarSrc.includes('default') ? avatarSrc : null;
};