// @ts-expect-error RecordPropType is not typed
import RecordPropType from 'conversations-prop-types/prop-types/RecordPropType';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
export default ImmutablePropTypes.mapOf(ImmutablePropTypes.recordOf({
  data: RecordPropType('Recipient'),
  status: PropTypes.string.isRequired
}), RecordPropType('RecipientLocator'));
export const LegacyRecipientMapPropType = ImmutablePropTypes.mapOf(ImmutablePropTypes.recordOf({
  data: RecordPropType('LegacyRecipient'),
  status: PropTypes.string.isRequired
}), RecordPropType('RecipientLocator'));