import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestSucceeded } from 'conversations-async-data/async-data/operators/requestSucceeded';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { updateData } from 'conversations-async-data/async-data/operators/setters';
import { updateAsyncData } from 'conversations-async-data/async-data/operators/updateAsyncData';
import { limitToCount } from 'conversations-async-data/indexed-async-data/eviction-strategies/limitToCount';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { updateExistingEntry } from 'conversations-async-data/indexed-async-data/operators/updateExistingEntry';
import { getNewChannelDescriptor } from 'conversations-internal-pub-sub/channel-change/operators/channelChangeGetters';
import ChatFilterOptions from 'conversations-internal-schema/constants/ChatFilterOptions';
// @ts-expect-error module not typed
import { getAssignedAgent } from 'conversations-message-history/assignment-update-message/operators/assignmentGetters';
// @ts-expect-error module not typed
import { isAssignmentUpdateMessage } from 'conversations-message-history/assignment-update-message/operators/isAssignmentUpdateMessage';
// @ts-expect-error module not typed
import { hasThreadBeenClosed } from 'conversations-message-history/thread-status-update/operators/hasThreadBeenClosed';
// @ts-expect-error module not typed
import { hasThreadBeenOpened } from 'conversations-message-history/thread-status-update/operators/hasThreadBeenOpened';
import { Map as ImmutableMap } from 'immutable';
import { combineActions, handleActions } from 'redux-actions';
import { CHANNEL_CHANGE_RECEIVED, HISTORY_MESSAGE_RECEIVED, THREAD_STATUS_UPDATED } from '../../../thread-view-realtime/public/constants/actionTypes';
import { VISITORS_INVALIDATED } from '../../../realtime/public/constants';
import { VISITOR_UPDATED, VISITOR_EMAIL_UPDATED } from '../../../visitor/constants/actionTypes';
import { BLOCK_THREAD, CLOSE_THREAD, MARK_AS_SPAM, OPEN_THREAD, UNBLOCK_THREAD, UNMARK_AS_SPAM, ASSIGNMENT_CHANGE, MOVE_TO_INBOX, RESTORE_THREAD, SOFT_DELETE_THREAD } from '../../../thread-actions/public/constants';
import { MANUAL_BLOCK, MANUAL_SPAM, NOT_FILTERED, FETCH_THREAD_LIST_MEMBER_DETAILS } from '../../../thread-details/public/constants';
import { setClosingThreadStatusWithOperator } from '../operators/setClosingThreadStatusWithOperator';
import { setOpeningThreadStatus } from '../operators/setOpeningThreadStatus';
import { setAssignee, setChannelDescriptor, setFilteredSource, setInboxId, setStatus, safeSetStatus, setVid, setVisitor, setVisitorEmail } from '../../../thread-details/public/operators';
const {
  ENDED,
  STARTED,
  SOFT_DELETED
} = ChatFilterOptions;
const DETAILS_LIMIT = 25;
const initialState = new IndexedAsyncData({
  evict: limitToCount(DETAILS_LIMIT),
  name: 'threadListMemberDetails',
  notSetValue: new AsyncData()
});
export const threadListMemberDetails = handleActions({
  /**
   * Handlers for declarative thread details fetching API
   */

  /* Fetching */
  [FETCH_THREAD_LIST_MEMBER_DETAILS.STARTED](state, action) {
    const {
      requestArgs
    } = action.payload;
    return updateEntry(requestArgs.threadId, requestStarted, state);
  },
  [FETCH_THREAD_LIST_MEMBER_DETAILS.SUCCEEDED](state, action) {
    const {
      requestArgs,
      data
    } = action.payload;
    const {
      threadListMemberDetails: tlmDetails
    } = data;
    return updateEntry(requestArgs.threadId, requestSucceededWithOperator(() => tlmDetails), state);
  },
  [FETCH_THREAD_LIST_MEMBER_DETAILS.FAILED](state, action) {
    const {
      requestArgs
    } = action.payload;
    return updateEntry(requestArgs.threadId, requestFailed, state);
  },
  /**
   * Handlers for core thread details imperative actions API.
   */

  /* FILTERING */
  [MARK_AS_SPAM.SUCCEEDED](state, action) {
    const {
      threadId
    } = action.payload.requestArgs;
    return updateExistingEntry(threadId,
    // @ts-expect-error transmute typing issue
    updateAsyncData(setFilteredSource(MANUAL_SPAM)), state);
  },
  [BLOCK_THREAD.SUCCEEDED](state, action) {
    const {
      threadId
    } = action.payload.requestArgs;
    return updateExistingEntry(threadId,
    // @ts-expect-error transmute typing issue
    updateAsyncData(setFilteredSource(MANUAL_BLOCK)), state);
  },
  [combineActions(UNMARK_AS_SPAM.SUCCEEDED, UNBLOCK_THREAD.SUCCEEDED).toString()](state, action) {
    const {
      threadId
    } = action.payload.requestArgs;
    return updateExistingEntry(threadId,
    // @ts-expect-error transmute typing issue
    updateAsyncData(setFilteredSource(NOT_FILTERED)), state);
  },
  /* ASSIGNMENT */
  [ASSIGNMENT_CHANGE.SUCCEEDED](state, action) {
    const {
      responder,
      threadId
    } = action.payload.requestArgs;
    const agentLocator = responder ? ImmutableMap(responder) : null;
    return updateExistingEntry(threadId,
    // @ts-expect-error transmute typing issue
    updateAsyncData(setAssignee(agentLocator)), state);
  },
  /* MOVE TO INBOX */
  [MOVE_TO_INBOX.SUCCEEDED](state, action) {
    const {
      newInboxId,
      threadId
    } = action.payload.requestArgs;
    return updateExistingEntry(threadId,
    // @ts-expect-error transmute typing issue
    updateAsyncData(setInboxId(newInboxId)), state);
  },
  /* OPENING/CLOSING */
  [CLOSE_THREAD.STARTED](state, action) {
    const {
      requestArgs
    } = action.payload;
    return updateExistingEntry(requestArgs.threadId,
    // @ts-expect-error transmute typing issue
    setClosingThreadStatusWithOperator(safeSetStatus(ENDED)), state);
  },
  [CLOSE_THREAD.SUCCEEDED](state, action) {
    const {
      requestArgs
    } = action.payload;
    return updateExistingEntry(requestArgs.threadId, requestSucceeded, state);
  },
  [CLOSE_THREAD.FAILED](state, action) {
    const {
      requestArgs
    } = action.payload;
    return updateExistingEntry(requestArgs.threadId, requestSucceededWithOperator(safeSetStatus(STARTED)), state);
  },
  [OPEN_THREAD.STARTED](state, action) {
    const {
      requestArgs
    } = action.payload;
    return updateExistingEntry(requestArgs.threadId, setOpeningThreadStatus, state);
  },
  [OPEN_THREAD.SUCCEEDED](state, action) {
    const {
      requestArgs
    } = action.payload;
    return updateExistingEntry(requestArgs.threadId,
    // @ts-expect-error transmute typing issue
    requestSucceededWithOperator(setStatus(STARTED)), state);
  },
  [OPEN_THREAD.FAILED](state, action) {
    const {
      requestArgs
    } = action.payload;
    return updateExistingEntry(requestArgs.threadId, requestSucceeded, state);
  },
  [SOFT_DELETE_THREAD.SUCCEEDED](state, action) {
    const {
      requestArgs
    } = action.payload;
    return updateExistingEntry(requestArgs.threadId,
    // @ts-expect-error transmute typing issue
    requestSucceededWithOperator(setStatus(SOFT_DELETED)), state);
  },
  [RESTORE_THREAD.SUCCEEDED](state, action) {
    const {
      requestArgs
    } = action.payload;
    return updateExistingEntry(requestArgs.threadId,
    // @ts-expect-error transmute typing issue
    requestSucceededWithOperator(setStatus(STARTED)), state);
  },
  /**
   * Handlers that define the integration point between the thread details
   * feature and the thread-view-realtime feature. These handlers add
   * additional functionality.
   */

  [CHANNEL_CHANGE_RECEIVED](state, action) {
    // @ts-expect-error this action is untyped
    const {
      threadId,
      channelChange
    } = action.payload;
    const newChannelDescriptor = getNewChannelDescriptor(channelChange);
    return updateExistingEntry(threadId, updateData(setChannelDescriptor(newChannelDescriptor)), state);
  },
  [THREAD_STATUS_UPDATED](state, action) {
    // @ts-expect-error this action is untyped
    const {
      threadId,
      message
    } = action.payload;
    if (hasThreadBeenOpened(message)) {
      return updateExistingEntry(threadId,
      // @ts-expect-error transmute typing issue
      requestSucceededWithOperator(setStatus(STARTED)), state);
    }
    if (hasThreadBeenClosed(message)) {
      return updateExistingEntry(threadId,
      // @ts-expect-error transmute typing issue
      requestSucceededWithOperator(setStatus(ENDED)), state);
    }
    return state;
  },
  [HISTORY_MESSAGE_RECEIVED](state, action) {
    // @ts-expect-error this action is untyped
    const {
      message,
      threadId
    } = action.payload;
    if (isAssignmentUpdateMessage(message)) {
      const assignedAgent = getAssignedAgent(message);
      const agentLocator = assignedAgent ? ImmutableMap(assignedAgent.toJS()) : null;
      return updateExistingEntry(threadId,
      // @ts-expect-error transmute typing issue
      updateAsyncData(setAssignee(agentLocator)), state);
    }
    return state;
  },
  // @ts-expect-error FIXME this action doesn't follow the pattern of the other actions that all have requestArgs
  [VISITORS_INVALIDATED](state, action) {
    const {
      threadId,
      vid
    } = action.payload;
    return updateExistingEntry(threadId, updateAsyncData(data => data ? setVid(vid, data) : data), state);
  },
  // @ts-expect-error FIXME this action doesn't follow the pattern of the other actions that all have requestArgs
  [VISITOR_UPDATED](state, action) {
    const {
      threadId,
      visitor
    } = action.payload;
    if (threadId) {
      return updateExistingEntry(threadId, updateAsyncData(data => data ? setVisitor(visitor, data) : data), state);
    }
  },
  // @ts-expect-error FIXME this action doesn't follow the pattern of the other actions that all have requestArgs
  [VISITOR_EMAIL_UPDATED](state, action) {
    const {
      threadId,
      visitorEmail
    } = action.payload;
    return updateExistingEntry(threadId, updateAsyncData(data => data ? setVisitorEmail(visitorEmail, data) : data), state);
  }
}, initialState);