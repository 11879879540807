import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { setStatusToggle as updateStatusToggle } from '../actions/setStatusToggle';
import { getStatusToggle } from '../selectors/getStatusToggle';
import { useInboxViewMembers } from 'find-and-filter-data/view-members-data/public';
import { getCurrentInboxId } from 'conversations-inbox-lib/unified-inbox/public/selectors';
import { getCurrentViewId } from '../../thread-list/selectors/getCurrentViewId';
import { getThreadListMemberFilters } from '../../thread-list-member/selectors/getThreadListMemberFilters';
export const useStatusToggle = () => {
  const statusToggle = useSelector(getStatusToggle);
  const inboxId = useSelector(getCurrentInboxId);
  const currentViewId = useSelector(getCurrentViewId);
  const viewFilters = useSelector(getThreadListMemberFilters);
  const {
    refetch
  } = useInboxViewMembers({
    inboxId,
    skip: true,
    sortDirection: viewFilters.sortDirection,
    threadStatus: statusToggle,
    viewId: currentViewId || undefined
  });
  const dispatch = useDispatch();
  const setStatusToggle = useCallback(status => {
    dispatch(updateStatusToggle(status));
    refetch({
      threadStatus: status
    });
  }, [dispatch, refetch]);
  return {
    statusToggle,
    setStatusToggle
  };
};