import { Map as ImmutableMap, List as ImmutableList } from 'immutable';
import { getRecipientKey, getRecipientFieldName, getDeliveryIdentifierType } from '../../../channel-configuration/public/operators';
import { isSucceeded } from 'conversations-async-data/async-data/operators/statusComparators';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { getDeliveryIdentifiers, getFilteredRecipientsByField } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
// @ts-expect-error module not typed

import { getEmailAddressRecordFromString } from './getEmailAddressRecordFromString';
import { deliveryIdentifierTypeToContact } from '../../_internal/constants/deliveryIdentifierTypeToContact';
/*
 * Creates a map of all recipients, by recipientKey and all
 */
export const getAllRecipients = (recipientsFields, commonMessage, indexedAsyncEmailAddresses) => {
  let recipientsMap = ImmutableMap({
    all: ImmutableList()
  });
  for (const recipientField of recipientsFields) {
    const recipientKey = getRecipientKey(recipientField);
    const recipientsFieldName = getRecipientFieldName(recipientField);
    const recipientsForField = getFilteredRecipientsByField(commonMessage, recipientsFieldName);
    const deliveryIdentifierType = getDeliveryIdentifierType(recipientField);
    const deliveryIdentifiersForField = getDeliveryIdentifiers(recipientsForField, deliveryIdentifierTypeToContact[deliveryIdentifierType]);
    const emailAddressRecordsForField = deliveryIdentifiersForField.map(deliveryIdentifier => {
      const asyncData = indexedAsyncEmailAddresses.get(deliveryIdentifier);
      return isSucceeded(asyncData) && getData(asyncData) ? getData(asyncData) : getEmailAddressRecordFromString(deliveryIdentifier);
    }).toList();
    recipientsMap = recipientsMap.set(recipientKey, emailAddressRecordsForField);
    recipientsMap = recipientsMap.updateIn(['all'], oldRecipients => oldRecipients.concat(emailAddressRecordsForField));
  }
  return recipientsMap;
};