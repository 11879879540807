import CallingProvider from 'calling-internal-common/call-provider/records/CallingProvider';
import { List } from 'immutable';
import get from 'transmute/get';
import { getIsLocal } from '../../utils/getIsLocal';
import { getDemoWidgetCallingProvider, getDemoWidgetValue } from './getDemoWidgetCallingProvider';
import getLocalDevelopmentWidgetInfo from './getLocalDevelopmentWidgetInfo';
import { getMockUiCallingProvider, getMockUiValue } from './getMockUiCallingProvider';
export function getInstalledCallingProviders({
  installedCallingApplications
}) {
  let providers = List();
  const demoWidgetValue = getDemoWidgetValue();
  if (demoWidgetValue) {
    providers = providers.push(getDemoWidgetCallingProvider(demoWidgetValue));
  }
  const mockUiValue = getMockUiValue();
  if (mockUiValue) {
    providers = providers.push(getMockUiCallingProvider(mockUiValue));
  } else if (getIsLocal()) {
    // eslint-disable-next-line no-console
    console.log(`Run localStorage.setItem('LocalSettings:Calling:installMockUi', 'local:supportsCustomObjects') to debug Mock UI locally`);
  }
  if (installedCallingApplications) {
    installedCallingApplications.forEach(config => {
      const name = get('name', config);
      const url = get('url', config);
      if (url && name) {
        const widgetConfig = new CallingProvider(config);
        providers = providers.push(widgetConfig);
      }
    });
  }
  providers = getLocalDevelopmentWidgetInfo(providers);
  providers = providers.filter(widget => Boolean(widget.isReady && widget.name));
  return providers;
}