import { TemplateMappings } from '../../constants/TemplateMappings';

// TODO: Look into if group prompts by slug and get featured templates can be combined into one function

/**
 * We want to group the prompts based on the above template mappings
 * i.e. /home: [{ name: 'Add company', id: 'add_company' }]
 */
export const groupPromptsBySlug = prompts => {
  return Object.entries(TemplateMappings).reduce((acc, [pageSlug, promptIds]) => {
    const featuredTemplates = promptIds.map(promptId => {
      return prompts.find(prompt => prompt.id === promptId);
    });
    return Object.assign({}, acc, {
      // Remove any undefined values
      [pageSlug]: featuredTemplates.filter(Boolean)
    });
  }, {});
};

// Converts dynamic url mapping into regex
// Converts '/contacts/:id/objects/0-2/views/all/list' -> /\/contacts\/(\d+)\/objects\/0-2\/views\/all\/list.*$/
const toSlugRegex = pageSlug => {
  const matchEndingRegex = '.*$';
  const convertedSlug = pageSlug.replaceAll(':id', '(\\d+)');
  const regex = `${convertedSlug}${matchEndingRegex}`;
  return new RegExp(regex);
};
export function getAppNameFromPageSlug(pageSlug) {
  // For apps with Copilot entry point in the collaboration sidebar
  const pattern = /^\/?collaboration-sidebar-embeddable\/\d+\/panel\/([^/]+)\//;

  // Match the pattern against the provided URL
  const match = pageSlug.match(pattern);
  if (match) {
    // Extract and return the type of app (the 4th parameter in the URL)
    return match[1];
  }
  return '';
}
export const getFeaturedTemplates = ({
  prompts,
  pageSlug
}) => {
  const groupedPrompts = groupPromptsBySlug(prompts);
  const appName = getAppNameFromPageSlug(pageSlug);
  return Object.entries(groupedPrompts).reduce((acc, [slug, promptTemplates]) => {
    const slugRegex = toSlugRegex(slug);
    if (pageSlug.match(slugRegex) || appName.match(slugRegex)) {
      return [...acc, ...promptTemplates];
    }
    return acc;
  }, []);
};