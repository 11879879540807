import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchMessageSubscription } from '../clients/fetchMessageSubscription';
const FETCH_MESSAGE_SUBSCRIPTION_QUERY = registerQuery({
  fieldName: 'messageSubscription',
  args: ['senderPhoneNumber', 'recipientPhoneNumber', 'contactVid'],
  fetcher: fetchMessageSubscription
});
export function useMessageSubscription({
  deferred,
  senderPhoneNumber,
  recipientPhoneNumber,
  contactVid
}) {
  const {
    data,
    loading,
    error
  } = useQuery(FETCH_MESSAGE_SUBSCRIPTION_QUERY, {
    skip: deferred,
    variables: {
      senderPhoneNumber,
      recipientPhoneNumber,
      contactVid
    }
  });
  const messageSubscription = data && data.messageSubscription;
  return {
    messageSubscription,
    loading,
    error
  };
}