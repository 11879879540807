import fromJS from 'transmute/fromJS';
import { createAsyncActionWithErrors } from './createAsyncActionWithErrors';
import { SEND_HEARTBEAT as actionName } from '../constants/asyncActionTypes';
import { sendHeartbeat as requestFn } from '../clients/sendHeartbeat';
import { buildErrorMetaObject } from 'conversations-error-reporting/error-actions/builders/buildErrorMetaObject';
import { ABLY, V2 } from '../constants/heartbeatFeatures';
const sendHeartbeatAction = createAsyncActionWithErrors({
  requestFn,
  actionName,
  toRecordFn: fromJS,
  failureMetaActionCreator: () => buildErrorMetaObject({
    ignore: true
  })
});
export const sendHeartbeat = dispatch => {
  return () => {
    return dispatch(sendHeartbeatAction({
      features: [V2, ABLY]
    }));
  };
};