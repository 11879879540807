export const ASSOCIATE_CONTACT_MARKER = 'universal-sidebar-contact-association';
export const DISASSOCIATE_CONTACT_MARKER = 'universal-sidebar-contact-disassociation';
export const THREAD_LIST_SWITCHING_MARKER = 'conversations-thread-list';
export const THREAD_SWITCHING_MARKER = 'conversations-thread-list-row';
export const CRITICAL_PATH_THREAD_HISTORY = 'critical-path-thread-history';
export const CRITICAL_PATH_THREAD_LIST_MEMBERS = 'critical-path-thread-list-members';
export const SECONDARY_PATH_THREAD_HISTORY = 'secondary-path-thread-history';
export const SECONDARY_PATH_THREAD_LIST_MEMBERS = 'secondary-path-thread-list-members';
export const UNIVERSAL_SIDEBAR_MARKERS = {
  DEALS_CARD: 'universal-sidebar-deals-card',
  OTHER_CONVERSATIONS_CARD: 'universal-sidebar-other-conversations-card',
  OTHER_TICKETS_CARD: 'universal-sidebar-other-tickets-card',
  TICKET_CARD: 'universal-sidebar-ticket-card'
};
export const INBOX_APPLICATION_START = 'inbox-application-start';