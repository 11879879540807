'use es6';

import userInfo from 'hub-http/userInfo';
import { CRM_UI } from 'customer-data-objects/property/PropertySourceTypes';
import { PATCH, POST, PUT, DELETE } from 'crm_data/constants/HTTPVerbs';
import { send } from 'crm_data/api/ImmutableAPI';
const doSendWithUser = (user, type, ...args) => {
  return send({
    type,
    headers: {
      'X-Properties-Source': CRM_UI,
      'X-Properties-SourceId': user.email
    }
  }, ...args);
};
const doSend = (type, ...args) => {
  return userInfo().then(({
    user
  }) => doSendWithUser(user, type, ...args)).catch(error => {
    throw error;
  });
};
export function patch(url, data, callback) {
  return doSend(PATCH, url, data, callback);
}
export function post(url, data, callback) {
  return doSend(POST, url, data, callback);
}
export function put(url, data, callback) {
  return doSend(PUT, url, data, callback);
}
export function del(url) {
  return doSend(DELETE, url);
}