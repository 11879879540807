import { registerQuery, useQuery } from 'data-fetching-client';
import { getSensistiveDataSettings } from '../queries/getSensitiveDataSettings';
export const FETCH_SENSITIVE_DATA_SETTINGS = registerQuery({
  fieldName: 'sensisitveDataSettings',
  fetcher: getSensistiveDataSettings
});
export const useIsSensitiveDataEnabled = () => {
  const {
    loading,
    error,
    data
  } = useQuery(FETCH_SENSITIVE_DATA_SETTINGS);
  return {
    loading,
    error,
    data: data === null || data === void 0 ? void 0 : data.sensisitveDataSettings
  };
};