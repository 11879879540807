import { DONE } from 'ui-shepherd-react/constants/TourStates';
import { UserPromptType } from '../../contextual-prompts/constants/userPromptTypes';
/**
 * The identifier for the inbox onboarding tour
 */
export const ONBOARDING_TOUR_ID = 'NEW_FEATURE_ONBOARDING_TOUR';

// Onboarding steps
export const VIEWS = 'VIEWS';
export const SEARCH_AND_FILTER_MODE_BUTTON = 'SEARCH_AND_FILTER_MODE_BUTTON';
export const AVAILABILITY_STATUS = 'AVAILABILITY_STATUS';
export const CRM_CONTACT_CARD = 'CRM_CONTACT_CARD';
/**
 * This is the sequence of steps we run through as part of the Inbox onboarding
 * tour. The tour will go through these steps in the given order.
 */
export const INBOX_ONBOARDING_TOUR = [UserPromptType.VIEWS, UserPromptType.SEARCH_AND_FILTER_MODE_BUTTON, UserPromptType.AVAILABILITY_STATUS, UserPromptType.CRM_CONTACT_CARD, DONE];

// This ID mapping comes from userPrompts PropmtType
// https://git.hubteam.com/HubSpot/cv-inbox-settings/blob/472ba90d73e675564ca7e6c69b2e147aed67dfdf/cv-inbox-settings-core/src/main/java/com/hubspot/cv/inbox/settings/core/user/prompts/PromptType.java#L8
export const INBOX_ONBOARDING_TOUR_PROMPTS_MAP = new Map([[UserPromptType.VIEWS, UserPromptType.VIEWS], [UserPromptType.SEARCH_AND_FILTER_MODE_BUTTON, UserPromptType.SEARCH_AND_FILTER_MODE_BUTTON], [UserPromptType.AVAILABILITY_STATUS, UserPromptType.AVAILABILITY_STATUS], [UserPromptType.CRM_CONTACT_CARD, UserPromptType.CRM_CONTACT_CARD]]);
export const INBOX_ONBOARDING_TOUR_PROMPTS_STEPS = [UserPromptType.VIEWS, UserPromptType.SEARCH_AND_FILTER_MODE_BUTTON, UserPromptType.AVAILABILITY_STATUS, UserPromptType.CRM_CONTACT_CARD];