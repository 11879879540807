import { Map as ImmutableMap } from 'immutable';
import { useState, useEffect, useCallback, useMemo } from 'react';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { requestFailedWithError } from 'conversations-async-data/async-data/operators/requestFailedWithError';
import { updateData } from 'conversations-async-data/async-data/operators/setters';
import { isUninitialized } from 'conversations-async-data/async-data/operators/statusComparators';
import { fetchPromptData } from '../clients/fetchPromptData';
import { markPromptAsShown } from '../clients/markPromptAsShown';
import { dismissPrompt as dismissPromptClient } from '../clients/dismissPrompt';
import UserPromptData from '../records/UserPromptData';
import { updateDismissedAt, updateViewedCount } from '../operators/userPromptDataSetters';
import { UserPromptType } from '../constants/userPromptTypes';
export const usePromptDismissal = (promptType, noCache = false) => {
  const [asyncPromptData, setPromptData] = useState(new AsyncData({
    data: UserPromptData()
  }));
  useEffect(() => {
    if (!isUninitialized(asyncPromptData)) return;
    setPromptData(requestStarted(asyncPromptData));
    fetchPromptData({
      promptType,
      noCache
    }).then(data => {
      const succeededData = requestSucceededWithOperator(() => UserPromptData(data), asyncPromptData);
      setPromptData(succeededData);
    }).catch(({
      error
    }) => {
      const failedData = requestFailedWithError(error, asyncPromptData);
      setPromptData(failedData);
    });
  }, [promptType, asyncPromptData, noCache]);
  const showPrompt = useCallback(() => {
    markPromptAsShown({
      promptType
    }).then(() => {
      setPromptData(updateData(updateViewedCount(count => count + 1)));
    }).catch(({
      error
    }) => {
      setPromptData(requestFailedWithError(error));
    });
  }, [promptType, setPromptData]);
  const dismissPrompt = useCallback(() => {
    return dismissPromptClient({
      promptType
    }).then(() => {
      setPromptData(updateData(updateDismissedAt(() => 1)));
    }).catch(({
      error
    }) => {
      setPromptData(requestFailedWithError(error));
    });
  }, [promptType, setPromptData]);
  return useMemo(() => ({
    asyncPromptData,
    showPrompt,
    dismissPrompt
  }), [asyncPromptData, showPrompt, dismissPrompt]);
};
export const useTipContentDismissals = () => {
  const conversationsReportsData = usePromptDismissal(UserPromptType.CONVERSATIONS_REPORTS);
  const promptDismissals = useMemo(() => ImmutableMap({
    [UserPromptType.CONVERSATIONS_REPORTS]: conversationsReportsData
  }), [conversationsReportsData]);
  return promptDismissals;
};