'use es6';

import { Record, Map as ImmutableMap } from 'immutable';
export const LAST_VIEWED = 'lastViewed';
export const LastViewedInboxes = Record({
  [LAST_VIEWED]: ImmutableMap()
}, 'LastViewedInboxes');
export const VIEW_ID = 'viewId';
export const INBOX_ID = 'inboxId';

// LastViewedInboxes entries
export const LastViewedInbox = Record({
  [VIEW_ID]: null,
  [INBOX_ID]: null
}, 'LastViewedInbox');