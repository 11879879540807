import { Record, Set as ImmutableSet, Map as ImmutableMap } from 'immutable';
import ProductArea from './ProductArea';
import TicketOwner from './TicketOwner';
class FilterOptions extends Record({
  languages: ImmutableSet(),
  productAreas: ImmutableSet(),
  ticketOwners: ImmutableSet()
}, 'FilterOptions') {
  get productAreasById() {
    return this.productAreas.reduce((lookup, productArea) => lookup.set(productArea.id, productArea), ImmutableMap());
  }
  get ticketOwnersByEmail() {
    return this.ticketOwners.reduce((lookup, ticketOwner) => lookup.set(ticketOwner.email, ticketOwner), ImmutableMap());
  }
}
FilterOptions.fromJS = obj => new FilterOptions({
  languages: ImmutableSet(obj.languages),
  productAreas: ImmutableSet(obj.productAreas ? obj.productAreas.map(ProductArea.fromJS) : null),
  ticketOwners: ImmutableSet(obj.ticketOwners ? obj.ticketOwners.map(TicketOwner.fromJS) : null)
});
export default FilterOptions;