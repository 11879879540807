export let MessageRelayTypes;
(function (MessageRelayTypes) {
  MessageRelayTypes["APP_DATA_UPDATE"] = "APP_DATA_UPDATE";
  MessageRelayTypes["APP_DATA_UPDATED"] = "APP_DATA_UPDATED";
  MessageRelayTypes["BROADCAST"] = "BROADCAST";
  MessageRelayTypes["FROM_WORKER"] = "FROM_WORKER";
  MessageRelayTypes["TO_WORKER"] = "TO_WORKER";
  MessageRelayTypes["TO_SERVICE_WORKER"] = "TO_SERVICE_WORKER";
})(MessageRelayTypes || (MessageRelayTypes = {}));
export function isAppDataUpdateRelay(message) {
  return message && message.metadata && message.metadata.messageRelayType === MessageRelayTypes.APP_DATA_UPDATE;
}
export function isAppDataUpdatedRelay(message) {
  return message && message.metadata && message.metadata.messageRelayType === MessageRelayTypes.APP_DATA_UPDATED;
}
export function isBroadcastRelay(message) {
  return message && message.metadata && message.metadata.messageRelayType === MessageRelayTypes.BROADCAST;
}
export function isToServiceWorkerRelay(message) {
  return message && message.metadata && message.metadata.messageRelayType === MessageRelayTypes.TO_SERVICE_WORKER;
}
export function isFromWorkerRelay(message) {
  return message && message.metadata && message.metadata.messageRelayType === MessageRelayTypes.FROM_WORKER;
}