'use es6';

import { AGENT_SENDER } from 'conversations-message-history/common-message-format/constants/cmfSenderTypes';
import { TYPE } from 'conversations-message-history/common-message-format/constants/keyPaths';
import { getSenderKeyFromType } from 'conversations-message-history/common-message-format/operators/getSenderKeyFromType';
import { Map as ImmutableMap } from 'immutable';
import { createSelector } from 'reselect';
import { getUserId } from 'conversations-thread-data/auth/public/selectors';
export const getCMFSender = createSelector([getUserId], userId => ImmutableMap({
  [TYPE]: AGENT_SENDER,
  [getSenderKeyFromType(AGENT_SENDER)]: parseInt(userId, 10)
}));