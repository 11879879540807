export const CHECK_CLOSE_TICKET = 'CHECK_CLOSE_TICKET';
export const CLOSE_CONVERSATION = 'CLOSE_CONVERSATION';
export const CLOSE_TICKET = 'CLOSE_TICKET';
export const LOAD_TICKET = 'LOAD_TICKET';
export const LOAD_TICKET_EXTERNAL_PROPERTIES = 'LOAD_TICKET_EXTERNAL_PROPERTIES';
export const CLEAR_ROADBLOCK = 'CLEAR_ROADBLOCK';
export const SAVE_PRODUCT_AREA = 'SAVE_PRODUCT_AREA';
export const SAVE_RESOLUTION = 'SAVE_RESOLUTION';
export const SAVE_ROADBLOCK = 'SAVE_ROADBLOCK';
export const SAVE_SUBJECT = 'SAVE_SUBJECT';
export const CANCEL_SUBJECT_EDIT = 'CANCEL_SUBJECT_EDIT';
export const START_SUBJECT_EDIT = 'START_SUBJECT_EDIT';