import { HelpDeskViewMembersPage } from '../../../view-members-schema/protected';
import { indexHelpDeskViewMembers } from '../help-desk-view-member/indexHelpDeskViewMembers';
function parseHelpDeskViewMembersPage(page) {
  const pagedResult = page.viewMemberPagedResult;
  return {
    latestPlaybackMessageTimestamp: page.latestPlaybackMessageTimestamp,
    totalCount: page.totalCount,
    results: pagedResult.results,
    offset: pagedResult.offset,
    hasMore: pagedResult.hasMore
  };
}
export function buildHelpDeskViewMembersPage(page) {
  const {
    latestPlaybackMessageTimestamp,
    hasMore,
    results,
    offset,
    totalCount
  } = parseHelpDeskViewMembersPage(page);
  const indexedMembers = indexHelpDeskViewMembers(results);
  return new HelpDeskViewMembersPage({
    hasMore,
    indexedMembers,
    offset,
    totalCount,
    latestAppliedPlaybackMessageTimestamp: latestPlaybackMessageTimestamp
  });
}
function parseSearchHelpDeskViewMembersPage(page) {
  const pagedResult = page.searchResults;
  return {
    totalCount: page.totalCount,
    results: pagedResult.results,
    offset: pagedResult.offset,
    hasMore: pagedResult.hasMore
  };
}
export function buildSearchHelpDeskViewMembersPage(page) {
  const {
    hasMore,
    results,
    offset,
    totalCount
  } = parseSearchHelpDeskViewMembersPage(page);
  const indexedMembers = indexHelpDeskViewMembers(results);
  return new HelpDeskViewMembersPage({
    hasMore,
    indexedMembers,
    offset,
    totalCount
  });
}