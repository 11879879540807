import { BucketAggregator } from './BucketAggregator';
const DEFAULT_INTERVAL_MS = 1000 * 60 * 15; // 15 minutes

const hasKeys = object => !!Object.keys(object).length;
export class AggregateDataReporter {
  /**
   * @callback reportDataCallback
   * @param {Object} reportData
   */
  /**
   * @param {reportDataCallback} reportData
   */
  constructor(reportData = () => {}, interval = DEFAULT_INTERVAL_MS, MetricAggregator = BucketAggregator) {
    this.sendRollup = () => {
      if (hasKeys(this.aggregateMetrics) || hasKeys(this.counts)) {
        const aggregateData = Object.keys(this.aggregateMetrics).reduce((acc, key) => Object.assign({}, acc, this.aggregateMetrics[key].getAggregateData()), {});
        this.reportData(Object.assign({}, aggregateData, this.counts));
        this.aggregateMetrics = {};
        this.counts = {};
      }
    };
    this.reportData = reportData;
    this.aggregateMetrics = {};
    this.counts = {};
    this.interval = interval;
    this.MetricAggregator = MetricAggregator;
  }

  /**
   * Start reporting on an interval
   */
  start() {
    if (this._intervalId) return;
    this._intervalId = setInterval(this.sendRollup, this.interval);
    window.addEventListener('beforeunload', this.sendRollup);
  }

  /**
   * Stop reporting on an interval
   */
  stop() {
    if (!this._intervalId) return;
    clearInterval(this._intervalId);
    delete this._intervalId;
    this.sendRollup();
    window.removeEventListener('beforeunload', this.sendRollup);
  }

  /**
   * @param {string} name
   * @param {Object} dataPoint
   * @param {number} dataPoint.startTime
   * @param {number} dataPoint.endTime
   * @param {number} dataPoint.duration
   * @param {('SUCCESS'|'FAILURE')} dataPoint.result
   */
  captureEvent(name, dataPoint) {
    if (dataPoint) {
      const {
        aggregateMetrics,
        MetricAggregator
      } = this;
      aggregateMetrics[name] = aggregateMetrics[name] || new MetricAggregator(name);
      aggregateMetrics[name].addDataPoint(dataPoint);
    }
    this.counts[name] = (this.counts[name] || 0) + 1;
  }
}