import { getDefaultViewType } from '../custom-views/customViewOperators';
import { getInternalName } from '../thread-lists/threadListOperators';

/**
 * @description Returns the view type. If it's a CustomView, this is the defaultViewType,
 * if it is a ThreadList then this is the internalName.
 */
export function getViewInternalType(view) {
  if (!view) return null;
  if ('customViewId' in view) {
    return getDefaultViewType(view);
  } else {
    return getInternalName(view);
  }
}