// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { buildFromLocalStorage } from 'conversations-internal-schema/last-viewed-inbox/operators/buildFromLocalStorage';
import { getLastViewedInboxId
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
} from 'conversations-internal-schema/last-viewed-inbox/operators/getters';
export const getLastViewedInboxFromLocalStorage = () => {
  const lastViewed = buildFromLocalStorage();
  const stringInboxId = getLastViewedInboxId(lastViewed);
  const inboxId = stringInboxId ? parseInt(stringInboxId, 10) : null;
  return inboxId;
};