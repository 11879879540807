import { useQuery } from 'data-fetching-client';
import Raven from 'raven-js';
import { FETCH_GENERIC_CHANNELS_WITH_CAPABILITIES_QUERY } from '../queries/fetchGenericChannelsWithCapabilitiesQuery';
function safeStringify(obj) {
  try {
    return JSON.stringify(obj);
  } catch (e) {
    return '';
  }
}
function onError(apolloError) {
  Raven.captureException(new Error(apolloError.message), {
    extra: {
      apolloError: safeStringify(apolloError) || apolloError.message
    }
  });
}
export const useAsyncGenericChannelsWithCapabilities = ({
  deferred
} = {}) => {
  const {
    loading,
    error,
    data: responseData
  } = useQuery(FETCH_GENERIC_CHANNELS_WITH_CAPABILITIES_QUERY, {
    onError,
    skip: deferred
  });
  const data = responseData ? responseData.genericChannelsWithCapabilities : undefined;
  return {
    loading,
    error,
    data
  };
};