// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'ui-a... Remove this comment to see the full error message
import { getInstallStatus } from 'ui-addon-scriptloader/InstallStatusHelper';
import { Map as ImmutableMap } from 'immutable';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { FETCH_INSTALL_STATUS } from '../constants/asyncActionTypes';
import { buildRequestErrorMetaObject } from 'conversations-error-reporting/error-actions/builders/buildRequestErrorMetaObject';
export const fetchInstallStatus = createAsyncAction({
  requestFn: getInstallStatus,
  actionTypes: FETCH_INSTALL_STATUS,
  toRecordFn: status => {
    return ImmutableMap(status);
  },
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type '{}'.
  failureMetaActionCreator: ({
    error
  }) => buildRequestErrorMetaObject({
    error
  })
});