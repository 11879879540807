import { useQuery } from 'data-fetching-client';
import { FETCH_SMS_CAMPAIGN_DETAILS_QUERY_NAME, FETCH_SMS_CAMPAIGN_DETAILS_QUERY } from '../queries/fetchSmsCampaignDetailsQuery';
export const useSmsCampaignDetails = ({
  contentId
}) => {
  const {
    data,
    loading,
    error
  } = useQuery(FETCH_SMS_CAMPAIGN_DETAILS_QUERY, {
    variables: {
      contentId
    }
  });
  const campaignDetails = data && data[FETCH_SMS_CAMPAIGN_DETAILS_QUERY_NAME];
  return {
    campaignDetails,
    loading,
    error
  };
};