import { createMetricsFactory } from 'metrics-js';
import { BucketAggregator } from './BucketAggregator';
const DEFAULT_INTERVAL_MS = 1000 * 60 * 15; // 15 minutes

const hasKeys = object => !!Object.keys(object).length;
export const Metrics = createMetricsFactory('realtime-communication');
export class AggregateDataReporter {
  // TODO

  constructor(reportData = __data => {}, interval = DEFAULT_INTERVAL_MS, MetricAggregatorConstructor = BucketAggregator) {
    this.sendRollup = () => {
      if (hasKeys(this.aggregateMetrics) || hasKeys(this.counts)) {
        const aggregateData = Object.keys(this.aggregateMetrics).reduce((acc, key) => Object.assign({}, acc, this.aggregateMetrics[key].getAggregateData()), {});
        this.reportData(Object.assign({}, aggregateData, this.counts, this.additionalContext));
        this.aggregateMetrics = {};
        this.counts = {};
      }
    };
    this.aggregateMetrics = {};
    this.counts = {};
    this.interval = interval;
    this.MetricAggregator = MetricAggregatorConstructor;
    this.reportData = reportData;
    this.additionalContext = {};
  }

  /**
   * Start reporting on an interval
   */
  start() {
    if (this._intervalId) return;
    this._intervalId = setInterval(this.sendRollup, this.interval);
    window.addEventListener('beforeunload', this.sendRollup);
  }

  /**
   * Stop reporting on an interval
   */
  stop() {
    if (!this._intervalId) return;
    clearInterval(this._intervalId);
    delete this._intervalId;
    this.sendRollup();
    window.removeEventListener('beforeunload', this.sendRollup);
  }
  captureEvent(name, metaData) {
    if (metaData) {
      const {
        aggregateMetrics
      } = this;
      aggregateMetrics[name] = aggregateMetrics[name] || new this.MetricAggregator(name);
      aggregateMetrics[name].addDataPoint(metaData);
      Metrics.timer(name).update(metaData.endTime - metaData.startTime);
    } else {
      Metrics.counter(name).increment();
    }
    this.counts[name] = (this.counts[name] || 0) + 1;
  }
  setAdditionalContext(additionalContext = {}) {
    this.additionalContext = Object.assign({}, this.additionalContext, additionalContext);
  }
}