import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestFailedWithError } from 'conversations-async-data/async-data/operators/requestFailedWithError';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { useCallback, useEffect, useState } from 'react';
import { fetchInboxChannels } from '../client/fetchInboxChannels';
import { isOutOfSync, isUninitialized } from 'conversations-async-data/async-data/operators/statusComparators';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { updateAsyncData } from 'conversations-async-data/async-data/operators/updateAsyncData';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';

// Emulate a redux store, to avoid having to fetch this each time
let connectedChannels = {
  hasMore: false,
  offset: 0,
  results: []
};
export const useInboxChannels = inboxId => {
  const defaultData = connectedChannels;
  const [asyncData, setAsyncData] = useState(new AsyncData({
    data: defaultData
  }));
  const deferred = !inboxId;
  const fetcher = useCallback(() => {
    fetchInboxChannels(inboxId).then(resp => {
      connectedChannels = resp;
      setAsyncData(requestSucceededWithOperator(() => connectedChannels, asyncData));
    }).catch(err => {
      setAsyncData(requestFailedWithError(err, asyncData));
      reportError({
        error: err
      });
    });
  }, [asyncData, inboxId]);
  useEffect(() => {
    const isMissingData = isOutOfSync(asyncData) || isUninitialized(asyncData) || connectedChannels.results[0] && connectedChannels.results[0].inboxId !== inboxId;
    if (!deferred && isMissingData) {
      fetcher();
    }
  }, [deferred, asyncData, defaultData, fetcher, inboxId]);
  useEffect(() => {
    if (getData(asyncData) !== connectedChannels) {
      setAsyncData(updateAsyncData(() => connectedChannels, asyncData));
    }
  }, [setAsyncData, asyncData, inboxId]);
  return {
    asyncChannels: asyncData,
    retryFailed: fetcher
  };
};