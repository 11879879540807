import Raven from 'raven-js';
const safelyCall = method => {
  function callImpl(e, opts) {
    let returnValue;
    try {
      returnValue = Raven[method](e, opts);
    } catch (error) {
      setTimeout(() => {
        throw error;
      }, 0);
    }
    return returnValue;
  }
  return callImpl;
};
export const captureException = safelyCall('captureException');
export const setExtraContext = safelyCall('setExtraContext');