import http from 'hub-http/clients/apiClient';
const TIMEOUT = 45000;
export let fetchCalleeOmnibus = ({
  objectId,
  objectTypeId
}) => {
  const url = '/calls/v1/callees/omnibus';
  return http.get(`${url}/${objectTypeId}/${objectId}`, {
    timeout: TIMEOUT,
    query: {
      clienttimeout: TIMEOUT
    }
  });
};
export const setFetchCalleeOmnibusForTesting = fetchFunction => {
  fetchCalleeOmnibus = fetchFunction;
};