import { useCallback, useEffect } from 'react';
import { purgeDrafts } from '../local-storage/purgeDrafts';
const makeSessionStorageKey = keyBase => {
  return `draft-purge-history::${keyBase}`;
};
const ONE_DAY_MS = 24 * 60 * 60 * 1000;
const DRAFT_PURGE_INTERVAL_MS = ONE_DAY_MS;
export function useDraftPurger({
  localStorageKeyPrefix,
  currentVersion,
  deserializeData,
  emptyChecker,
  ageLimitInMs,
  purgeIntervalMs = DRAFT_PURGE_INTERVAL_MS
}) {
  const sessionStorageKey = makeSessionStorageKey(localStorageKeyPrefix);
  const getShouldPurge = useCallback(() => {
    try {
      const rawPurgeEntry = sessionStorage.getItem(sessionStorageKey);
      if (!rawPurgeEntry) {
        return true;
      }
      const purgeEntry = JSON.parse(rawPurgeEntry);
      const {
        timestamp
      } = purgeEntry;
      if (Date.now() - timestamp >= purgeIntervalMs) {
        return true;
      }
    } catch (e) {
      return false;
    }
    return false;
  }, [sessionStorageKey, purgeIntervalMs]);
  const setHasPurged = useCallback(() => {
    try {
      const purgeEntry = {
        timestamp: Date.now()
      };
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(purgeEntry));
    } catch (error) {
      // no-op
    }
  }, [sessionStorageKey]);
  useEffect(() => {
    const shouldPurge = getShouldPurge();
    if (!shouldPurge) {
      return;
    }
    purgeDrafts({
      deserializeData,
      emptyChecker,
      localStorageKeyPrefix,
      currentVersion,
      ageLimitInMs
    });
    setHasPurged();
  }, [localStorageKeyPrefix, currentVersion, deserializeData, emptyChecker, ageLimitInMs, getShouldPurge, setHasPurged]);
}