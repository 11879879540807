import { useQuery, registerQuery } from 'data-fetching-client';
import { fetchThreadListMemberWithTimeline } from '../clients/fetchThreadListMemberWithTimeline';
const FETCH_THREAD_LIST_MEMBER_TIMELINE = 'fetchThreadListMemberWithTimeline';
const FETCH_THREAD_LIST_MEMBER_TIMELINE_QUERY = registerQuery({
  fieldName: FETCH_THREAD_LIST_MEMBER_TIMELINE,
  args: ['threadId', 'ticketId'],
  fetcher: fetchThreadListMemberWithTimeline
});
export const useThreadListMemberWithTimeline = ({
  threadId,
  ticketId,
  onCompleted
}) => {
  const {
    data,
    loading,
    error
  } = useQuery(FETCH_THREAD_LIST_MEMBER_TIMELINE_QUERY, {
    variables: {
      threadId,
      ticketId
    },
    skip: !threadId,
    onCompleted
  });
  return {
    threadDetailsWithTimeline: data && data[FETCH_THREAD_LIST_MEMBER_TIMELINE],
    loading,
    error
  };
};