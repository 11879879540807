import { buildError } from '../../error-reporting/builders/buildError';
import { keplerAlerting } from '../../error-reporting/keplerAlerting';
import { checkNetwork } from '../../network/clients/checkNetwork';
import { REQUEST_TIMEOUT } from '../../errors/constants/statusCodes';
export function reportAndCheckNetworkOnAblyTimeout(error) {
  const explicitTimeout = error.statusCode === REQUEST_TIMEOUT;

  /* If an rest request (including authCallback) fails due to a timeout, network error, or a token of an invalid format
   * this request will result in a code of 40170.
   *
   * This is potentially a timeout and should check the network
   *
   * See: https://docs.ably.io/client-lib-development-guide/features/#RSA4e
   */

  const potentialRestRequestTimeout = error.code === 40170;
  if (explicitTimeout || potentialRestRequestTimeout) {
    return checkNetwork().then(({
      online
    }) => {
      if (online) throw error;
      throw buildError('Network Unavailable', error);
    });
  }
  keplerAlerting(error);
  throw error;
}