import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { isSucceeded } from 'conversations-async-data/async-data/operators/statusComparators';
import { hasHostedEmailAccess } from 'conversations-thread-data/auth/public/selectors';
import { useConnectedAccounts } from 'conversations-thread-data/connected-accounts/public';
import { findAccountByEmail, isAccountHubspotHosted } from 'composer-data/connected-accounts/public/operators';
import { setHostedFromAddress } from 'conversations-thread-view/appeals/actions/setHostedFromAddress';

/*
 * In case the user doesn't have access to hosted accounts, dispach an and action
 * to show an alert in the thread view.
 */
export const useHostedAccountAlert = ({
  inboxId,
  fromAddress,
  isComposer
}) => {
  const dispatch = useDispatch();
  const hostedEmailEnabled = useSelector(hasHostedEmailAccess);
  const {
    asyncConnectedAccounts
  } = useConnectedAccounts({
    inboxId
  });
  useEffect(() => {
    // Don't show the alert on the thread view for the compose use case.
    if (isComposer) {
      return;
    }
    if (hostedEmailEnabled) {
      return;
    }
    if (!isSucceeded(asyncConnectedAccounts)) {
      return;
    }
    const connectedAccounts = getData(asyncConnectedAccounts);
    const connectedAccount = findAccountByEmail(connectedAccounts, fromAddress);
    const isHosted = isAccountHubspotHosted(connectedAccount);
    const hostedFromAddress = isHosted ? fromAddress : null;
    dispatch(setHostedFromAddress({
      fromAddress: hostedFromAddress
    }));
  }, [dispatch, hostedEmailEnabled, asyncConnectedAccounts, fromAddress, isComposer]);
};