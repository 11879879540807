import { getRecipients, getSenders } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { getDeliveryIdentifierValue } from 'conversations-message-history/common-message-format/operators/deliveryIdentifierGetters';
import { getSingleDeliveryIdentifier } from 'conversations-message-history/common-message-format/operators/recipientGetters';
import { getDeliveryIdentifier } from 'conversations-message-history/common-message-format/operators/senderGetters';
import { getCommonMessage } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
import Raven from 'raven-js';
import memoize from 'transmute/memoize';
const memoizedReportMissingDeliveryIdentifiers = memoize((asyncChannelInstances, recipients, senders) => {
  try {
    Raven.captureException(new Error(`Missing delivery identifiers on message`), {
      extra: {
        asyncChannelInstances: asyncChannelInstances.toJS(),
        recipients: recipients.toJS(),
        senders: senders.toJS()
      }
    });
  } catch (e) {
    // don't care
  }
});
export const missingDeliveryIdentifiers = ({
  asyncChannelInstances,
  reply
}) => {
  const message = getCommonMessage(reply);
  const recipients = message && getRecipients(message);
  const recipientsHaveDeliveryIdentifiers = !!recipients && recipients.size && recipients.every(recipient => {
    const deliveryIdentifier = getSingleDeliveryIdentifier(recipient);
    return Boolean(!!deliveryIdentifier && getDeliveryIdentifierValue(deliveryIdentifier));
  });
  const senders = message && getSenders(message);
  const sendersHaveDeliveryIdentifiers = !!senders && senders.size && senders.every(sender => {
    const deliveryIdentifier = getDeliveryIdentifier(sender);
    return Boolean(deliveryIdentifier && getDeliveryIdentifierValue(deliveryIdentifier));
  });
  const isValid = recipientsHaveDeliveryIdentifiers && sendersHaveDeliveryIdentifiers;
  if (!isValid) {
    memoizedReportMissingDeliveryIdentifiers(asyncChannelInstances, recipients, senders);
    return {
      disabled: true,
      sendDisabledReason: 'MISSING_DELIVERY_IDENTIFIERS'
    };
  }
  return null;
};