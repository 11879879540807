import { getSortTimestamp } from 'find-and-filter-data/view-members-schema/protected';
import { OrderedMap } from 'immutable';
import { useSelector } from 'react-redux';
import { useRealtimeViewMemberUpdates } from '../../view-member-optimistic-updates/public';
import { applyViewMemberUpdates, useViewMembersBase, selectHasViewMembersFailed, selectIsViewMembersPageLoading, selectViewMembersPageById } from '../../view-members-data/protected';
import { getHasMore, getMembers, getOffsetId, getOffsetTimestamp, getTotalCount } from '../../view-members-schema/protected';
import { fetchSearchAndFilterViewMembers } from './filterResultsSlice';
export const DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER = 'DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER';

/**
 *
 * @description This hook returns HelpDeskViewMembers that match
 * the provided filters.
 *
 */

const directionMap = {
  DESC: 1,
  ASC: -1
};

/**
 * After a realtime update comes in for a View that is currently open,
 * sort the preview card to the top of the list instead of appending it
 * to the bottom of the list where the update would not be seen.
 */
const realtimeUpdateSort = sortDirection => (vm1, vm2) => {
  return (getSortTimestamp(vm1) || 0) < (getSortTimestamp(vm2) || 0) ? directionMap[sortDirection] : -directionMap[sortDirection];
};
export const useSearchAndFilterViewMembers = ({
  customViewId = DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER,
  filterGroups,
  inboxId,
  searchQuery,
  skip,
  sortDirection = 'DESC'
}) => {
  //Select existing data from the store
  const data = useSelector(state => selectViewMembersPageById(state, {
    viewId: `${customViewId}`
  }));
  const loading = useSelector(selectIsViewMembersPageLoading);
  const viewMembers = (data && getMembers(data) || OrderedMap({})).sort(realtimeUpdateSort(sortDirection));
  const isInitialPageReceived = !!(data && getMembers(data));
  const hasMore = !!(data && getHasMore(data));
  const offsetTimestamp = data && getOffsetTimestamp(data) || undefined;
  const offsetId = data && getOffsetId(data);
  const totalCount = data ? getTotalCount(data) : 0;
  const failed = useSelector(selectHasViewMembersFailed);

  //Fetch results when a change is made to the view ID, filters, inbox ID, search query, or sort direction
  const {
    fetchMore,
    refetch
  } = useViewMembersBase({
    customViewId,
    fetcher: fetchSearchAndFilterViewMembers,
    filterGroups,
    hasMore,
    inboxId,
    loading,
    offsetId,
    offsetTimestamp,
    searchQuery,
    skip,
    sortDirection
  });
  const {
    viewMemberUpdates
  } = useRealtimeViewMemberUpdates();
  return {
    error: null,
    failed,
    fetchMore,
    hasMore,
    isInitialPageReceived,
    loading,
    refetch,
    totalCount,
    viewMembers: applyViewMemberUpdates({
      viewMemberUpdates,
      viewMembers
    })
  };
};