import { Record, List } from 'immutable';
const Visitor = Record({
  allEmails: List(),
  avatar: null,
  canonicalVid: null,
  city: null,
  company: null,
  contact: false,
  email: null,
  firstName: '',
  jobTitle: null,
  lastName: '',
  phone: null,
  vid: null
}, 'Visitor');
export default Visitor;