'use es6';

import { getUsageTrackingChannel } from 'conversations-thread-data/usage-tracking/public/trackerChannelIdMapping';
export const getChannelSwitch = ({
  nextGenericChannelId,
  currentGenericChannelId
}) => ({
  key: 'channel-switched',
  properties: {
    action: 'Channel switched',
    currentChannel: getUsageTrackingChannel(currentGenericChannelId),
    switchedChannel: getUsageTrackingChannel(nextGenericChannelId)
  }
});