import { LOAD_TOOL_LINKS } from './ActionTypes';
import { getToolLinksByThreadId } from '../utils/api/ToolLinksApi';
export const loadToolLinksInfo = threadId => {
  return dispatch => {
    dispatch({
      type: LOAD_TOOL_LINKS,
      meta: 'request'
    });
    return getToolLinksByThreadId(threadId).then(payload => dispatch({
      type: LOAD_TOOL_LINKS,
      meta: 'response',
      payload
    }), payload => dispatch({
      type: LOAD_TOOL_LINKS,
      error: true,
      payload
    }));
  };
};