import { handleActions } from 'redux-actions';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { getInboxId } from 'conversations-internal-schema/inbox/operators/getInboxId';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { buildFromLocalStorage } from 'conversations-internal-schema/last-viewed-inbox/operators/buildFromLocalStorage';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { updateLastViewedInbox } from 'conversations-internal-schema/last-viewed-inbox/operators/setters';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { updateLocalStorageLastViewedInboxes } from 'conversations-internal-schema/last-viewed-inbox/operators/updateLocalStorageLastViewedInboxes';
import { SET_CURRENT_INBOX } from 'conversations-inbox-lib/unified-inbox/public/constants';
import { SET_CURRENT_THREAD_LIST } from 'conversations-inbox-lib/views/public/constants';
import { getId, isTrashView, isFilteredView } from 'find-and-filter-data/views-schema/public';
import { getCurrentInboxId, getCurrentViewId } from 'conversations-inbox-lib/omnibus/public/operators';
import { FETCH_FROM_OMNI_BUS as omnibusActions, POLL_OMNIBUS } from 'conversations-inbox-lib/omnibus/public/constants';
import { fetchInboxActionTypes } from '../../unified-inbox/constants/fetchInboxActionTypes';
import { getLastViewedInboxFromLocalStorage } from 'conversations-inbox-lib/utils/last-viewed-view-and-inbox/getLastViewedInboxFromLocalStorage';
import { getLastViewedViewFromLocalStorage } from 'conversations-inbox-lib/utils/last-viewed-view-and-inbox/getLastViewedViewFromLocalStorage';
export const inboxAndViewLocalstorageInitialState = buildFromLocalStorage();
const inboxAndViewLocalstorage = handleActions({
  [omnibusActions.SUCCEEDED](state, action) {
    const omnibusResponse = action.payload.data;
    const viewId = getCurrentViewId(omnibusResponse);
    const inboxId = getCurrentInboxId(omnibusResponse);
    if (!viewId || !inboxId) return state;
    const updatedLastViewedInboxes = updateLastViewedInbox({
      inboxId,
      viewId
    }, state);
    updateLocalStorageLastViewedInboxes(updatedLastViewedInboxes);
    return updatedLastViewedInboxes;
  },
  [POLL_OMNIBUS.SUCCEEDED](state, action) {
    const omnibusResponse = action.payload.data;
    const viewId = getCurrentViewId(omnibusResponse);
    const inboxId = getCurrentInboxId(omnibusResponse);
    const alreadyInitialized = state !== inboxAndViewLocalstorageInitialState;
    if (alreadyInitialized || !viewId || !inboxId) return state;
    const updatedLastViewedInboxes = updateLastViewedInbox({
      inboxId,
      viewId
    }, state);
    updateLocalStorageLastViewedInboxes(updatedLastViewedInboxes);
    return updatedLastViewedInboxes;
  },
  [fetchInboxActionTypes.SUCCEEDED](state, action) {
    const viewId = action.payload.requestArgs.viewId;
    const inboxId = action.payload.requestArgs.inboxId;
    if (!viewId || !inboxId) return state;
    const updatedLastViewedInboxes = updateLastViewedInbox({
      inboxId,
      viewId
    }, state);
    updateLocalStorageLastViewedInboxes(updatedLastViewedInboxes);
    return updatedLastViewedInboxes;
  },
  [SET_CURRENT_INBOX](state, action) {
    const inbox = action.payload;
    const inboxId = getInboxId(inbox);
    const lastViewId = getLastViewedViewFromLocalStorage();
    if (!inboxId || !lastViewId) return state;
    const updatedLastViewedInboxes = updateLastViewedInbox({
      inboxId,
      viewId: lastViewId
    }, state);
    updateLocalStorageLastViewedInboxes(updatedLastViewedInboxes);
    return updatedLastViewedInboxes;
  },
  [SET_CURRENT_THREAD_LIST](state, action) {
    const view = action.payload.threadList;
    const inboxId = getLastViewedInboxFromLocalStorage();
    const viewId = getId(view);
    if (!inboxId || !viewId) return state;
    const updatedLastViewedInboxes = updateLastViewedInbox({
      inboxId,
      viewId
    }, state);
    if (!isTrashView(view) && !isFilteredView(view)) {
      updateLocalStorageLastViewedInboxes(updatedLastViewedInboxes);
    }
    return updatedLastViewedInboxes;
  }
}, inboxAndViewLocalstorageInitialState);
export default inboxAndViewLocalstorage;