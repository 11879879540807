import { fetchGoalsOnboardingData } from '../clients/goalsOnboardingClient';
import { registerQuery, useQuery } from 'data-fetching-client';
import { useMemo } from 'react';
const HAS_CONFIGURED_SLAS = registerQuery({
  fieldName: 'goalsOnboarding',
  fetcher: fetchGoalsOnboardingData
});
export const useHasConfiguredSLAs = () => {
  const {
    data,
    loading,
    error
  } = useQuery(HAS_CONFIGURED_SLAS);
  const goalsOnboarding = data && data.goalsOnboarding;
  const hasConfiguredSLAs = useMemo(() => {
    return goalsOnboarding ? goalsOnboarding.goalsEverCreated : false;
  }, [goalsOnboarding]);
  return {
    hasConfiguredSLAs,
    loading,
    error
  };
};