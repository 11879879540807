import { Map as ImmutableMap } from 'immutable';
import { UNINITIALIZED } from 'calling-lifecycle-internal/async-flux-utils/constants/apiLoadingStates';
import { getOrCreateUniversalCallingStore } from 'calling-global-api/universal-calling-store/UniversalCallingStore';
import { CallDirection } from 'calling-internal-common/constants/CallDirection';
const parseRawState = state => {
  return ImmutableMap(state);
};
const getOrCreateWidgetStore = () => {
  return getOrCreateUniversalCallingStore({
    name: 'widgetStore',
    initialState: ImmutableMap({
      callStatus: UNINITIALIZED,
      isThirdPartyActive: false,
      thirdPartyCallDirection: CallDirection.OUTBOUND
    }),
    parseRawState,
    version: 1,
    eventListeners: []
  });
};
export default getOrCreateWidgetStore;