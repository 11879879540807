import { List, Map as ImmutableMap } from 'immutable';
import get from 'transmute/get';
import { FILES } from '../constants/attachmentTypes';
import { getAttachments } from './getAttachments';
import { getFileUsageTypes } from './fileAttachmentGetters';
export const getFileUsageTypesInMessage = message => {
  const attachments = getAttachments(message) || List();
  const fileAttachment = attachments.find(attachment => {
    return get('@type', attachment) === FILES;
  });
  if (!fileAttachment) return ImmutableMap();
  return getFileUsageTypes(fileAttachment);
};