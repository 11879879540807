import { useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import actionStates from './actionStates';
import panelState from './panelState';
import ticket from './ticket';
import ticketExternalProperties from './ticketExternalProperties';
const rootReducer = combineReducers({
  actionStates,
  panelState,
  ticket,
  ticketExternalProperties
});
export function useAppDispatch() {
  return useDispatch();
}
export function useAppSelector(selector) {
  return useSelector(selector);
}
export default rootReducer;