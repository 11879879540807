import { useQuery } from 'data-fetching-client';
import { fetchWhatsAppChannelIsActive } from '../clients/fetchWhatsAppChannel';
const getHasMatchingScopes = scopes => {
  return scopes.includes('messaging-access') && !scopes.includes('messaging-trusted');
};
export const useWhatsAppAccessRevoked = ({
  scopes
}) => {
  const {
    data
  } = useQuery(fetchWhatsAppChannelIsActive);
  if (!scopes) {
    return false;
  }
  const hasMatchingScopes = getHasMatchingScopes(scopes);
  const hasWhatsAppChannelInstances = data && data.whatsapp_channel_instances && data.whatsapp_channel_instances.length;
  return Boolean(hasMatchingScopes && hasWhatsAppChannelInstances);
};