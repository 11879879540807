'use es6';

import Raven from 'raven-js';
export const logPartialOccurrence = (queryLocation, options) => {
  console.warn(`customer-data-sidebar: partial query response ${queryLocation}`, options.message);
};
export const logQueryError = (queryLocation, options) => {
  Raven.captureMessage(`customer-data-sidebar: query error ${queryLocation}`, {
    extra: Object.assign({
      status: 'query error',
      queryLocation,
      usingGraphQL: true
    }, options)
  });
  console.error(`customer-data-sidebar: query error ${queryLocation}`, options.message);
};