import { Map as ImmutableMap } from 'immutable';
import { handleActions } from 'redux-actions';
import { FETCH_INSTALL_STATUS } from '../constants/asyncActionTypes';
const initialState = ImmutableMap({
  isInstalled: true
});
export default handleActions({
  [FETCH_INSTALL_STATUS.SUCCEEDED](state, action) {
    return action.payload.data;
  }
}, initialState);