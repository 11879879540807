import http from 'hub-http/clients/apiClient';
export const fetchRoutingTeams = inboxId => http.get('messages/v2/users/teams/hierarchy', {
  query: {
    inboxId
  }
});
export const fetchTeams = () => http.get('app-users/v1/teams', {
  query: {
    includeHierarchy: true
  }
});
export const fetchTeamsByIds = ids => http.put('app-users/v1/teams/bulk-get', {
  data: ids
});