// gimme: conversations-inbox-ui 06/10/2020 24a2e66917cc934433dd111db7e06fd02943ca6a

import { getSortedUnpublishedMessagesForThreadId } from 'conversations-thread-data/pubsub/public/selectors/getSortedUnpublishedMessagesForThreadId';
import { createSelector } from 'reselect';
import { getMessage } from 'conversations-message-history/unpublished-messages/operators/getters';
import { isTypingMessage } from 'conversations-message-history/typing-indicator/operators/isTypingMessage';
export const getFilteredUnpublishedMessages = createSelector([getSortedUnpublishedMessagesForThreadId], unpublishedMessages => {
  return unpublishedMessages.filter(message => {
    const metadata = getMessage(message);
    return Boolean(metadata && !isTypingMessage(metadata));
  });
});