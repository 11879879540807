import { useMutation, useQuery } from 'data-fetching-client';
import { dismissPromptMutation, fetchPromptDataQuery, markPromptAsShownMutation } from '../api/userPrompts.queries';
import { useCallback } from 'react';
export const usePrompt = promptType => {
  const {
    data: promptData
  } = useQuery(fetchPromptDataQuery, {
    variables: {
      promptType
    }
  });
  const [markPromptAsShownMutate] = useMutation(markPromptAsShownMutation);
  const markPromptAsShown = useCallback(() => markPromptAsShownMutate({
    variables: {
      promptType
    }
  }), [markPromptAsShownMutate, promptType]);
  const [dismissPromptMutate] = useMutation(dismissPromptMutation);
  const dismissPrompt = useCallback(() => dismissPromptMutate({
    variables: {
      promptType
    }
  }), [dismissPromptMutate, promptType]);
  return {
    promptData: promptData && promptData.fetchPromptData,
    markPromptAsShown,
    dismissPrompt
  };
};