import { useCallback, useEffect, useState } from 'react';
export function useMetadataEditorHeight({
  onHeightChange = () => {}
}) {
  const [animatedFieldSizes, setAnimatedFieldSizes] = useState({});
  const totalHeight = Object.keys(animatedFieldSizes).reduce((acc, fieldName) => acc + animatedFieldSizes[fieldName], 0);
  useEffect(() => {
    onHeightChange(totalHeight);
  }, [onHeightChange, totalHeight]);
  const setAnimatedFieldSize = useCallback((key, size) => {
    setAnimatedFieldSizes(fieldSizes => Object.assign({}, fieldSizes, {
      [key]: size
    }));
  }, []);
  return {
    setAnimatedFieldSize
  };
}