import http from 'conversations-http/clients/http';
import { registerQuery } from 'data-fetching-client';
export const TEAM_USERS_QUERY_NAME = 'users';
export const TEAM_USERS_QUERY = registerQuery({
  fieldName: TEAM_USERS_QUERY_NAME,
  args: ['inboxId', 'agentIds'],
  fetcher({
    inboxId,
    agentIds
  }) {
    return http.put(`messages/v2/users/inbox/${inboxId}`, {
      data: agentIds
    });
  }
});