import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchSpamThreadListService } from './fetchSpamThreadListService';

/**
 * A redux thunk for fetching a specific custom view by id
 */
export const fetchSpamThreadList = createAsyncThunk('views/fetchSpamThreadList', () => fetchSpamThreadListService());
export const selectSpamThreadListState = state => state.findAndFilterData.spamThreadList;
const initialState = {
  data: {},
  state: 'UNINITIALIZED'
};
export const spamThreadListSlice = createSlice({
  name: 'views',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchSpamThreadList.pending, state => {
      state.data = initialState.data;
      state.state = 'STARTED';
    }).addCase(fetchSpamThreadList.rejected, state => {
      state.state = 'FAILED';
    }).addCase(fetchSpamThreadList.fulfilled, (state, action) => {
      state.data = action.payload.views;
      state.state = 'SUCCEEDED';
    });
  },
  reducers: {}
});