import { List as ImmutableList } from 'immutable';
import get from 'transmute/get';
import { ChannelTypes } from 'conversations-internal-schema/constants/ChannelTypes';
import { getEmailAddressRecordFromConnectedAccount } from 'conversations-thread-data/common-reply-metadata/public/operators/getEmailAddressRecordFromConnectedAccount';
import { COMPOSITION_THREAD_ID } from 'conversations-thread-data/communicator/constants/compositionThreadId';
import { COMPOSITION } from 'conversations-thread-data/common-reply/public/constants/commonReplyTypes';
import { buildReplyId } from './buildReplyId';
// @ts-expect-error module not typed

import CommonReply from 'conversations-thread-data/common-reply/public/records/CommonReply';
import { getFallbackAccount } from 'conversations-thread-data/connected-accounts/public';
import { buildCommonMessageForEmptyReply } from './buildCommonMessageForEmptyReply';
const {
  EMAIL
} = ChannelTypes;
export const buildEmptyEmailComposition = ({
  to = ImmutableList(),
  channelInstances,
  connectedAccounts,
  inboxId,
  replyId,
  userId
}) => {
  const source = to.size ? `${COMPOSITION}-${inboxId}-${to.map(get('id')).join('-')}` : `${COMPOSITION}-${inboxId}`;
  const fallbackAccount = getFallbackAccount({
    channelInstances,
    connectedAccounts
  });
  const fromEmailAddressRecord = getEmailAddressRecordFromConnectedAccount(fallbackAccount);
  const commonMessage = buildCommonMessageForEmptyReply({
    to,
    fromEmailAddressRecord,
    channelInstanceId: fallbackAccount && fallbackAccount.channelInstanceId,
    userId
  });
  return CommonReply({
    replyId: replyId || buildReplyId(EMAIL, source, COMPOSITION_THREAD_ID),
    commonMessage
  });
};