import { CALLING_GENERIC_CHANNEL_ID, EMAIL_GENERIC_CHANNEL_ID, FB_MESSENGER_GENERIC_CHANNEL_ID, FORMS_GENERIC_CHANNEL_ID, LIVE_CHAT_GENERIC_CHANNEL_ID, WHATSAPP_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
export const getIconForOriginatingGenericChannelId = channelId => {
  switch (channelId) {
    case LIVE_CHAT_GENERIC_CHANNEL_ID:
      return 'messages';
    case FB_MESSENGER_GENERIC_CHANNEL_ID:
      return 'socialFacebookMessenger';
    case EMAIL_GENERIC_CHANNEL_ID:
      return 'email';
    case FORMS_GENERIC_CHANNEL_ID:
      return 'forms';
    case WHATSAPP_GENERIC_CHANNEL_ID:
      return 'socialWhatsapp';
    case CALLING_GENERIC_CHANNEL_ID:
      return 'calling';
    default:
      return 'databases';
  }
};