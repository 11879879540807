import formatPhoneNumber from 'I18n/utils/formatPhoneNumber';
import { useMemo } from 'react';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { isLoading } from 'conversations-async-data/async-data/operators/statusComparators';
import { getDisplayGlobally, getFriendlyFromName, getResolvedFromName, getSendFromEmail
// @ts-expect-error module not typed
} from 'conversations-internal-schema/connected-email-account/operators/connectedEmailAccountGetters';
import { getDeliveryIdentifierType, getDeliveryIdentifierValue } from 'conversations-message-history/common-message-format/operators/deliveryIdentifierGetters';
import { useChannelInstancesForChannel } from 'conversations-thread-data/channel-instances/public/hooks';
import { useConnectedAccounts } from 'conversations-thread-data/connected-accounts/public';
import { EMAIL_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
import { getId, getIsActive, getName, getInstanceDeliveryIdentifier } from 'conversations-thread-data/channel-instances/public/operators';
import { findAccountByEmail } from 'composer-data/connected-accounts/public/operators';
const DEFERRED_GENERIC_CHANNEL_ID = 0;
function formatDeliveryIdentifier(deliveryIdentifier) {
  const type = getDeliveryIdentifierType(deliveryIdentifier);
  const value = getDeliveryIdentifierValue(deliveryIdentifier);
  switch (type) {
    case 'HS_PHONE_NUMBER':
      return formatPhoneNumber(value, '');
    default:
      return value;
  }
}
function sortByDisabledStateThenAlpha(optionA, optionB) {
  if (optionA.disabled === optionB.disabled) {
    return optionA.dropdownText < optionB.dropdownText ? -1 : 1;
  }
  return optionA.disabled ? 1 : -1;
}
function buildChannelInstanceOption({
  connectedAccount,
  instance
}) {
  const deliveryIdentifier = getInstanceDeliveryIdentifier(instance);
  const instanceName = getName(instance);
  const friendlyName = connectedAccount ? getResolvedFromName(connectedAccount) || getFriendlyFromName(connectedAccount) : instanceName;
  const connectedAccountSendFromAlias = connectedAccount && getSendFromEmail(connectedAccount);
  const formattedDeliveryIdentifier = deliveryIdentifier && formatDeliveryIdentifier(deliveryIdentifier);
  const displayDeliveryIdentifier = connectedAccountSendFromAlias || formattedDeliveryIdentifier;
  const dropdownText = instanceName === displayDeliveryIdentifier ? displayDeliveryIdentifier : `${instanceName} ( ${displayDeliveryIdentifier} )`;
  const text = friendlyName && displayDeliveryIdentifier ? `${friendlyName} ( ${displayDeliveryIdentifier} )` : displayDeliveryIdentifier || friendlyName;
  const disabled = !getIsActive(instance);
  const instanceId = getId(instance);
  return {
    id: instanceId,
    disabled,
    dropdownText,
    text,
    value: instanceId
  };
}
function buildOptions({
  connectedAccounts,
  channelInstances
}) {
  const dropdownOptions = [];
  channelInstances.forEach(instance => {
    const connectedAccount = findAccountByEmail(connectedAccounts, instance.instanceDeliveryIdentifierValue || null);
    const cannotSendFromConnectedAccount = connectedAccount && !getDisplayGlobally(connectedAccount);
    if (cannotSendFromConnectedAccount) return;
    dropdownOptions.push(buildChannelInstanceOption({
      connectedAccount,
      instance
    }));
  }, []);

  // sort alphabetically w/ disabled options last
  return dropdownOptions.sort(sortByDisabledStateThenAlpha);
}
export function useFromAccountOptions({
  inboxId,
  genericChannelId
}) {
  const deferred = !genericChannelId;
  const {
    isLoading: loadingInstances,
    channelInstances
  } = useChannelInstancesForChannel({
    inboxId,
    channelId: genericChannelId || DEFERRED_GENERIC_CHANNEL_ID,
    deferred
  });
  const skipConnectedAccounts = deferred || genericChannelId !== EMAIL_GENERIC_CHANNEL_ID;
  const {
    asyncConnectedAccounts
  } = useConnectedAccounts({
    inboxId,
    deferred: skipConnectedAccounts
  });
  const loadingConnectedAccounts = !skipConnectedAccounts && isLoading(asyncConnectedAccounts);
  const loading = !deferred && (loadingInstances || loadingConnectedAccounts);
  const connectedAccounts = getData(asyncConnectedAccounts);
  const options = useMemo(() => {
    return channelInstances ? buildOptions({
      connectedAccounts,
      channelInstances
    }) : [];
  }, [channelInstances, connectedAccounts]);
  if (loading) {
    return {
      options: [],
      loading
    };
  }
  return {
    options,
    loading
  };
}