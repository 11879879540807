import { OrderedMap } from 'immutable';
function debugDisableOptimisticUpdates() {
  // eslint-disable-next-line hs-react-native/no-web-globals
  if (window && window.sessionStorage) {
    try {
      return (
        // eslint-disable-next-line hs-react-native/no-web-globals
        window.sessionStorage.getItem('Conversation:debug:disableOptimisticUpdates') === 'true'
      );
    } catch (throwAwayError) {
      return false;
    }
  } else return false;
}
export const applyViewMemberUpdates = ({
  viewMemberUpdates,
  viewMembers
}) => {
  if (!viewMembers) return OrderedMap();
  if (debugDisableOptimisticUpdates()) return viewMembers;
  return viewMemberUpdates.reduce((updatedMembers, viewMemberPatch, threadId) => {
    if (!updatedMembers.has(threadId)) return updatedMembers;
    return updatedMembers.update(threadId, viewMember => viewMember.merge(viewMemberPatch.threadListMember));
  }, viewMembers);
};