/* hs-eslint ignored failing-rules */
/* eslint-disable filenames/match-exported */

import { AT_MENTIONS, ATTACHMENTS, IMAGES, FORMATTED_HYPERLINKS, RICH_TEXT, PLAIN_TEXT, INSERT_CORE, INSERT_TEMPLATES, SUBMIT_ON_ENTER, MODEL_SMART_COMPOSE, USER_DEFAULT_FONT, BOLD, ITALIC } from '../records/channelConfigurationInterface';
import { ALLOWED_FILE_TYPES } from 'conversations-internal-schema/file-metadata/constants/allowedFileTypes';
import { HS_EMAIL_ADDRESS, HS_PHONE_NUMBER, CHANNEL_SPECIFIC_OPAQUE_ID } from 'conversations-message-history/common-message-format/constants/deliveryIdentifierTypes';
import { TO, BCC, CC } from 'conversations-message-history/common-message-format/constants/recipientFields';
import { LIVE_CHAT_GENERIC_CHANNEL_ID, FB_MESSENGER_GENERIC_CHANNEL_ID, EMAIL_GENERIC_CHANNEL_ID, WHATSAPP_GENERIC_CHANNEL_ID, SMS_GENERIC_CHANNEL_ID } from '../../../generic-channels/public/constants';
import { THREAD_COMMENT } from 'conversations-message-history/comment-message/constants/messageTypes';
import { RECIPIENT_KEY_BCC, RECIPIENT_KEY_CC, RECIPIENT_KEY_DEFAULT, RECIPIENT_KEY_TO } from './recipientKeys';
const ONE_MB = 1024 * 1024;
const ABLY_MAX_MESSAGE_LENGTH = 64 * 1024;
const TWENTY_FIVE_MB = 25 * ONE_MB;
export const DEFAULT_ATTACHMENT_COUNT = 1;
export const DEFAULT_MAX_FILE_SIZE_IN_BYTES = TWENTY_FIVE_MB;
export const DEFAULT_MAX_CONTENT_SIZE = ONE_MB;
export const DEFAULT_MAX_FILE_ATTACHMENTS_SIZE_IN_BYTES = TWENTY_FIVE_MB;
export const CHANNEL_TYPES = {
  EXTERNAL: 'EXTERNAL',
  HUBSPOT: 'HUBSPOT',
  INTERNAL: 'INTERNAL'
};
const EMAIL_CONFIGURATION = {
  canStartConversation: true,
  channelCapabilities: [ATTACHMENTS, IMAGES, RICH_TEXT, FORMATTED_HYPERLINKS],
  channelType: CHANNEL_TYPES.HUBSPOT,
  editingCapabilities: [INSERT_CORE, INSERT_TEMPLATES, MODEL_SMART_COMPOSE, USER_DEFAULT_FONT],
  icon: 'email',
  label: 'Email',
  limits: {
    attachmentCount: 20,
    attachmentByteSizeMax: DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    recipientCountNew: 50,
    recipientCountReply: 100
  },
  messageMetadata: {
    senders: {
      fieldName: 'FROM',
      isVisible: true,
      required: true,
      deliveryIdentifier: {
        deliveryIdentifierType: HS_EMAIL_ADDRESS,
        editable: true
      }
    },
    recipients: {
      required: true,
      isVisible: true,
      recipientsFields: [{
        deliveryIdentifierType: 'email',
        recipientField: {
          key: RECIPIENT_KEY_TO,
          fieldName: TO
        }
      }, {
        deliveryIdentifierType: 'email',
        recipientField: {
          key: RECIPIENT_KEY_CC,
          fieldName: CC
        }
      }, {
        deliveryIdentifierType: 'email',
        recipientField: {
          key: RECIPIENT_KEY_BCC,
          fieldName: BCC
        }
      }]
    },
    subject: {
      editable: true,
      required: true
    },
    requireMessagePositionDescriptor: false
  },
  supportAssociations: true,
  requireChatFlowConnectionStatus: false,
  checkConnectedAccounts: true,
  typingIndicator: false,
  editor: {
    richText: true,
    submitOnEnter: false,
    atMention: false,
    plainTextHyperlinks: false,
    typingIndicator: false,
    maxContentSizeInBytes: DEFAULT_MAX_CONTENT_SIZE,
    submitButtonText: 'Send',
    placeHolderText: 'Write a message',
    inlineImages: true,
    insertTemplates: true,
    userDefaultFont: true,
    paymentLinks: true,
    invoices: true,
    paymentLinkReferrerParameter: 'PAYMENT_LINK_INBOX_EMAIL',
    paymentLinksShowNewTag: true,
    invoicesShowNewTag: false,
    contentAssistant: true,
    aiAssistant: true
  },
  fileAttachments: {
    attachmentFileTypes: ALLOWED_FILE_TYPES,
    maxAttachmentCount: 20,
    maxAttachmentSizeBytes: 20 * ONE_MB,
    // must be <= maxTotalAttachmentSizeBytes
    maxTotalAttachmentSizeBytes: 20 * ONE_MB // https://private.hubteam.com/config/com.hubspot.signals.facsimile/FacsimileDataConfig/OUTGOING_ATTACHMENT_SIZE_LIMIT/STRING
  },
  message: {
    forward: true,
    replyToSpecificMessages: true
  },
  urlFragment: 'email'
};
const channelConfiguration = {
  [EMAIL_GENERIC_CHANNEL_ID]: EMAIL_CONFIGURATION,
  [LIVE_CHAT_GENERIC_CHANNEL_ID]: {
    canStartConversation: false,
    channelCapabilities: [ATTACHMENTS, RICH_TEXT, FORMATTED_HYPERLINKS],
    channelType: CHANNEL_TYPES.HUBSPOT,
    editingCapabilities: [INSERT_CORE, MODEL_SMART_COMPOSE],
    icon: 'messages',
    label: 'Chat',
    limits: {
      attachmentCount: 10,
      attachmentByteSizeMax: DEFAULT_MAX_FILE_SIZE_IN_BYTES
    },
    supportAssociations: false,
    requireChatFlowConnectionStatus: false,
    checkConnectedAccounts: false,
    typingIndicator: true,
    messageMetadata: {
      senders: {
        fieldName: null,
        isVisible: false,
        required: true,
        deliveryIdentifier: null
      },
      recipients: {
        required: true,
        isVisible: false,
        recipientsFields: [{
          deliveryIdentifierType: CHANNEL_SPECIFIC_OPAQUE_ID,
          recipientField: {
            key: RECIPIENT_KEY_DEFAULT,
            fieldName: null
          }
        }]
      },
      requireMessagePositionDescriptor: false
    },
    editor: {
      richText: true,
      submitOnEnter: true,
      atMention: false,
      plainTextHyperlinks: false,
      typingIndicator: true,
      maxContentSizeInBytes: ABLY_MAX_MESSAGE_LENGTH,
      submitButtonText: 'Send',
      placeHolderText: 'Write a message',
      inlineImages: false,
      insertTemplates: false,
      userDefaultFont: false,
      paymentLinks: true,
      invoices: true,
      paymentLinkReferrerParameter: 'PAYMENT_LINK_INBOX_CHAT',
      paymentLinksShowNewTag: true,
      contentAssistant: true,
      aiAssistant: true,
      invoicesShowNewTag: false
    },
    fileAttachments: {
      attachmentFileTypes: ALLOWED_FILE_TYPES,
      maxAttachmentCount: 10,
      maxAttachmentSizeBytes: DEFAULT_MAX_FILE_SIZE_IN_BYTES,
      maxTotalAttachmentSizeBytes: DEFAULT_MAX_FILE_ATTACHMENTS_SIZE_IN_BYTES
    },
    message: {
      forward: false,
      replyToSpecificMessages: false
    },
    urlFragment: 'live-chat'
  },
  [FB_MESSENGER_GENERIC_CHANNEL_ID]: {
    canStartConversation: false,
    channelCapabilities: [PLAIN_TEXT, ATTACHMENTS],
    channelType: CHANNEL_TYPES.HUBSPOT,
    editingCapabilities: [INSERT_CORE, SUBMIT_ON_ENTER, MODEL_SMART_COMPOSE],
    icon: 'socialFacebookMessenger',
    label: 'Messenger',
    limits: {
      attachmentCount: 10,
      attachmentByteSizeMax: DEFAULT_MAX_FILE_SIZE_IN_BYTES
    },
    supportAssociations: false,
    requireChatFlowConnectionStatus: true,
    checkConnectedAccounts: false,
    typingIndicator: true,
    messageMetadata: {
      senders: {
        fieldName: null,
        isVisible: false,
        required: true,
        deliveryIdentifier: null
      },
      recipients: {
        required: true,
        isVisible: false,
        recipientsFields: [{
          deliveryIdentifierType: CHANNEL_SPECIFIC_OPAQUE_ID,
          recipientField: {
            key: RECIPIENT_KEY_DEFAULT,
            fieldName: null
          }
        }]
      },
      requireMessagePositionDescriptor: false
    },
    editor: {
      richText: false,
      submitOnEnter: true,
      atMention: false,
      plainTextHyperlinks: true,
      typingIndicator: true,
      maxContentSizeInBytes: ABLY_MAX_MESSAGE_LENGTH,
      submitButtonText: 'Send',
      placeHolderText: 'Write a message',
      inlineImages: false,
      insertTemplates: false,
      userDefaultFont: false,
      contentAssistant: true,
      aiAssistant: true
    },
    fileAttachments: {
      attachmentFileTypes: ALLOWED_FILE_TYPES,
      maxAttachmentCount: 10,
      maxAttachmentSizeBytes: DEFAULT_MAX_FILE_SIZE_IN_BYTES,
      maxTotalAttachmentSizeBytes: DEFAULT_MAX_FILE_ATTACHMENTS_SIZE_IN_BYTES
    },
    message: {
      forward: false,
      replyToSpecificMessages: false
    },
    urlFragment: 'fb-messenger'
  },
  [WHATSAPP_GENERIC_CHANNEL_ID]: {
    canStartConversation: false,
    channelCapabilities: [PLAIN_TEXT, ATTACHMENTS],
    channelType: CHANNEL_TYPES.EXTERNAL,
    editingCapabilities: [INSERT_CORE, SUBMIT_ON_ENTER, MODEL_SMART_COMPOSE],
    icon: 'socialWhatsapp',
    label: 'WhatsApp',
    limits: {
      attachmentCount: 10,
      attachmentByteSizeMax: DEFAULT_MAX_FILE_SIZE_IN_BYTES
    },
    splitAttachments: true,
    supportAssociations: false,
    requireChatFlowConnectionStatus: true,
    checkConnectedAccounts: false,
    typingIndicator: true,
    fileAttachments: {
      // source: https://developers.facebook.com/docs/whatsapp/cloud-api/reference/media#supported-media-types
      attachmentFileTypes: ['audio/aac', 'audio/mp4', 'audio/mpeg', 'audio/amr', 'audio/ogg', 'audio/x-m4a', 'text/plain', 'application/pdf', 'application/vnd.ms-powerpoint', 'application/msword', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'image/jpeg', 'image/png', 'video/mp4', 'video/3gp', 'image/webp'],
      maxAttachmentCount: 10,
      maxAttachmentSizeBytes: 16 * ONE_MB,
      maxTotalAttachmentSizeBytes: 10 * 16 * ONE_MB
    },
    messageMetadata: {
      senders: {
        fieldName: null,
        isVisible: false,
        required: true,
        deliveryIdentifier: {
          deliveryIdentifierType: HS_PHONE_NUMBER,
          editable: false
        }
      },
      recipients: {
        required: true,
        isVisible: false,
        recipientsFields: [{
          deliveryIdentifierType: HS_PHONE_NUMBER,
          recipientField: {
            key: RECIPIENT_KEY_DEFAULT,
            fieldName: null
          }
        }]
      },
      requireMessagePositionDescriptor: true
    },
    editor: {
      richText: true,
      inlineStyles: [BOLD, ITALIC],
      textStyles: [],
      formatStyles: [],
      submitOnEnter: true,
      atMention: false,
      plainTextHyperlinks: true,
      typingIndicator: true,
      maxContentSizeInBytes: ABLY_MAX_MESSAGE_LENGTH,
      submitButtonText: 'Send',
      placeHolderText: 'Write a message',
      inlineImages: false,
      insertTemplates: false,
      contentAssistant: true,
      aiAssistant: true
    },
    message: {
      forward: false,
      replyToSpecificMessages: false
    },
    urlFragment: 'whatsapp'
  },
  [THREAD_COMMENT]: {
    channelCapabilities: [AT_MENTIONS, ATTACHMENTS, IMAGES, RICH_TEXT, FORMATTED_HYPERLINKS],
    channelType: CHANNEL_TYPES.INTERNAL,
    editingCapabilities: [INSERT_CORE, SUBMIT_ON_ENTER],
    label: 'Comment',
    limits: {
      attachmentCount: 10,
      attachmentByteSizeMax: DEFAULT_MAX_FILE_SIZE_IN_BYTES
    },
    supportAssociations: false,
    requireChatFlowConnectionStatus: false,
    checkConnectedAccounts: false,
    typingIndicator: false,
    messageMetadata: {
      senders: {
        fieldName: null,
        isVisible: false,
        required: true,
        deliveryIdentifier: null
      },
      requireMessagePositionDescriptor: false
    },
    editor: {
      richText: true,
      submitOnEnter: false,
      atMention: true,
      plainTextHyperlinks: false,
      typingIndicator: true,
      maxContentSizeInBytes: ABLY_MAX_MESSAGE_LENGTH,
      submitButtonText: 'Add comment',
      placeHolderText: 'Write a comment and @mention teammates. This is only visible to your team.',
      inlineImages: true,
      insertTemplates: false,
      userDefaultFont: false,
      conversationSummary: true,
      aiAssistant: true
    },
    fileAttachments: {
      attachmentFileTypes: ALLOWED_FILE_TYPES,
      maxAttachmentCount: 10,
      maxAttachmentSizeBytes: DEFAULT_MAX_FILE_SIZE_IN_BYTES,
      maxTotalAttachmentSizeBytes: DEFAULT_MAX_FILE_ATTACHMENTS_SIZE_IN_BYTES
    },
    message: {
      forward: false,
      replyToSpecificMessages: false
    },
    urlFragment: 'comment'
  },
  UNSET: {
    channelCapabilities: [],
    channelType: CHANNEL_TYPES.HUBSPOT,
    editingCapabilities: [INSERT_CORE],
    label: 'Unset',
    limits: {
      attachmentCount: 0,
      attachmentByteSizeMax: 0
    },
    supportAssociations: false,
    requireChatFlowConnectionStatus: false,
    checkConnectedAccounts: false,
    typingIndicator: false,
    messageMetadata: {
      recipients: {
        required: true,
        isVisible: true,
        recipientsFields: [{
          deliveryIdentifierType: 'unset',
          recipientField: {
            key: RECIPIENT_KEY_DEFAULT,
            fieldName: null
          }
        }]
      },
      senders: {
        fieldName: 'FROM',
        isVisible: true,
        required: true,
        deliveryIdentifier: null
      },
      requireMessagePositionDescriptor: false
    },
    editor: {
      richText: false,
      submitOnEnter: false,
      atMention: false,
      plainTextHyperlinks: false,
      typingIndicator: false,
      maxContentSizeInBytes: ABLY_MAX_MESSAGE_LENGTH,
      submitButtonText: 'Send',
      placeHolderText: 'Write a message',
      inlineImages: false,
      insertTemplates: false,
      userDefaultFont: false,
      conversationSummary: false,
      aiAssistant: true
    },
    message: {
      forward: false,
      replyToSpecificMessages: false
    },
    urlFragment: 'new'
  },
  [SMS_GENERIC_CHANNEL_ID]: {
    canStartConversation: false,
    channelCapabilities: [PLAIN_TEXT],
    channelType: CHANNEL_TYPES.EXTERNAL,
    editingCapabilities: [INSERT_CORE, MODEL_SMART_COMPOSE],
    icon: 'sms',
    label: 'SMS',
    limits: {
      attachmentCount: 0,
      attachmentByteSizeMax: DEFAULT_MAX_FILE_SIZE_IN_BYTES
    },
    supportAssociations: false,
    requireChatFlowConnectionStatus: false,
    checkConnectedAccounts: false,
    typingIndicator: false,
    messageMetadata: {
      senders: {
        fieldName: null,
        isVisible: false,
        required: true,
        deliveryIdentifier: {
          deliveryIdentifierType: HS_PHONE_NUMBER,
          editable: false
        }
      },
      recipients: {
        required: true,
        isVisible: false,
        recipientsFields: [{
          deliveryIdentifierType: HS_PHONE_NUMBER,
          recipientField: {
            key: RECIPIENT_KEY_DEFAULT,
            fieldName: null
          }
        }]
      },
      requireMessagePositionDescriptor: true
    },
    editor: {
      richText: false,
      submitOnEnter: false,
      atMention: false,
      plainTextHyperlinks: true,
      typingIndicator: false,
      maxContentSizeInBytes: ABLY_MAX_MESSAGE_LENGTH,
      submitButtonText: 'Send',
      placeHolderText: 'Write a message',
      inlineImages: false,
      insertTemplates: false,
      userDefaultFont: false,
      contentAssistant: true,
      aiAssistant: true
    },
    message: {
      forward: false,
      replyToSpecificMessages: false
    },
    urlFragment: 'sms'
  }
};
export default channelConfiguration;