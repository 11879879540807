'use es6';

import { setSender } from 'conversations-message-history/common-message-format/operators/commonMessageFormatSetters';
import { publishEmail } from '../../../email/actions/publishEmail';
import { getCMFSender } from '../selectors/getCMFSender';
import { updateCMFSenders } from '../selectors/updateCMFSenders';
export const publishEmailMessage = (commonMessage, {
  options
}) => (dispatch, getState) => {
  const state = getState();
  const sender = getCMFSender(state);
  const updatedMessage = updateCMFSenders(state, {
    message: commonMessage
  });
  dispatch(publishEmail(setSender(sender, updatedMessage), options));
};