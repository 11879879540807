import { GYPSUM, KOALA, OLAF, HEFFALUMP, SLINKY } from 'HubStyleTokens/colors';
import { DEFAULT, DARK, SHADE } from './useOptions';
export const COLOUR_MAPPINGS = {
  [DEFAULT]: {
    primary: GYPSUM,
    secondary: KOALA
  },
  [SHADE]: {
    primary: KOALA,
    secondary: GYPSUM
  },
  [DARK]: {
    primary: HEFFALUMP,
    secondary: SLINKY
  }
};
export const BLANK_BACKGROUND = `
  background: ${OLAF};
`;
export const makeGradientBackground = ({
  primary,
  secondary
}) => `
  background: ${primary};
  background: linear-gradient(to right, ${primary}, ${secondary} 20%, ${primary} 40%);
`;