import { getData } from 'conversations-async-data/async-data/operators/getters';
import { isSucceeded } from 'conversations-async-data/async-data/operators/statusComparators';

// TODO: move to composer-data

// TODO: move to composer-data
import invariant from 'conversations-thread-data/utils/invariant';
export const accountsMissing = ({
  asyncConnectedAccounts
}) => {
  invariant(Boolean(asyncConnectedAccounts), 'asyncConnectedAccounts is required for accountsMissing disabling strategy');
  const connectedAccounts = getData(asyncConnectedAccounts);
  return isSucceeded(asyncConnectedAccounts) && (!connectedAccounts || !connectedAccounts.size) ? {
    disabled: true,
    sendDisabledReason: 'MISSING_ACCOUNTS'
  } : null;
};