import promiseTimeout from 'hs-promise-utils/timeout';
import defer from 'hs-promise-utils/defer';
import { performSubscribe } from './performSubscribe';
import { performUnsubscribe } from './performUnsubscribe';
import { PROMISE_TIMEOUT_MS } from '../constants/defaults';
import { buildRequestTimeoutError } from '../../errors/builders/buildRequestTimeoutError';
export function expectMessage(channels, channelId, predicate) {
  const deferredMessageExpectation = defer();
  function subscribeHandler(message) {
    if (predicate(message)) {
      deferredMessageExpectation.resolve(message);
    }
  }
  const channel = channels.get(channelId);
  const resolveMessage = performSubscribe({
    channel,
    onMessage: subscribeHandler
  }).then(() => deferredMessageExpectation.promise).then(verifiedMessage => {
    performUnsubscribe(channels, channelId, subscribeHandler);
    return verifiedMessage;
  });
  return promiseTimeout(resolveMessage, PROMISE_TIMEOUT_MS, buildRequestTimeoutError('PubSub message expectation timeout', {
    channelId
  }));
}