import { Set as ImmutableSet } from 'immutable';
import compose from 'transmute/compose';
import { debounceApi } from '../../utils/debounceApi';
import { diffByKeys } from '../../utils/diffByKeys';
import { throttleApi } from '../../utils/throttleApi';
import { retryOnce } from '../../utils/retryOnce';
import { invariant } from '../../utils/invariant';
import { omit } from '../../utils/omit';
import { pick } from '../../utils/pick';
import { decorateSubscriptionCallbacks } from '../operators/decorateSubscriptionCallbacks';
import { transformLegacySubscriptions } from '../operators/transformLegacySubscriptions';
import { transactSubscriptions } from './transactSubscriptions';
import { prepareMessage } from '../operators/prepareMessage';
import { vendorPublishingStrategy } from '../strategies/vendorPublishingStrategy';
import { transactPresenceSubscriptions } from './transactPresenceSubscriptions';
export class ConversationsPubSub {
  constructor(vendor, clientOptions = {}) {
    this.publish = retryOnce(({
      message,
      channel,
      threadId
    }) => {
      invariant(typeof message.id === 'string', 'A message id is required to publish a message');
      this.messageIdsBeingPublished = this.messageIdsBeingPublished.add(message.id);
      return this._publishFn({
        message,
        channel,
        threadId
      }).then(published => {
        this.messageIdsBeingPublished = this.messageIdsBeingPublished.remove(message.id);
        return prepareMessage({
          message: published
        });
      });
    });
    this.updateSubscriptions = debounceApi(updatedSubscriptions => {
      const toAdd = diffByKeys(updatedSubscriptions, this.subscriptions);
      const toRemove = diffByKeys(this.subscriptions, updatedSubscriptions);
      return this.transactSubscriptions({
        toAdd,
        toRemove: Object.keys(toRemove)
      });
    });
    this.overwriteSubscriptions = debounceApi(updatedSubscriptions => {
      return this.transactSubscriptions({
        toAdd: updatedSubscriptions,
        toRemove: Object.keys(this.subscriptions)
      });
    });
    this.transactSubscriptions = compose(throttleApi, retryOnce)(({
      toAdd,
      toRemove
    }) => {
      const transformedToAdd = transformLegacySubscriptions(toAdd);
      const decoratedToAdd = decorateSubscriptionCallbacks({
        subscriptions: transformedToAdd,
        isMessageEcho: message => message.connectionId === this.vendor.getConnectionId() || this.messageIdsBeingPublished.has(message.id),
        vendor: this.vendor
      });
      const subscriptionToRemove = pick(this.subscriptions, toRemove);
      transactPresenceSubscriptions({
        vendor: this.vendor,
        toAdd: decoratedToAdd,
        toRemove: subscriptionToRemove,
        reauthorize: this.reauthorize
      }).catch(error =>
      // Error logging and counting are handled via profile promise in subscriptions.
      // eslint-disable-next-line no-console
      console.log('Presence subscription failed - ', error.message));
      return transactSubscriptions({
        vendor: this.vendor,
        toAdd: decoratedToAdd,
        toRemove: subscriptionToRemove,
        reauthorize: this.reauthorize
      }).then(() => {
        this.subscriptions = Object.assign({}, omit(this.subscriptions, toRemove), decoratedToAdd);
        return Object.assign({}, this.subscriptions);
      }).catch(error =>
      // Error logging and counting are handled via profile promise in subscriptions.
      // eslint-disable-next-line no-console
      console.log('Subscription failed - ', error.message));
    });
    this.channelCapabilityValidator = clientOptions.channelCapabilityValidator;
    this.vendor = vendor;
    this.subscriptions = {};
    this.messageIdsBeingPublished = ImmutableSet();
    this.reauthorize = debounceApi(this.reauthorize.bind(this));
    this._publishFn = vendorPublishingStrategy({
      vendor: this.vendor
    });
  }

  /**
   * Check if the underlying vendor is connected
   *
   * @returns {Boolean} connected
   */
  isConnected() {
    return this.vendor.isConnected();
  }

  /**
   * Connect to PubSub.
   *
   * @returns {Promise} connection promise
   */
  connect() {
    return this.vendor.connect();
  }

  /*
   * Close PubSub connection
   */
  close() {
    this.vendor.close();
  }

  /*
   * Reauthorize the vendor instance given a set of channels
   */
  reauthorize(channels = []) {
    const shouldReauthorize = Boolean(channels.length);
    return shouldReauthorize ? this.vendor.reauthorize(channels, this.channelCapabilityValidator) : Promise.resolve();
  }

  /**
   * Publish a message on a channel. This method will retry once automatically before failing the publish.
   *
   * @example
   *   const publishObject = {
   *     channel: 'channel.123',
   *     message: { conversations: 'is', awesome: true }
   *   };
   *
   *   client
   *     .publish(publishObject)
   *     .then((messageAsPublished) => console.log('it worked', messageAsPublished))
   *     .catch(error => console.error('it didnt work', error));
   *
   * @param {Object} publishObject - publish options
   * @param {Object} publishObject.message - message to publish
   * @param {String} publishObject.message.id - message id
   * @param {String} publishObject.channel - channel to publish to
   * @returns {Promise}
   */

  /**
   * Update subscriptions
   *
   * This method is debounced.  Compare the result with `client.updateSubscriptions.DEBOUNCED`
   *
   * When an update is in progress, calls are queued.  When the update completes, the most recent call is
   * invoked and all queued calls receive the result.
   *
   * This method will retry once automatically
   *
   * @example
   *   const subscriptions = {
   *     'channel.123': message => { doSomethingWith(message) }
   *     'channel.456': message => { doSomethingDifferentWith(message) }
   *   };
   *
   *   client
   *     .updateSubscriptions(subscriptions)
   *     .then(result => {
   *       if (result === client.updateSubscriptions.DEBOUNCED) {
   *         // An out of date subscription update was skipped in favor of a more recent update.
   *         return;
   *       }
   *
   *       console.log('it worked')
   *     })
   *     .catch(error => console.error('it didnt work', error));
   *
   * @param {Object<channelId, messageCallback>} updatedSubscriptions
   * @param {Object} options
   *
   * @returns {Promise} Resolves with updated subscriptions
   *
   */
  /**
   * Callback provided to `client.updateSubscriptions` to handle received messages.
   *
   * @callback messageCallback
   * @param {Object} message - pubnub message object
   * @param {Object} message.data - conversations message data object
   * @param {Number} message.timestamp - when the message was published
   * @param {Object} message.message - a second copy of the message data object (deprecated)
   * @param {String} message.channel - channel (deprecated)
   */
  /**
   * Overwrite subscriptions. Same as updateSubscriptions but overwrites existing subscriptions instead of
   * only subscribing to new channels.
   *
   * This method is debounced.  Compare the result with `client.updateSubscriptions.DEBOUNCED`
   *
   * When an update is in progress, calls are queued.  When the update completes, the most recent call is
   * invoked and all queued calls receive the result.
   *
   * This method will retry once automatically
   *
   * @example
   *   const subscriptions = {
   *     'channel.123': message => { doSomethingWith(message) }
   *     'channel.456': message => { doSomethingDifferentWith(message) }
   *   };
   *
   *   client
   *     .overwriteSubscriptions(subscriptions)
   *     .then(result => {
   *       if (result === client.updateSubscriptions.DEBOUNCED) {
   *         // An out of date subscription update was skipped in favor of a more recent update.
   *         return;
   *       }
   *
   *       console.log('it worked')
   *     })
   *     .catch(error => console.error('it didnt work', error));
   *
   * @param {Object<channelId, messageCallback>} newSubscriptions
   *
   * @returns {Promise} Resolves with new subscriptions
   *
   */
  /**
   * Callback provided to `client.updateSubscriptions` to handle received messages.
   *
   * @callback messageCallback
   * @param {Object} message - pubnub message object
   * @param {Object} message.data - conversations message data object
   * @param {Number} message.timestamp - when the message was published
   * @param {Object} message.message - a second copy of the message data object (deprecated)
   * @param {String} message.channel - channel (deprecated)
   */
  /**
   * Transact a subscription change
   *
   * This method is throttled and will retry once automatically
   *
   * @example
   *   const toAdd = {
   *     'channel.123': message => { doSomethingWith(message) }
   *   };
   *
   *   const toRemove = {
   *     'channel.456': message => { doSomethingDifferentWith(message) }
   *   };
   *   client
   *     .transactSubscriptions({ toAdd, toRemove})
   *     .then(() => console.log('it worked'))
   *     .catch(error => console.error('it didnt work', error));
   *
   *
   * @param {Object} options
   * @param {Object<channelId, subscriptionObject>} options.toAdd subscriptions to add (optional)
   * @param {Array<channelId>} options.toRemove channels to unsubscribe from
   *
   * @returns {Promise} Resolves with updated subscriptions
   *
   */
  /**
   * Callback provided to `client.transactSubscriptions` to handle received messages.
   *
   * @callback messageCallback
   * @param {Object} message - pubnub message object
   * @param {Object} message.data - conversations message data object
   * @param {Number} message.timestamp - when the message was published
   * @param {Object} message.message - a second copy of the message data object (deprecated)
   * @param {String} message.channel - channel (deprecated)
   */
}