export const getEditPropertySettingsLinkClicked = ({
  objectType
}) => ({
  key: 'edit-property-settings',
  properties: {
    action: 'edit property settings',
    objectType
  }
});
export const getDisassociateThreadTicket = () => ({
  key: 'disassociate-thread-ticket',
  properties: {
    action: 'disassociate thread ticket'
  }
});