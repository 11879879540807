import { getApolloContext, useMutation } from '@apollo/client';
import { apolloClientInvariant } from '../../../graphql/invariant/apolloClientInvariant';
import { parseUpdatedCurrentAssigneeFromGqlResponse } from '../utils/parseAssigneesFromGqlResponse';
import { useContext, useMemo } from 'react';
import { queryResultToAsyncData } from '../../../graphql/utils/queryResultToAsyncData';
import { assignConversationAssignee } from '../mutations/assignConversationAssignee';
import { unassignConversationAssignee } from '../mutations/unassignConversationAssignee';
import { useQuery as useDataFetchingClientQuery, useDataFetchingClient } from 'data-fetching-client';
import { FETCH_CURRENT_ASSIGNEE_QUERY } from '../clients/fetchCurrentAssigneeREST';
/**
 * @typedef {function} ReassignThreadMutation
 * @param {Object} variables
 * @param {string} variables.actorId The actor ID string of the assignee to assign to the thread
 * @param {number} variables.threadId The ID of the thread to be updated
 * @returns {Object}
 */

/**
 * @typedef {function} UnassignThreadMutation
 * @param {Object} variables
 * @param {number} variables.threadId The ID of the thread to be updated
 * @returns {Object}
 */

/**
 * @typedef {Object} UseCurrentAssigneeReturnValue
 * @property {AsyncData} asyncAssignee The resulting assignee data
 * @property {ReassignThreadMutation} reassignThread A function to update an assignee
 * @property {function} refetch A function to refetch from the server
 * @property {UnassignThreadMutation} unassignThread A function to unassign an assignee from a Conversation
 */
/**
 * @description Fetches the assignees associated to the conversation
 * that matches the provided conversationId.
 *
 * @param {Object} options
 * @param {ApolloClient} [options.apolloClient] Pass this in if your code is not wrapped in an ApolloProvider
 * @param {number} options.conversationId ID of the conversation
 * @param {number} options.threadId ID of the thread
 * @param {boolean} options.skip Whether or not to skip the query
 * @returns {UseCurrentAssigneeReturnValue}
 */
export const useCurrentAssignee = ({
  apolloClient,
  threadId,
  skip = false
}) => {
  const apolloClientFromProvider = useContext(getApolloContext()).client;
  apolloClientInvariant(apolloClient || apolloClientFromProvider);
  const dataFetchingClient = useDataFetchingClient();
  const {
    data,
    error,
    loading,
    refetch
  } = useDataFetchingClientQuery(FETCH_CURRENT_ASSIGNEE_QUERY, {
    variables: {
      threadId
    },
    skip
  });
  const asyncAssignee = useMemo(() => {
    let finalData = null;
    if (data) {
      finalData = data.fetchCurrentAssignee;
    }
    return queryResultToAsyncData({
      data: finalData,
      error,
      loading
    });
  }, [data, error, loading]);
  const [reassignThread] = useMutation(assignConversationAssignee, {
    update(cache, {
      data: updatedData
    }) {
      if (updatedData) {
        const updatedAssignee = parseUpdatedCurrentAssigneeFromGqlResponse(updatedData);
        dataFetchingClient.cache.writeQuery({
          query: FETCH_CURRENT_ASSIGNEE_QUERY,
          variables: {
            threadId
          },
          data: {
            fetchCurrentAssignee: updatedAssignee
          }
        });
      }
    }
  });
  const [unassignThread] = useMutation(unassignConversationAssignee, {
    update() {
      dataFetchingClient.cache.writeQuery({
        query: FETCH_CURRENT_ASSIGNEE_QUERY,
        variables: {
          threadId
        },
        data: {
          fetchCurrentAssignee: null
        }
      });
    }
  });
  return {
    asyncAssignee,
    reassignThread,
    refetch,
    unassignThread
  };
};