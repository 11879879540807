import { useEffect, useState } from 'react';
import { fetchQuoteSetting } from 'quotes-ui-lib/api/quoteSettingsApi';
import Raven from 'raven-js';
export const useFetchQuoteSetting = ({
  quoteSettingKey,
  isUngated = true,
  defaultValue,
  earlyRequesterKey = ''
}) => {
  const [quoteSetting, setQuoteSetting] = useState({
    loading: true,
    error: null,
    value: defaultValue || null
  });
  useEffect(() => {
    if (isUngated) {
      fetchQuoteSetting(quoteSettingKey, earlyRequesterKey).then(data => {
        setQuoteSetting(prevState => Object.assign({}, prevState, {
          value: data
        }));
      }).catch(err => {
        setQuoteSetting(prevState => Object.assign({}, prevState, {
          error: new Error(err)
        }));
        Raven.captureException(err);
      }).finally(() => {
        setQuoteSetting(prevState => Object.assign({}, prevState, {
          loading: false
        }));
      });
    }
  }, [quoteSettingKey, isUngated, earlyRequesterKey]);
  return quoteSetting;
};