import { useQuery } from '@apollo/client';
import get from 'transmute/get';
import { ticketPipelinesQuery } from '../queries/fetchTicketPipelines';
export const useTicketPipelines = ({
  apolloClient
} = {}) => {
  const {
    loading,
    data,
    error,
    refetch
  } = useQuery(ticketPipelinesQuery, {
    client: apolloClient,
    notifyOnNetworkStatusChange: true
  });
  const pipelines = data && get('allPipelines', data);
  return {
    error,
    loading,
    pipelines,
    refetch
  };
};