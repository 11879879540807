'use es6';

import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { EMAIL_MESSAGE_IMAGE_STATUS_FETCH, MESSAGE_REFRESH } from 'conversations-thread-data/email-inline-images/public/constants/asyncActionTypes';
import { Map as ImmutableMap } from 'immutable';
import { handleActions } from 'redux-actions';
import { getShouldDisplayInlineMessageBanner } from '../operators/getShouldDisplayInlineMessageBanner';
import { stringIdInvariant } from 'conversations-async-data/indexed-async-data/invariants/stringIdInvariant';
import { limitToCount } from 'conversations-async-data/indexed-async-data/eviction-strategies/limitToCount';
import { INLINE_IMAGE_STATUS_ASYNC_DATA_CACHE_SIZE } from 'conversations-thread-data/common/public/constants/CacheSize';
const dataInitialState = {
  shouldDisplayBanner: false,
  numberOfProcessingImages: 0
};
export const initialState = IndexedAsyncData({
  entries: ImmutableMap(),
  name: 'inlineImageStatus',
  idInvariant: stringIdInvariant,
  notSetValue: AsyncData({
    data: dataInitialState
  }),
  evict: limitToCount(INLINE_IMAGE_STATUS_ASYNC_DATA_CACHE_SIZE)
});
export default handleActions({
  [EMAIL_MESSAGE_IMAGE_STATUS_FETCH.STARTED](state, action) {
    const {
      requestArgs
    } = action.payload;
    return updateEntry(requestArgs.messageId, requestStarted, state);
  },
  [EMAIL_MESSAGE_IMAGE_STATUS_FETCH.SUCCEEDED](state, action) {
    const {
      requestArgs,
      data
    } = action.payload;
    return updateEntry(requestArgs.messageId, requestSucceededWithOperator(() => getShouldDisplayInlineMessageBanner(data)), state);
  },
  [EMAIL_MESSAGE_IMAGE_STATUS_FETCH.FAILED](state, action) {
    const {
      requestArgs
    } = action.payload;
    return updateEntry(requestArgs.messageId, requestFailed, state);
  },
  [MESSAGE_REFRESH](state, action) {
    const {
      messageId
    } = action.payload;
    return updateEntry(messageId, requestSucceededWithOperator(() => dataInitialState), state);
  }
}, initialState);