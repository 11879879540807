'use es6';

import { Map as ImmutableMap } from 'immutable';
import { LastViewedInboxes, LastViewedInbox } from '../records/LastViewedInboxes';
import { LAST_VIEWED_INBOX_V2 } from '../constants/localStorageKeys';
const toRecordFn = previousLastViewedInboxes => {
  return LastViewedInboxes({
    lastViewed: ImmutableMap(previousLastViewedInboxes.lastViewed).map(lastViewedInbox => LastViewedInbox(lastViewedInbox))
  });
};
export const buildFromLocalStorage = () => {
  // eslint-disable-next-line hs-react-native/no-web-globals
  if (window.localStorage) {
    try {
      const prevLastViewedInboxesProps = JSON.parse(
      // eslint-disable-next-line hs-react-native/no-web-globals
      localStorage.getItem(LAST_VIEWED_INBOX_V2));
      return toRecordFn(prevLastViewedInboxesProps);
    } catch (e) {
      // eslint-disable-next-line hs-react-native/no-web-globals
      localStorage.removeItem(LAST_VIEWED_INBOX_V2);
    }
  }
  return LastViewedInboxes();
};