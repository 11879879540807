import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './rootReducer';
export const REDUX_DEV_TOOLS_CONFIG = {
  name: 'Subscription Sidebar: Highlight Card',
  trace: true,
  serialize: true
};
export function initStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware(),
    devTools: REDUX_DEV_TOOLS_CONFIG
  });
}