import { useLazyQuery } from 'data-fetching-client';
import { PROMOTE_TO_CONTACT_QUERY } from '../queries/promoteToContactQueries';
export const usePromoteToContact = () => {
  const [promoteToContact, {
    data,
    loading,
    error
  }] = useLazyQuery(PROMOTE_TO_CONTACT_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
  const handlePromoteToContact = ({
    firstName,
    lastName,
    email,
    vid,
    portalId,
    focusedThreadId
  }) => {
    promoteToContact({
      variables: {
        firstName,
        lastName,
        email,
        vid,
        portalId,
        focusedThreadId
      }
    });
  };
  return {
    promoteToContact: handlePromoteToContact,
    data,
    loading,
    error
  };
};