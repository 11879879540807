import { Set as ImmutableSet } from 'immutable';
import { getViewMemberId } from '../../../view-members-schema/public';
import logMergeError from './logMergeError';
export function mergeIncomingViewMembersPage({
  oldPage,
  newPage,
  totalCount
}) {
  try {
    // Remove view members from the old page that are on the incoming page.
    const newPageViewMemberIds = ImmutableSet(newPage.indexedMembers.keys());
    const trimmedOldPage = oldPage.update('indexedMembers', members => {
      return members.filter(vm => !newPageViewMemberIds.has(getViewMemberId(vm))).toOrderedMap();
    });
    return trimmedOldPage.mergeDeep(newPage).set('totalCount', trimmedOldPage.totalCount);
  } catch (e) {
    logMergeError({
      jsError: e,
      oldPage,
      newPage,
      totalCountFromPayload: totalCount
    });
  }
  return newPage;
}