import { handleActions, combineActions } from 'redux-actions';
import ChatFilterOptions from 'conversations-internal-schema/constants/ChatFilterOptions';
import { SET_STATUS_TOGGLE } from '../constants/actionTypes';
import { SET_CURRENT_THREAD_LIST } from 'conversations-inbox-lib/views/public/constants';
import { EXIT_SEARCH_AND_FILTER_MODE } from 'conversations-inbox-lib/thread-search-and-filtering/public/constants';
const initialState = ChatFilterOptions.STARTED;
export default handleActions({
  [SET_STATUS_TOGGLE](state, action) {
    return action.payload.statusToggle;
  },
  [combineActions(SET_CURRENT_THREAD_LIST, EXIT_SEARCH_AND_FILTER_MODE).toString()]() {
    return initialState;
  }
}, initialState);