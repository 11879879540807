import { buildEmailMetadataFromJS } from './buildEmailMetadataFromJS';
/**
 * @description Given a raw metadata object, build the
 * appropriate common-reply metadata record. For now,
 * there is only the email record metadata type, so
 * there's no need to check the type.
 * @param {object} metadataObject
 */
export const buildMetadataFromJS = metadataObject => {
  if (metadataObject) {
    return buildEmailMetadataFromJS(metadataObject);
  }
  return null;
};