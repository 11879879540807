import { useMemo } from 'react';
import { useSelector } from 'react-redux';
// @ts-expect-error module not typed
import { getMessageId } from 'conversations-message-history/message-history/operators/getMessageId';
import { getForwardCommunicatorState } from 'composer-data/email/public/selectors';
import { useLatestMessageIdForChannel } from '../../thread-history/hooks/useLatestMessageIdForChannel';
import { FORWARD_MESSAGE, REPLY_TO_MESSAGE, REPLY_ON_THREAD } from '../constants/replyStrategies';
import { isGenericChannelId } from 'conversations-thread-data/channel-configuration/public/types';
import { EMAIL_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
/**
 * @description Build a response strategy based on the current thread
 * and the current reply / forward redux state
 */

export const useResponseStrategy = ({
  currentChannelId,
  threadId
}) => {
  const canForward = currentChannelId === EMAIL_GENERIC_CHANNEL_ID;
  const canReply = currentChannelId === EMAIL_GENERIC_CHANNEL_ID;
  const latestMessageId = useLatestMessageIdForChannel({
    genericChannelId: isGenericChannelId(currentChannelId) ? currentChannelId : null,
    threadId
  });
  const {
    message: targetedMessage,
    isReply
  } = useSelector(getForwardCommunicatorState);
  const targetedMessageId = useMemo(() => targetedMessage && getMessageId(targetedMessage), [targetedMessage]);
  if (targetedMessageId && canReply && isReply) {
    return {
      strategy: REPLY_TO_MESSAGE,
      messageId: targetedMessageId
    };
  } else if (targetedMessageId && canForward && !isReply) {
    return {
      strategy: FORWARD_MESSAGE,
      messageId: targetedMessageId
    };
  }
  return {
    strategy: REPLY_ON_THREAD,
    messageId: latestMessageId
  };
};