import { FETCH_FILTER_OPTIONS } from './ActionTypes';
import { fetchFilterOptions as fetchFilterOptionsAjax } from '../api/ZorseTicketInspectorApi';
export function fetchFilterOptions() {
  return dispatch => {
    dispatch({
      type: FETCH_FILTER_OPTIONS,
      meta: 'request'
    });
    return fetchFilterOptionsAjax().then(payload => {
      return dispatch({
        type: FETCH_FILTER_OPTIONS,
        meta: 'response',
        payload
      });
    }, payload => dispatch({
      type: FETCH_FILTER_OPTIONS,
      error: true,
      payload
    }));
  };
}