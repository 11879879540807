import { createSelector } from 'reselect';
import { CALLING_GATES, MOVE_INBOX_TO_CALLTAB } from 'calling-internal-common/constants/CallingGates';
import { Set as ImmutableSet } from 'immutable';
import { LIMITED_TICKET_FUNCTION, USER_SETUP_WORKING_HOURS, CONVERSATION_SUMMARY_GATE, CONVERSATION_TICKET_STATUS_CONDITIONAL_PROPERTIES, HELP_DESK_MANUAL_ASSIGNMENT_SCOPE_FILTERING, HELP_DESK_ROUTING_SCOPE_FILTERING, USER_OUT_OF_OFFICE, ROUTING_REASSIGNMENT_CHIRP, MOBILE_CHAT_SDK } from '../constants/GatesConstants';
export const getGates = state => {
  var _state$auth;
  return (state === null || state === void 0 || (_state$auth = state.auth) === null || _state$auth === void 0 ? void 0 : _state$auth.gates) || [];
};
export const getGatesSet = createSelector([getGates], gates => ImmutableSet(gates));
export const getGatesArray = createSelector([getGatesSet], gatesSet => gatesSet.toArray());
export const createIsUngatedForGate = gate => createSelector(getGates, gates => gates.includes(gate));
export const getIsUngatedForTicketDisassociation = createSelector([getGates], gates => gates.includes('Conversations:ThreadTicketDisassociationButton'));
export const getIsUngatedForHelpDesk = createSelector([getGates], gates => gates.includes('Conversations:HelpDesk'));
export const getCallingGates = createSelector([getGates], gates => CALLING_GATES.filter(gate => gates.includes(gate)));
export const getIsUngatedForWhatsApp = createSelector([getGates], gates => gates.includes('Conversations:WhatsApp'));
export const getIsUngatedForTemplateIframe = createSelector([getGates], gates => gates.includes('Conversations:Whatsapp'));
export const getIsUngatedForWhatsAppComposeInbox = createSelector([getGates], gates => gates.includes('Communication:Inbox:WhatsAppCompose'));
export const getIsUngatedForWorkingHours = createIsUngatedForGate(USER_SETUP_WORKING_HOURS);
export const getIsUngatedForOutOfOffice = createIsUngatedForGate(USER_OUT_OF_OFFICE);
export const getIsUngatedForLimitedTicketFunctionality = createIsUngatedForGate(LIMITED_TICKET_FUNCTION);
export const isConversationSummaryUngatedSelector = createIsUngatedForGate(CONVERSATION_SUMMARY_GATE);
export const isUngatedToTicketStatusConditionalProperties = createIsUngatedForGate(CONVERSATION_TICKET_STATUS_CONDITIONAL_PROPERTIES);
export const getIsUngatedForHelpDeskManualAssignmentScopeFiltering = createIsUngatedForGate(HELP_DESK_MANUAL_ASSIGNMENT_SCOPE_FILTERING);
export const getIsUngatedForHelpDeskRoutingScopeFiltering = createIsUngatedForGate(HELP_DESK_ROUTING_SCOPE_FILTERING);
export const getIsUngatedToUseCallTab = createIsUngatedForGate(MOVE_INBOX_TO_CALLTAB);
export const getIsUngatedForCHIRPReassignment = createIsUngatedForGate(ROUTING_REASSIGNMENT_CHIRP);
export const getIsUngatedForMobileChatSDK = createIsUngatedForGate(MOBILE_CHAT_SDK);