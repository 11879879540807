'use es6';

import { getSenderTypeFromAgentType } from '../../senders/operators/getSenderTypeFromAgentType';
export const buildRecipientObjectsFromAgentsResponse = (agents = []) => {
  return agents.map(agent => {
    const {
      avatar,
      userId,
      email,
      agentType,
      firstName,
      lastName
    } = agent;
    const recipientType = getSenderTypeFromAgentType(agentType);
    return {
      avatar,
      email,
      firstName,
      id: userId,
      lastName,
      recipientType
    };
  });
};