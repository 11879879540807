import { logError } from '../utils/logError';
export const LOCAL_SETTINGS_PREFIX = 'LocalSettings:Calling:';
function getPrefixedKey(key, prefix) {
  return `${prefix}${key}`;
}
export function deleteSetting(key, prefix = LOCAL_SETTINGS_PREFIX) {
  if (!window.localStorage) {
    return;
  }
  try {
    window.localStorage.removeItem(getPrefixedKey(key, prefix));
  } catch (error) {
    logError('Removing item from localstorage failed', {
      error: error,
      level: 'info'
    });
  }
}
export function setSetting(key, value, prefix = LOCAL_SETTINGS_PREFIX) {
  if (!window.localStorage) {
    return undefined;
  }
  try {
    const val = typeof value === 'string' ? value : JSON.stringify(value);
    window.localStorage.setItem(getPrefixedKey(key, prefix), val);
    return value;
  } catch (error) {
    logError('Unable to setSetting to LocalStorage', {
      error: error,
      level: 'info'
    });
    return undefined;
  }
}
export function getSetting(key, fallback, parser, prefix = LOCAL_SETTINGS_PREFIX) {
  if (!window.localStorage) {
    return fallback;
  }
  const setFallback = () => {
    if (fallback) {
      setSetting(key, fallback);
    }
  };
  let entry;
  try {
    entry = window.localStorage.getItem(getPrefixedKey(key, prefix));
    if (!entry || entry === 'null' || entry === 'undefined') {
      setFallback();
      return fallback;
    } else if (entry.startsWith('{')) {
      const result = JSON.parse(entry);
      return typeof parser === 'function' ? parser(result) : result;
    }
    return entry;
  } catch (error) {
    setFallback();
    let errorMessage = 'Unable to getSetting from LocalStorage, using fallback if available.';
    if (error instanceof Error && 'message' in error) {
      const temp = errorMessage;
      errorMessage = error.message;
      error.message = temp;
    }
    logError(errorMessage, {
      extra: {
        entry: entry || '',
        fallback
      },
      level: 'info'
    });
    return fallback;
  }
}