import { DATE_TIME, ENUMERATION } from 'customer-data-filters/converters/objectSeg/OperationTypes';
/**
 * @description Different types of Filter operations will store the filter comparison
 * value in different properties. This getter will take a operation and return the
 * value for that FilterOperation.
 */
export const getFilterOperationValue = operation => {
  const {
    value: filterValue,
    values: filterValues,
    timestamp: filterTimestampValue
  } = operation;
  if (operation.propertyType === DATE_TIME) {
    return filterTimestampValue;
  }
  if (operation.propertyType === ENUMERATION) {
    return filterValues;
  }
  return filterValue;
};