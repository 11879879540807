import { OWNER } from '../constants/RoutingTypes';
import { setFallback } from '../utils/buildRoutingRules';
import RoutingRuleBase from './RoutingRuleBase';
class OwnerRoutingRules extends RoutingRuleBase({
  '@type': OWNER,
  fallback: null
}, 'OwnerRoutingRules') {
  constructor(options) {
    super(setFallback(options));
  }
}
export const buildOwnerRoutingRules = options => new OwnerRoutingRules(options);
export default OwnerRoutingRules;