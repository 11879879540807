import http from 'hub-http/clients/apiClient';
import toolsHubSpotterClient from '../../client/toolsHubSpotterClient';
const BASE_URL = 'ooo/v1';
const INTERNAL_URL_PATTERN = new RegExp('(private|tools|local)(-[a-z]{2,4}[0-9])?.hubteam(qa)?.com');
function isInternal() {
  return INTERNAL_URL_PATTERN.test(window.location.href);
}
function getHttpClient() {
  return isInternal() ? toolsHubSpotterClient : http;
}
export const fetchOutOfTheOfficeStatus = hubspotterId => {
  return getHttpClient().get(`${BASE_URL}/is-ooo/${hubspotterId}`);
};