import { GYPSUM, OBSIDIAN } from 'HubStyleTokens/colors';
import Small from 'UIComponents/elements/Small';
import UITile from 'UIComponents/tile/UITile';
import styled from 'styled-components';
export const StyledSmallObsidian = styled(Small).withConfig({
  displayName: "styledComponents__StyledSmallObsidian",
  componentId: "sc-1iffhz5-0"
})(["color:", ";font-weight:'300';"], OBSIDIAN);
export const StyledUITile = styled(UITile).withConfig({
  displayName: "styledComponents__StyledUITile",
  componentId: "sc-1iffhz5-1"
})(["background-color:", ";"], GYPSUM);