module.exports = {
  "zorseTicketSearchInteraction": {
    "name": "Zorse ticket search interaction",
    "class": "interaction",
    "namespace": "zorse-ticket-search",
    "version": "1",
    "properties": {
      "action": "string",
      "payload": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "state": {
        "type": [
          "initiated",
          "succeeded"
        ],
        "isOptional": true
      }
    }
  },
  "zorseTicketSearchUsage": {
    "name": "Zorse ticket search usage",
    "class": "usage",
    "namespace": "zorse-ticket-search",
    "version": "1",
    "properties": {
      "action": "string",
      "payload": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      },
      "state": {
        "type": [
          "initiated",
          "succeeded"
        ],
        "isOptional": true
      }
    }
  }
};