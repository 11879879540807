import { createSelector } from 'reselect';
import getIn from 'transmute/getIn';
const getViewIdFromProps = (_state, props) => props.viewId;
export const selectViewMembersPage = state => state.findAndFilterData.viewMembers;
export const selectViewMembersPageById = createSelector([selectViewMembersPage, getViewIdFromProps], (viewMembersPage, viewId) => {
  if (viewId) return viewMembersPage.data.get(viewId);else return undefined;
});
export const selectViewMembersPageStatus = createSelector([selectViewMembersPage], page => page.status);
export const selectIsViewMembersPageLoading = createSelector([selectViewMembersPage], page => page.loading);
export const selectHasViewMembersFailed = createSelector([selectViewMembersPage], page => page.failed);
const getViewMemberSelectProps = (_state, props) => ({
  threadId: props.threadId,
  viewId: props.viewId
});
export const selectMemberByThreadId = createSelector([selectViewMembersPage, getViewMemberSelectProps], (page, {
  threadId,
  viewId
}) => {
  if (!threadId || !viewId) {
    return undefined;
  }
  return getIn([viewId, 'indexedMembers', `${threadId}`], page.data);
});