import { List } from 'immutable';
import getIn from 'transmute/getIn';
import omit from 'transmute/omit';
import reduce from 'transmute/reduce';
import ObjectIds from 'customer-data-objects/constants/ObjectIds';

/**
 * Based on the PropertyValue schema in GraphQL
 */

const toRecordPropertiesFromObject = (acc, {
  definition,
  value,
  source,
  sourceId,
  timestamp
}, name) => {
  acc[name] = {
    definition,
    value,
    source,
    sourceId,
    timestamp
  };
  return acc;
};
const toRecordPropertiesFromList = (acc, {
  definition,
  name,
  value,
  source,
  sourceId,
  timestamp
}) => {
  acc[name] = {
    definition,
    value,
    source,
    sourceId,
    timestamp
  };
  return acc;
};
/**
 * This function is mostly a copy/paste of toSubject from customer-data-sidebar.
 * See: https://git.hubteam.com/HubSpot/customer-data-sidebar/blob/e99b11f7993f07cb9acd74b886f3d02b480dc932/static/js/graphQL/parse.js#L50-L75
 *
 * It is responsible for parsing the GQL response into immutable CRM Object records.
 */
export const toTicket = gqlCrmObject => {
  let recordReadyProperties = {};
  if (gqlCrmObject.defaultProperties) {
    const recordProperties = omit(['Symbol(id)', '__typename'], gqlCrmObject.defaultProperties);
    recordReadyProperties = reduce(recordReadyProperties, toRecordPropertiesFromObject,
    // Aggregator type signature doesn't allow key type as the third parameter, which is wrong
    recordProperties // reduce's type signature is too restrictive to immutable Iterables--vanilla JS objects are legal here
    );
  }
  if (gqlCrmObject.properties) {
    const recordProperties = omit(['Symbol(id)', '__typename'], gqlCrmObject.properties);
    recordReadyProperties = reduce(recordReadyProperties, toRecordPropertiesFromList, recordProperties // reduce's type signature is too restrictive to immutable Iterables--vanilla JS objects are legal here
    );
  }
  return {
    [ObjectIds.TICKET[0]]: gqlCrmObject.id,
    properties: recordReadyProperties
  };
};
export const parseTicketsFromGqlResponse = data => {
  const ticketEdges = getIn(['crmObject', 'associations', 'conversationToTicketV2', 'edges'], data);
  if (!ticketEdges) {
    return null;
  }
  const tickets = ticketEdges.map(ticketResponse => toTicket(ticketResponse.node));
  return List(tickets);
};