import { Set as ImmutableSet } from 'immutable';
import { buildCapabilityKey } from './buildCapabilityKey';
import { AT_MENTIONS, INSERT_CORE, INSERT_TEMPLATES } from '../../../channel-configuration/public/constants';
/**
 * @description Encodes an array of capabilities with any data
 * required to hydrate their data. This is used to passively
 * cache capabilities data: when the lookup data change, so does
 * the unique key being fetched for.
 */
export const buildCapabilityKeySet = (capabilities, inboxId, threadId) => ImmutableSet(capabilities.map(capability => {
  switch (capability) {
    case AT_MENTIONS:
      {
        return buildCapabilityKey(capability, {
          inboxId
        });
      }
    case INSERT_CORE:
    case INSERT_TEMPLATES:
      {
        return buildCapabilityKey(capability, {
          threadId
        });
      }
    default:
      return buildCapabilityKey(capability);
  }
}));