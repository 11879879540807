'use es6';

import formatName from 'I18n/utils/formatName';
import { getEmail, getFirstName, getLastName } from './salesUsersGetters';
import { Iterable } from 'immutable';
const getText = user => {
  if (Iterable.isIterable(user)) {
    return getFirstName(user) ? formatName({
      firstName: getFirstName(user),
      lastName: getLastName(user)
    }) : getEmail(user);
  }
  return user.firstName ? formatName({
    firstName: user.firstName,
    lastName: user.lastName
  }) : user.email;
};
export { getText as default, getText };