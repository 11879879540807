import { useCallback, useMemo } from 'react';
export const useConversationQuickFilters = ({
  userId
}) => {
  const filterOptions = useMemo(() => ({
    UNASSIGNED: {
      filterGroups: [{
        filters: [{
          operator: 'NOT_HAS_PROPERTY',
          property: 'hs_assigned_agent_id'
        }]
      }],
      objectTypeId: '0-11'
    },
    ALL: {
      filterGroups: [{
        filters: []
      }]
    },
    ME: {
      filterGroups: [{
        filters: [{
          operator: 'IN',
          property: 'hs_assigned_agent_id',
          values: [`${userId}`]
        }]
      }],
      objectTypeId: '0-11'
    }
  }), [userId]);
  const getFilterOptions = useCallback(() => filterOptions, [filterOptions]);
  return {
    getFilterOptions
  };
};
export let AssigneeFilterOption;
(function (AssigneeFilterOption) {
  AssigneeFilterOption["ALL"] = "ALL";
  AssigneeFilterOption["UNASSIGNED"] = "UNASSIGNED";
  AssigneeFilterOption["ME"] = "ME";
})(AssigneeFilterOption || (AssigneeFilterOption = {}));