import http from 'conversations-http/clients/http';
export const createEmailThread = data => {
  return http.post('conversations-email/v1/send/new', {
    data
  });
};
export const sendEmailReply = ({
  data,
  threadId
}) => http.post(`/conversations-email/v1/send/${threadId}`, {
  data
});