import { useLatestMessageIdForChannel } from 'conversations-thread-view/thread-history/hooks/useLatestMessageIdForChannel';
import { CALLING_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
import { useCommonMessageWithId } from 'conversations-thread-data/thread-history/public/hooks';
// @ts-expect-error module not typed
import { useThreadHistory } from 'conversations-thread-view/thread-histories/hooks/useThreadHistory';
import { getMessageDirection
// @ts-expect-error module not typed
} from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { getCallMetadataAttachment, getFromNumber, getToNumber
// @ts-expect-error module not typed
} from 'conversations-message-history/call/operators/callMetadataAttachmentGetters';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { INCOMING } from 'conversations-message-history/common-message-format/constants/messageDirections';
export const useLastPhoneNumber = ({
  threadId
}) => {
  const messageId = useLatestMessageIdForChannel({
    threadId,
    genericChannelId: CALLING_GENERIC_CHANNEL_ID
  });
  const threadHistory = useThreadHistory({
    threadId
  });
  const {
    asyncCommonMessage
  } = useCommonMessageWithId({
    messageId,
    threadHistory,
    threadId,
    deferred: Boolean(!messageId)
  });
  const commonMessage = getData(asyncCommonMessage);
  const direction = getMessageDirection(commonMessage);
  const callInfo = getCallMetadataAttachment(commonMessage);
  const defaultFromNumber = direction === INCOMING ? getToNumber(callInfo) : getFromNumber(callInfo);
  return defaultFromNumber;
};