import { triggerAsyncFailedEvent } from './asyncLoadEvents';
import { getIsDebugging } from './getIsDebugging';
import { getAppDomain } from './getAppDomain';

/**
 * This file mimics the contents of the loader in Navigation and will load
 * Notifications asyncronously.
 *
 * It should only be used if the navigation bar is not available in the app.
 */

export const SCRIPTS_ELEMENT_DATA_NAME = 'calling-cross-tab-messaging';
export let ScriptStatuses;
(function (ScriptStatuses) {
  ScriptStatuses["unknown"] = "unknown";
  ScriptStatuses["failed"] = "failed";
  ScriptStatuses["loaded"] = "loaded";
  ScriptStatuses["loading"] = "loading";
  ScriptStatuses["timeout"] = "timeout";
})(ScriptStatuses || (ScriptStatuses = {}));
export function loadCrossTabMessaging({
  onFailure
}) {
  const {
    domain
  } = getAppDomain();
  const isDebuggingCallingCrossTabMessaging = getIsDebugging();
  const isQA = !isDebuggingCallingCrossTabMessaging.localEmbed && domain.includes('qa');
  const staticDomainPrefix = isDebuggingCallingCrossTabMessaging.localEmbed ? '//local.hsappstatic.net' : '//static.hsappstatic.net';
  const bundleSrc = `${staticDomainPrefix}/calling-cross-tab-messaging/${isDebuggingCallingCrossTabMessaging.localEmbed ? 'static' : 'ex'}/js/asyncStart${isQA ? '-qa' : ''}.js`;
  const page = document.body;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  script.src = bundleSrc;
  script.dataset.status = ScriptStatuses.loading;
  script.dataset.name = SCRIPTS_ELEMENT_DATA_NAME;
  let timeout = undefined;
  script.onload = () => {
    clearTimeout(timeout);
    script.dataset.status = ScriptStatuses.loaded;
  };
  script.onerror = () => {
    clearTimeout(timeout);
    script.dataset.status = ScriptStatuses.failed;

    // Short circuit so we don't wait till timeout.
    onFailure();
    triggerAsyncFailedEvent();
  };
  timeout = setTimeout(() => {
    script.dataset.status = ScriptStatuses.timeout;
    onFailure();
    triggerAsyncFailedEvent();
  }, 60000);
  page.appendChild(script);
  return true;
}