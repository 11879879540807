import { useAsyncGenericChannelsWithCapabilities } from 'composer-data/channel-capabilities/public/hooks';
import { EMAIL_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
const FRONTEND_SUPPORTS_CHANNEL_SWITCHING = [EMAIL_GENERIC_CHANNEL_ID];
export function useAllowedMessageChannelIds({
  originalGenericChannelId
}) {
  const {
    data,
    loading
  } = useAsyncGenericChannelsWithCapabilities();
  const allowedChannelSwitchingChannels = data ? Object.keys(data).map(Number).filter(channelId => {
    const {
      capabilities
    } = data[channelId];
    const hasConversationStartCapability = Boolean(capabilities && capabilities.allowConversationStart);
    const isFEPreparedToChannelSwitch = FRONTEND_SUPPORTS_CHANNEL_SWITCHING.includes(channelId);
    const isOriginalChannel = channelId === originalGenericChannelId;
    return hasConversationStartCapability && isFEPreparedToChannelSwitch && !isOriginalChannel;
  }) : [];
  const originalChannelCapabilites = data && data[originalGenericChannelId] && data[originalGenericChannelId].capabilities;
  const originalChannelAllowsOutgoingMessages = Boolean(originalChannelCapabilites && originalChannelCapabilites.allowOutgoingMessages);
  const allowedMessageChannelIds = originalChannelAllowsOutgoingMessages ? [originalGenericChannelId, ...allowedChannelSwitchingChannels] : allowedChannelSwitchingChannels;
  return {
    allowedMessageChannelIds,
    loading
  };
}