import { useCallback } from 'react';
import { fetchThreaddHistory } from '../actions/fetchThreadHistory';
import { getAsyncPreviousMessageHistories } from '../selectors/getAsyncPreviousMessageHistories';
import { useThreadDataDispatch, useThreadDataSelector } from '../../../common/ThreadViewDataStore';
import { Set as ImmutableSet } from 'immutable';
import { useIndexedAsyncFetcher } from 'conversations-async-data/indexed-async-data/hooks/useIndexedAsyncFetcher';
export const useAsyncPreviousMessageHistoryHtml = ({
  threadId
}) => {
  const dispatch = useThreadDataDispatch();
  const fetcher = useCallback(({
    ids
  }) => dispatch(fetchThreaddHistory({
    threadIds: ids
  })), [dispatch]);
  const indexedAsyncData = useThreadDataSelector(getAsyncPreviousMessageHistories);
  const {
    entries,
    retryFailed
  } = useIndexedAsyncFetcher({
    fetcher,
    deferred: !threadId,
    indexedAsyncData,
    ids: ImmutableSet([threadId])
  });
  return {
    asyncPreviousMessageHistoryHtml: entries.get(threadId),
    fetchHistoryHtml: retryFailed
  };
};