import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import get from 'transmute/get';
import omit from 'transmute/omit';
import { omnibusFulfilled } from '../../../omnibus/public';
import { fetchViewMembers } from '../../../view-members-data/protected';
import { fetchVisitorService } from '../schema/fetchVisitorService';

/**
 * A redux thunk for fetching a specific visitor by vid
 */
export const fetchVisitor = createAsyncThunk('visitors/fetchVisitor', ({
  vid
}) => fetchVisitorService({
  vid
}));

/**
 * A redux thunk for refreshing the given visitor by vid. This is distinct from
 * fetchVisitor because this action will also invalidate contacts.
 */
export const refreshVisitor = createAsyncThunk('visitors/refreshVisitor', ({
  vid,
  threadId
}) => fetchVisitorService({
  vid
}).then(response => Object.assign({}, response, {
  threadId
})));

/**
 * Temporary type until we bring FilterKey into find-and-filter-lib
 */

const initialState = {
  data: {},
  filterKey: {},
  viewId: ''
};
export const visitorsSlice = createSlice({
  name: 'visitors',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchViewMembers.fulfilled, (state, {
      meta
    }) => {
      state.filterKey = {};
      if (state.viewId !== meta.arg.viewId) {
        // if the view has been changed, all previous visitors are invalidated
        state.viewId = meta.arg.viewId;
        state.data = {};
      }
    }).addCase('FETCH_SEARCH_AND_FILTER_RESULTS_SUCCEEDED', (state, action) => {
      const key = action.payload.requestArgs.filterKey;
      if (key.equals(state.filterKey) === false) {
        state.viewId = '';
        state.filterKey = key;
        state.data = {};
      }
      const members = action.payload.data.indexedMembers;
      members.forEach(member => {
        const vid = get('vid', member);
        const visitor = get('visitor', member);
        if (vid) {
          state.data[vid] = {
            loading: false,
            visitor: visitor
          };
        }
      });
    }).addCase(omnibusFulfilled, (state, {
      payload: {
        currentViewId,
        omnibus
      }
    }) => {
      state.filterKey = {};
      /**
       * https://private.hubteam.com/errors/js/view/205237466/error/Q2Fubm90JTIwcmVhZCUyMHByb3BlcnRpZXMlMjBvZiUyMG51bGwlMjAlMjhyZWFkaW5nJTIwJTI3cGFnZWRSZXN1bHQlMjclMjk%3D?timeRange=7%20days
       * omnibus.threadListMembers can be null, still investigating when and why that would happen
       */
      if (omnibus && omnibus.threadListMembers) {
        if (state.viewId !== currentViewId) {
          state.data = {};
        }
        state.viewId = currentViewId;
      }
    }).addMatcher(isAnyOf(fetchVisitor.fulfilled, refreshVisitor.fulfilled), (state, action) => {
      state.data[action.payload.vid] = {
        loading: false,
        visitor: omit(['threadId'], action.payload)
      };
    }).addMatcher(isAnyOf(fetchVisitor.rejected, refreshVisitor.rejected), (state, action) => {
      const {
        vid
      } = action.meta.arg;
      // create an empty "error" Visitor so we don't keep refetching it
      state.data[vid] = {
        loading: false,
        visitor: {
          canonicalVid: vid,
          vid
        }
      };
    }).addMatcher(isAnyOf(fetchVisitor.pending, refreshVisitor.pending), (state, action) => {
      const {
        vid
      } = action.meta.arg;
      state.data[vid] = {
        loading: true,
        visitor: null
      };
    });
  },
  reducers: {}
});