import Visitor from 'conversations-internal-schema/visitor/records/Visitor';
import { List, Map as ImmutableMap } from 'immutable';
import { getViewMemberId } from '../view-member/getViewMemberId';
export const buildPartialThreadListMember = attributes => {
  const {
    visitor
  } = attributes;
  return ImmutableMap(Object.assign({}, attributes, {
    threadId: getViewMemberId(attributes),
    visitor: visitor && Visitor(visitor)
  }));
};
export const buildPartialThreadListMembers = (members = List()) => List(members.map(buildPartialThreadListMember));