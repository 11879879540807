'use es6';

import { useSelector } from 'react-redux';
import { useIndexedAsyncAgentBotSenders } from 'conversations-thread-data/senders/hooks/useIndexedAsyncAgentBotSenders';
import { getPresentAgentLocators } from '../selectors/getPresentAgentLocators';
export const useThreadPresence = ({
  threadId
}) => {
  const senderLocators = useSelector(state => getPresentAgentLocators(state, {
    threadId
  }));
  const {
    agentBotSenders: asyncPresentSenders
  } = useIndexedAsyncAgentBotSenders({
    senderLocators
  });
  return {
    asyncPresentSenders
  };
};