/* eslint-disable no-console */

import Raven from 'raven-js';
import { getExtraErrorData } from './getExtraErrorData';
import { keplerAlerting } from './keplerAlerting';

/**
 * @description
 * Report an error to sentry, for more info on fingerprints see
 * https://blog.sentry.io/2018/01/18/setting-up-custom-fingerprints
 *
 * For help with tagging, see:
 * https://docs.sentry.io/enriching-error-data/context/?platform=javascript#tagging-events
 *
 * To enable local debugging, set the following option:
 * `localStorage.setItem('SENTRY_DEPLOYED', true);`
 *
 * Per the following Sentry issue, Sentry will automatically combine and throw out messages that
 * share similar stack traces. This makes it impossible to debug local errors since React will
 * cause errors to bubble up through `componentDidCatch` methods, forcing Sentry to effectively log
 * these errors twice. In order to work around this, building a new error in place inside the
 * `componentDidCatch` and attaching the stacktrace as a custom field of that error allows it to
 * reach Sentry.
 * https://github.com/getsentry/sentry-javascript/issues/1249
 */
export const reportError = ({
  error,
  fingerprint,
  tags = {}
}) => {
  const extraData = getExtraErrorData(error);
  Raven.captureException(error, {
    fingerprint,
    tags,
    extra: {
      error: extraData
    }
  });
  const lastEventId = Raven.lastEventId();
  console.error(`(Event Id: ${lastEventId})\nError reported with '${error.message}'`);
  if (extraData) {
    Object.keys(extraData).forEach(key => {
      if (extraData[key]) {
        console.error(`Extra Data [${key}]: `, extraData[key]);
      }
    });
  }
  keplerAlerting(error);
  return lastEventId;
};