import compact from 'hs-lodash/compact';
import map from 'transmute/map';
const matchDraftKey = (key, keyRegexp) => keyRegexp.exec(key);

/**
 * @description Get regex match objects for all keys in the store
 * that represent common reply drafts. Matches contain
 * the entire local storage key at the 0th index and the replyId
 * at the second index.
 * @returns {array} matches array
 * @argument {object} storageObj a plain js object (likely localStorage)
 */
export const getDraftKeyMatches = (storageObj, draftKeyPrefix) => {
  return compact(map(storageObjKey => {
    const keyRegExp = new RegExp(`${draftKeyPrefix}_([a-zA-Z0-9_.-]+)`);
    return matchDraftKey(storageObjKey, keyRegExp);
  }, Object.keys(storageObj)));
};