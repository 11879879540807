export let callDispositions;
(function (callDispositions) {
  callDispositions["BUSY"] = "BUSY";
  callDispositions["WRONG_NUMBER"] = "WRONG_NUMBER";
  callDispositions["NO_ANSWER"] = "NO_ANSWER";
  callDispositions["LEFT_LIVE_MESSAGE"] = "LEFT_LIVE_MESSAGE";
  callDispositions["LEFT_VOICEMAIL"] = "LEFT_VOICEMAIL";
  callDispositions["CONNECTED"] = "CONNECTED";
  callDispositions["SELECT_AN_OUTCOME"] = "SELECTED_AN_OUTCOME";
})(callDispositions || (callDispositions = {}));
export const callDispositionsIdMap = {
  BUSY: '9d9162e7-6cf3-4944-bf63-4dff82258764',
  WRONG_NUMBER: '17b47fee-58de-441e-a44c-c6300d46f273',
  NO_ANSWER: '73a0d17f-1163-4015-bdd5-ec830791da20',
  LEFT_LIVE_MESSAGE: 'a4c4c377-d246-4b32-a13b-75a56a4cd0ff',
  LEFT_VOICEMAIL: 'b2cf5968-551e-4856-9783-52b3da59a7d0',
  CONNECTED: 'f240bbac-87c9-4f6e-bf70-924b57d47db7',
  SELECT_AN_OUTCOME: undefined
};