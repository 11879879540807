// gimme: conversations-inbox-ui 06/05/2020 8722c66a58dcba4df92bee8f284215022188a305
'use es6';

import { getTimestamp } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { getMessages } from 'conversations-message-history/thread-history/operators/getters';
import { setMessages } from 'conversations-message-history/thread-history/operators/setters';
import merge from 'transmute/merge';
import pipe from 'transmute/pipe';
import sortBy from 'transmute/sortBy';
export const mergeMessagesIntoHistory = ({
  messages,
  threadHistory
}) => {
  if (!messages || !messages.size) {
    return threadHistory;
  }
  return pipe(merge(getMessages(threadHistory)), sortBy(getTimestamp), mergedMessages => setMessages(mergedMessages, threadHistory))(messages);
};