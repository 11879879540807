import { metricStore } from '../store';
import { Metrics } from '../util/Metrics';
export const stopTimer = (metricName, state) => {
  const metric = metricStore[metricName];
  if (!metric) return;
  const duration = performance.now() - metric.startTime;
  Metrics.timer(metricName, Object.assign({
    state
  }, metric.dimensions)).update(duration);
  delete metricStore[metricName];
};