import { useCallback, useEffect, useState } from 'react';
import emptyFunction from 'react-utils/emptyFunction';

// Avoid thundering herd if many clients fail at the same time
// https://en.wikipedia.org/wiki/Thundering_herd_problem
export function getRandomOffset(millis, step) {
  const offset = (Math.random() + 1) * millis;
  return Math.floor(offset + millis * step);
}
export function useRetry({
  retry,
  shouldRetry,
  onFailedAfterRetries = emptyFunction,
  maxRetries = 5,
  retryInterval = 1000 * 2
}) {
  const [retries, setRetries] = useState(0);
  const [doneRetrying, setDoneRetrying] = useState(false);
  useEffect(() => {
    if (doneRetrying) {
      return emptyFunction;
    }
    const timer = setTimeout(() => {
      if (!doneRetrying && retries >= maxRetries) {
        setDoneRetrying(true);
        onFailedAfterRetries();
        return;
      }
      if (shouldRetry()) {
        retry();
        setRetries(n => n + 1);
      } else {
        setDoneRetrying(true);
      }
    }, getRandomOffset(retryInterval, retries));
    return () => {
      clearTimeout(timer);
    };
  }, [doneRetrying, maxRetries, onFailedAfterRetries, retries, retry, retryInterval, shouldRetry]);
  return useCallback(() => {
    setRetries(0);
    setDoneRetrying(false);
    retry();
  }, [retry]);
}