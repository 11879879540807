// @ts-expect-error module not typed

import { setRecipientField, setGenericChannelId } from 'conversations-message-history/common-message-format/operators/commonMessageSetters';
import { List } from 'immutable';
import curry from 'transmute/curry';
import Recipient from 'conversations-message-history/common-message-format/records/Recipient';
import { HS_EMAIL_ADDRESS, HS_PHONE_NUMBER } from 'conversations-message-history/common-message-format/constants/deliveryIdentifierTypes';
import { EMAIL_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
import { buildEmailRecipientWithField } from 'conversations-message-history/common-message-format/operators/buildCommonMessageRecipients';
export const contactPropertyToDeliveryIdentifier = {
  ['email']: HS_EMAIL_ADDRESS,
  ['hs_whatsapp_phone_number']: HS_PHONE_NUMBER
};
export const buildGenericRecipientWithField = curry((recipientField, listOfAddresses) => {
  return listOfAddresses.map(recipient => {
    if (recipient.deliveryIdentifierType && recipient.deliveryIdentifierValue) {
      return Recipient({
        actorId: null,
        recipientField,
        deliveryIdentifier: {
          type: contactPropertyToDeliveryIdentifier[recipient.deliveryIdentifierType],
          value: recipient.deliveryIdentifierValue
        }
      });
    }
    return Recipient({
      actorId: null,
      recipientField,
      deliveryIdentifier: {
        type: HS_EMAIL_ADDRESS,
        value: recipient.address || ''
      }
    });
  }).toList();
});
export const identifyGenericChannelIdFromRecipients = recipients => {
  const firstRecipient = recipients.get(0);
  if (firstRecipient) {
    return firstRecipient.genericChannelId ? firstRecipient.genericChannelId : EMAIL_GENERIC_CHANNEL_ID;
  }
  return 'UNSET';
};
export const updateRecipientField = (fieldName, nextRecipients, commonMessage) => {
  const recipients = buildEmailRecipientWithField(fieldName, nextRecipients);

  // Update recipients fields on the Message.
  const updatedMessage = setRecipientField(fieldName, recipients, commonMessage);
  return updatedMessage;
};
export const updateGenericRecipientField = (fieldName, nextRecipients, commonMessage) => {
  const genericChannelId = identifyGenericChannelIdFromRecipients(nextRecipients);
  let updatedMessage = commonMessage;
  if (genericChannelId !== 'UNSET') {
    updatedMessage = setGenericChannelId(genericChannelId, commonMessage);
  }
  if (genericChannelId === EMAIL_GENERIC_CHANNEL_ID) {
    const recipients = buildEmailRecipientWithField(fieldName, nextRecipients);
    updatedMessage = setRecipientField(fieldName, recipients, updatedMessage);
  } else if (genericChannelId === 'UNSET') {
    updatedMessage = setRecipientField(fieldName, List(), updatedMessage);
  } else {
    const recipients = buildGenericRecipientWithField(fieldName, nextRecipients);
    updatedMessage = setRecipientField(fieldName, recipients, updatedMessage);
    updatedMessage = setRecipientField('CC', List(), updatedMessage);
    updatedMessage = setRecipientField('BCC', List(), updatedMessage);
  }
  return updatedMessage;
};