// original: https://git.hubteam.com/HubSpot/conversations-internal-schema/blob/1729733ab4b5cfdd5950af37c67157f5deb9589b/static/js/team/operators/setDataOnNodeAtAddress.js

import { getNodeAtAddress } from './getNodeAtAddress';
export const setDataOnNodeAtAddress = (keyAddress, data, tree) => {
  const keyAddressCopy = keyAddress.slice();
  const nodeToUpdate = getNodeAtAddress(keyAddress, tree);
  const updatedNode = nodeToUpdate.merge(data);
  const updatedKey = [];
  updatedKey.push(`${keyAddressCopy.shift()}`);
  while (keyAddressCopy.length) {
    updatedKey.push('childTeams');
    updatedKey.push(`${keyAddressCopy.shift()}`);
  }
  return tree.setIn(updatedKey, updatedNode);
};