import { useQuery } from '@apollo/client';
import { useGetHelpdeskTicketRules } from 'ui-addon-conversations-settings/tickets/queries/getHelpdeskTicketRules';
import { FetchTicket } from 'svh-tickets-lib/tickets/public/mutations';
export const useSavedPipelineInformation = ticketId => {
  var _ticketData$crmObject, _pipelineInfo$current, _pipelineInfo$pipelin, _pipelineInfo$pipelin2;
  const {
    data: ticketData,
    loading: ticketLoading
  } = useQuery(FetchTicket, {
    variables: {
      ticketId
    },
    skip: !ticketId
  });
  const pipelineInfo = ticketData ? ticketData === null || ticketData === void 0 || (_ticketData$crmObject = ticketData.crmObject) === null || _ticketData$crmObject === void 0 ? void 0 : _ticketData$crmObject.pipelineInfo : null;
  const savedStatusId = pipelineInfo === null || pipelineInfo === void 0 || (_pipelineInfo$current = pipelineInfo.currentStage) === null || _pipelineInfo$current === void 0 ? void 0 : _pipelineInfo$current.stageId;
  const savedPipelineId = pipelineInfo === null || pipelineInfo === void 0 || (_pipelineInfo$pipelin = pipelineInfo.pipeline) === null || _pipelineInfo$pipelin === void 0 ? void 0 : _pipelineInfo$pipelin.pipelineId;
  const savedPipelineStages = pipelineInfo === null || pipelineInfo === void 0 || (_pipelineInfo$pipelin2 = pipelineInfo.pipeline) === null || _pipelineInfo$pipelin2 === void 0 ? void 0 : _pipelineInfo$pipelin2.stages;
  return {
    savedPipelineId,
    savedStatusId,
    savedPipelineStages,
    ticketLoading
  };
};
export const useDefaultPipelineInformation = () => {
  const {
    data: defaultData,
    loading: defaultLoading
  } = useGetHelpdeskTicketRules();
  const rules = defaultData ? defaultData.helpdeskTicketRules.properties : null;
  const defaultPipelineId = rules === null || rules === void 0 ? void 0 : rules.hs_pipeline.value;
  const defaultStatusId = rules === null || rules === void 0 ? void 0 : rules.hs_pipeline_stage.value;
  return {
    defaultPipelineId,
    defaultStatusId,
    defaultLoading
  };
};