import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data", "previousData", "refetch", "stopPolling", "error"];
import { useQuery } from '@apollo/client';
import { FETCH_ASSOCIATED_OBJECTS_CARD_ASSOCIATIONS } from '../queries';
import memoize from 'transmute/memoize';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { SUCCESSFUL_DATA_POLL_TIME, Metrics } from '../helpers/Metrics';
const DEFAULT_SORT = [Object.freeze({
  property: 'hs_object_id',
  order: 'DESC'
})];
export const POLL_INTERVAL = 500;
export const UNKNOWN_ACTION = 'UNKNOWN';
const ASSOCIATION_CATEGORIES = Object.freeze({
  HUBSPOT_DEFINED: '0',
  USER_DEFINED: '1',
  INTEGRATOR_DEFINED: '2'
});
function getCompleteAssociationTypeId({
  associationCategory,
  associationTypeId
}) {
  const category = associationCategory ? ASSOCIATION_CATEGORIES[associationCategory] : null;
  if (!category || !associationTypeId) {
    return null;
  }
  return `${category}-${associationTypeId}`;
}
export const getAssociationsFilter = memoize((completeAssociationTypeId, fromObjectId) => {
  const filter = {
    property: `associations.${completeAssociationTypeId}`,
    operator: 'EQ',
    value: `${fromObjectId}`
  };
  return filter;
});
export function getImpliedUnlabeledAssociationDetails({
  associationTypes,
  unlabeledAssociationType
}) {
  const impliedUnlabeledAssociationCategory = unlabeledAssociationType && unlabeledAssociationType.associationCategory || associationTypes[0] && associationTypes[0].associationCategory || null;
  const impliedUnlabeledAssociationTypeId = unlabeledAssociationType && unlabeledAssociationType.associationTypeId || associationTypes[0] && associationTypes[0].associationTypeId || null;
  const impliedUnlabeledInverseAssociationTypeId = unlabeledAssociationType && unlabeledAssociationType.inverseAssociationTypeId || associationTypes[0] && associationTypes[0].inverseAssociationTypeId || null;
  return {
    impliedUnlabeledAssociationCategory,
    impliedUnlabeledAssociationTypeId,
    impliedUnlabeledInverseAssociationTypeId
  };
}
export function useFetchAssociatedObjectsCardAssociations({
  fetchPolicy,
  fromObjectId,
  impliedUnlabeledAssociationCategory,
  impliedUnlabeledInverseAssociationTypeId,
  primaryAssociationCategory,
  primaryInverseAssociationTypeId,
  toObjectTypeId
}) {
  const updateAssociationAction = useRef();
  const pollTime = useRef(0);
  const impliedUnlabeledCompleteAssociationTypeId = getCompleteAssociationTypeId({
    associationCategory: impliedUnlabeledAssociationCategory,
    associationTypeId: impliedUnlabeledInverseAssociationTypeId
  });
  const primaryCompleteAssociationTypeId = getCompleteAssociationTypeId({
    associationCategory: primaryAssociationCategory,
    associationTypeId: primaryInverseAssociationTypeId || null
  });
  const impliedUnlabeledAssociationFilterGroups = useMemo(() => {
    if (!impliedUnlabeledCompleteAssociationTypeId) {
      return [];
    }
    const associationFilter = getAssociationsFilter(impliedUnlabeledCompleteAssociationTypeId, fromObjectId);
    return [{
      filters: [associationFilter]
    }];
  }, [impliedUnlabeledCompleteAssociationTypeId, fromObjectId]);
  const primaryAssociationFilterGroups = useMemo(() => {
    if (!primaryCompleteAssociationTypeId) {
      return [];
    }
    const associationFilter = getAssociationsFilter(primaryCompleteAssociationTypeId, fromObjectId);
    return [{
      filters: [associationFilter]
    }];
  }, [primaryCompleteAssociationTypeId, fromObjectId]);
  const _useQuery = useQuery(FETCH_ASSOCIATED_OBJECTS_CARD_ASSOCIATIONS, {
      variables: {
        unlabeledAssociationFilterGroups: impliedUnlabeledAssociationFilterGroups,
        hasPrimaryAssociation: Boolean(primaryCompleteAssociationTypeId),
        offset: 0,
        pageSize: 5,
        primaryAssociationFilterGroups,
        sorts: DEFAULT_SORT,
        toObjectTypeId
      },
      fetchPolicy: fetchPolicy || 'cache-first',
      skip: !impliedUnlabeledCompleteAssociationTypeId
    }),
    {
      data,
      previousData,
      refetch,
      stopPolling,
      error
    } = _useQuery,
    useQueryResult = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  const handleRefetchAssociationsData = useCallback(async args => {
    const pageSize = data && data.impliedUnlabeledAssociationObjects.results.length > 5 ? data.impliedUnlabeledAssociationObjects.results.length : 5;

    // sometimes refetch returns undefined instead of a promise, so ensure that this returns a promise
    return await refetch(Object.assign({
      pageSize
    }, args));
  }, [data, refetch]);
  const handleStopPolling = useCallback(() => {
    updateAssociationAction.current = undefined;
    pollTime.current = 0;
    stopPolling();
  }, [stopPolling]);
  useEffect(() => {
    const difOfTotal = Math.abs(data && previousData ? previousData.impliedUnlabeledAssociationObjects.total - data.impliedUnlabeledAssociationObjects.total : 0);
    const isPollingAndDataChanged = difOfTotal > 0;
    if (updateAssociationAction.current) {
      pollTime.current += POLL_INTERVAL;
    }
    if (!isPollingAndDataChanged) {
      return;
    }
    Metrics.histogram(SUCCESSFUL_DATA_POLL_TIME, {
      associationAction: updateAssociationAction.current || UNKNOWN_ACTION
    }).update(pollTime.current);
    // We can only remove 1 association at a time when removing so we can stop polling
    if (updateAssociationAction.current === 'REMOVE_ASSOCIATION') {
      handleStopPolling();
    }
  }, [data, handleStopPolling, previousData]);

  // Assign alias here for primaryAssociation since there is a type generation bug
  // https://hubspot.slack.com/archives/CATGF5TQS/p1707755380329999
  return Object.assign({
    impliedUnlabeledAssociationObjects: data && data.impliedUnlabeledAssociationObjects,
    onStopPolling: handleStopPolling,
    pollTime,
    primaryAssociationObject: data && data.crmObjectsSearch,
    refetch: handleRefetchAssociationsData,
    updateAssociationAction,
    associationsDataError: error
  }, useQueryResult);
}