import pipe from 'transmute/pipe';

// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import AssignmentMessage from 'conversations-inbox-message-history/assignment/AssignmentMessage';
import TypicalResponseTimeMessage from 'conversations-inbox-message-history/availability/TypicalResponseTimeMessage';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import CommentMessage from 'conversations-inbox-message-history/comment/CommentMessage';
import CommonMessage from 'conversations-inbox-message-history/common-message/CommonMessage';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import ContextUpdateMessage from '../components/context-update/ContextUpdateMessage';
import CrmObjectLifecycleUpdateMessage from 'conversations-inbox-message-history/crm-object-lifecycle-update/CrmObjectLifecycleUpdateMessage';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import CrmObjectWorkflowEmailSentMessage from 'conversations-inbox-message-history/crm-object-workflow-email-sent/CrmObjectWorkflowEmailSentMessage';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import ShortDateLabel from 'conversations-inbox-message-history/dates/components/ShortDateLabel';
import EmailCapturePromptMessage from 'conversations-inbox-message-history/email-capture/EmailCapturePromptMessage';
import EmailCaptureResponseMessage from 'conversations-inbox-message-history/email-capture/EmailCaptureResponseMessage';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import FilteredChangeMessage from 'conversations-inbox-message-history/filtered-change/FilteredChangeMessage';
import InitialMessage from 'conversations-inbox-message-history/initial-message/InitialMessage';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import ActivelyPublishingMessage from 'conversations-inbox-message-history/publishing/ActivelyPublishingMessage';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import FailedToPublishMessage from 'conversations-inbox-message-history/publishing/FailedToPublishMessage';
import TypingIndicator from 'conversations-inbox-message-history/typing-indicator/TypingIndicator';
import OfficeHoursMessage from 'conversations-inbox-message-history/availability/OfficeHoursMessage';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import FeedbackSubmissionMessage from 'conversations-inbox-message-history/feedback-submission/components/FeedbackSubmissionMessage';
import BatchSmsGeneratedUserMessage from 'conversations-inbox-message-history/batch-sms-generated-user-message/components/BatchSmsGeneratedUserMessage';
import { DATE_MESSAGE } from 'conversations-message-history/dates/constants/messageTypes';
import { COMMON_MESSAGE } from 'conversations-message-history/common-message-format/constants/messageTypes';
import { CONTEXT_UPDATE } from 'conversations-message-history/context-update/constants/messageTypes';
import { CRM_OBJECT_WORKFLOW_EMAIL_SENT } from 'conversations-message-history/crm-object-workflow-email-sent/constants/messageTypes';
import { EMAIL_CAPTURE_PROMPT } from 'conversations-message-history/email-capture-prompt/constants/messageTypes';
import { EMAIL_CAPTURE_RESPONSE } from 'conversations-message-history/email-capture-response/constants/messageTypes';
import { FILTERED_CHANGE } from 'conversations-message-history/filtered-change-message/constants/messageTypes';
import { FEEDBACK_SUBMISSION } from 'conversations-message-history/feedback-submission/constants/messageTypes';
import { INITIAL_MESSAGE } from 'conversations-message-history/initial-message/constants/messageType';
import { OFFICE_HOURS } from 'conversations-message-history/office-hours-message/constants/messageTypes';
import { THREAD_COMMENT } from 'conversations-message-history/comment-message/constants/messageTypes';
import { TYPICAL_RESPONSE_TIME } from 'conversations-message-history/typical-response-time/constants/messageTypes';
import { ACTIVELY_PUBLISHING, FAILED_TO_PUBLISH } from 'conversations-message-history/unpublished-messages/constants/messageTypes';
import { THREAD_INBOX_UPDATED } from 'conversations-message-history/inbox-update-message/constants/messageTypes';
import { CRM_OBJECT_LIFECYCLE_UPDATE } from 'conversations-message-history/crm-object-lifecycle-update/constants/messageTypes';
import { TYPING } from 'conversations-message-history/typing-indicator/constants/messageTypes';
import { THREAD_STATUS_UPDATE } from 'conversations-message-history/thread-status-update/constants/messageTypes';
import { CHAT_TRANSCRIPT_SENT } from 'conversations-message-history/transcript-submission/constants/messageTypes';
import { TICKET_CREATION_FAILURE } from 'conversations-message-history/ticket-creation-failure/constants/messageTypes';
import { WORKFLOW_GENERATED_USER_MESSAGE } from 'conversations-message-history/workflow-generated-user-message/constants/messageTypes';
import { BATCH_SMS_GENERATED_USER_MESSAGE } from 'conversations-message-history/batch-sms-generated-user-message/constants/messageTypes';
import ThreadInboxUpdateMessage from '../../move-to-inbox/components/ThreadInboxUpdateMessage';
import { withOnRepublish } from '../providers/withOnRepublish';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import { withOnRestore } from '../providers/withOnRestore';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import { withUserId } from '../providers/withUserId';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import { withChannelsData } from '../providers/withChannelsData';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import { withFetchReplies } from '../providers/withFetchReplies';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import { withConnectedAccounts } from '../providers/withConnectedAccounts';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import { withTicketNavigationTracking } from '../providers/withTicketNavigationTracking';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import ThreadStatusUpdateMessage from 'conversations-inbox-message-history/status/ThreadStatusUpdateMessage';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import { withCommunicator } from '../providers/withCommunicator';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import { withInlineImageStatusValidator } from '../providers/withInlineImageStatusValidator';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import { withMessageDeletion } from '../providers/withMessageDeletion';
import { ASSIGNMENT_UPDATE } from 'conversations-message-history/assignment-update-message/constants/messageTypes';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import { withHoverState } from '../providers/withHoverState';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import TranscriptSubmission from 'conversations-inbox-message-history/transcript-submission/components/TranscriptSubmission';
// @ts-expect-error ts-migrate FIXME: Could not find a declaration file
import TicketCreationFailure from 'conversations-inbox-message-history/ticket-creation-failure/components/TicketCreationFailure';
import WorkflowGeneratedUserMessage from 'conversations-inbox-message-history/workflow-generated-user-message/components/WorkflowGeneratedUserMessage';
import { withWorkflowName } from '../providers/withWorkflowName';
import { withCampaignName } from '../providers/withCampaignName';
import { withDirectReplyToMessage } from '../providers/withDirectReplyToMessage';
import { withFriendlySenderName } from '../providers/withFriendlySenderName';
import { withChannelName } from 'conversations-thread-data/unified-thread-history/public/providers/withChannelName';
import withMessageActions from '../providers/withMessageActions';
export const InboxConfig = {
  [ACTIVELY_PUBLISHING]: ActivelyPublishingMessage,
  [ASSIGNMENT_UPDATE]: withUserId(AssignmentMessage),
  [CONTEXT_UPDATE]: withChannelsData(ContextUpdateMessage),
  [COMMON_MESSAGE]: pipe(withMessageActions, withChannelName, withFriendlySenderName, withDirectReplyToMessage, withOnRestore, withFetchReplies, withCommunicator, withInlineImageStatusValidator, withHoverState)(CommonMessage),
  [DATE_MESSAGE]: ShortDateLabel,
  [CRM_OBJECT_LIFECYCLE_UPDATE]: pipe(withUserId, withTicketNavigationTracking)(CrmObjectLifecycleUpdateMessage),
  [CRM_OBJECT_WORKFLOW_EMAIL_SENT]: CrmObjectWorkflowEmailSentMessage,
  [EMAIL_CAPTURE_PROMPT]: EmailCapturePromptMessage,
  [EMAIL_CAPTURE_RESPONSE]: EmailCaptureResponseMessage,
  [FAILED_TO_PUBLISH]: pipe(withChannelName, withOnRepublish, withFetchReplies, withFriendlySenderName, withConnectedAccounts)(FailedToPublishMessage),
  [FILTERED_CHANGE]: withUserId(FilteredChangeMessage),
  [INITIAL_MESSAGE]: InitialMessage,
  [OFFICE_HOURS]: OfficeHoursMessage,
  [THREAD_COMMENT]: pipe(withHoverState, withMessageDeletion, withFriendlySenderName)(CommentMessage),
  [TYPICAL_RESPONSE_TIME]: TypicalResponseTimeMessage,
  [TYPING]: TypingIndicator,
  [THREAD_INBOX_UPDATED]: withUserId(ThreadInboxUpdateMessage),
  [THREAD_STATUS_UPDATE]: pipe(withUserId)(ThreadStatusUpdateMessage),
  [FEEDBACK_SUBMISSION]: FeedbackSubmissionMessage,
  [CHAT_TRANSCRIPT_SENT]: TranscriptSubmission,
  [TICKET_CREATION_FAILURE]: withChannelsData(TicketCreationFailure),
  [WORKFLOW_GENERATED_USER_MESSAGE]: withWorkflowName(WorkflowGeneratedUserMessage),
  [BATCH_SMS_GENERATED_USER_MESSAGE]: withCampaignName(BatchSmsGeneratedUserMessage)
};