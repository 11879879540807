// gimme: conversations-inbox-ui 06/10/2020 24a2e66917cc934433dd111db7e06fd02943ca6a
'use es6';

import { createSelector } from 'reselect';
import get from 'transmute/get';
import { isStarted } from 'conversations-async-data/async-data/operators/statusComparators';
import { getEntries } from 'conversations-async-data/indexed-async-data/operators/getters';
import { getMessageTruncatedReplyRequestStatus } from './getMessageTruncatedReplyRequestStatus';
const getMessageIdFromProps = (_, {
  messageId
}) => messageId;
export const makeGetMessageReplyIsLoading = () => createSelector([getMessageIdFromProps, getMessageTruncatedReplyRequestStatus], (messageId, messageTruncatedReplyRequestStatus) => {
  const replyRequestStatus = get(messageId, getEntries(messageTruncatedReplyRequestStatus));
  return isStarted(replyRequestStatus);
});
export const getMessageReplyIsLoading = makeGetMessageReplyIsLoading();