import { useCallback } from 'react';
import Raven from 'raven-js';
import { sendFeedbackApi } from '../../../api/ChatSpotV1Api';
export const useInlineFeedback = () => {
  const handlePositiveFeedback = useCallback(({
    messageId,
    vote
  }) => {
    sendFeedbackApi({
      messageId,
      vote
    }).catch(error => {
      Raven.captureException(new Error('Failed to send positive feedback', error));
    });
  }, []);
  const handleNegativeFeedback = useCallback(({
    messageId,
    options,
    feedbackMessage,
    vote
  }) => {
    sendFeedbackApi({
      messageId,
      options,
      feedbackMessage,
      vote
    }).catch(error => {
      Raven.captureException(new Error('Failed to send negative feedback', error));
    });
  }, []);
  return {
    onPositiveFeedback: handlePositiveFeedback,
    onNegativeFeedback: handleNegativeFeedback
  };
};