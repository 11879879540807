import { handleActions, combineActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestSucceeded } from 'conversations-async-data/async-data/operators/requestSucceeded';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { MARK_AS_SPAM, BLOCK_THREAD, UNMARK_AS_SPAM, UNBLOCK_THREAD } from '../constants/asyncActionTypes';
const initialState = new AsyncData();
export const spamFilteringAndBlocking = handleActions({
  [combineActions(MARK_AS_SPAM.STARTED, BLOCK_THREAD.STARTED, UNMARK_AS_SPAM.STARTED, UNBLOCK_THREAD.STARTED).toString()](state) {
    return requestStarted(state);
  },
  [combineActions(MARK_AS_SPAM.SUCCEEDED, BLOCK_THREAD.SUCCEEDED, UNMARK_AS_SPAM.SUCCEEDED, UNBLOCK_THREAD.SUCCEEDED).toString()](state) {
    return requestSucceeded(state);
  },
  [combineActions(MARK_AS_SPAM.FAILED, BLOCK_THREAD.FAILED, UNMARK_AS_SPAM.FAILED, UNBLOCK_THREAD.FAILED).toString()](state) {
    return requestFailed(state);
  }
}, initialState);