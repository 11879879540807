const getURI = contentId => `presentations/v1/deck/${contentId}/share`;
const getInsertContent = ({
  shortLink,
  name
}) => {
  return {
    url: shortLink,
    text: name
  };
};
export function renderDocument({
  httpClient,
  contactEmail,
  contentId
}) {
  const promise = httpClient.post(getURI(contentId), {
    query: {
      sharedWith: contactEmail
    }
  }).then(res => {
    return getInsertContent(res);
  }).catch(error => {
    return Promise.reject(error);
  });
  return promise;
}