import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retryPubSub } from '../actions/retryPubSub';
import { DEFAULT_CLIENT_KEY } from '../constants/clientKeys';
import { isRetrying } from '../selectors/healthCheckSelectors';
/**
 * @typedef retrier
 * @type {Object}
 * @property {boolean} isRetrying - true if the connection is retrying
 * @property {function} retry - retry the connection
 */
/**
 * A hook to provide a retry function and retry state to a react component
 *
 * @param {function} startPubSub - action that the host app uses to initialize its pubsub connection
 * @returns {retrier}
 */
export const usePubSubRetrier = (startPubSub, {
  clientKey = DEFAULT_CLIENT_KEY
} = {}) => {
  const dispatch = useDispatch();
  const retry = useCallback(() => dispatch(retryPubSub(startPubSub, clientKey)), [clientKey, dispatch, startPubSub]);
  return {
    retry,
    isRetrying: useSelector(state =>
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
    isRetrying(state, {
      clientKey
    }))
  };
};