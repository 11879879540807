'use es6';

import curry from 'transmute/curry';
import ifThen from 'transmute/ifThen';
import pipe from 'transmute/pipe';
import { isPresentationalMessage } from '../../common-message/operators/isPresentationalMessage';
import { messageKeyInvariant } from '../invariants/messageKeyInvariant';
import { pubSubMessageRecordInvariant } from '../invariants/pubSubMessageRecordInvariant';
import { threadHistoryInvariant } from '../invariants/threadHistoryInvariant';
import { setMessage } from './setMessage';
import { sortMessages } from './sortMessages';

/**
 * Add a message to ThreadHistory and conditionally sort if the message is not
 * presentational.  Sorting can be skipped otherwise because the projection of
 * the history will not change.
 *
 * @param {string} messageKey
 * @param {MessageRecord} message
 * @param {ThreadHistory} threadHistory
 * @returns {ThreadHistory}
 */
export const addMessageToThreadHistory = curry((messageKey, message, threadHistory) => {
  messageKeyInvariant(messageKey);
  pubSubMessageRecordInvariant(message);
  threadHistoryInvariant(threadHistory);
  return pipe(setMessage(messageKey, message), ifThen(() => isPresentationalMessage(message), sortMessages))(threadHistory);
});