import { ASSIGNEE, HIDE_FILTERED, END_TIME, SEARCH, GENERIC_CHANNEL, START_TIME, STATUS, HIDE_TRASH, VID, CHANNEL_INSTANCE_ID } from '../../search-and-filter-schema/protected';
import updateAssigneeFilter from './updateAssigneeFilter';
import updateChannelFilter from './updateChannelFilter';
import { updateChannelInstanceIdFilter } from './updateChannelInstanceIdFilter';
import updateContactFilter from './updateContactFilter';
import updateEndTime from './updateEndTime';
import updateFilteredToggle from './updateFilteredToggle';
import updateSearchFilter from './updateSearchFilter';
import updateStartTime from './updateStartTime';
import updateStatusFilter from './updateStatusFilter';
import updateTrashToggle from './updateTrashToggle';
const updateChatFiltersByFilterKey = (key, value, filters) => {
  switch (key) {
    case ASSIGNEE:
      return updateAssigneeFilter(filters, value);
    case CHANNEL_INSTANCE_ID:
      return updateChannelInstanceIdFilter(filters, value);
    case HIDE_FILTERED:
      return updateFilteredToggle(filters, value);
    case END_TIME:
      return updateEndTime(filters, value);
    case SEARCH:
      return updateSearchFilter(filters, value);
    case GENERIC_CHANNEL:
      return updateChannelFilter(filters, value);
    case START_TIME:
      return updateStartTime(filters, value);
    case STATUS:
      return updateStatusFilter(filters, value);
    case HIDE_TRASH:
      return updateTrashToggle(filters, value);
    case VID:
      return updateContactFilter(filters, value);
    default:
      return filters;
  }
};
export default updateChatFiltersByFilterKey;