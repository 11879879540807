import { makeVar, useReactiveVar } from '@apollo/client';
const isFloatingEditorOpen = makeVar(false);
function open() {
  isFloatingEditorOpen(true);
}
function close() {
  isFloatingEditorOpen(false);
}
export function useFloatingEditorState() {
  const isOpen = useReactiveVar(isFloatingEditorOpen);
  return {
    isOpen,
    open,
    close
  };
}