// gimme: conversations-inbox-ui 05/26/2020 741c62dc71ca742adbc6d64d61d30de7cb46539f
'use es6';

import pipe from 'transmute/pipe';
import filter from 'transmute/filter';
import { threadHistoryInvariant } from 'conversations-message-history/thread-history/invariants/threadHistoryInvariant';
import { getMessages } from 'conversations-message-history/thread-history/operators/getters';
import { isContextUpdateMessage } from 'conversations-message-history/context-update/operators/isContextUpdateMessage';
export const getLatestContextUpdateMessage = threadHistory => {
  if (!threadHistory) {
    return null;
  }
  threadHistoryInvariant(threadHistory);
  return pipe(getMessages, filter(message => isContextUpdateMessage(message)), messages => messages && messages.last())(threadHistory);
};