'use es6';

import { TYPING_INDICATOR_TIMEOUT_MS } from '../../_internal/constants/typingIndicatorsConstants';
import { typingMessageReceived } from '../actions/typingMessageReceived';
import { typingMessageExpired } from '../actions/typingMessageExpired';
export const handleTypingMessage = ({
  message
}) => dispatch => {
  const timeoutId = setTimeout(() => {
    dispatch(typingMessageExpired({
      message
    }));
  }, TYPING_INDICATOR_TIMEOUT_MS);
  dispatch(typingMessageReceived({
    message,
    timeoutId
  }));
};