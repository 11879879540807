import debounce from 'transmute/debounce';
import { useState, useEffect, useCallback, useMemo } from 'react';
export const DEBOUNCE_DELAY = 200;
const buildQuery = searchQuery => ({
  query: searchQuery,
  count: 50,
  offset: 0
});
const useContentSearch = (search, transformData, onSearchChange, initialSearchQuery = '') => {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery || '');
  const performSearch = useCallback(_searchQuery => {
    search(buildQuery(_searchQuery)).then(results => {
      setData(results);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }, [search]);
  const debouncedSearch = useMemo(() => debounce(DEBOUNCE_DELAY, _searchQuery => {
    performSearch(_searchQuery);
    if (onSearchChange) onSearchChange();
  }), [onSearchChange, performSearch]);
  useEffect(() => {
    if (searchQuery.length === 0) {
      debouncedSearch.cancel();
      performSearch(searchQuery);
    } else {
      debouncedSearch(searchQuery);
    }
  }, [debouncedSearch, searchQuery, performSearch]);
  const options = useMemo(() => transformData ? transformData(data) : data, [data, transformData]);
  return [data, searchQuery, setSearchQuery, options, isLoading];
};
export default useContentSearch;