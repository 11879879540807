import { logPageAction } from 'calling-error-reporting/report/error';
import { CALLING_JS_API_ERROR } from '../constants/PageActions';
export function logJSAPIPageAction(tags) {
  logPageAction({
    key: CALLING_JS_API_ERROR,
    tags: Object.assign({
      project: window && window.hubspot &&
      // @ts-expect-error hubspot object not typed to include bender
      window.hubspot.bender &&
      // @ts-expect-error hubspot object not typed to include bender
      window.hubspot.bender.currentProject
    }, tags)
  });
}