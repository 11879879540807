import { ViewMembersPage } from 'find-and-filter-data/view-members-schema/public';
import { threadSeen } from 'find-and-filter-data/view-members-data/protected';
import { actions } from './filterValues';
import { createAction, createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { fetchFilteredThreadListMembers } from '../clients/fetchFilteredThreadListMembers';
import { buildViewMembersPage, mergeIncomingViewMembersPage } from 'find-and-filter-data/view-members-data/public';
import { doesThreadMatchFilters } from '../clients/doesThreadMatchFilters';
const initialState = {
  data: new ViewMembersPage(),
  loading: false
};
export const checkThreadMembership = createAsyncThunk('filterResults/checkThreadMembership', ({
  filterKey,
  inboxId,
  threadId
}) => doesThreadMatchFilters({
  filterKey,
  inboxId,
  threadId
}));
export const fetchSearchAndFilterResults = createAsyncThunk('filterResults/fetchFilterResults', ({
  filterKey,
  offsetTimestamp = null,
  offsetId = null,
  inboxId
}) => fetchFilteredThreadListMembers({
  filterKey,
  offsetTimestamp,
  offsetId,
  inboxId
}));
export const clearFilterResults = createAction('filterResults/clearFilterResults');
export const filterResults = createSlice({
  initialState,
  name: 'filterResults',
  reducers: {},
  extraReducers: builder => {
    builder.addCase(checkThreadMembership.rejected, (state, {
      meta
    }) => {
      const threadId = meta.arg.threadId;
      if (state.data.indexedMembers.has(`${threadId}`)) {
        state.data = state.data.update('indexedMembers', members => members.delete(`${threadId}`)).update('totalCount', count => count - 1);
      }
    }).addCase(fetchSearchAndFilterResults.pending, state => Object.assign({}, state, {
      loading: true
    })).addCase(fetchSearchAndFilterResults.rejected, () => initialState).addCase(fetchSearchAndFilterResults.fulfilled, (state, {
      meta,
      payload
    }) => {
      //This logic is almost identical to the logic in viewMembersSlice. Consider extracting duplicate
      //functionality once this has been moved to the f&f library.
      if (meta.arg.offsetId && meta.arg.offsetId > 0) {
        const oldPage = state.data;
        const newPage = buildViewMembersPage(payload).set('totalCount', oldPage.totalCount);
        const updatedPage = mergeIncomingViewMembersPage({
          oldPage,
          newPage,
          totalCount: payload.totalCount
        });
        state.data = updatedPage;
      } else {
        state.data = buildViewMembersPage(payload);
      }
      state.loading = false;
    }).addCase(threadSeen, (state, {
      payload
    }) => {
      state.data = state.data.setIn(['indexedMembers', payload.threadId, 'seen'], payload.seen);
    }).addMatcher(isAnyOf(clearFilterResults, actions.clearSearchAndFilterValues), () => initialState);
  }
});
export default filterResults.reducer;