import { fromJS, Iterable as ImmutableIterable, List as ImmutableList, Record as ImmutableRecord } from 'immutable';

// this more strictly types the ImmutableRecord.Factory so that we can
// accept different properties as values but return a record with Immutable-specific types
// See if we have something resembling an Immutable data structure
const checkIsImmutable = options => ImmutableIterable.isIterable(options) && ImmutableIterable.isKeyed(options);

// attempt to set the child teams for a set of values, if the exist
// this ensures that each of the child teams is turned into a `TeamBaseRecord`
const updateChildTeams = (values, KidTeamBase) => {
  if (!values) {
    return undefined;
  }
  return values.update('childTeams', ImmutableList(), childTeams => childTeams.map(childTeam => new KidTeamBase(childTeam)));
};

// wrapper to build ImmutableRecord factories for a team type that extends off of
// the `ServerTeamBase` type
export const TeamBase = (defaultValues, name) => {
  const fullDefaults = Object.assign({
    childTeams: [],
    id: 0,
    name: '',
    parentTeamId: null
  }, defaultValues);
  return class TeamBaseFactory extends ImmutableRecord(fullDefaults, name) {
    constructor(values) {
      const KidTeamBase = TeamBase(defaultValues, name);
      let finalValues;
      if (checkIsImmutable(values)) {
        finalValues = values;
      } else if (values) {
        finalValues = fromJS(values);
      }
      super(updateChildTeams(finalValues, KidTeamBase));
    }
  };
};