'use es6';

import { Component } from 'react';
import memoize from 'transmute/memoize';

// use class children since DND context expects a class component and will throw a warning on a functional component or Fragment
class UniversalDragDropContextWrapper extends Component {
  render() {
    return this.props.children;
  }
}
const getDragDropContextWrapper = memoize(DragDropContext => {
  return DragDropContext()(UniversalDragDropContextWrapper);
});
export default getDragDropContextWrapper;