'use es6';

import { createSelector } from 'reselect';
import pipe from 'transmute/pipe';
import { getEntry } from 'conversations-async-data/indexed-async-data/operators/getters';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { getCurrentThreadId } from '../../../thread-details/public/selectors';
import { getThreadHistories } from './getters';
import { COMPOSITION_THREAD_ID } from '../../../communicator/constants/compositionThreadId';
export const getCurrentThreadHistory = createSelector([getCurrentThreadId, getThreadHistories], (threadId, threadHistories) => {
  return threadId && threadId !== COMPOSITION_THREAD_ID && pipe(getEntry(threadId), getData)(threadHistories);
});