import { useQuery } from 'data-fetching-client';
import { FETCH_BILLING_LIMIT_DATA_QUERY_NAME, FETCH_BILLING_LIMIT_DATA_QUERY } from '../queries/fetchBillingLimitDataQuery';
export const useBillingLimitData = ({
  skip = false
}) => {
  const {
    data,
    loading,
    error
  } = useQuery(FETCH_BILLING_LIMIT_DATA_QUERY, {
    skip
  });
  const billingLimitData = data && data[FETCH_BILLING_LIMIT_DATA_QUERY_NAME];
  return {
    billingLimitData,
    loading,
    error
  };
};