import { createSelector } from 'reselect';
// @ts-expect-error module not typed
import { getMessages } from 'conversations-message-history/thread-history/operators/getters';
// @ts-expect-error module not typed
import { getCurrentThreadHistory } from 'conversations-thread-data/thread-histories/public/selectors/getCurrentThreadHistory';
// @ts-expect-error module not typed
import { isCommonMessageFormat } from 'conversations-message-history/common-message-format/operators/cmfComparators';
import { isMessagePublished } from 'conversations-message-history/common-message-format/operators/isMessagePublished';
// @ts-expect-error module not typed
import { getAllChannelsUsedWithChannelInstance } from '../../thread-details/selectors/getAllChannelsUsedWithChannelInstance';
// @ts-expect-error module not typed
import { getLatestGenericChannelUsed } from '../operators/getLatestGenericChannelUsed';
import { getGenericChannelId } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
// @ts-expect-error module not typed

export const getLastCMFGenericChannelIdFromThreadHistory = createSelector([getCurrentThreadHistory, getAllChannelsUsedWithChannelInstance], (threadHistory, channelsUsed) => {
  const lastCMFGenericChannelId = getGenericChannelId(getMessages(threadHistory).findLast(message => isCommonMessageFormat(message) && isMessagePublished(message)));
  if (!lastCMFGenericChannelId) return getGenericChannelId(getLatestGenericChannelUsed(channelsUsed));
  return lastCMFGenericChannelId;
});