import { fetchCallSummary } from '../clients/CallSummaryAPI';
import { registerQuery, useQuery } from 'data-fetching-client';
import { useEffect } from 'react';
const fetchCallSummaryQuery = registerQuery({
  fieldName: 'callSummary',
  args: ['executionId'],
  fetcher: ({
    executionId
  }) => {
    return fetchCallSummary({
      executionId
    });
  }
});
export const usePollForCallSummary = (executionId, skip) => {
  const {
    loading: isPollForCallSummaryLoading,
    error: pollForCallSummaryError,
    data,
    stopPolling
  } = useQuery(fetchCallSummaryQuery, {
    skip,
    pollInterval: 1000,
    variables: {
      executionId
    }
  });
  const callSummaryFromPolling = data && data.callSummary && data.callSummary.results && data.callSummary.results[0].value;
  useEffect(() => stopPolling(), [stopPolling]);
  return {
    callSummaryFromPolling,
    isPollForCallSummaryLoading,
    pollForCallSummaryError,
    stopPolling
  };
};