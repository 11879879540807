import http from 'conversations-http/clients/http';
const AGENT = 'AGENT';

/**
 *
 * @param {object} options
 * @param {object} options.httpClient
 * @param {function} options.httpClient.post
 * @param {number} options.threadId
 * @param {number} options.fileId
 * @returns {Promise}
 */
export const createFileMapping = ({
  threadId,
  fileId
}) => {
  return http.post(`conversations-threads/v2/attachment/map/thread/${threadId}`, {
    data: [fileId],
    query: {
      senderType: AGENT
    }
  });
};