'use es6';

import { createSelector } from 'reselect';
import { AGENT, BOT } from 'conversations-message-history/common-message-format/constants/legacySenderTypes';
import { getRecipientType } from 'conversations-message-history/legacy-recipients/operators/recipientLocatorGetters';
import { getIndexedAsyncSenders } from '../../senders/selectors/getIndexedAsyncSenders';
const getRecipientLocatorsFromProps = (state, {
  recipientLocators
}) => recipientLocators;
export const makeGetIndexedAsyncAgentBotRecipients = () => {
  return createSelector([getIndexedAsyncSenders, getRecipientLocatorsFromProps], (senders, recipientLocators) => {
    return senders.updateIn(['entries'], dataEntries => {
      return dataEntries.filter((_, recipientLocator) => (getRecipientType(recipientLocator) === AGENT || getRecipientType(recipientLocator) === BOT) && recipientLocators.has(recipientLocator));
    });
  });
};