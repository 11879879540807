import promiseTimeout from 'hs-promise-utils/timeout';
import { REALTIME_API_V1 } from '../constants/realtimeApiVersions';
import { PROMISE_TIMEOUT_MS } from '../constants/defaults';
import { profilePromise } from '../../metrics/profilePromise';
import { buildRequestTimeoutError } from '../../errors/builders/buildRequestTimeoutError';
import { playbackMessages } from './playbackMessages';
import { reportAndCheckNetworkOnAblyTimeout } from './reportAndCheckNetworkOnAblyTimeout';
import { ATTACHED } from '../constants/channelStates';
import { attach } from './attach';
/**
 * @description Subscribe to a channel and (optionally) playback recent history
 */
export function performSubscribe({
  channel,
  onMessage,
  onPlayback,
  playbackMessages: shouldPlaybackMessages,
  reporter = {
    captureEvent: () => {},
    setAdditionalContext: () => {}
  }
}) {
  return promiseTimeout(new Promise(resolve => {
    channel.subscribe(REALTIME_API_V1, onMessage);
    const replayRecentHistory = () => {
      if (!shouldPlaybackMessages && !onPlayback) {
        return Promise.resolve();
      }
      return profilePromise(reporter, 'message-playback', playbackMessages)({
        channel,
        onMessage,
        onPlayback
      });
    };

    // If the channel isn't attached, attempt an attach.
    const attachPromise = channel.state === ATTACHED ? Promise.resolve() : profilePromise(reporter, 'attach', attach)({
      channel
    });

    // After a successful attach
    attachPromise.then(replayRecentHistory).catch(reportAndCheckNetworkOnAblyTimeout)
    /* Counting and logging to sentry happens as part of profile promise. */.catch(error =>
    // eslint-disable-next-line no-console
    console.log('Channel attach failed - ', error.message));
    resolve();
  }), PROMISE_TIMEOUT_MS, buildRequestTimeoutError('PubSub subscribe timeout', {
    channelName: channel.name
  }));
}