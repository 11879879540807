// @ts-expect-error no types
import { DropTarget } from 'react-dnd';
// @ts-expect-error no types
import { NativeTypes } from 'react-dnd-html5-backend';
import { FILE_DROP } from './editorCapabilities';
import { DropPlugin } from './plugins/DropPlugin';
const fileTarget = {
  drop(props, monitor) {
    const files = monitor.getItem().files;
    const {
      editorState,
      setEditorState,
      editorConfig
    } = props;
    const fileDrop = editorConfig[FILE_DROP];
    if (fileDrop) {
      setEditorState(editorState.apply(editorState.tr.setMeta(DropPlugin, {
        files
      })));
    }
  }
};
function collector(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}
export const WithDropTarget = DropTarget(NativeTypes.FILE, fileTarget, collector);