import { compose } from 'redux';
import Immutable from 'immutable';
export const getCompose = ({
  name
}) => {
  const shouldEnableDevTools =
  // eslint-disable-next-line hs-react-native/no-web-globals
  typeof window === 'object' &&
  // eslint-disable-next-line hs-react-native/no-web-globals
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  if (shouldEnableDevTools) {
    // eslint-disable-next-line hs-react-native/no-web-globals
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      name,
      maxAge: 200,
      serialize: {
        options: {
          circular: '[CIRCULAR]'
        },
        immutable: Immutable
      }
    });
  }
  return compose;
};