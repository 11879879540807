export const UPDATE_CALLEE_PHONE_NUMBER = 'UPDATE_CALLEE_PHONE_NUMBER';
export const PAGE_NAVIGATION = 'PAGE_NAVIGATION';
export const ON_CALL_SAVED = 'ON_CALL_SAVED';
export const ON_CREATED_FOLLOWUP_TASK = 'ON_CREATED_FOLLOWUP_TASK';
export const ON_CALL_STARTED = 'ON_CALL_STARTED';
export const UPSERT_ENGAGEMENT_IN_TIMELINE_BY_ID = 'UPSERT_ENGAGEMENT_IN_TIMELINE_BY_ID';
export const UPDATE_TASK = 'UPDATE_TASK';
export const SET_BROWSER_HISTORY = 'SET_BROWSER_HISTORY';
export const HIDE_CALLING_WIDGET = 'HIDE_CALLING_WIDGET';
export const SET_CALL_METHOD = 'SET_CALL_METHOD';
export const SET_SUBJECT_FOR_THIRD_PARTIES = 'SET_SUBJECT_FOR_THIRD_PARTIES';
export const ADD_CALLABLE_ASSOCIATION = 'ADD_CALLABLE_ASSOCIATION';
export const REMOVE_CALLABLE_ASSOCIATION = 'REMOVE_CALLABLE_ASSOCIATION';
export const MINIMIZE_CALLING_WIDGET = 'MINIMIZE_CALLING_WIDGET';
export const PRELOAD_WIDGET = 'PRELOAD_WIDGET';
export const SET_CALLING_PROVIDER_SETTING = 'SET_CALLING_PROVIDER_SETTING';
export const OPEN_WIDGET = 'OPEN_WIDGET';
export const OPEN_THIRD_PARTY_WIDGET = 'OPEN_THIRD_PARTY_WIDGET';
export const HIDE_THIRD_PARTY_WIDGET = 'HIDE_THIRD_PARTY_WIDGET';
export const UPDATE_THIRD_PARTY_SUBJECT = 'UPDATE_THIRD_PARTY_SUBJECT';
export const ON_CALL_SAVED_THIRD_PARTY = 'ON_CALL_SAVED_THIRD_PARTY';
export const UPDATE_THREAD_ID = 'UPDATE_THREAD_ID';