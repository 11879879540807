import { useCallback } from 'react';
import { fetchGenericChannels } from '../actions/fetchGenericChannels';
import { Set as ImmutableSet } from 'immutable';
import { getIndexedAsyncGenericChannels } from '../selectors/getIndexedAsyncGenericChannels';
import { useThreadDataDispatch, useThreadDataSelector } from '../../../common/ThreadViewDataStore';
import { useIndexedAsyncFetcher } from 'conversations-async-data/indexed-async-data/hooks/useIndexedAsyncFetcher';
export const useGenericChannels = ({
  inboxId,
  deferred = false
}) => {
  const dispatch = useThreadDataDispatch();
  const fetcher = useCallback(({
    ids
  }) => dispatch(fetchGenericChannels({
    inboxIds: ids
  })), [dispatch]);
  const indexedAsyncData = useThreadDataSelector(getIndexedAsyncGenericChannels);
  const {
    entries,
    retryFailed
  } = useIndexedAsyncFetcher({
    fetcher,
    deferred: deferred || !inboxId,
    indexedAsyncData,
    ids: ImmutableSet([`${inboxId}`])
  });

  // Converting inboxId to string becasue the keys in ImmutableMap are stored as strings.
  return {
    asyncGenericChannels: entries.get(`${inboxId}`),
    retryFailed
  };
};