'use es6';

import { getData } from 'conversations-async-data/async-data/operators/getters';
import { isLoading } from 'conversations-async-data/async-data/operators/statusComparators';
import { useIndexedAsyncHydratedEmailAddresses } from 'composer-data/email-metadata/public/hooks';
import { Set as ImmutableSet } from 'immutable';
import { getEmail } from 'conversations-internal-schema/visitor/operators/visitorGetters';
import { getVid, getVisitor } from 'conversations-thread-data/thread-details/public/operators';
const getFirstRecipientWithContactId = indexedAsyncEmailAddresses => {
  const firstRecipientWithVid = indexedAsyncEmailAddresses.valueSeq().filter(asyncData => {
    const data = getData(asyncData);
    return data && data.get('id');
  }).first();
  return firstRecipientWithVid && getData(firstRecipientWithVid);
};
export const useContactInfoForEditor = ({
  emailAddresses,
  threadDetails
}) => {
  const {
    indexedAsyncEmailAddresses
  } = useIndexedAsyncHydratedEmailAddresses({
    emailAddresses: ImmutableSet(emailAddresses)
  });
  if (emailAddresses && emailAddresses.size) {
    const firstEmail = emailAddresses.first();
    if (isLoading(indexedAsyncEmailAddresses.get(firstEmail))) {
      return {};
    }
    const firstRecipientWithVid = getFirstRecipientWithContactId(indexedAsyncEmailAddresses);
    if (firstRecipientWithVid) {
      return {
        contactVid: firstRecipientWithVid.get('id'),
        contactEmail: firstRecipientWithVid.get('address')
      };
    } else if (getEmail(getVisitor(threadDetails))) {
      return {
        contactVid: getVid(threadDetails),
        contactEmail: getEmail(getVisitor(threadDetails))
      };
    } else {
      const firstEmailRecipient = emailAddresses.first();
      return {
        contactVid: undefined,
        contactEmail: firstEmailRecipient
      };
    }
  }
  return {
    contactVid: getVid(threadDetails),
    contactEmail: getEmail(getVisitor(threadDetails))
  };
};