import I18n from 'I18n';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { deleteThread } from '../clients/deleteThread';
import { SOFT_DELETE_THREAD } from '../constants/asyncActionTypes';
const softDeleteThreadClient = ({
  threadId
}) => deleteThread({
  threadId,
  hardDelete: false
});
export const softDeleteThread = createAsyncAction({
  requestFn: softDeleteThreadClient,
  actionTypes: SOFT_DELETE_THREAD,
  toRecordFn: () => {},
  successMetaActionCreator: () => ({
    notification: {
      type: 'success',
      titleText: I18n.text('conversations-thread-view.alerts.titles.success'),
      message: I18n.text('conversations-thread-view.alerts.DELETE_THREAD_SUCCEEDED.message')
    }
  }),
  failureMetaActionCreator: () => ({
    notification: {
      type: 'danger',
      titleText: I18n.text('conversations-thread-view.alerts.DELETE_THREAD_FAILED.title')
    }
  })
});