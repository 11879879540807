// gimme: conversations-inbox-ui 06/05/2020 2e71d6e614963e241a90482877a5457fbcbaab8a
import invariant from 'react-utils/invariant';
import { combineActions, handleActions } from 'redux-actions';

// @ts-expect-error module not typed
import ThreadHistory from 'conversations-message-history/thread-history/records/ThreadHistory';
// @ts-expect-error module not typed
import { addPageOfMessagesToThreadHistory } from 'conversations-message-history/thread-history/operators/addPageOfMessagesToThreadHistory';
// @ts-expect-error module not typed
import { addMessageToThreadHistory } from 'conversations-message-history/thread-history/operators/addMessageToThreadHistory';
import { getId, getTimestamp, getRichText
// @ts-expect-error module not typed
} from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { setVisitorLastReadAtTimestamp, setMessages, setFriendlyNameResults
// @ts-expect-error module not typed
} from 'conversations-message-history/thread-history/operators/setters';
// @ts-expect-error module not typed
import { isFromVisitor } from 'conversations-message-history/common-message-format/operators/senderTypeComparators';
// @ts-expect-error module not typed
import { getUpdates } from 'conversations-message-history/message-updates/operators/messagesUpdateGetters';

// @ts-expect-error module not typed
import { getMessageFromThreadHistory } from 'conversations-message-history/thread-history/operators/getMessageFromThreadHistory';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { limitToCount } from 'conversations-async-data/indexed-async-data/eviction-strategies/limitToCount';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { getEntry } from 'conversations-async-data/indexed-async-data/operators/getters';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { updateAsyncData } from 'conversations-async-data/async-data/operators/updateAsyncData';
import { THREAD_HISTORY } from '../../public/constants/asyncActionTypes';
import { MESSAGES_UPDATED, READ_RECEIPT_RECEIVED, HISTORY_MESSAGE_RECEIVED, THREAD_STATUS_UPDATED, DIRECT_REPLY_RECEIVED } from '../../../thread-view-realtime/public/constants/actionTypes';
import { FETCH_THREAD_LIST_MEMBER_DETAILS, SEED_THREAD_HISTORY } from '../../../thread-details/public/constants';
// @ts-expect-error module not typed
import { applyMessageUpdatesToThreadHistory } from 'conversations-message-history/thread-history/operators/applyMessageUpdatesToThreadHistory';
import { PUBLISH_MESSAGE } from '../../../pubsub/public/constants/asyncActionTypes';
// @ts-expect-error module not typed
import { getMessages } from 'conversations-message-history/thread-history/operators/getters';
import { FETCH_DELETED_MESSAGES, DELETE_THREAD_MESSAGE } from '../../../thread-deletion/public/constants/asyncActionTypes';
import { CMF_EMAIL_MESSAGE_FETCH } from '../../../email-common-message-format/public/constants/asyncActionTypes';
import { PUBLISH_EMAIL, FETCH_OVERSIZED_COMMON_MESSAGE } from '../../../email/public/constants/asyncActionTypes';
import { REFETCH_CONVERSATION_MESSAGE } from '../../../thread-view-realtime/public/constants/asyncActionTypes';
import { INITIALIZE_STORE_DATA, REFRESH_STORE_DATA } from '../../../store/public/constants';
import { MESSAGE_REFRESH } from '../../../email-inline-images/public/constants/asyncActionTypes';
import { getAlteredImgSrcCommonMessage } from '../../../email-inline-images/public/operators/getAlteredImgSrcCommonMessage';
// @ts-expect-error module not typed
import { updateMessageInThreadHistory } from 'conversations-message-history/thread-history/operators/updateMessageInThreadHistory';
import { mergeDirectReplies } from 'conversations-message-history/thread-history/operators/mergeDirectReplies';
import { THREAD_DETAILS_ASYNC_DATA_CACHE_SIZE } from '../../../common/public/constants/CacheSize';
import { mergeFriendlyNames } from 'conversations-message-history/thread-history/operators/mergeFriendlyNames';
import { addReplyRecommendationEntry } from 'conversations-message-history/reply-recommendations/operators/addReplyRecommendationEntry';
import { FRIENDLY_NAMES_RECEIVED, SET_FRIENDLY_NAMES } from '../../../friendly-names/public/constants';
import { REPLY_RECOMMENDATION_RECEIVED } from '../../../reply-recommendations/public/constants';
const threadIdInvariant = (threadId, actionType) => {
  invariant(threadId, `inboxThreadHistories: expected to receive threadId in handler for ${actionType}, got ${threadId}`);
};
const initialState = new IndexedAsyncData({
  name: 'inboxThreadHistories',
  evict: limitToCount(THREAD_DETAILS_ASYNC_DATA_CACHE_SIZE),
  notSetValue: new AsyncData({
    data: new ThreadHistory()
  })
});
export default handleActions({
  [INITIALIZE_STORE_DATA](state, action) {
    const {
      threadId,
      threadHistory
    } = action.payload.omnibusData;
    if (!threadId || !threadHistory) return state;
    return updateEntry(threadId, requestSucceededWithOperator(addPageOfMessagesToThreadHistory(threadHistory)), state);
  },
  [SEED_THREAD_HISTORY](state, action) {
    const {
      threadId,
      threadHistory
    } = action.payload;
    if (!threadId || !threadHistory) return state;
    return updateEntry(threadId, requestSucceededWithOperator(addPageOfMessagesToThreadHistory(threadHistory)), state);
  },
  [REFRESH_STORE_DATA](state, action) {
    const {
      threadId,
      threadHistory
    } = action.payload;
    if (!threadId || !threadHistory) return state;
    return updateEntry(threadId, requestSucceededWithOperator(addPageOfMessagesToThreadHistory(threadHistory)), state);
  },
  [combineActions(FETCH_THREAD_LIST_MEMBER_DETAILS.STARTED, THREAD_HISTORY.STARTED).toString()](state, action) {
    const {
      requestArgs
    } = action.payload;
    threadIdInvariant(requestArgs.threadId, action.type);
    return updateEntry(requestArgs.threadId, requestStarted, state);
  },
  [FETCH_DELETED_MESSAGES.STARTED](state, action) {
    const {
      threadId
    } = action.payload;
    threadIdInvariant(threadId, action.type);
    return updateEntry(threadId, requestStarted, state);
  },
  [DELETE_THREAD_MESSAGE.STARTED](state, action) {
    const {
      threadId
    } = action.payload.requestArgs;
    threadIdInvariant(threadId, action.type);
    return updateEntry(threadId, requestStarted, state);
  },
  [FETCH_THREAD_LIST_MEMBER_DETAILS.SUCCEEDED](state, action) {
    const {
      requestArgs,
      data
    } = action.payload;
    const {
      threadHistory
    } = data;
    threadIdInvariant(requestArgs.threadId, action.type);
    return updateEntry(requestArgs.threadId, requestSucceededWithOperator(addPageOfMessagesToThreadHistory(threadHistory)), state);
  },
  [THREAD_HISTORY.SUCCEEDED](state, action) {
    const {
      requestArgs,
      data: {
        history: newHistoryPage
      }
    } = action.payload;
    threadIdInvariant(requestArgs.threadId, action.type);
    return updateEntry(requestArgs.threadId, requestSucceededWithOperator(addPageOfMessagesToThreadHistory(newHistoryPage)), state);
  },
  [FETCH_DELETED_MESSAGES.SUCCEEDED](state, action) {
    const {
      threadId,
      threadHistory
    } = action.payload;
    threadIdInvariant(threadId, action.type);
    return updateEntry(threadId, requestSucceededWithOperator(() => threadHistory), state);
  },
  [DELETE_THREAD_MESSAGE.SUCCEEDED](state, action) {
    const {
      threadId,
      threadHistory,
      messageId
    } = action.payload.requestArgs;
    const messages = getMessages(new ThreadHistory(threadHistory));
    const newHistory = setMessages(messages.filterNot(msg => {
      return msg.id === messageId;
    }), threadHistory);
    threadIdInvariant(threadId, action.type);
    return updateEntry(threadId, requestSucceededWithOperator(() => newHistory), state);
  },
  [combineActions(FETCH_THREAD_LIST_MEMBER_DETAILS.FAILED, THREAD_HISTORY.FAILED).toString()](state, action) {
    const {
      requestArgs
    } = action.payload;
    threadIdInvariant(requestArgs.threadId, action.type);
    return updateEntry(requestArgs.threadId, requestFailed, state);
  },
  [FETCH_DELETED_MESSAGES.FAILED](state, action) {
    const {
      threadId
    } = action.payload;
    threadIdInvariant(threadId, action.type);
    return updateEntry(threadId, requestFailed, state);
  },
  [DELETE_THREAD_MESSAGE.FAILED](state, action) {
    const {
      threadId
    } = action.payload.requestArgs;
    threadIdInvariant(threadId, action.type);
    return updateEntry(threadId, requestFailed, state);
  },
  [CMF_EMAIL_MESSAGE_FETCH.SUCCEEDED](state, action) {
    const {
      data: message,
      requestArgs: {
        threadId
      }
    } = action.payload;
    const messageKey = getId(message);
    threadIdInvariant(threadId, action.type);
    return updateEntry(threadId, updateAsyncData(addMessageToThreadHistory(messageKey, message)), state);
  },
  [MESSAGE_REFRESH](state, action) {
    const {
      messageId,
      threadId
    } = action.payload;
    threadIdInvariant(threadId, action.type);
    const message = getMessageFromThreadHistory(messageId, getData(getEntry(threadId, state)));
    const currentRichText = getRichText(message);
    const hasImgTag = currentRichText && currentRichText.match(/<img/);
    if (!currentRichText || !hasImgTag) return state;
    const alteredMessage = getAlteredImgSrcCommonMessage(currentRichText, message);
    return updateEntry(threadId, updateAsyncData(updateMessageInThreadHistory(messageId, alteredMessage)), state);
  },
  [PUBLISH_EMAIL.SUCCEEDED](state, action) {
    const {
      message,
      threadId
    } = action.payload;
    const messageKey = getId(message);
    threadIdInvariant(threadId, action.type);
    const messageInRedux = getMessageFromThreadHistory(messageKey, getData(getEntry(threadId, state)));

    // if HISTORY_MESSAGE_RECEIVED already added message to thread history
    // then we shouldn't replace it with a more out of date one
    if (messageInRedux) return state;
    return updateEntry(threadId, updateAsyncData(addMessageToThreadHistory(messageKey, message)), state);
  },
  [combineActions(PUBLISH_MESSAGE.SUCCEEDED, HISTORY_MESSAGE_RECEIVED).toString()](state, action) {
    const {
      message,
      transient,
      threadId
    } = action.payload;
    const messageKey = getId(message);
    threadIdInvariant(threadId, action.type);
    return !transient ? updateEntry(threadId, updateAsyncData(addMessageToThreadHistory(messageKey, message)), state) : state;
  },
  [DIRECT_REPLY_RECEIVED](state, action) {
    const {
      newDirectReplies,
      threadId
    } = action.payload;
    threadIdInvariant(threadId, action.type);
    return updateEntry(threadId, updateAsyncData(mergeDirectReplies(newDirectReplies)), state);
  },
  [FRIENDLY_NAMES_RECEIVED](state, action) {
    const {
      newFriendlyNames,
      threadId
    } = action.payload;
    threadIdInvariant(threadId, action.type);
    return updateEntry(threadId, updateAsyncData(mergeFriendlyNames(newFriendlyNames)), state);
  },
  [SET_FRIENDLY_NAMES](state, action) {
    const {
      threadId,
      friendlyNames
    } = action.payload;
    threadIdInvariant(threadId, action.type);
    return updateEntry(threadId, updateAsyncData(setFriendlyNameResults(friendlyNames)), state);
  },
  [READ_RECEIPT_RECEIVED](state, action) {
    const {
      message,
      threadId
    } = action.payload;
    const timestamp = getTimestamp(message);
    threadIdInvariant(threadId, action.type);
    return isFromVisitor(message) ? updateEntry(threadId, updateAsyncData(setVisitorLastReadAtTimestamp(timestamp)), state) : state;
  },
  [MESSAGES_UPDATED](state, action) {
    const {
      threadId,
      message
    } = action.payload;
    const updates = getUpdates(message);
    threadIdInvariant(threadId, action.type);
    return updateEntry(threadId, updateAsyncData(applyMessageUpdatesToThreadHistory(updates)), state);
  },
  [THREAD_STATUS_UPDATED](state, action) {
    const {
      message,
      threadId
    } = action.payload;
    const messageKey = getId(message);
    threadIdInvariant(threadId, action.type);
    return updateEntry(threadId, updateAsyncData(addMessageToThreadHistory(messageKey, message)), state);
  },
  [FETCH_OVERSIZED_COMMON_MESSAGE.SUCCEEDED](state, action) {
    const {
      data,
      requestArgs
    } = action.payload;
    const {
      threadId
    } = requestArgs;
    threadIdInvariant(threadId, action.type);
    const messageKey = getId(data);
    const messageInRedux = getMessageFromThreadHistory(messageKey, getData(getEntry(threadId, state)));
    if (messageInRedux) return state;
    return updateEntry(threadId, updateAsyncData(addMessageToThreadHistory(messageKey, data)), state);
  },
  [REPLY_RECOMMENDATION_RECEIVED](state, action) {
    const {
      threadId
    } = action.payload;
    const message = action.payload && action.payload.message;
    const replyRecs = message && message.replyRecommendations;
    return updateEntry(threadId, updateAsyncData(addReplyRecommendationEntry(replyRecs)), state);
  },
  [REFETCH_CONVERSATION_MESSAGE.SUCCEEDED](state, action) {
    const {
      data,
      requestArgs
    } = action.payload;
    const {
      threadId
    } = requestArgs;
    threadIdInvariant(threadId, action.type);
    const messageKey = getId(data);
    return updateEntry(threadId, updateAsyncData(addMessageToThreadHistory(messageKey, data)), state);
  }
}, initialState);