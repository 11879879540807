// For V3 gates: https://product.hubteam.com/docs/gating/next-gen/usage.html#frontend-usage
import { gate as v3Gate } from 'hub-http/gates';
import { userInfoSync } from 'hub-http/userInfo';
import Raven from 'raven-js';
import { useMemo } from 'react';
import memoize from 'transmute/memoize';
export function getAuth() {
  try {
    return userInfoSync();
  } catch (err) {
    Raven.captureException(err, {
      extra: {
        description: 'Failed to retrieve gates synchronously. This is not expected.'
      }
    });
    return {
      gates: [],
      user: null,
      portal: null
    };
  }
}
export function useAuth() {
  const auth = getAuth();
  return useMemo(() => auth,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [auth.gates.length, auth.user, auth.portal]);
}
const portalHasGate = memoize((gates, gate) => {
  return gates.includes(gate);
}, (gates, gate) => `gate: ${gate}, gatesLength: ${gates.length}`);
export function hasGate(gate) {
  const {
    gates
  } = getAuth();
  return portalHasGate(gates, gate);
}
export const useHasIteration2Gate = () => hasGate(v3Gate('commerce-billing-wizard:iteration-2'));
const userHasScope = memoize((scopes, scope) => {
  return scopes.includes(scope);
}, (scopes, scope) => `scope: ${scope}, scopesLength: ${scopes.length}`);
export function hasScope(scope) {
  const {
    user
  } = getAuth();
  return userHasScope((user === null || user === void 0 ? void 0 : user.scopes) || [], scope);
}

// https://tools.hubteamqa.com/launch/scopes/2200
export const COMMERCE_SUBSCRIPTION_WRITE_SCOPE = 'subscriptions-write';