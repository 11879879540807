/**
 * Throttle calls to an API.  The first call must complete before the subsequent call is started.
 *
 * @param {Function} func async function to call.  This function must return a promise
 * @returns {Promise}
 */
export const throttleApi = (func, onCallThrottled) => {
  let previousCall = Promise.resolve();
  let callIsThrottled = false;
  return (...args) => {
    const callNext = () => {
      callIsThrottled = false;
      return func(...args);
    };
    if (callIsThrottled) {
      onCallThrottled();
    }
    previousCall = previousCall.then(callNext, callNext);
    callIsThrottled = true;
    return previousCall;
  };
};