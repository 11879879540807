import http from 'conversations-http/clients/http';
import { registerQuery } from 'data-fetching-client';
const OUTSIDE_BUSINESS_HOURS_FIELD_NAME = 'isInboxOutsideBusinessHours';
export const GET_INBOX_OUTSIDE_BUSINESS_HOURS = registerQuery({
  fieldName: OUTSIDE_BUSINESS_HOURS_FIELD_NAME,
  args: ['inboxId'],
  fetcher({
    inboxId
  }) {
    return http.get(`/livechat/v1/officeHours/inbox/${inboxId}`);
  }
});
const RETURN_TIME_FIELD_NAME = 'inboxBusinessHoursReturnTime';
export const GET_INBOX_BUSINESS_HOURS_RETURN_TIME = registerQuery({
  fieldName: RETURN_TIME_FIELD_NAME,
  args: ['inboxId'],
  fetcher({
    inboxId
  }) {
    return http.get(`/livechat/v1/officeHours/inbox/${inboxId}/return-time`);
  }
});