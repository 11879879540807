import { SUCCESS } from './constants/asyncDataPointResults';
function getBin(value, edges) {
  const found = edges.find((edge, j) => value > edge && value < edges[j + 1]);
  const index = found !== undefined ? edges.indexOf(found) : -1;
  if (index >= 0) {
    return `${edges[index]}-${edges[index + 1] - 1}`;
  }
  return `${edges[edges.length - 1]}+`;
}
export class BucketAggregator {
  constructor(name, edges = [0, 250, 500, 1000, 2500, 5000]) {
    this.name = name;
    this.edges = edges;
    this.metric = {
      min: Infinity,
      max: 0,
      success: 0,
      failure: 0
    };
  }

  /**
   * Return data prefixed with the metric name.
   *
   * Eg: 'min' becomes `${name}-min`
   */
  getAggregateData() {
    if (this.metric.success === 0 && this.metric.failure === 0) return {};
    return Object.keys(this.metric).reduce((acc, key) => {
      acc[`${this.name}-${key}`] = this.metric[key];
      return acc;
    }, {});
  }
  addDataPoint(dataPoint) {
    const {
      metric
    } = this;
    const {
      result,
      duration
    } = dataPoint;
    if (result === SUCCESS) {
      metric.success++;
      const bin = getBin(duration, this.edges);
      metric[bin] = (metric[bin] || 0) + 1;
      metric.min = duration < metric.min ? duration : metric.min;
      metric.max = duration > metric.max ? duration : metric.max;
    } else {
      metric.failure++;
    }
  }
}