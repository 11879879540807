export const ObjectConversionError = Object.freeze({
  STILL_LOADING: 'STILL_LOADING',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  UNSUPPORTED_OBJECT_TYPE: 'UNSUPPORTED_OBJECT_TYPE',
  NO_OBJECT_TYPE: 'NO_OBJECT_TYPE',
  NO_OBJECT_ID: 'NO_OBJECT_ID',
  OBJECT_NOT_FOUND: 'OBJECT_NOT_FOUND',
  NO_CREATE_ACCESS: 'NO_CREATE_ACCESS',
  /** @deprecated no longer used, deals only have a currency code set in multicurrency portals
   * so this is not necessarily valid */
  NO_CURRENCY: 'NO_CURRENCY',
  /** @deprecated no longer used, deals only have a currency code set in multicurrency portals
   * so this is not necessarily valid */
  UNSUPPORTED_CURRENCY: 'UNSUPPORTED_CURRENCY'
});
export const LineItemConversionError = Object.freeze({
  MULTIPLE_CURRENCIES: 'MULTIPLE_CURRENCIES',
  LARGE_QUANTITY: 'LARGE_QUANTITY',
  PAST_START_DATE: 'PAST_START_DATE',
  RELATIVE_START_DATE: 'RELATIVE_START_DATE',
  FUTURE_ONE_TIME_LINE_ITEM: 'FUTURE_ONE_TIME_LINE_ITEM',
  MULTI_YEAR_BILLING_FREQUENCY: 'MULTI_YEAR_BILLING_FREQUENCY',
  FRACTIONAL_QUANTITY: 'FRACTIONAL_QUANTITY',
  UNSUPPORTED_CURRENCY: 'UNSUPPORTED_CURRENCY'
});
export const BillingConversionError = Object.freeze(Object.assign({}, ObjectConversionError, LineItemConversionError));