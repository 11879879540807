'use es6';

import { Map as ImmutableMap } from 'immutable';
import memoize from 'transmute/memoize';
import { stringify } from 'hub-http/helpers/params';

// If these values change. Please duplicate the changes in crm-records-ui/static/js/quickFetchRecord.js
const getBodyParams = memoize((options = ImmutableMap()) => ImmutableMap({
  flpViewValidation: [true],
  formSubmissionMode: ['none'],
  identityProfileMode: ['all'],
  propertyMode: ['value_only'],
  resolveAssociations: [false],
  resolveOwner: [false],
  showAnalyticsDetails: [false],
  showListMemberships: [options.get('showListMemberships')],
  showPastListMemberships: [false],
  showPublicToken: [false],
  showSourceMetadata: [false]
}));
const getQueryParams = memoize(options => {
  return getBodyParams(options).map(value => value[0]);
});
const getQueryParamStr = memoize(options => {
  return stringify(getQueryParams(options).toJS());
});
export { getBodyParams, getQueryParams, getQueryParamStr };