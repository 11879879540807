import { useCallback, useEffect, useState } from 'react';
import { fetchLatestPreview } from '../api/ChatSpotV1HighVolumeApi';
const PLAIN_TEXT = 'PLAIN_TEXT';
const toResponse = result => {
  if (result.error) {
    return {
      message: result.message,
      messageId: result.id,
      sections: [{
        id: `${result.id}`,
        text: result.response,
        type: PLAIN_TEXT
      }],
      error: result.error,
      timestamp: result.created_at
    };
  } else {
    return {
      message: result.message,
      sections: result.sections,
      messageId: result.id,
      threadId: result.thread_id,
      timestamp: result.created_at
    };
  }
};
export const useLatestPreview = ({
  previewSource,
  previewKey
}) => {
  const [latestPreviewLoading, setLatestPreviewLoading] = useState(false);
  const [latestPreview, setLatestPreview] = useState();
  const [latestPreviewError, setLatestPreviewError] = useState();
  useEffect(() => {
    if (previewKey) {
      setLatestPreviewError(null);
      setLatestPreviewLoading(true);
      fetchLatestPreview({
        previewSource,
        previewKey
      }).then(response => {
        const latestPreviewData = response && response.messages && response.messages.results[0] && toResponse(response.messages.results[0]);
        setLatestPreview(latestPreviewData);
        setLatestPreviewLoading(false);
      }).catch(err => {
        setLatestPreviewError(err);
        setLatestPreviewLoading(false);
      });
    }
  }, [previewKey, previewSource]);
  const handleReset = useCallback(() => {
    setLatestPreview(undefined);
  }, []);
  return {
    onResetPreview: handleReset,
    latestPreviewLoading,
    latestPreviewError,
    latestPreview
  };
};