import { List } from 'immutable';
import map from 'transmute/map';
// @ts-expect-error module not typed
import EmailAddressRecord from 'customer-data-email/schema/email/EmailAddressRecord';
// @ts-expect-error module not typed
import EmailReplyMetadata from 'conversations-thread-data/common-reply-metadata/public/records/EmailReplyMetadata';
const buildAddressesList = addressesArray => {
  return addressesArray ? List(map(address => new EmailAddressRecord(address), addressesArray)) : List();
};
const buildFrom = fromObj => fromObj && new EmailAddressRecord(fromObj) || null;
export const buildEmailMetadataFromJS = jsMetadataObj => {
  const {
    bcc,
    cc,
    from,
    inReplyToMessageId,
    subject,
    to,
    templateId
  } = jsMetadataObj;
  return EmailReplyMetadata({
    bcc: buildAddressesList(bcc),
    cc: buildAddressesList(cc),
    from: buildFrom(from),
    inReplyToMessageId,
    subject,
    to: buildAddressesList(to),
    templateId
  });
};