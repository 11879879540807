import allSettled from 'hs-promise-utils/allSettled';
import promiseDone from 'hs-promise-utils/promiseDone';
import { useEffect, useRef, useState } from 'react';
import { toBatchObjectsNotFoundError } from 'reference-resolvers-lite/Errors';
import { STATUS_ERROR, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS, isIdle } from 'reference-resolvers-lite/Status';
export const STATUS_RELOADING = 'reloading';
export const isReloading = status => status === STATUS_RELOADING;
function isFulfilled(input) {
  return input.status === 'fulfilled';
}

// this hook is forked from useFetchApiResolver from reference-resolvers-lite
// https://git.hubteam.com/HubSpot/reference-resolvers-lite/blob/master/reference-resolvers-lite/static-1.3685/js/adapters/ApiHooks.ts#L32
// difference 1 - we do not trigger a fetch if the new ids are a subset of the old ids
// difference 2 - the hook switches to STATUS_RELOADING during refetch
// #framework-builder is planning to introduce this capability in the future - https://git.hubteam.com/HubSpot/reference-resolvers-lite/issues/306

const useFetchBatchResolverWithReload = (resolver, ids) => {
  const {
    api: {
      byId
    }
  } = resolver;
  const previousIdsRef = useRef();
  const isBatch = Array.isArray(ids);
  const initialStatus = ids ? STATUS_LOADING : STATUS_IDLE;
  const [results, setResults] = useState({
    error: undefined,
    references: undefined,
    status: initialStatus
  });
  if (ids && !isReloading(results.status) && isIdle(results.status)) {
    setResults({
      error: undefined,
      references: undefined,
      status: STATUS_LOADING
    });
  }
  useEffect(() => {
    if (!ids) {
      setResults({
        error: undefined,
        references: undefined,
        status: STATUS_IDLE
      });
      previousIdsRef.current = undefined;
      return;
    }

    // Prevent the same ids from being fetched constantly. If there is no new id, there is no need to refetch.
    // We intentionally won't trigger a refresh if the new ids are a subset of the old ids.
    // We cannot use toString here because file ids could have been reordered. We don't want to trigger a refetch on value reorders.
    if (previousIdsRef.current && ids.every(id => previousIdsRef.current.includes(id))) {
      previousIdsRef.current = ids;
      return;
    }
    previousIdsRef.current = ids;
    if (ids.length === 0) {
      setResults({
        error: undefined,
        references: [],
        status: STATUS_SUCCESS
      });
      return;
    }
    if (results.status === STATUS_SUCCESS) {
      // switch to reloading state during refetch and not reset the references
      // allows display of existing values while refetching
      // FileValues uses this reloading status to show skeleton loaders
      setResults({
        error: undefined,
        references: results.references,
        status: STATUS_RELOADING
      });
    }
    const setData = references => setResults({
      error: undefined,
      references,
      status: STATUS_SUCCESS
    });
    const setError = error => setResults({
      error,
      references: undefined,
      status: STATUS_ERROR
    });
    promiseDone(allSettled(ids.map(id => byId(id))).then(values => {
      const fulfilledPromises = values.filter(isFulfilled).map(({
        value
      }) => value);
      if (fulfilledPromises.length === 0) {
        setError(toBatchObjectsNotFoundError(ids));
      } else {
        setData(fulfilledPromises);
      }
    }));
  }, [isBatch, ids, byId, results.references, results.status]);
  return results;
};
export default useFetchBatchResolverWithReload;