import { getData } from 'conversations-async-data/async-data/operators/getters';
// TODO: move to composer-data

export const channelInstancesMissing = ({
  asyncChannelInstances
}) => {
  const channelInstances = getData(asyncChannelInstances);
  return !channelInstances || channelInstances.size === 0 ? {
    disabled: true,
    sendDisabledReason: 'MISSING_INSTANCES'
  } : null;
};