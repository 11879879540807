import { createSelector } from 'reselect';
import map from 'transmute/map';
import { getFocusedViewMember } from '../../focused-view-member/selectors/getFocusedViewMember';
import { getFocusedViewMemberThreadId } from '../../focused-view-member/selectors/getFocusedViewMemberThreadId';
import { buildOverlayFromThreadListMember } from '../../thread-list-member-overlay/operators/buildOverlayFromThreadListMember';
import FilterMisalignmentOverlay from '../../thread-list-member-overlay/records/FilterMisalignmentOverlay';
import { selectSortedViewMembers, selectOrderByTypeFromProps } from './selectSortedViewMembers';
import { filterViewMembers } from 'find-and-filter-data/search-and-filter-data/protected';
import { getViewThreadStatusAdjustedFilters } from '../../thread-list/selectors/getViewThreadStatus';
const getIsInitialPageReceivedFromProps = (_state, {
  isInitialPageReceived
}) => isInitialPageReceived;

/**
 * @deprecated
 * This selector contains a lot of business logic - this logic should be seperated
 * to something better suited for it like a hook or function. This selector containing
 * so much business logic makes it hard to move.
 */
export const getThreadListMembersWithOverlays = createSelector([selectSortedViewMembers,
/**
 * Required for selectSortedViewMembers
 */
selectOrderByTypeFromProps, getViewThreadStatusAdjustedFilters, getFocusedViewMember, getFocusedViewMemberThreadId, getIsInitialPageReceivedFromProps], (members, ___viewOrderByUnusedInThisFile, viewAdjustedFilters, focusedMember, focusedMemberThreadId, initialPageReceived) => {
  const filteredThreadListMembers = filterViewMembers(members, viewAdjustedFilters, focusedMemberThreadId);
  const membersWithOverlays = map(member => {
    const overlay = buildOverlayFromThreadListMember(member, viewAdjustedFilters);
    return overlay || member;
  }, filteredThreadListMembers);

  /**
   * If there is not TLM but there is a focused threadId and TLM page has been
   * received, we know it's a deep linked thread missing the TLM.
   */
  if (!focusedMember && focusedMemberThreadId && initialPageReceived) {
    // @ts-expect-error membersWithOverlays is Immutable.Iterable instead of Immutable.List
    return membersWithOverlays.unshift(FilterMisalignmentOverlay({
      deepLinkedThreadMissing: true
    }));
  } else {
    return membersWithOverlays;
  }
});