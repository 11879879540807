import { handleActions } from 'redux-actions';
import LoadingState from '../records/LoadingState';
import { LOAD_ESCALATION, LOAD_ESCALATION_META } from '../actions/ActionTypes';
export default handleActions({
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'Action<Loa... Remove this comment to see the full error message
  [LOAD_ESCALATION]: (state, {
    meta
  }) => state.set('isLoadingEscalation', meta === 'request'),
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'Action<Loa... Remove this comment to see the full error message
  [LOAD_ESCALATION_META]: (state, {
    meta
  }) => state.set('isLoadingEscalationMeta', meta === 'request')
}, new LoadingState());