import http from 'hub-http/clients/apiClient';
export const fetchChannelQuota = ({
  genericChannelId,
  sender,
  recipient
}) => {
  return http.put('/conversations-channels/v1/quota', {
    data: {
      genericChannelId,
      senderDeliveryIdentifier: sender,
      recipientDeliveryIdentifier: recipient
    }
  });
};