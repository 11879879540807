import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
// @ts-expect-error JS File
import { setupCsat } from 'wootric-nps/csat/csat-setup';
const SURVEY_KEY = 'INBOX';
export const triggerWootricsSurvey = () => {
  try {
    setupCsat(SURVEY_KEY);
  } catch (e) {
    const error = e instanceof Error ? e : new Error(`${e}`);
    reportError({
      error,
      fingerprint: ['{{ default }}', 'TRIGGER_WOOTRICS_SURVEY'],
      tags: {
        isVisibleToUser: false
      }
    });
  }
};