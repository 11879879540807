import { useEditorCapability, useEditorConfigurationKey } from 'composer-data/channel-capabilities/public/hooks';
import { EMAIL_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
import { BLOCKQUOTE, BOLD, CLEAR_FORMATTING, FONT_SIZE, FONT_STYLE, GLOBAL_VARS, ITALIC, LINK, LISTS, TEXT_ALIGN, TEXT_COLOR, TEXT_HIGHLIGHT, UNDERLINE } from 'hubspot-prosemirror/prosemirror/editorCapabilities';
import { useMemo } from 'react';
import { useFontSettings } from './useFontSettings';
function addProsemirrorConfigFromCapability(editorConfig, channelCapability) {
  switch (channelCapability) {
    case 'BOLD':
      editorConfig[BOLD] = {};
      break;
    case 'ITALIC':
      editorConfig[ITALIC] = {};
      break;
    case 'UNDERLINE':
      editorConfig[UNDERLINE] = {};
      break;
    case 'LISTS':
      editorConfig[LISTS] = {};
      break;
    case 'BLOCKQUOTE':
      editorConfig[BLOCKQUOTE] = {};
      break;
    case 'TEXT_COLOR':
      editorConfig[TEXT_COLOR] = {};
      break;
    case 'FONT_STYLE':
      editorConfig[FONT_STYLE] = {};
      break;
    case 'FONT_SIZE':
      editorConfig[FONT_SIZE] = {};
      break;
    case 'TEXT_ALIGNMENT':
      editorConfig[TEXT_ALIGN] = {};
      break;
    case 'TEXT_HIGHLIGHT_COLOR':
      editorConfig[TEXT_HIGHLIGHT] = {};
      break;
    default:
      break;
  }
}
function useDefaultFonts() {
  const editorConfigKey = useEditorConfigurationKey();
  const allowDefaultFonts = editorConfigKey === EMAIL_GENERIC_CHANNEL_ID;
  const {
    fontSettings,
    loading: fontSettingsLoading
  } = useFontSettings({
    deferred: !allowDefaultFonts
  });
  const defaultFonts = useMemo(() => {
    // TODO: should default fonts apply to all changes where fontSize/fontStyle/fontColor are capabilities?
    if (!allowDefaultFonts) {
      return {};
    }
    return fontSettings ? {
      defaultFontSize: fontSettings.fontSize,
      defaultFontStyle: fontSettings.fontStyle,
      defaultTextColor: fontSettings.fontColor
    } : {
      defaultFontStyle: 'sans-serif'
    };
  }, [allowDefaultFonts, fontSettings]);
  return {
    defaultFonts,
    loading: fontSettingsLoading
  };
}
export function useRichTextConfig() {
  const richText = useEditorCapability('richText');
  const {
    defaultFonts,
    loading: defaultFontsLoading
  } = useDefaultFonts();
  const richTextConfig = useMemo(() => {
    const config = {};
    richText.forEach(channelCapability => {
      addProsemirrorConfigFromCapability(config, channelCapability);
    });
    if (richText.length) {
      config[CLEAR_FORMATTING] = {};
    }

    // https://hubspot.slack.com/archives/C01NX2YJXG9/p1708707107512299
    const allowsAllRichTextFeatures = richText.includes('HYPERLINK') && richText.includes('LISTS') && richText.includes('FONT_SIZE') && richText.includes('FONT_STYLE') && richText.includes('TEXT_COLOR') && richText.includes('TEXT_ALIGNMENT') && richText.includes('TEXT_HIGHLIGHT_COLOR');
    config[GLOBAL_VARS] = Object.assign({
      useInsertMenu: true,
      useInboxLayout: true,
      usePlaintextMode: !allowsAllRichTextFeatures
    }, defaultFonts);

    // always allow links, the HYPERLINK capability will control usePlaintextMode, for inserting plain text v. anchor tag links
    config[LINK] = {};
    return config;
  }, [richText, defaultFonts]);
  return {
    richTextConfig,
    loading: defaultFontsLoading
  };
}