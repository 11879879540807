'use es6';

import reduce from 'transmute/reduce';
import { Set as ImmutableSet } from 'immutable';
import { getRecipientId } from 'conversations-message-history/legacy-recipients/operators/recipientLocatorGetters';
export const getValidRecipientIdsFromRecipientLocators = recipientLocators => {
  return reduce(ImmutableSet(), (accumulator, recipientLocator) => {
    const recipientId = getRecipientId(recipientLocator);
    // Avoiding recipientId which are not valid numbers. Specifically, for avoiding utk(s) of live chats.
    if (!recipientId || isNaN(recipientId)) {
      return accumulator;
    }
    return accumulator.add(recipientId);
  }, recipientLocators || ImmutableSet());
};