'use es6';

import { useMemo } from 'react';
import { AGENT, BOT, VISITOR } from 'conversations-message-history/common-message-format/constants/legacySenderTypes';
import { getRecipientType } from 'conversations-message-history/legacy-recipients/operators/recipientLocatorGetters';
import { useIndexedAsyncAgentBotRecipients } from './useIndexedAsyncAgentBotRecipients';
import { useIndexedAsyncVisitorRecipients } from './useIndexedAsyncVisitorRecipients';
export const useLegacyRecipients = ({
  recipientLocators
}) => {
  const visitorRecipientLocators = useMemo(() => recipientLocators.filter(recipientLocator => getRecipientType(recipientLocator) === VISITOR), [recipientLocators]);
  const agentAndBotRecipientLocators = useMemo(() => recipientLocators.filter(recipientLocator => getRecipientType(recipientLocator) === AGENT || getRecipientType(recipientLocator) === BOT), [recipientLocators]);
  const {
    visitorRecipients
  } = useIndexedAsyncVisitorRecipients({
    recipientLocators: visitorRecipientLocators,
    vid: null
  });
  const {
    agentBotRecipients
  } = useIndexedAsyncAgentBotRecipients({
    recipientLocators: agentAndBotRecipientLocators
  });
  const recipients = useMemo(() => {
    return agentBotRecipients.merge(visitorRecipients);
  }, [agentBotRecipients, visitorRecipients]);
  return {
    recipients
  };
};