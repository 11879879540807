import PortalIdParser from 'PortalIdParser';
import { registerQuery, useQuery } from 'data-fetching-client';
import userInfo from 'hub-http/userInfo';
export const GET_AUTH = registerQuery({
  fieldName: 'auth',
  fetcher: () => {
    PortalIdParser.get();
    return userInfo();
  }
});
export function useAuth() {
  const {
    loading,
    error,
    data
  } = useQuery(GET_AUTH);
  return {
    isLoadingUserInfo: loading,
    fetchUserInfoError: error,
    userInfo: data && data.auth.user
  };
}