'use es6';

import getIn from 'transmute/getIn';
import { FORM_ID, FORM_NAME, ID, OBJECT_ID, OBJECT_NAME, OBJECT_TYPE, SOURCE, SOURCE_ID, TIMESTAMP, UPDATE } from '../constants/keyPaths';
export const getId = getIn(ID);
export const getObjectType = getIn(OBJECT_TYPE);
export const getObjectId = getIn(OBJECT_ID);
export const getObjectName = getIn(OBJECT_NAME);
export const getUpdate = getIn(UPDATE);
export const getTimestamp = getIn(TIMESTAMP);
export const getSource = getIn(SOURCE);
export const getSourceId = getIn(SOURCE_ID);
export const getFormName = getIn(FORM_NAME);
export const getFormId = getIn(FORM_ID);