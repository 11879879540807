import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { fetchAgentCapacity as requestAgentCapacity, fetchPortalCapacityLimit as requestPortalCapacityLimit } from '../clients/CapacityLimitsClient';
import { FETCH_AGENT_CAPACITY_TYPES, FETCH_PORTAL_CAPACITY_LIMITS_TYPES, UPDATE_CAPACITY_LIMIT } from '../constants/capacityLimitsActionTypes';
export const fetchPortalCapacityLimit = createAsyncAction({
  actionTypes: FETCH_PORTAL_CAPACITY_LIMITS_TYPES,
  requestFn: requestPortalCapacityLimit,
  toRecordFn: resp => resp
});
export const fetchAgentCapacity = createAsyncAction({
  actionTypes: FETCH_AGENT_CAPACITY_TYPES,
  requestFn: requestAgentCapacity,
  toRecordFn: resp => resp
});
export const updateAgentCapacity = update => ({
  payload: update,
  type: UPDATE_CAPACITY_LIMIT
});