'use es6';

export const FIRST_NAME = ['firstName'];
export const LAST_NAME = ['lastName'];
export const EMAIL = ['email'];
export const ID = ['id'];
export const IS_FREE_USER = ['isFreeUser'];
export const IS_ADMIN_USER = ['isAdmin'];
export const SCOPES = ['scopes'];
export const ROUTABLE = ['routable'];
export const USER_IN_PORTAL = ['userInPortal'];
export const CAN_VIEW_INBOX = ['canViewInbox'];