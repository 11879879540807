'use es6';

import { useMemo } from 'react';
import { useVisitors } from '../../visitor/hooks/useVisitors';
import { getValidSenderIdsFromSenderLocators } from '../operators/getValidSenderIdsFromSenderLocators';
import { buildSendersFromVisitorsResponse } from '../operators/buildSendersFromVisitorsResponse';
export const useIndexedAsyncVisitorSenders = ({
  senderLocators,
  vid
}) => {
  // get exhaustive list of all vids present in thread
  const vids = useMemo(() => {
    const senderIds = getValidSenderIdsFromSenderLocators(senderLocators);
    return vid && !isNaN(vid) ? senderIds.add(vid) : senderIds;
  }, [senderLocators, vid]);
  const {
    asyncVisitors
  } = useVisitors({
    vids
  });
  const visitorSenders = useMemo(() => {
    return buildSendersFromVisitorsResponse(asyncVisitors);
  }, [asyncVisitors]);
  return {
    visitorSenders
  };
};