import fromJS from 'transmute/fromJS';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { SEND_OFFLINE_NOTICE as actionTypes } from '../constants/asyncActionTypes';
import { sendOfflineNotice as requestFn } from '../clients/sendOfflineNotice';
import { silenceErrorAlert } from 'conversations-error-reporting/error-actions/builders/silenceErrorAlert';
export const sendOfflineNotice = createAsyncAction({
  requestFn,
  actionTypes,
  toRecordFn: fromJS,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type '{}'.
  failureMetaActionCreator: ({
    error
  }) => silenceErrorAlert({
    error
  })
});