import identity from 'transmute/identity';
import { UNINITIALIZED } from '../constants/asyncStatuses';
import { requestStateUpdate } from './requestStateUpdate';

/**
 * Set status when a request starts
 *
 * @param {AsyncData} asyncData AsyncData record to update
 * @returns {AsyncData}
 */
export const requestReset = requestStateUpdate(UNINITIALIZED, identity);