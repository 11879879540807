import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getId, isProcessing, isCompleted } from 'find-and-filter-data/views-schema/public';
import { useCurrentView } from '../../custom-views/hooks/useCurrentView';
import { useInboxViewMembers } from 'find-and-filter-data/view-members-data/public';
import { getThreadListMemberFilters } from '../../thread-list-member/selectors/getThreadListMemberFilters';
/**
 * @description This hook manages the TLM fetching for the ThreadListMembersSidebar
 * component. It ensures that TLMs are maintained and will fetch for them whenever
 * they are cleared by changing views or editing a custom view.
 */
export function useHydrateThreadListMembers() {
  const currentView = useCurrentView();
  const {
    inboxId,
    sortDirection,
    threadStatus,
    viewId
  } = useSelector(getThreadListMemberFilters);
  const {
    error,
    fetchMore,
    hasMore,
    isInitialPageReceived,
    loading,
    refetch,
    viewMembers
  } = useInboxViewMembers({
    inboxId,
    skip: isProcessing(currentView),
    sortDirection,
    threadStatus: threadStatus,
    viewId: viewId || undefined
  });
  const haveMembersBeenFetched = !error && !loading && viewMembers;
  const previousView = useRef(currentView);
  useEffect(() => {
    const isViewSelected = !!currentView;
    const previousViewId = previousView.current && getId(previousView.current);
    const currentViewId = currentView && getId(currentView);
    const hasViewChanged = previousViewId !== currentViewId;
    if (isViewSelected && haveMembersBeenFetched) {
      if (hasViewChanged) {
        /**
         * Changing views is handled with a simple refetch
         */
        refetch();
      } else {
        /**
         * Note that we only check this if view has not changed
         */
        const wasProcessingAndIsNowComplete = isProcessing(previousView.current) && isCompleted(currentView);
        if (wasProcessingAndIsNowComplete) {
          refetch();
        }
      }
    }
  }, [currentView, refetch, haveMembersBeenFetched, inboxId]);
  useEffect(() => {
    /**
     * We update previousView after the above effect to ensure that previousView
     * isn't updated before we check if it was previously processing
     */
    previousView.current = currentView;
  }, [currentView]);
  return {
    error,
    fetchMore,
    hasMore,
    isInitialPageReceived,
    loading,
    refetch,
    viewMembers
  };
}