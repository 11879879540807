/**
 * A hook to determine if a user has a particular scope
 *
 * @example
 *
 * const DealMergeButton = (props) => {
 *   const canEditTransactions = useHasScope('payments-transactions-write');
 *
 *   if (!canEditTransactions) {
 *     return null
 *   }
 *
 *   return (
 *    ...
 *   );
 * }
 *
 * @param {String}  scopeName          the name of the scope to check for
 */

import { useContext, useMemo } from 'react';
import { PermissionsContext } from '../context/PermissionsContext';
export function useHasScope(scopeName) {
  const {
    scopes
  } = useContext(PermissionsContext);
  return useMemo(() => scopes.includes(scopeName), [scopes, scopeName]);
}