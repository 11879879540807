import { getChildTeams, getId } from '../../team/operators/getters';
export const buildTeamsSet = hierarchy => {
  let ids = new Set();
  hierarchy.forEach(team => {
    const childTeams = getChildTeams(team);
    ids.add(getId(team));
    if (childTeams) {
      ids = new Set([...ids, ...buildTeamsSet(childTeams)]);
    }
  });
  return ids;
};