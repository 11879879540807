import Track from '../utils/ZorseGrrTaggingTracker';
import { CHECK_CLOSE_TICKET } from './ActionTypes';
const checkedCloseTicket = (ticketId, isChecked) => ({
  type: CHECK_CLOSE_TICKET,
  payload: {
    ticketId,
    isChecked
  }
});
export function checkCloseTicket(ticketId, isChecked) {
  return dispatch => {
    dispatch(checkedCloseTicket(ticketId, isChecked));
    if (isChecked) {
      Track.checkCloseTicket();
    } else {
      Track.uncheckCloseTicket();
    }
  };
}