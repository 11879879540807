import { useMemo } from 'react';
import { FETCH_ASSOCIATED_OBJECTS_CARD_OBJECT } from '../queries';
import { useQuery } from '@apollo/client';
import Raven from 'raven-js';
export const useFetchAssociatedObjectsCardObject = ({
  objectId,
  objectType,
  toObjectTypeId
}) => {
  const {
    data,
    loading,
    error,
    refetch
  } = useQuery(FETCH_ASSOCIATED_OBJECTS_CARD_OBJECT, {
    variables: {
      objectId,
      objectType,
      toObjectTypeId
    }
  });
  if (error) {
    Raven.captureException(error, {
      extra: {
        objectId,
        objectType,
        toObjectTypeId
      }
    });
  }
  const userPermissions = useMemo(() => {
    if (data && data.crmObject) {
      return data.crmObject.userPermissions;
    }
    return {
      currentUserCanEdit: false,
      __typename: 'ObjectPermissions'
    };
  }, [data]);
  const objectTypePermissions = useMemo(() => {
    if (data && data.objectTypePermissions) {
      return data.objectTypePermissions;
    }
    return {
      currentUserCanCreate: false,
      __typename: 'ObjectTypePermissions'
    };
  }, [data]);
  return {
    error,
    loading,
    objectTypePermissions,
    primaryDisplayLabel: data && data.crmObject ? data.crmObject.primaryDisplayLabel : null,
    refetch,
    userPermissions
  };
};