import I18n from 'I18n';
import { callDispositionsIdMap } from '../constants/callDispositionsIdMap';
export const getDefaultOptions = () => {
  return [{
    text: I18n.text('conversations-calling-message.timeline-call-metadata.call-dispositions.busy'),
    value: callDispositionsIdMap.BUSY
  }, {
    text: I18n.text('conversations-calling-message.timeline-call-metadata.call-dispositions.connected'),
    value: callDispositionsIdMap.CONNECTED
  }, {
    text: I18n.text('conversations-calling-message.timeline-call-metadata.call-dispositions.left-live-message'),
    value: callDispositionsIdMap.LEFT_LIVE_MESSAGE
  }, {
    text: I18n.text('conversations-calling-message.timeline-call-metadata.call-dispositions.left-voicemail'),
    value: callDispositionsIdMap.LEFT_VOICEMAIL
  }, {
    text: I18n.text('conversations-calling-message.timeline-call-metadata.call-dispositions.no-answer'),
    value: callDispositionsIdMap.NO_ANSWER
  }, {
    text: I18n.text('conversations-calling-message.timeline-call-metadata.call-dispositions.wrong-number'),
    value: callDispositionsIdMap.WRONG_NUMBER
  }];
};