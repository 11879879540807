import I18n from 'I18n';
import { restoreThread as restoreThreadClient } from '../clients/restoreThread';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { RESTORE_THREAD } from '../constants/asyncActionTypes';
export const restoreThread = createAsyncAction({
  requestFn: restoreThreadClient,
  actionTypes: RESTORE_THREAD,
  toRecordFn: () => {},
  successMetaActionCreator: () => ({
    notification: {
      type: 'success',
      titleText: I18n.text('conversations-thread-view.alerts.titles.success'),
      message: I18n.text('conversations-thread-view.alerts.restore-thread.message')
    }
  })
});