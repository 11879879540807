import PortalIdParser from 'PortalIdParser';
import { getFullUrl } from 'hubspot-url-utils';
import { stringify } from 'hub-http/helpers/params';
const getOrigin = () => getFullUrl('app');
const decorateWithParams = (url, params) => `${url}${params ? `?${stringify(params)}` : ''}`;

// inbox urls
export function getRedirectToDefaultInboxUrl() {
  return `${getOrigin()}/live-messages-settings/${PortalIdParser.get()}`;
}
export function getInboxChannelsTableUrl({
  inboxId
}) {
  return `${getOrigin()}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/channels`;
}
export function getAvailabilityManagementUrl() {
  return `${getOrigin()}/settings/${PortalIdParser.get()}/availability-management`;
}
export function getLiveChatDisplayUrl({
  inboxId
}) {
  return `${getOrigin()}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/edit/live-chat/primary`;
}
export function getLiveChatTrackingCodeUrl({
  inboxId
}) {
  return `${getOrigin()}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/edit/live-chat/primary/tracking`;
}
export function getLiveChatAvailabilityUrl({
  inboxId
}) {
  return `${getOrigin()}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/edit/live-chat/primary/availability`;
}
export function getEmailEditConfigurationUrl({
  inboxId,
  emailId
}) {
  return `${getOrigin()}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/edit/email/${emailId}/configuration`;
}
export function getEmailEditAutomationUrl({
  inboxId,
  emailId
}) {
  return `${getOrigin()}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/edit/email/${emailId}/automation`;
}
export function getEditFormRulesUrl({
  inboxId,
  formId
}) {
  return `${getOrigin()}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/edit/forms/${formId}`;
}
export function getFormsCreateUrl({
  inboxId,
  params
}) {
  return decorateWithParams(`${getOrigin()}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/forms/create`, params);
}
export function getWhatsAppCreateUrl({
  inboxId,
  params
}) {
  return decorateWithParams(`${getOrigin()}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/whats-app/create`, params);
}
export function getCallingCreateUrl({
  inboxId,
  params
}) {
  return decorateWithParams(`${getOrigin()}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/calling/create`, params);
}
export function getEmailCreateUrl({
  inboxId,
  params
}) {
  return decorateWithParams(`${getOrigin()}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/email/create`, params);
}
export function getChatChannelCreateUrl({
  inboxId,
  params
}) {
  return decorateWithParams(`${getOrigin()}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/live-chat/create`, params);
}
export function getFacebookMessengerChannelCreateUrl({
  inboxId,
  params
}) {
  return decorateWithParams(`${getOrigin()}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/facebook-messenger/create`, params);
}
export function getCustomChannelCreateUrl({
  inboxId,
  channelId,
  params
}) {
  return decorateWithParams(`${getOrigin()}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/generic/${channelId}/create`, params);
}
export function getCurrentInboxAccessUrl({
  inboxId,
  params
}) {
  return decorateWithParams(`${getOrigin()}/live-messages-settings/${PortalIdParser.get()}/inboxes/${inboxId}/access`, params);
}

// Help desk settings urls
export function getManageHelpdeskUrl() {
  return `${getOrigin()}/helpdesk-settings/${PortalIdParser.get()}/inboxes/access`;
}
export function getHelpdeskSettingsUrl() {
  return `${getOrigin()}/helpdesk-settings/${PortalIdParser.get()}`;
}
export function getEmailCreateUrlForHelpdeskSettings() {
  return `${getOrigin()}/helpdesk-settings/${PortalIdParser.get()}/inboxes/email/create`;
}
export function getChatChannelCreateUrlForHelpdeskSettings() {
  return `${getOrigin()}/helpdesk-settings/${PortalIdParser.get()}/inboxes/live-chat/create`;
}
export function getFormsCreateUrlForHelpdeskSettings() {
  return `${getOrigin()}/helpdesk-settings/${PortalIdParser.get()}/inboxes/forms/create`;
}
export function getFacebookMessengerChannelCreateUrlForHelpdeskSettings() {
  return `${getOrigin()}/helpdesk-settings/${PortalIdParser.get()}/inboxes/facebook-messenger/create`;
}
export function getWhatsAppCreateUrlForHelpdeskSettings() {
  return `${getOrigin()}/helpdesk-settings/${PortalIdParser.get()}/inboxes/whats-app/create`;
}
export function getCallingCreateUrlForHelpdeskSettings() {
  return `${getOrigin()}/helpdesk-settings/${PortalIdParser.get()}/inboxes/calling/create`;
}
export function getCustomChannelCreateUrlForHelpdeskSettings({
  channelId
}) {
  return `${getOrigin()}/helpdesk-settings/${PortalIdParser.get()}/inboxes/generic/${channelId}/create`;
}