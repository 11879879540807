// TODO: move to composer-data
import { getAttachments } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
// TODO: move to composer-data

export const attachmentLimitExceeded = ({
  indexedAsyncFileUploads,
  reply,
  maxFileLimit
}) => {
  const attachments = getAttachments(reply);
  return indexedAsyncFileUploads && attachments && maxFileLimit && indexedAsyncFileUploads.size + attachments.size > maxFileLimit ? {
    disabled: true,
    sendDisabledReason: 'ATTACHMENT_LIMIT_EXCEEDED'
  } : null;
};