import http from 'conversations-http/clients/http';
export const searchContacts = ({
  offset,
  searchQuery
}) => {
  return http.post(`contacts/search/v1/search/contacts`, {
    data: {
      query: searchQuery,
      count: 50,
      doNotForwardRequest: true,
      offset
    }
  });
};