import { createAction } from '@reduxjs/toolkit';
/**
 * @description This action should be dispatched when a ViewUpdate realtime message
 * is received except when validated by the validation endpoint.
 */
export const viewMemberUpdated = createAction('realtime/ViewUpdate', ({
  currentAgentId,
  message,
  sortState
}) => {
  return {
    payload: {
      currentAgentId,
      message,
      sortState
    }
  };
});