import { BOT, HUMAN } from 'conversations-inbox-lib/agent/public/constants';
import { AgentLocator } from 'conversations-inbox-lib/agent/public/records';
import { BOT_HANDOFF, MANUAL } from 'conversations-message-history/audit/constants/auditTypes';
import { getType } from 'conversations-message-history/audit/operators/auditGetters';
import { getBotId } from 'conversations-message-history/audit/operators/botHandoffAuditGetters';
import { getAgentId } from 'conversations-message-history/audit/operators/manualAuditGetters';
export const getAgentLocatorFromAudit = audit => {
  if (!audit) return null;
  switch (getType(audit)) {
    case BOT_HANDOFF:
      return AgentLocator({
        agentId: getBotId(audit),
        agentType: BOT
      });
    case MANUAL:
      return AgentLocator({
        agentId: getAgentId(audit),
        agentType: HUMAN
      });
    default:
      return null;
  }
};