import { useMemo } from 'react';
import { useQuery, registerQuery } from 'data-fetching-client';
import { getCRMObjectByUserId } from '../api/userObject.api';
export const getCrmObjectByUserIdQuery = registerQuery({
  fieldName: 'crmObjectByUserId',
  args: ['userId'],
  fetcher: getCRMObjectByUserId
});
const defaultParser = crmProperty => {
  if (!crmProperty) {
    return undefined;
  }
  return crmProperty.value;
};
export function useUserPropertyValue(userId, propertyName, parser) {
  var _data$crmObjectByUser;
  const {
    data,
    loading,
    refetch
  } = useQuery(getCrmObjectByUserIdQuery, {
    variables: {
      userId
    },
    fetchPolicy: 'cache-and-network'
  });
  const userProperties = data === null || data === void 0 || (_data$crmObjectByUser = data.crmObjectByUserId.results) === null || _data$crmObjectByUser === void 0 || (_data$crmObjectByUser = _data$crmObjectByUser[0]) === null || _data$crmObjectByUser === void 0 ? void 0 : _data$crmObjectByUser.properties;
  const property = userProperties === null || userProperties === void 0 ? void 0 : userProperties[propertyName];
  const value = useMemo(() => parser ? parser(property) : defaultParser(property), [property, parser]);
  return {
    value,
    loading,
    refetch
  };
}