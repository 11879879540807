class Aggregator {
  constructor(name, edges) {
    this.name = name;
    this.edges = edges;
    this.metric = {
      min: Infinity,
      max: 0,
      success: 0,
      failure: 0,
      bins: {}
    };
  }
}
export default Aggregator;