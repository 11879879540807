/**
 * omit keys from an object
 *
 * @param {Object} object object to remove keys from
 * @param {Array} toRemove keys to remove
 * @returns {Object} new object with the keys removed
 */
export const omit = (ob, toRemove) => {
  return toRemove.reduce((acc, key) => {
    delete acc[key];
    return acc;
  }, Object.assign({}, ob));
};