'use es6';

import { createSelector } from 'reselect';
import { INTEGRATOR } from 'conversations-message-history/common-message-format/constants/legacySenderTypes';
import { getSenderType } from 'conversations-message-history/senders/operators/senderLocatorGetters';
import { getIndexedAsyncSenders } from './getIndexedAsyncSenders';
export const getIndexedAsyncIntegratorSenders = createSelector([getIndexedAsyncSenders], senders => {
  return senders.updateIn(['entries'], dataEntries => {
    return dataEntries.filter((_, senderLocator) => getSenderType(senderLocator) === INTEGRATOR);
  });
});