export function createInMemoryCache({
  cacheName = 'unknown'
} = {}) {
  const __cache = new Map();
  const cache = {
    has: cacheKey => __cache.has(cacheKey),
    get: cacheKey => {
      const cacheValue = __cache.get(cacheKey);
      if (!cacheValue) {
        return null;
      }
      return cacheValue.value;
    },
    set: (cacheKey, value) => {
      __cache.set(cacheKey, {
        value
      });
      return true;
    },
    delete: cacheKey => __cache.delete(cacheKey),
    clear: () => __cache.clear(),
    printDebug: () => {
      console.log(cacheName, __cache);
    }
  };
  return cache;
}