import { useMemo } from 'react';
import { registerQuery, useQuery } from 'data-fetching-client';
import fetchCallSummaryHistory from '../clients/fetchCallSummaryHistory';
const FETCH_PROPERTY_HISTORY = registerQuery({
  fieldName: 'propertyHistory',
  args: ['objectId'],
  fetcher: fetchCallSummaryHistory
});
export const useCallPropertyHistory = ({
  callId,
  skip
}) => {
  const {
    data,
    error,
    loading
  } = useQuery(FETCH_PROPERTY_HISTORY, {
    variables: {
      objectId: callId
    },
    skip,
    fetchPolicy: 'network-only' // Do not use cache as each time component is rendered, we want to fetch the latest history
  });
  const callSummaryHistory = useMemo(() => {
    if (loading || error || !data) {
      return null;
    }
    return data.propertyHistory && data.propertyHistory.propertiesWithHistory && data.propertyHistory.propertiesWithHistory['hs_call_summary'] && data.propertyHistory.propertiesWithHistory['hs_call_summary'][0] || null;
  }, [data, loading, error]);
  return {
    callSummaryHistory,
    loading,
    error
  };
};