import { getSingleAgentDeliveryIdentifier } from '../operator/getSingleAgentDeliveryIdentifier';
import { getAllVisitorDeliveryIdentifiers } from '../operator/getAllVisitorDeliveryIdentifiers';
import { useThreadDeliveryIdentifierData } from './useThreadDeliveryIdentifierData';
import { useMemo } from 'react';
function formatResponse(genericChannelId, threadDeliveryIdentifierData) {
  const threadDeliveryIdentifierDataInChannel = threadDeliveryIdentifierData === null || threadDeliveryIdentifierData === void 0 ? void 0 : threadDeliveryIdentifierData[genericChannelId];
  return {
    threadAgentDeliveryIdentifierData: threadDeliveryIdentifierDataInChannel && getSingleAgentDeliveryIdentifier(threadDeliveryIdentifierDataInChannel),
    threadVisitorDeliveryIdentifiersData: threadDeliveryIdentifierDataInChannel && getAllVisitorDeliveryIdentifiers(threadDeliveryIdentifierDataInChannel)
  };
}
export const useAgentVisitorDeliveryIdentifiers = ({
  threadId,
  genericChannelId,
  onCompleted
}) => {
  const {
    loading,
    error,
    threadDeliveryIdentifierData
  } = useThreadDeliveryIdentifierData({
    threadId,
    onCompleted: onCompleted && (response => {
      onCompleted(formatResponse(genericChannelId, response === null || response === void 0 ? void 0 : response.threadDeliveryIdentifierData));
    })
  });
  const threadDeliveryIdentifierDataInChannel = threadDeliveryIdentifierData ? threadDeliveryIdentifierData[genericChannelId] : undefined;
  const threadAgentDeliveryIdentifierData = threadDeliveryIdentifierDataInChannel ? getSingleAgentDeliveryIdentifier(threadDeliveryIdentifierDataInChannel) : undefined;
  const threadVisitorDeliveryIdentifiersData = useMemo(() => threadDeliveryIdentifierDataInChannel ? getAllVisitorDeliveryIdentifiers(threadDeliveryIdentifierDataInChannel) : [], [threadDeliveryIdentifierDataInChannel]);
  return {
    loading,
    error,
    threadAgentDeliveryIdentifierData,
    threadVisitorDeliveryIdentifiersData
  };
};