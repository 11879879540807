'use es6';

import { getId, getEmail, getIsFreeUser } from './salesUsersGetters';
import { getIsUserInPortal } from './userGetters';
import getText from './getText';
import { Iterable } from 'immutable';
export const toOption = user => {
  if (Iterable.isIterable(user)) {
    return {
      text: getText(user),
      value: getId(user),
      email: getEmail(user),
      userInPortal: getIsUserInPortal(user),
      isFreeUser: getIsFreeUser(user),
      tagUse: !getIsUserInPortal(user) ? 'error' : 'default'
    };
  }
  return {
    text: getText(user),
    value: user.id,
    email: user.email,
    userInPortal: user.userInPortal,
    isFreeUser: user.isFreeUser,
    tagUse: user.userInPortal ? 'default' : 'error'
  };
};