/* eslint-disable hs-react-native/no-web-globals */

import { AsyncStatus } from 'find-and-filter-data/common/public';
const defaultPrefetchState = {
  prefetchId: 0,
  views: {
    status: AsyncStatus.UNINITIALIZED
  },
  viewMembers: {
    status: AsyncStatus.UNINITIALIZED
  }
};
const windowObj = window;

/**
 * The prefetchState.ts is bundled into BOTH the prefetch and app scripts. When
 * loaded with the app, we want to use the existing state instead of resetting
 * it to the default state.
 */
windowObj.__FIND_AND_FILTER_PREFETCH_STATE__ = windowObj.__FIND_AND_FILTER_PREFETCH_STATE__ || defaultPrefetchState;
export function updateViewsPrefetchState(viewsPrefetchState) {
  windowObj.__FIND_AND_FILTER_PREFETCH_STATE__ = Object.assign({}, windowObj.__FIND_AND_FILTER_PREFETCH_STATE__, {
    views: viewsPrefetchState
  });
}
export function updateViewMembersPrefetchState(viewMembersPrefetchState) {
  windowObj.__FIND_AND_FILTER_PREFETCH_STATE__ = Object.assign({}, windowObj.__FIND_AND_FILTER_PREFETCH_STATE__, {
    viewMembers: viewMembersPrefetchState
  });
}
export function updatePrefetchError(error) {
  windowObj.__FIND_AND_FILTER_PREFETCH_STATE__ = Object.assign({}, windowObj.__FIND_AND_FILTER_PREFETCH_STATE__, {
    error
  });
}
export function resetPrefetchState() {
  windowObj.__FIND_AND_FILTER_PREFETCH_STATE__ = Object.assign({}, defaultPrefetchState, {
    prefetchId: windowObj.__FIND_AND_FILTER_PREFETCH_STATE__.prefetchId + 1
  });
}
export function getPrefetchState() {
  return windowObj.__FIND_AND_FILTER_PREFETCH_STATE__;
}