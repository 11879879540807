import { registerQuery, useQuery } from 'data-fetching-client';
import { logNetworkError } from 'calling-lifecycle-internal/utils/logNetworkError';
import { fetchCallsV1Settings } from 'calling-internal-common/calls-v1-settings/clients/fetchCallsV1Settings';
const FETCH_CALL_SETTINGS = registerQuery({
  fieldName: 'callSettings',
  fetcher: fetchCallsV1Settings
});
export const useFetchCallSettings = () => {
  const {
    data
  } = useQuery(FETCH_CALL_SETTINGS, {
    onError: networkError => logNetworkError({
      error: new Error('Error fetching call settings'),
      networkError
    })
  });
  const callAndMeetingTypesTrackingEnabled = data && data.callSettings && data.callSettings.hubSettings && data.callSettings.hubSettings['engagements:ActivityTypes:Enabled'] && data.callSettings.hubSettings['engagements:ActivityTypes:Enabled'].value && data.callSettings.hubSettings['engagements:ActivityTypes:Enabled'].value === 'true';
  const callOutcomeRequired = data && data.callSettings && data.callSettings.callingAdminSettings && data.callSettings.callingAdminSettings.result && data.callSettings.callingAdminSettings.result.callOutcomeRequired;
  return {
    callAndMeetingTypesTrackingEnabled,
    callOutcomeRequired
  };
};