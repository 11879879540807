import { useEffect, useState, useRef } from 'react';
import { getCurrentWorkingHour } from '../../working-hours/utils/workingHoursUtils';
import { useTrackUsageContext } from '../../usage-tracking/contexts/TrackUsageContext';
import { Triggers, getToggledAwayMode } from '../../agent-status/utils/event-handlers';
export const useToggledByWorkingHours = ({
  isAway,
  userTimezone,
  userWorkingHours
}) => {
  const trackUsage = useTrackUsageContext();
  const previousIsAway = useRef(isAway);
  const [wasToggledByWorkingHours, setWasToggledByWorkingHours] = useState(false);
  const [currentWorkingHour, setCurrentWorkingHour] = useState();
  useEffect(() => {
    // check if user is inside working hours
    if (userWorkingHours && userTimezone) {
      const workingHour = getCurrentWorkingHour(userWorkingHours, userTimezone);
      setCurrentWorkingHour(workingHour);
    }
  }, [userWorkingHours, userTimezone]);
  useEffect(() => {
    // check if availability was toggled
    if (isAway !== previousIsAway.current && userWorkingHours && userTimezone) {
      const workingHour = getCurrentWorkingHour(userWorkingHours, userTimezone);
      if (workingHour !== currentWorkingHour) {
        // availability was toggled by WH status change
        setCurrentWorkingHour(workingHour);
        setWasToggledByWorkingHours(true);
        trackUsage(getToggledAwayMode({
          awayMode: isAway,
          trigger: Triggers.WORKING_HOURS
        }));
      }
    }
    previousIsAway.current = isAway;
  }, [isAway, currentWorkingHour, userWorkingHours, userTimezone, trackUsage]);
  return {
    wasToggledByWorkingHours,
    currentWorkingHour
  };
};