'use es6';

import { getExtension, getType } from 'conversations-internal-schema/file-metadata/operators/fileMetadataGetters';
import { getFileMetadata } from 'conversations-internal-schema/resolved-attachment/operators/resolvedAttachmentGetters';
import { List } from 'immutable';
import { AUDIO, IMG, MOVIE } from '../../common-message-format/constants/fileAttachmentTypes';
const getFileTypeAndExtension = fileAttachment => {
  const fileMetadata = getFileMetadata(fileAttachment);
  return {
    type: getType(fileMetadata),
    extension: getExtension(fileMetadata)
  };
};
const isRenderableAttachment = fileAttachment => {
  const renderableTypes = [IMG, MOVIE, AUDIO];
  const {
    type,
    extension
  } = getFileTypeAndExtension(fileAttachment);
  return Boolean(renderableTypes.indexOf(type) >= 0 && extension !== 'svg');
};
export const getImageAttachments = fileAttachments => {
  if (!fileAttachments) {
    return List();
  }
  return fileAttachments.filter(fileAttachment => {
    const {
      type,
      extension
    } = getFileTypeAndExtension(fileAttachment);
    return Boolean(type === IMG && extension !== 'svg');
  });
};
export const getFilteredAttachments = ({
  fileAttachments,
  canRender = false
}) => {
  if (!fileAttachments) {
    return List();
  }
  return canRender ? fileAttachments.filter(file => isRenderableAttachment(file)) : fileAttachments.filterNot(file => isRenderableAttachment(file));
};
export const getNonImageAttachments = fileAttachments => {
  return fileAttachments.filter(fileAttachment => {
    const {
      type,
      extension
    } = getFileTypeAndExtension(fileAttachment);
    return Boolean(type !== IMG || extension === 'svg');
  });
};
export const hasRenderableAttachments = fileAttachments => fileAttachments.some(file => isRenderableAttachment(file));
export const hasImageAttachments = fileAttachments => {
  return fileAttachments.some(fileAttachment => {
    const {
      type,
      extension
    } = getFileTypeAndExtension(fileAttachment);
    return Boolean(type === IMG && extension !== 'svg');
  });
};