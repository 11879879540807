import http from 'hub-http/clients/apiClient';
import { logNetworkError } from 'calling-error-reporting/report/error';
import { CallReceivingPreferenceType } from '../types/UserSettings';
const getCallReceivingPreferenceApi = () => {
  return http.get('calling-orchestration/v1/settings/user');
};
const setCallReceivingPreferenceApi = callReceivingPreference => {
  return http.patch('calling-orchestration/v1/settings/user', {
    data: {
      callReceivingPreference
    }
  });
};
export const getCallReceivingPreference = () => {
  return getCallReceivingPreferenceApi().catch(networkError => {
    /**
     * TODO: Clean up 404 exception after BE runs backfill job to return default IN_APP setting
     * https://issues.hubspotcentral.com/browse/CALL-2099
     */
    if (networkError && networkError.xhr && networkError.status === 404) {
      return {
        callReceivingPreference: CallReceivingPreferenceType.IN_APP,
        updatedAt: ''
      };
    }
    logNetworkError({
      error: new Error('Failed to get Call Receiving Preference'),
      networkError
    });
    throw networkError;
  });
};
export const setCallReceivingPreference = callReceivingPreference => {
  return setCallReceivingPreferenceApi(callReceivingPreference).catch(networkError => {
    logNetworkError({
      error: new Error('Failed to set Call Receiving Preference'),
      networkError
    });
    throw networkError;
  });
};