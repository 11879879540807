import { handleActions } from 'redux-actions';
import Escalation from '../records/Escalation';
import { LOAD_ESCALATION, LOAD_ESCALATION_META, SAVE_ESCALATION } from '../actions/ActionTypes';
export default handleActions({
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'Action<Esc... Remove this comment to see the full error message
  [LOAD_ESCALATION]: (state, {
    error,
    meta,
    payload
  }) => !error && meta === 'response' ? Escalation.fromJS(payload) : state,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'Action<Esc... Remove this comment to see the full error message
  [LOAD_ESCALATION_META]: (state, {
    error,
    meta,
    payload
  }) => !error && meta === 'response' && payload.customerEmail && !state.customerEmail ? state.setCustomerEmail(payload.customerEmail) : state,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type 'Action<Esc... Remove this comment to see the full error message
  [SAVE_ESCALATION]: (state, {
    error,
    meta,
    payload
  }) => !error && meta === 'request' ? Escalation.fromJS(Object.assign({}, payload.data, {
    revision: payload.data.revision + 1
  })) : state
}, new Escalation());