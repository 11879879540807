import { useState, useCallback } from 'react';
import { getQuoteId } from 'quotes-ui-lib/utils/quote';
import QuotesLogger from 'quotes-ui-lib/tracking/quotesLogger';
import { getQuoteDownloadUrl } from 'quotes-ui-lib/api/QuotesApi';
import { pdfGenerationPendingAlert } from 'quotes-ui-lib/utils/alert';
import * as locationUtil from 'quotes-ui-lib/utils/location';
const useDownloadQuote = (quote, trackingLocation) => {
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const trackAction = (action, location) => QuotesLogger.log('quoteInteraction', {
    action,
    location
  });
  const callback = useCallback(() => {
    setFailed(false);
    setLoading(true);
    trackAction('Download quote', trackingLocation);
    getQuoteDownloadUrl(getQuoteId(quote)).then(response => {
      locationUtil.setHref(response.get('url'));
    }).catch(() => {
      pdfGenerationPendingAlert();
      setFailed(true);
    }).finally(() => {
      setLoading(false);
    });
  }, [quote, trackingLocation, setLoading, setFailed]);
  return [loading, failed, callback];
};
export default useDownloadQuote;