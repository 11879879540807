import { getChannelNames } from 'conversations-thread-data/thread-details/public/operators';
export const ablySubscriptionMismatch = ({
  threadDetails,
  ablySubscriptions
}) => {
  const ablySubscriptionChannelNames = ablySubscriptions ? Object.keys(ablySubscriptions) : [];
  const threadChannelNames = threadDetails ? getChannelNames(threadDetails) : null;
  if (!threadChannelNames || threadChannelNames.size === 0) {
    return {
      disabled: true,
      sendDisabledReason: 'ABLY_CHANNEL_MISMATCH'
    };
  }
  // When a thread is moved to a new inbox the new ThreadDetails will have the ably channels to subscribe to.
  // The ablySubscriptions are channels that the app is currently subscribed to.
  // This assumes all the ThreadDetails channels are subscribed to rather than a partial match.
  const allThreadsSubscribed = threadChannelNames.every(threadChannelName => {
    return ablySubscriptionChannelNames.includes(threadChannelName);
  });
  return !allThreadsSubscribed ? {
    disabled: true,
    sendDisabledReason: 'ABLY_CHANNEL_MISMATCH'
  } : null;
};