const getChargePaymentMethodDisabledReason = () => {
  const totalExceedsContactPMOFLimit = false;
  const contactMissingEmail = false;
  const invoiceHasNoContactWithPMOF = false;
  const invoiceHasPartialPayment = false;
  const invoiceHasFailedPMOFChargeAttempt = false;
  const pmofHasFailedChargeAttempt = false;
  const invoiceCurrencyInvalidforPMOF = false;
  const moreThanOneBillableContact = false;
  if (invoiceHasPartialPayment) {
    return 'PARTIAL_PAYMENTS';
  }
  if (invoiceHasFailedPMOFChargeAttempt || pmofHasFailedChargeAttempt) {
    return 'PMOF_NOT_WORKING';
  }
  if (invoiceHasNoContactWithPMOF) {
    return 'NO_VALID_CONTACT_WITH_PMOF';
  }
  if (totalExceedsContactPMOFLimit) {
    return 'TOTAL_EXCEEDS_CONTACT_PMOF_LIMIT';
  }
  if (invoiceCurrencyInvalidforPMOF) {
    return 'CURRENCY_INVALID_FOR_PMOF';
  }
  if (contactMissingEmail) {
    return 'CONTACT_MISSING_EMAIL';
  }
  if (moreThanOneBillableContact) {
    return 'MORE_THAN_ONE_BILLABLE_CONTACT';
  }
  return null;
};
export { getChargePaymentMethodDisabledReason };