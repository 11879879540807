import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["options"];
import { clearCommunicatorState } from 'composer-data/email/public/actions';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { isSucceeded } from 'conversations-async-data/async-data/operators/statusComparators';
import { useAssociations } from 'conversations-thread-data/associations/public/hooks';
import { getSupportAssociations } from 'conversations-thread-data/channel-configuration/public/operators';
import { getThreadId } from 'conversations-thread-data/thread-details/public/operators';
import { useCallback, useMemo } from 'react';
// TODO: move to composer-ui
import { getIsForward as getCMFIsForward, getRichText
// @ts-expect-error module not typed
} from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { getCommonMessage } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
import { useThreadViewPublishing } from 'conversations-thread-view/publish-message-feature/public/hooks/useThreadViewPublishing';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import { EMAIL_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
// @ts-expect-error module not typed
import { setRichText } from 'conversations-message-history/common-message-format/operators/commonMessageFormatSetters';
import { getGenericChannelId } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { useDispatch } from 'react-redux';
import { useShouldIncludeEmailReplies } from '../../../editor-state/protected/hooks';
import { uploadBase64Images } from '../../../email/public/clients';
import { useTrackMessagePublishing } from './useTrackMessagePublishing';
export const usePublishMessage = ({
  asyncEmptyReply,
  capabilitiesBlob,
  threadDetails,
  includeHistory
}) => {
  const threadId = getThreadId(threadDetails);
  const dispatch = useDispatch();
  const supportAssociations = getSupportAssociations(capabilitiesBlob);
  const splitAttachments = Boolean(capabilitiesBlob && capabilitiesBlob.splitAttachments);
  const isForwarded = useMemo(() => {
    const emptyReply = getData(asyncEmptyReply);
    const commonMessage = emptyReply && getCommonMessage(emptyReply);
    return getCMFIsForward(commonMessage);
  }, [asyncEmptyReply]);
  const shouldIncludeEmailReplies = useShouldIncludeEmailReplies(asyncEmptyReply.data && asyncEmptyReply.data.commonMessage || {});
  const {
    asyncAssociations
  } = useAssociations({
    /*  @ts-expect-error Jun-10-2024, 18:11UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
    threadId,
    deferred: !supportAssociations
  });
  const associations = isSucceeded(asyncAssociations) ? getData(asyncAssociations) : null;
  const {
    publishMessage,
    publishComment
  } = useThreadViewPublishing({
    /*  @ts-expect-error Jun-10-2024, 18:11UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
    threadId
  });
  const {
    trackComment,
    trackMessage
  } = useTrackMessagePublishing({
    threadDetails
  });
  const publishMessageWithImageUploads = useCallback((cmf, opts) => {
    const {
        options
      } = opts,
      rest = _objectWithoutPropertiesLoose(opts, _excluded);
    const messageRichText = getRichText(cmf);
    const messageChannelId = getGenericChannelId(cmf);
    const fileManagerAccess = messageChannelId === EMAIL_GENERIC_CHANNEL_ID ? 'HIDDEN_IN_APP_SENSITIVE' : 'HIDDEN_IN_APP_PRIVATE_NOT_INDEXABLE';
    /*  @ts-expect-error Jun-10-2024, 18:11UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
    uploadBase64Images({
      fileManagerAccess,
      threadId,
      html: messageRichText
    }).then(newMessageRichText => {
      const messageWithUpdatedRichText = setRichText(newMessageRichText, cmf);
      publishMessage(messageWithUpdatedRichText, Object.assign({}, rest, {
        splitAttachments,
        options: Object.assign({}, options, {
          associations,
          isForwarded,
          includeChatHistory: includeHistory,
          alreadyIncludesPreviousReplies: shouldIncludeEmailReplies
        })
      }));
    }).catch(error => {
      reportError({
        error
      });
    });
    trackMessage(cmf, opts);
    dispatch(clearCommunicatorState());
  }, [threadId, trackMessage, dispatch, publishMessage, splitAttachments, associations, isForwarded, includeHistory, shouldIncludeEmailReplies]);
  const handlePublishComment = useCallback(message => {
    publishComment(message);
    trackComment(message);
  }, [publishComment, trackComment]);
  return {
    publishComment: handlePublishComment,
    publishMessage: publishMessageWithImageUploads
  };
};