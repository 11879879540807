'use es6';

import setIn from 'transmute/setIn';
import getIn from 'transmute/getIn';
// import pipe from 'transmute/pipe';

import curry from 'transmute/curry';
import { ATTACHMENTS, COMMON_MESSAGE, EDITOR_STATE, METADATA, REPLY_ID, VID, COMMON_MESSAGE_RECIPIENTS, COMMON_MESSAGE_SENDERS } from '../../_internal/constants/keyPaths';
import { getRecipientsFromCommonMessage, getCommonMessage } from './commonReplyGetters';
import { RECIPIENT_FIELD } from 'conversations-message-history/common-message-format/constants/keyPaths';
import { getEmailMetadata } from 'conversations-message-history/common-message-format/operators/getEmailMetadata';
import { setEmailMetadataAttachment } from 'conversations-message-history/common-message-format/operators/commonMessageFormatSetters';
import { getCMFSyncedReply } from '../../_internal/operators/getCMFSyncedReply';
export const setCommonMessage = setIn(COMMON_MESSAGE);
export const setEditorState = setIn(EDITOR_STATE);
export const setReplyId = setIn(REPLY_ID);
export const setFileAttachments = setIn(ATTACHMENTS);
export const setReplyMetadata = setIn(METADATA);

/**
 * Setting CommonReply#attachments requires syncing the FileMap records to FileAttachmentRecords on CommonMessage
 */
export const setAttachments = curry((attachments, reply) => {
  const nextReply = setIn(ATTACHMENTS, attachments, reply);
  return getCMFSyncedReply(nextReply);
});
export const setMetadata = curry((metadata, reply) => {
  return setIn(METADATA, metadata, reply);
});
export const setBCC = curry((bcc, reply) => {
  return setIn(['metadata', 'bcc'], bcc, reply);
});
export const setCC = curry((cc, reply) => {
  return setIn(['metadata', 'cc'], cc, reply);
});
export const setFrom = curry((from, reply) => {
  return setIn(['metadata', 'from'], from, reply);
});
export const setInReplyToMessageId = curry((inReplyToMessageId, reply) => {
  return setIn(['metadata', 'inReplyToMessageId'], inReplyToMessageId, reply);
});
export const setSubject = curry((subject, reply) => {
  return setIn(['metadata', 'subject'], subject, reply);
});
export const setTo = curry((to, reply) => {
  return setIn(['metadata', 'to'], to, reply);
});
export const setTemplateId = curry((templateId, reply) => {
  return setIn(['metadata', 'templateId'], templateId, reply);
});
export const setVid = curry((visitor, reply) => {
  return setIn(VID, visitor, reply);
});
export const setRecipients = (fieldName, recipients, reply) => {
  return setIn(['metadata', fieldName], recipients, reply);
};
export const setIsForward = (reply, isForward) => {
  const commonMessage = getCommonMessage(reply);
  const emailMetadata = getEmailMetadata(commonMessage);
  const newEmailMetadata = emailMetadata.set('forward', isForward);
  const newCommonMessage = setEmailMetadataAttachment(commonMessage, newEmailMetadata);
  return setCommonMessage(newCommonMessage, reply);
};
export const replaceRecipientsField = curry((field, recipients, reply) => {
  const currentRecipients = getRecipientsFromCommonMessage(reply);
  const filteredRecipients = currentRecipients.filter(recipient => {
    return getIn(RECIPIENT_FIELD, recipient) !== field;
  });
  const nextRecipients = filteredRecipients.concat(recipients);
  return setIn(COMMON_MESSAGE_RECIPIENTS, nextRecipients, reply);
});
export const syncToRecipientField = replaceRecipientsField('TO');
export const syncCCRecipientField = replaceRecipientsField('CC');
export const syncBCCRecipientField = replaceRecipientsField('BCC');
export const setRecipientsInCommonMessage = curry((recipients, reply) => {
  return setIn(COMMON_MESSAGE_RECIPIENTS, recipients, reply);
});
export const setSendersInCommonMessage = (senders, reply) => {
  return setIn(COMMON_MESSAGE_SENDERS, senders, reply);
};