import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { requestSucceeded } from 'conversations-async-data/async-data/operators/requestSucceeded';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { limitToCount } from 'conversations-async-data/indexed-async-data/eviction-strategies/limitToCount';
import { ASSIGNMENT_CHANGE_STATUS_ASYNC_DATA_CACHE_SIZE } from '../../../common/public/constants/CacheSize';
import { ASSIGNMENT_CHANGE } from '../constants/asyncActionTypes';
const initialState = new IndexedAsyncData({
  name: 'assignmentStatuses',
  notSetValue: new AsyncData(),
  evict: limitToCount(ASSIGNMENT_CHANGE_STATUS_ASYNC_DATA_CACHE_SIZE)
});
export const assignmentStatuses = handleActions({
  [ASSIGNMENT_CHANGE.STARTED]: (state, action) => {
    const {
      threadId
    } = action.payload.requestArgs;
    return updateEntry(threadId, requestStarted, state);
  },
  [ASSIGNMENT_CHANGE.SUCCEEDED](state, action) {
    const {
      threadId
    } = action.payload.requestArgs;
    return updateEntry(threadId, requestSucceeded, state);
  },
  [ASSIGNMENT_CHANGE.FAILED](state, action) {
    const {
      threadId
    } = action.payload.requestArgs;
    return updateEntry(threadId, requestFailed, state);
  }
}, initialState);