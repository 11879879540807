import http from 'conversations-http/clients/http';
const CHATFLOWS_CHANNEL_INSTANCE_PREFIX = 'chatflows/v2';
export const fetchLiveChatflows = async params => {
  const allChatflows = await http.post(`${CHATFLOWS_CHANNEL_INSTANCE_PREFIX}/channel-instance/search-extended`, {
    data: Object.assign({
      chatflowSorting: 'PRIORITY',
      chatflowSource: 'LIVE_CHAT',
      sortingDirection: 'ASC'
    }, params)
  });
  return allChatflows;
};
export const fetchChatflowsForInbox = async ({
  inboxId
}) => await http.get(`${CHATFLOWS_CHANNEL_INSTANCE_PREFIX}/channel-instance/inbox/${inboxId}/hydrate-routing-rules`);
export const fetchChatflowByChannelInstanceId = async ({
  channelInstanceId
}) => await http.get(`${CHATFLOWS_CHANNEL_INSTANCE_PREFIX}/channel-instance-id/${channelInstanceId}`);