import BoldMark from '../marks/BoldMark';
import ItalicMark from '../marks/ItalicMark';
import UnderlineMark from '../marks/UnderlineMark';
import InsertSnippet from '../commands/snippets/InsertSnippet';
import ClearFormatting from '../commands/ClearFormatting';
import TextAlignmentMark from '../commands/TextAlignment';
import ListMark from '../commands/Lists';
import BlockquoteMark from '../commands/Blockquote';
import HighlightMark from '../marks/HighlightMark';
import TextColorMark from '../marks/TextColorMark';
import FontStyleMark from '../marks/FontStyleMark';
import FontSizeMark from '../marks/FontSizeMark';
import InsertLink from '../commands/InsertLink';
import InsertKnowledgeArticle from '../commands/knowledgeArticles/InsertKnowledgeArticle';
import FileAttachments from '../commands/FileAttachments';
import InlineImage from '../commands/InlineImage';
import RenderTrayPassthrough from '../commands/RenderTray';
import StrikeMark from '../marks/StrikeMark';
import VideoConferenceButton from '../commands/VideoConferenceButton';
import RenderSubmitButton from '../commands/RenderSubmitButton';
import InsertVideo from '../commands/InsertVideo';
import InsertMeetings from '../commands/meetings/InsertMeetings';
import InsertDocuments from '../commands/documents/InsertDocuments';
import InsertQuotes from '../commands/quotes/InsertQuotes';
import InsertHorizontalRule from '../commands/InsertHorizontalRule';
import InsertPaymentLink from '../commands/paymentLink/InsertPaymentLink';
import InsertTemplates from '../commands/templates/InsertTemplates';
import InsertInvoices from '../commands/invoices/InsertInvoices';
import InsertGeneratedAISummary from '../commands/ai/InsertGeneratedAISummary';
import ContentAssistant from '../commands/ai/ContentAssistant';
import AIAssistant from '../commands/ai/AIAssistant';
import InsertEmoji from '../commands/InsertEmoji';
import InsertPersonalizationToken from '../commands/InsertPersonalizationToken';
import { AI_SUMMARY, AI_ASSISTANT, CONTENT_ASSISTANT, BOLD, ITALIC, UNDERLINE, CLEAR_FORMATTING, SNIPPETS, TEMPLATES, TEXT_ALIGN, LISTS, BLOCKQUOTE, TEXT_HIGHLIGHT, TEXT_COLOR, FONT_STYLE, FONT_SIZE, LINK, KNOWLEDGE_ARTICLE, FILE_ATTACHMENTS, INLINE_IMAGE, RENDER_TRAY, RENDER_SUBMIT_BUTTON, VIDEO_CONFERENCE_LINK, VIDEO_INSERTION, STRIKETHROUGH, MEETINGS, DOCUMENTS, QUOTES, HORIZONTAL_RULE, PAYMENT_LINK, EMOJI, INVOICES, PERSONALIZATION_TOKEN } from './constants';
export const editorCapabilitiesCommands = {
  [AI_ASSISTANT]: AIAssistant,
  [AI_SUMMARY]: InsertGeneratedAISummary,
  [BOLD]: BoldMark,
  [CONTENT_ASSISTANT]: ContentAssistant,
  [ITALIC]: ItalicMark,
  [UNDERLINE]: UnderlineMark,
  [STRIKETHROUGH]: StrikeMark,
  [SNIPPETS]: InsertSnippet,
  [MEETINGS]: InsertMeetings,
  [DOCUMENTS]: InsertDocuments,
  [QUOTES]: InsertQuotes,
  [CLEAR_FORMATTING]: ClearFormatting,
  [TEXT_ALIGN]: TextAlignmentMark,
  [LISTS]: ListMark,
  [LINK]: InsertLink,
  [BLOCKQUOTE]: BlockquoteMark,
  [TEXT_HIGHLIGHT]: HighlightMark,
  [TEXT_COLOR]: TextColorMark,
  [FONT_STYLE]: FontStyleMark,
  [FONT_SIZE]: FontSizeMark,
  [KNOWLEDGE_ARTICLE]: InsertKnowledgeArticle,
  [FILE_ATTACHMENTS]: FileAttachments,
  [INLINE_IMAGE]: InlineImage,
  [RENDER_TRAY]: RenderTrayPassthrough,
  [RENDER_SUBMIT_BUTTON]: RenderSubmitButton,
  [VIDEO_CONFERENCE_LINK]: VideoConferenceButton,
  [VIDEO_INSERTION]: InsertVideo,
  [HORIZONTAL_RULE]: InsertHorizontalRule,
  [PAYMENT_LINK]: InsertPaymentLink,
  [TEMPLATES]: InsertTemplates,
  [EMOJI]: InsertEmoji,
  [INVOICES]: InsertInvoices,
  [PERSONALIZATION_TOKEN]: InsertPersonalizationToken
};