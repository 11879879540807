/**
 * Unsubscribe and subscribe from presence messages
 * on a channel. Noops on subscription objects that
 * don't provide a presence handler.
 *
 * @param {Object} options
 * @param {Object} options.vendor vendor api wrapper
 * @param {Object<String, Object>} options.toAdd subscriptions to add.
 * Mapping of channel IDs to callback objects.
 * @param {Object<String, Object>} options.toRemove subscriptions to remove
 * Mapping of channel IDs to callback objects.
 * @returns {Promise}
 *
 */

export const transactPresenceSubscriptions = ({
  vendor,
  toAdd = {},
  toRemove = {},
  reauthorize = () => {}
}) => {
  return Promise.all(Object.keys(toRemove).filter(key => toRemove[key].onPresence).map(channelId => {
    const {
      onPresence
    } = toRemove[channelId];
    return vendor.unsubscribePresence({
      channelId,
      onMessage: onPresence
    });
  }))
  /* ABLY TODO: Remove call to reauthorize when
   * wildcard auth is supported in the visitor widget */.then(() => reauthorize(Object.keys(toAdd))).then(() => Promise.all(Object.keys(toAdd).filter(key => toAdd[key].onPresence).map(channelId => {
    const {
      onPresence
    } = toAdd[channelId];
    return vendor.subscribePresence({
      channelId,
      onMessage: onPresence
    }).then(() => {
      // fire an initial message to trigger a presence lookup
      onPresence({});
    });
  })));
};