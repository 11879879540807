import { List } from 'immutable';
import formatName from 'I18n/utils/formatName';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'refe... Remove this comment to see the full error message
import ReferenceRecord from 'reference-resolvers/schema/ReferenceRecord';
export const formatVisitor = visitor => {
  const {
    firstName,
    lastName,
    email
  } = visitor;
  const id = visitor.canonicalVid || visitor.vid;
  const formattedName = formatName({
    firstName,
    lastName
  });
  return new ReferenceRecord({
    id: `${id}`,
    label: formattedName || email,
    referencedObject: visitor
  });
};
export const formatVisitors = visitors => {
  // @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
  return new List(Object.keys(visitors).map(visitor => formatVisitor(visitors[visitor])));
};