/**
 * pick keys from an object
 *
 * @param {Object} object object to remove keys from
 * @param {Array} toPick keys to pick
 * @returns {Object} new object with the keys removed
 */
export const pick = (ob, toPick) => toPick.reduce((acc, key) => {
  if (key in ob) {
    acc[key] = ob[key];
  }
  return acc;
}, {});