import { useEffect, useRef } from 'react';
import { getId, isProcessing, isCompleted, isCreatedByUser } from 'find-and-filter-data/views-schema/public';

/**
 * @description This hook is used to fire the view creation completed event. This
 * hook will fire that event with the view createdAt for each view created by the
 * user which has transitioned from Processing to Completed
 */
export function useTrackViewCompleted(trackedViews, userId, handleTrackViewTracking) {
  const prevTrackedViews = useRef(trackedViews);
  useEffect(() => {
    if (prevTrackedViews.current) {
      const prevProcessingViews = prevTrackedViews.current.filter(isProcessing);
      const nowCompletedViews = trackedViews.filter(isCompleted);
      const nowCompletedViewIds = new Set(nowCompletedViews.map(view => getId(view)));

      // get views which have transitioned from Processing -> Completed that
      // were created by the current user
      const currentUserProcessedViews = prevProcessingViews.filter(view => nowCompletedViewIds.has(getId(view)) && isCreatedByUser(view, userId));
      if (handleTrackViewTracking) {
        currentUserProcessedViews.forEach(view => {
          handleTrackViewTracking(view);
        });
      }
    }
  }, [userId, trackedViews, handleTrackViewTracking]);
  useEffect(() => {
    prevTrackedViews.current = trackedViews;
  }, [trackedViews]);
}