import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { getProductPropertiesForClone } from 'products-ui-components/utils/getProductPropertiesForClone';
import { CREATE_PRODUCT_MUTATION, PRODUCT_BY_ID_QUERY, PRODUCT_PROPERTIES_AND_GROUPS_QUERY } from '../graphQL/queries';
import { parseProperties } from '../graphQL/queryParser';
import { Map as ImmutableMap } from 'immutable';
import getPropertyValueInputs from 'products-ui-components/utils/getPropertyValueInputs';
export function useCloneProductById({
  objectId,
  onClone,
  onError
}) {
  const {
    data: productPropertiesData = {
      productProperties: []
    },
    loading: productPropertiesLoading
  } = useQuery(PRODUCT_PROPERTIES_AND_GROUPS_QUERY, {
    fetchPolicy: 'cache-and-network'
  });
  const {
    productProperties
  } = productPropertiesData;
  const properties = parseProperties(productProperties);
  const propertiesMap = useMemo(() => ImmutableMap(Object.entries(properties)), [properties]);
  const propertyNames = useMemo(() => Object.keys(properties), [properties]);
  const {
    data: productData,
    loading: productLoading
  } = useQuery(PRODUCT_BY_ID_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: objectId,
      properties: propertyNames
    },
    skip: !objectId,
    onError
  });
  const subject = useMemo(() => {
    var _productData$crmObjec, _productData$crmObjec2, _productData$crmObjec3, _productData$crmObjec4;
    const newSubjectProperties = ((_productData$crmObjec = productData === null || productData === void 0 || (_productData$crmObjec2 = productData.crmObject) === null || _productData$crmObjec2 === void 0 ? void 0 : _productData$crmObjec2.properties) !== null && _productData$crmObjec !== void 0 ? _productData$crmObjec : []).reduce((acc, value) => {
      var _value$value;
      acc[value.name] = {
        name: value.name,
        value: (_value$value = value.value) !== null && _value$value !== void 0 ? _value$value : undefined
      };
      return acc;
    }, {});
    const returnValue = {
      objectId: (_productData$crmObjec3 = productData === null || productData === void 0 || (_productData$crmObjec4 = productData.crmObject) === null || _productData$crmObjec4 === void 0 ? void 0 : _productData$crmObjec4.id) !== null && _productData$crmObjec3 !== void 0 ? _productData$crmObjec3 : null,
      properties: newSubjectProperties
    };
    return returnValue;
  }, [productData]);
  const [createProductMutation] = useMutation(CREATE_PRODUCT_MUTATION, {});
  const isLoading = productLoading || productPropertiesLoading;
  const handleClone = useCallback(() => {
    const cloneProperties = getProductPropertiesForClone(subject, propertiesMap);
    const modifiedPropertyNames = Object.values(cloneProperties).reduce((acc, value) => {
      if (!value.value || !value.name) return acc;
      acc.push(value.name);
      return acc;
    }, []);
    if (Object.keys(cloneProperties).length) {
      createProductMutation({
        variables: {
          properties: getPropertyValueInputs(cloneProperties)
        }
      }).then(response => {
        var _response$data;
        const createdCrmObject = (_response$data = response.data) === null || _response$data === void 0 || (_response$data = _response$data.createObjectAndAssociations) === null || _response$data === void 0 ? void 0 : _response$data.createdCrmObject;
        if (createdCrmObject) {
          return onClone(String(createdCrmObject.id), modifiedPropertyNames);
        } else {
          var _response$data2;
          // No created object returned so reporting any user errors
          onError((_response$data2 = response.data) === null || _response$data2 === void 0 || (_response$data2 = _response$data2.createObjectAndAssociations) === null || _response$data2 === void 0 ? void 0 : _response$data2.userErrors);
        }
      }).catch(onError);
    }
  }, [subject, propertiesMap, createProductMutation, onError, onClone]);
  return {
    handleClone,
    isLoading
  };
}