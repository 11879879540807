/**
 * UITourStep requires an initialized tour with tour.getHandler() to be defined
 * before rendering
 */
export function canRenderTourStep(tour) {
  return tour &&
  // tour.getHandler() will return undefined until the tour is activated
  tour.getHandler() &&
  // tour.getConfig() will return undefined until the users last seen steps
  // have been fetched
  tour.getConfig() &&
  // tour.getConfig().steps will return undefined/empty only in cases of errors
  // fetching user seen steps
  tour.getConfig().steps.length !== 0;
}