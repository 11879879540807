'use es6';

import { Record, List } from 'immutable';
import { EMAIL_REPLY_METADATA } from '../constants/metadataTypes';
import { METADATA_TYPE } from '../../_internal/constants/metadataKeyPaths';
export default Record({
  [METADATA_TYPE]: EMAIL_REPLY_METADATA,
  bcc: List(),
  cc: List(),
  from: null,
  inReplyToMessageId: null,
  subject: null,
  to: List(),
  templateId: null,
  isForward: false
}, 'EmailReplyMetadata');