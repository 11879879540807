'use es6';

import filter from 'transmute/filter';
import filterNot from 'transmute/filterNot';
import pipe from 'transmute/pipe';
import { isConversationalMessage } from '../../common-message/operators/isConversationalMessage';
import { isThreadComment } from '../../comment-message/operators/cmComparators';
import { isFromAgent } from '../../common-message-format/operators/senderTypeComparators';
import { getGenericChannelIdForMessage } from '../../common-message-format/operators/commonMessageGetters';
import { threadHistoryInvariant } from '../invariants/threadHistoryInvariant';
import { getMessages } from './getters';

/**
 * Returns the latest agent message in thread history
 *
 * @param {ThreadHistory} threadHistory
 * @returns {MessageRecord}
 */
export const getLatestAgentMessage = (threadHistory, genericChannelId) => {
  threadHistoryInvariant(threadHistory);
  const agentMessages = pipe(getMessages, filter(message => isFromAgent(message) && isConversationalMessage(message)), filterNot(isThreadComment))(threadHistory);
  if (genericChannelId) {
    return agentMessages.findLast(message => getGenericChannelIdForMessage(message) === genericChannelId);
  }
  return agentMessages && agentMessages.last();
};