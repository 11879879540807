export const getSentMessageChatActivationUser = ({
  communicatorType
}) => ({
  key: `sent-message-chat-activation-user`,
  properties: {
    action: 'sent message',
    'communicator-type': communicatorType
  }
});
export const getSentMessageFBMActivationUser = ({
  communicatorType
}) => ({
  key: `sent-message-fbm-activation-user`,
  properties: {
    action: 'sent message',
    'communicator-type': communicatorType
  }
});
export const getSentMessageWhatsAppActivationUser = ({
  communicatorType
}) => ({
  key: `sent-message-whatsapp-activation-user`,
  properties: {
    action: 'sent message',
    'communicator-type': communicatorType
  }
});
export const getSentMessageChatActivationPortal = ({
  communicatorType,
  portalId
}) => ({
  key: `sent-message-chat-activation-portal`,
  properties: {
    action: 'sent message',
    hubId: portalId,
    'communicator-type': communicatorType
  }
});
export const getSentMessageFBMActivationPortal = ({
  communicatorType,
  portalId
}) => ({
  key: `sent-message-fbm-activation-portal`,
  properties: {
    action: 'sent message',
    hubId: portalId,
    'communicator-type': communicatorType
  }
});
export const getSentMessageWhatsAppActivationPortal = ({
  communicatorType,
  portalId
}) => ({
  key: `sent-message-whatsapp-activation-portal`,
  properties: {
    action: 'sent message',
    hubId: portalId,
    'communicator-type': communicatorType
  }
});