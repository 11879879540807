import http from 'conversations-http/clients/http';
import { registerMutation } from 'data-fetching-client';
export const ASSIGN_THREAD_MUTATION_NAME = 'assignThread';
export const ASSIGN_THREAD_MUTATION = registerMutation({
  fieldName: ASSIGN_THREAD_MUTATION_NAME,
  args: ['threadId', 'data'],
  fetcher({
    threadId,
    data
  }) {
    return http.post(`conversations-assignments/v1/assignments/${threadId}/routing/definition`, {
      data
    });
  }
});