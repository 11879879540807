import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestFailedWithError } from 'conversations-async-data/async-data/operators/requestFailedWithError';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { useCallback, useEffect, useState } from 'react';
import { fetchInboxes } from '../clients/fetchInboxes';
import { isOutOfSync, isUninitialized } from 'conversations-async-data/async-data/operators/statusComparators';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { updateAsyncData } from 'conversations-async-data/async-data/operators/updateAsyncData';
import { isInboxHelpdesk } from 'conversations-internal-schema/inbox/operators/inboxGetters';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';

// Emulate a redux store, to avoid having to fetch this each time
let localInboxes = [];
const getLocalInboxesWithoutHelpdesk = () => localInboxes.filter(inbox => !isInboxHelpdesk(inbox));
export const useInboxes = (shouldIncludeHelpdesk = false) => {
  const defaultData = shouldIncludeHelpdesk ? localInboxes : getLocalInboxesWithoutHelpdesk();
  const [asyncData, setAsyncData] = useState(new AsyncData({
    data: defaultData
  }));
  const fetcher = useCallback(() => {
    fetchInboxes(true).then(resp => {
      localInboxes = resp;
      const responseListWithoutHelpdesk = getLocalInboxesWithoutHelpdesk();
      setAsyncData(requestSucceededWithOperator(() => shouldIncludeHelpdesk ? resp : responseListWithoutHelpdesk, asyncData));
      return;
    }, error => {
      setAsyncData(requestFailedWithError(error, asyncData));
    }).catch(err => {
      reportError({
        error: err
      });
    });
  }, [asyncData, shouldIncludeHelpdesk]);
  useEffect(() => {
    if (isOutOfSync(asyncData) || isUninitialized(asyncData)) {
      fetcher();
    }
  }, [asyncData, fetcher]);
  useEffect(() => {
    if (shouldIncludeHelpdesk && getData(asyncData) !== localInboxes) {
      setAsyncData(updateAsyncData(() => localInboxes, asyncData));
    }
    if (!shouldIncludeHelpdesk && getData(asyncData) === localInboxes) {
      setAsyncData(updateAsyncData(() => getLocalInboxesWithoutHelpdesk(), asyncData));
    }
  }, [setAsyncData, asyncData, shouldIncludeHelpdesk]);
  return {
    asyncInboxes: asyncData,
    retryFailed: fetcher
  };
};