import { parseRawDraft } from '../local-storage/parseRawDraft';
import { Draft } from '../records/Draft';
export function deserializeDraft(rawDraft, deserializeData) {
  try {
    const parsedDraft = parseRawDraft(rawDraft);
    if (!parsedDraft) {
      return null;
    }
    const {
      id,
      timestamp,
      version,
      data
    } = parsedDraft;
    const deserializedData = deserializeData(data);
    return new Draft({
      id,
      timestamp,
      version,
      data: deserializedData
    });
  } catch (e) {
    return null;
  }
}