import ChatFilterOptions from 'conversations-internal-schema/constants/ChatFilterOptions';
import http from 'conversations-http/clients/http';
const {
  STARTED
} = ChatFilterOptions;
export const openThread = ({
  threadId
}) => {
  const url = `conversations-threads/v1/threads/${threadId}/status`;
  return http.put(url, {
    data: {
      status: STARTED
    }
  });
};