import I18n from 'I18n';
export const getPersonalEmailFromValues = connectedInboxes => {
  const hasViewPermissions = connectedInbox => connectedInbox.inboxStatus === 'INACTIVE' || connectedInbox.inboxStatus === 'ENABLED';
  const isSharedConnectedInbox = connectedInbox => connectedInbox.shared;
  const getPrimaryAliasAsFromValue = connectedInbox => {
    const primaryAlias = connectedInbox.aliases.find(alias => alias.primary);
    return {
      disabled: connectedInbox.inboxStatus === 'INACTIVE',
      name: primaryAlias.resolvedFromName || undefined,
      email: primaryAlias.sendFromEmail || connectedInbox.accountId,
      shared: connectedInbox.shared,
      conversationsInboxName: primaryAlias.conversationsInboxName,
      accountId: connectedInbox.accountId,
      fallback: false
    };
  };
  return connectedInboxes.filter(hasViewPermissions).filter(connectedInbox => !isSharedConnectedInbox(connectedInbox)).map(getPrimaryAliasAsFromValue);
};
export const getTeamFromValues = (connectedAccounts, defaultEmail) => {
  const hasViewPermissionsOrIsDefaultEmail = connectedAccount => connectedAccount.userAllowedAccess || !connectedAccount.isDefault && connectedAccount.email === defaultEmail;
  const isFallbackEmail = connectedAccount => connectedAccount.isDefault;
  const mapFromValue = connectedAccount => ({
    email: connectedAccount.sendFromEmail || connectedAccount.email,
    name: connectedAccount.resolvedFromName || undefined,
    disabled: !connectedAccount.isActive,
    shared: true,
    conversationsInboxName: connectedAccount.conversationsInboxName,
    accountId: connectedAccount.email,
    fallback: connectedAccount.isDefault
  });
  return connectedAccounts.filter(connectedAccount => hasViewPermissionsOrIsDefaultEmail(connectedAccount)).filter(connectedAccount => !isFallbackEmail(connectedAccount)).map(mapFromValue);
};
export const getEmailOptionDisplayValue = ({
  name,
  email
}) => {
  if (name && email) {
    return `${name} (${email})`;
  } else if (email) {
    return `${email}`;
  } else {
    return `${name}`;
  }
};
export const getFromValuesGroupedSelectOptions = fromValues => {
  const personalEmails = fromValues.filter(fromValue => !fromValue.shared);
  const teamEmails = fromValues.filter(fromValue => fromValue.shared && !fromValue.fallback);
  const groupedFromEmails = [personalEmails.length > 0 && {
    text: I18n.text('invoices.components.sendInvoiceModal.fields.from.personalEmailsSubheader'),
    options: personalEmails.map(fromValue => ({
      text: getEmailOptionDisplayValue({
        name: fromValue.name,
        email: fromValue.email
      }),
      value: fromValue.email
    }))
  }, teamEmails.length > 0 && {
    text: I18n.text('invoices.components.sendInvoiceModal.fields.from.teamEmailsSubheader'),
    options: teamEmails.map(fromValue => ({
      text: getEmailOptionDisplayValue({
        name: fromValue.name,
        email: fromValue.email
      }),
      value: fromValue.email,
      help: fromValue.conversationsInboxName
    }))
  }].filter(maybeGroupedSelectOption => Boolean(maybeGroupedSelectOption));
  return groupedFromEmails;
};