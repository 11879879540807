export const ASSIGN_THREAD_MARKER = 'conversations-thread-assignment';
export const GQL_ASSIGN_THREAD_MARKER = 'conversations-gql-thread-assignment';
export const MARK_AS_SPAM_MARKER = 'conversations-thread-mark-as-spam';
export const MARK_THREAD_AS_CLOSED_MARKER = 'conversations-thread-mark-as-closed';
export const MOVE_TO_TRASH_MARKER = 'conversations-thread-move-to-trash';
export const UNMARK_AS_SPAM_MARKER = 'conversations-thread-unmark-as-spam';
export const THREAD_LIST_SWITCHING_MARKER = 'conversations-thread-list';
export const THREAD_SWITCHING_MARKER = 'conversations-thread-list-row';
export const CRITICAL_PATH_THREAD_HISTORY = 'critical-path-thread-history';
export const CRITICAL_PATH_THREAD_LIST_MEMBERS = 'critical-path-thread-list-members';
export const SECONDARY_PATH_THREAD_HISTORY = 'secondary-path-thread-history';
export const SECONDARY_PATH_THREAD_LIST_MEMBERS = 'secondary-path-thread-list-members';
export const SEND_BUTTON_READY = 'send-button-ready';
export const SEND_MESSAGE = 'send-message';