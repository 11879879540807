import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["__typename"];
import { List, Map as ImmutableMap } from 'immutable';
import getIn from 'transmute/getIn';
import ObjectIds from 'customer-data-objects/constants/ObjectIds';
import { DEAL, LINE_ITEM, PRODUCT } from 'customer-data-objects/constants/ObjectTypes';
import DealRecord from 'customer-data-objects/deal/DealRecord';
import LineItemRecord from 'customer-data-objects/lineItem/LineItemRecord';
import PropertyRecord from 'customer-data-objects/property/PropertyRecord';
import memoize from 'transmute/memoize';
import ProductRecord from 'customer-data-objects/product/ProductRecord';
import FolderRecord from 'customer-data-objects/folder/FolderRecord';
// @ts-expect-error getIn incorrectly returns unknown value
export const getDefaultCurrencyCode = getIn(['homeCurrency', 'currencyCode']);

// @ts-expect-error getIn incorrectly returns unknown value
export const getHasMulticurrencyActive = getIn(['isMulticurrencyActive']);

// @ts-expect-error getIn incorrectly return unknown value
export const getIsMulticurrencyActive = getIn(['currency', 'isMulticurrencyActive']);

// @ts-expect-error getIn incorrectly returns unknown value
export const getHomeCurrencyCode = getIn(['currency', 'homeCurrency', 'currencyCode']);

// @ts-expect-error getIn incorrectly returns unknown value
export const getMulticurrencies = getIn(['exchangeRates']);
export function parseSettingsArray(settingsArray) {
  return (settingsArray !== null && settingsArray !== void 0 ? settingsArray : []).reduce((acc, setting) => {
    let key;
    if (setting.__typename === 'CommerceProductsPortalSetting') {
      key = setting.name;
    } else {
      key = setting.key;
    }
    try {
      // string attributes come back as ""value"" - need to be de-stringified
      return Object.assign({}, acc, {
        [key]: setting.value && JSON.parse(setting.value)
      });
    } catch (e) {
      return Object.assign({}, acc, {
        [key]: setting.value
      });
    }
  }, {});
}

/**
 * Given a Multicurrencies Array returned from GraphQL with array values being
 * multicurrency properties objects, return a List of objects with key being
 * referencedObject and values being an ImmutableMap of the multicurrency properties
 *
 * @param {Array} multicurrenciesArray
 * @returns {Array} Array of objects
 * { referencedObject: ImmutableMap({ multicurrencyProperties }) }
 */
export function parseMulticurrencies(multicurrenciesArray) {
  return multicurrenciesArray.map(multicurrencies => ({
    referencedObject: new Map(Object.entries(multicurrencies))
  }));
}
export const parseProperties = memoize(propertyGroupsGql => {
  if (!propertyGroupsGql) {
    return {};
  }

  // flatten array of property groups into array of property definitions
  const propertiesArray = propertyGroupsGql.reduce((acc, propertyGroup) => acc.concat(propertyGroup.allPropertyDefinitions), []);
  const propertiesObject = {};
  propertiesArray.forEach(property => {
    propertiesObject[property.name] = PropertyRecord.fromJS(property);
  });
  return propertiesObject;
});
export const parseProductsAndFolders = productsAndFolders => {
  if (!productsAndFolders) {
    return productsAndFolders;
  }
  return ImmutableMap(Object.assign({}, productsAndFolders, {
    results: List(productsAndFolders.results.map(result => {
      return result.objectType === PRODUCT ? ProductRecord.fromJS(result) : FolderRecord.fromJS(result);
    }))
  }));
};
export const parseProducts = products => {
  if (!products) {
    return products;
  }
  return ImmutableMap(Object.assign({}, products, {
    results: List(products.results.map(result => ProductRecord.fromJS(result)))
  }));
};
export function parsePropertyGroups(graphQLPropertyGroups, propertyMappingFunc = property => property) {
  const returnMap = new Map();
  if (!graphQLPropertyGroups) {
    return returnMap;
  }
  return graphQLPropertyGroups.reduce((acc, propertyGroup, index) => acc.set(propertyGroup.name, {
    displayName: propertyGroup.label,
    displayOrder: propertyGroup.displayOrder || index,
    name: propertyGroup.name,
    hubspotDefined: propertyGroup.hubspotDefined,
    properties: propertyGroup.allPropertyDefinitions.map(propertyMappingFunc)
  }), returnMap);
}
const RecordType = {
  [DEAL]: DealRecord,
  [LINE_ITEM]: LineItemRecord,
  [PRODUCT]: ProductRecord
};
export function toSubject(objectType, gqlCrmObject = {}) {
  const _ref = gqlCrmObject.defaultProperties || {},
    defaultProperties = _objectWithoutPropertiesLoose(_ref, _excluded);
  const propertiesList = gqlCrmObject.properties || [];
  const allPropertiesList = gqlCrmObject.allProperties || [];
  const favoritePropertiesList = gqlCrmObject.favoriteProperties || [];
  // map all properties arrays to maps of PropertyValueRecords keyed by name
  const properties = [...propertiesList, ...allPropertiesList, ...favoritePropertiesList].filter(({
    value
  }) => value !== null).reduce((acc, {
    name,
    value
  }) => name ? Object.assign({}, acc, {
    [name]: {
      name,
      value
    }
  }) : acc, {});
  return RecordType[objectType].fromJS({
    [ObjectIds[objectType]]: gqlCrmObject.id,
    properties: Object.assign({}, properties, defaultProperties)
  });
}
export function parseProductViews(productViewDataShape) {
  if (!productViewDataShape) {
    return productViewDataShape;
  }
  const {
    total,
    results,
    paging
  } = productViewDataShape;
  return {
    total,
    results: results.map(result => Object.assign({}, result, {
      quickFilters: JSON.parse(result.quickFilters),
      filterGroups: JSON.parse(result.filterGroups)
    })),
    next: paging && paging.next.after
  };
}