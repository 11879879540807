import { List, Map as ImmutableMap } from 'immutable';
const _scopeIsInList = (scopes, scopeToCheck) => {
  return scopes.includes(scopeToCheck);
};
export const _userIsScoped = (scopes, scopeToCheck) => {
  let value;
  if (ImmutableMap.isMap(scopes)) {
    const scopesMap = scopes;
    value = scopesMap.get(scopeToCheck);
  } else {
    const scopeObj = scopes;
    value = scopeObj[scopeToCheck];
  }
  // Some scopes have them as string values. Cast those to boolean.
  return !!value;
};
export const isScoped = (scopes, scopesToCheck) => {
  const isIterable = List.isList(scopes) || Array.isArray(scopes);
  const scopeValidator = isIterable ? _scopeIsInList : _userIsScoped;
  if (Array.isArray(scopesToCheck)) {
    return scopesToCheck.every(scopeToCheck => scopeValidator(scopes, scopeToCheck));
  }
  return scopeValidator(scopes, scopesToCheck);
};
export const someScoped = (scopes, scopesToCheck) => {
  const scopeValidator = List.isList(scopes) || Array.isArray(scopes) ? _scopeIsInList : _userIsScoped;
  return scopesToCheck.some(scopeToCheck => scopeValidator(scopes, scopeToCheck));
};