import { Record } from 'immutable';
import AliasAddress from 'customer-data-email/schema/connectedAccount/AliasAddress';
// From EmailAddressRecord for backwards compatibility
const BaseGenericIdentifierRecord = Record({
  id: null,
  addressId: null,
  firstName: null,
  lastName: null,
  address: null,
  friendlyName: null,
  sendAsAddress: null,
  resolvedFromName: null,
  deliveryIdentifierType: null,
  deliveryIdentifierValue: null,
  genericChannelId: null,
  crmObjectId: null
}, 'BaseGenericIdentifierRecord');
export class GenericIdentifierRecord extends BaseGenericIdentifierRecord {
  fullName(nameFormatter) {
    return nameFormatter({
      firstName: this.firstName,
      lastName: this.lastName
    });
  }
  formatted(nameFormatter) {
    const fullName = this.fullName(nameFormatter);
    if (fullName) {
      return `"${fullName}" <${this.address}>`;
    }
    return this.address;
  }
  exists() {
    return !!this.id;
  }
  hasName() {
    return !!(this.firstName || this.lastName || this.friendlyName);
  }
  hasAddress() {
    return !!(this.address || this.sendAsAddress);
  }
  getAccountId() {
    return AliasAddress.toAccountId(this.addressId);
  }
  getSendAsAddress() {
    return this.sendAsAddress || this.address;
  }
  getFriendlyName(nameFormatter) {
    const fullName = this.fullName(nameFormatter);
    return this.resolvedFromName || this.friendlyName || fullName;
  }
}