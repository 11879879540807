'use es6';

import { CallableObject, AssociatedObjectType } from 'calling-lifecycle-internal/callees/records/CalleesRecordsTyped';
import { COMPANY_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { List, OrderedMap } from 'immutable';
import getIn from 'transmute/getIn';
import { isCallableObjectTypeId } from './isCallableObjectTypeId';

// CalleesRecord
export const getAssociatedObjectsPage = getIn(['associatedObjectsPage']);
export const getFromObject = getIn(['fromObject']);

// AssociatedObjectType
export const getAssociatedObjects = getIn(['associatedObjects']);
export const getAssociatedObjectType = getIn(['objectType']);

// AssociatedObjects
export const getResults = getIn(['results']);

// CallableObject
export const getPhoneNumberProperties = getIn(['phoneNumberProperties']);
export const getAdditionalProperties = getIn(['additionalProperties']);
export const getObjectId = getIn(['objectId']);
export const getObjectType = getIn(['legacyObjectType']);
export const getObjectTypeId = getIn(['objectTypeId']);
export const getObjectName = getIn(['name']);
export const getCanCommunicate = getIn(['canCommunicate']);
export const getObjectEmail = getIn(['additionalProperties', 'email', 'value']);
export const getObjectFirstName = getIn(['additionalProperties', 'firstName', 'value']);
export const getObjectLastName = getIn(['additionalProperties', 'lastName', 'value']);
export const getIsOptedOutOfCommunications = getIn(['optOutOfCommunications', '@ok']);
export const getIsOptedOutOfCommunicationsV2 = getIn(['crmObjectSubscriptionStatus', 'optedOutOfCommunications']);

// PhoneNumberProperty
export const getPropertyName = getIn(['propertyName']);
export const getLabel = getIn(['label']);
export const getHubSpotDefined = getIn(['hubspotDefined']);
export const getValue = getIn(['value']);
export const getMetadata = getIn(['metadata']);

// PhoneNumberPropertyMetadata
export const getExtension = getIn(['extension']);
export const getPhoneNumber = getIn(['phoneNumber']);
export const getValidNumber = getIn(['validNumber']);
export const getPossibleNumber = getIn(['possibleNumber']);
export const getShouldValidateNumber = phoneNumberPropertyMetadata => {
  return getValidNumber(phoneNumberPropertyMetadata) || getPossibleNumber(phoneNumberPropertyMetadata);
};
export const getHasMoreCallees = calleesRecord => {
  if (!calleesRecord) {
    return false;
  }
  const associatedObjectsPage = getAssociatedObjectsPage(calleesRecord);
  if (associatedObjectsPage && associatedObjectsPage.size) {
    return associatedObjectsPage.some(associatedObjectType => {
      return getIn(['associatedObjects', 'hasMore'], associatedObjectType);
    });
  }
  return false;
};
export const getCallableObjects = calleesRecord => {
  if (!calleesRecord) {
    return OrderedMap();
  }
  const fromObject = getFromObject(calleesRecord);
  const associatedObjectsPage = getAssociatedObjectsPage(calleesRecord);
  let associatedNumbers = OrderedMap();
  const fromObjectTypeId = fromObject && getObjectTypeId(fromObject);
  if (isCallableObjectTypeId(fromObjectTypeId)) {
    associatedNumbers = associatedNumbers.set(CallableObject.createReferenceKey(fromObject), fromObject);
  }
  associatedObjectsPage.forEach(associatedObjectType => {
    const associatedObjects = getAssociatedObjects(associatedObjectType);
    const results = getResults(associatedObjects);
    associatedNumbers = associatedNumbers.merge(results);
  });
  return associatedNumbers;
};
export const getCallablePhoneNumberProperties = phoneNumberProperties => {
  if (!phoneNumberProperties) {
    return List();
  }
  return phoneNumberProperties.filter(phoneNumberProperty => {
    const value = getValue(phoneNumberProperty) || '';
    return Boolean(value.trim());
  });
};
export const getCallableObject = ({
  objectId,
  objectTypeId
}, calleesRecord) => {
  const fromObject = getFromObject(calleesRecord);
  const fromObjectId = getObjectId(fromObject);
  const fromObjectTypeId = getObjectTypeId(fromObject);
  if (objectId === fromObjectId && objectTypeId === fromObjectTypeId) {
    return fromObject;
  }
  const referenceKey = CallableObject.createReferenceKey({
    objectId,
    objectTypeId
  });
  return calleesRecord.getIn(['associatedObjectsPage', objectTypeId, 'associatedObjects', 'results', referenceKey]);
};
export const getPropertyFromCallableObject = ({
  callableObject,
  propertyName
}) => {
  if (!callableObject) {
    return null;
  }
  return getIn(['phoneNumberProperties', propertyName], callableObject) || null;
};
export const getPropertyFromCallees = ({
  objectTypeId,
  objectId,
  propertyName
}, calleesRecord) => {
  const callableObject = getCallableObject({
    objectTypeId,
    objectId
  }, calleesRecord);
  if (!callableObject) {
    return null;
  }
  return callableObject.getIn(['phoneNumberProperties', propertyName]) || null;
};
export const getHasAssociatedObjects = calleesRecord => {
  if (!calleesRecord) {
    return false;
  }
  const associatedObjectsPage = getAssociatedObjectsPage(calleesRecord);
  if (associatedObjectsPage) {
    return associatedObjectsPage.some(associateObjectType => {
      const associatedObjects = getAssociatedObjects(associateObjectType);
      const results = getResults(associatedObjects);
      return results.size > 0;
    });
  }
  return false;
};
export const addCallee = (callee, callees) => {
  const calleeId = getObjectId(callee);
  const calleeObjectTypeId = getObjectTypeId(callee);
  const fromObject = getFromObject(callees);
  const fromObjectId = getObjectId(fromObject);
  const fromObjectTypeId = getObjectTypeId(fromObject);
  if (calleeId === fromObjectId && calleeObjectTypeId === fromObjectTypeId) {
    return callees.set('fromObject', callee);
  }
  const associatedObjectsPage = getAssociatedObjectsPage(callees);
  const referenceKey = CallableObject.createReferenceKey(callee);
  if (!getIn([calleeObjectTypeId], associatedObjectsPage)) {
    return callees.setIn(['associatedObjectsPage', calleeObjectTypeId], AssociatedObjectType.fromJS({
      objectTypeId: calleeObjectTypeId,
      objectType: getObjectType(callee),
      associatedObjects: {
        results: []
      }
    }).setIn(['associatedObjects', 'results', referenceKey], callee));
  }
  return callees.setIn(['associatedObjectsPage', calleeObjectTypeId, 'associatedObjects', 'results', referenceKey], callee);
};
export const deleteCallee = ({
  associationObjectId,
  associationObjectTypeId
}, callees) => {
  const referenceKey = CallableObject.createReferenceKey({
    objectId: associationObjectId,
    objectTypeId: associationObjectTypeId
  });
  const result = callees.deleteIn(['associatedObjectsPage', associationObjectTypeId, 'associatedObjects', 'results', referenceKey]);
  return result;
};
export const getPrimaryAssociatedCompanyName = calleesRecord => {
  const associatedObjectsPage = getAssociatedObjectsPage(calleesRecord);
  const associatedCompanyObjectType = associatedObjectsPage.find(associateObjectType => getObjectTypeId(associateObjectType) === COMPANY_TYPE_ID);
  if (!associatedCompanyObjectType) {
    return null;
  }
  const associatedObjects = getAssociatedObjects(associatedCompanyObjectType);
  const companies = getResults(associatedObjects);
  const primaryCompany = companies[0];
  const name = getObjectName(primaryCompany);
  return name;
};