import { getThreadCount } from 'find-and-filter-data/views-schema/public';

/**
 * @description This function returns whether the given is considered active. This
 * is determined by checking the views threadCount and confirming it has at least
 * `minimumNumberOfMembers`(by default this is 1).
 */
export function isViewActive(view, minimumNumberOfMembers = 1) {
  const count = getThreadCount(view);
  return !!count && count >= minimumNumberOfMembers;
}