import { createAction } from 'redux-actions';
import { OPTIMISTIC_CONTACT_ASSOCIATION } from '../constants/ActionTypes';
import I18n from 'I18n';
export const optimisticContactAssociation = createAction(OPTIMISTIC_CONTACT_ASSOCIATION, ({
  threadId,
  firstName,
  lastName
}) => ({
  threadId,
  firstName,
  lastName
}), () => ({
  notification: {
    type: 'success',
    titleText: I18n.text('conversations-inbox-ui.contactAssociation.success')
  }
}));