import http from 'conversations-http/clients/http';
export const postFile = ({
  formData,
  onProgress = () => {}
}) => {
  const request = Object.assign(http.post('filemanager/api/v3/files/upload', {
    headers: {
      'content-type': false
    },
    data: formData,
    timeout: 0,
    withXhr: xhr => {
      // https://product.hubteam.com/docs/frontend/docs/hub-http.html#aborting-requests
      // mutate the .mutableXhrWrapper object asynchronously to add xhr so that the request is accessible to abort if needed
      request.mutableXhrWrapper.xhr = xhr;
      xhr.upload.addEventListener('progress', onProgress, false);
    }
  }).then(({
    objects: [uploadedFile]
  }) => uploadedFile), {
    mutableXhrWrapper: {}
  });
  return request;
};