import { FB_MESSENGER_GENERIC_CHANNEL_ID, LIVE_CHAT_GENERIC_CHANNEL_ID, WHATSAPP_GENERIC_CHANNEL_ID } from '../../../generic-channels/public/constants';
export const getMessageShouldIncludePreviousHistory = ({
  canReplyToSpecificMessages,
  isForwardingMessage,
  latestCMFGenericChannelId,
  originalGenericChannelId
}) => {
  const historyEnabledChannels = [LIVE_CHAT_GENERIC_CHANNEL_ID, FB_MESSENGER_GENERIC_CHANNEL_ID, WHATSAPP_GENERIC_CHANNEL_ID];
  const sourceIsChatOrMessenger = historyEnabledChannels.includes(originalGenericChannelId);
  const lastCMFisChatOrMessenger = historyEnabledChannels.includes(latestCMFGenericChannelId);
  return Boolean(canReplyToSpecificMessages && !isForwardingMessage && sourceIsChatOrMessenger && lastCMFisChatOrMessenger);
};