import { ATTACHMENTS, INSERT_CORE, INSERT_TEMPLATES, MODEL_SMART_COMPOSE } from '../../../channel-configuration/public/constants';
/**
 * @description builds up the non-async parts of the capabilitiesConfig
 * by combining a static capabilities config with thread details
 */
export const buildBaseCapabilitiesConfig = (capabilitiesConfig, threadId, contactVid, contactEmail, auth) => Object.keys(capabilitiesConfig).reduce((accumulator, capability) => {
  switch (capability) {
    case ATTACHMENTS:
      {
        return Object.assign({}, accumulator, {
          [capability]: Object.assign({}, accumulator[capability], {
            threadId
          })
        });
      }
    case INSERT_CORE:
      {
        return Object.assign({}, accumulator, {
          [capability]: Object.assign({
            auth,
            contactVid,
            contactEmail
          }, accumulator[capability])
        });
      }
    case INSERT_TEMPLATES:
      {
        return Object.assign({}, accumulator, {
          [capability]: Object.assign({
            auth,
            contactVid,
            contactEmail
          }, accumulator[capability])
        });
      }
    case MODEL_SMART_COMPOSE:
      {
        return Object.assign({}, accumulator, {
          [capability]: Object.assign({}, accumulator[capability], {
            auth
          })
        });
      }
    default:
      return accumulator;
  }
}, capabilitiesConfig);