import http from 'conversations-http/clients/http';
/**
 * This function will take thread context via threadId and conversationId, and
 * timestamp to mark the message for that timestamp as read by the current agent/responder.
 *
 * Note that cvCrmObjectId should always be provided, however during CRM outages
 * may be unavailable so it can be optional. It's not a required param, it saves
 * the BE from needing to fetch it themselves and is a perf optimization.
 */
export function markLastMessageReadByAgent({
  cvCrmObjectId,
  inboxId,
  portalId,
  threadId,
  timestamp
}) {
  return http.post(`messages/v3/agent-read-threads/${threadId}`, {
    query: {
      portalId
    },
    data: {
      cvCrmObjectId,
      inboxId,
      messageTimestamp: timestamp
    }
  });
}