import { combineReducers } from 'redux';
import contacts from './contacts';
import currentOwner from './currentOwner';
import ticketSources from './ticketSources';
import ownerByUserId from './ownerByUserId';
import ticketData from '../../crm-integration/reducers/ticketData';
import deliveryIdentifiers from './deliveryIdentifiers';
const crmIntegration = combineReducers({
  contacts,
  currentOwner,
  ownerByUserId,
  ticketData,
  ticketSources,
  deliveryIdentifiers
});
export default crmIntegration;