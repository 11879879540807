export function viewSLAConfigModal() {
  return {
    key: 'view-sla-config-modal',
    properties: {
      action: 'viewed configure SLAs modal'
    }
  };
}
export function clickSetupSLAs() {
  return {
    key: 'click-set-up-slas',
    properties: {
      action: 'clicked to configure SLAs'
    }
  };
}
export function clickDismissSLAConfigModal() {
  return {
    key: 'dismissed-sla-config-modal',
    properties: {
      action: 'clicked to dismiss the SLA configuration modal'
    }
  };
}
export function viewInboxSLATourPopover() {
  return {
    key: 'view-inbox-sla-tour-popover',
    properties: {
      action: 'viewed Inbox SLA tour'
    }
  };
}
export function dismissInboxSLATourPopover() {
  return {
    key: 'dismiss-inbox-sla-tour-popover',
    properties: {
      action: 'clicked to dismiss Inbox SLA tour'
    }
  };
}