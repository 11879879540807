import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { FETCH_OWNER_BY_USER_ID } from '../constants/asyncActionTypes';
import { limitToCount } from 'conversations-async-data/indexed-async-data/eviction-strategies/limitToCount';
const OWNER_LIMIT = 25;
const initialState = new IndexedAsyncData({
  evict: limitToCount(OWNER_LIMIT),
  name: 'ticketData',
  notSetValue: new AsyncData({
    data: null
  })
});
export default handleActions({
  [FETCH_OWNER_BY_USER_ID.STARTED]: (state, action) => {
    const {
      userId
    } = action.payload.requestArgs;
    return updateEntry(userId, requestStarted, state);
  },
  [FETCH_OWNER_BY_USER_ID.SUCCEEDED](state, action) {
    const {
      data,
      requestArgs
    } = action.payload;
    const {
      userId
    } = requestArgs;
    return updateEntry(userId, requestSucceededWithOperator(() => data.ownerId), state);
  },
  [FETCH_OWNER_BY_USER_ID.FAILED](state, action) {
    const {
      userId
    } = action.payload.requestArgs;
    return updateEntry(userId, requestFailed, state);
  }
}, initialState);