import get from 'hs-lodash/get';
import I18n from 'I18n';
import { EMPTY_VALUE } from '../constants/emptyValues';
/**
 * Replaces nullable value with given `defaultValue`
 */
export const replaceIfNull = (mayBeNull, defaultValue = '') => mayBeNull === null ? defaultValue : mayBeNull;

/**
 * Returns a value of property of CRM object or given `defaultValue`
 */
export const getPropertyValue = (obj, fieldName, defaultValue = EMPTY_VALUE) => replaceIfNull(get(obj, [fieldName, 'value'], null), defaultValue);

/**
 * Returns a value of first not empty field or `defaultValue`
 */
export const getFirstNotEmptyValue = (obj, fieldNames, defaultValue = EMPTY_VALUE) => {
  let value;
  for (const fieldName of fieldNames) {
    value = getPropertyValue(obj, fieldName, defaultValue);
    if (value !== defaultValue) break;
  }
  return value;
};

/**
 * Returns date as MM/DD/YYYY or given `defaultValue`
 */
export const getDatePropertyValue = (obj, fieldName, defaultValue = EMPTY_VALUE) => {
  const dateTs = getPropertyValue(obj, fieldName, '');
  const parsedDateTs = parseInt(dateTs, 10);
  return parsedDateTs ? I18n.moment(parsedDateTs).format('L') : defaultValue;
};
/**
 * Returns the label of the pipeline with given `pipelineId`
 * If pipeline was not found then returns given `pipelineId`
 * Else if `pipelineId` is falsy then returns given `defaultValue`
 */
export const getPipelineData = ({
  pipelines,
  pipelineId,
  stageId,
  defaultValue = EMPTY_VALUE
}) => {
  const pipeline = pipelines.find(item => item.pipelineId === pipelineId);
  const stages = get(pipeline, ['stages'], []);
  const stage = stages.find(item => item.stageId === stageId);
  return {
    label: get(pipeline, ['label'], pipelineId || defaultValue),
    stage: get(stage, 'label', stageId || defaultValue)
  };
};
export const createIsEmpty = emptyValue => value => value === emptyValue;
export const isEmpty = createIsEmpty(EMPTY_VALUE);