import { getFullUrl } from 'hubspot-url-utils';
const baseHubSpotUrl = getFullUrl('app');
export const getSnippetsUrl = portalId => {
  return `${baseHubSpotUrl}/snippets/${portalId}`;
};
export const getNewSnippetsUrl = portalId => {
  return `${baseHubSpotUrl}/snippets/${portalId}/new`;
};
export const getDocumentsUrl = portalId => {
  return `${baseHubSpotUrl}/presentations/${portalId}`;
};
export const getKnowledgeUrl = (portalId, knowledgeBaseId) => {
  const url = `${baseHubSpotUrl}/knowledge/${portalId}`;
  return knowledgeBaseId ? `${url}/${knowledgeBaseId}` : url;
};
export const getMeetingsUrl = portalId => `${baseHubSpotUrl}/meetings/${portalId}`;