import count from 'transmute/count';
export const getAppliedFilter = ({
  filterType,
  filterValue,
  filterCount,
  filterResultsCount
}) => ({
  key: 'applied-filter',
  properties: {
    action: 'applied filter',
    'filter-type': filterType,
    'filter-value': filterValue,
    'filter-count': filterCount,
    'filter-results-count': filterResultsCount
  }
});
export const getClickedClearFilter = () => ({
  key: 'cleared-filter',
  properties: {
    action: 'cleared filter'
  }
});
export const getChangedInbox = ({
  inboxName
}) => ({
  key: 'changed-inbox',
  properties: {
    action: 'changed inbox',
    'inbox-name': inboxName.toLowerCase()
  }
});
export const clickToChangeInbox = {
  key: 'click-to-change-inbox',
  properties: {
    action: 'click to change inbox',
    subscreen: null
  }
};
export const getChangedView = ({
  view
}) => ({
  key: 'changed-view',
  properties: {
    action: 'changed view',
    view
  }
});
export const getClickedAlertBannerCta = ({
  ctaAction
}) => ({
  key: 'clicked-alert-banner-cta',
  properties: {
    action: 'clicked alert banner cta',
    'cta-action': ctaAction
  }
});
export const getClickedSearch = () => ({
  key: 'clicked-search',
  properties: {
    action: 'clicked search'
  }
});
export const getSearched = ({
  searchTerm
}) => ({
  key: 'searched',
  properties: {
    action: 'searched',
    isEmailSearch: searchTerm.includes('@')
  }
});
export const getEnterClickedInSearch = () => ({
  key: 'enter-key-pressed-in-search',
  properties: {
    action: 'Enter Key pressed in search'
  }
});
export const getClickedAssigneeWithSearchFilter = searchTerm => ({
  key: 'clicked-assignee-with-search',
  properties: {
    action: 'clicked assignee with search',
    'search-term': searchTerm
  }
});
export const getCheckedAssigneeDeactivated = () => ({
  key: 'checked-assignee-deactivated-checkbox',
  properties: {
    action: 'checked deactivated user assignee checkbox'
  }
});
export const getClickedContactWithSearchFilter = searchTerm => ({
  key: 'clicked-contact-with-search',
  properties: {
    action: 'clicked contact with search',
    'search-term': searchTerm
  }
});
export const clickedInboxSettings = {
  key: 'clicked-inbox-settings',
  properties: {
    action: 'clicked inbox settings',
    setting: 'manage inboxes'
  }
};
export const getClickedModalCta = ({
  ctaAction,
  subscreen2 = null
}) => ({
  key: 'clicked-modal-cta',
  properties: {
    action: 'clicked modal cta',
    'cta-action': ctaAction,
    subscreen2
  }
});
export const clickedToFilter = {
  key: 'clicked-to-filter',
  properties: {
    action: 'clicked to filter'
  }
};
export const getLoadMoreThreads = ({
  threadListMembers
}) => ({
  key: 'load-more-threads',
  properties: {
    action: 'load more threads',
    'conversation-count': count(threadListMembers)
  }
});
export const getOpenedThread = ({
  isFiltering = false,
  isSearching = false,
  index,
  channel,
  hasAttachedSLA = false
}) => ({
  key: 'opened-thread',
  properties: {
    action: 'opened thread',
    index,
    isSearching,
    isFiltering,
    channel,
    hasAttachedSLA
  }
});
export const getToggledAwayMode = ({
  awayMode
}) => ({
  key: 'toggled-away-mode',
  properties: {
    action: 'toggled away mode',
    value: awayMode ? 'on' : 'off'
  }
});
export const replyEditorResized = ({
  newHeight,
  windowHeight
}) => ({
  key: 'reply-editor-resized',
  properties: {
    action: 'reply editor resized',
    windowHeight,
    newHeight
  }
});
export const getClickedExitSearch = () => ({
  key: 'clicked-exit-search-button',
  properties: {
    action: 'exit search'
  }
});
export const getClickedStatusToggle = ({
  status
}) => ({
  key: 'clicked-status-toggle',
  properties: {
    action: 'clicked status toggle',
    status
  }
});
export const getSorted = ({
  sortOrder,
  isSentView
}) => ({
  key: 'sorted',
  properties: {
    action: 'sorted',
    'sort-order': sortOrder,
    'sort-by': isSentView ? 'Last Message Sent' : 'Last Message Received'
  }
});
export const getViewRecord = ({
  recordType
}) => ({
  key: 'view-record',
  properties: {
    action: 'view record',
    'record-type': recordType
  }
});
export const getClickedRefreshInboxButton = () => ({
  key: 'clicked-refresh-inbox-button',
  properties: {
    action: 'clicked refresh inbox button'
  }
});
export const getClosedRefreshInboxPrompt = () => ({
  key: 'closed-refresh-inbox-prompt',
  properties: {
    action: 'closed refresh inbox banner'
  }
});
export const getShowRefreshInboxPrompt = () => ({
  key: 'show-refresh-inbox-prompt',
  properties: {
    action: 'show refresh inbox banner'
  }
});
export const getCustomViewDragAndDrop = () => ({
  key: 'changed-custom-view-order',
  properties: {
    action: 'custom view order changed via drag and drop'
  }
});