'use es6';

import { List } from 'immutable';
import memoize from 'transmute/memoize';
import { getPubSubMessage } from '../../unpublished-messages/operators/getPubSubMessage';
import { fileAttachmentIdsInMessage } from '../../common-message-format/operators/fileAttachmentIdsInMessage';
import { getFileUsageTypesInMessage } from '../../common-message-format/operators/getFileUsageTypesInMessage';
export const getAttachmentsForMessage = memoize((message, fileAttachments) => {
  message = getPubSubMessage(message);
  const fileUsageTypes = getFileUsageTypesInMessage(message);
  return fileAttachmentIdsInMessage(message).reduce((updateList, fileId) => {
    if (fileAttachments.has(`${fileId}`)) {
      const fileAttachment = fileAttachments.get(`${fileId}`);
      const fileUsageType = fileUsageTypes.get(`${fileId}`);
      let resolvedFileAttachment = fileAttachment && fileAttachment.set('fileId', fileId);
      if (fileUsageType) {
        resolvedFileAttachment = resolvedFileAttachment && resolvedFileAttachment.set('fileUsageType', fileUsageType);
      }
      return updateList.push(resolvedFileAttachment);
    }
    return updateList;
  }, List());
});