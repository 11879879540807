import { useReduxAsyncFetcher } from 'conversations-async-data/async-data/hooks/useReduxAsyncFetcher';
import { useCallback } from 'react';
import { fetchThreadVisitorIdentification, NOOP_ACTION } from '../actions/fetchThreadVisitorIdentification';
import { getIdentificationAsyncData } from '../selectors/visitorIdentificationGetters';
import { isSucceeded } from 'conversations-async-data/async-data/operators/statusComparators';
import { getIsIdentifiedOperator } from '../../visitor-identification/selectors/visitorIdentificationGetters';
import { getAllEmails } from 'conversations-inbox-lib/contacts/public/operators';
export const useVisitorIdentification = ({
  threadId,
  contact
}) => {
  const emails = getAllEmails(contact);
  const fetchTheadVisitorIdentificationAction = useCallback(() => {
    if (emails && emails.length) {
      return fetchThreadVisitorIdentification({
        emails,
        threadId
      });
    }
    return NOOP_ACTION;
  }, [emails, threadId]);
  const {
    asyncData
  } = useReduxAsyncFetcher({
    action: fetchTheadVisitorIdentificationAction,
    selector: state => getIdentificationAsyncData(state, {
      threadId
    }),
    id: threadId,
    idTransform: id => ({
      threadId: id
    })
  });
  const isIdentified = isSucceeded(asyncData) && getIsIdentifiedOperator(asyncData);
  return {
    isIdentified,
    visitorIdentificationAsyncData: asyncData
  };
};