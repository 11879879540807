import Filters from '../models/Filters';
import Track from '../tracking/ZorseTicketInspectorTracker';
import { CLEAR_TICKETS, SEARCH_MORE_TICKETS, SEARCH_TICKETS } from './ActionTypes';
import { searchTickets as searchTicketsAjax, searchMoreTickets as searchMoreTicketsAjax } from '../api/ZorseTicketInspectorApi';
export function clearTickets() {
  return dispatch => dispatch({
    type: CLEAR_TICKETS
  });
}
export function searchTickets(searchTerms, filters = new Filters(), source) {
  return dispatch => {
    Track.ticketsSearchInitiated(source);
    dispatch({
      type: SEARCH_TICKETS,
      meta: 'request'
    });
    return searchTicketsAjax(searchTerms, filters).then(payload => {
      Track.ticketsSearchSucceeded(source);
      return dispatch({
        type: SEARCH_TICKETS,
        meta: 'response',
        payload
      });
    }, payload => dispatch({
      type: SEARCH_TICKETS,
      error: true,
      payload
    }));
  };
}
export function searchMoreTickets(searchTerms, lastId, filters = new Filters()) {
  return dispatch => {
    Track.moreTicketsFetchInitiated();
    dispatch({
      type: SEARCH_MORE_TICKETS,
      meta: 'request'
    });
    return searchMoreTicketsAjax(searchTerms, filters, lastId).then(payload => {
      Track.moreTicketsFetchSucceeded();
      return dispatch({
        type: SEARCH_MORE_TICKETS,
        meta: 'response',
        payload
      });
    }, payload => dispatch({
      type: SEARCH_MORE_TICKETS,
      error: true,
      payload
    }));
  };
}