import updateChatFiltersByFilterKey from '../operators/updateChatFiltersByFilterKey';
import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_CHAT_FILTERS } from '../constants/defaultChatFilters';
import { getSearch } from '../operators/chatFilterGetters';
import { setSearch } from '../operators/chatFilterSetters';
const initialState = {
  data: DEFAULT_CHAT_FILTERS
};
const filterValues = createSlice({
  name: 'filterValues',
  initialState,
  reducers: {
    clearFilterValues(state) {
      const searchQuery = getSearch(state.data);
      state.data = setSearch(searchQuery, initialState.data);
    },
    clearSearchValue(state) {
      const defaultSearchText = getSearch(initialState.data);
      state.data = setSearch(defaultSearchText, state.data);
    },
    clearSearchAndFilterValues() {
      return initialState;
    },
    setFilterValue(state, {
      payload: {
        filterKey,
        filterValue
      }
    }) {
      state.data = updateChatFiltersByFilterKey(filterKey, filterValue, state.data);
    }
  }
});
export const {
  actions,
  getInitialState
} = filterValues;
export default filterValues.reducer;