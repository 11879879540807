'use es6';

import pipe from 'transmute/pipe';
import LegacyRecipient from 'conversations-message-history/legacy-recipients/records/LegacyRecipient';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { getAgentRecipients } from '../clients/getAgentRecipients';
import { RECIPIENTS_FETCH } from '../constants/asyncActionTypes';
import { indexRecipients } from '../operators/indexRecipients';
export const fetchAgentRecipients = createAsyncAction({
  requestFn: getAgentRecipients,
  actionTypes: RECIPIENTS_FETCH,
  toRecordFn: pipe(recipientsObjArr => recipientsObjArr.map(LegacyRecipient), indexRecipients)
});