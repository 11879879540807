import { List, Record } from 'immutable';
import Ticket from './Ticket';
const TicketsPage = Record({
  hasMore: false,
  lastId: 0,
  tickets: List()
}, 'TicketsPage');
TicketsPage.fromJS = obj => new TicketsPage({
  hasMore: obj.hasMore,
  lastId: obj.lastId,
  tickets: List(obj.results.map(Ticket.fromJS))
});
TicketsPage.concat = (prevPage, nextPage) => new TicketsPage({
  hasMore: nextPage.hasMore,
  lastId: nextPage.lastId,
  tickets: prevPage.tickets.concat(nextPage.tickets)
});
export default TicketsPage;