import isBoolean from 'hs-lodash/isBoolean';
// TODO: move to composer-data
import invariant from 'conversations-thread-data/utils/invariant';
export const fbmReplyLimit = ({
  hasReachedFbmLimit
}) => {
  invariant(isBoolean(hasReachedFbmLimit), 'hasReachedFbmLimit is required for fbmReplyLimit disabling strategy');
  return hasReachedFbmLimit ? {
    disabled: true,
    sendDisabledReason: 'FACEBOOK_LIMIT_REACHED'
  } : null;
};