import { createSelector } from 'reselect';
import { makeGetUnpublishedMessagesForThreadId } from './getUnpublishedMessagesForThreadId';
import some from 'transmute/some';
import { isFailedToPublish } from '../operators/isFailedToPublish';
import { getLatestMessageFailed, getThreadId } from 'find-and-filter-data/view-members-schema/protected';
export const makeGetHasFailedUnpublishedMessagesForThreadId = () => createSelector([makeGetUnpublishedMessagesForThreadId()], some(isFailedToPublish));
const getThreadListMemberFromProps = (_, {
  threadListMember
}) => threadListMember;
export const makeGetHasFailedUnpublishedMessagesForThreadListMember = () => {
  const getHasFailedUnpublishedMessagesForThreadId = makeGetHasFailedUnpublishedMessagesForThreadId();
  return (state, {
    threadListMember
  }) =>
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
  getHasFailedUnpublishedMessagesForThreadId(state, {
    threadId: getThreadId(threadListMember)
  });
};
export const makeGetLatestMessageFailedForPropThreadListMember = () => createSelector([getThreadListMemberFromProps], threadListMember => getLatestMessageFailed(threadListMember));
export const makeGetPublishFailedForThreadListMember = () => createSelector([makeGetHasFailedUnpublishedMessagesForThreadListMember(), makeGetLatestMessageFailedForPropThreadListMember()], (hasFailedUnpublishedMessages, latestMessageFailed) => hasFailedUnpublishedMessages || latestMessageFailed);