import { PAGE_NAVIGATION } from '../constants/MethodNames';
import { callingAPIMethodWithQueue } from '../utils/hsCallingUtils';
import updateThirdPartySubject from './updateThirdPartySubject';
import { logError } from '../utils/logError';
export default function callingHostPageNavigation(subject) {
  const objectId = subject ? Number(subject.subjectId) : 0;
  const objectTypeId = subject ? subject.objectTypeId : '';
  updateThirdPartySubject({
    objectId,
    objectTypeId
  }).catch(() => {
    logError(new Error(`Unable to update third party subject`), {
      extra: {
        objectId,
        objectTypeId
      }
    });
  });
  return callingAPIMethodWithQueue(PAGE_NAVIGATION, subject);
}