import { List } from 'immutable';
import PropertiesMetaRecord from '../records/PropertiesMetaRecord';
import { FETCH_PROPERTIES_META } from '../actions/ActionTypes';
const processLoadPropertiesMeta = (state, action) => {
  if (action.meta === 'request') {
    return state.set('loading', true);
  } else if (action.meta === 'response') {
    return new PropertiesMetaRecord({
      funnelOptions: List(action.payload.funnelOptions),
      productInterestOptions: List(action.payload.productInterestOptions),
      tierFitOptions: List(action.payload.tierFitOptions)
    });
  }
  return state.set('loading', false);
};
const propertiesMeta = (state = new PropertiesMetaRecord(), action) => {
  switch (action.type) {
    case FETCH_PROPERTIES_META:
      return processLoadPropertiesMeta(state, action);
    default:
      return state;
  }
};
export default propertiesMeta;