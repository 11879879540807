import { useCallback, useState } from 'react';
import { UNINITIALIZED } from '../constants/AsyncStatuses';
export function useAsyncData(defaultData) {
  const [status, setStatus] = useState(UNINITIALIZED);
  const [data, setData] = useState(defaultData);
  const [error, setError] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);
  const updateAsyncData = useCallback(updates => {
    if ('data' in updates && updates.data !== undefined) setData(updates.data);
    if ('error' in updates && updates.error !== undefined) setError(updates.error);
    if ('status' in updates && updates.status !== undefined) setStatus(updates.status);
    if ('updatedAt' in updates && updates.updatedAt !== undefined) {
      setUpdatedAt(updates.updatedAt);
    } else {
      setUpdatedAt(Date.now());
    }
  }, []);
  return {
    data,
    error,
    status,
    updatedAt,
    updateAsyncData
  };
}