import { SMS_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
import { useBillingLimitData } from './useBillingLimitData';
export const useBillingLimits = ({
  currentEditorKey
}) => {
  const isBillingLimitChannel = currentEditorKey === SMS_GENERIC_CHANNEL_ID;
  const {
    billingLimitData
  } = useBillingLimitData({
    skip: !isBillingLimitChannel
  });
  const usagePercent = billingLimitData ? billingLimitData.usage / billingLimitData.limit * 100 : 0;
  return {
    billingLimitData,
    billingLimitStatus: billingLimitData === null || billingLimitData === void 0 ? void 0 : billingLimitData.limitStatus,
    usagePercent
  };
};