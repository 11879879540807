module.exports = {
  "realtimeRepsEscalationsPanelInteraction": {
    "name": "Escalations panel interaction",
    "class": "interaction",
    "properties": {
      "conversation_id": {
        "type": "string",
        "isOptional": true
      },
      "ticket_id": {
        "type": "string",
        "isOptional": true
      },
      "action": "string",
      "applicationName": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "zorse-crm-components",
    "version": "1"
  },
  "realtimeRepsEscalationsPanelUsage": {
    "name": "Escalations panel usage",
    "class": "usage",
    "properties": {
      "conversationId": {
        "type": "number",
        "isOptional": true
      },
      "escalatedTo": {
        "type": [
          "ACCOUNT_MANAGER",
          "BILLING",
          "DELIVERABILITY",
          "PAYMENT",
          "PRIVACY",
          "SECURITY"
        ],
        "isOptional": true
      },
      "escalationReason": {
        "type": "number",
        "isOptional": true
      },
      "ticketId": "number",
      "action": "string",
      "applicationName": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "zorse-crm-components",
    "version": "1"
  },
  "zorseCrmComponentsInteraction": {
    "name": "Zorse CRM Components interaction",
    "class": "interaction",
    "namespace": "zorse-crm-components",
    "version": "1",
    "properties": {
      "action": "string",
      "applicationName": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "realtimeRepsConversationPropertiesInteraction": {
    "namespace": "realtime-reps",
    "name": "Conversation Properties Interaction",
    "class": "interaction",
    "version": "1",
    "properties": {
      "action": "string",
      "applicationName": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "realtimeRepsConversationPropertiesUsage": {
    "namespace": "realtime-reps",
    "name": "Conversation Properties Usage",
    "class": "usage",
    "properties": {
      "thread_id": {
        "type": "string",
        "isOptional": true
      },
      "property_name": {
        "type": "string",
        "isOptional": true
      },
      "property_value": {
        "type": "string",
        "isOptional": true
      },
      "ghosted": {
        "type": "string",
        "isOptional": true
      },
      "properties": {
        "type": "string",
        "isOptional": true
      },
      "action": "string",
      "applicationName": {
        "type": "string",
        "isOptional": true
      }
    },
    "version": "1"
  }
};