import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"];
import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchFriendlyName } from '../clients/friendly-names';
const FIELD_NAME = 'fetchFriendlyName';
const query = registerQuery({
  fieldName: FIELD_NAME,
  args: ['messageId'],
  fetcher: fetchFriendlyName
});
export function useFriendlyName({
  messageId,
  useQueryOptions
}) {
  const _useQuery = useQuery(query, Object.assign({
      variables: {
        messageId: messageId || ''
      }
    }, useQueryOptions)),
    {
      data
    } = _useQuery,
    rest = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  return Object.assign({
    data: data && data[FIELD_NAME]
  }, rest);
}