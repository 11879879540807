import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { silenceErrorAlert } from 'conversations-error-reporting/error-actions/builders/silenceErrorAlert';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { fetchCurrentOwner as requestFn } from 'crm_data/owners/OwnersAPI';
import identity from 'transmute/identity';
import { FETCH_CURRENT_CRM_OWNER as actionTypes } from '../constants/asyncActionTypes';
export const fetchCrmContactOwners = createAsyncAction({
  requestFn,
  actionTypes,
  toRecordFn: identity,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type '{}'.
  failureMetaActionCreator: ({
    error
  }) => silenceErrorAlert({
    error
  })
});