import update from 'transmute/update';
import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { FETCH_MEMBERS_BY_VID } from '../../thread-list-member/constants/asyncActionTypes';
import { REMOVE_STALE_PAST_CONVERSATIONS } from '../constants/actionTypes';
import { getMembers } from 'find-and-filter-data/view-members-schema/protected';
const initialState = new AsyncData({
  data: null
});
const updateResults = update('indexedMembers');
export default handleActions({
  [FETCH_MEMBERS_BY_VID.STARTED](state) {
    return requestStarted(state);
  },
  [FETCH_MEMBERS_BY_VID.SUCCEEDED](state, action) {
    const {
      data
    } = action.payload;
    const currentData = getData(state);
    const updatedPaginatedResult = updateResults(incomingResults => {
      const currentMembers = getMembers(currentData);
      return currentMembers ? currentMembers.merge(incomingResults) : incomingResults;
    }, data);
    return requestSucceededWithOperator(() => updatedPaginatedResult, state);
  },
  [FETCH_MEMBERS_BY_VID.FAILED](state) {
    return requestFailed(state);
  },
  [REMOVE_STALE_PAST_CONVERSATIONS]() {
    return initialState;
  }
}, initialState);