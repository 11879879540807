import getIn from 'transmute/getIn';
import { createSelector } from 'reselect';
import { getFilterValues } from './getFilterValues';
import { selectSortOrder } from 'find-and-filter-data/search-and-filter-data/protected';
import FilterKey from '../records/FilterKey';
import { getMembers } from 'find-and-filter-data/view-members-schema/protected';
import get from 'transmute/get';
const MEMBERS = 'indexedMembers';

// @ts-expect-error getIn incorrectly yields a result of unknown
export const getIndexedAsyncFilterResults = getIn(['searchAndFiltering', 'filterResults']);
export const getFilterResults = state => {
  const searchAndFilterState = get('searchAndFiltering', state);
  return get('filterResults', searchAndFilterState);
};
export const getFilterResultsKey = createSelector([getFilterValues, selectSortOrder], (filterValues, sortOrder) => {
  return FilterKey({
    filterValues,
    sortOrder
  });
});
export const getFilterResultsThreadListPageData = createSelector([getFilterResults], filterResults => {
  return filterResults.data;
});
export const getFilterResultsMembers = createSelector([getFilterResultsThreadListPageData], filterResults => {
  return filterResults && getMembers(filterResults);
});