/* eslint '@typescript-eslint/member-ordering': [
      'warn',
      {
        default: {
          order: 'alphabetically-case-insensitive',
        },
      },
    ], */

// @ts-expect-error no types

import * as editorCapabilitiesConstants from './constants';
export function isInsertCapability(capability) {
  return capability === editorCapabilitiesConstants.DOCUMENTS || capability === editorCapabilitiesConstants.MEETINGS || capability === editorCapabilitiesConstants.SNIPPETS || capability === editorCapabilitiesConstants.QUOTES || capability === editorCapabilitiesConstants.KNOWLEDGE_ARTICLE || capability === editorCapabilitiesConstants.VIDEO_INSERTION || capability === editorCapabilitiesConstants.TEMPLATES || capability === editorCapabilitiesConstants.PAYMENT_LINK || capability === editorCapabilitiesConstants.INVOICES || capability === editorCapabilitiesConstants.SIGNATURE;
}

/**
 * Object type mapped to that object type's id. For example,
 * {
 *   CONTACT: 123, // includes a supplemental contact with id 123
 *   TICKET: 432, // includes a supplemental ticket with id 432
 * }
 */

/** Styles to apply to the token block in the editor. See fallbacks in PersonalizationTokenView. */

/**
 * A single possible token to insert into the editor.
 */

export default editorCapabilitiesConstants;