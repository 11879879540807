import { useReduxIndexedAsyncFetcher } from 'conversations-async-data/indexed-async-data/hooks/useReduxIndexedAsyncFetcher';
import { fetchEmailAddressRecords } from '../actions/fetchEmailAddressRecords';
import { getHydratedEmailAddresses } from '../selectors/getHydratedEmailAddresses';
const idsTransform = ids => ({
  emailAddresses: ids
});
export const useIndexedAsyncHydratedEmailAddresses = ({
  emailAddresses,
  deferred
}) => {
  const {
    entries
  } = useReduxIndexedAsyncFetcher({
    action: fetchEmailAddressRecords,
    selector: getHydratedEmailAddresses,
    ids: emailAddresses,
    idsTransform,
    deferred
  });
  return {
    indexedAsyncEmailAddresses: entries
  };
};