'use es6';

export const removeGmailLineBreak = domElement => {
  const emailReplies = Array.from(domElement.querySelectorAll('br + [data-email-reply]'));
  emailReplies.forEach(reply => {
    const prevElement = reply.previousElementSibling;
    if (prevElement && prevElement.nodeName && prevElement.nodeName.toLowerCase() === 'br' && prevElement.classList) {
      prevElement.classList.add('display-none');
    }
  });
};