import { useContext } from 'react';
import { PermissionsContext } from '../context/PermissionsContext';
/**
 * Returns the current logged in user
 *
 * ```tsx
 * const UserInfoCard = (props) => {
 *   const user = useAuthedUser();
 *
 *   return (
 *    <h2>{user.first_name} {user.last_name}</h2>
 *    <p>{user.email}</p>
 *   );
 * }
 * ```
 */
export function useAuthedUser() {
  const {
    user
  } = useContext(PermissionsContext);
  return user;
}