import { useReduxAsyncFetcher } from 'conversations-async-data/async-data/hooks/useReduxAsyncFetcher';
import { getMessengerConnections } from '../actions/getMessengerConnections';
import { getMessengerConnectionsAsyncData } from '../selectors/getMessengerConnectionsAsyncData';
export const useMessengerConnections = () => {
  const {
    asyncData,
    retryFailed
  } = useReduxAsyncFetcher({
    action: getMessengerConnections,
    selector: getMessengerConnectionsAsyncData
  });
  return {
    asyncMessengerConnections: asyncData,
    retryFailed
  };
};