import identity from 'transmute/identity';
import { OUT_OF_SYNC } from '../constants/asyncStatuses';
import { requestStateUpdate } from './requestStateUpdate';

/**
 * Set status when a request's data becomes out of sync.
 * This can serve as a flag for re-requesting the data.
 *
 * @param {AsyncData} asyncData AsyncData record to update
 * @returns {AsyncData}
 */
export const requestOutOfSync = requestStateUpdate(OUT_OF_SYNC, identity);