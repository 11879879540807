import { List as ImmutableList } from 'immutable';
import FileAttachment from 'conversations-message-history/common-message-format/records/FileAttachment';
import { getAttachments } from '../../public/operators/commonReplyGetters';
export const buildFileAttachmentsFromReply = ({
  commonReply
}) => {
  let commonMessageFileAttachments = ImmutableList();
  const commonReplyFileAttachments = getAttachments(commonReply);
  if (!commonReplyFileAttachments) {
    return commonMessageFileAttachments;
  }
  const fileIds = commonReplyFileAttachments.filter(attachment => {
    return !!(attachment && attachment.get('id'));
  }).map(attachment => attachment.get('id')).toList();
  if (fileIds.size) {
    commonMessageFileAttachments = commonMessageFileAttachments.push(FileAttachment({
      fileIds
    }));
  }
  return commonMessageFileAttachments;
};