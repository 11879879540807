// gimme: conversations-inbox-ui 05/26/2020 741c62dc71ca742adbc6d64d61d30de7cb46539f
'use es6';

import { AGENT_SENDER } from 'conversations-message-history/common-message-format/constants/cmfSenderTypes';
import { getAgentToVisitorChannelName, getThreadId } from 'conversations-thread-data/thread-details/public/operators';
import { getUserId } from 'conversations-thread-data/auth/public/selectors';
import { getFocusedThreadDetails } from '../../thread-details/selectors/getFocusedThreadDetails';
import TypingIndicatorMessage from 'conversations-message-history/typing-indicator/records/TypingIndicatorMessage';
import { generateUuid } from 'conversations-message-history/util/generateUuid';
import { getSenderKeyFromType } from 'conversations-message-history/common-message-format/operators/getSenderKeyFromType';
import { isGenericChannelId } from 'conversations-thread-data/channel-configuration/public/types';
import { publishMessageWithErrorHandling } from '../../publish-message-feature/public/actions/publishMessageWithErrorHandling';
export const publishTypingMessage = editorConfigurationKey => (dispatch, getState) => {
  const senderId = getUserId(getState());
  const threadDetails = getFocusedThreadDetails(getState());
  const threadId = getThreadId(threadDetails);
  const channel = getAgentToVisitorChannelName(threadDetails);
  const senderType = AGENT_SENDER;
  const senderTypeKey = getSenderKeyFromType(senderType);
  const message = new TypingIndicatorMessage({
    id: generateUuid(),
    typingState: null,
    sender: {
      '@type': senderType,
      [senderTypeKey]: senderId
    },
    genericChannelId: isGenericChannelId(editorConfigurationKey) ? editorConfigurationKey : null
  });
  dispatch(publishMessageWithErrorHandling({
    channel,
    message,
    transient: true,
    threadId
  }));
};