'use es6';

import { removeOutlookLineBreaks } from './removeOutlookLineBreaks';
import { removeReply } from './removeReply';
import { removeSignature } from './removeSignature';
import { removeStyles } from './removeStyles';
import { fixImages } from './fixImages';
import { replaceGmailDivs } from './replaceGmailDivs';
import { secureDocument } from './secureDocument';
export const sanitizeConfig = {
  transformers: [removeOutlookLineBreaks, removeReply, removeSignature, removeStyles, replaceGmailDivs, fixImages],
  dom: secureDocument,
  elements: ['b', 'blockquote', 'br', 'caption', 'cite', 'code', 'col', 'colgroup', 'dd', 'dl', 'dt', 'em', 'figure', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'i', 'img', 'li', 'ol', 'p', 'pre', 'q', 'small', 'strike', 'strong', 'sub', 'sup', 'table', 'tbody', 'td', 'tfoot', 'th', 'thead', 'tr', 'u', 'ul', 'font', 'div', 'span', 'a'],
  attributes: {
    __ALL__: ['style', 'align', 'valign', 'width', 'height'],
    blockquote: ['cite'],
    col: ['span'],
    colgroup: ['span'],
    figure: ['style'],
    ol: ['start', 'type'],
    q: ['cite'],
    img: ['title', 'alt', 'src'],
    table: ['summary', 'bgcolor'],
    td: ['abbr', 'axis', 'colspan', 'rowspan', 'bgcolor'],
    th: ['abbr', 'axis', 'colspan', 'rowspan', 'scope', 'bgcolor'],
    ul: ['type'],
    font: ['size'],
    a: ['href', 'title'],
    span: ['data-at-mention', 'data-owner-id', 'contenteditable', 'data-email-reply']
  },
  add_attributes: {
    a: {
      target: '_blank'
    }
  },
  remove_contents: ['script'],
  protocols: {
    a: {
      href: ['http', 'https']
    }
  }
};