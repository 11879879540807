import { AGENT, BOT, SYSTEM, VISITOR, INTEGRATOR } from '../../common-message-format/constants/legacySenderTypes';
import SenderLocator from '../records/SenderLocator';
const senderTypes = [AGENT, BOT, SYSTEM, VISITOR, INTEGRATOR];
export const buildSenderLocator = (senderType, senderId) => {
  if (!senderTypes.includes(senderType)) {
    // eslint-disable-next-line
    console.warn(`buildSenderLocator received invalid sender type ${senderType} expected one of: ${senderTypes}`);
  }
  return SenderLocator({
    senderType,
    senderId: senderId ? `${senderId}` : null
  });
};