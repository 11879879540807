import { List } from 'immutable';
import memoize from 'hs-lodash/memoize';

// @ts-expect-error module not typed
import { getTimestamp } from '../../common-message-format/operators/commonMessageFormatGetters';
// @ts-expect-error module not typed
import DateMessage from '../records/DateMessage';
// @ts-expect-error module not typed
import { shouldRenderDate } from './shouldRenderDate';
// @ts-expect-error module not typed
import { getMessageId } from '../../message-history/operators/getMessageId';
const buildDateMessage = memoize(DateMessage, ({
  id
}) => id);

/**
 * @description Strategically inserts date messages into a list
 * of history messages, optionally filtering out messages based on
 * the `messageFilter` function that can be passed in.
 */
export const insertDateMessages = (messageList, displayInitialMessageDate = true, messageFilter = () => true) => {
  return messageList.reduce((messagesWithDates, message, index) => {
    const previousMessage = index > 0 ? messageList.get(index - 1) : undefined;
    if (shouldRenderDate(message, previousMessage, displayInitialMessageDate) && messageFilter(message)) {
      messagesWithDates = messagesWithDates.push(buildDateMessage({
        id: `${getMessageId(message)}-date`,
        timestamp: getTimestamp(message)
      }));
    }
    return messagesWithDates.push(message);
  }, List());
};