/**
 * @description Returns whether the given view has unread threads
 */
export function isViewUnread(viewId, unreadThreadCount, unreadCountOffsets, trackedThreads) {
  /**
   * Find out if the user has read all the unread threads. If user has read all
   * unread threads unreadViewCount will be 0
   */
  const offset = unreadCountOffsets.get(viewId, 0);
  const hasNotReadAllUnreadThreads = unreadThreadCount !== null && unreadThreadCount + offset > 0;

  /**
   * Find any unread tracked threads for the view
   */
  const viewUnreadTrackedThreads = trackedThreads.get(viewId);
  const hasUnreadTrackedThreads = viewUnreadTrackedThreads ? viewUnreadTrackedThreads.size > 0 : false;

  /**
   * The view has unread threads if user hasn't read all unread threads or we
   * have tracked unread threads for that view
   */
  return hasNotReadAllUnreadThreads || hasUnreadTrackedThreads;
}