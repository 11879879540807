import set from 'transmute/set';
import { ASSIGNED_TO_BOT, HIDE_FILTERED, STATUS, ASSIGNEE, START_TIME, END_TIME, DATE_TYPE, UNASSIGNED, VID, SEARCH, HIDE_TRASH, GENERIC_CHANNEL, CHANNEL_INSTANCE_ID } from './FilterKeys';
export const setAssignedToBot = set(ASSIGNED_TO_BOT);
export const setChannelInstanceId = set(CHANNEL_INSTANCE_ID);
export const setHideFiltered = set(HIDE_FILTERED);
export const setStatus = set(STATUS);
export const setGenericChannel = set(GENERIC_CHANNEL);
export const setAssignee = set(ASSIGNEE);
export const setStartTime = set(START_TIME);
export const setEndTime = set(END_TIME);
export const setDateType = set(DATE_TYPE);
export const setUnassigned = set(UNASSIGNED);
export const setVid = set(VID);
export const setSearch = set(SEARCH);
export const setHideTrash = set(HIDE_TRASH);