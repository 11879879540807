import { getSourceObjectTypeId, getOriginalGenericChannelId, getAgentTimeToReply } from 'conversations-thread-data/thread-details/public/operators';
// @ts-expect-error module not typed - TODO: move to composer-ui
import getPortalId from 'conversations-thread-view/common/selectors/getPortalId';
import { getMessagePublishSourceByObjectId } from 'conversations-thread-data/message-publishing/public/operators';
import { getTrackingContext, trackEvent } from '../../../usage-tracking/clients/usageTracker';
import { clickedToSendEmail } from '../../../usage-tracking/events/editorInteractions';
// @ts-expect-error module not typed - TODO: move to composer-ui
import { getChannelSwitch } from 'conversations-thread-view/thread-histories/constants/Event';
import { getGenericChannelId } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { EMAIL_GENERIC_CHANNEL_ID, FB_MESSENGER_GENERIC_CHANNEL_ID, LIVE_CHAT_GENERIC_CHANNEL_ID, WHATSAPP_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
import { getDidChannelSwitch } from 'conversations-thread-data/thread-history/public/operators';
import { useSelector } from 'react-redux';
// TODO: move to composer-ui
import { getLastCMFGenericChannelIdFromThreadHistory } from 'conversations-thread-view/thread-histories/selectors/getLastCMFGenericChannelIdFromThreadHistory';
import { getMentionUserIds } from 'conversations-thread-data/common-reply-metadata/public/operators/getMentionUserIds';
import { getSentMessageChatActivationPortal, getSentMessageChatActivationUser, getSentMessageFBMActivationPortal, getSentMessageFBMActivationUser, getSentMessageWhatsAppActivationPortal, getSentMessageWhatsAppActivationUser
// TODO: move to composer-ui
} from 'conversations-thread-view/usage-tracking/utils/publishMessage';
import { getAddedComment, getSentMessage
// TODO: move to composer-ui
} from 'conversations-thread-view/usage-tracking/utils/threadInteraction';
export const useTrackMessagePublishing = props => {
  const {
    threadDetails
  } = props;
  // casting due to incorrect typing of transmute
  const sourceObjectTypeId = getSourceObjectTypeId(threadDetails);
  const source = getMessagePublishSourceByObjectId(sourceObjectTypeId);
  const numberOfTicketsAssociated = getTrackingContext().ticketCount;
  const timeToReply = getAgentTimeToReply(threadDetails);
  const originalGenericChannelId = getOriginalGenericChannelId(threadDetails);
  const lastestPublishedCommonMessageGenericChannelId = useSelector(getLastCMFGenericChannelIdFromThreadHistory);
  const portalId = useSelector(getPortalId);
  const trackComment = message => {
    const mentionUserIds = getMentionUserIds(message);
    trackEvent(getAddedComment({
      atMentionsCount: mentionUserIds.size
    }));
  };
  const trackMessage = (commonMessage, opts) => {
    const {
      communicatorType,
      replyType
    } = opts;
    const messageChannelId = getGenericChannelId(commonMessage);
    trackEvent(getSentMessage({
      communicatorType,
      replyType,
      source,
      /*  @ts-expect-error Jun-10-2024, 18:11UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
      genericChannelId: originalGenericChannelId,
      messageChannel: messageChannelId,
      numberOfTicketsAssociated,
      timeToReply
    }));
    const shouldTrackForChannelSwitching = getDidChannelSwitch(commonMessage, lastestPublishedCommonMessageGenericChannelId);
    if (shouldTrackForChannelSwitching) {
      trackEvent(getChannelSwitch({
        nextGenericChannelId: messageChannelId,
        currentGenericChannelId: lastestPublishedCommonMessageGenericChannelId
      }));
    }
    switch (messageChannelId) {
      case FB_MESSENGER_GENERIC_CHANNEL_ID:
        {
          trackEvent(getSentMessageFBMActivationUser({
            communicatorType
          }));
          trackEvent(getSentMessageFBMActivationPortal({
            communicatorType,
            portalId
          }));
          break;
        }
      case LIVE_CHAT_GENERIC_CHANNEL_ID:
        {
          trackEvent(getSentMessageChatActivationUser({
            communicatorType
          }));
          trackEvent(getSentMessageChatActivationPortal({
            communicatorType,
            portalId
          }));
          break;
        }
      case EMAIL_GENERIC_CHANNEL_ID:
        {
          trackEvent(clickedToSendEmail());
          break;
        }
      case WHATSAPP_GENERIC_CHANNEL_ID:
        {
          trackEvent(getSentMessageWhatsAppActivationUser({
            communicatorType
          }));
          trackEvent(getSentMessageWhatsAppActivationPortal({
            communicatorType,
            portalId
          }));
          break;
        }
      default:
        break;
    }
  };
  return {
    trackComment,
    trackMessage
  };
};