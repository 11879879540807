import setIn from 'transmute/setIn';
import { CHILD_TEAMS } from '../../team/constants/keyPaths';
import { getChildTeams, getId, getNumRoutableUsers } from '../../team/operators/getters';
const canRouteToTeam = (team, visibleTeams) => {
  const routableUsers = getNumRoutableUsers(team);
  return visibleTeams.has(getId(team)) && typeof routableUsers === 'number' && routableUsers > 0;
};
const removeUnroutableChildTeams = (team, visibleTeams) => {
  const childTeams = getChildTeams(team);
  if (!childTeams) {
    return team;
  }

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const updatedChildTeams = filterUnroutableTeams(childTeams, visibleTeams);
  return setIn(CHILD_TEAMS, updatedChildTeams, team);
};
export const filterUnroutableTeams = (hierarchy, visibleTeams) => {
  return hierarchy.filter(team => canRouteToTeam(team, visibleTeams)).map(team => removeUnroutableChildTeams(team, visibleTeams));
};