'use es6';

function createNotification({
  type,
  titleText,
  message,
  sticky = false
}) {
  return {
    type,
    titleText,
    message,
    sticky
  };
}
export function createDangerNotification(titleText, message, sticky) {
  return createNotification({
    type: 'danger',
    titleText,
    message,
    sticky
  });
}
export function createInfoNotification(titleText, message, sticky) {
  return createNotification({
    type: 'info',
    titleText,
    message,
    sticky
  });
}
export function createSuccessNotification(titleText, message, sticky) {
  return createNotification({
    type: 'success',
    titleText,
    message,
    sticky
  });
}
export function createTipNotification(titleText, message, sticky) {
  return createNotification({
    type: 'tip',
    titleText,
    message,
    sticky
  });
}
export function createWarningNotification(titleText, message, sticky) {
  return createNotification({
    type: 'warning',
    titleText,
    message,
    sticky
  });
}