import { Map as ImmutableMap, Record } from 'immutable';
import PropertyRecord from './PropertyRecord';
import { FUNNEL_STAGE, PRODUCT_INTEREST, TIER_FIT, FUNNEL_STAGE_OVERRIDE_VALUES } from '../constants/ConversationsProperties';
class ThreadRecord extends Record({
  ghosted: false,
  loading: false,
  properties: ImmutableMap({
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'undefined... Remove this comment to see the full error message
    [FUNNEL_STAGE]: new PropertyRecord({
      name: FUNNEL_STAGE
    }),
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'undefined... Remove this comment to see the full error message
    [PRODUCT_INTEREST]: new PropertyRecord({
      name: PRODUCT_INTEREST
    }),
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'undefined... Remove this comment to see the full error message
    [TIER_FIT]: new PropertyRecord({
      name: TIER_FIT
    })
  })
}, 'ThreadRecord') {
  hasAllRequiredProperties() {
    return this.properties.get(FUNNEL_STAGE).value && (!this.requiresProductAndTier() || this.properties.get(PRODUCT_INTEREST).value &&
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    this.properties.get(PRODUCT_INTEREST).value.length > 0 && this.properties.get(TIER_FIT).value);
  }
  requiresProductAndTier() {
    return this.properties.get(FUNNEL_STAGE) && !FUNNEL_STAGE_OVERRIDE_VALUES.includes(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
    this.properties.get(FUNNEL_STAGE).value);
  }
}
ThreadRecord.fromJS = obj => new ThreadRecord({
  properties: ImmutableMap({
    [FUNNEL_STAGE]: new PropertyRecord(Object.assign({
      name: FUNNEL_STAGE
    }, obj.find(prop => prop.name === FUNNEL_STAGE))),
    [PRODUCT_INTEREST]: new PropertyRecord(Object.assign({
      name: PRODUCT_INTEREST
    }, obj.find(prop => prop.name === PRODUCT_INTEREST))),
    [TIER_FIT]: new PropertyRecord(Object.assign({
      name: TIER_FIT
    }, obj.find(prop => prop.name === TIER_FIT)))
  })
});
export default ThreadRecord;