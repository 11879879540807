let isIframed;
export const getIsIframed = () => {
  if (typeof isIframed === 'boolean') {
    return isIframed;
  }
  try {
    isIframed = window.top !== window;
  } catch (e) {
    isIframed = true;
  }
  return isIframed;
};