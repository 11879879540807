import { Record } from 'immutable';
import ExternalProperty from './ExternalProperty';
class Ticket extends Record({
  id: 0,
  conversationId: 0,
  isResolved: false,
  productArea: null,
  resolution: null,
  roadblock: null,
  subject: null
}) {
  isRequiringRoadblock(productAreaToRoadblocksMapping) {
    return this.productArea && productAreaToRoadblocksMapping.has(`${this.productArea.id}`);
  }
  hasSupportedRoadblock(productAreaToRoadblocksMapping) {
    return this.productArea && (!this.isRequiringRoadblock(productAreaToRoadblocksMapping) || this.roadblock && productAreaToRoadblocksMapping.get(`${this.productArea.id}`).includes(this.roadblock.id));
  }
  isAlmostResolvable(productAreaToRoadblocksMapping) {
    return this.productArea && this.subject && this.hasSupportedRoadblock(productAreaToRoadblocksMapping);
  }
  isResolvable(productAreaToRoadblocksMapping) {
    return this.isAlmostResolvable(productAreaToRoadblocksMapping) && this.resolution;
  }
}
Ticket.fromJS = obj => new Ticket(Object.assign({}, obj, {
  productArea: obj.productArea && ExternalProperty.fromJS(obj.productArea),
  resolution: obj.resolution && ExternalProperty.fromJS(obj.resolution),
  roadblock: obj.roadblock && ExternalProperty.fromJS(obj.roadblock)
}));
export default Ticket;