import curry from 'transmute/curry';
import updateIn from 'transmute/updateIn';
import { REPLY_RECOMMENDATIONS_HISTORY } from '../constants/keyPaths';
import { getReplyRecommendationsHistory } from './getters';
import { createReplyRecsHistoryEntry } from '../../thread-history/operators/createReplyRecsHistoryEntry';
export const addReplyRecommendationEntry = curry((newReplyRecOptions, threadHistory) => {
  return updateIn(REPLY_RECOMMENDATIONS_HISTORY, () => {
    const existingReplyRecs = getReplyRecommendationsHistory(threadHistory);
    const newReplyRecRecordItem = createReplyRecsHistoryEntry(newReplyRecOptions);
    return Object.assign({}, existingReplyRecs, newReplyRecRecordItem);
  }, threadHistory);
});