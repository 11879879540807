import { Record } from 'immutable';
import invariant from 'react-utils/invariant';
const getPart = (addressId, part) => {
  const parts = addressId.split(' ');
  invariant(parts.length === 2, 'Invalid addressId %s', addressId);
  return parts[part];
};
export default class AliasAddress extends Record({
  accountId: null,
  address: null,
  displayableAddress: null,
  type: null,
  inboxId: null,
  conversationsInboxName: null,
  conversationsConnectedAccountId: null,
  disabled: null,
  primary: false
}, 'AliasAddressRecord') {
  getAddressId() {
    return `${this.accountId} ${this.address}`.trim();
  }
  static toAddressId(accountId, address) {
    return `${accountId} ${address}`;
  }
  static toAccountId(addressId) {
    return getPart(addressId, 0);
  }
  static toAliasAddress(addressId) {
    return getPart(addressId, 1);
  }
  static fromJS(json) {
    if (!json || typeof json !== 'object') {
      return json;
    }
    return new AliasAddress(json);
  }
}