import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPrefetchState } from 'find-and-filter-data/prefetch/prefetchState';
import { viewMemberDeleted, viewMemberUpdated, viewMemberUpserted, bulkUpdateTickets } from 'find-and-filter-data/realtime-updates/public';
import { generateViewId } from 'find-and-filter-data/views-schema/public';
import memoize from 'hs-lodash/memoize';
import omit from 'hs-lodash/omit';
import { Map as ImmutableMap } from 'immutable';
import Raven from 'raven-js';
import { AsyncStatus } from '../../../common/public';
import { applyHelpDeskBulkUpdateMessage, applyHelpDeskViewUpdateMessage, mergeUpserted } from '../../../realtime-updates/protected';
import { mergeRemoved } from '../../../realtime-updates/public';
import { DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER } from '../../../search-and-filter-data/protected';
import { getViewMemberId } from '../../../view-members-schema/public';
import { mergeIncomingViewMembersPage } from '../common/fetchViewMembersHelpers';
import { buildHelpDeskViewMembersPage, buildSearchHelpDeskViewMembersPage } from '../view-member-page/buildHelpDeskViewMembersPage';
import { DEFAULT_LIMIT, fetchHelpDeskViewMembersService } from './fetchHelpDeskViewMembersService';
import { fetchSearchViewMembersService } from './fetchSearchViewMembersService';
import { selectAreSearchParamsEqual, selectHelpDeskViewMembersPageStatus, selectIsDuplicateRequestPending } from './helpDeskViewMemberSelectors';
import { sortHelpDeskViewMembers } from './sortHelpDeskViewMembers';
export const prefetchViewMembersFulfilled = createAction('filterResults/prefetchViewMembersFulfilled', payload => ({
  payload
}));
function limitMembersOnRealtimeAddition(members, currentCount) {
  return members.slice(0, Math.max(currentCount, DEFAULT_LIMIT));
}
const updateViewMembers = ({
  data,
  message,
  viewId,
  sortState
}) => {
  return data.update(viewId, page => {
    if (!page) return page;
    return page.update('indexedMembers', members => {
      const updatedMembers = applyHelpDeskViewUpdateMessage(members, message);
      return limitMembersOnRealtimeAddition(sortState ? updatedMembers.sort(sortHelpDeskViewMembers(sortState)) : updatedMembers, members.count());
    });
  });
};
const updateTickets = ({
  data,
  message,
  viewId,
  sortState
}) => {
  return data.update(viewId, page => {
    if (!page) return page;
    const {
      members: updatedMembers,
      count
    } = applyHelpDeskBulkUpdateMessage(page.get('indexedMembers'), message);
    const updatedPage = page.set('indexedMembers', limitMembersOnRealtimeAddition(sortState ? updatedMembers.sort(sortHelpDeskViewMembers(sortState)) : updatedMembers, page.indexedMembers.count())).set('totalCount', page.get('totalCount') + count > 0 ? page.get('totalCount') + count : page.get('totalCount'));
    return updatedPage;
  });
};
const incrementCount = ({
  data,
  message,
  viewId
}) => {
  let count = 0;
  message.upserted.forEach(member => {
    var _data$get;
    if (((_data$get = data.get(viewId)) === null || _data$get === void 0 ? void 0 : _data$get.indexedMembers.get(getViewMemberId(member))) === undefined) count = count + 1;
  });
  return data.update(viewId, page => {
    if (!page) return page;
    return page.update('totalCount', () => page.totalCount + count);
  });
};
const decrementCount = ({
  data,
  decrement,
  viewId
}) => {
  return data.update(viewId, page => {
    if (!page) return page;
    return page.update('totalCount', () => page.totalCount - decrement);
  });
};
const upsertViewMembers = ({
  data,
  message,
  viewId,
  sortState
}) => {
  return data.update(viewId, page => {
    if (!page) return page;
    return page.update('indexedMembers', members => {
      const {
        indexedRecords: updatedMembers
      } = mergeUpserted(message.upserted, members);
      return limitMembersOnRealtimeAddition(sortState ? updatedMembers.sort(sortHelpDeskViewMembers(sortState)) : updatedMembers, members.count());
    });
  });
};
const deleteViewMembers = ({
  data,
  removedMembers,
  viewId
}) => {
  return data.update(viewId, page => {
    if (!page) return page;
    return page.update('indexedMembers', members => {
      const updatedMembers = mergeRemoved(removedMembers, members);
      return updatedMembers;
    });
  });
};
export const fetchHelpDeskViewMembers = createAsyncThunk('filterResults/fetchHelpDeskViewMembers', ({
  filterGroups,
  inboxId,
  limit,
  offsetTimestamp,
  offsetId,
  primaryOffsetValue,
  secondaryOffsetValue,
  searchQuery,
  sortDirection,
  sortProperty,
  viewId
}) => fetchHelpDeskViewMembersService(Object.assign({}, viewId !== DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER && {
  customViewId: +viewId
}, {
  filterGroups,
  inboxId,
  limit,
  offsetTimestamp,
  offsetId,
  primaryOffsetValue,
  secondaryOffsetValue,
  searchQuery,
  sortDirection,
  sortProperty
})), {
  condition: (params, {
    getState
  }) => {
    var _params$options;
    if ((_params$options = params.options) !== null && _params$options !== void 0 && _params$options.force) {
      return true;
    }
    const state = getState();
    const status = selectHelpDeskViewMembersPageStatus(state);
    if (status === AsyncStatus.PREFETCH) {
      return false;
    }
    const duplicateRequestIsPending = selectIsDuplicateRequestPending(state, params);

    // Do not initiate a new request if equivalent request is pending
    if (duplicateRequestIsPending) {
      return false;
    }

    // If there is pending request that is DIFFERENT from the current request, allow the new request to go through
    if (status === AsyncStatus.INITIAL_FETCH_LOADING || status === AsyncStatus.PAGINATED_FETCH_LOADING) {
      return true;
    }
    const searchParamsAreEqual = selectAreSearchParamsEqual(state, params);

    // If there is no pending request, and the search params are equal to the last request, ignore the request
    return !searchParamsAreEqual;
  }
});
export const fetchSearchViewMembers = createAsyncThunk('filterResults/searchHelpDeskViewMembers', ({
  filterGroups,
  inboxId,
  limit,
  offsetTimestamp,
  offsetId,
  primaryOffsetValue,
  secondaryOffsetValue,
  searchQuery,
  sortDirection,
  sortProperty
}) => fetchSearchViewMembersService({
  filterGroups,
  inboxId,
  limit,
  offsetTimestamp,
  offsetId,
  primaryOffsetValue,
  secondaryOffsetValue,
  searchQuery,
  sortDirection,
  sortProperty
}));
const initialState = {
  data: ImmutableMap(),
  status: AsyncStatus.UNINITIALIZED
};
const getInitialPrefetchState = memoize(prefetchState => {
  if (!prefetchState) {
    return initialState;
  }
  const {
    status,
    searchParams,
    viewMembersResponse,
    viewId
  } = prefetchState.viewMembers;
  return {
    status,
    data: status === AsyncStatus.SUCCEEDED && viewId && viewMembersResponse ? ImmutableMap({
      [viewId]: buildHelpDeskViewMembersPage(viewMembersResponse)
    }) : ImmutableMap(),
    lastResolvedSearchParams: status === AsyncStatus.SUCCEEDED || status === AsyncStatus.FAILED ? searchParams : null
  };
}, prefetchState => prefetchState === null || prefetchState === void 0 ? void 0 : prefetchState.prefetchId);
export const helpDeskViewMembersSlice = createSlice({
  name: 'helpDeskViewMembers',
  initialState() {
    const prefetchState = getPrefetchState();
    return getInitialPrefetchState(prefetchState);
  },
  reducers: {
    clearHelpDeskViewMembers() {
      return initialState;
    },
    optimisticUpdate(state, action) {
      const {
        payload
      } = action;
      state.data = state.data.updateIn([payload.viewId, 'indexedMembers', payload.id], value => value ? Object.assign({}, value, payload.viewMember) : value);
    },
    optimisticRemove(state, action) {
      const {
        payload
      } = action;
      state.data = state.data.deleteIn([payload.viewId, 'indexedMembers', payload.id]);
    },
    viewMemberSeen(state, action) {
      const {
        payload
      } = action;
      const {
        agentId,
        customViewIds,
        objectId
      } = payload;
      const allViews = [...customViewIds, DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER];

      //Update the ViewMember record in cached custom views.
      for (const viewId of allViews) {
        state.data = state.data.updateIn([`${viewId}`, 'indexedMembers', objectId], viewMember => {
          if (!viewMember) return viewMember;
          const seenByAgentIds = new Set(viewMember.seenByAgentIds);
          seenByAgentIds.add(agentId);
          return Object.assign(viewMember, {
            seenByAgentIds: Array.from(seenByAgentIds)
          });
        });
      }
    },
    viewMemberUnread(state, action) {
      const {
        payload
      } = action;
      const {
        agentId,
        objectId,
        customViewIds
      } = payload;

      //Update the ViewMember record in cached custom views.
      for (const viewId of customViewIds) {
        state.data = state.data.updateIn([`${viewId}`, 'indexedMembers', objectId], viewMember => {
          if (!viewMember) return viewMember;
          const seenByAgentIds = new Set(viewMember.seenByAgentIds);
          seenByAgentIds.delete(agentId);
          return Object.assign(viewMember, {
            seenByAgentIds: Array.from(seenByAgentIds)
          });
        });
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(viewMemberUpdated, (state, action) => {
      const {
        message,
        sortState
      } = action.payload;
      const viewId = generateViewId(message.viewKey);
      state.data = updateViewMembers({
        data: state.data,
        message,
        viewId,
        sortState
      });
    }).addCase(viewMemberUpserted, (state, action) => {
      const {
        message,
        sortState
      } = action.payload;
      const viewId = generateViewId(message.viewKey);
      state.data = incrementCount({
        data: state.data,
        message,
        viewId
      });
      state.data = upsertViewMembers({
        data: state.data,
        message,
        viewId,
        sortState
      });
    }).addCase(viewMemberDeleted, (state, action) => {
      const {
        removedMembers,
        viewKey
      } = action.payload;
      const viewId = generateViewId(viewKey);
      state.data = decrementCount({
        data: state.data,
        decrement: removedMembers.length,
        viewId
      });
      state.data = deleteViewMembers({
        data: state.data,
        removedMembers,
        viewId
      });
    }).addCase(fetchHelpDeskViewMembers.pending, (state, {
      meta
    }) => {
      const disabledLoad = meta.arg && meta.arg.options && meta.arg.options.disabledLoad;
      if (!disabledLoad) {
        const hasOffset = meta.arg && meta.arg.offsetId && meta.arg.offsetId > 0;
        if (hasOffset) {
          state.status = AsyncStatus.PAGINATED_FETCH_LOADING;
        } else {
          state.status = AsyncStatus.INITIAL_FETCH_LOADING;
        }
      }
      state.pendingSearchParams = omit(meta.arg, 'options');
    }).addCase(fetchHelpDeskViewMembers.fulfilled, (state, {
      meta,
      payload
    }) => {
      if (!payload.viewMemberPagedResult) {
        state.status = AsyncStatus.FAILED;
        Raven.captureMessage(`fetchHelpDeskViewMembers.fulfilled payload did not include viewMemberPagedResult`, {
          extra: {
            payload
          }
        });
        return;
      }
      if (meta.arg && meta.arg.offsetId && meta.arg.offsetId > 0) {
        const oldPage = state.data.get(meta.arg.viewId);
        const newPage = buildHelpDeskViewMembersPage(payload);
        const updatedPage = mergeIncomingViewMembersPage({
          oldPage,
          newPage,
          totalCount: payload.totalCount
        });
        if (updatedPage) state.data = state.data.set(meta.arg.viewId, updatedPage);
      } else {
        state.data = ImmutableMap({
          [meta.arg.viewId]: buildHelpDeskViewMembersPage(payload)
        });
      }
      state.status = AsyncStatus.SUCCEEDED;
      state.pendingSearchParams = undefined;
      state.lastResolvedSearchParams = meta.arg;
    }).addCase(fetchHelpDeskViewMembers.rejected, (state, {
      meta
    }) => {
      state.status = AsyncStatus.FAILED;
      state.pendingSearchParams = undefined;
      state.lastResolvedSearchParams = meta.arg;
    }).addCase(fetchSearchViewMembers.pending, (state, {
      meta
    }) => {
      const disabledLoad = meta.arg && meta.arg.options && meta.arg.options.disabledLoad;
      if (!disabledLoad) {
        const hasOffset = meta.arg && meta.arg.offsetId && meta.arg.offsetId > 0;
        if (hasOffset) {
          state.status = AsyncStatus.PAGINATED_FETCH_LOADING;
        } else {
          state.status = AsyncStatus.INITIAL_FETCH_LOADING;
        }
      }
      state.pendingSearchParams = omit(meta.arg, 'options');
    }).addCase(fetchSearchViewMembers.fulfilled, (state, {
      meta,
      payload
    }) => {
      if (!payload.searchResults) {
        state.status = AsyncStatus.FAILED;
        Raven.captureMessage(`fetchSearchViewMembers.fulfilled payload did not include searchResults`, {
          extra: {
            payload
          }
        });
        return;
      }
      if (meta.arg && meta.arg.offsetId && meta.arg.offsetId > 0) {
        const oldPage = state.data.get(DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER);
        const newPage = buildSearchHelpDeskViewMembersPage(payload);
        const updatedPage = mergeIncomingViewMembersPage({
          oldPage,
          newPage,
          totalCount: payload.totalCount
        });
        if (updatedPage) state.data = state.data.set(DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER, updatedPage);
      } else {
        state.data = ImmutableMap({
          [DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER]: buildSearchHelpDeskViewMembersPage(payload)
        });
      }
      state.status = AsyncStatus.SUCCEEDED;
      state.pendingSearchParams = undefined;
      state.lastResolvedSearchParams = meta.arg;
    }).addCase(fetchSearchViewMembers.rejected, (state, {
      meta
    }) => {
      state.status = AsyncStatus.FAILED;
      state.pendingSearchParams = undefined;
      state.lastResolvedSearchParams = meta.arg;
    }).addCase(prefetchViewMembersFulfilled, (state, action) => {
      state.status = action.payload.status;
      state.lastResolvedSearchParams = action.payload.searchParams;
      state.pendingSearchParams = undefined;
      const {
        viewId,
        viewMembersResponse
      } = action.payload;
      if (viewId && viewMembersResponse) {
        state.data = ImmutableMap({
          [viewId]: buildHelpDeskViewMembersPage(viewMembersResponse)
        });
      }
    }).addCase(bulkUpdateTickets, (state, action) => {
      const {
        added,
        updated,
        removed,
        customViewId,
        sortState
      } = action.payload;
      state.data = updateTickets({
        data: state.data,
        message: {
          added,
          updated,
          removed: removed || []
        },
        viewId: String(customViewId),
        sortState
      });
    });
  }
});
export const {
  actions: {
    clearHelpDeskViewMembers,
    optimisticUpdate,
    optimisticRemove,
    viewMemberSeen,
    viewMemberUnread
  }
} = helpDeskViewMembersSlice;