// original: https://git.hubteam.com/HubSpot/conversations-internal-schema/blob/1729733ab4b5cfdd5950af37c67157f5deb9589b/static/js/team/operators/selectNodeOnTree.js

import { getKeyAddress } from './getKeyAddress';
import { getNodeAtAddress } from './getNodeAtAddress';
import { setDataOnNodeAtAddress } from './setDataOnNodeAtAddress';
export const selectNodeOnTree = (optionNode, tree) => {
  const traverse = node => {
    const updatedNode = node.set('selected', true);
    // Because of how `TypedMap` works, TS thinks that `childTeams` is a `TeamTreeNode` and not a `TeamTree` (aka, `ImmutableMap<string, TeamTreeNode>`)
    // so we manually cast it
    const kids = updatedNode.get('childTeams');
    if (kids && kids.size) {
      return updatedNode.update('childTeams', childTeams => {
        // @ts-expect-error Typing maps doesn't play well with the update pattern
        return childTeams.map(childNode => {
          return traverse(childNode);
        });
      });
    }
    return updatedNode;
  };
  const address = getKeyAddress(optionNode, tree);
  const node = getNodeAtAddress(address, tree);
  const updatedChildTeams = traverse(node);
  return setDataOnNodeAtAddress(address, {
    childTeams: updatedChildTeams.get('childTeams'),
    selected: true
  }, tree);
};