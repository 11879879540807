import { useCallback, useReducer } from 'react';
import { ASSOCIATE_PROMPT, CREATE_TICKET_PROMPT, DISASSOCIATE_PROMPT, BLOCK_THREAD_PROMPT } from '../constants/PromptTypes';
const reducer = (state, {
  prompt,
  value
}) => Object.assign({}, state, {
  [prompt]: value
});
const initialState = {
  [ASSOCIATE_PROMPT]: false,
  [CREATE_TICKET_PROMPT]: false,
  [DISASSOCIATE_PROMPT]: false,
  [BLOCK_THREAD_PROMPT]: false
};
export const usePromptReducer = () => {
  const [promptStatus, dispatch] = useReducer(reducer, initialState);
  const openPrompt = useCallback(prompt => dispatch({
    prompt,
    value: true
  }), [dispatch]);
  const closePrompt = useCallback(prompt => dispatch({
    prompt,
    value: false
  }), [dispatch]);
  return {
    promptStatus,
    openPrompt,
    closePrompt
  };
};