// @ts-expect-error no types
import { fetchQuotes } from './fetchQuotes';
import { CONTACT } from 'customer-data-objects/constants/ObjectTypes';
export const quotesApiWrapper = (httpClient, contactVid) => ({
  fetchRenderedContent({
    id
  }) {
    return fetchQuotes({
      httpClient,
      objectType: CONTACT,
      objectId: contactVid
    }).then(results => {
      let quote;
      const hasId = results.some(el => {
        const found = el.options.find(q => q.value === id);
        if (found) quote = found;
        return !!found;
      });
      if (hasId && quote) {
        return {
          htmlBody: `<a href="${quote.value}">${quote.title}</a>`
        };
      }
      return {
        htmlBody: ''
      };
    });
  },
  searchArticles({
    query,
    count
  }) {
    return fetchQuotes({
      httpClient,
      objectType: CONTACT,
      objectId: contactVid
    }).then(results => {
      const queryResults = results.filter(res => res.text && res.text.includes(query));
      return count ? queryResults.slice(0, count) : queryResults;
    });
  }
});