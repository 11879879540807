import { useEffect } from 'react';
import { useFriendlyName } from 'conversations-thread-data/friendly-names/public/hooks';
export const useLiveFriendlyName = ({
  messageId,
  shouldFetch
}) => {
  const {
    error,
    loading,
    data: newFriendlyNames,
    startPolling,
    stopPolling
  } = useFriendlyName({
    messageId: messageId || '',
    useQueryOptions: {
      skip: !shouldFetch,
      onCompleted: () => {
        stopPolling();
      }
    }
  });
  useEffect(() => {
    if (shouldFetch && !newFriendlyNames) {
      startPolling(200);
    }
    setTimeout(stopPolling, 60 * 1000);
  }, [newFriendlyNames, shouldFetch, startPolling, stopPolling]);
  return {
    loading,
    data: newFriendlyNames,
    error
  };
};