import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { numberIdInvariant } from 'conversations-async-data/indexed-async-data/invariants/numberIdInvariant';
import { idToString } from 'conversations-async-data/indexed-async-data/operators/idToString';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { updateAsyncData } from 'conversations-async-data/async-data/operators/updateAsyncData';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { THREAD_ASSOCIATIONS_ASYNC_DATA_CACHE_SIZE } from '../../../common/public/constants/CacheSize';
import { COMPOSITION_THREAD_ID } from '../../../communicator/constants/compositionThreadId';
import { TICKET_DISASSOCIATED } from '../constants/actionTypes';
import { FETCH_ASSOCIATIONS } from '../constants/asyncActionTypes';
import { CREATE_EMAIL_THREAD } from '../../../email/public/constants';
import { limitToCount } from 'conversations-async-data/indexed-async-data/eviction-strategies/limitToCount';
const initialState = new IndexedAsyncData({
  entries: ImmutableMap(),
  evict: limitToCount(THREAD_ASSOCIATIONS_ASYNC_DATA_CACHE_SIZE),
  idInvariant: id => {
    if (id === COMPOSITION_THREAD_ID) {
      return;
    }
    numberIdInvariant(id);
  },
  idTransform: id => {
    return id === COMPOSITION_THREAD_ID ? COMPOSITION_THREAD_ID : idToString(id);
  },
  name: 'associations',
  notSetValue: new AsyncData({
    data: ImmutableSet()
  })
});
const updateEntryWithDebug = (id, updater, indexedData) => {
  const nextIndexedData = updateEntry(id, updater, indexedData);
  /**
   * Commenging out the sentry below till we have more understanding of the
   * original intent behind debugging this
   */

  // const updatedEntry = getEntry(id, nextIndexedData);
  // const updatedData = getData(updatedEntry);

  // const contactAssociationCount = updatedData.reduce((count, assoc) => {
  //   if (get('type', assoc) === 'CONTACT') {
  //     return count + 1;
  //   }
  //   return count;
  // }, 0);

  // if (contactAssociationCount > 1) {
  //   Raven.captureException('TOO_MANY_CONTACT_ASSOCIATIONS', {
  //     extra: {
  //       nextIndexedData: nextIndexedData.toJS(),
  //     },
  //   });
  // }

  return nextIndexedData;
};
export const associations = handleActions({
  [CREATE_EMAIL_THREAD.SUCCEEDED](state) {
    return updateEntryWithDebug(COMPOSITION_THREAD_ID, () => new AsyncData({
      data: ImmutableSet()
    }), state);
  },
  [FETCH_ASSOCIATIONS.STARTED](state, action) {
    const {
      threadId
    } = action.payload.requestArgs;
    return updateEntryWithDebug(threadId, requestStarted, state);
  },
  [FETCH_ASSOCIATIONS.FAILED](state, action) {
    const {
      threadId
    } = action.payload.requestArgs;
    return updateEntryWithDebug(threadId, requestFailed, state);
  },
  [FETCH_ASSOCIATIONS.SUCCEEDED](state, action) {
    const {
      requestArgs,
      data
    } = action.payload;
    const {
      threadId
    } = requestArgs;
    return updateEntryWithDebug(threadId, requestSucceededWithOperator(() => data), state);
  },
  [TICKET_DISASSOCIATED](state, action) {
    const {
      threadId,
      ticketId
    } = action.payload;
    return updateEntryWithDebug(threadId, asyncData => updateAsyncData(existingAssociations => existingAssociations.filterNot(association => association.get('type') === 'TICKET' && association.get('id') === ticketId), asyncData), state);
  }
}, initialState);