'use es6';

import { handleActions } from 'redux-actions';
import { TICKET_STATUS_CHANGED } from '../constants/actionTypes';
import { THREAD_STATUS_UPDATED } from 'conversations-thread-data/thread-view-realtime/public/constants/actionTypes';
const initialState = false;
export default handleActions({
  [THREAD_STATUS_UPDATED]() {
    return false;
  },
  [TICKET_STATUS_CHANGED]() {
    return true;
  }
}, initialState);