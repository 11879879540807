//@ts-expect-error JS File
import { makeAsyncCard } from 'customer-data-sidebar/async/makeAsyncCard';
import Raven from 'raven-js';
const AsyncAssociatedObjectsCardContent = makeAsyncCard({
  loader: () => import( /* webpackChunkName: "associated-objects-card-content" */'card-associated-objects-lib/card/content/AssociatedObjectsCardContentUniversalSidebarDataProvider').catch(error => {
    Raven.captureMessage(error, {
      extra: {
        message: 'customer-data-sidebar: error loading associated objects card content'
      }
    });
  })
});
export default AsyncAssociatedObjectsCardContent;