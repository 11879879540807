import { registerQuery } from 'data-fetching-client';
import { getConnectedEmailAccount, getConnectedEmailAccountByChannelInstanceId, getConnectedEmailAccounts } from '../clients/conversations-email.api';
export const fetchConnectedEmailAccount = registerQuery({
  args: ['id'],
  fetcher: getConnectedEmailAccount,
  fieldName: 'connectedEmailAccount'
});
export const fetchConnectedEmailAccounts = registerQuery({
  fetcher: getConnectedEmailAccounts,
  fieldName: 'connectedEmailAccounts'
});
export const fetchConnectedEmailByChannelInstanceId = registerQuery({
  args: ['channelInstanceId'],
  fetcher: getConnectedEmailAccountByChannelInstanceId,
  fieldName: 'connectedEmailAccount'
});