'use es6';

import { localStorage } from 'conversations-platform/localStorage';

// Note: legacy Plus one phrasing used for backwards consistency
const LOCAL_STORAGE_KEY = 'CV_DISMISSED_24_PLUS_ONE_WARNING';
export const hasDismissedTwentyFourWarning = () => {
  let hasDismissedWarning = false;
  try {
    hasDismissedWarning = !!localStorage.getItem(LOCAL_STORAGE_KEY);
  } catch (error) {
    hasDismissedWarning = true;
  }
  return hasDismissedWarning;
};
export const onDismissTwentyFourWarning = () => {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, true);
  } catch (error) {
    //
  }
};