// https://git.hubteam.com/HubSpot/cv-pubsub/blob/master/cv-pubsub-models/src/main/java/com/hubspot/cv/pubsub/core/message/viewmember/FriendlyNameResolutionSource.java

export const HUBSPOT_USER = 'HUBSPOT_USER';
export const BOT = 'BOT';
export const CONTACT = 'CONTACT';
export const GENERIC_SENDER_NAME_FIELD = 'GENERIC_SENDER_NAME_FIELD';
export const RAW_EMAIL = 'RAW_EMAIL';
export const RAW_PHONE_NUMBER = 'RAW_PHONE_NUMBER';
export const INTEGRATOR_APP = 'INTEGRATOR_APP';
export const DEFAULT_UNKNOWN = 'DEFAULT_UNKNOWN';
export const DEFAULT_AGENT = 'DEFAULT_AGENT';
export const DEFAULT_CUSTOMER = 'DEFAULT_CUSTOMER';
export const DEFAULT_INTEGRATOR = 'DEFAULT_INTEGRATOR';
export const DEFAULT_AUTOMATED = 'DEFAULT_AUTOMATED';
export const DEFAULT_EXTERNAL = 'DEFAULT_EXTERNAL';
export const DEFAULT_LLM = 'DEFAULT_LLM';