import getIn from 'transmute/getIn';
import memoize from 'transmute/memoize';
export const isPropertyOnInvoice = (propertyName, invoice) => !!getIn(['properties', propertyName], invoice);
export const getInvoiceProperty = (obj, propertyName) => getIn(['properties', propertyName, 'value'], obj);
export const getInvoiceAssociatedObjectProperty = (obj, propertyName) => getIn(['properties', propertyName, 'value'], obj);
export const setInvoiceProperty = (invoice, propertyName, propertyValue) => {
  return Object.assign({}, invoice, {
    properties: Object.assign({}, invoice.properties, {
      [propertyName]: {
        name: propertyName,
        value: propertyValue
      }
    })
  });
};
export const makeNewProperty = (name, value) => ({
  name,
  value
});
export const mapProperties = properties => Object.entries(properties).map(([name, propertyValue]) => ({
  name,
  value: propertyValue.value
}));
export const unmapProperties = properties => properties.reduce((acc, {
  name,
  value
}) => Object.assign({}, acc, {
  [name]: {
    name,
    value
  }
}), {});
export const getPropertyDefinition = (propertyDefinitions, propertyName) => {
  return propertyDefinitions.find(propertyDefinition => propertyDefinition.name === propertyName);
};
export const addTaxId = (taxIds, newTaxId) => taxIds.length > 0 ? [...taxIds.split(';'), newTaxId].join(';') : newTaxId;
export const removeTaxId = (existingTaxIds, removedTaxId) => existingTaxIds.split(';').filter(selectedTaxId => selectedTaxId !== removedTaxId).join(';');
export const filterTaxIds = (taxIds, ...filterGroup) => taxIds.split(';').filter(taxId => filterGroup.includes(taxId)).join(';');
export const hasTaxId = (taxIds, formattedTaxId) => taxIds.split(';').some(taxId => taxId === formattedTaxId);
const isOwnerProperty = propertyDefinition => propertyDefinition.fieldType === 'select' && propertyDefinition.externalOptionsReferenceType === 'OWNER';
export const ACCEPTABLE_PROPERTY_FIELD_TYPES = ['text', 'booleancheckbox', 'number', 'date', 'select', 'radio'];
const PO_NUMBER_PROPERTY = 'hs_purchase_order_number';
export const filterInvoicePropertiesIntoCustomOptions = memoize((properties, additionalPropertiesToFilter) => properties.allPropertyGroups.reduce((groupedOptions, propertyGroup) => {
  const filteredPropertyGroups = propertyGroup.allPropertyDefinitions.reduce((options, property) => [...options, {
    text: property.label,
    value: property.name,
    fieldType: property.fieldType,
    hidden: additionalPropertiesToFilter.includes(property.name) || property.hidden || isOwnerProperty(property) || !!property.hubspotDefined && property.name !== PO_NUMBER_PROPERTY,
    disabled: !ACCEPTABLE_PROPERTY_FIELD_TYPES.includes(property.fieldType)
  }], []).filter(option => !option.hidden);
  if (filteredPropertyGroups.length) {
    groupedOptions.push({
      text: propertyGroup.label,
      options: filteredPropertyGroups
    });
  }
  return groupedOptions;
}, []));