import { Map as ImmutableMap, Record, OrderedMap } from 'immutable';
import get from 'transmute/get';
// @ts-expect-error not typed
import { createPropertyKey } from '../operators/getPropertyKeys';
import { getAssociatedObjects, getResults } from '../operators/calleesOperators';
export class PhoneNumberPropertyMetadata extends Record({
  extension: null,
  phoneNumber: null,
  phoneNumberType: null,
  possibleNumber: false,
  regionCode: null,
  state: null,
  usnumber: false,
  validNumber: false,
  wasRegionInferred: false,
  wasParseResultEquivalentWithNoInference: null
}, 'PhoneNumberPropertyMetadata') {
  // The response from this can be passed to twilio to dial a number + extension
  get toNumberString() {
    const extension = this.get('extension');
    const phoneNumber = this.get('phoneNumber');
    return extension ? `${phoneNumber} ext ${extension}` : phoneNumber;
  }
}
const blankPhoneNumberProperty = {
  propertyName: null,
  label: null,
  hubspotDefined: false,
  value: null,
  metadata: new PhoneNumberPropertyMetadata()
};
export class PhoneNumberProperty extends Record(blankPhoneNumberProperty, 'PhoneNumberProperty') {
  constructor(attributes = blankPhoneNumberProperty) {
    super(Object.assign({}, attributes));
  }
  static fromJS({
    propertyName = null,
    label = null,
    hubspotDefined = false,
    value = null,
    metadata,
    validatedPhoneNumber
  }) {
    const metadataProps = metadata || validatedPhoneNumber;
    return new PhoneNumberProperty({
      propertyName,
      label,
      hubspotDefined,
      value,
      metadata: new PhoneNumberPropertyMetadata(metadataProps)
    });
  }
}
const blankCallableObject = {
  objectId: null,
  objectTypeId: null,
  legacyObjectType: null,
  name: null,
  phoneNumberProperties: OrderedMap(),
  additionalProperties: ImmutableMap(),
  optOutOfCommunications: ImmutableMap(),
  crmObjectSubscriptionStatus: ImmutableMap(),
  canCommunicate: null
};
export class CallableObject extends Record(blankCallableObject, 'CallableObject') {
  static createReferenceKey(callableObject) {
    return createPropertyKey({
      objectTypeId: get('objectTypeId', callableObject),
      objectId: get('objectId', callableObject)
    });
  }
  constructor(attributes) {
    super(Object.assign({}, attributes));
  }
  static fromJS({
    objectId = null,
    objectTypeId = null,
    legacyObjectType = null,
    name = null,
    phoneNumberProperties = [],
    additionalProperties = {},
    optOutOfCommunications = {
      '@ok': false,
      '@result': 'OK'
    },
    crmObjectSubscriptionStatus = {
      optedOutOfCommunications: false
    },
    canCommunicate = null
  }) {
    const phoneNumberPropertiesArray = phoneNumberProperties;
    const phoneNumberPropertiesParsed = phoneNumberPropertiesArray.reduce((orderedMap, property) => {
      const {
        propertyName
      } = property;
      const phoneNumberProperty = PhoneNumberProperty.fromJS(property);
      return orderedMap.set(propertyName, phoneNumberProperty);
    }, OrderedMap());
    const additionalPropertiesParsed = ImmutableMap(additionalProperties);
    const optOutOfCommunicationsParsed = ImmutableMap(optOutOfCommunications);
    const crmObjectSubscriptionStatusParsed = ImmutableMap(crmObjectSubscriptionStatus);
    const record = new CallableObject({
      objectId,
      objectTypeId,
      legacyObjectType,
      name,
      canCommunicate,
      phoneNumberProperties: phoneNumberPropertiesParsed,
      crmObjectSubscriptionStatus: crmObjectSubscriptionStatusParsed,
      optOutOfCommunications: optOutOfCommunicationsParsed,
      additionalProperties: additionalPropertiesParsed
    });
    return record;
  }
}
export class AssociatedObjects extends Record({
  hasMore: false,
  results: null
}, 'AssociatedObjects') {
  constructor(attributes) {
    super(Object.assign({}, attributes));
  }
  static fromJS({
    hasMore = false,
    results = []
  }) {
    const parsedResults = results.reduce((orderedMap, result) => {
      const callableObject = CallableObject.fromJS(result);
      return orderedMap.set(CallableObject.createReferenceKey(callableObject), callableObject);
    }, OrderedMap());
    const record = new AssociatedObjects({
      hasMore,
      results: parsedResults
    });
    return record;
  }
}
export class AssociatedObjectType extends Record({
  objectType: null,
  associatedObjects: null
}, 'AssociatedObjectType') {
  constructor(attributes) {
    super(Object.assign({}, attributes));
  }
  static fromJS({
    objectType = null,
    associatedObjects = {
      hasMore: false,
      results: []
    }
  }) {
    const result = new AssociatedObjectType({
      objectType,
      associatedObjects: AssociatedObjects.fromJS(associatedObjects)
    });
    return result;
  }
}
export class CalleesRecord extends Record({
  fromObject: null,
  // Everything breaks if you dont make this an array. We appear to have some state be correctly mapped immutable types and others just be plain JS
  associatedObjectsPage: OrderedMap()
}, 'CalleesRecord') {
  constructor(attributes) {
    super(Object.assign({}, attributes));
  }

  // The constructor is parsing from an API use this to convert a preformatted object to this record
  static fromJS({
    fromObject = null,
    associatedObjectsPage = []
  }) {
    const record = new CalleesRecord({
      fromObject: fromObject ? CallableObject.fromJS(fromObject) : null,
      associatedObjectsPage: associatedObjectsPage.reduce((orderedMap, associatedObjectType) => {
        const objectTypeId = associatedObjectType.objectTypeId;
        const newAssociatedObjectType = AssociatedObjectType.fromJS(associatedObjectType);

        // Do not replace existingAssociatedObjectType if number of associations is one to many.
        const existingAssociatedObjectType = orderedMap.get(objectTypeId);
        if (existingAssociatedObjectType && getResults(getAssociatedObjects(existingAssociatedObjectType)).size > 1) {
          return orderedMap;
        }
        return orderedMap.set(objectTypeId, newAssociatedObjectType);
      }, OrderedMap())
    });
    return record;
  }
}