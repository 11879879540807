import { handleActions } from 'redux-actions';
import { THREAD_ID_CHANGED } from '../../../thread-details/public/constants';
import { THREAD_PRESENCE_UPDATED } from '../constants/ActionTypes';
const initialState = {};
export default handleActions({
  [THREAD_PRESENCE_UPDATED](state, action) {
    const {
      threadId,
      message: presenceState
    } = action.payload;
    if (!threadId) return state;
    return {
      [threadId]: presenceState
    };
  },
  [THREAD_ID_CHANGED]() {
    return initialState;
  }
}, initialState);