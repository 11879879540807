import I18n from 'I18n';
import { ChannelCapabilitiesConfigContext } from 'conversations-thread-view/channel-capabilities/context/ChannelCapabilitiesConfigContext';
import { useContext, useMemo } from 'react';
export function useMessageBodyDisabledConfig() {
  const {
    genericChannelId: editorConfigKey
  } = useContext(ChannelCapabilitiesConfigContext);
  const isCapabilityUnset = editorConfigKey === 'UNSET';
  const disabledConfig = useMemo(() => {
    if (isCapabilityUnset) {
      return {
        isDisabled: true,
        disabledText: I18n.text('composer-ui.capability-editor.message-body-disabled.unset-capabilities')
      };
    }
    return undefined;
  }, [isCapabilityUnset]);
  return {
    disabledConfig
  };
}