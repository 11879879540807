// gimme: conversations-inbox-ui 05/26/2020 741c62dc71ca742adbc6d64d61d30de7cb46539f
'use es6';

import { createSelector } from 'reselect';
import { getLatestContextUpdateMessage } from 'conversations-thread-data/unified-thread-history/public/operators/getLatestContextUpdateMessage';
import { getCurrentThreadHistory } from 'conversations-thread-data/thread-histories/public/selectors/getCurrentThreadHistory';
export const getCurrentThreadLatestContextUpdateMessage = createSelector([getCurrentThreadHistory], threadHistory => {
  if (!threadHistory) {
    return null;
  }
  return getLatestContextUpdateMessage(threadHistory) || null;
});