// gimme: conversations-inbox-ui 06/10/2020 24a2e66917cc934433dd111db7e06fd02943ca6a
'use es6';

import { createSelector } from 'reselect';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { getAsyncFocusedThreadDetails } from '../../thread-details/selectors/getAsyncFocusedThreadDetails';
import { getAgentToVisitorChannelName } from 'conversations-thread-data/thread-details/public/operators';
export const getCurrentChannel = createSelector([getAsyncFocusedThreadDetails], threadDetails => {
  if (!threadDetails) return null;
  return getAgentToVisitorChannelName(getData(threadDetails));
});