import http from 'conversations-http/clients/http';

// https://tools.hubteam.com/api-catalog/services/MessagesService/v2/spec/internal#operations-Filtering-post-%2Fmessages%2Fv2%2Ffiltering%2Fstatus%2F%7BthreadId%7D

export function setFilterStatus({
  threadId,
  filtered,
  filteringType,
  contactDeletion
}) {
  return http.post(`/messages/v2/filtering/status/${threadId}`, {
    data: {
      filtered,
      filteringType,
      contactDeletion
    }
  });
}