import { IS_ANY_OF } from 'customer-data-filters/converters/objectSeg/ObjectSegOperatorTypes';
import { getConversationStatusFromThreadStatus } from '../../../view-members-schema/protected';
import { getInternalName } from './threadListOperators';
function makeStatusBranch(threadStatus) {
  const conversationStatus = getConversationStatusFromThreadStatus(threadStatus);
  return {
    filterBranchOperator: 'AND',
    filterBranchType: 'AND',
    filterBranches: [],
    filters: [{
      filterId: -1,
      filterType: 'PROPERTY',
      property: 'hs_conversation_status',
      operation: {
        defaultValue: null,
        includeObjectsWithNoValueSet: false,
        operationType: 'enumeration',
        operator: IS_ANY_OF,
        operatorName: IS_ANY_OF,
        propertyType: 'enumeration',
        values: [conversationStatus]
      }
    }],
    objectType: 'CONVERSATION'
  };
}
const UNSUPPORTED_THREAD_LISTS = ['TRASH'];

/**
 * @description This function generates a FilterBranch with a filter for open
 * or closed only threads based on the given ThreadList Filter status. Returns
 * null for ThreadLists that don't support optimistic updates, like Trash.
 */
export const buildThreadListFilterBranch = (threadList, threadFilterStatus) => {
  const threadListInternalName = getInternalName(threadList);
  if (!threadListInternalName || UNSUPPORTED_THREAD_LISTS.includes(threadListInternalName)) {
    return null;
  }
  return makeStatusBranch(threadFilterStatus);
};