import { useState, useCallback } from 'react';
import { fromJS } from 'immutable';
import invariant from 'react-utils/invariant';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { requestFailedWithError } from 'conversations-async-data/async-data/operators/requestFailedWithError';
import { useAsyncFetcher } from 'conversations-async-data/async-data/hooks/useAsyncFetcher';
import { isSucceeded } from 'conversations-async-data/async-data/operators/statusComparators';
import promiseDone from 'hs-promise-utils/promiseDone';
const isFunction = maybeFn => typeof maybeFn === 'function';
const isObject = maybeObj => typeof maybeObj === 'object' && !Array.isArray(maybeObj);

/**
 * Creating a custom asyncDataInvariant to distinguish between AsyncData invariants
 * from this invariant check and the ones from async-data lib.
 */
const asyncDataInvariant = asyncData => invariant(asyncData instanceof AsyncData, 'useFetchWithDefaultData: Expected an `AsyncData` not a `%s`(%s)', typeof asyncData, asyncData);

/**
 * @description a react hook built around `useAsyncFetcher` that
 * can be passed fetch function, an asyncData, requestArgs
 * the fetched data wil live in component state.
 * It delegates data fetching logic to `useAsyncFetcher` by passing
 * in a pre-bound fetcher along with the selected AsyncData record.
 * @param {Object} options
 * @param {Function} options.requestFn an api call that
 * will fetch the async data when called.
 * @param {Any} options.toRecordFn an optional id to be passed to the
 * selector and action creator.
 * @param {Object} options.requestArgs an object containing the request requestArgs
 * to pass to the fetch function
 * @param {Object} options.preloadedData an optional asyncData containing the preloaded
 * data so the fetch is unneeded
 *
 * @returns {Object} containing async data and the retry function
 */
export const useFetchWithDefaultData = ({
  requestFn,
  toRecordFn = fromJS,
  requestArgs,
  preloadedData = new AsyncData()
}) => {
  invariant(isFunction(requestFn), `createAsyncAction Expected requestFn to be a Function. Got: "${requestFn}"`);
  invariant(isFunction(toRecordFn), `createAsyncAction expected toRecordFn to be a Function. Got: "${toRecordFn}"`);
  invariant(!requestArgs || isObject(requestArgs), `requestArgs must be an Object. Received ${typeof requestArgs}`);
  asyncDataInvariant(preloadedData);
  const [asyncData, setData] = useState(preloadedData);
  const fetcher = useCallback(() => {
    if (preloadedData && isSucceeded(preloadedData)) {
      setData(preloadedData);
    } else {
      promiseDone(requestFn(requestArgs).then(resp => {
        setData(requestSucceededWithOperator(() => toRecordFn(resp), asyncData));
      }).catch(error => {
        setData(requestFailedWithError(error, asyncData));
      }));
    }
  }, [requestArgs, requestFn, asyncData, toRecordFn, preloadedData]);
  const {
    retryFailed
  } = useAsyncFetcher({
    asyncData,
    fetcher
  });
  return {
    asyncData,
    retryFailed
  };
};