import { createAction } from 'redux-actions';
import { FRIENDLY_NAMES_RECEIVED } from '../constants/actionTypes';
export const updateFriendlyNames = createAction(FRIENDLY_NAMES_RECEIVED, ({
  newFriendlyNames,
  threadId
}) => {
  return {
    newFriendlyNames,
    threadId
  };
});