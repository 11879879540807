import { getSource } from 'calling-lifecycle-internal/operators/from-number/FromNumberGetters';
import { PhoneNumberSources } from '../constants/PhoneNumberSources';
import { CONNECT_ACCOUNT, HUBSPOT_SUBACCOUNT, HUBSPOT_PORTAL_SUBACCOUNT } from '../constants/AccountTypes';
export const getAccountTypeFromPhoneNumber = fromNumberRecord => {
  const source = getSource(fromNumberRecord);
  let accountType;
  switch (source) {
    case PhoneNumberSources.CONNECT_NUMBER:
      accountType = CONNECT_ACCOUNT;
      break;
    case PhoneNumberSources.EXTERNAL_NUMBER:
      accountType = HUBSPOT_SUBACCOUNT;
      break;
    case PhoneNumberSources.HUBSPOT_NUMBER:
      accountType = HUBSPOT_PORTAL_SUBACCOUNT;
      break;
    default:
      accountType = HUBSPOT_PORTAL_SUBACCOUNT;
      break;
  }
  return accountType;
};