'use es6';

import { Set as ImmutableSet } from 'immutable';
import { FIRST, FIRST_IN_GROUP, LAST_IN_GROUP, LAST } from '../../constants/messagePositionDescriptors';
import { areMessagesGrouped } from './areMessagesGrouped';
export const getPositionDescriptors = (message, previousMessage, nextMessage, dontIncludeLastInGroup) => {
  const isFirst = !previousMessage;
  const isFirstInGroup = !areMessagesGrouped(message, previousMessage);
  const isLastInGroup = !dontIncludeLastInGroup && !areMessagesGrouped(message, nextMessage);
  const isLast = !nextMessage;
  const toInclude = {
    [FIRST]: isFirst,
    [FIRST_IN_GROUP]: isFirstInGroup,
    [LAST_IN_GROUP]: isLastInGroup,
    [LAST]: isLast
  };
  return Object.keys(toInclude).reduce((accumulator, k) => toInclude[k] ? accumulator.add(k) : accumulator, ImmutableSet());
};