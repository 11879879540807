const DragAndDropSort = ({
  source,
  destination,
  draggableId
}, displayOrder) => {
  if (!destination) {
    return displayOrder;
  }
  if (destination.droppableId === source.droppableId && destination.index === source.index) {
    return displayOrder;
  }
  if (Math.abs(destination.index - source.index) === 1) {
    displayOrder[source.index] = displayOrder[destination.index];
    displayOrder[destination.index] = draggableId;
  } else {
    displayOrder.splice(source.index, 1);
    displayOrder.splice(destination.index, 0, draggableId);
  }
  return displayOrder;
};
export default DragAndDropSort;