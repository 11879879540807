export const HANDOFF_TYPES = {
  ASSISTED_SALE: 'Assisted sale',
  BOOK_A_MEETING: 'Booked a meeting',
  BOOKED_MEETING: 'Booked a meeting',
  FYI_EMAIL: 'FYI to owner',
  IMMEDIATE_SALE_COMPLETE: 'Immediate sale completed',
  LIVE_TRANSFER: 'Live transfer',
  QUALIFIED_LEAD: 'Qualified Lead',
  SEND_MEETING_LINK: 'Meeting link sent',
  SEND_PAYMENT_LINK: 'Payment link sent',
  TOUCHLESS_PUSH: 'Touchless push'
};