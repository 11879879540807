import { createSelector } from 'reselect';
import get from 'transmute/get';
import { selectViews } from 'find-and-filter-data/views-data/public';
import { getViewInternalType } from 'find-and-filter-data/views-schema/public';
import { isInSearchAndFilterMode as getIsInSearchAndFilterMode } from 'conversations-inbox-lib/thread-search-and-filtering/public/selectors';
import { USER_CREATED_TEAM_VIEW } from 'conversations-inbox-lib/usage-tracking/constants';
import { getCurrentViewId } from '../../thread-list/selectors/getCurrentViewId';
const SEARCH = 'Search';
export const getUsageTrackingProperties = createSelector([getCurrentViewId, selectViews, getIsInSearchAndFilterMode], (viewId, views, isInSearchAndFilterMode) => {
  // TODO - subscreen property logic should be encapsulated in Find & Filter Lib
  const currentView = get(viewId, views);
  const viewType = getViewInternalType(currentView);
  const search = isInSearchAndFilterMode ? SEARCH : null;
  return {
    namespace: 'conversations',
    screen: 'Inbox',
    subscreen: search || viewType || USER_CREATED_TEAM_VIEW
  };
});