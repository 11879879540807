export const getMinutesPercentageUsed = minutesUsage => {
  const usage = minutesUsage.usage;
  const limit = minutesUsage.totalLimit;
  return limit ? Math.floor(usage / limit * 100) : 0;
};
export const getMinutesUsed = minutesUsage => {
  if (!minutesUsage) {
    return 0;
  }
  return minutesUsage.usage;
};
export const getMinutesLimit = minutesUsage => {
  if (!minutesUsage) {
    return 0;
  }
  return minutesUsage.totalLimit;
};
export const getMinutesGraceLimit = minutesUsage => {
  if (!minutesUsage) {
    return 0;
  }
  return minutesUsage.graceLimit;
};
export const getIsMinutesUsageOverLimit = minutesUsage => {
  if (!minutesUsage) {
    return false;
  }
  return !minutesUsage.portalUsageUnderLimit;
};