import ToolLinksRecord from '../records/ToolLinksRecord';
import { LOAD_TOOL_LINKS } from '../actions/ActionTypes';
const processLoadToolLinks = (state, action) => {
  if (action.meta === 'request') {
    return state.set('loading', true);
  } else if (action.meta === 'response') {
    return new ToolLinksRecord(action.payload);
  }
  return state;
};
const toolLinks = (state = new ToolLinksRecord(), action) => {
  switch (action.type) {
    case LOAD_TOOL_LINKS:
      return processLoadToolLinks(state, action);
    default:
      return state;
  }
};
export default toolLinks;