import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"];
import { Record, List, Map as ImmutableMap } from 'immutable';
import CallingProvider from 'calling-internal-common/call-provider/records/CallingProvider';
import { AsyncDataRecord } from 'calling-lifecycle-internal/universal-calling-store/records/AsyncDataRecord';
import { CallingAdminSettingsResult } from 'calling-internal-common/calls-v1-settings/records/CallingAdminSettings';
import { PhoneNumberSources } from 'calling-internal-common/constants/PhoneNumberSources';
import FromNumber from 'calling-internal-common/calls-v1-settings/records/InitialLoadSettingsRecords/FromNumber';
export class FromNumberResults extends Record({
  CONNECT_ACCOUNT: List(),
  HUBSPOT_SUBACCOUNT: List(),
  HUBSPOT_PORTAL_SUBACCOUNT: List()
}) {
  constructor(args = {}) {
    const {
      HUBSPOT_SUBACCOUNT,
      CONNECT_ACCOUNT,
      HUBSPOT_PORTAL_SUBACCOUNT
    } = args;
    const hasConnectResults = CONNECT_ACCOUNT && CONNECT_ACCOUNT.length;
    const connectResults = hasConnectResults ? FromNumberResults.createRegisteredNumberList(CONNECT_ACCOUNT, PhoneNumberSources.CONNECT_NUMBER) : List();
    const hasSubaccountResults = HUBSPOT_SUBACCOUNT && HUBSPOT_SUBACCOUNT.length;
    const subaccountResults = hasSubaccountResults ? FromNumberResults.createRegisteredNumberList(HUBSPOT_SUBACCOUNT) : List();
    const hasHsProvidedResults = HUBSPOT_PORTAL_SUBACCOUNT && HUBSPOT_PORTAL_SUBACCOUNT.length;
    const hsProvidedResults = hasHsProvidedResults ? FromNumberResults.createRegisteredNumberList(HUBSPOT_PORTAL_SUBACCOUNT, PhoneNumberSources.HUBSPOT_NUMBER) : List();
    super({
      CONNECT_ACCOUNT: connectResults,
      HUBSPOT_SUBACCOUNT: subaccountResults,
      HUBSPOT_PORTAL_SUBACCOUNT: hsProvidedResults
    });
  }
}
FromNumberResults.createRegisteredNumberList = (list, source = PhoneNumberSources.EXTERNAL_NUMBER) => List(list.map(number => {
  number.source = source;
  return new FromNumber(number);
}));
export const createInstalledCallingSettings = ({
  installedCallingApplications
}) => ImmutableMap({
  installedCallingApplications: List(installedCallingApplications.map(application => new CallingProvider(application)))
});
const isSuccessfulResult = result => result && result['@result'] === 'OK';
const hasNoError = result => result && !result.error;
const parsePhoneNumbers = phoneNumbers => {
  let phoneNumbersRaw = {};
  if (isSuccessfulResult(phoneNumbers)) {
    phoneNumbersRaw = phoneNumbers.phoneNumbersByAccountType;
  } else if (phoneNumbers && (phoneNumbers.HUBSPOT_SUBACCOUNT || phoneNumbers.HUBSPOT_PORTAL_SUBACCOUNT)) {
    phoneNumbersRaw = phoneNumbers;
  }
  return new FromNumberResults(phoneNumbersRaw);
};
const parseInstalledCallingSettings = installedCallingSettings => {
  let installedCallingSettingsParsed = ImmutableMap({
    error: installedCallingSettings && installedCallingSettings.error
  });
  if (installedCallingSettings && installedCallingSettings.result) {
    installedCallingSettingsParsed = createInstalledCallingSettings({
      installedCallingApplications: installedCallingSettings.result.results
    });
  } else if (installedCallingSettings && installedCallingSettings.installedCallingApplications) {
    installedCallingSettingsParsed = createInstalledCallingSettings(installedCallingSettings);
  }
  return installedCallingSettingsParsed;
};
const parseCallingAdminSettings = callingAdminSettings => {
  let data = {};
  if (hasNoError(callingAdminSettings)) {
    data = callingAdminSettings.result;
  } else if (callingAdminSettings && callingAdminSettings.lastUpdatedBy) {
    data = callingAdminSettings;
  }
  return new CallingAdminSettingsResult(data);
};
export class SettingsOmnibusResult extends Record({
  phoneNumbers: null,
  installedCallingSettings: ImmutableMap(),
  callingAdminSettings: new CallingAdminSettingsResult()
}, 'SettingsOmnibusResult') {
  constructor(args) {
    if (!args) {
      super();
      return;
    }
    const {
      phoneNumbers,
      installedCallingSettings,
      callingAdminSettings
    } = args;
    super({
      phoneNumbers: parsePhoneNumbers(phoneNumbers),
      installedCallingSettings: parseInstalledCallingSettings(installedCallingSettings),
      callingAdminSettings: parseCallingAdminSettings(callingAdminSettings)
    });
  }
}
export class AsyncSettingsOmnibusResult extends AsyncDataRecord({
  data: new SettingsOmnibusResult()
}, 'AsyncSettingsOmnibusResult') {
  constructor(_ref = {}) {
    let {
        data
      } = _ref,
      options = _objectWithoutPropertiesLoose(_ref, _excluded);
    options.data = new SettingsOmnibusResult(data || {});
    super(options);
  }
}