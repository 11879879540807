import { ATTACHMENTS } from './legacyConstants';
export const CAN_START_CONVERSATION = ['canStartConversation'];
export const CHANNEL_CAPABILITIES = ['channelCapabilities'];
export const CHANNEL_TYPE = ['channelType'];
export const EDITING_CAPABILITIES = ['editingCapabilities'];
export const ICON = ['icon'];
export const LABEL = ['label'];
export const LIMITS = ['limits'];
export const SUPPORT_ASSOCIATIONS = ['supportAssociations'];
export const REQUIRE_CHATFLOW_CONNECTION_STATUS = ['requireChatFlowConnectionStatus'];
export const CHECK_CONNECTED_ACCOUNTS = ['checkConnectedAccounts'];
export const TYPING_INDICATOR = ['typingIndicator'];
export const MESSAGE_METADATA = ['messageMetadata'];
export const SENDERS = [...MESSAGE_METADATA, 'senders'];
export const RECIPIENTS = [...MESSAGE_METADATA, 'recipients'];
export const FIELDS = ['recipientsFields'];
export const RECIPIENTS_FIELDS = [...RECIPIENTS, ...FIELDS];
export const SUBJECT = [...MESSAGE_METADATA, 'subject'];
export const KEY = ['key'];
export const IS_VISIBLE = ['isVisible'];
export const DELIVERY_IDENTIFIER = ['deliveryIdentifier'];
export const DELIVERY_IDENTIFIER_TYPE = ['deliveryIdentifierType'];
export const RECIPIENT_FIELD = ['recipientField'];
export const RECIPIENT_FIELD_NAME = [...RECIPIENT_FIELD, 'fieldName'];
export const RECIPIENT_KEY = [...RECIPIENT_FIELD, 'key'];
export const EDITOR_CONFIG = ['editor'];
export const RICH_TEXT = ['richText'];
export const INLINE_STYLES = ['inlineStyles'];
export const TEXT_STYLES = ['textStyles'];
export const FORMAT_STYLES = ['formatStyles'];
export const SUBMIT_ON_ENTER = ['submitOnEnter'];
export const AT_MENTIONS = ['atMention'];
export const PLAIN_TEXT_HYPERLINKS = ['plainTextHyperlinks'];
export const USER_DEFAULT_FONT = ['userDefaultFont'];
export const CONVERSATION_SUMMARY = ['conversationSummary'];
export const AI_ASSISTANT = ['aiAssistant'];
export const URL_FRAGMENT = ['urlFragment'];
export const TIME_LIMIT = ['timeLimit'];
export const MAX_RECIPIENT_COUNT_NEW = [...LIMITS, 'recipientCountNew'];
export const MAX_RECIPIENT_COUNT_REPLY = [...LIMITS, 'recipientCountReply'];
export const REQUIRED = ['required'];
export const PLACEHOLDER = [...EDITOR_CONFIG, 'placeHolderText'];
export const SUBMIT_BUTTON_TEXT = [...EDITOR_CONFIG, 'submitButtonText'];
export const EDITOR_TYPING_INDICATOR = [...EDITOR_CONFIG, ...TYPING_INDICATOR];
export const INSERT_TEMPLATES = ['insertTemplates'];
export const PAYMENT_LINKS = ['paymentLinks'];
export const INVOICES = ['invoices'];
export const MAX_CONTENT_IN_BYTES = [...EDITOR_CONFIG, 'maxContentSizeInBytes'];
export const INLINE_IMAGES = ['inlineImages'];
export const MESSAGE = ['message'];
export const REPLY_TO_SPECIFIC_MESSAGES = [...MESSAGE, 'replyToSpecificMessages'];
export const REQUIRED_MESSAGE_POSITION_DESCRIPTOR = [...MESSAGE_METADATA, 'requireMessagePositionDescriptor'];
export const FILE_ATTACHMENTS = ['fileAttachments'];
export const MAX_FILE_ATTACHMENTS_SIZE_IN_BYTES = ['maxAttachmentSizeBytes'];
export const MAX_ATTACHMENT_COUNT = ['maxAttachmentCount'];
export const MAX_TOTAL_ATTACHMENTS_SIZE_IN_BYTES = ['maxTotalAttachmentSizeBytes'];
export const SENDER_DELIVERY_IDENTIFIER_TYPE = [...DELIVERY_IDENTIFIER, ...DELIVERY_IDENTIFIER_TYPE];
export const ATTACHMENT_FILE_TYPES = ['attachmentFileTypes'];
export const MAX_FILE_SIZE = [ATTACHMENTS, ...MAX_FILE_ATTACHMENTS_SIZE_IN_BYTES];
export const MAX_TOTAL_FILE_LIMIT = [ATTACHMENTS, ...MAX_ATTACHMENT_COUNT];
export const MAX_TOTAL_FILE_SIZE_LIMIT = [ATTACHMENTS, ...MAX_TOTAL_ATTACHMENTS_SIZE_IN_BYTES];
export const FILETYPE_TO_OVERRIDE = [ATTACHMENTS, ...ATTACHMENT_FILE_TYPES];