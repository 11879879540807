import { InvoiceStatus } from '../constants/status';
import { getInvoiceProperty } from './property';
export function isFinalized(invoice) {
  const invoiceStatus = getInvoiceProperty(invoice, 'hs_invoice_status');
  return [InvoiceStatus.OPEN, InvoiceStatus.PAID].includes(invoiceStatus);
}
export function isVoided(invoice) {
  const invoiceStatus = getInvoiceProperty(invoice, 'hs_invoice_status');
  return invoiceStatus === InvoiceStatus.VOIDED;
}
export function isFinalizedOrVoided(invoice) {
  return isFinalized(invoice) || isVoided(invoice);
}
export function isOpen(invoice) {
  const invoiceStatus = getInvoiceProperty(invoice, 'hs_invoice_status');
  return invoiceStatus === InvoiceStatus.OPEN;
}