// @ts-expect-error module not typed

import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
import { getConnectedAccounts as requestFn } from '../clients/getConnectedAccounts';
import { FETCH_CONNECTED_ACCOUNTS as actionTypes } from '../../_internal/constants/asyncActionTypes';
import { indexConnectedAccounts } from '../../_internal/operators/indexConnectedAccounts';
export const fetchConnectedAccountsForInbox = createAsyncAction({
  requestFn,
  actionTypes,
  toRecordFn: indexConnectedAccounts
});