import { DEFAULT_CLIENT_KEY } from '../constants/clientKeys';
import { getPubSubClient } from '../selectors/pubSubClientGetters';
/**
 * Reconnect to pubsub
 */
export const reconnect = (clientKey = DEFAULT_CLIENT_KEY) => (__dispatch, getState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
  const client = getPubSubClient(getState(), {
    clientKey
  });
  client.connect();
};