import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { FAILED, UNINITIALIZED, OUT_OF_SYNC } from '../../async-data/constants/asyncStatuses';
import { getStatus } from '../../async-data/operators/getters';
import { getEntries } from '../operators/getters';
const subtract = (subtrahend, minuend) => subtrahend.subtract(minuend);

/**
 * @description A hook for managing batched fetching of indexed
 * async data entries as their statuses change and as new entries
 * need to be hydrated. Uses a set of ids to determine which
 * entries to keep track of.
 *
 * THIS HOOK IS NOT PART OF THIS LIBRARY'S PUBLIC API AND
 * IS AN IMPLEMENTATION DETAIL OF OTHER HOOKS. ITS INTERFACE
 * MAY CHANGE WITHOUT WARNING.
 * @param options
 * @param options.fetcher a fetcher method that
 * is passed imutable Sets of ids to fetch.
 * @param options.deferred if true, the fetcher will not fire regardless of
 * status. Defaults to false.
 * @param options.indexedAsyncData
 * @param options.ids a set of ids to manage. Entries
 * in the passed IndexedAsyncData will be ignored if their ids
 * are not present in this set.
 */
export const useIndexedAsyncFetcher = ({
  fetcher,
  deferred = false,
  indexedAsyncData,
  ids = ImmutableSet()
}) => {
  /**
   * Build up a Set of ids representing AsyncData entries with
   * UNINITIALIZED or OUT_OF_SYNC statues. This is split into
   * multiple stages for performance purposes. This ensures that
   * `unfetchedIds` isn't recomputed if properties other than
   * the `status`es of entries being tracked change.
   */
  const trackedEntries = useMemo(() => getEntries(indexedAsyncData, ids), [indexedAsyncData, ids]);
  const statuses = useMemo(() => trackedEntries.reduce((accumulator, entry, id) => {
    return accumulator.set(id, getStatus(entry));
  }, ImmutableMap()), [trackedEntries]);
  const unfetchedIds = useMemo(() => statuses.reduce((accumulator, status, id) => status === UNINITIALIZED || status === OUT_OF_SYNC ? accumulator.add(id) : accumulator, ImmutableSet()), [statuses]);

  /**
   * Subtract ids that were previously included in the list
   * of ids to fetch. This ensures that if a single AsyncData entry
   * or group of AsyncData entries get stuck in an UNINITIAILZED
   * or OUT_OF_SYNC state, they won't trigger new fetcher requets
   * on every render. (tldr; only request ids on transitions into
   * these statuses)
   */
  const prevUnfetchedIdsRef = useRef(ImmutableSet());
  useEffect(() => {
    /**
     * Only update prevFetchedIds if not deferred to ensure we refetch the
     * previously deferred ids after the request is no longer deferred.
     */
    if (!deferred) {
      prevUnfetchedIdsRef.current = unfetchedIds;
    }
  }, [deferred, unfetchedIds]);
  const idsToFetch = useMemo(() => subtract(unfetchedIds, prevUnfetchedIdsRef.current), [unfetchedIds]);

  /**
   * Use a reference to the fetcher so that changes to the
   * fetcher function don't trigger new requests.
   */
  const fetcherRef = useRef(fetcher);
  useEffect(() => {
    fetcherRef.current = fetcher;
  }, [fetcher]);

  /**
   * Fetch ids as a batch
   */
  useEffect(() => {
    if (idsToFetch.size && !deferred) fetcherRef.current({
      ids: idsToFetch
    });
  }, [deferred, idsToFetch]);

  /**
   * Keep an internal reference to the failed ids, and
   * return a callback that consumers can use to re-fetch
   * only failed ids. The refs here are to prevent recomputing
   * the callback that gets returned out of the hook to maintain
   * purity of consumer components.
   */
  const failedIds = useMemo(() => statuses.reduce((accumulator, status, id) => status === FAILED ? accumulator.add(id) : accumulator, ImmutableSet()), [statuses]);
  const failedIdsRef = useRef(failedIds);
  useEffect(() => {
    failedIdsRef.current = failedIds;
  });
  const retryFailed = useCallback(() => {
    if (failedIdsRef.current.size) {
      fetcherRef.current({
        ids: failedIdsRef.current
      });
    }
  }, []);
  return {
    entries: trackedEntries,
    retryFailed
  };
};