import get from 'transmute/get';
// @ts-expect-error module not typed
import EmailAddressRecord from 'customer-data-email/schema/email/EmailAddressRecord';
// @ts-expect-error module not typed

export const getEmailAddressRecordFromConnectedAccount = connectedAccount => {
  if (connectedAccount) {
    const id = get('id', connectedAccount);
    const friendlyFromName = get('friendlyFromName', connectedAccount);
    const resolvedFromName = get('resolvedFromName', connectedAccount);
    const email = get('email', connectedAccount);
    const sendFromEmail = get('sendFromEmail', connectedAccount);
    return new EmailAddressRecord({
      id,
      addressId: `${email} ${email}`,
      address: email,
      friendlyName: resolvedFromName || friendlyFromName,
      sendAsAddress: sendFromEmail
    });
  }
  return null;
};