import usageTracker from './usageTracker';
export const trackInteraction = props => usageTracker.track('invoiceInteraction', Object.assign({
  subscreen: 'highlight-card'
}, props));
export const trackUsage = props => usageTracker.track('invoiceUsage', Object.assign({
  subscreen: 'highlight-card'
}, props));
export const trackSendInteraction = props => usageTracker.track('invoiceSendInteraction', Object.assign({
  subscreen: 'highlight-card'
}, props));
export const trackSendUsage = props => usageTracker.track('invoiceSendUsage', Object.assign({
  subscreen: 'highlight-card'
}, props));