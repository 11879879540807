import http from 'conversations-http/clients/http';
export const fetchMessageSubscription = ({
  senderPhoneNumber,
  recipientPhoneNumber,
  contactVid
}) => {
  return http.post('short-messages/app/v1/recipient-status/sms', {
    data: {
      hubSpotCustomerPhoneNumber: senderPhoneNumber,
      endUserPhoneNumber: recipientPhoneNumber,
      contactVid
    }
  });
};