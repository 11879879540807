import { useMemo } from 'react';
import { updateAsyncData } from 'conversations-async-data/async-data/operators/updateAsyncData';
import { useReduxAsyncFetcher } from 'conversations-async-data/async-data/hooks/useReduxAsyncFetcher';
import { noOpAction } from '../../../utils/noOpAction';
import { deferredAsyncDataSelector } from '../../../utils/deferredAsyncDataSelector';
import { fetchConnectedAccountsForInbox } from '../actions/fetchConnectedAccountsForInbox';
import { getAsyncConnectedAccounts } from '../selectors/getAsyncConnectedAccounts';
import { filterNonGlobalConnectedAccounts } from '../operators/filterNonGlobalConnectedAccounts';
export const useConnectedAccounts = ({
  filterNonGlobalAccounts = false,
  inboxId,
  deferred = false
} = {}) => {
  const shouldDefer = deferred || !inboxId;
  const {
    asyncData,
    retryFailed
  } = useReduxAsyncFetcher({
    action: shouldDefer ? noOpAction : fetchConnectedAccountsForInbox,
    selector: shouldDefer ? deferredAsyncDataSelector : getAsyncConnectedAccounts,
    id: inboxId,
    idTransform: id => ({
      inboxId: id
    })
  });
  const asyncConnectedAccounts = useMemo(() => filterNonGlobalAccounts && !shouldDefer ? updateAsyncData(filterNonGlobalConnectedAccounts, asyncData) : asyncData, [asyncData, shouldDefer, filterNonGlobalAccounts]);
  return {
    asyncConnectedAccounts,
    retryFailed
  };
};