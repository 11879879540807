import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import { INVOICE_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
export const deleteInvoice = objectId => {
  return http.delete(`inbounddb-objects/v1/crm-objects/INVOICE/${objectId}`);
};
export const patchInvoice = (id, properties) => {
  return http.patch(`invoices/v1/invoices/${id}`, {
    data: {
      portalId: PortalIdParser.get(),
      objectTypeId: INVOICE_TYPE_ID,
      objectId: id,
      properties
    }
  });
};
export const downloadInvoicePdf = id => {
  return http.get(`invoices/v1/downloads/${id}/download`);
};
export const getPaymentStatus = id => http.get(`invoices/v1/hubspot-payment/${id}/status`);
export const updateEmailReminders = (id, upcomingReminderDatesToCancel) => {
  return http.patch(`invoices/v1/email-reminders/${id}`, {
    data: {
      portalId: PortalIdParser.get(),
      invoiceId: id,
      upcomingReminderDatesToCancel
    }
  });
};