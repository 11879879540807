export const VIEW_SELECTOR_COLLAPSED_WIDTH = 54;
export const VIEW_SELECTOR_FULL_WIDTH = 235;
export const VIEW_SELECTOR_BREAK_POINT = 1200;
export const DEAD_ZONE_SIZE = 100;
export const HOVER_BUFFER_SIZE = 40;
export const HIDE_CONTENTS_TIMEOUT = 150;
export const BLOCK_HOVER_TIMEOUT = 350;
export const HOVER_DELAY_TIMEOUT = 100;
export const X_DISTANCE_FROM_SIDE = 6;
export const SIDEBAR_BASE_Z_INDEX = 10;
export const SIDEBAR_HOVER_Z_INDEX = SIDEBAR_BASE_Z_INDEX + 1;
export const SIDEBAR_DEADZONE_Z_INDEX = SIDEBAR_BASE_Z_INDEX + 2;