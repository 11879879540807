import { getLocalId } from 'conversations-internal-schema/file-upload/operators/fileUploadGetters';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { stringIdInvariant } from 'conversations-async-data/indexed-async-data/invariants/stringIdInvariant';
import { updateEntry } from 'conversations-async-data/indexed-async-data/operators/updateEntry';
import { requestStartedWithOperator } from 'conversations-async-data/async-data/operators/requestStartedWithOperator';
import { requestFailedWithError } from 'conversations-async-data/async-data/operators/requestFailedWithError';
import { updateAsyncData } from 'conversations-async-data/async-data/operators/updateAsyncData';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { deleteEntry } from 'conversations-async-data/indexed-async-data/operators/deleteEntry';
import { setUploadProgress } from 'conversations-internal-schema/file-upload/operators/fileUploadSetters';
import { FILE_UPLOAD_STARTED, FILE_UPLOAD_PROGRESS, FILE_UPLOAD_SUCCEEDED, REMOVE_FILE_UPLOAD, FILE_UPLOAD_FAILED } from '../constants/actionTypes';
import { Map as ImmutableMap } from 'immutable';
export const initialState = new IndexedAsyncData({
  entries: ImmutableMap(),
  idInvariant: stringIdInvariant,
  name: 'fileUpload',
  notSetValue: new AsyncData({
    data: null
  })
});
export const reducer = (state, action) => {
  const {
    fileUpload
  } = action.payload;
  switch (action.type) {
    case FILE_UPLOAD_STARTED:
      return updateEntry(getLocalId(fileUpload), requestStartedWithOperator(() => fileUpload), state);
    case FILE_UPLOAD_PROGRESS:
      {
        const {
          progress
        } = action.payload;
        return updateEntry(getLocalId(fileUpload), asyncData => {
          const maybeFileUpload = getData(asyncData);
          return maybeFileUpload ? updateAsyncData(() => setUploadProgress(progress, maybeFileUpload), asyncData) : asyncData;
        }, state);
      }
    case FILE_UPLOAD_FAILED:
      {
        const {
          error
        } = action.payload;
        return updateEntry(getLocalId(fileUpload), requestFailedWithError(error), state);
      }
    case FILE_UPLOAD_SUCCEEDED: // Fallthrough
    case REMOVE_FILE_UPLOAD:
      return deleteEntry(getLocalId(fileUpload), state);
    default:
      return state;
  }
};