import { useSelector } from 'react-redux';
import { selectIsInFallbackMode } from './fallbackModeSlice';

/**
 * This hook will return whether Fallback Mode has been enabled on the BE.
 * Fallback Mode is enabled when there is an issue with Custom Views where they
 * must be disabled. While in Fallback Mode users should only be able to use
 * ThreadLists.
 */
export function useIsFallbackModeEnabled() {
  return useSelector(selectIsInFallbackMode);
}