export class MissingDynamicError extends Error {}
const getTranslationFromParts = (translationKey, prefix, suffix) => {
  return [prefix, translationKey, suffix].filter(e => !!e).join('.');
};
const _getDynamicNamespace = ({
  translationOptions = [],
  prefix = '',
  suffix = '',
  defaultOption = 'default',
  unsafe = false
}) => translationOption => {
  const fullKey = getTranslationFromParts(translationOption, prefix, suffix);
  if (!translationOptions.includes(translationOption) && !unsafe) {
    window.setTimeout(() => {
      const tried = `Tried to build incorrect i18n string [${fullKey}] using dynamic option ${translationOption}`;
      const available = `Available options are [${translationOptions}]`;
      const errorMessage = [tried, available].join('\n');
      throw new MissingDynamicError(errorMessage);
    }, 0);
    return getTranslationFromParts(defaultOption, prefix, suffix);
  }
  return fullKey;
};

/**
 * Builds a dynamic translation string using a given option or the specified default. Designed to
 * localize dynamic string building for easier in-code discovery and provide fallbacks in case a
 * given dynamic option type does not exist as a translation.
 *
 * @param {Object} options
 * @param {Array<string>} options.translationOptions The list of namespaces that the translation
 *                                                   option could be
 * @param {string|null} options.prefix The portion of the translation string that occurs before the
 *                                     dynamic option type
 * @param {string|null} options.suffix The portion of the translation string that occurs after the
 *                                     dynamic option type
 * @param {string} options.defaultOption Used in the event that the given dynamic option does not
 *                                     exist in the `translationOptions`
 * @param {boolean} options.unsafe If `true`, uses the `translationOption` as part of the namespace
 *                                 instead of the specified `defaultOption`
 * @param {string} translationOption The dynamic option type used to build the string
 * @return {string}
 */

export function getDynamicNamespace(options, translationOption) {
  return translationOption ? _getDynamicNamespace(options)(translationOption) : _getDynamicNamespace(options);
}