import { List as ImmutableList } from 'immutable';
import { buildCMFSender } from 'conversations-message-history/common-message-format/operators/buildCMFSender';
import { AGENT } from 'conversations-message-history/common-message-format/constants/legacySenderTypes';
import { getIsActive, getIsAuthorized, getInstanceDeliveryIdentifier } from '../../../channel-instances/public/operators';
import { EMAIL_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
function canSendFromChannelInstance(channelInstance) {
  return getIsActive(channelInstance) && getIsAuthorized(channelInstance);
}
export const getInitialSendersFromChannelInstance = ({
  userId,
  channelInstance,
  channelId
}) => {
  const isValidSendingChannelInstance = channelInstance && canSendFromChannelInstance(channelInstance);
  const channeInstanceDeliveryIdentifier = isValidSendingChannelInstance ? getInstanceDeliveryIdentifier(channelInstance) : null;
  const senderField = channelId === EMAIL_GENERIC_CHANNEL_ID ? 'FROM' : null;
  return ImmutableList([buildCMFSender({
    senderField,
    deliveryIdentifier: channeInstanceDeliveryIdentifier || null,
    senderId: userId,
    senderType: AGENT
  })]);
};