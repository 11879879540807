import { createAsyncActionTypes } from 'conversations-async-data/async-action/createAsyncActionTypes';
export const CLOSE_THREAD = createAsyncActionTypes('CLOSE_THREAD');
export const OPEN_THREAD = createAsyncActionTypes('OPEN_THREAD');
export const MARK_AS_SPAM = createAsyncActionTypes('MARK_AS_SPAM');
export const BLOCK_THREAD = createAsyncActionTypes('BLOCK_THREAD');
export const UNMARK_AS_SPAM = createAsyncActionTypes('UNMARK_AS_SPAM');
export const UNBLOCK_THREAD = createAsyncActionTypes('UNBLOCK_THREAD');
export const ASSIGNMENT_CHANGE = createAsyncActionTypes('ASSIGNMENT_CHANGE');
export const MOVE_TO_INBOX = createAsyncActionTypes('MOVE_TO_INBOX');
export const HARD_DELETE_THREAD = createAsyncActionTypes('HARD_DELETE_THREAD');
export const SOFT_DELETE_THREAD = createAsyncActionTypes('SOFT_DELETE_THREAD');
export const RESTORE_THREAD = createAsyncActionTypes('RESTORE_THREAD');