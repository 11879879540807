import http from 'conversations-http/clients/http';
import invariant from '../lib/invariant';
export function sendRequestAccessEmail({
  adminId,
  currentInboxId,
  lastAccessedInboxId
}) {
  const inboxId = currentInboxId || lastAccessedInboxId;
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 8 arguments, but got 2.
  invariant(!isNaN(inboxId), `InboxId must be a Number, got ${inboxId}`);
  return http.post(`/messages/v2/notifications/inbox/${inboxId}/request-access/${adminId}`);
}