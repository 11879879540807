import { getPhoneNumberProperties, getHasAssociatedObjects, getCallableObjects, getCallablePhoneNumberProperties } from 'calling-lifecycle-internal/callees/operators/calleesOperators';
export const hasCallableRecords = calleesData => {
  if (!calleesData) return false;
  const hasAssociatedObjects = getHasAssociatedObjects(calleesData);
  if (hasAssociatedObjects) return true;
  const callableObjects = getCallableObjects(calleesData);
  return callableObjects && callableObjects.some(callableObject => {
    const phoneNumberProperties = getPhoneNumberProperties(callableObject);
    return getCallablePhoneNumberProperties(phoneNumberProperties).size > 0;
  });
};