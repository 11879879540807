import { getEmail, getPhone } from 'conversations-inbox-lib/contacts/public/operators';
//@ts-expect-error formatContactName is untyped
import { formatContactName } from 'customer-data-objects/model/ContactModel';
export const getContactDetail = contact => {
  const contactEmail = getEmail(contact) || '';
  const contactPhone = getPhone(contact) || '';
  const isKnownContact = contact ? contact['is-contact'] : false;
  const contactDetail = formatContactName(contact, contactEmail);
  if (isKnownContact && !contactDetail) return contactPhone;
  return contactDetail;
};