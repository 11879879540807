import HistorySingleton from '../records/HistorySingleton';
import { push } from './push';
import { replace } from './replace';
export const setFragment = ({
  fragment = '',
  preserveSearch = true,
  replace: replaceLocation = false
}, historyOverride) => dispatch => {
  const history = historyOverride || HistorySingleton().instance();
  if (fragment.indexOf('#') !== 0) {
    fragment = `#${fragment}`;
  }
  if (history.location.hash === fragment) {
    return;
  }
  const pathname = history.location.pathname;
  let search = '';
  if (preserveSearch) {
    search = history.location.search || '';
  }
  const nextLocation = `${pathname}${search}${fragment}`;
  if (replaceLocation) {
    dispatch(replace(nextLocation));
  } else {
    dispatch(push(nextLocation));
  }
};