'use es6';

import { getUsageTrackingChannel } from 'conversations-thread-data/usage-tracking/public/trackerChannelIdMapping';
class UsageTrackerError extends Error {}
let coreTracker;
let beaconTracker;
let trackingContext = {};
export const setTracker = (passedTracker, passedTrackingContext) => {
  coreTracker = passedTracker;
  trackingContext = passedTrackingContext || trackingContext;
};
export const setBeaconTracker = passedTracker => {
  beaconTracker = passedTracker;
};
export const getTracker = () => coreTracker;
export const getBeaconTracker = () => beaconTracker;
export const getTrackingContext = () => {
  return trackingContext;
};
export const trackEvent = (event, getTrackerFn = getTracker) => {
  const tracker = getTrackerFn();
  if (!tracker) return;
  if (typeof event !== 'object') {
    window.setTimeout(() => {
      throw new UsageTrackerError(`trackEvent expects an object, got ${typeof event}`);
    }, 0);
  }
  const {
    availableChannels,
    threadId,
    ticketCount,
    originalGenericChannelId
  } = trackingContext;
  const availableChannelIdsAndNames = availableChannels ? availableChannels.map(channel => getUsageTrackingChannel(channel)) : [];
  const eventWithChannel = Object.assign({}, event, {
    properties: Object.assign({}, event.properties, {
      threadId,
      availableChannels: availableChannelIdsAndNames,
      channel: getUsageTrackingChannel(originalGenericChannelId),
      hasAssociatedTicket: Boolean(ticketCount)
    })
  });
  tracker.track(eventWithChannel.key, eventWithChannel.properties);
};