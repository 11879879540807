import get from 'transmute/get';
export const getAlignment = get('alignItems');
export const getAvatarWidth = get('avatarWidth');
export const getBackgroundColor = get('backgroundColor');
export const getBorderColor = get('borderColor');
export const getBorderRadius = get('borderRadius');
export const getBorderWidth = get('borderWidth');
export const getMessageSpacing = get('messageSpacing');
export const getTextColor = get('textColor');
export const getFileAttachmentTheme = get('fileAttachmentTheme');
export const getQuickReplyTheme = get('quickReplyTheme');
export const getAvatarBackgroundColor = get('avatarBackgroundColor');
export const getHeaderBackgroundColor = get('headerBackgroundColor');
export const getAvatarTextColor = get('avatarTextColor');
export const getAvatarBorderColor = get('avatarBorderColor');
export const getHeaderTextColor = get('headerTextColor');