import { WHATSAPP_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
import formatPhoneNumber from 'I18n/utils/formatPhoneNumber';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { useThreadDetails } from '../../../thread-details-feature/public/hooks/useThreadDetails';
import { getOriginalGenericChannelId } from 'conversations-thread-data/thread-details/public/operators';
import { useAgentVisitorDeliveryIdentifiers } from 'conversations-thread-data/delivery-identifiers/public/hooks';
export const useGetContactAssociationProps = ({
  threadId
}) => {
  const contactAssociationBuilderExtraProps = {
    defaultProperties: {},
    extraInitialCreatorPropertyNames: []
  };
  const {
    asyncThreadDetails
  } = useThreadDetails({
    threadId
  });
  const threadDetails = getData(asyncThreadDetails);

  /*  @ts-expect-error Jun-5-2024, 19:34UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
  const genericChannelId = getOriginalGenericChannelId(threadDetails);
  const {
    threadVisitorDeliveryIdentifiersData
  } = useAgentVisitorDeliveryIdentifiers({
    threadId,
    /*  @ts-expect-error Jun-5-2024, 19:34UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
    genericChannelId
  });
  const allVisitorDeliveryIdentifiers = threadVisitorDeliveryIdentifiersData.map(d => d.deliveryIdentifier);
  if (allVisitorDeliveryIdentifiers.length === 0) {
    return contactAssociationBuilderExtraProps;
  }

  /*  @ts-expect-error Jun-5-2024, 19:34UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
  switch (getOriginalGenericChannelId(threadDetails)) {
    case WHATSAPP_GENERIC_CHANNEL_ID:
      {
        // currently this is only used for whatsapp, whatsapp can only have one visitor/recipient
        const defaultVisitorIdentifier = allVisitorDeliveryIdentifiers[0];
        const hsWhatsAppPhoneNumber = formatPhoneNumber(defaultVisitorIdentifier.value, '');
        if (hsWhatsAppPhoneNumber) {
          contactAssociationBuilderExtraProps.defaultProperties = {
            hs_whatsapp_phone_number: hsWhatsAppPhoneNumber
          };
          contactAssociationBuilderExtraProps.extraInitialCreatorPropertyNames.push('hs_whatsapp_phone_number');
        }
        break;
      }
    default:
      {
        return contactAssociationBuilderExtraProps;
      }
  }
  return contactAssociationBuilderExtraProps;
};