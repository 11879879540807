// original: https://git.hubteam.com/HubSpot/conversations-internal-schema/blob/1729733ab4b5cfdd5950af37c67157f5deb9589b/static/js/team/operators/buildSalesTeamMap.js
import { fromJS, Map as ImmutableMap } from 'immutable';
import { AppUsersTeam } from '../records/Team';
const DEFAULT_TEAM_MAKER = team => new AppUsersTeam(team.toJS());
const mapItemKey = (item, makeTeam) => {
  const data = makeTeam(item);
  // We have to do some fudge work here because Immutable won't
  // allow us to specify a common type for Records but any type
  // that passes in to here should have a `childTeams` and `id`
  // since we work on those attributes
  const teamKids = data.get('childTeams');
  let childTeams = ImmutableMap();
  if (teamKids && teamKids.size) {
    childTeams = ImmutableMap(teamKids.reduce((kids, child) => Object.assign({}, kids, {
      [`${child.get('id')}`]: mapItemKey(child, makeTeam)
    }), {}));
  }
  return ImmutableMap({
    data,
    selected: false,
    childTeams
  });
};
export const buildSalesTeamsMap = (response, maybeMakeTeam) => {
  const makeTeam = maybeMakeTeam || DEFAULT_TEAM_MAKER;
  return fromJS(response).toMap().mapKeys((_key, item) => `${item.get('id')}`).map(item => mapItemKey(item, makeTeam));
};
export const convertTeamsMapToArray = teamTree => {
  const convertNode = node => {
    const data = node.get('data').toJS();
    const childTeams = node.get('childTeams');
    const mappedChildTeams = childTeams.valueSeq().map(childNode => convertNode(childNode)).toArray();

    // Append existing data fields and childTeams together
    const updatedData = Object.assign({}, data, {
      childTeams: mappedChildTeams
    });
    return updatedData;
  };
  return teamTree.valueSeq().map(node => convertNode(node)).toArray();
};