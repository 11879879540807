function getOperationOffset(operation) {
  switch (operation) {
    case 'ADD':
      return 1;
    case 'REMOVE':
      return -1;
    default:
      return 0;
  }
}
export function applyOperationsToCountOffset(countOffsetsByViewId, operations) {
  return operations.reduce((offsets, {
    viewId,
    operation
  }) => {
    const viewOffset = offsets.get(viewId, 0) + getOperationOffset(operation);
    return viewOffset === 0 ? offsets.delete(viewId) : offsets.set(viewId, viewOffset);
  }, countOffsetsByViewId);
}