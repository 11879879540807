/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable hubspot-dev/no-declarations */

import userInfo from 'hub-http/userInfo';
import { loadCrossTabMessaging } from '../utils/loadCrossTabMessaging';
import { addAsyncFailedEventListener, addAsyncLoadEventListener, removeAsyncFailedEventListener, removeAsyncLoadEventListener } from '../utils/asyncLoadEvents';
import once from 'shared-worker-versioning/lib/utils/once';
import { getMetricsFactory } from '../utils/Metrics';
import { getCallingWidgetConfig } from './getCallingWidgetConfig';
import { getNoopCrossTabMessagingInstance } from '../constants/NoopCrossTabMessagingInstance';
import { getUnsupportedReason } from '../utils/getIsCrossTabMessagingSupported';
import { UnsupportedReason } from '../constants/UnsupportedReason';
import { getDependencyStatus } from '../utils/getDependencyStatus';
const getUserInfoOnce = once(() => userInfo({
  safeMode: true
}));
const checkCallingAccessOnce = once(auth => {
  try {
    window.hsHasCallingAccessScope = auth.user.scopes.includes('calling-access');
    return auth.user.scopes.includes('calling-access');
  } catch (e) {
    return false;
  }
});
export const getHasCallingAccess = once(() => {
  if (typeof window.hsHasCallingAccessScope === 'undefined') {
    return getUserInfoOnce().then(auth => {
      checkCallingAccessOnce(auth);
      return window.hsHasCallingAccessScope;
    });
  }
  return Promise.resolve(window.hsHasCallingAccessScope);
});
const getSharedWorkerConnectionSingletonOnce = once(() => new Promise(resolve => {
  // If this global exists, the file has loaded and there's and we can immediately return the result.
  if (window.hsCallingWorkerEmbedConnection) {
    return resolve(window.hsCallingWorkerEmbedConnection);
  }
  const reason = getUnsupportedReason();
  if (reason !== UnsupportedReason.INAPPLICABLE) {
    getMetricsFactory().counter('unsupported', {
      reason
    }).increment();
    if (!window.hsCallingWorkerEmbedConnection) window.hsCallingWorkerEmbedConnection = getNoopCrossTabMessagingInstance();
    return resolve(window.hsCallingWorkerEmbedConnection);
  }
  return getUserInfoOnce().then(auth => {
    // Never use this gate outside this call.
    if (!checkCallingAccessOnce(auth)) {
      resolve(undefined);
      return;
    } else if (window.hsCallingWorkerEmbedConnection) {
      resolve(window.hsCallingWorkerEmbedConnection);
      return;
    }
    const callingWorkerDetails = getCallingWidgetConfig();
    const handleAsyncLoadEvent = () => {
      removeAsyncLoadEventListener(handleAsyncLoadEvent);
      removeAsyncFailedEventListener(handleAsyncLoadEvent);
      if (!window.hsCallingWorkerEmbedConnection) {
        const depStatus = getDependencyStatus();
        getMetricsFactory().counter('dependancy-failure', {
          status: depStatus
        }).increment();
        resolve(undefined);
        return;
      }
      resolve(window.hsCallingWorkerEmbedConnection);
    };
    addAsyncLoadEventListener(handleAsyncLoadEvent);
    addAsyncFailedEventListener(handleAsyncLoadEvent);
    if (!callingWorkerDetails.isLoadingStarted) {
      callingWorkerDetails.isLoadingStarted = true;
      loadCrossTabMessaging({
        onFailure: handleAsyncLoadEvent
      });
    }
  }).catch(() => {
    // nothing to see here, if userInfo fails we don't need to continue.
  });
}));
export const waitForSharedWorkerConnectionSingleton = () => {
  const callingWorkerDetails = getCallingWidgetConfig();
  if (!callingWorkerDetails.sharedWorkerConnectionSingletonPromise) {
    callingWorkerDetails.sharedWorkerConnectionSingletonPromise = getSharedWorkerConnectionSingletonOnce();
  }
  return callingWorkerDetails.sharedWorkerConnectionSingletonPromise;
};