import http from 'conversations-http/clients/http';
export const getFileAttachments = ({
  fileAttachmentIds
}) => {
  const fileIds = fileAttachmentIds.toJS ? fileAttachmentIds.toJS() : fileAttachmentIds;
  return http.get(`filemanager/api/v2/files/multiple`, {
    query: {
      id: fileIds
    }
  });
};