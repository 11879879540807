import Ticket from '../records/models/Ticket';
import { CLEAR_ROADBLOCK, LOAD_TICKET, SAVE_PRODUCT_AREA, SAVE_RESOLUTION, SAVE_ROADBLOCK, SAVE_SUBJECT } from '../actions/ActionTypes';
function ticket(state = new Ticket(), action) {
  switch (action.type) {
    case CLEAR_ROADBLOCK:
      return state.set('roadblock', null);
    case LOAD_TICKET:
      {
        if (action.error) {
          return Ticket.fromJS({
            conversationId: action.conversationId
          });
        }
        return action.meta === 'response' ? Ticket.fromJS(action.payload) : state;
      }
    case SAVE_RESOLUTION:
      return action.meta === 'request' ? state.set('resolution', action.payload) : state;
    case SAVE_ROADBLOCK:
      return action.meta === 'request' ? state.set('roadblock', action.payload) : state;
    case SAVE_PRODUCT_AREA:
      return action.meta === 'request' ? state.set('productArea', action.payload) : state;
    case SAVE_SUBJECT:
      return action.meta === 'request' ? state.set('subject', action.payload) : state;
    default:
      return state;
  }
}
export default ticket;