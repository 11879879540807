import http from 'hub-http/clients/apiClient';
import { registerQuery } from 'data-fetching-client';
import { WHATSAPP_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
export const WHATSAPP_CHANNELS_FIELDNAME = 'whatsapp_channel_instances';
export const fetchWhatsAppChannelIsActive = registerQuery({
  fieldName: WHATSAPP_CHANNELS_FIELDNAME,
  args: [],
  fetcher() {
    return http.get(`conversations-channels/v1/channel-instances/channelId/${WHATSAPP_GENERIC_CHANNEL_ID}/active`);
  }
});