import { Record } from 'immutable';
class ToolLinksRecord extends Record({
  chatSourceLink: undefined,
  hubDetailsLink: undefined,
  hubId: undefined,
  hubIdLink: undefined,
  fccSupportInboxLink: undefined,
  emailSupportToolLink: undefined,
  sqlFormLink: undefined,
  zorseTicketLink: undefined,
  loading: undefined
}, 'ToolsLinkRecord') {}
export default ToolLinksRecord;