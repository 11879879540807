// eslint-disable-next-line hs-react-native/no-dark-mode-incompatible-code
import { BATTLESHIP, KOALA, OLAF, OBSIDIAN } from 'HubStyleTokens/colors';
import { Record } from 'immutable';
import FileAttachmentTheme from '../../attachments/records/FileAttachmentTheme';
import QuickReplyTheme from '../../quick-reply/records/QuickReplyTheme';
/**
 * Represents the default values consumed by `PrimaryMessage` components required for rendering.
 */
const Theme = Record({
  alignItems: 'flex-start',
  avatarWidth: '32px',
  backgroundColor: KOALA,
  borderColor: BATTLESHIP,
  borderRadius: '4px',
  borderWidth: '1px',
  messageSpacing: '8px',
  textColor: OBSIDIAN,
  fileAttachmentTheme: FileAttachmentTheme(),
  quickReplyTheme: QuickReplyTheme(),
  headerBackgroundColor: OLAF,
  headerTextColor: OBSIDIAN,
  avatarBackgroundColor: BATTLESHIP,
  avatarBorderColor: BATTLESHIP,
  avatarTextColor: OBSIDIAN
}, 'Theme');
export default Theme;