import { useEffect } from 'react';
import { useUnassignedSeats } from '../../seats/hooks/useUnassignedSeats';
export const useUnassignedSeatsBanner = dismissFooter => {
  const {
    unassignedSeatCount,
    isFailed,
    isFetching
  } = useUnassignedSeats();
  useEffect(() => {
    if (isFailed) {
      dismissFooter();
    }
  }, [isFailed, dismissFooter]);
  return {
    isFetching,
    unassignedSeatCount
  };
};