import { threadListsUpdated } from 'find-and-filter-data/view-counts-and-unread/public';
import { customViewUpdated, fetchCustomView } from 'find-and-filter-data/views-data/public';
import { getCustomViewIdForStatusChange, isProcessingCompletedMessage } from 'find-and-filter-data/views-schema/public';
import Raven from 'raven-js';
import { selectIsMessageAlreadyApplied } from '../latest-omnibus-timestamp/selectIsMessageAlreadyApplied';
import { inboxUpdated } from './inboxUpdated';
import { handleThreadsBulkUpdate } from './threads-bulk-updated/handleThreadsBulkUpdate';
export const handleInboxChannelMessage = ({
  currentAgentId,
  message,
  onBulkUpdateFailed,
  onBulkUpdateSuccess,
  publishContext = {
    playback: false
  }
}) => (dispatch, getState) => {
  switch (message.data['@type']) {
    case 'THREADS_UPDATED_BULK':
      {
        if (publishContext.playback) break;
        dispatch(handleThreadsBulkUpdate({
          currentAgentId,
          message: message.data,
          onBulkUpdateFailed,
          onBulkUpdateSuccess
        }));
        break;
      }
    case 'THREAD_LISTS_UPDATED':
      {
        const isAlreadyApplied = selectIsMessageAlreadyApplied(getState(), {
          timestamp: message.timestamp
        });
        if (isAlreadyApplied) break;
        dispatch(threadListsUpdated({
          threadListsUpdate: message.data,
          agentId: currentAgentId
        }));
        break;
      }
    case 'INBOX_UPDATE':
      {
        const isAlreadyApplied = selectIsMessageAlreadyApplied(getState(), {
          timestamp: message.timestamp
        });
        if (isAlreadyApplied) break;
        dispatch(inboxUpdated({
          message: message.data,
          currentAgentId
        }));
        break;
      }
    case 'CUSTOM_VIEW_STATUS_CHANGE':
      {
        if (publishContext.playback) break;
        const customViewId = getCustomViewIdForStatusChange(message.data);
        dispatch(customViewUpdated(message.data));
        /*
        When getting a CUSTOM_VIEW_STATUS_CHANGE pubsub response we want
        to rehydrate the custom view whenever a creation or update is
        finished processing. We're dispatching customViewUpdated and
        fetchCustomView synchronously in these cases to ensure there are
        no discrepancies among different users and portals
        */
        if (isProcessingCompletedMessage(message.data)) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          dispatch(fetchCustomView({
            customViewId
          }));
        }
        break;
      }
    default:
      {
        Raven.captureException(Error(`UNHANDLED_MESSAGE_ON_INBOX_CHANNEL: ${message.data['@type']}`), {
          extra: {
            message: message.data
          }
        });
        break;
      }
  }
};