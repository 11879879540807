import { createSlice } from '@reduxjs/toolkit';
import { viewMemberUpdated } from 'find-and-filter-data/realtime-updates/public';
import { fetchViewMembers } from 'find-and-filter-data/view-members-data/protected';
import { getThreadId } from 'find-and-filter-data/view-members-schema/protected';
import { getViewMemberId } from 'find-and-filter-data/view-members-schema/public';
import { Map as ImmutableMap } from 'immutable';
export const selectViewMemberPatches = state => state.findAndFilterData.viewMembersUpdates.data;
const initialState = {
  data: ImmutableMap()
};
export const viewMembersUpdatesSlice = createSlice({
  name: 'viewMembersUpdates',
  initialState,
  reducers: {
    /**
     * THREADS_UPDATED
     * When THREADS_UPDATED come in we want to remove our FE patches and let
     * these remain the source of truth.
     */
    threadsUpdated(state, action) {
      const message = action.payload.message;
      const addedMembersThreadIds = message.added.map(getThreadId);
      const removedMembersThreadIds = message.removed.flatMap(updates => updates.members).map(getThreadId);
      const updatedMembersThreadIds = message.updated.flatMap(updates => updates.members).map(getThreadId);
      const updatedThreadIds = addedMembersThreadIds.concat(removedMembersThreadIds, updatedMembersThreadIds); // never null

      let newState = state.data;
      for (const id of updatedThreadIds) {
        newState = newState.delete(`${id}`);
      }
      state.data = newState;
    },
    viewMemberPatched(state, action) {
      const patch = action.payload;
      state.data = state.data.set(patch.threadId, patch);
    }
  },
  extraReducers: builder => {
    /**
     * fetchViewMembers.fulfilled
     * We reset the reducer when the thread list is changed because we would
     * need to re-evaluate every TLM patch against the new view in order to
     * correctly determine if a patch should actually be applied.
     *
     * Example: View A(only OPENED threads) & View B(only CLOSED threads)
     * A patch created for a closed thread in view A shouldn't be applied
     * to that same thread in view B.
     */
    builder.addCase(fetchViewMembers.fulfilled, (state, action) => {
      const {
        meta: {
          arg: {
            offsetId
          }
        }
      } = action;
      const isInitialViewFetch = offsetId === undefined || offsetId < 0;
      if (isInitialViewFetch) {
        state.data = initialState.data;
      }
    })
    /**
     * VIEW_UPDATE
     * When VIEW_UPDATE come in we want to remove our FE patches and let
     * these remain the source of truth.
     */.addCase(viewMemberUpdated, (state, {
      payload: {
        message
      }
    }) => {
      const updatedThreadIds = message.upserted.map(x => getViewMemberId(x)).concat(message.removed.map(x => getViewMemberId(x)));
      return {
        data: updatedThreadIds.reduce((acc, threadId) => acc.delete(`${threadId}`), state.data)
      };
    });
  }
});
export const {
  actions: {
    threadsUpdated,
    viewMemberPatched
  }
} = viewMembersUpdatesSlice;