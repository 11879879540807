import { useContext } from 'react';
// TODO: move to composer-ui
import { ChannelCapabilitiesConfigContext } from 'conversations-thread-view/channel-capabilities/context/ChannelCapabilitiesConfigContext';
import { getEditorTypingIndicator, getEditorMaxContentInBytes, getEditorPlaceholder, getEditorSubmitButtonText, getChannelType } from 'conversations-thread-data/channel-configuration/public/operators';
export const useEditorCapabilitiesConfig = () => {
  const {
    capabilitiesBlob
  } = useContext(ChannelCapabilitiesConfigContext);
  return {
    channelType: getChannelType(capabilitiesBlob),
    placeholder: getEditorPlaceholder(capabilitiesBlob),
    editorMaxContentSizeLimit: getEditorMaxContentInBytes(capabilitiesBlob),
    isEditorTypingIndicatorEnabled: getEditorTypingIndicator(capabilitiesBlob),
    submitButtonText: getEditorSubmitButtonText(capabilitiesBlob)
  };
};