/**
 * @description This operator will take a Thread status(STARTED, ENDED, etc) and
 * will return the corresponding Conversation status if it exists, otherwise it
 * will just return null.
 */
export function getConversationStatusFromThreadStatus(threadStatus) {
  switch (threadStatus) {
    case 'STARTED':
      return 'CONVERSATION_STATUS_OPEN';
    case 'ENDED':
      return 'CONVERSATION_STATUS_CLOSED';
    default:
      return null;
  }
}