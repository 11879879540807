import { useTicketPipelines } from './useTicketPipelines';
import { useAssociatedTickets } from './useAssociatedTickets';
import { useMemo } from 'react';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { FAILED, STARTED, SUCCEEDED } from 'conversations-async-data/async-data/constants/asyncStatuses';
import { getStagesForTicket } from '../operators/getStagesForTicket';

/**
 * Returns the pipelines stages for the primary ticket for the given thread.
 */
export const useTicketStagesForThread = ({
  apolloClient,
  conversationId
}) => {
  const {
    associatedTicket,
    error: associatedTicketError,
    loading: associatedTicketLoading
  } = useAssociatedTickets({
    apolloClient,
    conversationId
  });
  const {
    error: pipelineError,
    loading: pipelinesLoading,
    pipelines
  } = useTicketPipelines();
  const asyncTicketStages = useMemo(() => {
    const stages = associatedTicket && getStagesForTicket({
      ticket: associatedTicket,
      ticketPipelines: pipelines
    });
    if (pipelinesLoading || associatedTicketLoading) {
      return new AsyncData({
        status: STARTED,
        data: stages
      });
    }
    if (pipelineError || associatedTicketError) {
      return new AsyncData({
        status: FAILED,
        data: stages
      });
    }
    return new AsyncData({
      status: SUCCEEDED,
      data: stages
    });
  }, [associatedTicket, associatedTicketError, associatedTicketLoading, pipelines, pipelineError, pipelinesLoading]);
  return {
    asyncTicketStages
  };
};