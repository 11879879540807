import { useState, useCallback } from 'react';
// @ts-expect-error untyped-module
import { addDanger } from 'customer-data-ui-utilities/alerts/Alerts';
import * as QuotesActions from 'quotes-ui-lib/store/QuotesActions';
import QuotesLogger from 'quotes-ui-lib/tracking/quotesLogger';
const useRecallAndEditQuote = (quote, trackingLocation, onRecallSuccess) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const trackAction = useCallback(action => {
    QuotesLogger.log('quoteInteraction', {
      action,
      location: trackingLocation
    });
  }, [trackingLocation]);
  const recallQuote = useCallback(() => {
    trackAction('Recall quote');
    setIsLoading(true);
    QuotesActions.recallPublishedQuote(quote).then(onRecallSuccess).catch(() => {
      addDanger('quotesUiLib.recallQuoteModal.failure');
    }).finally(() => {
      setIsModalOpen(false);
      setIsLoading(false);
    });
  }, [quote, setIsModalOpen, setIsLoading, onRecallSuccess, trackAction]);
  return [isModalOpen, setIsModalOpen, isLoading, recallQuote];
};
export default useRecallAndEditQuote;