import { UNASSIGNED } from '../constants/RoutingTypes';
import RoutingRuleBase from './RoutingRuleBase';
import setIn from 'transmute/setIn';
class UnassignedRoutingRules extends RoutingRuleBase({
  '@type': UNASSIGNED
}, 'UnassignedRoutingRules') {
  constructor(options) {
    // make sure the type is always set to UNASSIGNED in case the provided options['@type'] is something
    // else
    super(setIn(['@type'], UNASSIGNED, options || {}));
  }
}
export const buildUnassignedRoutingRules = options => new UnassignedRoutingRules(options);
export default UnassignedRoutingRules;