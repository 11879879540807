import { isLoading } from 'conversations-async-data/async-data/operators/statusComparators';

// TODO: move to composer-data

// TODO: move to composer-data
import invariant from 'conversations-thread-data/utils/invariant';
export const accountsLoading = ({
  asyncConnectedAccounts
}) => {
  invariant(Boolean(asyncConnectedAccounts), 'asyncConnectedAccounts is required for accountsLoading disabling strategy');
  return isLoading(asyncConnectedAccounts) ? {
    disabled: true,
    sendDisabledReason: 'CONNECTING_TO_HUBSPOT'
  } : null;
};