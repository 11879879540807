import { createSlice } from '@reduxjs/toolkit';
export const initialState = [];
export const quickFiltersSlice = createSlice({
  name: 'quickFilters',
  initialState,
  reducers: {
    setQuickFilters(state, action) {
      if (action.payload.filters !== state) {
        return action.payload.filters;
      } else return state;
    }
  }
});
export const {
  actions: {
    setQuickFilters
  }
} = quickFiltersSlice;