import { getChatUrl } from './getChatUrl';
import { getInboxUrl } from './getInboxUrl';
import pathIncludesInboxPath from './pathIncludesInboxPath';
import pathIncludesChatPath from './pathIncludesChatPath';
const getThreadUrl = threadId => {
  if (pathIncludesInboxPath()) {
    return getInboxUrl(threadId);
  }
  if (pathIncludesChatPath()) {
    return getChatUrl(threadId);
  }
  return getInboxUrl(threadId);
};
export { getThreadUrl as default, getThreadUrl };