import get from 'transmute/get';
/**
 * @description A viewId differs between a CustomView and ThreadList record. This
 * operator will generate a viewId from a customViewId, threadListId, and threadListType
 * based on which exist.
 *
 * This operator will give precedence to customViewId - if
 * customViewId exists then a key based on that will be returned regardless of the
 * threadList properties.
 */
export function generateViewId(partialView) {
  const customViewId = get('customViewId', partialView);
  const threadListId = get('threadListId', partialView);
  const threadListType = get('threadListType', partialView);
  if (customViewId && customViewId > 0) {
    return `${customViewId}`;
  } else {
    return `${threadListType}-${threadListId}`;
  }
}

/**
 * @description Given a viewId this will parse the threadListType and threadListId
 * if the viewId is a ThreadList compound key, or will return customViewId for a
 * CustomView id
 */
export function parseViewId(viewId) {
  const [threadListTypeOrCustomViewId, threadListId] = viewId.split('-');
  if (threadListId) {
    return {
      customViewId: null,
      threadListId: Number(threadListId),
      threadListType: threadListTypeOrCustomViewId
    };
  } else {
    return {
      customViewId: Number(threadListTypeOrCustomViewId),
      threadListId: null,
      threadListType: null
    };
  }
}