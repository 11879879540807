import { useEffect, useRef, useState } from 'react';
import { safelyParseJson } from '../utils';
import { usePubSub } from './usePubSub';
const checkIsMessageData = messageData => !!messageData['@type'];
const checkIsMessageMatch = (messageData, messageType) => checkIsMessageData(messageData) && messageData['@type'] === messageType;
export const useAblyChannel = ({
  channel,
  type,
  onMessage,
  onPlayback,
  onReconnect = () => {}
}) => {
  const ablyClient = usePubSub();
  const onMessageRef = useRef(onMessage);
  const onPlaybackRef = useRef(onPlayback);
  const onReconnectRef = useRef(onReconnect);
  const [isSuspended, setIsSuspended] = useState(false);
  useEffect(() => {
    onMessageRef.current = onMessage;
  }, [onMessage]);
  useEffect(() => {
    onPlaybackRef.current = onPlayback;
  }, [onPlayback]);

  // Handle Ably message events
  useEffect(() => {
    const listener = message => {
      const messageData = safelyParseJson(message.data);
      if (checkIsMessageMatch(messageData, type)) {
        onMessageRef.current(messageData);
      }
    };
    const playbackListener = messages => {
      const messagesData = messages.reduce((acc, message) => {
        const messageData = safelyParseJson(message.data);
        if (checkIsMessageMatch(messageData, type)) {
          acc.push(messageData);
        }
        return acc;
      }, []);
      onPlaybackRef.current(messagesData);
    };
    if (ablyClient) {
      // disabling because we don't need async logic here
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      ablyClient.subscribe({
        channelId: channel,
        playbackMessages: true,
        onMessage: listener,
        onPlayback: playbackListener
      });
    }
    return () => {
      if (ablyClient) {
        // disabling because we don't need async logic here
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        ablyClient.unsubscribe({
          channelId: channel,
          onMessage: listener
        });
      }
    };
  }, [ablyClient, channel, type]);

  // Handle Ably lifecycle events
  useEffect(() => {
    const suspendedListener = () => {
      setIsSuspended(true);
    };
    const attachedListener = () => {
      if (isSuspended) {
        setIsSuspended(false);
        onReconnectRef.current();
      }
    };
    if (ablyClient) {
      const ablyChannel = ablyClient.realtime.channels.get(channel);
      ablyChannel.on('suspended', suspendedListener);
      ablyChannel.on('attached', attachedListener);
    }
    return () => {
      if (ablyClient) {
        const ablyChannel = ablyClient.realtime.channels.get(channel);
        ablyChannel.off('suspended', suspendedListener);
        ablyChannel.off('attached', attachedListener);
      }
    };
  }, [ablyClient, isSuspended, channel]);
};