import I18n from 'I18n';
import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchCallTypes } from '../clients/fetchCallTypes';
export const FETCH_CALL_TYPES = registerQuery({
  fieldName: 'callTypes',
  fetcher: fetchCallTypes
});
export const useFetchCallTypes = () => {
  const {
    loading,
    error,
    data
  } = useQuery(FETCH_CALL_TYPES);
  if (loading || error || !data) {
    return {
      callTypes: null,
      loading
    };
  }
  const callTypes = [{
    text: I18n.text('conversations-calling-message.timeline-call-metadata.call-type.placeholder'),
    value: undefined
  }];
  data.callTypes.forEach(callType => {
    callTypes.push({
      text: callType.name,
      value: callType.name
    });
  });
  return {
    callTypes,
    loading
  };
};