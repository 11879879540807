import pick from 'transmute/pick';
import toJS from 'transmute/toJS';
import { isThreadListMemberFieldToUpdate } from '../should-update/areOptimisticUpdateFields';
const UNKNOWN_REMOVAL_AUDIT = {
  '@type': 'UNKNOWN'
};

/**
 * @description This operator will build a ThreadListMemberUpdate patch which
 * contains the details necessary for the FE to optimistically update the
 * corresponding ThreadListMember.
 */
export const buildThreadListMemberUpdatePatch = ({
  propertyPasses,
  threadId,
  threadListMemberOrDetails,
  updatedTLMProperties
}) => {
  const threadListMemberProperties = updatedTLMProperties ? updatedTLMProperties.filter(isThreadListMemberFieldToUpdate) : [];
  const updatedTLM = toJS(pick(threadListMemberProperties, threadListMemberOrDetails));
  /**
   * This is the partial threadListMember that is directly merged into the real
   * ThreadListMember. As such this should only contain TLM properties.
   */
  const threadListMember = Object.assign({}, updatedTLM, {
    /**
     * If the property passes the filter criteria, we don't want to remove the TLM
     * and should set removal to null to remove any overlay that may be on it, and
     * if it does not pass then we create a general overlay type.
     */
    removal: propertyPasses ? null : UNKNOWN_REMOVAL_AUDIT
  });
  return {
    propertyPasses,
    threadId,
    threadListMember
  };
};