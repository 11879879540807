import { Set as ImmutableSet } from 'immutable';
import filter from 'transmute/filter';
import { handleActions, combineActions } from 'redux-actions';
import { updateAsyncData } from 'conversations-async-data/async-data/operators/updateAsyncData';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestReset } from 'conversations-async-data/async-data/operators/requestReset';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { ADD_CONVERSATION_TO_BATCH_GROUP, REMOVE_CONVERSATION_FROM_BATCH_GROUP, CLEAR_THREAD_LIST_BATCH_GROUP, ADD_ALL_CONVERSATIONS_TO_BATCH_GROUP } from '../constants/actionTypes';
import { MARK_THREADS_AS_CLOSED, DELETE_THREADS, RESTORE_THREADS } from '../../batch-thread-operations-toolbar/constants/asyncActionTypes';
import { MAX_THREAD_COUNT } from '../../batch-thread-operations-toolbar/constants/maxThreadCount';
import { FETCH_FROM_OMNI_BUS } from 'conversations-inbox-lib/omnibus/public/constants';
import { fetchViewMembers } from 'find-and-filter-data/view-members-data/protected';
import { fetchSearchAndFilterResults } from 'find-and-filter-data/search-and-filter-data/protected';
const initialState = new AsyncData({
  data: ImmutableSet()
});
export default handleActions({
  [ADD_CONVERSATION_TO_BATCH_GROUP](state, action) {
    return updateAsyncData(list => list.add(action.payload), state);
  },
  [ADD_ALL_CONVERSATIONS_TO_BATCH_GROUP](state, action) {
    return updateAsyncData(list => list.merge(action.payload.take(MAX_THREAD_COUNT)), state);
  },
  [REMOVE_CONVERSATION_FROM_BATCH_GROUP](state, action) {
    return updateAsyncData(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    list => filter(id => id !== action.payload, list), state);
  },
  [combineActions(CLEAR_THREAD_LIST_BATCH_GROUP, FETCH_FROM_OMNI_BUS.STARTED).toString()]() {
    return initialState;
  },
  [combineActions(fetchSearchAndFilterResults.pending.type, fetchViewMembers.pending.type).toString()](state, action) {
    const {
      meta: {
        arg
      }
    } = action;
    const isInitialFetch = !arg.offsetId;
    if (isInitialFetch) {
      return initialState;
    }
    return state;
  },
  [combineActions(MARK_THREADS_AS_CLOSED.STARTED, DELETE_THREADS.STARTED, RESTORE_THREADS.STARTED).toString()](state) {
    return requestStarted(state);
  },
  [combineActions(MARK_THREADS_AS_CLOSED.SUCCEEDED, DELETE_THREADS.SUCCEEDED, RESTORE_THREADS.SUCCEEDED).toString()]() {
    return requestReset(initialState);
  },
  [combineActions(MARK_THREADS_AS_CLOSED.FAILED, DELETE_THREADS.FAILED, RESTORE_THREADS.FAILED).toString()](state) {
    return requestReset(state);
  }
}, initialState);