import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchBlocklistCheckEmails } from '../clients/fetchBlocklistCheckEmails';
const BLOCKLIST_CHECK_EMAILS_QUERY = registerQuery({
  fieldName: 'blocklistCheckEmails',
  args: ['emailsToCheck'],
  fetcher: fetchBlocklistCheckEmails
});
export function useBlocklistCheckEmails({
  emailsToCheck,
  skip
}) {
  const {
    data,
    loading,
    error
  } = useQuery(BLOCKLIST_CHECK_EMAILS_QUERY, {
    skip: emailsToCheck.length === 0 || skip,
    variables: {
      emailsToCheck
    }
  });
  return {
    emailBlocklistStatus: data === null || data === void 0 ? void 0 : data.blocklistCheckEmails.emailChecks,
    loading,
    error
  };
}