import { handleActions } from 'redux-actions';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import IndexedAsyncData from 'conversations-async-data/indexed-async-data/IndexedAsyncData';
import { updateEntries } from 'conversations-async-data/indexed-async-data/operators/updateEntries';
import { getEntries } from 'conversations-async-data/indexed-async-data/operators/getters';
import { requestFailed } from 'conversations-async-data/async-data/operators/requestFailed';
import { requestOutOfSync } from 'conversations-async-data/async-data/operators/requestOutOfSync';
import { requestStarted } from 'conversations-async-data/async-data/operators/requestStarted';
import { requestSucceededWithOperator } from 'conversations-async-data/async-data/operators/requestSucceededWithOperator';
import { FETCH_VISITORS } from '../constants/asyncActionTypes';
import { VISITORS_INVALIDATED } from '../../realtime/public/constants';
import { limitToCount } from 'conversations-async-data/indexed-async-data/eviction-strategies/limitToCount';
import { VISITORS_ASYNC_DATA_CACHE_SIZE } from '../../common/public/constants/CacheSize';
const initialState = new IndexedAsyncData({
  name: 'visitors',
  notSetValue: new AsyncData(),
  evict: limitToCount(VISITORS_ASYNC_DATA_CACHE_SIZE)
});
export default handleActions({
  [FETCH_VISITORS.STARTED](state, action) {
    const {
      vids
    } = action.payload.requestArgs;
    return updateEntries(vids, (__vid, asyncData) => requestStarted(asyncData), state);
  },
  [FETCH_VISITORS.SUCCEEDED](state, action) {
    const {
      data,
      requestArgs
    } = action.payload;
    const {
      vids
    } = requestArgs;
    return updateEntries(vids, (vid, asyncData) => requestSucceededWithOperator(() => data.get(vid) || null, asyncData), state);
  },
  [FETCH_VISITORS.FAILED](state, action) {
    const {
      vids
    } = action.payload.requestArgs;
    return updateEntries(vids, (__vid, asyncData) => requestFailed(asyncData), state);
  },
  [VISITORS_INVALIDATED](state, action) {
    const {
      vid
    } = action.payload;
    const currentListOfVids = getEntries(state).keySeq().toList();
    const updatedListOfVids = vid ? currentListOfVids.push(vid.toString()) : currentListOfVids;
    return updateEntries(updatedListOfVids.toSet(), (__vid, asyncData) => requestOutOfSync(asyncData), state);
  }
}, initialState);