'use es6';

import { getMatches } from './getPrunedRepliesFromDrafts';
import { clearCommonReplyDraft } from './clearCommonReplyDraft';
import { restoreCommonReplyFromDraft } from './restoreCommonReplyFromDraft';
import { editorStateHasContent } from '../../../editor-state/protected/operators';
import { getEditorState, getAttachments, getTimestamp } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
const FIVE_HOURS_IN_MS = 1000 * 60 * 60 * 5;
export const evictCommonReplyDraft = () => {
  const matches = getMatches();
  if (matches.length <= 0) return;
  matches.forEach(draft => {
    const [localStorageKey] = draft;
    const restored = restoreCommonReplyFromDraft(localStorageKey);
    if (!restored) {
      return;
    }
    const draftEditorState = getEditorState(restored);
    const draftAttachments = getAttachments(restored);
    const expiredDraft = getTimestamp(restored) < Date.now() - FIVE_HOURS_IN_MS;
    if (expiredDraft || !editorStateHasContent(draftEditorState, draftAttachments)) {
      clearCommonReplyDraft(localStorageKey);
    }
  });
};