/* hs-eslint ignored failing-rules */
/* eslint-disable hubspot-dev/prefer-relative-imports */

// gimme: conversations-inbox-ui 06/10/2020 24a2e66917cc934433dd111db7e06fd02943ca6a
'use es6';

import { connect } from 'react-redux';
import { getMessageIdForCmf } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { fetchMessageReplies } from 'conversations-thread-view/common-message-reply/actions/fetchMessageReplies';
import { makeGetMessageReplyIsLoading } from '../../common-message-reply/selectors/getMessageReplyIsLoading';
const makeMapStateToProps = () => {
  const getMessageReplyIsLoading = makeGetMessageReplyIsLoading();
  return (state, {
    message
  }) => ({
    messageReplyIsLoading: getMessageReplyIsLoading(state, {
      messageId: getMessageIdForCmf(message)
    })
  });
};
const mapDispatchToProps = {
  fetchReplies: fetchMessageReplies
};
export const withFetchReplies = connect(makeMapStateToProps, mapDispatchToProps);