// gimme: conversations-inbox-ui 05/26/2020 741c62dc71ca742adbc6d64d61d30de7cb46539f
'use es6';

import { createSelector } from 'reselect';
import { getThreadHistories } from 'conversations-thread-data/thread-histories/public/selectors/getters';
import { getEntry } from 'conversations-async-data/indexed-async-data/operators/getters';
const getThreadIdFromProps = (__state, {
  threadId
} = {}) => threadId;
export const getAsyncThreadHistory = createSelector([getThreadIdFromProps, getThreadHistories], (threadId, threadHistory) => {
  if (!threadId) {
    return null;
  }
  return getEntry(threadId, threadHistory);
});