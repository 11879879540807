import { createIndexedDBSuperstore } from 'superstore';
export const localSuperstoreKeys = {
  CALL_SUMMARY_FEEDBACK: 'CallSummaryFeedback'
};
let superStoreInstance;
const getSuperstoreInstance = () => {
  if (!superStoreInstance) {
    try {
      superStoreInstance = createIndexedDBSuperstore({
        namespace: 'post-call-library'
      }).open();
    } catch (e) {
      superStoreInstance = Promise.reject(e);
    }
  }
  return superStoreInstance;
};
export function getSuperstoreValue(key) {
  return getSuperstoreInstance().then(superstore => superstore && superstore.get(key));
}
export function setSuperstoreValue(key, value) {
  return getSuperstoreInstance().then(store => store && store.set(key, value));
}