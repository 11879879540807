import { getGates } from 'conversations-thread-data/auth/public/selectors';
import { useSelector } from 'react-redux';
import { useBlocklistCheckEmails } from './useBlocklistCheckEmails';
import { getCCRecipients, getTORecipients } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
export function useNeverLog({
  commonMessage
}) {
  const gates = useSelector(getGates);
  const isUngatedForNeverLog = gates.includes('ConversationsEmail:RespectNeverLog');
  const toAndCcRecipients = getTORecipients(commonMessage).concat(getCCRecipients(commonMessage)).toSet().toArray();
  const {
    emailBlocklistStatus,
    loading,
    error
  } = useBlocklistCheckEmails({
    emailsToCheck: toAndCcRecipients,
    skip: !isUngatedForNeverLog
  });
  const allToAndCcRecipientsLoggable = toAndCcRecipients.every(email => {
    const isEmailBlocked = emailBlocklistStatus === null || emailBlocklistStatus === void 0 ? void 0 : emailBlocklistStatus[email];
    return !isEmailBlocked;
  });
  const willCreateEngagement = !isUngatedForNeverLog || allToAndCcRecipientsLoggable;
  return {
    emailBlocklistStatus,
    willCreateEngagement,
    loading,
    error
  };
}