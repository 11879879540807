import { CHANNEL_TYPES } from 'conversations-thread-data/channel-configuration/public/constants';
import { getChannelType, getCanStartConversation } from 'conversations-thread-data/channel-configuration/public/operators';
export const getAvailableEditorConfigs = ({
  configurations,
  originalGenericChannelId,
  includeNonGenericChannels = true
}) => {
  if (!configurations) return null;
  return configurations.filter((configuration, editorKey) => {
    if (getChannelType(configuration) === CHANNEL_TYPES.INTERNAL && includeNonGenericChannels) {
      return true;
    }

    // TODO - update to if genericChannelsUsed.includes(editorKey)
    const includesChannelId = originalGenericChannelId === editorKey;
    return Boolean(includesChannelId || getCanStartConversation(configuration));
  });
};